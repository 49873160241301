import React, { useRef, useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { Button, } from "reactstrap";
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import moment from "moment";
import { getCookie } from 'react-use-cookie';
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from '@tinymce/tinymce-react';
import MenuItem from '@mui/material/MenuItem';



function UpdateUserProfil(props) {
    const history = useHistory();
    const [myclasslevel, setmyclasslevel] = useState("n/a");
    const [fiedsupdated, setfiedsupdated] = useState(false);

    const [datamodification, setdatamodification] = useState("0");
    function DataUpdated() {
        setdatamodification("1");
    }

    const handleChangeSupportTraining = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setmyclasslevel(value);

    }

    const support_training_list = [
        {
            value: 'avant_bac',
            label: 'Avant Bac',
        },
        {
            value: 'bac',
            label: 'BAC',
        },
        {
            value: 'bac_3',
            label: 'BAC+3',
        },
        {
            value: 'bac_5',
            label: 'BAC+5',
        },
        {
            value: 'bac_6',
            label: 'Apres Bac+5',
        },

    ];


    const [recorddata_api, setrecorddata_api] = useState();
    const [recorddata_api_message, setrecorddata_api_message] = useState();
    const [recorddata_api_result, setrecorddata_api_result] = useState([]);
    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/add_update_user_profile/";

        var name = document.getElementsByName("name")[0].value;
        var class_level = document.getElementsByName("class_level")[0].value;
        var nb_lang = document.getElementsByName("nb_lang")[0].value;

        const stored_cookie = getCookie('tokenmysych');

        formData.append('token', stored_cookie);
        formData.append('name', name);
        formData.append('etude_level', class_level);
        formData.append('nb_lang', nb_lang);


        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                if (String(result['status']) === String("true")) {
                    setrecorddata_api("true");
                    setrecorddata_api_result(result['message']);
                }else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setrecorddata_api("false");
                    setrecorddata_api_message(result['message']);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setrecorddata_api("false");
            });


    };

    useEffect(() => {
        GetUserProfilData();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, []);


    const [GetUserProfilData_api, setGetUserProfilData_api] = useState();
    const [GetUserProfilData_api_message, setGetUserProfilData_api_message] = useState();
    const [GetUserProfilData_api_result, setGetUserProfilData_api_result] = useState([]);
    function GetUserProfilData() {


        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Get_user_profile/";

        const stored_cookie = getCookie('tokenmysych');
        formData.append('token', stored_cookie);

        var name = props.name_obj;


        axios.post(url, formData).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetUserProfilData  res.data.status = " + res.data.status);
                //console.log(" In GetUserProfilData  res.data.message = " + res.data.message);
                setGetUserProfilData_api("true");

                var mylocalobj = JSON.parse(res.data.message);
                
                if (mylocalobj) {

                    if (mylocalobj.name) {
                        document.getElementsByName("name")[0].value = mylocalobj.name;
                        document.getElementsByName("name")[0].disabled = true;
                        document.getElementsByName("name")[0].style.backgroundColor = "#ECEFF1";
                    }


                    if (mylocalobj.etude_level) {
                        setmyclasslevel(mylocalobj.etude_level);
                        document.getElementsByName("class_level")[0].disabled = true;
                        document.getElementsByName("class_level")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocalobj.nb_lang) {
                        document.getElementsByName("nb_lang")[0].value = mylocalobj.nb_lang;
                        document.getElementsByName("nb_lang")[0].disabled = true;
                        document.getElementsByName("nb_lang")[0].style.backgroundColor = "#ECEFF1";
                    }

                } else {
                    //console.log(" In test  res.data.status = " + res.data.status);
                    //console.log(" In test  res.data.message = " + res.data.message);

                }
            }
            else {
                setGetUserProfilData_api("true");
                setGetUserProfilData_api_message(res.data.message);
            }


        }).catch((error) => {
            console.warn(' GetUserProfilData : Not good man :( mysearchtext = ', error);
            setGetUserProfilData_api("false");

        })
    }


    function editefields() {

        document.getElementsByName("name")[0].disabled = false;
        document.getElementsByName("name")[0].style.backgroundColor = "#ffffff";

        document.getElementsByName("class_level")[0].disabled = false;
        document.getElementsByName("class_level")[0].style.backgroundColor = "#ffffff";


        document.getElementsByName("nb_lang")[0].disabled = false;
        document.getElementsByName("nb_lang")[0].style.backgroundColor = "#ffffff";

    }

    function updateprofile() {
        setfiedsupdated(true);
        editefields();
    }

    return (
        <div className="updateuserprofil">


            <h3> Votre profils </h3>

            
            {String(recorddata_api) === String("true") && <div className="okUpdateData"> Le profil a été ajouté/mise à jour </div>}

            {String(recorddata_api) === String("false") && <div className="koUpdateData"> {recorddata_api_message} </div>}

             &nbsp;
<Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
                onChange={DataUpdated}
            >


                <div className="training_caract">
                    <TextField
                        required
                        name="name"
                        id="name"
                        label="Description"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                    />
                </div>

                <div className="training_caract">
                    <TextField
                        required
                        name="trainer"
                        label="Formateur"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                    />
                </div>

                <div className="training_caract">
                    <TextField
                        name="class_level"
                        id="class_level"
                        select
                        label="Niveau Etude"
                        value={myclasslevel}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChangeSupportTraining}
                        disabled={false}
                        className="disabled_style"
                    >
                        {support_training_list.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label} &nbsp; <br />
                            </MenuItem>
                        ))}
                    </TextField>
                </div>

                <div className="training_caract">
                    <TextField
                        name="nb_lang"
                        id="nb_lang"
                        label="Nombre de langues"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ min: "1", max: "5", step: "1" }}
                        disabled={false}
                        className="disabled_style"
                    />
                </div>



            </Box>
            <div className="div_row" style={{ "marginTop": "1rem" }}>

                {fiedsupdated && <div className="div_row"><div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}

                {fiedsupdated && <div className="div_row">
                    <div className="div_row_gauche">
                        <Button variant="contained" color="success" className="div_row22 bton_enreg " onClick={RecordData}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                    </div>
                    <div className="div_row_droite" style={{ "textAlign": "right" }}>
                        <Button variant="contained" color="success" className="div_row22 bton_annnule" onClick={"CloseLocalcomp"}>Annuler</Button>
                    </div>
                </div>
                }

                {!fiedsupdated && <div className="div_row">

                    <div className="div_row_droite" style={{ "textAlign": "right" }}>
                        <Button variant="contained" color="success" className="div_row22 bton_annnule" onClick={updateprofile}> Modifier</Button>
                    </div>
                </div>
                }


            </div>

        </div>);
}
export default UpdateUserProfil;