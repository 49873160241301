import Recherche_new_v3 from "../components/Recherche_new_v3";
import Navigation from "../components/Navigation";
import Footer from "./footer";

const recherche = () => {


  return (
    <div>
      <Navigation />
      <div>
        <Recherche_new_v3 />
      </div>

    </div>

  )
}


export default recherche;
