
import Test_Drag_Drop from "../components/Test_Drag_Drop";
import Navigation from "../components/Navigation";
export default function Recherche() {
    return (
        <div>
           
            <Navigation />
            <Test_Drag_Drop />
           
        </div>
    );
}