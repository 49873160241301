import React from "react";

  
const ToggleSwitch_v2_mysy = ({ label, checked }) => {
  return (
    <div className="toggleswitch2_mysy">
    <div className="container">
      
      <nav  style={{"width":"100%", "float":"right", "textAlign":'right', "maxHeight":"2.5rem"}}>
        <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} checked={checked} />


        <label className="label" htmlFor={label} style={{"fontSize":"10px"}}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
      </nav> 
    </div>
    </div>
  );
};
  
export default ToggleSwitch_v2_mysy;