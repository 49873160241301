import Link from "next/link";
import SignIn from "../components/SignIn";
import Navigation from "../components/Navigation";



export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
                <SignIn />
               
            </div>
        </div>
    );
}
