
import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
//import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
//import timeGridPlugin from "@fullcalendar/timegrid";

import allLocales from '@fullcalendar/core/locales-all'
import { RepeatOnRounded } from "@mui/icons-material";
import { alignPropType } from "react-bootstrap/esm/types";


import ToggleSwitch from "./ToggleSwitch";
import ToggleSwitch_v2_mysy from "./ToggleSwitch_v2_mysy";
import ToggleSwitch_v3_mysy from "./ToggleSwitch_v3_mysy";


import Autocomplete from '@mui/material/Autocomplete';
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FcSearch } from "react-icons/fc";
import { Fab } from "@material-ui/core";
import {
    ListItem,
    ListItemText,
    useTheme,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import { convertNwSeToNeSw } from "google-map-react";

const Module_Editique = (props) => {

    const myconntected_employee_id = props.conntected_employee_id;

    const [selected_employee_id, setselected_employee_id] = useState(props.conntected_employee_id);

    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };



    const [expanded_nom_traite, setExpanded_nom_traite] = React.useState([]);
    const [selected_nom_traite, setSelected_nom_traite] = React.useState([]);

    const handleToggle_nom_traite = (event, nodeIds) => {
        setExpanded_nom_traite(nodeIds);
    };

    const handleSelect_nom_traite = (event, nodeIds) => {
        setSelected_nom_traite(nodeIds);
    };



    const [tab_handleExpandClick_id, settab_handleExpandClick_id] = React.useState([]);
    //settab_handleExpandClick_id(['0', '1', '2', '3', '4', '20'])

    const handleExpandClick = () => {
        setdisplay_audit_data("0");

        setExpanded((oldExpanded) =>
            oldExpanded.length === 0 ? ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] : [],
        );
    };


    const [Invoice_Session_Formation_api, setInvoice_Session_Formation_api] = useState();
    const [Invoice_Session_Formation_message, setInvoice_Session_Formation_message] = useState();
    const [Invoice_Session_Formation_result, setInvoice_Session_Formation_result] = useState([]);
    function Invoice_Session_Formation(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("tab_inscription_ids", "all");



        //var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Facture_From_Session_By_Email/";

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Invoice_Inscrption_With_Split_Session_By_Inscription_Id/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Invoice_Session_Formation  res.data.status = " + res.data.status);
                //console.log(" In Invoice_Session_Formation  res.data.message r_class = " + res.data.message);
                setInvoice_Session_Formation_api("true");
                setInvoice_Session_Formation_result(res.data.message);
                Getall_Editable_Document_By_Partner_By_Collection();
                props.GetListeInscrit(props.related_collection_recid, props.internal_url);
                alert(res.data.message);

            }
            else {
                setInvoice_Session_Formation_api("false");
                setInvoice_Session_Formation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Invoice_Session_Formation = ', error);
            setInvoice_Session_Formation_api("false");
            alert(" Impossible lancer la facturation de la session");
        })
    }





    const [Getall_Editable_Document_By_Partner_By_Collection_api, setGetall_Editable_Document_By_Partner_By_Collection_api] = useState();
    const [Getall_Editable_Document_By_Partner_By_Collection_message, setGetall_Editable_Document_By_Partner_By_Collection_message] = useState();
    const [Getall_Editable_Document_By_Partner_By_Collection_result, setGetall_Editable_Document_By_Partner_By_Collection_result] = useState([]);
    function Getall_Editable_Document_By_Partner_By_Collection(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", props.related_collection);
        form.append("related_collection_recid", props.related_collection_recid);
        form.append("session_id", props.related_collection_recid);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Editable_Document_By_Partner_By_Collection/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Editable_Document_By_Partner_By_Collection  res.data.status = " + res.data.status);
                //console.log(" In Getall_Editable_Document_By_Partner_By_Collection  res.data.message r_class = " + res.data.message);
                setGetall_Editable_Document_By_Partner_By_Collection_api("true");
                setGetall_Editable_Document_By_Partner_By_Collection_result(res.data.message);


            }
            else {
                setGetall_Editable_Document_By_Partner_By_Collection_api("false");
                setGetall_Editable_Document_By_Partner_By_Collection_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Editable_Document_By_Partner_By_Collection = ', error);
            setGetall_Editable_Document_By_Partner_By_Collection_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [partner_digital_signature_status, setpartner_digital_signature_status] = useState("0");
    const [local_partner_digital_signature_status, setlocal_partner_digital_signature_status] = useState(false);


    const [Getall_Partner_Digital_Sign_Status_api, setGetall_Partner_Digital_Sign_Status_api] = useState();
    const [Getall_Partner_Digital_Sign_Status_message, setGetall_Partner_Digital_Sign_Status_message] = useState();
    const [Getall_Partner_Digital_Sign_Status_result, setGetall_Partner_Digital_Sign_Status_result] = useState([]);
    function Getall_Partner_Digital_Sign_Status(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Is_Partnair_Has_Digital_Signature/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Digital_Sign_Status  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Digital_Sign_Status  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Digital_Sign_Status_api("true");
                setGetall_Partner_Digital_Sign_Status_result(res.data.message);


                if (JSON.parse(res.data.message).config_name && JSON.parse(res.data.message).config_value) {


                    if (String(JSON.parse(res.data.message).config_name) === "signature_digital" &&
                        String(JSON.parse(res.data.message).config_value) === "1") {

                        setpartner_digital_signature_status("1");
                        setlocal_partner_digital_signature_status(true);

                    }

                }



            }
            else {
                setGetall_Partner_Digital_Sign_Status_api("false");
                setGetall_Partner_Digital_Sign_Status_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Digital_Sign_Status = ', error);
            setGetall_Partner_Digital_Sign_Status_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Session_Document_Status_api, setGetall_Session_Document_Status_api] = useState();
    const [Getall_Session_Document_Status_message, setGetall_Session_Document_Status_message] = useState();
    const [Getall_Session_Document_Status_result, setGetall_Session_Document_Status_result] = useState([]);
    function Getall_Session_Document_Status(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Session_Action_Status_By_Document/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Session_Document_Status  res.data.status = " + res.data.status);
                console.log(" In Getall_Session_Document_Status  res.data.message r_class = " + res.data.message);
                setGetall_Session_Document_Status_api("true");
                setGetall_Session_Document_Status_result(res.data.message);



            }
            else {
                setGetall_Session_Document_Status_api("false");
                setGetall_Session_Document_Status_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Session_Document_Status = ', error);
            setGetall_Session_Document_Status_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [Action_Server_api, setAction_Server_api] = useState();
    const [Action_Server_message, setAction_Server_message] = useState();
    const [Action_Server_result, setAction_Server_result] = useState([]);
    function Action_Server(api_name) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", props.related_collection);
        form.append("related_collection_recid", props.related_collection_recid);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Action_Server_" + String(api_name) + "/";


        return;
        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Action_Server  res.data.status = " + res.data.status);
                //console.log(" In Action_Server  res.data.message r_class = " + res.data.message);
                setAction_Server_api("true");
                setAction_Server_result(res.data.message);


            }
            else {
                setAction_Server_api("false");
                setAction_Server_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Action_Server = ', error);
            setAction_Server_api("false");
            //setmyApimyApiMessage("")
        })
    }

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [is_session_invoiced, setis_session_invoiced] = useState("0");
    const [session_date_debut, setsession_date_debut] = useState(date_today_90j.toLocaleDateString('fr-FR'));


    const [Getall_Audit_Document_By_Partner_By_Collection_api, setGetall_Audit_Document_By_Partner_By_Collection_api] = useState();
    const [Getall_Audit_Document_By_Partner_By_Collection_message, setGetall_Audit_Document_By_Partner_By_Collection_message] = useState();
    const [Getall_Audit_Document_By_Partner_By_Collection_result, setGetall_Audit_Document_By_Partner_By_Collection_result] = useState([]);
    function Getall_Audit_Document_By_Partner_By_Collection(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Audit_Session_Action_Inscrit/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Editable_Document_By_Partner_By_Collection  res.data.status = " + res.data.status);
                //console.log(" In Getall_Editable_Document_By_Partner_By_Collection  res.data.message r_class = " + res.data.message);
                setGetall_Audit_Document_By_Partner_By_Collection_api("true");
                setGetall_Audit_Document_By_Partner_By_Collection_result(JSON.parse(res.data.message));



            }
            else {
                setGetall_Audit_Document_By_Partner_By_Collection_api("false");
                setGetall_Audit_Document_By_Partner_By_Collection_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Editable_Document_By_Partner_By_Collection = ', error);
            setGetall_Audit_Document_By_Partner_By_Collection_api("false");
            //setmyApimyApiMessage("")
        })
    }





    useEffect(() => {

        Getall_Partner_Digital_Sign_Status();
        setselected_session_id(props.related_collection_recid);
        Getall_Editable_Document_By_Partner_By_Collection();
        Getall_Audit_Document_By_Partner_By_Collection();
        Get_related_collection_Data();

    }, [])


    function Close_Dialog_Detail_Event_open() {
        setDialog_1_open(false);
    }




    const [tab_convention_pieces_jointes_result, settab_convention_pieces_jointes_result] = useState([]);

    const [Dialog_convention_message, setDialog_convention_message] = React.useState(false);
    const [Dialog_convention_open, setDialog_convention_open] = React.useState(false);
    function Dialog_convention_handle_change_participant_session(message) {
        setDialog_convention_message(message);
        setDialog_convention_open(true);
    }

    const Dialog_convention_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_convention_handleClose_buton = () => {

        setDialog_convention_open(false);
        setselected_convention_id();
        setselected_convention_type("");
        setselected_convention_email_destinataire_prod();
        setselected_convention_email_test();

        setactionmass_ftion_val();

        settab_convention_pieces_jointes_result([])

        setaction_server_to_run("");
        setaction_server_type_doc("");
        setaction_server_nom_doc("");
        setaction_server_ref_interne_doc("");
        setaction_server_type_doc_ref_interne("");


    };

    const [selected_session_id, setselected_session_id] = useState("");
    const [selected_internal_url, setselected_internal_url] = useState();

    const [local_nb_valide_inscription_entreprise, setlocal_nb_valide_inscription_entreprise] = useState("");
    const [local_nb_valide_inscription_individuelle, setlocal_nb_valide_inscription_individuelle] = useState();
    const [local_selected_session_invoiced_statut, setlocal_selected_session_invoiced_statut] = useState();



    const [selected_convention_id, setselected_convention_id] = useState("");
    const [selected_convention_type, setselected_convention_type] = useState("");
    const [selected_convention_email_destinataire_prod, setselected_convention_email_destinataire_prod] = useState("");
    const [selected_convention_email_test, setselected_convention_email_test] = useState("");

    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();

    function Download_Session_Conventions_PDF() {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Convention.zip";

        if (!selected_convention_id || String(selected_convention_id).trim() === "") {
            alert(" Vous devez choisir un modèle de document ");
            return;
        }

        var url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convention_From_Session_By_PDF/" + stored_cookie + "/" + selected_session_id + "/" + selected_convention_id;



        if (String(selected_convention_id) === "default_pdf") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convention_From_Session_By_PDF/" + stored_cookie + "/" + selected_session_id + "/" + selected_convention_id;

        }


        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_session_id = ", selected_session_id, " --- selected_convention_id =", selected_convention_id);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Download_Stagiaire_Conventions_PDF  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible de télécharger la convention (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd);
                    Getall_Editable_Document_By_Partner_By_Collection();

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de télécharger la convention ")

            });

    }


    function Download_Session_Convocation_PDF() {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Convocation.zip";

        if (String(selected_convention_id).trim() === "") {
            alert(" Vous devez choisir un modèle de document ");
            return;
        }
        var url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convocation_From_Session_By_PDF/" + stored_cookie + "/" + selected_session_id + "/" + selected_convention_id;



        if (String(selected_convention_id) === "default_pdf") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convocation_From_Session_By_PDF/" + stored_cookie + "/" + selected_session_id + "/" + selected_convention_id;

        }

        //console.log(" ### url = ", url);

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_session_id = ", selected_session_id, " --- selected_convention_id =", selected_convention_id);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Download_Stagiaire_Conventions_PDF  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible de télécharger la convocation (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd);
                    Getall_Editable_Document_By_Partner_By_Collection();

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de télécharger la convocation ")

            });

    }
    const [New_Get_List_Stagiaire_Conventions_result, setNew_Get_List_Stagiaire_Conventions_result] = useState([]);

    const [Get_List_Stagiaire_Conventions_api, setGet_List_Stagiaire_Conventions_api] = useState();
    const [Get_List_Stagiaire_Conventions_message, setGet_List_Stagiaire_Conventions_message] = useState();
    const [Get_List_Stagiaire_Conventions_result, setGet_List_Stagiaire_Conventions_result] = useState();
    function Get_List_Stagiaire_Conventions(ref_interne, nom, type_doc, courrier_template_type_document_ref_interne) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type_doc", type_doc);
        form.append("courrier_template_type_document_ref_interne", courrier_template_type_document_ref_interne);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Conventions_Stagiaire_With_Filter/";
        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Stagiaire_Conventions  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Stagiaire_Conventions  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_List_Stagiaire_Conventions_api("true");
                setGet_List_Stagiaire_Conventions_result(res.data.message);

                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Stagiaire_Conventions_result(new_data2);

            }
            else {
                setGet_List_Stagiaire_Conventions_api("false");
                setGet_List_Stagiaire_Conventions_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Stagiaire_Conventions = ', error);
            setGet_List_Stagiaire_Conventions_api("false");
            alert("Impossible de récupérer les modèles de convention");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Get_List_Stagiaire_Convocations_result, setNew_Get_List_Stagiaire_Convocations_result] = useState([]);

    const [Get_List_Stagiaire_Convocations_api, setGet_List_Stagiaire_Convocations_api] = useState();
    const [Get_List_Stagiaire_Convocations_message, setGet_List_Stagiaire_Convocations_message] = useState();
    const [Get_List_Stagiaire_Convocations_result, setGet_List_Stagiaire_Convocations_result] = useState();
    function Get_List_Stagiaire_Convocations(ref_interne, nom, type_doc, courrier_template_type_document_ref_interne) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type_doc", type_doc);
        form.append("courrier_template_type_document_ref_interne", courrier_template_type_document_ref_interne);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Convocations_Stagiaire_With_Filter/";
        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Stagiaire_Convocations  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Stagiaire_Convocations  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_List_Stagiaire_Convocations_api("true");
                setGet_List_Stagiaire_Convocations_result(res.data.message);

                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Stagiaire_Convocations_result(new_data2);

            }
            else {
                setGet_List_Stagiaire_Convocations_api("false");
                setGet_List_Stagiaire_Convocations_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Stagiaire_Convocations = ', error);
            setGet_List_Stagiaire_Convocations_api("false");
            alert("Impossible de récupérer les modèles de convocations");
            //setmyApimyApiMessage("")
        })
    }


    const [sessions_file_change_1_convention_api, setsessions_file_change_1_convention_api] = useState();
    const [sessions_file_change_1_convention_result, setsessions_file_change_1_convention_result] = useState();
    const [sessions_file_change_1_convention_message, setsessions_file_change_1_convention_message] = useState();
    const sessions_file_change_1_convention = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        if (file_type !== "application/pdf") {
            alert("Le fichier n'est pas de type PDF");
            return;
        }



        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


        var new_tmp = [];

        if (tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.length > 0) {

            tab_convention_pieces_jointes_result.map((x) => {
                new_tmp.push(x);

            });

        }

        new_tmp.push(event.target.files[0])

        settab_convention_pieces_jointes_result(new_tmp);

        //console.log(" tab_convention_pieces_jointes_result = ", tab_convention_pieces_jointes_result)
        return;
    };

    const [Send_Stagiaire_Conventions_By_Email_Test_api, setSend_Stagiaire_Conventions_By_Email_Test_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Test_message, setSend_Stagiaire_Conventions_By_Email_Test_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Test_result, setSend_Stagiaire_Conventions_By_Email_Test_result] = useState();
    function Send_Stagiaire_Conventions_By_Email_Test(event) {

        var form = new FormData();


        if (String(selected_convention_id).trim() === "") {
            alert(" Vous devez choisir un document");
            return;
        }

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", "");

        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convention_From_Session_By_Email/";

        if (String(selected_convention_id) === "default_mail") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convention_From_Session_By_Email/";
        }


        setLoading(true);
        fetch(
            myurl,
            {
                method: 'POST',
                body: form,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                setLoading(false);
                console.log(" In Send_Stagiaire_Conventions_By_Email_Test  res.data.status = " + result['status']);
                console.log(" In Send_Stagiaire_Conventions_By_Email_Test  res.data.message r_class = " + result['message']);
                if (String(result['status']) === String("true")) {

                    setSend_Stagiaire_Conventions_By_Email_Test_api("true");
                    setSend_Stagiaire_Conventions_By_Email_Test_result(result['message']);

                    alert(result['message']);
                    Dialog_convention_handleClose_buton()
                }
                else {
                    setSend_Stagiaire_Conventions_By_Email_Test_api("false");
                    setSend_Stagiaire_Conventions_By_Email_Test_message(result['message']);
                    alert(result['message']);
                }

            }).catch((error) => {
                setLoading(false);
                console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email_Test = ', error);
                setSend_Stagiaire_Conventions_By_Email_Test_api("false");
                alert("Impossible d'envoyer l'email de test ");
                //setmyApimyApiMessage("")
            })
    }



    /*
    Recuperation des autres données nécessaires à partir du : related_collection, related_collection_recid
    */
    function Get_related_collection_Data() {
        if (props.related_collection === "session_formation") {
            // Recuperation des données de la session
            GetCurrentSession(props.related_collection_recid);

        }
    }


    const [GetCurrentSession_api, setGetCurrentSession_api] = useState();
    const [GetCurrentSession_message, setGetCurrentSession_message] = useState();
    const [GetCurrentSession_result, setGetCurrentSession_result] = useState();
    function GetCurrentSession(local_session_id) {


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", local_session_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_SessionFormation_From_Id/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetCurrentSession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentSession  res.data.message r_class = " + res.data.message);
                //console.log(" In GetCurrentSession  res.data.message len = " + String(res.data.message).length);

                setLoading(false);
                setGetCurrentSession_api("true");
                if (String(res.data.message).length > 0) {
                    setGetCurrentSession_result(res.data.message);
                    var mylocaltraining = JSON.parse(res.data.message);

                    if (mylocaltraining.class_internal_url) {
                        setselected_internal_url(mylocaltraining.class_internal_url);
                    }

                    if (mylocaltraining.invoiced_statut) {
                        setis_session_invoiced(mylocaltraining.invoiced_statut);
                    }

                    if (mylocaltraining.date_debut) {
                        setsession_date_debut(mylocaltraining.date_debut);
                    }

                    if (mylocaltraining.nb_valide_inscription_entreprise) {
                        setlocal_nb_valide_inscription_entreprise(mylocaltraining.nb_valide_inscription_entreprise);
                    } else {
                        setlocal_nb_valide_inscription_entreprise("");
                    }

                    if (mylocaltraining.nb_valide_inscription_individuelle) {
                        setlocal_nb_valide_inscription_individuelle(mylocaltraining.nb_valide_inscription_individuelle);
                    } else {
                        setlocal_nb_valide_inscription_individuelle("");
                    }



                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetCurrentSession_api("false");
                setGetCurrentSession_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetCurrentSession = ', error);
            setGetCurrentSession_api("false");
            alert(" Impossible de récuperer les données de la session ");


        })
    }


    const [Get_Convention_List_Of_All_PJ_result, setGet_Convention_List_Of_All_PJ_result] = useState([]);

    function Delete_Convention_Attached_Doc(event) {

        var doc_to_del_name = event.target.id;
        const myArray = tab_convention_pieces_jointes_result;



        let new_myArray = myArray.filter(file => file.name !== String(doc_to_del_name));

        //console.log(" new_myArray = ", new_myArray);


        var new_tab = []
        for (let i = 0; i < new_myArray.length; i++) {
            new_tab.push(new_myArray[i]);
        }

        settab_convention_pieces_jointes_result(new_tab);


    }



    const [display_audit_data, setdisplay_audit_data] = useState("0");


    const [Send_Stagiaire_Conventions_By_Email_api, setSend_Stagiaire_Conventions_By_Email_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_message, setSend_Stagiaire_Conventions_By_Email_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_result, setSend_Stagiaire_Conventions_By_Email_result] = useState();
    function Send_Stagiaire_Conventions_By_Email(event) {

        var form = new FormData();

        if (String(selected_convention_id).trim() === "") {
            alert(" Vous devez choisir un document");
            return;
        }

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", selected_convention_email_destinataire_prod);

        if (local_partner_digital_signature_status === true) {
            form.append("request_digital_signature", "1");
        } else {
            form.append("request_digital_signature", "0");
        }



        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convention_From_Session_By_Email/";
        if (String(selected_convention_id) === "default_mail") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convention_From_Session_By_Email/";
        }

        //console.log(" ### myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Send_Stagiaire_Conventions_By_Email  res.data.status = " + res.data.status);
            //console.log(" In Send_Stagiaire_Conventions_By_Email  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setSend_Stagiaire_Conventions_By_Email_api("true");
                setSend_Stagiaire_Conventions_By_Email_result(res.data.message);

                Getall_Editable_Document_By_Partner_By_Collection();
                alert(res.data.message);
                Dialog_convention_handleClose_buton()
            }
            else {
                setSend_Stagiaire_Conventions_By_Email_api("false");
                setSend_Stagiaire_Conventions_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email = ', error);
            setSend_Stagiaire_Conventions_By_Email_api("false");
            alert("Impossible d'envoyer la convention par email");
            //setmyApimyApiMessage("")
        })
    }


    const [Send_Stagiaire_Convocations_By_Email_api, setSend_Stagiaire_Convocations_By_Email_api] = useState();
    const [Send_Stagiaire_Convocations_By_Email_message, setSend_Stagiaire_Convocations_By_Email_message] = useState();
    const [Send_Stagiaire_Convocations_By_Email_result, setSend_Stagiaire_Convocations_By_Email_result] = useState();
    function Send_Stagiaire_Convocations_By_Email(event) {

        var form = new FormData();

        if (String(selected_convention_id).trim() === "") {
            alert(" Vous devez choisir un document");
            return;
        }

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", selected_convention_email_destinataire_prod);


        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Convocation_From_Session_By_Email/";
        if (String(selected_convention_id) === "default_mail") {
            // Il s'agit d'envoyer les convention par defaut de type mail
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Default_Convocation_From_Session_By_Email/";
        }

        //console.log(" ### myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Send_Stagiaire_Convocations_By_Email  res.data.status = " + res.data.status);
            //console.log(" In Send_Stagiaire_Convocations_By_Email  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setSend_Stagiaire_Convocations_By_Email_api("true");
                setSend_Stagiaire_Convocations_By_Email_result(res.data.message);

                Getall_Editable_Document_By_Partner_By_Collection();
                alert(res.data.message);
                Dialog_convention_handleClose_buton()
            }
            else {
                setSend_Stagiaire_Convocations_By_Email_api("false");
                setSend_Stagiaire_Convocations_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Send_Stagiaire_Convocations_By_Email = ', error);
            setSend_Stagiaire_Convocations_By_Email_api("false");
            alert("Impossible d'envoyer la convocation par email");
            //setmyApimyApiMessage("")
        })
    }

    const [action_server_to_run, setaction_server_to_run] = useState("");
    const [action_server_type_doc, setaction_server_type_doc] = useState("");
    const [action_server_nom_doc, setaction_server_nom_doc] = useState("");
    const [action_server_ref_interne_doc, setaction_server_ref_interne_doc] = useState("");
    const [action_server_type_doc_ref_interne, setaction_server_type_doc_ref_interne] = useState("");

    const [Dialog_EMARGEMENT_FORMATION_open, setDialog_EMARGEMENT_FORMATION_open] = React.useState(false);
    const Dialog_EMARGEMENT_FORMATION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_EMARGEMENT_FORMATION_handleClose_buton = () => {
        setDialog_EMARGEMENT_FORMATION_open(false);
    };



    const [Dialog_ATTESTATION_FORMATION_open, setDialog_ATTESTATION_FORMATION_open] = React.useState(false);
    const Dialog_ATTESTATION_FORMATION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_ATTESTATION_FORMATION_handleClose_buton = () => {
        setDialog_ATTESTATION_FORMATION_open(false);
    };

    const [Dialog_CONF_INSCRIPTION_open, setDialog_CONF_INSCRIPTION_open] = React.useState(false);
    const Dialog_CONF_INSCRIPTION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_CONF_INSCRIPTION_handleClose_buton = () => {
        setDialog_CONF_INSCRIPTION_open(false);
    };

    const [Dialog_CONVOCATION_STAGIAIRE_open, setDialog_CONVOCATION_STAGIAIRE_open] = React.useState(false);
    const Dialog_CONVOCATION_STAGIAIRE_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_CONVOCATION_STAGIAIRE_handleClose_buton = () => {
        setDialog_CONVOCATION_STAGIAIRE_open(false);
    };


    const New_Option_Type_Document = [
        { "id": "email", "label": "Email", "value": "email" },
        { "id": "pdf", "label": "PDF", "value": "pdf" },
    ]


    const [Dialog_EVALUATION_FORMATION_open, setDialog_EVALUATION_FORMATION_open] = React.useState(false);
    const Dialog_EVALUATION_FORMATION_handleClose = () => {

    };

    const Dialog_EVALUATION_FORMATION_handleClose_buton = () => {
        setDialog_EVALUATION_FORMATION_open(false);
    };


    const [Dialog_Q_POSITIONNEMENT_open, setDialog_Q_POSITIONNEMENT_open] = React.useState(false);
    const Dialog_Q_POSITIONNEMENT_handleClose = () => {

    };

    const Dialog_Q_POSITIONNEMENT_handleClose_buton = () => {
        setDialog_Q_POSITIONNEMENT_open(false);
    };



    const [Dialog_FACTURATION_FORMATION_open, setDialog_FACTURATION_FORMATION_open] = React.useState(false);
    const Dialog_FACTURATION_FORMATION_handleClose = () => {

    };

    const Dialog_FACTURATION_FORMATION_handleClose_buton = () => {
        setDialog_FACTURATION_FORMATION_open(false);
    };



    const [MSG_INFO_TEXT, setMSG_INFO_TEXT] = React.useState(false);

    const [Dialog_MSG_INFO_open, setDialog_MSG_INFO_open] = React.useState(false);
    const Dialog_MSG_INFO_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_MSG_INFO_handleClose_buton = () => {
        setDialog_MSG_INFO_open(false);
    };


    function handleExpandClick_audit() {
        setdisplay_audit_data("1");


        setExpanded_nom_traite((oldExpanded_nom_traite) =>
            oldExpanded_nom_traite.length === 0 ? ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] : [],
        );
    }
    return (

        <div className="module_editique">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

                className="module_agenda"

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" >

                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Action a traiter </nav>

                    </div>

                    <div className="session_caract_Dialog" >  <b>Action a traiter </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={action_server_to_run}


                        />
                    </div>




                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={"Update_One_Agenda_Event"} className="bton_enreg_dialog">Mettre à jour</Button>

                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Event_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*  Dialog pour gerer les MSG_INFO */}
            <Dialog
                open={Dialog_MSG_INFO_open}
                onClose={Dialog_MSG_INFO_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "5rem" }}>
                    <DialogContentText>


                        <div style={{ "textAlign": "left" }}>
                            {MSG_INFO_TEXT}

                        </div>

                    </DialogContentText>


                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_MSG_INFO_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les MSG_INFO */}


            {/*  Dialog pour gerer les CONF_INSCRIPTION */}
            <Dialog
                open={Dialog_CONF_INSCRIPTION_open}
                onClose={Dialog_CONF_INSCRIPTION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "5rem" }}>
                    <DialogContentText>


                        <div style={{ "textAlign": "left" }}>
                            Cliquez sur l'onglet 'PREINSCRIPT.' pour confirmer les inscriptions.

                        </div>

                    </DialogContentText>


                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_CONF_INSCRIPTION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les CONF_INSCRIPTION */}


            {/*  Dialog pour gerer les EMARGEMENT_FORMATION */}
            <Dialog
                open={Dialog_EMARGEMENT_FORMATION_open}
                onClose={Dialog_EMARGEMENT_FORMATION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "10rem" }}>
                    <DialogContentText>
                        <div style={{ "textAlign": "left" }}>
                            Cliquez sur l'onglet 'EMARGEMENT' pour la gestion des émargements :<br />
                            <ul>
                                <li> Initialisation </li>
                                <li> Envoyer les demandes d'émargements </li>
                                <li> Mettre à jour les émargements </li>
                                <li> etc </li>
                            </ul>
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_EMARGEMENT_FORMATION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les EMARGEMENT_FORMATION */}



            {/*  Dialog pour gerer les ATTESTATION_FORMATION */}
            <Dialog
                open={Dialog_ATTESTATION_FORMATION_open}
                onClose={Dialog_ATTESTATION_FORMATION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "10rem" }}>
                    <DialogContentText>
                        <div style={{ "textAlign": "left" }}>
                            Cliquez sur l'onglet 'ATTESTATION' pour la gestion des attestations de formation :<br />
                            <ul>
                                <li> Initialisation </li>
                                <li> Envoyer par email </li>
                                <li> etc </li>
                            </ul>
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_ATTESTATION_FORMATION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les ATTESTATION_FORMATION */}



            {/*  Dialog pour gerer les EVALUATION_FORMATION */}
            <Dialog
                open={Dialog_EVALUATION_FORMATION_open}
                onClose={Dialog_EVALUATION_FORMATION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "10rem" }}>
                    <DialogContentText>
                        <div style={{ "textAlign": "left" }}>
                            Cliquez sur l'onglet 'LES AVIS' pour la gestion des évaluations :<br />
                            <ul>

                                <li> Envoyer les demandes d'évaluation </li>
                                <li> Visualiser et exporter les évaluations </li>
                                <li> etc </li>
                            </ul>
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_EVALUATION_FORMATION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/* FIN Dialog pour gerer les EVALUATION_FORMATION */}


            {/*  Dialog pour gerer les CONVOCATION_STAGIAIRE */}
            <Dialog
                open={Dialog_CONVOCATION_STAGIAIRE_open}
                onClose={Dialog_CONVOCATION_STAGIAIRE_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Type Document<br />

                        <Autocomplete
                            disablePortal
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"
                            fullWidth
                            options={New_Option_Type_Document}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setselected_convention_type(value.value);
                                    Get_List_Stagiaire_Convocations(action_server_ref_interne_doc, action_server_nom_doc,
                                        String(value.value), Dialog_1_message);

                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>

                    {(String(selected_convention_type) === "email" || String(selected_convention_type) === "pdf") && <div className="session_caract_Dialog" > Choisir une convention
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_convention"
                            id="event_dialog_version_convention"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Get_List_Stagiaire_Convocations_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_convention_id(value._id);

                                    if (String(value._id) === "default_mail") {
                                        setselected_convention_type("email");
                                    }
                                    else if (String(value._id) === "default_pdf") {
                                        setselected_convention_type("pdf");
                                    } else {
                                        var result2 = Get_List_Stagiaire_Convocations_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id === String(value._id))
                                        setselected_convention_type(JSON.parse(result2).type_doc);
                                    }
                                    settab_convention_pieces_jointes_result([]);
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option" />
                            }
                        />

                    </div>}

                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" > Type Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '75%' }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"

                            value="email"

                        />


                        {String(selected_convention_type) === "email" && <label className="bton_image_class" style={{ "width": "20%", "float": "right", "height": "3.5rem", "paddingTop": "15px" }} onClick={(e) => {

                            if (selected_convention_email_test) {
                                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (!selected_convention_email_test.match(validRegex)) {
                                    alert("L'adresse email de test est invalide.");
                                    return;
                                }
                                Send_Stagiaire_Conventions_By_Email_Test();
                            } else {
                                alert("L'adresse email de test est invalide.");
                            }



                        }} > <FcSearch /> &nbsp; Test</label>}

                    </div>}

                    {String(selected_convention_type) === "pdf" && <div className="session_caract_Dialog" > Type Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"

                            value="pdf"
                            fullWidth
                        />



                    </div>}


                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" >
                        <Tooltip className="tooltip_css" id="test01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="test01" data-tooltip-html="Pour recevoir une email de test avant de l'envoyer">
                            Adresse email de test <FcInfo /> <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_test_convention"
                                id="event_dialog_email_test_convention"

                                fullWidth

                                value={selected_convention_email_test}
                                onChange={(e) => {
                                    setselected_convention_email_test(e.target.value);
                                }
                                }
                            />
                        </a>

                    </div>
                    }

                    {/*String(selected_convention_type) === "email" && <div className="session_caract_Dialog" > Recevoir un pré-envoie
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_email_test_convention"
                            id="event_dialog_email_test_convention"
                            fullWidth
                            value=""

                        />

                    </div>
                    */}

                    {String(selected_convention_type) === "email" &&
                        String(actionmass_ftion_val) !== String("convention") && <div className="session_caract_Dialog" > Email destinaitaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_production_convention"
                                id="event_dialog_email_production_convention"

                                fullWidth

                                value={selected_convention_email_destinataire_prod}
                                onChange={(e) => {
                                    setselected_convention_email_destinataire_prod(e.target.value);
                                }
                                }
                            />

                        </div>
                    }



                    {String(selected_convention_type) === "email" && <div>

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes Convention

                            </div>

                            <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload_convention_file">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload_convention_file"
                                            name="upload_convention_file"
                                            type="file"
                                            onChange={sessions_file_change_1_convention}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>


                            </div>


                            {tab_convention_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    {<div className="div_row">
                                        {tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj_convention"  >
                                                <nav style={{ "color": "orange", "cursor": "pointer" }}
                                                    onClick={(e) => {
                                                        Delete_Convention_Attached_Doc(e);
                                                    }}
                                                    name={val.name} id={val.name}> Supprimer  {val.name} </nav> <br />


                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>}



                        </div>
                    </div>}



                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            {String(selected_convention_type) === "email" && <Button onClick={(e) => {

                                if (String(selected_convention_email_test).length > 2) {
                                    // On alerte juste l'utilisateur que l'email de test n'est pas vide, juste au cas ou il aurait cliqué par erreur sur l'envoie.
                                    const response = window.confirm(" Vous allez envoyer la convention au destinaitaire final. Continuer ?");
                                    if (!response) {
                                        return;
                                    }
                                }

                                Send_Stagiaire_Convocations_By_Email();
                                Dialog_CONVOCATION_STAGIAIRE_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Envoyer mail </Button>}


                            {String(selected_convention_type) === "pdf" &&
                                <Button className="bton_enreg_dialog" onClick={(e) => {
                                    Download_Session_Convocation_PDF();
                                    Dialog_CONVOCATION_STAGIAIRE_handleClose_buton();
                                }}
                                >Télécharger </Button>}

                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_CONVOCATION_STAGIAIRE_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les CONVOCATION_STAGIAIRE */}


            {/*  Dialog pour gerer les CONVENTIONS */}
            <Dialog
                open={Dialog_convention_open}
                onClose={Dialog_convention_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>



                    <div className="session_caract_Dialog" > Type Document<br />
                        <Autocomplete
                            disablePortal
                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            fullWidth
                            options={New_Option_Type_Document}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setselected_convention_type(value.value);

                                    Get_List_Stagiaire_Conventions(action_server_ref_interne_doc, action_server_nom_doc,
                                        String(value.value), Dialog_1_message);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    {String(partner_digital_signature_status) === "1" && String(selected_convention_type) === "email" &&
                        String(Dialog_1_message) === "CONVENTION_STAGIAIRE_ENTREPRISE" && <div className="session_caract_Dialog" >
                            <div className="session_caract_Dialog" >
                                <Tooltip className="tooltip_css" id="tooltip_signat_dig" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="tooltip_signat_dig" data-tooltip-html="Utiliser la fonctionnalité de signature électronique pour ce document ">
                                    <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                                        onChange={(e) => {

                                            if (local_partner_digital_signature_status === true)
                                                setlocal_partner_digital_signature_status(false)
                                            else {

                                                setlocal_partner_digital_signature_status(true);
                                            }

                                        }}
                                        id="toggleSwitchNav_1" name="toggleSwitchNav_1">
                                        <ToggleSwitch
                                            label="Signature Digiale" id="toggleSwitch_1" name="toggleSwitch_1" checked={local_partner_digital_signature_status} style={{ height: "2rem" }} />
                                    </nav>
                                </a>


                            </div>

                        </div>}

                    {String(partner_digital_signature_status) !== "1" && String(selected_convention_type) === "email" && String(Dialog_1_message) === "CONVENTION_STAGIAIRE_ENTREPRISE" &&
                        <div className="session_caract_Dialog" > Signature Digiale <br />

                            <div className="session_caract_Dialog" >
                                <Tooltip className="tooltip_css" id="tooltip_signat_dig" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="tooltip_signat_dig" data-tooltip-html="Nous ne disposez pas de l'option de signature électronique ">
                                    <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}

                                        id="toggleSwitchNav_1" name="toggleSwitchNav_1">
                                        <ToggleSwitch
                                            label="Activier la signature digiatale" id="toggleSwitch_1" name="toggleSwitch_1" checked={false} style={{ height: "2rem" }} />
                                    </nav>
                                </a>


                            </div>

                        </div>}

                    {(String(selected_convention_type) === "email" || String(selected_convention_type) === "pdf") && <div className="session_caract_Dialog" > Choisir une convention
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_convention"
                            id="event_dialog_version_convention"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Get_List_Stagiaire_Conventions_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_convention_id(value._id);

                                    if (String(value._id) === "default_mail") {
                                        setselected_convention_type("email");
                                    }
                                    else if (String(value._id) === "default_pdf") {
                                        setselected_convention_type("pdf");
                                    } else {
                                        var result2 = Get_List_Stagiaire_Conventions_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id === String(value._id))
                                        setselected_convention_type(JSON.parse(result2).type_doc);
                                    }
                                    settab_convention_pieces_jointes_result([]);
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>}



                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" >
                        <Tooltip className="tooltip_css" id="test01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="test01" data-tooltip-html="Pour recevoir une email de test avant de l'envoyer">
                            Adresse email de test <FcInfo /> <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '75%' }}

                                name="event_dialog_email_test_convention"
                                id="event_dialog_email_test_convention"

                                fullWidth

                                value={selected_convention_email_test}
                                onChange={(e) => {
                                    setselected_convention_email_test(e.target.value);
                                }
                                }
                            />
                        </a>

                        {String(selected_convention_type) === "email" && <label className="bton_image_class" style={{ "width": "20%", "float": "right", "height": "3rem", "paddingTop": "15px" }} onClick={(e) => {

                            if (selected_convention_email_test) {
                                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (!selected_convention_email_test.match(validRegex)) {
                                    alert("L'adresse email de test est invalide.");
                                    return;
                                }
                                Send_Stagiaire_Conventions_By_Email_Test();
                            } else {
                                alert("L'adresse email de test est invalide.");
                            }



                        }} > <FcSearch /> &nbsp; Test</label>}
                    </div>
                    }



                    {String(selected_convention_type) === "email" &&
                        String(actionmass_ftion_val) !== String("convention") && <div className="session_caract_Dialog" > Email destinaitaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_production_convention"
                                id="event_dialog_email_production_convention"

                                fullWidth

                                value={selected_convention_email_destinataire_prod}
                                onChange={(e) => {
                                    setselected_convention_email_destinataire_prod(e.target.value);
                                }
                                }
                            />

                        </div>
                    }



                    {String(selected_convention_type) === "email" && <div>

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes Convention

                            </div>

                            <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload_convention_file">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload_convention_file"
                                            name="upload_convention_file"
                                            type="file"
                                            onChange={sessions_file_change_1_convention}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>


                            </div>


                            {tab_convention_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    {<div className="div_row">
                                        {tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj_convention"  >
                                                <nav style={{ "color": "orange", "cursor": "pointer" }}
                                                    onClick={(e) => {
                                                        Delete_Convention_Attached_Doc(e);
                                                    }}
                                                    name={val.name} id={val.name}> Supprimer  {val.name} </nav> <br />


                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>}



                        </div>
                    </div>}

                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            {String(selected_convention_type) === "email" && <Button onClick={(e) => {

                                if (String(selected_convention_email_test).length > 2) {
                                    // On alerte juste l'utilisateur que l'email de test n'est pas vide, juste au cas ou il aurait cliqué par erreur sur l'envoie.
                                    const response = window.confirm(" Vous allez envoyer la convention au destinaitaire final. Continuer ?");
                                    if (!response) {
                                        return;
                                    }
                                }

                                Dialog_convention_handleClose_buton();
                                Send_Stagiaire_Conventions_By_Email();

                            }}
                                className="bton_enreg_dialog">Envoyer mail </Button>}


                            {String(selected_convention_type) === "pdf" &&
                                <Button className="bton_enreg_dialog" onClick={(e) => {

                                    Dialog_convention_handleClose_buton();
                                    Download_Session_Conventions_PDF();
                                }}
                                >Télécharger </Button>}
                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_convention_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/*  FIN Dialog pour gerer les CONVENTIONS */}


            {/*  Dialog pour gerer les Q_POSITIONNEMENT */}
            <Dialog
                open={Dialog_Q_POSITIONNEMENT_open}
                onClose={Dialog_Q_POSITIONNEMENT_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "10rem" }}>
                    <DialogContentText>
                        <div style={{ "textAlign": "left" }}>
                            Cliquez sur l'onglet 'Q. POSITION' pour la gestion des questions de positionnement :<br />
                            <ul>

                                <li> Choisir et envoyer les questionnaires de positionnement </li>
                                <li> Visualiser et exporter les resultats </li>
                                <li> etc </li>
                            </ul>
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_Q_POSITIONNEMENT_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/* FIN Dialog pour gerer les Q_POSITIONNEMENT */}


            {/*  Dialog pour gerer les FACTURATION_FORMATION */}
            <Dialog
                open={Dialog_FACTURATION_FORMATION_open}
                onClose={Dialog_FACTURATION_FORMATION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "10rem" }}>
                    <DialogContentText>
                        <div style={{ "textAlign": "left" }}>
                            En confirmation cette action, toute la session de formation sera facturée.

                            Verifier les informations

                            <ul>

                                <li> Prix de session </li>
                                <li> Données client </li>
                                <li> etc </li>
                            </ul>
                            avant de lancer la facturation.
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={(e) => {

                                if (String(selected_session_id).length > 2) {
                                    // On alerte juste l'utilisateur que l'email de test n'est pas vide, juste au cas ou il aurait cliqué par erreur sur l'envoie.
                                    const response = window.confirm(" Continuez la facturation?");
                                    if (!response) {
                                        return;
                                    }
                                }

                                Dialog_FACTURATION_FORMATION_handleClose_buton();
                                Invoice_Session_Formation();


                            }}
                                className="bton_enreg_dialog">Facturer </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_FACTURATION_FORMATION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/* FIN Dialog pour gerer les FACTURATION_FORMATION */}

            <div className="div_row" style={{ "background": "#e8e6e6", "marginLeft": "5px", "marginRight": "5px" }}>

                <div style={{ "width": "58%", "float": "left", "marginLeft": "5px", "marginRight": "5px", }}>
                    <br />
                    <div style={{ "width": "100%", "float": "left" }}>
                        {Getall_Editable_Document_By_Partner_By_Collection_result &&
                            Getall_Editable_Document_By_Partner_By_Collection_result.map((document) => (

                                <div>
                                    {String(props.selected_session_invoiced_statut) !== "1" && String(props.selected_session_invoiced_statut) !== "2" &&
                                        String(JSON.parse(document).courrier_template_ref_interne) !== "CONVENTION_STAGIAIRE_INDIVIDUELLE"
                                        && String(props.nb_valide_inscription_individuelle) === "0" && String(props.nb_valide_inscription_entreprise) !== "0" && <div className="div_row" style={{ "height": "3rem", "marginLeft": "5px", "marginRight": "5px" }}>


                                            <div className="bton_action_server" style={{ width: "45%", float: "left", height: "2.5rem", paddingTop: "10px" }}
                                                onClick={(e) => {

                                                    setaction_server_to_run(String(JSON.parse(document).server_action_name))
                                                    //setaction_server_type_doc(String(JSON.parse(document).courrier_template_type_doc))
                                                    setaction_server_nom_doc(String(JSON.parse(document).courrier_template_nom))
                                                    setaction_server_ref_interne_doc(String(JSON.parse(document).courrier_template_ref_interne))
                                                    Action_Server(String(JSON.parse(document).server_action_name));
                                                    setaction_server_type_doc_ref_interne(JSON.parse(document).courrier_template_ref_interne);
                                                    setDialog_1_message(String(JSON.parse(document).courrier_template_ref_interne))


                                                    if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVENTION_STAGIAIRE_ENTREPRISE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }

                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {
                                                            Get_List_Stagiaire_Conventions(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );
                                                            setDialog_convention_open(true);

                                                        }
                                                    }
                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EMARGEMENT_FORMATION") {
                                                        // setDialog_EMARGEMENT_FORMATION_open(true);
                                                        props.submenu_emargement();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "ATTESTATION_FORMATION") {
                                                        //setDialog_ATTESTATION_FORMATION_open(true);
                                                        props.submenu_attestation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVOCATION_STAGIAIRE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }


                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);


                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {

                                                            Get_List_Stagiaire_Convocations(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );

                                                            setDialog_CONVOCATION_STAGIAIRE_open(true);
                                                        }
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONF_INSCRIPTION") {
                                                        setDialog_CONF_INSCRIPTION_open(true);
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EVAL_FORMATION") {
                                                        //setDialog_EVALUATION_FORMATION_open(true);
                                                        props.submenu_evaluation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "QUESTION_POSITIONNEMENT") {
                                                        //setDialog_Q_POSITIONNEMENT_open(true);
                                                        props.submenu_q_positionnement();
                                                    }


                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "FACTURATION_SESSION") {

                                                        setDialog_FACTURATION_FORMATION_open(true);
                                                    }



                                                }}>
                                                {(JSON.parse(document).courrier_template_nom)}
                                            </div>



                                            <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                                <ToggleSwitch_v3_mysy id="toggleSwitch" name="toggleSwitch" checked={false} mysy_type={(JSON.parse(document).statut_completude)} style={{ height: "1.5rem" }} />

                                            </div>

                                        </div>}

                                    {String(props.selected_session_invoiced_statut) !== "1" && String(props.selected_session_invoiced_statut) !== "2" &&
                                        String(JSON.parse(document).courrier_template_ref_interne) !== "CONVENTION_STAGIAIRE_ENTREPRISE"
                                        && String(props.nb_valide_inscription_entreprise) === "0" && String(props.nb_valide_inscription_individuelle) !== "0" && <div className="div_row" style={{ "height": "3rem", "marginLeft": "5px", "marginRight": "5px" }}>


                                            <div className="bton_action_server" style={{ width: "45%", float: "left", height: "2.5rem", paddingTop: "10px" }}
                                                onClick={(e) => {

                                                    setaction_server_to_run(String(JSON.parse(document).server_action_name))
                                                    //setaction_server_type_doc(String(JSON.parse(document).courrier_template_type_doc))
                                                    setaction_server_nom_doc(String(JSON.parse(document).courrier_template_nom))
                                                    setaction_server_ref_interne_doc(String(JSON.parse(document).courrier_template_ref_interne))
                                                    Action_Server(String(JSON.parse(document).server_action_name));
                                                    setaction_server_type_doc_ref_interne(JSON.parse(document).courrier_template_ref_interne);
                                                    setDialog_1_message(String(JSON.parse(document).courrier_template_ref_interne))


                                                    if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVENTION_STAGIAIRE_INDIVIDUELLE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }

                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {
                                                            Get_List_Stagiaire_Conventions(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );
                                                            setDialog_convention_open(true);

                                                        }
                                                    }
                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EMARGEMENT_FORMATION") {
                                                        // setDialog_EMARGEMENT_FORMATION_open(true);
                                                        props.submenu_emargement();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "ATTESTATION_FORMATION") {
                                                        //setDialog_ATTESTATION_FORMATION_open(true);
                                                        props.submenu_attestation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVOCATION_STAGIAIRE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }


                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);


                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {

                                                            Get_List_Stagiaire_Convocations(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );

                                                            setDialog_CONVOCATION_STAGIAIRE_open(true);
                                                        }
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONF_INSCRIPTION") {
                                                        setDialog_CONF_INSCRIPTION_open(true);
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EVAL_FORMATION") {
                                                        //setDialog_EVALUATION_FORMATION_open(true);
                                                        props.submenu_evaluation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "QUESTION_POSITIONNEMENT") {
                                                        //setDialog_Q_POSITIONNEMENT_open(true);
                                                        props.submenu_q_positionnement();
                                                    }


                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "FACTURATION_SESSION") {

                                                        setDialog_FACTURATION_FORMATION_open(true);
                                                    }



                                                }}>
                                                {(JSON.parse(document).courrier_template_nom)}
                                            </div>




                                            <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                                <ToggleSwitch_v3_mysy id="toggleSwitch" name="toggleSwitch" checked={false} mysy_type={(JSON.parse(document).statut_completude)} style={{ height: "1.5rem" }} />

                                            </div>

                                        </div>}

                                    {String(props.selected_session_invoiced_statut) !== "1" && String(props.selected_session_invoiced_statut) !== "2" &&
                                        String(props.nb_valide_inscription_entreprise) !== "0" && String(props.nb_valide_inscription_individuelle) !== "0" && <div className="div_row" style={{ "height": "3rem", "marginLeft": "5px", "marginRight": "5px" }}>


                                            <div className="bton_action_server" style={{ width: "45%", float: "left", height: "2.5rem", paddingTop: "10px" }}
                                                onClick={(e) => {

                                                    setaction_server_to_run(String(JSON.parse(document).server_action_name))
                                                    //setaction_server_type_doc(String(JSON.parse(document).courrier_template_type_doc))
                                                    setaction_server_nom_doc(String(JSON.parse(document).courrier_template_nom))
                                                    setaction_server_ref_interne_doc(String(JSON.parse(document).courrier_template_ref_interne))
                                                    Action_Server(String(JSON.parse(document).server_action_name));
                                                    setaction_server_type_doc_ref_interne(JSON.parse(document).courrier_template_ref_interne);
                                                    setDialog_1_message(String(JSON.parse(document).courrier_template_ref_interne))


                                                    if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVENTION_STAGIAIRE_ENTREPRISE" ||
                                                        String(JSON.parse(document).courrier_template_ref_interne) === "CONVENTION_STAGIAIRE_INDIVIDUELLE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }

                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {
                                                            Get_List_Stagiaire_Conventions(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );
                                                            setDialog_convention_open(true);

                                                        }
                                                    }
                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EMARGEMENT_FORMATION") {
                                                        // setDialog_EMARGEMENT_FORMATION_open(true);
                                                        props.submenu_emargement();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "ATTESTATION_FORMATION") {
                                                        //setDialog_ATTESTATION_FORMATION_open(true);
                                                        props.submenu_attestation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVOCATION_STAGIAIRE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }


                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);


                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {

                                                            Get_List_Stagiaire_Convocations(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );

                                                            setDialog_CONVOCATION_STAGIAIRE_open(true);
                                                        }
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONF_INSCRIPTION") {
                                                        setDialog_CONF_INSCRIPTION_open(true);
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EVAL_FORMATION") {
                                                        //setDialog_EVALUATION_FORMATION_open(true);
                                                        props.submenu_evaluation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "QUESTION_POSITIONNEMENT") {
                                                        //setDialog_Q_POSITIONNEMENT_open(true);
                                                        props.submenu_q_positionnement();
                                                    }


                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "FACTURATION_SESSION") {

                                                        setDialog_FACTURATION_FORMATION_open(true);
                                                    }



                                                }}>
                                                {(JSON.parse(document).courrier_template_nom)}
                                            </div>

                                            {/*String(JSON.parse(document).has_history_event) === "1" &&
                                        <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                            <ToggleSwitch_v2_mysy id="toggleSwitch" name="toggleSwitch" checked={true} style={{ height: "1.5rem" }} />

                                        </div>}

                                    {String(JSON.parse(document).has_history_event) !== "1" &&
                                        <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                            <ToggleSwitch_v2_mysy id="toggleSwitch" name="toggleSwitch" checked={false} style={{ height: "1.5rem" }} />

                                </div>*/}


                                            <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                                <ToggleSwitch_v3_mysy id="toggleSwitch" name="toggleSwitch" checked={false} mysy_type={(JSON.parse(document).statut_completude)} style={{ height: "1.5rem" }} />

                                            </div>

                                        </div>}


                                    {String(props.selected_session_invoiced_statut) !== "1" && String(props.selected_session_invoiced_statut) !== "2" &&
                                        String(local_nb_valide_inscription_entreprise) === "0" && String(local_nb_valide_inscription_individuelle) === "0" && <div className="div_row" style={{ "height": "3rem", "marginLeft": "5px", "marginRight": "5px" }}>


                                            <div className="bton_action_server" style={{ width: "45%", float: "left", height: "2.5rem", paddingTop: "10px" }}
                                                onClick={(e) => {

                                                    setMSG_INFO_TEXT("Aucune inscription validée.")
                                                    setDialog_MSG_INFO_open(true);
                                                    return;

                                                }}>
                                                {(JSON.parse(document).courrier_template_nom)}
                                            </div>




                                            <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                                <ToggleSwitch_v3_mysy id="toggleSwitch" name="toggleSwitch" checked={false} mysy_type={(JSON.parse(document).statut_completude)} style={{ height: "1.5rem" }} />

                                            </div>

                                        </div>}

                                    {/*** Cas d'une sesson facturée */}
                                    {(String(props.selected_session_invoiced_statut) === "1" || String(props.selected_session_invoiced_statut) === "2") &&
                                        <div className="div_row" style={{ "height": "3rem", "marginLeft": "5px", "marginRight": "5px" }}>


                                            <div className="bton_action_server" style={{ width: "45%", float: "left", height: "2.5rem", paddingTop: "10px" }}
                                                onClick={(e) => {

                                                    setaction_server_to_run(String(JSON.parse(document).server_action_name))
                                                    //setaction_server_type_doc(String(JSON.parse(document).courrier_template_type_doc))
                                                    setaction_server_nom_doc(String(JSON.parse(document).courrier_template_nom))
                                                    setaction_server_ref_interne_doc(String(JSON.parse(document).courrier_template_ref_interne))
                                                    Action_Server(String(JSON.parse(document).server_action_name));
                                                    setaction_server_type_doc_ref_interne(JSON.parse(document).courrier_template_ref_interne);
                                                    setDialog_1_message(String(JSON.parse(document).courrier_template_ref_interne))


                                                    if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVENTION_STAGIAIRE_ENTREPRISE" ||
                                                        String(JSON.parse(document).courrier_template_ref_interne) === "CONVENTION_STAGIAIRE_INDIVIDUELLE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }

                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convention")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {
                                                            Get_List_Stagiaire_Conventions(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );
                                                            setDialog_convention_open(true);

                                                        }
                                                    }
                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EMARGEMENT_FORMATION") {
                                                        // setDialog_EMARGEMENT_FORMATION_open(true);
                                                        props.submenu_emargement();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "ATTESTATION_FORMATION") {
                                                        //setDialog_ATTESTATION_FORMATION_open(true);
                                                        props.submenu_attestation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONVOCATION_STAGIAIRE") {

                                                        // Verifier que la date de la session n'est pas passée
                                                        var date_eval = new Date(moment(session_date_debut, "DD/MM/YYYY"));
                                                        var diff = date_eval.getTime() - Date.now();

                                                        if (diff <= 0) {
                                                            setMSG_INFO_TEXT("Cette session a déjà commencé. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);
                                                            return;
                                                        }


                                                        if (String(is_session_invoiced) === "1") {
                                                            setMSG_INFO_TEXT("Cette session est en cours de facturation. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);


                                                        }
                                                        else if (String(is_session_invoiced) === "2") {
                                                            setMSG_INFO_TEXT("Cette session a déjà été facturée. Il n'est plus possible d'éditer une convocation")
                                                            setDialog_MSG_INFO_open(true);

                                                        } else {

                                                            Get_List_Stagiaire_Convocations(String(JSON.parse(document).courrier_template_ref_interne), String(JSON.parse(document).courrier_template_nom), String(JSON.parse(document).courrier_template_type_doc)
                                                            );

                                                            setDialog_CONVOCATION_STAGIAIRE_open(true);
                                                        }
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "CONF_INSCRIPTION") {
                                                        setDialog_CONF_INSCRIPTION_open(true);
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "EVAL_FORMATION") {
                                                        //setDialog_EVALUATION_FORMATION_open(true);
                                                        props.submenu_evaluation();
                                                    }

                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "QUESTION_POSITIONNEMENT") {
                                                        //setDialog_Q_POSITIONNEMENT_open(true);
                                                        props.submenu_q_positionnement();
                                                    }


                                                    else if (String(JSON.parse(document).courrier_template_ref_interne) === "FACTURATION_SESSION") {

                                                        setDialog_FACTURATION_FORMATION_open(true);
                                                    }



                                                }}>
                                                {(JSON.parse(document).courrier_template_nom)}
                                            </div>




                                            <div style={{ display: "inline-block", width: "15%", textAlign: "left", paddingLeft: "0.5rem", "float": "left", height: "2.5rem", }}>
                                                <ToggleSwitch_v3_mysy id="toggleSwitch" name="toggleSwitch" checked={false} mysy_type={(JSON.parse(document).statut_completude)} style={{ height: "1.5rem" }} />

                                            </div>

                                        </div>}

                                </div>


                            ))}
                    </div>



                </div>


                <div style={{ "width": "40%", "float": "right", "padding": "10px", "borderLeft": "3mm ridge rgba(211, 220, 50, .6)" }}>

                    <nav style={{ "width": "100%", "float": 'left' }}>
                        <nav style={{ float: "left", "width": '45%' }}> Traité  <Button onClick={handleExpandClick}>
                            {expanded.length === 0 ? 'Voir tout' : 'Réduire'}
                        </Button>
                        </nav>

                        <nav style={{ float: "right", "width": '45%' }}> Non Traité
                            <Button onClick={handleExpandClick_audit}>
                                {expanded_nom_traite.length === 0 ? 'Voir tout' : 'Réduire'}
                            </Button>
                        </nav>
                    </nav>

                    {String(display_audit_data) == "0" && <Box sx={{
                        minHeight: 270, flexGrow: 1,
                        boxShadow: 1,
                        borderRadius: 2,
                        bgcolor: "#DAF7A6",
                    }}>


                        {Getall_Editable_Document_By_Partner_By_Collection_result &&
                            Getall_Editable_Document_By_Partner_By_Collection_result.map((document) => (


                                <TreeView
                                    aria-label="controlled"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    expanded={expanded}
                                    selected={selected}
                                    onNodeToggle={handleToggle}
                                    onNodeSelect={handleSelect}
                                    multiSelect
                                    className="test03"
                                >
                                    <TreeItem nodeId={(JSON.parse(document).id)} label={(JSON.parse(document).courrier_template_ref_interne)} className="test01"
                                    >

                                        {JSON.parse(document).list_document_history_event &&
                                            JSON.parse(document).list_document_history_event.map((history_doc) => (
                                                <nav>
                                                    {String(history_doc.local_target_collection_name).trim() === "" &&
                                                        <TreeItem className="test02" nodeId={history_doc._id}
                                                            sx={{ color: 'darkslateblue', fontWeight: 'light', fontStyle: 'italic', mt: '10px' }}
                                                            label={(String(history_doc.date_update).substring(0, 16) + "  Par " + String(history_doc.local_update_by_email))}
                                                            icon={<FcInfo />}
                                                        />}

                                                    {String(history_doc.local_target_collection_name).trim() !== "" &&
                                                        <TreeItem className="test02" nodeId={history_doc._id}
                                                            label={(String(history_doc.date_update).substring(0, 16) + "  Par " + String(history_doc.local_update_by_email) + ". " + String(history_doc.local_target_collection) + " Pour " + String(history_doc.local_target_collection_name))}
                                                            sx={{ color: 'darkslateblue', fontWeight: 'light', fontStyle: 'italic', mt: '10px' }}
                                                            icon={<FcInfo />} />
                                                    }
                                                </nav>

                                            ))}


                                        {/*!JSON.parse(document).list_document_history_event || (JSON.parse(document).list_document_history_event).length <= 0 &&
                                            <TreeItem nodeId={"0"} label="Aucun"
                                                className="test02" />*/}




                                    </TreeItem>

                                </TreeView>
                            ))}
                    </Box>}

                    {String(display_audit_data) == "1" && <Box sx={{
                        minHeight: 270, flexGrow: 1,
                        boxShadow: 1,
                        borderRadius: 2,
                        bgcolor: "#fee347",
                        textAlign: "right"
                    }}>


                        {Getall_Audit_Document_By_Partner_By_Collection_result &&
                            Getall_Audit_Document_By_Partner_By_Collection_result.map((document) => (


                                <TreeView
                                    aria-label="controlled"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    expanded_nom_traite={expanded_nom_traite}
                                    selected={selected_nom_traite}
                                    onNodeToggle={handleToggle_nom_traite}
                                    onNodeSelect={handleSelect_nom_traite}
                                    multiSelect
                                    className="test04"
                                >
                                    <TreeItem nodeId={((document).id)} label={((document).courrier_template_ref_interne)} className="test01"
                                    >
                                        {(document).tab_user_statut &&
                                            (document).tab_user_statut.map((history_doc) => (
                                                <nav>
                                                    {String(history_doc.statut).trim() === "0" &&
                                                        <TreeItem className="test02" nodeId={history_doc._id}
                                                            label={(String(history_doc.email_inscrit))}
                                                            sx={{ color: '#fc5d5d ', fontWeight: 'light', fontStyle: 'italic', mt: '10px' }}
                                                            icon={<FcInfo />} />
                                                    }
                                                </nav>


                                            ))}


                                    </TreeItem>

                                </TreeView>



                            ))}
                    </Box>}

                </div>
            </div>

        </div >
    )
}


export default Module_Editique;