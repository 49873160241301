import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom'
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AiOutlineEdit } from "react-icons/ai";
import { getCookie } from 'react-use-cookie';
import parse from 'html-react-parser';
import UpdateUserGoals from './UpdateUserGoals';
import { IoCloseCircleOutline, IoAddCircleOutline } from "react-icons/io5";


function DisplayUserGoals(props) {
    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'name', headerName: 'Nom', },
        { field: 'description', headerName: 'Description', width: 200, },
        { field: 'domaine', headerName: 'Domaine', width: 200,  flex: 1 },
        { field: 'niveau', headerName: 'Niveau',  flex: 1 },
        { field: 'date_souhaite', headerName: ' Date de realisation souhaité', flex: 1 },

        {
            field: "Print", headerName: 'Editer',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}
                    >
                        <AiOutlineEdit />

                    </Button>

                );
            }
        }

    ]

    function handleClick(event, cellValues) {
        Editobjectif(event, cellValues);
        setaddnewgoals("");
        setopenclosebton(true);
        //console.log(cellValues);
    }

    const [refobjtoupdate, setrefobjtoupdate] = useState();
    const [updateobjectif, setupdateobjectif] = useState(false);

    function Editobjectif(event, cellValues) {
        setrefobjtoupdate(cellValues.row.name);
        setupdateobjectif(true);
        //alert(" traitement de  "+cellValues.row.name);

    }

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rowss, setRows] = useState([]);
    const [listgoals_api, setlistgoals_api] = useState();
    const [listgoals_api_result, setlistgoals_api_result] = useState([]);
    const [listgoals_api_message, setlistgoals_api_message] = useState();

    function Getlistgoals() {
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysych');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_user_objectif/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Getlistgoals : Success:', data['message'], "data['status'] = ", data['status']);

                if (String(data['status']) === 'true') {
                    setlistgoals_api("true");
                    setlistgoals_api_result(data['message']);
                }
                else {
                    setlistgoals_api_message(data['message']);
                }

            }).catch((error) => {
                console.error('Getlistgoals Error:', error);
                setlistgoals_api("false");

            });
    }

    useEffect(() => {
        Getlistgoals();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, []);

    function closeUpdateUserGoals() {
        setupdateobjectif(false);
        setaddnewgoals("");
        setupdateobjectif(false);
        setopenclosebton(false);
    }



    const [addnewgoals, setaddnewgoals] = useState("");
    const [openclosebton, setopenclosebton] = useState(false);


    function handleAddGoal() {
        setaddnewgoals("1");
        setupdateobjectif(false);
        setopenclosebton(true);
    }

    function closehandleAddGoal() {
        setaddnewgoals("");
        setupdateobjectif(false);
    }


    return (
        <div className="displayusergoals">

            {updateobjectif && <div>
                <UpdateUserGoals name_obj={refobjtoupdate} />
               
            </div>}

            {String(addnewgoals) === "1" && <div>
                <UpdateUserGoals />
               
            </div>}

            <div className="div_row">
                <div style={{ "width": "100%", "float": "left", "marginBottom": "0.5rem" }}>
                    <div className="div_row_gauche" > &nbsp; </div>

                    <div className="div_row_droite" style={{ "textAlign": "right" }}>
                      {!openclosebton && <Button variant="contained" color="success" className="bton_ajouter_obj"
                            onClick={handleAddGoal}>Ajouter un objectif &nbsp; &nbsp;{<IoAddCircleOutline />}</Button>}
                   
                   {openclosebton && <Button variant="contained" color="success" className="bton_annnule" onClick={closeUpdateUserGoals}> Fermer <IoCloseCircleOutline /> 
                   </Button>}
                    </div>

                </div>
            </div>

            {String(listgoals_api) === "true" &&
                <div style={{ height: 700, width: '100%' }}>
                    <DataGrid
                        checkboxSelection
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                            //console.log("ch selected--" + newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        rows={listgoals_api_result.map((item, index) => (

                            {
                                id: index,
                                name: JSON.parse(item).name,
                                description: JSON.parse(item).description,
                                domaine: JSON.parse(item).domaine,
                                niveau: JSON.parse(item).niveau,
                                date_souhaite: JSON.parse(item).date_souhaite,

                            }
                        ))}

                        columns={columns}
                        pageSize={10}
                        className="datagridclass"

                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        components={{
                            Toolbar: GridToolbar,
                        }}
                    />


                    <br />

                </div>}

        </div>
    );
}
export default DisplayUserGoals;