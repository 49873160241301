import React, {useState,  useEffect} from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Fotter";
import axios from 'axios';
import Formation from "../components/Formation";
import Select from "react-select";

const  Contact = (props) => {
    const [title, settitle] = useState("");
    const [domain, setdomain] = useState("");
    const [level, setlevel] = useState("");
    const [trainer, settrainer] = useState("");


    const [mydata,setmydata] = useState([]);
    const [mysearchtext,setmysearchtext] = useState("");
    const [myApiUrl,setmyApiUrl] = useState("spa");
    const [myApiResponse,setmyApiResponse] = useState("false");
    const [myRange,setmyRange] = useState(0);

    const [myCriteria1,setmyCriteria1] = useState("");
    const [myCriteria2,setmyCriteria2] = useState("");
    const [myCriteria3,setmyCriteria3] = useState("");
    const [myCriteria4,setmyCriteria4] = useState("");
    const [currentPage,setcurrentPage] = useState(0);
    const [debutpage, setdebutpage] = useState(0);
    const [finpage, setfinpage] = useState(0)
    const [nbligneresultpage, setnbligneresultpage ] = useState(5);

    const pagenumber = [];
    var totalligne = 100;
    var ligneparpage = 15;


    const data = [
        {
            value: 1,
            text: 'Cours',
            icon:  <img src="./img/cours.png" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16" />

        },
        {
            value: 2,
            text: 'Coaching',
            icon:  <img src="./img/coaching.png" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16" />
        },
        {
            value: 3,
            text: 'Video',
            icon:  <img src="./img/video.png" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16" />

        },
        {
            value: 4,
            text: 'Right Arrow tt',
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
        }
    ];

    const [selectedOption, setSelectedOption] = useState(null);

    // handle onChange event of the dropdown
    const newhandleChange = e => {
        setSelectedOption(e);
    }



    for (let i = 1;i <= Math.ceil(totalligne/ligneparpage);i++)
        pagenumber.push((i));

    MyPageNumber();

    function EventHandler(e) {
        //console.log(e.target.id);
    }

    const getRepo = () => {
        //var myurl = process.env.REACT_APP_API_URL+"myclass/api/get_class_global_search/"+mysearchtext;

        var myurl = process.env.REACT_APP_API_URL+"myclass/api/get_recherche_gle_class/"+mysearchtext;

        if( mysearchtext.toString().length == 0 )
            myurl = process.env.REACT_APP_API_URL+"myclass/api/get_all_class/";

        axios.get(myurl,  {

        }).then(res => {
            if(res.data.status != "False"){
                //console.log(" In test  res.data.status = "+res.data.status);
                setmydata(res.data);
                setmyApiResponse("true");
            }
            else
            {
                setmyApiResponse("false");
            }
            //console.log('setmyApiResponse main : ' + myApiResponse);

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = '+mysearchtext );
            setmyApiResponse("false");
        })
    }
    useEffect( () => getRepo(),[mysearchtext])

    window.addEventListener('keyup', (e) => {

        if (e.defaultPrevented) {
            return; // Ne devrait rien faire si l'événement de la touche était déjà consommé.
        }switch (e.key) {
            case "ArrowDown":
                // Faire quelque chose pour la touche "flèche vers le bas" pressée.
                break;
            case "ArrowUp":
                // Faire quelque chose pour la touche "up arrow" pressée.
                break;
            case "ArrowLeft":
                // Faire quelque chose pour la touche "left arrow" pressée.
                break;
            case "ArrowRight":
                // Faire quelque chose pour la touche "right arrow" pressée.
                break;
            case "Enter":
                alert(" ENTREEE");

                break;
            case "Escape":
                // Faire quelque chose pour la touche "esc" pressée.
                break;
            default:
                return; // Quitter lorsque cela ne gère pas l'événement touche.
        }

        // Annuler l'action par défaut pour éviter qu'elle ne soit traitée deux fois.
        e.preventDefault();
    }, true);


    window.addEventListener('click', (e) => {

        //console.log("€€€€  "+e.target.tagName+" => "+ e.target.id);
        // Annuler l'action par défaut pour éviter qu'elle ne soit traitée deux fois.
        if (e.target.tagName === 'A') {
            //alert(e.target.id);  // Check if the element is a LI
            setcurrentPage(e.target.id);
            //console.log("####  "+e.target.tagName+" => "+ e.target.id);
            var tmp = (e.target.id-1)*5;
            //console.log("tmp = "+tmp);
            setdebutpage(tmp);
            tmp = tmp + nbligneresultpage;
            setfinpage(tmp);

            //console.log("debutpage = "+debutpage+"  finpage = "+finpage)

        } /*else {
            setcurrentPage(e.target.id);

            //alert(e.target.tagName+" uuu "+e.target.id);
        }*/

        //e.preventDefault();
        //console.log("page nombre  = " + currentPage);
    }, true);

    function local_api(e){

       var myurl = process.env.REACT_APP_API_URL+"myclass/api/get_all_class/";

       // Verifier si la recherche est vide
       if( mysearchtext.toString().length == 0 )
           myurl = process.env.REACT_APP_API_URL+"myclass/api/get_all_class/";

        axios.get(myurl,  {

        }).then(res => {
            //console.log('response is : ' + res.data);
            setmydata(res.data);
            //console.log(mydata);

        }).catch((error) => {
            console.warn('Not good man :(');
            setmyApiResponse("false");
        })

    }

    function MyPageNumber(){

        var ul = document.getElementById('bulk');  //
        if( ul ) {
            ul.addEventListener('click', function (e) {
                ////console.log(" Tagname = " + e.target.tagName.toString());
                if (e.target.tagName === 'LI') {
                    //alert(e.target.id);  // Check if the element is a LI
                    setcurrentPage(e.target.id);
                } else {
                    setcurrentPage(e.target.id);

                    //alert(e.target.tagName+" uuu "+e.target.id);
                }
            });


        }
    }

    function toto(e){

        var search_value = document.getElementById("search").value;

        //Get Criterias
        var criteria1 = document.getElementById("criteria1").value;
        var criteria2 = document.getElementById("criteria2").value;
        var criteria3 = myCriteria3;
        var criteria4 = myRange;


        //console.log('criteria1 = '+criteria1+' - criteria2 = '+criteria2+' - criteria3 = '+criteria3+' - criteria4 '+criteria4)

        //var myurl = process.env.REACT_APP_API_URL+"myclass/api/get_class_global_search/"+search_value;

        var myurl = process.env.REACT_APP_API_URL+"myclass/api/get_recherche_gle_class/"+search_value;

         if( search_value.length == 0 )
             myurl = process.env.REACT_APP_API_URL+"myclass/api/get_all_class/";

        setmyApiUrl(myurl);
        setmysearchtext(search_value);
        //console.log("search_value TOTO = "+search_value)


        axios.get(myurl,  {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            },

        }).then(res => {
            if(res.data.status != "False"){
                //console.log(" In test  res.data.status = "+res.data.status);
                setmydata(res.data);
                setmyApiResponse("true");
            }
            else
            {
                setmyApiResponse("false");
            }

        }).catch((error) => {
            console.warn('Not good man :(');
        })

    }

    function getInitialState() {
        return {value: 3};
    }
    function  handleChange(event) {
        setmyRange(event.target.value);
    }

    function SelectHandle(event) {
        setmyCriteria3(event.target.value);
    }


    function handleClick(e) {
        e.preventDefault();
        var mytitle = document.getElementById("title").value;
        var mydomain = document.getElementById("domain").value;
        var mylevel = document.getElementById("level").value;
        var mytrainer = document.getElementById("trainer").value;


        if( mytitle.length == 0 )
            alert("WRONG mytitle "+mytitle+"  taille = "+mytitle.length);
        else
            settitle(mytitle);

        if( mydomain.length == 0 )
            alert("WRONG mydomain "+mydomain+"  taille = "+mydomain.length);
        else
            setdomain(mydomain);

        if( mylevel.length == 0 )
            alert("WRONG mylevel "+mylevel+"  taille = "+mylevel.length);
        else
            setlevel(mylevel);

        if( mytrainer.length == 0 )
            alert("WRONG mytrainer "+mytrainer+"  taille = "+mytrainer.length);
        else
            settrainer(mytrainer);

    }

    if ( myApiResponse == "false")
    {
        return (
            <div className={"contact"}>
                <Navigation/>
                <div className={"searchbar"}>

                    <div className="container justify-content-center">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="input-group mb-3" >
                                    <input type="text" className="form-control input-text" id="search" name="search"

                                           placeholder="La recherche sur : titre, obj pédagogique, formateur  "
                                           aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                    &nbsp;

                                    <div style={{color: "red", width: "20%"}} >
                                        <Select
                                            placeholder="Type..."
                                            value={selectedOption}
                                            options={data}
                                            onChange={newhandleChange}
                                            getOptionLabel={e => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {e.icon}
                                                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                                                </div>
                                            )}
                                        />

                                    </div>


                                    <button id="search-button" type="button" className="btn btn-primary"  onClick={toto}>
                                        <img className={"ch_login_img"} alt={"login"}
                                             src={"./img/search.svg"}
                                             width="16"
                                             height="16"
                                        />
                                    </button>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                


                <h1> Resultat vide </h1>
                <div className={"div1"}>
                    <div className="badge bg-primary text-wrap" >Critères.</div>
                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Critere 1</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria1"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm2">Critere 2</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria2"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm3">Critere 3</span>
                        <select className="form-select form-select-sm" onChange={SelectHandle} aria-label=".form-select-sm example" id={"criteria3"}>
                            <option selected>Choisir une valeur</option>
                            <option value="One">One</option>
                            <option value="Two">Two</option>
                            <option value="Three">Three</option>
                        </select>
                    </div>


                    <div className="slidecontainer">

                        <span className="input-group-text" id="inputGroup-sizing-sm3">C. 4</span>
                        <input
                            id="typeinp"
                            type="range"
                            min="0" max="5"
                            value={myRange}
                            onChange={handleChange}
                            step="1"/>
                        <span id="demo">{myRange}</span>

                    </div>
                    <Select
                        placeholder="Type..."
                        value={selectedOption}
                        options={data}

                        onChange={newhandleChange}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {e.icon}
                                <span style={{ marginLeft: 5 }}>{e.text}</span>
                            </div>
                        )}
                    />
                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Critere 1</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria1"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm2">Critere 2</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria2"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Quel niveau</span>
                        <select style={{width: "100%"}}>
                            <option value="">aucun</option>
                            <option value="">Debutant</option>
                            <option value="">Confirmé</option>
                            <option value="">Expert</option>

                        </select>

                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <select id="insightList" multiple="multiple"style={{width: "95%"}}>
                            <optgroup label="Type de formation">
                                <option value="vide"></option>
                                <option value="1-1">Tutoriel</option>
                                <option value="2-1">Cours</option>
                                <option value="2-2">Coaching</option>

                            </optgroup>
                        </select>

                    </div>
                    <div >
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Prix</span>
                        <span>
                        <label htmlFor="test" align={"left"} style={{width: "80%"}}>10 euro</label>
                            <input name="test" id="test" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                        <span>
                            <label htmlFor="test1" align={"left"} style={{width: "80%"}}>20 - 50 euro</label>
                            <input name="test1" id="test1" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                         </span>

                        <span>
                            <label htmlFor="test3" align={"left"} style={{width: "80%"}}>50 - 100 euro</label>
                            <input name="test3" id="test3" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                         </span>
                        <span>
                            <label htmlFor="test4" align={"left"} style={{width: "80%"}}> > 100 euro</label>
                            <input name="test4" id="test4" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                         </span>

                    </div>

                    <div >
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Durée</span>

                        <span><label htmlFor="d_courte" align={"left"} style={{width: "80%"}}>1 - 5 jours</label>
                            <input name="d_courte" id="d_courte" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                        <span><label htmlFor="d_inter" align={"left"} style={{width: "80%"}}>1 sem - 4 sem</label>
                            <input name="d_inter" id="d_inter" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                        <span><label htmlFor="d_long" align={"left"} style={{width: "80%"}}>+ 1 mois</label>
                            <input name="d_long" id="d_long" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>


                    </div>


                    <div className="slidecontainer">

                        <span className="input-group-text" id="inputGroup-sizing-sm3">C. 4</span>
                        <input
                            id="typeinp"
                            type="range"
                            min="0" max="5"
                            value={myRange}
                            onChange={handleChange}
                            step="1"/>
                        <span id="demo">{myRange}</span>

                    </div>



                </div>
                <div className={"div2"}>
                    <input
                        className="button"
                        type="button"
                        value="Relancer la recherche"
                        onClick={toto}
                    />

                </div>
                <div className={"div3"}>
                    <div className="badge bg-primary text-wrap" > Sponsoring </div>
                </div>

                <Footer/>

            </div>
        )
    }
    else {
        return (
            <div className={"contact"}>
                <Navigation/>
                <div className={"searchbar"}>

                    <div className="container justify-content-center">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="input-group mb-3" >
                                    <input type="text" className="form-control input-text" id="search" name="search"
                                           placeholder="La recherche sur : titre, obj pédagogique, formateur  "
                                           aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                    &nbsp;
                                    <div style={{color: "red", width: "20%"}} >

                                        <Select
                                            placeholder="Type..."
                                            value={selectedOption}
                                            options={data}
                                            onChange={newhandleChange}
                                            getOptionLabel={e => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {e.icon}
                                                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                                                </div>
                                            )}
                                        />

                                    </div>

                                    <button id="search-button" type="button" className="btn btn-primary" onClick={toto}>
                                        <img className={"ch_login_img"} alt={"login"}
                                             src={"./img/search.svg"}
                                             width="16"
                                             height="16"
                                        />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                

                <input
                    className="button"
                    type="button"
                    value="Lancer la recherche"
                    onClick={toto}
                />

                <div className={"div1"}>
                    <div className="badge bg-primary text-wrap" >Critères.</div>
                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Critere 1</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria1"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm2">Critere 2</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria2"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm3">Critere 3</span>
                        <select className="form-select form-select-sm" onChange={SelectHandle} aria-label=".form-select-sm example" id={"criteria3"}>
                            <option selected>Choisir une valeur</option>
                            <option value="One">One</option>
                            <option value="Two">Two</option>
                            <option value="Three">Three</option>
                        </select>
                    </div>


                    <div className="slidecontainer">

                        <span className="input-group-text" id="inputGroup-sizing-sm3">C. 4</span>
                        <input
                            id="typeinp"
                            type="range"
                            min="0" max="5"
                            value={myRange}
                            onChange={handleChange}
                            step="1"/>
                        <span id="demo">{myRange}</span>

                    </div>
                    <Select
                        placeholder="Type..."
                        value={selectedOption}
                        options={data}

                        onChange={newhandleChange}
                        getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {e.icon}
                                <span style={{ marginLeft: 5 }}>{e.text}</span>
                            </div>
                        )}
                    />
                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Critere 1</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria1"}/>
                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm2">Critere 2</span>
                        <input type="text" className="form-control" aria-label="Sizing example input"
                               aria-describedby="inputGroup-sizing-sm" id={"criteria2"}/>
                    </div>

                    <div >
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Prix</span>
                        <span>
                        <label htmlFor="test" align={"left"} style={{width: "80%"}}>10 euro</label>
                            <input name="test" id="test" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                       
                    </div>
                    <div className="input-group input-group-sm mb-3">
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Quel niveau xx</span>
                        <select style={{width: "100%"}}>
                            <option value="">aucun</option>
                            <option value="">Debutant</option>
                            <option value="">Confirmé</option>
                            <option value="">Expert</option>

                        </select>

                    </div>

                    <div className="input-group input-group-sm mb-3">
                        <select id="insightList" multiple="multiple"style={{width: "95%"}}>
                            <optgroup label="Type de formation">
                                <option value="vide"></option>
                                <option value="1-1">Tutoriel</option>
                                <option value="2-1">Cours</option>
                                <option value="2-2">Coaching</option>

                            </optgroup>
                        </select>

                    </div>
                    <div >
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Prix</span>
                        <span>
                        <label htmlFor="test" align={"left"} style={{width: "80%"}}>10 euro</label>
                            <input name="test" id="test" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                        <span>
                            <label htmlFor="test1" align={"left"} style={{width: "80%"}}>20 - 50 euro</label>
                            <input name="test1" id="test1" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                         </span>

                        <span>
                            <label htmlFor="test3" align={"left"} style={{width: "80%"}}>50 - 100 euro</label>
                            <input name="test3" id="test3" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                         </span>
                        <span>
                            <label htmlFor="test4" align={"left"} style={{width: "80%"}}> > 100 euro</label>
                            <input name="test4" id="test4" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                         </span>

                    </div>

                    <div >
                        <span className="input-group-text" id="inputGroup-sizing-sm2"
                              style={{width: "100%"}}>Durée</span>

                        <span><label htmlFor="d_courte" align={"left"} style={{width: "80%"}}>1 - 5 jours</label>
                            <input name="d_courte" id="d_courte" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                        <span><label htmlFor="d_inter" align={"left"} style={{width: "80%"}}>1 sem - 4 sem</label>
                            <input name="d_inter" id="d_inter" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>

                        <span><label htmlFor="d_long" align={"left"} style={{width: "80%"}}>+ 1 mois</label>
                            <input name="d_long" id="d_long" lign={"right"}
                                   type="checkbox" style={{width: "20%"}}/>
                        </span>


                    </div>


                    <div className="slidecontainer">

                        <span className="input-group-text" id="inputGroup-sizing-sm3">C. 4</span>
                        <input
                            id="typeinp"
                            type="range"
                            min="0" max="5"
                            value={myRange}
                            onChange={handleChange}
                            step="1"/>
                        <span id="demo">{myRange}</span>

                    </div>



                </div>
                <div className={"div2"}>
                    <div className="badge bg-primary text-wrap" > Resultat recherche </div>
                    <nav className={"nb_page"}>
                        <ul className={"pagination"} id="bulk">
                            {pagenumber.map(number => (
                                <li key={number} className={"page-item"} id={number} value="ii">
                                    <a href="#" id={number}   value="ici" className={"page-link"} onClick={ MyPageNumber }> {number}</a>

                                </li>

                            ))}

                        </ul>
                    </nav>


                    <div className={"user_list"}>
                        {mydata.slice(debutpage,finpage).map((formation) => (
                            <ul>
                                <li><Formation formation={JSON.parse(formation)} key={JSON.parse(formation).title}/></li>
                            </ul>
                        ))}
                    </div>
                </div>
                <div className={"div3"}>
                    <div className="badge bg-primary text-wrap" > Sponsoring </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Contact;

