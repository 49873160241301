import React, { useRef, useState, useEffect } from "react";

import TextField from '@mui/material/TextField';

import fileDownload from 'js-file-download';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";

import { getCookie, setCookie } from 'react-use-cookie';
import { AiTwotoneSave } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { GrOrderedList } from "react-icons/gr";
import { Fab } from "@material-ui/core";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { CiEdit, CiTrash } from "react-icons/ci";

import { AiOutlineUserAdd } from "react-icons/ai";
import PartnerClientActivite from "./PartnerClient_Activite";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ToggleSwitch from "./ToggleSwitch";
import { FcSpeaker } from "react-icons/fc";
import Autocomplete from '@mui/material/Autocomplete';
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { IoCloseCircleOutline } from "react-icons/io5";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Radio from '@mui/material/Radio';
import { FcOpenedFolder } from "react-icons/fc";

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
const AddParnerClient = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");

    const [current_contact_recid, setcurrent_contact_recid] = useState("");

    const [datamodification, setdatamodification] = useState("0");
    const [formedit_mode, setformedit_mode] = useState("0");

    const [datamodification_invoice, setdatamodification_invoice] = useState("0");
    const [formedit_mode_invoice, setformedit_mode_invoice] = useState("0");

    const [datamodification_contact, setdatamodification_contact] = useState("0");
    const [formedit_mode_contact, setformedit_mode_contact] = useState("0");

    const [p_client_email, setclient_email] = useState("");
    const [p_client_nom, setclient_nom] = useState("");

    const [p_client_type_id, setp_client_type_id] = useState("");
    const [p_client_type_label, setp_client_type_label] = useState("");

    const [p_client_type_financeur, setp_client_type_financeur] = useState("");
    const [p_client_type_pouvoir_public, setp_client_type_pouvoir_public] = useState("");

    const [p_client_raison_sociale, setclient_raison_sociale] = useState("");

    const [p_client_is_company, setp_client_is_company] = useState("");


    const [p_client_siret, setclient_siret] = useState("");
    const [p_client_tva, setclient_tva] = useState("");
    const [p_client_adresse, setclient_adresse] = useState("");
    const [p_client_ville, setclient_ville] = useState("");
    const [p_client_code_postal, setclient_code_postal] = useState("");
    const [p_client_pays, setclient_pays] = useState("");
    const [p_client_telephone, setclient_telephone] = useState("");
    const [p_client_website, setclient_website] = useState("");

    // Données de facturation
    const [p_client_invoice_email, setclient_invoice_email] = useState("");
    const [p_client_invoice_nom, setclient_invoice_nom] = useState("");
    const [p_client_invoice_siret, setclient_invoice_siret] = useState("");
    const [p_client_invoice_tva, setclient_invoice_tva] = useState("");
    const [p_client_invoice_adresse, setclient_invoice_adresse] = useState("");

    const [p_client_invoice_paiement_condition_code, setp_client_invoice_paiement_condition_code] = useState("");
    const [p_client_invoice_paiement_condition_id, setp_client_invoice_paiement_condition_id] = useState("");

    const [p_client_invoice_ville, setclient_invoice_ville] = useState("");
    const [p_client_invoice_code_postal, setclient_invoice_code_postal] = useState("");
    const [p_client_invoice_pays, setclient_invoice_pays] = useState("");

    // Données contact
    const [p_contact_email, setp_contact_email] = useState("");
    const [p_contact_nom, setp_contact_nom] = useState("");
    const [p_contact_prenom, setp_contact_prenom] = useState("");
    const [p_contact_siret, setcontact_siret] = useState("");
    const [p_contact_tva, setcontact_tva] = useState("");
    const [p_contact_adresse, setcontact_adresse] = useState("");
    const [p_contact_ville, setcontact_ville] = useState("");
    const [p_contact_code_postal, setcontact_code_postal] = useState("");
    const [p_contact_pays, setcontact_pays] = useState("");
    const [p_contact_telephone, setcontact_telephone] = useState("");
    const [p_contact_telephone_mobile, setp_contact_telephone_mobile] = useState("");

    const [p_contact_fonction, setp_contact_fonction] = useState("");
    const [p_contact_civilite, setp_contact_civilite] = useState("");


    const [selected_contact_id, setelected_contact_id] = useState("");



    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }



    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    function inactive_active_menu_header(current_menu_header) {

        const list_fields = ['submenu_devis', 'submenu_factures', 'submenu_piece_jointe',
            'submenu_activite', 'submenu_contact', 'submenu_donnee_facturation', 'submenu_donnee_client']

        for (let i = 0; i < list_fields.length; i++) {
            if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
                document.getElementsByName(String(list_fields[i]))[0].style.background = "#d8edfc";
                document.getElementsByName(String(list_fields[i]))[0].style.color = "#3b3e40";

            }
        }

        if (document.getElementsByName(String(current_menu_header)) && document.getElementsByName(String(current_menu_header))[0]) {
            document.getElementsByName(String(current_menu_header))[0].style.background = "#104277";
            document.getElementsByName(String(current_menu_header))[0].style.color = "white";
        }


    }

    const [importmessage, setimportmessage] = useState("");
    async function submenu_donnee_client() {
        setsubmenu("submenu_donnee_client");
        /*
       Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
       */
        await sleep(5);
        if (String(formedit_mode) !== "1") {
            Disable_fields();
        } else {
            Enable_fields();
        }

        inactive_active_menu_header("submenu_donnee_client");

    }



    async function submenu_donnee_facturation() {
        setsubmenu("submenu_donnees_facturation");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
        if (String(formedit_mode_invoice) !== "1") {
            Disable_invoice_fields();
        } else {
            Enable_invoice_fields();
        }

        inactive_active_menu_header("submenu_donnee_facturation");

    }

    async function submenu_contact() {
        setsubmenu("submenu_contact");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);

        inactive_active_menu_header("submenu_contact");

    }
 
    async function submenu_activite() {

        setsubmenu("submenu_activite");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
        if (String(formedit_mode_invoice) !== "1") {
            //Disable_invoice_fields();
        } else {
            //Enable_invoice_fields();
        }
        inactive_active_menu_header("submenu_activite");


    }


    async function submenu_piece_jointe() {

        setsubmenu("submenu_piece_jointe");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
        if (String(formedit_mode_invoice) !== "1") {
            //Disable_invoice_fields();
        } else {
            //Enable_invoice_fields();
        }
        inactive_active_menu_header("submenu_piece_jointe");
        // Refresh affichage des pièces jointes
        Get_List_Of_All_PJ(props.client_id);
    }


    async function submenu_devis() {
        setsubmenu("submenu_devis");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
        inactive_active_menu_header("submenu_devis");
    }

    async function submenu_cmd() {
        setsubmenu("submenu_cmd");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
    }

    async function submenu_factures() {
        setsubmenu("submenu_factures");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
        inactive_active_menu_header("submenu_factures");
    }


    async function submenu_stagiaires() {
        setsubmenu("submenu_stagiaires");
        /*
        Ceci est super crade, fair eun wait de pour attendre l'afficage avant de desacitcation si on a desactiver, mais bon ... on avance.
        */
        await sleep(5);
    }

    function DataUpdated() {
        setdatamodification("1");
    }

    function DataEditMode() {
        setformedit_mode("1");
        Enable_fields();
    }

    function AnnulerEdit() {
        setformedit_mode("0");
        setdatamodification("0");
        Disable_fields();
        clear_fields();
        fillfield_Only_Main_Data(props.client_mail);

    }

    function DataUpdated_Invoice_Data() {
        setdatamodification_invoice("1");
    }

    function DataEditMode_Invoice_Data() {
        setformedit_mode_invoice("1");
        Enable_invoice_fields();
    }



    function DataUpdated_Contact_Data() {
        setdatamodification_contact("1");
    }

    function DataEditMode_Contact_Data() {
        setformedit_mode_contact("1");
        Enable_contact_fields();
    }


    function AnnulerEdit_Invoice_Data() {
        setformedit_mode_invoice("0");
        setdatamodification_invoice("0");
        Disable_invoice_fields();
        clear_invoice_fields();
        fillfield_Invoice_Data(props.client_mail);
    }


    function SetTabFocus() {

        document.getElementById("submenu_donnee_client").style.background = "#d8edfc";
        document.getElementById("submenu_donnee_client").style.color = "#3b3e40";
        document.getElementById("submenu_donnee_facturation").style.background = "#d8edfc";
        document.getElementById("submenu_donnee_facturation").style.color = "#3b3e40";


        if (String(submenu) === "submenu_donnee_client") {
            document.getElementById("submenu_donnee_client").style.background = "#104277";
            document.getElementById("submenu_donnee_client").style.color = "white";
        }
        else if (String(submenu) === "submenu_donnees_facturation") {
            document.getElementById("submenu_donnee_facturation").style.background = "#104277";
            document.getElementById("submenu_donnee_facturation").style.color = "white";
        }

        else if (String(submenu) === "submenu_piece_jointe") {
            document.getElementById("submenu_piece_jointe").style.background = "#104277";
            document.getElementById("submenu_piece_jointe").style.color = "white";
        }

    }

    const [addcontact, setaddcontact] = useState("0");
    function Add_Contact() {
        setis_contact_include_com(false);
        setdatamodification_contact("0");
        setformedit_mode_contact("1");
        clean_contact_set_values();
        setaddcontact("1");
        setcurrent_contact_recid("");
    }

    function Close_Contact() {
        setaddcontact("0");
        setdatamodification_contact("0");
        setformedit_mode_contact("0");
        clear_contact_fields();
        clean_contact_set_values();
        setdatamodification_invoice("0");
        setformedit_mode_contact("0");
        setcurrent_contact_recid("");
        setis_contact_include_com(false);
    }

    const [New_Get_List_Client_Type_result, setNew_Get_List_Client_Type_result] = useState([]);

    const [Get_List_Client_Type_api, setGet_List_Client_Type_api] = useState();
    const [Get_List_Client_Type_message, setGet_List_Client_Type_message] = useState();
    const [Get_List_Client_Type_result, setGet_List_Client_Type_result] = useState();
    function Get_List_Client_Type(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        const related_collection = "partner_client"


        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Client_Type_List/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Client_Type  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Client_Type  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Client_Type_api("true");
                setGet_List_Client_Type_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",

                };
                new_data2.push(node);
                if (new_data2.length > 0)
                    setNew_Get_List_Client_Type_result(new_data2);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Client_Type_api("false");
                setGet_List_Client_Type_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Client_Type = ', error);
            setGet_List_Client_Type_api("false");
            alert(" Impossible de récupérer les types de clients");
        })
    }

    const [New_Getall_Partner_Paiement_Condition_result, setNew_Getall_Partner_Paiement_Condition_result] = useState([]);

    const [Getall_Partner_Paiement_Condition_api, setGetall_Partner_Paiement_Condition_api] = useState();
    const [Getall_Partner_Paiement_Condition_message, setGetall_Partner_Paiement_Condition_message] = useState();
    const [Getall_Partner_Paiement_Condition_result, setGetall_Partner_Paiement_Condition_result] = useState([]);
    function Getall_Partner_Paiement_Condition(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Paiement_Condition/";

        //setLoading(true);
        axios.post(myurl, form).then(res => {
            //setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Paiement_Condition  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Paiement_Condition  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Paiement_Condition_api("true");
                setGetall_Partner_Paiement_Condition_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;
                    var local_nb_jour = JSON.parse(x).nb_jour;
                    var local_depart = JSON.parse(x).depart;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "description": local_description,
                        "nb_jour": local_nb_jour,
                        "depart": local_depart,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "nb_jour": "",
                    "depart": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Paiement_Condition_result(new_data2);


            }
            else {
                setGetall_Partner_Paiement_Condition_api("false");
                setGetall_Partner_Paiement_Condition_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            //setLoading(false);
            console.warn('Not good man :( Getall_Partner_Paiement_Condition = ', error);
            setGetall_Partner_Paiement_Condition_api("false");
            alert(" Impossible de recuperer la liste des conditions de paiement");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {
        Get_List_Client_Type();
        Getall_Partner_Paiement_Condition();
        Get_List_Of_All_PJ(props.client_id);
        Get_List_Type_Financeur();
        Get_List_Type_Pouvoir_Public();

        if (String(props.new_customer) !== "1") {
            setformedit_mode("0");
            setformedit_mode_invoice("0");

            submenu_donnee_client();
            fillfield();

            setdatamodification("0");
            setdatamodification_invoice("0");

            Get_List_Contact_Of_client_Part();


        } else {
            setformedit_mode("1");
            setformedit_mode_invoice("1");
        }


    }, [props.client_mail,]);



    const [has_client_invoice_email, sethas_client_invoice_email] = useState();


    const [fillfield_api, setfillfield_api] = useState("");
    const [fillfield_result, setfillfield_result] = useState("");
    const [fillfield_message, setfillfield_message] = useState("");
    function fillfield() {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", props.client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Client_From_Id/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In fillfield  res.data.status = " + res.data.status);
                //console.log(" In fillfield  res.data.message  = " + res.data.message);
                setfillfield_api("true");
                setfillfield_result(res.data.message);
                var mylocalclient = JSON.parse(res.data.message);


                if (mylocalclient) {


                    if (mylocalclient.nom) {
                        setclient_nom(mylocalclient.nom);
                    } else {
                        setclient_nom("");
                    }

                    if (mylocalclient.email) {
                        setclient_email(mylocalclient.email);
                    } else {
                        setclient_email("");
                    }


                    if (mylocalclient.raison_sociale) {
                        setclient_raison_sociale(mylocalclient.raison_sociale);
                    } else {
                        setclient_raison_sociale("");
                    }

                    setp_client_is_company("0");
                    if (mylocalclient.is_company) {
                        setp_client_is_company(mylocalclient.is_company);
                    }


                    if (String(mylocalclient.is_financeur) === "1")
                        setp_client_is_financeur(true);
                    else
                        setp_client_is_financeur(false);


                    if (String(mylocalclient.is_client) === "1")
                        setp_client_is_client(true);
                    else
                        setp_client_is_client(false);


                    if (String(mylocalclient.is_fournisseur) === "1")
                        setp_client_is_fournisseur(true);
                    else
                        setp_client_is_fournisseur(false);



                    if (mylocalclient.adr_adresse)
                        setclient_adresse(mylocalclient.adr_adresse);
                    else
                        setclient_adresse("");


                    if (mylocalclient.adr_ville)
                        setclient_ville(mylocalclient.adr_ville);
                    else
                        setclient_ville("");

                    if (mylocalclient.adr_pays)
                        setclient_pays(mylocalclient.adr_pays);
                    else
                        setclient_pays("");

                    if (mylocalclient.siret)
                        setclient_siret(mylocalclient.siret);
                    else
                        setclient_siret("");


                    if (mylocalclient.client_type_id) {

                        setp_client_type_id(mylocalclient.client_type_id);
                        setp_client_type_label(mylocalclient.client_type_code);
                    } else {
                        setp_client_type_id("");
                        setp_client_type_label("");
                    }

                    if (mylocalclient.tva)
                        setclient_tva(mylocalclient.tva);
                    else
                        setclient_tva("");


                    if (mylocalclient.type_financeur_id)
                        setp_client_type_financeur(mylocalclient.type_financeur_id);
                    else
                        setp_client_type_financeur("");


                    if (mylocalclient.type_pouvoir_public_id)
                        setp_client_type_pouvoir_public(mylocalclient.type_pouvoir_public_id);

                    else
                        setp_client_type_pouvoir_public("");




                    if (mylocalclient.website)
                        setclient_website(mylocalclient.website);
                    else
                        setclient_website("");

                    if (mylocalclient.telephone)
                        setclient_telephone(mylocalclient.telephone);
                    else
                        setclient_telephone("");

                    // Remplissage des doonées de facturation
                    if (mylocalclient.invoice_email)
                        setclient_invoice_email(mylocalclient.invoice_email);
                    else {
                        setclient_invoice_email("");
                        sethas_client_invoice_email("0");
                    }

                    if (mylocalclient.invoice_nom)
                        setclient_invoice_nom(mylocalclient.invoice_nom);
                    else
                        setclient_invoice_nom("");


                    if (mylocalclient.invoice_siret)
                        setclient_invoice_siret(mylocalclient.invoice_siret);
                    else
                        setclient_invoice_siret("");

                    if (mylocalclient.invoice_tva)
                        setclient_invoice_tva(mylocalclient.invoice_tva);
                    else
                        setclient_invoice_tva("");

                    if (mylocalclient.invoice_adresse)
                        setclient_invoice_adresse(mylocalclient.invoice_adresse);
                    else
                        setclient_invoice_adresse("");

                    if (mylocalclient.invoice_condition_paiement_id)
                        setp_client_invoice_paiement_condition_id(mylocalclient.invoice_condition_paiement_id);
                    else {
                        setp_client_invoice_paiement_condition_id("");
                        sethas_client_invoice_email("0");
                    }

                    if (mylocalclient.invoice_condition_paiement_code)
                        setp_client_invoice_paiement_condition_code(mylocalclient.invoice_condition_paiement_code);
                    else
                        setp_client_invoice_paiement_condition_code("");

                    if (mylocalclient.invoice_ville)
                        setclient_invoice_ville(mylocalclient.invoice_ville);
                    else
                        setclient_invoice_ville("");

                    if (mylocalclient.invoice_code_postal)
                        setclient_invoice_code_postal(mylocalclient.invoice_code_postal);
                    else
                        setclient_invoice_code_postal("");

                    if (mylocalclient.invoice_pays)
                        setclient_invoice_pays(mylocalclient.invoice_pays);
                    else
                        setclient_invoice_pays("");

                    if (mylocalclient.invoice_automatique)
                        setp_detail_facturation_automatique(mylocalclient.invoice_automatique);
                    else
                        setp_detail_facturation_automatique("");

                    setdatamodification("0");
                    setdatamodification_invoice("0");

                    Disable_fields();
                    //Disable_invoice_fields();
                }
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setfillfield_api("false");
                setfillfield_message(res.data.message);
            }


        }).catch((error) => {
            console.warn('Not good man :( fillfield = ', error);
            setfillfield_result("false");
            setfillfield_message(" Impossible de recuperer les informations du client")

        })
    }


    const [fillfield_Invoice_Data_api, setfillfield_Invoice_Data_api] = useState("");
    const [fillfield_Invoice_Data_result, setfillfield_Invoice_Data_result] = useState("");
    const [fillfield_Invoice_Data_message, setfillfield_Invoice_Data_message] = useState("");
    function fillfield_Invoice_Data(cust_email) {

        var val = cust_email;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", props.client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Client_From_Id/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In fillfield_Invoice_Data  res.data.status = " + res.data.status);
                //console.log(" In fillfield_Invoice_Data  res.data.message  = " + res.data.message);
                setfillfield_Invoice_Data_api("true");
                setfillfield_Invoice_Data_result(res.data.message);
                var mylocalclient = JSON.parse(res.data.message);


                if (mylocalclient) {

                    // Remplissage des doonées de facturation
                    if (mylocalclient.invoice_email)
                        setclient_invoice_email(mylocalclient.invoice_email);
                    else
                        setclient_invoice_email("");

                    if (mylocalclient.invoice_nom)
                        setclient_invoice_nom(mylocalclient.invoice_nom);
                    else
                        setclient_invoice_nom("");

                    if (mylocalclient.invoice_siret)
                        setclient_invoice_siret(mylocalclient.invoice_siret);
                    else
                        setclient_invoice_siret("");

                    if (mylocalclient.invoice_tva)
                        setclient_invoice_tva(mylocalclient.invoice_tva);
                    else
                        setclient_invoice_tva("");

                    if (mylocalclient.invoice_adresse)
                        setclient_invoice_adresse(mylocalclient.invoice_adresse);
                    else
                        setclient_invoice_adresse("");

                    if (mylocalclient.invoice_condition_paiement_id)
                        setp_client_invoice_paiement_condition_id(mylocalclient.invoice_condition_paiement_id);
                    else
                        setp_client_invoice_paiement_condition_id("");

                    if (mylocalclient.invoice_condition_paiement_code)
                        setp_client_invoice_paiement_condition_code(mylocalclient.invoice_condition_paiement_code);
                    else
                        setp_client_invoice_paiement_condition_code("");

                    if (mylocalclient.invoice_ville)
                        setclient_invoice_ville(mylocalclient.invoice_ville);
                    else
                        setclient_invoice_ville("");

                    if (mylocalclient.invoice_code_postal)
                        setclient_invoice_code_postal(mylocalclient.invoice_code_postal);
                    else
                        setclient_invoice_code_postal("");

                    if (mylocalclient.invoice_pays)
                        setclient_invoice_pays(mylocalclient.invoice_pays);
                    else
                        setclient_invoice_pays("");

                    if (mylocalclient.invoice_automatique)
                        setp_detail_facturation_automatique(mylocalclient.invoice_automatique);
                    else
                        setp_detail_facturation_automatique("");

                    Disable_invoice_fields();
                }
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setfillfield_Invoice_Data_api("false");
                setfillfield_Invoice_Data_message(res.data.message);
            }


        }).catch((error) => {
            console.warn('Not good man :( fillfield_Invoice_Data = ', error);
            setfillfield_Invoice_Data_result("false");
            setfillfield_Invoice_Data_message(" Impossible de recuperer les informations du client")

        })
    }

    const [fillfield_Only_Main_Data_api, setfillfield_Only_Main_Data_api] = useState("");
    const [fillfield_Only_Main_Data_result, setfillfield_Only_Main_Data_result] = useState("");
    const [fillfield_Only_Main_Data_message, setfillfield_Only_Main_Data_message] = useState("");
    function fillfield_Only_Main_Data(cust_email) {

        var val = cust_email;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", props.client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Client_From_Id/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In fillfield_Only_Main_Data  res.data.status = " + res.data.status);
                //console.log(" In fillfield_Only_Main_Data  res.data.message  = " + res.data.message);
                setfillfield_Only_Main_Data_api("true");
                setfillfield_Only_Main_Data_result(res.data.message);
                var mylocalclient = JSON.parse(res.data.message);


                if (mylocalclient) {

                    if (mylocalclient.raison_sociale) {
                        setclient_raison_sociale(mylocalclient.raison_sociale);
                    } else {
                        setclient_raison_sociale("");
                    }

                    setp_client_is_company("0");
                    if (mylocalclient.is_company) {
                        setp_client_is_company(mylocalclient.is_company);
                    }

                    if (mylocalclient.adr_adresse)
                        setclient_adresse(mylocalclient.adr_adresse);
                    else
                        setclient_adresse("");


                    if (mylocalclient.adr_ville)
                        setclient_ville(mylocalclient.adr_ville);
                    else
                        setclient_ville("");

                    if (mylocalclient.adr_pays)
                        setclient_pays(mylocalclient.adr_pays);
                    else
                        setclient_pays("");

                    if (mylocalclient.siret)
                        setclient_siret(mylocalclient.siret);
                    else
                        setclient_siret("");

                    if (mylocalclient.tva)
                        setclient_tva(mylocalclient.tva);
                    else
                        setclient_tva("");

                    if (mylocalclient.website)
                        setclient_website(mylocalclient.website);
                    else
                        setclient_website("");

                    if (mylocalclient.telephone)
                        setclient_telephone(mylocalclient.telephone);
                    else
                        setclient_telephone("");


                    Disable_fields();

                }
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setfillfield_Only_Main_Data_api("false");
                setfillfield_Only_Main_Data_message(res.data.message);
            }


        }).catch((error) => {
            console.warn('Not good man :( fillfield_Only_Main_Data = ', error);
            setfillfield_Only_Main_Data_result("false");
            setfillfield_Only_Main_Data_message(" Impossible de recuperer les informations du client")

        })
    }


    const [client_has_communication_contact, setclient_has_communication_contact] = useState();

    const [Get_List_Contact_Of_client_Part_api, setGet_List_Contact_Of_client_Part_api] = useState();
    const [Get_List_Contact_Of_client_Part_message, setGet_List_Contact_Of_client_Part_message] = useState();
    const [Get_List_Contact_Of_client_Part_result, setGet_List_Contact_Of_client_Part_result] = useState();
    function Get_List_Contact_Of_client_Part(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        const related_collection = "partner_client"


        form.append("token", stored_cookie);
        form.append("related_collection", related_collection);
        form.append("related_collection_owner_id", props.client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Entity_Contact/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Contact_Of_client_Part  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Contact_Of_client_Part  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Contact_Of_client_Part_api("true");
                setGet_List_Contact_Of_client_Part_result(res.data.message);

                var contact_com = "0";

                const new_data = res.data.message.map((x) => {

                    //---
                    var include_com = JSON.parse(x).include_com;
                    if (String(include_com) === "1") {
                        contact_com = "1"
                    }
                });

                setclient_has_communication_contact(contact_com);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Contact_Of_client_Part_api("false");
                setGet_List_Contact_Of_client_Part_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Contact_Of_client_Part = ', error);
            setGet_List_Contact_Of_client_Part_api("false");
            //setmyApimyApiMessage("")
        })
    }


    function clear_fields() {
        if (document.getElementsByName("client_email")) {
            document.getElementsByName("client_email")[0].value = "";
        }

        if (document.getElementsByName("client_nom")) {
            document.getElementsByName("client_nom")[0].value = "";
        }

        if (document.getElementsByName("client_raison_sociale")) {
            document.getElementsByName("client_raison_sociale")[0].value = "";
        }

        if (document.getElementsByName("client_adresse")) {
            document.getElementsByName("client_adresse")[0].value = "";
        }

        if (document.getElementsByName("client_ville")) {
            document.getElementsByName("client_ville")[0].value = "";
        }

        if (document.getElementsByName("client_pays")) {
            document.getElementsByName("client_pays")[0].value = "";
        }

        if (document.getElementsByName("client_email")) {
            document.getElementsByName("client_email")[0].value = "";
        }

        if (document.getElementsByName("client_siret")) {
            document.getElementsByName("client_siret")[0].value = "";
        }

        if (document.getElementsByName("client_type_id") && document.getElementsByName("client_type_id")[0]) {
            document.getElementsByName("client_type_id")[0].value = "";
        }

        if (document.getElementsByName("client_type_financeur_id") && document.getElementsByName("client_type_financeur_id")[0]) {
            document.getElementsByName("client_type_financeur_id")[0].value = "";
        }

        if (document.getElementsByName("client_type_pouvoir_public_id") && document.getElementsByName("client_type_pouvoir_public_id")[0]) {
            document.getElementsByName("client_type_pouvoir_public_id")[0].value = "";
        }



        if (document.getElementsByName("client_tva")) {
            document.getElementsByName("client_tva")[0].value = "";
        }

        if (document.getElementsByName("client_website")) {
            document.getElementsByName("client_website")[0].value = "";
        }

        if (document.getElementsByName("client_code_postal")) {
            document.getElementsByName("client_code_postal")[0].value = "";
        }

        if (document.getElementsByName("client_telephone")) {
            document.getElementsByName("client_telephone")[0].value = "";
        }

    }

    function Disable_fields() {
        if (document.getElementsByName("client_email" && document.getElementsByName("client_email")[0])) {
            document.getElementsByName("client_email")[0].disabled = true;
            document.getElementsByName("client_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_nom") && document.getElementsByName("client_nom")[0]) {
            document.getElementsByName("client_nom")[0].disabled = true;
            document.getElementsByName("client_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_raison_sociale") && document.getElementsByName("client_raison_sociale")[0]) {
            document.getElementsByName("client_raison_sociale")[0].disabled = true;
            document.getElementsByName("client_raison_sociale")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_adresse") && document.getElementsByName("client_adresse")[0]) {
            document.getElementsByName("client_adresse")[0].disabled = true;
            document.getElementsByName("client_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_ville") && document.getElementsByName("client_ville")[0]) {
            document.getElementsByName("client_ville")[0].disabled = true;
            document.getElementsByName("client_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_pays") && document.getElementsByName("client_pays")[0]) {
            document.getElementsByName("client_pays")[0].disabled = true;
            document.getElementsByName("client_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_email") && document.getElementsByName("client_pays")[0]) {
            document.getElementsByName("client_email")[0].disabled = true;
            document.getElementsByName("client_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_siret") && document.getElementsByName("client_pays")[0]) {
            document.getElementsByName("client_siret")[0].disabled = true;
            document.getElementsByName("client_siret")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_type_id") && document.getElementsByName("client_type_id")[0]) {
            document.getElementsByName("client_type_id")[0].disabled = true;
            document.getElementsByName("client_type_id")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("client_type_financeur_id") && document.getElementsByName("client_type_financeur_id")[0]) {
            document.getElementsByName("client_type_financeur_id")[0].disabled = true;
            document.getElementsByName("client_type_financeur_id")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_type_pouvoir_public_id") && document.getElementsByName("client_type_pouvoir_public_id")[0]) {
            document.getElementsByName("client_type_pouvoir_public_id")[0].disabled = true;
            document.getElementsByName("client_type_pouvoir_public_id")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("client_tva") && document.getElementsByName("client_tva")[0]) {
            document.getElementsByName("client_tva")[0].disabled = true;
            document.getElementsByName("client_tva")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_website") && document.getElementsByName("client_website")[0]) {
            document.getElementsByName("client_website")[0].disabled = true;
            document.getElementsByName("client_website")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_code_postal") && document.getElementsByName("client_code_postal")[0]) {
            document.getElementsByName("client_code_postal")[0].disabled = true;
            document.getElementsByName("client_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_telephone") && document.getElementsByName("client_telephone")[0]) {
            document.getElementsByName("client_telephone")[0].disabled = true;
            document.getElementsByName("client_telephone")[0].style.backgroundColor = "#ECEFF1";
        }



    }

    function Enable_fields() {
        if (document.getElementsByName("client_email")) {
            document.getElementsByName("client_email")[0].disabled = false;
            document.getElementsByName("client_email")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_nom")) {
            document.getElementsByName("client_nom")[0].disabled = false;
            document.getElementsByName("client_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_raison_sociale")) {
            document.getElementsByName("client_raison_sociale")[0].disabled = false;
            document.getElementsByName("client_raison_sociale")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_adresse")) {
            document.getElementsByName("client_adresse")[0].disabled = false;
            document.getElementsByName("client_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_ville")) {
            document.getElementsByName("client_ville")[0].disabled = false;
            document.getElementsByName("client_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_pays")) {
            document.getElementsByName("client_pays")[0].disabled = false;
            document.getElementsByName("client_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_email")) {
            document.getElementsByName("client_email")[0].disabled = false;
            document.getElementsByName("client_email")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_siret")) {
            document.getElementsByName("client_siret")[0].disabled = false;
            document.getElementsByName("client_siret")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_type_id") && document.getElementsByName("client_type_id")[0]) {
            document.getElementsByName("client_type_id")[0].disabled = false;
            document.getElementsByName("client_type_id")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("client_type_financeur_id") && document.getElementsByName("client_type_financeur_id")[0]) {
            document.getElementsByName("client_type_financeur_id")[0].disabled = false;
            document.getElementsByName("client_type_financeur_id")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_type_pouvoir_public_id") && document.getElementsByName("client_type_pouvoir_public_id")[0]) {
            document.getElementsByName("client_type_pouvoir_public_id")[0].disabled = false;
            document.getElementsByName("client_type_pouvoir_public_id")[0].style.backgroundColor = "#FFFFFF";
        }



        if (document.getElementsByName("client_tva")) {
            document.getElementsByName("client_tva")[0].disabled = false;
            document.getElementsByName("client_tva")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_website")) {
            document.getElementsByName("client_website")[0].disabled = false;
            document.getElementsByName("client_website")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_code_postal")) {
            document.getElementsByName("client_code_postal")[0].disabled = false;
            document.getElementsByName("client_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_telephone")) {
            document.getElementsByName("client_telephone")[0].disabled = false;
            document.getElementsByName("client_telephone")[0].style.backgroundColor = "#FFFFFF";
        }


    }


    async function Disable_contact_fields() {

        await sleep(5);

        if (document.getElementsByName("contact_email")) {
            document.getElementsByName("contact_email")[0].disabled = true;
            document.getElementsByName("contact_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_nom")) {
            document.getElementsByName("contact_nom")[0].disabled = true;
            document.getElementsByName("contact_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_prenom")) {
            document.getElementsByName("contact_prenom")[0].disabled = true;
            document.getElementsByName("contact_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_fonction")) {
            document.getElementsByName("contact_fonction")[0].disabled = true;
            document.getElementsByName("contact_fonction")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("contact_civilite")) {
            document.getElementsByName("contact_civilite")[0].disabled = true;
            document.getElementsByName("contact_civilite")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("contact_siret")) {
            document.getElementsByName("contact_siret")[0].disabled = true;
            document.getElementsByName("contact_siret")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_tva")) {
            document.getElementsByName("contact_tva")[0].disabled = true;
            document.getElementsByName("contact_tva")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_adresse")) {
            document.getElementsByName("contact_adresse")[0].disabled = true;
            document.getElementsByName("contact_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_ville")) {
            document.getElementsByName("contact_ville")[0].disabled = true;
            document.getElementsByName("contact_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_code_postal")) {
            document.getElementsByName("contact_code_postal")[0].disabled = true;
            document.getElementsByName("contact_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_pays")) {
            document.getElementsByName("contact_pays")[0].disabled = true;
            document.getElementsByName("contact_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_telephone")) {
            document.getElementsByName("contact_telephone")[0].disabled = true;
            document.getElementsByName("contact_telephone")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("contact_telephone_mobile")) {
            document.getElementsByName("contact_telephone_mobile")[0].disabled = true;
            document.getElementsByName("contact_telephone_mobile")[0].style.backgroundColor = "#ECEFF1";
        }

    }

    function Enable_contact_fields() {
        if (document.getElementsByName("contact_email")) {
            document.getElementsByName("contact_email")[0].disabled = false;
            document.getElementsByName("contact_email")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_nom")) {
            document.getElementsByName("contact_nom")[0].disabled = false;
            document.getElementsByName("contact_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_prenom")) {
            document.getElementsByName("contact_prenom")[0].disabled = false;
            document.getElementsByName("contact_prenom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_civilite")) {
            document.getElementsByName("contact_civilite")[0].disabled = false;
            document.getElementsByName("contact_civilite")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_fonction")) {
            document.getElementsByName("contact_fonction")[0].disabled = false;
            document.getElementsByName("contact_fonction")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_siret")) {
            document.getElementsByName("contact_siret")[0].disabled = false;
            document.getElementsByName("contact_siret")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_tva")) {
            document.getElementsByName("contact_tva")[0].disabled = false;
            document.getElementsByName("contact_tva")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_adresse")) {
            document.getElementsByName("contact_adresse")[0].disabled = false;
            document.getElementsByName("contact_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_ville")) {
            document.getElementsByName("contact_ville")[0].disabled = false;
            document.getElementsByName("contact_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_code_postal")) {
            document.getElementsByName("contact_code_postal")[0].disabled = false;
            document.getElementsByName("contact_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_pays")) {
            document.getElementsByName("contact_pays")[0].disabled = false;
            document.getElementsByName("contact_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_telephone")) {
            document.getElementsByName("contact_telephone")[0].disabled = false;
            document.getElementsByName("contact_telephone")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("contact_telephone_mobile")) {
            document.getElementsByName("contact_telephone_mobile")[0].disabled = false;
            document.getElementsByName("contact_telephone_mobile")[0].style.backgroundColor = "#FFFFFF";
        }

    }




    const [Add_New_PartnerClient_api, setAdd_New_PartnerClient_api] = useState("");
    const [Add_New_PartnerClient_result, setAdd_New_PartnerClient_result] = useState("");
    const [Add_New_PartnerClient_message, setAdd_New_PartnerClient_message] = useState("");
    function Add_New_PartnerClient(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        // Recupeation des données à enregister

        var client_email = ""
        if (document.getElementsByName("client_email")) {
            client_email = String(document.getElementsByName("client_email")[0].value).trim();
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


        if (!client_email.match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }

        form.append("email", client_email);


        var client_nom = ""
        if (document.getElementsByName("client_nom")) {
            client_nom = String(document.getElementsByName("client_nom")[0].value).trim();
        }
        form.append("nom", client_nom);
        if (String(client_nom).trim() === "") {
            alert("Le nom du client est invalide.");
            return;
        }


        var client_raison_sociale = ""
        if (document.getElementsByName("client_raison_sociale")) {
            client_raison_sociale = document.getElementsByName("client_raison_sociale")[0].value;
        }
        form.append("raison_sociale", client_raison_sociale);

        form.append("is_company", p_client_is_company);

        var client_adresse = ""
        if (document.getElementsByName("client_adresse")) {
            client_adresse = document.getElementsByName("client_adresse")[0].value;
        }
        form.append("adr_adresse", client_adresse);

        var client_code_postal = ""
        if (document.getElementsByName("client_code_postal")) {
            client_code_postal = document.getElementsByName("client_code_postal")[0].value;
        }
        form.append("adr_code_postal", client_code_postal);

        var client_ville = ""
        if (document.getElementsByName("client_ville")) {
            client_ville = document.getElementsByName("client_ville")[0].value;
        }
        form.append("adr_ville", client_ville);

        var client_pays = ""
        if (document.getElementsByName("client_pays")) {
            client_pays = document.getElementsByName("client_pays")[0].value;
        }
        form.append("adr_pays", client_pays);



        var client_telephone = ""
        if (document.getElementsByName("client_telephone")) {
            client_telephone = document.getElementsByName("client_telephone")[0].value;
        }
        form.append("telephone", client_telephone);


        var client_siret = ""
        if (document.getElementsByName("client_siret")) {
            client_siret = document.getElementsByName("client_siret")[0].value;
        }
        form.append("siret", client_siret);

        var client_tva = ""
        if (document.getElementsByName("client_tva")) {
            client_tva = document.getElementsByName("client_tva")[0].value;
        }
        form.append("tva", client_tva);

        var client_website = ""
        if (document.getElementsByName("client_website")) {
            client_website = document.getElementsByName("client_website")[0].value;
        }
        form.append("website", client_website);

        if (p_client_type_id)
            form.append("client_type_id", p_client_type_id);
        else
            form.append("client_type_id", "");

        if (String(p_client_is_financeur) === "true")
            form.append("is_financeur", "1");
        else
            form.append("is_financeur", "0");


        if (String(p_client_is_fournisseur) === "true")
            form.append("is_fournisseur", "1");
        else
            form.append("is_fournisseur", "0");


        if (String(p_client_is_client) === "true")
            form.append("is_client", "1");
        else
            form.append("is_client", "0");

        form.append("invoice_automatique", p_detail_facturation_automatique);
        form.append("type_financeur_id", p_client_type_financeur);

        form.append("type_pouvoir_public_id", p_client_type_pouvoir_public);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Client/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_New_PartnerClient  res.data.status = " + res.data.status);
                //console.log(" In Add_New_PartnerClient  res.data.message  = " + res.data.message);
                setAdd_New_PartnerClient_api("true");
                setAdd_New_PartnerClient_result(res.data.message);
                setformedit_mode("0");
                setdatamodification("0");
                Disable_fields();
                alert(res.data.message);
                props.Get_List_Partner_Clients();
                props.father_scroll_to_top();
                props.close_detail_client();


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setAdd_New_PartnerClient_api("false");
                setAdd_New_PartnerClient_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Add_New_PartnerClient = ', error);
            setAdd_New_PartnerClient_result("false");
            setAdd_New_PartnerClient_message(" Impossible de recuperer les informations du client")
            alert(" Impossible de recuperer les informations du client");

        })
    }

    const [recordPartnerClient_api, setrecordPartnerClient_api] = useState("");
    const [recordPartnerClient_result, setrecordPartnerClient_result] = useState("");
    const [recordPartnerClient_message, setrecordPartnerClient_message] = useState("");
    function recordPartnerClient(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("_id", props.client_id);

        // Recupeation des données à enregister

        var client_email = ""
        if (document.getElementsByName("client_email")) {
            client_email = String(document.getElementsByName("client_email")[0].value).trim();
        }
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!client_email.match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }

        form.append("email", client_email);


        var client_nom = ""
        if (document.getElementsByName("client_nom")) {
            client_nom = document.getElementsByName("client_nom")[0].value;
        }
        if (String(client_nom).trim() === "") {
            alert("Le nom du client est invalide.");
            return;
        }

        form.append("nom", client_nom);

        var client_raison_sociale = ""
        if (document.getElementsByName("client_raison_sociale")) {
            client_raison_sociale = document.getElementsByName("client_raison_sociale")[0].value;
        }
        form.append("raison_sociale", client_raison_sociale);


        form.append("is_company", p_client_is_company);


        var client_adresse = ""
        if (document.getElementsByName("client_adresse")) {
            client_adresse = document.getElementsByName("client_adresse")[0].value;
        }
        form.append("adr_adresse", client_adresse);

        var client_code_postal = ""
        if (document.getElementsByName("client_code_postal")) {
            client_code_postal = document.getElementsByName("client_code_postal")[0].value;
        }
        form.append("adr_code_postal", client_code_postal);

        var client_ville = ""
        if (document.getElementsByName("client_ville")) {
            client_ville = document.getElementsByName("client_ville")[0].value;
        }
        form.append("adr_ville", client_ville);

        var client_pays = ""
        if (document.getElementsByName("client_pays")) {
            client_pays = document.getElementsByName("client_pays")[0].value;
        }
        form.append("adr_pays", client_pays);



        var client_telephone = ""
        if (document.getElementsByName("client_telephone")) {
            client_telephone = document.getElementsByName("client_telephone")[0].value;
        }
        form.append("telephone", client_telephone);


        var client_siret = ""
        if (document.getElementsByName("client_siret")) {
            client_siret = document.getElementsByName("client_siret")[0].value;
        }
        form.append("siret", client_siret);




        var client_tva = ""
        if (document.getElementsByName("client_tva")) {
            client_tva = document.getElementsByName("client_tva")[0].value;
        }
        form.append("tva", client_tva);

        var client_website = ""
        if (document.getElementsByName("client_website")) {
            client_website = document.getElementsByName("client_website")[0].value;
        }
        form.append("website", client_website);

        if (p_client_type_id)
            form.append("client_type_id", p_client_type_id);
        else
            form.append("client_type_id", "");


        if (String(p_client_is_financeur) === "true")
            form.append("is_financeur", "1");
        else
            form.append("is_financeur", "0");


        if (String(p_client_is_fournisseur) === "true")
            form.append("is_fournisseur", "1");
        else
            form.append("is_fournisseur", "0");


        if (String(p_client_is_client) === "true")
            form.append("is_client", "1");
        else
            form.append("is_client", "0");


        form.append("type_financeur_id", p_client_type_financeur);

        form.append("type_pouvoir_public_id", p_client_type_pouvoir_public);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Client/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient  res.data.message  = " + res.data.message);
                setrecordPartnerClient_api("true");
                setrecordPartnerClient_result(res.data.message);
                setformedit_mode("0");
                setdatamodification("0");
                fillfield();
                Disable_fields();
                alert(res.data.message);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecordPartnerClient_api("false");
                setrecordPartnerClient_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient = ', error);
            setrecordPartnerClient_result("false");
            setrecordPartnerClient_message(" Impossible de recuperer les informations du client")
            alert(" Impossible de recuperer les informations du client");

        })
    }


    // Fonction de mise à jour des données de facturation
    const [recordPartnerClient_Invoice_Data_api, setrecordPartnerClient_Invoice_Data_api] = useState("");
    const [recordPartnerClient_Invoice_Data_result, setrecordPartnerClient_Invoice_Data_result] = useState("");
    const [recordPartnerClient_Invoice_Data_message, setrecordPartnerClient_Invoice_Data_message] = useState("");
    function recordPartnerClient_Invoice_Data(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        // Recupeation des données à enregister
        form.append("email", p_client_email);
        form.append("nom", p_client_nom);

        // Données de facturation 
        var client_invoice_email = p_client_invoice_email

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (String(client_invoice_email) === "" || !client_invoice_email.match(validRegex)) {
            alert("L'adresse email de facturation est invalide.");
            return;
        }


        if (String(p_client_invoice_paiement_condition_id).trim() === "") {
            alert(" Vous devez choisir une condition de paiement");
            return;
        }


        form.append("invoice_email", client_invoice_email);

        var client_invoice_nom = p_client_invoice_nom;

        form.append("invoice_nom", client_invoice_nom);

        var client_invoice_siret = p_client_invoice_siret;


        form.append("invoice_siret", client_invoice_siret);

        var client_invoice_tva = p_client_invoice_tva;


        form.append("invoice_tva", client_invoice_tva);

        form.append("invoice_condition_paiement_id", p_client_invoice_paiement_condition_id);



        var client_invoice_adresse = p_client_invoice_adresse;


        form.append("invoice_adresse", client_invoice_adresse);

        var client_invoice_ville = p_client_invoice_ville;

        form.append("invoice_ville", client_invoice_ville);

        var client_invoice_code_postal = p_client_invoice_code_postal;


        form.append("invoice_code_postal", client_invoice_code_postal);


        var client_invoice_pays = p_client_invoice_pays;


        form.append("invoice_pays", client_invoice_pays);



        form.append("invoice_automatique", p_detail_facturation_automatique);

        form.append("_id", props.client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Client/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient_Invoice_Data  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient_Invoice_Data  res.data.message  = " + res.data.message);
                setrecordPartnerClient_Invoice_Data_api("true");
                setrecordPartnerClient_Invoice_Data_result(res.data.message);
                setformedit_mode_invoice("0");
                setdatamodification_invoice("0");
                alert(" Les données de facturation ont été mises à jour.")
                Disable_invoice_fields();
                sethas_client_invoice_email("");
                alert(res.data.message);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecordPartnerClient_Invoice_Data_api("false");
                setrecordPartnerClient_Invoice_Data_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient_Invoice_Data = ', error);
            setrecordPartnerClient_Invoice_Data_result("false");
            setrecordPartnerClient_Invoice_Data_message(" Impossible de recuperer les informations du client")
            alert(" Impossible de recuperer les informations du client");

        })
    }

    function clear_invoice_fields() {
        if (document.getElementsByName("client_invoice_email")) {
            document.getElementsByName("client_invoice_email")[0].value = "";
        }

        if (document.getElementsByName("client_invoice_nom")) {
            document.getElementsByName("client_invoice_nom")[0].value = "";
        }

        if (document.getElementsByName("client_invoice_siret")) {
            document.getElementsByName("client_invoice_siret")[0].value = "";
        }

        if (document.getElementsByName("client_invoice_tva")) {
            document.getElementsByName("client_invoice_tva")[0].value = "";
        }

        if (document.getElementsByName("client_invoice_adresse")) {
            document.getElementsByName("client_invoice_adresse")[0].value = "";
        }


        if (document.getElementsByName("client_invoice_paiement_condition")) {
            document.getElementsByName("client_invoice_paiement_condition")[0].value = "";
        }


        if (document.getElementsByName("client_invoice_ville")) {
            document.getElementsByName("client_invoice_ville")[0].value = "";
        }

        if (document.getElementsByName("client_invoice_code_postal")) {
            document.getElementsByName("client_invoice_code_postal")[0].value = "";
        }

        if (document.getElementsByName("client_invoice_pays")) {
            document.getElementsByName("client_invoice_pays")[0].value = "";
        }

    }

    function Disable_invoice_fields() {
        if (document.getElementsByName("client_invoice_email")) {
            document.getElementsByName("client_invoice_email")[0].disabled = true;
            document.getElementsByName("client_invoice_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_nom")) {
            document.getElementsByName("client_invoice_nom")[0].disabled = true;
            document.getElementsByName("client_invoice_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_siret")) {
            document.getElementsByName("client_invoice_siret")[0].disabled = true;
            document.getElementsByName("client_invoice_siret")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_tva")) {
            document.getElementsByName("client_invoice_tva")[0].disabled = true;
            document.getElementsByName("client_invoice_tva")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_paiement_condition")) {
            document.getElementsByName("client_invoice_paiement_condition")[0].disabled = true;
            document.getElementsByName("client_invoice_paiement_condition")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_adresse")) {
            document.getElementsByName("client_invoice_adresse")[0].disabled = true;
            document.getElementsByName("client_invoice_adresse")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("client_invoice_ville")) {
            document.getElementsByName("client_invoice_ville")[0].disabled = true;
            document.getElementsByName("client_invoice_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_code_postal")) {
            document.getElementsByName("client_invoice_code_postal")[0].disabled = true;
            document.getElementsByName("client_invoice_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("client_invoice_pays")) {
            document.getElementsByName("client_invoice_pays")[0].disabled = true;
            document.getElementsByName("client_invoice_pays")[0].style.backgroundColor = "#ECEFF1";
            //console.log("iiiiii");
        }

        if (document.getElementsByName("facturation_automatique")) {
            document.getElementsByName("facturation_automatique")[0].disabled = true;
            document.getElementsByName("facturation_automatique")[0].style.backgroundColor = "#ECEFF1";

        }


    }

    function Enable_invoice_fields() {
        if (document.getElementsByName("client_invoice_email")) {
            document.getElementsByName("client_invoice_email")[0].disabled = false;
            document.getElementsByName("client_invoice_email")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_nom")) {
            document.getElementsByName("client_invoice_nom")[0].disabled = false;
            document.getElementsByName("client_invoice_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_siret")) {
            document.getElementsByName("client_invoice_siret")[0].disabled = false;
            document.getElementsByName("client_invoice_siret")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_tva")) {
            document.getElementsByName("client_invoice_tva")[0].disabled = false;
            document.getElementsByName("client_invoice_tva")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_paiement_condition")) {
            document.getElementsByName("client_invoice_paiement_condition")[0].disabled = false;
            document.getElementsByName("client_invoice_paiement_condition")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_adresse")) {
            document.getElementsByName("client_invoice_adresse")[0].disabled = false;
            document.getElementsByName("client_invoice_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_ville")) {
            document.getElementsByName("client_invoice_ville")[0].disabled = false;
            document.getElementsByName("client_invoice_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_code_postal")) {
            document.getElementsByName("client_invoice_code_postal")[0].disabled = false;
            document.getElementsByName("client_invoice_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("client_invoice_pays")) {
            document.getElementsByName("client_invoice_pays")[0].disabled = false;
            document.getElementsByName("client_invoice_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("facturation_automatique")) {
            document.getElementsByName("facturation_automatique")[0].disabled = false;
            document.getElementsByName("facturation_automatique")[0].style.backgroundColor = "#FFFFFF";
        }
    }

    function close_detail_client() {
        props.close_detail_client();
    }

    function retour_liste_contact() {
        setaddcontact("0");
        setdatamodification_contact('0');
    }


    function clean_contact_set_values() {
        setp_contact_email("");
        setp_contact_nom("");
        setp_contact_prenom("");
        setcontact_siret("");
        setcontact_tva("");
        setcontact_adresse("");
        setcontact_ville("");
        setcontact_code_postal("");
        setcontact_pays("");

        setcontact_telephone("");
        setp_contact_telephone_mobile("");

        setp_contact_fonction("");
        setp_contact_civilite("");

    }

    function clear_contact_fields() {
        if (document.getElementsByName("contact_email")) {
            document.getElementsByName("contact_email")[0].value = "";
        }

        if (document.getElementsByName("contact_nom")) {
            document.getElementsByName("contact_nom")[0].value = "";
        }

        if (document.getElementsByName("contact_prenom")) {
            document.getElementsByName("contact_prenom")[0].value = "";
        }

        if (document.getElementsByName("contact_siret")) {
            document.getElementsByName("contact_siret")[0].value = "";
        }

        if (document.getElementsByName("contact_tva")) {
            document.getElementsByName("contact_tva")[0].value = "";
        }

        if (document.getElementsByName("contact_adresse")) {
            document.getElementsByName("contact_adresse")[0].value = "";
        }

        if (document.getElementsByName("contact_ville")) {
            document.getElementsByName("contact_ville")[0].value = "";
        }

        if (document.getElementsByName("contact_code_postal")) {
            document.getElementsByName("contact_code_postal")[0].value = "";
        }

        if (document.getElementsByName("contact_pays")) {
            document.getElementsByName("contact_pays")[0].value = "";
        }

        if (document.getElementsByName("contact_telephone")) {
            document.getElementsByName("contact_telephone")[0].value = "";
        }

        if (document.getElementsByName("contact_telephone_mobile")) {
            document.getElementsByName("contact_telephone_mobile")[0].value = "";
        }

    }

    // Fonction de mise à jour des données de facturation
    const [recordPartnerClient_Contact_Data_api, setrecordPartnerClient_Contact_Data_api] = useState("");
    const [recordPartnerClient_Contact_Data_result, setrecordPartnerClient_Contact_Data_result] = useState("");
    const [recordPartnerClient_Contact_Data_message, setrecordPartnerClient_Contact_Data_message] = useState("");
    function recordPartnerClient_Contact_Data(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        // Recupeation des données à enregister

        var myurl = "";
        if (current_contact_recid && String(current_contact_recid).length > 3) {
            // Il s'agit d'un mise à jour. on a une valeur dans le current_activite_recid
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Contact/";
            form.append("_id", selected_contact_id);

        } else {
            // Il s'agit d'un ajout d'une nouvelle activité. on a rien dans le current_activite_recid
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Contact/";
            form.append("related_collection", "partner_client");
            form.append("related_collection_owner_id", props.client_id);
        }


        //console.log(" ### current_contact_recid = ", current_contact_recid, " myurl = ", myurl)

        // Données de contact du client du partner 
        var contact_email = ""
        if (document.getElementsByName("contact_email")) {
            contact_email = String(document.getElementsByName("contact_email")[0].value).trim();
        }

        // Pour activier la fontion de "include com", le contact foit avoir une adresse email
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!contact_email.match(validRegex)) {
            alert("L'adresse email est invalide. Pour créer un contact l'adresse email est obligatoire");
            return;
        }

        form.append("email", contact_email);

        var contact_nom = ""
        if (document.getElementsByName("contact_nom")) {
            contact_nom = String(document.getElementsByName("contact_nom")[0].value).trim();
        }
        form.append("nom", contact_nom);
        if (contact_nom.length < 2) {
            alert("Le nom du contact est invalide ");
            return;
        }


        var contact_prenom = ""
        if (document.getElementsByName("contact_prenom")) {
            contact_prenom = document.getElementsByName("contact_prenom")[0].value;
        }
        form.append("prenom", contact_prenom);


        var contact_fonction = ""
        if (document.getElementsByName("contact_fonction")) {
            contact_fonction = document.getElementsByName("contact_fonction")[0].value;
        }
        form.append("fonction", contact_fonction);

        var contact_civilite = ""
        if (p_contact_civilite) {
            contact_civilite = p_contact_civilite;
        }
        form.append("civilite", contact_civilite);



        var contact_siret = ""
        if (document.getElementsByName("contact_siret")) {
            contact_siret = document.getElementsByName("contact_siret")[0].value;
        }
        form.append("siret", contact_siret);

        var contact_tva = ""
        if (document.getElementsByName("contact_tva")) {
            contact_tva = document.getElementsByName("contact_tva")[0].value;
        }
        form.append("tva", contact_tva);

        var contact_adresse = ""
        if (document.getElementsByName("contact_adresse")) {
            contact_adresse = document.getElementsByName("contact_adresse")[0].value;
        }
        form.append("adr_adresse", contact_adresse);

        var contact_ville = ""
        if (document.getElementsByName("contact_ville")) {
            contact_ville = document.getElementsByName("contact_ville")[0].value;
        }
        form.append("adr_ville", contact_ville);


        var contact_code_postal = ""
        if (document.getElementsByName("contact_code_postal")) {
            contact_code_postal = document.getElementsByName("contact_code_postal")[0].value;
        }
        form.append("adr_code_postal", contact_code_postal);


        var contact_pays = ""
        if (document.getElementsByName("contact_pays")) {
            contact_pays = document.getElementsByName("contact_pays")[0].value;
        }
        form.append("adr_pays", contact_pays);

        var include_com = "0"
        if (is_contact_include_com) {
            include_com = "1";
            setclient_has_communication_contact("");
        }
        form.append("include_com", include_com);


        var contact_telephone = ""
        if (document.getElementsByName("contact_telephone")) {
            contact_telephone = document.getElementsByName("contact_telephone")[0].value;
        }
        form.append("telephone", contact_telephone);


        var contact_telephone_mobile = ""
        if (document.getElementsByName("contact_telephone_mobile")) {
            contact_telephone_mobile = document.getElementsByName("contact_telephone_mobile")[0].value;
        }
        form.append("telephone_mobile", contact_telephone_mobile);


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient_Contact_Data  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient_Contact_Data  res.data.message  = " + res.data.message);
                setrecordPartnerClient_Contact_Data_api("true");
                setrecordPartnerClient_Contact_Data_result(res.data.message);
                Get_List_Contact_Of_client_Part();

                setaddcontact("0");
                setdatamodification_contact("");
                setis_contact_include_com(false)
                alert(res.data.message);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecordPartnerClient_Contact_Data_api("false");
                setrecordPartnerClient_Contact_Data_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient_Contact_Data = ', error);
            setrecordPartnerClient_Contact_Data_result("false");
            setrecordPartnerClient_Contact_Data_message(" Impossible d'enregistrer les doonées du contact")
            alert(" Impossible d'enregistrer les doonées du contact");

        })
    }


    const [Display_Part_Client_Contact_api, setDisplay_Part_Client_Contact_api] = useState("");
    const [Display_Part_Client_Contact_result, setDisplay_Part_Client_Contact_result] = useState("");
    const [Display_Part_Client_Contact_message, setDisplay_Part_Client_Contact_message] = useState("");
    function Display_Part_Client_Contact(event) {


        var form = new FormData();

        var contactmail = event.currentTarget.getAttribute('contactmail');

        var contact_id = event.currentTarget.getAttribute('contact_id');

        setelected_contact_id(contact_id);

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", contact_id);

        // Recupeation des données à enregister
        form.append("related_collection", "partner_client");
        form.append("related_collection_owner_id", props.client_id);

        //console.log(" ### contactmail = ", contactmail);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Contact/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Display_Part_Client_Contact  res.data.status = " + res.data.status);
                //console.log(" In Display_Part_Client_Contact  res.data.message  = " + res.data.message);
                setDisplay_Part_Client_Contact_api("true");
                setDisplay_Part_Client_Contact_result(res.data.message);
                Get_List_Contact_Of_client_Part();

                setaddcontact("1");
                var mylocalclient_contact = JSON.parse(res.data.message);


                if (mylocalclient_contact) {

                    // Remplissage des doonées du contact
                    if (mylocalclient_contact.nom)
                        setp_contact_nom(mylocalclient_contact.nom);
                    else
                        setp_contact_nom("");

                    if (mylocalclient_contact.email)
                        setp_contact_email(mylocalclient_contact.email);
                    else
                        setp_contact_email("");

                    if (mylocalclient_contact.prenom)
                        setp_contact_prenom(mylocalclient_contact.prenom);
                    else
                        setp_contact_prenom("");


                    setis_contact_include_com(false);
                    if (mylocalclient_contact.include_com) {

                        if (String(mylocalclient_contact.include_com) === "1")
                            setis_contact_include_com(true);
                    }



                    if (mylocalclient_contact.siret)
                        setcontact_siret(mylocalclient_contact.siret);
                    else
                        setcontact_siret("");


                    if (mylocalclient_contact.tva)
                        setcontact_tva(mylocalclient_contact.tva);
                    else
                        setcontact_tva("");


                    if (mylocalclient_contact.adr_adresse)
                        setcontact_adresse(mylocalclient_contact.adr_adresse);
                    else
                        setcontact_adresse("");

                    if (mylocalclient_contact.adr_ville)
                        setcontact_ville(mylocalclient_contact.adr_ville);
                    else
                        setcontact_ville("");

                    if (mylocalclient_contact.adr_code_postal)
                        setcontact_code_postal(mylocalclient_contact.adr_code_postal);
                    else
                        setcontact_code_postal("");

                    if (mylocalclient_contact.adr_pays)
                        setcontact_pays(mylocalclient_contact.adr_pays);
                    else
                        setcontact_pays("");

                    if (mylocalclient_contact.telephone)
                        setcontact_telephone(mylocalclient_contact.telephone);
                    else
                        setcontact_telephone("");

                    if (mylocalclient_contact.telephone_mobile)
                        setp_contact_telephone_mobile(mylocalclient_contact.telephone_mobile);
                    else
                        setp_contact_telephone_mobile("");


                    if (mylocalclient_contact.fonction)
                        setp_contact_fonction(mylocalclient_contact.fonction);
                    else
                        setp_contact_fonction("");

                    if (mylocalclient_contact.civilite)
                        setp_contact_civilite(mylocalclient_contact.civilite);
                    else
                        setp_contact_civilite("");




                    if (mylocalclient_contact.recid)
                        setcurrent_contact_recid(mylocalclient_contact.recid);
                    else
                        setcurrent_contact_recid("");

                }


                Disable_contact_fields();
                setformedit_mode_contact("0");

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setDisplay_Part_Client_Contact_api("false");
                setDisplay_Part_Client_Contact_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Display_Part_Client_Contact = ', error);
            setDisplay_Part_Client_Contact_result("false");
            setDisplay_Part_Client_Contact_message(" Impossible de recuperer les detail du contact")
            alert(" Impossible de recuperer les details du contact");

        })
    }


    const [Delete_Part_Client_Contact_api, setDelete_Part_Client_Contact_api] = useState("");
    const [Delete_Part_Client_Contact_result, setDelete_Part_Client_Contact_result] = useState("");
    const [Delete_Part_Client_Contact_message, setDelete_Part_Client_Contact_message] = useState("");
    function Delete_Part_Client_Contact(event) {


        var form = new FormData();

        var contactmail = event.currentTarget.getAttribute('contactmail');

        var contact_id = event.currentTarget.getAttribute('contact_id');

        setelected_contact_id(contact_id);

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", contact_id);

        form.append("related_collection", "partner_client");
        form.append("related_collection_owner_id", props.client_id);

        //console.log(" ### contactmail = ", contactmail);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Contact/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Delete_Part_Client_Contact  res.data.status = " + res.data.status);
                //console.log(" In Delete_Part_Client_Contact  res.data.message  = " + res.data.message);
                setDelete_Part_Client_Contact_api("true");
                setDelete_Part_Client_Contact_result(res.data.message);
                Get_List_Contact_Of_client_Part();



            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setDelete_Part_Client_Contact_api("false");
                setDelete_Part_Client_Contact_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Delete_Part_Client_Contact = ', error);
            setDelete_Part_Client_Contact_result("false");
            setDelete_Part_Client_Contact_message(" Impossible de recuperer les detail du contact")
            alert(" Impossible de supprimer le contact");

        })
    }
    const [is_contact_include_com, setis_contact_include_com] = useState(false);

    const New_civilite = [
        { "id": "mme", "label": "Mme", "value": "mme" },
        { "id": "m", "label": "M", "value": "m" },
        { "id": "neutre", "label": "Neutre", "value": "neutre" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_Facturation_Automatique = [
        { "id": "mois", "label": "Mensuelle", "value": "mois" },
        { "id": "0", "label": "Facturation Manuelle", "value": "0" },

        { "id": "", "label": "", "value": "" },
    ]

    const [p_detail_facturation_automatique, setp_detail_facturation_automatique] = useState("");

    const [p_client_is_financeur, setp_client_is_financeur] = useState(false);
    const [p_client_is_fournisseur, setp_client_is_fournisseur] = useState(false);
    const [p_client_is_client, setp_client_is_client] = useState(true);

    const [isLoading, setLoading] = useState();

    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();
    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;
        setLoading(true);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const editorRef_competence = useRef(null);
    const [editorRef_competence_limite, seteditorRef_competence_limite] = useState(800);


    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };


    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_2_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_SessionFormation_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setsessions_file_change_2_result(result['message']);
                    setsessions_file_change_2_api("true");
                    //GetCurrentClass_trainingsession();

                }
                else {
                    setsessions_file_change_2_message(result['message']);
                    setsessions_file_change_2_api("false");
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setsessions_file_change_2_api("false");
            });
    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();
    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {


            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "partner_client");
            formData.append("object_owner_id", props.client_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);


            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(props.client_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();
    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(props.client_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();
    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "partner_client");
        formData.append("object_owner_id", local_employee_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes

    const [New_Get_List_Type_Financeur_result, setNew_Get_List_Type_Financeur_result] = useState([]);

    const [Get_List_Type_Financeur_api, setGet_List_Type_Financeur_api] = useState();
    const [Get_List_Type_Financeur_message, setGet_List_Type_Financeur_message] = useState();
    const [Get_List_Type_Financeur_result, setGet_List_Type_Financeur_result] = useState();
    function Get_List_Type_Financeur() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Type_Organisme_Financement/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_List_Type_Financeur  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Type_Financeur  res.data.message r_class = " + res.data.message);
                setGet_List_Type_Financeur_api("true");
                setGet_List_Type_Financeur_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---

                    var _id = JSON.parse(x)._id;
                    var code = JSON.parse(x).code;
                    var description = JSON.parse(x).description;


                    //---
                    var node = {
                        "_id": _id,
                        "code": code,
                        "description": description,
                        "label": code,
                    };
                    //console.log(" --- node = ", node);
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "code": "",
                    "description": "",
                    "label": "",
                };
                //console.log(" --- node = ", node);
                new_data2.push(node);
                if (new_data2.length > 0)
                    setNew_Get_List_Type_Financeur_result(new_data2);
            }
            else {
                setGet_List_Type_Financeur_api("false");
                setGet_List_Type_Financeur_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Type_Financeur = ', error);
            setGet_List_Type_Financeur_api("false");
            alert(" Impossible de recuperer Get_List_Type_Financeur");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Get_List_Type_Pouvoir_Public_result, setNew_Get_List_Type_Pouvoir_Public_result] = useState([]);

    const [Get_List_Type_Pouvoir_Public_api, setGet_List_Type_Pouvoir_Public_api] = useState();
    const [Get_List_Type_Pouvoir_Public_message, setGet_List_Type_Pouvoir_Public_message] = useState();
    const [Get_List_Type_Pouvoir_Public_result, setGet_List_Type_Pouvoir_Public_result] = useState();
    function Get_List_Type_Pouvoir_Public() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Type_Pouvoir_Public/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_List_Type_Pouvoir_Public  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Type_Pouvoir_Public  res.data.message r_class = " + res.data.message);
                setGet_List_Type_Pouvoir_Public_api("true");
                setGet_List_Type_Pouvoir_Public_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---

                    var _id = JSON.parse(x)._id;
                    var code = JSON.parse(x).code;
                    var description = JSON.parse(x).description;


                    //---
                    var node = {
                        "_id": _id,
                        "code": code,
                        "description": description,
                        "label": code + " - " + description,
                    };
                    //console.log(" --- node = ", node);
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "code": "",
                    "description": "",
                    "label": "",
                };
                //console.log(" --- node = ", node);
                new_data2.push(node);
                if (new_data2.length > 0)
                    setNew_Get_List_Type_Pouvoir_Public_result(new_data2);
            }
            else {
                setGet_List_Type_Pouvoir_Public_api("false");
                setGet_List_Type_Pouvoir_Public_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Type_Pouvoir_Public = ', error);
            setGet_List_Type_Pouvoir_Public_api("false");
            alert(" Impossible de recuperer Get_List_Type_Pouvoir_Public");
            //setmyApimyApiMessage("")
        })
    }

    return (
        <div className="add_partner_client">

            {String(props.new_customer) === "1" && <div className="div_row" style={{ "paddingRight": "5px" }}>
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_donnee_client} className="detail_submenu client_menu" id='submenu_donnee_client' name='submenu_donnee_client'>Données Principales</Button>
                </div>

                <div className="session_caract" >
                    &nbsp; <br /><br />
                    <nav style={{ "paddingLeft": "10px" }}>
                        <Radio
                            checked={p_client_is_company === '1'}
                            onChange={(e) => {
                                DataEditMode();
                                setp_client_is_company('1');
                            }}
                            value="1"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            sx={{
                                color: '#FF1493',
                                '&.Mui-checked': {
                                    color: '#FF1493',
                                },
                            }}

                        />&nbsp;Société &nbsp;

                        <Radio
                            checked={p_client_is_company === '0'}
                            onChange={(e) => {
                                DataEditMode();
                                setp_client_is_company('0');
                            }}
                            value="0"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'B' }}

                        /> &nbsp; Particulier
                        <Radio
                            checked={p_client_is_company === '2'}
                            onChange={(e) => {
                                DataEditMode();
                                setp_client_is_company('2');
                            }}
                            value="2"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'C' }}

                        /> &nbsp; P. Public
                    </nav>
                </div>

                <div className="session_caract">  Raison sociale <br />
                    <TextField
                        required
                        name="client_raison_sociale"
                        //label="Raison sociale"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_raison_sociale}
                        onChange={(e) => setclient_raison_sociale(e.target.value)}
                    />
                </div>
                <div className="session_caract">  <b>Nom </b> <br />
                    <TextField
                        required
                        name="client_nom"
                        //label="Nom"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_nom}
                        onChange={(e) => setclient_nom(e.target.value)}

                    />
                </div>




                <div className="session_caract"> Type client <br />

                    <Autocomplete
                        disablePortal
                        name="client_type_id"
                        id="client_type_id"
                        className="disabled_style"
                        options={New_Get_List_Client_Type_result}
                        onChange={(event, value) => {
                            if (value && value._id) {
                                setp_client_type_id(value._id);

                            } else {
                                setp_client_type_id("");
                            }
                        }}
                        //value={p_one_detail_client_rattachement_nom}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />


                </div>

                {p_client_is_financeur === true && <div className="session_caract"> Type Financeur <br />

                    <Autocomplete
                        disablePortal
                        name="client_type_financeur_id"
                        id="client_type_financeur_id"
                        className="disabled_style"
                        options={New_Get_List_Type_Financeur_result}

                        onChange={(event, value) => {
                            if (value && value._id) {
                                setp_client_type_financeur(value._id);

                            } else {

                                setp_client_type_financeur("");

                            }
                        }}
                        //value={p_one_detail_client_rattachement_nom}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />


                </div>}

                {p_client_is_company && String(p_client_is_company) === "2" && <div className="session_caract"> Type P. Public <br />

                    <Autocomplete
                        disablePortal
                        name="client_type_pouvoir_public_id"
                        id="client_type_pouvoir_public_id"
                        className="disabled_style"
                        options={New_Get_List_Type_Pouvoir_Public_result}

                        onChange={(event, value) => {
                            if (value && value._id) {
                                setp_client_type_pouvoir_public(value._id);

                            } else {

                                setp_client_type_pouvoir_public("");

                            }
                        }}
                        //value={p_one_detail_client_rattachement_nom}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />


                </div>}


                <div className="session_caract">  N° Siret <br />
                    <TextField
                        required
                        name="client_siret"
                        //label="N° Siret"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_siret}
                        onChange={(e) => setclient_siret(e.target.value)}
                    />
                </div>

                <div className="session_caract">  N° TVA  <br />
                    <TextField
                        required
                        name="client_tva"
                        //label="N° TVA"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_tva}
                        onChange={(e) => setclient_tva(e.target.value)}
                    />
                </div>

                <div className="session_caract">
                    <b>Email</b> <br />
                    <TextField
                        required
                        name="client_email"
                        //label="Email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_email}
                        onChange={(e) => setclient_email(e.target.value)}

                    />
                </div>
                <div className="session_caract">  Adresse <br />
                    <TextField
                        required
                        name="client_adresse"
                        //label="Adresse"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_adresse}
                        onChange={(e) => setclient_adresse(e.target.value)}
                    />
                </div>
                <div className="session_caract"> ville <br />
                    <TextField
                        required
                        name="client_ville"
                        //label="ville"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_ville}
                        onChange={(e) => setclient_ville(e.target.value)}
                    />
                </div>
                <div className="session_caract">  Code Postal <br />
                    <TextField
                        required
                        name="client_code_postal"
                        //label="Code Postal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_code_postal}
                        onChange={(e) => setclient_code_postal(e.target.value)}
                    />
                </div>
                <div className="session_caract">  Pays <br />
                    <TextField
                        required
                        name="client_pays"
                        //label="Pays"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_pays}
                        onChange={(e) => setclient_pays(e.target.value)}
                    />
                </div>

                <div className="session_caract">  Téléphone <br />
                    <TextField
                        required
                        name="client_telephone"
                        //label="Telephone"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_client_telephone}
                        onChange={(e) => setclient_telephone(e.target.value)}
                    />
                </div>

                <div className="session_caract">  Site web <br />
                    <TextField
                        required
                        name="client_website"
                        //label="Site web"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_client_website}
                        onChange={(e) => setclient_website(e.target.value)}
                    />
                </div>


                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>
                <div className="session_caract" >
                    <Tooltip className="tooltip_css" id="tooltip_financeur" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_financeur" data-tooltip-html="Si ce type s'applique aux organismes finaceurs, cliquez 'OUI' ">
                        <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                            onChange={(e) => {

                                DataEditMode();
                                if (p_client_is_financeur === true)
                                    setp_client_is_financeur(false)
                                else {

                                    setp_client_is_financeur(true);
                                }

                            }}
                            id="toggleSwitchNav_1" name="toggleSwitchNav_1">
                            <ToggleSwitch
                                label="Est il un financeur ?" id="toggleSwitch_1" name="toggleSwitch_1" checked={p_client_is_financeur} style={{ height: "2rem" }} />
                        </nav>
                    </a>


                </div>

                <div className="session_caract" >
                    <Tooltip className="tooltip_css" id="tooltip_fsseur" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_fsseur" data-tooltip-html="Si ce type s'applique aux fournisseurs, cliquez 'OUI' ">
                        <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                            onChange={(e) => {
                                DataEditMode();
                                if (p_client_is_fournisseur === true)
                                    setp_client_is_fournisseur(false)
                                else {

                                    setp_client_is_fournisseur(true);
                                }

                            }}
                            id="toggleSwitchNav_2" name="toggleSwitchNav_2">
                            <ToggleSwitch
                                label="Est il un fournisseur ?" id="toggleSwitch_2" name="toggleSwitch_2" checked={p_client_is_fournisseur} style={{ height: "2rem" }} />
                        </nav>

                    </a>



                </div>


                <div className="session_caract" >

                    <Tooltip className="tooltip_css" id="tooltip_is_client" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_is_client" data-tooltip-html="Si ce type s'applique aux clients, cliquez 'OUI' ">
                        <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                            onChange={(e) => {
                                DataEditMode();
                                if (p_client_is_client === true)
                                    setp_client_is_client(false)
                                else {

                                    setp_client_is_client(true);
                                }

                            }}
                            id="toggleSwitchNav_3" name="toggleSwitchNav_3">
                            <ToggleSwitch
                                label="Est il un client ?" id="toggleSwitch_3" name="toggleSwitch_3" checked={p_client_is_client} style={{ height: "2rem" }} />
                        </nav>

                    </a>
                </div>


                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>
                <div className="div_row">
                    <div className="div_row_gauche">
                        <Popup
                            trigger={
                                <Button variant="contained" color="success" className="bton_enreg " >Enregistrer  &nbsp; <AiTwotoneSave /> </Button>

                            }
                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        Confirmez la création du nouveau partenaire <br />

                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                Add_New_PartnerClient();
                                                //console.log('modal closed ');
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    //console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>


                    </div>
                    <div className="div_row_droite" style={{ "textAlign": "right" }}>
                        <Button variant="contained" color="success" className="bton_annule" onClick={close_detail_client}>Fermer</Button>
                    </div>
                </div>
            </div>
            }

            {String(props.new_customer) !== "1" && <div className="div_row">
                <div className="div_row">

                    <Button variant="outlined" onClick={submenu_donnee_client} className="detail_submenu client_menu" id='submenu_donnee_client' name='submenu_donnee_client'>Données Principales</Button>
                    <Button variant="outlined" onClick={submenu_donnee_facturation} className="detail_submenu client_menu" id='submenu_donnee_facturation' name='submenu_donnee_facturation'>Données Facturation &nbsp;
                        {has_client_invoice_email && String(has_client_invoice_email) === "0" && <nav>
                            <Tooltip className="tooltip_css" id="warn_invoice_data" style={{ "fontSize": "10px" }} />
                            <a data-tooltip-id="warn_invoice_data" data-tooltip-html="Les données de facturation sont incompletes ">
                                <WarningAmberIcon style={{ color: 'red', fontSize: "12px" }} />
                            </a>
                        </nav>}

                    </Button>
                    <Button variant="outlined" onClick={submenu_contact} className="detail_submenu client_menu" id='submenu_contact' name='submenu_contact'>Contact & Adresses &nbsp;
                        {client_has_communication_contact && String(client_has_communication_contact) === "0" && <nav>
                            <Tooltip className="tooltip_css" id="warn_com_contact" style={{ "fontSize": "10px" }} />
                            <a data-tooltip-id="warn_com_contact" data-tooltip-html="Aucun contact de communication ">
                                <WarningAmberIcon style={{ color: 'red', fontSize: "12px" }} />
                            </a>
                        </nav>}


                    </Button>
                    <Button variant="outlined" onClick={submenu_activite} className="detail_submenu client_menu" id='submenu_activite' name='submenu_activite'>Activité</Button>
                    <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_submenu client_menu" id='submenu_piece_jointe' name='submenu_piece_jointe'>Pièces Jointes</Button>
                   
                    <Button variant="outlined" className="detail_submenu client_menu" id='submenu_factures' name='submenu_factures'
                        onClick={(event) => {
                            inactive_active_menu_header("submenu_factures");
                            if (props.client_id &&
                                String(props.client_id).length > 2) {
                                window.open(
                                    process.env.REACT_APP_FRONT_URL + "Partner/factures_client/" + String(props.client_id),
                                    '_blank'
                                );
                            }
                        }} > Factures &nbsp;
                        <FcOpenedFolder style={{ fontSize: "10px" }} />
                    </Button>

                    <Button variant="outlined" className="detail_submenu client_menu" id='submenu_devis' name='submenu_devis'
                        onClick={(event) => {
                            inactive_active_menu_header("submenu_devis");
                            if (props.client_id &&
                                String(props.client_id).length > 2) {
                                window.open(
                                    process.env.REACT_APP_FRONT_URL + "Partner/mes_cmd/" + String(props.client_id),
                                    '_blank'
                                );
                            }
                        }} > Devis & Cmd &nbsp;
                        <FcOpenedFolder style={{ fontSize: "10px" }} />
                    </Button>


                </div>


                {String(submenu).trim() === "submenu_donnee_client" && <div className="training_data" onChange={DataUpdated}>

                    <div className="session_caract" >
                        &nbsp; <br /><br />
                        <nav style={{ "paddingLeft": "10px" }}>
                            <Radio
                                checked={p_client_is_company === '1'}
                                onChange={(e) => {
                                    DataEditMode();
                                    setp_client_is_company('1');
                                }}
                                value="1"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                                sx={{
                                    color: '#FF1493',
                                    '&.Mui-checked': {
                                        color: '#FF1493',
                                    },
                                }}

                            />&nbsp;Société &nbsp;


                            <Radio
                                checked={p_client_is_company === '0'}
                                onChange={(e) => {
                                    DataEditMode();
                                    setp_client_is_company('0');
                                }}
                                value="0"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'B' }}

                            /> &nbsp; Particulier

                            <Radio
                                checked={p_client_is_company === '2'}
                                onChange={(e) => {
                                    DataEditMode();
                                    setp_client_is_company('2');
                                }}
                                value="2"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'C' }}

                            /> &nbsp; P. Public
                        </nav>
                    </div>

                    <div className="session_caract"> Raison sociale <br />
                        <TextField
                            required
                            name="client_raison_sociale"
                            //label="Raison sociale"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_raison_sociale}
                            onChange={(e) => setclient_raison_sociale(e.target.value)}
                        />
                    </div>


                    <div className="session_caract"><b> Nom </b><br />
                        <TextField
                            required
                            name="client_nom"
                            //label="Nom"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_nom}
                            onChange={(e) => setclient_nom(e.target.value)}

                        />
                    </div>



                    {String(formedit_mode) !== "1" && <div className="session_caract"> Type client<br />
                        <TextField
                            required
                            name="client_type_id"
                            id="client_type_id"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_type_label}
                        />
                    </div>}

                    {String(formedit_mode) !== "1" && p_client_is_financeur === true &&
                        New_Get_List_Type_Financeur_result && New_Get_List_Type_Financeur_result.length > 1 && <div className="session_caract"> Type Financeur<br />
                            <TextField
                                required
                                name="client_type_financeur_id"
                                id="client_type_financeur_id"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={New_Get_List_Type_Financeur_result.filter((data) => (data)._id === String(p_client_type_financeur))[0].label}
                            />
                        </div>}

                    {String(formedit_mode) !== "1" && p_client_is_company && String(p_client_is_company) === "2" &&
                        New_Get_List_Type_Pouvoir_Public_result && New_Get_List_Type_Pouvoir_Public_result.length > 1 && <div className="session_caract"> Type P. Public <br />

                            <TextField
                                required
                                name="client_type_pouvoir_public_id"
                                id="client_type_pouvoir_public_id"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={New_Get_List_Type_Pouvoir_Public_result.filter((data) => (data)._id === String(p_client_type_pouvoir_public))[0].code}
                            />
                        </div>}


                    {New_Get_List_Client_Type_result && New_Get_List_Client_Type_result.length > 1 &&
                        String(formedit_mode) === "1" && <div className="session_caract"> Type client <br />
                            <Autocomplete
                                disablePortal
                                name="client_type_id"
                                id="client_type_id"
                                className="disabled_style"
                                options={New_Get_List_Client_Type_result}
                                value={New_Get_List_Client_Type_result.filter((data) => (data)._id === String(p_client_type_id))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_client_type_id(value._id);

                                        DataUpdated();

                                    }
                                }}


                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}


                    {p_client_is_financeur === true && New_Get_List_Type_Financeur_result && New_Get_List_Type_Financeur_result.length > 1 &&
                        String(formedit_mode) === "1" && <div className="session_caract"> Type Financeur <br />
                            <Autocomplete
                                disablePortal
                                name="client_type_financeur_id"
                                id="client_type_financeur_id"
                                className="disabled_style"
                                options={New_Get_List_Type_Financeur_result}
                                value={New_Get_List_Type_Financeur_result.filter((data) => (data)._id === String(p_client_type_financeur))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_client_type_financeur(value._id);

                                        DataUpdated();

                                    }
                                }}


                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}

                    {p_client_is_company && String(p_client_is_company) === "2" && New_Get_List_Type_Pouvoir_Public_result && New_Get_List_Type_Pouvoir_Public_result.length > 1 &&
                        String(formedit_mode) === "1" && <div className="session_caract"> Type P. Public <br />
                            <Autocomplete
                                disablePortal
                                name="client_type_pouvoir_public_id"
                                id="client_type_pouvoir_public_id"
                                className="disabled_style"
                                options={New_Get_List_Type_Pouvoir_Public_result}
                                value={New_Get_List_Type_Pouvoir_Public_result.filter((data) => (data)._id === String(p_client_type_pouvoir_public))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_client_type_pouvoir_public(value._id);

                                        DataUpdated();

                                    }
                                }}


                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}


                    <div className="session_caract"> N° Siret<br />
                        <TextField
                            required
                            name="client_siret"
                            //label="N° Siret"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_siret}
                            onChange={(e) => setclient_siret(e.target.value)}
                        />
                    </div>

                    <div className="session_caract"> N° TVA<br />
                        <TextField
                            required
                            name="client_tva"
                            //label="N° TVA"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_tva}
                            onChange={(e) => setclient_tva(e.target.value)}
                        />
                    </div>

                    <div className="session_caract"> <b> Email </b> <br />
                        <TextField
                            required
                            name="client_email"
                            //label="Email"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_email}
                            onChange={(e) => setclient_email(e.target.value)}

                        />
                    </div>

                    <div className="session_caract"> Adresse<br />
                        <TextField
                            required
                            name="client_adresse"
                            //label="Adresse"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_adresse}
                            onChange={(e) => setclient_adresse(e.target.value)}
                        />
                    </div>
                    <div className="session_caract"> Ville<br />
                        <TextField
                            required
                            name="client_ville"
                            //label="Ville"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_ville}
                            onChange={(e) => setclient_ville(e.target.value)}
                        />
                    </div>
                    <div className="session_caract"> Code Postal<br />
                        <TextField
                            required
                            name="client_code_postal"
                            //label="Code Postal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_code_postal}
                            onChange={(e) => setclient_code_postal(e.target.value)}
                        />
                    </div>
                    <div className="session_caract"> Pays<br />
                        <TextField
                            required
                            name="client_pays"
                            //label="Pays"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_pays}
                            onChange={(e) => setclient_pays(e.target.value)}
                        />
                    </div>

                    <div className="session_caract"> Telephone<br />
                        <TextField
                            required
                            name="client_telephone"
                            //label="Telephone"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_telephone}
                            onChange={(e) => setclient_telephone(e.target.value)}
                        />
                    </div>

                    <div className="session_caract"> Site web <br />
                        <TextField
                            required
                            name="client_website"
                            //label="Site web"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_website}
                            onChange={(e) => setclient_website(e.target.value)}
                        />
                    </div>



                    <div className="session_caract" >
                        <Tooltip className="tooltip_css" id="tooltip_financeur" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="tooltip_financeur" data-tooltip-html="Si ce type s'applique aux organismes finaceurs, cliquez 'OUI' ">
                            <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                                onChange={(e) => {

                                    DataEditMode();
                                    if (p_client_is_financeur === true)
                                        setp_client_is_financeur(false)
                                    else {

                                        setp_client_is_financeur(true);
                                    }

                                }}
                                id="toggleSwitchNav_1" name="toggleSwitchNav_1">
                                <ToggleSwitch
                                    label="Est il un financeur ?" id="toggleSwitch_1" name="toggleSwitch_1" checked={p_client_is_financeur} style={{ height: "2rem" }} />
                            </nav>
                        </a>


                    </div>

                    <div className="session_caract" >
                        <Tooltip className="tooltip_css" id="tooltip_fsseur" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="tooltip_fsseur" data-tooltip-html="Si ce type s'applique aux fournisseurs, cliquez 'OUI' ">
                            <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                                onChange={(e) => {
                                    DataEditMode();
                                    if (p_client_is_fournisseur === true)
                                        setp_client_is_fournisseur(false)
                                    else {

                                        setp_client_is_fournisseur(true);
                                    }

                                }}
                                id="toggleSwitchNav_2" name="toggleSwitchNav_2">
                                <ToggleSwitch
                                    label="Est il un fournisseur ?" id="toggleSwitch_2" name="toggleSwitch_2" checked={p_client_is_fournisseur} style={{ height: "2rem" }} />
                            </nav>

                        </a>



                    </div>


                    <div className="session_caract" >

                        <Tooltip className="tooltip_css" id="tooltip_is_client" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="tooltip_is_client" data-tooltip-html="Si ce type s'applique aux clients, cliquez 'OUI' ">
                            <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                                onChange={(e) => {
                                    DataEditMode();
                                    if (p_client_is_client === true)
                                        setp_client_is_client(false)
                                    else {

                                        setp_client_is_client(true);
                                    }

                                }}
                                id="toggleSwitchNav_3" name="toggleSwitchNav_3">
                                <ToggleSwitch
                                    label="Est il un client ?" id="toggleSwitch_3" name="toggleSwitch_3" checked={p_client_is_client} style={{ height: "2rem" }} />
                            </nav>

                        </a>
                    </div>


                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    {String(datamodification) === "1" && <div className="div_row">
                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}


                    {String(formedit_mode) === "1" && <div className="div_row">
                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={recordPartnerClient}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_annule" onClick={AnnulerEdit}>Annuler</Button>
                        </div>
                    </div>
                    }


                    {String(formedit_mode) !== "1" && <div className="div_row">

                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_edit" onClick={DataEditMode}> Editer </Button>
                        </div>
                    </div>
                    }

                </div>}

                {String(submenu).trim() === "submenu_donnees_facturation" && <div className="training_data" onChange={DataUpdated_Invoice_Data}>

                    <div className="session_caract"><b> Email de Facturation </b> <br />
                        <TextField
                            required
                            name="client_invoice_email"
                            //label="Email de Facturation"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_email}
                            onChange={(e) => setclient_invoice_email(e.target.value)}
                        />
                    </div>


                    <div className="session_caract"> Nom de Facturation<br />
                        <TextField
                            required
                            name="client_invoice_nom"
                            //label="Nom de Facturation"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_nom}
                            onChange={(e) => setclient_invoice_nom(e.target.value)}
                        />
                    </div>



                    <div className="session_caract"> N° Siret Facturation<br />
                        <TextField
                            required
                            name="client_invoice_siret"
                            //label="N° Siret Facturation"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_siret}
                            onChange={(e) => setclient_invoice_siret(e.target.value)}
                        />
                    </div>

                    <div className="session_caract"> N° TVA Facturation<br />
                        <TextField
                            required
                            name="client_invoice_tva"
                            // label="N° TVA Facturation"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_tva}
                            onChange={(e) => setclient_invoice_tva(e.target.value)}
                        />
                    </div>

                    <div className="session_caract"> <b> Conditions Paiement </b><br />
                        {String(formedit_mode_invoice) !== "1" && <TextField
                            required
                            name="client_invoice_paiement_condition"
                            //label="Adresse"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_paiement_condition_code}
                            onChange={(e) => setp_client_invoice_paiement_condition_code(e.target.value)}
                        />}

                        {String(formedit_mode_invoice) === "1" && New_Getall_Partner_Paiement_Condition_result && New_Getall_Partner_Paiement_Condition_result.length > 0 &&
                            <Autocomplete
                                disablePortal
                                name="client_invoice_paiement_condition"
                                id="client_invoice_paiement_condition"
                                value={New_Getall_Partner_Paiement_Condition_result.filter((data) => (data).label === String(p_client_invoice_paiement_condition_code))[0].label}
                                options={New_Getall_Partner_Paiement_Condition_result}
                                className="disabled_style"
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_client_invoice_paiement_condition_code(value.code);
                                        setp_client_invoice_paiement_condition_id(value._id);
                                        DataUpdated_Invoice_Data();
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}
                    </div>

                    <div className="session_caract"> Adresse<br />
                        <TextField
                            required
                            name="client_invoice_adresse"
                            //label="Adresse"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_adresse}
                            onChange={(e) => setclient_invoice_adresse(e.target.value)}
                        />
                    </div>


                    <div className="session_caract"> Ville<br />
                        <TextField
                            required
                            name="client_invoice_ville"
                            //label="Ville"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_ville}
                            onChange={(e) => setclient_invoice_ville(e.target.value)}
                        />
                    </div>
                    <div className="session_caract"> Code Postal<br />
                        <TextField
                            required
                            name="client_invoice_code_postal"
                            //label="Code Postal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_code_postal}
                            onChange={(e) => setclient_invoice_code_postal(e.target.value)}
                        />
                    </div>
                    <div className="session_caract"> Pays<br />
                        <TextField
                            required
                            name="client_invoice_pays"
                            //label="Pays"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_client_invoice_pays}

                            onChange={(e) => {
                                setclient_invoice_pays(e.target.value);
                                DataUpdated_Invoice_Data();
                            }}
                        />
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        <hr className="hr_break" />
                    </div>

                    <div className="session_caract"> Facturation automatique<br />

                        {New_Option_Facturation_Automatique && String(formedit_mode_invoice) !== "1" && <TextField
                            required
                            name="facturation_automatique"
                            //label="Adresse"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={New_Option_Facturation_Automatique.filter((data) => (data).value === String(p_detail_facturation_automatique))[0].label}

                        />}

                        {String(formedit_mode_invoice) === "1" && New_Option_Facturation_Automatique && <Autocomplete
                            disablePortal
                            name="facturation_automatique"
                            id="facturation_automatique"
                            className="disabled_style enable_style"
                            value={New_Option_Facturation_Automatique.filter((data) => (data).value === String(p_detail_facturation_automatique))[0].label}
                            options={New_Option_Facturation_Automatique}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_facturation_automatique(value.value);
                                    DataUpdated_Invoice_Data();
                                } else {
                                    setp_detail_facturation_automatique("");
                                    DataUpdated_Invoice_Data();
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    {String(datamodification_invoice) === "1" && <div className="div_row">
                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}
                    {String(formedit_mode_invoice) === "1" && <div className="div_row">
                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={recordPartnerClient_Invoice_Data}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_annule" onClick={AnnulerEdit_Invoice_Data}>Annuler</Button>
                        </div>
                    </div>
                    }
                    {String(formedit_mode_invoice) !== "1" && <div className="div_row">

                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_edit" onClick={DataEditMode_Invoice_Data}> Modifier</Button>
                        </div>
                    </div>
                    }

                </div>}

                {String(submenu).trim() === "submenu_contact" && <div className="training_data">

                    {String(addcontact) !== "1" && <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px", "paddingRight": "10px" }}>
                        <div className="div_row" onClick={Add_Contact} style={{ "cursor": "pointer" }}> <AiOutlineUserAdd /> Ajouter un contact  </div>
                        <div className="div_row scroll_mysy" style={{ "height": "30rem", }}>
                            <div className="contact_grid">

                                {Get_List_Contact_Of_client_Part_result && String(Get_List_Contact_Of_client_Part_api) === "true" &&
                                    Get_List_Contact_Of_client_Part_result.map((client) => (

                                        <div >
                                            {(JSON.parse(client).nom || JSON.parse(client).prenom) &&
                                                <nav style={{ "width": "100%", "float": "left" }}>
                                                    <nav className="grid_title" style={{ "width": "69%", "float": "left", "cursor": "pointer" }} contactmail={String(JSON.parse(client).email)} contact_id={String(JSON.parse(client)._id)} onClick={Display_Part_Client_Contact}>
                                                        {String(JSON.parse(client).prenom)} &nbsp; {String(JSON.parse(client).nom)}

                                                    </nav>
                                                    {String(JSON.parse(client).include_com) === "1" && <nav style={{ "width": "10%", "float": "left" }}>
                                                        <Tooltip className="tooltip_css" id="tooltip_speacker" />
                                                        <a data-tooltip-id="tooltip_speacker" data-tooltip-html="Contact utilisé dans les échanges de mails">
                                                            <FcSpeaker style={{ "border": "1px solid", "cursor": "pointer" }} /> &nbsp; &nbsp;
                                                        </a>
                                                    </nav>}

                                                    {String(JSON.parse(client).include_com) !== "1" && <nav style={{ "width": "10%", "float": "left" }}>
                                                        &nbsp;
                                                    </nav>}



                                                    <nav style={{ "width": "10%", "float": "left" }}>
                                                        <Tooltip className="tooltip_css" id="tooltip_edit" />
                                                        <a data-tooltip-id="tooltip_edit" data-tooltip-html="Editer le contact">

                                                            <CiEdit style={{ "border": "1px solid", "cursor": "pointer" }} contactmail={String(JSON.parse(client).email)} contact_id={String(JSON.parse(client)._id)} onClick={Display_Part_Client_Contact} /> &nbsp; &nbsp;
                                                        </a>
                                                    </nav>


                                                    <Popup
                                                        trigger={
                                                            <nav style={{ "width": "10%", "float": "right", "cursor": "pointer" }}>
                                                                <Tooltip className="tooltip_css" id="tooltip_delete" />
                                                                <a data-tooltip-id="tooltip_delete" data-tooltip-html="Suppimer le contact">
                                                                    <CiTrash style={{ "color": "red", "border": "1px solid" }} />
                                                                </a>
                                                            </nav>


                                                        }
                                                        modal
                                                        nested
                                                        position="center center"
                                                    >
                                                        {close => (
                                                            <div>
                                                                <button className="gest_close" onClick={close}>
                                                                    &times;
                                                                </button>
                                                                <div className="gest_header"> MySy Information </div>
                                                                <div className="gest_content">
                                                                    {' '}

                                                                    En confirmant cette opération, le contact sera  <i><font color="red"> définitivement supprimé</font></i>. <br />


                                                                </div>
                                                                <div className="gest_actions">
                                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                                        <button className="gest_bton_popup" onClick={(event) => {

                                                                            Delete_Part_Client_Contact(event);
                                                                            close();
                                                                        }}
                                                                            contactmail={String(JSON.parse(client).email)} contact_id={String(JSON.parse(client)._id)}

                                                                        > Valider </button>

                                                                    </div>
                                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                                        <button
                                                                            className="gest_bton_popup"
                                                                            onClick={() => {
                                                                                //console.log('modal closed ');
                                                                                close();
                                                                            }}
                                                                        >
                                                                            Annuler
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>


                                                </nav>


                                            }

                                            <div className="div_row" style={{ "border": "None", "marginTop": "2px", "marginBottom": "2px" }}>
                                                <hr className="hr_break" style={{ "marginTop": "2px", "marginBottom": "2px" }} />
                                            </div>
                                            <nav style={{ "fontSize": '12px', fontWeight: "600", letterSpacing: "2px", color: "#616161", "marginTop": "1rem" }} >

                                                {JSON.parse(client).fonction && <nav> {String(JSON.parse(client).fonction)} </nav>}
                                                {JSON.parse(client).email && <nav> {String(JSON.parse(client).email)} </nav>}
                                                {JSON.parse(client).telephone_mobile && <nav> {String(JSON.parse(client).telephone_mobile)} </nav>}
                                            </nav>

                                        </div>


                                    ))}
                            </div>
                        </div>
                    </div>}

                    {String(addcontact) === "1" && <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }} onChange={DataUpdated_Contact_Data}>

                        {String(formedit_mode_contact) === "1" && <div className="session_caract">
                            Civilité <br />

                            <Autocomplete
                                disablePortal
                                name="contact_civilite"
                                id="contact_civilite"
                                className="disabled_style enable_style"
                                value={New_civilite.filter((data) => (data).value === String(p_contact_civilite))[0].label}
                                options={New_civilite}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_contact_civilite(value.value);
                                        setdatamodification_contact("1");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                            {/*<TextField
                                required
                                name="contact_civilite"
                                //label="Civilité"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                select
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_civilite}
                                onChange={(e) => setp_contact_civilite(e.target.value)}

                            >
                                <MenuItem value="Mme" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mme. &nbsp;</MenuItem>
                                <MenuItem value="M." style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >M.  &nbsp;</MenuItem>
                                <MenuItem value="Neutre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Neutre&nbsp;&nbsp;</MenuItem>


                            </TextField>*/}
                        </div>}

                        {String(formedit_mode_contact) !== "1" && <div className="session_caract">
                            Civilité <br />
                            <TextField
                                required
                                name="contact_civilite"
                                //label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                disabled={false}
                                className="disabled_style"
                                value={p_contact_civilite}


                            />

                        </div>}


                        <div className="session_caract">
                            <b>Nom  </b> <br />
                            <TextField
                                required
                                name="contact_nom"
                                //label="Nom"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_nom}
                                onChange={(e) => setp_contact_nom(e.target.value)}

                            />
                        </div>


                        <div className="session_caract">
                            Prénom <br />
                            <TextField
                                required
                                name="contact_prenom"
                                //label="Prenom"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_prenom}
                                onChange={(e) => setp_contact_prenom(e.target.value)}
                            />
                        </div>
                        <div className="session_caract"> <b> Email </b> <br />
                            <TextField
                                required
                                name="contact_email"
                                //label="Email"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_email}
                                onChange={(e) => setp_contact_email(e.target.value)}

                            />
                        </div>


                        <div className="session_caract">
                            Fonction <br />
                            <TextField
                                required
                                name="contact_fonction"
                                //label="Fonction"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_fonction}
                                onChange={(e) => setp_contact_fonction(e.target.value)}

                            />
                        </div>




                        <div className="session_caract">
                            Téléphone Fixe <br />
                            <TextField
                                required
                                name="contact_telephone"
                                //label="Telephone"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_telephone}
                                onChange={(e) => setcontact_telephone(e.target.value)}
                            />
                        </div>

                        <div className="session_caract">
                            Téléphone Mobile <br />
                            <TextField
                                required
                                name="contact_telephone_mobile"
                                // label="Téléphone Mobile"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_telephone_mobile}
                                onChange={(e) => setp_contact_telephone_mobile(e.target.value)}
                            />
                        </div>


                        <div className="session_caract">
                            Adresse <br />
                            <TextField
                                required
                                name="contact_adresse"
                                //label="Adresse"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_adresse}
                                onChange={(e) => setcontact_adresse(e.target.value)}
                            />
                        </div>
                        <div className="session_caract">
                            ville <br />
                            <TextField
                                required
                                name="contact_ville"
                                //label="ville"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_ville}
                                onChange={(e) => setcontact_ville(e.target.value)}
                            />
                        </div>
                        <div className="session_caract">
                            Code Postal <br />
                            <TextField
                                required
                                name="contact_code_postal"
                                //label="Code Postal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_code_postal}
                                onChange={(e) => setcontact_code_postal(e.target.value)}
                            />
                        </div>
                        <div className="session_caract">
                            Pays <br />
                            <TextField
                                required
                                name="contact_pays"
                                //label="Pays"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_contact_pays}
                                onChange={(e) => setcontact_pays(e.target.value)}
                            />
                        </div>

                        <div className="session_caract">
                            <Tooltip className="tooltip_css" id="tooltip_siret" />
                            <a data-tooltip-id="tooltip_siret" data-tooltip-html="Pour les contacts de type entreprise ">
                                N° Siret <br />
                                <TextField
                                    required
                                    name="contact_siret"
                                    //label="N° Siret"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_contact_siret}
                                    onChange={(e) => setcontact_siret(e.target.value)}
                                />
                            </a>
                        </div>

                        <div className="session_caract">
                            <Tooltip className="tooltip_css" id="tooltip_tva" />
                            <a data-tooltip-id="tooltip_tva" data-tooltip-html="Pour les contacts de type entreprise ">
                                N° TVA <br />
                                <TextField
                                    required
                                    name="contact_tva"
                                    //label="N° TVA"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_contact_tva}
                                    onChange={(e) => setcontact_tva(e.target.value)}
                                />
                            </a>
                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                        </div>
                        <Tooltip className="tooltip_css" id="tooltip_com" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="tooltip_com" data-tooltip-html="Si oui, ce contact recevra tous les emails échangés">
                            <div className="session_caract" style={{ "float": "right", "fontSize": "12px", 'fontWeight': 'bolder', }}>

                                <nav onChange={(e) => {
                                    DataUpdated_Contact_Data();
                                    setformedit_mode_contact("1");
                                    //console.log("is_contact_include_com == ", is_contact_include_com);
                                    if (is_contact_include_com === true)
                                        setis_contact_include_com(false)
                                    else {
                                        // Pour activier la fontion de "include com", le contact foit avoir une adresse email
                                        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                        if (!String(p_contact_email).trim().match(validRegex)) {
                                            alert("L'adresse email est invalide. Pour activer cette fontion, vous devez saisir une adresse email valide");
                                            return;
                                        }

                                        setis_contact_include_com(true);
                                    }

                                }}
                                    id="toggleSwitchNav" name="toggleSwitchNav">
                                    <ToggleSwitch
                                        label="Inclure / Exclure de la communication ? " id="toggleSwitch" name="toggleSwitch" checked={is_contact_include_com} style={{ height: "2rem" }} />
                                </nav>

                            </div>
                        </a>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>


                        {String(datamodification_contact) === "1" && <div className="div_row">
                            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}

                        {String(formedit_mode_contact) === "1" && <div className="div_row">
                            <div className="div_row_gauche">
                                <Button variant="contained" color="success" className="bton_enreg " onClick={recordPartnerClient_Contact_Data}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                            </div>
                            <div className="div_row_droite" style={{ "textAlign": "right" }}>
                                <Button variant="contained" color="success" className="bton_annule" onClick={Close_Contact}>Annuler</Button>
                            </div>
                        </div>
                        }
                        {String(formedit_mode_contact) !== "1" && <div className="div_row">

                            <div className="div_row_gauche">
                                <Button variant="contained" color="success" className="bton_enreg " onClick={retour_liste_contact}>Liste Contacts &nbsp; <GrOrderedList /> </Button>
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": "right" }}>
                                <Button variant="contained" color="success" className="bton_edit" onClick={DataEditMode_Contact_Data}> Editer</Button>
                            </div>
                        </div>
                        }

                    </div>}

                </div>}


                {String(submenu).trim() === "submenu_activite" && <div className="training_data">
                    <PartnerClientActivite email={p_client_email} conntected_employee_id={props.conntected_employee_id}  
                    related_collection={"partner_client"} />
                </div>}

                {String(submenu).trim() === "submenu_piece_jointe" && <div className="training_data">


                    <div className="div_row">
                        <div className="div_row" style={{ "padding": "5px" }}>

                            <div style={{ "fontSize": "12px" }}>
                                <label htmlFor="upload-photo">
                                    <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={sessions_file_change_1}
                                    />

                                    <Fab
                                        color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"
                                    >
                                        <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                    </Fab>

                                </label>
                            </div>
                            {file_1_name && file_1_name.name &&
                                <nav style={{ "fontSize": "12px" }}>
                                    <div className="session_caract">Nom du fichier <br />
                                        <TextField
                                            name="file_to_download_type_name"
                                            id="file_to_download_type_name"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_detail_one_file_to_download_type_name}
                                            onChange={(e) => {
                                                setp_detail_one_file_to_download_type_name(e.target.value);

                                            }}
                                        />

                                    </div>
                                    <br />
                                    <br />
                                    Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                        onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                                </nav>}

                            {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                            {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                        </div>



                        {file_1_name && file_1_name.name && <div className="div_row">

                            <div className="div_row_gauche">
                                <Button variant="contained" onClick={Record_All_PJ}
                                    startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                            </div>



                        </div>}

                        {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                            <div className="div_row">
                                <b> Liste des pièces jointes </b> <br />
                                <div className="div_row">
                                    {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                        <div className="div_row_list_pj"  >
                                            <nav name={(JSON.parse(val).file_name)}
                                                id={(JSON.parse(val).file_name)}> <i> <AttachFileIcon />{(JSON.parse(val).file_business_object)} </i> <br /><font style={{ "color": "green", "cursor": "pointer" }}
                                                    name={(JSON.parse(val).file_name)}
                                                    id={(JSON.parse(val).file_name)} onClick={Download_one_attached_document}>  Télécharger &nbsp;</font></nav>

                                            <Popup
                                                trigger={
                                                    <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                                }
                                                modal
                                                nested
                                                position="center center"
                                            >
                                                {close => (
                                                    <div>
                                                        <button className="gest_close" onClick={close}>
                                                            &times;
                                                        </button>
                                                        <div className="gest_header"> MySy Information </div>
                                                        <div className="gest_content">
                                                            {' '}

                                                            En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                        </div>
                                                        <div className="gest_actions">
                                                            <div style={{ "width": "45%", "float": "left" }}>
                                                                <button className="gest_bton_popup" onClick={(event) => {

                                                                    Delete_one_attached_document(event);
                                                                    close();
                                                                }}
                                                                    name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                                > Valider </button>

                                                            </div>
                                                            <div style={{ "width": "45%", "float": "right" }}>
                                                                <button
                                                                    className="gest_bton_popup"
                                                                    onClick={() => {
                                                                        //console.log('modal closed ');
                                                                        close();
                                                                    }}
                                                                >
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>}


                <div className="div_row" style={{
                    "border": "None", "marginLeft": "auto", "marginLeft": "auto", "marginRight": "auto", "textAlign": "center",
                    "marginTop": "1rem", "cursor": "pointer", "marginBottom": "1rem", "marginLeft": "auto", "marginRight": "auto"
                }} onClick={close_detail_client}>
                    <IoCloseCircleOutline /> &nbsp; Fermer
                </div>
            </div>}
        </div>
    );
}

export default AddParnerClient;

