import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import { Button, } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { confirmAlert } from 'react-confirm-alert';
import topimg from "../mysy_img/top.png";
import coeurimg from "../mysy_img/coeur.png";
import img_met_program from "../mysy_img/met_programmation.jpg";
import img_met_hotel from "../mysy_img/met_hotel.jpg";
import img_met_graphisme from "../mysy_img/metier_graphisme.jpg";

import img_met_autre from "../mysy_img/met_autre.jpg";
import img_met_digital from "../mysy_img/met_digital.jpg";
import img_met_management from "../mysy_img/met_management.jpg";
import img_met_projet from "../mysy_img/met_projet.jpg";

import img_met_market from "../mysy_img/met_market.jpg";
import img_met_autre2 from "../mysy_img/met_autre2.jpg";
import img_met_dev_perso from "../mysy_img/metier_dev_perso.jpg";
import img_met_rh from "../mysy_img/met_rh.jpg";

import img_met_bureautic from "../mysy_img/met_burautic.jpg";
import img_met_vente from "../mysy_img/met_vente.jpg";
import axios from "axios";
//import { Rating } from 'react-simple-star-rating'
import Rating from '@mui/material/Rating';
import parse from 'html-react-parser'
import { CiLocationOn } from "react-icons/ci";
  
const Formation = (props) => {
  const [userconnected, setuserconnected] = useState("0");
  const [partnerconnected, setpartnerconnected] = useState("0");
  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);
  const history = useHistory();
  const [myfilter, setmyfilter] = useState(props.formation.price);

  const [profileimg, setprofileimg] = useState(props.formation.price);


  // Gestion des Cookies
  const stored_partner = cookie_part.tokenmysypart;
  const stored_user = cookie.tokenmysych;

  function test(val) {

    if (userconnected === "0" && partnerconnected === "0") {
      confirmAlert({
        title: 'Activer votre session ?',
        message: 'Merci de vous connecter pour acceder au detail de cette formation',
        buttons: [
          {
            label: 'Oui',
            onClick: () => { history.push("/mysy-training-login/training/" + props.formation.internal_url) }
          },
          {
            label: 'Non',
            onClick: () => { return }

          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
      });
    } else {
      window.open(
        "/Display-Detail-formation/" + props.formation.internal_url,
        '_blank'
      );
    }


  }

  const [myclassimage, setmyclassimage] = useState();
  const [isclassimage, setisclassimage] = useState("False");
  const [userimgclassprofil, setuserimgclassprofil] = useState("");

  const [class_img_stored_in_bdd, setclass_img_stored_in_bdd] = useState("");

  function getClassImage_no_token() {

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedClassImage_no_token/";



    var form = new FormData();

    form.append("type", "partner");
    form.append("related_collection", "myclass");
    form.append("related_collection_recid", props.formation._id);
    form.append("class_external_code", props.formation.external_code);


    axios.post(myurl, form).then(res => {

      if (res.data.status != "False") {

        //console.log(" getClassImage : In test  res.data.status = " + res.data.status);
        //console.log(" getClassImage: res.data.message.img = " + res.data.message);

        if (res.data.message.img.length > 0) {
          setisclassimage("True");
        }
        else {
          setisclassimage("False");
        }
        setisclassimage(res.data.message.img);
        var ch_img = "data:image/png;base64," + res.data.message.img;
        setclass_img_stored_in_bdd(ch_img);
      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setisclassimage("False");
        setuserimgclassprofil();
        setclass_img_stored_in_bdd();
      }
    }).catch((error) => {

      console.warn('getClassImage ee: Not good man :( getClassImage = ');
      setisclassimage("False");
      setuserimgclassprofil("");
      setclass_img_stored_in_bdd("");
    })

  }


  /* Cette variable accompagne la gestion des images par defaut.
  idée : si la valeur de "DetailTraining.metier" != "management, digital, office, rh, vente, dev_perso"
  alors l'utilisateur à créer une formation avec un metier qui n'est pas geré, donc on met une image par defaut
  */
  const [ismetiermanaged, setismetiermanaged] = useState();
  const liste_metier = ["management", "digital", "office", "rh", "vente", "dev_perso"];

  useEffect(() => {
    setmyfilter(props.myfil);

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
    } else {
      setuserconnected("1");
    }

    if (typeof (stored_partner) === "undefined" || String(stored_partner) === '') {
      setpartnerconnected("0");
    } else {
      setpartnerconnected("1");
    }

    getClassImage_no_token();
    GetCurrentClass_trainingsession();

    GetCurrentClass_trainingsession_cities();

    // Verifier si le metier est geré
    if (props.formation.metier) {
      if (!liste_metier.includes(props.formation.metier)) {
        setismetiermanaged("0"); // j'ai une valeur dans metier, mais qui n'est pas geré
      }
    }
    else {
      setismetiermanaged("0");
    }

    if (props.formation.img_url && String(props.formation.img_url).length > 0) {
      setismetiermanaged("1");
    }

  }, []);


  const [GetCurrentClass_api, setGetCurrentClass_api] = useState();
  const [GetCurrentClass_message, setGetCurrentClass_message] = useState();
  const [GetCurrentClass_result, setGetCurrentClass_result] = useState();
  function GetCurrentClass_trainingsession(event) {

    var form = new FormData();

    form.append("class_internal_url", props.formation.internal_url);

    form.append("token", stored_partner);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetActiveSessionFormation_List/";

    axios.post(myurl, form).then(res => {

      if (res.data.status != "false") {
        //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
        //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
        setGetCurrentClass_api("true");
        setGetCurrentClass_result(res.data.message);
      }
      else {
        setGetCurrentClass_api("false");
        setGetCurrentClass_message(res.data.message);
      }

    }).catch((error) => {
      console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
      setGetCurrentClass_api("false");
      //setmyApimyApiMessage("")
    })
  }


  const [GetCurrentClass_trainingsession_cities_api, setGetCurrentClass_trainingsession_cities_api] = useState();
  const [GetCurrentClass_trainingsession_cities_message, setGetCurrentClass_trainingsession_cities_message] = useState();
  const [GetCurrentClass_trainingsession_cities_result, setGetCurrentClass_trainingsession_cities_result] = useState([]);
  function GetCurrentClass_trainingsession_cities(event) {

    var form = new FormData();

    form.append("class_internal_url", props.formation.internal_url);

    form.append("token", stored_partner);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetActiveSession_Cities_And_Distance_Formation_List/";

    axios.post(myurl, form).then(res => {

      if (res.data.status != "false") {
        //console.log(" In GetCurrentClass_trainingsession_cities  res.data.status = " + res.data.status);
        //console.log(" In GetCurrentClass_trainingsession_cities  res.data.message r_class = " + res.data.message);
        setGetCurrentClass_trainingsession_cities_api("true");
        setGetCurrentClass_trainingsession_cities_result(res.data.message);
      }
      else {
        setGetCurrentClass_trainingsession_cities_api("false");
        setGetCurrentClass_trainingsession_cities_message(res.data.message);
      }

    }).catch((error) => {
      console.warn('Not good man :( GetCurrentClass_trainingsession_cities = ', error);
      setGetCurrentClass_trainingsession_cities_api("false");
      //setmyApimyApiMessage("")
    })
  }

  return (

    <div className="formation">

      <div style={{ "width": "100%" }}>
    
        {class_img_stored_in_bdd && String(class_img_stored_in_bdd).length > 50 && <div className="forma_img_gauche">
          <img src={class_img_stored_in_bdd} alt="informatique" className="training_img" />
        </div>
        }

        
        { (!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length <  50 ) && String(ismetiermanaged) !== "0" && String(ismetiermanaged) !== "1" && <div className="forma_img_gauche">

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("it") &&
            String(isclassimage) === "False" && <img src={img_met_program} alt="informatique" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("graphisme") &&
            String(isclassimage) === "False" && <img src={img_met_graphisme} alt="graphisme" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("management") &&
            String(isclassimage) === "False" && <img src={img_met_management} alt="management" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("digital") &&
            String(isclassimage) === "False" && <img src={img_met_digital} alt="digital" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("office") &&
            String(isclassimage) === "False" && <img src={img_met_bureautic} alt="office" className="training_img" />}

          {!props.formation.img_url || String(props.formation.img_url).length <= 0 &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("rh") &&
            String(isclassimage) === "False" && <img src={img_met_rh} alt="ressources humaines" className="training_img" />}

          {!props.formation.img_url || String(props.formation.img_url).length <= 0 &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("vente") &&
            String(isclassimage) === "False" && <img src={img_met_vente} alt="vente" className="training_img" />}

          {!props.formation.img_url || String(props.formation.img_url).length <= 0 &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("dev_perso") &&
            String(isclassimage) === "False" && <img src={img_met_dev_perso} alt="developpement personnel" className="training_img" />}

          {String(isclassimage) === "True" && <img src={userimgclassprofil} alt="developpement personnel" className="training_img" />}


        </div>
        }


        {(!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length <  50 ) &&  String(ismetiermanaged) === "0" && String(userimgclassprofil).length > 100 && <div className="forma_img_gauche">
          <img src={userimgclassprofil} alt="autre" className="training_img" />
          {props.formation.institut_formation && <div style={{ width: '100%', "textAlign": "center" }}> Dispensé par :<br /> {String(props.formation.institut_formation)}  </div>}
        </div>}

        {(!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length <  50 ) && String(ismetiermanaged) === "0" && String(userimgclassprofil).length <= 100 && <div className="forma_img_gauche">
          <img src={img_met_autre} alt="autre" className="training_img" />
          {props.formation.institut_formation && <div style={{ width: '100%', "textAlign": "center" }}> Dispensé par :<br /> {String(props.formation.institut_formation)}  </div>}
        </div>}

        {(!class_img_stored_in_bdd || String(class_img_stored_in_bdd).length <  50 ) && String(ismetiermanaged) === "1" && <div className="forma_img_gauche">
          <img src={props.formation.img_url} alt="autre" className="training_img" />
          {props.formation.institut_formation && <div style={{ width: '100%', "textAlign": "center" }}> Dispensé par : <br /> {String(props.formation.institut_formation)} </div>}
        </div>}

      
        <div className="forma_rightbox"
          onClick={(e) => {
            e.preventDefault();
            if (props.formation.url && props.formation.url.length > 5) {

              window.open(
                props.formation.url,
                '_blank'
              );
            } else {
              window.open(
                "/Display-Detail-formation/" + props.formation.internal_url,
                '_blank'
              );
            }
          }}>
          <div className="p_forma">
            <div className="div_row">

              <div className="div_row_gauche" style={{ "marginTop": "5px" }}>
                {props.formation.note && String(props.formation.note).length === 1 &&
                  parseInt(String(props.formation.note)) <= 5 &&

                  <Rating name="half-rating-read" defaultValue={props.formation.note} precision={0.5} readOnly />

                }

              </div>
              <div className="div_row_droite div_row_droite_cpf">
                <div className="block_cpf">
                  <div className="block_cpf_txt">


                    {props.formation.cpf && String(props.formation.cpf) === String("1") &&
                      <div>
                        OUI Éligible au CPF &nbsp;
                      </div>}

                    {props.formation.cpf && String(props.formation.cpf) !== String("1") &&
                      <div>
                        NON Éligible au CPF &nbsp;
                      </div>}

                    {!props.formation.cpf &&
                      <div>
                        NON Éligible au CPF  &nbsp;
                      </div>}

                  </div>
                </div>
              </div>
            </div>

            <div className="div_row row_formation_title">
              <p className="p_class training_title">
                <b>
                  {String(parse(String(props.formation.title).replace(/(<([^>]+)>)/ig, ''))).substring(0, 100)}
                </b>

              </p>
            </div>
            <div className="div_row">

              {String(props.formation.price).length == 9 &&
                <div className="training_price" > <i>Prix : Non communiqué</i> &nbsp;
                </div>
              }


              {String(props.formation.price).length != 9 && String(props.formation.price) === String("0") &&
                <div className="training_price">Gratuit  &nbsp;
                </div>
              }

              {String(props.formation.price).length != 9 && String(props.formation.price) !== String("0") && (!props.formation.business_prices ||
                Object.keys(props.formation.business_prices).length <= 0) &&
                <div className="training_price">{props.formation.price}  € HT&nbsp;

                </div>
              }

              {String(props.formation.price).length != 9 && String(props.formation.price) !== String("0") &&
                props.formation.business_prices && Object.keys(props.formation.business_prices).length > 0 &&
                <div className="training_price">
                  <nav className="discounted_price">{String(props.formation.business_prices[0].discounted_price)} € HT</nav>
                  <nav className="training_price_barre">
                    {props.formation.price}  € HT&nbsp;
                  </nav>

                  <nav className="discount_block">  (- {String(props.formation.business_prices[0].discount)} % )
                  </nav>
                </div>
              }

            </div>

            <div className="div_row row_formation_desc">
              {parse(String(props.formation.description).replace(/(<([^>]+)>)/ig, ''))}

            </div>
            {GetCurrentClass_trainingsession_cities_result && GetCurrentClass_trainingsession_cities_result.length > 0 &&
              <nav style={{ "width": "100%", "float": "left", "textAlign": "left", "color": "#619E31" }}>
                <nav style={{ "width": "auto", "float": "left" }}> <CiLocationOn className="icone_location" />&nbsp; </nav>

                {GetCurrentClass_trainingsession_cities_result && GetCurrentClass_trainingsession_cities_result.sort().slice(0, 5).map((formation_ville) => (
                  <nav style={{ "width": "auto", "float": "left" }}>



                    {formation_ville &&

                      <b>  {formation_ville.replaceAll('"', '').substring(0, 20).toLocaleLowerCase().charAt(0).toUpperCase() +
                        formation_ville.replaceAll('"', '').substring(0, 20).toLocaleLowerCase().slice(1)} &nbsp;&nbsp; </b>

                    }

                  </nav>
                ))}



              </nav>
            }
            {!GetCurrentClass_result || GetCurrentClass_result.length <= 0 &&
              <nav style={{ "width": "100%", "float": "left", "textAlign": "left", "fontWeight": "700" }}><CiLocationOn className="icone_location" />&nbsp; A venir </nav>
            }

          </div>
        </div>
        <div className="div_row row_formation_crit">

          <hr style={{ "width": "90%", "marginLeft": "auto", "marginRight": "auto", "marginBottom": "2px", "marginTop": "2px" }} />

          <div className="div_row_gauche div_row_gauche_critere" >
            <div className="div_row_critere" style={{ "marginTop": "0.5rem", "marginBottom": "0.3rem" }}>
              {!props.formation.extented_search &&
                <div className="div_row_gauche">


                  {props.formation.duration_unit &&
                    <div style={{ "width": "100%" }}>
                      <div className="criteres ftion_tab_gauche">Durée
                      </div>

                      {String(props.formation.duration_unit) === "heure" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} hr(s) &nbsp;
                        </div>}

                      {String(props.formation.duration_unit) === "heures" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} hr(s) &nbsp;
                        </div>}


                      {String(props.formation.duration_unit) === "jour" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} jr(s)  &nbsp;
                        </div>}

                      {String(props.formation.duration_unit) === "mois" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} mois(s)
                        </div>}

                      {String(props.formation.duration_unit) === "semaine" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} Sem (s)
                        </div>}

                      {String(props.formation.duration_unit) === "user_rythme" &&
                        <div className="criteres ftion_tab_droite">
                          A votre rythme
                        </div>}

                    </div>
                  }


                  {!props.formation.duration_unit &&
                    <div style={{ "width": "100%" }}>
                      <div className="criteres ftion_tab_gauche">Durée
                      </div>

                      {props.formation.duration && <div className="criteres ftion_tab_droite">
                        {props.formation.duration} jr(s) &nbsp;
                      </div>}

                      {!props.formation.duration && <div className="criteres ftion_tab_droite">
                        <i> En traitement &nbsp; </i>
                      </div>}


                    </div>

                  }
                  <div style={{ "width": "100%" }}>
                    <div className="criteres ftion_tab_gauche">
                      Certification
                    </div>

                    <div className="criteres ftion_tab_droite">
                      {props.formation.certif && String(props.formation.certif) === String("1") &&
                        <div>
                          Oui &nbsp;
                        </div>}

                      {props.formation.certif && String(props.formation.certif) !== String("1") &&
                        <div>
                          Non &nbsp;
                        </div>}

                      {!props.formation.certif &&
                        <div>
                          NC  &nbsp;
                        </div>}


                    </div>
                  </div>

                  <div className="media_sociaux" style={{ 'float': "left", marginTop: "0.2rem", }}>
                    <FacebookShareButton
                      url={"https://www.mysy-training.com/Display-Detail-formation/" + props.formation.internal_url}

                      quote={props.formation.title + " -- " + props.formation.description}
                      hashtag={"ySyTraining"}
                    >
                      <SocialIcon network="facebook" style={{ height: 20, width: 20 }} />
                    </FacebookShareButton>
                    &nbsp;

                    <TwitterShareButton
                      url={"https://www.mysy-training.com/Display-Detail-formation/" + props.formation.internal_url}
                      hashtag={"#MySy-Formation"}
                    >
                      <SocialIcon network="twitter" style={{ height: 20, width: 20 }} />
                    </TwitterShareButton>

                    &nbsp;
                    <LinkedinShareButton
                      url={"https://www.mysy-training.com/Display-Detail-formation/" + props.formation.internal_url}
                      title={" Une nouvelle formation : " + props.formation.title}
                      summary={props.formation.title + " -- " + props.formation.description}

                      source={"https://www.mysy-training.com/"}

                    >
                      <SocialIcon network="linkedin" style={{ height: 20, width: 20 }} />
                    </LinkedinShareButton>

                  </div>

               


                </div>}


              <div className="div_row_droite" style={{ textAlign: "center" }}>
                {String(props.formation.coeur) === String("1") && <div>
                  <img class="imgcoeur" src={coeurimg} alt="coup de coeur" />
                </div>}





                <div style={{ "width": "100%", "float": "right" }}>
                  <div className="criteres ftion_tab_gauche">
                    Sur site
                  </div>
                  {props.formation.presentiel && props.formation.presentiel.presentiel &&
                    String(props.formation.presentiel.presentiel) === String("1") && <div className="criteres ftion_tab_droite">
                      Oui
                    </div>}

                  {props.formation.presentiel && props.formation.presentiel.presentiel &&
                    String(props.formation.presentiel.presentiel) !== String("1") && <div className="criteres ftion_tab_droite">
                      Non
                    </div>}

                  {!props.formation.presentiel || !props.formation.presentiel.presentiel &&
                    <div className="criteres ftion_tab_droite">
                      NC
                    </div>}
                </div>

                <div style={{ "width": "100%", "float": "right" }}>
                  <div className="criteres ftion_tab_gauche">
                    A distance
                  </div>
                  {props.formation.presentiel && props.formation.presentiel.distantiel &&
                    String(props.formation.presentiel.distantiel) === String("1") && <div className="criteres ftion_tab_droite">
                      Oui
                    </div>}

                  {props.formation.presentiel && props.formation.presentiel.distantiel &&
                    String(props.formation.presentiel.distantiel) !== String("1") && <div className="criteres ftion_tab_droite">
                      Non
                    </div>}

                  {!props.formation.presentiel || !props.formation.presentiel.distantiel &&
                    <div className="criteres ftion_tab_droite">
                      NC
                    </div>}
                </div>

                <div style={{ "width": "100%", "float": "right" }}>
                  <div className="criteres ftion_tab_gauche">
                    Support
                  </div>
                  {props.formation.support &&
                    <div className="criteres ftion_tab_droite">
                      {props.formation.support}
                    </div>}

                  {!props.formation.support &&
                    <div className="criteres ftion_tab_droite">
                      NC
                    </div>}
                </div>

               

              </div>

            </div>
          </div>
          <div className="div_row_droite div_row_droite_critere" >
            {!props.formation.extented_search && props.formation.coeur != "1" &&
              <div style={{ 'float': "right", "width": "100%", textAlign: "right", marginTop: "0rem", }}>

                <Button className="bton_se_renseinger"

                  onClick={(e) => {
                    e.preventDefault();
                    if (props.formation.url && props.formation.url.length > 5) {

                      window.open(
                        props.formation.url,
                        '_blank'
                      );
                    } else {

                      window.open(
                        "/Display-Detail-formation/" + props.formation.internal_url + "/Information",
                        '_blank'
                      );
                    }


                  }}

                >Se renseigner </Button>


                <Button className="bton_voir_detail"

                  onClick={(e) => {
                    e.preventDefault();
                    //test(props.formation.external_code);

                    if (props.formation.url && props.formation.url.length > 5) {

                      window.open(
                        props.formation.url,
                        '_blank'
                      );
                    } else {
                      window.open(
                        "/Display-Detail-formation/" + props.formation.internal_url,
                        '_blank'
                      );
                    }

                  }}
                >Voir details</Button>

                {GetCurrentClass_result && GetCurrentClass_result.length > 0 &&
                  <Button variant="contained" className="bton_sinscrire"

                    onClick={(e) => {
                      e.preventDefault();

                      window.open(
                        "/Display-Detail-formation/" + props.formation.internal_url + "/Inscription",
                        '_blank'
                      );

                    }}

                  >S'incrire</Button>
                }

              </div>}

          </div>
        </div>
      </div>
    </div>

  )
}


export default Formation;