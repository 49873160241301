import QuisSommesNous from "../components/QuisSommesNous";
import Navigation from "../components/Navigation";
import MySy_Fonctionnalite from "../components/Fonctionnalites_V1";


const Fonctionnalites = () => {

  return (
            <div>
              <div>
            <Navigation />
            </div>
            <MySy_Fonctionnalite/>
        </div>

  )
}


export default Fonctionnalites;
