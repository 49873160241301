import PartnerSignInFirst from "../components/PartnerSignInFirst";
import Navigation from "../components/Navigation";



export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
                <PartnerSignInFirst />
               
            </div>
        </div>
    );
}
