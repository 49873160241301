import React, { useState, useEffect } from "react";
import { Button, } from "reactstrap";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import happyimg from "../mysy_img/happy.png";
import unhappyimg from "../mysy_img/nothappy.png";
import middleimg from "../mysy_img/middle.png";
import parse from 'html-react-parser'
import img_met_autre from "../mysy_img/met_autre.jpg";
import img_met_digital from "../mysy_img/met_digital.jpg";
import { Rating } from 'react-simple-star-rating'

const Article = (props) => {
  const [rating, setRating] = useState(props.article.note * 20)
  //console.log(props.article.note * 10);
  // Catch Rating value

  function handleRating(rate) {
    alert(" rate =" + rate);
    setRating(40);
  }

  const [title_resized, settitle_resized] = useState("")

  const [article_img, setarticle_img] = useState("")

  useEffect(() => {

    var local_title = "";
    if (props.article.title_formation && String(props.article.title_formation).length > 45) {
      local_title = String(props.article.title_formation).substring(0, 43) + " ...";

    } else {
      local_title = String(props.article.title_formation);

    }
    settitle_resized(local_title);



    var img_article = "";
    if (props.article.img_path && String(props.article.img_path).length > 10) {
      img_article = String(props.article.img_path)
      setarticle_img(img_article);

    } else {
      setarticle_img(img_met_digital);
    }
  

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }, []);

  return (

    <div className="article">

      <div className="div_row">


        <div className="div_row_border" >
          <div style={{ "width": "100%", textAlign: "center" }}>

            <div className="gauche_img">
              <img src={article_img} alt={props.article.title_formation} className="img_categorie" id="projets" />
            </div>

            <div className="droite_txt">
              <div className="met_text" id="projets" onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }} style={{
                cursor: "pointer"
              }} >   {title_resized} </div>

              <div className="met_text_detail" >


                <div className="art_criteres_value">

                  {parse(String(props.article.avis).replace(/(<([^>]+)>)/ig, ''))}

                </div>


              </div>
              <div style={{
                width: '100%', "textAlign": "right", paddingRight: "10px", float: "left", height: "2rem",
                fontStyle: "italic"
              }}>  {(String(props.article.date_avis)).substring(0, 10)} </div>

              <div className="grid8p" onClick={(e) => {
                e.preventDefault();
                window.location.href = "/Display-Detail-Article/" + props.article.internal_url
              }}> En savoir plus &rsaquo; </div>


            </div>
          </div>
        </div>
      </div>



    </div>
  )
}


export default Article;