import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { Helmet } from "react-helmet";
import FormControl from '@mui/material/FormControl';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import { useParams } from 'react-router-dom'
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Header from "./Header";
import Footer from "./Fotter";

function ResetUserPwd() {
    const { token, accounttype } = useParams();
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("");
    const [fields1desabled, setfields1desabled] = useState(true);
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [updatepwd, setupdatepwd] = useState();
    const [newpawdvisible, setnewpawdvisible] = useState(false);
    const [confpwdvisible, setconfpwdvisible] = useState(false);
    const [pwdfieldsdesabled, setpwdfieldsdesabled] = useState(true);



    const [valuesPass2, setValuesPass2] = React.useState({
        password2: '',
        showPassword2: false,
    });
    const [valuesPass3, setValuesPass3] = React.useState({
        password3: '',
        showPassword3: false,
    });

    const history = useHistory();

    // Verification de la valididé du token
    function IsTokenValide(e) {

        var form = new FormData();
        form.append("token", token);
        form.append("accounttype", accounttype);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/IsUserTokenValide/";

        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            console.log(" In test  res.data.status = " + res.data.status);
            console.log(" In test  res.data.message = " + res.data.message);
            if (String(res.data.status) === String("true")) {

                setmyApiResponse("true");
                setResult(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                //alert("Erreur: La session n'est plus active");
                history.push("/");
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
                setResult(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })

    }

    useEffect(() => {
        IsTokenValide();

    })


    const handleClickShowPassword_new_pwd = () => {
        if (newpawdvisible == false) {
            document.getElementsByName("new_pwd")[0].type = "text";
            setnewpawdvisible(true);
        }
        else if (newpawdvisible == true) {
            document.getElementsByName("new_pwd")[0].type = "password";
            setnewpawdvisible(false);
        }

    };


    function handleChange2(prop) {
        return (event) => {
            setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
        };
    }

    function handleChange3(prop) {
        return (event) => {
            setValuesPass3({ ...valuesPass3, [prop]: event.target.value });
        };
    }

    const handleClickShowPassword_new_pwd_conf = () => {
        if (confpwdvisible == false) {
            document.getElementsByName("new_pwd_conf")[0].type = "text";
            setconfpwdvisible(true);
        }
        else if (confpwdvisible == true) {
            document.getElementsByName("new_pwd_conf")[0].type = "password";
            setconfpwdvisible(false);
        }

    };



    const [UpdateUserPwd_api, setUpdateUserPwd_api] = useState("");
    const [UpdateUserPwd_result, setUpdateUserPwd_result] = useState("");
    const [UpdateUserPwd_message, setUpdateUserPwd_message] = useState("");
    function UpdateUserPwd() {

        setupdatepwd(false);
        const url = process.env.REACT_APP_API_URL + "myclass/api/Reset_user_pwd_by_token/";
        const formData = new FormData();


        var new_pwd = document.getElementsByName("new_pwd")[0].value;
        var conf_new_pwd = document.getElementsByName("new_pwd_conf")[0].value;


        document.getElementsByName("identique")[0].style.backgroundColor = "white";
        if (new_pwd !== conf_new_pwd) {
            document.getElementsByName("identique")[0].style.backgroundColor = "red";
            return
        }

        document.getElementsByName("caractere")[0].style.backgroundColor = "white";
        if (String(new_pwd).length < 8) {
            document.getElementsByName("caractere")[0].style.backgroundColor = "red";
            return
        }

        var regex = /\d/g;
        document.getElementsByName("nombre")[0].style.backgroundColor = "white";
        if (!regex.test(new_pwd)) {
            document.getElementsByName("nombre")[0].style.backgroundColor = "red";
            return
        }

        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        document.getElementsByName("special")[0].style.backgroundColor = "white";
        if (!specialChars.test(new_pwd)) {
            document.getElementsByName("special")[0].style.backgroundColor = "red";
            return
        }


        formData.append('token', token);
        formData.append('new_pwd', new_pwd);
        formData.append('conf_new_pwd', conf_new_pwd);
        formData.append("accounttype", accounttype);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);


                if (String(result['status']) === String("true")) {
                    setUpdateUserPwd_api("true")
                    setupdatepwd(true);
                    setfields1desabled(true);
                    alert(" le mot de passe a bien été mis à jour");
                   // history.push("/Connexion");
                }
                else {
                    setUpdateUserPwd_api("false");
                    setUpdateUserPwd_message(String(result['message']))
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setUpdateUserPwd_api("false");

                setupdatepwd(false);
            });
    }


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <div className="resetuserpwd">

            <Helmet>
                <title>Connexion utilisateur formation</title>
                <meta name="description" content="utisateur formation connexion" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />
            <div className="div_mobile">



                {/* hello reset user pwd, tock = {token} <br/>
            {}
    check api = {result} */}

                <div >
                    <div>
                        &nbsp;
                    </div>
                    <nav className="title"> Reinitialisation du mot de passe  </nav>

                     &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 } }}>


                        <br />

                        <FormControl sx={{ m: 1, }} variant="standard" size="small" className="texte_area_passwd">
                            <InputLabel htmlFor="outlined-adornment-password2">Mot de passe</InputLabel>
                            <OutlinedInput
                                id="new_pwd"
                                name="new_pwd"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password2}
                                onChange={handleChange2('password2')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword_new_pwd}
                                            edge="end"
                                        >
                                            {newpawdvisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password 2"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1, }} variant="standard" size="small" className="texte_area_passwd">
                            <InputLabel htmlFor="outlined-adornment-password">Confirme mot de passe</InputLabel>
                            <OutlinedInput
                                id="new_pwd_conf"
                                name="new_pwd_conf"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password3}
                                onChange={handleChange3('password3')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword_new_pwd_conf}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <div className="div_row2">

                            <Button variant="contained" color="success" className="div_row2 btn_modif" onClick={UpdateUserPwd}>Modifier</Button>

                            {UpdateUserPwd_api && String(UpdateUserPwd_api) === "false" && <div className="koUpdateData">
                                {UpdateUserPwd_message} </div>

                            }

                        </div>


                    </Box>

                    <div className="text_pwd_crit">
                        Créez un mot de passe contenant au moins :
                        <ul>
                            <li name="caractere">
                                8 caractères
                            </li>
                            <li name="nombre">
                                1 nombre
                            </li>
                            <li name="special">
                                1 caractère spécial p. ex., $, !, @, %, &
                            </li>

                            <li name="identique">
                                Identiques
                            </li>
                        </ul>
                    </div>
                    {String(myApiResponse) === String("false") && <div className="koUpdateData">
                        {result} <br />
                        Impossible de mettre à jour le mot de passe.<br />
                    </div>}

                    <div className="pieddepage">
                        <Footer />
                    </div>
                </div>

            </div>
        </div>
    )

};

export default ResetUserPwd;