import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { getCookie, setCookie } from 'react-use-cookie';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import LanguageIcon from '@mui/icons-material/Language';
import HomeIcon from '@mui/icons-material/Home'
import { Button, Form, FormGroup, Label } from "reactstrap";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useHistory } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from "axios";
import { ConsoleView } from "react-device-detect";
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Partner_Securite_Data from "./Partner_Securite_Data";
import Partner_Invoicing_Data from "./Partner_Invoicing_Data";
import Autocomplete from '@mui/material/Autocomplete';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';

import fileDownload from 'js-file-download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ColorPicker from 'react-pick-color';
import { gridClasses } from '@mui/x-data-grid';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import DownloadingIcon from '@mui/icons-material/Downloading';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { CiTrash } from "react-icons/ci";

const useStyles = makeStyles((theme) => ({

  dialog: {
    position: 'absolute',
    // left: 10,
    top: 400
  },

  paper: {
    overflowY: 'unset',
  },
  customizedButton: {
    position: 'absolute',
    left: '95%',
    top: '-9%',
    backgroundColor: 'lightgray',
    color: 'gray',
  },
}));



function UpdateParnterInfo(props) {
  const classes = useStyles();
  const history = useHistory();
  const [myApiResponse, setmyApiResponse] = useState("");
  const [result, setResult] = useState("");
  const [testval, settestval] = useState();
  const [fields1desabled, setfields1desabled] = useState(true);
  const [mailfieldsdesabled, setmailfieldsdesabled] = useState(true);
  const [pwdfieldsdesabled, setpwdfieldsdesabled] = useState(true);

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const [valuesPass1, setValuesPass1] = React.useState({
    password1: '',
    showPassword1: false,
  });

  const [valuesPass2, setValuesPass2] = React.useState({
    password2: '',
    showPassword2: false,
  });

  const [valuesPass3, setValuesPass3] = React.useState({
    password3: '',
    showPassword3: false,
  });



  const [isLoading, setLoading] = useState();

  const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
  const [newpawdvisible, setnewpawdvisible] = useState(false);
  const [confpwdvisible, setconfpwdvisible] = useState(false);
  const [updatemainmail, setupdatemainmail] = useState();
  const [updatepwd, setupdatepwd] = useState();


  // recuperation et gestion  du token utilisateur 
  const stored_user = getCookie('tokenmysypart');
  if (!stored_user || stored_user.length <= 0) {
    history.push("/PartnerLogin");
  }


  const myserver = "127.0.0.1";
  //const myserver = "89.156.84.196";
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();


  useEffect(() => {
    getCurrentUserData();
    //desablesecurtymailfields();
    //desablesecurtypwdfields();
    //desablesecurtyInsertKeyfields();
    getPartnerImages();
    Get_List_Catalog_Public_Themes();

  }, [])


  const [userformedited, setuserformedited] = useState();


  const [insert_key_api, setinsert_key_api] = useState("");
  const [insert_key_api_message, setinsert_key_api_message] = useState("");
  const [insert_key_updated, setinsert_key_updated] = useState();


  function UpdatePartnerInsertKey() {

    setinsert_key_updated();
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_insert_key/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/PartnerLogin");
    }

    var current_key = document.getElementsByName("current_insert_key")[0].value;
    var new_key = document.getElementsByName("new_insert_key")[0].value;
    var conf_new_key = document.getElementsByName("conf_new_insert_key")[0].value;



    document.getElementsByName("identique_insert_key")[0].style.backgroundColor = "white";
    if (new_key !== conf_new_key) {
      document.getElementsByName("identique_insert_key")[0].style.backgroundColor = "red";
      return;
    }

    document.getElementsByName("caractere_insert_key")[0].style.backgroundColor = "white";
    if (String(new_key).length < 8) {
      document.getElementsByName("caractere_insert_key")[0].style.backgroundColor = "red";
      return
    }

    var regex = /\d/g;
    document.getElementsByName("nombre_insert_key")[0].style.backgroundColor = "white";
    if (!regex.test(new_key)) {
      document.getElementsByName("nombre_insert_key")[0].style.backgroundColor = "red";
      return
    }

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    document.getElementsByName("special_insert_key")[0].style.backgroundColor = "white";
    if (!specialChars.test(new_key)) {
      document.getElementsByName("special_insert_key")[0].style.backgroundColor = "red";
      return
    }


    formData.append('current_insert_key', current_key);
    formData.append('new_insert_key', new_key);
    formData.append('conf_insert_key', conf_new_key);
    formData.append('partner_account_id', partner_account_id);



    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setinsert_key_api(result['status']);
        setinsert_key_api_message(result['message']);
        if (String(result['status']) === String("true")) {
          desablesecurtyInsertKeyfields();
          document.getElementsByName("current_insert_key")[0].value = new_key;
          setinsert_key_updated(true);
        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        }


      })
      .catch((error) => {
        console.error('Error:', error);
        setinsert_key_api("false");
        setinsert_key_updated(false);
      });
  }


  function UpdatePartnerPwd() {

    //setupdatepwd(false);
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_pwd/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/PartnerLogin");
    }

    var pwd = document.getElementsByName("current_pwd")[0].value;
    var new_pwd = document.getElementsByName("new_pwd")[0].value;
    var conf_new_pwd = document.getElementsByName("new_pwd_conf")[0].value;


    formData.append('pwd', pwd);
    formData.append('new_pwd', new_pwd);
    formData.append('conf_new_pwd', conf_new_pwd);
    formData.append('partner_account_id', partner_account_id);

    document.getElementsByName("identique")[0].style.backgroundColor = "white";
    if (new_pwd !== conf_new_pwd) {
      document.getElementsByName("identique")[0].style.backgroundColor = "red";
      return;
    }

    document.getElementsByName("caractere")[0].style.backgroundColor = "white";
    if (String(new_pwd).length < 8) {
      document.getElementsByName("caractere")[0].style.backgroundColor = "red";
      return
    }

    var regex = /\d/g;
    document.getElementsByName("nombre")[0].style.backgroundColor = "white";
    if (!regex.test(new_pwd)) {
      document.getElementsByName("nombre")[0].style.backgroundColor = "red";
      return
    }

    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    document.getElementsByName("special")[0].style.backgroundColor = "white";
    if (!specialChars.test(new_pwd)) {
      document.getElementsByName("special")[0].style.backgroundColor = "red";
      return
    }

    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) == String("true")) {
          setupdatepwd(true);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        setupdatepwd(false);
      });
  }


  function UpdatePartnerMail() {

    setupdatemainmail(false);
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_main_mail/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/PartnerLogin");
    }

    var mail = document.getElementsByName("current_email")[0].value;
    var new_mail = document.getElementsByName("new_email")[0].value;
    var conf_new_mail = document.getElementsByName("conf_new_email")[0].value;


    formData.append('mail', mail);
    formData.append('new_mail', new_mail);
    formData.append('conf_new_mail', conf_new_mail);
    formData.append('partner_account_id', partner_account_id);


    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) == String("true")) {
          setupdatemainmail(true);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        setupdatemainmail(false);
      });



  }



  const [partner_account_id, setpartner_account_id] = useState("");
  const [is_partner_admin_account, setis_partner_admin_account] = useState("");

  function getCurrentUserData() {

    settestval('d'); // ==> Do not delete
    const formData = new FormData();
    const url = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
    formData.append('token', stored_user);

    axios.post(url, formData).then(res => {
      if (String(res.data.status) === "true") {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("True");
        setResult(res.data.message);

        var mylocaltraining = JSON.parse(res.data.message);
        if (mylocaltraining) {

          setpartner_account_id(mylocaltraining._id);

          if (mylocaltraining.is_partner_admin_account) {
            setis_partner_admin_account(mylocaltraining.is_partner_admin_account);
          } else {
            setis_partner_admin_account("0");
          }


          if (mylocaltraining.adr_city) {
            setp_adr_street(mylocaltraining.adr_city);

          }


          if (mylocaltraining.adr_city) {
            setp_adr_zip(mylocaltraining.adr_zip);

          }

          if (mylocaltraining.email) {
            setp_email(mylocaltraining.email);

          }


          if (mylocaltraining.website) {
            setp_website(mylocaltraining.website);

          }



          if (mylocaltraining.adr_country) {
            setp_adr_country(mylocaltraining.adr_country);

          }

          if (mylocaltraining.link_facebook) {
            setp_link_facebook(mylocaltraining.link_facebook);

          }

          if (mylocaltraining.telephone) {
            setp_telephone(mylocaltraining.telephone);

          }

          if (mylocaltraining.siret) {
            setp_siret(mylocaltraining.siret);
          }



          if (mylocaltraining.num_nda) {
            setp_num_nda(mylocaltraining.num_nda);

          }



          if (mylocaltraining.iscertifvoltaire) {
            setmycertifvoltaire(mylocaltraining.iscertifvoltaire);
          }
          else {
            setmycertifvoltaire("0");
          }



          if (mylocaltraining.isdatadock) {
            setmydatadock(mylocaltraining.isdatadock);
          }
          else {
            setmydatadock("0");
          }

          if (mylocaltraining.isqualiopi) {
            setmyqualiopi(mylocaltraining.isqualiopi);
          }
          else {
            setmyqualiopi("0");
          }


          if (mylocaltraining.link_linkedin) {
            setp_link_linkedin(mylocaltraining.link_linkedin);
          } else {
            setp_link_linkedin('');
          }

          if (mylocaltraining.link_twitter) {
            setp_link_twitter(mylocaltraining.link_twitter);

          } else {
            setp_link_twitter('');
          }

          if (mylocaltraining.adr_street) {
            setp_adr_street(mylocaltraining.adr_street);

          } else {
            setp_adr_street('');
          }


          if (mylocaltraining.nom) {
            setp_nom(mylocaltraining.nom);

          } else {
            setp_nom('');
          }

          if (mylocaltraining.contact_tel) {
            setp_contact_tel(mylocaltraining.contact_tel);

          } else {
            setp_contact_tel('');
          }

          if (mylocaltraining.contact_nom) {
            setp_contact_nom(mylocaltraining.contact_nom);

          } else {
            setp_contact_nom('');
          }

          if (mylocaltraining.contact_prenom) {
            setp_contact_prenom(mylocaltraining.contact_prenom);

          } else {
            setp_contact_prenom('');
          }

          if (mylocaltraining.contact_mail) {
            setp_contact_mail(mylocaltraining.contact_mail);

          } else {
            setp_contact_mail('');
          }



          if (mylocaltraining.lms_virtualhost_url) {
            setp_lms_virtualhost_url(mylocaltraining.lms_virtualhost_url);

          } else {
            setp_lms_virtualhost_url('');
          }

          if (mylocaltraining.lms_username) {
            setp_lms_username(mylocaltraining.lms_username);

          } else {
            setp_lms_username('');
          }

          if (mylocaltraining.mysy_lms_user_id) {
            setp_mysy_lms_user_id(mylocaltraining.mysy_lms_user_id);

          } else {
            setp_mysy_lms_user_id('');
          }


          desablefield1();



        }
      }
      else {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("False");
        //setmyApimyApiMessage("")
      }


    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ', error);
      setmyApiResponse("False");
      //setmyApimyApiMessage("")
    })


  }


  function annuler_change_user_data() {
    desablefield1();
    getCurrentUserData();
    setuserformedited("");
  }

  async function desablefield1() {
    setdatamodification();
    await sleep(20);

    const list_fields = ['adr_city', 'adr_zip', 'adr_country',
      'telephone', 'siret', 'num_nda',
      'link_facebook', 'link_linkedin', 'link_twitter',
      'adr_street', 'nom', 'contact_tel',
      'contact_nom', 'contact_prenom', 'contact_mail',
      'email', 'website', 'website',
      'isqualiopi', 'iscertifvoltaire',]

    for (let i = 0; i < list_fields.length; i++) {
      if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
        document.getElementsByName(String(list_fields[i]))[0].disabled = true;
        document.getElementsByName(String(list_fields[i]))[0].style.backgroundColor = "#ECEFF1";

      }
    }

    setfields1desabled(true);
  }


  function enablefieds1() {

    setuserformedited("1");

    const list_fields = ['adr_city', 'adr_zip', 'adr_country',
      'telephone', 'siret', 'num_nda',
      'link_facebook', 'link_linkedin', 'link_twitter',
      'adr_street', 'nom', 'contact_tel',
      'contact_nom', 'contact_prenom', 'contact_mail',
      'email', 'website', 'website',
      'isqualiopi', 'iscertifvoltaire',]

    for (let i = 0; i < list_fields.length; i++) {
      if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
        document.getElementsByName(String(list_fields[i]))[0].disabled = false;
        document.getElementsByName(String(list_fields[i]))[0].style.backgroundColor = "#FFFFFF";

      }
    }


    setfields1desabled(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }


  function desablesecurtymailfields() {/*
    document.getElementsByName("current_email")[0].disabled = true;
    document.getElementsByName("current_email")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("current_email")[0].value = "";


    document.getElementsByName("new_email")[0].disabled = true;
    document.getElementsByName("new_email")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("new_email")[0].value = "";


    document.getElementsByName("conf_new_email")[0].disabled = true;
    document.getElementsByName("conf_new_email")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("conf_new_email")[0].value = "";


    setmailfieldsdesabled(true);*/
  }



  function enablesecurtymailfields() {


    document.getElementsByName("current_email")[0].disabled = false;
    document.getElementsByName("current_email")[0].style.backgroundColor = "#FFFFFF";



    document.getElementsByName("new_email")[0].disabled = false;
    document.getElementsByName("new_email")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("conf_new_email")[0].disabled = false;
    document.getElementsByName("conf_new_email")[0].style.backgroundColor = "#FFFFFF";


    setmailfieldsdesabled(false);
  }



  function desablesecurtypwdfields() {
    /* document.getElementsByName("current_pwd")[0].disabled = true;
     document.getElementsByName("current_pwd")[0].style.backgroundColor = "#ECEFF1";
     document.getElementsByName("current_pwd")[0].value = "";
 
     document.getElementsByName("new_pwd")[0].disabled = true;
     document.getElementsByName("new_pwd")[0].style.backgroundColor = "#ECEFF1";
     document.getElementsByName("new_pwd")[0].value = "";
 
     document.getElementsByName("new_pwd_conf")[0].disabled = true;
     document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#ECEFF1";
     document.getElementsByName("new_pwd_conf")[0].value = "";
 
 
     setpwdfieldsdesabled(true);*/
  }



  function enablesecurtypwdfields() {

    document.getElementsByName("current_pwd")[0].disabled = false;
    document.getElementsByName("current_pwd")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("new_pwd")[0].disabled = false;
    document.getElementsByName("new_pwd")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("new_pwd_conf")[0].disabled = false;
    document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#FFFFFF";

    setpwdfieldsdesabled(false);
  }


  function RecordData() {


    const formData = new FormData();
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";

    var adr_city = document.getElementsByName("adr_city")[0].value
    adr_city = adr_city.replace("undefined", "");

    var adr_zip = document.getElementsByName("adr_zip")[0].value;
    adr_zip = adr_zip.replace("undefined", "");


    var adr_country = document.getElementsByName("adr_country")[0].value;
    adr_country = adr_country.replace("undefined", "");


    var telephone = document.getElementsByName("telephone")[0].value;
    telephone = telephone.replace("undefined", "");

    var siret = "";
    if (document.getElementsByName("siret")[0]) {
      siret = document.getElementsByName("siret")[0].value;
      siret = siret.replace("undefined", "");
    }

    var num_nda = "";
    if (document.getElementsByName("num_nda")[0]) {
      num_nda = document.getElementsByName("num_nda")[0].value;
      num_nda = num_nda.replace("undefined", "");
    }


    var website = document.getElementsByName("website")[0].value;
    website = website.replace("undefined", "");

    var link_facebook = document.getElementsByName("link_facebook")[0].value;
    var link_linkedin = document.getElementsByName("link_linkedin")[0].value;
    var link_twitter = document.getElementsByName("link_twitter")[0].value;
    var adr_street = document.getElementsByName("adr_street")[0].value;
    var nom = document.getElementsByName("nom")[0].value;
    var contact_nom = document.getElementsByName("contact_nom")[0].value;


    var contact_prenom = document.getElementsByName("contact_prenom")[0].value;
    var contact_tel = document.getElementsByName("contact_tel")[0].value;
    var contact_mail = document.getElementsByName("contact_mail")[0].value;


    //var mycertitrace = document.getElementsByName("iscertitrace")[0].value;
    //var mybureaucertitrace = document.getElementsByName("isbureaucertitrace")[0].value;

    var mycertifvoltaire = "0"
    if (document.getElementsByName("iscertifvoltaire")[0]) {
      mycertifvoltaire = document.getElementsByName("iscertifvoltaire")[0].value;
    }

    var mydatadock = "0";
    if (document.getElementsByName("isdatadock")[0]) {
      mydatadock = document.getElementsByName("isdatadock")[0].value;
    }

    var myqualiopi = "0";
    if (document.getElementsByName("isqualiopi")[0]) {
      var myqualiopi = document.getElementsByName("isqualiopi")[0].value;
    }


    formData.append('partner_account_id', partner_account_id);
    formData.append('token', stored_user);
    formData.append('nom', nom);

    var new_nom = (p_nom).replace(/[^0-9a-z]/gi, '').toLocaleLowerCase();
    formData.append('subdomaine_catalog_pub', new_nom);


    //formData.append('iscertitrace', mycertitrace);
    formData.append('isdatadock', mydatadock);
    formData.append('isqualiopi', myqualiopi);
    // formData.append('isbureaucertitrace', mybureaucertitrace);
    formData.append('iscertifvoltaire', mycertifvoltaire);
    formData.append('website', website);

    formData.append('adr_city', adr_city);
    formData.append('adr_zip', adr_zip);
    formData.append('telephone', telephone);
    formData.append('siret', siret);
    formData.append('num_nda', num_nda);
    formData.append('adr_country', adr_country);
    formData.append('link_facebook', link_facebook);
    formData.append('link_twitter', link_twitter);
    formData.append('adr_street', adr_street);
    formData.append('contact_nom', contact_nom);
    formData.append('contact_prenom', contact_prenom);
    formData.append('link_linkedin', link_linkedin);
    formData.append('contact_tel', contact_tel);
    formData.append('contact_mail', contact_mail);

    // Recuperation de l'image logo & de l'image du cachet
    if (String(userimgclassprofilchanged) === "1") {
      formData.append('file_logo', isimgclassSelected);
      formData.append('file_logo_recid', isimgclassSelected_recid);
    }

    if (String(isimgcachetchanged) === "1") {
      formData.append('file_cachet', isimgcachetSelected);
      formData.append('file_cachet_recid', isimgcachetSelected_recid);
    }



    //console.log(" ### formData = ", formData);
    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) === String("true")) {
          settestval(true);
          desablefield1();
          setdatamodification();
          getPartnerImages();


          if (document.getElementsByName("isdatadock")[0]) {
            document.getElementsByName("isdatadock")[0].disabled = true;
            document.getElementsByName("isdatadock")[0].style.backgroundColor = "#ECEFF1";
          }

          if (document.getElementsByName("isqualiopi")[0]) {
            document.getElementsByName("isqualiopi")[0].disabled = true;
            document.getElementsByName("isqualiopi")[0].style.backgroundColor = "#ECEFF1";
          }


          if (document.getElementsByName("iscertifvoltaire")[0]) {
            document.getElementsByName("iscertifvoltaire")[0].disabled = true;
            document.getElementsByName("iscertifvoltaire")[0].style.backgroundColor = "#ECEFF1";
          }

          alert(" La mise à jour a été faite ");


        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        } else {
          alert(" Erreur : " + result['message']);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        alert(" Erreur : Impossible de mettre à jour les informations ");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [Add_Update_Catalog_Pub_Theme_api, setAdd_Update_Catalog_Pub_Theme_api] = useState();
  const [Add_Update_Catalog_Pub_Theme_message, setAdd_Update_Catalog_Pub_Theme_message] = useState();
  const [Add_Update_Catalog_Pub_Theme_result, setAdd_Update_Catalog_Pub_Theme_result] = useState();
  function Add_Update_Catalog_Pub_Theme() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("theme_id", selected_catalog_pub_theme_id);


    form.append("message_1_txt", p_catalog_pub_message_1_txt);
    form.append("message_1_taille", p_text1_taille);
    form.append("message_1_color", p_text1_color);
    if (p_text1_gras)
      form.append("message_1_gras", "700");
    else
      form.append("message_1_gras", "400");


    form.append("message_2_txt", p_catalog_pub_message_2_txt);
    form.append("message_2_color", p_text2_color);
    form.append("message_2_taille", p_text2_taille);

    if (p_text2_gras)
      form.append("message_2_gras", "700");
    else
      form.append("message_2_gras", "400");

    form.append("message_3_txt", p_catalog_pub_message_3_txt);
    form.append("message_3_color", p_text3_color);
    form.append("message_3_taille", p_text3_taille);

    if (p_text3_gras)
      form.append("message_3_gras", "700");
    else
      form.append("message_3_gras", "400");



    form.append("indicateur_1_txt", p_indicateur1_text);
    form.append("indicateur_1_taille", p_indicateur1_taille);
    form.append("indicateur_1_color", p_indicateur1_color);
    if (p_indicateur1_gras)
      form.append("indicateur_1_gras", "700");
    else
      form.append("indicateur_1_gras", "400");

    form.append("indicateur_2_txt", p_indicateur2_text);
    form.append("indicateur_2_taille", p_indicateur2_taille);
    form.append("indicateur_2_color", p_indicateur2_color);
    if (p_indicateur2_gras)
      form.append("indicateur_2_gras", "700");
    else
      form.append("indicateur_2_gras", "400");


    form.append("indicateur_3_txt", p_indicateur3_text);
    form.append("indicateur_3_taille", p_indicateur3_taille);
    form.append("indicateur_3_color", p_indicateur3_color);
    if (p_indicateur3_gras)
      form.append("indicateur_3_gras", "700");
    else
      form.append("indicateur_3_gras", "400");


    form.append("indicateur_4_txt", p_indicateur4_text);
    form.append("indicateur_4_taille", p_indicateur4_taille);
    form.append("indicateur_4_color", p_indicateur4_color);
    if (p_indicateur4_gras)
      form.append("indicateur_4_gras", "700");
    else
      form.append("indicateur_4_gras", "400");



    form.append("_id", selected_catalog_pub_config_id);



    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Add_Update_Catalog_Pub_Theme  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Catalog_Pub_Theme  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Catalog_Pub_Theme_api("true");
        setAdd_Update_Catalog_Pub_Theme_result(res.data.message);

        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Catalog_Pub_Theme_api("false");
        setAdd_Update_Catalog_Pub_Theme_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Add_Update_Catalog_Pub_Theme = ' + error);
      setAdd_Update_Catalog_Pub_Theme_api("false");
      alert(" Impossible de mettre à jour la configuration");

    })
  }



  const [getPartnerImages_api, setgetPartnerImages_api] = useState();
  const [getPartnerImages_message, setgetPartnerImages_message] = useState();
  const [getPartnerImages_result, setgetPartnerImages_result] = useState();
  function getPartnerImages(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedParnterImage_from_front/";

    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);



    axios.post(myurl, form).then(res => {

      //console.log(" getPartnerImages : In test  res.data.status = " + res.data.status);
      // console.log(" getPartnerImages: res.data.message.img = " + res.data.message);

      if (String(res.data.status) === "true") {
        setgetPartnerImages_api("true");

        if (JSON.parse(res.data.message).logo_img) {
          var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
          setisimgclassSelected(partner_logo_img);
          setuserimgclassprofil(partner_logo_img);
          setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
        }

        if (JSON.parse(res.data.message).cachet_img) {
          var partner_cachet_img = "data:image/png;base64," + JSON.parse(res.data.message).cachet_img;
          setisimgcachet(partner_cachet_img);
          setisimgcachetSelected(partner_cachet_img);
          setisimgcachetSelected_recid(JSON.parse(res.data.message).cachet_img_recid);
        }

      }
      else if (String(res.data.status) === String("Err_Connexion")) {
        alert('Erreur: ' + res.data.message);
        history.push("/Connexion");
        return;
      }
      else {
        setgetPartnerImages_api("false");
        setgetPartnerImages_message(res.data.message)

      }
    }).catch((error) => {
      console.warn('getPartnerImages ee: Not good man :(  = ', error);
      setgetPartnerImages_api("false");
      setgetPartnerImages_message(" Impossible de recuperer les images/médias du partenaire")
    })

  }


  function handleChange(prop) {
    return (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  }


  function handleChange1(prop) {
    return (event) => {
      setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
    };
  }

  function handleChange2(prop) {
    return (event) => {
      setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
    };
  }

  function handleChange3(prop) {
    return (event) => {
      setValuesPass3({ ...valuesPass3, [prop]: event.target.value });
    };
  }


  const handleClickShowPassword_current_pwd = () => {
    var filtre2_value = document.getElementsByName("current_pwd")[0].value;

    if (currentpawdvisible == false) {
      document.getElementsByName("current_pwd")[0].type = "text";
      setcurrentpawdvisible(true);
    }
    else if (currentpawdvisible == true) {
      document.getElementsByName("current_pwd")[0].type = "password";
      setcurrentpawdvisible(false);
    }

  };


  const handleClickShowPassword_new_pwd = () => {
    if (newpawdvisible == false) {
      document.getElementsByName("new_pwd")[0].type = "text";
      setnewpawdvisible(true);
    }
    else if (newpawdvisible == true) {
      document.getElementsByName("new_pwd")[0].type = "password";
      setnewpawdvisible(false);
    }
  };


  const handleClickShowPassword_new_pwd_conf = () => {
    if (confpwdvisible == false) {
      document.getElementsByName("new_pwd_conf")[0].type = "text";
      setconfpwdvisible(true);
    }
    else if (confpwdvisible == true) {
      document.getElementsByName("new_pwd_conf")[0].type = "password";
      setconfpwdvisible(false);
    }

  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [insert_keyfieldsdesabled, setinsert_keyfieldsdesabled] = useState(true);

  // Gestion champs caché : Current_Insertion_Key
  const [values_current_insert_key, setvalues_current_insert_key] = React.useState({
    current_insert_key: '',
    showCurrent_insert_key: false,
  });


  const [valuescurrent_insert_key, setvaluescurrent_insert_key] = React.useState({
    password_insert_current_key: '',
    showpassword_insert_current_key: false,
  });

  const [current_insert_key_visible, setcurrent_insert_key_visible] = useState(false);

  function handleChange_current_insert_key(prop) {
    return (event) => {
      setvaluescurrent_insert_key({ ...current_insert_key_visible, [prop]: event.target.value });
    };
  }

  const handleClickcurrent_insert_key = () => {
    if (current_insert_key_visible == false) {
      document.getElementsByName("current_insert_key")[0].type = "text";
      setcurrent_insert_key_visible(true);
    }
    else if (current_insert_key_visible == true) {
      document.getElementsByName("current_insert_key")[0].type = "password";
      setcurrent_insert_key_visible(false);
    }
  };
  // FIN Gestion champs caché : Current_Insertion_Key

  // Gestion champs caché : new_Insertion_Key
  const [values_new_insert_key, setvalues_new_insert_key] = React.useState({
    new_insert_key: '',
    shownew_insert_key: false,
  });


  const [valuesnew_insert_key, setvaluesnew_insert_key] = React.useState({
    password_insert_new_key: '',
    showpassword_insert_new_key: false,
  });

  const [new_insert_key_visible, setnew_insert_key_visible] = useState(false);

  function handleChange_new_insert_key(prop) {
    return (event) => {
      setvaluesnew_insert_key({ ...new_insert_key_visible, [prop]: event.target.value });
    };
  }

  const handleClicknew_insert_key = () => {
    if (new_insert_key_visible == false) {
      document.getElementsByName("new_insert_key")[0].type = "text";
      setnew_insert_key_visible(true);
    }
    else if (new_insert_key_visible == true) {
      document.getElementsByName("new_insert_key")[0].type = "password";
      setnew_insert_key_visible(false);
    }
  };
  // FIN Gestion new champs caché : new_insertion_Key

  // Gestion champs caché : conf_new_Insertion_Key
  const [values_conf_new_insert_key, setvalues_conf_new_insert_key] = React.useState({
    conf_new_insert_key: '',
    showconf_new_insert_key: false,
  });


  const [valuesconf_new_insert_key, setvaluesconf_new_insert_key] = React.useState({
    password_insertconf_new_key: '',
    showpassword_insertconf_new_key: false,
  });

  const [conf_new_insert_key_visible, setconf_new_insert_key_visible] = useState(false);

  function handleChangeconf_new_insert_key(prop) {
    return (event) => {
      setvaluesconf_new_insert_key({ ...conf_new_insert_key_visible, [prop]: event.target.value });
    };
  }

  const handleClickconf_new_insert_key = () => {
    if (conf_new_insert_key_visible == false) {
      document.getElementsByName("conf_new_insert_key")[0].type = "text";
      setconf_new_insert_key_visible(true);
    }
    else if (conf_new_insert_key_visible == true) {
      document.getElementsByName("conf_new_insert_key")[0].type = "password";
      setconf_new_insert_key_visible(false);
    }
  };
  // FIN Gestion new champs caché : conf_new_insertion_Key

  function desablesecurtyInsertKeyfields() {

    setinsert_key_updated();
    document.getElementsByName("current_insert_key")[0].disabled = true;
    document.getElementsByName("current_insert_key")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("current_insert_key")[0].value = "";

    document.getElementsByName("new_insert_key")[0].disabled = true;
    document.getElementsByName("new_insert_key")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("new_insert_key")[0].value = "";

    document.getElementsByName("conf_new_insert_key")[0].disabled = true;
    document.getElementsByName("conf_new_insert_key")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("conf_new_insert_key")[0].value = "";


    setinsert_keyfieldsdesabled(true);
  }




  function enablesecurtyInsertKeyfields() {

    setinsert_key_updated();
    document.getElementsByName("current_insert_key")[0].disabled = false;
    document.getElementsByName("current_insert_key")[0].style.backgroundColor = "#FFFFFF";


    document.getElementsByName("new_insert_key")[0].disabled = false;
    document.getElementsByName("new_insert_key")[0].style.backgroundColor = "#FFFFFF";

    document.getElementsByName("conf_new_insert_key")[0].disabled = false;
    document.getElementsByName("conf_new_insert_key")[0].style.backgroundColor = "#FFFFFF";

    setinsert_keyfieldsdesabled(false);
  }

  const qualiopi = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const certitrace = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const datadock = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const bureaucertitrace = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const certifvoltaire = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];



  const [myqualiopi, setmyqualiopi] = useState("");
  const handleChangequaliopi = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmyqualiopi(value);
  }

  const [mydatadock, setmydatadock] = useState("");
  const handleChangedatadock = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmydatadock(value);
  }


  const [mycertitrace, setmycertitrace] = useState("");
  const handleChangecertitrace = (event) => {

    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmycertitrace(value);
  }

  const [mybureaucertitrace, setmybureaucertitrace] = useState("");
  const handleChangebureaucertitrace = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmybureaucertitrace(value);
  }


  const [mycertifvoltaire, setmycertifvoltaire] = useState("");
  const handleChangecertifvoltaire = (event) => {
    if (fields1desabled) {
      alert(" Vous devez cliquer sur 'Editer' pour activer les modifications");
      return;
    }

    const name = event.target.name;
    const value = event.target.value;
    setdatamodification("1");
    setmycertifvoltaire(value);
  }



  const [datamodification, setdatamodification] = useState("0");
  function DataUpdated() {
    setdatamodification("1");
  }

  const [isimgclassdeleted, setisimgclassdeleted] = useState("");
  const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");

  function removeRecodedClassImage() {
    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("class_external_code", class_external_code);
    form.append("type", "partner");

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteRecodedClassImage/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {

        //console.log(' removeRecodedClassImage : status:', result['status']);
        //console.log('removeRecodedClassImage : Success:', result['message']);

        setisimgclassSelected(false);

        if (String(result['status']) === String("true")) {
          setisimgclassdeleted("1");
          setuserimgclassprofil("");
        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          setisimgclassdeleted("0");
          setisimgclassdeleted_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassdeleted("0");
        setisimgclassdeleted_message(result['message']);
      });

  }



  function removeRecodedLogoImage() {
    if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
      alert(" Image incorrecte. Impossible de la supprimer ");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("image_recid", isimgclassSelected_recid);

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteClassImage_v2/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {

        console.log(' removeRecodedClassImage : status:', result['status']);
        console.log('removeRecodedClassImage : Success:', result['message']);


        if (String(result['status']) === String("true")) {
          setisimgclassSelected("");
          setisimgclassSelected_recid("");
          setisimgclassSelectedfile("");
          setuserimgclassprofil("");
          getPartnerImages();

        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          alert(result['message']);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        alert(" Impossible de supprimer l'image ");
      });

  }

  function removeRecodedCachetImage() {
    if (!isimgcachetSelected_recid || String(isimgcachetSelected_recid).length < 3) {
      alert(" Image incorrecte. Impossible de la supprimer ");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("image_recid", isimgcachetSelected_recid);

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteClassImage_v2/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {


        if (String(result['status']) === String("true")) {
          console.log(' removeRecodedClassImage : status:', result['status']);
          console.log('removeRecodedClassImage : Success:', result['message']);
          setisimgcachetSelected("");
          setisimgcachetSelected_recid("");
          setisimgcachetSelectedfile("");
          setisimgcachet("");
          getPartnerImages();

        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          alert(result['message']);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        alert(" Impossible de supprimer l'image ");
      });


  }

  const [isimgclassSaved, setisimgclassSaved] = useState("");
  const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
  function sendClassImage(e) {

    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var formData = new FormData();
    formData.append('File', isimgclassSelected);
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append("class_external_code", class_external_code);

    formData.append("type", "partner");
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/recordClassImage/",
      {
        method: 'POST',
        body: formData,
      }
    ).then((response) => response.json())
      .then((result) => {
        //console.log('status:', result['status']);
        //console.log('Success:', result['message']);
        setisimgclassSelected(false);

        if (String(result['status']) == String("true")) {
          setisimgclassSaved("1");
        }
        else {
          setisimgclassSaved("0");
          setisimgclassSaved_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassSaved("0");
        setisimgclassSaved_message(result['message']);
      });
  };

  const [isimgclassSelected, setisimgclassSelected] = useState(false);
  const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
  const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


  const [userimgclassprofil, setuserimgclassprofil] = useState();
  const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");
  const imglogoclasschangeHandler = (event) => {


    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    //console.log(event.target.files);

    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;



    //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
    if (file_size > 1000000) {
      alert("L'image ne doit pas dépasser un 1 Méga octets");
      return;
    }
    setisimgclassSelected(event.target.files[0]);
    setisimgclassSelectedfile(true);

    setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
    setuserimgclassprofilchanged("1");
  };

  const [isimgcachetSelected, setisimgcachetSelected] = useState(false);
  const [isimgcachetSelected_recid, setisimgcachetSelected_recid] = useState("");
  const [isimgcachetSelectedfile, setisimgcachetSelectedfile] = useState(false);


  const [isimgcachet, setisimgcachet] = useState();
  const [isimgcachetchanged, setisimgcachetchanged] = useState("");
  const imgcachetchangeHandler = (event) => {



    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    //console.log(event.target.files);

    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;



    //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
    if (file_size > 1000000) {
      alert("L'image ne doit pas dépasser un 1 Méga octets");
      return;
    }
    setisimgcachetSelected(event.target.files[0]);
    setisimgcachetSelectedfile(true);

    setisimgcachet(URL.createObjectURL(event.target.files[0]));
    setisimgcachetchanged("1");
  };


  const [isimgcachetdeleted, setisimgcachetdeleted] = useState("");
  const [isimgcachetdeleted_message, setisimgcachetdeleted_message] = useState("");

  const [isimgcachetSaved, setisimgcachetSaved] = useState("");
  const [isimgcachetSaved_message, setisimgcachetSaved_message] = useState("");

  const [submenu, setsubmenu] = useState("submenu_donnee_entreprise");

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function submenu_donnee_entreprise() {
    setsubmenu("submenu_donnee_entreprise");
    await sleep(5);
    if (fields1desabled == true) {
      desablefield1();
    }
    else {
      enablefieds1();
    }
  }

  async function submenu_donnee_facturation() {
    setsubmenu("submenu_donnee_facturation");
  }

  async function submenu_donnee_securite() {

    //var security_retval = props.check_user_acces_right("account_securite", "read");

    setsubmenu("submenu_donnee_securite");

  }

  async function submenu_contact() {
    setsubmenu("submenu_contact");
  }


  const [partner_catalog_pub_url, setpartner_catalog_pub_url] = useState("");

  async function submenu_donnee_catalog_pub() {
    Get_Partner_Catalog_Pub_Config();

    var new_nom = (p_nom).replace(/[^0-9a-z]/gi, '').toLocaleLowerCase();
    var new_url = window.location.protocol + "//" + new_nom + "." + process.env.REACT_APP_PART_PUB_CATALOG_DOMAIN + "moncatalog";

    setpartner_catalog_pub_url(new_url);

    setsubmenu("submenu_donnee_catalog_pub");
  }



  const [p_nom, setp_nom] = useState("");
  const [p_siret, setp_siret] = useState("");
  const [p_telephone, setp_telephone] = useState("");
  const [p_email, setp_email] = useState("");
  const [p_website, setp_website] = useState("");
  const [p_link_linkedin, setp_link_linkedin] = useState("");
  const [p_link_facebook, setp_link_facebook] = useState("");
  const [p_link_twitter, setp_link_twitter] = useState("");
  const [p_adr_street, setp_adr_street] = useState("");
  const [p_adr_zip, setp_adr_zip] = useState("");
  const [p_adr_city, setp_adr_city] = useState("");
  const [p_adr_country, setp_adr_country] = useState("");
  const [p_num_nda, setp_num_nda] = useState("");
  const [p_isqualiopi, setp_isqualiopi] = useState("");
  const [p_isdatadock, setp_isdatadock] = useState("");
  const [p_iscertifvoltaire, setp_iscertifvoltaire] = useState("");
  const [p_contact_nom, setp_contact_nom] = useState("");
  const [p_contact_prenom, setp_contact_prenom] = useState("");
  const [p_contact_tel, setp_contact_tel] = useState("");
  const [p_contact_mail, setp_contact_mail] = useState("");



  const [p_lms_virtualhost_url, setp_lms_virtualhost_url] = useState("");
  const [p_lms_username, setp_lms_username] = useState("");
  const [p_mysy_lms_user_id, setp_mysy_lms_user_id] = useState("");


  const [submenu_catalog_pub, setsubmenu_catalog_pub] = useState("design");
  function submenu_catalog_pub_design() {
    setsubmenu_catalog_pub("design");

    if (String(catalog_pub_config_edit) !== "1") {
      disable_catalog_pub_fields();
    }

  }

  function submenu_catalog_pub_logo() {
    clear_message_catalog_pub();
    setsubmenu_catalog_pub("logo");
  }

  function submenu_catalog_pub_img_bg() {
    setsubmenu_catalog_pub("banniere");
  }

  const [handleSubmission_logo_api, sethandleSubmission_logo_api] = useState();
  const [handleSubmission_logo_result, sethandleSubmission_logo_result] = useState();
  const [handleSubmission_logo_message, sethandleSubmission_logo_message] = useState();

  function clear_message_catalog_pub() {
    /*sethandleSubmission_logo_message();
    sethandleSubmission_logo_api();
    setSendBackground_Img_api();
    setSendBackground_Img_message();*/
  }

  const [NEW_Get_List_Catalog_Public_Themes_result, setNEW_Get_List_Catalog_Public_Themes_result] = useState([]);

  const [Get_List_Catalog_Public_Themes_api, setGet_List_Catalog_Public_Themes_api] = useState();
  const [Get_List_Catalog_Public_Themes_message, setGet_List_Catalog_Public_Themes_message] = useState();
  const [Get_List_Catalog_Public_Themes_result, setGet_List_Catalog_Public_Themes_result] = useState([]);
  function Get_List_Catalog_Public_Themes() {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Theme_Catalog_Pub/";

    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === "true") {
        //console.log(" In Get_List_Catalog_Public_Themes  res.data.status = " + res.data.status);
        //console.log(" In Get_List_Catalog_Public_Themes  res.data.message r_class = " + res.data.message);
        setGet_List_Catalog_Public_Themes_api("true");
        setGet_List_Catalog_Public_Themes_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var id = JSON.parse(x).id;
          var _id = JSON.parse(x)._id;
          var code = JSON.parse(x).code;
          var description = JSON.parse(x).description;
          var theme_preview = JSON.parse(x).theme_preview;


          //---
          var node = {
            "_id": _id,
            "id": id,
            "label": code + " - " + description,
            "code": code,
            "description": description,
            "theme_preview": theme_preview,

          };
          new_data2.push(node);
        });

        //---
        var node = {
          "_id": "",
          "id": "id",
          "label": "",
          "code": "",
          "description": "",
          "theme_preview": "",

        };
        new_data2.push(node);


        if (new_data2.length > 0)
          setNEW_Get_List_Catalog_Public_Themes_result(new_data2);


      }
      else {
        setGet_List_Catalog_Public_Themes_api("false");
        setGet_List_Catalog_Public_Themes_message(res.data.message);
      }

    }).catch((error) => {
      console.warn('Not good man :( Get_List_Catalog_Public_Themes = ', error);
      setGet_List_Catalog_Public_Themes_api("false");

    })
  }




  const [selected_catalog_pub_theme_id, setselected_catalog_pub_theme_id] = useState("");
  const [selected_catalog_pub_theme_preview, setselected_catalog_pub_theme_preview] = useState("");
  const [p_catalog_pub_message_1_txt, setp_catalog_pub_message_1_txt] = useState("");
  const [p_catalog_pub_message_2_txt, setp_catalog_pub_message_2_txt] = useState("");
  const [p_catalog_pub_message_3_txt, setp_catalog_pub_message_3_txt] = useState("");


  const [catalog_pub_config_edit, setcatalog_pub_config_edit] = useState("");
  const [catalog_pub_config_changed, setcatalog_pub_config_changed] = useState("");



  const [Add_Update_Partner_Catalog_Pub_Config_api, setAdd_Update_Partner_Catalog_Pub_Config_api] = useState();
  const [Add_Update_Partner_Catalog_Pub_Config_result, setAdd_Update_Partner_Catalog_Pub_Config_result] = useState();
  const [Add_Update_Partner_Catalog_Pub_Config_message, setAdd_Update_Partner_Catalog_Pub_Config_message] = useState();

  function Add_Update_Partner_Catalog_Pub_Config() {
    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";
    setLoading(true);
    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === "true") {
        //console.log(" In GetLms_Themes  res.data.status = " + res.data.status);
        //console.log(" In GetLms_Themes  res.data.message r_class = " + res.data.message);
        setAdd_Update_Partner_Catalog_Pub_Config_api("true");
        setAdd_Update_Partner_Catalog_Pub_Config_result(res.data.message);
        setLoading(false);
        alert(" La mise à jour du theme a été correctement effectuée. ");
      }
      else {
        setAdd_Update_Partner_Catalog_Pub_Config_api("false");
        setAdd_Update_Partner_Catalog_Pub_Config_message(res.data.message);
        setLoading(false);
      }

    }).catch((error) => {
      console.warn('Not good man :( GetLms_Themes = ', error);
      setAdd_Update_Partner_Catalog_Pub_Config_api("false");
      setLoading(false);

    })
  }


  const [Partner_Catalog_Pub_logo, setPartner_Catalog_Pub_logo] = useState("");
  const [Partner_Catalog_Pub_banniere, setPartner_Catalog_Pub_banniere] = useState("");
  const [Partner_Catalog_Pub_message_1_txt, setPartner_Catalog_Pub_message_1_txt] = useState("");
  const [Partner_Catalog_Pub_theme_id, setPartner_Catalog_Pub_theme_id] = useState("");


  const [selected_catalog_pub_config_id, setselected_catalog_pub_config_id] = useState();


  const [Get_Partner_Catalog_Pub_Config_api, setGet_Partner_Catalog_Pub_Config_api] = useState();
  const [Get_Partner_Catalog_Pub_Config_message, setGet_Partner_Catalog_Pub_Config_message] = useState();
  const [Get_Partner_Catalog_Pub_Config_result, setGet_Partner_Catalog_Pub_Config_result] = useState();
  function Get_Partner_Catalog_Pub_Config(event) {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      if (String(res.data.status) === "true") {
        //console.log(" In Get_Partner_Catalog_Pub_Config  res.data.status = " + res.data.status);
        //  console.log(" In Get_Partner_Catalog_Pub_Config  res.data.message r_class = " + res.data.message);
        setGet_Partner_Catalog_Pub_Config_api("true");
        setGet_Partner_Catalog_Pub_Config_result(res.data.message);

        setselected_catalog_pub_config_id(JSON.parse(res.data.message)._id);

        setselected_catalog_pub_theme_id(JSON.parse(res.data.message).theme_id);

        var local_cgu = "";
        if (JSON.parse(res.data.message).cgu_pdf)
          local_cgu = JSON.parse(res.data.message).cgu_pdf;
        setp_detail_cgu_file_pdf(local_cgu);


        var local_cgv = "";
        if (JSON.parse(res.data.message).cgv_pdf)
          local_cgv = JSON.parse(res.data.message).cgv_pdf;
        setp_detail_cgv_file_pdf(local_cgv);

        var local_mentions_legales = "";
        if (JSON.parse(res.data.message).mention_legale_pdf)
          local_mentions_legales = JSON.parse(res.data.message).mention_legale_pdf;
        setp_detail_mention_legale_file_pdf(local_mentions_legales);


        var theme_id_preview = "data:image/png;base64," + JSON.parse(res.data.message).theme_preview;
        setselected_catalog_pub_theme_preview(theme_id_preview);


        var logo_img = JSON.parse(res.data.message).logo;
        setPartner_Catalog_Pub_logo(logo_img);


        var banniere_img = JSON.parse(res.data.message).banniere_img;
        setPartner_Catalog_Pub_banniere(banniere_img);

        setPartner_Catalog_Pub_message_1_txt(JSON.parse(res.data.message).message_1_txt);
        setp_catalog_pub_message_1_txt(JSON.parse(res.data.message).message_1_txt);

        setp_catalog_pub_message_2_txt(JSON.parse(res.data.message).message_2_txt);

        setp_catalog_pub_message_3_txt(JSON.parse(res.data.message).message_3_txt);

        setPartner_Catalog_Pub_theme_id(JSON.parse(res.data.message).theme_id);

        if (JSON.parse(res.data.message).message_1_color)
          setp_text1_color(JSON.parse(res.data.message).message_1_color)
        else
          setp_text1_color('#000000');

        if (JSON.parse(res.data.message).message_1_taille)
          setp_text1_taille(JSON.parse(res.data.message).message_1_taille)
        else
          setp_text1_taille('12');

        setp_text1_gras(false);
        if (JSON.parse(res.data.message).message_1_gras) {
          if (String(JSON.parse(res.data.message).message_1_gras) === "700")
            setp_text1_gras(true);
        }




        if (JSON.parse(res.data.message).message_2_color)
          setp_text2_color(JSON.parse(res.data.message).message_2_color)
        else
          setp_text2_color('#000000');


        if (JSON.parse(res.data.message).message_2_taille)
          setp_text2_taille(JSON.parse(res.data.message).message_2_taille)
        else
          setp_text2_taille('12');


        setp_text2_gras(false);
        if (JSON.parse(res.data.message).message_2_gras) {
          if (String(JSON.parse(res.data.message).message_2_gras) === "700")
            setp_text2_gras(true);

        }


        if (JSON.parse(res.data.message).message_3_color)
          setp_text3_color(JSON.parse(res.data.message).message_3_color)
        else
          setp_text3_color('#000000');


        if (JSON.parse(res.data.message).message_3_taille)
          setp_text3_taille(JSON.parse(res.data.message).message_3_taille)
        else
          setp_text3_taille('12');


        setp_text3_gras(false);
        if (JSON.parse(res.data.message).message_3_gras) {
          if (String(JSON.parse(res.data.message).message_3_gras) === "700")
            setp_text3_gras(true);
        }

        //  -----------

        if (JSON.parse(res.data.message).indicateur_1_txt)
          setp_indicateur1_text(JSON.parse(res.data.message).indicateur_1_txt)
        else
          setp_indicateur1_text('');

        if (JSON.parse(res.data.message).indicateur_1_color)
          setp_indicateur1_color(JSON.parse(res.data.message).indicateur_1_color)
        else
          setp_indicateur1_color('#000000');


        if (JSON.parse(res.data.message).indicateur_1_taille)
          setp_indicateur1_taille(JSON.parse(res.data.message).indicateur_1_taille)
        else
          setp_indicateur1_taille('12');


        setp_indicateur1_gras(false);
        if (JSON.parse(res.data.message).indicateur_1_gras) {
          if (String(JSON.parse(res.data.message).indicateur_1_gras) === "700")
            setp_indicateur1_gras(true);
        }

        if (JSON.parse(res.data.message).indicateur_2_txt)
          setp_indicateur2_text(JSON.parse(res.data.message).indicateur_2_txt)
        else
          setp_indicateur2_text('');

        if (JSON.parse(res.data.message).indicateur_2_color)
          setp_indicateur2_color(JSON.parse(res.data.message).indicateur_2_color)
        else
          setp_indicateur2_color('#000000');


        if (JSON.parse(res.data.message).indicateur_2_taille)
          setp_indicateur2_taille(JSON.parse(res.data.message).indicateur_2_taille)
        else
          setp_indicateur2_taille('12');


        setp_indicateur2_gras(false);
        if (JSON.parse(res.data.message).indicateur_2_gras) {
          if (String(JSON.parse(res.data.message).indicateur_2_gras) === "700")
            setp_indicateur2_gras(true);
        }


        if (JSON.parse(res.data.message).indicateur_3_txt)
          setp_indicateur3_text(JSON.parse(res.data.message).indicateur_3_txt)
        else
          setp_indicateur3_text('');

        if (JSON.parse(res.data.message).indicateur_3_color)
          setp_indicateur3_color(JSON.parse(res.data.message).indicateur_3_color)
        else
          setp_indicateur3_color('#000000');


        if (JSON.parse(res.data.message).indicateur_3_taille)
          setp_indicateur3_taille(JSON.parse(res.data.message).indicateur_3_taille)
        else
          setp_indicateur3_taille('12');


        setp_indicateur3_gras(false);
        if (JSON.parse(res.data.message).indicateur_3_gras) {
          if (String(JSON.parse(res.data.message).indicateur_3_gras) === "700")
            setp_indicateur3_gras(true);
        }

        if (JSON.parse(res.data.message).indicateur_4_txt)
          setp_indicateur4_text(JSON.parse(res.data.message).indicateur_4_txt)
        else
          setp_indicateur4_text('');

        if (JSON.parse(res.data.message).indicateur_4_color)
          setp_indicateur4_color(JSON.parse(res.data.message).indicateur_4_color)
        else
          setp_indicateur4_color('#000000');


        if (JSON.parse(res.data.message).indicateur_4_taille)
          setp_indicateur4_taille(JSON.parse(res.data.message).indicateur_4_taille)
        else
          setp_indicateur4_taille('12');


        setp_indicateur4_gras(false);
        if (JSON.parse(res.data.message).indicateur_4_gras) {
          if (String(JSON.parse(res.data.message).indicateur_4_gras) === "700")
            setp_indicateur4_gras(true);
        }


        disable_catalog_pub_fields();

      }
      else {
        setGet_Partner_Catalog_Pub_Config_api("false");
        setGet_Partner_Catalog_Pub_Config_message(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Get_Partner_Catalog_Pub_Config = ', error);
      alert(" Impossible de récuperer la configuration du catalogue public ")

    })
  }





  const changeHandler2_bg = (event) => {
    hiddenFileInput_bg.current.click();
  };

  const [logo_file_name, setlogo_file_name] = useState();
  const hiddenFileInput_logo = React.useRef(null);

  const [bg_file_name, setbg_file_name] = useState();
  const hiddenFileInput_bg = React.useRef(null);
  const [handleSubmission_bg_api, sethandleSubmission_bg_api] = useState();
  const [handleSubmission_bg_result, sethandleSubmission_bg_result] = useState();
  const [handleSubmission_bg_message, sethandleSubmission_bg_message] = useState();

  const handleSubmission_bg = event => {
    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;

    sethandleSubmission_bg_message("");

    sethandleSubmission_bg_api("");

    console.log("file_size =  ", file_size, " file_type = ", file_type, " naturalWidth = ", fileUploaded.naturalWidth);
    if (file_size > 1000000) {
      alert("Le fichier ne doit pas dépasser un 1 Méga octets");
      return;
    }

    //image/png
    if (String(file_type) !== "image/png") {
      alert("Le fichier n'est pas au format PNG");
      return;
    }

    setLoading(true);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    var img_height, img_width;

    if ((file = event.target.files[0])) {
      img = new Image();
      var objectUrl = _URL.createObjectURL(file);
      img.onload = function () {
        img_height = this.height;
        img_width = this.width;
        //console.log(" img_width =  ", img_width);
        _URL.revokeObjectURL(objectUrl);
        if (parseFloat(img_width) > 1500 && parseFloat(img_width) < 1930 &&
          parseFloat(img_height) > 150 && parseFloat(img_height) < 195) {
          //console.log(" img_height est = ", img_height, " < à 1500 ");

          // Verification que le nom du fichier est alpha numerique
          /*var regExp = /^[A-Za-z0-9]+$/;
          if (!regExp.test(event.target.files[0].name)) {
              sethandleSubmission_bg_message(" Le nom de l'image comporte des caractères non alphanumériques");
              sethandleSubmission_bg_api("false");
              setLoading(false);
              return
          }*/


          if (String(event.target.files[0].name).length > 50) {
            sethandleSubmission_bg_message(" Le nom du fichier est trop long. il doit faire moins de 50 caractère");
            sethandleSubmission_bg_api("false");
            setLoading(false);

            return
          }

          setbg_file_name(event.target.files[0].name);

          //setimg_bg_file(URL.createObjectURL(event.target.files[0]));
          setimg_bg_file2(event.target.files[0]);

          reader(event.target.files[0], (err, res) => {
            // console.log("#### res === ", res); // Base64 `data:image/...` String result.
            setimg_bg_file(res);
          });





        } else {
          sethandleSubmission_bg_message(" La taille de l'image est incorrecte. Larg = " + String(img_width) + " px Long = " + String(img_height) + " px");
          sethandleSubmission_bg_api("false");
          setLoading(false);
          return;

        }
      };
      img.src = objectUrl;

    }
    setLoading(false);

  };


  function reader(file, callback) {
    const fr = new FileReader();
    fr.onload = () => callback(null, fr.result);
    fr.onerror = (err) => callback(err);
    fr.readAsDataURL(file);
  }



  const [SendBackground_Img_api, setSendBackground_Img_api] = useState();
  const [SendBackground_Img_result, setSendBackground_Img_result] = useState();
  const [SendBackground_Img_message, setSendBackground_Img_message] = useState();



  const [img_bg_file, setimg_bg_file] = useState();
  const [img_logo_file2, setimg_logo_file2] = useState();
  const [img_bg_file2, setimg_bg_file2] = useState();


  const changeHandler2_logo = (event) => {
    hiddenFileInput_logo.current.click();
  };

  const [Delete_logo_Img_api, setDelete_logo_Img_api] = useState();
  const [Delete_logo_Img_result, setDelete_logo_Img_result] = useState();
  const [Delete_logo_Img_message, setDelete_logo_Img_message] = useState();
  function Delete_logo_Img() {
    setLoading(true);
    const formData = new FormData();

    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);


    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/Lms_Delete_Logo_File/",
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message']);

        if (String(result['status']) === String("true")) {
          setDelete_logo_Img_api("true");
          setPartner_Catalog_Pub_logo('');
          alert(" Le logo a été correctement supprimé ");

        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        }

        else {
          setDelete_logo_Img_api("false");
          setDelete_logo_Img_message(result['message']);
        }
        setLoading(false);

      })
      .catch((error) => {
        console.error('Error:', result);
        setDelete_logo_Img_api("false");
        setDelete_logo_Img_message(" Impossible de supprimer le fichier logo");
        setLoading(false);
      });
  }

  const [img_logo_file, setimg_logo_file] = useState();
  const [Send_logo_Img_api, setSend_logo_Img_api] = useState();
  const [Send_logo_Img_result, setSend_logo_Img_result] = useState();
  const [Send_logo_Img_message, setSend_logo_Img_message] = useState();
  function Send_logo_Img() {
    setLoading(true);


    const formData = new FormData();
    formData.append('File', img_logo_file2);
    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);


    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/Lms_Add_Logo_File/",
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message']);

        if (String(result['status']) === String("true")) {
          setSend_logo_Img_api("true");
          alert(" La mise à jour du logo a été correctement effectuée. ");

        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        }

        else {
          setSend_logo_Img_api("false");
          setSend_logo_Img_message(result['message']);
        }
        setLoading(false);

      })
      .catch((error) => {
        console.error('Error:', result);
        setSend_logo_Img_api("false");
        setSend_logo_Img_message(result['message']);
        setLoading(false);
      });

  }


  const handleSubmission_logo = event => {
    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;

    sethandleSubmission_logo_message("");
    sethandleSubmission_logo_api("false");


    //console.log("file_size =  ", file_size, " file_type = ", file_type);
    //console.log(" fileUploaded = ", fileUploaded);
    if (file_size > 1000000) {
      alert("Le fichier ne doit pas dépasser un 1 Méga octets");
      return;
    }

    //image/png
    if (String(file_type) !== "image/png") {
      alert("Le fichier n'est pas au format PNG");
      return;
    }

    setLoading(true);

    var _URL = window.URL || window.webkitURL;
    var file, img;
    var img_height, img_width;

    if ((file = event.target.files[0])) {
      img = new Image();
      var objectUrl = _URL.createObjectURL(file);
      img.onload = function () {
        img_height = this.height;
        img_width = this.width;
        _URL.revokeObjectURL(objectUrl);
        // console.log(" img_width = ", img_width, "  img_height = ", img_height)

        if (parseFloat(img_width) > 130 && parseFloat(img_width) < 260 &&
          parseFloat(img_height) > 60 && parseFloat(img_height) < 150) {
          //console.log(" img_height est = ", img_height, " < à 1500 ");

          // Verification que le nom du fichier est alpha numerique
          /*var regExp = /^[A-Za-z0-9]+$/;
          if (!regExp.test(event.target.files[0].name)) {
              sethandleSubmission_logo_message(" Le nom de l'image comporte des caractères non alphanumériques");
              sethandleSubmission_logo_api("false");
              setLoading(false);

              return
          }*/


          if (String(event.target.files[0].name).length > 50) {
            sethandleSubmission_logo_message(" Le nom du fichier est trop long. il doit faire moins de 50 caractère");
            sethandleSubmission_logo_api("false");
            setLoading(false);

            return
          }

          setlogo_file_name(event.target.files[0].name);
          // setimg_logo_file(URL.createObjectURL(event.target.files[0]));
          setimg_logo_file2(event.target.files[0]);

          reader(event.target.files[0], (err, res) => {
            // console.log("#### res === ", res); // Base64 `data:image/...` String result.
            setimg_logo_file(res);
          });


        } else {
          sethandleSubmission_logo_message(" La taille de l'image est incorrecte.  Larg = " + String(img_width) + " px Long = " + String(img_height) + " px");
          sethandleSubmission_logo_api("false");
          setLoading(false);
          return;
        }

        //console.log(" after img_width = ", img_width, "  after img_height = ", img_height)
      };
      img.src = objectUrl;

    }
    setLoading(false);
  };


  const [Delete_Catalog_Pub_Banniere_Img_api, setDelete_Catalog_Pub_Banniere_Img_api] = useState();
  const [Delete_Catalog_Pub_Banniere_Img_message, setDelete_Catalog_Pub_Banniere_Img_message] = useState();
  const [Delete_Catalog_Pub_Banniere_Img_result, setDelete_Catalog_Pub_Banniere_Img_result] = useState();
  function Delete_Catalog_Pub_Banniere_Img() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("banniere_img", "");

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Delete_Catalog_Pub_Banniere_Img  res.data.status = " + res.data.status);
      //console.log(" In Delete_Catalog_Pub_Banniere_Img  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setDelete_Catalog_Pub_Banniere_Img_api("true");
        setDelete_Catalog_Pub_Banniere_Img_result(res.data.message);

        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setDelete_Catalog_Pub_Banniere_Img_api("false");
        setDelete_Catalog_Pub_Banniere_Img_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Delete_Catalog_Pub_Banniere_Img = ' + error);
      setDelete_Catalog_Pub_Banniere_Img_api("false");
      alert(" Impossible de supprimer la bannière ");

    })
  }


  const [Delete_Catalog_Pub_Logo_Img_api, setDelete_Catalog_Pub_Logo_Img_api] = useState();
  const [Delete_Catalog_Pub_Logo_Img_message, setDelete_Catalog_Pub_Logo_Img_message] = useState();
  const [Delete_Catalog_Pub_Logo_Img_result, setDelete_Catalog_Pub_Logo_Img_result] = useState();
  function Delete_Catalog_Pub_Logo_Img() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("logo", "");

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Delete_Catalog_Pub_Logo_Img  res.data.status = " + res.data.status);
      //console.log(" In Delete_Catalog_Pub_Logo_Img  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setDelete_Catalog_Pub_Logo_Img_api("true");
        setDelete_Catalog_Pub_Logo_Img_result(res.data.message);

        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setDelete_Catalog_Pub_Logo_Img_api("false");
        setDelete_Catalog_Pub_Logo_Img_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Delete_Catalog_Pub_Logo_Img = ' + error);
      setDelete_Catalog_Pub_Logo_Img_api("false");
      alert(" Impossible de supprimer la bannière ");

    })
  }


  const [Add_Update_Catalog_Pub_Banniere_api, setAdd_Update_Catalog_Pub_Banniere_api] = useState();
  const [Add_Update_Catalog_Pub_Banniere_message, setAdd_Update_Catalog_Pub_Banniere_message] = useState();
  const [Add_Update_Catalog_Pub_Banniere_result, setAdd_Update_Catalog_Pub_Banniere_result] = useState();
  function Add_Update_Catalog_Pub_Banniere() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("banniere_img", img_bg_file);

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Add_Update_Catalog_Pub_Banniere  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Catalog_Pub_Banniere  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Catalog_Pub_Banniere_api("true");
        setAdd_Update_Catalog_Pub_Banniere_result(res.data.message);

        setimg_bg_file("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Catalog_Pub_Banniere_api("false");
        setAdd_Update_Catalog_Pub_Banniere_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Add_Update_Catalog_Pub_Banniere = ' + error);
      setAdd_Update_Catalog_Pub_Banniere_api("false");
      alert(" Impossible de supprimer la bannière ");

    })
  }


  const [Add_Update_Catalog_Pub_Logo_api, setAdd_Update_Catalog_Pub_Logo_api] = useState();
  const [Add_Update_Catalog_Pub_Logo_message, setAdd_Update_Catalog_Pub_Logo_message] = useState();
  const [Add_Update_Catalog_Pub_Logo_result, setAdd_Update_Catalog_Pub_Logo_result] = useState();
  function Add_Update_Catalog_Pub_Logo() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("logo", img_logo_file);

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Add_Update_Catalog_Pub_Logo  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Catalog_Pub_Logo  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Catalog_Pub_Logo_api("true");
        setAdd_Update_Catalog_Pub_Logo_result(res.data.message);

        setimg_logo_file("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Catalog_Pub_Logo_api("false");
        setAdd_Update_Catalog_Pub_Logo_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Add_Update_Catalog_Pub_Logo = ' + error);
      setAdd_Update_Catalog_Pub_Logo_api("false");
      alert(" Impossible de supprimer la bannière ");

    })
  }



  function Edit_Catalog_Config_Fields() {
    setcatalog_pub_config_edit("1");
    Enable_Suivi_Pedago_Fields();

  }


  function Annule_Edit_Catalog_Config_Fields() {
    setcatalog_pub_config_edit("");
    Get_Partner_Catalog_Pub_Config();
  }


  function disable_catalog_pub_fields() {

    setcatalog_pub_config_edit("");
    const list_fields = ['config_text_1', 'config_text_2', 'config_text_3',
      'indicateur_text_1', 'indicateur_text_2', 'indicateur_text_3', 'indicateur_text_4'
    ]

    for (let i = 0; i < list_fields.length; i++) {
      if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
        document.getElementsByName(String(list_fields[i]))[0].disabled = true;
        document.getElementsByName(String(list_fields[i]))[0].style.backgroundColor = "#ECEFF1";

      }
    }
  }


  function Enable_Suivi_Pedago_Fields() {
    setcatalog_pub_config_edit("1");


    const list_fields = ['config_text_1', 'config_text_2', 'config_text_3',
      'indicateur_text_1', 'indicateur_text_2', 'indicateur_text_3', 'indicateur_text_4'
    ]

    for (let i = 0; i < list_fields.length; i++) {
      if (document.getElementsByName(String(list_fields[i])) && document.getElementsByName(String(list_fields[i]))[0]) {
        document.getElementsByName(String(list_fields[i]))[0].disabled = false;
        document.getElementsByName(String(list_fields[i]))[0].style.backgroundColor = "#FFFFFF";

      }
    }
  }




  const [quel_text, setquel_text] = React.useState("");


  const [p_detail_cgv_file_pdf, setp_detail_cgv_file_pdf] = React.useState("");
  const [p_detail_cgu_file_pdf, setp_detail_cgu_file_pdf] = React.useState("");
  const [p_detail_mention_legale_file_pdf, setp_detail_mention_legale_file_pdf] = React.useState("");



  const [p_text2_taille, setp_text2_taille] = React.useState("12");
  const [p_text2_gras, setp_text2_gras] = React.useState(false);
  const [p_text2_color, setp_text2_color] = React.useState("#000000");

  const [p_text3_taille, setp_text3_taille] = React.useState("12");
  const [p_text3_gras, setp_text3_gras] = React.useState(false);
  const [p_text3_color, setp_text3_color] = React.useState("#000000");


  const [p_text1_taille, setp_text1_taille] = React.useState("12");
  const [p_text1_gras, setp_text1_gras] = React.useState(false);
  const [p_text1_color, setp_text1_color] = React.useState("#000000");


  const [p_indicateur1_text, setp_indicateur1_text] = React.useState("");
  const [p_indicateur1_taille, setp_indicateur1_taille] = React.useState("12");
  const [p_indicateur1_gras, setp_indicateur1_gras] = React.useState(false);
  const [p_indicateur1_color, setp_indicateur1_color] = React.useState("#000000");

  const [p_indicateur2_text, setp_indicateur2_text] = React.useState("");
  const [p_indicateur2_taille, setp_indicateur2_taille] = React.useState("12");
  const [p_indicateur2_gras, setp_indicateur2_gras] = React.useState(false);
  const [p_indicateur2_color, setp_indicateur2_color] = React.useState("#000000");

  const [p_indicateur3_text, setp_indicateur3_text] = React.useState("");
  const [p_indicateur3_taille, setp_indicateur3_taille] = React.useState("12");
  const [p_indicateur3_gras, setp_indicateur3_gras] = React.useState(false);
  const [p_indicateur3_color, setp_indicateur3_color] = React.useState("#000000");

  const [p_indicateur4_text, setp_indicateur4_text] = React.useState("");
  const [p_indicateur4_taille, setp_indicateur4_taille] = React.useState("12");
  const [p_indicateur4_gras, setp_indicateur4_gras] = React.useState(false);
  const [p_indicateur4_color, setp_indicateur4_color] = React.useState("#000000");


  const [Dialog_GRP_COLOR_message, setDialog_GRP_COLOR_message] = React.useState(false);
  const [Dialog_GRP_COLOR_open, setDialog_GRP_COLOR_open] = React.useState(false);


  const Dialog_GRP_COLOR_handleClose = () => {
    //alert(" Utiliser le bouton 'fermer' ");
    //setOpen(false);
  };

  const Dialog_GRP_COLOR_handleClose_buton = () => {
    setDialog_GRP_COLOR_open(false);

  };



  /// ======= CGV

  const changeHandler2_cgv = (event) => {
    hiddenFileInput_cgv.current.click();
  };


  const [cgv_file_name, setcgv_file_name] = useState();
  const hiddenFileInput_cgv = React.useRef(null);
  const [handleSubmission_cgv_api, sethandleSubmission_cgv_api] = useState();
  const [handleSubmission_cgv_result, sethandleSubmission_cgv_result] = useState();
  const [handleSubmission_cgv_message, sethandleSubmission_cgv_message] = useState();

  const handleSubmission_cgv = event => {
    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;

    sethandleSubmission_cgv_message("");

    sethandleSubmission_cgv_api("");

    // console.log("file_size =  ", file_size, " file_type = ", file_type, " naturalWidth = ", fileUploaded.naturalWidth);
    if (file_size > 1000000) {
      alert("Le fichier ne doit pas dépasser un 1 Méga octets");
      return;
    }

    setcgv_file_name(event.target.files[0].name);

    reader(event.target.files[0], (err, res) => {
      // console.log("#### res === ", res); // Base64 `data:image/...` String result.
      setpdf_cgv_file_pdf(res);
    });



  };


  const [pdf_cgv_file_pdf, setpdf_cgv_file_pdf] = useState();

  const [Add_Update_Catalog_Pub_CGV_api, setAdd_Update_Catalog_Pub_CGV_api] = useState();
  const [Add_Update_Catalog_Pub_CGV_message, setAdd_Update_Catalog_Pub_CGV_message] = useState();
  const [Add_Update_Catalog_Pub_CGV_result, setAdd_Update_Catalog_Pub_CGV_result] = useState();
  function Add_Update_Catalog_Pub_CGV() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("cgv_pdf", pdf_cgv_file_pdf);

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Add_Update_Catalog_Pub_CGV  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Catalog_Pub_CGV  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Catalog_Pub_CGV_api("true");
        setAdd_Update_Catalog_Pub_CGV_result(res.data.message);

        setcgv_file_name("");
        setpdf_cgv_file_pdf("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Catalog_Pub_CGV_api("false");
        setAdd_Update_Catalog_Pub_CGV_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Add_Update_Catalog_Pub_CGV = ' + error);
      setAdd_Update_Catalog_Pub_CGV_api("false");
      alert(" Impossible de supprimer le fichier CGV ");

    })
  }

  function Cancel_Add_Update_Catalog_Pub_CGV() {
    setpdf_cgv_file_pdf("");
  }

  const [Supprimer_Catalog_Pub_CGV_api, setSupprimer_Catalog_Pub_CGV_api] = useState();
  const [Supprimer_Catalog_Pub_CGV_message, setSupprimer_Catalog_Pub_CGV_message] = useState();
  const [Supprimer_Catalog_Pub_CGV_result, setSupprimer_Catalog_Pub_CGV_result] = useState();
  function Supprimer_Catalog_Pub_CGV() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("cgv_pdf", "");

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Supprimer_Catalog_Pub_CGV  res.data.status = " + res.data.status);
      //console.log(" In Supprimer_Catalog_Pub_CGV  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setSupprimer_Catalog_Pub_CGV_api("true");
        setSupprimer_Catalog_Pub_CGV_result(res.data.message);

        setcgv_file_name("");
        setpdf_cgv_file_pdf("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setSupprimer_Catalog_Pub_CGV_api("false");
        setSupprimer_Catalog_Pub_CGV_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Supprimer_Catalog_Pub_CGV = ' + error);
      setSupprimer_Catalog_Pub_CGV_api("false");
      alert(" Impossible de supprimer le fichier CGV ");

    })
  }


  function Display_CGV_File() {

    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";
    downloadLink.href = p_detail_cgv_file_pdf;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  // === CGU
  const changeHandler2_cgu = (event) => {
    hiddenFileInput_cgu.current.click();
  };


  const [cgu_file_name, setcgu_file_name] = useState();
  const hiddenFileInput_cgu = React.useRef(null);
  const [handleSubmission_cgu_api, sethandleSubmission_cgu_api] = useState();
  const [handleSubmission_cgu_result, sethandleSubmission_cgu_result] = useState();
  const [handleSubmission_cgu_message, sethandleSubmission_cgu_message] = useState();

  const handleSubmission_cgu = event => {
    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;

    sethandleSubmission_cgu_message("");

    sethandleSubmission_cgu_api("");

    // console.log("file_size =  ", file_size, " file_type = ", file_type, " naturalWidth = ", fileUploaded.naturalWidth);
    if (file_size > 1000000) {
      alert("Le fichier ne doit pas dépasser un 1 Méga octets");
      return;
    }

    setcgu_file_name(event.target.files[0].name);

    reader(event.target.files[0], (err, res) => {
      // console.log("#### res === ", res); // Base64 `data:image/...` String result.
      setpdf_cgu_file_pdf(res);
    });



  };


  const [pdf_cgu_file_pdf, setpdf_cgu_file_pdf] = useState();

  const [Add_Update_Catalog_Pub_CGU_api, setAdd_Update_Catalog_Pub_CGU_api] = useState();
  const [Add_Update_Catalog_Pub_CGU_message, setAdd_Update_Catalog_Pub_CGU_message] = useState();
  const [Add_Update_Catalog_Pub_CGU_result, setAdd_Update_Catalog_Pub_CGU_result] = useState();
  function Add_Update_Catalog_Pub_CGU() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("cgu_pdf", pdf_cgu_file_pdf);

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Add_Update_Catalog_Pub_CGU  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Catalog_Pub_CGU  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Catalog_Pub_CGU_api("true");
        setAdd_Update_Catalog_Pub_CGU_result(res.data.message);

        setcgv_file_name("");
        setpdf_cgu_file_pdf("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Catalog_Pub_CGU_api("false");
        setAdd_Update_Catalog_Pub_CGU_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Add_Update_Catalog_Pub_CGU = ' + error);
      setAdd_Update_Catalog_Pub_CGU_api("false");
      alert(" Impossible de supprimer le fichier CGV ");

    })
  }


  function Cancel_Add_Update_Catalog_Pub_CGU() {
    setpdf_cgu_file_pdf("");
  }

  const [Supprimer_Catalog_Pub_CGU_api, setSupprimer_Catalog_Pub_CGU_api] = useState();
  const [Supprimer_Catalog_Pub_CGU_message, setSupprimer_Catalog_Pub_CGU_message] = useState();
  const [Supprimer_Catalog_Pub_CGU_result, setSupprimer_Catalog_Pub_CGU_result] = useState();
  function Supprimer_Catalog_Pub_CGU() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("cgu_pdf", "");

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Supprimer_Catalog_Pub_CGU  res.data.status = " + res.data.status);
      //console.log(" In Supprimer_Catalog_Pub_CGU  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setSupprimer_Catalog_Pub_CGU_api("true");
        setSupprimer_Catalog_Pub_CGU_result(res.data.message);

        setcgv_file_name("");
        setpdf_cgu_file_pdf("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setSupprimer_Catalog_Pub_CGU_api("false");
        setSupprimer_Catalog_Pub_CGU_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Supprimer_Catalog_Pub_CGU = ' + error);
      setSupprimer_Catalog_Pub_CGU_api("false");
      alert(" Impossible de supprimer le fichier CGU ");

    })
  }


  function Display_CGU_File() {

    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";
    downloadLink.href = p_detail_cgu_file_pdf;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  // ==> Mentions Legales
  const changeHandler2_mention_legale = (event) => {
    hiddenFileInput_mention_legale.current.click();
  };


  const [mention_legale_file_name, setmention_legale_file_name] = useState();
  const hiddenFileInput_mention_legale = React.useRef(null);
  const [handleSubmission_mention_legale_api, sethandleSubmission_mention_legale_api] = useState();
  const [handleSubmission_mention_legale_result, sethandleSubmission_mention_legale_result] = useState();
  const [handleSubmission_mention_legale_message, sethandleSubmission_mention_legale_message] = useState();

  function Cancel_Add_Update_Catalog_Pub_Mention_Legale() {
    setpdf_mention_legale_file_pdf("");
  }

  const handleSubmission_mention_legale = event => {
    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;

    sethandleSubmission_mention_legale_message("");

    sethandleSubmission_mention_legale_api("");

    // console.log("file_size =  ", file_size, " file_type = ", file_type, " naturalWidth = ", fileUploaded.naturalWidth);
    if (file_size > 1000000) {
      alert("Le fichier ne doit pas dépasser un 1 Méga octets");
      return;
    }

    setmention_legale_file_name(event.target.files[0].name);

    reader(event.target.files[0], (err, res) => {
      // console.log("#### res === ", res); // Base64 `data:image/...` String result.
      setpdf_mention_legale_file_pdf(res);
    });



  };


  const [pdf_mention_legale_file_pdf, setpdf_mention_legale_file_pdf] = useState();

  const [Add_Update_Catalog_Pub_Mention_Legale_api, setAdd_Update_Catalog_Pub_Mention_Legale_api] = useState();
  const [Add_Update_Catalog_Pub_Mention_Legale_message, setAdd_Update_Catalog_Pub_Mention_Legale_message] = useState();
  const [Add_Update_Catalog_Pub_Mention_Legale_result, setAdd_Update_Catalog_Pub_Mention_Legale_result] = useState();
  function Add_Update_Catalog_Pub_Mention_Legale() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("mention_legale_pdf", pdf_mention_legale_file_pdf);

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Add_Update_Catalog_Pub_CGU  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Catalog_Pub_CGU  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Catalog_Pub_Mention_Legale_api("true");
        setAdd_Update_Catalog_Pub_Mention_Legale_result(res.data.message);

        setcgv_file_name("");
        setpdf_mention_legale_file_pdf("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Catalog_Pub_Mention_Legale_api("false");
        setAdd_Update_Catalog_Pub_Mention_Legale_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Add_Update_Catalog_Pub_CGU = ' + error);
      setAdd_Update_Catalog_Pub_Mention_Legale_api("false");
      alert(" Impossible de supprimer le fichier CGV ");

    })
  }

  const [Supprimer_Catalog_Pub_Mention_Legale_api, setSupprimer_Catalog_Pub_Mention_Legale_api] = useState();
  const [Supprimer_Catalog_Pub_Mention_Legale_message, setSupprimer_Catalog_Pub_Mention_Legale_message] = useState();
  const [Supprimer_Catalog_Pub_Mention_Legale_result, setSupprimer_Catalog_Pub_Mention_Legale_result] = useState();
  function Supprimer_Catalog_Pub_Mention_Legale() {
    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("_id", selected_catalog_pub_config_id);
    form.append("mention_legale_pdf", "");

    //console.log(" form == ", form);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Catalog_Pub_Config/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      //console.log(" In Supprimer_Catalog_Pub_CGU  res.data.status = " + res.data.status);
      //console.log(" In Supprimer_Catalog_Pub_CGU  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setSupprimer_Catalog_Pub_Mention_Legale_api("true");
        setSupprimer_Catalog_Pub_Mention_Legale_result(res.data.message);

        setcgv_file_name("");
        setpdf_mention_legale_file_pdf("");
        Get_Partner_Catalog_Pub_Config();
        alert(res.data.message);

      }
      else {
        setSupprimer_Catalog_Pub_Mention_Legale_api("false");
        setSupprimer_Catalog_Pub_Mention_Legale_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('UpdateStagiaireData : Not good man :( Supprimer_Catalog_Pub_CGU = ' + error);
      setSupprimer_Catalog_Pub_Mention_Legale_api("false");
      alert(" Impossible de supprimer le fichier CGU ");

    })
  }


  function Display_Mention_Legale_File() {

    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";
    downloadLink.href = p_detail_mention_legale_file_pdf;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <div className='updateparnterinfo'>


      {/*** Dialog pickup Color */}

      <Dialog
        open={Dialog_GRP_COLOR_open}
        // onClose={Dialog_GRP_COLOR_handleClose}


        static
        onClose={() => null}

        PaperProps={{


          className: classes.paper, // Apply the paper style
          style: {
            overflowY: 'unset',
            position: 'absolute',
            //top: '5rem'
          },
        }}
        hideBackdrop={true}
        disableScrollLock
        classes={{
          paper: classes.dialog
        }}
        className="groupe_apprenant_dialog"

      >

        <DialogTitle>  Choisir une couleur</DialogTitle>
        <DialogContent className="DialogContent_color_pickup_width" >
          <DialogContentText>

          </DialogContentText>

          <div className="session_caract"> Code couleur  <br />
            <ColorPicker
              color="#3573CB"
              theme={{
                background: 'lightgrey',
                inputBackground: 'grey',
                borderColor: 'darkgrey',
                borderRadius: '8px',
                color: 'black',
                width: '100px'
              }}
              name="detail_grp_color"
              id="detail_grp_color"
              onChange={(color) => {
                if (String(quel_text) === "1")
                  setp_text1_color(color.hex);

                else if (String(quel_text) === "2")
                  setp_text2_color(color.hex);

                else if (String(quel_text) === "3")
                  setp_text3_color(color.hex);
              }
              }
            />



          </div>
        </DialogContent>

        <DialogActions>

          <IconButton
            autoFocus
            onClick={Dialog_GRP_COLOR_handleClose_buton}
            color="primary"
            className={classes.customizedButton}
          >
            <CancelPresentationIcon />
          </IconButton>

        </DialogActions>





      </Dialog>

      {/*** Fin ajout  pickup Color */}

      <div className="div_row">
        {String(is_partner_admin_account) !== "1" && <Button variant="outlined" onClick={submenu_donnee_entreprise} className="detail_submenu" id='submenu_donnee_entreprise' name='submenu_donnee_entreprise'>DONNEES PERSONNELLES</Button>}

        {String(is_partner_admin_account) === "1" && <Button variant="outlined" onClick={submenu_donnee_entreprise} className="detail_submenu" id='submenu_donnee_entreprise' name='submenu_donnee_entreprise'>DONNEES ENTREPRISE</Button>}
        {String(is_partner_admin_account) === "1" && <Button variant="outlined" onClick={submenu_donnee_facturation} className="detail_submenu" id='submenu_donnee_facturation' name='submenu_donnee_facturation'>DONNEES FACTURATION</Button>}
        <Button variant="outlined" onClick={submenu_donnee_securite} className="detail_submenu" id='submenu_donnee_securite' name='submenu_donnee_securite'>SECURITE</Button>
        {/*<Button variant="outlined" onClick={submenu_contact} className="detail_submenu" id='submenu_contact' name='submenu_contact'>Contact & Adresses</Button>*/}
        {String(is_partner_admin_account) === "1" && <Button variant="outlined" onClick={submenu_donnee_catalog_pub} className="detail_submenu" id='submenu_donnee_catalog_pub' name='submenu_donnee_catalog_pub'>CATALOG. PUB</Button>}

      </div>

      {String(submenu).trim() === "submenu_donnee_securite" && <div className="training_data">

        <Partner_Securite_Data partner_account_id={partner_account_id} />
      </div>}

      {String(submenu).trim() === "submenu_donnee_facturation" && <div className="training_data">
        <Partner_Invoicing_Data partner_account_id={partner_account_id} />
      </div>}

      {String(submenu).trim() === "submenu_contact" && <div className="training_data">

      </div>}

      {String(submenu).trim() === "submenu_donnee_catalog_pub" && <div className="training_data">
        <h3> Configuration du Catalogue public </h3>


        <div className="partner_catalog_pub">
          <div className="titre1"> <b> Lien web (url) </b> </div>
          <div className="div_row">
            <TextField
              name="config_url"
              label="lien web (url)  catalogue "
              className="texte_area"
              sx={{ m: 1, width: '48%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                ),
              }}
              value={partner_catalog_pub_url}

            /> <Button

              onClick={(event) => {
                window.open(
                  String(partner_catalog_pub_url),
                  '_blank'
                );
              }}

              style={{ marginTop: '10px' }}
            >
              <OpenInNewIcon />

            </Button>

          </div>
          <div className="div_row">
            <hr className="hr_break" />
          </div>


          {String(submenu_catalog_pub) === String("design") &&


            <div style={{ "border": "None" }}>

              <div className="div_row">
                <div className="titre1"> <b> Thème  </b> </div>

                {String(catalog_pub_config_edit) === "1" && <div>
                  <div className="session_caract">Choisir un exemple de thème : <br />

                    <Autocomplete
                      disablePortal
                      name="config_theme"
                      id="config_theme"
                      className="disabled_style"
                      options={NEW_Get_List_Catalog_Public_Themes_result}
                      value={NEW_Get_List_Catalog_Public_Themes_result.filter((data) => (data)._id === String(selected_catalog_pub_theme_id))[0].label}
                      onChange={(event, value) => {
                        if (value && value._id) {
                          setselected_catalog_pub_theme_id(value._id);

                          var partner_logo_img = "data:image/png;base64," + value.theme_preview;
                          setselected_catalog_pub_theme_preview(partner_logo_img);


                        } else {
                          setselected_catalog_pub_theme_id('');
                          setselected_catalog_pub_theme_preview('')
                        }
                      }}
                      //value={p_one_detail_client_rattachement_nom}

                      renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                      />
                      }
                    />

                  </div>
                  <br />
                  <div className="div_row">
                    {selected_catalog_pub_theme_preview && <div className="session_caract">
                      <img className="preview_certif" src={selected_catalog_pub_theme_preview} />
                    </div>}
                  </div>


                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text 1 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="config_text_1"
                        id="config_text_1"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_catalog_pub_message_1_txt}
                        onChange={(e) => {
                          setp_catalog_pub_message_1_txt(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques</b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="text1_taille"
                            id="text1_taille"
                            type="number"
                            fullWidth
                            value={p_text1_taille}
                            onChange={(e) => {
                              setp_text1_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_text1_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("1");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="text1_gras"
                            // className="disabled_style"
                            checked={p_text1_gras}
                            onChange={(e) => {
                              setp_text1_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }} > <b>Text 2 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="config_text_2"
                        id="config_text_2"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}
                        value={p_catalog_pub_message_2_txt}
                        onChange={(e) => {
                          setp_catalog_pub_message_2_txt(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>

                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="text1_taille"
                            id="text1_taille"
                            type="number"
                            fullWidth
                            value={p_text2_taille}
                            onChange={(e) => {
                              setp_text2_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_text2_color, cursor: 'pointer',
                          borderRadius: '5px', paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("2");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="text2_gras"
                            // className="disabled_style"
                            checked={p_text2_gras}
                            onChange={(e) => {
                              setp_text2_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>



                    </div>
                  </div>


                  <div className="div_row">

                    <div style={{ width: "70%", float: 'left', }} > <b>Text 3</b> <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="config_text_3"
                        id="config_text_3"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}
                        value={p_catalog_pub_message_3_txt}
                        onChange={(e) => {
                          setp_catalog_pub_message_3_txt(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>

                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="text1_taille"
                            id="text1_taille"
                            type="number"
                            fullWidth
                            value={p_text3_taille}
                            onChange={(e) => {
                              setp_text3_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_text3_color, cursor: 'pointer',
                          borderRadius: '5px', paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("3");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="text2_gras"
                            // className="disabled_style"
                            checked={p_text3_gras}
                            onChange={(e) => {
                              setp_text3_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>



                    </div>

                  </div>


                  <hr className="hr_break" />
                  <div className="titre1"> <b> Les indicateurs  zzzz</b> </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 1 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_1"
                        id="indicateur_text_1"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur1_text}
                        onChange={(e) => {
                          setp_indicateur1_text(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques</b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur1_taille"
                            id="indicateur1_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur1_taille}
                            onChange={(e) => {
                              setp_indicateur1_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur1_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("indiq_1");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur1_gras"
                            // className="disabled_style"
                            checked={p_indicateur1_gras}
                            onChange={(e) => {
                              setp_indicateur1_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 2 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_2"
                        id="indicateur_text_2"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur2_text}
                        onChange={(e) => {
                          setp_indicateur2_text(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur2_taille"
                            id="indicateur2_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur2_taille}
                            onChange={(e) => {
                              setp_indicateur2_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur2_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("indiq_2");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur2_gras"
                            // className="disabled_style"
                            checked={p_indicateur2_gras}
                            onChange={(e) => {
                              setp_indicateur2_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 3 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_3"
                        id="indicateur_text_3"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur3_text}
                        onChange={(e) => {
                          setp_indicateur3_text(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur3_taille"
                            id="indicateur3_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur3_taille}
                            onChange={(e) => {
                              setp_indicateur3_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur3_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("indiq_3");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur3_gras"
                            // className="disabled_style"
                            checked={p_indicateur3_gras}
                            onChange={(e) => {
                              setp_indicateur3_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 4 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_4"
                        id="indicateur_text_4"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur4_text}
                        onChange={(e) => {
                          setp_indicateur4_text(e.target.value);

                        }
                        }
                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur3_taille"
                            id="indicateur3_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur4_taille}
                            onChange={(e) => {
                              setp_indicateur4_taille(e.target.value);
                            }
                            }

                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur4_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                          onClick={(event, value) => {
                            setquel_text("indiq_4");
                            setDialog_GRP_COLOR_open(true)
                          }}> &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur4_gras"
                            // className="disabled_style"
                            checked={p_indicateur4_gras}
                            onChange={(e) => {
                              setp_indicateur4_gras(e.target.checked);
                            }}
                          />
                        </nav>
                      </div>

                    </div>

                  </div>


                </div>}

                {String(catalog_pub_config_edit) !== "1" && <div>

                  <div className="div_row">
                    {selected_catalog_pub_theme_preview && <div className="session_caract">
                      <img className="preview_certif" src={selected_catalog_pub_theme_preview} />
                    </div>}
                  </div>



                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }} > <b> Text 1 </b> <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="config_text_1"
                        id="config_text_1"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}
                        value={p_catalog_pub_message_1_txt}

                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>

                      <b> Caracteristiques</b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="text1_taille"
                            id="text1_taille"
                            type="text"
                            fullWidth
                            value={p_text1_taille}


                          />
                        </nav>
                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_text1_color, cursor: 'unset',
                          borderRadius: '5px', paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="text2_gras"
                            // className="disabled_style"
                            checked={p_text1_gras}

                          />
                        </nav>
                      </div>



                    </div>


                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b>Text 2 </b> <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="config_text_2"
                        id="config_text_2"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}
                        value={p_catalog_pub_message_2_txt}

                      />

                    </div>


                    <div style={{ width: "30%", float: 'left' }}>

                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="text1_taille"
                            id="text1_taille"
                            type="number"
                            fullWidth
                            value={p_text2_taille}


                          />
                        </nav>
                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_text2_color, cursor: 'unset',
                          borderRadius: '5px', paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="text2_gras"
                            // className="disabled_style"
                            checked={p_text2_gras}

                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }} > <b> Text 3 </b> <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="config_text_3"
                        id="config_text_3"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}
                        value={p_catalog_pub_message_3_txt}

                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>

                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="text1_taille"
                            id="text1_taille"
                            type="number"
                            fullWidth
                            value={p_text3_taille}


                          />
                        </nav>
                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_text3_color, cursor: 'unset',
                          borderRadius: '5px', paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="text2_gras"
                            // className="disabled_style"
                            checked={p_text3_gras}

                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <hr className="hr_break" />
                  <div className="titre1"> <b> Les indicateurs  zzzz22</b> </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 1 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_1"
                        id="indicateur_text_1"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur1_text}

                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur1_taille"
                            id="indicateur1_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur1_taille}


                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur1_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur1_gras"
                            // className="disabled_style"
                            checked={p_indicateur1_gras}

                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 2 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_2"
                        id="indicateur_text_2"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur2_text}

                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur2_taille"
                            id="indicateur2_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur2_taille}


                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur2_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur2_gras"
                            // className="disabled_style"
                            checked={p_indicateur2_gras}

                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 3 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_3"
                        id="indicateur_text_3"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur3_text}

                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur3_taille"
                            id="indicateur3_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur3_taille}


                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur3_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur3_gras"
                            // className="disabled_style"
                            checked={p_indicateur3_gras}

                          />
                        </nav>
                      </div>

                    </div>

                  </div>

                  <div className="div_row">
                    <div style={{ width: "70%", float: 'left', }}> <b> Text Indicateur 4 </b>  <br />
                      <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                        name="indicateur_text_4"
                        id="indicateur_text_4"
                        multiline
                        rows={2}
                        className="disabled_style_multiline"
                        inputProps={{ style: { height: '4rem' } }}

                        value={p_indicateur4_text}

                      />

                    </div>

                    <div style={{ width: "30%", float: 'left' }}>
                      <b> Caracteristiques </b>  <br />

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Taille (px) </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="indicateur3_taille"
                            id="indicateur3_taille"
                            type="number"
                            fullWidth
                            value={p_indicateur4_taille}


                          />
                        </nav>
                      </div>



                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>

                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Code couleur : </nav>

                        <nav style={{
                          paddingTop: '10px', width: "20%", float: 'left', backgroundColor: p_indicateur4_color, cursor: 'pointer', borderRadius: '5px',
                          paddingTop: '15px', border: '1px solid gray'
                        }}
                        > &nbsp;
                        </nav>

                      </div>

                      <div style={{ width: "100%", float: 'left', height: '3rem', marginBottom: "10px" }}>
                        <nav style={{ width: "50%", float: 'left', paddingTop: '10px' }}> Gras </nav>
                        <nav style={{ width: "20%", float: 'left' }}>
                          <Checkbox
                            name="indicateur4_gras"
                            // className="disabled_style"
                            checked={p_indicateur4_gras}

                          />
                        </nav>
                      </div>

                    </div>

                  </div>



                </div>}



                <div className="div_row">
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>
                  {String(catalog_pub_config_edit) !== "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                      &nbsp;
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                      <Button variant="contained" className="btn_modif" onClick={Edit_Catalog_Config_Fields}>Editer
                      </Button>


                    </div>

                  </div>}



                  {String(catalog_pub_config_edit) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                      <Button variant="contained" className="btn_modif" onClick={Annule_Edit_Catalog_Config_Fields}>Annuler
                      </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                      <Button variant="contained" className="btn_enreg" onClick={Add_Update_Catalog_Pub_Theme}>Enregistrer
                      </Button>


                    </div>

                  </div>}
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>

                </div>
              </div>

              <hr className="hr_break" />
              <div className="titre1"> <b> Gestion Bannière  </b> </div>

              <div style={{ "border": "None" }}>

                {Partner_Catalog_Pub_banniere && String(Partner_Catalog_Pub_banniere).length > 20 && <div className="div_row" style={{ "border": "None" }}>
                  Bannière actuelle : <br />
                  <img src={Partner_Catalog_Pub_banniere} alt={""} className="img_bg_file_css" />
                  <br />
                  <br />
                  <Button variant="contained" className="btn_enreg" onClick={Delete_Catalog_Pub_Banniere_Img}>Supprimer Bannière
                  </Button>
                </div>}
                <div className="div_row"> Import Bannière <br />

                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>

                  <Button variant="outlined" onClick={changeHandler2_bg} className="detail_class_submenu" style={{
                    "background": "#c8cfd5",
                    "minWidth": "15rem", "color": "black", "width": "auto"
                  }}
                    id='menu_import_formation' name='menu_import_formation'>Choisir une image bannière &nbsp; <br />

                  </Button><br />
                  <nav className="koUpdateData" style={{ "color": "orange" }}>
                    <Tooltip className="tooltip_css" id="tooltip_1" />


                    <a data-tooltip-id="tooltip_1" data-tooltip-html="Respecter ces critères vous aide à avoir un meilleur affichage">
                      <i> Important : Taille de la bannière : longueur entre  1500px et 1930px  et largeur entre 150px et 195px, au format PNG <br />
                        Le nom du fichier ne contient que des caractères alphanumériques </i>
                    </a>
                  </nav>

                  {bg_file_name && <nav className="okUpdateData"><i>{bg_file_name}</i></nav>}
                  <input type="file"
                    accept=".png"
                    ref={hiddenFileInput_bg}
                    style={{ display: 'none' }}
                    name="liste_formation_file"
                    onChange={handleSubmission_bg}
                  />

                  {img_bg_file && <nav> Fichier à importer : {img_bg_file.name}  <br />
                    <img src={img_bg_file} alt={""} className="img_bg_file_css" />

                    <br />
                  </nav>}


                  {String(SendBackground_Img_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
                  {String(SendBackground_Img_api) === "false" && <div className="koUpdateData"> {SendBackground_Img_message}</div>}

                  {String(handleSubmission_bg_api) === "false" && <div className="koUpdateData"> {handleSubmission_bg_message}</div>}

                  <br />

                  {img_bg_file && <div className="div_row">
                    <div className="div_row" style={{ "border": "None" }}>
                      &nbsp;
                    </div>
                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                      <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="btn_modif" onClick={"Annule_bg_img"}>Annuler
                        </Button>
                      </div>

                      <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="btn_enreg" onClick={Add_Update_Catalog_Pub_Banniere}>Enregistrer
                        </Button>


                      </div>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                      &nbsp;
                    </div>

                  </div>}
                </div>

              </div>


              <hr className="hr_break" />

              <div className="titre1"> <b>Gestion LOGO </b> </div>

              <div className="detail_class_submenu_data" style={{ "border": "None" }}>

                {Partner_Catalog_Pub_logo && String(Partner_Catalog_Pub_logo) !== "" && <div className="div_row" style={{ "border": "None" }}>
                  Logo actuel :<br />
                  <img src={Partner_Catalog_Pub_logo} alt={""} />
                  <br />
                  <br />
                  <Button variant="contained" className="btn_enreg" onClick={Delete_Catalog_Pub_Logo_Img}>Supprimer Logo
                  </Button>

                </div>}
                <div className="div_row"> Import du logo <br />
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>

                  <Button variant="outlined" onClick={changeHandler2_logo} className="detail_class_submenu" style={{
                    "background": "#c8cfd5",
                    "minWidth": "15rem", "color": "black", "width": "auto"
                  }}
                    id='menu_import_formation' name='menu_import_formation'>Choisir une image  &nbsp; <br />

                  </Button><br />
                  <nav className="koUpdateData" style={{ "color": "orange" }}>
                    <Tooltip className="tooltip_css" id="tooltip_2" />

                    <a data-tooltip-id="tooltip_2" data-tooltip-html="Respecter ces critères vous aide à avoir un meilleur affichage">
                      <i> Important : Taille du logo : longueur  entre  140px et 250px  et largeur entre 60px et 150px, au format PNG <br />
                        Le nom du fichier ne contient que des caractères alphanumériques </i>
                    </a>
                  </nav>
                  {logo_file_name && <nav className="okUpdateData"><i>{logo_file_name}</i></nav>}
                  <input type="file"
                    accept=".png"
                    ref={hiddenFileInput_logo}
                    style={{ display: 'none' }}
                    name="liste_formation_file"
                    onChange={handleSubmission_logo}
                  />

                  {img_logo_file && <nav> Fichier à importer : {img_logo_file.name}  <br />
                    <img src={img_logo_file} alt={""} />


                    <br />
                  </nav>}

                  {String(Send_logo_Img_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
                  {String(Send_logo_Img_api) === "false" && <div className="koUpdateData"> {Send_logo_Img_message}</div>}

                  {String(handleSubmission_logo_api) === "false" && <div className="koUpdateData"> {handleSubmission_logo_message}</div>}



                  <br />

                  {img_logo_file && <div className="div_row">
                    <div className="div_row" style={{ "border": "None" }}>
                      &nbsp;
                    </div>
                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                      <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="btn_modif" onClick={"Annule_logo_img"}>Annuler
                        </Button>
                      </div>

                      <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="btn_enreg" onClick={Add_Update_Catalog_Pub_Logo}>Enregistrer
                        </Button>



                      </div>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                      &nbsp;
                    </div>

                  </div>}
                </div>

              </div>

            </div>



          }

          {String(submenu_catalog_pub) === String("banniere") &&

            <div className="detail_class_submenu_data" style={{ "border": "None" }}>

              {Partner_Catalog_Pub_banniere && String(Partner_Catalog_Pub_banniere).length > 20 && <div className="div_row" style={{ "border": "None" }}>
                Bannière actuelle : <br />
                <img src={Partner_Catalog_Pub_banniere} alt={""} className="img_bg_file_css" />
                <br />
                <br />
                <Button variant="contained" className="btn_enreg" onClick={Delete_Catalog_Pub_Banniere_Img}>Supprimer Bannière
                </Button>
              </div>}
              <div className="div_row"> Import Bannière <br />

                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>

                <Button variant="outlined" onClick={changeHandler2_bg} className="detail_class_submenu" style={{
                  "background": "#c8cfd5",
                  "minWidth": "15rem", "color": "black", "width": "auto"
                }}
                  id='menu_import_formation' name='menu_import_formation'>Choisir une image bannière &nbsp; <br />

                </Button><br />
                <nav className="koUpdateData" style={{ "color": "orange" }}>
                  <Tooltip className="tooltip_css" id="tooltip_1" />


                  <a data-tooltip-id="tooltip_1" data-tooltip-html="Respecter ces critères vous aide à avoir un meilleur affichage">
                    <i> Important : Taille de la bannière : longueur entre  1500px et 1930px  et largeur entre 150px et 195px, au format PNG <br />
                      Le nom du fichier ne contient que des caractères alphanumériques </i>
                  </a>
                </nav>

                {bg_file_name && <nav className="okUpdateData"><i>{bg_file_name}</i></nav>}
                <input type="file"
                  accept=".png"
                  ref={hiddenFileInput_bg}
                  style={{ display: 'none' }}
                  name="liste_formation_file"
                  onChange={handleSubmission_bg}
                />

                {img_bg_file && <nav> Fichier à importer : {img_bg_file.name}  <br />
                  <img src={img_bg_file} alt={""} className="img_bg_file_css" />

                  <br />
                </nav>}


                {String(SendBackground_Img_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
                {String(SendBackground_Img_api) === "false" && <div className="koUpdateData"> {SendBackground_Img_message}</div>}

                {String(handleSubmission_bg_api) === "false" && <div className="koUpdateData"> {handleSubmission_bg_message}</div>}

                <br />

                <div className="div_row">
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>
                  {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                      <Button variant="contained" className="btn_modif" onClick={"Annule_bg_img"}>Annuler
                      </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                      <Button variant="contained" className="btn_enreg" onClick={Add_Update_Catalog_Pub_Banniere}>Enregistrer
                      </Button>


                    </div>

                  </div>}
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>

                </div>
              </div>

            </div>}

          {String(submenu_catalog_pub) === String("logo") &&
            <div className="detail_class_submenu_data" style={{ "border": "None" }}>

              {Partner_Catalog_Pub_logo && String(Partner_Catalog_Pub_logo) !== "" && <div className="div_row" style={{ "border": "None" }}>
                Logo actuel :<br />
                <img src={Partner_Catalog_Pub_logo} alt={""} />
                <br />
                <br />
                <Button variant="contained" className="btn_enreg" onClick={Delete_Catalog_Pub_Logo_Img}>Supprimer Logo
                </Button>

              </div>}
              <div className="div_row"> Import du logo <br />
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>

                <Button variant="outlined" onClick={changeHandler2_logo} className="detail_class_submenu" style={{
                  "background": "#c8cfd5",
                  "minWidth": "15rem", "color": "black", "width": "auto"
                }}
                  id='menu_import_formation' name='menu_import_formation'>Choisir une image  &nbsp; <br />

                </Button><br />
                <nav className="koUpdateData" style={{ "color": "orange" }}>
                  <Tooltip className="tooltip_css" id="tooltip_2" />

                  <a data-tooltip-id="tooltip_2" data-tooltip-html="Respecter ces critères vous aide à avoir un meilleur affichage">
                    <i> Important : Taille du logo : longueur  entre  140px et 250px  et largeur entre 60px et 150px, au format PNG <br />
                      Le nom du fichier ne contient que des caractères alphanumériques </i>
                  </a>
                </nav>
                {logo_file_name && <nav className="okUpdateData"><i>{logo_file_name}</i></nav>}
                <input type="file"
                  accept=".png"
                  ref={hiddenFileInput_logo}
                  style={{ display: 'none' }}
                  name="liste_formation_file"
                  onChange={handleSubmission_logo}
                />

                {img_logo_file && <nav> Fichier à importer : {img_logo_file.name}  <br />
                  <img src={img_logo_file} alt={""} />


                  <br />
                </nav>}

                {String(Send_logo_Img_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
                {String(Send_logo_Img_api) === "false" && <div className="koUpdateData"> {Send_logo_Img_message}</div>}

                {String(handleSubmission_logo_api) === "false" && <div className="koUpdateData"> {handleSubmission_logo_message}</div>}



                <br />

                <div className="div_row">
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>
                  {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                      <Button variant="contained" className="btn_modif" onClick={"Annule_logo_img"}>Annuler
                      </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                      <Button variant="contained" className="btn_enreg" onClick={Add_Update_Catalog_Pub_Logo}>Enregistrer
                      </Button>



                    </div>

                  </div>}
                  <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                  </div>

                </div>
              </div>

            </div>}

        </div>

        <hr className="hr_break" />
        <div className="titre1"> <b> Footer : Les pièces joints</b> </div>
        <div>


          <div className="detail_class_submenu_data" style={{ "border": "None", "width": "100%", "float": "left" }}>

            <div className="div_row block_cgv_pj">
              {p_detail_cgv_file_pdf && <div style={{ "marginBottom": "0.5rem" }}>
                <nav style={{ "width": "100%" }}> CGV actuelle(pdf)  <AttachFileIcon /> </nav>
                <div style={{ "width": "100%" }}>
                  <nav className="block_cgv_pj_telecharger" onClick={Display_CGV_File} >Télécharger <DownloadingIcon /> </nav> &nbsp;

                  <Popup
                    trigger={

                      <nav className="block_cgv_pj_supprimer" > Supprimer <CiTrash />  </nav>

                    }
                    modal
                    nested
                    position="center center"
                  >
                    {close => (
                      <div>
                        <button className="gest_close" onClick={close}>
                          &times;
                        </button>
                        <div className="gest_header"> MySy Information </div>
                        <div className="gest_content">
                          {' '}

                          En confirmant cette opération, le fichier sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                        </div>
                        <div className="gest_actions">
                          <div style={{ "width": "45%", "float": "left" }}>
                            <button className="gest_bton_popup" onClick={(event) => {
                              Supprimer_Catalog_Pub_CGV();

                              close();
                            }}> Valider </button>

                          </div>
                          <div style={{ "width": "45%", "float": "right" }}>
                            <button
                              className="gest_bton_popup"
                              onClick={() => {
                                //console.log('modal closed ');
                                close();
                              }}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>


                </div>

              </div>}

              <Button variant="outlined" onClick={changeHandler2_cgv} className="detail_class_submenu" style={{
                "background": "#c8cfd5",
                "minWidth": "15rem", "color": "black", "width": "auto"
              }}
                id='menu_import_formation' name='menu_import_formation'>Importer CGV (pdf)  &nbsp; <br />

              </Button><br />
              <nav className="koUpdateData" style={{ "color": "orange" }}>
                <Tooltip className="tooltip_css" id="tooltip_2" />


              </nav>
              {cgv_file_name && <nav className="okUpdateData"><i><AttachFileIcon /> {cgv_file_name}</i></nav>}
              <input type="file"
                accept=".pdf"
                ref={hiddenFileInput_cgv}
                style={{ display: 'none' }}
                name="liste_formation_file"
                onChange={handleSubmission_cgv}
              />



              {String(Add_Update_Catalog_Pub_CGV_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
              {String(Add_Update_Catalog_Pub_CGV_api) === "false" && <div className="koUpdateData"> {Add_Update_Catalog_Pub_CGV_message}</div>}

              <br />

              {pdf_cgv_file_pdf && <div className="div_row">
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>
                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                  <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px', maxWidth: '40%' }}>
                    <Button variant="contained" className="btn_modif" onClick={Cancel_Add_Update_Catalog_Pub_CGV}>Annuler
                    </Button>
                  </div>

                  <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px', maxWidth: '40%' }}>
                    <Button variant="contained" className="btn_enreg" onClick={Add_Update_Catalog_Pub_CGV}>Enregistrer
                    </Button>

                  </div>

                </div>}
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>

              </div>}
            </div>

            <div className="div_row block_cgv_pj" >
              {p_detail_cgu_file_pdf && <div style={{ "marginBottom": "0.5rem" }}>
                <nav style={{ "width": "100%" }}> CGU actuelle(pdf)  <AttachFileIcon /> </nav>
                <div style={{ "width": "100%" }}>
                  <nav className="block_cgv_pj_telecharger" onClick={Display_CGU_File} >Télécharger <DownloadingIcon /> </nav> &nbsp;

                  <Popup
                    trigger={

                      <nav className="block_cgv_pj_supprimer"  > Supprimer <CiTrash />  </nav>

                    }
                    modal
                    nested
                    position="center center"
                  >
                    {close => (
                      <div>
                        <button className="gest_close" onClick={close}>
                          &times;
                        </button>
                        <div className="gest_header"> MySy Information </div>
                        <div className="gest_content">
                          {' '}

                          En confirmant cette opération, le fichier sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                        </div>
                        <div className="gest_actions">
                          <div style={{ "width": "45%", "float": "left" }}>
                            <button className="gest_bton_popup" onClick={(event) => {
                              Supprimer_Catalog_Pub_CGU();

                              close();
                            }}> Valider </button>

                          </div>
                          <div style={{ "width": "45%", "float": "right" }}>
                            <button
                              className="gest_bton_popup"
                              onClick={() => {
                                //console.log('modal closed ');
                                close();
                              }}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>


                </div>

              </div>}

              <Button variant="outlined" onClick={changeHandler2_cgu} className="detail_class_submenu" style={{
                "background": "#c8cfd5",
                "minWidth": "15rem", "color": "black", "width": "auto"
              }}
                id='menu_import_formation' name='menu_import_formation'>Importer CGU (pdf)  &nbsp; <br />

              </Button><br />
              <nav className="koUpdateData" style={{ "color": "orange" }}>
                <Tooltip className="tooltip_css" id="tooltip_2" />


              </nav>
              {cgu_file_name && <nav className="okUpdateData"><i><AttachFileIcon /> {cgu_file_name}</i></nav>}
              <input type="file"
                accept=".pdf"
                ref={hiddenFileInput_cgu}
                style={{ display: 'none' }}
                name="liste_formation_file"
                onChange={handleSubmission_cgu}
              />



              {String(Add_Update_Catalog_Pub_CGU_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
              {String(Add_Update_Catalog_Pub_CGU_api) === "false" && <div className="koUpdateData"> {Add_Update_Catalog_Pub_CGU_message}</div>}

              <br />

              {pdf_cgu_file_pdf && <div className="div_row ">
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>
                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                  <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px', maxWidth: '40%' }}>
                    <Button variant="contained" className="btn_modif" style={{ maxWidth: '80%' }} onClick={Cancel_Add_Update_Catalog_Pub_CGU}>Annuler
                    </Button>
                  </div>

                  <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px', maxWidth: '40%' }}>
                    <Button variant="contained" className="btn_enreg" style={{ maxWidth: '80%' }} onClick={Add_Update_Catalog_Pub_CGU}>Enregistrer
                    </Button>

                  </div>

                </div>}
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>

              </div>}
            </div>

            <div className="div_row block_cgv_pj" >
              {p_detail_mention_legale_file_pdf && <div style={{ "marginBottom": "0.5rem" }}>
                <nav style={{ "width": "100%" }}> Mentions légales actuelle(pdf)  <AttachFileIcon /> </nav>
                <div style={{ "width": "100%" }}>
                  <nav className="block_cgv_pj_telecharger" onClick={Display_Mention_Legale_File} >Télécharger <DownloadingIcon /> </nav> &nbsp;

                  <Popup
                    trigger={

                      <nav className="block_cgv_pj_supprimer"  > Supprimer <CiTrash />  </nav>

                    }
                    modal
                    nested
                    position="center center"
                  >
                    {close => (
                      <div>
                        <button className="gest_close" onClick={close}>
                          &times;
                        </button>
                        <div className="gest_header"> MySy Information </div>
                        <div className="gest_content">
                          {' '}

                          En confirmant cette opération, le fichier sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                        </div>
                        <div className="gest_actions">
                          <div style={{ "width": "45%", "float": "left" }}>
                            <button className="gest_bton_popup" onClick={(event) => {
                              Supprimer_Catalog_Pub_Mention_Legale();

                              close();
                            }}> Valider </button>

                          </div>
                          <div style={{ "width": "45%", "float": "right" }}>
                            <button
                              className="gest_bton_popup"
                              onClick={() => {
                                //console.log('modal closed ');
                                close();
                              }}
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>


                </div>

              </div>}

              <Button variant="outlined" onClick={changeHandler2_mention_legale} className="detail_class_submenu" style={{
                "background": "#c8cfd5",
                "minWidth": "15rem", "color": "black", "width": "auto"
              }}
                id='menu_import_formation' name='menu_import_formation'>Imp. Ment. Légales(pdf) <br />

              </Button><br />
              <nav className="koUpdateData" style={{ "color": "orange" }}>
                <Tooltip className="tooltip_css" id="tooltip_2" />


              </nav>
              {cgu_file_name && <nav className="okUpdateData"><i><AttachFileIcon /> {mention_legale_file_name}</i></nav>}
              <input type="file"
                accept=".pdf"
                ref={hiddenFileInput_mention_legale}
                style={{ display: 'none' }}
                name="liste_formation_file"
                onChange={handleSubmission_mention_legale}
              />



              {String(Add_Update_Catalog_Pub_Mention_Legale_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
              {String(Add_Update_Catalog_Pub_Mention_Legale_api) === "false" && <div className="koUpdateData"> {Add_Update_Catalog_Pub_Mention_Legale_message}</div>}

              <br />

              {pdf_mention_legale_file_pdf && <div className="div_row">
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>
                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                  <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px', maxWidth: '40%' }}>
                    <Button variant="contained" className="btn_modif" style={{ maxWidth: '80%' }} onClick={Cancel_Add_Update_Catalog_Pub_Mention_Legale}>Annuler
                    </Button>
                  </div>

                  <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px', maxWidth: '40%' }}>
                    <Button variant="contained" className="btn_enreg" style={{ maxWidth: '80%' }} onClick={Add_Update_Catalog_Pub_Mention_Legale}>Enregistrer
                    </Button>

                  </div>

                </div>}
                <div className="div_row" style={{ "border": "None" }}>
                  &nbsp;
                </div>

              </div>}
            </div>

          </div>


        </div>

      </div>}


      {String(submenu).trim() === "submenu_donnee_entreprise" && <div className="training_data">

        <div className="titre1"> Mes Informations </div>


        {testval == true && <div className="okUpdateData">
          Les données ont été mises à jour.
        </div>
        }

        {testval == false && <div className="koUpdateData">
          Erreur mise à jour.
        </div>
        }


        &nbsp;
        <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }} onChange={DataUpdated}>

          {String(is_partner_admin_account) === "1" && <TextField
            required
            label="Raison sociale"
            name="nom"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_nom}
            onChange={(e) => setp_nom(e.target.value)}
          />}

          {String(is_partner_admin_account) !== "1" && <TextField
            required
            label="Structure de rattachement"
            name="nom"
            id="nom"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_nom}

          />}

          {String(is_partner_admin_account) === "1" && <TextField
            name="siret"
            id="siret"
            label="SIRET"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_siret}
            onChange={(e) => setp_siret(e.target.value)}
          />
          }



          <TextField
            name="telephone"
            label="Telephone"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            value={p_telephone}
            onChange={(e) => setp_telephone(e.target.value)}
          />


          <TextField
            name="email"
            label="Adresse mail"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            value={p_email}
            onChange={(e) => setp_email(e.target.value)}
          />

          <TextField
            name="website"
            label="Site Web"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon />
                </InputAdornment>
              ),
            }}
            value={p_website}
            onChange={(e) => setp_website(e.target.value)}
          />

          <TextField
            name="link_linkedin"
            label="LinkedIn"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkedInIcon />
                </InputAdornment>
              ),
            }}
            value={p_link_linkedin}
            onChange={(e) => setp_link_linkedin(e.target.value)}
          />


          <TextField
            name="link_facebook"
            label="Facebook"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon />
                </InputAdornment>
              ),
            }}
            value={p_link_facebook}
            onChange={(e) => setp_link_facebook(e.target.value)}
          />


          <TextField
            name="link_twitter"
            label="X (Twitter)"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TwitterIcon />
                </InputAdornment>
              ),
            }}
            value={p_link_twitter}
            onChange={(e) => setp_link_twitter(e.target.value)}
          />

          <TextField
            name="adr_street"
            label="Adresse"
            className="texte_area_adress"
            sx={{ m: 1, width: '90%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_street}
            onChange={(e) => setp_adr_street(e.target.value)}
          />

          <TextField
            name="adr_zip"
            label="Code Postal"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_zip}
            onChange={(e) => setp_adr_zip(e.target.value)}
          />

          <TextField
            name="adr_city"
            label="Ville"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_city}
            onChange={(e) => setp_adr_city(e.target.value)}
          />

          <TextField
            name="adr_country"
            label="Pays"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            value={p_adr_country}
            onChange={(e) => setp_adr_country(e.target.value)}
          />
          {String(is_partner_admin_account) === "1" && <div>
            <div style={{ "width": "10%" }}> &nbsp;</div>
            <div className="div_row_gauche">
              <div className="titre1"> Votre logo </div>
              <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                <div className="div_row_gauche_image">
                  <img class="img_class_logo" src={userimgclassprofil} /><br />

                </div>
                <div className="div_row_droite_image">
                  <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                  {String(userformedited) === "1" && <nav> <label for="files_img_class" className="bton_image_class">Charger un logo</label><br />

                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                      <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer le logo<br />  </nav>
                    }

                  </nav>}

                  {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                  {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                  {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                  {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                </div>

              </div>
              <div className="div_row_bis tips_img_class">
                <li>
                  L'image doit etre carrée.
                </li>
                <li>
                  Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                </li>
                <li>
                  Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                  Les dimensions maximales sont de 256pixels X 256pixels
                </li>
                <li>
                  La taille de l'image ne doit pas dépasser 1 mega octet
                </li>
              </div>

            </div>

            <div className="div_row_droite">
              <div className="titre1"> Votre cachet </div>
              <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                <div className="div_row_gauche_image">
                  <img class="img_class_logo" src={isimgcachet} /><br />

                </div>
                <div className="div_row_droite_image">
                  <input type="file" id="files_img_cachet_class" accept="image/*" onChange={imgcachetchangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                  {String(userformedited) === "1" && <nav>  <label for="files_img_cachet_class" className="bton_image_class">Charger un cachet</label><br />


                    {String(isimgcachetSelected_recid) && String(isimgcachetSelected_recid).length > 3 &&
                      <nav className="bton_supprime_image_class" onClick={removeRecodedCachetImage}>Supprimer le cachet </nav>}

                    <br /> </nav>}
                  {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                  {isimgcachetdeleted && String(isimgcachetdeleted) === "1" && <nav className="koUpdateData"> {isimgcachetdeleted_message}</nav>}
                  {isimgcachetdeleted && String(isimgcachetdeleted) === "0" && <nav className="okUpdateData"> {isimgcachetdeleted_message}</nav>}
                  {isimgcachetSelected && String(isimgcachetSaved) === "0" && <nav className="koUpdateData"> {isimgcachetSaved_message}</nav>}
                </div>

              </div>
              <div className="div_row_bis tips_img_class">
                <li>
                  L'image doit etre carrée.
                </li>
                <li>
                  Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                </li>
                <li>
                  Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                  Les dimensions maximales sont de 256pixels X 256pixels
                </li>
                <li>
                  La taille de l'image ne doit pas dépasser 1 mega octet
                </li>
              </div>


            </div>


            <div style={{ "width": "10%" }}> &nbsp;</div>
            <div className="titre1"> Certification </div>
            <div className="div_row">


              <TextField
                required
                name="num_nda"
                label="Numéro NDA"
                className="texte_area"
                sx={{ m: 1, width: '48%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                value={p_num_nda}
                onChange={(e) => setp_num_nda(e.target.value)}
              />

              {fields1desabled == true && String(myqualiopi) !== "1" &&

                <TextField
                  name="isqualiopi"
                  label="Qualiopi"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Non"

                />

              }

              {fields1desabled == true && String(myqualiopi) === "1" &&

                <TextField
                  name="isqualiopi"
                  label="Qualiopi"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Oui"


                />

              }

              {fields1desabled !== true &&
                <TextField
                  name="isqualiopi"
                  label="Qualiopi"
                  select
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}

                  value={myqualiopi}

                  onChange={handleChangequaliopi}

                >{qualiopi.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} &nbsp; <br />
                  </MenuItem>
                ))}
                </TextField>
              }
            </div>
            <div className="div_row">




              {fields1desabled == true && String(mydatadock) !== "1" &&

                <TextField
                  name="isdatadock"
                  label="Datadock"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Non"


                />

              }

              {fields1desabled == true && String(mydatadock) === "1" &&

                <TextField
                  name="isdatadock"
                  label="Datadock"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Oui"


                />

              }

              {fields1desabled !== true &&
                <TextField
                  name="isdatadock"
                  label="Datadock"
                  select
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}

                  value={mydatadock}

                  onChange={handleChangedatadock}

                >{datadock.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label} &nbsp; <br />
                  </MenuItem>
                ))}
                </TextField>
              }

              {fields1desabled == true && String(mycertifvoltaire) !== "1" &&

                <TextField
                  name="iscertifvoltaire"
                  label="Certificat Voltaire"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Non"


                />

              }

              {fields1desabled == true && String(mycertifvoltaire) === "1" &&

                <TextField
                  name="iscertifvoltaire"
                  label="Certificat Voltaire"
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}
                  value="Oui"


                />

              }


              {fields1desabled !== true &&
                <TextField
                  name="iscertifvoltaire"
                  label="Certificat Voltaire"
                  select
                  className="texte_area"
                  sx={{ m: 1, width: '48%' }}

                  value={mycertifvoltaire}

                  onChange={handleChangecertifvoltaire}


                >{certifvoltaire.map((option) => (
                  <MenuItem key={option.value} value={option.value} >
                    {option.label} &nbsp; <br />
                  </MenuItem>
                ))}
                </TextField>
              }
            </div>
          </div>}


          <div style={{ "width": "10%" }}> &nbsp;</div>
          <div className="titre1"> Info Contact </div>

          <TextField
            name="contact_nom"
            label="Nom Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_contact_nom}
            onChange={(e) => setp_contact_nom(e.target.value)}
          />

          <TextField
            name="contact_prenom"
            label="Prénom Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_contact_prenom}
            onChange={(e) => setp_contact_prenom(e.target.value)}
          />

          <TextField
            name="contact_tel"
            label="Tel Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            value={p_contact_tel}
            onChange={(e) => setp_contact_tel(e.target.value)}
          />
          <TextField
            name="contact_mail"
            label="Mail Contact"
            className="texte_area"
            sx={{ m: 1, width: '48%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            value={p_contact_mail}
            onChange={(e) => setp_contact_mail(e.target.value)}
          />


          {fields1desabled == false && String(datamodification) === String("1") && <div className="div_row">
            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
          </div>}

          <div className="div_row22">
            {fields1desabled == false && <div className="div_row">
              <div className="div_row_gauche">
                <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
              </div>
              <div className="div_row_droite">
                <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={annuler_change_user_data}>Annuler</Button>
              </div>
            </div>

            }
            {fields1desabled == true &&
              <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablefieds1}>Editer</Button>
            }

          </div>

          <div style={{ "width": "10%" }}> &nbsp;</div>
          <div className="titre1"> Accès E-Learning </div>
          <TextField
            name="contact_login_elearning"
            label="E-Learning Login"
            className="texte_area"


            sx={{ m: 1, width: '48%', background: "#ECEFF1", color: "black" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_lms_username}

          />

          <TextField
            name="contact_login_elearning"
            label="Url plateforme"
            className="texte_area"

            sx={{ m: 1, width: '48%', background: "#ECEFF1", color: "black" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            value={p_lms_virtualhost_url}

          />


        </Box>




      </div>}



      <div className="div_row">
        <div className="div_row22">
          <div className="titre1"> Supppression de compte </div>
          <div style={{ "marginTop": "1rem", "textAlign": "left" }}>
            Pour supprimer votre compte, veuillez vous rapprocher de votre contact commercial ou nous écrire à contact@mysy-training.com.
          </div>
        </div>
      </div>
    </div>
  );
}
export default UpdateParnterInfo;