import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import ToggleSwitch from "./ToggleSwitch";
import { FcInfo } from "react-icons/fc";

const Partner_Configuration_Technique = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("technique");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selectionModel_quotation, setselectionModel_quotation] = React.useState([]);

    const [selectionModel_type_client, setselectionModel_type_client] = React.useState([]);

    const [selectionModel_site_ftion, setselectionModel_site_ftion] = React.useState([]);

    const [selectionModel_paiement_ction, setselectionModel_paiement_ction] = React.useState([]);

    const [selectionModel_crm_opportunite_etape, setselectionModel_crm_opportunite_etape] = React.useState([]);

    const [selectionModel_competence, setselectionModel_competence] = React.useState([]);

    const [rows_order_lines, setrows_order_lines] = useState([]);
    const [selectionModel_order_lines, setselectionModel_order_lines] = React.useState([]);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const [isLoading, setLoading] = useState();

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'config_name', headerName: 'Nom Configuration', flex: 1, hide: false, editable: false },
        { field: 'config_value', headerName: 'Valeur config', flex: 1, hide: false, editable: false },
    ]

    const columns_quotation = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'config_name', headerName: 'Nom Configuration', flex: 1, hide: false, editable: false },
        { field: 'config_value', headerName: 'Valeur config', flex: 1, hide: false, editable: false },
    ]


    const columns_type_client = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Nom Configuration', flex: 1, hide: false, editable: false },
        { field: 'description', headerName: 'Valeur config', flex: 1, hide: false, editable: false },

        {
            field: 'is_financeur', headerName: 'Financeur', flex: 1, hide: false, editable: false,
            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {String(cellValues.row.is_financeur) === "1" && "Oui"}&nbsp;
                        {String(cellValues.row.is_financeur) !== "1" && "Non"}&nbsp;

                    </div>

                );
            },

        },
        {
            field: 'is_client', headerName: 'Client', flex: 1, hide: false, editable: false,
            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {String(cellValues.row.is_client) === "1" && "Oui"}&nbsp;
                        {String(cellValues.row.is_client) !== "1" && "Non"}&nbsp;

                    </div>

                );
            },
        },
        {
            field: 'is_fournisseur', headerName: 'Fournisseur', flex: 1, hide: false, editable: false,
            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {String(cellValues.row.is_fournisseur) === "1" && "Oui"}&nbsp;
                        {String(cellValues.row.is_fournisseur) !== "1" && "Non"}&nbsp;

                    </div>

                );
            },
        },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le type de client sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Type_Client(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_site_formation = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code_site', headerName: 'Code', flex: 1, hide: false, editable: false },
        { field: 'nom_site', headerName: 'Nom', flex: 1, hide: false, editable: false },
        { field: 'description', headerName: 'description', flex: 1, hide: false, editable: false },
        { field: 'site_adr', headerName: 'Adresse', flex: 1, hide: false, editable: false },
        { field: 'site_cp', headerName: 'CP', flex: 1, hide: false, editable: false },
        { field: 'site_ville', headerName: 'Ville', flex: 1, hide: false, editable: false },
        { field: 'site_pays', headerName: 'Pays', flex: 1, hide: false, editable: false },
        { field: 'telephone', headerName: 'Tel', flex: 1, hide: false, editable: false },
        { field: 'email', headerName: 'email', flex: 1, hide: false, editable: false },
        { field: 'site_rattachement_id', headerName: 'site_rattachement_id', flex: 1, hide: false, editable: false },
        { field: 'site_rattachement_nom', headerName: 'site_rattachement_nom', flex: 1, hide: false, editable: false },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le site de formation sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Site_Ftion(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_paiement_condition = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', flex: 1, hide: false, editable: false },
        { field: 'description', headerName: 'Description', flex: 1, hide: false, editable: false },
        { field: 'nb_jour', headerName: 'Jours', flex: 1, hide: false, editable: false },
        { field: 'depart', headerName: 'Départ', flex: 1, hide: false, editable: false },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la condition de paiement sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Paiement_Ction(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_crm_opportune_etape = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', flex: 1, hide: false, editable: false },
        { field: 'description', headerName: 'description', flex: 1, hide: false, editable: false },
        { field: 'rang', headerName: 'Rang', flex: 1, hide: false, editable: false },
        { field: 'gagne', headerName: 'Gagne', flex: 1, hide: false, editable: false },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'étape sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_CRM_Opport(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_list_competence = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'description', headerName: 'Description', flex: 1, hide: false, editable: false },
        { field: 'domaine', headerName: 'Domaine', flex: 1, hide: false, editable: false },
        { field: 'metier', headerName: 'Métier', flex: 1, hide: false, editable: false },
        { field: 'commentaire', headerName: 'Commentaire', flex: 1, hide: false, editable: false },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la compétence sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_competence(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const myRef = useRef(null)

    useEffect(() => {

        Getall_Parter_config_Points();
        Getall_Parter_type_client();
        Getall_Partner_Site_Formation();
        Getall_Partner_Paiement_Condition();
        Getall_Partner_CRM_Opportunite_Etape();
        Getall_Partner_Competence_List();
        Getall_Parter_Devis_Config_Points();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        submenu_technique();

    }, [])



    const [New_Getall_Partner_Site_Formation_result, setNew_Getall_Partner_Site_Formation_result] = useState([]);

    const [Getall_Partner_Site_Formation_api, setGetall_Partner_Site_Formation_api] = useState();
    const [Getall_Partner_Site_Formation_message, setGetall_Partner_Site_Formation_message] = useState();
    const [Getall_Partner_Site_Formation_result, setGetall_Partner_Site_Formation_result] = useState([]);
    function Getall_Partner_Site_Formation(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Site_Formation_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Site_Formation  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Site_Formation  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Site_Formation_api("true");
                setGetall_Partner_Site_Formation_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code_site = JSON.parse(x).code_site;
                    var local_nom_site = JSON.parse(x).nom_site;
                    var local_adr_site = JSON.parse(x).site_adr;
                    var local_cp_site = JSON.parse(x).site_cp;
                    var local_ville_site = JSON.parse(x).site_ville;
                    var local_pays_site = JSON.parse(x).site_pays;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_site,
                        "code_site": local_code_site,
                        "nom_site": local_nom_site,
                        "adr_site": local_adr_site,
                        "cp_site": local_cp_site,
                        "ville_site": local_ville_site,
                        "pays_site": local_pays_site,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code_site": "",
                    "nom_site": "",
                    "adr_site": "",
                    "cp_site": "",
                    "ville_site": "",
                    "pays_site": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Site_Formation_result(new_data2);


            }
            else {
                setGetall_Partner_Site_Formation_api("false");
                setGetall_Partner_Site_Formation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Site_Formation = ', error);
            setGetall_Partner_Site_Formation_api("false");
            alert(" Impossible de recuperer la liste des sites de formation");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Paiement_Condition_result, setNew_Getall_Partner_Paiement_Condition_result] = useState([]);

    const [Getall_Partner_Paiement_Condition_api, setGetall_Partner_Paiement_Condition_api] = useState();
    const [Getall_Partner_Paiement_Condition_message, setGetall_Partner_Paiement_Condition_message] = useState();
    const [Getall_Partner_Paiement_Condition_result, setGetall_Partner_Paiement_Condition_result] = useState([]);
    function Getall_Partner_Paiement_Condition(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Paiement_Condition/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Paiement_Condition  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Paiement_Condition  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Paiement_Condition_api("true");
                setGetall_Partner_Paiement_Condition_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;
                    var local_nb_jour = JSON.parse(x).nb_jour;
                    var local_depart = JSON.parse(x).depart;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "description": local_description,
                        "nb_jour": local_nb_jour,
                        "depart": local_depart,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "nb_jour": "",
                    "depart": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Paiement_Condition_result(new_data2);


            }
            else {
                setGetall_Partner_Paiement_Condition_api("false");
                setGetall_Partner_Paiement_Condition_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Paiement_Condition = ', error);
            setGetall_Partner_Paiement_Condition_api("false");
            alert(" Impossible de recuperer la liste des conditions de paiement");
            //setmyApimyApiMessage("")
        })
    }





    const [Getall_Parter_config_Points_api, setGetall_Parter_config_Points_api] = useState();
    const [Getall_Parter_config_Points_message, setGetall_Parter_config_Points_message] = useState();
    const [Getall_Parter_config_Points_result, setGetall_Parter_config_Points_result] = useState([]);
    function Getall_Parter_config_Points(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", "");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Basic_Setup/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_config_Points  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_config_Points  res.data.message r_class = " + res.data.message);
                setGetall_Parter_config_Points_api("true");
                setGetall_Parter_config_Points_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_config_Points_api("false");
                setGetall_Parter_config_Points_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Parter_config_Points = ', error);
            setGetall_Parter_config_Points_api("false");
            alert(" Impossible de recuperer la liste des points de configuration");
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Parter_Devis_Config_Points_api, setGetall_Parter_Devis_Config_Points_api] = useState();
    const [Getall_Parter_Devis_Config_Points_message, setGetall_Parter_Devis_Config_Points_message] = useState();
    const [Getall_Parter_Devis_Config_Points_result, setGetall_Parter_Devis_Config_Points_result] = useState([]);
    function Getall_Parter_Devis_Config_Points(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", "quotation");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Basic_Setup/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Devis_Config_Points  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Devis_Config_Points  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Devis_Config_Points_api("true");
                setGetall_Parter_Devis_Config_Points_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Devis_Config_Points_api("false");
                setGetall_Parter_Devis_Config_Points_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Parter_Devis_Config_Points = ', error);
            setGetall_Parter_Devis_Config_Points_api("false");
            alert(" Impossible de recuperer la liste des points de configuration des devis");
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Parter_type_client_api, setGetall_Parter_type_client_api] = useState();
    const [Getall_Parter_type_client_message, setGetall_Parter_type_client_message] = useState();
    const [Getall_Parter_type_client_result, setGetall_Parter_type_client_result] = useState([]);
    function Getall_Parter_type_client(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Client_Type_List/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_type_client  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_type_client  res.data.message r_class = " + res.data.message);
                setGetall_Parter_type_client_api("true");
                setGetall_Parter_type_client_result(res.data.message);

            }
            else {
                setGetall_Parter_type_client_api("false");
                setGetall_Parter_type_client_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Parter_type_client = ', error);
            setGetall_Parter_type_client_api("false");
            alert(" Impossible de recuperer la liste des types de client");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Getall_Partner_CRM_Opportunite_Etape_result, setNew_Getall_Partner_CRM_Opportunite_Etape_result] = useState([]);

    const [Getall_Partner_CRM_Opportunite_Etape_api, setGetall_Partner_CRM_Opportunite_Etape_api] = useState();
    const [Getall_Partner_CRM_Opportunite_Etape_message, setGetall_Partner_CRM_Opportunite_Etape_message] = useState();
    const [Getall_Partner_CRM_Opportunite_Etape_result, setGetall_Partner_CRM_Opportunite_Etape_result] = useState([]);
    function Getall_Partner_CRM_Opportunite_Etape(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_CRM_List_Opportunite_Etape/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_CRM_Opportunite_Etape  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_CRM_Opportunite_Etape  res.data.message r_class = " + res.data.message);
                setGetall_Partner_CRM_Opportunite_Etape_api("true");
                setGetall_Partner_CRM_Opportunite_Etape_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;
                    var local_rang = JSON.parse(x).rang;
                    var local_gagne = JSON.parse(x).gagne;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "description": local_description,
                        "rang": local_rang,
                        "gagne": local_gagne,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "rang": "",
                    "gagne": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_CRM_Opportunite_Etape_result(new_data2);


            }
            else {
                setGetall_Partner_CRM_Opportunite_Etape_api("false");
                setGetall_Partner_CRM_Opportunite_Etape_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_CRM_Opportunite_Etape = ', error);
            setGetall_Partner_CRM_Opportunite_Etape_api("false");
            alert(" Impossible de recuperer la liste des étapes d'une opportunité ");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Competence_List_result, setNew_Getall_Partner_Competence_List_result] = useState([]);

    const [Getall_Partner_Competence_List_api, setGetall_Partner_Competence_List_api] = useState();
    const [Getall_Partner_Competence_List_message, setGetall_Partner_Competence_List_message] = useState();
    const [Getall_Partner_Competence_List_result, setGetall_Partner_Competence_List_result] = useState([]);
    function Getall_Partner_Competence_List(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Competence_no_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Competence_List  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Competence_List  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Competence_List_api("true");
                setGetall_Partner_Competence_List_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_description = JSON.parse(x).description;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_description,
                        "description": local_description,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "rang": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Competence_List_result(new_data2);


            }
            else {
                setGetall_Partner_Competence_List_api("false");
                setGetall_Partner_Competence_List_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Competence_List = ', error);
            setGetall_Partner_Competence_List_api("false");
            alert(" Impossible de recuperer la liste des compétences ");
            //setmyApimyApiMessage("")
        })
    }

    const [display_detail_quotation_config, setdisplay_detail_quotation_config] = React.useState();

    const [display_detail_config, setdisplay_detail_config] = React.useState();

    const [selected_row_id_val, setselected_row_id_val] = React.useState();

    const [selected_site_ftion_id, setselected_site_ftion_id] = React.useState("");

    function handleClick_edit_config_From_Line(selected_row_id) {

        //submenu_detail_employe();

        setselected_row_id_val(selected_row_id);

        var line = JSON.parse(rowss[selected_row_id]);

        setdisplay_detail_config("1");
        //console.log("### line = ", line);
        var invoice_id = line._id;
        setselected_id(invoice_id);

        var local_config_name = line.config_name;
        setp_detail_config_point(local_config_name);

        var local_config_value = line.config_value;
        setp_detail_config_valeur(local_config_value);

        Disable_Config_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Annule_Order_DetailFields() {

        var line = JSON.parse(rowss[selected_row_id_val]);

        setdisplay_detail_config("1");
        //console.log("### line = ", line);
        var invoice_id = line._id;
        setselected_id(invoice_id);

        var local_config_name = line.config_name;
        setp_detail_config_point(local_config_name);

        var local_config_value = line.config_value;
        setp_detail_config_valeur(local_config_value);

        setConfig_data_changed();
        setconfig_data_edit_mode();

        Disable_Config_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }

    const myRef_head = useRef(null);
    const myRef_type_client = useRef(null);

    const [selected_id, setselected_id] = useState("");



    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [p_detail_config_valeur, setp_detail_config_valeur] = useState("");
    const [Config_data_changed, setConfig_data_changed] = useState("");
    const [p_detail_config_point, setp_detail_config_point] = useState("");
    const [config_data_edit_mode, setconfig_data_edit_mode] = useState("");


    const [p_detail_quotation_config_valeur, setp_detail_quotation_config_valeur] = useState("");
    const [Config_quotation_data_changed, setConfig_quotation_data_changed] = useState("");
    const [p_detail_quotation_config_point, setp_detail_quotation_config_point] = useState("");
    const [config_quotation_data_edit_mode, setconfig_quotation_data_edit_mode] = useState("");


    function Enable_Config_quotation_DetailFields() {

        setconfig_quotation_data_edit_mode("1");

        if (document.getElementsByName("detail_config_point")[0]) {
            document.getElementsByName("detail_config_point")[0].disabled = false;
            document.getElementsByName("detail_config_point")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_valeur")[0]) {
            document.getElementsByName("detail_config_valeur")[0].disabled = false;
            document.getElementsByName("detail_config_valeur")[0].style.backgroundColor = "#FFFFFF";
        }

    }


    function Enable_Config_DetailFields() {

        setconfig_data_edit_mode("1");

        if (document.getElementsByName("detail_config_point")[0]) {
            document.getElementsByName("detail_config_point")[0].disabled = false;
            document.getElementsByName("detail_config_point")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_valeur")[0]) {
            document.getElementsByName("detail_config_valeur")[0].disabled = false;
            document.getElementsByName("detail_config_valeur")[0].style.backgroundColor = "#FFFFFF";
        }

    }

    function Disable_Config_DetailFields() {

        setconfig_data_edit_mode("0");

        if (document.getElementsByName("detail_config_point")[0]) {

            document.getElementsByName("detail_config_point")[0].disabled = true;
            document.getElementsByName("detail_config_point")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_valeur")[0]) {
            document.getElementsByName("detail_config_valeur")[0].disabled = true;
            document.getElementsByName("detail_config_valeur")[0].style.backgroundColor = "#ECEFF1";
        }

    }

    const [selected_quotation_id, setselected_quotation_id] = useState("");

    function handleClick_edit_config_quotation_From_Line(selected_row_id) {

        var line = JSON.parse(Getall_Parter_Devis_Config_Points_result[selected_row_id]);

        setdisplay_detail_quotation_config("1");
        //console.log("### line = ", line);
        var invoice_id = line._id;
        setselected_quotation_id(invoice_id);

        var local_config_name = line.config_name;
        setp_detail_quotation_config_point(local_config_name);

        var local_config_value = line.config_value;
        setp_detail_quotation_config_valeur(local_config_value);

        Disable_Config_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Annule_quotation_DetailFields() {

        var line = JSON.parse(Getall_Parter_Devis_Config_Points_result[gridline_quotation_id]);

        setdisplay_detail_config("1");
        //console.log("### line = ", line);
        var invoice_id = line._id;
        setselected_id(invoice_id);

        var local_config_name = line.config_name;
        setp_detail_quotation_config_point(local_config_name);

        var local_config_value = line.config_value;
        setp_detail_quotation_config_valeur(local_config_value);

        Disable_Config_DetailFields();

        setConfig_quotation_data_changed("");
        setconfig_quotation_data_edit_mode("");

        Disable_Config_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    const [Update_One_Config_Data_api, setUpdate_One_Config_Data_api] = useState();
    const [Update_One_Config_Data_message, setUpdate_One_Config_Data_message] = useState();
    const [Update_One_Config_Data_result, setUpdate_One_Config_Data_result] = useState();
    function Update_One_Config_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("config_name", p_detail_config_point);
        form.append("config_value", p_detail_config_valeur);
        form.append("related_collection", "");

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Basic_Setup/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Update_One_Config_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Config_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setUpdate_One_Config_Data_api("true");
                setUpdate_One_Config_Data_result(res.data.message);

                Getall_Parter_config_Points();

                setp_detail_config_valeur("");
                setp_detail_config_point("");
                setConfig_data_changed("");
                setconfig_data_edit_mode("");
                setdisplay_detail_config("");

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Config_Data_api("false");
                setUpdate_One_Config_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Config_Data = ' + error);
            setUpdate_One_Config_Data_api("false");
            alert(" Impossible de mettre à jour le paramétrage ");

        })
    }

    const [Update_One_Quotation_Config_Data_api, setUpdate_One_Quotation_Config_Data_api] = useState();
    const [Update_One_Quotation_Config_Data_message, setUpdate_One_Quotation_Config_Data_message] = useState();
    const [Update_One_Quotation_Config_Data_result, setUpdate_One_Quotation_Config_Data_result] = useState();
    function Update_One_Quotation_Config_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("config_name", p_detail_quotation_config_point);
        form.append("config_value", p_detail_quotation_config_valeur);
        form.append("related_collection", "quotation");

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_Basic_Setup/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Update_One_Quotation_Config_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Quotation_Config_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setUpdate_One_Quotation_Config_Data_api("true");
                setUpdate_One_Quotation_Config_Data_result(res.data.message);

                Getall_Parter_Devis_Config_Points();

                setp_detail_quotation_config_valeur("");
                setConfig_quotation_data_changed("");
                setp_detail_quotation_config_point("");
                setconfig_quotation_data_edit_mode("");
                setdisplay_detail_quotation_config("");

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Quotation_Config_Data_api("false");
                setUpdate_One_Quotation_Config_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Quotation_Config_Data = ' + error);
            setUpdate_One_Quotation_Config_Data_api("false");
            alert(" Impossible de mettre à jour le paramétrage ");

        })
    }


    const [gridline_id, setgridline_id] = useState("");
    const [gridline_site_ftion_id, setgridline_site_ftion_id] = useState("");

    const [gridline_paiement_ction_id, setgridline_paiement_ction_id] = useState("");

    const [gridline_crm_opport_id, setgridline_crm_opport_id] = useState("");

    const [gridline_competence_id, setgridline_competence_id] = useState("");

    const [gridline_quotation_id, setgridline_quotation_id] = useState("");


    const [selected_quotation_row_id_val, setselected_quotation_row_id_val] = useState("");


    const [display_detail_type_client, setdisplay_detail_type_client] = React.useState();

    function handleClick_edit_config_From_Client_Type(selected_row_id) {

        var line = JSON.parse(Getall_Parter_type_client_result[selected_row_id]);

        setdisplay_detail_type_client("1");


        setselected_type_client_id(line._id);

        setp_type_client_code(line.code);
        setp_type_client_desc(line.description);

        if (String(line.is_financeur) === "1")
            setp_type_client_is_financeur(true);
        else
            setp_type_client_is_financeur(false);


        if (String(line.is_client) === "1")
            setp_type_client_is_client(true);
        else
            setp_type_client_is_client(false);


        if (String(line.is_fournisseur) === "1")
            setp_type_client_is_fournisseur(true);
        else
            setp_type_client_is_fournisseur(false);



        setDialog_type_client_1_open(true);

    }

    const [Dialog_type_client_1_message, setDialog_type_client_1_message] = React.useState(false);
    const [Dialog_type_client_1_open, setDialog_type_client_1_open] = React.useState(false);

    function Dialog_1_handle_change_type_client(message) {
        setDialog_type_client_1_message(message);
        setDialog_type_client_1_open(true);
    }

    const Dialog_type_client_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_type_client_1_handleClose_buton = () => {
        setp_type_client_code("");
        setp_type_client_desc("");
        setselected_type_client_id("");
        setDialog_type_client_1_open(false);
    };


    const [p_type_client_code, setp_type_client_code] = useState("");
    const [p_type_client_desc, setp_type_client_desc] = useState("");
    const [selected_type_client_id, setselected_type_client_id] = useState("");


    const [p_type_client_is_financeur, setp_type_client_is_financeur] = useState(false);
    const [p_type_client_is_fournisseur, setp_type_client_is_fournisseur] = useState(false);
    const [p_type_client_is_client, setp_type_client_is_client] = useState(false);


    const [p_detail_code_site_stored, setp_detail_code_site_stored] = useState("");
    const [p_detail_code_site, setp_detail_code_site] = useState("");
    const [p_detail_nom_site, setp_detail_nom_site] = useState("");
    const [p_detail_site_adr, setp_detail_site_adr] = useState("");
    const [p_detail_site_cp, setp_detail_site_cp] = useState("");
    const [p_detail_site_ville, setp_detail_site_ville] = useState("");
    const [p_detail_site_pays, setp_detail_site_pays] = useState("");
    const [p_detail_site_telephone, setp_detail_site_telephone] = useState("");
    const [p_detail_site_email, setp_detail_site_email] = useState("");
    const [p_detail_site_description, setp_detail_site_description] = useState("");
    const [p_detail_site_rattachement_id, setp_detail_site_rattachement_id] = useState("");
    const [p_detail_site_rattachement_nom, setp_detail_site_rattachement_nom] = useState("");


    const [selected_paiement_id, setselected_paiement_id] = useState("");
    const [p_detail_paiement_code, setp_detail_paiement_code] = useState("");
    const [p_detail_paiement_description, setp_detail_paiement_description] = useState("");
    const [p_detail_paiement_nb_jour, setp_detail_paiement_nb_jour] = useState("");
    const [p_detail_paiement_depart, setp_detail_paiement_depart] = useState("facture");


    const [selected_crm_opport_etape_id, setselected_crm_opport_etape_id] = useState("");
    const [p_detail_crm_opport_code, setp_detail_crm_opport_code] = useState("");
    const [p_detail_crm_opport_description, setp_detail_crm_opport_description] = useState("");
    const [p_detail_crm_opport_rang, setp_detail_crm_opport_rang] = useState("");

    const [p_detail_crm_opport_gagne, setp_detail_crm_opport_gagne] = useState(false);


    const [selected_competence_id, setselected_competence_id] = useState("");
    const [p_detail_competence_description, setp_detail_competence_description] = useState("");
    const [p_detail_competence_domaine, setp_detail_competence_domaine] = useState("");
    const [p_detail_competence_metier, setp_detail_competence_metier] = useState("");
    const [p_detail_competence_commentaire, setp_detail_competence_commentaire] = useState("");



    const [Add_Update_Client_Type_api, setAdd_Update_Client_Type_api] = useState();
    const [Add_Update_Client_Type_message, setAdd_Update_Client_Type_message] = useState();
    const [Add_Update_Client_Type_result, setAdd_Update_Client_Type_result] = useState();
    function Add_Update_Client_Type(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        if (String(p_type_client_is_financeur) === "true")
            form.append("is_financeur", "1");
        else
            form.append("is_financeur", "0");


        if (String(p_type_client_is_fournisseur) === "true")
            form.append("is_fournisseur", "1");
        else
            form.append("is_fournisseur", "0");


        if (String(p_type_client_is_client) === "true")
            form.append("is_client", "1");
        else
            form.append("is_client", "0");


        var myurl = "";

        if (String(selected_type_client_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Client_Type/";
            form.append("_id", selected_type_client_id);
            form.append("code", p_type_client_code);
            form.append("description", p_type_client_desc);


        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Client_Type/";
            form.append("code", p_type_client_code);
            form.append("description", p_type_client_desc);

        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_Client_Type  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_Client_Type  res.data.message r_class = " + res.data.message);
                setAdd_Update_Client_Type_api("true");
                setAdd_Update_Client_Type_result(res.data.message);
                Dialog_type_client_1_handleClose_buton();
                Getall_Parter_type_client();
                alert(res.data.message);
            }
            else {
                setAdd_Update_Client_Type_api("false");
                setAdd_Update_Client_Type_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_Client_Type = ', error);
            setAdd_Update_Client_Type_api("false");
            alert(" Impossible d'ajouter / mettre à jour les types de client");
            //setmyApimyApiMessage("")
        })
    }


    const [Add_Update_Site_Formation_api, setAdd_Update_Site_Formation_api] = useState();
    const [Add_Update_Site_Formation_message, setAdd_Update_Site_Formation_message] = useState();
    const [Add_Update_Site_Formation_result, setAdd_Update_Site_Formation_result] = useState();
    function Add_Update_Site_Formation(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = "";

        if (String(selected_site_ftion_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Site_Formation/";
            form.append("site_id", selected_site_ftion_id);
            form.append("code_site", p_detail_code_site);
            form.append("nom_site", p_detail_nom_site);
            form.append("site_adr", p_detail_site_adr);
            form.append("site_cp", p_detail_site_cp);
            form.append("site_ville", p_detail_site_ville);
            form.append("site_pays", p_detail_site_pays);
            form.append("telephone", p_detail_site_telephone);
            form.append("email", p_detail_site_email);
            form.append("site_rattachement_id", p_detail_site_rattachement_id);
            form.append("description", p_detail_site_description);

        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Site_Formation/";
            form.append("code_site", p_detail_code_site);
            form.append("nom_site", p_detail_nom_site);
            form.append("site_adr", p_detail_site_adr);
            form.append("site_cp", p_detail_site_cp);
            form.append("site_ville", p_detail_site_ville);
            form.append("site_pays", p_detail_site_pays);
            form.append("telephone", p_detail_site_telephone);
            form.append("email", p_detail_site_email);
            form.append("site_rattachement_id", p_detail_site_rattachement_id);
            form.append("description", p_detail_site_description);


        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_Site_Formation  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_Site_Formation  res.data.message r_class = " + res.data.message);
                setAdd_Update_Site_Formation_api("true");
                setAdd_Update_Site_Formation_result(res.data.message);
                Dialog_site_ftion_1_handleClose_buton();
                Getall_Partner_Site_Formation();
                alert(res.data.message);
            }
            else {
                setAdd_Update_Site_Formation_api("false");
                setAdd_Update_Site_Formation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_Site_Formation = ', error);
            setAdd_Update_Site_Formation_api("false");
            alert(" Impossible d'ajouter / mettre à jour le site de formation");
            //setmyApimyApiMessage("")
        })
    }


    const [Add_Update_Paiement_Ction_api, setAdd_Update_Paiement_Ction_api] = useState();
    const [Add_Update_Paiement_Ction_message, setAdd_Update_Paiement_Ction_message] = useState();
    const [Add_Update_Paiement_Ction_result, setAdd_Update_Paiement_Ction_result] = useState();
    function Add_Update_Paiement_Ction(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = "";

        if (String(selected_paiement_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Paiement_Condition/";
            form.append("_id", selected_paiement_id);
            form.append("code", p_detail_paiement_code);
            form.append("description", p_detail_paiement_description);
            form.append("nb_jour", p_detail_paiement_nb_jour);
            form.append("depart", p_detail_paiement_depart);

        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Paiement_Condition/";
            form.append("code", p_detail_paiement_code);
            form.append("description", p_detail_paiement_description);
            form.append("nb_jour", p_detail_paiement_nb_jour);
            form.append("depart", p_detail_paiement_depart);



        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_Paiement_Ction  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_Paiement_Ction  res.data.message r_class = " + res.data.message);
                setAdd_Update_Paiement_Ction_api("true");
                setAdd_Update_Paiement_Ction_result(res.data.message);
                Dialog_paiement_ction_1_handleClose_buton();
                Getall_Partner_Paiement_Condition();
                alert(res.data.message);
            }
            else {
                setAdd_Update_Paiement_Ction_api("false");
                setAdd_Update_Paiement_Ction_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_Paiement_Ction = ', error);
            setAdd_Update_Paiement_Ction_api("false");
            alert(" Impossible d'ajouter / mettre à jour la condition de paiement");
            //setmyApimyApiMessage("")
        })
    }

    const [handleClick_delete_Type_Client_api, sethandleClick_delete_Type_Client_api] = useState();
    const [handleClick_delete_Type_Client_message, sethandleClick_delete_Type_Client_message] = useState();
    const [handleClick_delete_Type_Client_result, sethandleClick_delete_Type_Client_result] = useState();
    function handleClick_delete_Type_Client(event, cellValues) {

        var type_client_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", type_client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Client_Type/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Type_Client  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Type_Client  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Type_Client_api("true");
                sethandleClick_delete_Type_Client_result(res.data.message);
                Dialog_type_client_1_handleClose_buton();
                Getall_Parter_type_client();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Type_Client_api("false");
                sethandleClick_delete_Type_Client_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Type_Client = ', error);
            sethandleClick_delete_Type_Client_api("false");
            alert(" Impossible de supprimer le type de client");
            //setmyApimyApiMessage("")
        })
    }



    const [handleClick_delete_Site_Ftion_api, sethandleClick_delete_Site_Ftion_api] = useState();
    const [handleClick_delete_Site_Ftion_message, sethandleClick_delete_Site_Ftion_message] = useState();
    const [handleClick_delete_Site_Ftion_result, sethandleClick_delete_Site_Ftion_result] = useState();
    function handleClick_delete_Site_Ftion(event, cellValues) {

        var local_site_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("site_id", local_site_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Site_Formation/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Site_Ftion  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Site_Ftion  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Site_Ftion_api("true");
                sethandleClick_delete_Site_Ftion_result(res.data.message);

                Getall_Partner_Site_Formation();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Site_Ftion_api("false");
                sethandleClick_delete_Site_Ftion_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Site_Ftion = ', error);
            sethandleClick_delete_Site_Ftion_api("false");
            alert(" Impossible de supprimer le site de formation");
            //setmyApimyApiMessage("")
        })
    }


    const [handleClick_delete_Paiement_Ction_api, sethandleClick_delete_Paiement_Ction_api] = useState();
    const [handleClick_delete_Paiement_Ction_message, sethandleClick_delete_Paiement_Ction_message] = useState();
    const [handleClick_delete_Paiement_Ction_result, sethandleClick_delete_Paiement_Ction_result] = useState();
    function handleClick_delete_Paiement_Ction(event, cellValues) {

        var local_paiement_ction_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_paiement_ction_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Paiement_Condition/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Paiement_Ction  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Paiement_Ction  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Paiement_Ction_api("true");
                sethandleClick_delete_Paiement_Ction_result(res.data.message);

                Getall_Partner_Paiement_Condition();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Paiement_Ction_api("false");
                sethandleClick_delete_Paiement_Ction_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Paiement_Ction = ', error);
            sethandleClick_delete_Paiement_Ction_api("false");
            alert(" Impossible de supprimer la condition de paiement");
            //setmyApimyApiMessage("")
        })
    }



    const [handleClick_delete_CRM_Opport_api, sethandleClick_delete_CRM_Opport_api] = useState();
    const [handleClick_delete_CRM_Opport_message, sethandleClick_delete_CRM_Opport_message] = useState();
    const [handleClick_delete_CRM_Opport_result, sethandleClick_delete_CRM_Opport_result] = useState();
    function handleClick_delete_CRM_Opport(event, cellValues) {

        var local_CRM_etape_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_CRM_etape_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_CRM_List_Opportunite_Etape/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_CRM_Opport  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_CRM_Opport  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_CRM_Opport_api("true");
                sethandleClick_delete_CRM_Opport_result(res.data.message);

                Getall_Partner_Paiement_Condition();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_CRM_Opport_api("false");
                sethandleClick_delete_CRM_Opport_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_CRM_Opport = ', error);
            sethandleClick_delete_CRM_Opport_api("false");
            alert(" Impossible de supprimer l'étape");
            //setmyApimyApiMessage("")
        })
    }


    const [handleClick_delete_competence_api, sethandleClick_delete_competence_api] = useState();
    const [handleClick_delete_competence_message, sethandleClick_delete_competence_message] = useState();
    const [handleClick_delete_competence_result, sethandleClick_delete_competence_result] = useState();
    function handleClick_delete_competence(event, cellValues) {

        var local_competence_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("competence_id", local_competence_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Competence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_competence  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_competence  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_competence_api("true");
                sethandleClick_delete_competence_result(res.data.message);

                Getall_Partner_Competence_List();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_competence_api("false");
                sethandleClick_delete_competence_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_competence = ', error);
            sethandleClick_delete_competence_api("false");
            alert(" Impossible de supprimer la compétence");
            //setmyApimyApiMessage("")
        })
    }


    const New_Config_Point = [
        { "id": "tva", "label": "tva", "value": "tva" },
        { "id": "smtp_server", "label": "smtp_server", "value": "smtp_server" },
        { "id": "smtp_user", "label": "smtp_user", "value": "smtp_user" },
        { "id": "smtp_count_from_name", "label": "smtp_count_from_name", "value": "smtp_count_from_name" },
        { "id": "smtp_count_port", "label": "smtp_count_port", "value": "smtp_count_port" },
        { "id": "partner_smtp", "label": "partner_smtp", "value": "partner_smtp" },
        { "id": "partner_jour_heure", "label": "partner_jour_heure", "value": "partner_jour_heure" },
        { "id": "partner_devise", "label": "partner_devise", "value": "partner_devise" },
        { "id": "signature_digital", "label": "signature_digital", "value": "signature_digital" },
        { "id": "session_warning", "label": "session_warning", "value": "session_warning" },
        { "id": "session_warning_lead_time", "label": "session_warning_lead_time", "value": "session_warning_lead_time" },

        { "id": "recyclage_warning", "label": "recyclage_warning", "value": "recyclage_warning" },
        { "id": "recyclage_warning_lead_time", "label": "recyclage_warning_lead_time", "value": "recyclage_warning_lead_time" },

        { "id": "nb_heure_par_jour", "label": "nb_heure_par_jour", "value": "nb_heure_par_jour" },
        { "id": "nb_heure_par_semaine", "label": "nb_heure_par_semaine", "value": "nb_heure_par_semaine" },
        { "id": "nb_heure_par_annee", "label": "nb_heure_par_annee", "value": "nb_heure_par_annee" },



        { "id": "", "label": "", "value": "" },
    ]



    const New_Config_Quotation_Point = [
        { "id": "nb_relance_auto", "label": "Nbre Relance", "value": "nb_relance_auto" },
        { "id": "relance_auto", "label": "Relance Automatique", "value": "relance_auto" },
        { "id": "frequence_relance_auto", "label": "Fréquence Relance Auto.", "value": "frequence_relance_auto" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Config_paiement_ction_depart = [
        { "id": "facture", "label": "Date Facture", "value": "facture" },
        { "id": "mois", "label": "Fin de Mois", "value": "mois" },
        { "id": "", "label": "", "value": "" },
    ]

    const [Dialog_site_ftion_1_message, setDialog_site_ftion_1_message] = React.useState(false);
    const [Dialog_site_ftion_1_open, setDialog_site_ftion_1_open] = React.useState(false);

    const Dialog_site_ftion_1_handleClose = () => {

    };

    const Dialog_site_ftion_1_handleClose_buton = () => {

        setDialog_site_ftion_1_open(false);

        setp_detail_code_site_stored("");
        setp_detail_code_site("");
        setp_detail_nom_site("");
        setp_detail_site_adr("");
        setp_detail_site_cp("");
        setp_detail_site_ville("");
        setp_detail_site_pays("");
        setp_detail_site_telephone("");
        setp_detail_site_email("");
        setp_detail_site_description("");
        setp_detail_site_rattachement_id("");
        setp_detail_site_rattachement_nom("");

    };

    const [Dialog_paiement_ction_1_message, setDialog_paiement_ction_1_message] = React.useState(false);
    const [Dialog_paiement_ction_1_open, setDialog_paiement_ction_1_open] = React.useState(false);

    const Dialog_paiement_ction_1_handleClose = () => {

    };

    const Dialog_paiement_ction_1_handleClose_buton = () => {
        setgridline_paiement_ction_id("");

        setselected_paiement_id("");
        setp_detail_paiement_code("");
        setp_detail_paiement_description("");
        setp_detail_paiement_nb_jour("");
        setp_detail_paiement_depart("facture");

        setDialog_paiement_ction_1_open(false);

    };

    const [Dialog_crm_opport_1_message, setDialog_crm_opport_1_message] = React.useState(false);
    const [Dialog_crm_opport_1_open, setDialog_crm_opport_1_open] = React.useState(false);

    const Dialog_crm_opport_1_handleClose = () => {

    };

    const Dialog_crm_opport_1_handleClose_buton = () => {
        setgridline_crm_opport_id("");
        setselected_crm_opport_etape_id("");
        setp_detail_crm_opport_code("");
        setp_detail_crm_opport_description("");
        setp_detail_crm_opport_rang("");
        setp_detail_crm_opport_gagne(false);
        setDialog_crm_opport_1_open(false);

    };


    const [Add_Update_CRM_Opport_Etape_api, setAdd_Update_CRM_Opport_Etape_api] = useState();
    const [Add_Update_CRM_Opport_Etape_message, setAdd_Update_CRM_Opport_Etape_message] = useState();
    const [Add_Update_CRM_Opport_Etape_result, setAdd_Update_CRM_Opport_Etape_result] = useState();
    function Add_Update_CRM_Opport_Etape(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = "";

        if (String(selected_crm_opport_etape_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_CRM_List_Opportunite_Etape/";
            form.append("_id", selected_crm_opport_etape_id);
            form.append("code", p_detail_crm_opport_code);
            form.append("description", p_detail_crm_opport_description);
            form.append("rang", p_detail_crm_opport_rang);

            if (p_detail_crm_opport_gagne)
                form.append("gagne", "1");
            else
                form.append("gagne", "0");


        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_CRM_Opportunite_Etape/";
            form.append("code", p_detail_crm_opport_code);
            form.append("description", p_detail_crm_opport_description);
            form.append("rang", p_detail_crm_opport_rang);

            if (p_detail_crm_opport_gagne)
                form.append("gagne", "1");
            else
                form.append("gagne", "0");

        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_CRM_Opport_Etape  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_CRM_Opport_Etape  res.data.message r_class = " + res.data.message);
                setAdd_Update_CRM_Opport_Etape_api("true");
                setAdd_Update_CRM_Opport_Etape_result(res.data.message);
                Dialog_crm_opport_1_handleClose_buton();
                Getall_Partner_CRM_Opportunite_Etape();
                alert(res.data.message);
            }
            else {
                setAdd_Update_CRM_Opport_Etape_api("false");
                setAdd_Update_CRM_Opport_Etape_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_CRM_Opport_Etape = ', error);
            setAdd_Update_CRM_Opport_Etape_api("false");
            alert(" Impossible d'ajouter / mettre à jour l'étape");
            //setmyApimyApiMessage("")
        })
    }


    const [Add_Update_Competence_api, setAdd_Update_Competence_api] = useState();
    const [Add_Update_Competence_message, setAdd_Update_Competence_message] = useState();
    const [Add_Update_Competence_result, setAdd_Update_Competence_result] = useState();
    function Add_Update_Competence(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = "";

        if (String(selected_competence_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Competence/";
            form.append("competence_id", selected_competence_id);
            form.append("description", p_detail_competence_description);
            form.append("domaine", p_detail_competence_domaine);
            form.append("metier", p_detail_competence_metier);
            form.append("commentaire", p_detail_competence_commentaire);

        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Competence/";
            form.append("description", p_detail_competence_description);
            form.append("domaine", p_detail_competence_domaine);
            form.append("metier", p_detail_competence_metier);
            form.append("commentaire", p_detail_competence_commentaire);

        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_Competence  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_Competence  res.data.message r_class = " + res.data.message);
                setAdd_Update_Competence_api("true");
                setAdd_Update_Competence_result(res.data.message);
                Dialog_competence_1_handleClose_buton();
                Getall_Partner_Competence_List();
                alert(res.data.message);
            }
            else {
                setAdd_Update_Competence_api("false");
                setAdd_Update_Competence_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_Competence = ', error);
            setAdd_Update_Competence_api("false");
            alert(" Impossible d'ajouter / mettre à jour la compétence ");
            //setmyApimyApiMessage("")
        })
    }

    const [Dialog_competence_1_message, setDialog_competence_1_message] = React.useState(false);
    const [Dialog_competence_1_open, setDialog_competence_1_open] = React.useState(false);

    const Dialog_competence_1_handleClose = () => {

    };

    const Dialog_competence_1_handleClose_buton = () => {
        setgridline_competence_id("");
        setselected_competence_id("");
        setp_detail_competence_description("");
        setp_detail_competence_domaine("");
        setp_detail_competence_metier("");
        setp_detail_competence_commentaire("");
        setDialog_competence_1_open(false);

    };


    function submenu_technique() {
        Getall_Parter_config_Points();
        setsubmenu("technique");

        submenu_color_management("technique");

    }


    function submenu_type_client() {
        Getall_Parter_type_client();
        setsubmenu("type_client");

        submenu_color_management("type_client");


    }



    function submenu_site() {
        Getall_Partner_Site_Formation();
        setsubmenu("site");
        submenu_color_management("site");


    }



    function submenu_condition_paiement() {
        Getall_Partner_Paiement_Condition();
        setsubmenu("condition_paiement");
        submenu_color_management("condition_paiement");



    }

    function submenu_etape_opportunite() {
        Getall_Partner_CRM_Opportunite_Etape();
        setsubmenu("etape_opportunite");

        submenu_color_management("etape_opportunite");

    }



    function submenu_competence() {
        Getall_Partner_Competence_List();
        setsubmenu("competence");

        submenu_color_management("competence");


    }


    function submenu_devis() {
        Getall_Parter_Devis_Config_Points();
        setsubmenu("devis");
        submenu_color_management("devis");
    }


    function submenu_color_management(current_menu) {
        const list_sous_menu = ["competence", "technique", "type_client", "site", "condition_paiement", "etape_opportunite", "devis"]

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }



        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }

    const New_Option_Frequence_Relance = [
        { "id": "semaine", "label": "Semaine", "value": "semaine" },
        { "id": "mois", "label": "Mois", "value": "mois" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_Oui_Non = [
        { "id": "1", "label": "Oui", "value": "1" },
        { "id": "0", "label": "Non", "value": "0" },
    ]

    return (
        <div className="partner_configuration_technique">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            {/*** COMPETENCE */}

            <Dialog
                open={Dialog_competence_1_open}
                onClose={Dialog_competence_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle> Compétence </DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_competence_desc"
                            id="event_dialog_competence_desc"

                            fullWidth

                            value={p_detail_competence_description}
                            onChange={(e) => {
                                setp_detail_competence_description(e.target.value);

                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Domaine
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_competence_domaine"
                            id="event_dialog_competence_domaine"

                            fullWidth

                            value={p_detail_competence_domaine}
                            onChange={(e) => {
                                setp_detail_competence_domaine(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Métier
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_competence_metier"
                            id="event_dialog_competence_metier"

                            fullWidth
                            inputProps={{ min: "0", max: "100", step: "1" }}
                            value={p_detail_competence_metier}
                            onChange={(e) => {
                                setp_detail_competence_metier(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_competence_comment"
                            id="event_dialog_competence_comment"

                            fullWidth
                            inputProps={{ min: "0", max: "100", step: "1" }}
                            value={p_detail_competence_commentaire}
                            onChange={(e) => {
                                setp_detail_competence_commentaire(e.target.value);
                            }
                            }
                        />

                    </div>




                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_competence_id).trim().length > 2 && <Button onClick={Add_Update_Competence} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_competence_id).trim() === "" && <Button onClick={Add_Update_Competence} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_competence_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>


            {/*** FIN COMPETENCE */}
            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            {/*** Type Client */}
            <Dialog
                open={Dialog_type_client_1_open}
                onClose={Dialog_type_client_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Type de client </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_type_client_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > code
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_type_client_code"
                            id="event_dialog_type_client_code"

                            fullWidth

                            value={p_type_client_code}
                            onChange={(e) => {
                                setp_type_client_code(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_type_client_desc"
                            id="event_dialog_type_client_desc"

                            fullWidth

                            value={p_type_client_desc}
                            onChange={(e) => {
                                setp_type_client_desc(e.target.value);
                            }
                            }
                        />

                    </div>



                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_type_client_id).trim().length > 2 && <Button onClick={Add_Update_Client_Type} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_type_client_id).trim() === "" && <Button onClick={Add_Update_Client_Type} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_type_client_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*** Fin Type Client */}


            <Dialog
                open={Dialog_site_ftion_1_open}
                onClose={Dialog_site_ftion_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Sites de formation </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_site_ftion_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > code
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_code_site"
                            id="event_dialog_site_ftion_code_site"

                            fullWidth

                            value={p_detail_code_site}
                            onChange={(e) => {
                                setp_detail_code_site(e.target.value);

                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Nom
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_nom_site"
                            id="event_dialog_site_ftion_nom_site"

                            fullWidth

                            value={p_detail_nom_site}
                            onChange={(e) => {
                                setp_detail_nom_site(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_description"
                            id="event_dialog_site_ftion_description"

                            fullWidth

                            value={p_detail_site_description}
                            onChange={(e) => {
                                setp_detail_site_description(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Email
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_email"
                            id="event_dialog_site_ftion_email"

                            fullWidth

                            value={p_detail_site_email}
                            onChange={(e) => {
                                setp_detail_site_email(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Téléphone
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_description"
                            id="event_dialog_site_ftion_description"

                            fullWidth

                            value={p_detail_site_telephone}
                            onChange={(e) => {
                                setp_detail_site_telephone(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Adresse
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_adresse"
                            id="event_dialog_site_ftion_adresse"

                            fullWidth

                            value={p_detail_site_adr}
                            onChange={(e) => {
                                setp_detail_site_adr(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Code Postal
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_cp"
                            id="event_dialog_site_ftion_cp"

                            fullWidth

                            value={p_detail_site_cp}
                            onChange={(e) => {
                                setp_detail_site_cp(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Ville
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_ville"
                            id="event_dialog_site_ftion_ville"

                            fullWidth

                            value={p_detail_site_ville}
                            onChange={(e) => {
                                setp_detail_site_ville(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Pays
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_pays"
                            id="event_dialog_site_ftion_pays"

                            fullWidth

                            value={p_detail_site_pays}
                            onChange={(e) => {
                                setp_detail_site_pays(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Site de rattachement


                        {p_detail_code_site && New_Getall_Partner_Site_Formation_result && New_Getall_Partner_Site_Formation_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                                name="event_dialog_site_ftion_site_rattachement"
                                id="event_dialog_site_ftion_site_rattachement"
                                fullWidth
                                options={New_Getall_Partner_Site_Formation_result.filter((data) => (data).code_site !== String(p_detail_code_site_stored))}
                                value={New_Getall_Partner_Site_Formation_result.filter((data) => (data)._id === String(p_detail_site_rattachement_id))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_site_rattachement_id(value._id);

                                    } else {
                                        setp_detail_site_rattachement_id("");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        {/*<TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_site_ftion_site_rattachement"
                            id="event_dialog_site_ftion_site_rattachement"

                            fullWidth

                            value={p_detail_site_rattachement_nom}
                            onChange={(e) => {
                                setp_detail_site_rattachement_nom(e.target.value);
                            }
                            }
                        /> */}

                    </div>




                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_site_ftion_id).trim().length > 2 && <Button onClick={Add_Update_Site_Formation} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_site_ftion_id).trim() === "" && <Button onClick={Add_Update_Site_Formation} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_site_ftion_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            <Dialog
                open={Dialog_paiement_ction_1_open}
                onClose={Dialog_paiement_ction_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle> Condition de Paiement </DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="session_caract_Dialog" > code
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_paiement_ction_code"
                            id="event_dialog_paiement_ction_code"

                            fullWidth

                            value={p_detail_paiement_code}
                            onChange={(e) => {
                                setp_detail_paiement_code(e.target.value);

                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_paiement_ction_description"
                            id="event_dialog_paiement_ction_description"

                            fullWidth

                            value={p_detail_paiement_description}
                            onChange={(e) => {
                                setp_detail_paiement_description(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Nb Jours
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_paiement_ction_nb_jour"
                            id="event_dialog_paiement_ction_nb_jour"

                            fullWidth
                            type="number"
                            inputProps={{ min: "0", max: "100", step: "1" }}
                            value={p_detail_paiement_nb_jour}
                            onChange={(e) => {
                                setp_detail_paiement_nb_jour(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Départ
                        {p_detail_paiement_depart && New_Config_paiement_ction_depart && New_Config_paiement_ction_depart.length > 0 &&
                            <Autocomplete
                                disablePortal
                                name="event_dialog_paiement_ction_depart"
                                id="event_dialog_paiement_ction_depart"
                                //className="disabled_style"
                                fullWidth
                                value={New_Config_paiement_ction_depart.filter((data) => (data).value === String(p_detail_paiement_depart))[0].label}
                                options={New_Config_paiement_ction_depart}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_detail_paiement_depart(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                    </div>



                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_paiement_id).trim().length > 2 && <Button onClick={Add_Update_Paiement_Ction} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_paiement_id).trim() === "" && <Button onClick={Add_Update_Paiement_Ction} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_paiement_ction_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>


            <Dialog
                open={Dialog_crm_opport_1_open}
                onClose={Dialog_crm_opport_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle> CRM étape opportunité </DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="session_caract_Dialog" > code
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_crm_opport_code"
                            id="event_dialog_crm_opport_code"

                            fullWidth

                            value={p_detail_crm_opport_code}
                            onChange={(e) => {
                                setp_detail_crm_opport_code(e.target.value);

                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_crm_opport_description"
                            id="event_dialog_crm_opport_description"

                            fullWidth

                            value={p_detail_crm_opport_description}
                            onChange={(e) => {
                                setp_detail_crm_opport_description(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Rang
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_crm_opport_rang"
                            id="event_dialog_crm_opport_rang"

                            fullWidth
                            type="number"
                            inputProps={{ min: "0", max: "100", step: "1" }}
                            value={p_detail_crm_opport_rang}
                            onChange={(e) => {
                                setp_detail_crm_opport_rang(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog"> Gagné<br />
                        <Checkbox
                            name="detail_config_gagne"
                            id="detail_config_gagne"
                            //  className="disabled_style"
                            checked={p_detail_crm_opport_gagne}
                            onChange={(e) => {
                                setp_detail_crm_opport_gagne(e.target.checked);

                            }}

                        />
                    </div>



                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_crm_opport_etape_id).trim().length > 2 && <Button onClick={Add_Update_CRM_Opport_Etape} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_crm_opport_etape_id).trim() === "" && <Button onClick={Add_Update_CRM_Opport_Etape} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_crm_opport_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>


            <h3> Votre configuration </h3>

            <div className="div_row" ref={myRef_head} id="myRef_head" >
                <Button variant="outlined" onClick={submenu_technique} className="detail_class_submenu" id='technique' name='technique'>Technique</Button>
                <Button variant="outlined" onClick={submenu_type_client} className="detail_class_submenu" id='type_client' name='type_client'>Type de clients </Button>
                <Button variant="outlined" onClick={submenu_site} className="detail_class_submenu" id='site' name='site'>Sites de formation  </Button>
                <Button variant="outlined" onClick={submenu_condition_paiement} className="detail_class_submenu" id='condition_paiement' name='condition_paiement'>Conditions de paiement  </Button>
                <Button variant="outlined" onClick={submenu_etape_opportunite} className="detail_class_submenu" id='etape_opportunite' name='etape_opportunite'>Etapes d'opportunité </Button>
                <Button variant="outlined" onClick={submenu_competence} className="detail_class_submenu" id='competence' name='competence'>Compétences </Button>
                <Button variant="outlined" onClick={submenu_devis} className="detail_class_submenu" id='devis' name='devis'>Devis </Button>


            </div>


            {/*** Technique */}
            {String(submenu) === "technique" && <div className="div_row" style={{ paddingRight: '10px' }}>

                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>
                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            // checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_config_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    setp_detail_config_valeur();
                                                    setp_detail_config_point();
                                                    setConfig_data_changed();
                                                    setconfig_data_edit_mode();
                                                    setdisplay_detail_config();


                                                }
                                            }}
                                            selectionModel={selectionModel}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rowss.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    config_name: JSON.parse(item).config_name,
                                                    config_value: JSON.parse(item).config_value,
                                                }
                                            ))}

                                            columns={columns}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_id(newSelectionModel.row.id);

                                                handleClick_edit_config_From_Line(newSelectionModel.row.id);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            {/*<div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                            <Button variant="outlined" onClick={"submenu_add_one_order"}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Point de configuration &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>
                        </div>*/}
                        </div>
                        <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                    </div>



                    {display_detail_config && String(display_detail_config) === "1" && <div className="div_row">
                        {config_data_edit_mode && String(config_data_edit_mode) === "1" &&
                            <div className="session_caract"> Point de configuration<br />

                                <Autocomplete
                                    disablePortal
                                    name="detail_config_point"
                                    id="detail_config_point"
                                    className="disabled_style"
                                    value={New_Config_Point.filter((data) => (data).label === String(p_detail_config_point))[0].label}
                                    options={New_Config_Point}
                                    onChange={(event, value) => {

                                        if (value && value.value && value.value === "signature_digital") {
                                            alert(" Vos droits d'accès ne vous permettent pas  modifier la configuration : 'signature_digital'");
                                            return;
                                        }
                                        if (value && value.value) {
                                            setp_detail_config_point(value.value);
                                            setConfig_data_changed("1");
                                        } else {
                                            setp_detail_config_point("");
                                            setConfig_data_changed("1");
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />




                            </div>}
                        {String(config_data_edit_mode) !== "1" &&
                            <div className="session_caract"> Point  de configuration<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_config_point"
                                    id="detail_config_point"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_config_point}


                                />


                            </div>}

                        {String(config_data_edit_mode) === "1" && <div className="session_caract"> Valeur de configuration<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_valeur"
                                id="detail_config_valeur"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_config_valeur}
                                onChange={(e) => {
                                    if (String(p_detail_config_point) !== "signature_digital") {
                                        setp_detail_config_valeur(e.target.value);
                                        setConfig_data_changed("1");
                                    }
                                }
                                }
                            />
                        </div>}

                        {String(config_data_edit_mode) !== "1" && <div className="session_caract"> Valeur de configuration<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_valeur"
                                id="detail_config_valeur"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_config_valeur}

                            />
                        </div>}

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                            {String(Config_data_changed) === "1" && String(config_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                /!\ Pensez à enregistrer les modifications
                            </div>}
                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                {String(config_data_edit_mode) === "1" && String(Config_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Update_One_Config_Data}>Enregistrer les modifications
                                    </Button>

                                </div>}

                                {String(config_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={Annule_Order_DetailFields}>Annuler
                                    </Button>
                                </div>}
                            </div>


                            {String(config_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_Config_DetailFields}>Editer
                                </Button>

                            </div>}
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>

                    </div>
                    }
                </div>
            </div>}

            {/*** Type de clients   */}
            {String(submenu) === "type_client" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },




                                            /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                                "& .MuiDataGrid-row": {
                                                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                                }
                                            },*/
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            //   checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_type_client(newSelectionModel);

                                            }}
                                            selectionModel={selectionModel_type_client}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Parter_type_client_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code: JSON.parse(item).code,
                                                    description: JSON.parse(item).description,
                                                    is_financeur: JSON.parse(item).is_financeur,
                                                    is_client: JSON.parse(item).is_client,
                                                    is_fournisseur: JSON.parse(item).is_fournisseur,

                                                }
                                            ))}

                                            columns={columns_type_client}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                //setgridline_id(newSelectionModel.row.id);
                                                handleClick_edit_config_From_Client_Type(newSelectionModel.row.id);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {

                                    setp_type_client_code('');
                                    setp_type_client_desc('');
                                    setselected_type_client_id('');
                                    setDialog_type_client_1_open(true);

                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 type Client &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef_type_client} id="myRef_type_client"> &nbsp;</div>
                    </div>

                </div>
            </div>}

            {/*** Sites de formation  */}
            {String(submenu) === "site" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },




                                            /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                                "& .MuiDataGrid-row": {
                                                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                                }
                                            },*/
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            //   checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_site_ftion(newSelectionModel);

                                            }}
                                            selectionModel={selectionModel_site_ftion}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_Site_Formation_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code_site: JSON.parse(item).code_site,
                                                    nom_site: JSON.parse(item).nom_site,
                                                    description: JSON.parse(item).description,
                                                    site_adr: JSON.parse(item).site_adr,
                                                    site_cp: JSON.parse(item).site_cp,
                                                    site_ville: JSON.parse(item).site_ville,
                                                    site_pays: JSON.parse(item).site_pays,
                                                    telephone: JSON.parse(item).telephone,
                                                    email: JSON.parse(item).email,
                                                    site_rattachement_id: JSON.parse(item).site_rattachement_id,
                                                    site_rattachement_nom: JSON.parse(item).site_rattachement_nom,

                                                }
                                            ))}

                                            columns={columns_site_formation}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_site_ftion_id(newSelectionModel.row.id);
                                                setselected_site_ftion_id(newSelectionModel.row._id);

                                                setp_detail_code_site_stored(newSelectionModel.row.code_site);
                                                setp_detail_code_site(newSelectionModel.row.code_site);
                                                setp_detail_nom_site(newSelectionModel.row.nom_site);
                                                setp_detail_site_adr(newSelectionModel.row.site_adr);
                                                setp_detail_site_cp(newSelectionModel.row.site_cp);
                                                setp_detail_site_ville(newSelectionModel.row.site_ville);
                                                setp_detail_site_pays(newSelectionModel.row.site_pays);
                                                setp_detail_site_telephone(newSelectionModel.row.telephone);
                                                setp_detail_site_email(newSelectionModel.row.email);
                                                setp_detail_site_description(newSelectionModel.row.description);
                                                setp_detail_site_rattachement_id(newSelectionModel.row.site_rattachement_id);
                                                setp_detail_site_rattachement_nom(newSelectionModel.row.site_rattachement_nom);

                                                setDialog_site_ftion_1_open(true);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {

                                    setgridline_site_ftion_id("");
                                    setselected_site_ftion_id("");

                                    setp_detail_code_site_stored("");
                                    setp_detail_code_site("");
                                    setp_detail_nom_site("");
                                    setp_detail_site_adr("");
                                    setp_detail_site_cp("");
                                    setp_detail_site_ville("");
                                    setp_detail_site_pays("");
                                    setp_detail_site_telephone("");
                                    setp_detail_site_email("");
                                    setp_detail_site_description("");
                                    setp_detail_site_rattachement_id("");
                                    setp_detail_site_rattachement_nom("");

                                    setDialog_site_ftion_1_open(true);


                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 site de formation &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />

                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef_type_client} id="myRef_type_client"> &nbsp;</div>
                    </div>

                </div>
            </div>}

            {/*** Conditions de paiement */}
            {String(submenu) === "condition_paiement" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>


                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },




                                            /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                                "& .MuiDataGrid-row": {
                                                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                                }
                                            },*/
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            //   checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_paiement_ction(newSelectionModel);

                                            }}
                                            selectionModel={selectionModel_paiement_ction}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_Paiement_Condition_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code: JSON.parse(item).code,
                                                    description: JSON.parse(item).description,
                                                    nb_jour: JSON.parse(item).nb_jour,
                                                    depart: JSON.parse(item).depart,
                                                }
                                            ))}

                                            columns={columns_paiement_condition}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_paiement_ction_id(newSelectionModel.row.id);
                                                setselected_paiement_id(newSelectionModel.row._id);

                                                setp_detail_paiement_code(newSelectionModel.row.code);
                                                setp_detail_paiement_description(newSelectionModel.row.description);
                                                setp_detail_paiement_nb_jour(newSelectionModel.row.nb_jour);
                                                setp_detail_paiement_depart(newSelectionModel.row.depart);

                                                setDialog_paiement_ction_1_open(true);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {

                                    setgridline_paiement_ction_id("");

                                    setselected_paiement_id("");
                                    setp_detail_paiement_code("");
                                    setp_detail_paiement_description("");
                                    setp_detail_paiement_nb_jour("");
                                    setp_detail_paiement_depart("facture");

                                    setDialog_paiement_ction_1_open(true);


                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 condition &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />

                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef_type_client} id="myRef_type_client"> &nbsp;</div>
                    </div>

                </div>
            </div>}

            {/*** Etapes d'opportunité   */}
            {String(submenu) === "etape_opportunite" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>


                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },




                                            /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                                "& .MuiDataGrid-row": {
                                                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                                }
                                            },*/
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            //   checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_crm_opportunite_etape(newSelectionModel);

                                            }}
                                            selectionModel={selectionModel_crm_opportunite_etape}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_CRM_Opportunite_Etape_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code: JSON.parse(item).code,
                                                    description: JSON.parse(item).description,
                                                    rang: JSON.parse(item).rang,
                                                    gagne: JSON.parse(item).gagne,

                                                }
                                            ))}

                                            columns={columns_crm_opportune_etape}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_crm_opport_id(newSelectionModel.row.id);
                                                setselected_crm_opport_etape_id(newSelectionModel.row._id);

                                                setp_detail_crm_opport_code(newSelectionModel.row.code);
                                                setp_detail_crm_opport_description(newSelectionModel.row.description);
                                                setp_detail_crm_opport_rang(newSelectionModel.row.rang);

                                                setDialog_crm_opport_1_open(true);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {

                                    setgridline_crm_opport_id("");

                                    setselected_crm_opport_etape_id("");
                                    setp_detail_crm_opport_code("");
                                    setp_detail_crm_opport_description("");
                                    setp_detail_crm_opport_rang("");
                                    setp_detail_crm_opport_gagne(false);

                                    setDialog_crm_opport_1_open(true);


                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 étape &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />

                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef_type_client} id="myRef_type_client"> &nbsp;</div>
                    </div>

                </div>

            </div>}


            {/*** Compétences */}
            {String(submenu) === "competence" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>


                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },




                                            /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                                "& .MuiDataGrid-row": {
                                                    "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                                }
                                            },*/
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            //   checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_competence(newSelectionModel);

                                            }}
                                            selectionModel={selectionModel_competence}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_Competence_List_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    description: JSON.parse(item).description,
                                                    domaine: JSON.parse(item).domaine,
                                                    metier: JSON.parse(item).metier,
                                                    commentaire: JSON.parse(item).commentaire,

                                                }
                                            ))}

                                            columns={columns_list_competence}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_competence_id(newSelectionModel.row.id);
                                                setselected_competence_id(newSelectionModel.row._id);

                                                setp_detail_competence_description(newSelectionModel.row.description);
                                                setp_detail_competence_domaine(newSelectionModel.row.domaine);
                                                setp_detail_competence_metier(newSelectionModel.row.metier);
                                                setp_detail_competence_commentaire(newSelectionModel.row.commentaire);

                                                setDialog_competence_1_open(true);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {

                                    setselected_competence_id("");

                                    setp_detail_competence_description("");
                                    setp_detail_competence_domaine("");
                                    setp_detail_competence_metier("");
                                    setp_detail_competence_commentaire("");

                                    setDialog_competence_1_open(true);


                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 compétence &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />

                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef_type_client} id="myRef_type_client"> &nbsp;</div>
                    </div>

                </div>
            </div>}


            {/**** Devis */}
            {String(submenu) === "devis" && <div className="div_row" style={{ paddingRight: '10px' }}>

                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>
                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            // Gestion des cellule "statut"
                                            '& .cell--status--encours': {
                                                backgroundColor: '#E6F7C8',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--status--traite': {
                                                backgroundColor: '#E6CEAA',
                                                color: '#F9E79F',
                                                fontWeight: 'light',
                                            },

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            disableSelectionOnClick
                                            //   checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_quotation(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_config_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    setp_detail_quotation_config_valeur();
                                                    setConfig_quotation_data_changed();
                                                    setp_detail_quotation_config_point();
                                                    setconfig_quotation_data_edit_mode();
                                                    setdisplay_detail_quotation_config();


                                                }
                                            }}
                                            selectionModel={selectionModel_quotation}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Parter_Devis_Config_Points_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    config_name: JSON.parse(item).config_name,
                                                    config_value: JSON.parse(item).config_value,
                                                }
                                            ))}

                                            columns={columns_quotation}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_quotation_id(newSelectionModel.row.id);
                                                handleClick_edit_config_quotation_From_Line(newSelectionModel.row.id);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {


                                                //field === "order_header_status"
                                                if (params.field === "order_header_status" && String(params.value) == "0") {
                                                    return 'cell--status--brouillon';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "1") {

                                                    return 'cell--status--encours';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "2") {
                                                    return 'cell--status--traite';
                                                }

                                                if (params.field === "order_header_status" && String(params.value) == "3") {
                                                    return 'cell--status--facture';
                                                }

                                            }}
                                            getRowClassName={(params) => {

                                                if (String(params.row.status) === "-1") {
                                                    return 'line--statut--annule';
                                                }
                                                if (String(params.row.status) === "0") {
                                                    return 'line--statut--preinscrit';
                                                }
                                                if (String(params.row.status) === "1") {
                                                    return 'line--statut--inscrit';
                                                }

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                            {/*<div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                            <Button variant="outlined" onClick={"submenu_add_one_order"}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Point de configuration &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>
                        </div>*/}
                        </div>
                        <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                    </div>



                    {display_detail_quotation_config && String(display_detail_quotation_config) === "1" && <div className="div_row">
                        {config_quotation_data_edit_mode && String(config_quotation_data_edit_mode) === "1" &&
                            New_Config_Quotation_Point &&
                            <div className="session_caract"> Point de configuration<br />

                                <Autocomplete
                                    disablePortal
                                    name="detail_config_point"
                                    id="detail_config_point"
                                    className="disabled_style"
                                    value={New_Config_Quotation_Point.filter((data) => (data).value === String(p_detail_quotation_config_point))[0].label}
                                    options={New_Config_Quotation_Point}
                                    onChange={(event, value) => {


                                        if (value && value.value) {
                                            setp_detail_quotation_config_point(value.value);
                                            setConfig_quotation_data_changed("1");

                                        } else {
                                            setp_detail_quotation_config_point("");
                                            setConfig_quotation_data_changed("1");
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />




                            </div>}
                        {String(config_quotation_data_edit_mode) !== "1" &&
                            <div className="session_caract"> Point  de configuration<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_config_point"
                                    id="detail_config_point"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_quotation_config_point}

                                />


                            </div>}

                        {String(config_quotation_data_edit_mode) === "1" && String(p_detail_quotation_config_point) !== "frequence_relance_auto" &&
                            <div className="session_caract"> Valeur de configuration<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_config_valeur"
                                    id="detail_config_valeur"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_quotation_config_valeur}
                                    onChange={(e) => {

                                        setp_detail_quotation_config_valeur(e.target.value);
                                        setConfig_quotation_data_changed("1");

                                    }
                                    }
                                />
                            </div>}

                        {String(config_quotation_data_edit_mode) === "1" && String(p_detail_quotation_config_point) === "frequence_relance_auto" &&
                            <div className="session_caract">
                                <Tooltip className="tooltip_css" id="info_relance_1" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="info_relance_1" data-tooltip-html="Le devis sera envoyé tous les X jours, tant qu'il est encours et non expiré ">
                                    Fréquence Relance  (en jour) <FcInfo />   </a>
                                <br />

                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_config_valeur"
                                    id="detail_config_valeur"

                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_quotation_config_valeur}
                                    onChange={(e) => {

                                        setp_detail_quotation_config_valeur(e.target.value);
                                        setConfig_quotation_data_changed("1");

                                    }
                                    }
                                />



                            </div>}


                        {String(config_quotation_data_edit_mode) !== "1" && <div className="session_caract"> Valeur de configuration<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_valeur"
                                id="detail_config_valeur"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_quotation_config_valeur}

                            />
                        </div>}

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                            {String(Config_quotation_data_changed) === "1" && String(config_quotation_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                /!\ Pensez à enregistrer les modifications
                            </div>}
                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                {String(config_quotation_data_edit_mode) === "1" && String(Config_quotation_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Update_One_Quotation_Config_Data}>Enregistrer les modifications
                                    </Button>

                                </div>}

                                {String(config_quotation_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={Annule_quotation_DetailFields}>Annuler
                                    </Button>
                                </div>}
                            </div>


                            {String(config_quotation_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_Config_quotation_DetailFields}>Editer
                                </Button>

                            </div>}
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>

                    </div>
                    }
                </div>
            </div>}

        </div>
    )
}


export default Partner_Configuration_Technique;