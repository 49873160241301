import React, { useRef, useState, useEffect } from "react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import { gridClasses } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Box from '@mui/material/Box';

import 'react-tooltip/dist/react-tooltip.css'
import { FcCancel, FcApproval, FcAcceptDatabase, } from "react-icons/fc";
import 'reactjs-popup/dist/index.css';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { CiTrash } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom'
import Header from "./Header";
import Footer from "./Fotter";

import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Display_Action_And_Class from "./Display_Action_And_Class";


function UpdateAttendeeList() {

    const history = useHistory();
    const { client_id, session_id, partner_owner_recid } = useParams();
    const [isLoading, setLoading] = useState();

    const [tab_variable, settab_variable] = useState([]);

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    const [rowss_preinsc, setRows_preinsc] = useState([]);


    const [selectionModel_preinsc, setselectionModel_preinsc] = React.useState([]);
    const columns_preinscrit = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        {
            field: 'civilite', headerName: 'Civ.', minWidth: 100, flex: 1, maxWidth: 100, hide: false, editable: true,
            type: "singleSelect",
            valueOptions: [
                { value: 'm', label: 'M' },
                { value: 'mme', label: 'Mme' },
                { value: 'neutre', label: 'Neutre' }
            ],
            renderCell: (params) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                        {params.value && String(params.value) === "m" && "M"}
                        {params.value && String(params.value) === "mme" && "Mme"}
                        {params.value && String(params.value) === "neutre" && "Neutre"}

                    </div>

                );
            }
        },

        { field: 'nom', headerName: 'nom', minWidth: 150, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minWidth: 150, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minWidth: 150, flex: 1, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },



    ]

    const [document_checked, setdocument_checked] = useState("0");

    const [Getall_Client_Communication_Contact_api, setGetall_Client_Communication_Contact_api] = useState();
    const [Getall_Client_Communication_Contact_message, setGetall_Client_Communication_Contact_message] = useState();
    const [Getall_Client_Communication_Contact_result, setGetall_Client_Communication_Contact_result] = useState([]);
    function Getall_Client_Communication_Contact(event) {

        var form = new FormData();

        form.append("_id", client_id);
        form.append("partner_owner_recid", partner_owner_recid);

        //console.log(" ### Getall_Client_Communication_Contact form = ", form)
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Client_Communication_Contact_NO_TOKEN/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

         
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Client_Communication_Contact  res.data.status = " + res.data.status);
                //console.log(" In Getall_Client_Communication_Contact  res.data.message r_class = " + res.data.message);
                setGetall_Client_Communication_Contact_api("true");
                setGetall_Client_Communication_Contact_result(res.data.message);
                setdocument_checked("1");

            }
            else {
                setGetall_Client_Communication_Contact_api("false");
                setGetall_Client_Communication_Contact_message(res.data.message);
                alert(res.data.message);
                setdocument_checked("1");

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Client_Communication_Contact = ', error);
            setGetall_Client_Communication_Contact_api("false");
            alert(" Impossible de recuperer la liste des stagiaires")
            setdocument_checked("1");

            //setmyApimyApiMessage("")
        })
    }


    const [Partner_Recid, setPartner_Recid] = useState("");
    const [class_internal_url, setclass_internal_url] = useState("");



    const [New_Getall_TrainingParticipant_result, setNew_Getall_TrainingParticipant_result] = useState([]);

    const [Getall_TrainingParticipant_api, setGetall_TrainingParticipant_api] = useState();
    const [Getall_TrainingParticipant_message, setGetall_TrainingParticipant_message] = useState();
    const [Getall_TrainingParticipant_result, setGetall_TrainingParticipant_result] = useState([]);
    function Getall_TrainingParticipant(event) {

        var form = new FormData();

        form.append("client_rattachement_id", client_id);
        form.append("session_id", session_id);
        form.append("partner_owner_recid", partner_owner_recid);
        form.append("status", "0");

        //console.log(" ### Getall_TrainingParticipant form = ", form)
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Statgaire_List_Partner_with_filter_FOR_ONLY_INSCRIPTION_NO_TOKEN/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingParticipant  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingParticipant  res.data.message r_class = " + res.data.message);
                setGetall_TrainingParticipant_api("true");
                setGetall_TrainingParticipant_result(res.data.message);
                setRows_preinsc(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_email = JSON.parse(x).email;
                    var local_civilite = JSON.parse(x).civilite;

                    setPartner_Recid(String(JSON.parse(x).partner_owner_recid))
                    setclass_internal_url(String(JSON.parse(x).class_internal_url))

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "email": local_email,
                        "civilite": local_civilite,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });


                if (new_data2.length > 0) {
                    setNew_Getall_TrainingParticipant_result(new_data2);
                }

            }
            else {
                setGetall_TrainingParticipant_api("false");
                setGetall_TrainingParticipant_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_TrainingParticipant = ', error);
            setGetall_TrainingParticipant_api("false");
            alert(" Impossible de recuperer la liste des stagiaires")
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {

        Getall_TrainingParticipant();

        Getall_Client_Communication_Contact();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])


    function OnchangeCellDataGrid(params) {


        // params = la nouvelle lignes
       

        var num_line = params.id;  // ==> Id de la ligne changé
        var colomn = params.field; // ==> colonne de la ligne changé
        var new_value = params.value; // ==> la nouvelle valeur



        const updatedData = New_Getall_TrainingParticipant_result.map((x) => {

            var localid = (x).id;

            if (String(num_line) === String(localid) && colomn === "nom") {
                var tmp = {};
                tmp['id'] = (x).id;
                tmp['_id'] = (x)._id;
                tmp['nom'] = new_value;
                tmp['prenom'] = (x).prenom;
                tmp['email'] = (x).email;
                tmp['civilite'] = (x).civilite;

                return tmp;


            }

            if (String(num_line) === String(localid) && colomn === "prenom") {
                var tmp = {};
                tmp['id'] = (x).id;
                tmp['_id'] = (x)._id;
                tmp['nom'] = (x).nom;
                tmp['prenom'] = new_value;
                tmp['email'] = (x).email;
                tmp['civilite'] = (x).civilite;

                return tmp;
            }

            if (String(num_line) === String(localid) && colomn === "civilite") {
                var tmp = {};
                tmp['id'] = (x).id;
                tmp['_id'] = (x)._id;
                tmp['nom'] = (x).nom;
                tmp['prenom'] = (x).prenom;
                tmp['email'] = (x).email;
                tmp['civilite'] = new_value;

                return tmp;
            }

            if (String(num_line) === String(localid) && colomn === "email") {
                var tmp = {};
                tmp['id'] = (x).id;
                tmp['_id'] = (x)._id;
                tmp['nom'] = (x).nom;
                tmp['prenom'] = (x).prenom;
                tmp['email'] = new_value;
                tmp['civilite'] = (x).civilite;

                return tmp;
            }


            var tmp = {};
            tmp['id'] = (x).id;
            tmp['_id'] = (x)._id;
            tmp['nom'] = (x).nom;
            tmp['prenom'] = (x).prenom;
            tmp['email'] = (x).email;
            tmp['civilite'] = (x).civilite;

            return tmp;


        });

        console.log(" ### updatedData = ", updatedData);

        setNew_Getall_TrainingParticipant_result([]);

        setNew_Getall_TrainingParticipant_result(updatedData);

    }



    const [Record_New_Attendee_Data_api, setRecord_New_Attendee_Data_api] = useState();
    const [Record_New_Attendee_Data_message, setRecord_New_Attendee_Data_message] = useState();
    const [Record_New_Attendee_Data_result, setRecord_New_Attendee_Data_result] = useState([]);
    function Record_New_Attendee_Data() {
        var form = new FormData();

        const myJSON = JSON.stringify(tab_variable);



        const new_attende_dat_JSON = JSON.stringify(New_Getall_TrainingParticipant_result);

       // console.log(" Record_New_Attendee_Data New_Getall_TrainingParticipant_result = ", New_Getall_TrainingParticipant_result);

        const stored_cookie = getCookie('tokenmysypart');
        form.append("client_id", client_id);
        form.append("session_id", session_id);
        form.append("list_attendee_data", new_attende_dat_JSON);
        form.append("partner_recid", Partner_Recid);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Client_Update_Liste_Attendee_No_Token/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            console.log(" In Record_New_Attendee_Data  res.data.status = " + res.data.status);
            console.log(" In Record_New_Attendee_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {

                alert(res.data.message);
                history.push("/");
            }
            else {
                setRecord_New_Attendee_Data_api("false");
                setRecord_New_Attendee_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Record_New_Attendee_Data : Not good man :( Record_New_Attendee_Data = ' + error);
            setRecord_New_Attendee_Data_api("false");
            alert(" Impossible de mettre à jour les données des participants ");

        })
    }

    return (
        <div className="updateattendeelist">


            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Header />

            <div className="div_row">
                &nbsp;
            </div>

            {Getall_Client_Communication_Contact_result && Getall_Client_Communication_Contact_result.length > 0 && <div className="div_row" style={{ "width": "90%", "marginLeft": "5%", "background": "white", }}>
                <div style={{ textAlign: 'left' }}>


                    <nav style={{ textAlign: 'left' }}>
                        <b>   Bonjour  {JSON.parse(Getall_Client_Communication_Contact_result).civilite}  {JSON.parse(Getall_Client_Communication_Contact_result).nom} </b>

                    </nav>


                    Merci de completer les informations des participants à session de formation ci-dessous. <br />
                </div>
                <br />
                {Getall_TrainingParticipant_result && Getall_TrainingParticipant_result.length > 0 &&
                    <div style={{ textAlign: 'left' }}>
                        <b>  Formation :  </b>  {JSON.parse(Getall_TrainingParticipant_result[0]).title} <br />
                        <b>  Code Session :  </b>  {JSON.parse(Getall_TrainingParticipant_result[0]).title} <br />
                        <b>  Titre Session :  </b>  {JSON.parse(Getall_TrainingParticipant_result[0]).session_titre} <br />
                        <b>  Date début : </b>   {JSON.parse(Getall_TrainingParticipant_result[0]).date_du} <br />
                        <b>  Date fin : </b>  {JSON.parse(Getall_TrainingParticipant_result[0]).date_au} <br />

                    </div>
                }

                <br />
            </div>}

            <div className="div_row">
                &nbsp;
            </div>
            {New_Getall_TrainingParticipant_result && New_Getall_TrainingParticipant_result.length > 0 && <div style={{ "width": "90%", "marginLeft": "5%", "background": "white", }}>


                <div className="session_data" >
                    <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                        <Box
                            sx={{
                                height: 500,
                                width: '100%',
                                paddingRight: '2px',


                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                                '& .line--statut--invoiced': {
                                    backgroundColor: '#EAFAF1',
                                    color: '#239B56',
                                    fontWeight: '700'

                                },
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#c8cfd5",
                                    color: "black",
                                    fontSize: 14
                                },


                            }}

                        >
                            <DataGrid

                                onCellEditCommit={OnchangeCellDataGrid}
                                editMode="cell"


                                checkboxSelection
                                onSelectionModelChange={(newSelectionModel) => {
                                    setselectionModel_preinsc(newSelectionModel);
                                    //console.log("ch selected--" + newSelectionModel);
                                }}
                                selectionModel={selectionModel_preinsc}

                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={New_Getall_TrainingParticipant_result.map((item, index) => (
                                    {
                                        id: index,
                                        _id: item._id,
                                        nom: item.nom,
                                        prenom: (item).prenom,
                                        email: (item).email,
                                        civilite: (item).civilite,
                                    }
                                ))}

                                columns={columns_preinscrit}
                                pageSize={10}
                                className="datagridclass"

                                rowsPerPageOptions={[10]}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                getEstimatedRowHeight={() => 200}
                                getRowHeight={() => "auto"}
                                sx={{
                                    "& .MuiDataGrid-cellContent": {
                                        minHeight: 50,

                                    },
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </Box>

                        <br />

                    </div>

                </div>
                <br />
                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                    <Popup
                        trigger={

                            <Button variant="contained" className="bton_enreg" onClick={Record_New_Attendee_Data}>Mettre à jour
                            </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, les données des préinscrits seront mises à jour.


                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            Record_New_Attendee_Data(event);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                </div>

                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                    <Button variant="contained" className="bton_annule" onClick={(event) => {
                        history.push("/");
                    }}  >Annuler
                    </Button>
                </div>

                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>

                <div style={{ "width": "100%", "background": "#F2F4F4", overflow: "auto", "paddingBottom": "1rem" }}>
                    <br />

                    {class_internal_url && String(class_internal_url).length > 2 && <div className="question_box_entete">
                        <Display_Action_And_Class classId={class_internal_url} action={""} />

                    </div>}
                </div>

            </div>}

            <div className="div_row">
                {String(document_checked) === "1" && (!Getall_TrainingParticipant_result || Getall_TrainingParticipant_result.length <= 0) && <div style={{
                    height: "40vh", "width": "100%",
                    "background": "#F2F4F4", overflow: "auto", "paddingBottom": "1rem",
                    "marginTop":"5rem", "color":"red", fontWeight:'700', "fontSize":"20px"
                }}>

                    Le document est invalide

                </div>}
            </div>

            <div className="div_row">
                &nbsp;
            </div>


            <div className="pieddepage">
                <Footer />
            </div>
        </div>
    );
}

export default UpdateAttendeeList;