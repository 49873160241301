import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useParams } from 'react-router-dom'
import axios from "axios";

import Header from "./Header";
import Footer from "./Fotter";
import { getCookie } from 'react-use-cookie';
import { useCookies } from "react-cookie";

function DeleteUserAccount() {
    const { token } = useParams();
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("");
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);


    function Check() {

    }
    const history = useHistory();

    // Verification de la valididé du token
    function DeleteUserAccount(e) {


        var form = new FormData();
        form.append("user_recid", token);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_user_account/";

        //alert("myurl = "+myurl);
        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("true");
                setResult(res.data.message);

                user_logout_confirmation();
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
                setResult(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })

    }

    const [userconnected, setuserconnected] = useState("0");
    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const stored_user = getCookie('tokenmysych');
    const stored_part = getCookie('tokenmysypart');

    function whosisconnected() {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            // alert(" partner connected OK");
        }
    }


    useEffect(() => {
        async function fetchData() {
            const result = await axios('https://geolocation-db.com/json/',);
            setuserIp(result.data.IPv4);
            setcountry_code(result.data.country_code);
            setcountry_name(result.data.country_name);
            setcity(result.data.city);
            setpostal(result.data.postal);
            setlatitude(result.data.latitude);
            setlongitude(result.data.longitude);
            setIPv4(result.data.IPv4);
            setstate(result.data.state);
            setuserIp(result.data.IPv4);

        }
        fetchData();

        DeleteUserAccount();
        whosisconnected();

    }, []);


    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
           
        }
        
    }



    function handleAccountLogout() {

        if (String(userconnected) === String("1")) {
            user_logout_confirmation();
        }
     
    }


    return (
        <div className="deleteuseraccount">

            <Helmet>
                <title>Suppression du compte MySy Training</title>
                <meta name="description" content="Suppression compte Mysy Training" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />
            <div className="div_mobile">


                <div >
                    &nbsp;
                </div>
                <br />
                <br />
                <div >
                    <nav className="title"> Suppression du compte utilisateur MySy  </nav>
                    {String(myApiResponse) === String("true") && <div className="okUpdateData">
                         <br />
                        {result}

                    </div>}

                    {String(myApiResponse) !== String("true") && <div className="koUpdateData">
                        KO, Impossible de supprimer votre compte. merci de contacter le support de MySy Training.

                    </div>}

                </div>
                <div className="pieddepage">
                    <Footer />
                </div>
            </div>
        </div>
    )

};

export default DeleteUserAccount;