import React, { useState, useEffect } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import { confirmAlert } from 'react-confirm-alert'; // Import
import bannerimg from "../mysy_img/MYSY_banner_compte.png";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';
import { useCookies } from "react-cookie";
import { format } from 'date-fns'
import moment from "moment";
import connect_white from "../mysy_img/connect_white_v2.png";
import connect_green from "../mysy_img/connect_green.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import mysy_logo2 from "../mysy_img2/Logo_MySy.png"

const Header = () => {
    const history = useHistory();
    const stored_user = getCookie('tokenmysych');
    const stored_part = getCookie('tokenmysypart');
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych'], { path: '/' });
    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart'], { path: '/' });

    const [country_code, setcountry_code] = useState("FR");
    const [country_name, setcountry_name] = useState("France");
    const [city, setcity] = useState("Paris");
    const [postal, setpostal] = useState("75001");
    const [latitude, setlatitude] = useState("48.861000061035156");
    const [longitude, setlongitude] = useState("2.3380000591278076");
    const [IPv4, setIPv4] = useState("172.71.122.128");
    const [state, setstate] = useState("France");
    const [userIp, setuserIp] = useState("172.71.122.128");



    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);
    const [CategorieMenu, setCategorieMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };


    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    const CategorieMenu_Close = () => {
        setCategorieMenu(null);
    };

    const handleCategorieMenu = (event) => {
        setCategorieMenu(event.currentTarget);
    };



    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };


    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };



    const handleClassCategorie = (e) => {
        setCategorieMenu(null);
        var mymetier = "";
        mymetier = e.target.id
        //alert(" cours de : " + mymetier);
        history.push("/Recherche_new_v2/0/" + mymetier);
    };


    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/tarifs_produits_services/")
    };

    const handleAvis = () => {
        setavisMenu(null);
        history.push("/Recherche-Article-formation")
    };

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */

        if (typeof (stored_part) != "undefined") {
            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie + "_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });
            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });


        }
        setsomeoneconnected("0");
        setDialog_1_open(false);
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }

    const [userconnected, setuserconnected] = useState("0");
    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");

    useEffect(() => {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            //alert(" partner connected OK");
        }
    }, []);

    function handleAccountLogout() {


        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }


    const handleMyPartnerAccount = () => {
        setAccountMenu(null);
        history.push("/Partner")
    };

    function handleAccountConnexion_v2() {

        if (String(userconnected) === String("1")) {
            handleMyAccount();
        }
        else if (String(partnerconnected) === String("1")) {
            handleMyPartnerAccount();
        }
    }
    function publiecatalogue() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }

    const handleBlogMenu = (event) => {

        history.push("/Recherche-Article-formation")
    };

    const handleQuisommesNousMenu = (event) => {
        history.push("/qui-sommes-nous")
    };

    const handleMarketplace = (event) => {
        history.push("/recherche-formation")
    };

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const [Dialog_contact_message, setDialog_contact_message] = React.useState(false);
    const [Dialog_contact_open, setDialog_contact_open] = React.useState(false);
    function Dialog_contact_handle_change_participant_session(message) {
        setDialog_contact_message(message);
        setDialog_contact_open(true);
    }

    const Dialog_contact_handleClose = () => {

    };

    const Dialog_contact_handleClose_buton = () => {

        setDialog_contact_open(false);
        clear_contact_message_fields();

    };

    function clear_contact_message_fields() {
        setcontact_message_nom_prenom("");
        setcontact_message_email("");
        setcontact_message_phone("");
        setcontact_message_sujet("");
        setcontact_message_sujet("");
        setcontact_message_message("");
    }

    const [contact_message_nom_prenom, setcontact_message_nom_prenom] = React.useState("");
    const [contact_message_email, setcontact_message_email] = React.useState("");
    const [contact_message_phone, setcontact_message_phone] = React.useState("");
    const [contact_message_sujet, setcontact_message_sujet] = React.useState("");
    const [contact_message_message, setcontact_message_message] = React.useState("");

    function Send_Contact_Message(e) {

        var sender_mail = "";
        var sender_tel = "";
        var token = "";

        ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")

        var mysubject = contact_message_sujet;
        var mymessage = contact_message_message;
        var mysender_name = contact_message_nom_prenom;
        var mysender_email = contact_message_email;


        //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
        //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

        var re = /\S+@\S+\.\S+/;
        if (re.test(mysender_email) == false) {
            alert("l'email est incorrecte");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
        var form = new FormData();
        form.append("type", "email");
        form.append("recever_mail", "contact@mysy-training.com");
        form.append("recever_tel", "+33769203945");
        form.append("sender_mail", mysender_email);
        form.append("sender_tel", sender_tel);
        form.append("object", mysubject);
        form.append("message", mymessage);
        form.append("sender_name", mysender_name);
        form.append("token", "");
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        // console.log("## form = ", form);



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("false")) {

                alert(res.data.message);
            }

            else if (String(res.data.status) === String("true")) {
                Dialog_contact_handleClose_buton();
                alert(res.data.message);

            }
            else {

            }
        }).catch((error) => {
            console.warn('contact-nous  Not good man :( mysearchtext = ');


        })


    }



    return (
        <div className="header">


            {/***   GESTION Contact */}
            <Dialog
                open={Dialog_contact_open}
                onClose={Dialog_contact_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>Contactez Nous</DialogTitle>
                <DialogContent className="DialogContent_width">


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                            Nom & Prénom
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            <input
                                type='text'
                                name='name'
                                value={contact_message_nom_prenom}
                                onChange={(e) => {
                                    setcontact_message_nom_prenom(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Nom'
                            ></input>
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                            E-mail
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <input
                                type='email'
                                name='email'
                                id='email'
                                value={contact_message_email}
                                onChange={(e) => {
                                    setcontact_message_email(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Adresse email'
                            ></input>
                        </div>
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                            Téléphone
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <input
                                type='text'
                                name='phone'
                                id='phone'
                                value={contact_message_phone}
                                onChange={(e) => {
                                    setcontact_message_phone(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Numéro Tél'
                            ></input>
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            Sujet
                        </div>
                        <div className="div_row_dialog" >
                            <input
                                type='text'
                                name='subject'
                                value={contact_message_sujet}
                                onChange={(e) => {
                                    setcontact_message_sujet(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Sujet'
                            ></input>
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            Votre message
                        </div>
                        <div className="div_row_dialog" >
                            <textarea
                                rows={3}
                                name='message'
                                value={contact_message_message}
                                onChange={(e) => {
                                    setcontact_message_message(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Message'
                            ></textarea>
                        </div>
                    </div>

                    <div className="div_row_dialog" >

                        <div>MySy Training Technology<br />
                        </div>
                        <div style={{ fontSize: "small" }}>

                            Adresse : 1 Cr du Havre, 75008 Paris <br />
                            Adresse email :
                            <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                            Téléphone fixe : +331 77 00 38 57<br />
                        </div>

                    </div>


                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Dialog_contact_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Send_Contact_Message} className="bton_enreg_dialog">Envoyer&nbsp; <SendIcon /></Button>

                        </div>
                    </div>

                </DialogActions>


            </Dialog>
            {/***  FIN GESTION Contact */}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        Confirmez la deconnexion
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={part_logout_confirmation} className="bton_enreg_dialog">Deconnexion</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_1_handleClose_buton} className="bton_annule_dialog" >Annuler</Button>
                        </div>
                    </div>

                </DialogActions>
            </Dialog>

            <Menu
                keepMounted
                anchorEl={PartnerMenu}
                onClose={PartnerMenu_Close}
                open={Boolean(PartnerMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handlePartnerConnexion} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }} >Mon Compte</MenuItem>
                <MenuItem onClick={part_logout} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }} >Deconnexion</MenuItem>

            </Menu>

            <Menu
                keepMounted
                anchorEl={AccountMenu}
                onClose={AccountMenu_Close}
                open={Boolean(AccountMenu)}
                style={{ "top": "2rem" }}

            > <MenuItem onClick={handleMyAccount} className="mymenu_item"
                style={{ "fontSize": "0.7rem" }}>Mon Compte</MenuItem>
                <MenuItem onClick={handleAccountConnexion} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }}>Connexion</MenuItem>
                <MenuItem onClick={handleAccountCreation} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }}>Creation</MenuItem>
                <MenuItem onClick={user_logout} className="mymenu_item"
                    style={{ "fontSize": "0.7rem" }}>Deconnexion</MenuItem>
            </Menu>

            <Menu
                keepMounted
                anchorEl={ProduitMenu}
                onClose={ProduitMenu_Close}
                open={Boolean(ProduitMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleProduitService} className="mymenu_item">
                    <button className='bton_menu'> Tarifs  </button>
                </MenuItem>

                <MenuItem onClick={handleAvis} className="mymenu_item">
                    <button className='bton_menu'>Le blog </button>
                </MenuItem>

            </Menu>


            <Menu
                keepMounted
                anchorEl={CategorieMenu}
                onClose={CategorieMenu_Close}
                open={Boolean(CategorieMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="IT" >Informatique</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="vente" > Commerce</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="RH" > Ressources Humaines</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="marketing" >Marketting</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="graphisme" >Graphisme</button>
                </MenuItem>


                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="management" >Management</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item">
                    <button className='bton_menu' id="dev_perso" >Dev. Personnel </button>
                </MenuItem>


            </Menu>


            <div className="header_div_row2">

                <div className="header_div_row_bas">
                    <div className="header_div_001">
                        <div className="header_div_002">
                            <h1 className="header_h1_transparent">Connexion MySy Training Technology </h1>

                            <div className="vvv" >
                                <a href={process.env.REACT_APP_BASE_URL} style={{ "textDecoration": "none" }}> <img class="img-responsive header_img_logo22" src={mysy_logo2} alt="MySy Training Technology" />
                                    <nav className="training">TRAINING</nav>
                                </a>

                            </div>
                            <div className="header_menuPrincipal_left" onClick={handleMarketplace}>
                                Marketplace
                            </div>

                        </div>
                        <div className="header_div_003">
                            <h1 className="header_h1_transparent">Connexion MySy Training Technology </h1>

                            <div className="header_menuPrincipal_bton" >
                                <div style={{ "textAlign": "left" }}>
                                    {String(someoneconnected) !== String("1") &&
                                        <div className="header_menuPrincipal" onClick={(event) => {
                                            handleAccountConnexion();
                                        }}>
                                            Connexion
                                        </div>}

                                    {String(someoneconnected) === String("1") && <div className="header_menuPrincipal" onClick={(e) => setDialog_1_open(true)}>
                                        <font style={{ color: "rgb(129, 188, 58)" }}> Deconnexion </font>
                                    </div>}

                                </div>
                            </div>


                            {String(someoneconnected) === String("1") && <div className="header_menuPrincipal" onClick={handleAccountConnexion_v2}>
                                Mon Compte
                            </div>}

                            {String(someoneconnected) !== String("1") && <div className="header_menuPrincipal" onClick={handleAccountCreation}>
                                S'inscrire
                            </div>}

                            <div className="header_menuPrincipal" onClick={(e) => {
                                e.preventDefault();
                                var date2 = new Date();
                                const format2 = "YYYY-MM";
                                var dateTime2 = moment(date2).format(format2);
                                window.open(
                                    "https://calendly.com/mysytraining/demo-mysy-training-solution?month=" + String(dateTime2),
                                    '_blank'
                                );
                            }}
                                style={{ "background": "#53B7EB", "borderRadius": "5px", "paddingLeft": "3px", "paddingRight": "3px" }}>
                                Reserver une Demo
                            </div>

                            <div className="header_menuPrincipal" onClick={(event) => {
                                setDialog_contact_open(true);
                            }}>
                                Contact
                            </div>


                            <div className="header_menuPrincipal" onClick={handleProduitService} >
                                Tarifs
                            </div>


                            <div className="header_menuPrincipal" onClick={(e) => { history.push("/fonctionnalites") }}>
                                Fonctionnalites
                            </div>

                            <div className="header_menuPrincipal" onClick={handleQuisommesNousMenu}>
                                A propos
                            </div>

                        </div>
                    </div>


                    <div className="header_text_head">
                        <hr className="header_div_004" />
                        {/*<font style={{ "color": "#81BC3A", "fontWeight": "bold" }}>Recherche </font> de Formations & Tutoriels*/}
                    </div>

                </div>
            </div>

        </div>
    )

}

export default Header;