import React from "react";
import homeImg from "../mysy_img/mysy_home.jpg";
import { Helmet } from "react-helmet";

const Acceuil = () => {



  return (

     <div className={"cards"}>
       <Helmet>
                <title>Acceul Mysy Training</title>
                <meta name="description" content= "Mysy Training Formation Acceuil"/>
                <meta name="robots" content="index,follow"/>
            </Helmet>

    <header className="card">
        <img src={homeImg} className="App-logo" alt="logo" />
        <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
        >

        </a>

    </header>

</div>
  )
}


export default Acceuil;