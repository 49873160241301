import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import ToggleSwitch from "./ToggleSwitch";
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

const Partner_domaine_Categorie_Metier = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("technique");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selectionModel_domaine, setselectionModel_domaine] = React.useState([]);

    const [selectionModel_categorie, setselectionModel_categorie] = React.useState([]);

    const [selectionModel_metier, setselectionModel_metier] = React.useState([]);

    const [selected_domaine_id, setselected_domaine_id] = React.useState("");
    const [selected_categorie_id, setselected_categorie_id] = React.useState("");
    const [selected_metier_id, setselected_metier_id] = React.useState("");


    const [isLoading, setLoading] = useState();
    const myRef_head = useRef(null);
    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    const columns_domaine = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'description', headerName: 'Description', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le domaine sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_domaine(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_categorie = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'description', headerName: 'Description', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_domaine_id', headerName: 'class_domaine_id', hide: true },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la catégorie sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Categorie(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const columns_metier = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'description', headerName: 'Description', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_domaine_id', headerName: 'class_domaine_id', hide: true },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le métier sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Metier(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]



    const [handleClick_delete_Metier_api, sethandleClick_delete_Metier_api] = useState();
    const [handleClick_delete_Metier_message, sethandleClick_delete_Metier_message] = useState();
    const [handleClick_delete_Metier_result, sethandleClick_delete_Metier_result] = useState();
    function handleClick_delete_Metier(event, cellValues) {

        var type_client_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", type_client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_class_metier/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Metier  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Metier  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Metier_api("true");
                sethandleClick_delete_Metier_result(res.data.message);

                setmetier_data_edit_mode("");
                setmetier_data_changed("");
                setdisplay_detail_metier("");


                Getall_Partner_Class_Metier();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Metier_api("false");
                sethandleClick_delete_Metier_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Metier = ', error);
            sethandleClick_delete_Metier_api("false");
            alert(" Impossible de supprimer le métier");
            //setmyApimyApiMessage("")
        })
    }




    const [handleClick_delete_Categorie_api, sethandleClick_delete_Categorie_api] = useState();
    const [handleClick_delete_Categorie_message, sethandleClick_delete_Categorie_message] = useState();
    const [handleClick_delete_Categorie_result, sethandleClick_delete_Categorie_result] = useState();
    function handleClick_delete_Categorie(event, cellValues) {

        var type_client_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", type_client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Class_Categorie/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Categorie  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Categorie  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Categorie_api("true");
                sethandleClick_delete_Categorie_result(res.data.message);

                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");
                setdisplay_detail_categorie("");


                Getall_Partner_Class_Categorie();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Categorie_api("false");
                sethandleClick_delete_Categorie_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Categorie = ', error);
            sethandleClick_delete_Categorie_api("false");
            alert(" Impossible de supprimer la catégorie");
            //setmyApimyApiMessage("")
        })
    }

    const [handleClick_delete_domaine_api, sethandleClick_delete_domaine_api] = useState();
    const [handleClick_delete_domaine_message, sethandleClick_delete_domaine_message] = useState();
    const [handleClick_delete_domaine_result, sethandleClick_delete_domaine_result] = useState();
    function handleClick_delete_domaine(event, cellValues) {

        var type_client_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", type_client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Class_domaine/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_domaine  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_domaine  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_domaine_api("true");
                sethandleClick_delete_domaine_result(res.data.message);

                setdomaine_data_edit_mode("");
                setdomaine_data_changed("");
                setdisplay_detail_domaine("");


                Getall_Partner_Class_Domaine();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_domaine_api("false");
                sethandleClick_delete_domaine_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_domaine = ', error);
            sethandleClick_delete_domaine_api("false");
            alert(" Impossible de supprimer la catégorie");
            //setmyApimyApiMessage("")
        })
    }

    

    const [gridline_domaine_id, setgridline_domaine_id] = useState("");
    const [gridline_categorie_id, setgridline_categorie_id] = useState("");
    const [gridline_metier_id, setgridline_metier_id] = useState("");

    const [p_detail_domaine_id, setp_detail_domaine_id] = useState("");
    const [p_detail_domaine_code, setp_detail_domaine_code] = useState("");
    const [p_detail_domaine_nom, setp_detail_domaine_nom] = useState("");
    const [p_detail_domaine_description, setp_detail_domaine_description] = useState("");



    const [p_detail_categorie_id, setp_detail_categorie_id] = useState("");
    const [p_detail_categorie_code, setp_detail_categorie_code] = useState("");
    const [p_detail_categorie_nom, setp_detail_categorie_nom] = useState("");
    const [p_detail_categorie_description, setp_detail_categorie_description] = useState("");
    const [p_detail_categorie_class_domaine_id, setp_detail_categorie_class_domaine_id] = useState("");


    const [p_detail_metier_id, setp_detail_metier_id] = useState("");
    const [p_detail_metier_code, setp_detail_metier_code] = useState("");
    const [p_detail_metier_nom, setp_detail_metier_nom] = useState("");
    const [p_detail_metier_description, setp_detail_metier_description] = useState("");
    const [p_detail_metier_class_domaine_id, setp_detail_metier_class_domaine_id] = useState("");

    const [domaine_data_edit_mode, setdomaine_data_edit_mode] = useState("");
    const [domaine_data_changed, setdomaine_data_changed] = useState("");


    const [categorie_data_edit_mode, setcategorie_data_edit_mode] = useState("");
    const [categorie_data_changed, setcategorie_data_changed] = useState("");


    const [metier_data_edit_mode, setmetier_data_edit_mode] = useState("");
    const [metier_data_changed, setmetier_data_changed] = useState("");


    const [display_detail_domaine, setdisplay_detail_domaine] = useState("");
    const [display_detail_categorie, setdisplay_detail_categorie] = useState("");
    const [display_detail_metier, setdisplay_detail_metier] = useState("");

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    function submenu_domaine() {
        setsubmenu("domaine");
        Getall_Partner_Class_Domaine();
        submenu_color_management("domaine")
    }

    async function submenu_categorie() {
        setsubmenu("categorie");
        await sleep(5);

        Getall_Partner_Class_Categorie();
        submenu_color_management("categorie");

        if (String(display_detail_categorie) === "1" && String(categorie_data_edit_mode) !== "1") {
            Disable_Categorie_DetailFields();
        }
    }


    function submenu_metier() {
        setsubmenu("metier");
        Getall_Partner_Class_Metier();

        if (String(display_detail_metier) === "1" && String(metier_data_edit_mode) !== "1") {
            Disable_Metier_DetailFields();
        }

        submenu_color_management("metier")
    }


    function submenu_color_management(current_menu) {
        const list_sous_menu = ["domaine", "categorie", "metier",]

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }

        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

    }

    const myRef = useRef(null)


    const [New_Getall_Partner_Class_domaine_result, setNew_Getall_Partner_Class_domaine_result] = useState([]);

    const [Getall_Partner_Class_domaine_api, setGetall_Partner_Class_domaine_api] = useState();
    const [Getall_Partner_Class_domaine_message, setGetall_Partner_Class_domaine_message] = useState();
    const [Getall_Partner_Class_domaine_result, setGetall_Partner_Class_domaine_result] = useState([]);
    function Getall_Partner_Class_Domaine(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_domaine/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Class_Domaine  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Class_Domaine  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Class_domaine_api("true");
                setGetall_Partner_Class_domaine_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_description = JSON.parse(x).description;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "code": local_code,
                        "description": local_description,
                        "nom": local_nom,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "nom": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Class_domaine_result(new_data2);


            }
            else {
                setGetall_Partner_Class_domaine_api("false");
                setGetall_Partner_Class_domaine_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Class_Domaine = ', error);
            setGetall_Partner_Class_domaine_api("false");
            alert(" Impossible de recuperer la liste des domaines de formation ");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Class_Categorie_result, setNew_Getall_Partner_Class_Categorie_result] = useState([]);

    const [Getall_Partner_Class_Categorie_api, setGetall_Partner_Class_Categorie_api] = useState();
    const [Getall_Partner_Class_Categorie_message, setGetall_Partner_Class_Categorie_message] = useState();
    const [Getall_Partner_Class_Categorie_result, setGetall_Partner_Class_Categorie_result] = useState([]);
    function Getall_Partner_Class_Categorie(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Categorie/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Class_Categorie  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Class_Categorie  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Class_Categorie_api("true");
                setGetall_Partner_Class_Categorie_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_description = JSON.parse(x).description;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "code": local_code,
                        "description": local_description,
                        "nom": local_nom,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "nom": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Class_Categorie_result(new_data2);


            }
            else {
                setGetall_Partner_Class_Categorie_api("false");
                setGetall_Partner_Class_Categorie_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Class_Categorie = ', error);
            setGetall_Partner_Class_Categorie_api("false");
            alert(" Impossible de recuperer la liste des domaines de formation ");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Class_Metier_result, setNew_Getall_Partner_Class_Metier_result] = useState([]);

    const [Getall_Partner_Class_Metier_api, setGetall_Partner_Class_Metier_api] = useState();
    const [Getall_Partner_Class_Metier_message, setGetall_Partner_Class_Metier_message] = useState();
    const [Getall_Partner_Class_Metier_result, setGetall_Partner_Class_Metier_result] = useState([]);
    function Getall_Partner_Class_Metier(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_class_metier/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Class_Metier  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Class_Metier  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Class_Metier_api("true");
                setGetall_Partner_Class_Metier_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_description = JSON.parse(x).description;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "code": local_code,
                        "description": local_description,
                        "nom": local_nom,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "nom": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Class_Metier_result(new_data2);


            }
            else {
                setGetall_Partner_Class_Metier_api("false");
                setGetall_Partner_Class_Metier_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Class_Metier = ', error);
            setGetall_Partner_Class_Metier_api("false");
            alert(" Impossible de recuperer la liste des domaines de formation ");
            //setmyApimyApiMessage("")
        })
    }


    function handleClick_edit_categorie_From_Line(selected_row_id) {

        var line = JSON.parse(Getall_Partner_Class_Categorie_result[selected_row_id]);

        setselected_categorie_id(line._id);

        setp_detail_categorie_id(line._id);
        setp_detail_categorie_code(line.code);
        setp_detail_categorie_nom(line.nom);
        setp_detail_categorie_description(line.description);
        setp_detail_categorie_class_domaine_id(line.class_domaine_id);

        setdisplay_detail_domaine("");
        setdisplay_detail_metier("");
        setdisplay_detail_categorie("1");

        Disable_Categorie_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Annule_Categorie_DetailFields() {
        var line = JSON.parse(Getall_Partner_Class_Categorie_result[gridline_categorie_id]);

        setselected_categorie_id(line._id);

        setp_detail_categorie_id(line._id);
        setp_detail_categorie_code(line.code);
        setp_detail_categorie_nom(line.nom);
        setp_detail_categorie_description(line.description);
        setp_detail_categorie_class_domaine_id(line.class_domaine_id);

        setdisplay_detail_domaine("");
        setdisplay_detail_metier("");
        setdisplay_detail_categorie("1");

        Disable_Categorie_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }


    function handleClick_edit_metier_From_Line(selected_row_id) {

        var line = JSON.parse(Getall_Partner_Class_Metier_result[selected_row_id]);

        setselected_metier_id(line._id);

        setp_detail_metier_id(line._id);
        setp_detail_metier_code(line.code);
        setp_detail_metier_nom(line.nom);
        setp_detail_metier_description(line.description);
        setp_detail_metier_class_domaine_id(line.class_domaine_id);

        setdisplay_detail_domaine("");
        setdisplay_detail_metier("1");
        setdisplay_detail_categorie("");

        Disable_Metier_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Annule_Metier_DetailFields() {
        var line = JSON.parse(Getall_Partner_Class_Metier_result[gridline_metier_id]);

        setselected_metier_id(line._id);

        setp_detail_metier_id(line._id);
        setp_detail_metier_code(line.code);
        setp_detail_metier_nom(line.nom);
        setp_detail_metier_description(line.description);
        setp_detail_metier_class_domaine_id(line.class_domaine_id);

        setdisplay_detail_domaine("");
        setdisplay_detail_metier("1");
        setdisplay_detail_categorie("");

        Disable_Metier_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }


    async function Disable_Categorie_DetailFields() {
        await sleep(5);

        setcategorie_data_edit_mode("0");

        if (document.getElementsByName("detail_categorie_code")[0]) {
            document.getElementsByName("detail_categorie_code")[0].disabled = true;
            document.getElementsByName("detail_categorie_code")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_categorie_nom")[0]) {
            document.getElementsByName("detail_categorie_nom")[0].disabled = true;
            document.getElementsByName("detail_categorie_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_categorie_description")[0]) {
            document.getElementsByName("detail_categorie_description")[0].disabled = true;
            document.getElementsByName("detail_categorie_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_categorie_domaine")[0]) {
            document.getElementsByName("detail_categorie_domaine")[0].disabled = true;
            document.getElementsByName("detail_categorie_domaine")[0].style.backgroundColor = "#ECEFF1";
        }

    }

    function Enable_Categorie_DetailFields() {
        setcategorie_data_edit_mode("1");
        if (document.getElementsByName("detail_categorie_code")[0]) {
            document.getElementsByName("detail_categorie_code")[0].disabled = false;
            document.getElementsByName("detail_categorie_code")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_categorie_nom")[0]) {
            document.getElementsByName("detail_categorie_nom")[0].disabled = false;
            document.getElementsByName("detail_categorie_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_categorie_description")[0]) {
            document.getElementsByName("detail_categorie_description")[0].disabled = false;
            document.getElementsByName("detail_categorie_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_categorie_domaine")[0]) {
            document.getElementsByName("detail_categorie_domaine")[0].disabled = false;
            document.getElementsByName("detail_categorie_domaine")[0].style.backgroundColor = "#FFFFFF";
        }

    }

    function clear_Categorie_DetailFields() {
        setp_detail_categorie_id("");
        setp_detail_categorie_code("");
        setp_detail_categorie_nom("");
        setp_detail_categorie_description("");
        setp_detail_categorie_class_domaine_id("");

        setselected_categorie_id("");
        setgridline_categorie_id("");


    }



    async function Disable_Metier_DetailFields() {
        await sleep(5);
        setmetier_data_edit_mode("0");

        if (document.getElementsByName("detail_metier_code")[0]) {
            document.getElementsByName("detail_metier_code")[0].disabled = true;
            document.getElementsByName("detail_metier_code")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_metier_nom")[0]) {
            document.getElementsByName("detail_metier_nom")[0].disabled = true;
            document.getElementsByName("detail_metier_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_metier_description")[0]) {
            document.getElementsByName("detail_metier_description")[0].disabled = true;
            document.getElementsByName("detail_metier_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_metier_domaine")[0]) {
            document.getElementsByName("detail_metier_domaine")[0].disabled = true;
            document.getElementsByName("detail_metier_domaine")[0].style.backgroundColor = "#ECEFF1";
        }

    }

    function Enable_Metier_DetailFields() {
        setmetier_data_edit_mode("1")

        if (document.getElementsByName("detail_metier_code")[0]) {
            document.getElementsByName("detail_metier_code")[0].disabled = false;
            document.getElementsByName("detail_metier_code")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_metier_nom")[0]) {
            document.getElementsByName("detail_metier_nom")[0].disabled = false;
            document.getElementsByName("detail_metier_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_metier_description")[0]) {
            document.getElementsByName("detail_metier_description")[0].disabled = false;
            document.getElementsByName("detail_metier_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_metier_domaine")[0]) {
            document.getElementsByName("detail_metier_domaine")[0].disabled = false;
            document.getElementsByName("detail_metier_domaine")[0].style.backgroundColor = "#FFFFFF";
        }

    }


    function clear_Metier_DetailFields() {
        setp_detail_metier_id("");
        setp_detail_metier_code("");
        setp_detail_metier_nom("");
        setp_detail_metier_description("");
        setp_detail_metier_class_domaine_id("");

        setselected_metier_id("");
        setgridline_metier_id("");

    }


    async function Disable_domaine_DetailFields() {
        await sleep(10);
        setdomaine_data_edit_mode("0");

        if (document.getElementsByName("detail_domaine_code") && document.getElementsByName("detail_domaine_code")[0]) {
            document.getElementsByName("detail_domaine_code")[0].disabled = true;
            document.getElementsByName("detail_domaine_code")[0].style.backgroundColor = "#ECEFF1";

        }

        if (document.getElementsByName("detail_domaine_nom") && document.getElementsByName("detail_domaine_nom")[0]) {
            document.getElementsByName("detail_domaine_nom")[0].disabled = true;
            document.getElementsByName("detail_domaine_nom")[0].style.backgroundColor = "#ECEFF1";
           
        }

        if (document.getElementsByName("detail_domaine_description") && document.getElementsByName("detail_domaine_description")[0]) {
            document.getElementsByName("detail_domaine_description")[0].disabled = true;
            document.getElementsByName("detail_domaine_description")[0].style.backgroundColor = "#ECEFF1";
        }


    }

    function Enable_domaine_DetailFields() {
        setdomaine_data_edit_mode("1")

        if (document.getElementsByName("detail_domaine_code")[0] && document.getElementsByName("detail_domaine_code")[0]) {
            document.getElementsByName("detail_domaine_code")[0].disabled = false;
            document.getElementsByName("detail_domaine_code")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_domaine_nom")[0] && document.getElementsByName("detail_domaine_nom")[0]) {
            document.getElementsByName("detail_domaine_nom")[0].disabled = false;
            document.getElementsByName("detail_domaine_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_domaine_description")[0] && document.getElementsByName("detail_domaine_description")[0]) {
            document.getElementsByName("detail_domaine_description")[0].disabled = false;
            document.getElementsByName("detail_domaine_description")[0].style.backgroundColor = "#FFFFFF";
        }


    }


    function clear_domaine_DetailFields() {
        setp_detail_domaine_id("");
        setp_detail_domaine_code("");
        setp_detail_domaine_nom("");
        setp_detail_domaine_description("");
        setp_detail_domaine_class_domaine_id("");

        setselected_domaine_id("");
        setgridline_domaine_id("");

    }

    function Annule_domaine_DetailFields() {
        var line = JSON.parse(Getall_Partner_Class_domaine_result[gridline_domaine_id]);

        setselected_domaine_id(line._id);

        setp_detail_domaine_id(line._id);
        setp_detail_domaine_code(line.code);
        setp_detail_domaine_nom(line.nom);
        setp_detail_domaine_description(line.description);


        setdisplay_detail_domaine("");
        setdisplay_detail_metier("");
        setdisplay_detail_domaine("1");

       

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        Disable_domaine_DetailFields();
    }



    function handleClick_edit_domaine_From_Line(selected_row_id) {

        var line = JSON.parse(Getall_Partner_Class_domaine_result[selected_row_id]);

        setselected_domaine_id(line._id);

        setp_detail_domaine_id(line._id);
        setp_detail_domaine_code(line.code);
        setp_detail_domaine_nom(line.nom);
        setp_detail_domaine_description(line.description);
        setp_detail_domaine_class_domaine_id(line.class_domaine_id);

        setdisplay_detail_domaine("");
        setdisplay_detail_metier("");
        setdisplay_detail_domaine("1");

        Disable_domaine_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }



    useEffect(() => {

        Getall_Partner_Class_Domaine();
        submenu_domaine();
        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }, [])


    const [Update_Categorie_Data_api, setUpdate_Categorie_Data_api] = useState();
    const [Update_Categorie_Data_message, setUpdate_Categorie_Data_message] = useState();
    const [Update_Categorie_Data_result, setUpdate_Categorie_Data_result] = useState();
    function Update_Categorie_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_categorie_id);
        form.append("code", p_detail_categorie_code);
        form.append("nom", p_detail_categorie_nom);
        form.append("description", p_detail_categorie_description);
        form.append("class_domaine_id", p_detail_categorie_class_domaine_id);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Class_Categorie/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Update_Categorie_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_Categorie_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setUpdate_Categorie_Data_api("true");
                setUpdate_Categorie_Data_result(res.data.message);

                Getall_Partner_Class_Categorie();
                clear_Categorie_DetailFields();

                setdisplay_detail_categorie("");
                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_Categorie_Data_api("false");
                setUpdate_Categorie_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_Categorie_Data = ' + error);
            setUpdate_Categorie_Data_api("false");
            alert(" Impossible de mettre à jour la catégorie ");

        })
    }

    const [Add_Categorie_Data_api, setAdd_Categorie_Data_api] = useState();
    const [Add_Categorie_Data_message, setAdd_Categorie_Data_message] = useState();
    const [Add_Categorie_Data_result, setAdd_Categorie_Data_result] = useState();
    function Add_Categorie_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_categorie_code);
        form.append("nom", p_detail_categorie_nom);
        form.append("description", p_detail_categorie_description);
        form.append("class_domaine_id", p_detail_categorie_class_domaine_id);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Class_Categorie/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_Categorie_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Categorie_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setAdd_Categorie_Data_api("true");
                setAdd_Categorie_Data_result(res.data.message);

                Getall_Partner_Class_Categorie();
                clear_Categorie_DetailFields();

                setdisplay_detail_categorie("");
                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");

                Dialog_Categorie_1_handleClose_buton();

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Categorie_Data_api("false");
                setAdd_Categorie_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Categorie_Data = ' + error);
            setAdd_Categorie_Data_api("false");
            alert(" Impossible d'ajouter la catégorie ");

        })
    }

    const [Update_Metier_Data_api, setUpdate_Metier_Data_api] = useState();
    const [Update_Metier_Data_message, setUpdate_Metier_Data_message] = useState();
    const [Update_Metier_Data_result, setUpdate_Metier_Data_result] = useState();
    function Update_Metier_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_metier_id);
        form.append("code", p_detail_metier_code);
        form.append("nom", p_detail_metier_nom);
        form.append("description", p_detail_metier_description);
        form.append("class_domaine_id", p_detail_metier_class_domaine_id);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_class_metier/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Update_Metier_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_Metier_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setUpdate_Metier_Data_api("true");
                setUpdate_Metier_Data_result(res.data.message);

                Getall_Partner_Class_Metier();
                clear_Metier_DetailFields();

                setdisplay_detail_metier("");
                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_Metier_Data_api("false");
                setUpdate_Metier_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_Metier_Data = ' + error);
            setUpdate_Metier_Data_api("false");
            alert(" Impossible de mettre à jour le métier ");

        })
    }

    const [Add_Metier_Data_api, setAdd_Metier_Data_api] = useState();
    const [Add_Metier_Data_message, setAdd_Metier_Data_message] = useState();
    const [Add_Metier_Data_result, setAdd_Metier_Data_result] = useState();
    function Add_Metier_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_metier_code);
        form.append("nom", p_detail_metier_nom);
        form.append("description", p_detail_metier_description);
        form.append("class_domaine_id", p_detail_metier_class_domaine_id);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_class_metier/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_Metier_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Metier_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setAdd_Metier_Data_api("true");
                setAdd_Metier_Data_result(res.data.message);

                Getall_Partner_Class_Metier();
                clear_Metier_DetailFields();

                setdisplay_detail_metier("");
                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");

                Dialog_Metier_1_handleClose_buton();

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Metier_Data_api("false");
                setAdd_Metier_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Metier_Data = ' + error);
            setAdd_Metier_Data_api("false");
            alert(" Impossible d'ajouter le métier ");

        })
    }


    const [Update_domaine_Data_api, setUpdate_domaine_Data_api] = useState();
    const [Update_domaine_Data_message, setUpdate_domaine_Data_message] = useState();
    const [Update_domaine_Data_result, setUpdate_domaine_Data_result] = useState();
    function Update_domaine_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_domaine_id);
        form.append("code", p_detail_domaine_code);
        form.append("nom", p_detail_domaine_nom);
        form.append("description", p_detail_domaine_description);

        //console.log(" form ttt == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Class_domaine/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Update_domaine_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_domaine_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {

                setUpdate_domaine_Data_api("true");
                setUpdate_domaine_Data_result(res.data.message);

                Getall_Partner_Class_Domaine();
                clear_domaine_DetailFields();

                setdisplay_detail_domaine("");
                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_domaine_Data_api("false");
                setUpdate_domaine_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_domaine_Data = ' + error);
            setUpdate_domaine_Data_api("false");
            alert(" Impossible de mettre à jour le métier ");

        })
    }

    const [Add_domaine_Data_api, setAdd_domaine_Data_api] = useState();
    const [Add_domaine_Data_message, setAdd_domaine_Data_message] = useState();
    const [Add_domaine_Data_result, setAdd_domaine_Data_result] = useState();
    function Add_domaine_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_domaine_code);
        form.append("nom", p_detail_domaine_nom);
        form.append("description", p_detail_domaine_description);
        //form.append("class_domaine_id", p_detail_domaine_class_domaine_id);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Class_Domaine/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_domaine_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_domaine_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {



                setAdd_domaine_Data_api("true");
                setAdd_domaine_Data_result(res.data.message);

                Getall_Partner_Class_Domaine();
                clear_domaine_DetailFields();

                setdisplay_detail_domaine("");
                setcategorie_data_edit_mode("");
                setcategorie_data_changed("");

                Dialog_domaine_1_handleClose_buton();

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_domaine_Data_api("false");
                setAdd_domaine_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_domaine_Data = ' + error);
            setAdd_domaine_Data_api("false");
            alert(" Impossible d'ajouter le métier ");

        })
    }


    const [Dialog_Categorie_1_message, setDialog_Categorie_1_message] = React.useState(false);
    const [Dialog_Categorie_1_open, setDialog_Categorie_1_open] = React.useState(false);

    function Dialog_1_handle_change_Categorie(message) {
        setDialog_Categorie_1_message(message);
        setDialog_Categorie_1_open(true);
    }

    const Dialog_Categorie_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_Categorie_1_handleClose_buton = () => {
        clear_Categorie_DetailFields();
        setDialog_Categorie_1_open(false);
    };

    const [Dialog_Metier_1_message, setDialog_Metier_1_message] = React.useState(false);
    const [Dialog_Metier_1_open, setDialog_Metier_1_open] = React.useState(false);

    function Dialog_1_handle_change_Metier(message) {
        setDialog_Metier_1_message(message);
        setDialog_Metier_1_open(true);
    }

    const Dialog_Metier_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_Metier_1_handleClose_buton = () => {
        clear_Metier_DetailFields();
        setDialog_Metier_1_open(false);
    };


    const [p_detail_domaine_class_domaine_id, setp_detail_domaine_class_domaine_id] = useState("");

    const [Dialog_domaine_1_message, setDialog_domaine_1_message] = React.useState(false);
    const [Dialog_domaine_1_open, setDialog_domaine_1_open] = React.useState(false);

    function Dialog_1_handle_change_Domaine(message) {
        setDialog_domaine_1_message(message);
        setDialog_domaine_1_open(true);
    }

    const Dialog_domaine_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_domaine_1_handleClose_buton = () => {
        clear_domaine_DetailFields();
        setDialog_domaine_1_open(false);
    };


    return (
        <div className="partner_domaine_categorie_metier">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            {/***Domaine */}
            <Dialog
                open={Dialog_domaine_1_open}
                onClose={Dialog_domaine_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Domaine de Formation </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_domaine_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > <b>Code </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_code"
                            id="event_dialog_code"

                            fullWidth

                            value={p_detail_domaine_code}
                            onChange={(e) => {
                                setp_detail_domaine_code(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > <b>Nom</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_nom"
                            id="event_dialog_nom"

                            fullWidth

                            value={p_detail_domaine_nom}
                            onChange={(e) => {
                                setp_detail_domaine_nom(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_description"
                            id="event_dialog_description"

                            fullWidth

                            value={p_detail_domaine_description}
                            onChange={(e) => {
                                setp_detail_domaine_description(e.target.value);
                            }
                            }
                        />

                    </div>



                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_domaine_id).trim().length > 2 && <Button onClick={"Add_Update_Client_Type"} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_domaine_id).trim() === "" && <Button onClick={Add_domaine_Data} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_domaine_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*** FinDomaine */}

            {/***Categorie */}
            <Dialog
                open={Dialog_Categorie_1_open}
                onClose={Dialog_Categorie_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Catégorie de Formation </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_Categorie_1_message}
                    </DialogContentText>

                    {New_Getall_Partner_Class_domaine_result && New_Getall_Partner_Class_domaine_result.length > 0 && <div className="session_caract_Dialog" > <b> Domaine Formation </b>
                        <Autocomplete
                            disablePortal
                            name="event_dialog_domaine"
                            id="event_dialog_domaine"
                            //className="disabled_style"
                            fullWidth
                            value={New_Getall_Partner_Class_domaine_result.filter((data) => (data)._id === String(p_detail_categorie_class_domaine_id))[0].label}
                            options={New_Getall_Partner_Class_domaine_result}
                            onChange={(event, value) => {


                                if (value && value._id) {
                                    setp_detail_categorie_class_domaine_id(value._id);
                                    setcategorie_data_changed("1");
                                } else {
                                    setp_detail_categorie_class_domaine_id("");
                                    setcategorie_data_changed("1");
                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir un domaine"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>}


                    <div className="session_caract_Dialog" > <b>Code </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_code"
                            id="event_dialog_code"

                            fullWidth

                            value={p_detail_categorie_code}
                            onChange={(e) => {
                                setp_detail_categorie_code(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > <b>Nom</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_nom"
                            id="event_dialog_nom"

                            fullWidth

                            value={p_detail_categorie_nom}
                            onChange={(e) => {
                                setp_detail_categorie_nom(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_description"
                            id="event_dialog_description"

                            fullWidth

                            value={p_detail_categorie_description}
                            onChange={(e) => {
                                setp_detail_categorie_description(e.target.value);
                            }
                            }
                        />

                    </div>



                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_categorie_id).trim().length > 2 && <Button onClick={"Add_Update_Client_Type"} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_categorie_id).trim() === "" && <Button onClick={Add_Categorie_Data} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_Categorie_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*** FinCategorie */}


            {/*** Metier */}
            <Dialog
                open={Dialog_Metier_1_open}
                onClose={Dialog_Metier_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Catégorie de Formation </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_Metier_1_message}
                    </DialogContentText>

                    {New_Getall_Partner_Class_domaine_result && New_Getall_Partner_Class_domaine_result.length > 0 && <div className="session_caract_Dialog" > <b> Domaine Formation </b>
                        <Autocomplete
                            disablePortal
                            name="event_dialog_domaine"
                            id="event_dialog_domaine"
                            //className="disabled_style"
                            fullWidth
                            value={New_Getall_Partner_Class_domaine_result.filter((data) => (data)._id === String(p_detail_metier_class_domaine_id))[0].label}
                            options={New_Getall_Partner_Class_domaine_result}
                            onChange={(event, value) => {


                                if (value && value._id) {
                                    setp_detail_metier_class_domaine_id(value._id);
                                    setmetier_data_changed("1");
                                } else {
                                    setp_detail_metier_class_domaine_id("");
                                    setmetier_data_changed("1");
                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir un domaine"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>}


                    <div className="session_caract_Dialog" > <b>Code </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_code"
                            id="event_dialog_code"

                            fullWidth

                            value={p_detail_metier_code}
                            onChange={(e) => {
                                setp_detail_metier_code(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > <b>Nom</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_nom"
                            id="event_dialog_nom"

                            fullWidth

                            value={p_detail_metier_nom}
                            onChange={(e) => {
                                setp_detail_metier_nom(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_description"
                            id="event_dialog_description"

                            fullWidth

                            value={p_detail_metier_description}
                            onChange={(e) => {
                                setp_detail_metier_description(e.target.value);
                            }
                            }
                        />

                    </div>



                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_metier_id).trim().length > 2 && <Button onClick={"Add_Update_Client_Type"} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_metier_id).trim() === "" && <Button onClick={Add_Metier_Data} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_Metier_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*** FinMetier */}

            <div className="div_row" ref={myRef_head} id="myRef_head" >
                <Button variant="outlined" onClick={submenu_domaine} className="detail_class_submenu" id='domaine' name='domaine'>Domaines </Button>
                <Button variant="outlined" onClick={submenu_categorie} className="detail_class_submenu" id='categorie' name='categorie'>Catégories </Button>
                <Button variant="outlined" onClick={submenu_metier} className="detail_class_submenu" id='metier' name='metier'> Métiers  </Button>
            </div>


            {/*** domaine */}
            {String(submenu) === "domaine" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <h3> Les domaines de formation</h3>

                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>
                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',



                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_domaine(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_config_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /* setp_detail_config_valeur();
                                                     setp_detail_config_point();
                                                     setConfig_data_changed();
                                                     setconfig_data_edit_mode();
                                                     setdisplay_detail_config();*/


                                                }
                                            }}
                                            selectionModel={selectionModel_domaine}
                                            disableSelectionOnClick
                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_Class_domaine_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code: JSON.parse(item).code,
                                                    nom: JSON.parse(item).nom,
                                                    description: JSON.parse(item).description,


                                                }
                                            ))}

                                            columns={columns_domaine}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_domaine_id(newSelectionModel.row.id);
                                                handleClick_edit_domaine_From_Line(newSelectionModel.row.id);


                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {



                                            }}
                                            getRowClassName={(params) => {


                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_domaine_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                        </div>

                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {
                                    setdisplay_detail_domaine("");
                                    setdomaine_data_edit_mode("");
                                    setdomaine_data_changed("");

                                    clear_domaine_DetailFields();
                                    setDialog_domaine_1_open(true);

                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 Domaine &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                    </div>



                    {String(display_detail_domaine) === "1" && <div className="div_row">

                        {domaine_data_edit_mode && String(domaine_data_edit_mode) !== "1" &&
                            <div className="session_caract"> Code<br />

                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_domaine_code"
                                    id="detail_domaine_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_domaine_code}

                                />

                            </div>}


                        {domaine_data_edit_mode && String(domaine_data_edit_mode) === "1" &&
                            <div className="session_caract"> Code<br />

                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_domaine_code"
                                    id="detail_domaine_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_domaine_code}

                                    onChange={(e) => {
                                        setp_detail_domaine_code(e.target.value);
                                        setdomaine_data_changed("1");
                                    }
                                    }

                                />

                            </div>}


                        {String(domaine_data_edit_mode) !== "1" &&
                            <div className="session_caract"> Nom <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_domaine_nom"
                                    id="detail_domaine_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_domaine_nom}
                                />


                            </div>}

                        {String(domaine_data_edit_mode) === "1" &&
                            <div className="session_caract"> Nom<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_domaine_nom"
                                    id="detail_domaine_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_domaine_nom}
                                    onChange={(e) => {
                                        setp_detail_domaine_nom(e.target.value);
                                        setdomaine_data_changed("1");
                                    }
                                    }
                                />
                            </div>}

                        {String(domaine_data_edit_mode) !== "1" &&
                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_domaine_description"
                                    id="detail_domaine_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_domaine_description}


                                />
                            </div>}

                        {String(domaine_data_edit_mode) === "1" && <div className="session_caract"> Description<br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_domaine_description"
                                id="detail_domaine_description"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_domaine_description}
                                onChange={(e) => {
                                    setp_detail_domaine_description(e.target.value);
                                    setdomaine_data_changed("1");
                                }
                                }
                            />
                        </div>}


                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                            {String(domaine_data_changed) === "1" && String(domaine_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                /!\ Pensez à enregistrer les modifications
                            </div>}
                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                {String(domaine_data_edit_mode) === "1" && String(domaine_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Update_domaine_Data}>Enregistrer les modifications
                                    </Button>

                                </div>}

                                {String(domaine_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={Annule_domaine_DetailFields}>Annuler
                                    </Button>
                                </div>}
                            </div>


                            {String(domaine_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_domaine_DetailFields}>Editer
                                </Button>

                            </div>}
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>

                    </div>
                    }
                </div>

            </div>}

            {/*** categorie */}
            {String(submenu) === "categorie" && <div className="div_row" style={{ paddingRight: '10px' }}>
                <h3> Les catégories de formation</h3>

                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>
                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_categorie(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_config_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /* setp_detail_config_valeur();
                                                     setp_detail_config_point();
                                                     setConfig_data_changed();
                                                     setconfig_data_edit_mode();
                                                     setdisplay_detail_config();*/


                                                }
                                            }}
                                            selectionModel={selectionModel_categorie}
                                            disableSelectionOnClick
                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_Class_Categorie_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code: JSON.parse(item).code,
                                                    nom: JSON.parse(item).nom,
                                                    description: JSON.parse(item).description,
                                                    class_domaine_id: JSON.parse(item).class_domaine_id,
                                                }
                                            ))}

                                            columns={columns_categorie}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setdisplay_detail_domaine("");
                                                setdisplay_detail_metier("");
                                                setdisplay_detail_categorie("1");
                                                setgridline_categorie_id(newSelectionModel.row.id);
                                                handleClick_edit_categorie_From_Line(newSelectionModel.row.id);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {



                                            }}
                                            getRowClassName={(params) => {


                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_categorie_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                        </div>

                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {
                                    clear_Categorie_DetailFields();
                                    setDialog_Categorie_1_open(true);

                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 Catégorie &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                                </Button>
                            </div>
                        </div>

                        <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                    </div>



                    {New_Getall_Partner_Class_Categorie_result && display_detail_categorie && String(display_detail_categorie) === "1" &&
                        <div className="div_row">

                            {categorie_data_edit_mode && String(categorie_data_edit_mode) === "1" &&
                                <div className="session_caract"> Domaine <br />

                                    <Autocomplete
                                        disablePortal
                                        name="detail_categorie_domaine"
                                        id="detail_categorie_domaine"
                                        className="disabled_style"
                                        value={New_Getall_Partner_Class_domaine_result.filter((data) => (data)._id === String(p_detail_categorie_class_domaine_id))[0].label}
                                        options={New_Getall_Partner_Class_domaine_result}
                                        onChange={(event, value) => {


                                            if (value && value._id) {
                                                setp_detail_categorie_class_domaine_id(value._id);
                                                setcategorie_data_changed("1");
                                            } else {
                                                setp_detail_categorie_class_domaine_id("");
                                                setcategorie_data_changed("1");
                                            }

                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                </div>}


                            {String(categorie_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Domaine <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_categorie_domaine"
                                        id="detail_categorie_domaine"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"

                                        value={New_Getall_Partner_Class_domaine_result.filter((data) => (data)._id === String(p_detail_categorie_class_domaine_id))[0].label}

                                    />
                                </div>}


                            {String(categorie_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_categorie_code"
                                        id="detail_categorie_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_categorie_code}
                                    />
                                </div>}


                            {String(categorie_data_edit_mode) === "1" &&
                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_categorie_code"
                                        id="detail_categorie_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_categorie_code}
                                        onChange={(e) => {
                                            setp_detail_categorie_code(e.target.value);
                                            setcategorie_data_changed("1");
                                        }
                                        }
                                    />
                                </div>}



                            {String(categorie_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Nom  <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_categorie_nom"
                                        id="detail_categorie_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_categorie_nom}


                                    />
                                </div>}

                            {String(categorie_data_edit_mode) === "1" && <div className="session_caract"> Nom<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_categorie_nom"
                                    id="detail_categorie_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_categorie_nom}
                                    onChange={(e) => {
                                        setp_detail_categorie_nom(e.target.value);
                                        setcategorie_data_changed("1");
                                    }
                                    }
                                />
                            </div>}

                            {String(categorie_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Description  <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_categorie_description"
                                        id="detail_categorie_description"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_categorie_description}


                                    />
                                </div>}

                            {String(categorie_data_edit_mode) === "1" && <div className="session_caract"> Description<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_categorie_description"
                                    id="detail_categorie_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_categorie_description}
                                    onChange={(e) => {
                                        setp_detail_categorie_description(e.target.value);
                                        setcategorie_data_changed("1");
                                    }
                                    }
                                />
                            </div>}




                            <div>
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {String(categorie_data_changed) === "1" && String(categorie_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                    /!\ Pensez à enregistrer les modifications
                                </div>}
                                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    {String(categorie_data_edit_mode) === "1" && String(categorie_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                        <Button variant="contained" className="bton_enreg" onClick={Update_Categorie_Data}>Enregistrer les modifications
                                        </Button>

                                    </div>}

                                    {String(categorie_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                        <Button variant="contained" className="bton_annule" onClick={Annule_Categorie_DetailFields}>Annuler
                                        </Button>
                                    </div>}
                                </div>


                                {String(categorie_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                    <Button variant="contained" className="bton_edit" onClick={Enable_Categorie_DetailFields}>Editer
                                    </Button>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                    }
                </div>

            </div>}

            {/*** metier */}
            {String(submenu) === "metier" && <div className="div_row" style={{ paddingRight: '10px' }}>

                <h3> Les métiers</h3>
                <div>
                    <div className="div_row" style={{ paddingRight: '10px' }}>
                        <div className="session_data">
                            <div style={{ "border": "None" }}>
                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection
                                            onSelectionModelChange={(newSelectionModel) => {
                                                setselectionModel_metier(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_config_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /* setp_detail_config_valeur();
                                                     setp_detail_config_point();
                                                     setConfig_data_changed();
                                                     setconfig_data_edit_mode();
                                                     setdisplay_detail_config();*/


                                                }
                                            }}
                                            selectionModel={selectionModel_metier}
                                            disableSelectionOnClick
                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Partner_Class_Metier_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    code: JSON.parse(item).code,
                                                    nom: JSON.parse(item).nom,
                                                    description: JSON.parse(item).description,
                                                    class_domaine_id: JSON.parse(item).class_domaine_id,
                                                }
                                            ))}

                                            columns={columns_metier}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                setgridline_metier_id(newSelectionModel.row.id);
                                                setdisplay_detail_domaine("");
                                                setdisplay_detail_metier("1");
                                                setdisplay_detail_categorie("");

                                                setmetier_data_edit_mode("0");

                                                handleClick_edit_metier_From_Line(newSelectionModel.row.id);

                                            }}


                                            rowsPerPageOptions={[10]}
                                            //disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {



                                            }}
                                            getRowClassName={(params) => {


                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_metier_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }
                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>

                        </div>

                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                <Button variant="outlined" onClick={() => {
                                    setdisplay_detail_metier("");
                                    setmetier_data_changed("");
                                    setmetier_data_edit_mode("");
                                    clear_Metier_DetailFields();
                                    setDialog_Metier_1_open(true);

                                }}
                                    className="detail_class_submenu bton_add_session"
                                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 Métier &nbsp;
                                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                                </Button>
                            </div>
                        </div>
                        <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                    </div>


                    {New_Getall_Partner_Class_Metier_result && display_detail_metier && String(display_detail_metier) === "1" &&
                        <div className="div_row">

                            {metier_data_edit_mode && String(metier_data_edit_mode) === "1" &&
                                <div className="session_caract"> Domaine 1<br />

                                    <Autocomplete
                                        disablePortal
                                        name="detail_metier_domaine"
                                        id="detail_metier_domaine"
                                        className="disabled_style"
                                        value={New_Getall_Partner_Class_domaine_result.filter((data) => (data)._id === String(p_detail_metier_class_domaine_id))[0].label}
                                        options={New_Getall_Partner_Class_domaine_result}
                                        onChange={(event, value) => {


                                            if (value && value._id) {
                                                setp_detail_metier_class_domaine_id(value._id);
                                                setmetier_data_changed("1");
                                            } else {
                                                setp_detail_metier_class_domaine_id("");
                                                setmetier_data_changed("1");
                                            }

                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                </div>}


                            {String(metier_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Domaine <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_metier_domaine"
                                        id="detail_metier_domaine"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"

                                        value={New_Getall_Partner_Class_domaine_result.filter((data) => (data)._id === String(p_detail_metier_class_domaine_id))[0].label}

                                    />
                                </div>}


                            {String(metier_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_metier_code"
                                        id="detail_metier_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_metier_code}
                                    />
                                </div>}


                            {String(metier_data_edit_mode) === "1" &&
                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_metier_code"
                                        id="detail_metier_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_metier_code}
                                        onChange={(e) => {
                                            setp_detail_metier_code(e.target.value);
                                            setmetier_data_changed("1");
                                        }
                                        }
                                    />
                                </div>}



                            {String(metier_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Nom  <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_metier_nom"
                                        id="detail_metier_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_metier_nom}


                                    />
                                </div>}

                            {String(metier_data_edit_mode) === "1" && <div className="session_caract"> Nom<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_metier_nom"
                                    id="detail_metier_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_metier_nom}
                                    onChange={(e) => {
                                        setp_detail_metier_nom(e.target.value);
                                        setmetier_data_changed("1");
                                    }
                                    }
                                />
                            </div>}

                            {String(metier_data_edit_mode) !== "1" &&
                                <div className="session_caract"> Description  <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_metier_description"
                                        id="detail_metier_description"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_metier_description}


                                    />
                                </div>}

                            {String(metier_data_edit_mode) === "1" && <div className="session_caract"> Description<br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_metier_description"
                                    id="detail_metier_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_metier_description}
                                    onChange={(e) => {
                                        setp_detail_metier_description(e.target.value);
                                        setmetier_data_changed("1");
                                    }
                                    }
                                />
                            </div>}




                            <div>
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {String(metier_data_changed) === "1" && String(metier_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                    /!\ Pensez à enregistrer les modifications
                                </div>}
                                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    {String(metier_data_edit_mode) === "1" && String(metier_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                        <Button variant="contained" className="bton_enreg" onClick={Update_Metier_Data}>Enregistrer les modifications
                                        </Button>

                                    </div>}

                                    {String(metier_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                        <Button variant="contained" className="bton_annule" onClick={Annule_Metier_DetailFields}>Annuler
                                        </Button>
                                    </div>}
                                </div>


                                {String(metier_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                    <Button variant="contained" className="bton_edit" onClick={Enable_Metier_DetailFields}>Editer
                                    </Button>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                    }

                </div>

            </div>}
        </div>
    )
}


export default Partner_domaine_Categorie_Metier;