import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import { FcInfo } from "react-icons/fc";
import Autocomplete from '@mui/material/Autocomplete';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import Chip from "@mui/material/Chip";


const DisplayPartnerUnite_Enseignement = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("detail_ue");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selectionModel_planif, setSelectionModel_planif] = React.useState([]);


    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState();


    const [isLoading, setLoading] = useState();

    const [gridline_id, setgridline_id] = useState("");

    const [planif_gridline_id, setplanif_gridline_id] = useState("");


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    function ExpandableCell_50_left_align({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav style={{ width: '100%', textAlign: 'center' }}>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all", }}>
                {value}&nbsp;

            </div>
        );
    }


    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'titre', headerName: 'Titre', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'bloc', headerName: 'Bloc', flex: 1, hide: true, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        {
            field: 'domain_id', headerName: 'Domaine', flex: 1, hide: false, editable: false, renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Get_List_Domaines_result && New_Get_List_Domaines_result.length > 0 && <nav>
                            {New_Get_List_Domaines_result.filter((data) => (data)._id === String(cellValues.row.domain_id))[0].label}

                        </nav>}
                    </div>

                );
            }
        },


        { field: 'duration_concat', headerName: 'Durée', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50_left_align {...params} />, },
        { field: 'duration', headerName: 'Durée', flex: 1, hide: true, editable: false },
        { field: 'duration_unite', headerName: 'Unit.', flex: 1, hide: true, editable: false },

        { field: 'planification_line', headerName: 'Ligne Plan.', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50_left_align {...params} />, },
        { field: 'planification_used_line', headerName: 'Ligne Plan. Uti.', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50_left_align {...params} />, },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button


                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'UE sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]


    const columns_planif_ue = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'ue_id', headerName: 'ue_id', hide: true },
        { field: 'comment', headerName: 'comment', hide: true },

        { field: 'code', headerName: 'Code', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <Display_Colunm_text {...params} />, },
        { field: 'titre', headerName: 'Titre', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <Display_Colunm_text {...params} />, },
        { field: 'description', headerName: 'Description', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <Display_Colunm_text {...params} />, },
        { field: 'volume_horaire', headerName: 'Volume_horaire', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50_left_align {...params} />, },

        { field: 'in_use', headerName: 'Utilisé', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50_left_align {...params} />, },
        { field: 'used_sequence_title', headerName: 'Seq. Utilisé', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50_left_align {...params} />, },

        { field: 'prerequis', headerName: 'Prérequis', minWidth: 200, flex: 1, hide: true, editable: false, renderCell: (params) => <Display_Colunm_text {...params} />, },


        {
            field: "modifier", headerName: 'Modifier', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            setupdate_planif_line("1");
                            setselected_planif_line_id(cellValues.row._id);
                            setp_detail_planif_line_code(cellValues.row.code);
                            setp_detail_planif_line_titre(cellValues.row.titre);
                            setp_detail_planif_line_description(cellValues.row.description);
                            setp_detail_planif_line_prerequis(cellValues.row.prerequis);
                            setp_detail_planif_line_volume_horaire(cellValues.row.volume_horaire);
                            setp_detail_planif_line_comment(cellValues.row.comment);

                            setDialog_PLANIF_LINE_open(true);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ligne sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_ue_planif_line_delete(cellValues.row._id);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]



    function handleClick_delete(event, cellValues) {

        Delete_Given_UE(cellValues.row._id);
    }

    const [Delete_Given_UE_api, setDelete_Given_UE_api] = useState();
    const [Delete_Given_UE_message, setDelete_Given_UE_message] = useState();
    const [Delete_Given_UE_result, setDelete_Given_UE_result] = useState();
    function Delete_Given_UE(local_ue_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_ue_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Unite_Enseignement/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Given_UE  res.data.status = " + res.data.status);
            //console.log(" In Delete_Given_UE  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Given_UE_api("true");
                setDelete_Given_UE_result(res.data.message);
                Getall_Parter_Unite_Enseignement();
                setdisplay_ue("");
                setadd_one_ue("");
                setsubmenu("");


                alert(res.data.message)
            }
            else {
                setDelete_Given_UE_api("false");
                setDelete_Given_UE_message(res.data.message);

                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_Given_UE : Not good man :( mysearchtext = ' + error);
            setDelete_Given_UE_api("false");
            alert(" Impossible de supprimer l'UE ");
        })
    }


    function handleClick_ue_planif_line_delete(my_row_id) {

        Delete_Given_UE_Planif_Line(my_row_id);
    }

    const [Delete_Given_UE_Planif_Line_api, setDelete_Given_UE_Planif_Line_api] = useState();
    const [Delete_Given_UE_Planif_Line_message, setDelete_Given_UE_Planif_Line_message] = useState();
    const [Delete_Given_UE_Planif_Line_result, setDelete_Given_UE_Planif_Line_result] = useState();
    function Delete_Given_UE_Planif_Line(local_ue_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_eu_planif_id", local_ue_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_List_Unite_Enseignement_Planif/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Given_UE_Planif_Line  res.data.status = " + res.data.status);
            //console.log(" In Delete_Given_UE_Planif_Line  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Given_UE_Planif_Line_api("true");
                setDelete_Given_UE_Planif_Line_result(res.data.message);
                Getall_UE_Planif_data(selected_id);
                alert(res.data.message);
            }
            else {
                setDelete_Given_UE_Planif_Line_api("false");
                setDelete_Given_UE_Planif_Line_message(res.data.message);

                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_Given_UE_Planif_Line : Not good man :( mysearchtext = ' + error);
            setDelete_Given_UE_Planif_Line_api("false");
            alert(" Impossible de supprimer la ligne de planification de l'UE ");
        })
    }


    const myRef = useRef(null)


    const myRef_head = useRef(null);
    const [selected_id, setselected_id] = useState("");

    const [add_one_ue, setadd_one_ue] = useState("");
    const [display_ue, setdisplay_ue] = useState("");
    let [ue_data_edit_mode, setue_data_edit_mode] = useState("0");



    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const [Getall_Parter_Unite_Enseignement_api, setGetall_Parter_Unite_Enseignement_api] = useState();
    const [Getall_Parter_Unite_Enseignement_message, setGetall_Parter_Unite_Enseignement_message] = useState();
    const [Getall_Parter_Unite_Enseignement_result, setGetall_Parter_Unite_Enseignement_result] = useState([]);
    function Getall_Parter_Unite_Enseignement(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_no_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Unite_Enseignement  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Unite_Enseignement  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Unite_Enseignement_api("true");
                setGetall_Parter_Unite_Enseignement_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Unite_Enseignement_api("false");
                setGetall_Parter_Unite_Enseignement_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Unite_Enseignement = ', error);
            setGetall_Parter_Unite_Enseignement_api("false");
            alert(" Impossible de recuperer la liste des unités d'enseignement ");
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Parter_Unite_Enseignement_with_Filter_api, setGetall_Parter_Unite_Enseignement_with_Filter_api] = useState();
    const [Getall_Parter_Unite_Enseignement_with_Filter_message, setGetall_Parter_Unite_Enseignement_with_Filter_message] = useState();
    const [Getall_Parter_Unite_Enseignement_with_Filter_result, setGetall_Parter_Unite_Enseignement_with_Filter_result] = useState([]);
    function Getall_Parter_Unite_Enseignement_with_Filter(event) {

        var form = new FormData();
        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Unite_Enseignement_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Unite_Enseignement_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Unite_Enseignement_with_Filter_api("true");
                setGetall_Parter_Unite_Enseignement_with_Filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Unite_Enseignement_with_Filter_api("false");
                setGetall_Parter_Unite_Enseignement_with_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Unite_Enseignement_with_Filter = ', error);
            setGetall_Parter_Unite_Enseignement_with_Filter_api("false");
            alert(" Impossible de recuperer la liste des unités d'enseignement ");
            //setmyApimyApiMessage("")
        })
    }

    function clean_all_filters() {

        setgridline_id('');

        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");


        setSelectionModel([]);

        Getall_Parter_Unite_Enseignement();
    }

    const [Get_Given_Unite_Enseignement_api, setGet_Given_Unite_Enseignement_api] = useState();
    const [Get_Given_Unite_Enseignement_message, setGet_Given_Unite_Enseignement_message] = useState();
    const [Get_Given_Unite_Enseignement_result, setGet_Given_Unite_Enseignement_result] = useState([]);
    function Get_Given_Unite_Enseignement(local_selected_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Unite_Enseignement/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Given_Unite_Enseignement  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Unite_Enseignement  res.data.message r_class = " + res.data.message);
                setGet_Given_Unite_Enseignement_api("true");
                setGet_Given_Unite_Enseignement_result(res.data.message);

                var local_ue = JSON.parse(res.data.message);

                if (local_ue.code) {
                    setp_detail_code(local_ue.code);
                } else {
                    setp_detail_code("");
                }

                if (local_ue.domain_id) {
                    setp_detail_domaine(local_ue.domain_id);
                } else {
                    setp_detail_domaine("");
                }

                if (local_ue.bloc) {
                    setp_detail_bloc(local_ue.bloc);
                } else {
                    setp_detail_bloc("");
                }

                if (local_ue.titre) {
                    setp_detail_titre(local_ue.titre);
                } else {
                    setp_detail_titre("");
                }


                if (local_ue.vh_cm) {
                    setp_detail_cours_cm(local_ue.vh_cm);
                } else {
                    setp_detail_cours_cm("");
                }

                if (local_ue.vh_tp) {
                    setp_detail_cours_tp(local_ue.vh_tp);
                } else {
                    setp_detail_cours_tp("");
                }

                if (local_ue.vh_tpg) {
                    setp_detail_cours_tpg(local_ue.vh_tpg);
                } else {
                    setp_detail_cours_tpg("");
                }

                if (local_ue.vh_td) {
                    setp_detail_cours_td(local_ue.vh_td);
                } else {
                    setp_detail_cours_td("");
                }


                if (local_ue.semestre) {
                    setp_detail_semestre(local_ue.semestre);
                } else {
                    setp_detail_semestre("");
                }




                if (local_ue.support) {
                    setp_detail_support(local_ue.support);
                } else {
                    setp_detail_support("");
                }



                if (local_ue.duration) {
                    setp_detail_duration(local_ue.duration);
                } else {
                    setp_detail_duration("");
                }

                if (local_ue.duration_unite) {
                    setp_detail_duration_unite(local_ue.duration_unite);
                } else {
                    setp_detail_duration_unite("");
                }

                if (local_ue.programme) {
                    setfield_programme(local_ue.programme);
                } else {
                    setfield_programme("");
                }


                if (local_ue.prerequis) {
                    setfield_preprequis(local_ue.prerequis);
                } else {
                    setfield_preprequis("");
                }



                if (local_ue.objectif) {
                    setfield_objectif(local_ue.objectif);
                } else {
                    setfield_objectif("");
                }

                if (local_ue.methode_evaluation) {
                    setfield_suivi_eval(local_ue.methode_evaluation);
                } else {
                    setfield_suivi_eval("");
                }


                if (local_ue.methode_pedagogique) {
                    setfield_methode_pedago(local_ue.methode_pedagogique);
                } else {
                    setfield_methode_pedago("");
                }


                if (local_ue.description) {
                    setfield_description(local_ue.description);
                } else {
                    setfield_description("");
                }

                if (local_ue.niveau_competence) {
                    setp_detail_tab_competence(local_ue.niveau_competence);
                    var tab_tmp = String(local_ue.niveau_competence).split(",");
                    setVal(tab_tmp);

                } else {
                    setp_detail_tab_competence([]);
                    setVal([]);
                }


                if (local_ue.ects) {
                    setp_detail_ects(local_ue.ects);
                } else {
                    setp_detail_ects("");
                }

                if (local_ue.seuil_validation) {
                    setp_detail_seuil_validation(local_ue.seuil_validation);
                } else {
                    setp_detail_seuil_validation("");
                }



                desable_detail_fields();
            }
            else {
                setGet_Given_Unite_Enseignement_api("false");
                setGet_Given_Unite_Enseignement_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Unite_Enseignement = ', error);
            setGet_Given_Unite_Enseignement_api("false");
            alert(" Impossible de recuperer les données de l'UE ");
            //setmyApimyApiMessage("")
        })
    }


    const [eu_changed, seteu_changed] = useState("");
    function is_EU_changed() {
        seteu_changed("1");
    }

    const New_duration_unit_list = [
        {
            value: 'heure',
            label: 'Heures',
        },

        {
            value: 'jour',
            label: 'Jours',
        },
        {
            value: 'semaine',
            label: 'Semaines',
        },
        {
            value: 'mois',
            label: 'Mois',
        },
        {
            value: 'user_rythme',
            label: 'Rythme stagiaire',
        },
        {
            value: '',
            label: '',
        }
    ];



    const [enable_editor, setenable_editor] = useState(0);

    function desable_detail_fields() {
        setue_data_edit_mode("0");
        const list_menu_header_names = ['detail_ue_code', 'detail_ue_titre', 'detail_ue_bloc', 'detail_ue_domaine', 'detail_ue_competence',
            'detail_ue_semestre', 'detail_ue_ects', 'detail_ue_seuil_validation', 'detail_ue_duree', 'detail_ue_unit_duree', 'detail_ue_cm_heure',
            'detail_ue_td_heure', 'detail_ue_tp_heure', 'detail_ue_tpg_heure', 'detail_ue_cm_heure']


        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].disabled = true;
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.backgroundColor = "#ECEFF1";
            }
        }
    }


    function enable_detail_fields() {

        setue_data_edit_mode("1");
        const list_menu_header_names = ['detail_ue_code', 'detail_ue_titre', 'detail_ue_bloc', 'detail_ue_domaine', 'detail_ue_competence',
            'detail_ue_semestre', 'detail_ue_ects', 'detail_ue_seuil_validation', 'detail_ue_duree', 'detail_ue_unit_duree', 'detail_ue_cm_heure',
            'detail_ue_td_heure', 'detail_ue_tp_heure', 'detail_ue_tpg_heure', 'detail_ue_cm_heure']


        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].disabled = false;
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.backgroundColor = "#FFFFFF";
            }
        }

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    useEffect(() => {
        Get_List_Domaines();

        Get_List_Type_Cours();

        Getall_Parter_Unite_Enseignement();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }


    }, []);



    function clean_fields() {
        setp_detail_code("");
        setp_detail_titre("");
        setp_detail_support("");
        setp_detail_duration("");
        setp_detail_duration_unite("");
        setfield_methode_pedago("");
        setfield_suivi_eval("");
        setfield_prerequis("");
        setfield_handicap("");
        setfield_description("");
        setfield_objectif("");
        setfield_programme("");
        setfield_preprequis("");
        setp_detail_domaine("");
        setp_detail_bloc("");

    }

    const [Add_Update_UE_Data_api, setAdd_Update_UE_Data_api] = useState();
    const [Add_Update_UE_Data_message, setAdd_Update_UE_Data_message] = useState();
    const [Add_Update_UE_Data_result, setAdd_Update_UE_Data_result] = useState();
    function Add_Update_UE_Data() {
        setLoading(true);
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("code", p_detail_code);
        form.append("titre", p_detail_titre);

        form.append("domain_id", p_detail_domaine);
        form.append("bloc", p_detail_bloc);

        form.append("duration", p_detail_duration);
        form.append("duration_unite", p_detail_duration_unite);

        form.append("ects", p_detail_ects);
        form.append("seuil_validation", p_detail_seuil_validation);

        form.append("vh_cm", p_detail_cours_cm);
        form.append("vh_tp", p_detail_cours_tp);
        form.append("vh_tpg", p_detail_cours_tpg);
        form.append("vh_td", p_detail_cours_td);

        form.append("semestre", p_detail_semestre);


        form.append("niveau_competence", val);


        var description = "";
        if (editorRef_description.current) {
            description = editorRef_description.current.getContent();

            if (parse(description.replace(/(<([^>]+)>)/ig, '')).length > editorRef_description_limite) {
                alert(" Champ 'Description', limite depassée !")
                return;
            }
        }

        form.append("description", description);

        var objectif = "";
        if (editorRef_objectif.current) {
            objectif = editorRef_objectif.current.getContent();

            if (parse(objectif.replace(/(<([^>]+)>)/ig, '')).length > editorRef_objectif_limite) {
                alert(" Champ 'Objectif', limite depassée !")
                return;
            }
        }
        form.append("objectif", objectif);


        var prerequis = "";
        if (editorRef_prerequis.current) {
            prerequis = editorRef_prerequis.current.getContent();

            if (parse(prerequis.replace(/(<([^>]+)>)/ig, '')).length > editorRef_prerequis_limite) {
                alert(" Champ 'Pré-requis', limite depassée !")
                return;
            }
        }
        form.append("prerequis", prerequis);


        var programme = "";
        if (editorRef_programme.current) {
            programme = editorRef_programme.current.getContent();

            if (parse(programme.replace(/(<([^>]+)>)/ig, '')).length > editorRef_prerequis_limite) {
                alert(" Champ 'Programme', limite depassée !")
                return;
            }
        }

        form.append("programme", programme);


        var methode_eval = "";
        if (editorRef_methode_eval.current) {
            methode_eval = editorRef_methode_eval.current.getContent();

            if (parse(methode_eval.replace(/(<([^>]+)>)/ig, '')).length > editorRef_methode_eval_limite) {
                alert(" Champ 'Suivi & Evaluation', limite depassée !")
                return;
            }
        }

        form.append("methode_evaluation", methode_eval);


        var methode_pedago = "";
        if (editorRef_programme.current) {
            methode_pedago = editorRef_methode_pedago.current.getContent();

            if (parse(methode_pedago.replace(/(<([^>]+)>)/ig, '')).length > editorRef_methode_eval_limite) {
                alert(" Champ 'Méhode Pédagogique', limite depassée !")
                return;
            }
        }

        form.append("methode_pedagogique", methode_pedago);
        var myurl = ""

        var is_update = 0;

        if (String(display_ue) === "1" && String(add_one_ue) !== "1") {
            form.append("_id", selected_id);
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Unite_Enseignement/";
            is_update = 1;

        } else if (String(display_ue) !== "1" && String(add_one_ue) === "1") {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Unite_Enseignement/";
        }



        axios.post(myurl, form).then(res => {
            setLoading(false);
            // console.log(" In Add_Update_UE_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_UE_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_Update_UE_Data_api("true");
                setAdd_Update_UE_Data_result(res.data.message);
                Getall_Parter_Unite_Enseignement();
                seteu_changed("");
                setdisplay_ue("");
                clean_fields();
                alert(res.data.message);


                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }
            }
            else {
                setAdd_Update_UE_Data_api("false");
                setAdd_Update_UE_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Add_Update_UE_Data : Not good man :( mysearchtext = ' + error);
            setAdd_Update_UE_Data_api("false");
            alert(" Impossible d'ajouter ou  mettre à jour l'UE ");

        })
    }


    function submenu_add_one_UE() {
        clean_fields();
        setdisplay_ue("0");
        setue_data_edit_mode("");
        setadd_one_ue("1");

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }

    const [p_detail_code, setp_detail_code] = useState("");


    const [p_detail_cours_tp, setp_detail_cours_tp] = useState("");
    const [p_detail_cours_cm, setp_detail_cours_cm] = useState("");
    const [p_detail_cours_tpg, setp_detail_cours_tpg] = useState("");
    const [p_detail_cours_td, setp_detail_cours_td] = useState("");
    const [p_detail_semestre, setp_detail_semestre] = useState("");


    const [p_detail_titre, setp_detail_titre] = useState("");
    const [p_detail_bloc, setp_detail_bloc] = useState("");

    const [p_detail_domaine, setp_detail_domaine] = useState("");


    const [p_detail_support, setp_detail_support] = useState("");
    const [p_detail_duration, setp_detail_duration] = useState("");
    const [p_detail_duration_unite, setp_detail_duration_unite] = useState("");

    const [p_detail_ects, setp_detail_ects] = useState("");
    const [p_detail_seuil_validation, setp_detail_seuil_validation] = useState("");
    const [p_detail_tab_competence, setp_detail_tab_competence] = useState([]);


    const editorRef_description = useRef(null);
    const [editorRef_description_limite, seteditorRef_description_limite] = useState(5000);


    const editorRef_objectif = useRef(null);
    const [editorRef_objectif_limite, seteditorRef_objectif_limite] = useState(5000);

    const editorRef_programme = useRef(null);
    const [editorRef_programme_limite, seteditorRef_programme_limite] = useState(5000);

    const editorRef_prerequis = useRef(null);
    const [editorRef_prerequis_limite, seteditorRef_prerequis_limite] = useState(5000);



    const editorRef_methode_pedago = useRef(null);
    const [editorRef_methode_pedago_limite, seteditorRef_methode_pedago] = useState(2000);


    const editorRef_methode_eval = useRef(null);
    const [editorRef_methode_eval_limite, seteditorRef_methode_eval_limite] = useState(2000);


    const [field_description, setfield_description] = useState("");
    const [field_objectif, setfield_objectif] = useState("");
    const [field_programme, setfield_programme] = useState("");
    const [field_preprequis, setfield_preprequis] = useState("");

    const [field_methode_pedago, setfield_methode_pedago] = useState("");
    const [field_suivi_eval, setfield_suivi_eval] = useState("");


    const [field_prerequis, setfield_prerequis] = useState("");

    const [field_handicap, setfield_handicap] = useState("");


    const [editor_tochange, seteditor_tochange] = useState("");
    function editor_keyup() {
        seteditor_tochange("1");
    }


    function Annule_change_data() {
        Get_Given_Unite_Enseignement(selected_id);
        desable_detail_fields();
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function handleClick_edit_ue_From_Line(selected_row_id) {

        submenu_detail();
        var line = JSON.parse(Getall_Parter_Unite_Enseignement_result[selected_row_id]);

        await sleep(10);
        setadd_one_ue("0");
        clean_fields();
        setue_data_edit_mode("");
        setdisplay_ue("1");
        seteu_changed("");

        var invoice_id = line._id;
        setselected_id(invoice_id);

        Get_Given_Unite_Enseignement(line._id);

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        Getall_UE_Planif_data(line._id);


        desable_detail_fields();

    }

    function Annule_add_new_UE() {
        clean_fields();
        setdisplay_ue("0");
        setue_data_edit_mode("");
        setadd_one_ue("");
    }

    const New_Option_Filter = [
        { "id": "titre", "label": "Titre ", "value": "titre" },
        { "id": "code", "label": "Code", "value": "code" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]

    const [New_Get_List_Domaines_result, setNew_Get_List_Domaines_result] = useState([]);

    const [Get_List_Domaines_api, setGet_List_Domaines_api] = useState();
    const [Get_List_Domaines_message, setGet_List_Domaines_message] = useState();
    const [Get_List_Domaines_result, setGet_List_Domaines_result] = useState();
    function Get_List_Domaines() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_domaine/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log(' IN Get_List_Domaines Success:', data['message'], "data['status'] = ", data['status']);

                if (String(data['status']) === String("true")) {
                    setGet_List_Domaines_api("true");
                    setGet_List_Domaines_result(data['message']);

                    var new_data2 = [];
                    const new_data = data['message'].map((x) => {

                        //---
                        var localid = JSON.parse(x).id;
                        var local_id = JSON.parse(x)._id;
                        var local_code = JSON.parse(x).code;
                        var local_nom = JSON.parse(x).nom;


                        //---
                        var node = {
                            "id": localid,
                            "_id": local_id,
                            "nom": local_nom,
                            "code": local_code,
                            "label": local_nom,


                        };
                        new_data2.push(node);
                    });

                    var node = {
                        "id": "",
                        "_id": "",
                        "nom": "",
                        "code": "",
                        "label": "",


                    };
                    new_data2.push(node);

                    if (new_data2.length > 0) {
                        setNew_Get_List_Domaines_result(new_data2);

                        // console.log(" ## new_data2 =  ", new_data2);
                        // console.log(" ## New_Get_List_Domaines_result =  ", New_Get_List_Domaines_result);
                    } else {
                        setNew_Get_List_Domaines_result([])
                    }
                }
                else {
                    setGet_List_Domaines_api("false");
                    setGet_List_Domaines_message(data['message']);
                }

            }).catch((error) => {
                console.error('Error Get_List_Domaines :', error);
                setGet_List_Domaines_api("false");
            });
    }


    const [New_Get_List_Type_Cours_result, setNew_Get_List_Type_Cours_result] = useState([]);

    const [Get_List_Type_Cours_api, setGet_List_Type_Cours_api] = useState();
    const [Get_List_Type_Cours_message, setGet_List_Type_Cours_message] = useState();
    const [Get_List_Type_Cours_result, setGet_List_Type_Cours_result] = useState();
    function Get_List_Type_Cours() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Type_Cours/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log(' IN Get_List_Type_Cours Success:', data['message'], "data['status'] = ", data['status']);

                if (String(data['status']) === String("true")) {
                    setGet_List_Type_Cours_api("true");
                    setGet_List_Type_Cours_result(data['message']);

                    var new_data2 = [];
                    const new_data = data['message'].map((x) => {

                        //---
                        var localid = JSON.parse(x).id;
                        var local_id = JSON.parse(x)._id;
                        var local_code = JSON.parse(x).code;
                        var local_nom = JSON.parse(x).nom;
                        var local_description = JSON.parse(x).description;


                        //---
                        var node = {
                            "id": localid,
                            "_id": local_id,
                            "nom": local_nom,
                            "code": local_code,
                            "label": local_nom,
                            "description": local_description,


                        };
                        new_data2.push(node);
                    });

                    var node = {
                        "id": "",
                        "_id": "",
                        "nom": "",
                        "code": "",
                        "label": "",
                        "description": "",


                    };
                    new_data2.push(node);

                    if (new_data2.length > 0) {
                        setNew_Get_List_Type_Cours_result(new_data2);

                        // console.log(" ## new_data2 =  ", new_data2);
                        // console.log(" ## New_Get_List_Type_Cours_result =  ", New_Get_List_Type_Cours_result);
                    } else {
                        setNew_Get_List_Type_Cours_result([])
                    }
                }
                else {
                    setGet_List_Type_Cours_api("false");
                    setGet_List_Type_Cours_message(data['message']);
                }

            }).catch((error) => {
                console.error('Error Get_List_Type_Cours :', error);
                setGet_List_Type_Cours_api("false");
            });
    }

    async function submenu_detail() {

        setsubmenu("detail_ue");
        submenu_color_management("detail_ue");
    }

    async function submenu_planif() {
        setsubmenu("planification_ue");
        submenu_color_management("planification_ue");
    }

    function submenu_color_management(current_menu) {
        const list_sous_menu = ["detail_ue", "planification_ue",]

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }

            if (String(sous_menu) === "editique") {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#6C3483";
                    document.getElementById(sous_menu).style.color = "#FFFFFF";
                    document.getElementById(sous_menu).style.fontWeight = "bold";
                }
            }

        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

    }

    const [nb_line_planif, setnb_line_planif] = useState("0");


    const [Getall_UE_Planif_data_api, setGetall_UE_Planif_data_api] = useState();
    const [Getall_UE_Planif_data_message, setGetall_UE_Planif_data_message] = useState();
    const [Getall_UE_Planif_data_result, setGetall_UE_Planif_data_result] = useState([]);
    function Getall_UE_Planif_data(local_ue_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ue_id", local_ue_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Planif_lines/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_UE_Planif_data  res.data.status = " + res.data.status);
                //console.log(" In Getall_UE_Planif_data  res.data.message r_class = " + res.data.message);
                setGetall_UE_Planif_data_api("true");
                setGetall_UE_Planif_data_result(res.data.message);

                //console.log(" ##NB_Sequence  = ", res.data.message.length);
                setnb_line_planif(res.data.message.length);


            }
            else {
                setGetall_UE_Planif_data_api("false");
                setGetall_UE_Planif_data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_UE_Planif_data = ', error);
            setGetall_UE_Planif_data_api("false");
            alert("Impossible de récuperer la liste des séquences")
            //setmyApimyApiMessage("")
        })
    }



    const [p_detail_planif_line_code, setp_detail_planif_line_code] = useState("");
    const [p_detail_planif_line_titre, setp_detail_planif_line_titre] = useState("");
    const [p_detail_planif_line_description, setp_detail_planif_line_description] = useState("");
    const [p_detail_planif_line_prerequis, setp_detail_planif_line_prerequis] = useState("");
    const [p_detail_planif_line_volume_horaire, setp_detail_planif_line_volume_horaire] = useState("");
    const [p_detail_planif_line_comment, setp_detail_planif_line_comment] = useState("");

    function claer_detail_planif_line_fields() {
        setp_detail_planif_line_code("");
        setp_detail_planif_line_titre("");
        setp_detail_planif_line_description("");
        setp_detail_planif_line_prerequis("");
        setp_detail_planif_line_volume_horaire("");
        setp_detail_planif_line_comment("");
        setupdate_planif_line("");
        setselected_planif_line_id("")

    }


    const [Dialog_PLANIF_LINE_message, setDialog_PLANIF_LINE_message] = React.useState("");

    const [Dialog_PLANIF_LINE_open, setDialog_PLANIF_LINE_open] = React.useState(false);

    function Close_Dialog_PLANIF_LINE_buton() {
        claer_detail_planif_line_fields();
        setDialog_PLANIF_LINE_open(false);
    }

    const [update_planif_line, setupdate_planif_line] = useState();

    const [selected_planif_line_id, setselected_planif_line_id] = useState("");


    const [Add_Update_Unite_Enseignement_Planif_api, setAdd_Update_Unite_Enseignement_Planif_api] = useState();
    const [Add_Update_Unite_Enseignement_Planif_message, setAdd_Update_Unite_Enseignement_Planif_message] = useState();
    const [Add_Update_Unite_Enseignement_Planif_result, setAdd_Update_Unite_Enseignement_Planif_result] = useState();
    const Add_Update_Unite_Enseignement_Planif = event => {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ue_id", selected_id);
        form.append("code", p_detail_planif_line_code);
        form.append("titre", p_detail_planif_line_titre);
        form.append("description", p_detail_planif_line_description);
        form.append("prerequis", p_detail_planif_line_prerequis);
        form.append("volume_horaire", p_detail_planif_line_volume_horaire);
        form.append("comment", p_detail_planif_line_comment);
        if (selected_planif_line_id && String(selected_planif_line_id).length > 3)
            form.append("unite_enseignement_planif_id", selected_planif_line_id);



        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Unite_Enseignement_Planif/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_Unite_Enseignement_Planif  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Unite_Enseignement_Planif  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_Unite_Enseignement_Planif_api("true");
                setAdd_Update_Unite_Enseignement_Planif_result(res.data.message);

                Getall_UE_Planif_data(selected_id);

                alert(res.data.message);
                Close_Dialog_PLANIF_LINE_buton();
            }
            else {
                setAdd_Update_Unite_Enseignement_Planif_api("false");
                setAdd_Update_Unite_Enseignement_Planif_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_Unite_Enseignement_Planif = ' + error);
            setAdd_Update_Unite_Enseignement_Planif_api("false");
            alert(" Impossible d'ajouter ou mettre à jour la ligne de planification");
        })
    }


    /*** Gestion fichier csv à importer */

    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Unite_Enseignement_Planif_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                setLoading(false);
                if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");
                    Getall_UE_Planif_data(selected_id);


                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    alert(result['message']);

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer le fichier");
            });

    }


    const [session_file_name, setsession_file_name] = useState();
    function submenu_import_session() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }


    const hiddenFileInput_session = React.useRef(null);
    /**** fin gestion fichier csv a importer */


    const nivau_competence = [
        "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]

    const fixedVal = [];
    const [val, setVal] = useState([...fixedVal]);
    const CustomChip = (chipVal, getTagProps) => {
        return chipVal.map((item, index) => (
            <Chip
                label={String(item)} //adding options label
                {...getTagProps({ index })}
                disabled={fixedVal.indexOf(item) !== -1}
            />
        ));
    };

    return (
        <div className="displayPartnerunite_enseignement">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            {/*** Dialog ajout PLANIF_LINE ue  */}

            <Dialog
                open={Dialog_PLANIF_LINE_open}
                //onClose={Dialog_PLANIF_LINE_handleClose}

                className="displaypartnersession"
                static
                onClose={() => null}

            >

                <DialogTitle>

                    {String(update_planif_line) !== "1" && "Ajouter une ligne"}
                    {String(update_planif_line) === "1" && "Mettre à jour une ligne"}


                </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_PLANIF_LINE_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" > <b>Code </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_code"
                            id="event_dialog_code"


                            fullWidth

                            value={p_detail_planif_line_code}
                            onChange={(e) => {
                                setp_detail_planif_line_code(e.target.value);
                                seteu_changed("1");
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > <b> Titre </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={p_detail_planif_line_titre}
                            onChange={(e) => {
                                setp_detail_planif_line_titre(e.target.value);
                                seteu_changed("1");
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > <b>Volume Horaire </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_vh"
                            id="event_dialog_vh"
                            type="number"
                            step="any"

                            fullWidth

                            value={p_detail_planif_line_volume_horaire}
                            onChange={(e) => {
                                setp_detail_planif_line_volume_horaire(e.target.value);
                                seteu_changed("1");
                            }
                            }

                        />

                    </div>



                    <div className="session_caract_Dialog_comment_2_lines" > Description
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_desc"
                            id="event_dialog_desc"

                            fullWidth

                            value={p_detail_planif_line_description}
                            onChange={(e) => {

                                setp_detail_planif_line_description(e.target.value);
                                seteu_changed("1");
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Prérequis
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_prerequis"
                            id="event_dialog_prerequis"

                            fullWidth

                            value={p_detail_planif_line_prerequis}
                            onChange={(e) => {
                                setp_detail_planif_line_prerequis(e.target.value);
                                seteu_changed("1");
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_comment"
                            id="event_dialog_comment"

                            fullWidth

                            value={p_detail_planif_line_comment}
                            onChange={(e) => {
                                setp_detail_planif_line_comment(e.target.value);
                                seteu_changed("1");
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(update_planif_line) !== "1" && <Button onClick={Add_Update_Unite_Enseignement_Planif} className="bton_enreg_dialog">Ajouter</Button>}
                            {String(update_planif_line) === "1" && <Button onClick={Add_Update_Unite_Enseignement_Planif} className="bton_enreg_dialog">Mettre à jour</Button>}
                        </div>




                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_PLANIF_LINE_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>
            {/*** Fin Dialog ajout PLANIF_LINE ue */}


            <h3> Gestion des Unités d'enseignement </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value("");
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Parter_Unite_Enseignement_with_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>



            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',



                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },


                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },


                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);

                                    }}
                                    selectionModel={selectionModel}
                                    disableSelectionOnClick
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            code: JSON.parse(item).code,
                                            titre: JSON.parse(item).titre,
                                            duration_concat: JSON.parse(item).duration_concat,
                                            duration: JSON.parse(item).duration_concat,
                                            duration_unite: JSON.parse(item).duration_unite,

                                            domain_id: JSON.parse(item).domain_id,
                                            bloc: JSON.parse(item).bloc,

                                            planification_line: JSON.parse(item).planification_line,
                                            planification_used_line: JSON.parse(item).planification_used_line,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        // handleClick_edit_config_From_Line(newSelectionModel.row.id);

                                        setselected_id(newSelectionModel.row._id);
                                        handleClick_edit_ue_From_Line(newSelectionModel.row.id);
                                        setsession_file_name("");
                                        setliste_sessions_file_change_api("");
                                        setgridline_id(newSelectionModel.row.id);

                                    }}

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {


                                        //field === "order_header_status"
                                        if (params.field === "order_header_status" && String(params.value) == "0") {
                                            return 'cell--status--brouillon';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "1") {

                                            return 'cell--status--encours';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "2") {
                                            return 'cell--status--traite';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "3") {
                                            return 'cell--status--facture';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {

                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>
                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                        <Button variant="outlined" onClick={submenu_add_one_UE}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 UE &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>
                    </div>
                </div>

            </div>

            <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>


            {display_ue && String(display_ue) === "1" && String(add_one_ue) !== "1" && <div className="div_row">
                <Button variant="outlined" onClick={submenu_detail} className="detail_class_submenu" id='detail_ue' name='detail_ue'>Détail UE</Button>
                <Button variant="outlined" onClick={submenu_planif} className="detail_class_submenu" id='planification_ue' name='planification_ue'>Planification </Button>

            </div>}

            {/*** Affichage d'une UE */}
            <Tooltip className="tooltip_css" id="tooltip_cm1" />
            <Tooltip className="tooltip_css" id="tooltip_td1" />
            <Tooltip className="tooltip_css" id="tooltip_tp1" />
            <Tooltip className="tooltip_css" id="tooltip_tpg1" />


            {display_ue && String(display_ue) === "1" && String(add_one_ue) !== "1" && String(submenu) === "detail_ue" &&
                <div className="session_data" onChange={is_EU_changed}>

                    <div className="div_row">
                        <div className="block_ue_div_50_percent_gauche">

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" &&
                                <div className="session_caract"><b> Code </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_code"
                                        id="detail_ue_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_code}
                                    />
                                </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" &&
                                <div className="session_caract"><b> Code </b><br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_code"
                                        id="detail_ue_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_code}
                                        onChange={(e) => {
                                            setp_detail_code(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }
                                    />
                                </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div className="session_caract"><b> Titre </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ue_titre"
                                    id="detail_ue_titre"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_titre}

                                />
                            </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div className="session_caract"><b> Titre </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ue_titre"
                                    id="detail_ue_titre"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_titre}
                                    onChange={(e) => {
                                        setp_detail_titre(e.target.value);
                                        seteu_changed("1");
                                    }
                                    }

                                />
                            </div>}


                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div className="session_caract"><b> Bloc </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ue_bloc"
                                    id="detail_ue_bloc"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_bloc}
                                />
                            </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div className="session_caract"><b> Bloc </b><br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ue_bloc"
                                    id="detail_ue_bloc"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    //disabled={true}
                                    className="disabled_style"
                                    value={p_detail_bloc}
                                    onChange={(e) => {
                                        setp_detail_bloc(e.target.value);
                                        seteu_changed("1");
                                    }
                                    }


                                />
                            </div>}


                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" &&
                                New_Get_List_Domaines_result && New_Get_List_Domaines_result.length > 0 && <div className="training_caract">Domaine <br />
                                    <Autocomplete
                                        disablePortal

                                        name="detail_ue_domaine"
                                        id="detail_ue_domaine"
                                        className="disabled_style"
                                        options={New_Get_List_Domaines_result}

                                        value={New_Get_List_Domaines_result.filter((data) => (data)._id === String(p_detail_domaine))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_domaine(value._id);

                                            } else {
                                                setp_detail_domaine("");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" />
                                        }
                                    />

                                </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" &&
                                New_Get_List_Domaines_result && New_Get_List_Domaines_result.length > 0 && <div className="training_caract">Domaine <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_domaine"
                                        id="detail_ue_domaine"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={New_Get_List_Domaines_result.filter((data) => (data)._id === String(p_detail_domaine))[0].label}


                                    />

                                </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div>
                                <div className="session_caract"> Compétence <br />
                                    <Autocomplete
                                        multiple
                                        onChange={(event, newValue) => {
                                            setVal([
                                                ...fixedVal,
                                                ...newValue.filter((i) => fixedVal.indexOf(i) === -1),
                                            ]);

                                        }}
                                        value={val}
                                        options={nivau_competence}
                                        className="disabled_style"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                placeholder="Ajout"

                                            />
                                        )}
                                        renderTags={CustomChip}
                                    />

                                </div>

                            </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div>
                                <div className="session_caract"> Compétence <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_competence"
                                        id="detail_ue_competence"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_tab_competence}

                                    />

                                </div>

                            </div>}

                        </div>

                        <div className="block_ue_div_50_percent_droite">
                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div>
                                <div className="session_caract"> Semestre <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_semestre"
                                        id="detail_ue_semestre"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_semestre}
                                    />
                                </div>

                            </div>}


                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div>
                                <div className="session_caract"> Semestre <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_semestre"
                                        id="detail_ue_semestre"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='number'
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_semestre}
                                        onChange={(e) => {
                                            setp_detail_semestre(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }
                                    />
                                </div>

                            </div>}



                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div>
                                <div className="session_caract"> ECTS <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_ects"
                                        id="detail_ue_ects"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_ects}


                                    />
                                </div>

                            </div>}
                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div>
                                <div className="session_caract"> ECTS <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_ects"
                                        id="detail_ue_ects"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_ects}
                                        onChange={(e) => {
                                            setp_detail_ects(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }

                                    />
                                </div>

                            </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div>
                                <div className="session_caract"> Seuil Validation <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_seuil_validation"
                                        id="detail_ue_seuil_validation"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_seuil_validation}

                                    />
                                </div>

                            </div>}



                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div>
                                <div className="session_caract"> Seuil Validation <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_seuil_validation"
                                        id="detail_ue_seuil_validation"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_seuil_validation}
                                        onChange={(e) => {
                                            setp_detail_seuil_validation(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }
                                    />
                                </div>

                            </div>}

                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                                <div className="titre1" > Volume Horaire  </div>
                            </div>

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div>
                                <div className="session_caract"> Durée Totale <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_duree"
                                        id="detail_ue_duree"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_duration}


                                    />
                                </div>

                                <div className="session_caract"> Unité Durée <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_unit_duree"
                                        id="detail_ue_unit_duree"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_duration_unite}
                                    />
                                </div>


                            </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" &&
                                <div>
                                    <div className="session_caract"> Durée Totale<br />

                                        <TextField

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            name="detail_ue_duree"
                                            id="detail_ue_duree"
                                            value={p_detail_duration}
                                            type="number"
                                            onChange={(e) => {
                                                setp_detail_duration(e.target.value);
                                                seteu_changed("1");
                                            }
                                            }
                                        />
                                    </div>

                                    <div className="session_caract"> Unité Durée <br />
                                        <Tooltip className="tooltip_css" id="my-tooltip11" />
                                        <a data-tooltip-id="my-tooltip11" data-tooltip-html="Les durée autorisées sont : 'heure', 'jour', 'semaine', 'mois', 'annee', 'rythme participant'">

                                            <Autocomplete
                                                disablePortal
                                                name="detail_ue_unit_duree"
                                                id="detail_ue_unit_duree"
                                                className="disabled_style"
                                                options={New_duration_unit_list}

                                                value={New_duration_unit_list.filter((data) => (data).value === String(p_detail_duration_unite))[0].label}

                                                onChange={(event, value) => {
                                                    if (value && value.value) {
                                                        setp_detail_duration_unite(value.value);
                                                        seteu_changed();
                                                    }
                                                }}

                                                renderInput={(params) => <TextField {...params} label="" />
                                                }
                                            />
                                        </a>
                                    </div>
                                </div>}

                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            {ue_data_edit_mode && String(ue_data_edit_mode) !== "1" && <div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_cm1" data-tooltip-html="Nombre d'heures cours magistraux (CM)">
                                        CM <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_cm_heure"
                                        id="detail_ue_cm_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_cm}

                                    />
                                </div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_td1" data-tooltip-html="Nombre d'heures travaux dirigés (TD) ">
                                        TD <FcInfo />
                                        <br />
                                    </a>

                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_td_heure"
                                        id="detail_ue_td_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_td}

                                    />
                                </div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_tp1" data-tooltip-html="Nombre d'heures travaux pratiques (TP)">
                                        TP  <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_tp_heure"
                                        id="detail_ue_tp_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_tp}

                                    />
                                </div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_tpg1" data-tooltip-html="Nombre d'heures Travail Personnel Guidé (TPG)">
                                        TPG <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_tpg_heure"
                                        id="detail_ue_tpg_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_tpg}

                                    />
                                </div>

                            </div>}

                            {ue_data_edit_mode && String(ue_data_edit_mode) === "1" && <div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_cm1" data-tooltip-html="Nombre d'heures cours magistraux (CM)">
                                        CM <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_cm_heure"
                                        id="detail_ue_cm_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_cm}
                                        type="number"
                                        onChange={(e) => {
                                            setp_detail_cours_cm(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }

                                    />
                                </div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_td1" data-tooltip-html="Nombre d'heures travaux dirigés (TD) ">
                                        TD <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_td_heure"
                                        id="detail_ue_td_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_td}
                                        type="number"
                                        onChange={(e) => {
                                            setp_detail_cours_td(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }

                                    />
                                </div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_tp1" data-tooltip-html="Nombre d'heures travaux pratiques (TP)">
                                        TP  <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_tp_heure"
                                        id="detail_ue_tp_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_tp}
                                        type="number"
                                        onChange={(e) => {
                                            setp_detail_cours_tp(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }

                                    />
                                </div>

                                <div className="session_caract">
                                    <a data-tooltip-id="tooltip_tpg1" data-tooltip-html="Nombre d'heures Travail Personnel Guidé (TPG)">
                                        TPG <FcInfo />
                                        <br />
                                    </a>
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_ue_tpg_heure"
                                        id="detail_ue_tpg_heure"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //disabled={true}
                                        className="disabled_style"
                                        value={p_detail_cours_tpg}
                                        type="number"
                                        onChange={(e) => {
                                            setp_detail_cours_tpg(e.target.value);
                                            seteu_changed("1");
                                        }
                                        }

                                    />
                                </div>

                            </div>}

                        </div>

                    </div>

                    {String(ue_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {eu_changed === "1" &&
                            <div className="div_row">
                                <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications
                                </div>
                            </div>}

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_Update_UE_Data}>Enregistrer les modifications
                            </Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_change_data}>Annuler
                            </Button>
                        </div>

                    </div>}


                    {String(ue_data_edit_mode) !== "1" && <div className="div_row" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                            <Button variant="contained" className="bton_edit" onClick={enable_detail_fields}>Editer
                            </Button>
                        </div>

                    </div>}

                    <div className="div_row" style={{ "border": "None" }}>
                        <hr className="hr_break" />
                        <div className="titre1" > Description détaillée  </div>
                    </div>



                    {ue_data_edit_mode && String(ue_data_edit_mode) === "1" &&
                        <div className="div_row">

                            <div className="training_data">


                                <div className="training_text" style={{ border: "1px solid" }} >
                                    <div className="plus_produit_desabled">
                                        <div className="titre_training_text titre2"> Pré-requis  (max 1000 caractères) </div>
                                        <Editor
                                            name="prerequis"
                                            id="prerequis"
                                            onInit={(evt, editor) => editorRef_prerequis.current = editor}
                                            initialValue={field_preprequis}
                                            onKeyUp={editor_keyup}
                                            onEditorChange={(newValue, editor) => {

                                                if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                                    //alert(" change");
                                                    is_EU_changed()
                                                    seteditor_tochange("");
                                                }
                                            }}

                                            init={{
                                                resize: false,
                                                height: 300,
                                                readonly: 1,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],

                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',


                                            }}
                                        />

                                    </div>
                                </div>


                                <div className="training_text">
                                    <div className="plus_produit_desabled">
                                        <div className="titre_training_text titre2"> Description  (max 1000 caractères) </div>
                                        <Editor
                                            name="description"
                                            id="description"
                                            onInit={(evt, editor) => editorRef_description.current = editor}
                                            initialValue={field_description}
                                            onKeyUp={editor_keyup}
                                            onEditorChange={(newValue, editor) => {

                                                if (String(editor_tochange) === "1") {
                                                    //alert(" change");
                                                    is_EU_changed()
                                                    seteditor_tochange("");
                                                }
                                            }}

                                            init={{
                                                resize: false,
                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],

                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="training_text" style={{ border: "1px solid" }} >
                                    <div className="plus_produit_desabled">
                                        <div className="titre_training_text titre2"> Objectif (max 1000 caractères) </div>
                                        <Editor
                                            onInit={(evt, editor) => editorRef_objectif.current = editor}
                                            initialValue={field_objectif}
                                            onKeyUp={editor_keyup}
                                            onEditorChange={(newValue, editor) => {
                                                if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                                    //alert(" change");
                                                    is_EU_changed();
                                                    seteditor_tochange("");
                                                }
                                            }}
                                            init={{
                                                resize: false,
                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],

                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                            }}
                                        />

                                    </div>
                                </div>


                                <div className="training_text">
                                    <div className="plus_produit_desabled">
                                        <div className="titre_training_text titre2"> Programme (max 1000 caractères) </div>
                                        <Editor
                                            onInit={(evt, editor) => editorRef_programme.current = editor}

                                            initialValue={field_programme}
                                            onKeyUp={editor_keyup}
                                            onEditorChange={(newValue, editor) => {
                                                if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                                    //alert(" change");
                                                    is_EU_changed();
                                                    seteditor_tochange("");
                                                }
                                            }}
                                            init={{
                                                resize: false,

                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],

                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',

                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="training_text">
                                    <div className="plus_produit_desabled">
                                        <div className="titre_training_text titre2"> Suivi & Evaluation (max 1000 caractères) </div>
                                        <Editor
                                            onInit={(evt, editor) => editorRef_methode_eval.current = editor}

                                            initialValue={field_suivi_eval}
                                            onKeyUp={editor_keyup}
                                            onEditorChange={(newValue, editor) => {
                                                if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                                    //alert(" change");
                                                    is_EU_changed();
                                                    seteditor_tochange("");
                                                }
                                            }}
                                            init={{
                                                resize: false,

                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],

                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',

                                            }}
                                        />

                                    </div>
                                </div>


                                <div className="training_text">
                                    <div className="plus_produit_desabled">
                                        <div className="titre_training_text titre2"> Méthode Pédagogique </div>
                                        <Editor
                                            onInit={(evt, editor) => editorRef_methode_pedago.current = editor}

                                            initialValue={field_methode_pedago}
                                            onKeyUp={editor_keyup}
                                            onEditorChange={(newValue, editor) => {
                                                if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                                    //alert(" change");
                                                    is_EU_changed();
                                                    seteditor_tochange("");
                                                }
                                            }}
                                            init={{
                                                resize: false,

                                                height: 300,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],

                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',

                                            }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {String(ue_data_edit_mode) !== "1" &&
                        <div className="div_row" style={{ padding: "10px" }}>

                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text titre2"> Pré-requis </div>
                                    {parse(field_preprequis.replace(/(<([^>]+)>)/ig, ''))}
                                </div>
                                <br />
                            </div>

                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text titre2">  Description </div>
                                    {parse(field_description.replace(/(<([^>]+)>)/ig, ''))}

                                </div>
                                <br />
                            </div>

                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text titre2">  Objectif </div>
                                    {parse(field_objectif.replace(/(<([^>]+)>)/ig, ''))}

                                </div>
                                <br />
                            </div>


                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text titre2">  Programme </div>

                                    {parse(field_programme.replace(/(<([^>]+)>)/ig, ''))}

                                </div>
                                <br />
                            </div>

                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text titre2"> Suivi & Evaluation </div>
                                    {parse(field_suivi_eval.replace(/(<([^>]+)>)/ig, ''))}


                                </div>
                                <br />
                            </div>


                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text titre2"> Méthode Pédagogique </div>

                                    {parse(field_methode_pedago.replace(/(<([^>]+)>)/ig, ''))}
                                </div>
                                <br />
                            </div>


                        </div>}


                    {String(ue_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {eu_changed === "1" &&
                            <div className="div_row">
                                <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications
                                </div>
                            </div>}

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_Update_UE_Data}>Enregistrer les modifications
                            </Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_change_data}>Annuler
                            </Button>
                        </div>

                    </div>}


                    {String(ue_data_edit_mode) !== "1" && <div className="div_row" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                            <Button variant="contained" className="bton_edit" onClick={enable_detail_fields}>Editer
                            </Button>
                        </div>

                    </div>}

                </div>
            }

            {display_ue && String(display_ue) === "1" && String(add_one_ue) !== "1" && String(submenu) === "planification_ue" &&
                <div className="session_data" onChange={is_EU_changed} style={{ minHeight: '15rem' }}>

                    <div className="session_data">
                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',



                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },


                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        }, [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },


                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel_planif(newSelectionModel);

                                        }}
                                        selectionModel={selectionModel_planif}
                                        disableSelectionOnClick
                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_UE_Planif_data_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                code: JSON.parse(item).code,
                                                titre: JSON.parse(item).titre,
                                                description: JSON.parse(item).description,
                                                volume_horaire: JSON.parse(item).volume_horaire,
                                                ue_id: JSON.parse(item).ue_id,

                                                prerequis: JSON.parse(item).prerequis,
                                                comment: JSON.parse(item).comment,

                                                in_use: JSON.parse(item).in_use,
                                                used_sequence_title: JSON.parse(item).used_sequence_title,


                                            }
                                        ))}

                                        columns={columns_planif_ue}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            setupdate_planif_line("1");
                                            setselected_planif_line_id(newSelectionModel.row._id);
                                            setp_detail_planif_line_code(newSelectionModel.row.code);
                                            setp_detail_planif_line_titre(newSelectionModel.row.titre);
                                            setp_detail_planif_line_description(newSelectionModel.row.description);
                                            setp_detail_planif_line_prerequis(newSelectionModel.row.prerequis);
                                            setp_detail_planif_line_volume_horaire(newSelectionModel.row.volume_horaire);
                                            setp_detail_planif_line_comment(newSelectionModel.row.comment);

                                            setDialog_PLANIF_LINE_open(true);
                                        }}

                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {


                                            //field === "order_header_status"
                                            if (params.field === "order_header_status" && String(params.value) == "0") {
                                                return 'cell--status--brouillon';
                                            }

                                            if (params.field === "order_header_status" && String(params.value) == "1") {

                                                return 'cell--status--encours';
                                            }

                                            if (params.field === "order_header_status" && String(params.value) == "2") {
                                                return 'cell--status--traite';
                                            }

                                            if (params.field === "order_header_status" && String(params.value) == "3") {
                                                return 'cell--status--facture';
                                            }

                                        }}
                                        getRowClassName={(params) => {

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(planif_gridline_id)) {

                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }
                                        }}


                                    />
                                </Box>
                                <br />

                            </div>

                        </div>

                    </div>

                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>


                            <Button variant="outlined" onClick={submenu_import_session} className="detail_class_submenu bton_import_excel"
                                id='menu_import_participant' name='menu_import_participant'>Importer des lignes Excel &nbsp;
                                <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                            </Button>


                            <Button variant="outlined" onClick={(e) => {
                                setupdate_planif_line("0");
                                claer_detail_planif_line_fields();

                                setDialog_PLANIF_LINE_open(true);
                            }}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Ligne &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />

                            </Button>

                            <br />
                            {session_file_name && <nav><i>{session_file_name}</i></nav>}
                            {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les lignes ont été correctement importées</nav>}
                            {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                            <input type="file"
                                accept=".csv"
                                ref={hiddenFileInput_session}
                                style={{ display: 'none' }}
                                name="liste_sessions_file"
                                onChange={liste_sessions_file_change}
                            />

                            <a href='/sample/template_import_ue_planification.csv' download>Télécharger un fichier modèle</a>
                        </div>
                    </div>
                </div>
            }



            {/**** Ajout d'une UE */}
            {display_ue && String(display_ue) !== "1" && String(add_one_ue) === "1" && <div className="session_data" >

                <div className="div_row">

                    <div className="block_ue_div_50_percent_gauche">

                        <div className="session_caract"><b> Code </b><br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="code_session"
                                id="code_session"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_code}
                                onChange={(e) => setp_detail_code(e.target.value)}

                            />
                        </div>

                        <div className="session_caract"><b> Titre </b><br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="titre"
                                id="titre"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_titre}
                                onChange={(e) => setp_detail_titre(e.target.value)}

                            />
                        </div>

                        <div className="session_caract"> Bloc <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="bloc"
                                id="bloc"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_bloc}
                                onChange={(e) => setp_detail_bloc(e.target.value)}

                            />
                        </div>
                        {New_Get_List_Domaines_result && New_Get_List_Domaines_result.length > 0 && <div className="training_caract"> Domaine <br />
                            <Autocomplete
                                disablePortal

                                name="domaine"
                                id="domaine"
                                className="disabled_style"
                                options={New_Get_List_Domaines_result}

                                value={New_Get_List_Domaines_result.filter((data) => (data)._id === String(p_detail_domaine))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_domaine(value._id);

                                    } else {
                                        setp_detail_domaine("");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" />
                                }
                            />

                        </div>}

                        <div className="session_caract"> Compétence <br />
                            <Autocomplete
                                multiple
                                onChange={(event, newValue) => {
                                    setVal([
                                        ...fixedVal,
                                        ...newValue.filter((i) => fixedVal.indexOf(i) === -1),
                                    ]);

                                }}
                                value={val}
                                options={nivau_competence}
                                className="disabled_style"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=""
                                        placeholder="Ajout"

                                    />
                                )}
                                renderTags={CustomChip}
                            />

                        </div>

                    </div>

                    <div className="block_ue_div_50_percent_droite">

                        <div className="session_caract"> Semestre <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_semestre"
                                id="detail_ue_semestre"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type='number'
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_semestre}
                                onChange={(e) => {
                                    setp_detail_semestre(e.target.value);
                                    seteu_changed("1");
                                }
                                }
                            />
                        </div>

                        <div className="session_caract"> ECTS <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_ects"
                                id="detail_ue_ects"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_ects}
                                onChange={(e) => {
                                    setp_detail_ects(e.target.value);
                                    seteu_changed("1");
                                }
                                }

                            />
                        </div>

                        <div className="session_caract"> Seuil Validation <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_seuil_validation"
                                id="detail_ue_seuil_validation"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_seuil_validation}
                                onChange={(e) => {
                                    setp_detail_seuil_validation(e.target.value);
                                    seteu_changed("1");
                                }
                                }
                            />
                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                            <div className="titre1" > Volume Horaire  </div>
                        </div>
                        <div className="session_caract"> Durée Totale<br />

                            <TextField

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                name="detail_ue_duree"
                                id="detail_ue_duree"
                                value={p_detail_duration}
                                type="number"
                                onChange={(e) => {
                                    setp_detail_duration(e.target.value);
                                    seteu_changed("1");
                                }
                                }
                            />
                        </div>

                        <div className="session_caract"> Unité Durée <br />
                            <Tooltip className="tooltip_css" id="my-tooltip11" />
                            <a data-tooltip-id="my-tooltip11" data-tooltip-html="Les durée autorisées sont : 'heure', 'jour', 'semaine', 'mois', 'annee', 'rythme participant'">

                                <Autocomplete
                                    disablePortal
                                    name="detail_ue_unit_duree"
                                    id="detail_ue_unit_duree"
                                    className="disabled_style"
                                    options={New_duration_unit_list}

                                    value={New_duration_unit_list.filter((data) => (data).value === String(p_detail_duration_unite))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_detail_duration_unite(value.value);
                                            seteu_changed();
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" />
                                    }
                                />
                            </a>
                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                        </div>

                        <div className="session_caract"><a data-tooltip-id="tooltip_cm1" data-tooltip-html="Nombre d'heures cours magistraux (CM)">
                            CM <FcInfo />
                            <br />
                        </a>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_cm_heure"
                                id="detail_ue_cm_heure"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_cours_cm}
                                type="number"
                                onChange={(e) => {
                                    setp_detail_cours_cm(e.target.value);
                                    seteu_changed("1");
                                }
                                }

                            />
                        </div>

                        <div className="session_caract">
                            <a data-tooltip-id="tooltip_td1" data-tooltip-html="Nombre d'heures travaux dirigés (TD) ">
                                TD <FcInfo />
                                <br />
                            </a>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_td_heure"
                                id="detail_ue_td_heure"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_cours_td}
                                type="number"
                                onChange={(e) => {
                                    setp_detail_cours_td(e.target.value);
                                    seteu_changed("1");
                                }
                                }

                            />
                        </div>

                        <div className="session_caract">
                            <a data-tooltip-id="tooltip_tp1" data-tooltip-html="Nombre d'heures travaux pratiques (TP)">
                                TP  <FcInfo />
                                <br />
                            </a>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_tp_heure"
                                id="detail_ue_tp_heure"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_cours_tp}
                                type="number"
                                onChange={(e) => {
                                    setp_detail_cours_tp(e.target.value);
                                    seteu_changed("1");
                                }
                                }

                            />
                        </div>

                        <div className="session_caract">
                            <a data-tooltip-id="tooltip_tpg1" data-tooltip-html="Nombre d'heures Travail Personnel Guidé (TPG)">
                                TPG <FcInfo />
                                <br />
                            </a>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_ue_tpg_heure"
                                id="detail_ue_tpg_heure"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //disabled={true}
                                className="disabled_style"
                                value={p_detail_cours_tpg}
                                type="number"
                                onChange={(e) => {
                                    setp_detail_cours_tpg(e.target.value);
                                    seteu_changed("1");
                                }
                                }

                            />
                        </div>


                    </div>
                </div>



                <div className="div_row">

                    <div className="training_data">


                        <div className="training_text">
                            <div className="plus_produit_desabled">
                                <div className="titre_training_text titre2"> Pré-requis  (max 1000 caractères) </div>
                                <Editor
                                    name="prerequis"
                                    id="prerequis"
                                    onInit={(evt, editor) => editorRef_prerequis.current = editor}
                                    initialValue={field_preprequis}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {

                                        if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                            //alert(" change");
                                            is_EU_changed()
                                            seteditor_tochange("");
                                        }
                                    }}

                                    init={{
                                        resize: false,
                                        height: 300,
                                        readonly: 1,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',


                                    }}
                                />

                            </div>
                        </div>


                        <div className="training_text">
                            <div className="plus_produit_desabled">
                                <div className="titre_training_text titre2"> Description (max 1000 caractères) </div>
                                <Editor
                                    name="description"
                                    id="description"
                                    onInit={(evt, editor) => editorRef_description.current = editor}
                                    initialValue={field_description}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {

                                        if (String(editor_tochange) === "1") {
                                            //alert(" change");
                                            is_EU_changed()
                                            seteditor_tochange("");
                                        }
                                    }}

                                    init={{
                                        resize: false,
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                    }}
                                />

                            </div>
                        </div>

                        <div className="training_text" style={{ border: "1px solid" }}  >
                            <div className="plus_produit_desabled">
                                <div className="titre_training_text titre2"> Objectif (max 1000 caractères) </div>
                                <Editor
                                    onInit={(evt, editor) => editorRef_objectif.current = editor}
                                    initialValue={field_objectif}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {
                                        if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                            //alert(" change");
                                            is_EU_changed();
                                            seteditor_tochange("");
                                        }
                                    }}
                                    init={{
                                        resize: false,
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                    }}
                                />

                            </div>
                        </div>


                        <div className="training_text" style={{ border: "1px solid" }} >
                            <div className="plus_produit_desabled">
                                <div className="titre_training_text titre2"> Programme (max 1000 caractères) </div>
                                <Editor
                                    onInit={(evt, editor) => editorRef_programme.current = editor}

                                    initialValue={field_programme}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {
                                        if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                            //alert(" change");
                                            is_EU_changed();
                                            seteditor_tochange("");
                                        }
                                    }}
                                    init={{
                                        resize: false,

                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',

                                    }}
                                />

                            </div>
                        </div>

                        <div className="training_text">
                            <div className="plus_produit_desabled">
                                <div className="titre_training_text titre2"> Suivi & Evaluation (max 1000 caractères) </div>
                                <Editor
                                    onInit={(evt, editor) => editorRef_methode_eval.current = editor}

                                    initialValue={field_suivi_eval}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {
                                        if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                            //alert(" change");
                                            is_EU_changed();
                                            seteditor_tochange("");
                                        }
                                    }}
                                    init={{
                                        resize: false,

                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',

                                    }}
                                />

                            </div>
                        </div>


                        <div className="training_text">
                            <div className="plus_produit_desabled">
                                <div className="titre_training_text titre2"> Méthode Pédagogique </div>
                                <Editor
                                    onInit={(evt, editor) => editorRef_methode_pedago.current = editor}

                                    initialValue={field_methode_pedago}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {
                                        if (String(editor_tochange) === "1" && String(ue_data_edit_mode) === "1") {
                                            //alert(" change");
                                            is_EU_changed();
                                            seteditor_tochange("");
                                        }
                                    }}
                                    init={{
                                        resize: false,

                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',

                                    }}
                                />

                            </div>
                        </div>
                    </div>


                </div>




                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>


                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                        <Button variant="contained" className="bton_enreg" onClick={Add_Update_UE_Data} >Enregistrer
                        </Button>

                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                        <Button variant="contained" className="bton_annule" onClick={Annule_add_new_UE}>Annuler
                        </Button>
                    </div>

                </div>


            </div>}


        </div>
    )
}


export default DisplayPartnerUnite_Enseignement;

