import React, { useRef, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Articles from "../components/Article";
import { FaSearch, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from 'react-icons/fa';
import { format } from 'date-fns'
import moment from "moment";
import Pagination from "./TestPagination";
import data from "./data/mock-data.json";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';
import { Helmet } from "react-helmet";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Button from '@mui/material/Button';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";
import connect_white from "../mysy_img/connect_white_v2.png";
import connect_green from "../mysy_img/connect_green.png";
import { BsFillXCircleFill } from "react-icons/bs";
import Fotter_v3 from "./Fotter_v3";

let PageSize = 5;
const RechercheArticles_new_v2 = (props) => {
    const [userconnected, setuserconnected] = useState("");
    const [SearchText, setSearchText] = useState("");
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("false");
    const [displayAllClasses, setdisplayAllClasses] = useState(true);
    const [showelement, setshowelement] = useState("init");
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [fin, setfin] = useState(0);
    const [ismobilephone, setismobilephone] = useState();
    const [TrainingDistanciel, setTrainingDistanciel] = useState();


    const stored_user = getCookie('tokenmysych');
    const history = useHistory();


    const url = process.env.REACT_APP_API_URL + "myclass/api/add_user_account/";
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm();
    const pwd = useRef({});
    pwd.current = watch("pwd", "");

    const onSubmit = async (data) => {
        const formData = new FormData();
        //await sleep(20);
        formData.append('search_text', data.name);
        formData.append('name', data.name);
        formData.append('surname', data.surname);
        formData.append('email', data.email);
        formData.append('pwd', data.pwd);
        const res = await fetch(
            url,
            {
                method: "POST",
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: formData,
            }
        );
        const results = await res.json();
        //console.log(results);
    };

    const [Trainingdistance, setTrainingdistance] = useState(0);
    const handleInput = (e) => {
        setTrainingdistance(e.target.value);
    }

    const [Trainingduration, setTrainingduration] = useState(0);
    const handleInputDuration = (e) => {
        setTrainingduration(e.target.value);
    }


    function showdisplayfilter(event) {

        if (showelement == "true") {
            setshowelement("false");
        }
        else if (showelement == "false") {
            setshowelement("true");
        }


    }

    function clearsearchtext(event) {
        setSearchText("");
        document.getElementById("SearchText").value = "";
    }


    function SearchTextHandle(event) {
        setSearchText(event.target.value);
    }



    function displayAllClassesHandle(event) {

        setdisplayAllClasses(event.target.checked);
        if (event.target.checked == true)
            document.getElementById("SearchText").disabled = true;
        else
            document.getElementById("SearchText").disabled = false;
    }


    const [searchFunction_api, setsearchFunction_api] = useState();
    const [searchFunction_message, setsearchFunction_message] = useState();
    const [searchFunction_result, setsearchFunction_result] = useState([]);
    function searchFunction(e) {

        var token = "";
        if (typeof (stored_user) != "undefined") {
            token = stored_user
        }


        var form = new FormData();
        form.append("search_text", SearchText);
        form.append("token", token);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = ""
        /* if (document.getElementById("allelement").checked !== true &&
             document.getElementById("SearchText").disabled !== true) {
             //alert(" recherche classique laaa ");
             myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_articles_avis/";
         }
         else if (document.getElementById("allelement").checked === true &&
             document.getElementById("SearchText").disabled === true) {
             myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_articles_avis/";
         }*/
        myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_articles_avis/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In searchFunction  res.data.status = " + res.data.status);
                console.log(" In searchFunction  res.data.message = " + res.data.message);
                setsearchFunction_api(res.data.status);
                setsearchFunction_result(res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message)

            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                setsearchFunction_api(res.data.status);
                setsearchFunction_message(res.data.message);
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            setsearchFunction_api("False");
            //setmyApimyApiMessage("")
        })
    }


    const currentTableData2 = useMemo(() => {
        if (result) {
            const firstPageIndex = (currentPage2 - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            setfin(1);
            return result.slice(firstPageIndex, lastPageIndex);
        }
        else {
            return [];
            setfin(0);
        }
    }, [currentPage2]);

    useEffect(() => {
        setdisplayAllClasses("true");
        async function chloadfunction() {
            if (document.getElementById('allelement')) {
                document.getElementById("allelement").checked = true;
            }
            // document.getElementById("SearchText").disabled = true;

        }
        chloadfunction();
        searchFunction();

        whosisconnected();

    }, []);

    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);

    const stored_part = getCookie('tokenmysypart');

    function whosisconnected() {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            // alert(" partner connected OK");
        }
    }

    function handleAccountLogout() {

        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const handleMyPartnerAccount = () => {
        setAccountMenu(null);
        history.push("/Partner")
    };

    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);
    function handleAccountConnexion_v2() {

        if (String(userconnected) === String("1")) {
            handleMyAccount();
        }
        else if (String(partnerconnected) === String("1")) {
            handleMyPartnerAccount();
        }
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_part) != "undefined") {

            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie + "_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });

            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });
        }
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };

    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/tarifs_produits_services/")
    };

    const handleQuisommesNousMenu = () => {
        setproduitMenu(null);
        history.push("/qui-sommes-nous/")
    };

    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    function publiecatalogue() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }

    const handleFtionMenu = (event) => {
        history.push("/")
    };

    return (
        <div style={{ width: "100%", float: "left" }}>
            <div className="recherchearticles_new_v2_style">
                <Helmet>


                    <title>Le blog, liste des artickes</title>
                    <meta name="description" content="Le blog, liste des artickes. Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, Gestion formateurs, Catalogue formations personnalisé. Nous sommes les moins cher du marché. Nous automatisons vos process : inscription, évaluation, émargement (qr-code),  QUALIOPI, Personnalisation des documents, Gestion formateurs, Catalogue personnalisé, bilan pédagogique et financier (PBF).  Nos options comme la signature électronique sont inclus dans le tarif de base." />
                    <meta name="robots" content="index,follow" />
                    <meta name="title" content="MySy Training, contactez-nous" />
                    <link rel="canonical" href={window.location.href} />


                </Helmet>

                <Header />

                <div className="div_mobile">

                    <div className="div_row block_entete" >
                        <div className="Background-header">

                            <div className="Title">



                                <div className="besoin_aide">
                                   Restez informé. Quelques articles pour vous.
                                    <div className="detail_text_aide">
                                        &nbsp;
                                    </div>
                                </div>

                                <div className="barre_recherche">
                                    <form onSubmit={handleSubmit(onSubmit)} className="div_005">
                                        <div class="form">

                                            <i class="fa fa-search"></i>

                                            <input
                                                type="text"
                                                class="form-control form-input"
                                                placeholder=" Texte (Titre, Description)"
                                                onChange={SearchTextHandle}
                                                id={"SearchText"}
                                                name={"SearchText"}
                                                style={{ "width": "80%", float: "left" }}
                                            />
                                            <nav style={{ "width": "15%", float: "left", paddingTop: "5px" }}>
                                                {SearchText.length > 2 && <span_clear> <BsFillXCircleFill onClick={clearsearchtext} /> </span_clear>} <span class="left-pan" >
                                                    &nbsp;<FaSearch onClick={searchFunction} /></span>
                                            </nav>


                                        </div>
                                    </form>
                                </div>

                            </div>


                        </div>



                    </div>
                    <div className="block_centrer">
                        {/*<div className="aricles_a_la_une">
                        <div className="img_article">
                            imgg
                        </div>

                        <div className="desc_article">
                            destail aricle
                        </div>


                    </div>*/}


                        <div className="liste_articles">

                            {/*result && myApiResponse === "True" && <div className="okUpdateData">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />


                        </div>*/}

                            {searchFunction_result && searchFunction_result.length > 0 && currentTableData2 && searchFunction_result.map((formation) => (
                                <div className="block_article">
                                    <Articles article={JSON.parse(formation)} key={JSON.parse(formation).title_formation} />
                                </div>

                            ))}


                            {/*result && <div>
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />

                        </div>
                        */}
                        </div>

                    </div>
                </div>


            </div>

            <div style={{ height: "5rem", width: "100%" }}>
                <Fotter_v3 />
            </div>

        </div>
    )
}

export default RechercheArticles_new_v2;