import Partner from "../components/Partner";
import Navigation from "../components/Navigation";

const recherche_draft = () => {

  return (
            <div>
              <div>
            <Navigation />
            </div>
            <Partner/>
        </div>

  )
}


export default recherche_draft;
