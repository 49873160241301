import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { Button, FormGroup, Label, Input } from "reactstrap";
import { useCookies } from "react-cookie";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Helmet } from "react-helmet";
import Footer from "./Fotter";

import Header from "./Header";
import { getCookie, removeCookie } from 'react-use-cookie';


function PartnerSignIn() {
    const [isconnected, setisconnected] = useState("");
    const [cookie_acces_right, setCookie_acces_right, removeCookie_acces_right] = useCookies(['mysy_user_access_right']);
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
    const [usercookie, setusercookie, removeusercookie] = useCookies(['tokenmysych']);
    const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);


    const url_login = process.env.REACT_APP_API_URL + "myclass/api/partner_login/";

    const {

        formState: { errors },

    } = useForm();
    const [result, setResult] = useState("");

    const history = useHistory();

    function logout_partner_confirmation() {
        setCookie("tokenmysypart", "", { path: '/' });
    }

    function Logout_Partenaire() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion partenaire',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_partner_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }


    function logout_user_confirmation() {
        removeCookie_part('tokenmysych', { path: '/' });
    }

    function Logout_User() {
        confirmAlert({
            title: 'Deconnexion utilisateur ?',
            message: 'Confirmez la deconnexion utilisateur',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_user_confirmation()
                },
                /* {
                     label: 'Non',
 
                 }*/
            ]
        });
    }

    const [onSubmit_api, setonSubmit_api] = useState();
    const [onSubmit_result, setonSubmit_result] = useState();
    const [onSubmit_message, setonSubmit_message] = useState();
    const onSubmit = async (data) => {
        const formData = new FormData();

        // Gestion des Cookies
        const stored_partner = cookie_part.tokenmysypart;
        const stored_user = cookie.tokenmysych;

        // si c'est une connexion partner qui est active
        if( String(userconnected) === String("1")){
            alert("Un compte utilisateur deja connecté");
            Logout_User();
        }

        if( String(partnerconnected) === String("1")){
            alert(" Un compté est deja connecté");
            Logout_Partenaire();
        }
        var mymail = document.getElementsByName("email_partner")[0].value;
        var mypwd = document.getElementsByName("pwd_partner")[0].value;
        //var mysecret = document.getElementsByName("secret_partner")[0].value;


        formData.append("email", mymail);
        formData.append("pwd", mypwd);
        //formData.append("secret", mysecret);

        fetch(
            url_login,
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                var val = result['status'];

                if (String(val) === String("true")) {
                    
                    setCookie("tokenmysypart", result['message'], { path: '/' });
                    var acces_right_token_name = String(JSON.parse(result['message']).token) + "_uar";
                    var acces_right_token_val = JSON.parse(result['message']).user_access_right;

                    //acces_right_token_val = [{'key1':'val1'}, {'key2':'val2'}];

                    console.log(" acces_right_token_val = ", acces_right_token_val);

                    setCookie_acces_right(acces_right_token_name, acces_right_token_val, { path: '/' });

                    history.push("/Partner");
                }
                else {
                    setisconnected("false");
                    setonSubmit_api("false");
                    setonSubmit_message(result['message']);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setisconnected("false");
                setonSubmit_api("false");
                    setonSubmit_message(" Impossible de se connecter à l'hôte distant. Merci de contacter votre administrateur");

            });
    }


    const onError = () => {
        //console.log("error");
    };


    const [userconnected, setuserconnected] = useState("0");
    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");

    const stored_user = getCookie('tokenmysych');
    const stored_part = getCookie('tokenmysypart');

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onSubmit();
            }
        };
        document.addEventListener("keydown", listener);

        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            //alert(" partner connected OK");
        }

    }, []);

    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };

    return (
        <div className="partnersignin">
            <Helmet>
                <title>Recherche articles formation</title>
                <meta name="description" content="Liste des articles sur les formations" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />
            <div className="div_mobile">
            <h1 className="text_welcome">Se connecter à MySy Training PRO</h1>
                <div className="display_screen_600_901">

                    <div className="div_centre">
                        <nav className="connect"> PARTENAIRE CONNEXION </nav>
                        <hr />


                        <FormGroup>
                            <Label>Email</Label>
                            <Input type="email" placeholder="Email" name="email_partner" />
                        </FormGroup>

                        <FormGroup>
                            <Label>Password</Label>
                            <Input type="password" placeholder="Password" name="pwd_partner" />
                        </FormGroup>


                        <div className="div_row22">
                            <Button variant="contained" color="success" className="div_row22 btn_login button" onClick={onSubmit}>Connectez-Vous</Button>
                        </div>


                    </div>
                    {String(onSubmit_api) === "false" && <div className="erreurconnexion">
                        <br />
                        Erreur Connexion  : {onSubmit_message} </div>
                    }


                </div>
                <div className="pieddepage">
                    <Footer />
                </div>

            </div>
        </div>
    );
}

export default PartnerSignIn;
