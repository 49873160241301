import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { useParams } from 'react-router-dom';
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import {
    DataGrid, frFR, GridToolbar,

} from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Autocomplete from '@mui/material/Autocomplete';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { gridClasses } from '@mui/x-data-grid';
import {
    GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton,
    GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridValueGetterParams,
    GridPreferencePanelsValue,

} from '@mui/x-data-grid';

//import { utils, writeFileXLSX } from "xlsx";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import zIndex from "@material-ui/core/styles/zIndex";
import { FcOpenedFolder } from "react-icons/fc";
import Module_Historique_Action from "./Module_Historique_Action";
import { GridExportCsvOptions } from '@mui/x-data-grid';


const Partner_Facture = (props) => {

    const { action, orderid } = useParams();


    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [rowss_total_data, setRows_total_data] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_invoice_lines, setrows_invoice_lines] = useState([]);
    const [selectionModel_invoice_lines, setselectionModel_invoice_lines] = React.useState([]);
    const [gridline_id, setgridline_id] = useState("");
    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState();

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }



    const columns = [
        { field: '_id', headerName: '_id', hide: true, disableExport: true, },
        { field: 'id', headerName: 'id', hide: true, disableExport: true },
        { field: 'invoice_header_ref_interne', headerName: 'Num Facture', minWidth: 200, flex: 1, hide: false, editable: false, },
        { field: 'order_header_ref_interne', headerName: 'Reference Cmd', minWidth: 200, flex: 1, hide: false, editable: false },
        { field: 'invoice_header_type', headerName: 'Type', width: 100, hide: false, editable: false },
        { field: 'invoice_date', headerName: 'Date Facture', width: 150, hide: false, editable: false },

        { field: 'order_header_client_id', headerName: 'Client_Id', width: 150, hide: true, editable: false, disableExport: true },
        { field: 'order_header_client_nom', headerName: 'Nom Client', minWidth: 150, flex: 1 },
        { field: 'order_header_vendeur_id', headerName: 'Vendeur Id', width: 0, hide: true, editable: false, disableExport: true },
        { field: 'order_header_vendeur_nom_prenom', headerName: 'Vendeur', width: 150, flex: 1, editable: false },
        { field: 'order_header_date_cmd', headerName: 'Date Cmd', width: 150, hide: true, editable: false },
        { field: 'total_header_hors_taxe_after_header_reduction', headerName: 'Montant HT', width: 150, hide: false, editable: false },
        { field: 'order_header_tax_amount', headerName: 'Taxe', width: 150, hide: false, editable: false },
        { field: 'total_header_toutes_taxes', headerName: 'Montant TTC', width: 150, hide: false, editable: false },
        { field: 'reste_to_paye', headerName: 'A payer', width: 150, hide: false, editable: false },
        { field: 'credit_note_ref', headerName: 'Ref. Avoir', width: 150, hide: true, editable: false, },




        /* {
             field: "Detail", headerName: 'Voir detail',
             renderCell: (cellValues) => {
                 return (
 
                     <Button
 
                         onClick={(event) => {
                             Display_Detail_Employe(event, cellValues);
                         }}
                     >
                         <FcViewDetails />
 
                     </Button>
 
                 );
             }
         },*/
        {/*
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            //handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
            */
        }

    ]


    const columns_invoice_lines = [
        { field: '_id', headerName: '_id', hide: true, editable: false },
        { field: 'id', headerName: 'id', hide: true, editable: false },
        { field: 'order_line_formation', headerName: 'intenal_url', width: 0, hide: true, editable: false },
        {
            field: 'order_line_formation_title', headerName: 'Titre', width: 250, hide: false, editable: false, flex: 1,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        <nav>

                            <Tooltip className="tooltip_css" id="affiche_article_01" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="affiche_article_01" data-tooltip-html="Détails Formation">
                                <FcOpenedFolder style={{ fontSize: "10px" }}
                                    onClick={(event) => {
                                        if (cellValues.row.order_line_formation && cellValues.row.order_line_formation_external_code &&
                                            String(cellValues.row.order_line_formation).length > 2 && cellValues.row.order_line_type_article === "formation") {
                                            window.open(
                                                process.env.REACT_APP_FRONT_URL + "Partner/affichage/" + String(cellValues.row.order_line_formation_external_code) + "/" + String(cellValues.row.order_line_formation),
                                                '_blank'
                                            );
                                        } else if (cellValues.row.order_line_formation && cellValues.row.order_line_formation_external_code &&
                                            String(cellValues.row.order_line_formation).length > 2 && cellValues.row.order_line_type_article === "produit") {
                                            window.open(
                                                process.env.REACT_APP_FRONT_URL + "Partner/part_produit_service/" + String(cellValues.row.order_line_formation),
                                                '_blank'
                                            );
                                        }
                                    }
                                    } />
                            </a>  &nbsp; &nbsp; {cellValues.row.order_line_formation_title}
                        </nav>
                    </div>
                );
            }
        },
        { field: 'order_line_formation_external_code', headerName: 'Code Formation', width: 150, hide: false, editable: false },
        { field: 'order_line_formation_duration', headerName: 'Durée', width: 80, hide: false, editable: false },

        { field: 'order_line_qty', headerName: 'Qte', width: 100, hide: false, editable: false },
        { field: 'order_line_prix_unitaire', headerName: 'Prix Unit.', width: 100, hide: false, editable: false },

        { field: 'order_line_montant_hors_taxes', headerName: 'Total HT', width: 100, hide: false, editable: false },

        { field: 'order_header_id', headerName: 'Id_Entete', hide: true, editable: false },
        { field: 'order_header_ref_interne', headerName: 'Ref_Interne', hide: true, editable: false },
        { field: 'order_line_type', headerName: 'Type', width: 100, hide: false, editable: false },
        { field: 'order_line_type_article', headerName: 'Type Article', width: 100, hide: true, editable: false },

        {
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Display_Detail_Invoice_Line(event, cellValues);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {/*
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ligne sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            //handleClick_delete_Order_line(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
            */
        },
        { field: 'order_line_comment', headerName: 'Commentaire', width: 150, hide: true, editable: false, },

    ]

    const columns_paiement = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'invoice_id', headerName: 'invoice_id', width: 0, hide: true, editable: false },
        { field: 'paiement_date', headerName: 'Date', minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'paiement_ref', headerName: 'Réference', minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'paiement_amount', headerName: 'Montant', minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'paiement_mode', headerName: 'Mode', minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le paiement sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Paiement(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]

    const [handleClick_delete_Paiement_api, sethandleClick_delete_Paiement_api] = useState();
    const [handleClick_delete_Paiement_message, sethandleClick_delete_Paiement_message] = useState();
    const [handleClick_delete_Paiement_result, sethandleClick_delete_Paiement_result] = useState();
    function handleClick_delete_Paiement(event, cellValues) {

        var paiement_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", paiement_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Invoice_Paiement/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Paiement  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Paiement  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Paiement_api("true");
                sethandleClick_delete_Paiement_result(res.data.message);

                Getall_Invoice_Paiement();
                Getall_Invoice_Paiement_Payed_Remain_Amounts();
                clear_Paiement_DetailFields()

                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Paiement_api("false");
                sethandleClick_delete_Paiement_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Paiement = ', error);
            sethandleClick_delete_Paiement_api("false");
            alert(" Impossible de supprimer le paiement");
            //setmyApimyApiMessage("")
        })
    }


    const [selectionModel_paiement, setselectionModel_paiement] = React.useState([]);

    const [gridline_paiment_id, setgridline_paiment_id] = useState("");

    const [selected_invoice_line_id, setselected_invoice_line_id] = useState();
    const [p_add_line_item, setp_add_line_item] = useState();

    const [p_add_line_item_type_article, setp_add_line_item_type_article] = useState("");

    const [p_add_line_item_internal_url, setp_add_line_item_internal_url] = useState();
    const [p_add_line_item_title, setp_add_line_item_title] = useState();
    const [p_add_line_item_qty, setp_add_line_item_qty] = useState();
    const [p_add_line_item_price, setp_add_line_item_price] = useState();
    const [p_add_line_item_comment, setp_add_line_item_comment] = useState();
    const [p_add_line_item_status, setp_add_line_item_status] = useState();
    const [p_add_line_item_type, setp_add_line_item_type] = useState();

    const [p_add_line_item_type_reduction, setp_add_line_item_type_reduction] = useState("fixe");
    const [p_add_line_item_reduction_value, setp_add_line_item_reduction_value] = useState();
    const [p_add_line_item_reduction_total_amount, setp_add_line_item_reduction_total_amount] = useState();
    const [p_add_line_item_total_amount_ht, setp_add_line_item_total_amount_ht] = useState();
    const [p_add_line_item_taxe, setp_add_line_item_taxe] = useState();
    const [p_add_line_item_taxe_amount, setp_add_line_item_taxe_amount] = useState();
    const [p_add_line_item_total_amount_ttc, setp_add_line_item_total_amount_ttc] = useState();
    const [p_add_line_item_duration_concat, setp_add_line_item_duration_concat] = useState();

    const [p_add_line_item_type_apprenant, setp_add_line_item_type_apprenant] = useState("");



    const [New_Getall_Invoice_Paiement_result, setNew_Getall_Invoice_Paiement_result] = useState([]);

    const [Getall_Invoice_Paiement_api, setGetall_Invoice_Paiement_api] = useState();
    const [Getall_Invoice_Paiement_message, setGetall_Invoice_Paiement_message] = useState();
    const [Getall_Invoice_Paiement_result, setGetall_Invoice_Paiement_result] = useState();
    function Getall_Invoice_Paiement(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Invoice_Liste_Payement/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Invoice_Paiement  res.data.status = " + res.data.status);
                console.log(" In Getall_Invoice_Paiement  res.data.message r_class = " + res.data.message);
                setGetall_Invoice_Paiement_api("true");
                setGetall_Invoice_Paiement_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_paiement_ref = JSON.parse(x).paiement_ref;
                    var local_paiement_amount = JSON.parse(x).paiement_amount;
                    var local_paiement_mode = JSON.parse(x).paiement_mode;
                    var local_paiement_date = JSON.parse(x).paiement_date;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_paiement_ref + " " + local_paiement_amount,
                        "paiement_amount": local_paiement_amount,
                        "paiement_mode": local_paiement_mode,
                        "paiement_date": local_paiement_date
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Invoice_Paiement_result(new_data2);

            }
            else {
                setGetall_Invoice_Paiement_api("false");
                setGetall_Invoice_Paiement_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Invoice_Paiement = ', error);
            setGetall_Invoice_Paiement_api("false");
            alert(" Impossible de recuperer la liste des paiements");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result, setNew_Getall_Invoice_Paiement_Payed_Remain_Amounts_result] = useState([]);

    const [Getall_Invoice_Paiement_Payed_Remain_Amounts_api, setGetall_Invoice_Paiement_Payed_Remain_Amounts_api] = useState();
    const [Getall_Invoice_Paiement_Payed_Remain_Amounts_message, setGetall_Invoice_Paiement_Payed_Remain_Amounts_message] = useState();
    const [Getall_Invoice_Paiement_Payed_Remain_Amounts_result, setGetall_Invoice_Paiement_Payed_Remain_Amounts_result] = useState();
    function Getall_Invoice_Paiement_Payed_Remain_Amounts(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Invoice_Total_Amount_Payed_And_Remaining_Amount/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Invoice_Paiement_Payed_Remain_Amounts  res.data.status = " + res.data.status);
                console.log(" In Getall_Invoice_Paiement_Payed_Remain_Amounts  res.data.message r_class = " + res.data.message);
                setGetall_Invoice_Paiement_Payed_Remain_Amounts_api("true");
                setGetall_Invoice_Paiement_Payed_Remain_Amounts_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    var local_total_amount = JSON.parse(x).total_amount;
                    var local_payed_amount = JSON.parse(x).payed_amount;
                    var local_remaining_amount = JSON.parse(x).remaining_amount;



                    //---
                    var node = {
                        "total_amount": local_total_amount,
                        "payed_amount": local_payed_amount,
                        "remaining_amount": local_remaining_amount,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0) {
                    setNew_Getall_Invoice_Paiement_Payed_Remain_Amounts_result(new_data2);
                    // console.log(" ### new_data2 = ", new_data2)
                }

            }
            else {
                setGetall_Invoice_Paiement_Payed_Remain_Amounts_api("false");
                setGetall_Invoice_Paiement_Payed_Remain_Amounts_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Invoice_Paiement_Payed_Remain_Amounts = ', error);
            setGetall_Invoice_Paiement_Payed_Remain_Amounts_api("false");
            alert(" Impossible de recuperer les montants de la facture");
            //setmyApimyApiMessage("")
        })
    }

    function Display_Detail_Invoice_Line(event, cellValues) {

        var invoice_line_id = String(cellValues.row._id);

        var order_line_formation = String(cellValues.row.order_line_formation);
        var order_line_qty = String(cellValues.row.order_line_qty);
        var order_line_prix_unitaire = String(cellValues.row.order_line_prix_unitaire);

        setselected_invoice_line_id(invoice_line_id);

        setp_add_line_item(order_line_formation);
        setp_add_line_item_qty(order_line_qty);
        setp_add_line_item_price(order_line_prix_unitaire);
        setp_add_line_item_status(String(cellValues.row.order_line_status));
        setp_add_line_item_type(String(cellValues.row.order_line_type));
        setp_add_line_item_comment(String(cellValues.row.order_line_comment));


        Get_Given_Line_Of_Partner_Invoice_Line_Data(invoice_line_id, invoice_internal_ref);


        setDialog_Detail_Article_open(true);

        //alert(" afficher le detail de la ligne " + String(order_line_id));

    }

    const [Get_Given_Line_Of_Partner_Invoice_Line_Data_api, setGet_Given_Line_Of_Partner_Invoice_Line_Data_api] = useState();
    const [Get_Given_Line_Of_Partner_Invoice_Line_Data_message, setGet_Given_Line_Of_Partner_Invoice_Line_Data_message] = useState();
    const [Get_Given_Line_Of_Partner_Invoice_Line_Data_result, setGet_Given_Line_Of_Partner_Invoice_Line_Data_result] = useState();
    function Get_Given_Line_Of_Partner_Invoice_Line_Data(local_invoice_line_id, local_invoice_header_ref_interne) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_line_id", local_invoice_line_id);
        form.append("invoice_header_ref_interne", local_invoice_header_ref_interne);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Line_Of_Partner_Invoice_Lines/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Given_Line_Of_Partner_Invoice_Line_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Line_Of_Partner_Invoice_Line_Data  res.data.message r_class = " + res.data.message);
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_api("true");
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_order = JSON.parse(res.data.message);


                    if (mylocal_order.title) {
                        setp_add_line_item(mylocal_order.title);
                    }

                    if (mylocal_order.order_line_type_article) {
                        setp_add_line_item_type_article(mylocal_order.order_line_type_article);
                    } else {
                        setp_add_line_item_type_article("");
                    }


                    if (mylocal_order.order_line_qty) {
                        setp_add_line_item_qty(mylocal_order.order_line_qty);
                    }



                    if (mylocal_order.order_line_prix_unitaire) {
                        setp_add_line_item_price(mylocal_order.order_line_prix_unitaire);
                    }


                    /*if (mylocal_order.order_header_id) {
                        setp_detail_header_type_reduction(mylocal_order.order_header_id);
                    }

                 
                    if (mylocal_order.order_header_ref_interne) {
                        setp_detail_header_type_reduction_valeur(mylocal_order.order_header_ref_interne);
                    }*/


                    if (mylocal_order.order_line_montant_reduction) {
                        setp_add_line_item_reduction_total_amount(mylocal_order.order_line_montant_reduction);
                    }



                    if (mylocal_order.order_line_montant_toutes_taxes) {
                        setp_add_line_item_total_amount_ttc(mylocal_order.order_line_montant_toutes_taxes);

                    }


                    if (mylocal_order.order_line_tax) {
                        setp_add_line_item_taxe(mylocal_order.order_line_tax);
                    }


                    if (mylocal_order.order_line_tax_amount) {
                        setp_add_line_item_taxe_amount(mylocal_order.order_line_tax_amount);
                    } else {
                        setp_add_line_item_taxe_amount("");
                    }

                    if (mylocal_order.order_line_type_apprenant) {
                        setp_add_line_item_type_apprenant(mylocal_order.order_line_type_apprenant);
                    } else {
                        setp_add_line_item_type_apprenant("");
                    }


                    if (mylocal_order.order_line_type_reduction) {
                        setp_add_line_item_type_reduction(mylocal_order.order_line_type_reduction);
                    } else {
                        setp_add_line_item_type_reduction("");
                    }


                    if (mylocal_order.order_line_type_valeur) {
                        setp_add_line_item_reduction_value(mylocal_order.order_line_type_valeur);
                    } else {
                        setp_add_line_item_reduction_value("");
                    }


                    if (mylocal_order.order_line_type) {
                        setp_add_line_item_type(mylocal_order.order_line_type);
                    } else {
                        setp_add_line_item_type("");
                    }


                    if (mylocal_order.order_line_status) {
                        setp_add_line_item_status(mylocal_order.order_line_status);
                    } else {
                        setp_add_line_item_status("");
                    }


                    if (mylocal_order.order_line_comment) {
                        setp_add_line_item_comment(mylocal_order.order_line_comment);
                    } else {
                        setp_add_line_item_comment("");
                    }


                    if (mylocal_order.title) {
                        setp_add_line_item_title(mylocal_order.title);
                    } else {
                        setp_add_line_item_title("");
                    }

                    if (mylocal_order.order_line_montant_hors_taxes) {
                        setp_add_line_item_total_amount_ht(mylocal_order.order_line_montant_hors_taxes);

                    } else {
                        setp_add_line_item_total_amount_ht("");
                    }


                    /*if (mylocal_order.domaine) {
                        setp_detail_header_montant_reduction(mylocal_order.domaine);
                    }*/


                    if (mylocal_order.duration_concat) {
                        setp_add_line_item_duration_concat(mylocal_order.duration_concat);
                    } else {
                        setp_add_line_item_duration_concat("");
                    }


                    ;

                } else {
                    alert(" Aucune ligne de facture recuperée");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_api("false");
                setGet_Given_Line_Of_Partner_Invoice_Line_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Line_Of_Partner_Invoice_Line_Data = ', error);
            setGet_Given_Line_Of_Partner_Invoice_Line_Data_api("false");
            alert(" Impossible de recuperer les données de la ligne de facture");
            //setmyApimyApiMessage("")
        })
    }


    const [isLoading, setLoading] = useState();

    const [p_detail_header_client_nom, setp_detail_header_client_nom] = useState();
    const [p_detail_header_email_client, setp_detail_header_email_client] = useState();
    const [p_detail_header_client_nom_id, setp_detail_header_client_nom_id] = useState();

    const [p_detail_header_description, setp_detail_header_description] = useState();
    const [p_detail_header_comment, setp_detail_header_comment] = useState();
    const [p_detail_header_date_cmd, setp_detail_header_date_cmd] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_detail_header_date_expiration, setp_detail_header_date_expiration] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_detail_header_condition_paiement_id, setp_detail_header_condition_paiement_id] = useState("");
    const [p_detail_header_condition_paiement_code, setp_detail_header_condition_paiement_code] = useState("");

    const [p_detail_header_ref_client, setp_detail_header_ref_client] = useState();
    const [p_detail_header_ref_interne, setp_detail_header_ref_interne] = useState();

    const [p_detail_header_vendeur_nom, setp_detail_header_vendeur_nom] = useState();
    const [p_detail_header_vendeur_nom_id, setp_detail_header_vendeur_nom_id] = useState();


    const [p_detail_header_invoice_header_annotation, setp_detail_header_invoice_header_annotation] = useState("");

    const [p_detail_header_invoice_header_ref_interne, setp_detail_header_invoice_header_ref_interne] = useState();
    const [p_detail_header_invoice_header_type, setp_detail_header_invoice_header_type] = useState();
    const [p_detail_header_invoice_date, setp_detail_header_invoice_date] = useState();


    const [p_detail_header_type, setp_detail_header_type] = useState();
    const [p_detail_header_statut, setp_detail_header_statut] = useState();
    const [p_detail_header_vendeur_id, setp_detail_header_vendeur_id] = useState();
    const [p_detail_header_client_id, setp_detail_header_client_id] = useState();
    const [p_detail_header_statut_label, setp_detail_header_statut_label] = useState();

    const [p_detail_header_date_cmd_val, setp_detail_header_date_cmd_val] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_detail_header_date_expiration_val, setp_detail_header_date_expiration_val] = useState(date_today_90j.toLocaleDateString('fr-FR'));

    const [p_detail_header_condition_paiement, setp_detail_header_condition_paiement] = useState();
    const [p_detail_header_condition_paiement_desc, setp_detail_header_condition_paiement_desc] = useState();
    const [p_detail_header_date_echeance, setp_detail_header_date_echeance] = useState();

    const [p_detail_header_fact_adr, setp_detail_header_fact_adr] = useState();
    const [p_detail_header_fact_code_postal, setp_detail_header_fact_code_postal] = useState();
    const [p_detail_header_fact_ville, setp_detail_header_fact_ville] = useState();
    const [p_detail_header_fact_pays, setp_detail_header_fact_pays] = useState();


    const [p_detail_header_liv_adr, setp_detail_header_liv_adr] = useState();
    const [p_detail_header_liv_code_postal, setp_detail_header_liv_code_postal] = useState();
    const [p_detail_header_liv_ville, sep_detail_header_liv_ville] = useState();
    const [p_detail_header_liv_pays, setp_detail_header_liv_pays] = useState();
    const [p_detail_header_total_ht, setp_detail_header_total_ht] = useState();
    const [p_detail_header_total_tax, setp_detail_header_total_tax] = useState();
    const [p_detail_header_total_ttc, setp_detail_header_total_ttc] = useState();
    const [p_detail_header_type_reduction, setp_detail_header_type_reduction] = useState();
    const [p_detail_header_total_reduction_amount, setp_detail_header_total_reduction_amount] = useState();


    const [p_detail_header_credit_note_ref, setp_detail_header_credit_note_ref] = useState();

    const [p_detail_header_type_reduction_valeur, setp_detail_header_type_reduction_valeur] = useState();
    const [p_detail_header_montant_reduction, setp_detail_header_montant_reduction] = useState();
    const [p_detail_total_header_hors_taxe_before_header_reduction, setp_detail_total_header_hors_taxe_before_header_reduction] = useState();
    const [p_detail_total_header_hors_taxe_after_header_reduction, setp_detail_total_header_hors_taxe_after_header_reduction] = useState();
    const [p_detail_order_header_tax_amount, setp_detail_order_header_tax_amount] = useState();
    const [p_detail_total_header_toutes_taxes, setp_detail_total_header_toutes_taxes] = useState();



    const [p_detail_total_lines_hors_taxe_before_lines_reduction, setp_detail_total_lines_hors_taxe_before_lines_reduction] = useState();
    const [p_detail_total_lines_hors_taxe_after_lines_reduction, setp_detail_total_lines_hors_taxe_after_lines_reduction] = useState();

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }




    const [Getall_Parter_Invoice_No_Filter_api, setGetall_Parter_Invoice_No_Filter_api] = useState();
    const [Getall_Parter_Invoice_No_Filter_message, setGetall_Parter_Invoice_No_Filter_message] = useState();
    const [Getall_Parter_Invoice_No_Filter_result, setGetall_Parter_Invoice_No_Filter_result] = useState([]);
    function Getall_Parter_Invoice_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Invoice_no_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Invoice_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Invoice_No_Filter  res.data.message r_class = " + res.data.message);
                console.log(" In Getall_Parter_Invoice_No_Filter  res.data.total_data r_class = ", res.data.total_data);
                setGetall_Parter_Invoice_No_Filter_api("true");
                setGetall_Parter_Invoice_No_Filter_result(res.data.message);
                setRows(res.data.message);
                setRows_total_data(JSON.parse(res.data.total_data));
            }
            else {
                setGetall_Parter_Invoice_No_Filter_api("false");
                setGetall_Parter_Invoice_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Invoice_No_Filter = ', error);
            setGetall_Parter_Invoice_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des factures");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Parter_Invoice_With_Filter_On_Client_Id_api, setGetall_Parter_Invoice_With_Filter_On_Client_Id_api] = useState();
    const [Getall_Parter_Invoice_With_Filter_On_Client_Id_message, setGetall_Parter_Invoice_With_Filter_On_Client_Id_message] = useState();
    const [Getall_Parter_Invoice_With_Filter_On_Client_Id_result, setGetall_Parter_Invoice_With_Filter_On_Client_Id_result] = useState();
    function Getall_Parter_Invoice_With_Filter_On_Client_Id(local_client_id) {

        setgridline_id("");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("order_header_client_id", local_client_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Invoice_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Invoice_With_Filter_On_Client_Id  res.data.status = " + res.data.status);
                // console.log(" In Getall_Parter_Invoice_With_Filter_On_Client_Id  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Invoice_With_Filter_On_Client_Id_api("true");
                setGetall_Parter_Invoice_With_Filter_On_Client_Id_result(res.data.message);
                setRows(res.data.message);
                setRows_total_data(JSON.parse(res.data.total_data));
            }
            else {
                setGetall_Parter_Invoice_With_Filter_On_Client_Id_api("false");
                setGetall_Parter_Invoice_With_Filter_On_Client_Id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Invoice_With_Filter_On_Client_Id = ', error);
            setGetall_Parter_Invoice_With_Filter_On_Client_Id_api("false");
            alert(" Impossible de recuperer la liste des factures");
            //setm  yApimyApiMessage("")
        })
    }

    const myRef = useRef(null);

    const [history_securite_read, sethistory_securite_read] = useState("");

    useEffect(() => {

        sethistory_securite_read(props.check_user_acces_right("historique", "read"))
        Get_List_Partner_Clients();


        if (orderid && String(orderid).length > 3) {

            /* 
           Dans le cas précis des produits et service, "props.class_external_code" est utilisé comme
           l'_id du client*/

            Getall_Parter_Invoice_With_Filter_On_Client_Id(orderid);
            //console.log(" ~### mes facture props action, orderid = ", action, orderid);

        } else {
            Getall_Parter_Invoice_No_Filter();

        }


        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }



    }, [])

    const [display_detail_invoice, setdisplay_detail_invoice] = React.useState();

    const myRef_head = useRef(null);
    const [selected_id, setselected_id] = useState("");
    const [selected_order_line_id, setselected_order_line_id] = useState("");
    const [selected_order_header_type, setselected_order_header_type] = useState();

    const [order_internal_ref, setorder_internal_ref] = useState("");
    const [invoice_internal_ref, setinvoice_internal_ref] = useState("");

    function handleClick_edit_invoice_From_Line(selected_row_id) {

        //submenu_detail_employe();

        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var invoice_id = line._id;
        setselected_id(invoice_id);

        var order_ref = line.order_header_ref_interne;
        setorder_internal_ref(order_ref);

        var invoice_ref = line.invoice_header_ref_interne;
        setinvoice_internal_ref(invoice_ref);



        //console.log(" ### employe_id  = ", employe_id);
        Display_Detail_Invoice_data(invoice_id);


        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
        setp_detail_paiement_id("");
        order_header_submenu_main();

    }


    function Display_Detail_Invoice_data(invoice_id) {
        //clearDetailAttendeFields();

        setdisplay_detail_invoice("1");


        setLoading(true);
        Get_Invoice_Data(invoice_id);
        Get_Partner_Invoice_Lines_from_header_id(invoice_id);

    }


    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Invoice_Data(invoice_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", invoice_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Invoice/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In Get_Invoice_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Invoice_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_order = JSON.parse(res.data.message);


                    if (mylocal_order.invoice_header_ref_interne)
                        setp_detail_header_invoice_header_ref_interne(mylocal_order.invoice_header_ref_interne);


                    if (mylocal_order.invoice_header_type)
                        setp_detail_header_invoice_header_type(mylocal_order.invoice_header_type);
                    else {
                        setp_detail_header_invoice_header_type("");
                    }


                    if (mylocal_order.invoice_date)
                        setp_detail_header_invoice_date(mylocal_order.invoice_date);

                    if (mylocal_order.annotation)
                        setp_detail_header_invoice_header_annotation(mylocal_order.annotation);
                    else
                        setp_detail_header_invoice_header_annotation("");



                    //document.getElementsByName("detail_header_type")[0].value = "";
                    if (mylocal_order.order_header_type)
                        setp_detail_header_type(mylocal_order.order_header_type);



                    //document.getElementsByName("detail_header_ref_interne")[0].value = "";
                    if (mylocal_order.order_header_ref_interne) {
                        setp_detail_header_ref_interne(mylocal_order.order_header_ref_interne);
                    }

                    //document.getElementsByName("detail_header_ref_cient")[0].value = "";
                    if (mylocal_order.order_header_ref_client) {
                        setp_detail_header_ref_interne(mylocal_order.order_header_ref_client);
                    }


                    //document.getElementsByName("detail_header_date_cmd")[0].value = "";
                    if (mylocal_order.order_header_date_cmd) {
                        setp_detail_header_date_cmd(mylocal_order.order_header_date_cmd);
                        setp_detail_header_date_cmd_val(mylocal_order.order_header_date_cmd);
                    }

                    //document.getElementsByName("detail_header_date_expiration")[0].value = "";
                    if (mylocal_order.order_header_date_expiration) {
                        setp_detail_header_date_expiration(mylocal_order.order_header_date_expiration);
                        setp_detail_header_date_expiration_val(mylocal_order.order_header_date_cmd);
                    }

                    if (mylocal_order.order_header_paiement_condition_code) {
                        setp_detail_header_condition_paiement_code(mylocal_order.order_header_paiement_condition_code);
                    }

                    if (mylocal_order.order_header_paiement_condition_id) {
                        setp_detail_header_condition_paiement_id(mylocal_order.order_header_paiement_condition_id);
                    }


                    if (mylocal_order.credit_note_ref) {
                        setp_detail_header_credit_note_ref(mylocal_order.credit_note_ref);

                    } else {
                        setp_detail_header_credit_note_ref("");
                    }



                    //document.getElementsByName("detail_nom_client")[0].value = "";
                    if (mylocal_order.order_header_client_nom) {
                        setp_detail_header_client_nom(mylocal_order.order_header_client_nom);
                        setp_detail_header_client_nom_id(mylocal_order.order_header_client_id);
                    }


                    if (mylocal_order.order_header_email_client) {
                        setp_detail_header_email_client(mylocal_order.order_header_email_client);
                    }


                    //document.getElementsByName("detail_header_description")[0].value = "";
                    if (mylocal_order.order_header_description) {
                        setp_detail_header_description(mylocal_order.order_header_description);
                    }

                    //document.getElementsByName("detail_header_comment")[0].value = "";
                    if (mylocal_order.order_header_comment) {
                        setp_detail_header_comment(mylocal_order.order_header_comment);
                    }


                    // document.getElementsByName("detail_header_vendeur_nom_prenom")[0].value = "";
                    if (mylocal_order.order_header_vendeur_nom_prenom) {
                        setp_detail_header_vendeur_nom(mylocal_order.order_header_vendeur_nom_prenom);
                        setp_detail_header_vendeur_nom_id(mylocal_order.order_header_vendeur_id);
                    }


                    // Recuperation des adresses (livraison / lieu execution - Facturation ) 
                    //document.getElementsByName("detail_header_fact_adresse")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_adresse) {
                        setp_detail_header_fact_adr(mylocal_order.order_header_adr_fact_adresse);
                    }

                    //document.getElementsByName("detail_header_fact_code_postal")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_code_postal) {
                        setp_detail_header_fact_code_postal(mylocal_order.order_header_adr_fact_code_postal);
                    }

                    //document.getElementsByName("detail_header_fact_ville")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_ville) {
                        setp_detail_header_fact_ville(mylocal_order.order_header_adr_fact_ville);
                    }

                    //document.getElementsByName("detail_header_fact_pays")[0].value = "";
                    if (mylocal_order.order_header_adr_fact_pays) {
                        setp_detail_header_fact_pays(mylocal_order.order_header_adr_fact_pays);
                    }

                    //document.getElementsByName("detail_header_liv_adresse")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_adresse) {
                        setp_detail_header_liv_adr(mylocal_order.order_header_adr_liv_adresse);
                    }

                    //document.getElementsByName("detail_header_liv_code_postal")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_code_postal) {
                        setp_detail_header_liv_code_postal(mylocal_order.order_header_adr_liv_code_postal);
                    }

                    if (mylocal_order.invoice_date_echeance) {
                        setp_detail_header_date_echeance(mylocal_order.invoice_date_echeance);
                    } else {
                        setp_detail_header_date_echeance("");
                    }

                    if (mylocal_order.order_header_condition_paiement_code) {
                        setp_detail_header_condition_paiement(mylocal_order.order_header_condition_paiement_code);
                    } else {
                        setp_detail_header_condition_paiement("");
                    }

                    if (mylocal_order.order_header_condition_paiement_description) {
                        setp_detail_header_condition_paiement_desc(mylocal_order.order_header_condition_paiement_description);
                    } else {
                        setp_detail_header_condition_paiement_desc("");
                    }



                    //document.getElementsByName("detail_header_liv_ville")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_ville) {
                        sep_detail_header_liv_ville(mylocal_order.order_header_adr_liv_ville);
                    }

                    //document.getElementsByName("detail_header_liv_pays")[0].value = "";
                    if (mylocal_order.order_header_adr_liv_pays) {
                        setp_detail_header_liv_pays(mylocal_order.order_header_adr_liv_pays);
                    }


                    // Recuperation des montant, taxes et autres reductions
                    //document.getElementsByName("detail_header_total_ht")[0].value = "";
                    if (mylocal_order.order_header_total_ht) {
                        setp_detail_header_total_ht(mylocal_order.order_header_total_ht);
                    }

                    //document.getElementsByName("detail_header_total_tax")[0].value = "";
                    if (mylocal_order.order_header_tax_amount) {
                        setp_detail_header_total_tax(mylocal_order.order_header_tax_amount);
                    }


                    //document.getElementsByName("detail_header_total_ttc")[0].value = "";
                    if (mylocal_order.order_header_total_ttc) {
                        setp_detail_header_total_ttc(mylocal_order.order_header_total_ttc);
                    }

                    //document.getElementsByName("detail_header_type_reduction")[0].value = "";
                    if (mylocal_order.order_header_type_reduction) {
                        setp_detail_header_type_reduction(mylocal_order.order_header_type_reduction);
                    }

                    //document.getElementsByName("detail_header_type_reduction_valeur")[0].value = "";
                    if (mylocal_order.order_header_type_reduction_valeur) {
                        setp_detail_header_type_reduction_valeur(mylocal_order.order_header_type_reduction_valeur);
                    }


                    if (mylocal_order.order_header_montant_reduction) {
                        setp_detail_header_total_reduction_amount(mylocal_order.order_header_montant_reduction);
                    }


                    if (mylocal_order.total_lines_montant_reduction) {
                        setp_detail_header_montant_reduction(mylocal_order.total_lines_montant_reduction);
                    }

                    if (mylocal_order.total_header_hors_taxe_before_header_reduction) {
                        setp_detail_total_header_hors_taxe_before_header_reduction(mylocal_order.total_header_hors_taxe_before_header_reduction);
                    }

                    if (mylocal_order.total_header_hors_taxe_after_header_reduction) {
                        setp_detail_total_header_hors_taxe_after_header_reduction(mylocal_order.total_header_hors_taxe_after_header_reduction);
                    }

                    if (mylocal_order.order_header_tax_amount) {
                        setp_detail_order_header_tax_amount(mylocal_order.order_header_tax_amount);
                    }


                    if (mylocal_order.total_header_toutes_taxes) {
                        setp_detail_total_header_toutes_taxes(mylocal_order.total_header_toutes_taxes);
                    }

                    if (mylocal_order.total_lines_hors_taxe_before_lines_reduction) {
                        setp_detail_total_lines_hors_taxe_before_lines_reduction(mylocal_order.total_lines_hors_taxe_before_lines_reduction);
                    }

                    if (mylocal_order.total_lines_hors_taxe_after_lines_reduction) {
                        setp_detail_total_lines_hors_taxe_after_lines_reduction(mylocal_order.total_lines_hors_taxe_after_lines_reduction);
                    }



                    disable_Order_Header_DetailFields();
                    //order_header_submenu_main();

                } else {
                    alert(" Aucune commande/devis recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Invoice_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de la facture");
            //setmyApimyApiMessage("")
        })
    }

    const [header_submenu, setheader_submenu] = useState("");
    async function order_header_submenu_main() {
        setheader_submenu("main");

        await sleep(5);

        submenu_color_management("main");

        /*  if (document.getElementById("order_header_main")) {
              document.getElementById("order_header_main").style.backgroundColor = "#104277";
              document.getElementById("order_header_main").style.color = "white";
          }
  
          if (document.getElementById("order_header_detail")) {
              document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
              document.getElementById("order_header_detail").style.color = "black";
          }
  
          if (document.getElementById("order_header_facturation")) {
              document.getElementById("order_header_facturation").style.backgroundColor = "#d8edfc";
              document.getElementById("order_header_facturation").style.color = "black";
          }
  */

        disable_Order_Header_DetailFields();

    }

    async function order_header_submenu_detail() {
        setheader_submenu("detail");

        await sleep(5);

        submenu_color_management("detail");

        /*  if (document.getElementById("order_header_detail")) {
              document.getElementById("order_header_detail").style.backgroundColor = "#104277";
              document.getElementById("order_header_detail").style.color = "white";
          }
  
          if (document.getElementById("order_header_main")) {
              document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
              document.getElementById("order_header_main").style.color = "black";
          }
  
          if (document.getElementById("order_header_facturation")) {
              document.getElementById("order_header_facturation").style.backgroundColor = "#d8edfc";
              document.getElementById("order_header_facturation").style.color = "black";
          }*/

        disable_Order_Header_DetailFields();

    }

    async function order_header_submenu_facturation() {
        setheader_submenu("facturation");

        await sleep(5);

        submenu_color_management("facturation");

        /* if (document.getElementById("order_header_facturation")) {
             document.getElementById("order_header_facturation").style.backgroundColor = "#104277";
             document.getElementById("order_header_facturation").style.color = "white";
         }
 
         if (document.getElementById("order_header_detail")) {
             document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
             document.getElementById("order_header_detail").style.color = "black";
         }
 
         if (document.getElementById("order_header_main")) {
             document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
             document.getElementById("order_header_main").style.color = "black";
         }
         */


        disable_Order_Header_DetailFields();

    }

    async function order_header_submenu_paiement() {
        setheader_submenu("paiement");

        Getall_Invoice_Paiement();
        Getall_Invoice_Paiement_Payed_Remain_Amounts();

        await sleep(5);

        submenu_color_management("paiement");

        /* if (document.getElementById("order_header_facturation")) {
             document.getElementById("order_header_facturation").style.backgroundColor = "#104277";
             document.getElementById("order_header_facturation").style.color = "white";
         }
 
         if (document.getElementById("order_header_detail")) {
             document.getElementById("order_header_detail").style.backgroundColor = "#d8edfc";
             document.getElementById("order_header_detail").style.color = "black";
         }
 
         if (document.getElementById("order_header_main")) {
             document.getElementById("order_header_main").style.backgroundColor = "#d8edfc";
             document.getElementById("order_header_main").style.color = "black";
         }
         */


    }


    function submenu_color_management(current_menu) {
        const list_sous_menu = ["order_header_facturation", "order_header_detail", "order_header_main", "order_header_detail", "paiement"]

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }

        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    function print_invoice_pdf() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Facture_" + invoice_internal_ref + ".pdf";
        // console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/GerneratePDF_Partner_Invoice/" + stored_cookie + "/" + selected_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fichier_cmd)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    const [Getall_Parter_Invoice_With_Filter_api, setGetall_Parter_Invoice_With_Filter_api] = useState();
    const [Getall_Parter_Invoice_With_Filter_message, setGetall_Parter_Invoice_With_Filter_message] = useState();
    const [Getall_Parter_Invoice_With_Filter_result, setGetall_Parter_Invoice_With_Filter_result] = useState();
    function Getall_Parter_Invoice_With_Filter(event) {

        setgridline_id("");

        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Invoice_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Invoice_With_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Invoice_With_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Invoice_With_Filter_api("true");
                setGetall_Parter_Invoice_With_Filter_result(res.data.message);
                setRows(res.data.message);
                setRows_total_data(JSON.parse(res.data.total_data));
            }
            else {
                setGetall_Parter_Invoice_With_Filter_api("false");
                setGetall_Parter_Invoice_With_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Invoice_With_Filter = ', error);
            setGetall_Parter_Invoice_With_Filter_api("false");
            alert(" Impossible de recuperer la liste des factures");
            //setmyApimyApiMessage("")
        })
    }

    const [Send_Invoice_By_Email_api, setSend_Invoice_By_Email_api] = useState();
    const [Send_Invoice_By_Email_message, setSend_Invoice_By_Email_message] = useState();
    const [Send_Invoice_By_Email_result, setSend_Invoice_By_Email_result] = useState();
    function Send_Invoice_By_Email() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_id", selected_id);

        for (let i = 0; i < tab_invoice_mail_pieces_jointes_result.length; i++) {
            form.append('File', tab_invoice_mail_pieces_jointes_result[i]);
        }



        //console.log("### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Send_Partner_Invoice_By_Email/";


        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Order_Line_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setSend_Invoice_By_Email_api("true");
                setSend_Invoice_By_Email_message(res.data.message);
                alert(res.data.message);
            }
            else {
                setSend_Invoice_By_Email_api("false");
                setSend_Invoice_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('Send_Invoice_By_Email : Not good man :( Send_Invoice_By_Email = ' + error);
            setSend_Invoice_By_Email_api("false");

            alert(" Impossible d'envoyer la facture par email");


        })


    }



    const [Get_Partner_Invoice_Lines_from_header_id_api, setGet_Partner_Invoice_Lines_from_header_id_api] = useState();
    const [Get_Partner_Invoice_Lines_from_header_id_message, setGet_Partner_Invoice_Lines_from_header_id_message] = useState();
    const [Get_Partner_Invoice_Lines_from_header_id_result, setGet_Partner_Invoice_Lines_from_header_id_result] = useState();
    function Get_Partner_Invoice_Lines_from_header_id(local_invoice_header_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_header_id", local_invoice_header_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Invoice_Lines/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In Get_Partner_Invoice_Lines_from_header_id  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Invoice_Lines_from_header_id  res.data.message r_class = " + res.data.message);
                setGet_Partner_Invoice_Lines_from_header_id_api("true");
                setGet_Partner_Invoice_Lines_from_header_id_result(res.data.message);
                setrows_invoice_lines(res.data.message);
            }
            else {
                setLoading(false);
                setGet_Partner_Invoice_Lines_from_header_id_api("false");
                setGet_Partner_Invoice_Lines_from_header_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Invoice_Lines_from_header_id = ', error);
            setGet_Partner_Invoice_Lines_from_header_id_api("false");
            alert(" Impossible de recuperer le detail de la facture");
            //setmyApimyApiMessage("")
        })
    }


    function Enable_Order_Header_DetailFields() {


        if (document.getElementsByName("detail_header_type")[0]) {
            document.getElementsByName("detail_header_type")[0].disabled = false;
            document.getElementsByName("detail_header_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_status")[0]) {
            document.getElementsByName("detail_header_status")[0].disabled = false;
            document.getElementsByName("detail_header_status")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_ref_interne")[0]) {
            document.getElementsByName("detail_header_ref_interne")[0].disabled = false;
            document.getElementsByName("detail_header_ref_interne")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_order_paiement_condition")[0]) {
            document.getElementsByName("detail_header_order_paiement_condition")[0].disabled = false;
            document.getElementsByName("detail_header_order_paiement_condition")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_date_cmd")[0]) {
            document.getElementsByName("detail_header_date_cmd")[0].disabled = false;
            document.getElementsByName("detail_header_date_cmd")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_date_expiration")[0]) {
            document.getElementsByName("detail_header_date_expiration")[0].disabled = false;
            document.getElementsByName("detail_header_date_expiration")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_nom_client")[0]) {
            document.getElementsByName("detail_nom_client")[0].disabled = false;
            document.getElementsByName("detail_nom_client")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_description")[0]) {
            document.getElementsByName("detail_header_description")[0].disabled = false;
            document.getElementsByName("detail_header_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_comment")[0]) {
            document.getElementsByName("detail_header_comment")[0].disabled = false;
            document.getElementsByName("detail_header_comment")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_vendeur_nom_prenom")[0]) {
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].disabled = false;
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].style.backgroundColor = "#FFFFFF";
        }

        //----
        if (document.getElementsByName("detail_header_ref_cient")[0]) {
            document.getElementsByName("detail_header_ref_cient")[0].disabled = false;
            document.getElementsByName("detail_header_ref_cient")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_mode_paiement")[0]) {
            document.getElementsByName("detail_header_mode_paiement")[0].disabled = false;
            document.getElementsByName("detail_header_mode_paiement")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_date_echeance")[0]) {
            document.getElementsByName("detail_header_date_echeance")[0].disabled = false;
            document.getElementsByName("detail_header_date_echeance")[0].style.backgroundColor = "#FFFFFF";
        }



        if (document.getElementsByName("detail_header_fact_adresse")[0]) {
            document.getElementsByName("detail_header_fact_adresse")[0].disabled = false;
            document.getElementsByName("detail_header_fact_adresse")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_fact_code_postal")[0]) {
            document.getElementsByName("detail_header_fact_code_postal")[0].disabled = false;
            document.getElementsByName("detail_header_fact_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_ville")[0]) {
            document.getElementsByName("detail_header_fact_ville")[0].disabled = false;
            document.getElementsByName("detail_header_fact_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_fact_pays")[0]) {
            document.getElementsByName("detail_header_fact_pays")[0].disabled = false;
            document.getElementsByName("detail_header_fact_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_adresse")[0]) {
            document.getElementsByName("detail_header_liv_adresse")[0].disabled = false;
            document.getElementsByName("detail_header_liv_adresse")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_code_postal")[0]) {
            document.getElementsByName("detail_header_liv_code_postal")[0].disabled = false;
            document.getElementsByName("detail_header_liv_code_postal")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_ville")[0]) {
            document.getElementsByName("detail_header_liv_ville")[0].disabled = false;
            document.getElementsByName("detail_header_liv_ville")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_liv_pays")[0]) {
            document.getElementsByName("detail_header_liv_pays")[0].disabled = false;
            document.getElementsByName("detail_header_liv_pays")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_ht")[0]) {
            document.getElementsByName("detail_header_total_ht")[0].disabled = false;
            document.getElementsByName("detail_header_total_ht")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_tax")[0]) {
            document.getElementsByName("detail_header_total_tax")[0].disabled = false;
            document.getElementsByName("detail_header_total_tax")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_total_ttc")[0]) {
            document.getElementsByName("detail_header_total_ttc")[0].disabled = false;
            document.getElementsByName("detail_header_total_ttc")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_header_type_reduction")[0]) {
            document.getElementsByName("detail_header_type_reduction")[0].disabled = false;
            document.getElementsByName("detail_header_type_reduction")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_type_reduction_valeur")[0]) {
            document.getElementsByName("detail_header_type_reduction_valeur")[0].disabled = false;
            document.getElementsByName("detail_header_type_reduction_valeur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_header_montant_reduction")[0]) {
            document.getElementsByName("detail_header_montant_reduction")[0].disabled = false;
            document.getElementsByName("detail_header_montant_reduction")[0].style.backgroundColor = "#FFFFFF";
        }


    }

    function disable_Order_Header_DetailFields() {


        if (document.getElementsByName("detail_header_num_facture")[0]) {
            document.getElementsByName("detail_header_num_facture")[0].disabled = true;
            document.getElementsByName("detail_header_num_facture")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_type_document")[0]) {
            document.getElementsByName("detail_header_type_document")[0].disabled = true;
            document.getElementsByName("detail_header_type_document")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_facture")[0]) {
            document.getElementsByName("detail_header_date_facture")[0].disabled = true;
            document.getElementsByName("detail_header_date_facture")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_type")[0]) {
            document.getElementsByName("detail_header_type")[0].disabled = true;
            document.getElementsByName("detail_header_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_status")[0]) {
            document.getElementsByName("detail_header_status")[0].disabled = true;
            document.getElementsByName("detail_header_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_ref_interne")[0]) {
            document.getElementsByName("detail_header_ref_interne")[0].disabled = true;
            document.getElementsByName("detail_header_ref_interne")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_order_paiement_condition")[0]) {
            document.getElementsByName("detail_header_order_paiement_condition")[0].disabled = true;
            document.getElementsByName("detail_header_order_paiement_condition")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_cmd")[0]) {
            document.getElementsByName("detail_header_date_cmd")[0].disabled = true;
            document.getElementsByName("detail_header_date_cmd")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_expiration")[0]) {
            document.getElementsByName("detail_header_date_expiration")[0].disabled = true;
            document.getElementsByName("detail_header_date_expiration")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_nom_client")[0]) {
            document.getElementsByName("detail_nom_client")[0].disabled = true;
            document.getElementsByName("detail_nom_client")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_email_client")[0]) {
            document.getElementsByName("detail_header_email_client")[0].disabled = true;
            document.getElementsByName("detail_header_email_client")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_description")[0]) {
            document.getElementsByName("detail_header_description")[0].disabled = true;
            document.getElementsByName("detail_header_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_comment")[0]) {
            document.getElementsByName("detail_header_comment")[0].disabled = true;
            document.getElementsByName("detail_header_comment")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_vendeur_nom_prenom")[0]) {
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].disabled = true;
            document.getElementsByName("detail_header_vendeur_nom_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        //---
        if (document.getElementsByName("detail_header_ref_cient")[0]) {
            document.getElementsByName("detail_header_ref_cient")[0].disabled = true;
            document.getElementsByName("detail_header_ref_cient")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_mode_paiement")[0]) {
            document.getElementsByName("detail_header_mode_paiement")[0].disabled = true;
            document.getElementsByName("detail_header_mode_paiement")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_date_echeance")[0]) {
            document.getElementsByName("detail_header_date_echeance")[0].disabled = true;
            document.getElementsByName("detail_header_date_echeance")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_fact_adresse")[0]) {
            document.getElementsByName("detail_header_fact_adresse")[0].disabled = true;
            document.getElementsByName("detail_header_fact_adresse")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_fact_code_postal")[0]) {
            document.getElementsByName("detail_header_fact_code_postal")[0].disabled = true;
            document.getElementsByName("detail_header_fact_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_ville")[0]) {
            document.getElementsByName("detail_header_fact_ville")[0].disabled = true;
            document.getElementsByName("detail_header_fact_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_fact_pays")[0]) {
            document.getElementsByName("detail_header_fact_pays")[0].disabled = true;
            document.getElementsByName("detail_header_fact_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_adresse")[0]) {
            document.getElementsByName("detail_header_liv_adresse")[0].disabled = true;
            document.getElementsByName("detail_header_liv_adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_code_postal")[0]) {
            document.getElementsByName("detail_header_liv_code_postal")[0].disabled = true;
            document.getElementsByName("detail_header_liv_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_ville")[0]) {
            document.getElementsByName("detail_header_liv_ville")[0].disabled = true;
            document.getElementsByName("detail_header_liv_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_liv_pays")[0]) {
            document.getElementsByName("detail_header_liv_pays")[0].disabled = true;
            document.getElementsByName("detail_header_liv_pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_ht")[0]) {
            document.getElementsByName("detail_header_total_ht")[0].disabled = true;
            document.getElementsByName("detail_header_total_ht")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_tax")[0]) {
            document.getElementsByName("detail_header_total_tax")[0].disabled = true;
            document.getElementsByName("detail_header_total_tax")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_total_ttc")[0]) {
            document.getElementsByName("detail_header_total_ttc")[0].disabled = true;
            document.getElementsByName("detail_header_total_ttc")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_header_type_reduction")[0]) {
            document.getElementsByName("detail_header_type_reduction")[0].disabled = true;
            document.getElementsByName("detail_header_type_reduction")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_type_reduction_valeur")[0]) {
            document.getElementsByName("detail_header_type_reduction_valeur")[0].disabled = true;
            document.getElementsByName("detail_header_type_reduction_valeur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_header_montant_reduction")[0]) {
            document.getElementsByName("detail_header_montant_reduction")[0].disabled = true;
            document.getElementsByName("detail_header_montant_reduction")[0].style.backgroundColor = "#ECEFF1";
        }


    }


    function clean_all_filters() {
        setp_filtre1("");
        setp_filtre1_value();

        setp_filtre2("");
        setp_filtre2_value();

        setp_filtre3("");
        setp_filtre3_value();

        setp_filtre4("");
        setp_filtre4_value();

        clean_order_detail_fields();
        setsubmenu();

        setSelectionModel([]);
        setselectionModel_invoice_lines([]);

        Getall_Parter_Invoice_No_Filter();
        setdisplay_detail_invoice();

        setgridline_id("");
    }

    function clean_order_detail_fields() {

    }

    const [Dialog_Detail_Article_open, setDialog_Detail_Article_open] = React.useState(false);

    const Close_Dialog_Detail_Article_open = () => {

        setDialog_Detail_Article_open(false);
    };

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [Dialog_1_open, setDialog_1_open] = React.useState(false);


    const [Get_Given_Paiement_Data_api, setGet_Given_Paiement_Data_api] = useState();
    const [Get_Given_Paiement_Data_message, setGet_Given_Paiement_Data_message] = useState();
    const [Get_Given_Paiement_Data_result, setGet_Given_Paiement_Data_result] = useState();
    function Get_Given_Paiement_Data(local_paiement_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_paiement_id);

        //console.log(" ## form = ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Invoice_Paiement/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === "true") {
                //console.log(" In Get_Given_Paiement_Data  res.data.status = " + res.data.status);
                console.log(" In Get_Given_Paiement_Data  res.data.message r_class = " + res.data.message);
                setGet_Given_Paiement_Data_api("true");
                setGet_Given_Paiement_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_document = JSON.parse(res.data.message);

                    if (mylocal_document.paiement_amount)
                        setp_detail_paiement_amount(mylocal_document.paiement_amount);
                    else
                        setp_detail_paiement_amount("");

                    if (mylocal_document.paiement_mode)
                        setp_detail_paiement_mode(mylocal_document.paiement_mode);
                    else
                        setp_detail_paiement_mode("");

                    if (mylocal_document.paiement_ref)
                        setp_detail_paiement_ref(mylocal_document.paiement_ref);
                    else
                        setp_detail_paiement_ref("");

                    if (mylocal_document.paiement_date)
                        setp_detail_paiement_date(mylocal_document.paiement_date);
                    else
                        setp_detail_paiement_date(new Date().toLocaleDateString('fr-FR'));



                    if (mylocal_document.commentaire)
                        setp_detail_paiement_comment(mylocal_document.commentaire);
                    else
                        setp_detail_paiement_comment("");






                } else {
                    alert(" Aucun paiement recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Given_Paiement_Data_api("false");
                setGet_Given_Paiement_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Given_Paiement_Data = ', error);
            setGet_Given_Paiement_Data_api("false");
            alert(" Impossible de recuperer les données du paiement");
            //setmyApimyApiMessage("")
        })
    }


    const [Dialog_Paiement_1_message, setDialog_Paiement_1_message] = React.useState(false);
    const [Dialog_Paiement_1_open, setDialog_Paiement_1_open] = React.useState(false);

    function Dialog_1_handle_change_Paiement(message) {
        setDialog_Paiement_1_message(message);
        setDialog_Paiement_1_open(true);
    }

    const Dialog_Paiement_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_Paiement_1_handleClose_buton = () => {
        clear_Paiement_DetailFields();
        setDialog_Paiement_1_open(false);

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    };

    const [p_detail_paiement_id, setp_detail_paiement_id] = useState("");
    const [p_detail_paiement_amount, setp_detail_paiement_amount] = useState("");

    const [p_detail_paiement_date, setp_detail_paiement_date] = useState(new Date().toLocaleDateString('fr-FR'));

    const [p_detail_paiement_ref, setp_detail_paiement_ref] = useState("");
    const [p_detail_paiement_comment, setp_detail_paiement_comment] = useState("");
    const [p_detail_paiement_mode, setp_detail_paiement_mode] = useState("");
    const [p_detail_paiement_invoice_id, setp_detail_paiement_invoice_id] = useState("");



    function clear_Paiement_DetailFields() {
        setp_detail_paiement_id("");
        setp_detail_paiement_amount("");
        setp_detail_paiement_date(new Date().toLocaleDateString('fr-FR'));
        setp_detail_paiement_ref("");
        setp_detail_paiement_comment("");
    }


    const [Add_Update_Paiement_Data_api, setAdd_Update_Paiement_Data_api] = useState();
    const [Add_Update_Paiement_Data_message, setAdd_Update_Paiement_Data_message] = useState();
    const [Add_Update_Paiement_Data_result, setAdd_Update_Paiement_Data_result] = useState();
    function Add_Update_Paiement_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("invoice_id", selected_id);
        form.append("paiement_amount", p_detail_paiement_amount);
        form.append("paiement_mode", p_detail_paiement_mode);
        form.append("paiement_ref", p_detail_paiement_ref);
        form.append("paiement_date", p_detail_paiement_date);
        form.append("commentaire", p_detail_paiement_comment);


        //console.log(" form == ", form);

        var myurl = "";
        if (p_detail_paiement_id && String(p_detail_paiement_id).length > 2) {

            form.append("_id", p_detail_paiement_id);
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Invoice_Paiement/";
        }
        else
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Invoice_Paiement/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_Update_Paiement_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Paiement_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {

                setAdd_Update_Paiement_Data_api("true");
                setAdd_Update_Paiement_Data_result(res.data.message);

                Getall_Invoice_Paiement();
                Getall_Invoice_Paiement_Payed_Remain_Amounts();
                clear_Paiement_DetailFields()

                Dialog_Paiement_1_handleClose_buton();

                alert(res.data.message);
                if (document.getElementById('myRef')) {
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


            }
            else {
                setAdd_Update_Paiement_Data_api("false");
                setAdd_Update_Paiement_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_Paiement_Data = ' + error);
            setAdd_Update_Paiement_Data_api("false");
            alert(" Impossible d'ajouter ou mettre à jour le paiement ");

        })
    }



    const [Create_Invoice_Credit_Note_api, setCreate_Invoice_Credit_Note_api] = useState();
    const [Create_Invoice_Credit_Note_message, setCreate_Invoice_Credit_Note_message] = useState();
    const [Create_Invoice_Credit_Note_result, setCreate_Invoice_Credit_Note_result] = useState();
    function Create_Invoice_Credit_Note() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_id);


        //console.log(" form == ", form);

        var myurl = myurl = process.env.REACT_APP_API_URL + "myclass/api/Create_Invoice_Avoir_Total/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Create_Invoice_Credit_Note  res.data.status = " + res.data.status);
            //console.log(" In Create_Invoice_Credit_Note  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {

                setCreate_Invoice_Credit_Note_api("true");
                setCreate_Invoice_Credit_Note_result(res.data.message);

                Get_List_Partner_Clients();
                Getall_Parter_Invoice_No_Filter();

                Display_Detail_Invoice_data(selected_id);

                alert(res.data.message);

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


            }
            else {
                setCreate_Invoice_Credit_Note_api("false");
                setCreate_Invoice_Credit_Note_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Create_Invoice_Credit_Note = ' + error);
            setCreate_Invoice_Credit_Note_api("false");
            alert(" Impossible d'ajouter ou mettre à jour le paiement ");

        })
    }

    const [Dialog_annotation_1_message, setDialog_annotation_1_message] = React.useState(false);
    const [Dialog_annotation_1_open, setDialog_annotation_1_open] = React.useState(false);

    function Dialog_1_handle_change_annotation(message) {
        setDialog_annotation_1_message(message);
        setDialog_annotation_1_open(true);
    }

    const Dialog_annotation_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_annotation_1_handleClose_buton = () => {

        setDialog_annotation_1_open(false);

        /* if (document.getElementById('myRef')) {
             var divh = document.getElementById('myRef').offsetTop;
             window.scrollTo({
                 top: divh,
                 behavior: "smooth",
             });
         }*/

    };

    const [Add_Update_annotation_Data_api, setAdd_Update_annotation_Data_api] = useState();
    const [Add_Update_annotation_Data_message, setAdd_Update_annotation_Data_message] = useState();
    const [Add_Update_annotation_Data_result, setAdd_Update_annotation_Data_result] = useState();
    function Add_Update_annotation_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_id);
        form.append("annotation", p_detail_header_invoice_header_annotation);


        //console.log(" form == ", form);

        var myurl = myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Invoice_Annotation/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_Update_annotation_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_annotation_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {

                setAdd_Update_annotation_Data_api("true");
                setAdd_Update_annotation_Data_result(res.data.message);


                Dialog_annotation_1_handleClose_buton();

                alert(res.data.message);
                if (document.getElementById('myRef')) {
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


            }
            else {
                setAdd_Update_annotation_Data_api("false");
                setAdd_Update_annotation_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_annotation_Data = ' + error);
            setAdd_Update_annotation_Data_api("false");
            alert(" Impossible d'ajouter ou mettre à jour l'annotation ");

        })
    }


    const New_Option_Filter = [
        { "id": "ref_interne_invoice", "label": "Num Facture", "value": "ref_interne_invoice" },
        { "id": "ref_interne_cmd", "label": "Ref. Interne Cmd", "value": "ref_interne_cmd" },
        { "id": "ref_externe", "label": "Ref. Externe Cmd (chez le client)", "value": "ref_externe" },
        { "id": "client_nom", "label": "Nom Client", "value": "client_nom" },
        { "id": "formation", "label": "Lié a la Formation (code externe)", "value": "formation" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]


    function handleClick_menu_droite_facture(e) {
        console.log(" III ");
    }

    const New_type_apprenant = [
        { "id": "0", "label": "Autre", "value": "0" },
        { "id": "1", "label": "Salariés", "value": "1" },
        { "id": "2", "label": "Apprentis", "value": "2" },
        { "id": "3", "label": "Particuliers", "value": "3" },
        { "id": "4", "label": "Rech. Emploi", "value": "4" },
        { "id": "", "label": "", "value": "" },
    ]


    // DEBUT  EXPORT EXCEL DATAGRID 

    function CustomToolbar(props) {


        return (
            <GridToolbarContainer {...props}>

                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <ExportButton data_colums={props.data_colums} />


            </GridToolbarContainer>
        );
    }

    function getExcelData(apiRef) {
        // Select rows and columns
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        // Format the data. Here we only keep the value
        const data = filteredSortedRowIds.map((id) => {
            const row = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

        return data;
    }


    function ExportButton(props) {
        return (
            <GridToolbarExportContainer {...props}>
                <ExportMenuItem data_colums={props.data_colums} />
            </GridToolbarExportContainer>
        );
    }

    function handleExport(apiRef, data_colums) {
        const data = getExcelData(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);


        const local_config = {
            keys: visibleColumnsField,

            columnNames: data_colums.filter((mydata) => (mydata).disableExport !== true).map(function (mydata) {
                if (mydata.headerName && visibleColumnsField.includes(mydata.field)) {
                    if (mydata.headerName) {
                        return mydata.headerName;
                    }
                }

            }),

            fileName: 'data_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_") + '.xlsx',
            sheetName: 'Export_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_"),
        };

        local_config.columnNames = local_config.columnNames.filter(function (element) {
            return element !== undefined;
        });


        const rows = data.map((row) => {
            const mRow = {};
            for (const key of local_config.keys) {
                mRow[key] = row[key];
            }
            return mRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.sheet_add_aoa(worksheet, [[...local_config.columnNames]], {
            origin: 'A1',
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, local_config.sheetName);
        XLSX.writeFile(workbook, local_config.fileName, { compression: true });
    }

    function ExportMenuItem(props) {
        const apiRef = useGridApiContext();
        const { hideMenu } = props;

        return (
            <MenuItem
                onClick={() => {
                    handleExport(apiRef, props.data_colums);
                    // Hide the export menu after the export
                    hideMenu?.();
                }}
            >
                Export Excel
            </MenuItem>
        );
    }

    // FIN EXPORT EXCEL DATAGRID 

    const [Dialog_send_invoice_with_PJ_message, setDialog_send_invoice_with_PJ_message] = React.useState(false);
    const [Dialog_send_invoice_with_PJ_open, setDialog_send_invoice_with_PJ_open] = React.useState(false);
    function Dialog_send_invoice_with_PJ_handle_change_participant_session(message) {
        setDialog_send_invoice_with_PJ_message(message);
        setDialog_send_invoice_with_PJ_open(true);
    }

    const Dialog_send_invoice_with_PJ_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_send_invoice_with_PJ_handleClose_buton = () => {
        setDialog_send_invoice_with_PJ_open(false);
        settab_invoice_mail_pieces_jointes_result([]);
    };


    function Delete_invoice_email_Attached_Doc(event) {

        var doc_to_del_name = event.target.id;
        const myArray = tab_invoice_mail_pieces_jointes_result;

        let new_myArray = myArray.filter(file => file.name !== String(doc_to_del_name));

        var new_tab = []
        for (let i = 0; i < new_myArray.length; i++) {
            new_tab.push(new_myArray[i]);
        }

        settab_invoice_mail_pieces_jointes_result(new_tab);


    }

    const [sessions_file_change_1_convention_api, setsessions_file_change_1_convention_api] = useState();
    const [sessions_file_change_1_convention_result, setsessions_file_change_1_convention_result] = useState();
    const [sessions_file_change_1_convention_message, setsessions_file_change_1_convention_message] = useState();
    const sessions_file_change_1_convention = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        if (file_type !== "application/pdf") {
            alert("Le fichier n'est pas de type PDF");
            return;
        }



        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


        var new_tmp = [];

        if (tab_invoice_mail_pieces_jointes_result && tab_invoice_mail_pieces_jointes_result.length > 0) {

            tab_invoice_mail_pieces_jointes_result.map((x) => {
                new_tmp.push(x);

            });

        }

        new_tmp.push(event.target.files[0])

        settab_invoice_mail_pieces_jointes_result(new_tmp);

        //console.log(" tab_invoice_mail_pieces_jointes_result = ", tab_invoice_mail_pieces_jointes_result)


        return;


    };

    const [tab_invoice_mail_pieces_jointes_result, settab_invoice_mail_pieces_jointes_result] = useState([]);

    const [Dialog_invoice_history_message, setDialog_invoice_history_message] = React.useState(false);
    const [Dialog_invoice_history_open, setDialog_invoice_history_open] = React.useState(false);
    function Dialog_invoice_history_handle_change_participant_session(message) {
        setDialog_invoice_history_message(message);
        setDialog_invoice_history_open(true);
    }

    const Dialog_invoice_history_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_invoice_history_handleClose_buton = () => {
        setDialog_invoice_history_open(false);
        settab_invoice_mail_pieces_jointes_result([]);

        if (document.getElementsByName("invoice_history_bton") && document.getElementsByName("invoice_history_bton")[0]) {
            document.getElementsByName("invoice_history_bton")[0].style.backgroundColor = "#d8edfc";
            document.getElementsByName("invoice_history_bton")[0].style.color = "#3b3e40";
        }
    };

    return (

        <div className="partner_facture">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_Detail_Article_open}
                onClose={Dialog_1_handleClose}
                className="partner_commande"
            >

                <DialogTitle> Détail ligne Facture <font style={{ fontWeight: "700" }}>  {String(invoice_internal_ref)} </font> </DialogTitle>
                <DialogContent>



                    <div className="session_caract_Dialog" > Article

                        <TextField
                            autoFocus
                            margin="dense"
                            id="ref_article"
                            //label="Article"
                            disabled
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item}

                        />


                    </div>

                    <div className="session_caract_Dialog" > Quantité

                        <TextField

                            margin="dense"
                            id="ref_article_qty"
                            //label="Quantité"
                            //type="number"
                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_qty}

                            disabled
                            type="text"

                        />

                    </div>


                    <div className="session_caract_Dialog" > Prix Unitaire
                        <TextField
                            margin="dense"
                            id="ref_article_unit_price"
                            name="ref_article_unit_price"
                            //label="Prix Unitaire"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_price}
                            disabled
                            type="text"
                        />
                    </div>

                    <div className="session_caract_Dialog" > Taxe
                        <TextField

                            margin="dense"
                            id="ref_article_taux_tva"
                            name="ref_article_taux_tva"
                            //label="Prix Unitaire" 

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_taxe_amount}
                            disabled
                            type="text"


                        //type="number"
                        />
                    </div>

                    {p_add_line_item_type_article && String(p_add_line_item_type_article) === "formation" &&
                        <div className="session_caract_Dialog" > Type apprenant
                            <TextField

                                margin="dense"
                                id="ref_type_apprenant"
                                name="ref_type_apprenant"
                                //label="Prix Unitaire"

                                fullWidth
                                //variant="standard"
                                value={New_type_apprenant.filter((val) => val.value === String(p_add_line_item_type_apprenant))[0].label}
                                disabled
                                type="text"
                            />
                        </div>}

                    <div className="session_caract_Dialog" > Type reduction
                        <TextField

                            margin="dense"
                            id="ref_article_type_reduction"
                            //label="Type reduction"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_type_reduction}
                            disabled

                        />

                    </div>


                    <div className="session_caract_Dialog" > Reduction
                        <TextField

                            margin="dense"
                            id="ref_article_reduction_value"
                            //label="Reduction"

                            fullWidth
                            //variant="standard"
                            value={p_add_line_item_reduction_value}
                            disabled
                            type="text"
                        />

                    </div>

                    <div className="session_caract_Dialog" > Total Reduction
                        <TextField

                            margin="dense"
                            id="ref_article_reduction_total_amount"
                            //label="Total Reduction"

                            fullWidth
                            disabled
                            //variant="standard"
                            value={p_add_line_item_reduction_total_amount}

                        />

                    </div>



                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="ref_article_comment"
                            id="ref_article_comment"

                            fullWidth

                            value={p_add_line_item_comment}

                            //label="Commentaire"
                            //variant="standard"
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}
                            disabled

                        />

                    </div>

                    <div className="session_caract_Dialog" > Total HT
                        <TextField

                            margin="dense"
                            id="ref_article_total_amount_ht"
                            //label="Total HT"
                            type="text"
                            fullWidth
                            //variant="standard"
                            disabled={true}
                            value={p_add_line_item_total_amount_ht}

                        />

                    </div>






                </DialogContent>

                <DialogActions>


                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Article_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                    </div>

                </DialogActions>
            </Dialog>

            {/*   Dialog affichage historique facture  */}
            <Dialog
                open={Dialog_invoice_history_open}
                // onClose={Dialog_invoice_history_handleClose}
                className="displaypartnersession"
                static
                onClose={() => null}  >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>

                    <div>
                        <div>

                            <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                                <Module_Historique_Action related_collection="partner_invoice_header"
                                    related_collection_recid={selected_id}
                                    read_access={"1"}
                                    write_access={"1"} />

                            </div>


                        </div>
                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            &nbsp;
                        </div>


                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_invoice_history_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/*  FIN  Dialog affichage historique facture */}


            {/*   Dialog envoie facture mail avec PJ */}
            <Dialog
                open={Dialog_send_invoice_with_PJ_open}
                // onClose={Dialog_send_invoice_with_PJ_handleClose}
                className="displaypartnersession"
                static
                onClose={() => null}  >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>
                    <DialogContentText>
                        Envoi Facture par email
                    </DialogContentText>


                    <div>

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes à la facture

                            </div>

                            <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload_convention_file">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload_convention_file"
                                            name="upload_convention_file"
                                            type="file"
                                            onChange={sessions_file_change_1_convention}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>


                            </div>


                            {tab_invoice_mail_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    {<div className="div_row">
                                        {tab_invoice_mail_pieces_jointes_result && tab_invoice_mail_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj_convention"  >
                                                <nav style={{ "color": "orange", "cursor": "pointer" }}
                                                    onClick={(e) => {
                                                        Delete_invoice_email_Attached_Doc(e);
                                                    }}
                                                    name={val.name} id={val.name}> Supprimer  {val.name} </nav> <br />


                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>}



                        </div>
                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {

                                Dialog_send_invoice_with_PJ_handleClose_buton();
                                Send_Invoice_By_Email();

                            }}
                                className="bton_enreg_dialog">Envoyer  </Button>
                        </div>


                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_send_invoice_with_PJ_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/*  FIN Dialog envoie facture mail avec PJ */}




            {/*** Paiement */}
            <Dialog
                open={Dialog_Paiement_1_open}
                onClose={Dialog_Paiement_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle> Paiement </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_Paiement_1_message}
                    </DialogContentText>



                    <div className="session_caract_Dialog" > <b>Réf. Paiement </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_ref"
                            id="event_dialog_ref"

                            fullWidth

                            value={p_detail_paiement_ref}
                            onChange={(e) => {
                                setp_detail_paiement_ref(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > <b>Montant</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_montant"
                            id="event_dialog_montant"

                            fullWidth

                            type="number"
                            inputProps={{
                                step: 0.1,
                            }}

                            value={p_detail_paiement_amount}
                            onChange={(e) => {
                                setp_detail_paiement_amount(e.target.value);
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > <b>Mode Paiement</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_mode"
                            id="event_dialog_mode"

                            fullWidth

                            value={p_detail_paiement_mode}
                            onChange={(e) => {
                                setp_detail_paiement_mode(e.target.value);
                            }
                            }
                        />

                    </div>

                    {p_detail_paiement_date && <div className="session_caract_Dialog"> <b> Date paiement </b><br />
                        <DatePicker
                            name="detail_naissance"
                            id="detail_naissance"
                            selected={new Date(moment(p_detail_paiement_date, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_detail_paiement_date(format(date, 'd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                            fullWidth

                        />

                    </div>}

                    <div className="session_caract_Dialog" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_comment"
                            id="event_dialog_comment"

                            fullWidth

                            value={p_detail_paiement_comment}
                            onChange={(e) => {
                                setp_detail_paiement_comment(e.target.value);
                            }
                            }
                        />

                    </div>

                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(p_detail_paiement_id).trim().length > 2 && <Button onClick={Add_Update_Paiement_Data} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(p_detail_paiement_id).trim() === "" && <Button onClick={Add_Update_Paiement_Data} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_Paiement_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*** Fin Paiement */}


            {/*** annotation */}
            <Dialog
                open={Dialog_annotation_1_open}
                onClose={Dialog_annotation_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle> Annotation </DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "15rem" }}>
                    <DialogContentText>
                        {Dialog_annotation_1_message}
                    </DialogContentText>




                    <TextField
                        name="event_dialog_invoice_annotation"
                        id="event_dialog_invoice_annotation"
                        //label="Multiline"
                        multiline
                        rows={5}
                        value={p_detail_header_invoice_header_annotation}
                        onChange={(e) => {
                            setp_detail_header_invoice_header_annotation(e.target.value);
                        }
                        }
                        maxRows={10}
                        variant="outlined"
                        fullWidth
                    />

                </DialogContent>


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Add_Update_annotation_Data} className="bton_enreg_dialog">Mettre à jour</Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_annotation_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*** Fin annotation */}

            {/******** Menu Clique Droit test */}
            <ContextMenu id="open_item_right_click" hideOnLeave={true} style={{ backgroundColor: '##abb2b9', zIndex: "99" }}>
                <MenuItem onClick={(event) => {
                    if (p_detail_header_client_nom_id && String(p_detail_header_client_nom_id).length > 2) {
                        window.open(
                            process.env.REACT_APP_FRONT_URL + "Partner/affichage/" + String("selected_class_external_code") + "/" + String("selected_internal_url"),
                            '_blank'
                        );
                    }
                }
                }>
                    ContextMenu Item 1
                </MenuItem>
                <MenuItem onClick={"handleClick"}>
                    ContextMenu Item 2
                </MenuItem>
                <MenuItem divider />
                <MenuItem onClick={"handleClick"}>
                    ContextMenu Item 3
                </MenuItem>
            </ContextMenu>

            {/******** FIN Menu Clique Droit test */}

            <h3> Vos Factures </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >


                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);
                                } else {
                                    setp_filtre1("");
                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value();
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Parter_Invoice_With_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row" style={{ "border": "None", paddingRight: '10px' }}>

                <div className="session_data">

                    {rowss_total_data && rowss_total_data.length > 0 && rowss_total_data.map((val) => (<nav style={{ "textAlign": 'right', 'marginRight': '15px', "fontWeight": "600", "fontSize": "15px", "paddingTop": "2px" }}>  {parseFloat(String(val.somme_header_ca_ht)).toLocaleString()} (€) / {val.nb_header_invoice} Factures </nav>))}

                    <div style={{ "border": "None" }}>
                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule "statut"
                                    '& .cell--status--encours': {
                                        backgroundColor: '#E6F7C8',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--status--traite': {
                                        backgroundColor: '#E6CEAA',
                                        color: '#F9E79F',
                                        fontWeight: 'light',
                                    },




                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },
                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },


                                    '& .line--statut--payed': {
                                        backgroundColor: '#EAFAF1',
                                        color: '#239B56',
                                        fontWeight: '700'
                                    },


                                }}
                            >
                                <DataGrid

                                    components={{
                                        Toolbar: CustomToolbar
                                    }}
                                    componentsProps={{ toolbar: { data_colums: columns } }}

                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_invoice_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            //clean_order_detail_fields();
                                            //setsubmenu();
                                            setdisplay_detail_invoice();


                                        }
                                    }}



                                    onRowDoubleClick={(newSelectionModel) => {
                                        setgridline_id(newSelectionModel.row.id);
                                        setp_detail_header_client_nom_id(newSelectionModel.row.order_header_client_id);
                                        handleClick_edit_invoice_From_Line(newSelectionModel.row.id);
                                    }
                                    }
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            order_header_ref_interne: JSON.parse(item).order_header_ref_interne,

                                            invoice_header_ref_interne: JSON.parse(item).invoice_header_ref_interne,
                                            invoice_header_type: JSON.parse(item).invoice_header_type,
                                            order_header_date_expiration: JSON.parse(item).order_header_date_expiration,
                                            invoice_date: JSON.parse(item).invoice_date,
                                            total_header_hors_taxe_after_header_reduction: JSON.parse(item).total_header_hors_taxe_after_header_reduction,

                                            order_header_tax_amount: JSON.parse(item).order_header_tax_amount,
                                            total_header_toutes_taxes: JSON.parse(item).total_header_toutes_taxes,
                                            order_header_client_id: JSON.parse(item).order_header_client_id,
                                            order_header_client_nom: JSON.parse(item).order_header_client_nom,
                                            order_header_vendeur_id: JSON.parse(item).order_header_vendeur_id,
                                            order_header_vendeur_nom_prenom: JSON.parse(item).order_header_vendeur_nom_prenom,
                                            order_header_date_cmd: JSON.parse(item).order_header_date_cmd,

                                            reste_to_paye: JSON.parse(item).reste_to_paye,
                                            credit_note_ref: JSON.parse(item).credit_note_ref,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    /*  components={{
                                          Toolbar: GridToolbar,
                                      }}*/


                                    //sx={datagridSx}
                                    getCellClassName={(params) => {

                                    }}
                                    getRowClassName={(params) => {

                                        if (parseInt(parseFloat(params.row.reste_to_paye)) <= 0 && String(params.row.invoice_header_type) === "facture") {

                                            return 'line--statut--payed';
                                        }

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>
            </div>

            <div className="div_row" ref={myRef} id="myRef" > &nbsp;</div>
            {String(display_detail_invoice) === "1" &&
                <div className="div_row" style={{ "border": "None", paddingRight: '10px' }}>
                    <nav style={{ "fontSize": "22px", "fontWeight": '600' }}> Facture  <i> {invoice_internal_ref} </i>: Affichage détaillé </nav>

                    <div className="session_data">
                        <div className="div_row" style={{ "textAlign": "right", "paddingTop": "10px" }}>
                            <Button variant="outlined" onClick={order_header_submenu_main} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Détail</Button>
                            <Button variant="outlined" onClick={order_header_submenu_facturation} className="detail_class_submenu" id='order_header_facturation' name='order_header_facturation'>Données Facture  </Button>
                            <Button variant="outlined" onClick={order_header_submenu_detail} className="detail_class_submenu" id='order_header_detail' name='order_header_detail'> Totaux  </Button>
                            <Button variant="outlined" onClick={order_header_submenu_paiement} className="detail_class_submenu" id='paiement' name='paiement'> Paiements  </Button>


                        </div>

                        {String(header_submenu) !== "paiement" && <div style={{ "width": "100%", "float": "left" }}>
                            <div style={{ "width": "75%", "float": "left" }}>

                                {String(header_submenu) !== "detail" && String(header_submenu) !== "facturation" && <div>

                                    <div className="session_caract"> Num Facture<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_num_facture"
                                            id="detail_header_num_facture"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_invoice_header_ref_interne}


                                        />
                                    </div>

                                    <div className="session_caract"> Type Document<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_document"
                                            id="detail_header_type_document"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_invoice_header_type}


                                        />
                                    </div>

                                    <div className="session_caract"> Date Facture<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_date_facture"
                                            id="detail_header_date_facture"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_invoice_date}


                                        />
                                    </div>




                                    <div className="session_caract"> Ref. Commande<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_ref_interne"
                                            id="detail_header_ref_interne"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_ref_interne}


                                        />
                                    </div>

                                    <div className="session_caract" > Conditions Paiement  <br />

                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_order_paiement_condition"
                                            id="detail_header_order_paiement_condition"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_condition_paiement_code}


                                        />
                                    </div>

                                    <div className="session_caract"> Date Commande<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_date_cmd"
                                            id="detail_header_date_cmd"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_date_cmd}


                                        />
                                    </div>




                                    <div className="session_caract"> Nom Client <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_nom_client"
                                            id="detail_nom_client"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style disabled_style_left_text_with_visualiser_icone"
                                            value={p_detail_header_client_nom}
                                        />

                                        <Tooltip className="tooltip_css" id="detail_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="detail_01" data-tooltip-html="Voir les détails">
                                            <div className="disabled_style disabled_style_right_bton_visualiser" onClick={(event) => {

                                                if (p_detail_header_client_nom_id && String(p_detail_header_client_nom_id).length > 2) {
                                                    window.open(
                                                        process.env.REACT_APP_FRONT_URL + "Partner/mes_clients/" + String(p_detail_header_client_nom_id),
                                                        '_blank'
                                                    );
                                                }
                                            }
                                            }
                                            >
                                                <div className="disabled_style_with_visualiser_icone" >
                                                    <FcOpenedFolder />

                                                </div>
                                            </div>
                                        </a>
                                    </div>


                                    <div className="session_caract"> Email Client<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_email_client"
                                            id="detail_header_email_client"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_email_client}


                                        />
                                    </div>


                                    <div className="session_caract"> Commande Ref. Externe<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_ref_cient"
                                            id="detail_header_ref_cient"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_ref_client}


                                        />
                                    </div>

                                    <div className="session_caract"> Description<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_description"
                                            id="detail_header_description"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_description}


                                        />
                                    </div>

                                    <div className="session_caract"> Commentaire<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_comment"
                                            id="detail_header_comment"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_comment}


                                        />
                                    </div>

                                    <div className="session_caract"> Vendeur<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_vendeur_nom_prenom"
                                            id="detail_header_vendeur_nom_prenom"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_vendeur_nom}


                                        />
                                    </div>

                                </div>}

                                {String(header_submenu) === "detail" && <div>
                                    {/*<div className="div_row">
                                            <Button variant="outlined" onClick={Compute_Order_Header_Price} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Mettre a jour prix</Button>
                                            <Button variant="outlined" onClick={print_order_pdf} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Imprimer pdf</Button>
                                            <Button variant="outlined" onClick={Send_Order_By_Email} className="detail_class_submenu" id='order_header_main' name='order_header_main'>envoyer par mail</Button>
                                                    </div>*/}




                                    <div className="session_caract"> Type de Reduction (entete) <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction"
                                            id="detail_header_type_reduction"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_type_reduction}


                                        />

                                    </div>


                                    <div className="session_caract"> Valeur reduction (entete) <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_type_reduction_valeur}

                                        />
                                    </div>

                                    <div className="session_caract"> Montant Reduction (entete) <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_montant_reduction"
                                            id="detail_header_montant_reduction"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_total_reduction_amount}



                                        />
                                    </div>



                                    <div className="session_caract"> Total Ligne HT avant reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_lines_hors_taxe_before_lines_reduction}
                                        />
                                    </div>

                                    <div className="session_caract"> Total Ligne Reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_header_montant_reduction}
                                        />
                                    </div>

                                    <div className="session_caract"> Total Ligne HT apres reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_lines_hors_taxe_after_lines_reduction}
                                        />
                                    </div>


                                    <div className="session_caract"> Total Ligne Taxes <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_order_header_tax_amount}
                                        />
                                    </div>

                                    <div className="session_caract"> Entete : Total reduction <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_header_total_reduction_amount}
                                        />
                                    </div>



                                    <div className="session_caract"> Entete : Total HT avant reduction d'entete <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_header_hors_taxe_before_header_reduction}
                                        />
                                    </div>

                                    <div className="session_caract"> Entete : Total HT apres reduction d'entete <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_type_reduction_valeur"
                                            id="detail_header_type_reduction_valeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={true}
                                            className="disabled_style"
                                            value={p_detail_total_header_hors_taxe_after_header_reduction}
                                        />
                                    </div>

                                </div>}

                                {String(header_submenu) === "facturation" && <div>

                                    <div className="session_caract"> Condition Paiement &nbsp; <FcInfo /><br />

                                        <Tooltip className="tooltip_css" id="tooltip_desc_paiement" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="tooltip_desc_paiement" data-tooltip-html={p_detail_header_condition_paiement_desc}>
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_header_mode_paiement"
                                                id="detail_header_mode_paiement"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={p_detail_header_condition_paiement}

                                            />
                                        </a>
                                    </div>


                                    <div className="session_caract"> Date échéance <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_date_echeance"
                                            id="detail_header_date_echeance"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_date_echeance}

                                        />
                                    </div>

                                    <div className="session_caract"> Adresse Facturation <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_adresse"
                                            id="detail_header_fact_adresse"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_adr}


                                        />
                                    </div>

                                    <div className="session_caract"> Code postal <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_code_postal"
                                            id="detail_header_fact_code_postal"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_code_postal}

                                        />
                                    </div>

                                    <div className="session_caract"> Ville <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_ville"
                                            id="detail_header_fact_ville"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_ville}


                                        />
                                    </div>

                                    <div className="session_caract"> Pays <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_fact_pays"
                                            id="detail_header_fact_pays"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_fact_pays}


                                        />
                                    </div>



                                    <div className="session_caract"> Adresse Livraison <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_adresse"
                                            id="detail_header_liv_adresse"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_adr}


                                        />
                                    </div>

                                    <div className="session_caract"> Code postal <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_code_postal"
                                            id="detail_header_liv_code_postal"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_code_postal}

                                        />
                                    </div>

                                    <div className="session_caract"> Ville <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_ville"
                                            id="detail_header_liv_ville"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_ville}


                                        />
                                    </div>

                                    <div className="session_caract"> Pays <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_header_liv_pays"
                                            id="detail_header_liv_pays"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_header_liv_pays}


                                        />
                                    </div>


                                </div>}




                            </div>

                            <div style={{ "width": "23%", "float": "left", "textAlign": "right", "paddingTop": "2rem" }}>
                                <Button variant="outlined" onClick={print_invoice_pdf} className="detail_class_submenu" id='order_header_main' name='order_header_main'>Imprimer pdf</Button>
                                <Button variant="outlined" onClick={(event) => {
                                    setDialog_send_invoice_with_PJ_open(true);
                                }
                                } className="detail_class_submenu" id='order_header_main' name='order_header_main'>envoyer par mail</Button>


                                {String(history_securite_read) === "1" && <Button variant="outlined" onClick={(event) => {
                                    setDialog_invoice_history_open(true);
                                }
                                } className="detail_class_submenu" id='invoice_history_bton' name='invoice_history_bton'>Historique
                                </Button>}



                                <div className="div_row"> &nbsp; </div>
                                <Button variant="outlined" onClick={(event) => {
                                    setDialog_annotation_1_open(true);
                                }
                                } className="detail_class_submenu" id='order_header_main' name='order_header_main' style={{ "background": "#b9f6ca" }}> Annotation</Button>



                                <div className="div_row"> &nbsp; </div>


                                {p_detail_header_invoice_header_type && String(p_detail_header_invoice_header_type) === "facture" &&
                                    String(p_detail_header_credit_note_ref) === "" &&
                                    <Popup
                                        trigger={
                                            <Button variant="outlined" className="detail_class_submenu" id='order_header_main' name='order_header_main'
                                                style={{ "background": "#f57f17" }}> Annuler (Avoir)
                                            </Button>
                                        }
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information </div>
                                                <div className="gest_content">
                                                    {' '}
                                                    En confirmant cette opération,<i><font color="red"> La facture sera annulée et un avoir créé  </font></i>. <br />

                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {

                                                            Create_Invoice_Credit_Note();
                                                            close();
                                                        }}

                                                        > Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>}
                            </div>

                        </div>}


                        {String(header_submenu) === "paiement" && <div style={{ "width": "100%", "float": "left" }}>

                            {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result && New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result.length > 0 &&
                                <div className="session_data" >
                                    {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result &&
                                        <div className="div_row">


                                            {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result
                                                && New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0] &&
                                                New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount'] &&

                                                parseFloat(New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount']) > 0 && <nav className="block_paiement_montant" style={{ background: "#ffd600" }}>

                                                    <nav style={{ "width": '45%', 'float': 'left', "textAlign": 'left', }}><b>  Montant Non réglé  </b> </nav> <nav style={{ "width": '45%', 'float': 'right', "textAlign": 'right' }}> <b> {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount']} </b> </nav> <br />
                                                    <nav style={{ "width": '45%', 'float': 'left', "textAlign": 'left' }}> Montant Réglé   </nav> <nav style={{ "width": '45%', 'float': 'right', "textAlign": 'right' }}> {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['payed_amount']} </nav><br />
                                                    <nav style={{ "width": '45%', 'float': 'left', "textAlign": 'left' }}> Montant Total Facture  </nav> <nav style={{ "width": '45%', 'float': 'right', "textAlign": 'right' }}> {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['total_amount']} </nav> <br />

                                                </nav>}

                                            {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result
                                                && New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0] &&
                                                New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount'] &&


                                                parseFloat(New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount']) <= 0 && <nav className="block_paiement_montant">

                                                    <nav style={{ "width": '45%', 'float': 'left', "textAlign": 'left', 'fontWeight': '700px' }}> Montant Non réglé  </nav> <nav style={{ "width": '45%', 'float': 'right', "textAlign": 'right' }}> {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount']} </nav> <br />
                                                    <nav style={{ "width": '45%', 'float': 'left', "textAlign": 'left' }}> Montant Réglé   </nav> <nav style={{ "width": '45%', 'float': 'right', "textAlign": 'right' }}> {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['payed_amount']} </nav><br />
                                                    <nav style={{ "width": '45%', 'float': 'left', "textAlign": 'left' }}> Montant Total Facture  </nav> <nav style={{ "width": '45%', 'float': 'right', "textAlign": 'right' }}> {New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['total_amount']} </nav> <br />

                                                </nav>}
                                        </div>}

                                    <div style={{ height: 550, width: '100%', paddingRight: '5px', paddingLeft: "5px" }}>
                                        &nbsp;
                                        <Box

                                            sx={{
                                                height: 400,
                                                width: '100%',
                                                paddingRight: '2px',


                                                [`& .${gridClasses.cell}`]: {
                                                    py: 1,
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    backgroundColor: "#c8cfd5",
                                                    color: "black",
                                                    fontSize: 14
                                                },
                                                '& .line--statut--selected': {
                                                    backgroundColor: '#FBF2EF',
                                                    color: 'black',
                                                },
                                                '& .line--statut--pair': {
                                                    backgroundColor: 'rgba(235, 235, 235, .7)',
                                                    color: 'black',
                                                },
                                                '& .line--statut--impair': {
                                                    backgroundColor: '#FFFFFF',
                                                    color: 'black',
                                                },



                                            }}

                                        >

                                            <DataGrid
                                                checkboxSelection
                                                onSelectionModelChange={(newSelectionModel) => {
                                                    setselectionModel_paiement(newSelectionModel);
                                                    //console.log("ch selected--" + newSelectionModel_insc);
                                                }}
                                                selectionModel={selectionModel_paiement}

                                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                                rows={Getall_Invoice_Paiement_result.map((item, index) => (
                                                    {
                                                        id: index,
                                                        _id: JSON.parse(item)._id,
                                                        invoice_id: JSON.parse(item).invoice_id,
                                                        paiement_amount: JSON.parse(item).paiement_amount,
                                                        paiement_mode: JSON.parse(item).paiement_mode,
                                                        paiement_ref: JSON.parse(item).paiement_ref,
                                                        paiement_date: JSON.parse(item).paiement_date,

                                                    }
                                                ))}

                                                columns={columns_paiement}
                                                pageSize={10}
                                                className="datagridclass"

                                                getCellClassName={(params) => {


                                                }}

                                                onRowDoubleClick={(newSelectionModel) => {

                                                    setgridline_paiment_id(newSelectionModel.row.id);
                                                    setp_detail_paiement_id(newSelectionModel.row._id);
                                                    Get_Given_Paiement_Data(newSelectionModel.row._id);

                                                    setDialog_Paiement_1_open(true);
                                                }
                                                }

                                                rowsPerPageOptions={[10]}
                                                disableSelectionOnClick
                                                components={{
                                                    Toolbar: GridToolbar,
                                                }}

                                                getRowClassName={(params) => {

                                                    // Pour la gestion de la couleur de zone double cliquée
                                                    if (String(params.row.id) === String(gridline_paiment_id)) {
                                                        return 'line--statut--selected';
                                                    }
                                                    else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                        return 'line--statut--pair';
                                                    }
                                                    else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                        return 'line--statut--impair';
                                                    }
                                                }}

                                                getEstimatedRowHeight={() => 200}
                                                getRowHeight={() => "auto"}
                                                sx={{

                                                    [`& .${gridClasses.cell}`]: {
                                                        py: 1,
                                                    },
                                                }}

                                            />
                                        </Box>


                                        <br />


                                    </div>
                                    <div className="div_row" style={{ "border": "None" }}>

                                    </div>

                                    <div className="div_row" style={{ "border": "None" }}>

                                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                            <Button variant="outlined" onClick={() => {
                                                clear_Paiement_DetailFields();

                                                if (New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0] && New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount'])
                                                    setp_detail_paiement_amount(New_Getall_Invoice_Paiement_Payed_Remain_Amounts_result[0]['remaining_amount']);
                                                setDialog_Paiement_1_open(true);

                                            }}
                                                className="detail_class_submenu bton_add_session"
                                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Paiement &nbsp;
                                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                                            </Button>
                                        </div>
                                    </div>


                                </div>}
                        </div>}
                    </div>


                    {String(header_submenu) !== "paiement" && <div className="session_data" >

                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',

                                        // Gestion des cellule a distance ou presentiel
                                        '& .cell--distantiel': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'black',
                                        },

                                        '& .cell--presentiel': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'black',
                                        },


                                        // gestion couleur des lignes avec le statut d'inscription
                                        '& .line--statut--annule': {
                                            backgroundColor: '#D8D8D8',
                                            color: 'white',
                                        },

                                        '& .line--statut--inscrit': {
                                            backgroundColor: '#CEF6D8',
                                            color: 'black',
                                        },
                                        '& .line--statut--preinscrit': {
                                            backgroundColor: '#F7F2E0',
                                            color: 'black',
                                        },

                                        /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                            "& .MuiDataGrid-row": {
                                                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                            }
                                        },*/
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            //setselectionModel_order_lines(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_order_From_Line(newSelectionModel);
                                            if (newSelectionModel.length !== 1) {
                                                setsubmenu();
                                                setdisplay_detail_order();
                                                setadd_One_Order();
                                            }*/
                                        }}
                                        selectionModel={selectionModel_invoice_lines}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={rows_invoice_lines.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                order_line_formation: JSON.parse(item).order_line_formation,
                                                order_line_qty: JSON.parse(item).order_line_qty,
                                                order_line_status: JSON.parse(item).order_line_status,
                                                order_line_prix_unitaire: JSON.parse(item).order_line_prix_unitaire,
                                                order_header_id: JSON.parse(item).order_header_id,
                                                order_header_ref_interne: JSON.parse(item).order_header_ref_interne,
                                                order_line_type: JSON.parse(item).order_line_type,
                                                order_line_formation_title: JSON.parse(item).title,
                                                order_line_formation_duration: JSON.parse(item).duration_concat,
                                                order_line_comment: JSON.parse(item).order_line_comment,
                                                order_line_formation_external_code: JSON.parse(item).order_line_formation_external_code,
                                                order_line_montant_hors_taxes: JSON.parse(item).order_line_montant_hors_taxes,

                                                order_line_type_article: JSON.parse(item).order_line_type_article,

                                            }
                                        ))}

                                        columns={columns_invoice_lines}
                                        pageSize={10}
                                        className="datagridclass"

                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}


                                    />
                                </Box>
                                <br />

                            </div>

                        </div>
                    </div>}
                </div>}


        </div >
    )
}


export default Partner_Facture;
