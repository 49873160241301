import Navigation from "./Navigation";
import { Helmet } from "react-helmet";

import ousommesnousimg from "../mysy_img/mysy_training_technology_ici.png"
import Fotter_v3 from "./Fotter_v3";
import React, { Component, useState, useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import Button from '@mui/material/Button';
import img_1_old from "../mysy_img/1.jpg";
import gestion_admin_img from "../mysy_img/gestion_admin_img.png";
import e_learning_lms from "../mysy_img/e_learning_lms.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import img_2 from "../mysy_img/lms.png";
import img_3_old from "../mysy_img/3.jpg";
import mysy_crm from "../mysy_img/mysy_crm.png";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player/lazy'
import tick_circle_ok from "../mysy_img/tick_circle_ok.png";

import img_uca from "../mysy_img/uca.png";
import img_5discovery from "../mysy_img/5discovery.png";
import img_aroma from "../mysy_img/aroma.png";
import img_potential from "../mysy_img/potential.png";
import img_capl from "../mysy_img/capl.png";
import img_hr from "../mysy_img/hr.png";


import img_qualiopi from "../mysy_img/qualiopi.png";
import img_solution_complete from "../mysy_img/solution_complete.png";
import img_euro from "../mysy_img/euro.png";
import img_france from "../mysy_img/france.png";

import img_objectif from "../mysy_img/objectif.png";
import img_equipe from "../mysy_img/equipe.png";
import img_force from "../mysy_img/force.png";
import img_vision from "../mysy_img/vision.png";

import moment from "moment";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import pack_independant from "../mysy_img/pack_independant.png";
import pack_standart from "../mysy_img/pack_standart.png";
import pack_gold from "../mysy_img/pack_gold.png";

const QuiSommesNous_v4 = () => {

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    setDialog_contact_open(true);
                }}
            >
                <nav className="contactez_nous_new">  {label} </nav>
            </Link>
        );
    };

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap

            defaultZoom={18}
            defaultCenter={{ lat: 48.866669, lng: 2.33333 }}
        >
            {props.isMarkerShown && <Marker position={{ lat: 48.866669, lng: 2.33333 }} />}
        </GoogleMap>
    ))

    function sendEmail() {
        window.location.assign("mailto:contact@mysy-training.com");

    };

    const [Dialog_contact_message, setDialog_contact_message] = React.useState(false);
    const [Dialog_contact_open, setDialog_contact_open] = React.useState(false);
    function Dialog_contact_handle_change_participant_session(message) {
        setDialog_contact_message(message);
        setDialog_contact_open(true);
    }

    const Dialog_contact_handleClose = () => {

    };

    const Dialog_contact_handleClose_buton = () => {

        setDialog_contact_open(false);
        clear_contact_message_fields();

    };

    function clear_contact_message_fields() {
        setcontact_message_nom_prenom("");
        setcontact_message_email("");
        setcontact_message_phone("");
        setcontact_message_sujet("");
        setcontact_message_sujet("");
        setcontact_message_message("");
    }

    const [contact_message_nom_prenom, setcontact_message_nom_prenom] = React.useState("");
    const [contact_message_email, setcontact_message_email] = React.useState("");
    const [contact_message_phone, setcontact_message_phone] = React.useState("");
    const [contact_message_sujet, setcontact_message_sujet] = React.useState("");
    const [contact_message_message, setcontact_message_message] = React.useState("");

    function Send_Contact_Message(e) {

        var sender_mail = "";
        var sender_tel = "";
        var token = "";

        ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")

        var mysubject = contact_message_sujet;
        var mymessage = contact_message_message;
        var mysender_name = contact_message_nom_prenom;
        var mysender_email = contact_message_email;


        //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
        //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

        var re = /\S+@\S+\.\S+/;
        if (re.test(mysender_email) == false) {
            alert("l'email est incorrecte");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
        var form = new FormData();
        form.append("type", "email");
        form.append("recever_mail", "contact@mysy-training.com");
        form.append("recever_tel", "+33769203945");
        form.append("sender_mail", mysender_email);
        form.append("sender_tel", sender_tel);
        form.append("object", mysubject);
        form.append("message", mymessage);
        form.append("sender_name", mysender_name);
        form.append("token", "");
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        // console.log("## form = ", form);



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("false")) {

                alert(res.data.message);
            }

            else if (String(res.data.status) === String("true")) {
                Dialog_contact_handleClose_buton();
                alert(res.data.message);

            }
            else {

            }
        }).catch((error) => {
            console.warn('contact-nous  Not good man :( mysearchtext = ');


        })


    }
    const [country_code, setcountry_code] = useState("FR");
    const [country_name, setcountry_name] = useState("France");
    const [city, setcity] = useState("Paris");
    const [postal, setpostal] = useState("75001");
    const [latitude, setlatitude] = useState("48.861000061035156");
    const [longitude, setlongitude] = useState("2.3380000591278076");
    const [IPv4, setIPv4] = useState("172.71.122.128");
    const [state, setstate] = useState("France");
    const [userIp, setuserIp] = useState("172.71.122.128");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, []);


    return (

        <div style={{ width: "100%", float: "left" }}>
            <div className="quisommesnous_v4">
                <Helmet>
                    <title>MySy Training, qui sommes nous ?</title>
                    <meta name="description" content="Éditeur Français de logiciels pour organismes de formation. Avec notre solution Tout-en-Un, Nous automatisons les étapes Qualiopi (Inscription, Documents personnalisés, Les émargements sécurisés, Les évaluations, etc). Votre Le bilan pédagogique et financier est généré automatiquement." />
                    <meta name="robots" content="index,follow" />
                    <meta name="title" content="Formations.Gestion administrative.E-Learning.CRM" />
                    <link rel="canonical" href={window.location.href} />

                </Helmet>
                <Header />

                <h1 style={{ fontSize: '0.1px', opacity: '0', height: '0.1px' }}>Éditeur Français de logiciels pour organismes de formation. Avec solutions de E-Learning,
                    gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité.
                    Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents,
                    Gestion formateurs, Catalogue formations personnalisé </h1>
                {/***   GESTION Contact */}
                <Dialog
                    open={Dialog_contact_open}
                    onClose={Dialog_contact_handleClose}
                    className="displaypartnersession"
                >

                    <DialogTitle>Contactez Nous</DialogTitle>
                    <DialogContent className="DialogContent_width">


                        <div className="div_row_dialog">
                            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                                Nom & Prénom
                            </div>
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                                <input
                                    type='text'
                                    name='name'
                                    value={contact_message_nom_prenom}
                                    onChange={(e) => {
                                        setcontact_message_nom_prenom(e.target.value);
                                    }}
                                    className='form-control formInput'
                                    placeholder='Nom'
                                ></input>
                            </div>
                        </div>

                        <div className="div_row_dialog">
                            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                                E-mail
                            </div>
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                <input
                                    type='email'
                                    name='email'
                                    id='email'
                                    value={contact_message_email}
                                    onChange={(e) => {
                                        setcontact_message_email(e.target.value);
                                    }}
                                    className='form-control formInput'
                                    placeholder='Adresse email'
                                ></input>
                            </div>
                        </div>


                        <div className="div_row_dialog">
                            <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                                Téléphone
                            </div>
                            <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                <input
                                    type='text'
                                    name='phone'
                                    id='phone'
                                    value={contact_message_phone}
                                    onChange={(e) => {
                                        setcontact_message_phone(e.target.value);
                                    }}
                                    className='form-control formInput'
                                    placeholder='Numéro Tél'
                                ></input>
                            </div>
                        </div>

                        <div className="div_row_dialog">
                            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                                Sujet
                            </div>
                            <div className="div_row_dialog" >
                                <input
                                    type='text'
                                    name='subject'
                                    value={contact_message_sujet}
                                    onChange={(e) => {
                                        setcontact_message_sujet(e.target.value);
                                    }}
                                    className='form-control formInput'
                                    placeholder='Sujet'
                                ></input>
                            </div>
                        </div>

                        <div className="div_row_dialog">
                            <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                                Votre message
                            </div>
                            <div className="div_row_dialog" >
                                <textarea
                                    rows={3}
                                    name='message'
                                    value={contact_message_message}
                                    onChange={(e) => {
                                        setcontact_message_message(e.target.value);
                                    }}
                                    className='form-control formInput'
                                    placeholder='Message'
                                ></textarea>
                            </div>
                        </div>

                        <div className="div_row_dialog" >


                            <div style={{ fontSize: "small" }}>

                                Adresse : 1 Cr du Havre, 75008 Paris <br />
                                Adresse email :
                                <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                                Téléphone fixe : +331 77 00 38 57<br />
                            </div>

                        </div>


                    </DialogContent>

                    <DialogActions>
                        <div className="div_row">
                            <div className="div_row_gauche">
                                <Button onClick={Dialog_contact_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                            </div>
                            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                                <Button onClick={Send_Contact_Message} className="bton_enreg_dialog">Envoyer&nbsp; <SendIcon /></Button>

                            </div>
                        </div>

                    </DialogActions>


                </Dialog>
                {/***  FIN GESTION Contact */}

                <div className="div_mobile_v3" >


                    <div className="Background-header">
                        <div className="block_centrer">

                            <div className="Title_txt2">
                                Qui sommes nous ?
                            </div>

                            <div className="text_seul_1">
                                MySy Training est un éditeur de logiciels dédiés à la gestion des établissements de formation : <b> Organisme de formation, Indépendants, Ecoles, Universités </b>, etc.
                                <br />
                                Nous conseillons, accompagnons nos clients dans la structuration, l’audit et l’organisation de leurs processus et outils.
                                <br /><br/>
                                Avec une équipe d’une dizaine de personnes, nous développons des solutions axées sur l' <b> efficacité </b> et le <b> gain de productivité </b> de nos clients, sans oublier la <b> sécurité  de leurs données </b>.

                            </div>

                        </div>



                    </div>

                    <div className="block_centrer_new_v2">
                        <div className="div_mobile_v3_debut">

                            <div className="fonctionalite_header">
                                Ils nous ont fait confiance

                            </div>
                            <div style={{ "width": "100%", float: "left" }}>
                                <div className="block_centrer_client">

                                    <img src={img_uca} alt="Université Clermont Auvergne" className="img_uca" />
                                    <img src={img_5discovery} alt="5 discovery" className="img_5discovery" />
                                    <img src={img_aroma} alt="aromathérapie" className="img_aroma" />
                                </div>

                            </div>
                            <div style={{ "width": "100%", float: "left" }}>
                                <div className="block_centrer_client">
                                    <img src={img_potential} alt="Potentials" className="img_potential" />
                                    <img src={img_capl} alt="Groupe CAPL" className="img_capl" />
                                    <img src={img_hr} alt="HR consultant" className="img_hr" />


                                </div>

                            </div>

                            <div className="div_row"> &nbsp; </div>


                            <div className="content_mysy_4_point" >


                                <div className="content_mysy_4_point_txt" >
                                    <div>

                                        <div className="detail_mysy_4_point_text_head_2" id="en_4_points" >
                                            MySy Training Technology en 4 points
                                        </div>

                                        <div className="tableau_mysy_4_point">
                                            <div className="tableau_mysy_4_point_case" >
                                                <div className="tableau_mysy_4_point_case_img">
                                                    <img src={img_france} alt=" Editeur logiciel Français, Basé en Ile de France" className="img_a_retenir" />
                                                </div>

                                                <div className="tableau_mysy_4_point_case_text tableau_mysy_4_point_case_text_editeur_francais">
                                                    Editeur de logiciels Français <br />
                                                    Basé en Ile de France
                                                </div>
                                            </div>

                                            <div className="tableau_mysy_4_point_case" style={{ float: "right", borderRight: "None" }}>

                                                <div className="tableau_mysy_4_point_case_img_droite" >
                                                    <img src={img_qualiopi} alt="Accompagnent à l'obtention et maintien de la certification Qualiopie" className="img_a_retenir" />
                                                </div>
                                                <div className="tableau_mysy_4_point_case_text_droite tableau_mysy_4_point_case_text_qualipoi" >
                                                    <nav style={{ textAlign: "center", "fontFamily": "Arial Black", "color": "#000091", width: "100%" }} >Qualiopi</nav>
                                                    Accompagnement à l'obtention et maintien de la certif.
                                                </div>


                                            </div>

                                            <div className="tableau_mysy_4_point_case" >
                                                <div className="tableau_mysy_4_point_case_img">
                                                    <img src={img_solution_complete} alt="gestion administrative, CRM, E-Learning, Marketplace" className="img_a_retenir" />
                                                </div>
                                                <div className="tableau_mysy_4_point_case_text tableau_mysy_4_point_case_text_tout_en_un">
                                                    Solution Complète pour Organisme de formation <br />
                                                    <font style={{ fontSize: "13px", "fontStyle": "italic", lineHeight: "20px" }}> (Gestion administrative, CRM, E-Learning, Marketplace) </font>
                                                </div>


                                            </div>

                                            <div className="tableau_mysy_4_point_case" style={{ float: "right", borderRight: "None" }}>
                                                <div className="tableau_mysy_4_point_case_img_droite" >
                                                    <img src={img_euro} alt="MySy Training La solution  la moins chère du marché" className="img_a_retenir" />
                                                </div>
                                                <div className="tableau_mysy_4_point_case_text_droite tableau_mysy_4_point_case_text_moins_cher"  >
                                                    La solution  la moins chère du marché
                                                </div>



                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div className="div_row" style={{ border: "none" }} >

                            </div>


                            <div id="gestion_elearning"> &nbsp; </div>

                            <div id="notre_objectif"> &nbsp; </div>
                            <div className="content_gestion_admin">

                                <div className="content_gestion_admin_img">
                                    <div className="image_activite_detail">
                                        <img src={img_objectif} alt="MySy Training Gestion administrative" className="block_img_objectif" />
                                    </div>
                                </div>

                                <div className="content_gestion_admin_txt" >
                                    <div>


                                        <div className="detail_content_text_head_2" >
                                            Notre objectif
                                        </div>

                                        <div className="detail_content_text_head_3">
                                            Notre objectif peut se résumer en ces quelques mots :  <br />
                                            <b> « Une solution tout-en-un personnalisée pour chacun de nos partenaires ». </b> <br />

                                            Notre objectif est de développer des solutions de proximité qui permettront à chacun de nos clients d’être unique, de se distinguer. <br />

                                            Cette approche personnalisée leur permettra de disposer d’une solution adaptée à leur besoin.
                                        </div>


                                    </div>

                                </div>
                            </div>



                            <div className="div_row" style={{ border: "none" }} >

                            </div>



                            <div id="gestion_elearning"> &nbsp; </div>
                            <div className="content_gestion_elearning" >

                                <div className="content_gestion_elearning_img">
                                    <div className="image_activite_detail">
                                        <img src={img_force} alt="MySy Training E-Learning LMS" className="block_img_force" />
                                    </div>
                                </div>

                                <div className="content_gestion_elearning_txt" >
                                    <div>


                                        <div className="detail_content_text_head_2" >
                                            Nos points forts
                                        </div>

                                        <div className="detail_content_text_head_3_gauche">
                                            Avec un marché de plus en plus carentiel, MySy Training Technology se positionne sur les segments suivants :<br />
                                            <ul>
                                                <li> Le tarif le moins cher du marché, à périmètre identique  </li>
                                                <li> L'ensemble des options sont incluses dans le coût d’abonnement.  </li>
                                                <li> Une solution complète pour gérer les établissements de formation (Aucun besoin d’outils externes)  </li>
                                                <li> Accompagnement à l'obtention et au maintien de la certification Qualiopi  </li>
                                            </ul>

                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div className="div_row" style={{ border: "none" }} >
                                <hr className="hr_break" />
                            </div>

                            <div id="gestion_crm"> &nbsp; </div>
                            <div className="content_gestion_admin">

                                <div className="content_gestion_admin_img">
                                    <div className="image_activite_detail">
                                        <img src={img_vision} alt="Créer des systèmes d’information simples, accessibles et sécurisés. " className="bock_img_equipe" />
                                    </div>
                                </div>

                                <div className="content_gestion_admin_txt" >
                                    <div>


                                        <div className="detail_content_text_head_2">
                                            Notre vision
                                        </div>

                                        <div className="detail_content_text_head_3">
                                            Créer des systèmes d’information simples, accessibles et sécurisés.   </div>

                                        <div className="detail_content_text_head_3">
                                            Mais aussi permettre à chaque organisme de formation d’acquérir une solution informatique complète et personnalisée avec les coûts les moins chers du marché.
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>




                        <div className="div_row"> &nbsp; </div>

                        <div className="fonctionalite_header">
                            Découvrez nos formules

                        </div>
                        <div className="div_row">

                            <div className="div_block_fonction">

                                <div className="div_block_fonction_detail">
                                    <div className="div_block_fonction_detail_titre">
                                        Pack Indépendant
                                        <hr className="hr_break" />
                                    </div>

                                    <div className="div_block_fonction_detail_img">
                                        <img src={pack_independant} alt="pack indépendant : Destiné aux organismes de formation qui démarrent et qui moins de 25 sessions de formation par an. Nous automatisons Qualiopi. L’ensemble de nos options : signature électronique, Gestion avis participants, Les émargements sécurisés, les contrats des formateurs, la gestion des financeurs sont inclus dans nos tarifs de base. Le bilan pédagogique et financier (PBF) est généré automatiquement" className="div_block_fonction_detail_img_inside" />
                                        <nav className="div_block_prix" > 150 € HT /mois </nav>
                                    </div>

                                    <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                        <ul className="div_ul_detail_pack">
                                            <li> <b> Jusqu'à 25 Sessions / an </b></li>
                                            <li> Sans engagement</li>
                                            <li> 2 Utilisateurs</li>

                                        </ul>
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        <Button variant="contained"

                                            className="btn_abonnement_v2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = "/Produits-Services/#detail_produit_service";
                                            }}
                                        > En savoir +  </Button>
                                    </div>

                                </div>

                                <div className="div_block_fonction_detail" style={{ background: '#F8F9F9' }}>
                                    <div className="div_block_fonction_detail_titre">
                                        Pack Standard
                                        <hr className="hr_break" />
                                    </div>

                                    <div className="div_block_fonction_detail_img">
                                        <img src={pack_standart} alt="pack standard : Destiné aux organismes de formation qui ont entre 26 et 200 sessions de formation par an. Nous automatisons Qualiopi. L’ensemble de nos options : signature électronique, Gestion avis participants, Les émargements sécurisés, les contrats des formateurs, la gestion des financeurs sont inclus dans nos tarifs de base. Le bilan pédagogique et financier (PBF) est généré automatiquement" className="div_block_fonction_detail_img_inside" />
                                        <nav className="div_block_prix"> 299 € HT/mois </nav>
                                    </div>

                                    <div className="div_block_fonction_detail_desc detail_content_text_head_3">
                                        <ul className="div_ul_detail_pack">
                                            <li> <b> De 26 à 200 Sessions / an </b> </li>
                                            <li> Sans engagement </li>
                                            <li> Utilisateurs illimités </li>
                                        </ul>
                                    </div>



                                    <div style={{ width: '100%' }}>
                                        <Button variant="contained"

                                            className="btn_abonnement_v2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = "/Produits-Services/#detail_produit_service";
                                            }}
                                        > En savoir +  </Button>
                                    </div>
                                </div>

                                <div className="div_block_fonction_detail">
                                    <div className="div_block_fonction_detail_titre">
                                        Pack Gold
                                        <hr className="hr_break" />
                                    </div>

                                    <div className="div_block_fonction_detail_img">
                                        <img src={pack_gold} alt="pack Gold : Destiné aux organismes de formation qui ont plus de 200 sessions de formation par an. Nous automatisons Qualiopi. L’ensemble de nos options : signature électronique, Gestion avis participants, Les émargements sécurisés, les contrats des formateurs, la gestion des financeurs sont inclus dans nos tarifs de base. Le bilan pédagogique et financier (PBF) est généré automatiquement" className="div_block_fonction_detail_img_inside" />
                                        <nav className="div_block_prix"> 599 € HT/mois </nav>
                                    </div>

                                    <div className="div_block_fonction_detail_desc detail_content_text_head_3">

                                        <ul className="div_ul_detail_pack">
                                            <li><b> A partir de 201 Sessions / an </b> </li>
                                            <li>Sans engagement </li>
                                            <li>Utilisateurs illimités </li>
                                        </ul>
                                    </div>

                                    <div style={{ width: '100%' }}>
                                        <Button variant="contained"

                                            className="btn_abonnement_v2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.location.href = "/Produits-Services/#detail_produit_service";
                                            }}
                                        > En savoir +  </Button>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="div_row"> &nbsp; </div>

                        <div className="fonctionalite_header">
                            Nous contacter
                        </div>

                        <div className="block_contact">
                            <div className="block_contact_formulaire">

                                <div className="displaypartnersession">
                                    <div style={{ width: "100%", float: "left" }}>
                                        <div style={{ width: "100%", float: "left" }}>

                                            <div className="div_row_dialog">
                                                <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                                                    Nom & Prénom
                                                </div>
                                                <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                                                    <input
                                                        type='text'
                                                        name='name'
                                                        value={contact_message_nom_prenom}
                                                        onChange={(e) => {
                                                            setcontact_message_nom_prenom(e.target.value);
                                                        }}
                                                        className='form-control formInput'
                                                        placeholder='Nom'
                                                    ></input>
                                                </div>
                                            </div>

                                            <div className="div_row_dialog">
                                                <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                                                    E-mail
                                                </div>
                                                <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                                    <input
                                                        type='email'
                                                        name='email'
                                                        id='email'
                                                        value={contact_message_email}
                                                        onChange={(e) => {
                                                            setcontact_message_email(e.target.value);
                                                        }}
                                                        className='form-control formInput'
                                                        placeholder='Adresse email'
                                                    ></input>
                                                </div>
                                            </div>


                                            <div className="div_row_dialog">
                                                <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                                                    Téléphone
                                                </div>
                                                <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                                                    <input
                                                        type='text'
                                                        name='phone'
                                                        id='phone'
                                                        value={contact_message_phone}
                                                        onChange={(e) => {
                                                            setcontact_message_phone(e.target.value);
                                                        }}
                                                        className='form-control formInput'
                                                        placeholder='Numéro Tél'
                                                    ></input>
                                                </div>
                                            </div>

                                            <div className="div_row_dialog">
                                                <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                                                    Sujet
                                                </div>
                                                <div className="div_row_dialog" >
                                                    <input
                                                        type='text'
                                                        name='subject'
                                                        value={contact_message_sujet}
                                                        onChange={(e) => {
                                                            setcontact_message_sujet(e.target.value);
                                                        }}
                                                        className='form-control formInput'
                                                        placeholder='Sujet'
                                                    ></input>
                                                </div>
                                            </div>

                                            <div className="div_row_dialog">
                                                <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                                                    Votre message
                                                </div>
                                                <div className="div_row_dialog" >
                                                    <textarea
                                                        rows={3}
                                                        name='message'
                                                        value={contact_message_message}
                                                        onChange={(e) => {
                                                            setcontact_message_message(e.target.value);
                                                        }}
                                                        className='form-control formInput'
                                                        placeholder='Message'
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="div_row_dialog" >

                                                <div className="block_bouton_gauche">
                                                    <Button onClick={Dialog_contact_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                                                </div>
                                                <div className="block_bouton_droite" style={{ "textAlign": 'center' }}>
                                                    <Button onClick={Send_Contact_Message} className="bton_enreg_dialog">Envoyer&nbsp; <SendIcon /></Button>

                                                </div>

                                            </div>


                                        </div>



                                    </div>
                                </div>

                                <div className="div_row_dialog" >
                                    &nbsp;
                                    <br />
                                    <br />

                                </div>
                            </div>
                            <div className="block_contact_mysy">

                                <div className="contactez_nous_titre">&nbsp;</div>

                                <div className="contactez_nous_info">

                                    Adresse : 1 Cr du Havre, 75008 Paris <br />
                                    Adresse email :
                                    <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                                    Téléphone fixe : +331 77 00 38 57<br />

                                </div>
                                <br />
                                <div className="contactez_nous_info"> Sur nos reseaux sociaux

                                    <div style={{ 'float': "center", "textAlign": "center" }}>
                                        <div className="media_sociaux">
                                            <FacebookShareButton
                                                url={"https://www.facebook.com/MySy-Training-114572794561371/"}
                                            >
                                                <SocialIcon network="facebook" className="SocialIcon" />
                                            </FacebookShareButton>
                                            &nbsp;

                                            <TwitterShareButton
                                                url={"https://twitter.com/mysy_training"}
                                                hashtag={"#MySyTraining"}
                                            >
                                                <SocialIcon network="twitter" className="SocialIcon" />
                                            </TwitterShareButton>

                                            &nbsp;
                                            <LinkedinShareButton
                                                url={"https://www.linkedin.com/"}
                                                summary={"ee"}
                                                source={"https://www.linkedin.com/in/mysy-training-96b53a237/"}

                                            >
                                                <SocialIcon network="linkedin" className="SocialIcon" />
                                            </LinkedinShareButton>


                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>



                </div>

            </div>


            <div style={{ height: "5rem", width: "100%" }}>
                <Fotter_v3 />
            </div>
        </div>

    )
}


export default QuiSommesNous_v4;
