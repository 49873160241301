import React from "react";
 
  
const ToggleSwitch = ({ label, checked }) => {
  return (
    <div className="toggleswitch">
    <div className="container">
      <nav style={{"width":"50%", "float":"left", "textAlign":'right',"paddingTop":'10px'}}>{label}{" "}  </nav>
      <nav  style={{"width":"50%", "float":"right", "textAlign":'right', }}>
        <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} checked={checked} />


        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
      </nav>
    </div>
    </div>
  );
};
  
export default ToggleSwitch;