import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import Partner from "./Partner";
import { useHistory } from "react-router-dom";
import Facture from "./Facture";
import { FaHandPointRight } from "react-icons/fa";

function OrderConfirmation(props) {
    const [menu, setmenu] = useState("");
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, []);

    function GoToInvoice() {
        setmenu("facture");
    }

    return (
        <div className="orderconfirmation">
            {String(menu) === "facture" && <div>
                <Facture currentpack={String(props.packs)} />
            </div>
            }

            {String(menu) !== "facture" && <div>

                <h2 style={{ "textAlign": "center", "color": "green", "marginTop": "3rem" }}> Commande numéro :  {props.num_cmd} </h2>

            {String(props.ok_order) === "1" &&  <div className="div_row_central">
                    Votre commande a été validée avec succès.<br />
                    Votre pack <b> {String(props.packs).toLocaleUpperCase()}  </b>se mettra à jour dès que vous aurez actualisé votre page.<br />
                    Merci d'avoir choisi MySy Training.

                    <br />
                    <br />
                    Votre facture est disponible dans votre espace personnel <Button  onClick={GoToInvoice}> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; &nbsp; ici</Button>

                    <br />
                    <br />
                    Vous pouvez dès à present commencer à utiliser nos services.<br />
                    Si vous avez d'aider n'hesitez pas à contacter notre équipe support par le chatbot (en bas de votre écran) ou mail.
                    <br />
                </div>}

                {String(props.ok_order) !== "1" &&  <div className="div_row_central koUpdateData">
                    Impossible de trouver la commnde {String(props.num_cmd)} .<br />
                    Merci de contacter notre équipe support par le chatbot (en bas de votre écran) ou mail.
                   
                    <br />
                      
                </div>}

                <div className="div_row_central">
                    <Button variant="contained" color="success" className="div_row22 btn_enreg_cmd" onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "/Partner";
                    }}>Retour vers mon compte</Button>

                </div>
            </div>}

        </div>
    );

}

export default OrderConfirmation;