import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import partnerbannerimg from "../mysy_img/MYSY_banner_compte.png";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { useCookies } from "react-cookie";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import { Helmet } from "react-helmet";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Header from "./Header";


function PartnerSignIn() {
    const [isconnected, setisconnected] = useState("");
    const [cookie_acces_right, setCookie_acces_right, removeCookie_acces_right] = useCookies(['mysy_user_access_right']);
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
    const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);

    const url_login = process.env.REACT_APP_API_URL + "myclass/api/partner_login/";

    const {

        formState: { errors },

    } = useForm();
    const [result, setResult] = useState("");

    const history = useHistory();

    function logout_partner_confirmation() {
        removeCookie_part('tokenmysypart', { path: '/' });
    }

    function Logout_Partenaire() {
        confirmAlert({
            title: 'Deconnexion Partenaire ?',
            message: 'Confirmez la deconnexion partenaire',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_partner_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }


    function logout_user_confirmation() {
        removeCookie_part('tokenmysych', { path: '/' });
    }

    function Logout_User() {
        confirmAlert({
            title: 'Deconnexion utilisateur ?',
            message: 'Confirmez la deconnexion utilisateur',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_user_confirmation()
                },
                /* {
                     label: 'Non',
 
                 }*/
            ]
        });
    }


    const onSubmit = async (data) => {
        const formData = new FormData();

        // Gestion des Cookies
        const stored_partner = cookie_part.tokenmysypart;
        const stored_user = cookie.tokenmysych;



        if (typeof (stored_partner) != "undefined" && String(stored_partner) !== String('')) {
            //alert(" c'est une connexion PARTTT  qui est active");
            Logout_Partenaire();

        }

        // si c'est une connexion user qui est active
        if (typeof (stored_user) != "undefined" && String(stored_user) !== String('')) {
            //alert(" c'est une connexion USERR  qui est active");
            Logout_User();
        }

        var mymail = document.getElementsByName("email_partner")[0].value;
        var mypwd = document.getElementsByName("pwd_partner")[0].value;
        //var mysecret = document.getElementsByName("secret_partner")[0].value;


        formData.append("email", mymail);
        formData.append("pwd", mypwd);

        fetch(
            url_login,
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                var val = result['status'];

                if (String(val) === String("true")) {
                    sessionStorage.setItem("token", result['message']);
                    setCookie("tokenmysypart", result['message'], { path: '/' });
                    var acces_right_token_name = String(JSON.parse(result['message']).token) + "_uar";
                    var acces_right_token_val = JSON.parse(result['message']).user_access_right;

                    //acces_right_token_val = [{'key1':'val1'}, {'key2':'val2'}];

                    ///console.log(" acces_right_token_val = ", acces_right_token_val);

                    setCookie_acces_right(acces_right_token_name, acces_right_token_val, { path: '/' });

                    history.push("/Partner");
                }
                else {
                    setisconnected("false");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setisconnected("false");

            });
    }


    const onError = () => {
        //console.log("error");
    };


    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onSubmit();
            }
        };
        document.addEventListener("keydown", listener);
    }, []);

    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };

    return (
        <div className="partnersignin partnersigninfirst">
            <Helmet>
                <title>Recherche articles formation</title>
                <meta name="description" content="Liste des articles sur les formations" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />
            <div className="div_mobile">
                <h1 className="text_welcome"> Première connexion à Mysy Training Pro </h1>

                <div className="display_screen_600_901">

                    <div className="div_row">

                        <div className="div_gauche_local">
                            <nav><b> Pour bien démarrer (1/2)</b></nav>
                            <nav> <i>Vous êtes à la première étape sur deux </i> </nav>
                            <hr />


                            <ul>
                                <li style={{ "paddingBottom": "1rem" }}> <b> &#62;&#62;  Etape 1 : Un courriel d'activation vous a été envoyé par email. Activez votre compte à partir de ce courriel. &#60;&#60;  </b></li>
                                <li style={{ "paddingBottom": "1rem" }}> Etape 2 : Récuperez votre clé secréte envoyée dans un second email. Elle est unique et est nécessaire pour administrer votre compte
                                    ( Et pensez à regarder vos mails indésirables/vos spams !). <br />&nbsp;</li>
                            </ul>


                        </div>

                        {/* <div className="div_droite">
                            <nav className="connect"> Connexion Partenaire </nav>
                            <hr />


                            <FormGroup>
                                <Label>Email</Label>
                                <Input type="email" placeholder="Email" name="email_partner" />
                            </FormGroup>

                            <FormGroup>
                                <Label>Password</Label>
                                <Input type="password" placeholder="Password" name="pwd_partner" />
                            </FormGroup>

                        
                            <div className="div_row22">
                                <Button variant="contained" color="success" className="div_row22 btn_login button" onClick={onSubmit}>Connectez-Vous</Button>
                            </div>


                        </div>*/}
                        {isconnected === "false" && <div className="erreurconnexion">
                            <br />
                            Erreur Connexion</div>
                        }
                    </div>

                </div>
                <div className="pieddepage">
                    <Footer />
                </div>

            </div>
        </div>
    );
}

export default PartnerSignIn;
