import React, { useRef, useState, useEffect } from "react"; 
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcPlanner } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash, CiBoxList } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import allLocales from '@fullcalendar/core/locales-all'
import { RepeatOnRounded, RestaurantMenu } from "@mui/icons-material";
import { alignPropType } from "react-bootstrap/esm/types";

import ToggleSwitch from "./ToggleSwitch";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";

const Module_Absence = (props) => {
    const myconntected_employee_id = props.conntected_employee_id;

    const attendee_email = props.attendee_email;

    const [selected_employee_id, setselected_employee_id] = useState(props.conntected_employee_id);

    const [selected_attendee_email, setselected_attendee_email] = useState(props.attendee_email);
    const [selected_related_collection, setselected_related_collection] = useState(props.related_collection);

    const history = useHistory();
    const [isLoading, setLoading] = useState();


    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'event_type', headerName: 'Type', flex: 1, hide: false, editable: false },
        { field: 'event_title', headerName: 'Titre', flex: 1, hide: false, editable: false, },
        {
            field: 'event_start', headerName: 'Debut', flex: 1, hide: false, editable: false,
            valueFormatter: (params) => {
                return moment(params.value).format('D/MM/YYYY - H:mm');
            },
        },
        {
            field: 'event_end', headerName: 'Fin', flex: 1, hide: false, editable: false,
            valueFormatter: (params) => {
                return moment(params.value).format('D/MM/YYYY - H:mm');
            },
        },
        {
            field: 'justified', headerName: 'Justifié', flex: 1, hide: false, editable: false,
            renderCell: (params) => {
                if (String(params.value) === "true")
                    return <FcApproval />
                else
                    return ""
            },
        },

        { field: 'event_comment', headerName: 'Comment', flex: 1, hide: true, editable: false, },


        /*{
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (
 
                    <Popup
                        trigger={<Button
 
                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />
 
                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}
 
                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />
 
                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>
 
                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>
 
 
                );
            }
        } */

    ]


    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [p_filtre1, setp_filtre1] = useState("ressource_humaine");
    const [p_filtre1_value, setp_filtre1_value] = useState("ressource_humaine");

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const myRef = useRef(null)

    const [mysy_events, setmysy_events] = useState();

    const [mysy_list_event_for_map, setmysy_list_event_for_map] = useState();

    const [Get_List_Agenda_Events_api, setGet_List_Agenda_Events_api] = useState();
    const [Get_List_Agenda_Events_message, setGet_List_Agenda_Events_message] = useState();
    const [Get_List_Agenda_Events_result, setGet_List_Agenda_Events_result] = useState();
    function Get_List_Agenda_Events(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", props.related_collection);
        form.append("related_collection_email", selected_attendee_email);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List_Stagiaire/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Agenda_Events  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_api("true");
                setGet_List_Agenda_Events_result(res.data.message);
                setmysy_list_event_for_map(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {}

                    if (JSON.parse(obj).comment) {
                        local_val = {
                            'title': String(JSON.parse(obj).event_title),
                            'start': String(JSON.parse(obj).event_start),
                            'end': String(JSON.parse(obj).event_end),
                            'id': String(JSON.parse(obj)._id),

                            'type': String(JSON.parse(obj).event_type),
                            'justified': String(JSON.parse(obj).justified),
                        }
                    } else {
                        local_val = {
                            'title': String(JSON.parse(obj).event_title),
                            'start': String(JSON.parse(obj).event_start),
                            'end': String(JSON.parse(obj).event_end),
                            'id': String(JSON.parse(obj)._id),
                            'comment': "",
                            'type': String(JSON.parse(obj).event_type),
                            'justified': String(JSON.parse(obj).justified),
                        }

                    }
                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);



            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_api("false");
                setGet_List_Agenda_Events_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Agenda_Events = ', error);
            setGet_List_Agenda_Events_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Connected_User_List_Agenda_Events_api, setGet_Connected_User_List_Agenda_Events_api] = useState();
    const [Get_Connected_User_List_Agenda_Events_message, setGet_Connected_User_List_Agenda_Events_message] = useState();
    const [Get_Connected_User_List_Agenda_Events_result, setGet_Connected_User_List_Agenda_Events_result] = useState();
    function Get_Connected_User_List_Agenda_Events(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", props.related_collection);
        form.append("related_collection_email", attendee_email);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List_Stagiaire/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Connected_User_List_Agenda_Events  res.data.status = " + res.data.status);
            //console.log(" In Get_Connected_User_List_Agenda_Events  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_Connected_User_List_Agenda_Events_api("true");
                setGet_Connected_User_List_Agenda_Events_result(res.data.message);
                setmysy_list_event_for_map(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {}
                    if (JSON.parse(obj).comment) {
                        local_val = {
                            'title': String(JSON.parse(obj).event_title),
                            'start': String(JSON.parse(obj).event_start),
                            'end': String(JSON.parse(obj).event_end),
                            'id': String(JSON.parse(obj)._id),
                            'comment': String(JSON.parse(obj).comment),
                            'type': String(JSON.parse(obj).event_type),
                            'justified': String(JSON.parse(obj).justified),
                        }
                    } else {
                        local_val = {
                            'title': String(JSON.parse(obj).event_title),
                            'start': String(JSON.parse(obj).event_start),
                            'end': String(JSON.parse(obj).event_end),
                            'id': String(JSON.parse(obj)._id),
                            'comment': "",
                            'type': String(JSON.parse(obj).event_type),
                            'justified': String(JSON.parse(obj).justified),
                        }

                    }
                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Connected_User_List_Agenda_Events_api("false");
                setGet_Connected_User_List_Agenda_Events_message(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_Connected_User_List_Agenda_Events = ', error);
            setGet_Connected_User_List_Agenda_Events_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_List_Agenda_Events_For_Given_User_api, setGet_List_Agenda_Events_For_Given_User_api] = useState();
    const [Get_List_Agenda_Events_For_Given_User_message, setGet_List_Agenda_Events_For_Given_User_message] = useState();
    const [Get_List_Agenda_Events_For_Given_User_result, setGet_List_Agenda_Events_For_Given_User_result] = useState();
    function Get_List_Agenda_Events_For_Given_User(local_selected_attendee_email) {

        var local_related_collection = p_filtre1;


        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", props.related_collection);
        form.append("related_collection_email", local_selected_attendee_email);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Agenda_Event_List_Stagiaire/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Agenda_Events_For_Given_User  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Agenda_Events_For_Given_User  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Agenda_Events_For_Given_User_api("true");
                setGet_List_Agenda_Events_For_Given_User_result(res.data.message);
                setmysy_list_event_for_map(res.data.message);

                var tab_event = []
                var filtre2 = res.data.message.map(obj => {

                    var local_val = {}
                    if (JSON.parse(obj).comment) {
                        local_val = {
                            'title': String(JSON.parse(obj).event_title),
                            'start': String(JSON.parse(obj).event_start),
                            'end': String(JSON.parse(obj).event_end),
                            'id': String(JSON.parse(obj)._id),
                            'comment': String(JSON.parse(obj).comment),
                            'type': String(JSON.parse(obj).event_type),
                            'justified': String(JSON.parse(obj).justified),
                        }
                    } else {
                        local_val = {
                            'title': String(JSON.parse(obj).event_title),
                            'start': String(JSON.parse(obj).event_start),
                            'end': String(JSON.parse(obj).event_end),
                            'id': String(JSON.parse(obj)._id),
                            'comment': "",
                            'type': String(JSON.parse(obj).event_type),
                            'justified': String(JSON.parse(obj).justified),
                        }

                    }
                    tab_event.push(local_val);



                });
                setmysy_events(tab_event);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Agenda_Events_For_Given_User_api("false");
                setGet_List_Agenda_Events_For_Given_User_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Agenda_Events_For_Given_User = ', error);
            setGet_List_Agenda_Events_For_Given_User_api("false");
            //setmyApimyApiMessage("")
        })
    }



    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Ressource_Data(local_selected_attendee_email) {

        var local_related_collection = p_filtre1;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", props.related_collection);
        form.append("related_collection_email", local_selected_attendee_email);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_Ressource_Info_Data_Satgiaire/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Ressource_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Ressource_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);
                var nom_prenom = "";


                if (res.data.message.length > 0) {
                    var mylocal_employe = JSON.parse(res.data.message);


                    if (JSON.parse(res.data.message).nom) {
                        nom_prenom = JSON.parse(res.data.message).nom;
                    }

                    if (JSON.parse(res.data.message).prenom) {
                        nom_prenom = String(nom_prenom) + " " + String(JSON.parse(res.data.message).prenom);
                    }


                }
                setagenda_owner_name(nom_prenom);


                setagenda_owner_type(" Stagiaire");


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Ressource_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de l'employé");
            //setmyApimyApiMessage("")
        })
    }


    const myEventsList = []
    const [events, setEvents] = useState([]);
    const handleSelect = (info) => {

        if (String(selected_attendee_email).length < 2) {
            alert(" Vous devez choisir un stagiaire");
            return;
        }


        setp_detail_title("Nouvelle")
        setp_detail_start(info.start)
        setp_detail_end(info.end)
        setp_detail_comment("");
        setp_detail_event_id("");
        setp_detail_justified(false);
        setp_detail_event_type("");

        setStartDate(info.start);
        setendDate(info.end);
        setDialog_1_open(true);




        /*const { start, end } = info;
        const eventNamePrompt = prompt("Enter, Nom evenment");
        if (eventNamePrompt) {
    
            setEvents([
                ...events,
                {
                    start,
                    end,
                    title: eventNamePrompt,
                    id: uuid(),
                },
            ]);
        }*/
    };


    const handleSelect_list = (info) => {

        if (String(selected_attendee_email).length < 2) {
            alert(" Vous devez choisir un stagiaire");
            return;
        }

        var new_start_date = new Date();
        var new_end_date = new Date();

        setp_detail_title("Nouvelle")
        setp_detail_start(new_start_date)
        setp_detail_end(new_start_date)
        setp_detail_comment("");
        setp_detail_event_id("");
        setp_detail_justified(false);
        setp_detail_event_type("");

        setStartDate(new_start_date);
        setendDate(new_start_date);
        setDialog_1_open(true);


    };


    const renderEventContent = (eventInfo) => {
        //console.log(eventInfo);
        return (
            <>
                <b>{eventInfo.timeText}</b><br />
                <i>{eventInfo.event.title}</i>
            </>
        )
    };

    const handleEventClick = (arg) => {

        if (String(selected_attendee_email).length < 2) {
            alert(" Vous devez choisir un stagiaire");
            return;
        }


        setp_detail_title(arg.event.title)
        setp_detail_start(arg.event.start)
        setp_detail_end(arg.event.end)
        setp_detail_event_id(arg.event.id);

        if (arg.event.extendedProps.comment) {
            setp_detail_comment(arg.event.extendedProps.comment);
        }
        else
            setp_detail_comment("");


        if (arg.event.extendedProps.justified) {
            if (String(arg.event.extendedProps.justified) === "true")
                setp_detail_justified(true);

            else
                setp_detail_justified(false);


        }
        else
            setp_detail_justified(false);



        if (arg.event.extendedProps.type) {
            setp_detail_event_type(arg.event.extendedProps.type);
        }
        else
            setp_detail_event_type("");


        setStartDate(arg.event.start);
        setendDate(arg.event.end);
        setDialog_1_open(true);

    }



    const handleEventClick_from_list = (selected_row_id) => {

        var line = JSON.parse(mysy_list_event_for_map[selected_row_id]);



        if (String(selected_attendee_email).length < 2) {
            alert(" Vous devez choisir un stagiaire");
            return;
        }


        setp_detail_title(line.event_title)
        setp_detail_start(line.event_start)
        setp_detail_end(line.event_end)
        setp_detail_event_id(line._id);

        if (line.comment) {
            setp_detail_comment(line.comment);
        }
        else
            setp_detail_comment("");


        if (line.justified) {
            if (String(line) === "true")
                setp_detail_justified(true);

            else
                setp_detail_justified(false);


        }
        else
            setp_detail_justified(false);



        if (line.type) {
            setp_detail_event_type(line.type);
        }
        else
            setp_detail_event_type("");

        console.log("  (line.event_start =", line.event_start);



        var new_start_date = new Date(moment(line.event_start, "YYYY/MM/DD HH:mm"));
        var new_end_date = new Date(moment(line.event_end, "YYYY/MM/DD HH:mm"));


        setStartDate(new_start_date);
        setendDate(new_end_date);
        setDialog_1_open(true);

    }


    function Close_Dialog_Detail_Event_open() {
        setp_detail_title("Nouvelle")
        setp_detail_start()
        setp_detail_end()
        setp_detail_comment("");
        setp_detail_event_id("");
        setp_detail_justified(false);
        setp_detail_event_type("");
        setDialog_1_open(false);
    }



    const [Partner_Debut_Journee, setPartner_Debut_Journee] = useState("7:00");
    const [Partner_Fin_Journee, setPartner_Fin_Journee] = useState("20:00");


    const [Get_Partner_Debut_Fin_Journee_api, setGet_Partner_Debut_Fin_Journee_api] = useState();
    const [Get_Partner_Debut_Fin_Journee_message, setGet_Partner_Debut_Fin_Journee_message] = useState();
    const [Get_Partner_Debut_Fin_Journee_result, setGet_Partner_Debut_Fin_Journee_result] = useState();
    function Get_Partner_Debut_Fin_Journee(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Default_Debut_Fin_journee_Modele_Journee/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Partner_Debut_Fin_Journee  res.data.status = " + res.data.status);
            //console.log(" In Get_Partner_Debut_Fin_Journee  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_Partner_Debut_Fin_Journee_api("true");
                setGet_Partner_Debut_Fin_Journee_result(res.data.message);


                var val_json = JSON.parse(res.data.message);

                if (JSON.parse(res.data.message).planning_debut_journee)
                    setPartner_Debut_Journee(String(JSON.parse(res.data.message).planning_debut_journee))

                if (JSON.parse(res.data.message).planning_fin_journee)
                    setPartner_Fin_Journee(String(JSON.parse(res.data.message).planning_fin_journee))


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_Partner_Debut_Fin_Journee_api("false");
                setGet_Partner_Debut_Fin_Journee_message(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_Partner_Debut_Fin_Journee = ', error);
            setGet_Partner_Debut_Fin_Journee_api("false");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Get_Connected_User_List_Agenda_Events();
        Get_Partner_Debut_Fin_Journee();
        //Getall_Training_Employee_No_Filter();
        //Getall_Training_Materiel_No_Filter();

    }, [attendee_email])



    const [p_detail_event_id, setp_detail_event_id] = useState();
    const [p_detail_title, setp_detail_title] = useState();
    const [p_detail_start, setp_detail_start] = useState();
    const [p_detail_end, setp_detail_end] = useState();
    const [p_detail_comment, setp_detail_comment] = useState();

    const [p_detail_justified, setp_detail_justified] = useState(false);
    const [p_detail_event_type, setp_detail_event_type] = useState();


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());





    const [Update_One_Agenda_Event_api, setUpdate_One_Agenda_Event_api] = useState();
    const [Update_One_Agenda_Event_message, setUpdate_One_Agenda_Event_message] = useState();
    const [Update_One_Agenda_Event_result, setUpdate_One_Agenda_Event_result] = useState();
    const Update_One_Agenda_Event = event => {

        var local_related_collection = p_filtre1;

        if (String(selected_attendee_email).length < 2) {
            alert(" Vous devez un stagiaire");
            return;
        }

        if (moment(endDate) <= moment(startDate)) {
            alert(" Les dates de début et de fin sont incohérentes");
            return;
        }
        var new_title = p_detail_title;
        var new_start_date = moment(startDate).format();
        var new_end_date = moment(endDate).format();
        var event_id = p_detail_event_id

        //console.log(" ### new event = ", new_title, " -- ", new_start_date, " --- ", new_end_date, " : setp_detail_event_id = ", p_detail_event_id);


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_event_id);
        form.append("inscription_id", props.inscription_id);
        form.append("related_collection", props.related_collection);

        form.append("event_title", new_title);
        form.append("event_start", new_start_date);
        form.append("event_end", new_end_date);
        form.append("related_collection_email", selected_attendee_email);
        form.append("comment", p_detail_comment);

        form.append("event_type", p_detail_event_type);
        form.append("justified", p_detail_justified);



        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Agenda_Event_Stagiaire/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Agenda_Event  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Agenda_Event  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_One_Agenda_Event_api("true");
                setUpdate_One_Agenda_Event_result(res.data.message);

                Get_List_Agenda_Events_For_Given_User(selected_attendee_email);

                setp_detail_event_id();
                setp_detail_title();
                setp_detail_start();
                setp_detail_end();
                setp_detail_comment();
                setp_detail_justified(false);
                setp_detail_event_type();


                alert(res.data.message);
                Close_Dialog_Detail_Event_open();

            }
            else {
                setUpdate_One_Agenda_Event_api("false");
                setUpdate_One_Agenda_Event_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Agenda_Event = ' + error);
            setUpdate_One_Agenda_Event_api("false");
            alert(" Impossible de mettre à jour l'événement ");

        })
    }


    const [Delete_One_Agenda_Event_api, setDelete_One_Agenda_Event_api] = useState();
    const [Delete_One_Agenda_Event_message, setDelete_One_Agenda_Event_message] = useState();
    const [Delete_One_Agenda_Event_result, setDelete_One_Agenda_Event_result] = useState();
    function Delete_One_Agenda_Event() {


        var event_id = p_detail_event_id

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", p_detail_event_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Agenda_Event/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_One_Agenda_Event  res.data.status = " + res.data.status);
            //console.log(" In Delete_One_Agenda_Event  res.data.message r_class = " + res.data.message);


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_One_Agenda_Event_api("true");
                setDelete_One_Agenda_Event_result(res.data.message);

                Get_List_Agenda_Events();

                setp_detail_event_id();
                setp_detail_title();
                setp_detail_start();
                setp_detail_end();
                setp_detail_comment();

                setp_detail_justified(false);
                setp_detail_event_type();


                alert(res.data.message);
                Close_Dialog_Detail_Event_open();

            }
            else {
                setDelete_One_Agenda_Event_api("false");
                setDelete_One_Agenda_Event_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);

            console.warn('UpdateStagiaireData : Not good man :( Delete_One_Agenda_Event = ' + error);
            setDelete_One_Agenda_Event_api("false");
            alert(" Impossible de supprimer l'événement ");

        })
    }



    const [display_other_agenda, setdisplay_other_agenda] = useState();

    const [agenda_owner_name, setagenda_owner_name] = useState("Mon Planning");
    const [agenda_owner_type, setagenda_owner_type] = useState("Employe");


    const [display_view, setdisplay_view] = useState("planning");
    function func_display_planning_view() {
        setdisplay_view("planning");
    }

    function func_display_list_view() {
        setdisplay_view("list");
    }

 

    return (
        <div className="module_agenda">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

                className="module_agenda"

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" >

                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Type </nav>

                        <nav style={{ "float": "right", "width": "65%" }}>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_type"
                                id="event_dialog_type"

                                select
                                fullWidth

                                value={p_detail_event_type}
                                onChange={(e) => {
                                    if (String(props.write_access) === "1") {
                                        setp_detail_event_type(e.target.value);

                                    }
                                }
                                }

                            >
                                <MenuItem value="absence" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Absence &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="planning" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Planning &nbsp;</MenuItem>
                                <MenuItem value="autre" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Autre &nbsp;</MenuItem>

                            </TextField>

                        </nav>
                    </div>


                    <div className="session_caract_Dialog" >
                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Justifié ? </nav>

                        <nav style={{ "float": "right", "width": "65%" }}
                            onClick={(e) => {
                                if (p_detail_justified === true)
                                    setp_detail_justified(false);
                                else
                                    setp_detail_justified(true);

                            }
                            }

                            id="toggleSwitchNav" name="toggleSwitchNav">
                            <ToggleSwitch id="toggleSwitch" name="toggleSwitch" checked={p_detail_justified}
                            />
                        </nav>
                    </div>


                    <div className="session_caract_Dialog" > Titre
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={p_detail_title}
                            onChange={(e) => {
                                if (String(props.write_access) === "1")
                                    setp_detail_title(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > Debut
                        <DatePicker
                            name="event_dialog_start"
                            id="event_dialog_start"
                            selected={startDate}
                            onChange={(date) => {
                                if (String(props.write_access) === "1")
                                    return setStartDate(date);
                            }
                            }


                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>


                    <div className="session_caract_Dialog" > Fin
                        <DatePicker
                            name="event_dialog_end"
                            id="event_dialog_end"
                            selected={endDate}

                            onChange={(date) => {
                                if (String(props.write_access) === "1")
                                    return setendDate(date);
                            }
                            }


                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_comment"
                            id="event_dialog_comment"

                            fullWidth

                            value={p_detail_comment}
                            onChange={(e) => {
                                if (String(props.write_access) === "1")
                                    setp_detail_comment(e.target.value);
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        {String(props.write_access) === "1" && <div className="div_row_gauche">
                            {p_detail_event_id && String(p_detail_event_id) !== "" && <Button onClick={Update_One_Agenda_Event} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {(!p_detail_event_id || String(p_detail_event_id) === "") && <Button onClick={Update_One_Agenda_Event} className="bton_enreg_dialog">Ajouter</Button>}



                        </div>}
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Event_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>




                {String(props.write_access) === "1" && p_detail_event_id && String(p_detail_event_id) !== "" && <DialogActions>
                    <div className="div_row" style={{ "textAlign": "center" }}>
                        <Button onClick={(e) => {
                            const response = window.confirm("Confirmez-vous la suppression de l'événement ?");
                            if (!response) {
                                return;
                            }
                            Delete_One_Agenda_Event();
                        }
                        } className="bton_supprime_dialog">Supprimer l'événement</Button>
                    </div>


                </DialogActions>}

            </Dialog>

            <h3> Planning </h3>

            <div style={{ "textAlign": "right", "float": "right", "width": "100%" }}>
                Affichage : <nav className="mode_affichage" onClick={func_display_planning_view}> <FcPlanner /> planning </nav>
                <nav className="mode_affichage" onClick={func_display_list_view}> <CiBoxList /> Liste </nav>
            </div>

           
            {String(display_view) === "planning" && <div style={{ "width": "98%", "marginLeft": "5px" }}>
                &nbsp;

                <FullCalendar
                    locales={allLocales}
                    locale={'fr'}
                    editable
                    selectable
                    //events={events}
                    events={mysy_events}

                    select={handleSelect}
                    headerToolbar={{
                        start: "today prev next",
                        center: "title",
                        end: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    //eventContent={(info) => <EventItem info={info} />}
                    //plugins={[timeGridPlugin]}
                    plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                    slotMinTime={Partner_Debut_Journee}
                    slotMaxTime={Partner_Fin_Journee}
                    scrollTime='08:00'
                    displayEventEnd={true}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}


                />



            </div>}


            {String(display_view) === "list" && <div style={{ "width": "98%", "marginLeft": "5px" }}>
                &nbsp;


                <div className="div_row">
                    <div className="session_data">
                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',

                                        // Gestion des cellule "statut"
                                        '& .cell--status--encours': {
                                            backgroundColor: '#E6F7C8',
                                            color: '#1a3e72',
                                            fontWeight: 'light',
                                        },
                                        '& .cell--status--traite': {
                                            backgroundColor: '#E6CEAA',
                                            color: '#F9E79F',
                                            fontWeight: 'light',
                                        },




                                        /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                            "& .MuiDataGrid-row": {
                                                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                            }
                                        },*/
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                            /* if (newSelectionModel.length === 1)
                                                 handleClick_edit_config_From_Line(newSelectionModel);
                                             if (newSelectionModel.length !== 1) {
                                                 setp_detail_config_valeur();
                                                 setp_detail_config_point();
                                                 setConfig_data_changed();
                                                 setconfig_data_edit_mode();
                                                 setdisplay_detail_config();
     
     
                                             }*/
                                        }}
                                        selectionModel={selectionModel}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={mysy_list_event_for_map.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                event_title: JSON.parse(item).event_title,
                                                event_start: JSON.parse(item).event_start,
                                                event_end: JSON.parse(item).event_end,
                                                event_type: JSON.parse(item).event_type,
                                                justified: JSON.parse(item).justified,
                                                event_comment: JSON.parse(item).comment,

                                            }
                                        ))}

                                        columns={columns}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            handleEventClick_from_list(newSelectionModel.row.id)
                                            // handleClick_edit_config_From_Line(newSelectionModel.row.id);

                                        }}

                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {


                                            //field === "order_header_status"
                                            if (params.field === "order_header_status" && String(params.value) == "0") {
                                                return 'cell--status--brouillon';
                                            }

                                            if (params.field === "order_header_status" && String(params.value) == "1") {

                                                return 'cell--status--encours';
                                            }

                                            if (params.field === "order_header_status" && String(params.value) == "2") {
                                                return 'cell--status--traite';
                                            }

                                            if (params.field === "order_header_status" && String(params.value) == "3") {
                                                return 'cell--status--facture';
                                            }

                                        }}
                                        getRowClassName={(params) => {

                                            if (String(params.row.status) === "-1") {
                                                return 'line--statut--annule';
                                            }
                                            if (String(params.row.status) === "0") {
                                                return 'line--statut--preinscrit';
                                            }
                                            if (String(params.row.status) === "1") {
                                                return 'line--statut--inscrit';
                                            }
                                        }}


                                    />
                                </Box>
                                <br />

                            </div>

                        </div>

                    </div>
                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                            <Button variant="outlined" onClick={handleSelect_list}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 event &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>
                        </div>
                    </div>
                    <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                </div>


            </div>}


        </div>
    )
}


export default Module_Absence;