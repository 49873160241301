import Link from "next/link";
import Navigation from "../components/Navigation";
import UpdateAttendeeList from "../components/UpdateAttendeeList"



export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
              <UpdateAttendeeList />
               
            </div>
        </div>
    );
}
