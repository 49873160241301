import React, { useState } from "react";

const SearchBar = () => {
    const [apiUrl, SetapiUrl] = useState("https://restcountries.com/v3.1/lang/");

    function toto(e) {
        e.preventDefault();
        var local_api = apiUrl;


        var search_value = document.getElementById("search").value;
        if( search_value.length === 0 )
            alert("WRONG search_value "+search_value+"  taille = "+search_value.length);
        else {
            local_api = apiUrl+search_value;
            alert(" la nouvelle api = "+local_api);
        }

    }


    return (
        <div className={"searchbar"}>

            <div className="container justify-content-center">
                <div className="row">
                    <div className="col-md-8">
                        <div className="input-group mb-3">
                            <input type="text" className="form-control input-text" id="search" name="search"
                                   placeholder="La recherche sur : titre, obj pédagogique, formateur  "
                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default SearchBar;