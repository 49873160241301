import Acceuil   from "../components/Accueuil";
import Navigation from "../components/Navigation";


const acceuil = () => {


  return (
            <div>
              <div>
            <Navigation />
            </div>
            
            <Acceuil/>
            
        </div>

  )
}


export default acceuil;
