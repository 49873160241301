import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import useCookie from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import partnerbannerimg from "../mysy_img/MYSY_banner_compte.png";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { getCookie, setCookie, removeCookie } from 'react-use-cookie';
import { Helmet } from "react-helmet";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Header from "./Header";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from "axios";


import { RiMailCheckLine, RiPhoneLine, RiCommunityLine } from "react-icons/ri";

function SignUp() {

    const url_login = process.env.REACT_APP_API_URL + "myclass/api/login/";

    const [isconnected, setisconnected] = useState("");
    const [myApimyApiMessage, setmyApimyApiMessage] = useState("");
    const textInput = useRef(null);
    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [updatepwd, setupdatepwd] = useState();
    const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
    const [newpawdvisible, setnewpawdvisible] = useState(false);
    const [confpwdvisible, setconfpwdvisible] = useState(false);
    const [pwdfieldsdesabled, setpwdfieldsdesabled] = useState(true);
    const [valuesPass1, setValuesPass1] = React.useState({
        password1: '',
        showPassword1: false,
    });

    const [valuesPass2, setValuesPass2] = React.useState({
        password2: '',
        showPassword2: false,
    });

    const [valuesPass3, setValuesPass3] = React.useState({
        password3: '',
        showPassword3: false,
    });

    const random1 = Math.floor(Math.random() * 10);
    const random2 = Math.floor(Math.random() * 10);


    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [menucompte, setmenucompte] = useState("partner");

    function handleChange1(prop) {
        return (event) => {
            setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
        };
    }

    function handleChange2(prop) {
        return (event) => {
            setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
        };
    }

    function handleChange3(prop) {

        return (event) => {
            setValuesPass3({ ...valuesPass3, [prop]: event.target.value });
        };


    }


    const [result, setResult] = useState("");

    const history = useHistory();


    const onSubmit = async (data) => {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        var mymail = document.getElementsByName("email")[0].value;
        //var mypwd = document.getElementsByName("pwd")[0].value;
        const formData = new FormData();


        var re = /\S+@\S+\.\S+/;
        if (re.test(mymail) === false) {
            alert("Votre adresse email est incorrecte");
            document.getElementsByName("email")[0].style.backgroundColor = "red";
            return;
        }



        var new_pwd = document.getElementsByName("new_pwd")[0].value;
        var conf_new_pwd = document.getElementsByName("new_pwd_conf")[0].value;

        if (new_pwd.length < 8) {
            alert("Le mot de passe doit faire au moins 8 caractères ");
            return;
        }

        document.getElementsByName("identique")[0].style.backgroundColor = "white";
        if (new_pwd !== conf_new_pwd) {
            document.getElementsByName("identique")[0].style.backgroundColor = "red";
            return
        }

        document.getElementsByName("caractere")[0].style.backgroundColor = "white";
        if (String(new_pwd).length < 8) {
            document.getElementsByName("caractere")[0].style.backgroundColor = "red";
            return
        }

        var regex = /\d/g;
        document.getElementsByName("nombre")[0].style.backgroundColor = "white";
        if (!regex.test(new_pwd)) {
            document.getElementsByName("nombre")[0].style.backgroundColor = "red";
            return
        }

        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        document.getElementsByName("special")[0].style.backgroundColor = "white";
        if (!specialChars.test(new_pwd)) {
            document.getElementsByName("special")[0].style.backgroundColor = "red";
            return
        }

        document.getElementsByName("pasrobot")[0].style.backgroundColor = "white";
        var total = " ?? ";
        if (document.getElementsByName("sommerandom")[0].value) {
            total = document.getElementsByName("sommerandom")[0].value;
        }

        var calcaul_total = parseInt(random1) + parseInt(random2);
        if (parseInt(total) != parseInt(calcaul_total)) {
            document.getElementsByName("pasrobot")[0].style.backgroundColor = "red";

            alert(" Le total dans la zone 'je ne suis pas un robot' est incohérent. Recommencez svp :) !!!");

            return;
        }

        var mymail = document.getElementsByName("email")[0].value;
        var contact_nom = "";
        var contact_prenom = "";
        var contact_mail = "";
        var contact_tel = "";

        var raison_sociale = "";
        var telephone = "";


        var url_sign_up = process.env.REACT_APP_API_URL + "myclass/api/add_user_account/";
        if (String(menucompte) === String("partner")) {

            // Verification des champs obligatoires
            document.getElementsByName("contact_nom")[0].style.backgroundColor = "white";
            if (!document.getElementsByName("contact_nom")[0] || document.getElementsByName("contact_nom")[0].value.length <= 1) {
                document.getElementsByName("contact_nom")[0].style.backgroundColor = "red";
                alert("Le nom du contact principal est incorrect ");
                return
            }

            document.getElementsByName("contact_prenom")[0].style.backgroundColor = "white";
            if (!document.getElementsByName("contact_prenom")[0] || document.getElementsByName("contact_prenom")[0].value.length <= 1) {
                document.getElementsByName("contact_prenom")[0].style.backgroundColor = "red";
                alert("Le prenom du contact principal est incorrect ");
                return
            }

            document.getElementsByName("contact_email")[0].style.backgroundColor = "white";
            if (!document.getElementsByName("contact_email")[0] || document.getElementsByName("contact_email")[0].value.length <= 1) {
                document.getElementsByName("contact_email")[0].style.backgroundColor = "red";
                alert("L'email du contact principal est incorrect ");
                return
            }
            var re2 = /\S+@\S+\.\S+/;
            if (re2.test(document.getElementsByName("contact_email")[0].value) === false) {
                alert("L'email du contact principal est incorrect ");
                document.getElementsByName("contact_email")[0].style.backgroundColor = "red";
                return;
            }


            document.getElementsByName("contact_telephone")[0].style.backgroundColor = "white";
            if (!document.getElementsByName("contact_telephone")[0] || document.getElementsByName("contact_telephone")[0].value.length <= 8) {
                document.getElementsByName("contact_telephone")[0].style.backgroundColor = "red";
                alert("Le téléphone du contact principal est incorrect ");
                return
            }

            document.getElementsByName("raison_sociale")[0].style.backgroundColor = "white";
            if (!document.getElementsByName("raison_sociale")[0] || document.getElementsByName("raison_sociale")[0].value.length <= 2) {
                document.getElementsByName("raison_sociale")[0].style.backgroundColor = "red";
                alert("La raison sociale du contact principal est incorrecte");
                return
            }

            contact_nom = document.getElementsByName("contact_nom")[0].value;
            contact_prenom = document.getElementsByName("contact_prenom")[0].value;
            contact_mail = document.getElementsByName("contact_email")[0].value;
            contact_tel = document.getElementsByName("contact_telephone")[0].value;
            raison_sociale = document.getElementsByName("raison_sociale")[0].value;
            telephone = document.getElementsByName("telephone")[0].value;

            formData.append("nom", raison_sociale);

            var new_nom = (raison_sociale).replace(/[^0-9a-z]/gi, '').toLocaleLowerCase();
            formData.append('subdomaine_catalog_pub', new_nom);

            formData.append("telephone", telephone);
            formData.append("contact_nom", contact_nom);
            formData.append("contact_prenom", contact_prenom);
            formData.append("contact_tel", contact_tel);
            formData.append("contact_mail", contact_mail);

            url_sign_up = process.env.REACT_APP_API_URL + "myclass/api/add_partner_account/";
        }

        if (!document.getElementsByName("cgu")[0].checked) {
            alert(" Vous devez accepter les conditions générales d'utilisation du site. Merci.");
            document.getElementsByName("cgu")[0].style.backgroundColor = "red";
            return;
        }
        formData.append("email", mymail);
        formData.append("pwd", new_pwd);


        fetch(
            url_sign_up,
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) == String("true")) {
                    alert(String(result['message']));
                    setisconnected("true");
                    setmyApimyApiMessage(result['message']);
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });

                    if (String(menucompte) === String("user")) {
                        history.push("/Connexion-utilisateur-0");
                    }
                    else if (String(menucompte) === String("partner")) {
                        history.push("/PartnerLogin0");
                    }
                }
                else {
                    //alert(" GRR "+String(result['message']+ "  result['status'] = "+ result['status']));
                    setisconnected("false");
                    setmyApimyApiMessage(result['message']);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setisconnected("false");
                setmyApimyApiMessage("Le service est momentanémentindisponible, merci de ressayer plus tard. ");

            });

    };




    useEffect(() => {



        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onSubmit();
            }

        };
        document.addEventListener("keydown", listener);
        //menu_user_account();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (String(menucompte) === String("user")) {
            menu_user_account()
        }
        else {
            menu_parter_account();
        }


    }, []);

    const handleClickShowPassword_new_pwd_conf = () => {
        if (confpwdvisible == false) {
            document.getElementsByName("new_pwd_conf")[0].type = "text";
            setconfpwdvisible(true);
        }
        else if (confpwdvisible == true) {
            document.getElementsByName("new_pwd_conf")[0].type = "password";
            setconfpwdvisible(false);
        }

    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleClickShowPassword_new_pwd = () => {
        if (newpawdvisible == false) {
            document.getElementsByName("new_pwd")[0].type = "text";
            setnewpawdvisible(true);
        }
        else if (newpawdvisible == true) {
            document.getElementsByName("new_pwd")[0].type = "password";
            setnewpawdvisible(false);
        }

    };

    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };

    function menu_parter_account() {
        setmenucompte("partner");
        document.getElementsByName("partner_account")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("user_account")[0].style.backgroundColor = "#ffffff";

    }

    function menu_user_account() {
        setmenucompte("user");
        document.getElementsByName("user_account")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("partner_account")[0].style.backgroundColor = "#ffffff";
    }



    return (
        <div className="signup">
            <Helmet>
                <title>Créer le compte de votre organisme de formation</title>
                <meta name="description" content="Créer le compte de votre organisme de formation. " />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Créer le compte de votre organisme de formation" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Header />

            <div className="div_mobile">
                {isconnected === "false" && <div className="erreurconnexion">
                    <br />
                    {myApimyApiMessage}</div>
                }

                {isconnected === "true" && String(myApimyApiMessage).length > 5 &&
                    <div className="okUpdateData">
                        <br />
                        Le compte a été correctement créé. Vous allez recevoir un email de validation sous peu.<br />
                        Pensez à regarder vos spam aussi.</div>
                }

                <div className="display_screen_600_901">
                    <div>
                        <h1 className="text_welcome">Bien commencer avec MySy Training</h1>
                    </div>
                    <div className="div_menu_centre">
                        <div className="div_row">
                            <Button variant="outlined" onClick={menu_user_account} className="signin_menu"
                                id="user_account" name="user_account"
                                style={{ "float": "left", "textAlign": "center" }}>Utilisateur</Button>

                            <Button variant="outlined" onClick={menu_parter_account} className="signin_menu"
                                id="partner_account" name="partner_account"
                                style={{ "float": "left", "textAlign": "center" }}>Organisme de formation</Button>

                        </div>
                        <div className="div_row_bis">
                            {/*String(menucompte) === String("partner") && <FormGroup>
                                <Label className="text_label">Raison Sociale*</Label>
                                <Input type="text" placeholder="" name="raison_sociale" />
            </FormGroup>*/}

                            {String(menucompte) === String("partner") && <FormControl sx={{ m: 1, }} variant="outlined" size="small" className="texte_area_passwd">
                                <InputLabel htmlFor="raison_sociale" style={{ "margin": "0px" }}>Raison Sociale*</InputLabel>
                                <OutlinedInput
                                    id="raison_sociale"
                                    name="raison_sociale"
                                    type="text"

                                    label="Raison Sociale*"
                                    className="ch"                                                                                                                                                                                                                                                                                                                                                                              
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <RiCommunityLine /> &nbsp;

                                        </InputAdornment>
                                    }

                                />
                                                                                                                                                                                                                                                        
                            </FormControl>}

                            {/*String(menucompte) === String("partner") && <FormGroup>                                                                                                                                                                                                                                                                                                                                                                              
                                <Label className="text_label">Telephone*</Label>
                                <Input type="text" placeholder="" name="telephone" />
                                </FormGroup>*/}

                            {String(menucompte) === String("partner") && <FormControl sx={{ m: 1, }} variant="outlined" size="small" className="texte_area_passwd">
                                <InputLabel htmlFor="raison_sociale" style={{ "margin": "0px" }}>Telephone*</InputLabel>
                                <OutlinedInput
                                    id="telephone"
                                    name="telephone"
                                    type="text"

                                    label="Telephone*"
                                    className="ch"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <RiPhoneLine /> &nbsp;

                                        </InputAdornment>
                                    }

                                />

                            </FormControl>}

                            <FormControl sx={{ m: 1, }} variant="outlined" size="small" className="texte_area_passwd">
                                <InputLabel htmlFor="email" style={{ "margin": "0px" }}>Email</InputLabel>
                                <OutlinedInput
                                    id="email"
                                    name="email"
                                    type="email"

                                    label="email"
                                    className="ch"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <RiMailCheckLine /> &nbsp;

                                        </InputAdornment>
                                    }

                                />

                            </FormControl>
                            <br />
                            <FormControl sx={{ m: 1, }} variant="outlined" size="small" className="texte_area_passwd">
                                <InputLabel htmlFor="new_pwd" style={{ "margin": "0px" }}>Mot de passe</InputLabel>
                                <OutlinedInput
                                    id="new_pwd"
                                    name="new_pwd"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password2}
                                    label="Mot de passe"
                                    onChange={handleChange2('password2')}
                                    className="ch"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword_new_pwd}
                                                edge="end"

                                            >
                                                {newpawdvisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />

                            </FormControl>
                            <br />
                            {/*<Label className="text_label"> Confirmer mot de passe </Label><br />

                            <FormControl sx={{ m: 1, }} variant="standard" size="small" className="texte_area_passwd">


                                <OutlinedInput
                                    id="new_pwd_conf"
                                    name="new_pwd_conf"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password3}
                                    onChange={handleChange3('password3')}
                                    className="ch"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword_new_pwd_conf}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label=" "
                                />
                                </FormControl>*/}

                            <FormControl sx={{ m: 1, }} variant="outlined" size="small" className="texte_area_passwd">
                                <InputLabel htmlFor="new_pwd_conf" style={{ "margin": "0px" }}>Confirmer mot de passe</InputLabel>
                                <OutlinedInput
                                    id="new_pwd_conf"
                                    name="new_pwd_conf"
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password2}
                                    label="Confirmer mot de passe"
                                    onChange={handleChange2('password2')}
                                    className="ch"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword_new_pwd_conf}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />

                            </FormControl>
                            <br />
                            <div className="text_pwd_crit">
                                Créez un mot de passe contenant au moins :
                                <ul>
                                    <li name="caractere">
                                        8 caractères
                                    </li>
                                    <li name="nombre">
                                        1 nombre
                                    </li>
                                    <li name="special">
                                        1 caractère spécial p. ex., $, !, @, %, &
                                    </li>

                                    <li name="identique">
                                        Identiques
                                    </li>
                                </ul>
                            </div>

                            <div className="div_row22">

                                {String(menucompte) === String("partner") && <div style={{ "textAlign": "left" }}>
                                    <div className="titre1"> Contact Principal </div>

                                     &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>


                                        <TextField
                                            required
                                            name="contact_nom"
                                            label="Nom"
                                            className="texte_area"
                                            sx={{ m: 1, width: '48%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">

                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />
                                        <TextField
                                            required
                                            name="contact_prenom"
                                            label="Prénom"
                                            className="texte_area"
                                            sx={{ m: 1, width: '48%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">

                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />


                                        <TextField
                                            required
                                            name="contact_email"
                                            label="Adresse mail"
                                            className="texte_area"
                                            sx={{ m: 1, width: '48%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">

                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />
                                        <TextField
                                            required
                                            name="contact_telephone"
                                            label="Téléphone"
                                            className="texte_area"
                                            sx={{ m: 1, width: '48%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">

                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                        />


                                        <br />
                                    </Box>
                                </div>}
                                <div style={{ "textAlign": "left", "width": "100%", "marginTop": "0.5rem" }}>

                                    <input type={"checkbox"} unchecked
                                        id="cgu"
                                        name="cgu"
                                        style={{ "textAlign": "left", "width": "auto", "height": "1rem" }} />
                                    <label for="cgu" style={{ "textAlign": "left", maxWidth: "90%", "fontSize": "small" }}>Accepter <a href='/sample/MYSY_CGU.pdf' download>les conditions générales d'usage </a></label>

                                </div>

                                <div className="div_row22  text_pwd_crit" style={{ "textAlign": "center", "fontWeight": "bold" }}>
                                    <nav className="pasrobot_block"> Je ne suis pas un robot <br />
                                        <div  >


                                            <div name="pasrobot" > {random1} + {random2} =  <input
                                                type='text'
                                                name='sommerandom'
                                                id='sommerandom'

                                                {...register('sommerandom', {
                                                    required: { value: true, message: 'sommes de valeurs' },
                                                    maxLength: {
                                                        value: 30,
                                                    }
                                                })}

                                                className="pasrobot"
                                                placeholder='Le total est ?'
                                            />

                                                {errors.sommerandom && (
                                                    <span className='koUpdateData'>la sommes est incorrect</span>
                                                )}
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Button variant="contained" color="success"
                                    className="div_row22 btn_login button"
                                    ref={textInput}
                                    onClick={onSubmit}>S'inscrire</Button>
                            </div>


                        </div>
                    </div>

                </div>


                <div className="pieddepage">
                    <Footer />
                </div>
            </div>
        </div >
    );
}

export default SignUp;
