import React, { useRef, useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Articles from "../components/Article";
import { FaSearch, FaRegArrowAltCircleDown, FaRegArrowAltCircleUp } from 'react-icons/fa';

import Pagination from "./TestPagination";
import { useHistory } from "react-router-dom";
import { getCookie, removeCookie } from 'react-use-cookie';

import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";


let PageSize = 5;
const ListeArticles = (props) => {
    const [userconnected, setuserconnected] = useState("");
    const [SearchText, setSearchText] = useState("");
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("false");
    const [displayAllClasses, setdisplayAllClasses] = useState(true);
    const [showelement, setshowelement] = useState("init");
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage2, setCurrentPage2] = useState(1);
    const [fin, setfin] = useState(0);
    const [ismobilephone, setismobilephone] = useState();
    const [TrainingDistanciel, setTrainingDistanciel] = useState();


    const stored_user = getCookie('tokenmysych');
    const history = useHistory();


    const url = process.env.REACT_APP_API_URL + "myclass/api/add_user_account/";
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm();
    const pwd = useRef({});
    pwd.current = watch("pwd", "");

    const onSubmit = async (data) => {
        const formData = new FormData();
        //await sleep(20);
        formData.append('search_text', data.name);
        formData.append('name', data.name);
        formData.append('surname', data.surname);
        formData.append('email', data.email);
        formData.append('pwd', data.pwd);
        const res = await fetch(
            url,
            {
                method: "POST",
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: formData,
            }
        );
        const results = await res.json();
        //console.log(results);
    };

    const [Trainingdistance, setTrainingdistance] = useState(0);
    const handleInput = (e) => {
        setTrainingdistance(e.target.value);
    }

    const [Trainingduration, setTrainingduration] = useState(0);
    const handleInputDuration = (e) => {
        setTrainingduration(e.target.value);
    }


    function showdisplayfilter(event) {

        if (showelement == "true") {
            setshowelement("false");
        }
        else if (showelement == "false") {
            setshowelement("true");
        }


    }

    function clearsearchtext(event) {
        setSearchText("");
        document.getElementById("SearchText").value = "";
    }


    function SearchTextHandle(event) {
        setSearchText(event.target.value);
    }



    function displayAllClassesHandle(event) {

        setdisplayAllClasses(event.target.checked);
        if (event.target.checked == true)
            document.getElementById("SearchText").disabled = true;
        else
            document.getElementById("SearchText").disabled = false;
    }


    function searchFunction(e) {

        var token = "";
        if (typeof (stored_user) != "undefined") {
            token = stored_user
        }


        var form = new FormData();
        form.append("search_text", SearchText);
        form.append("token", token);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = ""
       /* if (document.getElementById("allelement").checked !== true &&
            document.getElementById("SearchText").disabled !== true) {
            //alert(" recherche classique laaa ");
            myurl = process.env.REACT_APP_API_URL + "myclass/api/recherche_articles_avis/";
        }
        else if (document.getElementById("allelement").checked === true &&
            document.getElementById("SearchText").disabled === true) {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_articles_avis/";
        }*/
        myurl = process.env.REACT_APP_API_URL + "myclass/api/get_all_articles_avis/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message)
                //setmyApimyApiMessage("OK")
                ////console.log("taille = "+res.data.message.length);
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })
    }


    const currentTableData2 = useMemo(() => {
        if (result) {
            const firstPageIndex = (currentPage2 - 1) * PageSize;
            const lastPageIndex = firstPageIndex + PageSize;
            setfin(1);
            return result.slice(firstPageIndex, lastPageIndex);
        }
        else {
            return [];
            setfin(0);
        }
    }, [currentPage2]);

    useEffect(() => {
        setdisplayAllClasses("true");
        async function chloadfunction() {
            document.getElementById("allelement").checked = true;
           // document.getElementById("SearchText").disabled = true;


        }
        chloadfunction();
        searchFunction();

        whosisconnected();

    }, []);

    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);

    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);

    const stored_part = getCookie('tokenmysypart');

    function whosisconnected() {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            // alert(" partner connected OK");
        }
    }

    function handleAccountLogout() {

        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };

    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };

    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };

    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const handleMyPartnerAccount = () => {
        setAccountMenu(null);
        history.push("/Partner")
    };

    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);
    function handleAccountConnexion_v2() {

        if (String(userconnected) === String("1")) {
            handleMyAccount();
        }
        else if (String(partnerconnected) === String("1")) {
            handleMyPartnerAccount();
        }
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_part) != "undefined") {
            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie+"_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });
            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });
        }
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);


  
    

    return (
        <div className="recherche_style ListeArticles">
            
            <div>
                <div>
                    {showelement === "true" && <div onClick={showdisplayfilter} className="recherchefilter">

                        <div className="recherchedivgauche">Masquer les filtres</div>
                        <div className="recherchedivdroite"> <FaRegArrowAltCircleUp /> </div>

                    </div>
                    }

                    {showelement === "false" && <div onClick={showdisplayfilter} className="recherchefilter">
                        <div className="recherchedivgauche">Afficher les filtres</div>
                        <div className="recherchedivdroite"> <FaRegArrowAltCircleDown /> </div>

                    </div>
                    }


                    <div className="rech_middlebox">

                        <div style={{ "display": "none" }}>
                            <label className="default_font">
                                <i>Afficher tous nos articles :</i>  &nbsp;&nbsp;
                                <input type="checkbox"
                                    onChange={displayAllClassesHandle}
                                    id="allelement"
                                    name="allelement" />
                            </label>
                        </div>

                        {myApiResponse == "False" && <div className="koUpdateData">

                            Impossible de contacter l'hote distant
                        </div>}

                        {result && myApiResponse === "True" && <div className="okUpdateData pagination_align">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />


                        </div>}

                        {result && myApiResponse == "True" && fin == 0 && result.slice(0, PageSize).map((article) => (
                            <div className="rech_list_formation2">
                                 <Articles article={JSON.parse(article)} key={JSON.parse(article).title_formation} />
                               
                               
                            </div>


                        ))}
                        {result && myApiResponse === "True" && fin === 1 && currentTableData2 && currentTableData2.map((formation) => (
                            <div className="rech_list_formation2">
 
                                <Articles article={JSON.parse(formation)} key={JSON.parse(formation).title_formation} />
                                
                            </div>

                        ))}


                        {result && <div className="pagination_align">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage2}
                                totalCount={result.length}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage2(page)}
                            />

                        </div>
                        }


                    </div>


                </div>

            </div>

        </div>
    )
}

export default ListeArticles;