import React, { useState, useEffect, } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import axios from "axios";
import { GridToolbar, frFR } from '@mui/x-data-grid';
import { AiOutlineEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { getCookie, setCookie, removeCookie } from 'react-use-cookie';

const UserHistoryRecherche = (props) => {

  function handleClick(event, cellValues) {
    //console.log(cellValues);
  }

  const [selectionModel, setSelectionModel] = React.useState([]);
  const [rowss, setRows] = useState([]);
  const [tableData, setTableData] = useState([])
  const [myApiResponse, setmyApiResponse] = useState("false");
  const [result, setResult] = useState("");
  const formData = new FormData();


  const displayHistory = () => {

    setmyApiResponse("false");

    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
    const stored_cookie = getCookie('tokenmysych');
    formData.append("token", stored_cookie);
    //console.log("token = " + stored_cookie);

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/get_user_recherche_history/",
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((myresult) => {
        //console.log('Success:', myresult['message']);

        if (String(myresult['status']) == String("true")) {
          setmyApiResponse("true");
          setResult(myresult['message']);
          setTableData(myresult['message']);
          setRows(myresult['message']);
          //console.log("rowss = " + rowss);

        }
        else {
          setmyApiResponse("false");
        }

      })
      .catch((error) => {
        console.error('Error:');
        setmyApiResponse("false");
      });
  };


  useEffect(() => {
    displayHistory();
  }, []);

  const columns = [
    { field: 'id', headerName: 'id', hide: true },
    { field: 'search_text', headerName: 'Search Texte', width: 200,  flex: 1 },
    { field: 'date', headerName: 'Date', width: 100,  flex: 1 },

  ]


  return (
    <div>
      {/*
      <div class="row">
        <div class="col-4 border">
          <b>  Id </b>
        </div>
        <div class="col-3 border">
          <b>  Expressions recherchées</b>
        </div>
        <div class="col-3 border">
          <b>  Date Recherche</b>
        </div>
      </div>

      {result &&

        result.map((formation) => (
          <div class="row border">
            <div class="col-4 border">
              {JSON.parse(formation).id}
            </div>

            <div class="col-3 border">
              {JSON.parse(formation).search_text}
            </div>
            <div class="col-3 border">
              {JSON.parse(formation).date_update}
            </div>
          </div>

        ))}
        */}
      {result && <div>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            selectionModel={selectionModel}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            rows={rowss.map((item, index) => (
              {
                id: index,
                search_text: JSON.parse(item).search_text,
                date: JSON.parse(item).date_update,
              }
            ))}

            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
          />

        </div>
      </div>}



    </div>

  )
}


export default UserHistoryRecherche;