import QuisSommesNous from "../components/QuisSommesNous";
import Navigation from "../components/Navigation";

import QuiSommesNous_v4 from "../components/QuisSommesNous_V4";

const recherche_draft = () => {

  return (
    <div>
      <div>
        <Navigation />
      </div>
      <QuiSommesNous_v4 />
    </div>

  )
}


export default recherche_draft;
