import Link from "next/link";
import Navigation from "../components/Navigation";
import ESign from "../components/ESign";



export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
              <ESign />
               
            </div>
        </div>
    );
}
