import OrderConfirmation_3DS from "../components/OrderConfirmation_3DS";
import Navigation from "../components/Navigation";


const acceuil = () => {


  return (
    <div>

      <OrderConfirmation_3DS />

    </div>

  )
}


export default acceuil;
