import React, { useState, useEffect } from "react";
import { FaSearch, } from 'react-icons/fa';
import axios from "axios";
import { latLng2Tile } from "google-map-react";
import { useHistory } from "react-router-dom";



const AideRecherche = (props) => {

    const [recherche, setrecherche] = useState("");
    const [Result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("false");
    const history = useHistory();


    function searchFunction(e) {
        setrecherche("1");
        setResult([]);

        //Get Criterias
        var crit_SearchText = document.getElementById("mottype").value;
        //var crit_domaine = document.getElementById("domaine").value;

        //alert(" vous avez choisi : crit_SearchText = "+crit_SearchText+"  -- crit_domaine = "+crit_domaine);
        var form = new FormData();
        form.append("mot", crit_SearchText);
        form.append("domaine", "");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetMotFromElaIndex/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message)

            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");

            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");

        })

    }

    function Checkmottype(e){
        const val = e.target.value;
        const regex = new RegExp('^[a-zA-Z0-9]+$');

        
        if( regex.test(val) == false || String(val).length > 4  ){
            var newval = val.slice(0, -1);
            document.getElementsByName("mottype")[0].value = newval;
        }
    }

    function handleClick(e, data) {
        // access to e.target here
        
        //console.log(data);
        history.push("/recherche-formation/"+data);

        // ici faire call recherche avec search texte
    }


    return (
        <div className="AideRecherche">
            <div className="div_row">
{/*
                <div className="input-group input-group-sm mb-3 " style={{ width: "90%" }}>
                    <span className="criteres input-group-text " id="inputGroup-sizing-sm3 ">Domaine de recheche </span>
                    <select className="form-select form-select-sm " onChange={"TrainingCertifHandle"} aria-label=".form-select-sm example"
                        id={"domaine"} name={"domaine"}  >
                        <option selected value="">Choisir une valeur</option>
                        <option value="programmation">Prog</option>
                        <option value="reseaux">Reseaux</option>
                    </select>
                </div>
    */}
                <div class="input-group mb-3">

                    <div className="input-group input-group-sm mb-3 ">
                        <span className="criteres input-group-text " id="inputGroup-sizing-sm3 ">Saisir 4 lettres </span>
                        <input type="text" name="mottype"
                            id="mottype"
                            maxlength="4" size="4" class="form-control" 
                            aria-label="Default" aria-describedby="inputGroup-sizing-default"
                            onChange={Checkmottype} />
                        <FaSearch onClick={searchFunction} style={{
                            width: "10%",
                            border: "0px solid black", height: "1.5rem"
                        }} />

                    </div>

                </div>
            </div>

            {Result && String(myApiResponse) === String("True") && <div className="div_row" style={{border:"None"}}>
                
                <div className="div_row_gauche okUpdateData" style={{textAlign:"center"}}>
                    Essayez l'un de ces mots (cliquer dessus)

                </div>
                <div className="div_row_droite" >
                    {Result && String(myApiResponse) === String("True") &&
                        Result.map((formation) => (
                        <a href={"/recherche-formation/ref/"+formation}> 
                                 <div style={{ display: "inline-block", width: "33%", textAlign:"center",paddingLeft: "0.5rem" }} value= {`${formation}`}  
                            >  
                                "{(formation)}"

                            </div>
                            </a>

                        ))}
                </div>
            </div>}


        </div>

    )
}


export default AideRecherche;