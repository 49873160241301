import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { Button, } from "reactstrap";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from "axios";
import ProduitsServices from "./ComponentProduitsServices";
import tick_ok from "../mysy_img/tick_OK.png";
import tick_ko from "../mysy_img/tick_KO.png";
import { confirmAlert } from 'react-confirm-alert';
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";



function UpgradeToPro() {

    const history = useHistory();
    const [fields1desabled, setfields1desabled] = useState(true);
    const [periode, setperiode] = useState("Mensuel");
    const [isconnected, setisconnected] = useState("");
    const [myApimyApiMessage, setmyApimyApiMessage] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [gotocheckout, setgotocheckout] = useState("");
   


    useEffect(() => {
        
    }, [gotocheckout])

    // recuperation et gestion  du token utilisateur 
    const stored_user = getCookie('tokenmysych');
    if (!stored_user || stored_user.length <= 0) {
        history.push("/mysy-user-account");
    }

    function Clik_bton_mensuel(e) {
        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel")[0].style.opacity = "40%";

        setperiode("Mensuel");
    }

    function Clik_bton_annuel(e) {
        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "40%";

        setperiode("Annuel");
    }


    function checkRequiredInfo() {

        var mymail = document.getElementsByName("email")[0].value;

        var re = /\S+@\S+\.\S+/;
        if (re.test(mymail) === false) {
            alert("l'adresse email est incorrecte");
            return false;
        }

        var nom = document.getElementsByName("nom")[0].value;
        if (nom.length < 3) {
            alert("le nom est incorrect");
            return false;
        }

        var telephone = document.getElementsByName("telephone")[0].value;
        if (telephone.length < 6) {
            alert("le telephone est incorrect");
            return false;
        }

        var adr_street = document.getElementsByName("adr_street")[0].value;
        if (adr_street.length < 6) {
            alert("la rue est incorrecte");
            return false;
        }


        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        if (adr_zip.length < 3) {
            alert("le code postal est incorrect");
            return false;
        }

        var adr_city = document.getElementsByName("adr_city")[0].value;
        if (adr_city.length < 3) {
            alert("la ville est incorrecte");
            return false;
        }

        var adr_country = document.getElementsByName("adr_country")[0].value;
        if (adr_country.length < 3) {
            alert("le pays est incorrect");
            return false;
        }
    }



    // Cette fonction appelle l'API d'upgrade
    function UpgradeToPro(pack) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/UpgradetoPro/";

        var nom = document.getElementsByName("nom")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var link_linkedin = document.getElementsByName("link_linkedin")[0].value;
        var link_facebook = document.getElementsByName("link_facebook")[0].value;
        var link_twitter = document.getElementsByName("link_twitter")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;


        formData.append('token', stored_user);
        formData.append("pack_service", pack);
        formData.append("nom", nom);
        formData.append("telephone", telephone);
        formData.append("email", email);
        formData.append('link_linkedin', link_linkedin);
        formData.append("link_facebook", link_facebook);
        formData.append("link_twitter", link_twitter);
        formData.append('adr_street', adr_street);
        formData.append("adr_zip", adr_zip);
        formData.append("adr_city", adr_city);
        formData.append("adr_country", adr_country);


        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    alert(String(result['message']));
                    setmyApiResponse("1");

                }else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    //alert(" GRR "+String(result['message']+ "  result['status'] = "+ result['status']));
                    setisconnected("false");
                    setmyApimyApiMessage(result['message']);
                    setmyApiResponse("0")
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("0");
                setisconnected("false");
                setmyApimyApiMessage("Le service est momentanémentindisponible, merci de ressayer plus tard. ");

            });

    }

    function confirmAbonnement(packs) {


        if (checkRequiredInfo() === false) {
            return;
        }


        confirmAlert({
            title: "Confirmer l'abonnement au pack " + packs,
            message: '',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { UpgradeToPro(packs); window.scrollTo({
            top: 0,
            behavior: "smooth",
          }); }
                },
                {
                    label: 'Non',
                    onClick: () => { return }

                }
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
            willUnmount: () => { },
            afterClose: () => { },
            onClickOutside: () => { },
            onKeypress: () => { },
            onKeypressEscape: () => { },
        });
    }


    return (

        <div className='upgradetopro'>

            <div>

                <div className="titre1"> Upgrade du compte </div>
                <hr className="my_hr" />

                {String(myApiResponse) === String("1") && <div className="okUpdateData">
                    Votre compte a été correctement migré. <br />
                    Vous allez recevoir les nouvelles informations de connection par email sous peu. <br /> <b>/!\ Pensez à regarder dans vos spams.</b>
                </div>
                }

                {String(myApiResponse) === String("0") && <div className="koUpdateData">
                    La migration de votre compte a echoué<br />
                    {myApimyApiMessage}
                </div>
                }



                 &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                    <TextField
                        required
                        label="Raison sociale"
                        name="nom"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <br />

                    <TextField
                        required
                        name="telephone"
                        label="Téléphone"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PhoneIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />


                    <TextField
                        required
                        name="email"
                        label="Adresse mail"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <br />

                    <TextField
                        name="link_linkedin"
                        label="LinkedIn"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LinkedInIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />


                    <TextField
                        name="link_facebook"
                        label="Facebook"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FacebookIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />


                    <TextField
                        name="link_twitter"
                        label="Twitter"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TwitterIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <br />
                    <TextField
                        required
                        name="adr_street"
                        label="Adresse"
                        className="texte_area_adress"
                        sx={{ m: 1, width: '90%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <br />
                    <TextField
                        required
                        name="adr_zip"
                        label="Code Postal"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />

                    <TextField
                        required
                        name="adr_city"
                        label="Ville"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <br />
                    <TextField
                        required
                        name="adr_country"
                        label="Pays"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <br />
                    <br />
                    <hr className="my_hr" />
                    <div className="titre1"> Choisir une plan </div>
                    <br />

                    <div className="produitsservices">
                        <div className="critere">

                            <div className="critere_gauche">

                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm3">Devise  </span>
                                    <select className="form-select form-select-sm limite_input" onChange={"ChangeDevise"} aria-label=".form-select-sm example"
                                        id={"devise"} name={"devise"}  >
                                        <option selected value="euro">€</option>
                                        {/*<option value="dollard">$ US</option>*/}

                                    </select>
                                </div>
                            </div>
                            <div className="critere_droite">
                                <span className="span1"> Periodicité &nbsp; </span>
                                <button
                                    onClick={Clik_bton_mensuel}
                                    className="bton_radius_gauche"
                                    id="bton_mensuel" name="bton_mensuel">Mensuelle
                                </button>


                                <button
                                    onClick={Clik_bton_annuel}
                                    className="bton_radius_droite"
                                    id="bton_annuel" name="bton_annuel">Annuelle
                                </button>


                            </div>

                        </div>


                        <div className="div_row">
                            <div className="div_row_description">
                                <div className="div_basique_header">  &nbsp;
                                </div>
                                <div style={{ "marginBottom": "1rem", "background": "#E9ECEF" }}>
                                    Prix 
                                </div>
                                <div className="produits_service_label">
                                    Publication des formations
                                </div>
                                <div className="produits_service_label">
                                    Support téléphonique
                                </div>

                                <div className="produits_service_label">
                                    Customisation de l'affichage d'une formation
                                </div>
                                <div className="produits_service_label">
                                    Mots clés par formation
                                </div>
                                <div className="produits_service_label">
                                    Reporting standard
                                </div>
                                <div className="produits_service_label">
                                    Reporting Dynamique et personnalisé
                                </div>
                                <div className="produits_service_label">
                                    Préférence/ Diffusion géographique
                                </div>
                                <div className="produits_service_label">
                                    Publication d'un article
                                    (plateforme + réseaux sociaux )

                                </div>

                                <div className="produits_service_label">
                                    Community management externalisé

                                </div>

                                <div className="produits_service_label">
                                    Accompagnement aux certifications ( NDA, QUALIOPI,…)

                                </div>

                                <div className="produits_service_label">
                                    Design catalogue numérique

                                </div>
                                <div className="produits_service_label">
                                    ---

                                </div>



                            </div>

                            <div className="div_row_basique">
                                <div className="div_basique_header">  Découverte </div>
                                <hr style={{ "margin": "0.2rem" }} />
                                <div style={{ "marginBottom": "0.5rem" }}>
                                    &nbsp;
                                </div>

                                <div className="produits_service_tick">
                                    5 Formations
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    ----
                                </div>

                                <div className="div_row_basique_price">
                                    Gratuit 15 jours
                                </div>
                                <div className="div_row_basique_price">
                                    &nbsp;
                                </div>

                                <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement"
                                        onClick={(e) => {
                                            confirmAbonnement("Decouverte");
                                        }}
                                    > Activer le compte PRO  </Button>
                                </div>

                            </div>

                            <div className="div_row_standard">
                                <div className="div_standard_header"> Standard </div>
                                <hr style={{ "margin": "0.2rem" }} />
                                <div style={{ "marginBottom": "0.5rem" }}>
                                    &nbsp;
                                </div>

                                <div className="produits_service_tick">
                                    7 Formations
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    ----
                                </div>

                                <div className="div_row_standard_price">

                                    {String(periode) === String("Mensuel") && "350 €/mois"}
                                    {String(periode) === String("Annuel") && "1 500 €/an - (2 mois offerts)"}

                                </div>

                                <div className="div_row_standard_price">
                                    &nbsp;
                                </div>

                                <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement"
                                        onClick={(e) => {
                                            confirmAbonnement("Standard");
                                        }}> Activer le compte PRO  </Button>
                                </div>


                            </div>

                            <div className="div_row_gold">
                                <div className="div_gold_header">   Gold </div>
                                <hr style={{ "margin": "0.2rem" }} />
                                <div style={{ "marginBottom": "0.5rem" }}>
                                    &nbsp;
                                </div>

                                <div className="produits_service_tick">
                                    10 Formations
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick">
                                    ----
                                </div>

                                <div className="div_row_gold_price">
                                    {String(periode) === String("Mensuel") && "550 €/mois"}
                                    {String(periode) === String("Annuel") && "4 950 €/an - (3 mois offerts)"}

                                </div>

                                <div className="div_row_gold_price">
                                    &nbsp;
                                </div>

                                <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement"
                                        onClick={(e) => {
                                            confirmAbonnement("Gold");
                                        }}
                                    > Activer le compte PRO  </Button>
                                </div>


                            </div>

                            <div className="div_row_sur_mesure">
                                <div className="div_sur_mesure_header">   Sur mesure </div>
                                <hr style={{ "margin": "0.2rem" }} />
                                <div style={{ "marginBottom": "0.5rem" }}>
                                    &nbsp;
                                </div>

                                <div className="produits_service_tick">
                                    Illimité
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick">
                                    ----
                                </div>

                                <div className="div_row_sur_mesure_price">
                                    &nbsp;
                                </div>
                                <div className="div_row_sur_mesure_price">
                                    &nbsp;
                                </div>

                                <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement_sur_mesure"
                                        style={{ "whiteSpace": "nowrap", "paddingLeft": "1px" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = "/ContactezNous";
                                        }}
                                    > CONTACTEZ-NOUS  </Button>
                                </div>

                            </div>
                            {<div style={{ "width": "1%" }}> &nbsp;</div>}

                        </div>
                    </div>
                    <hr className="my_hr" />
                    <div className="div_row22">
                        {fields1desabled == false && <div className="div_row">
                            <div className="div_row_gauche">
                                <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={"RecordData"}>Enregistrer</Button>
                            </div>
                            <div className="div_row_droite">
                                <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={"desablefield1"}>Annuler</Button>
                            </div>
                        </div>

                        }


                    </div>
                </Box>
             
            </div>

        </div>);
}

export default UpgradeToPro;