import React, { useRef, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { getCookie, setCookie } from 'react-use-cookie';
import { Alert } from "@mui/material";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import profileimg from "../mysy_img/MYSY-profil-2.png";
import Abonnement from "./Abonnement";
import { ConstructionOutlined, PropaneSharp, ResetTvRounded } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RichTextEditor } from '@mantine/rte';
import { Editor } from '@tinymce/tinymce-react';
import { IoArrowUndoCircle } from "react-icons/io5";
import { GrUserAdmin, GrOrderedList } from "react-icons/gr";
import parse from 'html-react-parser';
import { format } from 'date-fns'
import moment from "moment";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Autocomplete from '@mui/material/Autocomplete';
import GestionAdministrative from "./GestionAdministrative";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { Fab } from "@material-ui/core";
import { ConsoleView } from "react-device-detect";
import fileDownload from 'js-file-download'
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { AiOutlineUserAdd } from "react-icons/ai";

import FiberNewIcon from '@mui/icons-material/FiberNew';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useCallback, } from "react";
import { DndProvider, useDrag } from "react-dnd";
import { useDrop } from 'react-dnd'

import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import Rating from '@mui/material/Rating';


import { CiBoxList } from "react-icons/ci";
import { FcPlanner } from "react-icons/fc";


const PartnerClientActivite = (props) => {

    const history = useHistory();
    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [selected_id, setselected_id] = useState("");


    const [related_collection, setrelated_collection] = useState("");

    const [current_activite_recid, setcurrent_activite_recid] = useState("");

    const [show_all_activite_list, setshow_all_activite_list] = useState("1");
    const [showactivite_detail, setshowactivite_detail] = useState();
    const [add_one_activite, setadd_one_activite] = useState("");

    function Add_Activite() {
        /*  setdatamodification_activite("0");
          setformedit_mode_activite("0");
          setcurrent_activite_recid("");
          //clean_contact_set_values();
  
          setadd_one_activite("1");
          setshowactivite_detail();
          setshow_all_activite_list();
          */

        setselected_id("");
        setDialog_ACTIVITE_open(true);

        Clear_activite_P_fields();

    }

    function Close_Activite() {
        setshowactivite_detail("0");
        setdatamodification_activite("0");
        setformedit_mode_activite("0");
        setcurrent_activite_recid("");
        //clear_contact_fields();
        //clean_contact_set_values();

    }

    function retour_liste_Activite() {
        setshowactivite_detail();
        setadd_one_activite();
        setshow_all_activite_list("1");

        setcurrent_activite_recid("");
        setformedit_mode_activite("0");
    }

    const [datamodification_activite, setdatamodification_activite] = useState("0");
    const [formedit_mode_activite, setformedit_mode_activite] = useState("0");

    function DataUpdated_Activite_Data() {
        setdatamodification_activite("1");
    }


    function DataEditMode_Activite_Data() {
        setformedit_mode_activite("1");
        Enable_activite_fields();

    }



    const type_activite_list = [
        {
            id: 'email',
            value: 'email',
            label: 'Email',
        },
        {
            id: 'call',
            value: 'call',
            label: 'Appel',
        },
        {
            id: 'meeting',
            value: 'meeting',
            label: 'Réunion',
        },
        {
            id: 'autre',
            value: 'autre',
            label: 'Autre',
        },
        {
            id: 'cmd',
            value: 'cmd',
            label: 'commande',
        },
        {
            id: 'devis',
            value: 'devis',
            label: 'devis',
        },
        {
            id: 'facture',
            value: 'facture',
            label: 'facture',
        },
        {
            id: '',
            value: '',
            label: '',
        },

    ];

    const activite_status_list = [
        {
            id: '0',
            value: '0',
            label: 'Encours',
        },
        {
            id: '1',
            value: '1',
            label: 'Fait',
        },
        {
            id: '-1',
            value: '-1',
            label: 'Annulé',
        },
        {
            id: '2',
            value: '2',
            label: 'Archivé',
        },
        {
            id: '',
            value: '',
            label: '',
        },
    ];


    const activite_status_list_pave_affiche = [
        {
            id: '0',
            value: '0',
            label: 'Encours',
        },
        {
            id: '1',
            value: '1',
            label: 'Fait',
        },
        {
            id: '-1',
            value: '-1',
            label: 'Annulé',
        },
        {
            id: '2',
            value: '2',
            label: 'Archivé',
        },

    ];


    // Données Activites
    const [p_activite_description, setp_activite_description] = useState("");
    const [p_activite_detail, setp_activite_detail] = useState("");

    const [p_activite_deadline, setp_activite_deadline] = useState(new Date());

    const [p_activite_deadline_label, setp_activite_deadline_label] = useState("");
    const [p_activite_type, setp_activite_type] = useState("");
    const [p_activite_status, setp_activite_status] = useState("0");
    const [p_activite_status_label, setp_activite_status_label] = useState("");
    const [p_activite_employee_assigned_id, setp_activite_employee_assigned_id] = useState(props.conntected_employee_id);
    const [p_activite_employee_assigned_nom_prenom, setp_activite_employee_assigned_nom_prenom] = useState();

    const [p_activite_employee_assigned, setp_activite_employee_assigned] = useState(props.conntected_employee_id);


    const [nb_activite_en_retard, setnb_activite_en_retard] = useState("");
    const [nb_activite_new, setnb_activite_new] = useState("");

    // Recuperation des activités du client d'un partner
    const [Get_List_Activite_Of_client_Part_api, setGet_List_Activite_Of_client_Part_api] = useState();
    const [Get_List_Activite_Of_client_Part_message, setGet_List_Activite_Of_client_Part_message] = useState();
    const [Get_List_Activite_Of_client_Part_result, setGet_List_Activite_Of_client_Part_result] = useState([]);
    function Get_List_Activite_Of_client_Part(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        const related_collection = props.related_collection


        form.append("token", stored_cookie);
        form.append("related_collection", related_collection);
        form.append("related_collection_owner_email", props.email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Entity_Activite/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Activite_Of_client_Part  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Activite_Of_client_Part  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Activite_Of_client_Part_api("true");
                setGet_List_Activite_Of_client_Part_result(res.data.message);
                setnb_activite_en_retard(res.data.nb_activite_en_retard);
                setnb_activite_new(res.data.nb_new_activite_assigned_to_me);


                props.update_nb_activite_en_retard(res.data.nb_activite_en_retard);
                props.update_nb_activite_en_new(res.data.nb_new_activite_assigned_to_me);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_description = JSON.parse(x).description;
                    var local_status = JSON.parse(x).status;
                    var local_type = JSON.parse(x).type;
                    var local_recid = JSON.parse(x).recid;

                    var local_deadline = JSON.parse(x).deadline;
                    var local_retard = JSON.parse(x).retard;
                    var local_employee_assigned_to_id = JSON.parse(x).employee_assigned_to_id;
                    var local_employee_assigned_view_statut = JSON.parse(x).employee_assigned_view_statut;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_description,
                        "type": local_type,
                        "description": local_description,
                        "status": local_status,
                        "deadline": local_deadline,
                        "retard": local_retard,
                        "employee_assigned_to_id": local_employee_assigned_to_id,
                        "employee_assigned_view_statut": local_employee_assigned_view_statut,
                        "recid": local_recid,
                    };

                    new_data2.push(node);
                    setTaskStatus(new_data2);
                });

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Activite_Of_client_Part_api("false");
                setGet_List_Activite_Of_client_Part_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Activite_Of_client_Part = ', error);
            setGet_List_Activite_Of_client_Part_api("false");
            //setmyApimyApiMessage("")
        })
    }


    // Recuperation des activités du client d'un partner
    const [Get_List_Activite_Assign_To_Me_api, setGet_List_Activite_Assign_To_Me_api] = useState();
    const [Get_List_Activite_Assign_To_Me_message, setGet_List_Activite_Assign_To_Me_message] = useState();
    const [Get_List_Activite_Assign_To_Me_result, setGet_List_Activite_Assign_To_Me_result] = useState();
    function Get_List_Activite_Assign_To_Me(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        const related_collection = props.related_collection


        form.append("token", stored_cookie);
        form.append("employee_assigned_to_id", props.conntected_employee_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Activite_Assign_To/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Activite_Assign_To_Me  res.data.status = " + res.data.status);
            console.log(" In Get_List_Activite_Assign_To_Me  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Activite_Assign_To_Me_api("true");
                setGet_List_Activite_Assign_To_Me_result(res.data.message);
                setnb_activite_en_retard(res.data.nb_activite_en_retard);

                props.update_nb_activite_en_retard(res.data.nb_activite_en_retard);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Activite_Assign_To_Me_api("false");
                setGet_List_Activite_Assign_To_Me_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Activite_Assign_To_Me = ', error);
            setGet_List_Activite_Assign_To_Me_api("false");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {

        setrelated_collection(props.related_collection);
        Getall_Training_Employee_No_Filter();
        Get_List_Activite_Of_client_Part();
        Get_List_Activite_Assign_To_Me();

    }, []);

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState([]);
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "ismanager": ""
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };



    // Fonction de mise à jour d'une activité
    const [recordPartnerClient_Activite_Data_api, setrecordPartnerClient_Activite_Data_api] = useState("");
    const [recordPartnerClient_Activite_Data_result, setrecordPartnerClient_Activite_Data_result] = useState("");
    const [recordPartnerClient_Activite_Data_message, setrecordPartnerClient_Activite_Data_message] = useState("");
    function recordPartnerClient_Activite_Data(event) {

        var new_dead_line_format = "";

        if (typeof p_activite_deadline == "object") {
            var act_deadline = format(p_activite_deadline, 'dd/MM/yyyy');
            new_dead_line_format = act_deadline;
        } else {
            new_dead_line_format = p_activite_deadline;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("description", p_activite_description);
        form.append("detail", p_activite_detail);

        form.append("deadline", new_dead_line_format);

        form.append("type", p_activite_type);
        form.append("status", p_activite_status);

        if (p_activite_employee_assigned_id)
            form.append("employee_assigned_to_id", p_activite_employee_assigned_id);



        // Controle d'integrité des infos
        var activite_description = ""
        if (document.getElementsByName("activite_description")) {
            activite_description = document.getElementsByName("activite_description")[0].value;
        }
        if (activite_description.length == 0) {
            alert(" Le champ 'Description' est vide ");
            return;
        }

        if (activite_description.length > 255) {
            alert(" Le champ 'Description' comporte plus de 255 caractères ");
            return;
        }


        var activite_detail = ""
        if (document.getElementsByName("activite_detail")) {
            activite_detail = document.getElementsByName("activite_detail")[0].value;
        }
        if (activite_detail.length > 500) {
            alert(" Le champ 'Détail' comporte plus de 500 caractères ");
            return;
        }


        var myurl = "";

        // Il s'agit d'un mise à jour. on a une valeur dans le current_activite_recid
        myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_activite/";
        form.append("activite_recid", current_activite_recid);



        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient_Activite_Data  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient_Activite_Data  res.data.message  = " + res.data.message);
                setrecordPartnerClient_Activite_Data_api("true");
                setrecordPartnerClient_Activite_Data_result(res.data.message);

                Get_List_Activite_Of_client_Part();
                Get_List_Activite_Assign_To_Me();

                setdatamodification_activite("0");
                setformedit_mode_activite("0");
                setcurrent_activite_recid("");


                setshow_all_activite_list("1");
                setshowactivite_detail('0');
                setadd_one_activite('0');
                alert(res.data.message);
            }

            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecordPartnerClient_Activite_Data_api("false");
                setrecordPartnerClient_Activite_Data_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient_Activite_Data = ', error);
            setrecordPartnerClient_Activite_Data_result("false");
            setrecordPartnerClient_Activite_Data_message(" Impossible d'ajouter l'activité ")
            alert(" Impossible d'ajouter/mettre à jour l'activité ");

        })
    }



    // Fonction de mise à jour d'une activité
    const [Update_Activite_Status_api, setUpdate_Activite_Status_api] = useState("");
    const [Update_Activite_Status_result, setUpdate_Activite_Status_result] = useState("");
    const [Update_Activite_Status_message, setUpdate_Activite_Status_message] = useState("");
    function Update_Activite_Status(activite_id, new_status) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("status", new_status);
        form.append("_id", activite_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_activite_status/";


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Update_Activite_Status  res.data.status = " + res.data.status);
                //console.log(" In Update_Activite_Status  res.data.message  = " + res.data.message);
                setUpdate_Activite_Status_api("true");
                setUpdate_Activite_Status_result(res.data.message);

                Get_List_Activite_Of_client_Part();
                Get_List_Activite_Assign_To_Me();

                setdatamodification_activite("0");
                setformedit_mode_activite("0");
                setcurrent_activite_recid("");


                setshow_all_activite_list("1");
                setshowactivite_detail('0');
                setadd_one_activite('0');

            }

            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setUpdate_Activite_Status_api("false");
                setUpdate_Activite_Status_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Update_Activite_Status = ', error);
            setUpdate_Activite_Status_result("false");
            setUpdate_Activite_Status_message(" Impossible d'ajouter l'activité ")
            alert(" Impossible d'ajouter/mettre à jour l'activité ");

        })
    }



    // Fonction d'ajout d'un activité
    const [record_One_PartnerClient_Activite_Data_api, setrecord_One_PartnerClient_Activite_Data_api] = useState("");
    const [record_One_PartnerClient_Activite_Data_result, setrecord_One_PartnerClient_Activite_Data_result] = useState("");
    const [record_One_PartnerClient_Activite_Data_message, setrecord_One_PartnerClient_Activite_Data_message] = useState("");
    function record_One_PartnerClient_Activite_Data(event) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        // Controle d'integrité des infos
        if (p_activite_description && p_activite_description.length == 0) {
            alert(" Le champ 'Description' est vide ");
            return;
        }

        if (p_activite_description && p_activite_description.length > 255) {
            alert(" Le champ 'Description' comporte plus de 255 caractères ");
            return;
        }

        if (p_activite_detail && p_activite_detail.length > 255) {
            alert(" Le champ 'Détail' comporte plus de 255 caractères ");
            return;
        }

        if (p_activite_description)
            form.append("description", p_activite_description);
        else
            form.append("description", "");

        if (p_activite_detail)
            form.append("detail", p_activite_detail);
        else
            form.append("detail", "");


        var new_dead_line_format = "";
        if (typeof p_activite_deadline == "object") {
            var act_deadline = format(p_activite_deadline, 'dd/MM/yyyy');
            new_dead_line_format = act_deadline;
        } else {
            new_dead_line_format = p_activite_deadline;
        }


        if (p_activite_deadline)
            form.append("deadline", new_dead_line_format);

        if (p_activite_type)
            form.append("type", p_activite_type);
        else
            form.append("type", "");

        if (p_activite_status)
            form.append("status", p_activite_status);

        if (p_activite_employee_assigned_id)
            form.append("employee_assigned_to_id", p_activite_employee_assigned_id);


        var myurl = "";

        // Il s'agit d'un ajout d'une nouvelle activité. on a rien dans le current_activite_recid
        myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_activite/";
        form.append("related_collection", props.related_collection);
        form.append("related_collection_owner_email", props.email);


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In recordPartnerClient_Activite_Data  res.data.status = " + res.data.status);
                //console.log(" In recordPartnerClient_Activite_Data  res.data.message  = " + res.data.message);
                setrecord_One_PartnerClient_Activite_Data_api("true");
                setrecord_One_PartnerClient_Activite_Data_result(res.data.message);
                Clear_activite_P_fields();
                setshowactivite_detail("0");
                Get_List_Activite_Of_client_Part();
                Get_List_Activite_Assign_To_Me();

                alert(res.data.message);
                setdatamodification_activite("0");
                setformedit_mode_activite("0");
                setcurrent_activite_recid("");
                retour_liste_Activite();
            }

            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setrecord_One_PartnerClient_Activite_Data_api("false");
                setrecord_One_PartnerClient_Activite_Data_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( recordPartnerClient_Activite_Data = ', error);
            setrecord_One_PartnerClient_Activite_Data_api("false");
            setrecord_One_PartnerClient_Activite_Data_message(" Impossible d'ajouter l'activité ")
            alert(" Impossible d'ajouter/mettre à jour l'activité ");

        })
    }

    const [Display_Part_Client_Activite_api, setDisplay_Part_Client_Activite_api] = useState("");
    const [Display_Part_Client_Activite_result, setDisplay_Part_Client_Activite_result] = useState("");
    const [Display_Part_Client_Activite_message, setDisplay_Part_Client_Activite_message] = useState("");
    async function Display_Part_Client_Activite(event) {


        var myactivity_recid = event.currentTarget.getAttribute('recid');

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("activite_recid", myactivity_recid);



        //console.log(" ### event.currentTarget.getAttribute('recid') = ", event.currentTarget.getAttribute('recid')); zzzzz

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Activite/";


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Display_Part_Client_Activite  res.data.status = " + res.data.status);
                //console.log(" In Display_Part_Client_Activite  res.data.message  = " + res.data.message);
                setDisplay_Part_Client_Activite_api("true");
                setDisplay_Part_Client_Activite_result(res.data.message);

                setshowactivite_detail("1");
                setshow_all_activite_list();
                setadd_one_activite();


                var mylocalclient_activite = JSON.parse(res.data.message);


                if (mylocalclient_activite) {

                    // Remplissage des doonées de l'activite
                    if (mylocalclient_activite.description)
                        setp_activite_description(mylocalclient_activite.description);
                    else
                        setp_activite_description("");

                    if (mylocalclient_activite.detail)
                        setp_activite_detail(mylocalclient_activite.detail);
                    else
                        setp_activite_detail("");

                    if (mylocalclient_activite.deadline) {
                        var act_deadline = new Date(moment(mylocalclient_activite.deadline, "DD/MM/YYYY HH:mm:ss"));
                        setp_activite_deadline(act_deadline);
                        setp_activite_deadline_label(mylocalclient_activite.deadline);
                    }
                    else
                        setp_activite_deadline(new Date());


                    if (mylocalclient_activite.employee_assigned_to_id) {
                        setp_activite_employee_assigned_nom_prenom(mylocalclient_activite.employee_assigned_to_nom_prenom);
                        setp_activite_employee_assigned_id(mylocalclient_activite.employee_assigned_to_id);
                    }


                    if (mylocalclient_activite.type)
                        setp_activite_type(mylocalclient_activite.type);
                    else
                        setp_activite_type("");


                    if (mylocalclient_activite.status) {
                        setp_activite_status(mylocalclient_activite.status);
                        if (String(mylocalclient_activite.status) === "0")
                            setp_activite_status_label("En cours");

                        else if (String(mylocalclient_activite.status) === "1")
                            setp_activite_status_label("Fait");

                        else if (String(mylocalclient_activite.status) === "-1")
                            setp_activite_status_label("Annulé");


                    }

                    else
                        setp_activite_status("");


                    if (mylocalclient_activite.recid)
                        setcurrent_activite_recid(mylocalclient_activite.recid);
                    else
                        setcurrent_activite_recid("");
                }


                Disable_activite_fields();

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setDisplay_Part_Client_Activite_api("false");
                setDisplay_Part_Client_Activite_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Display_Part_Client_Activite = ', error);
            setDisplay_Part_Client_Activite_result("false");
            setDisplay_Part_Client_Activite_message(" Impossible de recuperer les details de l'activité")
            alert(" Impossible de recuperer les details de l'activité");

        })
    }


    const [Display_Given_Activite_From_Activite_Recid_api, setDisplay_Given_Activite_From_Activite_Recid_api] = useState("");
    const [Display_Given_Activite_From_Activite_Recid_result, setDisplay_Given_Activite_From_Activite_Recid_result] = useState("");
    const [Display_Given_Activite_From_Activite_Recid_message, setDisplay_Given_Activite_From_Activite_Recid_message] = useState("");
    async function Display_Given_Activite_From_Activite_Recid(myactivity_recid) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("activite_recid", myactivity_recid);


        //console.log(" ### event.currentTarget.getAttribute('recid') = ", event.currentTarget.getAttribute('recid'));

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Activite/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In Display_Given_Activite_From_Activite_Recid  res.data.status = " + res.data.status);
                // console.log(" In Display_Given_Activite_From_Activite_Recid  res.data.message  = " + res.data.message);
                setDisplay_Given_Activite_From_Activite_Recid_api("true");
                setDisplay_Given_Activite_From_Activite_Recid_result(res.data.message);

                props.update_nb_activite_en_new(res.data.nb_new_activite_assigned_to_me);

                var mylocalclient_activite = JSON.parse(res.data.message);


                if (mylocalclient_activite) {

                    setselected_id(mylocalclient_activite._id);

                    // Remplissage des doonées de l'activite
                    if (mylocalclient_activite.description)
                        setp_activite_description(mylocalclient_activite.description);
                    else
                        setp_activite_description("");

                    if (mylocalclient_activite.detail)
                        setp_activite_detail(mylocalclient_activite.detail);
                    else
                        setp_activite_detail("");

                    if (mylocalclient_activite.deadline) {

                        var act_deadline = new Date(moment(mylocalclient_activite.deadline, "DD/MM/YYYY HH:mm:ss"));
                        setp_activite_deadline(act_deadline);
                        setp_activite_deadline_label(mylocalclient_activite.deadline);
                    }
                    else
                        setp_activite_deadline(new Date());


                    if (mylocalclient_activite.employee_assigned_to_id) {
                        setp_activite_employee_assigned_nom_prenom(mylocalclient_activite.employee_assigned_to_nom_prenom);
                        setp_activite_employee_assigned_id(mylocalclient_activite.employee_assigned_to_id);
                    }


                    if (mylocalclient_activite.type)
                        setp_activite_type(mylocalclient_activite.type);
                    else
                        setp_activite_type("");


                    if (mylocalclient_activite.status) {
                        setp_activite_status(mylocalclient_activite.status);
                        if (String(mylocalclient_activite.status) === "0")
                            setp_activite_status_label("En cours");

                        else if (String(mylocalclient_activite.status) === "1")
                            setp_activite_status_label("Fait");

                        else if (String(mylocalclient_activite.status) === "-1")
                            setp_activite_status_label("Annulé");

                    }

                    else
                        setp_activite_status("");


                    if (mylocalclient_activite.recid)
                        setcurrent_activite_recid(mylocalclient_activite.recid);
                    else
                        setcurrent_activite_recid("");



                    // Apres avoir ouvert l'activité, on va rafraichir l'affichage de a liste des activitées qui me sont assignée
                    // ceci pour actualiser le statut "employee_assigned_view_statut"
                    Get_List_Activite_Assign_To_Me();
                }


                //  Disable_activite_fields();

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                setDisplay_Given_Activite_From_Activite_Recid_api("false");
                setDisplay_Given_Activite_From_Activite_Recid_message(res.data.message);
                alert(res.data.message);

            }


        }).catch((error) => {
            console.warn('Not good man :( Display_Given_Activite_From_Activite_Recid = ', error);
            setDisplay_Given_Activite_From_Activite_Recid_result("false");
            setDisplay_Given_Activite_From_Activite_Recid_message(" Impossible de recuperer les details de l'activité")
            alert(" Impossible de recuperer les details de l'activité");

        })
    }

    async function Disable_activite_fields() {

        await sleep(5);

        if (document.getElementsByName("activite_description")[0]) {
            document.getElementsByName("activite_description")[0].disabled = true;
            document.getElementsByName("activite_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_deadline")[0]) {
            document.getElementsByName("activite_deadline")[0].disabled = true;
            document.getElementsByName("activite_deadline")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_type")[0]) {
            document.getElementsByName("activite_type")[0].disabled = true;
            document.getElementsByName("activite_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_status")[0]) {
            document.getElementsByName("activite_status")[0].disabled = true;
            document.getElementsByName("activite_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("activite_assigned_to")[0]) {
            document.getElementsByName("activite_assigned_to")[0].disabled = true;
            document.getElementsByName("activite_assigned_to")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("activite_detail")[0]) {
            document.getElementsByName("activite_detail")[0].disabled = true;
            document.getElementsByName("activite_detail")[0].style.backgroundColor = "#ECEFF1";
        }


    }

    function Enable_activite_fields() {
        if (document.getElementsByName("activite_description")) {
            document.getElementsByName("activite_description")[0].disabled = false;
            document.getElementsByName("activite_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_deadline")) {
            document.getElementsByName("activite_deadline")[0].disabled = false;
            document.getElementsByName("activite_deadline")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_type")) {
            document.getElementsByName("activite_type")[0].disabled = false;
            document.getElementsByName("activite_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_status")) {
            document.getElementsByName("activite_status")[0].disabled = false;
            document.getElementsByName("activite_status")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("activite_assigned_to")) {
            document.getElementsByName("activite_assigned_to")[0].disabled = false;
            document.getElementsByName("activite_assigned_to")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("activite_detail")) {
            document.getElementsByName("activite_detail")[0].disabled = false;
            document.getElementsByName("activite_detail")[0].style.backgroundColor = "#FFFFFF";
        }

    }



    function Clear_activite_P_fields() {
        setp_activite_description("");
        setp_activite_detail("");
        setp_activite_deadline(new Date());
        setp_activite_type("");
        setp_activite_status("0");
        setp_activite_status_label("");
        setp_activite_detail("");

        setone_activite_description("");
        setone_activite_type("");
        setone_activite_status("call");
        setone_activite_detail("");

    }



    function Clear_activite_fields() {
        if (document.getElementsByName("activite_description")) {
            document.getElementsByName("activite_description")[0].value = "";
        }

        if (document.getElementsByName("activite_deadline")) {
            document.getElementsByName("activite_deadline")[0].value = "";
        }

        if (document.getElementsByName("activite_type")) {
            document.getElementsByName("activite_type")[0].value = "";
        }

        if (document.getElementsByName("activite_status")) {
            document.getElementsByName("activite_status")[0].value = "";
        }

        if (document.getElementsByName("activite_detail")) {
            document.getElementsByName("activite_detail")[0].value = "";
        }

    }



    const [one_activite_description, setone_activite_description] = useState();
    const [one_activite_deadline, setone_activite_deadline] = useState(new Date().toLocaleDateString('fr-FR'));
    const [one_activite_type, setone_activite_type] = useState("call");
    const [one_activite_status, setone_activite_status] = useState("0");
    const [one_activite_detail, setone_activite_detail] = useState();

    function Close_add_one_activite() {
        setone_activite_description("");
        setone_activite_deadline(new Date().toLocaleDateString('fr-FR'));
        setone_activite_type("call");
        setone_activite_status("0");
        setone_activite_detail("");

        setshowactivite_detail();

        setshowactivite_detail();
        setadd_one_activite();
        setshow_all_activite_list("1");

        setcurrent_activite_recid("");
        setformedit_mode_activite("0");

    }


    /*******  kanban */

    const [kanban_colonne_width, setkanban_colonne_width] = useState("20vw");

    // MySy Comment : design d'une tache (couleur, taille, etc)
    const classes = {
        board: {
            display: "flex",
            margin: "0 auto",
            width: "100%",
            fontFamily: 'Arial, "Helvetica Neue", sans-serif'
        },
        column: {
            // minWidth: 200,
            //width: "16vw",
            width: kanban_colonne_width,
            height: "80vh",
            margin: "0 auto",
            backgroundColor: "#212F3D",
            border: 'double #32a1ce',

        },
        columnHead: {
            textAlign: "center",
            padding: 10,
            fontSize: "1.2em",
            backgroundColor: "#C6D8AF",
            border: "red",
        },
        item: {
            padding: 10,
            margin: 10,
            fontSize: "0.8em",
            cursor: "pointer",
            backgroundColor: "white"
        }
    };


    const KanbanColumn = ({ status, changeTaskStatus, children }) => {
        const ref = useRef(null);
        const [, drop] = useDrop({
            accept: "card",
            drop(item) {
                changeTaskStatus(item.id, status);
            }
        });
        drop(ref);
        return <div ref={ref}> {children}</div>;
    };

    const KanbanItem = ({ id, children }) => {
        const ref = useRef(null);


        const [{ isDragging }, drag] =
            useDrag({
                type: "card",
                item: () => ({ id }),
                collect: monitor => ({
                    isDragging: monitor.isDragging()
                })
            });

        const opacity = isDragging ? 0 : 1;
        drag(ref);
        return (
            <div ref={ref} style={{ opacity }}>
                {children}
            </div>
        );
    };

    const [tasks, setTaskStatus] = useState([]);

    // MySy Comment : quand on change la position d'une tache, dans cette fonction on appel l'api de mise à jour
    const changeTaskStatus = useCallback(
        (id, status) => {
            let local_task = tasks.find(task => task._id === id);

            const taskIndex = tasks.indexOf(local_task);
            local_task = { ...local_task, status };
            let newTasks = update(tasks, {
                [taskIndex]: { $set: local_task }
            });

            setTaskStatus(newTasks);


            // Recuperer l'_id du statuts
            if (id && status) {
                Update_Activite_Status(id, status);
            }
            //eeeee */

        },
        [tasks]
    );

    const [New_Option_Status, setNew_Option_Status] = useState([]);

    const [Opportunite_chanel_label, setOpportunite_chanel_label] = useState({
        "0": "Encours",
        "1": "Fait",
        "2": "Archivé",
        "-1": "Annulé",
    });

    /*******  Fin kanban  */

    const [isLoading, setLoading] = useState();

    const [handleClick_delete_Activite_data_from_id_api, sethandleClick_delete_Activite_data_from_id_api] = useState();
    const [handleClick_delete_Activite_data_from_id_message, sethandleClick_delete_Activite_data_from_id_message] = useState();
    const [handleClick_delete_Activite_data_from_id_result, sethandleClick_delete_Activite_data_from_id_result] = useState();
    function handleClick_delete_Activite_data_from_id(id_to_delete) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", id_to_delete);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Activite/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Activite_data_from_id  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Activite_data_from_id  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Activite_data_from_id_api("true");
                sethandleClick_delete_Activite_data_from_id_result(res.data.message);

                Get_List_Activite_Of_client_Part();
                Get_List_Activite_Assign_To_Me();
                Dialog_ACTIVITE_handleClose_buton();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Activite_data_from_id_api("false");
                sethandleClick_delete_Activite_data_from_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Activite_data_from_id = ', error);
            sethandleClick_delete_Activite_data_from_id_api("false");
            alert(" Impossible de supprimer l'activité");
            //setmyApimyApiMessage("")
        })
    }




    const [Dialog_ACTIVITE_open, setDialog_ACTIVITE_open] = React.useState(false);
    const Dialog_ACTIVITE_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_ACTIVITE_handleClose_buton = () => {
        setDialog_ACTIVITE_open(false);
        setselected_id("");
        setdatamodification_activite("");
    };


    return (
        <div className="partner_client_activite" >


            {/*  Dialog pour gerer une ACTIVITE */}
            <Dialog
                open={Dialog_ACTIVITE_open}
                onClose={Dialog_ACTIVITE_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
                    <DialogContentText>

                    </DialogContentText>


                    <div className="session_caract_Dialog"> Description  <br />

                        <TextField
                            required
                            name="activite_description"
                            //label="Description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_description}
                            onChange={(event) => {
                                setp_activite_description(event.target.value);
                                setdatamodification_activite("1");
                            }}

                        />
                    </div>


                    <div className="session_caract_Dialog">
                        Date souhaitée <br />
                        <DatePicker
                            name="activite_deadline"
                            id="activite_deadline"
                            //  selected={p_activite_deadline} zzzz

                            selected={new Date(moment(p_activite_deadline, "DD/MM/YYYY HH:mm:ss"))}
                            onChange={(date) => {

                                setp_activite_deadline(format(date, 'dd/MM/yyyy'));
                                setdatamodification_activite("1");
                            }
                            }
                            showTimeSelect={false}
                            filterTime={filterPassedTime_start}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'
                        />

                    </div>



                    {type_activite_list && type_activite_list.length > 0 && <div className="session_caract_Dialog">
                        Type d'activité <br />

                        <Autocomplete
                            disablePortal
                            name="activite_type"
                            id="activite_type"
                            className="disabled_style"

                            options={type_activite_list}
                            value={type_activite_list.filter((data) => (data).id === String(p_activite_type))[0].label}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_activite_type(value.value);
                                    setdatamodification_activite("1");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>}



                    {activite_status_list && activite_status_list.length > 0 && <div className="session_caract_Dialog">
                        Statut <br />

                        <Autocomplete
                            disablePortal
                            name="activite_status"
                            id="activite_status"
                            className="disabled_style"

                            options={activite_status_list}
                            value={activite_status_list.filter((data) => (data).id === String(p_activite_status))[0].label}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_activite_status(value.value);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>}


                    {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&
                        <div className="session_caract_Dialog" > Assigné à (employé)<br />

                            <Autocomplete
                                disablePortal
                                id="activite_assigned_to"
                                name="activite_assigned_to"
                                className="disabled_style"
                                options={New_Getall_Training_Employee_No_Filter_result}
                                value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_activite_employee_assigned_id))[0].label}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_activite_employee_assigned_id(value._id);
                                        setdatamodification_activite("1");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />


                        </div>}

                    <div className="session_caract_Dialog" >
                        Détail <br />
                        <TextField
                            required
                            name="activite_detail"
                            //  label="Détail"
                            multiline


                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_detail}

                            onChange={(event) => {
                                setp_activite_detail(event.target.value);
                                setdatamodification_activite("1");
                            }}

                        />
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(datamodification_activite) === "1" && <div className="div_row">
                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}



                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">


                            {selected_id && String(selected_id).length > 2 && <Button onClick={(e) => {

                                recordPartnerClient_Activite_Data();
                                Dialog_ACTIVITE_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Mettre à jour </Button>}


                            {String(selected_id).length < 2 && <Button onClick={(e) => {
                                record_One_PartnerClient_Activite_Data();
                                Dialog_ACTIVITE_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Ajouter </Button>}


                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_ACTIVITE_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                        {selected_id && String(selected_id).length > 2 && <div className="div_row">
                            <div className="div_row" style={{ "textAlign": "center", "marginTop": "10px" }}>
                                <Button onClick={(e) => {
                                    const response = window.confirm("Confirmez-vous la suppression de l'opportunité ?");
                                    if (!response) {
                                        return;
                                    }
                                    handleClick_delete_Activite_data_from_id(selected_id);
                                }
                                } className="bton_supprime_dialog">Supprimer l'activité</Button>
                            </div>
                        </div>}
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer une ACTIVITE */}



            <div className="div_row">
                {String(show_all_activite_list) === "1" && String(showactivite_detail) !== "1" && String(add_one_activite) !== "1" &&
                    <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }}>
                        <div className="div_row" onClick={Add_Activite} style={{ "cursor": "pointer" }}> <AiOutlineUserAdd /> Ajouter une activité  </div>

                        {/*<div> 
                        <div className="div_row">
                            <div className="div_row" style={{ "border": "None" }}>
                                <div className="titre1" > Activité(s) qui me sont Assignée(s) </div>
                            </div>
                            <div className="block_all_activite">

                                {Get_List_Activite_Assign_To_Me_result && String(Get_List_Activite_Assign_To_Me_api) === "true" &&
                                    Get_List_Activite_Assign_To_Me_result.map((client) => (
                                        <nav className="block_activite">


                                            {String(String(JSON.parse(client).status)) === "1" &&
                                                <nav recid={String(JSON.parse(client).recid)}
                                                    onClick={(event) => {
                                                        Display_Given_Activite_From_Activite_Recid(String(JSON.parse(client).recid));
                                                        setDialog_ACTIVITE_open(true);
                                                    }}

                                                    className="detail_activite">

                                                    {JSON.parse(client).employee_assigned_view_statut && String(JSON.parse(client).employee_assigned_view_statut) !== "1" &&
                                                        <nav style={{ marginRight: "10px", textAlign: "right" }}> <FiberNewIcon style={{ color: 'red', }} />  </nav>}

                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >
                                                        <i>FAIT </i>  <br />
                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                            {String(String(JSON.parse(client).status)) === "0" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={(event) => {
                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(client).recid));
                                                    setDialog_ACTIVITE_open(true);
                                                }} className="detail_activite">

                                                    {JSON.parse(client).employee_assigned_view_statut && String(JSON.parse(client).employee_assigned_view_statut) !== "1" &&
                                                        <nav style={{ marginRight: "10px", textAlign: "right" }}> <FiberNewIcon style={{ color: 'red', }} />   </nav>}

                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >

                                                        {parseInt(String(JSON.parse(client).retard)) < 0 && <i style={{ "color": "red" }}> RETARD de {String(JSON.parse(client).retard)} jours <br /></i>}
                                                        {parseInt(String(JSON.parse(client).retard)) > 0 && <i> DANS  {String(JSON.parse(client).retard)} jours <br /></i>}
                                                        {parseInt(String(JSON.parse(client).retard)) == 0 && <i style={{ "color": "orange" }}> AUJOURD'HUI <br /></i>}


                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                            {String(String(JSON.parse(client).status)) === "-1" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={(event) => {
                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(client).recid));
                                                    setDialog_ACTIVITE_open(true);
                                                }} className="detail_activite">
                                                    {JSON.parse(client).employee_assigned_view_statut && String(JSON.parse(client).employee_assigned_view_statut) !== "1" &&
                                                        <nav> <FiberNewIcon style={{ color: 'red', fontSize: '10px' }} />  </nav>}

                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >
                                                        <i>ANNULE </i>  <br />
                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}




                                        </nav>
                                    ))}

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                        </div>
                        <div className="div_row">
                            <div className="div_row" style={{ "border": "None" }}>
                                <div className="titre1" > Activté(s) que j'ai créée(s)  </div>
                            </div>
                            <div className="block_all_activite">

                                {Get_List_Activite_Of_client_Part_result && String(Get_List_Activite_Of_client_Part_api) === "true" &&
                                    Get_List_Activite_Of_client_Part_result.map((client) => (
                                        <nav className="block_activite">

                                            {String(String(JSON.parse(client).status)) === "1" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={(event) => {
                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(client).recid));
                                                    setDialog_ACTIVITE_open(true);
                                                }} className="detail_activite">
                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >
                                                        <i>FAIT </i>  <br />
                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                            {String(String(JSON.parse(client).status)) === "0" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={(event) => {
                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(client).recid));
                                                    setDialog_ACTIVITE_open(true);
                                                }} className="detail_activite">


                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >

                                                        {parseInt(String(JSON.parse(client).retard)) < 0 && <i style={{ "color": "red" }}> RETARD de {String(JSON.parse(client).retard)} jours <br /></i>}
                                                        {parseInt(String(JSON.parse(client).retard)) > 0 && <i> DANS  {String(JSON.parse(client).retard)} jours <br /></i>}
                                                        {parseInt(String(JSON.parse(client).retard)) == 0 && <i style={{ "color": "orange" }}> AUJOURD'HUI <br /></i>}

                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                            {String(String(JSON.parse(client).status)) === "-1" &&
                                                <nav recid={String(JSON.parse(client).recid)} onClick={(event) => {
                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(client).recid));
                                                    setDialog_ACTIVITE_open(true);
                                                }} className="detail_activite">
                                                    <nav className="detail_header" recid={String(JSON.parse(client).recid)} >  {String(JSON.parse(client).deadline)} : {String(JSON.parse(client).type)}</nav>
                                                    <nav recid={String(JSON.parse(client).recid)} >
                                                        <i>ANNULE </i>  <br />
                                                        {(JSON.parse(client).description).substring(0, 50)}<br />
                                                        {(JSON.parse(client).detail).substring(0, 50)}<br />

                                                    </nav>
                                                </nav>}

                                        </nav>
                                    ))}

                            </div>

                        </div>
                        </div>*/}

                        <div className="div_row" style={{ "border": "None" }}>
                            <div className="titre1" > Activité(s) qui me sont Assignée(s) </div>
                        </div>
                        {/*<div style={{ "textAlign": "right", "float": "right", "width": "100%", "paddingRight": "15px" }}>
                            Affichage : <nav className="mode_affichage" onClick={"func_display_kanban_view"}> <FcPlanner /> Kanban </nav>
                            <nav className="mode_affichage" onClick={"func_display_list_view"}> <CiBoxList /> Liste </nav>
                        </div>*/}


                        <div className="div_row">
                            <DndProvider backend={HTML5Backend}>
                                <section style={classes.board}>
                                    {activite_status_list_pave_affiche.map(channel => (
                                        <KanbanColumn
                                            key={channel.id}
                                            status={channel.id}
                                            changeTaskStatus={changeTaskStatus}
                                        >
                                            <div style={classes.column}>
                                                <div style={classes.columnHead}>{Opportunite_chanel_label[channel.id]}</div>
                                                <div style={{ overflowY: 'auto', height: '95%' }}>
                                                    {Get_List_Activite_Assign_To_Me_result && Get_List_Activite_Assign_To_Me_result.length > 0 &&
                                                        Get_List_Activite_Assign_To_Me_result.filter(item => JSON.parse(item).status === channel.id)
                                                            .map(item => (
                                                                <KanbanItem key={JSON.parse(item)._id} id={JSON.parse(item)._id} actvite_recid={JSON.parse(item).recid}>
                                                                    <br />
                                                                    <div id={JSON.parse(item)._id}

                                                                    >

                                                                        {String(String(JSON.parse(item).status)) === "1" &&
                                                                            <nav recid={String(JSON.parse(item).recid)}
                                                                                onClick={(event) => {
                                                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(item).recid));
                                                                                    setDialog_ACTIVITE_open(true);
                                                                                }}

                                                                                className="detail_activite">
                                                                                <nav className="detail_header" recid={String(JSON.parse(item).recid)} >  {String(JSON.parse(item).deadline)} : {String(JSON.parse(item).type)}</nav>
                                                                                <nav recid={String(JSON.parse(item).recid)} >
                                                                                    <i>FAIT </i>  <br />
                                                                                    {(JSON.parse(item).description).substring(0, 50)}<br />
                                                                                    {(JSON.parse(item).detail).substring(0, 50)}<br />

                                                                                </nav>
                                                                            </nav>}

                                                                        {String(String(JSON.parse(item).status)) === "0" &&
                                                                            <nav recid={String(JSON.parse(item).recid)}

                                                                                onClick={(event) => {
                                                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(item).recid));
                                                                                    setDialog_ACTIVITE_open(true);
                                                                                }}

                                                                                className="detail_activite">
                                                                                {JSON.parse(item).employee_assigned_view_statut && String(JSON.parse(item).employee_assigned_view_statut) !== "1" &&
                                                                                    <nav style={{ marginRight: "10px", textAlign: "right" }}> <FiberNewIcon style={{ color: 'red', }} />   </nav>}

                                                                                <nav className="detail_header" recid={String(JSON.parse(item).recid)} >  {String(JSON.parse(item).deadline)} : {String(JSON.parse(item).type)}</nav>
                                                                                <nav recid={String(JSON.parse(item).recid)} >


                                                                                    {parseInt(String(JSON.parse(item).retard)) < 0 && <i style={{ "color": "red" }}> RETARD de {String(JSON.parse(item).retard)} jours <br /></i>}
                                                                                    {parseInt(String(JSON.parse(item).retard)) > 0 && <i> DANS  {String(JSON.parse(item).retard)} jours <br /></i>}
                                                                                    {parseInt(String(JSON.parse(item).retard)) == 0 && <i style={{ "color": "orange" }}> AUJOURD'HUI <br /></i>}


                                                                                    {(JSON.parse(item).description).substring(0, 50)}<br />
                                                                                    {(JSON.parse(item).detail).substring(0, 50)}<br />

                                                                                </nav>
                                                                            </nav>}

                                                                        {String(String(JSON.parse(item).status)) === "-1" &&
                                                                            <nav recid={String(JSON.parse(item).recid)}

                                                                                onClick={(event) => {
                                                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(item).recid));
                                                                                    setDialog_ACTIVITE_open(true);
                                                                                }}

                                                                                className="detail_activite">
                                                                                <nav className="detail_header" recid={String(JSON.parse(item).recid)} >  {String(JSON.parse(item).deadline)} : {String(JSON.parse(item).type)}</nav>
                                                                                <nav recid={String(JSON.parse(item).recid)} >
                                                                                    <i>ANNULE </i>  <br />
                                                                                    {(JSON.parse(item).description).substring(0, 50)}<br />
                                                                                    {(JSON.parse(item).detail).substring(0, 50)}<br />

                                                                                </nav>
                                                                            </nav>}
                                                                    </div>

                                                                </KanbanItem>
                                                            ))}
                                                </div>
                                            </div>
                                        </KanbanColumn>
                                    ))}
                                </section>
                            </DndProvider>
                            <div className="div_row"> &nbsp;</div>


                        </div>


                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                            <div className="titre1" > Activté(s) que j'ai créée(s)  </div>

                        </div>
                        {/*<div style={{ "textAlign": "right", "float": "right", "width": "100%", "paddingRight": "15px" }}>
                            Affichage : <nav className="mode_affichage" onClick={"func_display_kanban_view"}> <FcPlanner /> Kanban </nav>
                            <nav className="mode_affichage" onClick={"func_display_list_view"}> <CiBoxList /> Liste </nav>
                        </div>*/}


                        <div className="div_row">
                            <DndProvider backend={HTML5Backend}>
                                <section style={classes.board}>
                                    {activite_status_list_pave_affiche.map(channel => (
                                        <KanbanColumn
                                            key={channel.id}
                                            status={channel.id}
                                            changeTaskStatus={changeTaskStatus}
                                        >
                                            <div style={classes.column}>
                                                <div style={classes.columnHead}>{Opportunite_chanel_label[channel.id]}</div>
                                                <div style={{ overflowY: 'auto', height: '95%' }}>
                                                    {Get_List_Activite_Of_client_Part_result && Get_List_Activite_Of_client_Part_result.length > 0 &&
                                                        Get_List_Activite_Of_client_Part_result.filter(item => JSON.parse(item).status === channel.id)
                                                            .map(item => (
                                                                <KanbanItem key={JSON.parse(item)._id} id={JSON.parse(item)._id} actvite_recid={JSON.parse(item).recid}>
                                                                    <br />
                                                                    <div id={JSON.parse(item)._id}

                                                                    >

                                                                        {String(String(JSON.parse(item).status)) === "1" &&
                                                                            <nav recid={String(JSON.parse(item).recid)}
                                                                                onClick={(event) => {
                                                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(item).recid));
                                                                                    setDialog_ACTIVITE_open(true);
                                                                                }}

                                                                                className="detail_activite">
                                                                                <nav className="detail_header" recid={String(JSON.parse(item).recid)} >  {String(JSON.parse(item).deadline)} : {String(JSON.parse(item).type)}</nav>
                                                                                <nav recid={String(JSON.parse(item).recid)} >
                                                                                    <i>FAIT </i>  <br />
                                                                                    {(JSON.parse(item).description).substring(0, 50)}<br />
                                                                                    {(JSON.parse(item).detail).substring(0, 50)}<br />

                                                                                </nav>
                                                                            </nav>}

                                                                        {String(String(JSON.parse(item).status)) === "0" &&
                                                                            <nav recid={String(JSON.parse(item).recid)}

                                                                                onClick={(event) => {
                                                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(item).recid));
                                                                                    setDialog_ACTIVITE_open(true);
                                                                                }}

                                                                                className="detail_activite">
                                                                                <nav className="detail_header" recid={String(JSON.parse(item).recid)} >  {String(JSON.parse(item).deadline)} : {String(JSON.parse(item).type)}</nav>
                                                                                <nav recid={String(JSON.parse(item).recid)} > 

                                                                                    {JSON.parse(item).employee_assigned_view_statut && String(JSON.parse(item).employee_assigned_view_statut) !== "1" &&
                                                                                        <nav style={{ marginRight: "10px", textAlign: "right" }}> <FiberNewIcon style={{ color: 'red', }} />   </nav>}

                                                                                    {parseInt(String(JSON.parse(item).retard)) < 0 && <i style={{ "color": "red" }}> RETARD de {String(JSON.parse(item).retard)} jours <br /></i>}
                                                                                    {parseInt(String(JSON.parse(item).retard)) > 0 && <i> DANS  {String(JSON.parse(item).retard)} jours <br /></i>}
                                                                                    {parseInt(String(JSON.parse(item).retard)) == 0 && <i style={{ "color": "orange" }}> AUJOURD'HUI <br /></i>}


                                                                                    {(JSON.parse(item).description).substring(0, 50)}<br />
                                                                                    {(JSON.parse(item).detail).substring(0, 50)}<br />

                                                                                </nav>
                                                                            </nav>}

                                                                        {String(String(JSON.parse(item).status)) === "-1" &&
                                                                            <nav recid={String(JSON.parse(item).recid)}

                                                                                onClick={(event) => {
                                                                                    Display_Given_Activite_From_Activite_Recid(String(JSON.parse(item).recid));
                                                                                    setDialog_ACTIVITE_open(true);
                                                                                }}

                                                                                className="detail_activite">
                                                                                <nav className="detail_header" recid={String(JSON.parse(item).recid)} >  {String(JSON.parse(item).deadline)} : {String(JSON.parse(item).type)}</nav>
                                                                                <nav recid={String(JSON.parse(item).recid)} >
                                                                                    <i>ANNULE </i>  <br />
                                                                                    {(JSON.parse(item).description).substring(0, 50)}<br />
                                                                                    {(JSON.parse(item).detail).substring(0, 50)}<br />

                                                                                </nav>
                                                                            </nav>}
                                                                    </div>

                                                                </KanbanItem>
                                                            ))}
                                                </div>
                                            </div>
                                        </KanbanColumn>
                                    ))}
                                </section>
                            </DndProvider>
                            <div className="div_row"> &nbsp;</div>


                        </div>




                    </div>}





                {String(showactivite_detail) === "ttt1" && <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }} onChange={DataUpdated_Activite_Data}>
                    <div className="training_caract"> Description  <br />

                        <TextField
                            required
                            name="activite_description"
                            //label="Description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_description}
                            onChange={(e) => setp_activite_description(e.target.value)}

                        />
                    </div>


                    {String(formedit_mode_activite) === "1" && <div className="training_caract">
                        Date souhaitée <br />
                        <DatePicker
                            name="activite_deadline"
                            id="activite_deadline"
                            selected={p_activite_deadline}
                            onChange={(date) => {
                                setp_activite_deadline(date);
                                setdatamodification_activite("1");
                            }
                            }
                            showTimeSelect={false}
                            filterTime={filterPassedTime_start}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'
                        />

                    </div>}

                    {String(formedit_mode_activite) === "0" && <div className="training_caract">

                        Date souhaitée <br />
                        <TextField
                            required
                            name="activite_deadline"
                            //label="Date souhaitée"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_deadline_label}
                        />

                    </div>}

                    {String(formedit_mode_activite) === "1" && type_activite_list && type_activite_list.length > 0 && <div className="training_caract">
                        Type d'activité <br />

                        <Autocomplete
                            disablePortal
                            name="activite_type"
                            id="activite_type"
                            className="disabled_style"

                            options={type_activite_list}
                            value={type_activite_list.filter((data) => (data).id === String(p_activite_type))[0].label}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_activite_type(value.value);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>}

                    {String(formedit_mode_activite) === "0" && <div className="training_caract">
                        Type d'activité <br />
                        <TextField
                            required
                            name="activite_type"
                            //label="Type d'activité"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_type}
                            onChange={(e) => setp_activite_type(e.target.value)} >

                        </TextField>

                    </div>}

                    {String(formedit_mode_activite) === "1" && activite_status_list && activite_status_list.length > 0 &&
                        <div className="training_caract">
                            Statut <br />

                            <Autocomplete
                                disablePortal
                                name="activite_status"
                                id="activite_status"
                                className="disabled_style"

                                options={activite_status_list}
                                value={activite_status_list.filter((data) => (data).id === String(p_activite_status))[0].label}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_activite_status(value.value);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}

                    {String(formedit_mode_activite) === "0" && <div className="training_caract">
                        Statut <br />
                        <TextField
                            required
                            name="activite_status"
                            //label="Statut"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_activite_status_label}
                            onChange={(e) => setp_activite_status(e.target.value)} >
                        </TextField>
                    </div>}


                    {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&
                        String(formedit_mode_activite) === "1" && <div className="training_caract" > Assigné à (employé)<br />
                            <Autocomplete
                                disablePortal
                                id="activite_assigned_to"
                                name="activite_assigned_to"
                                className="disabled_style"
                                options={New_Getall_Training_Employee_No_Filter_result}
                                //value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_activite_employee_assigned_id))[0].label}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_activite_employee_assigned_id(value._id);

                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}

                    {String(formedit_mode_activite) !== "1" && <div className="training_caract" > Assigné à (employé) <br />

                        <TextField

                            margin="dense"
                            id="activite_assigned_to"
                            name="activite_assigned_to"
                            className="disabled_style"

                            fullWidth

                            value={p_activite_employee_assigned_nom_prenom}

                        />

                    </div>}


                    <div className="training_caract" style={{ "width": "105%" }}>
                        Détail <br />
                        <TextField
                            required
                            name="activite_detail"
                            //  label="Détail"
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style_no_border"
                            value={p_activite_detail}
                            onChange={(e) => setp_activite_detail(e.target.value)}

                        />
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(datamodification_activite) === "1" && <div className="div_row">
                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}

                    {String(formedit_mode_activite) === "1" && <div className="div_row">
                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={recordPartnerClient_Activite_Data}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_annule" onClick={retour_liste_Activite}>Annuler</Button>
                        </div>
                    </div>
                    }

                    {String(formedit_mode_activite) !== "1" && <div className="div_row">

                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={retour_liste_Activite}>Liste Activités &nbsp; <GrOrderedList /> </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_edit" onClick={DataEditMode_Activite_Data}> Modifier</Button>
                        </div>
                    </div>
                    }


                </div>}

                {String(add_one_activite) === "1" && <div className="div_row" style={{ "marginLeft": "5px", "marginRight": "5px" }} >
                    <div className="training_caract">  Description <br />
                        <TextField
                            required
                            name="activite_description"
                            //label="Description"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={one_activite_description}
                            onChange={(e) => setone_activite_description(e.target.value)}

                        />
                    </div>



                    <div className="training_caract">
                        Date souhaitée  <br />
                        <DatePicker
                            name="activite_deadline"
                            id="activite_deadline"
                            selected={new Date(moment(one_activite_deadline, "DD/MM/YYYY HH:mm:ss"))}

                            onChange={(date) => {
                                setone_activite_deadline(format(date, 'dd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            filterTime={filterPassedTime_start}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'

                        />

                    </div>


                    <div className="training_caract">  Type d'activité <br />

                        <Autocomplete
                            disablePortal
                            name="activite_type"
                            id="activite_type"
                            className="disabled_style"

                            options={type_activite_list}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setone_activite_type(value.value);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                        {/*<TextField
                            required
                            select
                            name="activite_type"
                            //label="Type d'activité"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={one_activite_type}
                            onChange={(e) => setone_activite_type(e.target.value)} >
                            {type_activite_list.map((option) => (
                                <MenuItem key={option.value} value={option.value} style={{ "width": "100%", "paddingLeft": "5px" }} >
                                    {option.label} &nbsp; <br />
                                </MenuItem>
                            ))}
                        </TextField>*/}

                    </div>

                    <div className="training_caract">  Statut <br />

                        <Autocomplete
                            disablePortal
                            name="activite_status"
                            id="activite_status"
                            className="disabled_style"

                            options={activite_status_list}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setone_activite_status(value.value);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    <div className="training_caract">   Assigné à (employé) <br />

                        <Autocomplete
                            disablePortal
                            id="activite_assigned_to"
                            name="activite_assigned_to"
                            className="disabled_style"
                            options={New_Getall_Training_Employee_No_Filter_result}
                            //value={p_formateur_label}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_activite_employee_assigned_id(value._id);

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                        {/*<TextField

                            id="activite_assigned_to"
                            name="activite_assigned_to"

                            select

                            className="disabled_style"

                            value={p_activite_employee_assigned_id}
                            onChange={(e) => {
                                setp_activite_employee_assigned_id(e.target.value);

                            }
                            }
                        >
                            {Getall_Training_Employee_No_Filter_result &&
                                Getall_Training_Employee_No_Filter_result.map((myclient) => (
                                    <MenuItem key={JSON.parse(myclient)._id} value={JSON.parse(myclient)._id}
                                        style={{ "paddingLeft": "5px", "width": "100%" }}>
                                        {JSON.parse(myclient).nom}&nbsp; {JSON.parse(myclient).prenom} <br />

                                    </MenuItem>
                                ))}
                        </TextField>*/}
                    </div>

                    <div className="training_caract" style={{ "width": "105%" }}>  Détail <br />
                        <TextField
                            required
                            name="activite_detail"
                            //label="Détail"
                            multiline
                            rows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style_no_border"
                            value={one_activite_detail}
                            onChange={(e) => setone_activite_detail(e.target.value)}

                        />
                    </div>




                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>


                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button variant="contained" color="success" className="bton_enreg " onClick={record_One_PartnerClient_Activite_Data}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="bton_annule" onClick={Close_add_one_activite}>Annuler</Button>
                        </div>
                    </div>

                </div>}

                <div className="div_row" style={{ "border": "None" }}>

                    <Button variant="outlined" onClick={Add_Activite} style={{ "width": '20rem' }}
                        className="detail_class_submenu bton_add_session"
                        id='menu_import_participant' name='menu_import_participant'>Ajout 1 Activité &nbsp;
                        <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                    </Button>


                </div>
                <div className="div_row"> &nbsp;</div>
            </div>

        </div >
    )
}

export default PartnerClientActivite;