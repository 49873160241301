import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import ToggleSwitch from "./ToggleSwitch";
import ToggleSwitch_v2_mysy from "./ToggleSwitch_v2_mysy";

const Materiel_Salle = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_affectations, setrows_affectations] = useState([]);
    const [selectionModel_affectations, setSelectionModel_affectations] = React.useState([]);


    const [rows_ressources_associes, setrows_ressources_associes] = useState([]);
    const [selectionModel_ressources_associes, setselectionModel_ressources_associes] = React.useState([]);


    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const [gridline_id, setgridline_id] = useState("");
    const [gridline_affectation_id, setgridline_affectation_id] = useState("");

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'ref_interne', headerName: 'Ref. Interne', flex: 1, },
        { field: 'nom', headerName: 'Nom', flex: 1, hide: false, },

        { field: 'type', headerName: 'Type', width: 0, hide: true, },
        { field: 'code_site', headerName: 'Site', width: 150, flex: 1, hide: false, },
        {
            field: 'acces_handicape', headerName: 'Handicape', width: 150, hide: false,
            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {String(cellValues.row.acces_handicape) === "1" && "Oui"}&nbsp;
                        {String(cellValues.row.acces_handicape) !== "1" && "Non"}&nbsp;

                    </div>

                );
            },
        },
        { field: 'capacite_ideale', headerName: 'Place Idéal', width: 100, hide: true, },
        { field: 'capacite_max', headerName: 'Place Max', width: 100, hide: false, },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la salle sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]

    const columns_materielle_affectation = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'poste', headerName: 'Poste', flex: 1, },
        { field: 'date_du', headerName: 'Du', width: 150, hide: false, },
        { field: 'date_au', headerName: 'Au', width: 150, hide: false, },
        { field: 'related_target_collection_object', headerName: 'Cible', flex: 1, hide: false, },
        { field: 'related_target_collection_id_nom', headerName: 'Cible Nom', flex: 1, hide: false, },
        { field: 'target_related_collection', headerName: 'Type', hide: true },
        { field: 'target_related_collection_id', headerName: 'Type ID', hide: true },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                //handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la fonction sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_affection(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_ressources_associes = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'poste', headerName: 'Poste', flex: 1, },
        { field: 'date_du', headerName: 'Du', width: 150, hide: false, },
        { field: 'date_au', headerName: 'Au', width: 150, hide: false, },
        { field: 'related_target_collection_object', headerName: 'Cible', flex: 1, hide: false, },
        { field: 'related_target_collection_id_nom', headerName: 'Cible Nom', flex: 1, hide: false, },
        { field: 'target_related_collection', headerName: 'Type', hide: true },
        { field: 'target_related_collection_id', headerName: 'Type ID', hide: true },
        {/*
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                //handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la fonction sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_affection(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        */}


    ]

    const myRef_head = useRef(null);

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [New_Getall_Partner_Site_Formation_result, setNew_Getall_Partner_Site_Formation_result] = useState([]);

    const [Getall_Partner_Site_Formation_api, setGetall_Partner_Site_Formation_api] = useState();
    const [Getall_Partner_Site_Formation_message, setGetall_Partner_Site_Formation_message] = useState();
    const [Getall_Partner_Site_Formation_result, setGetall_Partner_Site_Formation_result] = useState([]);
    function Getall_Partner_Site_Formation(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Site_Formation_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Site_Formation  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Site_Formation  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Site_Formation_api("true");
                setGetall_Partner_Site_Formation_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code_site = JSON.parse(x).code_site;
                    var local_nom_site = JSON.parse(x).nom_site;
                    var local_adr_site = JSON.parse(x).site_adr;
                    var local_cp_site = JSON.parse(x).site_cp;
                    var local_ville_site = JSON.parse(x).site_ville;
                    var local_pays_site = JSON.parse(x).site_pays;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_site,
                        "code_site": local_code_site,
                        "nom_site": local_nom_site,
                        "adr_site": local_adr_site,
                        "cp_site": local_cp_site,
                        "ville_site": local_ville_site,
                        "pays_site": local_pays_site,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code_site": "",
                    "nom_site": "",
                    "adr_site": "",
                    "cp_site": "",
                    "ville_site": "",
                    "pays_site": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Site_Formation_result(new_data2);


            }
            else {
                setGetall_Partner_Site_Formation_api("false");
                setGetall_Partner_Site_Formation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Site_Formation = ', error);
            setGetall_Partner_Site_Formation_api("false");
            alert(" Impossible de recuperer la liste des sites de formation");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Getall_Training_Materiel_Salle();
        Get_Materiel_Related_Target_Collection_Data();

        Getall_Type_Materiel();
        Getall_Categorie_Materiel();
        Getall_Partner_Site_Formation();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    const myRef = useRef(null)

    const [session_file_name, setsession_file_name] = useState();
    const [display_affectation, setdisplay_affectation] = useState();

    const [Add_new_affectation, setAdd_new_affectation] = useState();
    const [display_detail_materiel, setdisplay_detail_materiel] = React.useState();

    const hiddenFileInput_session = React.useRef(null);
    const [materiel_data_changed, setmateriel_data_changed] = useState();

    const [add_One_Materiel, setadd_One_Materiel] = useState();

    function submenu_add_one_materiel() {
        Getall_Type_Materiel();
        Getall_Partner_Site_Formation();
        Getall_Categorie_Materiel();
        clean_detail_fields();
        setdisplay_detail_materiel();
        setadd_One_Materiel("1");
        setsubmenu("detail_materiel");

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }

    function Annule_add_one_materiel() {
        setdisplay_detail_materiel();
        setadd_One_Materiel();
        setsubmenu("");
        setSelectionModel([]);
        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }


    function clean_detail_fields() {
        setp_one_detail_ref_interne("");
        setp_one_detail_nom("");
        setp_one_detail_description("");
        setp_one_detail_detail("");
        setp_one_detail_famille("");
        setp_one_detail_marque("");

        setp_one_detail_prix_achat("");
        setp_one_detail_prix_achat_by("");
        setp_one_site_formation_id("");
        setp_one_detail_fournisseur("");
        setp_one_detail_qty_stock("");

        setp_one_detail_complement_adresse("");
        setp_one_detail_capacite_ideale("");
        setp_one_detail_capacite_max("");
        setp_one_detail_acces_handicape(false);


        setgridline_id("");
        setgridline_affectation_id("");

    }

    function clear_salle_fields() {
        setp_detail_ref_interne("");
        setp_detail_nom("");
        setp_detail_description("");
        setp_detail_detail("");
        setp_detail_famille("");
        setp_detail_marque("");
        setp_one_site_formation_id("");
        setp_detail_prix_achat("");
        setp_detail_prix_achat_by("");
        setp_detail_fournisseur("");
        setp_detail_qty_stock("");

        setp_detail_complement_adresse("");
        setp_detail_capacite_ideale("");
        setp_detail_capacite_max("");
        setp_detail_acces_handicape(false);
        setgridline_id("");
        setgridline_affectation_id("");
    }

    function clean_affectation_fields() {
        setp_affect_poste();
        setp_affect_date_du(new Date().toLocaleDateString('fr-FR'));
        setp_affect_date_au(new Date().toLocaleDateString('fr-FR'));
        setp_affect_cible();
        setp_affect_cible_label();
        setp_affect_cible_nom();
        setp_affect_cible_nom_label();
        setp_affect_comment();

        if (document.getElementsByName("affect_poste")[0])
            document.getElementsByName("affect_poste")[0].value = "";

        if (document.getElementsByName("affect_cible")[0])
            document.getElementsByName("affect_cible")[0].value = "";

        if (document.getElementsByName("affect_cible_nom")[0])
            document.getElementsByName("affect_cible_nom")[0].value = "";

        if (document.getElementsByName("affect_date_du")[0])
            document.getElementsByName("affect_date_du")[0].value = "";

        if (document.getElementsByName("affect_date_au")[0])
            document.getElementsByName("affect_date_au")[0].value = "";

        if (document.getElementsByName("affect_comment")[0])
            document.getElementsByName("affect_comment")[0].value = "";


    }



    function clean_nouvelle_affectation_fields() {
        setp_one_affect_poste();
        setp_one_affect_date_du(new Date().toLocaleDateString('fr-FR'));
        setp_one_affect_date_au(date_today_90j.toLocaleDateString('fr-FR'));
        setp_one_affect_cible();
        setp_one_affect_cible_nom();

        setp_one_affect_comment();

        if (document.getElementsByName("one_affect_poste")[0])
            document.getElementsByName("one_affect_poste")[0].value = "";

        if (document.getElementsByName("one_affect_cible")[0])
            document.getElementsByName("one_affect_cible")[0].value = "";

        if (document.getElementsByName("one_affect_cible_nom")[0])
            document.getElementsByName("one_affect_cible_nom")[0].value = "";

        if (document.getElementsByName("one_affect_date_du")[0])
            document.getElementsByName("one_affect_date_du")[0].value = "";

        if (document.getElementsByName("one_affect_date_au")[0])
            document.getElementsByName("one_affect_date_au")[0].value = "";

        if (document.getElementsByName("one_affect_comment")[0])
            document.getElementsByName("one_affect_comment")[0].value = "";
    }


    const [Getall_Training_Materiel_api, setGetall_Training_Materiel_api] = useState();
    const [Getall_Training_Materiel_message, setGetall_Training_Materiel_message] = useState();
    const [Getall_Training_Materiel_result, setGetall_Training_Materiel_result] = useState();
    function Getall_Training_Materiel(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code_categorie", "salle");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_with_filter/";


        setdisplay_affectation("");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
        setSelectionModel_affectations([]);
        setSelectionModel([]);
        setsubmenu("");
        setSelectionModel([]);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_api("true");
                setGetall_Training_Materiel_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Training_Materiel_api("false");
                setGetall_Training_Materiel_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Materiel = ', error);
            setGetall_Training_Materiel_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Add_Ressource_Humaine_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");

                    Getall_Training_Materiel_Salle();
                    alert(" Le materiel ont été correctement importé");

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer le materiel ");
            });
    }



    function submenu_import_materiel() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }


    const [isLoading, setLoading] = useState();

    const [Add_One_Materiel_Data_api, setAdd_One_Materiel_Data_api] = useState();
    const [Add_One_Materiel_Data_message, setAdd_One_Materiel_Data_message] = useState();
    const [Add_One_Materiel_Data_result, setAdd_One_Materiel_Data_result] = useState();
    function Add_One_Materiel_Data() {
        var form = new FormData();


        if (p_one_detail_ref_interne.trim() == "") {
            alert(" Vous devez saisir la référence interne du materiel");
            return;
        }


        if (p_one_detail_nom.trim() == "") {
            alert(" Vous devez saisir le nom du materiel");
            return;
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ref_interne", p_one_detail_ref_interne);
        form.append("nom", p_one_detail_nom);

        form.append("code_categorie", "salle");



        if (p_one_detail_description)
            form.append("description", p_one_detail_description);
        else
            form.append("description", "");


        if (p_one_detail_detail)
            form.append("detail", p_one_detail_detail);
        else
            form.append("detail", "");

        if (p_one_detail_famille)
            form.append("famille", p_one_detail_famille);
        else
            form.append("famille", "");


        if (p_one_detail_marque)
            form.append("marque", p_one_detail_marque);
        else
            form.append("marque", "");

        if (p_one_detail_type)
            form.append("type", p_one_detail_type);
        else
            form.append("type", "");

        if (p_one_detail_prix_achat)
            form.append("prix_achat", p_one_detail_prix_achat);
        else
            form.append("prix_achat", "");

        if (p_one_site_formation_id)
            form.append("site_formation_id", p_one_site_formation_id);
        else
            form.append("site_formation_id", "");

        if (p_one_detail_fournisseur)
            form.append("fournisseur", p_one_detail_fournisseur);
        else
            form.append("fournisseur", "");

        if (p_one_detail_qty_stock)
            form.append("qty_stock", p_one_detail_qty_stock);
        else
            form.append("qty_stock", "0");

        if (p_detail_complement_adresse)
            form.append("complement_adresse", p_detail_complement_adresse);
        else
            form.append("complement_adresse", "");


        var local_capacite_ideale = 0;
        if (p_one_detail_capacite_ideale) {
            form.append("capacite_ideale", p_one_detail_capacite_ideale);
            local_capacite_ideale = parseInt(String(p_one_detail_capacite_ideale))
        }
        else
            form.append("capacite_ideale", "");


        var local_capacite_max = 0;
        if (p_one_detail_capacite_max) {
            form.append("capacite_max", p_one_detail_capacite_max);
            local_capacite_max = parseInt(String(p_one_detail_capacite_max))
        }
        else
            form.append("capacite_max", "");

        if (local_capacite_max < local_capacite_ideale) {
            alert(" La capacité maximale doit être supérieure ou égale à à la capacité idéale");
            return;
        }

        if (p_one_detail_prix_achat_by)
            form.append("prix_achat_by", p_one_detail_prix_achat_by);
        else
            form.append("prix_achat_by", "");


        if (String(p_one_detail_acces_handicape) === "true")
            form.append("acces_handicape", "1");
        else
            form.append("acces_handicape", "0");


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Ressource_Materielle/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_One_Materiel_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Materiel_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_One_Materiel_Data_api("true");
                setAdd_One_Materiel_Data_result(res.data.message);

                Getall_Training_Materiel_Salle();
                Get_Materiel_Related_Target_Collection_Data();

                setmateriel_data_changed("");
                setmateriel_data_edit_mode("");
                clean_detail_fields();
                setadd_One_Materiel("");

                alert(res.data.message);

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_One_Materiel_Data_api("false");
                setAdd_One_Materiel_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Materiel_Data = ' + error);
            setAdd_One_Materiel_Data_api("false");
            alert(" Impossible d'ajouter le materiel");

        })
    }


    const [Getall_Training_Materiel_Salle_api, setGetall_Training_Materiel_Salle_api] = useState();
    const [Getall_Training_Materiel_Salle_message, setGetall_Training_Materiel_Salle_message] = useState();
    const [Getall_Training_Materiel_Salle_result, setGetall_Training_Materiel_Salle_result] = useState();
    function Getall_Training_Materiel_Salle(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code_categorie", "salle");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_with_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_Salle  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_Salle  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_Salle_api("true");
                setGetall_Training_Materiel_Salle_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Training_Materiel_Salle_api("false");
                setGetall_Training_Materiel_Salle_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Materiel_Salle = ', error);
            setGetall_Training_Materiel_Salle_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Type_Materiel_result, setNew_Getall_Type_Materiel_result] = useState();

    const [Getall_Type_Materiel_api, setGetall_Type_Materiel_api] = useState();
    const [Getall_Type_Materiel_message, setGetall_Type_Materiel_message] = useState();
    const [Getall_Type_Materiel_result, setGetall_Type_Materiel_result] = useState();
    function Getall_Type_Materiel(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Type_Ressource_Materielle/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Type_Materiel  res.data.status = " + res.data.status);
                //console.log(" In Getall_Type_Materiel  res.data.message r_class = " + res.data.message);
                setGetall_Type_Materiel_api("true");
                setGetall_Type_Materiel_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_code = JSON.parse(x).code;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "nom": local_nom,
                        "code": local_code,

                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Type_Materiel_result(new_data2);

            }
            else {
                setGetall_Type_Materiel_api("false");
                setGetall_Type_Materiel_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Type_Materiel = ', error);
            setGetall_Type_Materiel_api("false");
            alert(" Impossible de recuperer les types de materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Categorie_Materiel_result, setNew_Getall_Categorie_Materiel_result] = useState();

    const [Getall_Categorie_Materiel_api, setGetall_Categorie_Materiel_api] = useState();
    const [Getall_Categorie_Materiel_message, setGetall_Categorie_Materiel_message] = useState();
    const [Getall_Categorie_Materiel_result, setGetall_Categorie_Materiel_result] = useState();
    function Getall_Categorie_Materiel(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Categorie_Ressource_Materielle/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Categorie_Materiel  res.data.status = " + res.data.status);
                //console.log(" In Getall_Categorie_Materiel  res.data.message r_class = " + res.data.message);
                setGetall_Categorie_Materiel_api("true");
                setGetall_Categorie_Materiel_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_code = JSON.parse(x).code;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,

                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Categorie_Materiel_result(new_data2);

            }
            else {
                setGetall_Categorie_Materiel_api("false");
                setGetall_Categorie_Materiel_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Categorie_Materiel = ', error);
            setGetall_Categorie_Materiel_api("false");
            alert(" Impossible de recuperer les catégoeirs  de materiel");
            //setmyApimyApiMessage("")
        })
    }


    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async function submenu_detail_materiel() {
        setsubmenu("detail_materiel");

        //await sleep(5);
        /*if (!edit_session_form) {
            desableSessionFields();
        }*/

        if (document.getElementById("detail_materiel")) {
            document.getElementById("detail_materiel").style.backgroundColor = "#104277";
            document.getElementById("detail_materiel").style.color = "white";
        }


        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#d8edfc";
            document.getElementById("affectation").style.color = "black";
        }

        if (document.getElementById("ressource_associe")) {
            document.getElementById("ressource_associe").style.backgroundColor = "#d8edfc";
            document.getElementById("ressource_associe").style.color = "black";
        }

        //console.log(" ### employee_data_edit_mode = ", employee_data_edit_mode);
        if (String(materiel_data_edit_mode) !== "1")
            disable_Materiel_DetailFields();


        setSelectionModel_affectations([]);
        setdisplay_affectation("0");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();

    }


    async function submenu_affectation() {
        setsubmenu("affectation");

        //await sleep(5);
        /*if (!edit_session_form) {
            desableSessionFields();
        }*/

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#104277";
            document.getElementById("affectation").style.color = "white";
        }

        if (document.getElementById("detail_materiel")) {
            document.getElementById("detail_materiel").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_materiel").style.color = "black";
        }

        if (document.getElementById("ressource_associe")) {
            document.getElementById("ressource_associe").style.backgroundColor = "#d8edfc";
            document.getElementById("ressource_associe").style.color = "black";
        }
    }

    async function submenu_ressource_associe() {
        setsubmenu("ressource_associe");


        if (document.getElementById("ressource_associe")) {
            document.getElementById("ressource_associe").style.backgroundColor = "#104277";
            document.getElementById("ressource_associe").style.color = "white";
        }

        if (document.getElementById("affectation")) {
            document.getElementById("affectation").style.backgroundColor = "#d8edfc";
            document.getElementById("affectation").style.color = "black";
        }

        if (document.getElementById("detail_materiel")) {
            document.getElementById("detail_materiel").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_materiel").style.color = "black";
        }
    }





    function Display_Detail_Material_Data(materiel_id) {
        //clearDetailAttendeFields();
        setadd_One_Materiel();
        setdisplay_detail_materiel("1");

        Get_Materiel_Data(materiel_id);
        get_Materiel_Images(materiel_id);
    }

    const [materiel_data_edit_mode, setmateriel_data_edit_mode] = useState("");

    function disable_Materiel_DetailFields() {
        setmateriel_data_edit_mode("0");

        if (document.getElementsByName("detail_ref_interne")[0]) {
            document.getElementsByName("detail_ref_interne")[0].disabled = true;
            document.getElementsByName("detail_ref_interne")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_nom")[0]) {
            document.getElementsByName("detail_nom")[0].disabled = true;
            document.getElementsByName("detail_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_description")[0]) {
            document.getElementsByName("detail_description")[0].disabled = true;
            document.getElementsByName("detail_description")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_detail")[0]) {
            document.getElementsByName("detail_detail")[0].disabled = true;
            document.getElementsByName("detail_detail")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_famille")[0]) {
            document.getElementsByName("detail_famille")[0].disabled = true;
            document.getElementsByName("detail_famille")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_marque")[0]) {
            document.getElementsByName("detail_marque")[0].disabled = true;
            document.getElementsByName("detail_marque")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_ville")[0]) {
            document.getElementsByName("detail_ville")[0].disabled = true;
            document.getElementsByName("detail_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_civilite")[0]) {
            document.getElementsByName("detail_civilite")[0].disabled = true;
            document.getElementsByName("detail_civilite")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_type")[0]) {
            document.getElementsByName("detail_type")[0].disabled = true;
            document.getElementsByName("detail_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_prix_achat")[0]) {
            document.getElementsByName("detail_prix_achat")[0].disabled = true;
            document.getElementsByName("detail_prix_achat")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_prix_achat_by")[0]) {
            document.getElementsByName("detail_prix_achat_by")[0].disabled = true;
            document.getElementsByName("detail_prix_achat_by")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_site_formation")[0]) {
            document.getElementsByName("detail_site_formation")[0].disabled = true;
            document.getElementsByName("detail_site_formation")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("detail_fournisseur")[0]) {
            document.getElementsByName("detail_fournisseur")[0].disabled = true;
            document.getElementsByName("detail_fournisseur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_qty_stock")[0]) {
            document.getElementsByName("detail_qty_stock")[0].disabled = true;
            document.getElementsByName("detail_qty_stock")[0].style.backgroundColor = "#ECEFF1";
        }




        if (document.getElementsByName("detail_capcite_max")[0]) {
            document.getElementsByName("detail_capcite_max")[0].disabled = true;
            document.getElementsByName("detail_capcite_max")[0].style.backgroundColor = "#ECEFF1";
        }
        if (document.getElementsByName("detail_capcite_ideale")[0]) {
            document.getElementsByName("detail_capcite_ideale")[0].disabled = true;
            document.getElementsByName("detail_capcite_ideale")[0].style.backgroundColor = "#ECEFF1";
        }
        if (document.getElementsByName("detail_complement_adr")[0]) {
            document.getElementsByName("detail_complement_adr")[0].disabled = true;
            document.getElementsByName("detail_complement_adr")[0].style.backgroundColor = "#ECEFF1";
        }


    }

    function Enable_Materiel_DetailFields() {
        setmateriel_data_edit_mode("1");

        if (document.getElementsByName("detail_ref_interne")[0]) {
            document.getElementsByName("detail_ref_interne")[0].disabled = false;
            document.getElementsByName("detail_ref_interne")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_nom")[0]) {
            document.getElementsByName("detail_nom")[0].disabled = false;
            document.getElementsByName("detail_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_description")[0]) {
            document.getElementsByName("detail_description")[0].disabled = false;
            document.getElementsByName("detail_description")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_detail")[0]) {
            document.getElementsByName("detail_detail")[0].disabled = false;
            document.getElementsByName("detail_detail")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_famille")[0]) {
            document.getElementsByName("detail_famille")[0].disabled = false;
            document.getElementsByName("detail_famille")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_marque")[0]) {
            document.getElementsByName("detail_marque")[0].disabled = false;
            document.getElementsByName("detail_marque")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_type")[0]) {
            document.getElementsByName("detail_type")[0].disabled = false;
            document.getElementsByName("detail_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_prix_achat")[0]) {
            document.getElementsByName("detail_prix_achat")[0].disabled = false;
            document.getElementsByName("detail_prix_achat")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_prix_achat_by")[0]) {
            document.getElementsByName("detail_prix_achat_by")[0].disabled = false;
            document.getElementsByName("detail_prix_achat_by")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_site_formation")[0]) {
            document.getElementsByName("detail_site_formation")[0].disabled = false;
            document.getElementsByName("detail_site_formation")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_fournisseur")[0]) {
            document.getElementsByName("detail_fournisseur")[0].disabled = false;
            document.getElementsByName("detail_fournisseur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_qty_stock")[0]) {
            document.getElementsByName("detail_qty_stock")[0].disabled = false;
            document.getElementsByName("detail_qty_stock")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("toggleSwitch_acces_hand")[0]) {
            document.getElementsByName("toggleSwitch_acces_hand")[0].disabled = false;
            document.getElementsByName("toggleSwitch_acces_hand")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_capcite_max")[0]) {
            document.getElementsByName("detail_capcite_max")[0].disabled = false;
            document.getElementsByName("detail_capcite_max")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_capcite_ideale")[0]) {
            document.getElementsByName("detail_capcite_ideale")[0].disabled = false;
            document.getElementsByName("detail_capcite_ideale")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_complement_adr")[0]) {
            document.getElementsByName("detail_complement_adr")[0].disabled = false;
            document.getElementsByName("detail_complement_adr")[0].style.backgroundColor = "#FFFFFF";
        }

    }


    function Annule_Materiel_DetailFields() {

        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        Get_Materiel_Data(selected_id);
        get_Materiel_Images(selected_id);

        setmateriel_data_changed("");
        setmateriel_data_edit_mode("");

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    const [Get_Materiel_Data_api, setGet_Materiel_Data_api] = useState();
    const [Get_Materiel_Data_message, setGet_Materiel_Data_message] = useState();
    const [Get_Materiel_Data_result, setGet_Materiel_Data_result] = useState();
    function Get_Materiel_Data(materiel_id) {
        setmateriel_data_changed("");
        setmateriel_data_edit_mode("");
        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", materiel_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Ressource_Materielle/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Materiel_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Materiel_Data  res.data.message r_class = " + res.data.message);
                setGet_Materiel_Data_api("true");
                setGet_Materiel_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_materiel = JSON.parse(res.data.message);


                    if (mylocal_materiel.ref_interne)
                        setp_detail_ref_interne(mylocal_materiel.ref_interne);


                    if (mylocal_materiel.nom)
                        setp_detail_nom(mylocal_materiel.nom);


                    if (mylocal_materiel.description)
                        setp_detail_description(mylocal_materiel.description);

                    if (mylocal_materiel.detail)
                        setp_detail_detail(mylocal_materiel.detail);


                    if (mylocal_materiel.famille)
                        setp_detail_famille(mylocal_materiel.famille);


                    if (mylocal_materiel.marque)
                        setp_detail_marque(mylocal_materiel.marque);


                    if (mylocal_materiel.type)
                        setp_detail_type(mylocal_materiel.type);
                    else
                        setp_detail_type("");

                    if (mylocal_materiel.prix_achat)
                        setp_detail_prix_achat(mylocal_materiel.prix_achat);


                    if (mylocal_materiel.prix_achat_by)
                        setp_detail_prix_achat_by(mylocal_materiel.prix_achat_by);
                    else
                        setp_detail_prix_achat_by("");

                    if (mylocal_materiel.site_formation_id)
                        setp_detail_site_formation(mylocal_materiel.site_formation_id);
                    else
                        setp_detail_site_formation("");



                    if (mylocal_materiel.fournisseur)
                        setp_detail_fournisseur(mylocal_materiel.fournisseur);


                    if (mylocal_materiel.complement_adresse)
                        setp_detail_complement_adresse(mylocal_materiel.complement_adresse);

                    if (mylocal_materiel.capacite_ideale)
                        setp_detail_capacite_ideale(mylocal_materiel.capacite_ideale);

                    if (mylocal_materiel.capacite_max)
                        setp_detail_capacite_max(mylocal_materiel.capacite_max);

                    if (mylocal_materiel.acces_handicape) {
                        if (String(mylocal_materiel.acces_handicape) === "1")
                            setp_detail_acces_handicape(true);
                        else
                            setp_detail_acces_handicape(false);
                    }



                    disable_Materiel_DetailFields();
                } else {
                    alert(" Aucun materiel recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Materiel_Data_api("false");
                setGet_Materiel_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Materiel_Data = ', error);
            setGet_Materiel_Data_api("false");
            alert(" Impossible de recuperer les données du materiel");
            //setmyApimyApiMessage("")
        })
    }

    const [selected_id, setselected_id] = useState("");
    const [selected_employe_email, setselected_employe_email] = useState("");
    function handleClick_edit_materiel_From_Line(selected_row_id) {

        submenu_detail_materiel();

        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var materiel_id = line._id;
        setselected_id(materiel_id);

        //console.log(" ### employe_id  = ", employe_id);
        Display_Detail_Material_Data(materiel_id);



        Getall_Materiel_Affectation(materiel_id);
        Getall_Materiel_Rattachement(materiel_id);
        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    const [Getall_Materiel_Affectation_api, setGetall_Materiel_Affectation_api] = useState();
    const [Getall_Materiel_Affectation_message, setGetall_Materiel_Affectation_message] = useState();
    const [Getall_Materiel_Affectation_result, setGetall_Materiel_Affectation_result] = useState();
    function Getall_Materiel_Affectation(local_materiel_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rm_id", local_materiel_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_Affectation/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Materiel_Affectation  res.data.status = " + res.data.status);
                //console.log(" In Getall_Materiel_Affectation  res.data.message r_class = " + res.data.message);
                setGetall_Materiel_Affectation_api("true");
                setGetall_Materiel_Affectation_result(res.data.message);
                setrows_affectations(res.data.message);
            }
            else {
                setGetall_Materiel_Affectation_api("false");
                setGetall_Materiel_Affectation_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Materiel_Affectation = ', error);
            setGetall_Materiel_Affectation_api("false");
            alert(" Impossible de recuperer les affectations du materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Materiel_Rattachement_api, setGetall_Materiel_Rattachement_api] = useState();
    const [Getall_Materiel_Rattachement_message, setGetall_Materiel_Rattachement_message] = useState();
    const [Getall_Materiel_Rattachement_result, setGetall_Materiel_Rattachement_result] = useState();
    function Getall_Materiel_Rattachement(local_materiel_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rm_id", local_materiel_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_Rattachement/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Materiel_Rattachement  res.data.status = " + res.data.status);
                //console.log(" In Getall_Materiel_Rattachement  res.data.message r_class = " + res.data.message);
                setGetall_Materiel_Rattachement_api("true");
                setGetall_Materiel_Rattachement_result(res.data.message);

            }
            else {
                setGetall_Materiel_Rattachement_api("false");
                setGetall_Materiel_Rattachement_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Materiel_Rattachement = ', error);
            setGetall_Materiel_Rattachement_api("false");
            alert(" Impossible de recuperer la liste des ressources rattachées à ce materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/DeleteImage_Ressource_Materielle/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {

                console.log(' removeRecodedClassImage : status:', result['status']);
                console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");
                    get_Materiel_Images();

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }


    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };

    const [selected_affectation_id, set_selected_affectation_id] = useState();

    const [New_Get_Materiel_Related_Target_Collection_Data_result, setNew_Get_Materiel_Related_Target_Collection_Data_result] = useState();

    const [Get_Materiel_Related_Target_Collection_Data_api, setGet_Materiel_Related_Target_Collection_Data_api] = useState();
    const [Get_Materiel_Related_Target_Collection_Data_message, setGet_Materiel_Related_Target_Collection_Data_message] = useState();
    const [Get_Materiel_Related_Target_Collection_Data_result, setGet_Materiel_Related_Target_Collection_Data_result] = useState();
    function Get_Materiel_Related_Target_Collection_Data() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Related_Target_Materiel_Collection_Data/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In Get_Materiel_Related_Target_Collection_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Materiel_Related_Target_Collection_Data  res.data.message r_class = " + res.data.message);
                setGet_Materiel_Related_Target_Collection_Data_api("true");
                setGet_Materiel_Related_Target_Collection_Data_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---

                    var local_related_target_collection = JSON.parse(x).related_target_collection;
                    var local_related_target_collection_id = JSON.parse(x).related_target_collection_id;
                    var local_related_target_collection_id_nom = JSON.parse(x).related_target_collection_id_nom;


                    //---
                    var node = {
                        "related_target_collection": local_related_target_collection,
                        "related_target_collection_id": local_related_target_collection_id,
                        "related_target_collection_id_nom": local_related_target_collection_id_nom,
                        "label": local_related_target_collection_id_nom,

                    };
                    //console.log(" --- node = ", node);
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_Materiel_Related_Target_Collection_Data_result(new_data2);

            }
            else {
                setGet_Materiel_Related_Target_Collection_Data_api("false");
                setGet_Materiel_Related_Target_Collection_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_Materiel_Related_Target_Collection_Data = ', error);
            setGet_Materiel_Related_Target_Collection_Data_api("false");
            alert(" Impossible de recuperer les cibles d'affectation");
            //setmyApimyApiMessage("")
        })
    }

    const [record_materiel_Image_api, setrecord_materiel_Image_api] = useState();
    const [record_materiel_Image_message, setrecord_materiel_Image_message] = useState();
    const [record_materiel_Image_result, setrecord_materiel_Image_result] = useState();
    function record_materiel_Image(l) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Update_Ressource_Materielle_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("rm_id", selected_id);

        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);


        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_materiel_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_materiel_Image_api("false");
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setrecord_materiel_Image_api("false");
                alert(" Erreur : Impossible d'enregistrer l'image ");

            });


    };

    const [Update_One_Materiel_Data_api, setUpdate_One_Materiel_Data_api] = useState();
    const [Update_One_Materiel_Data_message, setUpdate_One_Materiel_Data_message] = useState();
    const [Update_One_Materiel_Data_result, setUpdate_One_Materiel_Data_result] = useState();
    function Update_One_Materiel_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ref_interne", p_detail_ref_interne);

        if (p_detail_nom)
            form.append("nom", p_detail_nom);
        else
            form.append("nom", "");


        if (p_detail_description)
            form.append("description", p_detail_description);
        else
            form.append("description", "");


        if (p_detail_detail)
            form.append("detail", p_detail_detail);
        else
            form.append("detail", "");


        if (p_detail_famille)
            form.append("famille", p_detail_famille);
        else
            form.append("famille", "");

        if (p_detail_marque)
            form.append("marque", p_detail_marque);
        else
            form.append("marque", "");


        if (p_detail_type)
            form.append("type", p_detail_type);
        else
            form.append("type", "");

        if (p_detail_prix_achat)
            form.append("prix_achat", p_detail_prix_achat);
        else
            form.append("prix_achat", "");

        if (p_detail_fournisseur)
            form.append("fournisseur", p_detail_fournisseur);
        else
            form.append("fournisseur", "");

        if (p_detail_site_formation)
            form.append("site_formation_id", p_detail_site_formation);
        else
            form.append("site_formation_id", "");



        if (p_detail_qty_stock)
            form.append("qty_stock", p_detail_qty_stock);
        else
            form.append("qty_stock", "0");



        if (p_detail_complement_adresse)
            form.append("complement_adresse", p_detail_complement_adresse);
        else
            form.append("complement_adresse", "");


        var local_capacite_ideale = 0;
        if (p_detail_capacite_ideale) {
            form.append("capacite_ideale", p_detail_capacite_ideale);
            local_capacite_ideale = parseInt(String(p_detail_capacite_ideale))
        }
        else
            form.append("capacite_ideale", "");


        var local_capacite_max = 0;
        if (p_detail_capacite_max) {
            form.append("capacite_max", p_detail_capacite_max);
            local_capacite_max = parseInt(String(p_detail_capacite_max))
        }
        else
            form.append("capacite_max", "");

        if (local_capacite_max < local_capacite_ideale) {
            alert(" La capacité maximale doit être supérieure ou égale à à la capacité idéale");
            return;
        }



        if (p_detail_prix_achat_by)
            form.append("prix_achat_by", p_detail_prix_achat_by);
        else
            form.append("prix_achat_by", "");


        if (String(p_detail_acces_handicape) === "true")
            form.append("acces_handicape", "1");
        else
            form.append("acces_handicape", "0");



        form.append("_id", selected_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Ressource_Materielle/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Materiel_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Materiel_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_Materiel_Data_api("true");
                setUpdate_One_Materiel_Data_result(res.data.message);


                Getall_Training_Materiel_Salle();

                disable_Materiel_DetailFields();
                setmateriel_data_changed("");
                setmateriel_data_edit_mode("");
                Getall_Training_Materiel(selected_id);


                setsubmenu("");
                setadd_One_Materiel("");
                setgridline_id("");
                //Get_Materiel_Data(selected_id);
                Display_Detail_Material_Data(selected_id);

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

                alert(" La mise à jour a été correctement faite.");

            }
            else {
                setUpdate_One_Materiel_Data_api("false");
                setUpdate_One_Materiel_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('UpdateStagiaireData : Not good man :( Update_One_Materiel_Data = ' + error);
            setUpdate_One_Materiel_Data_api("false");
            alert(" Impossible de mettre à jour le materiel");

        })
    }


    const [get_Materiel_Images_api, setget_Materiel_Images_api] = useState();
    const [get_Materiel_Images_message, setget_Materiel_Images_message] = useState();
    const [get_Materiel_Images_result, setget_Materiel_Images_result] = useState();
    function get_Materiel_Images(material_id) {

        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecoded_Materielle_Image_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rm_id", material_id);


        axios.post(myurl, form).then(res => {

            //console.log(" get_Materiel_Images : In test  res.data.status = " + res.data.status);
            // console.log(" get_Materiel_Images: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setget_Materiel_Images_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setget_Materiel_Images_api("false");
                get_Materiel_Images_message(res.data.message)
                alert(res.data.message);

            }
        }).catch((error) => {
            console.warn('get_Materiel_Images ee: Not good man :(  = ', error);
            setget_Materiel_Images_api("false");
            get_Materiel_Images_message(" Impossible de recuperer l'image du materiel")
        })

    }

    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);

    const [p_detail_marque, setp_detail_marque] = useState("");
    const [p_detail_type, setp_detail_type] = useState("");


    const [p_detail_ref_interne, setp_detail_ref_interne] = useState();
    const [p_detail_nom, setp_detail_nom] = useState();
    const [p_detail_description, setp_detail_description] = useState("");

    const [p_detail_detail, setp_detail_detail] = useState("");
    const [p_detail_famille, setp_detail_famille] = useState("");




    const [p_detail_complement_adresse, setp_detail_complement_adresse] = useState("");
    const [p_detail_capacite_ideale, setp_detail_capacite_ideale] = useState("");
    const [p_detail_capacite_max, setp_detail_capacite_max] = useState("");
    const [p_detail_acces_handicape, setp_detail_acces_handicape] = useState(false);

    const [p_detail_prix_achat, setp_detail_prix_achat] = useState("1");


    const [p_detail_prix_achat_by, setp_detail_prix_achat_by] = useState("");


    const [p_detail_fournisseur, setp_detail_fournisseur] = useState("");
    const [p_detail_qty_stock, setp_detail_qty_stock] = useState("1");
    const [p_detail_site_formation, setp_detail_site_formation] = useState("");


    // ------------


    const [p_one_detail_ref_interne, setp_one_detail_ref_interne] = useState();
    const [p_one_detail_nom, setp_one_detail_nom] = useState();
    const [p_one_detail_description, setp_one_detail_description] = useState();
    const [p_one_detail_detail, setp_one_detail_detail] = useState();
    const [p_one_detail_famille, setp_one_detail_famille] = useState();
    const [p_one_detail_marque, setp_one_detail_marque] = useState();
    const [p_one_detail_type, setp_one_detail_type] = useState();
    const [p_one_detail_prix_achat, setp_one_detail_prix_achat] = useState("1");
    const [p_one_detail_prix_achat_by, setp_one_detail_prix_achat_by] = useState("");

    const [p_one_detail_fournisseur, setp_one_detail_fournisseur] = useState();
    const [p_one_detail_qty_stock, setp_one_detail_qty_stock] = useState();
    const [p_one_detail_complement_adresse, setp_one_detail_complement_adresse] = useState("");
    const [p_one_detail_capacite_ideale, setp_one_detail_capacite_ideale] = useState("");
    const [p_one_detail_capacite_max, setp_one_detail_capacite_max] = useState("");
    const [p_one_detail_acces_handicape, setp_one_detail_acces_handicape] = useState(false);

    const [p_one_site_formation_id, setp_one_site_formation_id] = useState("");


    const [p_one_affect_poste, setp_one_affect_poste] = useState();
    const [p_one_affect_date_du, setp_one_affect_date_du] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_one_affect_date_au, setp_one_affect_date_au] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_one_affect_cible, setp_one_affect_cible] = useState();
    const [p_one_affect_cible_nom, setp_one_affect_cible_nom] = useState();
    const [p_one_affect_comment, setp_one_affect_comment] = useState();


    const [p_affect_poste, setp_affect_poste] = useState();
    const [p_affect_date_du, setp_affect_date_du] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_affect_date_au, setp_affect_date_au] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_affect_cible, setp_affect_cible] = useState();
    const [p_affect_cible_label, setp_affect_cible_label] = useState();

    const [p_affect_cible_nom, setp_affect_cible_nom] = useState();
    const [p_affect_cible_nom_label, setp_affect_cible_nom_label] = useState();


    const [edite_affectation_form, setedite_affectation_form] = useState();
    const [p_affect_comment, setp_affect_comment] = useState();
    const [p_related_target_collection, setp_related_target_collection] = useState();
    const [p_related_target_collection_id, setp_related_target_collection_id] = useState();


    function add_one_affectation_block() {

        setdisplay_affectation("");

        setAdd_new_affectation("1");

        if (document.getElementById('myRef_new_affectation')) {
            var divh = document.getElementById('myRef_new_affectation').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        clean_affectation_fields();

    }

    const [Update_One_Affectation_Data_api, setUpdate_One_Affectation_Data_api] = useState();
    const [Update_One_Affectation_Data_message, setUpdate_One_Affectation_Data_message] = useState();
    const [Update_One_Affectation_Data_result, setUpdate_One_Affectation_Data_result] = useState();
    function Update_One_Affectation_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_affectation_id);
        form.append("rm_id", selected_id);

        if (p_affect_poste)
            form.append("poste", p_affect_poste);
        else {
            alert(" Vous devez definir une poste pour cette affectation");
            return;
        }

        if (p_affect_date_du)
            form.append("date_du", p_affect_date_du);
        else {
            alert(" Vous devez definir une date de début pour cette affectation");
            return;
        }

        if (p_affect_date_au)
            form.append("date_au", p_affect_date_au);
        else
            form.append("date_au", "");


        if (p_affect_comment)
            form.append("comment", p_affect_comment);
        else
            form.append("comment", "");


        if (p_affect_cible)
            form.append("related_target_collection", p_affect_cible);
        else
            form.append("related_target_collection", "");

        if (p_affect_cible_nom)
            form.append("related_target_collection_id", p_affect_cible_nom);
        else
            form.append("related_target_collection_id", "");



        //console.log(" affectation form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Affectation_Ressource_Materielle_Poste/";

        axios.post(myurl, form).then(res => {
            console.log(" In Update_One_Affectation_Data  res.data.status = " + res.data.status);
            console.log(" In Update_One_Affectation_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_Affectation_Data_api("true");
                setUpdate_One_Affectation_Data_result(res.data.message);

                Disable_Detail_Affectation_Fields();
                setAffectation_data_changed("");
                setedite_affectation_form("");
                Getall_Materiel_Affectation(selected_id);
                Getall_Materiel_Rattachement(selected_id);
                setSelectionModel_affectations([]);
                setgridline_affectation_id('');

                alert(" La mise à jour a été correctement faite.");

                if (document.getElementById('myRef_new_affectation')) {
                    var divh = document.getElementById('myRef_new_affectation').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Affectation_Data_api("false");
                setUpdate_One_Affectation_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Update_One_Affectation_Data = ' + error);
            setUpdate_One_Affectation_Data_api("false");
            alert(" Impossible de mettre à jour l'affectation");

        })
    }

    const [Affectation_data_changed, setAffectation_data_changed] = useState();
    const myRef_affectation = useRef(null);
    const myRef_new_affectation = useRef(null);


    function Disable_Detail_Affectation_Fields() {
        setedite_affectation_form("0");

        if (document.getElementsByName("affect_poste")[0]) {
            document.getElementsByName("affect_poste")[0].disabled = true;
            document.getElementsByName("affect_poste")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_cible")[0]) {
            document.getElementsByName("affect_cible")[0].disabled = true;
            document.getElementsByName("affect_cible")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_cible_nom")[0]) {
            document.getElementsByName("affect_cible_nom")[0].disabled = true;
            document.getElementsByName("affect_cible_nom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_date_du")[0]) {
            document.getElementsByName("affect_date_du")[0].disabled = true;
            document.getElementsByName("affect_date_du")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("affect_date_au")[0]) {
            document.getElementsByName("affect_date_au")[0].disabled = true;
            document.getElementsByName("affect_date_au")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("affect_comment")[0]) {
            document.getElementsByName("affect_comment")[0].disabled = true;
            document.getElementsByName("affect_comment")[0].style.backgroundColor = "#ECEFF1";
        }

    }


    function Enable_Detail_Affectation_Fields() {

        setedite_affectation_form("1");

        if (document.getElementsByName("affect_poste")[0]) {
            document.getElementsByName("affect_poste")[0].disabled = false;
            document.getElementsByName("affect_poste")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_cible")[0]) {
            document.getElementsByName("affect_cible")[0].disabled = false;
            document.getElementsByName("affect_cible")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_cible_nom")[0]) {
            document.getElementsByName("affect_cible_nom")[0].disabled = false;
            document.getElementsByName("affect_cible_nom")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_date_du")[0]) {
            document.getElementsByName("affect_date_du")[0].disabled = false;
            document.getElementsByName("affect_date_du")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("affect_date_au")[0]) {
            document.getElementsByName("affect_date_au")[0].disabled = false;
            document.getElementsByName("affect_date_au")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("affect_comment")[0]) {
            document.getElementsByName("affect_comment")[0].disabled = false;
            document.getElementsByName("affect_comment")[0].style.backgroundColor = "#FFFFFF";
        }
    }

    function Annule_Affectation_DetailFields() {
        clean_affectation_fields();
        Get_Given_Materiel_Affectation(selected_affectation_id);
        setAffectation_data_changed("");
        setedite_affectation_form("");

    }


    function Fermer_Affectation_Fields() {
        clean_affectation_fields();
        setedite_affectation_form();
        setdisplay_affectation();
        setSelectionModel_affectations([]);
        setgridline_affectation_id('');
    }

    function Fermer_nouvelle_Affectation_Fields() {
        clean_nouvelle_affectation_fields();
        setedite_affectation_form();
        setdisplay_affectation();
        setAdd_new_affectation();
        setSelectionModel_affectations([]);
        setgridline_affectation_id('');
    }

    const [Get_Given_Materiel_Affectation_api, setGet_Given_Materiel_Affectation_api] = useState();
    const [Get_Given_Materiel_Affectation_message, setGet_Given_Materiel_Affectation_message] = useState();
    const [Get_Given_Materiel_Affectation_result, setGet_Given_Materiel_Affectation_result] = useState();
    function Get_Given_Materiel_Affectation(local_affectation_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("affectation_id", local_affectation_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Affectation_Ressource_Materielle_Poste/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Given_Materiel_Affectation  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Materiel_Affectation  res.data.message r_class = " + res.data.message);
                setGet_Given_Materiel_Affectation_api("true");



                if (String(res.data.message).length > 0) {
                    setGet_Given_Materiel_Affectation_result(res.data.message);
                    var mylocal_affectation = JSON.parse(res.data.message);

                    if (mylocal_affectation.poste) {
                        setp_affect_poste(mylocal_affectation.poste);
                    }

                    if (mylocal_affectation.date_du) {
                        setp_affect_date_du(mylocal_affectation.date_du);

                    }

                    if (mylocal_affectation.date_au) {
                        setp_affect_date_au(mylocal_affectation.date_au);
                    }

                    if (mylocal_affectation.related_target_collection) {
                        setp_affect_cible(mylocal_affectation.related_target_collection);
                        if (String(mylocal_affectation.related_target_collection) === "myclass")
                            setp_affect_cible_label("Formation");
                        else if (String(mylocal_affectation.related_target_collection) === "session_formation")
                            setp_affect_cible_label("Session formation");
                        else if (String(mylocal_affectation.related_target_collection) === "ressource_humaine")
                            setp_affect_cible_label("Employé");
                    }


                    if (mylocal_affectation.related_target_collection_id) {
                        setp_affect_cible_nom(mylocal_affectation.related_target_collection_id);
                        setp_affect_cible_nom_label(mylocal_affectation.related_target_collection_id_nom);
                    }

                    if (mylocal_affectation.comment) {
                        setp_affect_comment(mylocal_affectation.comment);
                    }


                } else {
                    alert(" Aucune donnée recuperée");
                }

                Disable_Detail_Affectation_Fields();
            }
            else {
                setGet_Given_Materiel_Affectation_api("false");
                setGet_Given_Materiel_Affectation_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Materiel_Affectation = ', error);
            setGet_Given_Materiel_Affectation_api("false");
            alert(" Impossible de recuperer l'affectation de l'employé");
            //setmyApimyApiMessage("")
        })
    }

    const [Add_one_affectation_api, setAdd_one_affectation_api] = useState();
    const [Add_one_affectation_message, setAdd_one_affectation_message] = useState();
    const [Add_one_affectation_result, setAdd_one_affectation_result] = useState();
    function Add_one_affectation() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rm_id", selected_id);

        if (p_one_affect_poste)
            form.append("poste", p_one_affect_poste);
        else {
            alert(" Vous devez definir une poste pour cette affectation");
            return;
        }

        if (p_one_affect_date_du)
            form.append("date_du", p_one_affect_date_du);
        else {
            alert(" Vous devez definir une date de début pour cette affectation");
            return;
        }


        if (p_one_affect_date_au)
            form.append("date_au", p_one_affect_date_au);
        else
            form.append("date_au", "");


        if (p_one_affect_comment)
            form.append("comment", p_one_affect_comment);
        else
            form.append("comment", "");


        if (p_one_affect_cible)
            form.append("related_target_collection", p_one_affect_cible);
        else
            form.append("related_target_collection", "");

        if (p_one_affect_cible_nom)
            form.append("related_target_collection_id", p_one_affect_cible_nom);
        else
            form.append("related_target_collection_id", "");


        console.log(" Add_one_affectation affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Affectation_Ressource_Materielle_Poste/";

        axios.post(myurl, form).then(res => {
            console.log(" In Add_one_affectation  res.data.status = " + res.data.status);
            console.log(" In Add_one_affectation  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_one_affectation_api("true");
                setAdd_one_affectation_result(res.data.message);

                Disable_Detail_Affectation_Fields();
                setAffectation_data_changed("");
                setedite_affectation_form("");
                setAdd_new_affectation("");
                Getall_Materiel_Affectation(selected_id);
                Getall_Materiel_Rattachement(selected_id);
                setSelectionModel_affectations([]);
                setgridline_affectation_id('');

                alert(" L'affectation a été ajoutée.");

                if (document.getElementById('myRef_new_affectation')) {
                    var divh = document.getElementById('myRef_new_affectation').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_one_affectation_api("false");
                setAdd_one_affectation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.log('UpdateStagiaireData : Not good man :( Add_one_affectation = ' + error);
            setAdd_one_affectation_api("false");
            alert(" Impossible d'ajouter l'affectation");

        })
    }

    function handleClick_edit_affectation_From_Line(selected_row_id) {
        setdisplay_affectation("1");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
        //setsubmenu("");

        var line = JSON.parse(rows_affectations[selected_row_id]);

        //console.log("### line = ", line);
        var affectation_id = line._id;
        set_selected_affectation_id(affectation_id);


        if (document.getElementById('myRef_new_affectation')) {
            var divh = document.getElementById('myRef_new_affectation').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        Get_Given_Materiel_Affectation(affectation_id);

    }

    function handleClick_delete_affection(event, cellValues) {

        var local_affectation_id = cellValues.row._id;

        Delete_affection(selected_id, local_affectation_id);
    }

    const [Delete_affection_api, setDelete_affection_api] = useState();
    const [Delete_affection_message, setDelete_affection_message] = useState();
    const [Delete_affection_result, setDelete_affection_result] = useState();
    function Delete_affection(local_rh_id, local_affectation_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("rm_id", local_rh_id);
        form.append("_id", local_affectation_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Affectation_Ressource_Materielle_Poste/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_affection  res.data.status = " + res.data.status);
            //console.log(" In Delete_affection  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_affection_api("true");
                setDelete_affection_result(res.data.message);
                clean_affectation_fields();
                setAffectation_data_changed();
                setedite_affectation_form();
                setdisplay_affectation();
                Getall_Materiel_Affectation(local_rh_id);
                Getall_Materiel_Rattachement(local_rh_id);
                alert(" La fonction a été correctement supprimée.")
            }
            else {
                setDelete_affection_api("false");
                setDelete_affection_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.log('Delete_affection : Not good man :( Delete_affection = ' + error);
            setDelete_affection_api("false");
            alert(" Impossible de supprimer la fonction");
        })
    }


    const [Delete_Materiel_api, setDelete_Materiel_api] = useState();
    const [Delete_Materiel_message, setDelete_Materiel_message] = useState();
    const [Delete_Materiel_result, setDelete_Materiel_result] = useState();
    function Delete_Materiel(local_rh_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_rh_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Ressource_Materielle/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Materiel  res.data.status = " + res.data.status);
            //console.log(" In Delete_Materiel  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Materiel_api("true");
                setDelete_Materiel_result(res.data.message);
                clean_all_filters();
                alert(" Le materiel a été correctement supprimé.")
            }
            else {
                setDelete_Materiel_api("false");
                setDelete_Materiel_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.log('Delete_Materiel : Not good man :( Delete_Materiel = ' + error);
            setDelete_Materiel_api("false");
            alert(" Impossible de supprimer le materiel");
        })
    }

    function clean_all_filters() {

        setgridline_id('');
        setgridline_affectation_id('');
        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");

        setdisplay_affectation("");
        setAdd_new_affectation("");
        clean_affectation_fields();
        clean_nouvelle_affectation_fields();
        setSelectionModel_affectations([]);
        setgridline_affectation_id('');
        setSelectionModel([]);
        setsubmenu("");

        Getall_Training_Materiel_Salle();
    }


    function handleClick_delete(event, cellValues) {

        var local_rm_id = cellValues.row._id;

        Delete_Materiel(local_rm_id);
    }


    const New_Option = [

        { "id": "0", "label": "Employe", "value": "ressource_humaine" },
        { "id": "1", "label": "Formation", "value": "myclass" },
        { "id": "2", "label": "Session de formation", "value": "session_formation" },
        { "id": "2", "label": "Materiel", "value": "ressource_materielle" },
    ]


    const New_Price_by = [
        { "id": "fixe", "label": "Fixe", "value": "fixe" },
        { "id": "heure", "label": "heure", "value": "heure" },
        { "id": "jour", "label": "jour", "value": "jour" },
        { "id": "mois", "label": "mois", "value": "mois" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_Filter = [
        { "id": "nom", "label": "Nom Salle", "value": "nom" },
        { "id": "ref_interne", "label": "Ref. Interne", "value": "ref_interne" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.

    ]

    return (
        <div className="materiel_salle">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            <h3> Vos Salles </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}



                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>


                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }




                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Training_Materiel}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },

                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },
                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_materiel_From_Line(newSelectionModel);
                                            */
                                        if (newSelectionModel.length !== 1) {
                                            setsubmenu();
                                            setdisplay_detail_materiel();
                                            setadd_One_Materiel();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            email: JSON.parse(item).email,
                                            nom: JSON.parse(item).nom,
                                            ref_interne: JSON.parse(item).ref_interne,

                                            type: JSON.parse(item).type,
                                            code_site: JSON.parse(item).site_formation_code_site,
                                            acces_handicape: JSON.parse(item).acces_handicape,
                                            capacite_max: JSON.parse(item).capacite_max,
                                            capacite_ideale: JSON.parse(item).capacite_ideale,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        clear_salle_fields();
                                        setgridline_affectation_id('');
                                        setgridline_id(newSelectionModel.row.id);
                                        handleClick_edit_materiel_From_Line(newSelectionModel.row.id);

                                    }}


                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "statut"
                                        if (params.field === "status" && String(params.value) == "0") {
                                            return 'cell--statut--preinscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "1") {
                                            return 'cell--statut--inscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "-1") {
                                            return 'cell--statut--annule';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        {/* <Button variant="outlined" onClick={submenu_import_materiel} className="detail_class_submenu bton_import_excel"
                            id='menu_import_participant' name='menu_import_participant'>Importer du materiel Excel &nbsp;
                            <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                        </Button>
                                */}


                        <Button variant="outlined" onClick={submenu_add_one_materiel}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Salle &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                        <br />
                        {session_file_name && <nav><i>{session_file_name}</i></nav>}
                        {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Le materiel ont été correctement importé</nav>}
                        {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}


                    </div>


                </div>
                <div className="div_row"> &nbsp;</div>


            </div>

            <div className="div_row" ref={myRef} id="myRef"> &nbsp; </div>

            {String(submenu) === "detail_materiel" && String(add_One_Materiel) !== "1" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_materiel} className="detail_class_submenu" id='detail_materiel' name='detail_materiel'>Détail Salle</Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_ressource_associe} className="detail_class_submenu" id='ressource_associe' name='affectation'>Ressources Associées  </Button>
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail Salle </nav>
                <div className="div_row" style={{ "border": "None" }}>
                    <div className="div_row_gauche">

                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                            <div className="div_row_gauche_image">
                                <img class="img_class_logo" src={userimgclassprofil} /><br />

                            </div>
                            <div className="div_row_droite_image">
                                <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                {<nav>

                                    <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                    {String(userimgclassprofilchanged) === "1" &&
                                        <nav className="bton_image_class" onClick={record_materiel_Image}>Enregistrer la photo<br />  </nav>
                                    }

                                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                        <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                    }
                                    {String(userimgclassprofilchanged) === "1" &&
                                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                        </div>}


                                </nav>}

                                {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                            </div>

                        </div>
                        <div className="div_row_bis tips_img_class">
                            <li>
                                L'image doit etre carrée.
                            </li>
                            <li>
                                Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                            </li>
                            <li>
                                Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                Les dimensions maximales sont de 256pixels X 256pixels
                            </li>
                            <li>
                                La taille de l'image ne doit pas dépasser 1 mega octet
                            </li>
                        </div>

                    </div>
                </div>
                <div className="session_data" >
                    <div className="session_caract"> <b>Ref. Interne </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_ref_interne"
                            id="detail_ref_interne"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_ref_interne}
                            onChange={(e) => {
                                setp_detail_ref_interne(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b> Nom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_nom"
                            id="detail_nom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_nom}
                            onChange={(e) => {
                                setp_detail_nom(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Description<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_description"
                            id="detail_description"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_description}
                            onChange={(e) => {
                                setp_detail_description(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>





                    {String(materiel_data_edit_mode) === "1" &&
                        <div className="session_caract"> Site de formation<br />
                            {New_Getall_Partner_Site_Formation_result && New_Getall_Partner_Site_Formation_result.length > 0 &&

                                <Autocomplete
                                    disablePortal
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, fontSize: 2, }}

                                    name="detail_site_formation"
                                    id="detail_site_formation"
                                    className="disabled_style"
                                    options={New_Getall_Partner_Site_Formation_result}
                                    value={New_Getall_Partner_Site_Formation_result.filter((data) => (data)._id === String(p_detail_site_formation))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_detail_site_formation(value._id);
                                            setmateriel_data_changed("1");
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />}


                        </div>}

                    {String(materiel_data_edit_mode) !== "1" &&
                        <div className="session_caract"> Site de formation<br />
                            {New_Getall_Partner_Site_Formation_result && New_Getall_Partner_Site_Formation_result.length > 0 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_site_formation"
                                    id="detail_site_formation"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Site_Formation_result.filter((data) => (data)._id === String(p_detail_site_formation))[0].label}
                                />
                            }


                        </div>}



                    <div className="session_caract" > Coût (estimé)<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_prix_achat"
                            id="detail_prix_achat"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style disabled_style_moitier"
                            value={p_detail_prix_achat}
                            onChange={(e) => {
                                setp_detail_prix_achat(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />

                        {String(materiel_data_edit_mode) !== "1" && <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_prix_achat_by"
                            id="detail_prix_achat_by"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ min: "1", max: "1000", step: "1" }}
                            disabled={false}
                            className="disabled_style disabled_style_moitier"
                            value={New_Price_by.filter((data) => (data).value === String(p_detail_prix_achat_by))[0].label}

                        />}
                        {String(materiel_data_edit_mode) === "1" && <Autocomplete
                            disablePortal
                            name="detail_prix_achat_by"
                            id="detail_prix_achat_by"
                            className="disabled_style disabled_style_moitier"
                            value={New_Price_by.filter((data) => (data).value === String(p_detail_prix_achat_by))[0].label}

                            options={New_Price_by}
                            onChange={(event, value) => {
                                if (value && value.value) {

                                    setp_detail_prix_achat_by(value.value);
                                    setmateriel_data_changed("1");

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}
                    </div>




                    <div className="session_caract"> Complement Adresse<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_complement_adr"
                            id="detail_complement_adr"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_complement_adresse}
                            onChange={(e) => {
                                setp_detail_complement_adresse(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Capacité Idéale<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_capcite_ideale"
                            id="detail_capcite_ideale"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_capacite_ideale}
                            onChange={(e) => {
                                setp_detail_capacite_ideale(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Capacité Maximale<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_capcite_max"
                            id="detail_capcite_max"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_capacite_max}
                            onChange={(e) => {
                                setp_detail_capacite_max(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>


                    <div className="session_caract" >
                        Accès handicapé ?<br />
                        <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                            onChange={(e) => {
                                setmateriel_data_changed("1");
                                if (p_detail_acces_handicape === true)
                                    setp_detail_acces_handicape(false)
                                else {

                                    setp_detail_acces_handicape(true);
                                }

                            }}
                            id="toggleSwitch_acces_hand" name="toggleSwitch_acces_hand">
                            <ToggleSwitch_v2_mysy
                                label="Accès handicapé ?" id="toggleSwitch_acces_hand" name="toggleSwitch_acces_hand" checked={p_detail_acces_handicape} style={{ height: "2rem" }} />
                        </nav>

                    </div>

                    <div className="session_caract"> Commentaire <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_detail"
                            id="detail_detail"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_detail}
                            onChange={(e) => {
                                setp_detail_detail(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(materiel_data_changed) === "1" && String(materiel_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}

                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(materiel_data_changed) === "1" && String(materiel_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Update_One_Materiel_Data}>Enregistrer les modifications
                            </Button>

                        </div>}

                        {String(materiel_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_Materiel_DetailFields}>Annuler
                            </Button>
                        </div>}
                    </div>


                    {String(materiel_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                        <Button variant="contained" className="bton_edit" onClick={Enable_Materiel_DetailFields}>Editer
                        </Button>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                </div>


            </div>}


            {String(submenu) === "detail_materiel" && String(add_One_Materiel) === "1" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_materiel} className="detail_class_submenu" id='detail_materiel' name='detail_materiel'>Détail Salle</Button>
                    {/*  <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_ressource_associe} className="detail_class_submenu" id='ressource_associe' name='affectation'>Ressources Associées  </Button>
                        */}
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Ajouter un  nouveau  materiel </nav>
                <div className="session_data" >
                    <div className="session_caract"> <b>Ref. Interne </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_ref_interne"
                            id="p_one_detail_ref_interne"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_ref_interne}
                            onChange={(e) => {
                                setp_one_detail_ref_interne(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b> Nom </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_nom"
                            id="p_one_detail_nom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_nom}
                            onChange={(e) => {
                                setp_one_detail_nom(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Description<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_description"
                            id="p_one_detail_description"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_description}
                            onChange={(e) => {
                                setp_one_detail_description(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>




                    <div className="session_caract"> Type<br />
                        <Autocomplete
                            disablePortal
                            name="p_one_detail_type"
                            id="p_one_detail_type"
                            className="disabled_style"

                            options={New_Getall_Type_Materiel_result}

                            onChange={(event, value) => {
                                if (value && value.code) {
                                    setp_one_detail_type(value.code);
                                    setmateriel_data_changed("1");

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>


                    <div className="session_caract"> Site de formation<br />
                        {New_Getall_Partner_Site_Formation_result && New_Getall_Partner_Site_Formation_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                                name="p_one_detail_site_formation"
                                id="p_one_detail_site_formation"
                                className="disabled_style enable_style"
                                options={New_Getall_Partner_Site_Formation_result}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_one_site_formation_id(value._id)

                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                    </div>



                    <div className="session_caract"> Coût(estimé)<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_prix_achat"
                            id="p_one_detail_prix_achat"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style disabled_style_moitier"
                            value={p_one_detail_prix_achat}
                            onChange={(e) => {
                                setp_one_detail_prix_achat(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }
                        />

                        <Autocomplete
                            disablePortal
                            name="p_one_detail_prix_achat_by"
                            id="p_one_detail_prix_achat_by"
                            className="disabled_style disabled_style_moitier"
                            value={New_Price_by.filter((data) => (data).value === String(p_one_detail_prix_achat_by))[0].label}

                            options={New_Price_by}
                            onChange={(event, value) => {
                                if (value && value.value) {

                                    setp_one_detail_prix_achat_by(value.value);


                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>


                    <div className="session_caract"> Complement Adresse<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_complement_adr"
                            id="detail_complement_adr"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_complement_adresse}
                            onChange={(e) => {
                                setp_one_detail_complement_adresse(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Capacité Idéale<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_capcite_ideale"
                            id="detail_capcite_ideale"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_capacite_ideale}
                            onChange={(e) => {
                                setp_one_detail_capacite_ideale(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Capacité Maximale<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_capcite_max"
                            id="detail_capcite_max"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_capacite_max}
                            onChange={(e) => {
                                setp_one_detail_capacite_max(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract" >
                        Accès handicapé ?<br />
                        <nav className="disabled_style" style={{ "height": '3rem', border: "none" }}
                            onChange={(e) => {
                                setmateriel_data_changed("1");
                                if (p_one_detail_acces_handicape === true)
                                    setp_one_detail_acces_handicape(false)
                                else {

                                    setp_one_detail_acces_handicape(true);
                                }


                            }}
                            id="toggleSwitch_acces_hand" name="toggleSwitch_acces_hand">
                            <ToggleSwitch_v2_mysy
                                label="Accès handicapé ?" id="toggleSwitch_acces_hand" name="toggleSwitch_acces_hand" checked={p_one_detail_acces_handicape} style={{ height: "2rem" }} />
                        </nav>

                    </div>


                    <div className="session_caract"> Commentaire<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_detail"
                            id="p_one_detail_detail"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_detail}
                            onChange={(e) => {
                                setp_one_detail_detail(e.target.value);
                                setmateriel_data_changed("1");
                            }
                            }

                        />
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {<div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_One_Materiel_Data}>Enregistrer
                            </Button>

                        </div>}

                        {<div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_add_one_materiel}>Annuler
                            </Button>
                        </div>}
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>

            </div>}

            {String(submenu) === "affectation" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_materiel} className="detail_class_submenu" id='detail_materiel' name='detail_materiel'>Détail Salle</Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_ressource_associe} className="detail_class_submenu" id='ressource_associe' name='affectation'>Ressources Associées  </Button>
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Affectation</nav>
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 350, width: '100%', paddingRight: '1px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    paddingRight: '1px',
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },



                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel_affectations(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_affectation_From_Line(newSelectionModel);
*/
                                        if (newSelectionModel.length !== 1) {
                                            setedite_affectation_form();
                                            setdisplay_affectation();
                                            setgridline_affectation_id('');
                                        }
                                    }}
                                    selectionModel={selectionModel_affectations}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rows_affectations.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            poste: JSON.parse(item).poste,
                                            date_du: JSON.parse(item).date_du,
                                            date_au: JSON.parse(item).date_au,
                                            target_related_collection: JSON.parse(item).target_related_collection,
                                            target_related_collection_id: JSON.parse(item).target_related_collection_id,
                                            related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                            related_target_collection_object: JSON.parse(item).related_target_collection_object,


                                        }
                                    ))}

                                    columns={columns_materielle_affectation}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}

                                    onRowDoubleClick={(newSelectionModel) => {

                                        setgridline_affectation_id(newSelectionModel.row.id);
                                        handleClick_edit_affectation_From_Line(newSelectionModel.row.id);


                                    }}

                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_affectation_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}




                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>
                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        <Button variant="outlined" onClick={add_one_affectation_block}
                            className="detail_class_submenu bton_add_session"
                            id='menu_add_participant' name='menu_add_participant'>Ajout 1 affectation &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                        <br />

                    </div>
                </div>

                <nav ref={myRef_affectation}> &nbsp; </nav>
                {String(display_affectation) === "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail Affectation </nav>

                    <div className="session_data" >
                        <div className="session_caract">Usage du Matériel <br />
                            <TextField
                                name="affect_poste"
                                id="affect_poste"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_poste}
                                onChange={(e) => {
                                    setp_affect_poste(e.target.value);
                                    setAffectation_data_changed("1");
                                }}

                            />

                        </div>


                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Affecté à (type) <br />
                            <TextField
                                name="affect_cible"
                                id="affect_cible"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible_label}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Affecté à (type) <br />

                            <Autocomplete
                                disablePortal
                                name="affect_cible"
                                id="affect_cible"
                                className="disabled_style enable_style"
                                fullWidth
                                options={New_Option}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_affect_cible(value.value);
                                        setAffectation_data_changed("1");


                                    }

                                }}


                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />


                            {/*<TextField
                                name="affect_cible"
                                id="affect_cible"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible}
                                onChange={(e) => {
                                    setp_affect_cible(e.target.value);
                                    setAffectation_data_changed("1");
                                }}


                            >
                                <MenuItem value="ressource_humaine" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Employe &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="myclass" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Formation &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="session_formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Session de formation &nbsp;</MenuItem>
                                <MenuItem value="" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Aucun &nbsp;</MenuItem>

                            </TextField>*/}

                        </div>}


                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Affecté à (Nom) <br />
                            <TextField
                                name="affect_cible_nom"
                                id="affect_cible_nom"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible_nom_label}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Affecté à (Nom)<br />
                            <Autocomplete
                                disablePortal
                                name="affect_cible_nom"
                                id="affect_cible_nom"
                                className="disabled_style enable_style"
                                fullWidth
                                options={New_Get_Materiel_Related_Target_Collection_Data_result.filter((local_mymanager) => local_mymanager.related_target_collection === p_affect_cible)}
                                onChange={(event, value) => {
                                    if (value && value) {
                                        setp_affect_cible_nom(value.related_target_collection_id);
                                        setAffectation_data_changed("1");

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                            {/*<TextField
                                name="affect_cible_nom"
                                id="affect_cible_nom"
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_cible_nom}
                                onChange={(e) => {
                                    setp_affect_cible_nom(e.target.value);
                                    setAffectation_data_changed("1");
                                }}
                            >

                                {Get_Materiel_Related_Target_Collection_Data_result &&
                                    Get_Materiel_Related_Target_Collection_Data_result.filter((local_mymanager) => JSON.parse(local_mymanager).related_target_collection === p_affect_cible).map((mymanager) => (
                                        <MenuItem key={JSON.parse(mymanager).related_target_collection_id} value={JSON.parse(mymanager).related_target_collection_id}
                                            style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                            {JSON.parse(mymanager).related_target_collection_id_nom}&nbsp;&nbsp;

                                        </MenuItem>
                                    ))}


                            </TextField>*/}

                        </div>}



                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Date du <br />
                            <TextField
                                name="affect_date_du"
                                id="affect_date_du"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_date_du}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Date du <br />

                            <DatePicker
                                name="affect_date_du"
                                id="affect_date_du"
                                selected={new Date(moment(p_affect_date_du, "DD/MM/YYYY HH:mm:ss"))}
                                onChange={(date) => {
                                    setp_affect_date_du(format(date, 'dd/MM/yyyy'));
                                    setAffectation_data_changed("1");
                                }
                                }
                                showTimeSelect={false}
                                //filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style enable_style"
                                locale='fr-FR'
                            />

                        </div>}


                        {String(edite_affectation_form) !== "1" && <div className="session_caract">Date au <br />
                            <TextField
                                name="affect_date_au"
                                id="affect_date_au"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_date_au}

                            />

                        </div>}

                        {String(edite_affectation_form) === "1" && <div className="session_caract">Date au <br />
                            <DatePicker
                                name="affect_date_au"
                                id="affect_date_au"
                                selected={new Date(moment(p_affect_date_au, "DD/MM/YYYY HH:mm:ss"))}
                                onChange={(date) => {
                                    setp_affect_date_au(format(date, 'dd/MM/yyyy'));
                                    setAffectation_data_changed("1");
                                }
                                }
                                showTimeSelect={false}
                                //filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style enable_style"
                                locale='fr-FR'
                            />

                        </div>}

                        <div className="session_caract">Commentaire <br />
                            <TextField
                                name="affect_comment"
                                id="affect_comment"
                                text
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                minRows={3}
                                maxRows={3}
                                disabled={false}
                                className="disabled_style enable_style"
                                value={p_affect_comment}
                                onChange={(e) => {
                                    setp_affect_comment(e.target.value);
                                    setAffectation_data_changed("1");

                                }}

                            />

                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {String(Affectation_data_changed) === "1" && String(edite_affectation_form) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}


                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(Affectation_data_changed) === "1" && String(edite_affectation_form) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Update_One_Affectation_Data}>Enregistrer les modifications
                                </Button>

                            </div>}

                            {String(edite_affectation_form) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Annule_Affectation_DetailFields}>Annuler
                                </Button>
                            </div>}
                        </div>
                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(edite_affectation_form) !== "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_annule" onClick={Fermer_Affectation_Fields}>Fermer
                                </Button>

                            </div>}


                            {String(edite_affectation_form) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={Enable_Detail_Affectation_Fields}>Editer
                                </Button>

                            </div>}
                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                    </div>
                </div>}


                <nav ref={myRef_new_affectation} id="myRef_new_affectation"> &nbsp; </nav>
                {String(Add_new_affectation) === "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Nouvelle affectation du materiel</nav>
                    <div className="session_data" >
                        <div>
                            <div className="session_caract">Usage du Matériel  <br />
                                <TextField
                                    name="one_affect_poste"
                                    id="one_affect_poste"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_poste}
                                    onChange={(e) => {
                                        setp_one_affect_poste(e.target.value);

                                    }}

                                />

                            </div>

                            <div className="session_caract">Affecté à (type) <br />
                                <Autocomplete
                                    disablePortal
                                    name="one_affect_cible"
                                    id="one_affect_cible"
                                    className="disabled_style enable_style"
                                    fullWidth
                                    options={New_Option}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_one_affect_cible(value.value);


                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                                {/* <TextField
                                    name="one_affect_cible"
                                    id="one_affect_cible"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_cible}
                                    onChange={(e) => {
                                        setp_one_affect_cible(e.target.value);
                                    }}

                                >
                                    <MenuItem value="ressource_humaine" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Employe &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="myclass" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Formation &nbsp;&nbsp;</MenuItem>
                                    <MenuItem value="session_formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Session de formation &nbsp;</MenuItem>
                                    <MenuItem value="" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Aucun &nbsp;</MenuItem>

                                </TextField>*/}

                            </div>

                            {/* <div className="session_caract">Cible Nom  <br />
                                <TextField
                                    name="one_affect_cible_nom"
                                    id="one_affect_cible_nom"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_cible_nom}
                                    onChange={(e) => {
                                        setp_one_affect_cible_nom(e.target.value);
                                    }}
                                >

                                    {Get_Materiel_Related_Target_Collection_Data_result &&
                                        Get_Materiel_Related_Target_Collection_Data_result.filter((local_mymanager) => JSON.parse(local_mymanager).related_target_collection === p_one_affect_cible).map((mymanager) => (
                                            <MenuItem key={JSON.parse(mymanager).related_target_collection_id} value={JSON.parse(mymanager).related_target_collection_id}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mymanager).related_target_collection_id_nom}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}


                                </TextField>Cible 

                            </div>*/}


                            <div className="session_caract">Affecté à (Nom)  <br />
                                <Autocomplete
                                    disablePortal
                                    name="one_affect_cible_nom"
                                    id="one_affect_cible_nom"
                                    className="disabled_style enable_style"
                                    fullWidth
                                    options={New_Get_Materiel_Related_Target_Collection_Data_result.filter((local_mymanager) => local_mymanager.related_target_collection === p_one_affect_cible).filter((local_mymanager2) => local_mymanager2.related_target_collection_id !== selected_id)}
                                    onChange={(event, value) => {
                                        if (value && value) {
                                            setp_one_affect_cible_nom(value.related_target_collection_id);
                                            console.log(" ### value = ", value);

                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />
                            </div>



                            <div className="session_caract">Date du <br />
                                <DatePicker
                                    name="one_affect_date_du"
                                    id="one_affect_date_du"
                                    selected={new Date(moment(p_one_affect_date_du, "DD/MM/YYYY HH:mm:ss"))}
                                    onChange={(date) => {
                                        setp_one_affect_date_du(format(date, 'dd/MM/yyyy'));
                                    }
                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                />

                            </div>


                            <div className="session_caract">Date au <br />
                                <DatePicker
                                    name="one_affect_date_au"
                                    id="one_affect_date_au"
                                    selected={new Date(moment(p_one_affect_date_au, "DD/MM/YYYY HH:mm:ss"))}
                                    onChange={(date) => {
                                        setp_one_affect_date_au(format(date, 'dd/MM/yyyy'));
                                    }
                                    }
                                    showTimeSelect={false}
                                    //filterTime={filterPassedTime_start}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                />

                            </div>

                            <div className="session_caract">Commentaire <br />
                                <TextField
                                    name="one_affect_comment"
                                    id="one_affect_comment"
                                    text
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    minRows={3}
                                    maxRows={3}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_one_affect_comment}
                                    onChange={(e) => {
                                        setp_one_affect_comment(e.target.value);

                                    }}

                                />

                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Add_one_affectation}>Enregistrer
                                </Button>

                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Fermer_nouvelle_Affectation_Fields}>Annuler
                                </Button>
                            </div>
                        </div>

                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>


                </div>}

            </div>}

            {String(submenu) === "ressource_associe" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_materiel} className="detail_class_submenu" id='detail_materiel' name='detail_materiel'>Détail Salle</Button>
                    <Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_ressource_associe} className="detail_class_submenu" id='ressource_associe' name='affectation'>Ressources Associées  </Button>
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Ressources Associées</nav>
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 350, width: '100%', paddingRight: '1px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    paddingRight: '1px',
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },



                                }}
                            >
                                <DataGrid
                                    // checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_ressources_associes(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_affectation_From_Line(newSelectionModel);
*/
                                        if (newSelectionModel.length !== 1) {
                                            setedite_affectation_form();
                                            setdisplay_affectation();
                                            setgridline_affectation_id('');
                                        }
                                    }}
                                    selectionModel={selectionModel_affectations}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Getall_Materiel_Rattachement_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            poste: JSON.parse(item).poste,
                                            date_du: JSON.parse(item).date_du,
                                            date_au: JSON.parse(item).date_au,
                                            target_related_collection: JSON.parse(item).target_related_collection,
                                            target_related_collection_id: JSON.parse(item).target_related_collection_id,
                                            related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                            related_target_collection_object: JSON.parse(item).related_target_collection_object,


                                        }
                                    ))}

                                    columns={columns_ressources_associes}
                                    pageSize={10}
                                    className="datagridclass"

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}

                                    onRowDoubleClick={(newSelectionModel) => {




                                    }}

                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_affectation_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}




                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>

            </div>}

        </div >
    );
}

export default Materiel_Salle;
