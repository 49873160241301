import React, { useRef, useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { useHistory } from "react-router-dom";
import OrderConfirmation from "./OrderConfirmation";
import { IoAddCircleOutline } from "react-icons/io5";
import MyPaymentMethode from "./PaymentMethode";
import img_visa_mastercard from "./../mysy_img/visa_mastercard.png"
import img_logo_stripe from "./../mysy_img/logo-stripe-secure.png";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import img_paypal from "./../mysy_img/paypal.png"
import moment from "moment";
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import Popup from 'reactjs-popup';
import SendIcon from '@mui/icons-material/Send';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'


const stripePromise = loadStripe('pk_test_51LUUfAAbmaEugrFTI25uZBD3IFjbtaL6jUfRV83diDf7nco8worna4NGKhMHbPP71WCwT5EHFRdDNatxPrJWwgZ300kgH5EO4p');
//const stripePromise_PROD = loadStripe('pk_live_51LUUfAAbmaEugrFTyfNe4Dj0vbxc5qXQqNs0rztjAKy8oSlc5EEQyJGjA8Z5kXmP2gvUMcYLUBYCIyWWzLCw59RY00PtGInkVf');



function HebergementLms(props) {

    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [menu, setmenu] = useState("default");
    const [fields1desabled, setfields1desabled] = useState(true);
    const [periode, setperiode] = useState("Mensuel");
    const [testval, settestval] = useState();
    const [gotocheckout, setgotocheckout] = useState("0");
    const [currentpack, setcurrentpack] = useState(props.packs);
    const [periodicity, setperiodicity] = useState(props.periodicity);
    const [packprice, setpackprice] = useState(props.mypackprice);
    const [getpackproducts, setgetpackproducts] = useState();
    const scrollDiv = useRef(null);
    const [okcommande, setokcommande] = useState();
    const stripe = useStripe();
    const elements = useElements();


    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }


    const [GetLms_Themes_api, setGetLms_Themes_api] = useState();
    const [GetLms_Themes_message, setGetLms_Themes_message] = useState();
    const [GetLms_Themes_result, setGetLms_Themes_result] = useState();
    function GetLms_Themes(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Lms_Themes/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetLms_Themes  res.data.status = " + res.data.status);
                //console.log(" In GetLms_Themes  res.data.message r_class = " + res.data.message);
                setGetLms_Themes_api("true");
                setGetLms_Themes_result(res.data.message);
            }
            else {
                setGetLms_Themes_api("false");
                setGetLms_Themes_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetLms_Themes = ', error);
            setGetLms_Themes_api("false");

        })
    }


    const [GetLms_Themes_Logo_Img, setGetLms_Themes_Logo_Img] = useState("");
    const [GetLms_Themes_Banniere_Img, setGetLms_Themes_Banniere_Img] = useState("");

    const [GetLms_Themes_Logo_Banniere_api, setGetLms_Themes_Logo_Banniere_api] = useState();
    const [GetLms_Themes_Logo_Banniere_message, setGetLms_Themes_Logo_Banniere_message] = useState();
    const [GetLms_Themes_Logo_Banniere_result, setGetLms_Themes_Logo_Banniere_result] = useState();
    function GetLms_Themes_Logo_Banniere(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_LMS_Logo_And_Bannier_Img/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === "true") {
                //console.log(" In GetLms_Themes_Logo_Banniere  res.data.status = " + res.data.status);
                console.log(" In GetLms_Themes_Logo_Banniere  res.data.message r_class = " + res.data.message);
                setGetLms_Themes_Logo_Banniere_api("true");
                setGetLms_Themes_Logo_Banniere_result(res.data.message);

                var logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo;
                setGetLms_Themes_Logo_Img(logo_img);


                var banniere_img = "data:image/png;base64," + JSON.parse(res.data.message).banniere;
                setGetLms_Themes_Banniere_Img(banniere_img);

            }
            else {
                setGetLms_Themes_Logo_Banniere_api("false");
                setGetLms_Themes_Logo_Banniere_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetLms_Themes_Logo_Banniere = ', error);
            alert(" Impossible de récuperer le logo et la bannière ")

        })
    }

    const [RecordData_api, setRecordData_api] = useState();
    const [RecordData_result, setRecordData_result] = useState();
    const [RecordData_message, setRecordData_message] = useState();

    function RecordData() {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append('theme_name', selected_lms_theme_text);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Lms_Config_Theme_and_Logo/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetLms_Themes  res.data.status = " + res.data.status);
                //console.log(" In GetLms_Themes  res.data.message r_class = " + res.data.message);
                setRecordData_api("true");
                setRecordData_result(res.data.message);
                setLoading(false);
                alert(" La mise à jour du theme a été correctement effectuée. ");
            }
            else {
                setRecordData_api("false");
                setRecordData_message(res.data.message);
                setLoading(false);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetLms_Themes = ', error);
            setRecordData_api("false");
            setLoading(false);

        })
    }


    const [selected_lms_theme, setselected_lms_theme] = useState();
    const [selected_lms_theme_text, setselected_lms_theme_text] = useState();
    const [urlpreview_lms_theme, seturlpreview_lms_theme] = useState();
    function handleChangeselected_lms_theme(event) {

        var val = event.target.value
        GetLms_Themes(val)
        seturlpreview_lms_theme(val);

        const sel = document.getElementById("lms_theme_liste");
        let value = sel.options[sel.selectedIndex].value;
        let text = sel.options[sel.selectedIndex].text;
        setselected_lms_theme_text(text)

    }


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        GetLms_Themes();
        submenu_design();
        GetLms_Themes_Logo_Banniere();
    }, [])

    const [isLoading, setLoading] = useState();
    const [logo_file_name, setlogo_file_name] = useState();
    const hiddenFileInput_logo = React.useRef(null);

    const [bg_file_name, setbg_file_name] = useState();
    const hiddenFileInput_bg = React.useRef(null);


    const [handleSubmission_logo_api, sethandleSubmission_logo_api] = useState();
    const [handleSubmission_logo_result, sethandleSubmission_logo_result] = useState();
    const [handleSubmission_logo_message, sethandleSubmission_logo_message] = useState();


    const [img_logo_file, setimg_logo_file] = useState();
    const [img_bg_file, setimg_bg_file] = useState();

    const [img_logo_file2, setimg_logo_file2] = useState();
    const [img_bg_file2, setimg_bg_file2] = useState();

    const handleSubmission_logo = event => {
        clear_message();


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ", file_size, " file_type = ", file_type);
        //console.log(" fileUploaded = ", fileUploaded);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        //image/png
        if (String(file_type) !== "image/png") {
            alert("Le fichier n'est pas au format PNG");
            return;
        }

        setLoading(true);

        var _URL = window.URL || window.webkitURL;
        var file, img;
        var img_height, img_width;

        if ((file = event.target.files[0])) {
            img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                img_height = this.height;
                img_width = this.width;
                _URL.revokeObjectURL(objectUrl);
                console.log(" img_width = ", img_width, "  img_height = ", img_height)

                if (parseFloat(img_width) > 130 && parseFloat(img_width) < 260 &&
                    parseFloat(img_height) > 60 && parseFloat(img_height) < 150) {
                    //console.log(" img_height est = ", img_height, " < à 1500 ");

                    // Verification que le nom du fichier est alpha numerique
                    /*var regExp = /^[A-Za-z0-9]+$/;
                    if (!regExp.test(event.target.files[0].name)) {
                        sethandleSubmission_logo_message(" Le nom de l'image comporte des caractères non alphanumériques");
                        sethandleSubmission_logo_api("false");
                        setLoading(false);

                        return
                    }*/


                    if (String(event.target.files[0].name).length > 50) {
                        sethandleSubmission_logo_message(" Le nom du fichier est trop long. il doit faire moins de 50 caractère");
                        sethandleSubmission_logo_api("false");
                        setLoading(false);

                        return
                    }

                    setlogo_file_name(event.target.files[0].name);
                    setimg_logo_file(URL.createObjectURL(event.target.files[0]));
                    setimg_logo_file2(event.target.files[0]);

                } else {
                    sethandleSubmission_logo_message(" La taille de l'image est incorrecte.  Larg = " + String(img_width) + " px Long = " + String(img_height) + " px");
                    sethandleSubmission_logo_api("false");
                    setLoading(false);
                    return;
                }

                //console.log(" after img_width = ", img_width, "  after img_height = ", img_height)
            };
            img.src = objectUrl;

        }
        setLoading(false);
    };


    const [Send_logo_Img_api, setSend_logo_Img_api] = useState();
    const [Send_logo_Img_result, setSend_logo_Img_result] = useState();
    const [Send_logo_Img_message, setSend_logo_Img_message] = useState();
    function Send_logo_Img() {
        clear_message();


        setLoading(true);


        const formData = new FormData();
        formData.append('File', img_logo_file2);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Lms_Add_Logo_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);

                if (String(result['status']) === String("true")) {
                    setSend_logo_Img_api("true");
                    alert(" La mise à jour du logo a été correctement effectuée. ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setSend_logo_Img_api("false");
                    setSend_logo_Img_message(result['message']);
                }
                setLoading(false);

            })
            .catch((error) => {
                console.error('Error:', result);
                setSend_logo_Img_api("false");
                setSend_logo_Img_message(result['message']);
                setLoading(false);
            });

    }


    const [Delete_logo_Img_api, setDelete_logo_Img_api] = useState();
    const [Delete_logo_Img_result, setDelete_logo_Img_result] = useState();
    const [Delete_logo_Img_message, setDelete_logo_Img_message] = useState();
    function Delete_logo_Img() {
        clear_message();


        setLoading(true);


        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Lms_Delete_Logo_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);

                if (String(result['status']) === String("true")) {
                    setDelete_logo_Img_api("true");
                    setGetLms_Themes_Logo_Img('');
                    alert(" Le logo a été correctement supprimé ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setDelete_logo_Img_api("false");
                    setDelete_logo_Img_message(result['message']);
                }
                setLoading(false);

            })
            .catch((error) => {
                console.error('Error:', result);
                setDelete_logo_Img_api("false");
                setDelete_logo_Img_message(" Impossible de supprimer le fichier logo");
                setLoading(false);
            });

    }


    const [Delete_Banniere_Img_api, setDelete_Banniere_Img_api] = useState();
    const [Delete_Banniere_Img_result, setDelete_Banniere_Img_result] = useState();
    const [Delete_Banniere_Img_message, setDelete_Banniere_Img_message] = useState();
    function Delete_Banniere_Img() {
        clear_message();


        setLoading(true);


        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Lms_Delete_Banniere_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);

                if (String(result['status']) === String("true")) {
                    setDelete_Banniere_Img_api("true");
                    setGetLms_Themes_Banniere_Img('');
                    alert(" La bannière a été correctement supprimée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setDelete_Banniere_Img_api("false");
                    setDelete_Banniere_Img_message(result['message']);
                }
                setLoading(false);

            })
            .catch((error) => {
                console.error('Error:', result);
                setDelete_Banniere_Img_api("false");
                setDelete_Banniere_Img_message(" Impossible de supprimer la bannière");
                setLoading(false);
            });

    }

    const changeHandler2_logo = (event) => {
        clear_message();
        hiddenFileInput_logo.current.click();
    };



    const [handleSubmission_bg_api, sethandleSubmission_bg_api] = useState();
    const [handleSubmission_bg_result, sethandleSubmission_bg_result] = useState();
    const [handleSubmission_bg_message, sethandleSubmission_bg_message] = useState();

    const handleSubmission_bg = event => {
        clear_message();
        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        console.log("file_size =  ", file_size, " file_type = ", file_type, " naturalWidth = ", fileUploaded.naturalWidth);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        //image/png
        if (String(file_type) !== "image/png") {
            alert("Le fichier n'est pas au format PNG");
            return;
        }

        setLoading(true);

        var _URL = window.URL || window.webkitURL;
        var file, img;
        var img_height, img_width;

        if ((file = event.target.files[0])) {
            img = new Image();
            var objectUrl = _URL.createObjectURL(file);
            img.onload = function () {
                img_height = this.height;
                img_width = this.width;
                //console.log(" img_width =  ", img_width);
                _URL.revokeObjectURL(objectUrl);
                if (parseFloat(img_width) > 1500 && parseFloat(img_width) < 1930 &&
                    parseFloat(img_height) > 150 && parseFloat(img_height) < 195) {
                    //console.log(" img_height est = ", img_height, " < à 1500 ");

                    // Verification que le nom du fichier est alpha numerique
                    /*var regExp = /^[A-Za-z0-9]+$/;
                    if (!regExp.test(event.target.files[0].name)) {
                        sethandleSubmission_bg_message(" Le nom de l'image comporte des caractères non alphanumériques");
                        sethandleSubmission_bg_api("false");
                        setLoading(false);
                        return
                    }*/


                    if (String(event.target.files[0].name).length > 50) {
                        sethandleSubmission_bg_message(" Le nom du fichier est trop long. il doit faire moins de 50 caractère");
                        sethandleSubmission_bg_api("false");
                        setLoading(false);

                        return
                    }

                    setbg_file_name(event.target.files[0].name);

                    setimg_bg_file(URL.createObjectURL(event.target.files[0]));
                    setimg_bg_file2(event.target.files[0]);


                } else {
                    sethandleSubmission_bg_message(" La taille de l'image est incorrecte. Larg = " + String(img_width) + " px Long = " + String(img_height) + " px");
                    sethandleSubmission_bg_api("false");
                    setLoading(false);
                    return;

                }
            };
            img.src = objectUrl;

        }
        setLoading(false);

    };


    const [SendBackground_Img_api, setSendBackground_Img_api] = useState();
    const [SendBackground_Img_result, setSendBackground_Img_result] = useState();
    const [SendBackground_Img_message, setSendBackground_Img_message] = useState();

    function SendBackground_Img() {
        clear_message();

        setLoading(true);

        const formData = new FormData();
        formData.append('File', img_bg_file2);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Lms_Add_BG_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);

                if (String(result['status']) === String("true")) {
                    setSendBackground_Img_api("true");
                    alert(" L'image bannière a été correctement mise à jour")

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setSendBackground_Img_api("false");
                    setSendBackground_Img_message(result['message']);
                }
                setLoading(false);

            })
            .catch((error) => {
                console.error('Error:', result);
                setSendBackground_Img_api("false");
                setSendBackground_Img_message(result['message']);
                setLoading(false);
            });


    };

    const changeHandler2_bg = (event) => {
        clear_message();
        hiddenFileInput_bg.current.click();
    };


    function clear_message() {
        sethandleSubmission_logo_message();
        sethandleSubmission_logo_api();
        setSendBackground_Img_api();
        setSendBackground_Img_message();
    }

    const [submenu, setsubmenu] = useState("design");
    function submenu_design() {
        clear_message();
        setsubmenu("design");
    }

    function submenu_logo() {
        clear_message();
        setsubmenu("logo");
    }

    function submenu_img_bg() {
        clear_message();
        setsubmenu("img_bg");
    }


    function Annule_bg_img() {
        clear_message();
        setimg_bg_file();
        setimg_bg_file2();
        sethandleSubmission_bg_message();
        setSendBackground_Img_api();
        setSendBackground_Img_message();
        setbg_file_name();
    }

    function Annule_logo_img() {
        clear_message();
        setimg_logo_file();
        setimg_logo_file2();
        sethandleSubmission_logo_message();
        setSend_logo_Img_api();
        setSend_logo_Img_message();
        setlogo_file_name();
    }

    return (
        <div className="hebergementlms">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            {String(menu) === String("default") && <div>
                <h2 style={{ "textAlign": "center", "marginBottom": "2rem" }} ref={scrollDiv}> Parametrage de l'environnement E-Learning</h2>

                <br />
            </div>}



            <div style={{ "textAlign": "left", "float": "left", "width": "100%", "marginBottom": "1rem" }} id="participant_menu_tab">
                <Button variant="outlined" onClick={submenu_design} className="detail_class_submenu" id='menu_preinscrit' name='menu_preinscrit'>Thème & Design</Button>
                <Button variant="outlined" onClick={submenu_img_bg} className="detail_class_submenu" id='menu_inscrit' name='menu_inscrit'>Bannière</Button>
                <Button variant="outlined" onClick={submenu_logo} className="detail_class_submenu" id='menu_preinscrit' name='menu_preinscrit'>Logo</Button>

            </div>

            {String(submenu) === String("design") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>
                <div className="div_row">
                    {String(RecordData_api) === "true" && <div className="okUpdateData"> la mise à jour a été correctement faite</div>}

                    {String(RecordData_api) === "false" && <div className="koUpdateData"> {RecordData_message}</div>}

                    <div className="session_caract">Choisir un exemple de thème : <br />

                        <select id="lms_theme_liste" name="lms_theme_liste" value={selected_lms_theme} onChange={handleChangeselected_lms_theme} className="selectsession">
                            <option value="">Choisir un modèle </option>
                            {GetLms_Themes_result &&
                                GetLms_Themes_result.map((lms_theme) => (
                                    <option value={JSON.parse(lms_theme).template_preview_url}>
                                        {JSON.parse(lms_theme).nom_template}

                                    </option>
                                ))}
                        </select>

                    </div>
                    {/*
                 <div className="session_caract">Le modèle d'attestation :
                        <img className="preview_certif" src={urlpreview_lms_theme} />
                                </div>*/}

                    <div className="div_row">
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                <Button variant="contained" className="btn_modif" onClick={"One_annuleSessionUpdate"}>Annuler
                                </Button>
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                <Button variant="contained" className="btn_enreg" onClick={RecordData}>Enregistrer
                                </Button>


                            </div>

                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                    </div>
                </div>
            </div>}

            {String(submenu) === String("logo") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>

                {GetLms_Themes_Logo_Img && String(GetLms_Themes_Logo_Img) !== "" && <div className="div_row" style={{ "border": "None" }}>
                    Logo actuel :<br />
                    <img src={GetLms_Themes_Logo_Img} alt={""} />
                    <br />
                    <Button variant="contained" className="btn_enreg" onClick={Delete_logo_Img}>Supprimer Logo
                    </Button>

                </div>}
                <div className="div_row"> Import du logo <br />
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    <Button variant="outlined" onClick={changeHandler2_logo} className="detail_class_submenu" style={{
                        "background": "#c8cfd5",
                        "minWidth": "15rem", "color": "black", "width": "auto"
                    }}
                        id='menu_import_formation' name='menu_import_formation'>Choisir une image  &nbsp; <br />

                    </Button><br />
                    <nav className="koUpdateData" style={{ "color": "orange" }}>
                        <Tooltip className="tooltip_css" id="tooltip_2" />

                        <a data-tooltip-id="tooltip_2" data-tooltip-html="Respecter ces critères vous aide à avoir un meilleur affichage">
                            <i> Important : Taille du logo : longueur  entre  140px et 250px  et largeur entre 60px et 150px, au format PNG <br />
                                Le nom du fichier ne contient que des caractères alphanumériques </i>
                        </a>
                    </nav>
                    {logo_file_name && <nav className="okUpdateData"><i>{logo_file_name}</i></nav>}
                    <input type="file"
                        accept=".png"
                        ref={hiddenFileInput_logo}
                        style={{ display: 'none' }}
                        name="liste_formation_file"
                        onChange={handleSubmission_logo}
                    />

                    {img_logo_file && <nav> Fichier à importer : {img_logo_file.name}  <br />
                        <img src={img_logo_file} alt={""} />


                        <br />
                    </nav>}

                    {String(Send_logo_Img_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
                    {String(Send_logo_Img_api) === "false" && <div className="koUpdateData"> {Send_logo_Img_message}</div>}

                    {String(handleSubmission_logo_api) === "false" && <div className="koUpdateData"> {handleSubmission_logo_message}</div>}



                    <br />

                    <div className="div_row">
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                <Button variant="contained" className="btn_modif" onClick={Annule_logo_img}>Annuler
                                </Button>
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                <Button variant="contained" className="btn_enreg" onClick={Send_logo_Img}>Enregistrer
                                </Button>



                            </div>

                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                    </div>
                </div>
            </div>}

            {String(submenu) === String("img_bg") && <div className="detail_class_submenu_data" style={{ "border": "None" }}>

                {GetLms_Themes_Banniere_Img && String(GetLms_Themes_Banniere_Img) !== "" && <div className="div_row" style={{ "border": "None" }}>
                    Bannière actuelle : <br />
                    <img src={GetLms_Themes_Banniere_Img} alt={""} className="img_bg_file_css" />
                    <br />
                    <Button variant="contained" className="btn_enreg" onClick={Delete_Banniere_Img  }>Supprimer Bannière
                    </Button>
                </div>}
                <div className="div_row"> Import Bannière <br />

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    <Button variant="outlined" onClick={changeHandler2_bg} className="detail_class_submenu" style={{
                        "background": "#c8cfd5",
                        "minWidth": "15rem", "color": "black", "width": "auto"
                    }}
                        id='menu_import_formation' name='menu_import_formation'>Choisir une image bannière &nbsp; <br />

                    </Button><br />
                    <nav className="koUpdateData" style={{ "color": "orange" }}>
                        <Tooltip className="tooltip_css" id="tooltip_1" />

                        <a data-tooltip-id="tooltip_1" data-tooltip-html="Respecter ces critères vous aide à avoir un meilleur affichage">
                            <i> Important : Taille de la bannière : longueur entre  1500px et 1930px  et largeur entre 150px et 195px, au format PNG <br />
                                Le nom du fichier ne contient que des caractères alphanumériques </i>
                        </a>
                    </nav>

                    {bg_file_name && <nav className="okUpdateData"><i>{bg_file_name}</i></nav>}
                    <input type="file"
                        accept=".png"
                        ref={hiddenFileInput_bg}
                        style={{ display: 'none' }}
                        name="liste_formation_file"
                        onChange={handleSubmission_bg}
                    />

                    {img_bg_file && <nav> Fichier à importer : {img_bg_file.name}  <br />
                        <img src={img_bg_file} alt={""} className="img_bg_file_css" />

                        <br />
                    </nav>}


                    {String(SendBackground_Img_api) === "true" && <div className="okUpdateData"> La mise à jour a été correctement faite</div>}
                    {String(SendBackground_Img_api) === "false" && <div className="koUpdateData"> {SendBackground_Img_message}</div>}

                    {String(handleSubmission_bg_api) === "false" && <div className="koUpdateData"> {handleSubmission_bg_message}</div>}

                    <br />

                    <div className="div_row">
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche " style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                <Button variant="contained" className="btn_modif" onClick={Annule_bg_img}>Annuler
                                </Button>
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                <Button variant="contained" className="btn_enreg" onClick={SendBackground_Img}>Enregistrer
                                </Button>


                            </div>

                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>

                    </div>
                </div>
            </div>}

        </div>);

}

export default HebergementLms;