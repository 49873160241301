import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import { FcPodiumWithAudience } from "react-icons/fc";
import { FcInfo } from "react-icons/fc";
import { LiaDharmachakraSolid } from "react-icons/lia";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcCheckmark } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import Autocomplete from '@mui/material/Autocomplete';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";

import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import Rating from '@mui/material/Rating';
import Module_Selection_Evaluation_Apprenants from "./Module_Selection_Evaluation_Apprenants";
import AttachFileIcon from '@mui/icons-material/AttachFile';

const Notes_Evaluation = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [isLoading, setLoading] = useState();

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);


    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState("");

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState("");


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    function ExpandableCell_50_bg_hiligt({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{
                    paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all", color: '#0000FF',
                    "textAlign": "center"
                }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all", color: '#0000FF' }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    const columns_evalutions = [
        { field: '_id', headerName: '_id', hide: true, Width: 0 },
        { field: 'id', headerName: 'id', hide: true, Width: 0 },

        { field: 'code', headerName: 'code', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'titre', headerName: 'titre', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_id', headerName: 'class_id', hide: true, Width: 0 },

        {
            field: 'class_id_code', headerName: 'Ftion Code', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 && <nav>
                            {New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(cellValues.row.class_id))[0].label}
                        </nav>}


                    </div>

                );
            }
        },

        { field: 'session_id', headerName: 'session_id', hide: true, Width: 0 },
        {
            field: 'session_code', headerName: 'Classe', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 && <nav>
                            {New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(cellValues.row.session_id))[0].label}
                        </nav>}


                    </div>

                );
            }
        },

        { field: 'class_eu_id', headerName: 'class_eu_id', hide: true, Width: 0 },
        {
            field: 'class_ue_code', headerName: 'Code UE', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Partner_List_UE_result && New_Getall_Partner_List_UE_result.length > 0 && <nav>
                            {New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(cellValues.row.class_eu_id))[0].label}
                        </nav>}


                    </div>

                );
            }
        },


        { field: 'type_eval_id', headerName: 'type_eval_id', hide: true, Width: 0 },
        {
            field: 'type_eval_code', headerName: 'Type Eval', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Partner_Type_Evalution_List_result && New_Getall_Partner_Type_Evalution_List_result.length > 0 && <nav>
                            {New_Getall_Partner_Type_Evalution_List_result.filter((data) => (data)._id === String(cellValues.row.type_eval_id))[0].label}
                        </nav>}


                    </div>

                );
            }
        },

        { field: 'statut', headerName: 'statut', minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        { field: 'eval_date_heure_debut', headerName: 'Début', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'eval_date_heure_fin', headerName: 'Fin', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        {
            field: "Detail", headerName: 'Voir detail',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            handleClick_edit_evaluation_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button


                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'évaluation sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_evaluation(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]

    function handleClick_delete_evaluation(event, cellValues) {
        Delete_Given_Evaluation(cellValues.row._id);
    }

    const columns_sequence_ressources_affectation = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true, minWidth: 200, },
        { field: 'related_target_collection_id', headerName: 'related_target_collection_id', minWidth: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'related_target_collection', headerName: 'related_target_collection', minWidth: 150, hide: true, editable: false, resizable: true },
        { field: 'type_ressource', headerName: 'Type', minWidth: 150, hide: true, editable: false },
        { field: 'related_target_collection_object', headerName: 'object', minWidth: 200, hide: false, editable: false, resizable: true, flex: 1, },
        { field: 'related_target_collection_id_nom', headerName: 'nom', minWidth: 200, hide: false, editable: false, resizable: true, flex: 1, },
        { field: 'poste', headerName: 'Poste', minWidth: 200, hide: false, editable: false, flex: 1, },
        { field: 'comment', headerName: 'Comment', width: 200, hide: false, editable: false },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ressource sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {

                                            Delete_Sequence_Session_Ressource_Data(cellValues.row._id);
                                            console.log('cellValues.row =  ', cellValues.row);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]



    const [Delete_Given_Evaluation_api, setDelete_Given_Evaluation_api] = useState();
    const [Delete_Given_Evaluation_message, setDelete_Given_Evaluation_message] = useState();
    const [Delete_Given_Evaluation_result, setDelete_Given_Evaluation_result] = useState();
    function Delete_Given_Evaluation(evaluation_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("evaluation_id", evaluation_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Evaluation_Planification/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Given_Evaluation  res.data.status = " + res.data.status);
            //console.log(" In Delete_Given_Evaluation  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Given_Evaluation_api("true");
                setDelete_Given_Evaluation_result(res.data.message);

                Getall_Partner_Evaluation();

                setdisplay_evaluation("");
                setevaluation_data_changed("");
                setevaluation_data_edit_mode("");
                setgridline_id("");
                alert(res.data.message);

            }
            else {
                setDelete_Given_Evaluation_api("false");
                setDelete_Given_Evaluation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('Delete_Given_Evaluation : Not good man :( mysearchtext = ' + error);
            setDelete_Given_Evaluation_api("false");
            alert(" Impossible de supprimer l'évaluation ");
        })
    }


    const columns_evalutions_participant = [
        { field: '_id', headerName: '_id', hide: true, Width: 0 },
        { field: 'id', headerName: 'id', hide: true, Width: 0 },
        { field: 'note_changed', headerName: 'note_changed', hide: true, Width: 0 },

        { field: 'nom', headerName: 'nom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'groupe', headerName: 'groupe', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'note', headerName: 'Note', width: 200, editable: true, renderCell: (params) => <ExpandableCell_50_bg_hiligt {...params} />, align: "center", },

        { field: 'convocation_send_date', headerName: 'Conv. Envoyé', width: 200, editable: true, renderCell: (params) => <ExpandableCell_50_bg_hiligt {...params} />, align: "center", },
        { field: 'convocation_send_type', headerName: 'Conv. Par', width: 200, editable: true, renderCell: (params) => <ExpandableCell_50_bg_hiligt {...params} />, align: "center", },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscrit sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            var tab = []
                                            tab.push(String(cellValues.row._id));
                                            Delete_List_Participant_From_Evaluation(tab);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]

    const [Delete_List_Participant_From_Evaluation_api, setDelete_List_Participant_From_Evaluation_api] = useState();
    const [Delete_List_Participant_From_Evaluation_message, setDelete_List_Participant_From_Evaluation_message] = useState();
    const [Delete_List_Participant_From_Evaluation_result, setDelete_List_Participant_From_Evaluation_result] = useState();
    function Delete_List_Participant_From_Evaluation(local_tab_ids) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("evaluation_id", selected_id);
        form.append("tab_inscription_id", local_tab_ids);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Participant_From_Evaluation/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_List_Participant_From_Evaluation  res.data.status = " + res.data.status);
            //console.log(" In Delete_List_Participant_From_Evaluation  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_List_Participant_From_Evaluation_api("true");
                setDelete_List_Participant_From_Evaluation_result(res.data.message);

                setnoted_changed("");

                setselectionModel_evalutions_participant([]);
                settab_convention_pieces_jointes_result([]);
                Getall_Evaluation_Participant_List(selected_id);

                alert(res.data.message)
            }
            else {
                setDelete_List_Participant_From_Evaluation_api("false");
                setDelete_List_Participant_From_Evaluation_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_List_Participant_From_Evaluation : Not good man :( mysearchtext = ' + error);
            setDelete_List_Participant_From_Evaluation_api("false");
            alert(" Impossible de supprimer les données");
        })
    }


    const [selectionModel_evalutions, setselectionModel_evalutions] = React.useState([]);
    const [selectionModel_evalutions_participant, setselectionModel_evalutions_participant] = React.useState([]);

    const [selected_id, setselected_id] = React.useState("");
    const [display_evaluation, setdisplay_evaluation] = React.useState("");
    const [evaluation_data_changed, setevaluation_data_changed] = React.useState("");
    const [evaluation_data_edit_mode, setevaluation_data_edit_mode] = useState("");

    const [add_one_evaluation, setadd_one_evaluation] = React.useState("");

    function handleClick_edit_evaluation_From_Line(selected_row_id) {

        var line = JSON.parse(Getall_Partner_Evaluation_result[selected_row_id]);

        setselected_id(line._id);
        setgridline_id(selected_row_id);

        Get_Given_Evaluation_Data(line._id);

        Get_List_UE_From_Class_Id(line.class_id);


        setdisplay_evaluation("1");
        setadd_one_evaluation("");
        setevaluation_data_changed("");
        setevaluation_data_edit_mode("");
        setsubmenu("detail_evaluation");

        //Getall_Evaluation_Participant_List();


    }


    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Given_Evaluation_Data(evaluation_id) {
        setevaluation_data_changed("");
        setevaluation_data_edit_mode("");


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("evaluation_id", evaluation_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Evaluation_Planification/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In Get_Given_Evaluation_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Evaluation_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_evaluation = JSON.parse(res.data.message);

                    if (mylocal_evaluation.code)
                        setp_detail_code(mylocal_evaluation.code);
                    else
                        setp_detail_code("");

                    if (mylocal_evaluation.titre) {
                        setp_detail_titre(mylocal_evaluation.titre);
                    } else {
                        setp_detail_titre("");
                    }


                    if (mylocal_evaluation.description) {
                        setp_detail_description(mylocal_evaluation.description);
                    } else {
                        setp_detail_description("");
                    }

                    if (mylocal_evaluation.comment) {
                        setp_detail_comment(mylocal_evaluation.comment);
                    } else {
                        setp_detail_comment("");
                    }


                    if (mylocal_evaluation.class_id) {
                        setp_detail_class_id(mylocal_evaluation.class_id);
                    } else {
                        setp_detail_class_id("");
                    }

                    if (mylocal_evaluation.class_internal_url) {
                        setp_detail_class_internal_url(mylocal_evaluation.class_internal_url);
                    } else {
                        setp_detail_class_internal_url("");
                    }


                    if (mylocal_evaluation.class_eu_id) {
                        setp_detail_class_eu_id(mylocal_evaluation.class_eu_id);
                    } else {
                        setp_detail_class_eu_id("");
                    }


                    if (mylocal_evaluation.type_eval_id) {
                        setp_detail_type_eval_id(mylocal_evaluation.type_eval_id);
                    } else {
                        setp_detail_type_eval_id("");
                    }


                    if (mylocal_evaluation.eval_date_heure_debut) {
                        setp_detail_eval_date_heure_debut(mylocal_evaluation.eval_date_heure_debut);
                    }

                    if (mylocal_evaluation.eval_date_heure_fin)
                        setp_detail_eval_date_heure_fin(mylocal_evaluation.eval_date_heure_fin);


                    if (mylocal_evaluation.statut) {
                        setp_detail_statut(mylocal_evaluation.statut);
                    } else {
                        setp_detail_statut("");
                    }

                    if (mylocal_evaluation.adress) {
                        setp_detail_adress(mylocal_evaluation.adress);
                    } else {
                        setp_detail_adress("");
                    }


                    if (mylocal_evaluation.cp) {
                        setp_detail_cp(mylocal_evaluation.cp);
                    } else {
                        setp_detail_cp("");
                    }

                    if (mylocal_evaluation.ville) {
                        setp_detail_ville(mylocal_evaluation.ville);
                    } else {
                        setp_detail_ville("");
                    }


                    if (mylocal_evaluation.pays) {
                        setp_detail_pays(mylocal_evaluation.pays);
                    } else {
                        setp_detail_pays("");
                    }


                    if (mylocal_evaluation.responsable_id) {
                        setp_detail_responsable_id(mylocal_evaluation.responsable_id);
                    } else {
                        setp_detail_responsable_id("");
                    }

                    if (mylocal_evaluation.session_id) {
                        setp_detail_session_id(mylocal_evaluation.session_id);
                    } else {
                        setp_detail_session_id("");
                    }


                    if (document.getElementById('myRef')) {
                        var divh = document.getElementById('myRef').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }

                    Disable_Evaluation_DetailFields();
                } else {
                    alert(" Aucune donnée recuperée");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Evaluation_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données de l'évaluation");
            //setmyApimyApiMessage("")
        })
    }


    const New_Option_Filter = [
        { "id": "class_external_code", "label": "Lié a la Formation (code externe) ", "value": "class_external_code" },
        { "id": "code_session", "label": "Lié a la Classe (code Classe) ", "value": "code_session" },
        { "id": "code_ue", "label": "Lié a l'UE (code UE) ", "value": "code_ue" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]


    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState([]);
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "ismanager": ""
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Training_Materiel_No_Filter_result, setNew_Getall_Training_Materiel_No_Filter_result] = useState([]);

    const [Getall_Training_Materiel_No_Filter_api, setGetall_Training_Materiel_No_Filter_api] = useState();
    const [Getall_Training_Materiel_No_Filter_message, setGetall_Training_Materiel_No_Filter_message] = useState();
    const [Getall_Training_Materiel_No_Filter_result, setGetall_Training_Materiel_No_Filter_result] = useState();
    function Getall_Training_Materiel_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_No_Filter_api("true");
                setGetall_Training_Materiel_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_ref_interne = JSON.parse(x).ref_interne;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "ref_interne": local_ref_interne,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Materiel_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Materiel_No_Filter_api("false");
                setGetall_Training_Materiel_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Training_Materiel_No_Filter = ', error);
            setGetall_Training_Materiel_No_Filter_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Evaluation_Participant_List_result, setNew_Getall_Evaluation_Participant_List_result] = useState([]);

    const [Getall_Evaluation_Participant_List_api, setGetall_Evaluation_Participant_List_api] = useState();
    const [Getall_Evaluation_Participant_List_message, setGetall_Evaluation_Participant_List_message] = useState();
    const [Getall_Evaluation_Participant_List_result, setGetall_Evaluation_Participant_List_result] = useState([]);
    function Getall_Evaluation_Participant_List(local_evalutaion_id) {

        var evalutaion_id = "";

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("evaluation_id", local_evalutaion_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Participant_To_Evaluation/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Evaluation_Participant_List  res.data.status = " + res.data.status);
                //console.log(" In Getall_Evaluation_Participant_List  res.data.message r_class = " + res.data.message);
                setGetall_Evaluation_Participant_List_api("true");
                setGetall_Evaluation_Participant_List_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_email = JSON.parse(x).email;
                    var local_note = JSON.parse(x).note;
                    var local_inscription_id = JSON.parse(x).inscription_id;
                    var local_group_inscription_id = JSON.parse(x).group_inscription_id;

                    var local_convocation_send_date = JSON.parse(x).convocation_send_date;
                    var local_convocation_send_type = JSON.parse(x).convocation_send_type;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "email": local_email,
                        "note": local_note,
                        "note_changed": "0",
                        "inscription_id": local_inscription_id,
                        "group_inscription_id": local_group_inscription_id,
                        "convocation_send_date": String(local_convocation_send_date).substring(0, 10),
                        "convocation_send_type": String(local_convocation_send_type).substring(0, 10),

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "email": "",
                    "note": "",
                    "note_changed": "0",
                    "inscription_id": "",
                    "group_inscription_id": "",
                    "convocation_send_date": "",
                    "convocation_send_type": "",
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Evaluation_Participant_List_result(new_data2);

            }
            else {
                setGetall_Evaluation_Participant_List_api("false");
                setGetall_Evaluation_Participant_List_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Evaluation_Participant_List = ', error);
            setGetall_Evaluation_Participant_List_api("false");
            alert(" Impossible de recuperer la liste des inscrits à l'évaluation");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Partner_Evaluation_result, setNew_Getall_Partner_Evaluation_result] = useState([]);

    const [Getall_Partner_Evaluation_api, setGetall_Partner_Evaluation_api] = useState();
    const [Getall_Partner_Evaluation_message, setGetall_Partner_Evaluation_message] = useState();
    const [Getall_Partner_Evaluation_result, setGetall_Partner_Evaluation_result] = useState([]);
    function Getall_Partner_Evaluation(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Evaluation_Planification_No_Filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Evaluation  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Evaluation  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Evaluation_api("true");
                setGetall_Partner_Evaluation_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_eu_id = JSON.parse(x).class_eu_id;
                    var local_type_eval_id = JSON.parse(x).type_eval_id;
                    var local_eval_date_heure_debut = JSON.parse(x).eval_date_heure_debut;
                    var local_eval_date_heure_fin = JSON.parse(x).eval_date_heure_fin;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "class_id": local_class_id,
                        "class_eu_id": local_class_eu_id,
                        "type_eval_id": local_type_eval_id,
                        "eval_date_heure_debut": local_eval_date_heure_debut,
                        "eval_date_heure_fin": local_eval_date_heure_fin,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "class_id": "",
                    "class_eu_id": "",
                    "type_eval_id": "",
                    "eval_date_heure_debut": "",
                    "eval_date_heure_fin": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Evaluation_result(new_data2);

            }
            else {
                setGetall_Partner_Evaluation_api("false");
                setGetall_Partner_Evaluation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Evaluation = ', error);
            setGetall_Partner_Evaluation_api("false");
            alert(" Impossible de recuperer la liste des évaluations");
            //setmyApimyApiMessage("")
        })
    }

    const [gridline_id, setgridline_id] = useState("");


    const [gridline_inscrit_id, setgridline_inscrit_id] = useState("");


    const [New_Getall_Partner_Class_Reduice_Fields_result, setNew_Getall_Partner_Class_Reduice_Fields_result] = useState([]);

    const [Getall_Partner_Class_Reduice_Fields_api, setGetall_Partner_Class_Reduice_Fields_api] = useState();
    const [Getall_Partner_Class_Reduice_Fields_message, setGetall_Partner_Class_Reduice_Fields_message] = useState();
    const [Getall_Partner_Class_Reduice_Fields_result, setGetall_Partner_Class_Reduice_Fields_result] = useState();
    function Getall_Partner_Class_Reduice_Fields(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_All_Class_Few_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Class_Reduice_Fields  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Class_Reduice_Fields  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Class_Reduice_Fields_api("true");
                setGetall_Partner_Class_Reduice_Fields_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_external_code = JSON.parse(x).external_code;
                    var local_internal_code = JSON.parse(x).internal_code;
                    var local_internal_url = JSON.parse(x).internal_url;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_external_code,
                        "external_code": local_external_code,
                        "internal_code": local_internal_code,
                        "internal_url": local_internal_url
                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "external_code": "",
                    "internal_code": "",
                    "internal_url": ""
                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Class_Reduice_Fields_result(new_data2);

            }
            else {
                setGetall_Partner_Class_Reduice_Fields_api("false");
                setGetall_Partner_Class_Reduice_Fields_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Class_Reduice_Fields = ', error);
            setGetall_Partner_Class_Reduice_Fields_api("false");
            alert(" Impossible de recuperer la liste des formations");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Getall_Partner_Session_Reduice_Fields_result, setNew_Getall_Partner_Session_Reduice_Fields_result] = useState([]);

    const [Getall_Partner_Session_Reduice_Fields_api, setGetall_Partner_Session_Reduice_Fields_api] = useState();
    const [Getall_Partner_Session_Reduice_Fields_message, setGetall_Partner_Session_Reduice_Fields_message] = useState();
    const [Getall_Partner_Session_Reduice_Fields_result, setGetall_Partner_Session_Reduice_Fields_result] = useState();
    function Getall_Partner_Session_Reduice_Fields(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Session_Ftion_Reduice_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Session_Reduice_Fields  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Session_Reduice_Fields  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Session_Reduice_Fields_api("true");
                setGetall_Partner_Session_Reduice_Fields_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code_session = JSON.parse(x).code_session;
                    var local_titre = JSON.parse(x).titre;
                    var local_class_internal_url = JSON.parse(x).class_internal_url;

                    var local_date_debut = JSON.parse(x).date_debut;
                    var local_date_fin = JSON.parse(x).date_fin;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_session,
                        "code_session": local_code_session,
                        "titre": local_titre,
                        "class_internal_url": local_class_internal_url,
                        "date_debut": local_date_debut,
                        "date_fin": local_date_fin,
                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code_session": "",
                    "titre": "",
                    "class_internal_url": "",
                    "date_debut": "",
                    "date_fin": "",
                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Session_Reduice_Fields_result(new_data2);

            }
            else {
                setGetall_Partner_Session_Reduice_Fields_api("false");
                setGetall_Partner_Session_Reduice_Fields_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Session_Reduice_Fields = ', error);
            setGetall_Partner_Session_Reduice_Fields_api("false");
            alert(" Impossible de recuperer la liste des sessions de formation (classe)");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Getall_Partner_List_UE_result, setNew_Getall_Partner_List_UE_result] = useState([]);

    const [Getall_Partner_List_UE_api, setGetall_Partner_List_UE_api] = useState();
    const [Getall_Partner_List_UE_message, setGetall_Partner_List_UE_message] = useState();
    const [Getall_Partner_List_UE_result, setGetall_Partner_List_UE_result] = useState();
    function Getall_Partner_List_UE(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_List_UE  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_List_UE  res.data.message r_class = " + res.data.message);
                setGetall_Partner_List_UE_api("true");
                setGetall_Partner_List_UE_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "credit": "",
                    "pres_dist_hyp": "",
                    "is_noted": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_List_UE_result(new_data2);
            }
            else {
                setGetall_Partner_List_UE_api("false");
                setGetall_Partner_List_UE_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Partner_List_UE = ', error);
            setGetall_Partner_List_UE_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Get_List_UE_From_Class_Id_result, setNew_Get_List_UE_From_Class_Id_result] = useState([]);

    const [Get_List_UE_From_Class_Id_api, setGet_List_UE_From_Class_Id_api] = useState();
    const [Get_List_UE_From_Class_Id_message, setGet_List_UE_From_Class_Id_message] = useState();
    const [Get_List_UE_From_Class_Id_result, setGet_List_UE_From_Class_Id_result] = useState();
    function Get_List_UE_From_Class_Id(local_class_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Of_Given_Class/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_UE_From_Class_Id  res.data.status = " + res.data.status);
                //console.log(" In Get_List_UE_From_Class_Id  res.data.message r_class = " + res.data.message);
                setGet_List_UE_From_Class_Id_api("true");
                setGet_List_UE_From_Class_Id_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;

                    var local_class_id = JSON.parse(x).class_id;
                    var local_internal_url = JSON.parse(x).internal_url;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "class_id": local_class_id,
                        "internal_url": local_internal_url,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "class_id": "",
                    "internal_url": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Get_List_UE_From_Class_Id_result(new_data2);
            }
            else {
                setGet_List_UE_From_Class_Id_api("false");
                setGet_List_UE_From_Class_Id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_List_UE_From_Class_Id = ', error);
            setGet_List_UE_From_Class_Id_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Type_Evalution_List_result, setNew_Getall_Partner_Type_Evalution_List_result] = useState([]);

    const [Getall_Partner_Type_Evalution_List_api, setGetall_Partner_Type_Evalution_List_api] = useState();
    const [Getall_Partner_Type_Evalution_List_message, setGetall_Partner_Type_Evalution_List_message] = useState();
    const [Getall_Partner_Type_Evalution_List_result, setGetall_Partner_Type_Evalution_List_result] = useState([]);
    function Getall_Partner_Type_Evalution_List(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Type_Evaluation/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Type_Evalution_List  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Type_Evalution_List  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Type_Evalution_List_api("true");
                setGetall_Partner_Type_Evalution_List_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "nom": local_nom,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "nom": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Type_Evalution_List_result(new_data2);
            }
            else {
                setGetall_Partner_Type_Evalution_List_api("false");
                setGetall_Partner_Type_Evalution_List_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Type_Evalution_List = ', error);
            setGetall_Partner_Type_Evalution_List_api("false");
            alert(" Impossible de recuperer la liste des types d'evaluation");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Getall_Partner_Class_Reduice_Fields();
        Getall_Partner_Session_Reduice_Fields();
        Getall_Partner_List_UE();
        Getall_Partner_Type_Evalution_List();
        Getall_Training_Employee_No_Filter();
        Getall_Training_Materiel_No_Filter();

        Getall_Partner_Evaluation();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc("EVALUATION_CONVOCATION");

    }, [])

    const myRef_head = useRef(null);
    function clean_all_filters() {
        setgridline_id('');
        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");

        Getall_Partner_Evaluation();
    }

    const [Getall_Training_Employee_With_Filter_api, setGetall_Training_Employee_With_Filter_api] = useState();
    const [Getall_Training_Employee_With_Filter_message, setGetall_Training_Employee_With_Filter_message] = useState();
    const [Getall_Training_Employee_With_Filter_result, setGetall_Training_Employee_With_Filter_result] = useState();
    function Getall_Training_Employee_With_Filter(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Evaluation_Planification_With_Filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_With_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_With_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_With_Filter_api("true");
                setGetall_Training_Employee_With_Filter_result(res.data.message);

                setGetall_Partner_Evaluation_result(res.data.message);



                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_eu_id = JSON.parse(x).class_eu_id;
                    var local_type_eval_id = JSON.parse(x).type_eval_id;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "class_id": local_class_id,
                        "local_class_eu_id": local_class_eu_id,
                        "local_type_eval_id": local_type_eval_id,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "class_id": "",
                    "local_class_eu_id": "",
                    "local_type_eval_id": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Evaluation_result(new_data2);

            }
            else {
                setGetall_Training_Employee_With_Filter_api("false");
                setGetall_Training_Employee_With_Filter_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_With_Filter = ', error);
            setGetall_Training_Employee_With_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Add_Update_Evaluation_Data_api, setAdd_Update_Evaluation_Data_api] = useState();
    const [Add_Update_Evaluation_Data_message, setAdd_Update_Evaluation_Data_message] = useState();
    const [Add_Update_Evaluation_Data_result, setAdd_Update_Evaluation_Data_result] = useState();
    function Add_Update_Evaluation_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("code", p_detail_code);
        form.append("titre", p_detail_titre);
        form.append("description", p_detail_description);
        form.append("comment", p_detail_comment);
        form.append("class_id", p_detail_class_id);
        form.append("class_eu_id", p_detail_class_eu_id);
        form.append("type_eval_id", p_detail_type_eval_id);


        var new_p_detail_eval_date_heure_debut = "";
        if (typeof p_detail_eval_date_heure_debut == "object") {
            var act_deadline = format(p_detail_eval_date_heure_debut, 'dd/MM/yyyy HH:mm');
            new_p_detail_eval_date_heure_debut = act_deadline;
        } else {
            new_p_detail_eval_date_heure_debut = p_detail_eval_date_heure_debut;
        }
        form.append("eval_date_heure_debut", new_p_detail_eval_date_heure_debut);



        var new_p_detail_eval_date_heure_fin = "";
        if (typeof p_detail_eval_date_heure_fin == "object") {
            var act_deadline = format(p_detail_eval_date_heure_fin, 'dd/MM/yyyy HH:mm');
            new_p_detail_eval_date_heure_fin = act_deadline;
        } else {
            new_p_detail_eval_date_heure_fin = p_detail_eval_date_heure_fin;
        }
        form.append("eval_date_heure_fin", new_p_detail_eval_date_heure_fin);

        form.append("statut", p_detail_statut);
        form.append("adress", p_detail_adress);
        form.append("cp", p_detail_cp);
        form.append("ville", p_detail_ville);

        form.append("pays", p_detail_pays);
        form.append("responsable_id", p_detail_responsable_id);
        form.append("session_id", p_detail_session_id);

        var myurl = "";

        if (selected_id && String(selected_id).length > 5) {
            form.append("evaluation_id", selected_id);
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Evaluation_Planification/";
        } else {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Evaluation_Planification/";
        }



        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_Evaluation_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Evaluation_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setAdd_Update_Evaluation_Data_api("true");
                setAdd_Update_Evaluation_Data_result(res.data.message);

                Disable_Evaluation_DetailFields();
                setevaluation_data_changed("");
                setevaluation_data_edit_mode("");


                if (selected_id && String(selected_id).length > 5) {
                    Disable_Evaluation_DetailFields();
                    setevaluation_data_changed("");
                    setevaluation_data_edit_mode("");
                    setdisplay_evaluation('1');

                } else {
                    Disable_Evaluation_DetailFields();
                    setevaluation_data_changed("");
                    setevaluation_data_edit_mode("");
                    setdisplay_evaluation("");
                    setadd_one_evaluation("");
                    setsubmenu("");
                }

                Getall_Partner_Evaluation();


                alert(res.data.message);

                if (document.getElementById('myRef')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Update_Evaluation_Data_api("false");
                setAdd_Update_Evaluation_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.log('UpdateStagiaireData : Not good man :( Add_Update_Evaluation_Data = ' + error);
            setAdd_Update_Evaluation_Data_api("false");
            alert(" Impossible d'ajouter ou  mettre à jour l'évaluation");

        })
    }

    function Annule_Evaluaton_DetailFields() {
        Get_Given_Evaluation_Data(selected_id);

        setevaluation_data_changed("");
        setevaluation_data_edit_mode("");

    }


    const [p_detail_code, setp_detail_code] = React.useState("");
    const [p_detail_titre, setp_detail_titre] = React.useState("");
    const [p_detail_description, setp_detail_description] = React.useState("");
    const [p_detail_comment, setp_detail_comment] = React.useState("");
    const [p_detail_class_id, setp_detail_class_id] = React.useState("");

    const [p_detail_class_internal_url, setp_detail_class_internal_url] = React.useState("");

    const [p_detail_class_eu_id, setp_detail_class_eu_id] = React.useState("");
    const [p_detail_type_eval_id, setp_detail_type_eval_id] = React.useState("");

    const [p_detail_eval_date_heure_debut, setp_detail_eval_date_heure_debut] = useState(new Date());
    const [p_detail_eval_date_heure_fin, setp_detail_eval_date_heure_fin] = useState(new Date());

    const [p_detail_statut, setp_detail_statut] = React.useState("");
    const [p_detail_adress, setp_detail_adress] = React.useState("");
    const [p_detail_cp, setp_detail_cp] = React.useState("");
    const [p_detail_ville, setp_detail_ville] = React.useState("");
    const [p_detail_pays, setp_detail_pays] = React.useState("");
    const [p_detail_responsable_id, setp_detail_responsable_id] = React.useState("");
    const [p_detail_session_id, setp_detail_session_id] = React.useState("");

    function clear_DetailFieds() {

        setp_detail_code("");
        setp_detail_titre("");
        setp_detail_description("");
        setp_detail_comment("");
        setp_detail_class_id("");
        setp_detail_class_eu_id("");
        setp_detail_type_eval_id("");

        setp_detail_eval_date_heure_debut(new Date());
        setp_detail_eval_date_heure_fin(new Date());

        setp_detail_statut("");
        setp_detail_adress("");
        setp_detail_cp("");
        setp_detail_ville("");
        setp_detail_pays("");
        setp_detail_responsable_id("");
        setp_detail_session_id("");
    }

    const myRef = useRef(null)

    function Enable_Evaluation_DetailFields() {
        setevaluation_data_edit_mode("1");

        var liste_champs = ['detail_code', 'detail_titre', 'detail_description', 'detail_classe',
            'detail_ftion', 'detail_ue', 'detail_type_eval', 'detail_debut', 'detail_fin', 'detail_adresse',
            'detail_cp', 'detail_ville', 'detail_pays', 'detail_responsable']

        for (let i = 0; i < liste_champs.length; i++) {
            if (document.getElementsByName(String(liste_champs[i])) && document.getElementsByName(String(liste_champs[i]))[0]) {
                document.getElementsByName(String(liste_champs[i]))[0].disabled = false;
                document.getElementsByName(String(liste_champs[i]))[0].style.backgroundColor = "#FFFFFF";

            }
        }
    }


    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    async function Disable_Evaluation_DetailFields() {
        await sleep(5);
        setevaluation_data_edit_mode("0");

        var liste_champs = ['detail_code', 'detail_titre', 'detail_description', 'detail_classe',
            'detail_ftion', 'detail_ue', 'detail_type_eval', 'detail_debut', 'detail_fin', 'detail_adresse',
            'detail_cp', 'detail_ville', 'detail_pays', 'detail_responsable', 'detail_titre_01']


        for (let i = 0; i < liste_champs.length; i++) {

            if (document.getElementsByName(String(liste_champs[i])) && document.getElementsByName(String(liste_champs[i]))[0]) {
                document.getElementsByName(String(liste_champs[i]))[0].disabled = true;
                document.getElementsByName(String(liste_champs[i]))[0].style.backgroundColor = "#ECEFF1";

            }
        }


        if (document.getElementsByName("detail_titre_01") && document.getElementsByName("detail_titre_01")[0]) {
            document.getElementsByName("detail_titre_01")[0].disabled = true;
            document.getElementsByName("detail_titre_01")[0].style.backgroundColor = "#ECEFF1";

        }
    }


    function planifier_one_evaluation() {

        clear_DetailFieds();

        setevaluation_data_edit_mode("");
        setevaluation_data_changed("");
        setdisplay_evaluation("");
        setselected_id("");
        setgridline_id("");
        setadd_one_evaluation("1");
        setsubmenu("detail_evaluation");

        if (document.getElementById('myRef')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Annule_Add_One_Evaluaton_DetailFields() {
        setevaluation_data_edit_mode("");
        setevaluation_data_changed("");
        setdisplay_evaluation("");
        setselected_id("");

        setadd_one_evaluation("");
        setsubmenu("");

    }

    async function submenu_detail() {
        await sleep(5);
        setsubmenu("detail_evaluation");
        inactive_active_menu_header("detail_evaluation");


        if (String(evaluation_data_edit_mode) !== "1") {
            Disable_Evaluation_DetailFields();
        }

    }


    async function submenu_apprenant() {
        await sleep(5);
        setsubmenu("apprenant");
        inactive_active_menu_header("apprenant");

    }

    async function submenu_piece_jointe() {
        await sleep(5);
        setsubmenu("piece_jointe");
        inactive_active_menu_header("piece_jointe");

        // Refresh affichage des pièces jointes
        Get_List_Of_All_PJ(props.client_id);

    }

    async function submenu_ressources() {
        Getall_List_note_evaluation_Ressource(selected_id);
        await sleep(5);
        setsubmenu("ressources");
        inactive_active_menu_header("ressources");

    }

    async function submenu_selection() {
        await sleep(5);
        setsubmenu("selection");
        inactive_active_menu_header("selection");

    }


    // Cette fontion desactive tous les menu header et reactive just le menu_header concerné
    function inactive_active_menu_header(current_menu_header) {
        const list_menu_header_names = ['detail_evaluation', 'apprenant', 'piece_jointe', 'selection', 'ressources']
        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.fontWeight = "400";
            }
        }


        if (document.getElementsByName(String(current_menu_header)) && document.getElementsByName(String(current_menu_header))[0]) {
            document.getElementsByName(String(current_menu_header))[0].style.fontWeight = "700";
        }
    }


    function GetSelectedRows_Apprenant_Evaluation_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_evalutions_participant.length; i++) {
            //console.log(" ### selectionModel_insc[i] = ", selectionModel_insc[i]);
            var myid = parseInt(String(selectionModel_evalutions_participant[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_evaluation_apprenant_Traitemet() {

        var liste_apprenant_id = GetSelectedRows_Apprenant_Evaluation_Ids();


        var tab_mongo_preins_ids = [];

        for (var i = 0; i < liste_apprenant_id.length; i++) {

            var line = Getall_Evaluation_Participant_List_result[liste_apprenant_id[i]]

            var line_json = JSON.parse(line)
            //console.log(" line_json['prenom'] = ", line_json['prenom']);
            //console.log(" line_json['_id'] = ", line_json['_id']);
            tab_mongo_preins_ids.push(line_json['_id'])

        }

        if (String(actionmass_eval_inscr_val) === String("delete_inscr")) {
            Delete_List_Participant_From_Evaluation(tab_mongo_preins_ids);
            setactionmass_eval_inscr_val("");
        }

        if (String(actionmass_eval_inscr_val) === String("send_convocation")) {
            setDialog_CONVOCATION_EVAL_open(true); //zzzz
            setactionmass_eval_inscr_val("");
        }
    }

    const [actionmass_eval_inscr_val, setactionmass_eval_inscr_val] = useState();


    const New_Option_PreInscription = [
        { "id": "send_convocation", "label": "Envoyer Convocation", "value": "send_convocation" },
        { "id": "delete_inscr", "label": "Supprimer", "value": "delete_inscr" },
        { "id": "", "label": "", "value": "" },
    ]


    const [Record_Evaluation_Participants_Notes_api, setRecord_Evaluation_Participants_Notes_api] = useState();
    const [Record_Evaluation_Participants_Notes_message, setRecord_Evaluation_Participants_Notes_message] = useState();
    const [Record_Evaluation_Participants_Notes_result, setRecord_Evaluation_Participants_Notes_result] = useState([]);
    function Record_Evaluation_Participants_Notes() {
        var form = new FormData();

        const new_partcipant_notes_JSON = JSON.stringify(New_Getall_Evaluation_Participant_List_result);

        // console.log(" Record_Evaluation_Participants_Notes New_Getall_TrainingParticipant_result = ", New_Getall_TrainingParticipant_result);


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("evaluation_id", selected_id);
        form.append("tab_participant_note", new_partcipant_notes_JSON);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Participant_Evaluation_Note/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            console.log(" In Record_Evaluation_Participants_Notes  res.data.status = " + res.data.status);
            console.log(" In Record_Evaluation_Participants_Notes  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {

                setnoted_changed("");
                Getall_Evaluation_Participant_List(selected_id);
                setselectionModel_evalutions_participant([]);
                settab_convention_pieces_jointes_result([]);
                alert(res.data.message);

            }
            else {
                setRecord_Evaluation_Participants_Notes_api("false");
                setRecord_Evaluation_Participants_Notes_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Record_Evaluation_Participants_Notes : Not good man :( Record_Evaluation_Participants_Notes = ' + error);
            setRecord_Evaluation_Participants_Notes_api("false");
            alert(" Impossible de mettre à jour les données des participants ");

        })
    }

    function OnchangeCellDataGrid(params) {


        // params = la nouvelle lignes


        var num_line = params.id;  // ==> Id de la ligne changé
        var colomn = params.field; // ==> colonne de la ligne changé
        var new_value = params.value; // ==> la nouvelle valeur


        setnoted_changed("1");

        const updatedData = New_Getall_Evaluation_Participant_List_result.map((x) => {

            var localid = (x).id;


            if (String(num_line) === String(localid) && colomn === "note") {
                var tmp = {};
                tmp['id'] = (x).id;
                tmp['_id'] = (x)._id;
                tmp['nom'] = (x).nom;
                tmp['prenom'] = (x).prenom;
                tmp['email'] = (x).email;
                tmp['groupe'] = (x).groupe;
                tmp['note_changed'] = "1";
                tmp['note'] = new_value;

                return tmp;
            }


            var tmp = {};
            tmp['id'] = (x).id;
            tmp['_id'] = (x)._id;
            tmp['nom'] = (x).nom;
            tmp['prenom'] = (x).prenom;
            tmp['email'] = (x).email;
            tmp['groupe'] = (x).groupe;
            tmp['note_changed'] = (x).note_changed;
            tmp['note'] = (x).note;

            return tmp;


        });

        //console.log(" ### updatedData = ", updatedData);

        setNew_Getall_Evaluation_Participant_List_result([]);

        setNew_Getall_Evaluation_Participant_List_result(updatedData);

    }

    const [line_changed_color, setline_changed_color] = useState("red");

    const [noted_changed, setnoted_changed] = useState("");

    function Annuler_change_note() {
        Getall_Evaluation_Participant_List(selected_id);
        setnoted_changed("");
        submenu_apprenant();
    }


    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();
    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;
        setLoading(true);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const editorRef_competence = useRef(null);
    const [editorRef_competence_limite, seteditorRef_competence_limite] = useState(800);


    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };


    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_2_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();
    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "note_evaluation");
            formData.append("object_owner_id", selected_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);


            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(props.client_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();
    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(props.client_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();
    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "note_evaluation");
        formData.append("object_owner_id", selected_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes


    function Export_evaluation_note_exel() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_notes.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_List_Participant_To_Evaluation/" + stored_cookie + "/" + selected_id + "/";


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les notes (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les notes ")

            });


        setLoading(false);
    }


    // -- Debut gestion des ressources


    const [event_start_date, setevent_start_date] = useState("");
    const [event_end_date, setevent_end_date] = useState("");


    const [Get_Ressource_Avabilities_avability_check, setGet_Ressource_Avabilities_avability_check] = useState("");

    const [Get_Ressource_Avabilities_api, setGet_Ressource_Avabilities_api] = useState();
    const [Get_Ressource_Avabilities_message, setGet_Ressource_Avabilities_message] = useState();
    const [Get_Ressource_Avabilities_result, setGet_Ressource_Avabilities_result] = useState([]);
    function Get_Ressource_Avabilities(local_related_collection_recid) {

        var form = new FormData();

        var line = New_Getall_Partner_Evaluation_result.filter((data) => (data)._id === String(selected_id))

        var event_start = String(line[0].eval_date_heure_debut);
        setevent_start_date(String(line[0].eval_date_heure_debut));

        var event_end = String(line[0].eval_date_heure_fin);
        setevent_end_date(String(line[0].eval_date_heure_fin));


        //var event_start = format(String(line[0].eval_date_heure_debut), 'dd/MM/yyyy HH:mm'); 
        //var event_end = format(String(line[0].eval_date_heure_fin), 'dd/MM/yyyy HH:mm');


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", Dialog_seq_ressource_1_selected_type_ressource);
        form.append("related_collection_recid", local_related_collection_recid);
        form.append("event_start", String(event_start));
        form.append("event_end", String(event_end));

        //console.log(" ### form = ", form)

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Is_Ressource_Available/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                /*console.log(" In Get_Ressource_Avabilities  res.data.status = " + res.data.status);
                console.log(" In Get_Ressource_Avabilities  res.data.message r_class = " + res.data.message);
                console.log(" In Get_Ressource_Avabilities  res.data.avability_check r_class = " + res.data.avability_check);
*/

                setGet_Ressource_Avabilities_api("true");
                setGet_Ressource_Avabilities_result(res.data.message);
                setGet_Ressource_Avabilities_avability_check(res.data.avability_check);

            }
            else {
                setGet_Ressource_Avabilities_api("false");
                setGet_Ressource_Avabilities_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Ressource_Avabilities = ', error);
            setGet_Ressource_Avabilities_api("false");
            alert("Impossible de récuperer la liste des ressources de la sequence")
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_List_note_evaluation_Ressource_api, setGetall_List_note_evaluation_Ressource_api] = useState();
    const [Getall_List_note_evaluation_Ressource_message, setGetall_List_note_evaluation_Ressource_message] = useState();
    const [Getall_List_note_evaluation_Ressource_result, setGetall_List_note_evaluation_Ressource_result] = useState([]);
    function Getall_List_note_evaluation_Ressource(local_note_evaluation_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("note_evaluation_id", local_note_evaluation_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_note_evaluation_Ressource_Affectation/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_List_note_evaluation_Ressource  res.data.status = " + res.data.status);
                //console.log(" In Getall_List_note_evaluation_Ressource  res.data.message r_class = " + res.data.message);
                setGetall_List_note_evaluation_Ressource_api("true");
                setGetall_List_note_evaluation_Ressource_result(res.data.message);

            }
            else {
                setGetall_List_note_evaluation_Ressource_api("false");
                setGetall_List_note_evaluation_Ressource_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_List_note_evaluation_Ressource = ', error);
            setGetall_List_note_evaluation_Ressource_api("false");
            alert("Impossible de récuperer la liste des ressources de la sequence")
            //setmyApimyApiMessage("")
        })
    }

    const [selectionModel_ressource, setselectionModel_ressource] = React.useState([]);

    function submenu_add_one_Ressource() {
        setevent_start_date("");
        setevent_end_date("");

        setGet_Ressource_Avabilities_avability_check("");
        setDialog_seq_ressource_1_open(true);
    }


    const [Dialog_seq_ressource_1_message, setDialog_seq_ressource_1_message] = React.useState(false);
    const [Dialog_seq_ressource_1_open, setDialog_seq_ressource_1_open] = React.useState(false);


    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_ressource_1_message(message);
        setDialog_seq_ressource_1_open(true);
    }

    const Dialog_seq_ressource_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_seq_ressource_1_handleClose_buton = () => {

        setevent_start_date("");
        setevent_end_date("");

        setDialog_seq_ressource_1_open(false);
    };

    const New_Option_Type_Ressource = [
        { "id": "0", "label": "Employe", "value": "ressource_humaine" },
        { "id": "1", "label": "Materiel", "value": "ressource_materielle" },
    ]


    const [Dialog_seq_ressource_1_selected_type_ressource, setDialog_seq_ressource_1_selected_type_ressource] = React.useState();
    const [Dialog_seq_ressource_1_selected_ressource_id, setDialog_seq_ressource_1_selected_ressource_id] = React.useState();
    const [Dialog_seq_ressource_1_selected_ressource_poste, setDialog_seq_ressource_1_selected_ressource_poste] = React.useState();


    const datagridSx = {
        borderRadius: 2,
        marginRight: 1,
        marginLeft: 1,
        border: 0,
        "& .MuiDataGrid-main": { borderRadius: 0 },

        "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
            }
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#c8cfd5",
            color: "black",
            fontSize: 16
        }
    };

    const [Add_One_Sequence_Session_mass, setAdd_One_Sequence_Session_mass] = useState();

    const [Add_One_Sequence_Session_Ressource_api, setAdd_One_Sequence_Session_Ressource_api] = useState();
    const [Add_One_Sequence_Session_Ressource_message, setAdd_One_Sequence_Session_Ressource_message] = useState();
    const [Add_One_Sequence_Session_Ressource_result, setAdd_One_Sequence_Session_Ressource_result] = useState();
    const Add_One_Sequence_Session_Ressource = event => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("note_evaluation_id", selected_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        if (String("new_ressource_type") === "ressource_humaine" && String(new_ressource_poste).trim() === "") {
            alert(" Vous devez préciser le poste occupé pour cette ressrouce");
            return;
        }

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Note_Evaluation_Affectation_Ressource_Poste/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session_Ressource  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session_Ressource  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_Ressource_api("true");
                setAdd_One_Sequence_Session_Ressource_result(res.data.message);

                Getall_List_note_evaluation_Ressource(selected_id);

                setDialog_seq_ressource_1_selected_type_ressource();
                setDialog_seq_ressource_1_selected_ressource_id();
                setDialog_seq_ressource_1_selected_ressource_poste();

                alert(res.data.message);
                Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Sequence_Session_Ressource_api("false");
                setAdd_One_Sequence_Session_Ressource_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session_Ressource = ' + error);
            setAdd_One_Sequence_Session_Ressource_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }

    const [Delete_Sequence_Session_Ressource_Data_api, setDelete_Sequence_Session_Ressource_Data_api] = useState();
    const [Delete_Sequence_Session_Ressource_Data_message, setDelete_Sequence_Session_Ressource_Data_message] = useState();
    const [Delete_Sequence_Session_Ressource_Data_result, setDelete_Sequence_Session_Ressource_Data_result] = useState();
    function Delete_Sequence_Session_Ressource_Data(local_seq_ressource_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_seq_ressource_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Note_Evaluation_Ressource_Poste/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Ressource_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Ressource_Data  res.data.message r_class = " + res.data.message);


            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Ressource_Data_api("true");
                setDelete_Sequence_Session_Ressource_Data_result(res.data.message);

                Getall_List_note_evaluation_Ressource(selected_id);

                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Ressource_Data_api("false");
                setDelete_Sequence_Session_Ressource_Data_message(res.data.message);

                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Ressource_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Ressource_Data_api("false");
            alert(" Impossible de supprimer la ressource");
        })
    }

    const [tab_selected_sequence_id, settab_selected_sequence_id] = useState([]);
    function GetSelectedRows_Sequence_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_evalutions.length; i++) {
            var myid = parseInt(String(selectionModel_evalutions[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_sequence_Traitement() {

        setAdd_One_Sequence_Session_mass("1");
        var liste_formation = GetSelectedRows_Sequence_Ids();

        var tab_seq_mongo_ids = [];

        /* 22/05/24 : Retravailler ce code pour le module note_evaluation.
        for (var i = 0; i < selectionModel_evalutions.length; i++) {

            var line_id = selectionModel_evalutions[i];
            var mongo_id = JSON.parse(Getall_Sequence_Of_Session_result[line_id])._id;
            tab_seq_mongo_ids.push(mongo_id);


        }
        */

        settab_selected_sequence_id(tab_seq_mongo_ids);


        if (String(actionmass_sequence_val) === "ajout_ressource") {
            setDialog_seq_ressource_1_open(true);

        }
        else if (String(actionmass_sequence_val) === "supprimer") {

            //            Delete_Sequence_Session_Data_Mass(tab_seq_mongo_ids);

        }
    }


    const [actionmass_sequence_val, setactionmass_sequence_val] = useState();


    const [Add_One_Sequence_Session_Ressource_Mass_api, setAdd_One_Sequence_Session_Ressource_Mass_api] = useState();
    const [Add_One_Sequence_Session_Ressource_Mass_message, setAdd_One_Sequence_Session_Ressource_Mass_message] = useState();
    const [Add_One_Sequence_Session_Ressource_Mass_result, setAdd_One_Sequence_Session_Ressource_Mass_result] = useState();
    const Add_One_Sequence_Session_Ressource_Mass = tab_ids => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_sequence_session_id", tab_selected_sequence_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Affectation_Ressource_Poste_Mass/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session_Ressource_Mass  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session_Ressource_Mass  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_Ressource_Mass_api("true");
                setAdd_One_Sequence_Session_Ressource_Mass_result(res.data.message);

                Getall_List_note_evaluation_Ressource(selected_id);

                setDialog_seq_ressource_1_selected_type_ressource();
                setDialog_seq_ressource_1_selected_ressource_id();
                setDialog_seq_ressource_1_selected_ressource_poste();

                setAdd_One_Sequence_Session_mass();
                //settab_selected_sequence_id([]);

                alert(res.data.message);
                Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Sequence_Session_Ressource_Mass_api("false");
                setAdd_One_Sequence_Session_Ressource_Mass_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session_Ressource_Mass = ' + error);
            setAdd_One_Sequence_Session_Ressource_Mass_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }

    // -- end gestion des ressources


    const [New_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result, setNew_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result] = useState([]);

    const [Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_api, setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_api] = useState();
    const [Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_message, setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_message] = useState();
    const [Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result, setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result] = useState([]);
    function Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc(ref_interne_document) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ref_interne", ref_interne_document);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Document_with_filter/";
        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc  res.data.status = " + res.data.status);
            //console.log(" In Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_api("true");
                setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result(res.data.message);

                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;
                    var local_contenu_doc = JSON.parse(x).contenu_doc;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " - " + local_type_doc,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,
                        "contenu_doc": local_contenu_doc,

                    };
                    new_data2.push(node);

                    //  console.log(" ## node = ", node)
                });

                if (new_data2.length > 0)
                    setNew_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result(new_data2);

            }
            else {
                setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_api("false");
                setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc = ', error);
            setGet_Partner_List_EVALUATION_CONVOCATION_Model_Doc_api("false");
            alert("Impossible de récupérer les modèles de convocations");
            //setmyApimyApiMessage("")
        })
    }

    const New_Option_Type_Document = [
        { "id": "email", "label": "Email", "value": "email" },
        { "id": "pdf", "label": "PDF", "value": "pdf" },

    ]

    const [Convocation_Eval_Type_doc, setConvocation_Eval_Type_doc] = React.useState("pdf");
    const [Convocation_Eval_model_doc_id, setConvocation_Eval_model_doc_id] = React.useState("pdf");


    const [Dialog_CONVOCATION_EVAL_open, setDialog_CONVOCATION_EVAL_open] = React.useState(false);


    const Dialog_CONVOCATION_EVAL_handleClose_buton = () => {
        setDialog_CONVOCATION_EVAL_open(false);
        settab_convention_pieces_jointes_result([]);
    };


    function Download_Evaluation_Convocation_PDF() {

        const stored_cookie = getCookie('tokenmysypart');
        var nom_fichier = "Convocation_Evaluation.zip";

        var liste_apprenant_id = GetSelectedRows_Apprenant_Evaluation_Ids();
        var tab_mongo_preins_ids = [];

        for (var i = 0; i < liste_apprenant_id.length; i++) {
            var line = Getall_Evaluation_Participant_List_result[liste_apprenant_id[i]]
            var line_json = JSON.parse(line);
            tab_mongo_preins_ids.push(line_json['_id'])
        }

        var list_ids = tab_mongo_preins_ids.toString()


        var url = process.env.REACT_APP_API_URL + "myclass/api/Send_Evaluation_Convocation_Participant_By_PDF/" + stored_cookie + "/" + selected_id + "/" + list_ids + "/" + Convocation_Eval_model_doc_id;


        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_session_id = ", selected_session_id, " --- selected_convention_id =", selected_convention_id);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Download_Stagiaire_Conventions_PDF  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible de télécharger le fichier (2) ");
                } else {
                    fileDownload(res.data, nom_fichier);
                    setselectionModel_evalutions_participant([]);
                    settab_convention_pieces_jointes_result([]);

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de télécharger la convention ")

            });

    }

    const [Send_Eval_Convocation_By_Email_api, setSend_Eval_Convocation_By_Email_api] = useState();
    const [Send_Eval_Convocation_By_Email_message, setSend_Eval_Convocation_By_Email_message] = useState();
    const [Send_Eval_Convocation_By_Email_result, setSend_Eval_Convocation_By_Email_result] = useState();
    function Send_Eval_Convocation_By_Email(event) {

        var liste_apprenant_id = GetSelectedRows_Apprenant_Evaluation_Ids();
        var tab_mongo_preins_ids = [];

        for (var i = 0; i < liste_apprenant_id.length; i++) {
            var line = Getall_Evaluation_Participant_List_result[liste_apprenant_id[i]]
            var line_json = JSON.parse(line);
            tab_mongo_preins_ids.push(line_json['_id'])
        }

        var list_ids = tab_mongo_preins_ids.toString()

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("evaluation_id", selected_id);
        form.append("tab_eval_inscription_id", list_ids);
        form.append("courrier_template_id", Convocation_Eval_model_doc_id);


        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);
        }
        //form.append("File", list_files);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Send_Evaluation_Convocation_Participant_By_Email/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Send_Eval_Convocation_By_Email  res.data.status = " + res.data.status);
                //console.log(" In Send_Eval_Convocation_By_Email  res.data.message r_class = " + res.data.message);
                setSend_Eval_Convocation_By_Email_api("true");
                setSend_Eval_Convocation_By_Email_result(res.data.message);
                setselectionModel_evalutions_participant([]);
                settab_convention_pieces_jointes_result([]);

                Dialog_CONVOCATION_EVAL_handleClose_buton();
                alert(res.data.message);

            }
            else {
                setSend_Eval_Convocation_By_Email_api("false");
                setSend_Eval_Convocation_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Send_Eval_Convocation_By_Email = ', error);
            setSend_Eval_Convocation_By_Email_api("false");
            alert("Impossible d'envoyer la convocation par email");
            //setmyApimyApiMessage("")
        })
    }

    function Delete_Convention_Attached_Doc(event) {

        var doc_to_del_name = event.target.id;
        const myArray = tab_convention_pieces_jointes_result;



        let new_myArray = myArray.filter(file => file.name !== String(doc_to_del_name));

        var new_tab = []
        for (let i = 0; i < new_myArray.length; i++) {
            new_tab.push(new_myArray[i]);
        }

        settab_convention_pieces_jointes_result(new_tab);


    }

    const [sessions_file_change_1_convention_api, setsessions_file_change_1_convention_api] = useState();
    const [sessions_file_change_1_convention_result, setsessions_file_change_1_convention_result] = useState();
    const [sessions_file_change_1_convention_message, setsessions_file_change_1_convention_message] = useState();
    const sessions_file_change_1_convention = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        if (file_type !== "application/pdf") {
            alert("Le fichier n'est pas de type PDF");
            return;
        }



        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


        var new_tmp = [];

        if (tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.length > 0) {

            tab_convention_pieces_jointes_result.map((x) => {
                new_tmp.push(x);

            });

        }

        new_tmp.push(event.target.files[0])

        settab_convention_pieces_jointes_result(new_tmp);

        //console.log(" tab_convention_pieces_jointes_result = ", tab_convention_pieces_jointes_result)


        return;


    };

    const [tab_convention_pieces_jointes_result, settab_convention_pieces_jointes_result] = useState([]);




    return (
        <div className="note_evaluation">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}



            {/*  Dialog pour gerer les CONVOCATION_EVAL */}
            <Dialog
                open={Dialog_CONVOCATION_EVAL_open}
                onClose={Dialog_CONVOCATION_EVAL_handleClose_buton}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "15rem" }}>

                    {setConvocation_Eval_Type_doc && setConvocation_Eval_Type_doc.length > 0 &&
                        <div className="session_caract_Dialog" > Type Document<br />
                            <Autocomplete
                                disablePortal
                                name="event_dialog_type_doc_CONVOCATION_EVAL"
                                id="event_dialog_type_doc_CONVOCATION_EVAL"
                                fullWidth
                                //className="disabled_style enable_style"
                                options={New_Option_Type_Document}
                                value={New_Option_Type_Document.filter((data) => (data).id === String(Convocation_Eval_Type_doc))[0].label}

                                onChange={(event, value) => {
                                    if (value && value.id) {
                                        setConvocation_Eval_Type_doc(value.id);
                                    } else {
                                        setConvocation_Eval_Type_doc("pdf");

                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option" />
                                }
                            />

                        </div>}

                    {New_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result && New_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result.length > 0 &&
                        <div className="session_caract_Dialog" > Choisir un modèle de convocation
                            <Autocomplete
                                disablePortal
                                name="event_dialog_version_CONVOCATION_EVAL"
                                id="event_dialog_version_CONVOCATION_EVAL"
                                fullWidth
                                //className="disabled_style enable_style"
                                // options={New_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result}
                                options={New_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result.filter((data) => (data).type_doc === String(Convocation_Eval_Type_doc))}

                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setConvocation_Eval_model_doc_id(value._id);
                                        //var result2 = New_Get_Partner_List_EVALUATION_CONVOCATION_Model_Doc_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id === String(value._id))

                                    } else {
                                        setConvocation_Eval_model_doc_id("");

                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option" />
                                }
                            />

                        </div>}



                    {String(Convocation_Eval_Type_doc) === "email" && <div>

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes Convention

                            </div>

                            <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload_convention_file">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload_convention_file"
                                            name="upload_convention_file"
                                            type="file"
                                            onChange={sessions_file_change_1_convention}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>


                            </div>


                            {tab_convention_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    {<div className="div_row">
                                        {tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj_convention"  >
                                                <nav style={{ "color": "orange", "cursor": "pointer" }}
                                                    onClick={(e) => {
                                                        Delete_Convention_Attached_Doc(e);
                                                    }}
                                                    name={val.name} id={val.name}> Supprimer  {val.name} </nav> <br />


                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>}



                        </div>
                    </div>}

                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">



                            {Convocation_Eval_Type_doc && String(Convocation_Eval_Type_doc) === "pdf" && <Button className="bton_enreg_dialog" onClick={(e) => {
                                Download_Evaluation_Convocation_PDF();
                                Dialog_CONVOCATION_EVAL_handleClose_buton();
                            }}
                            >Télécharger </Button>}

                            {Convocation_Eval_Type_doc && String(Convocation_Eval_Type_doc) === "email" && <Button className="bton_enreg_dialog" onClick={(e) => {
                                Send_Eval_Convocation_By_Email();
                                Dialog_CONVOCATION_EVAL_handleClose_buton();
                            }}
                            >Envoyer </Button>}

                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_CONVOCATION_EVAL_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les CONVOCATION_EVAL */}



            {/*** Dialog Ajout Ressources */}

            <Dialog
                open={Dialog_seq_ressource_1_open}
                onClose={Dialog_seq_ressource_1_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Ajout ressource </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_seq_ressource_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Type de ressource
                        <Autocomplete
                            disablePortal
                            name="event_dialog_type_ressource"
                            id="event_dialog_type_ressource"
                            fullWidth

                            options={New_Option_Type_Ressource}
                            onChange={(event, value) => {
                                setGet_Ressource_Avabilities_avability_check("");
                                if (value && value.value) {

                                    setDialog_seq_ressource_1_selected_type_ressource(value.value);
                                }
                                setGet_Ressource_Avabilities_avability_check("");

                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />


                    </div>



                    {String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_humaine" && <div className="session_caract_Dialog" > Ressource Humaine

                        <Autocomplete
                            disablePortal
                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"
                            fullWidth
                            options={New_Getall_Training_Employee_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setDialog_seq_ressource_1_selected_ressource_id(value._id);
                                    Get_Ressource_Avabilities(value._id);
                                } else {
                                    Get_Ressource_Avabilities_avability_check("");
                                    setDialog_seq_ressource_1_selected_ressource_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />

                        {Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                            <div className="session_caract_Dialog koUpdateData">
                                <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                    <FcCancel /><FcInfo />  <font size="10px" color="orange"> /!\ Indisponible du {event_start_date} au {event_end_date} </font>
                                </a>
                            </div>
                        }

                        {Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                            <div className="session_caract_Dialog okUpdateData" >
                                <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                    <FcApproval />  Disponible
                                </a>
                            </div>
                        }

                    </div>
                    }


                    {String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_materielle" && <div className="session_caract_Dialog" > Ressource Materielle

                        <Autocomplete
                            disablePortal
                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"
                            fullWidth
                            options={New_Getall_Training_Materiel_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setDialog_seq_ressource_1_selected_ressource_id(value._id);
                                    Get_Ressource_Avabilities(value._id);
                                } else {
                                    Get_Ressource_Avabilities_avability_check("");
                                    setDialog_seq_ressource_1_selected_ressource_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />
                        {Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                            <div className="session_caract_Dialog koUpdateData">
                                <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                    <FcCancel /><FcInfo /> <font size="10px" color="orange"> /!\  Indisponible du {event_start_date} au {event_end_date} </font>
                                </a>
                            </div>
                        }

                        {Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                            <div className="session_caract_Dialog okUpdateData" >
                                <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                    <FcApproval />  Disponible
                                </a>
                            </div>
                        }

                    </div>
                    }


                    <div className="session_caract_Dialog" > Poste
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={Dialog_seq_ressource_1_selected_ressource_poste}
                            onChange={(e) => {

                                setDialog_seq_ressource_1_selected_ressource_poste(e.target.value);
                            }
                            }

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={Add_One_Sequence_Session_Ressource} className="bton_enreg_dialog">Ajouter</Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_seq_ressource_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            {/*** Fin ajout ressource */}
            <h3> Les Evaluations </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}

                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value("");
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Training_Employee_With_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>


            <div className="div_row" style={{ paddingRight: '10px' }}>

                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>

                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },


                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },


                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_evalutions(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_employee_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            /*  setsubmenu();
                                              setdisplay_detail_employe();
                                              setadd_One_Employee();*/
                                        }
                                    }}
                                    selectionModel={selectionModel_evalutions}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Getall_Partner_Evaluation_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            code: JSON.parse(item).code,
                                            titre: JSON.parse(item).titre,
                                            class_id: JSON.parse(item).class_id,
                                            class_eu_id: JSON.parse(item).class_eu_id,
                                            type_eval_id: JSON.parse(item).type_eval_id,
                                            statut: JSON.parse(item).statut,
                                            session_id: JSON.parse(item).session_id,
                                            eval_date_heure_debut: JSON.parse(item).eval_date_heure_debut,
                                            eval_date_heure_fin: JSON.parse(item).eval_date_heure_fin,


                                        }
                                    ))}

                                    columns={columns_evalutions}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {

                                        Getall_Evaluation_Participant_List(newSelectionModel.row._id);

                                        setselected_id(newSelectionModel.row._id);

                                        setgridline_id(newSelectionModel.row.id);

                                        handleClick_edit_evaluation_From_Line(newSelectionModel.row.id);
                                        setselectionModel_evalutions_participant([]);
                                        settab_convention_pieces_jointes_result([]);


                                    }}

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {



                                    }}
                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }


                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}

                                />
                            </Box>
                            <br />

                        </div>


                    </div>
                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                        <Button variant="outlined" onClick={planifier_one_evaluation}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Planifier 1 Eval &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                    </div>
                </div>
            </div>

            <div className="div_row" ref={myRef} id="myRef"> &nbsp; </div>

            {selected_id && String(selected_id).length > 5 && <div className="div_row">
                <Button variant="outlined" onClick={submenu_detail} className="detail_class_submenu" id='detail_evaluation' name='detail_evaluation'>Détail Evaluation</Button>
                <Button variant="outlined" onClick={submenu_apprenant} className="detail_class_submenu" id='apprenant' name='apprenant'>Apprenants & Groupes</Button>
                <Button variant="outlined" onClick={submenu_ressources} className="detail_class_submenu" id='ressources' name='ressources'>Ressources  </Button>
                <Button variant="outlined" onClick={submenu_selection} className="detail_class_submenu" id='selection' name='selection'>Selection   </Button>
                <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_class_submenu" id='piece_jointe' name='piece_jointe'>Pièces Jointes  </Button>

            </div>}

            {String(submenu) === "detail_evaluation" && <div>
                {String(display_evaluation) === "1" && String(add_one_evaluation) !== "1" && <div className="div_row session_data"  >


                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "paddingTop": "10px", "paddingLeft": "5px" }}> Détail Evaluation </nav>

                    {String(evaluation_data_edit_mode) !== "1" && <div>

                        <div>
                            <div className="session_caract"> <b> Code </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_code"
                                    id="detail_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_code}

                                />

                            </div>

                            <div className="session_caract"> <b> Titre </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_titre"
                                    id="detail_titre"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_titre}

                                />

                            </div>

                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_description"
                                    id="detail_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_description}

                                />

                            </div>



                            <div className="session_caract"> <b>Formation </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ftion"
                                    id="detail_ftion"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> <b> Classe </b>  <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_classe"
                                    id="detail_classe"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> <b>Unite D'enseignement </b> <br />
                                {New_Getall_Partner_List_UE_result && New_Getall_Partner_List_UE_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ue"
                                    id="detail_ue"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(p_detail_class_eu_id))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> <b>Type d'évaluation </b> <br />
                                {New_Getall_Partner_Type_Evalution_List_result && New_Getall_Partner_Type_Evalution_List_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_type_eval"
                                    id="detail_type_eval"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Type_Evalution_List_result.filter((data) => (data)._id === String(p_detail_type_eval_id))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> <b>Début </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_debut"
                                    id="detail_debut"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_eval_date_heure_debut}

                                />

                            </div>

                            <div className="session_caract"> <b>Fin </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_fin"
                                    id="detail_fin"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_eval_date_heure_fin}

                                />

                            </div>



                            <div className="session_caract"> Adresse  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_adresse"
                                    id="detail_adresse"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_adress}

                                />

                            </div>

                            <div className="session_caract"> Code postal  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_cp"
                                    id="detail_cp"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_cp}

                                />

                            </div>


                            <div className="session_caract"> Ville  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ville"
                                    id="detail_ville"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_ville}

                                />

                            </div>



                            <div className="session_caract"> Pays  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_pays"
                                    id="detail_pays"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_pays}

                                />

                            </div>



                            <div className="session_caract"> Responsable  <br />
                                {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_responsable"
                                    id="detail_responsable"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_responsable_id))[0].label}

                                />}

                            </div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>



                    </div>}


                    {String(evaluation_data_edit_mode) === "1" && <div>

                        <div>
                            <div className="session_caract"> <b> Code </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_code"
                                    id="detail_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_code}
                                    onChange={(e) => {
                                        setp_detail_code(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> <b> Titre </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_titre"
                                    id="detail_titre"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_titre}
                                    onChange={(e) => {
                                        setp_detail_titre(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_description"
                                    id="detail_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_description}
                                    onChange={(e) => {
                                        setp_detail_description(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> <b>Formation  </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ftion"
                                        id="detail_ftion"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Class_Reduice_Fields_result}
                                        value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {

                                                Get_List_UE_From_Class_Id(value._id);

                                                setp_detail_class_id(value._id);
                                                setp_detail_class_internal_url(value.internal_url);
                                                setevaluation_data_changed("1");
                                            } else {

                                                Get_List_UE_From_Class_Id("");

                                                setp_detail_class_id("");
                                                setp_detail_class_internal_url("");
                                                setevaluation_data_changed("1");
                                            }

                                            setp_detail_session_id("");
                                            setp_detail_class_eu_id("");
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="session_caract"> <b>Classe    </b>  <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_classe"
                                        id="detail_classe"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        // options={New_Getall_Partner_Session_Reduice_Fields_result}
                                        options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_class_internal_url))}

                                        value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_session_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_session_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>



                            <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                                {New_Getall_Partner_List_UE_result && New_Getall_Partner_List_UE_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ue"
                                        id="detail_ue"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        //options={New_Getall_Partner_List_UE_result}
                                        options={New_Get_List_UE_From_Class_Id_result.filter((data) => (data).class_id === String(p_detail_class_id))}
                                        value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(p_detail_class_eu_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_class_eu_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_class_eu_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract"> <b>Type d'évaluation </b> <br />
                                {New_Getall_Partner_Type_Evalution_List_result && New_Getall_Partner_Type_Evalution_List_result.length > 0 &&
                                    <Autocomplete
                                        disablePortal
                                        name="detail_type_eval"
                                        id="detail_type_eval"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Type_Evalution_List_result}
                                        value={New_Getall_Partner_Type_Evalution_List_result.filter((data) => (data)._id === String(p_detail_type_eval_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_type_eval_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_type_eval_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract" > <b> Début </b><br />
                                <DatePicker
                                    name="detail_debut"
                                    id="detail_debut"
                                    selected={new Date(moment(p_detail_eval_date_heure_debut, "DD/MM/YYYY hh:mm"))}
                                    onChange={(date) => {
                                        setp_detail_eval_date_heure_debut(format(date, 'dd/MM/yyyy HH:mm'));
                                        setevaluation_data_changed("1");
                                    }
                                    }


                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    is24Hour

                                />

                            </div>



                            <div className="session_caract"> <b>Fin </b> <br />
                                <DatePicker
                                    name="detail_debut"
                                    id="detail_debut"
                                    selected={new Date(moment(p_detail_eval_date_heure_fin, "DD/MM/YYYY hh:mm"))}
                                    onChange={(date) => {

                                        setp_detail_eval_date_heure_fin(format(date, 'dd/MM/yyyy HH:mm'));
                                        setevaluation_data_changed("1");
                                    }
                                    }


                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    is24Hour

                                />

                            </div>



                            <div className="session_caract"> Adresse  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_adresse"
                                    id="detail_adresse"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_adress}
                                    onChange={(e) => {
                                        setp_detail_adress(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> Code postal  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_cp"
                                    id="detail_cp"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_cp}
                                    onChange={(e) => {
                                        setp_detail_cp(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>


                            <div className="session_caract"> Ville  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ville"
                                    id="detail_ville"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_ville}
                                    onChange={(e) => {
                                        setp_detail_ville(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>



                            <div className="session_caract"> Pays  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_pays"
                                    id="detail_pays"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_pays}
                                    onChange={(e) => {
                                        setp_detail_pays(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>




                            <div className="session_caract"> Responsable  <br />
                                {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ue"
                                        id="detail_ue"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Training_Employee_No_Filter_result}
                                        value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_responsable_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_responsable_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_responsable_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>}

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        {String(evaluation_data_changed) === "1" && String(evaluation_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}


                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(evaluation_data_changed) === "1" && String(evaluation_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Add_Update_Evaluation_Data}>Enregistrer les modifications
                                </Button>

                            </div>}

                            {String(evaluation_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Annule_Evaluaton_DetailFields}>Annuler
                                </Button>
                            </div>}
                        </div>


                        {String(evaluation_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                            <Button variant="contained" className="bton_edit" onClick={Enable_Evaluation_DetailFields}>Editer
                            </Button>


                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                </div>}


                {String(add_one_evaluation) === "1" && String(display_evaluation) !== "1" && <div className="div_row session_data">

                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "paddingTop": "10px", "paddingLeft": "5px" }}>   Planifier une nouvelle évaluation  </nav>

                    <div>

                        <div>
                            <div className="session_caract"> <b> Code </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_code"
                                    id="detail_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_code}
                                    onChange={(e) => {
                                        setp_detail_code(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> <b> titre </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_titre"
                                    id="detail_titre"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_titre}
                                    onChange={(e) => {
                                        setp_detail_titre(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_description"
                                    id="detail_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_description}
                                    onChange={(e) => {
                                        setp_detail_description(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>



                            <div className="session_caract"> <b>Formation  </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ftion"
                                        id="detail_ftion"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Class_Reduice_Fields_result}
                                        value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                        //value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                Get_List_UE_From_Class_Id(value._id);

                                                setp_detail_class_id(value._id);
                                                setp_detail_class_internal_url(value.internal_url);
                                                setevaluation_data_changed("1");
                                            } else {
                                                Get_List_UE_From_Class_Id("");

                                                setp_detail_class_id("");
                                                setp_detail_class_internal_url("");
                                                setevaluation_data_changed("1");
                                            }

                                            // Quand on modifie la formation, la session et l'UE sont reinitialitées
                                            setp_detail_session_id("");
                                            setp_detail_class_eu_id("");
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="session_caract"> <b> Classe  </b> <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_classe"
                                        id="detail_classe"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_class_internal_url))}
                                        value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_session_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_session_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                                {New_Get_List_UE_From_Class_Id_result &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ue"
                                        id="detail_ue"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Get_List_UE_From_Class_Id_result.filter((data) => (data).class_id === String(p_detail_class_id))}
                                        value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(p_detail_class_eu_id))[0].label}

                                        // value={New_Get_List_UE_From_Class_Id_result.filter((data) => (data)._id === String(p_detail_class_eu_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_class_eu_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_class_eu_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract"> <b>Type d'évaluation </b> <br />
                                {New_Getall_Partner_Type_Evalution_List_result && New_Getall_Partner_Type_Evalution_List_result.length > 0 &&
                                    <Autocomplete
                                        disablePortal
                                        name="detail_type_eval"
                                        id="detail_type_eval"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Type_Evalution_List_result}
                                        value={New_Getall_Partner_Type_Evalution_List_result.filter((data) => (data)._id === String(p_detail_type_eval_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_type_eval_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_type_eval_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract" > <b> Début </b><br />
                                <DatePicker
                                    name="detail_debut"
                                    id="detail_debut"
                                    selected={new Date(moment(p_detail_eval_date_heure_debut, "DD/MM/YYYY hh:mm"))}
                                    onChange={(date) => {
                                        setp_detail_eval_date_heure_debut(format(date, 'dd/MM/yyyy HH:mm'));
                                        setevaluation_data_changed("1");
                                    }
                                    }


                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    is24Hour

                                />

                            </div>



                            <div className="session_caract"> <b>Fin </b> <br />
                                <DatePicker
                                    name="detail_fin"
                                    id="detail_fin"
                                    selected={new Date(moment(p_detail_eval_date_heure_fin, "DD/MM/YYYY hh:mm"))}
                                    onChange={(date) => {

                                        setp_detail_eval_date_heure_fin(format(date, 'dd/MM/yyyy HH:mm'));
                                        setevaluation_data_changed("1");
                                    }
                                    }


                                    className="disabled_style enable_style"
                                    locale='fr-FR'
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    is24Hour

                                />

                            </div>



                            <div className="session_caract"> Adresse  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_adresse"
                                    id="detail_adresse"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_adress}
                                    onChange={(e) => {
                                        setp_detail_adress(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> Code postal  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_cp"
                                    id="detail_cp"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_cp}
                                    onChange={(e) => {
                                        setp_detail_cp(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>


                            <div className="session_caract"> Ville  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ville"
                                    id="detail_ville"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_ville}
                                    onChange={(e) => {
                                        setp_detail_ville(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>



                            <div className="session_caract"> Pays  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_pays"
                                    id="detail_pays"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_pays}
                                    onChange={(e) => {
                                        setp_detail_pays(e.target.value);
                                        setevaluation_data_changed("1");
                                    }
                                    }
                                />

                            </div>




                            <div className="session_caract"> Responsable  <br />
                                {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ue"
                                        id="detail_ue"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Training_Employee_No_Filter_result}
                                        value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_responsable_id))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_responsable_id(value._id);
                                                setevaluation_data_changed("1");
                                            } else {
                                                setp_detail_responsable_id("");
                                                setevaluation_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Add_Update_Evaluation_Data}>Enregistrer
                                </Button>

                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Annule_Add_One_Evaluaton_DetailFields}>Annuler
                                </Button>
                            </div>
                        </div>



                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                </div>}

            </div>}

            {String(submenu) === "apprenant" && <div>

                <div className="div_row" style={{ paddingRight: '10px' }}>


                    <div className="session_data">


                        <div style={{ "border": "None" }}>

                            <nav style={{ "border": "None", "fontSize": "20px", "paddingTop": "5px", "paddingLeft": "5px" }}> Liste des apprenants ou Groupes d'apprenants </nav>

                            <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                <Button className="bton_emarge" onClick={(event) => {
                                    Getall_Evaluation_Participant_List(selected_id);

                                }}>Actualiser la liste</Button>

                                <Button className="bton_emarge" onClick={(event) => {
                                    Export_evaluation_note_exel();
                                }}>Exporter (excel)</Button><br />



                            </div>
                            <div style={{ height: 650, width: '100%', paddingRight: '5px' }}>

                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',



                                        '& .cell--note': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'red',
                                        },

                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        },

                                        '& .line--statut--noted_changed': {
                                            backgroundColor: '#F8F6B3',
                                            fontStyle: 'italic',
                                        },

                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },


                                    }}
                                >
                                    <DataGrid
                                        onCellEditCommit={OnchangeCellDataGrid}

                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_evalutions_participant(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_employee_From_Line(newSelectionModel);*/
                                            if (newSelectionModel.length !== 1) {
                                                /*  setsubmenu();
                                                  setdisplay_detail_employe();
                                                  setadd_One_Employee();*/
                                            }
                                        }}
                                        selectionModel={selectionModel_evalutions_participant}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={New_Getall_Evaluation_Participant_List_result.filter((tmp) => String(tmp._id) !== "").map((item, index) => (
                                            {
                                                id: index,
                                                _id: (item)._id,
                                                nom: (item).nom,
                                                prenom: (item).prenom,
                                                email: (item).email,
                                                groupe: (item).groupe,
                                                note: (item).note,
                                                note_changed: (item).note_changed,

                                                convocation_send_date: (item).convocation_send_date,
                                                convocation_send_type: (item).convocation_send_type,


                                            }
                                        ))}

                                        columns={columns_evalutions_participant}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            /* setselected_id(newSelectionModel.row._id);
 
                                             setgridline_id(newSelectionModel.row.id);
 
                                             handleClick_edit_evaluation_From_Line(newSelectionModel.row.id);
                                             */


                                        }}

                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {

                                            // Traitement de la cellule 'notes' 
                                            if (String(params.field) === "note") {
                                                return 'cell--note';
                                            }

                                        }}

                                        getRowClassName={(params) => {
                                            // Pour la gestion de la couleur de zone double cliquée

                                            //console.log(" ### params.row = ", params.row);

                                            if (String(params.row.note_changed) === "1") {
                                                return 'line--statut--noted_changed';
                                            }

                                            else if (String(params.row.id) === String(gridline_inscrit_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }


                                        }}
                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}
                                        sx={{
                                            "& .MuiDataGrid-cellContent": {
                                                minHeight: 50,

                                            }
                                        }}

                                    />
                                </Box>
                                {selectionModel_evalutions_participant && selectionModel_evalutions_participant.length >= 1 &&
                                    <div className="div_row" style={{ "border": "none", "marginLeft": "10px" }}>
                                        <div className="block_en_mass">
                                            <nav className='traitement_mass'>Traitement en masse</nav>
                                            &nbsp;

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                className="block_en_mass_select"
                                                fullWidth
                                                options={New_Option_PreInscription}
                                                onChange={(event, value) => {
                                                    if (value && value.value) {
                                                        if (String(value.value) === "delete_inscr" || String(value.value) === "send_convocation") {
                                                            setactionmass_eval_inscr_val(value.value);
                                                        }
                                                        else {
                                                            setactionmass_eval_inscr_val("");
                                                        }
                                                    }
                                                    else {
                                                        setactionmass_eval_inscr_val("");
                                                    }

                                                }}

                                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                                />
                                                }
                                            />
                                            &nbsp;


                                            {actionmass_eval_inscr_val && String(actionmass_eval_inscr_val).length > 1 && <nav className='block_en_mass_bton_action'>
                                                <Popup
                                                    trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>
                                                        <FcAcceptDatabase /> Traiter

                                                    </Button>}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >

                                                    {close => (
                                                        <div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}
                                                                {String(actionmass_eval_inscr_val) === "delete_inscr" &&
                                                                    <font>  Confirmer la <b> supprression des inscriptions </b> en masse pour {selectionModel_evalutions_participant.length} lignes.
                                                                    </font>}


                                                                {String(actionmass_eval_inscr_val) === "send_convocation" &&
                                                                    <font>  Confirmer la <b> l'envoi des convocations </b> en masse pour {selectionModel_evalutions_participant.length} lignes.
                                                                    </font>}


                                                            </div>
                                                            <div className="gest_actions">
                                                                <div style={{ "width": "45%", "float": "left" }}>
                                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                                        actionmass_evaluation_apprenant_Traitemet();
                                                                        close();
                                                                    }}> Valider </button>

                                                                </div>
                                                                <div style={{ "width": "45%", "float": "right" }}>
                                                                    <button
                                                                        className="gest_bton_popup"
                                                                        onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Annuler
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </nav>
                                            }
                                            <br />

                                        </div>

                                    </div>}
                                <br />
                            </div>

                            {String(noted_changed) === "1" && <div className="div_row">
                                <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
                            </div>}


                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Popup
                                        trigger={

                                            <Button variant="contained" className="bton_enreg" >Mettre à jour les notes
                                            </Button>}
                                        modal
                                        nested
                                        position="center center"
                                    >
                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information </div>
                                                <div className="gest_content">
                                                    {' '}

                                                    En confirmant cette opération, les notes seront mises à jour.


                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            Record_Evaluation_Participants_Notes(event);
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>


                                </div>

                                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                    <Button variant="contained" className="bton_annule" onClick={Annuler_change_note}>Annuler
                                    </Button>

                                </div>

                            </div>

                            <div className="div_row"> &nbsp; </div>

                        </div>
                    </div>


                </div>

            </div>}


            {String(submenu) === "ressources" && <div>
                <h3> Ressources Humaines & Materielles</h3>

                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',


                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_ressource(newSelectionModel);

                                    }}
                                    //selectionModel={selectionModel_evalutions}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Getall_List_note_evaluation_Ressource_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            related_target_collection_id: JSON.parse(item).related_target_collection_id,
                                            related_target_collection: JSON.parse(item).related_target_collection,
                                            type_ressource: JSON.parse(item).related_target_collection_object,
                                            related_target_collection_object: JSON.parse(item).related_target_collection_object,
                                            related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                            poste: JSON.parse(item).poste,
                                        }
                                    ))}

                                    columns={columns_sequence_ressources_affectation}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {

                                    }}



                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    sx={datagridSx}



                                />
                            </Box>
                            <br />

                        </div>


                    </div>

                </div>
                <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                    <Button variant="outlined" onClick={submenu_add_one_Ressource}
                        className="detail_class_submenu bton_add_session"
                        id='menu_import_participant' name='menu_import_participant'>Ajout 1 Ressource &nbsp;
                        <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                    </Button>

                    <br />

                </div>

            </div>}

            {String(submenu) === "selection" && <div className="div_row session_data">

                <Module_Selection_Evaluation_Apprenants note_evaluation_id={selected_id} session_id={p_detail_session_id}
                    class_id={p_detail_class_id} class_eu_id={p_detail_class_eu_id} type_eval_id={p_detail_type_eval_id}
                    evaluation_selected_id={selected_id} />

            </div>}


            {String(submenu) === "piece_jointe" && <div className="div_row ">


                <div className="div_row session_data">
                    <div className="div_row" style={{ "padding": "5px", 'paddingTop': '15px' }}>

                        <div style={{ "fontSize": "12px" }}>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: "none" }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    onChange={sessions_file_change_1}
                                />

                                <Fab
                                    color="secondary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                >
                                    <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                </Fab>

                            </label>
                        </div>
                        {file_1_name && file_1_name.name &&
                            <nav style={{ "fontSize": "12px" }}>
                                <div className="session_caract">Nom du fichier <br />
                                    <TextField
                                        name="file_to_download_type_name"
                                        id="file_to_download_type_name"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_file_to_download_type_name}
                                        onChange={(e) => {
                                            setp_detail_one_file_to_download_type_name(e.target.value);

                                        }}
                                    />

                                </div>
                                <br />
                                <br />
                                Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                    onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                            </nav>}

                        {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                        {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                    </div>



                    {file_1_name && file_1_name.name && <div className="div_row">

                        <div className="div_row_gauche">
                            <Button variant="contained" onClick={Record_All_PJ}
                                startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                        </div>



                    </div>}

                    {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                        <div className="div_row">
                            <b> Liste des pièces jointes </b> <br />
                            <div className="div_row">
                                {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                    <div className="div_row_list_pj"  >
                                        <nav name={(JSON.parse(val).file_name)}
                                            id={(JSON.parse(val).file_name)}> <i> <AttachFileIcon />{(JSON.parse(val).file_business_object)} </i> <br /><font style={{ "color": "green", "cursor": "pointer" }}
                                                name={(JSON.parse(val).file_name)}
                                                id={(JSON.parse(val).file_name)} onClick={Download_one_attached_document}>  Télécharger &nbsp;</font></nav>

                                        <Popup
                                            trigger={
                                                <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                            }
                                            modal
                                            nested
                                            position="center center"
                                        >
                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}

                                                        En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {

                                                                Delete_one_attached_document(event);
                                                                close();
                                                            }}
                                                                name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                            > Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>

            </div>}

        </div >
    );
}

export default Notes_Evaluation;
