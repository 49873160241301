import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import DisplayUserGoals from "./DisplayUserGoals";

import UpdateUserProfil from "./UpdateUserProfil";

function ProfilObjectif() {
    const history = useHistory();

    

    return (

        <div className="profilobjectif">

           

            <div className="div_row">
                <nav className="titre1">  Liste des profils </nav>
                <UpdateUserProfil />
            </div>

            <hr />

            <div className="div_row">
                <nav className="titre1"> Liste des objectifs </nav> <br />
               
                <DisplayUserGoals />
            </div>

        </div>

    );


}

export default ProfilObjectif;
