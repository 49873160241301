import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Fotter";
import SearchBar from "../components/SearchBar";


const Connexion = () => {

    return(
        <div className={"connexion"}>
            <Navigation />
            <SearchBar />
            <Footer />
            <h1> Page de Connexion</h1>


        </div>

    )
}

export default Connexion;