import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { tokens } from "../theme";
import { MdOutlineAddchart, } from "react-icons/md";
import {
    useTheme,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { RiFileExcel2Line } from "react-icons/ri";
import { BarChart } from '@mui/x-charts';
import { MdDeleteForever } from "react-icons/md";
import { TbColumns2, TbColumns1 } from "react-icons/tb";
import { axisClasses } from '@mui/x-charts';
import { pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';
import fileDownload from 'js-file-download'
const pieParams = { height: 200, margin: { right: 5 } };
const palette = ['red', 'blue', 'green'];

const Dashbord_Factures_Previsionnel_Par_Periode = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));


    const [filter_value, setfilter_value] = useState("d");

    const [filter_type_tache, setfilter_type_tache] = useState("");

    const [tdb_title, settdb_title] = useState();

    const [display_nb_column, setdisplay_nb_column] = useState("1");
    const [display_column_size, setdisplay_column_size] = useState("100%");

    const [dashbord_internal_code, setdashbord_internal_code] = useState("");
    const [gridline_id, setgridline_id] = useState("");

    const [user_dashbord_id, setuser_dashbord_id] = useState(props.user_dashbord_id);

    const [Remove_From_User_TBD_api, setRemove_From_User_TBD_api] = useState();
    const [Remove_From_User_TBD_message, setRemove_From_User_TBD_message] = useState();
    const [Remove_From_User_TBD_result, setRemove_From_User_TBD_result] = useState();
    function Remove_From_User_TBD() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');



        form.append("token", stored_cookie);
        form.append("user_dashbord_id", user_dashbord_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Remove_From_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Remove_From_User_TBD  res.data.message r_class = " + res.data.message);

                setRemove_From_User_TBD_api("true");
                setRemove_From_User_TBD_result(res.data.message);
                // Getall_Qry_Formation_Session();
                alert(res.data.message);
                window.location.reload();


            }
            else {
                setRemove_From_User_TBD_api("false");
                setRemove_From_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Remove_From_User_TBD = ', error);
            setRemove_From_User_TBD_api("false");
            alert("Impossible de supprimer le tableau de bord de votre liste");
            //setmyApimyApiMessage("")
        })
    }




    const [Getall_Qry_Previsionnel_Data_result_JSON, setGetall_Qry_Previsionnel_Data_result_JSON] = useState([]);
    const [Getall_Qry_Previsionnel_Data_api, setGetall_Qry_Previsionnel_Data_api] = useState();
    const [Getall_Qry_Previsionnel_Data_message, setGetall_Qry_Previsionnel_Data_message] = useState();
    const [Getall_Qry_Previsionnel_Data_result, setGetall_Qry_Previsionnel_Data_result] = useState([]);
    function Getall_Qry_Previsionnel_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", p_detail_partner_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Facture_Previsionnelle_Data_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Previsionnel_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Previsionnel_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Previsionnel_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("jsonObj =  ", jsonObj)

                setGetall_Qry_Previsionnel_Data_api("true");
                setGetall_Qry_Previsionnel_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Previsionnel_Data_api("false");
                setGetall_Qry_Previsionnel_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Previsionnel_Data = ', error);
            setGetall_Qry_Previsionnel_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    const chartSetting = {
        yAxis: [
            {
                label: "Chiffre d'affaire (€)",
            },
        ],
        width: 600,
        height: 400,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
            },
        },
    };

    const valueFormatter_CA = (value) => `${value}€`;

    const valueFormatter_count = (value) => `${value} Fact`;


    const valueFormatter_CA_Prev = (value) => `${value} €`;


    function Export_Dashbord_to_CSV(local_dashbord_internal_id) {

        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;
            date_from = date_from.replaceAll('/', "-")

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;
            date_to = date_to.replaceAll('/', "-")
        }


        var local_client_id = "";
        if (!p_detail_partner_client_id || String(p_detail_partner_client_id) === "") {
            local_client_id = "-"
        } else {
            local_client_id = p_detail_partner_client_id;
        }

        console.log(" #### p_detail_partner_client_id = ", p_detail_partner_client_id, " ### local_client_id = ", local_client_id)
        var nom_fichier_cmd_excel = "export_excel.xlsx";
        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/TBD_FACTURE_01_Export_Dashbord_To_Excel/" + stored_cookie + "/" + local_dashbord_internal_id + "/" + date_from + "/" + date_to + "/" + local_client_id + "/";

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);

        console.log(" #### url = ", url)

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les données (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd_excel)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les données ")

            });

    }


    useEffect(() => {
        Getall_Qry_Previsionnel_Data();
        Get_List_Partner_Clients();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])

    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;
                    var local_cond_paiement_id = JSON.parse(x).invoice_condition_paiement_id;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,
                        "invoice_condition_paiement_id": local_cond_paiement_id,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "raison_sociale": "",
                    "invoice_condition_paiement_id": "",

                };
                new_data2.push(node);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }



    const [p_detail_partner_client_id, setp_detail_partner_client_id] = useState("");
    const [p_detail_partner_client_nom, setp_detail_partner_client_nom] = useState("");

    const New_option_periode = [
        { "id": "m0", "label": "Mois en cours", "value": "m0" },
        { "id": "m1", "label": "Mois précédant ", "value": "m1" },
        { "id": "d", "label": "Choisir des dates", "value": "d" },

    ]

    return (
        <div className="dashbord_session">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <div style={{ "height": "40rem" }}>

                <div style={{ "width": "100%", "float": "left" }}>
                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Filtre <br />

                        <Autocomplete
                            disablePortal
                            name="filtre11"
                            id="filtre11"
                            className="disabled_style"
                            options={New_option_periode}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setfilter_value(value.value);

                                } else {
                                    setfilter_value("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une période"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    {filter_value && String(filter_value) === "d" &&

                        <div style={{ "width": "100%", "float": "left" }}>
                            <div className="" style={{ "width": "50%", "float": "left" }}>
                                <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                    Date Debut
                                    <DatePicker
                                        name="one_sessiondatedebut"
                                        id="one_sessiondatedebut"

                                        selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                                        onChange={(date) => {
                                            setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                                        }
                                        }
                                        showTimeSelect={false}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style"
                                        locale='fr-FR'

                                    />

                                </div>
                            </div>

                            <div className="" style={{ "width": "50%", "float": "left" }}>
                                <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                    Date Fin
                                    <DatePicker
                                        name="one_sessiondatefin"
                                        id="one_sessiondatefin"
                                        selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                                        onChange={(date) => {
                                            setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                                        }
                                        }
                                        showTimeSelect={false}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style"
                                    />

                                </div>
                            </div>

                            <br />
                        </div>}
                    <div style={{ "width": "100%", "float": "left" }}>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            Client <br />

                            {New_Get_List_Partner_Clients_result && New_Get_List_Partner_Clients_result.length > 0 &&

                                <Autocomplete
                                    disablePortal
                                    id="dialog_opport_ref_client"
                                    Name="dialog_opport_ref_client"

                                    className="disabled_style"

                                    options={New_Get_List_Partner_Clients_result}
                                    value={New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(p_detail_partner_client_id))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value._id) {

                                            setp_detail_partner_client_id(value._id);
                                            setp_detail_partner_client_nom(value.label);

                                        } else {
                                            setp_detail_partner_client_id("");
                                            setp_detail_partner_client_nom("");
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />}
                        </div>

                    </div>
                    <br />
                    <div className="div_row">

                        <Button variant="contained" className="bton_enreg" onClick={(e) => {
                            Getall_Qry_Previsionnel_Data();
                        }}> Afficher
                        </Button><br />
                    </div>
                </div>

                <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                    <nav style={{ "width": "80%", "float": "left", "textAlign": "center" }}>  TBD : {props.user_dashbord_title} - Du : {props.user_dashbord_filter_start} au : {props.user_dashbord_filter_end}</nav>
                    <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer de ma liste de TBD">
                        <Popup
                            trigger={
                                <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                    <MdDeleteForever style={{ "cursor": "pointer", }} />
                                </nav>
                            }
                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        En confirmant cette opération, le tableau sera supprimé de votre tableau de bord global.<br />
                                        Vous pourrez le rajouter à nouveau si besoin. <br />

                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                Remove_From_User_TBD();
                                                //console.log('modal closed ');
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </a>

                    <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip03" data-tooltip-html="Export Csv/Excel">
                        <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                            <RiFileExcel2Line style={{ "cursor": "pointer", }} onClick={(event) => {
                                Export_Dashbord_to_CSV(props.user_dashbord_id);

                            }} />
                        </nav>
                    </a>

                </div>

                <Box style={{ "color": "black", "height": "30rem" }}>
                    &nbsp;

                    <div className="session_data" style={{ paddingLeft: "2rem" }}>

                        {Getall_Qry_Previsionnel_Data_result_JSON && Getall_Qry_Previsionnel_Data_result_JSON.length > 0 &&
                            Getall_Qry_Previsionnel_Data_result_JSON[0].data &&
                            <BarChart

                                dataset={Getall_Qry_Previsionnel_Data_result_JSON[0].data}
                                xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                series={[
                                    { dataKey: 'ca_month_previsionnel', label: 'CA Prév', valueFormatter_CA_Prev },
                                    //   { dataKey: 'ca_month_previsionnel', label: 'CA Prév', color: '#FA7070' },


                                ]}
                                {...chartSetting}
                                margin={{ right: 15, left: 100 }}

                            />}
                    </div>

                </Box>

                <br />
            </div>

        </div>)

}


export default Dashbord_Factures_Previsionnel_Par_Periode;