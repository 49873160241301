import React, { useRef, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import {
	Box,
	Container,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyles";

const Fotter_Catalog_Pub = (props) => {

	const [partner_id, setpartner_id] = useState(props.partner_data);
	// Integration du gestionnaire de cookie : axeptio

	window.axeptioSettings = {
		clientId: "6273ecd99c50de9cfb190555",
		cookiesVersion: "mysy training technology-fr",
	};

	(function (d, s) {
		var t = d.getElementsByTagName(s)[0], e = d.createElement(s);

		e.async = true; e.src = "//static.axept.io/sdk-slim.js";
		t.parentNode.insertBefore(e, t);
	})(document, "script");

	function Display_CGV_File() {

		const downloadLink = document.createElement("a");
		const fileName = "file.pdf";
		downloadLink.href = props.partner_data.catalog_pub_cgv_pdf;

		downloadLink.download = fileName;
		downloadLink.click();
	}

	function Display_CGU_File() {
		const downloadLink = document.createElement("a");
		const fileName = "file.pdf";
		downloadLink.href = props.partner_data.catalog_pub_cgu_pdf;
		downloadLink.download = fileName;
		downloadLink.click();
	}

	function Display_Mention_Legale_File() {
		const downloadLink = document.createElement("a");
		const fileName = "file.pdf";
		downloadLink.href = props.partner_data.catalog_pub_mention_legale_pdf;
		downloadLink.download = fileName;
		downloadLink.click();
	}


	return (
		<div className='footer_catalog_pub'>
			<div className='Box_div' style={{ "minHeight": "5rem", "width": "100%" }}>
				&nbsp;
			</div>

			<div className='Box_div'>

				<hr />
				<i className='copyright'>
					Copyright © 2024 {props.partner_data.nom} . Tous droits réservés. &nbsp;
					
					<a> <nav onClick={Display_Mention_Legale_File} >| Mentions légales </nav> </a>
					<a> <nav onClick={Display_CGU_File} >| Conditions générales d’utilisation </nav> </a>
					<a><nav onClick={Display_CGV_File} > | Conditions générales de vente </nav> </a>
				</i>
			</div>

		</div>

	);
};
export default Fotter_Catalog_Pub;
