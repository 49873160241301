import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import Autocomplete from '@mui/material/Autocomplete';
import { gridClasses } from '@mui/x-data-grid';
import { PiDotsThree } from "react-icons/pi";
import Link from '@mui/material/Link';
import { LiaSave } from "react-icons/lia";
import Checkbox from '@mui/material/Checkbox';
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";

const Partner_Config_Formulaires = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selectionModel_question, setSelectionModel_question] = React.useState([]);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState("");

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState("");

    const [isLoading, setLoading] = useState();

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'code', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'nom', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'type', headerName: 'Type', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'description', headerName: 'description', flex: 1, hide: true, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'commentaire', headerName: 'commentaire', flex: 1, hide: true, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le formulaire sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {

                                            Delete_One_Formulaire(cellValues.row._id);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]



    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }



    const columns_question = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },

        { field: 'list_choix', headerName: 'Liste Choix', hide: true },
        { field: 'list_case_cocher', headerName: 'Liste C.A.C', hide: true },
        { field: 'question', headerName: 'Question', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'type', headerName: 'Type', flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'max_note', headerName: 'Note MAx', flex: 1, hide: true, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        {
            field: 'is_statistic', headerName: 'Statistique', flex: 1, hide: false, editable: false,
            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {String(cellValues.row.is_statistic) === "1" && "Oui"}&nbsp;
                        {String(cellValues.row.is_statistic) !== "1" && "Non"}&nbsp;

                    </div>

                );
            },

        },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (


                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la question sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {

                                            Delete_One_Question(cellValues.row._id);

                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>



                );
            }
        }

    ]

    const myRef = useRef(null)


    const myRef_head = useRef(null);

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const [Dialog_Question_message, setDialog_Question_message] = React.useState(false);
    const [Dialog_Question_open, setDialog_Question_open] = React.useState(false);
    function Dialog_Question_handle_change_participant_session(message) {
        setDialog_Question_message(message);
        setDialog_Question_open(true);
    }

    const Dialog_Question_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_Question_handleClose_buton = () => {
        setDialog_Question_open(false);
    };


    const [New_Getall_Partner_Formulaires, setNew_Getall_Partner_Formulaires] = useState([]);

    const [Getall_Partner_Formulaires_api, setGetall_Partner_Formulaires_api] = useState();
    const [Getall_Partner_Formulaires_message, setGetall_Partner_Formulaires_message] = useState();
    const [Getall_Partner_Formulaires_result, setGetall_Partner_Formulaires_result] = useState([]);
    function Getall_Partner_Formulaires(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Formulaire/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Formulaires  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Formulaires  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Formulaires_api("true");
                setGetall_Partner_Formulaires_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " " + local_nom,
                        "nom": local_nom,
                        "code": local_code,
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Formulaires(new_data2);


            }
            else {
                setGetall_Partner_Formulaires_api("false");
                setGetall_Partner_Formulaires_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Formulaires = ', error);
            setGetall_Partner_Formulaires_api("false");
            alert(" Impossible de recuperer la liste des formulaires");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Partner_Formulaires_with_Filter_api, setGetall_Partner_Formulaires_with_Filter_api] = useState();
    const [Getall_Partner_Formulaires_with_Filter_message, setGetall_Partner_Formulaires_with_Filter_message] = useState();
    const [Getall_Partner_Formulaires_with_Filter_result, setGetall_Partner_Formulaires_with_Filter_result] = useState([]);
    function Getall_Partner_Formulaires_with_Filter(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Formulaire_with_filter/";


        setadd_new_form("");
        setdisplay_form("");
        clear_detail_field();

        setSelectionModel([]);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Formulaires_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Formulaires_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Formulaires_with_Filter_api("true");
                setGetall_Partner_Formulaires_with_Filter_result(res.data.message);


                Getall_Partner_Formulaires_result(res.data.message);  // Normal ici on utilise bien le 'Getall_Partner_Formulaires_result' car c'est lui qui est affiché dans le datagrid
            }
            else {
                setGetall_Partner_Formulaires_with_Filter_api("false");
                setGetall_Partner_Formulaires_with_Filter_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Formulaires_with_Filter = ', error);
            setGetall_Partner_Formulaires_with_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }


    function clean_all_filters() {
        setgridline_id('');
        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");

        setadd_new_form("");
        setdisplay_form("");
        setform_edit_mode("");
        setconfig_data_changed("");

        clear_detail_field();
        setSelectionModel([]);
        setsubmenu("");

        Getall_Partner_Formulaires();
    }



    const [p_detail_from_code, setp_detail_from_code] = useState("");
    const [p_detail_from_nom, setp_detail_from_nom] = useState("");
    const [p_detail_from_description, setp_detail_from_description] = useState("");
    const [p_detail_from_commentaire, setp_detail_from_commentaire] = useState("");
    const [p_detail_from_list_question, setp_detail_from_list_question] = useState([]);

    const [p_detail_from_list_check_box, setp_detail_from_list_check_box] = useState([]);


    const [p_detail_from_message_intro, setp_detail_from_message_intro] = useState("");
    const [p_detail_from_type, setp_detail_from_type] = useState("");


    const [p_detail_one_from_code, setp_detail_one_from_code] = useState("");
    const [p_detail_one_from_nom, setp_detail_one_from_nom] = useState("");
    const [p_detail_one_from_description, setp_detail_one_from_description] = useState("");
    const [p_detail_one_from_commentaire, setp_detail_one_from_commentaire] = useState("");
    const [p_detail_one_from_list_question, setp_detail_one_from_list_question] = useState([]);

    const [p_detail_one_from_message_intro, setp_detail_one_from_message_intro] = useState("");
    const [p_detail_one_from_type, setp_detail_one_from_type] = useState("");

    const [add_new_form, setadd_new_form] = useState("");
    const [display_form, setdisplay_form] = useState("");
    const [form_edit_mode, setform_edit_mode] = useState("");
    const [config_data_changed, setconfig_data_changed] = useState("");



    const [Get_Given_Formulaire_Data_api, setGet_Given_Formulaire_Data_api] = useState();
    const [Get_Given_Formulaire_Data_message, setGet_Given_Formulaire_Data_message] = useState();
    const [Get_Given_Formulaire_Data_result, setGet_Given_Formulaire_Data_result] = useState();
    function Get_Given_Formulaire_Data(local_from_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("form_id", local_from_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Formulaire/";


        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Given_Formulaire_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Formulaire_Data  res.data.message r_class = " + res.data.message);
                setdisplay_form("1");
                setGet_Given_Formulaire_Data_api("true");
                setGet_Given_Formulaire_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_form_data = JSON.parse(res.data.message);

                    setp_detail_from_code("");
                    if (mylocal_form_data.code)
                        setp_detail_from_code(mylocal_form_data.code);

                    setp_detail_from_type("");
                    if (mylocal_form_data.type)
                        setp_detail_from_type(mylocal_form_data.type);

                    setp_detail_from_nom("");
                    if (mylocal_form_data.nom)
                        setp_detail_from_nom(mylocal_form_data.nom);

                    setp_detail_from_description("");
                    if (mylocal_form_data.description)
                        setp_detail_from_description(mylocal_form_data.description);

                    setp_detail_from_commentaire("");
                    if (mylocal_form_data.commentaire)
                        setp_detail_from_commentaire(mylocal_form_data.commentaire);

                    setp_detail_from_message_intro("");
                    if (mylocal_form_data.message_introduction)
                        setp_detail_from_message_intro(mylocal_form_data.message_introduction);


                    setp_detail_from_list_check_box([]);
                    if (mylocal_form_data.list_case_cocher) {
                        setp_detail_from_list_check_box(mylocal_form_data.list_case_cocher);
                    }

                    setp_detail_from_list_question([]);
                    if (mylocal_form_data.list_questions) {
                        setp_detail_from_list_question(mylocal_form_data.list_questions);
                    }



                    disable_formulaire_fields();
                }
                else {
                    alert("Aucune information récuperée");
                }

            }
            else {
                setGet_Given_Formulaire_Data_api("false");
                setGet_Given_Formulaire_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Formulaire_Data = ', error);
            setGet_Given_Formulaire_Data_api("false");
            alert(" Impossible de recuperer les données du formulaire");
            //setmyApimyApiMessage("")
        })
    }



    function disable_formulaire_fields() {
        setform_edit_mode("0");

        if (document.getElementsByName("detail_config_code") && document.getElementsByName("detail_config_code")[0]) {
            document.getElementsByName("detail_config_code")[0].disabled = true;
            document.getElementsByName("detail_config_code")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_type") && document.getElementsByName("detail_config_type")[0]) {
            document.getElementsByName("detail_config_type")[0].disabled = true;
            document.getElementsByName("detail_config_type")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_config_nom") && document.getElementsByName("detail_config_nom")[0]) {
            document.getElementsByName("detail_config_nom")[0].disabled = true;
            document.getElementsByName("detail_config_nom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_config_description") && document.getElementsByName("detail_config_description")[0]) {
            document.getElementsByName("detail_config_description")[0].disabled = true;
            document.getElementsByName("detail_config_description")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_commentaire") && document.getElementsByName("detail_config_commentaire")[0]) {
            document.getElementsByName("detail_config_commentaire")[0].disabled = true;
            document.getElementsByName("detail_config_commentaire")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_message_intro") && document.getElementsByName("detail_config_message_intro")[0]) {
            document.getElementsByName("detail_config_message_intro")[0].disabled = true;
            document.getElementsByName("detail_config_message_intro")[0].style.backgroundColor = "#ECEFF1";
        }
    }



    function enable_formulaire_fields() {
        setform_edit_mode("1");

        if (document.getElementsByName("detail_config_code") && document.getElementsByName("detail_config_code")[0]) {
            document.getElementsByName("detail_config_code")[0].disabled = false;
            document.getElementsByName("detail_config_code")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_type") && document.getElementsByName("detail_config_type")[0]) {
            document.getElementsByName("detail_config_type")[0].disabled = false;
            document.getElementsByName("detail_config_type")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_config_nom") && document.getElementsByName("detail_config_nom")[0]) {
            document.getElementsByName("detail_config_nom")[0].disabled = false;
            document.getElementsByName("detail_config_nom")[0].style.backgroundColor = "#FFFFFF";
        }


        if (document.getElementsByName("detail_config_description") && document.getElementsByName("detail_config_description")[0]) {
            document.getElementsByName("detail_config_description")[0].disabled = false;
            document.getElementsByName("detail_config_description")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_commentaire") && document.getElementsByName("detail_config_commentaire")[0]) {
            document.getElementsByName("detail_config_commentaire")[0].disabled = false;
            document.getElementsByName("detail_config_commentaire")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_message_intro") && document.getElementsByName("detail_config_message_intro")[0]) {
            document.getElementsByName("detail_config_message_intro")[0].disabled = false;
            document.getElementsByName("detail_config_message_intro")[0].style.backgroundColor = "#FFFFFF";
        }
    }


    const [gridline_id, setgridline_id] = useState("");

    useEffect(() => {

        Getall_Partner_Formulaires();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }, [])

    const [selected_id, setselected_id] = useState("");

    function handleClick_edit_config_From_Line(selected_row_id) {


        var line = JSON.parse(Getall_Partner_Formulaires_result[selected_row_id]);

        setselected_id(line._id);

        setadd_new_form("");
        setform_edit_mode("");
        setconfig_data_changed("");
        setdisplay_form("1");

        Get_Given_Formulaire_Data(line._id);

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function clear_detail_field() {
        setp_detail_from_code('');
        setp_detail_from_nom('');
        setp_detail_from_description('');
        setp_detail_from_commentaire('');
        setp_detail_from_list_question([]);
    }

    function clear_one_detail_field() {
        setp_detail_one_from_code('');
        setp_detail_one_from_nom('');
        setp_detail_one_from_description('');
        setp_detail_one_from_commentaire('');
        setp_detail_one_from_list_question([]);
    }

    const New_Option_Filter = [
        { "id": "code", "label": "Code", "value": "code" },
        { "id": "nom", "label": "Nom", "value": "nom" },
        { "id": "description", "label": "Description", "value": "description" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]



    const New_Option_type_Question = [
        { "id": "text", "label": "Text", "value": "text" },
        { "id": "note", "label": "Note ", "value": "note" },
        { "id": "bool", "label": "Oui/Non", "value": "bool" },
        { "id": "entier", "label": "Entier", "value": "entier" },
        { "id": "qcm", "label": "Qcm", "value": "qcm" },
        { "id": "checkbox", "label": "Case à cocher", "value": "checkbox" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_type_Formulaire = [
        { "id": "pos", "label": "Q. Positionnement", "value": "pos" },
        { "id": "hot_eval", "label": "Eval. Chaud ", "value": "hot_eval" },
        { "id": "cold_eval", "label": "Eval. Froid", "value": "cold_eval" },
        { "id": "human_eval", "label": "Eval. Employé", "value": "human_eval" },
        { "id": "autre_eval", "label": "Autre", "value": "autre_eval" },
        { "id": "", "label": "", "value": "" },
    ]

    function annule_edit_formulaire() {
        clear_detail_field();
        setadd_new_form("");
        setform_edit_mode("");
        setconfig_data_changed("");
        setdisplay_form("1");

        Get_Given_Formulaire_Data(selected_id);
    }


    const [Update_formlaire_data_api, setUpdate_formlaire_data_api] = useState();
    const [Update_formlaire_data_message, setUpdate_formlaire_data_message] = useState();
    const [Update_formlaire_data_result, setUpdate_formlaire_data_result] = useState();
    function Update_formlaire_data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_from_code);
        form.append("nom", p_detail_from_nom);
        form.append("description", p_detail_from_description);
        form.append("commentaire", p_detail_from_commentaire);
        form.append("message_introduction", p_detail_from_message_intro);
        form.append("type", p_detail_from_type);

        form.append("form_id", selected_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Formulaire/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Update_formlaire_data  res.data.status = " + res.data.status);
            //console.log(" In Update_formlaire_data  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_formlaire_data_api("true");
                setUpdate_formlaire_data_result(res.data.message);

                clean_all_filters();

                Getall_Partner_Formulaires();


                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_formlaire_data_api("false");
                setUpdate_formlaire_data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_formlaire_data = ' + error);
            setUpdate_formlaire_data_api("false");
            alert(" Impossible de mettre à jour le formulaire ");

        })
    }

    const [Delete_One_Formulaire_api, setDelete_One_Formulaire_api] = useState();
    const [Delete_One_Formulaire_message, setDelete_One_Formulaire_message] = useState();
    const [Delete_One_Formulaire_result, setDelete_One_Formulaire_result] = useState();
    function Delete_One_Formulaire(local_form_id) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("form_id", local_form_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Formulaire/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_One_Formulaire  res.data.status = " + res.data.status);
            //console.log(" In Delete_One_Formulaire  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_One_Formulaire_api("true");
                setDelete_One_Formulaire_result(res.data.message);

                clean_all_filters();

                Getall_Partner_Formulaires();


                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setDelete_One_Formulaire_api("false");
                setDelete_One_Formulaire_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Delete_One_Formulaire = ' + error);
            setDelete_One_Formulaire_api("false");
            alert(" Impossible de supprimer le formulaire ");

        })
    }

    function annule_add_new_formulaire() {
        clear_one_detail_field();
        setadd_new_form("");
        setdisplay_form("");
        setform_edit_mode("");
        setconfig_data_changed("");
    }

    const [Add_formlaire_data_api, setAdd_formlaire_data_api] = useState();
    const [Add_formlaire_data_message, setAdd_formlaire_data_message] = useState();
    const [Add_formlaire_data_result, setAdd_formlaire_data_result] = useState();
    function Add_formlaire_data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_one_from_code);
        form.append("nom", p_detail_one_from_nom);
        form.append("description", p_detail_one_from_description);
        form.append("commentaire", p_detail_one_from_commentaire);
        form.append("message_introduction", p_detail_one_from_message_intro);
        form.append("type", p_detail_one_from_type);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Formulaire/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Add_formlaire_data  res.data.status = " + res.data.status);
            //console.log(" In Add_formlaire_data  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_formlaire_data_api("true");
                setAdd_formlaire_data_result(res.data.message);

                clear_one_detail_field();

                Getall_Partner_Formulaires();

                setadd_new_form("");
                setdisplay_form("");
                setform_edit_mode("");
                setconfig_data_changed("");


                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_formlaire_data_api("false");
                setAdd_formlaire_data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_formlaire_data = ' + error);
            setAdd_formlaire_data_api("false");
            alert(" Impossible d'ajouter le formulaire ");

        })
    }


    const [selected_question_id, setselected_question_id] = useState("");
    const [question_question, setquestion_question] = useState("");
    const [question_type, setquestion_type] = useState("");
    const [question_max_note, setquestion_max_note] = useState("2");


    function Ajouter_Question() {
        setselected_question_id("");
        setquestion_question("");
        setquestion_type("");
        setquestion_max_note("2");
        setDialog_Question_open(true);

        settab_qcm_choix([]);
        settab_checkbox_choix([]);

    }

    const [Add_Update_One_Question_api, setAdd_Update_One_Question_api] = useState();
    const [Add_Update_One_Question_message, setAdd_Update_One_Question_message] = useState();
    const [Add_Update_One_Question_result, setAdd_Update_One_Question_result] = useState();
    const Add_Update_One_Question = event => {


        if (String(selected_id).length < 2) {
            alert(" Vous devez choisir un formulaire ");
            return;
        }


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("form_id", selected_id);
        form.append("question", question_question);
        form.append("type", question_type);
        form.append("max_note", question_max_note);

        if (String(question_type) === "note") {

            if( String(question_max_note) === ""){
                alert(" La note maximale doit être un entier supérieur à 1");
                return;  
            }

            if (isNaN(Number(question_max_note))) {
                alert(" La note maximale doit être un entier supérieur à 1");
                return;
            }
        }


        if (statistic_checked === true) {
            form.append("is_statistic", "1");
        } else {
            form.append("is_statistic", "0");
        }


        var local_list_choix = []
        for (let i = 0; i < tab_qcm_choix.length; i++) {
            var choix = "reponse";
            var val = String(tab_qcm_choix[i])
            var node = {};
            node[choix] = val;
            local_list_choix.push(node);
        }
        form.append("list_choix", JSON.stringify(local_list_choix));


        var local_list_checkbox = []
        for (let i = 0; i < tab_checkbox_choix.length; i++) {
            var choix = "reponse";
            var val = String(tab_checkbox_choix[i])
            var node = {};
            node[choix] = val;
            local_list_checkbox.push(node);
        }
        form.append("list_case_cocher", JSON.stringify(local_list_checkbox));



        if (String(selected_question_id).length > 2) {
            form.append("question_id", selected_question_id);
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Question_Formulaire/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_One_Question  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_One_Question  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_One_Question_api("true");
                setAdd_Update_One_Question_result(res.data.message);

                setselected_question_id("");
                setquestion_question("");
                setquestion_type("");
                setquestion_max_note("2");
                settab_qcm_choix([]);
                setadd_new_qcm_choix("");
                setnew_qcm_choix_value("");
                setstatistic_checked(false);

                Get_Given_Formulaire_Data(selected_id);

                if (document.getElementById('myRef')) {
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


                alert(res.data.message);
                setDialog_Question_open(false);

            }
            else {
                setAdd_Update_One_Question_api("false");
                setAdd_Update_One_Question_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_One_Question = ' + error);
            setAdd_Update_One_Question_api("false");
            alert(" Impossible de mettre à jour la question ");

        })
    }


    const [Delete_One_Question_api, setDelete_One_Question_api] = useState();
    const [Delete_One_Question_message, setDelete_One_Question_message] = useState();
    const [Delete_One_Question_result, setDelete_One_Question_result] = useState();
    function Delete_One_Question(local_question_id) {


        if (String(selected_id).length < 2) {
            alert(" Vous devez choisir un formulaire ");
            return;
        }

        if (String(local_question_id).length < 2) {
            alert(" Vous devez choisir une question  ");
            return;
        }


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("form_id", selected_id);
        form.append("question_id", local_question_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Question_Formulaire/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_One_Question  res.data.status = " + res.data.status);
            //console.log(" In Delete_One_Question  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDelete_One_Question_api("true");
                setDelete_One_Question_result(res.data.message);

                setselected_question_id("");
                setquestion_question("");
                setquestion_type("");
                setquestion_max_note("2");

                Get_Given_Formulaire_Data(selected_id);

                if (document.getElementById('myRef')) {
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


                alert(res.data.message);
                setDialog_Question_open(false);

            }
            else {
                setDelete_One_Question_api("false");
                setDelete_One_Question_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Delete_One_Question = ' + error);
            setDelete_One_Question_api("false");
            alert(" Impossible de supprimer la question ");

        })
    }


    const [nb_qcm_choix, setnb_qcm_choix] = useState(3);

    const [tab_qcm_choix, settab_qcm_choix] = useState([]);
    const [add_new_qcm_choix, setadd_new_qcm_choix] = useState("");
    const [new_qcm_choix_value, setnew_qcm_choix_value] = useState("");

    function add_new_list_to_qcm() {
        var current_tab_qcm_choix = tab_qcm_choix;
        current_tab_qcm_choix.push(new_qcm_choix_value);
        settab_qcm_choix(current_tab_qcm_choix);
        setnew_qcm_choix_value("");
    }


    function remove_last_list_to_qcm() {
        var current_tab_qcm_choix = tab_qcm_choix;

        var new_current_tab_qcm_choix = [];
        for (let i = 0; i < current_tab_qcm_choix.length - 1; i++)
            new_current_tab_qcm_choix.push(current_tab_qcm_choix[i]);

        current_tab_qcm_choix.pop();
        setnew_qcm_choix_value("");
        settab_qcm_choix(new_current_tab_qcm_choix);
    }


    const [tab_checkbox_choix, settab_checkbox_choix] = useState([]);
    const [add_new_checkbox_choix, setadd_new_checkbox_choix] = useState("");
    const [new_checkbox_choix_value, setnew_checkbox_choix_value] = useState("");

    function add_new_list_to_checkbox() {
        var current_tab_checkbox_choix = tab_checkbox_choix;
        current_tab_checkbox_choix.push(new_checkbox_choix_value);
        settab_checkbox_choix(current_tab_checkbox_choix);
        setnew_checkbox_choix_value("");
    }


    function remove_last_list_to_checkbox() {
        var current_tab_checkbox_choix = tab_checkbox_choix;

        var new_current_tab_checkbox_choix = [];
        for (let i = 0; i < current_tab_checkbox_choix.length - 1; i++)
            new_current_tab_checkbox_choix.push(current_tab_checkbox_choix[i]);

        current_tab_checkbox_choix.pop();
        setnew_checkbox_choix_value("");
        settab_checkbox_choix(new_current_tab_checkbox_choix);
    }


    const [statistic_checked, setstatistic_checked] = React.useState(false);

    const handleChange_statistic_checked = (event) => {
        setstatistic_checked(event.target.checked);
    };

    return (
        <div className="partner_configuration_technique">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            {/**** Dialog pour gestion d'une question */}

            <Dialog
                open={Dialog_Question_open}
                onClose={Dialog_Question_handleClose}
                className="displaypartnersession"

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "25rem" }}>

                    <DialogContentText>
                        {Dialog_Question_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > La question
                        <TextField
                            autoFocus
                            margin="dense"
                            id="field_question_question"
                            name="field_question_question"

                            fullWidth
                            //variant="standard"
                            value={question_question}
                            onChange={(e) => {
                                setquestion_question(e.target.value);

                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Type

                        {New_Option_type_Question && New_Option_type_Question.length > 0 &&

                            <Autocomplete
                                disablePortal
                                id="field_question_type"
                                name="field_question_type"

                                fullWidth

                                options={New_Option_type_Question}
                                value={New_Option_type_Question.filter((data) => (data).value === String(question_type))[0].label}

                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setquestion_type(value.value);
                                    } else {
                                        setquestion_type("");
                                    }

                                    // Reinitialisation du "statistique"
                                    setstatistic_checked(false);
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}
                    </div>


                    {String(question_type) === "note" && <div className="session_caract_Dialog" > Note sur ?
                        <TextField
                            autoFocus
                            margin="dense"
                            id="field_question_question"
                            name="field_question_question"

                            fullWidth
                            type="number"
                            InputProps={{ inputProps: { min: 2, max: 21 } }}
                            value={question_max_note}
                            onChange={(e) => {

                                if (!isNaN(Number((e.target.value)))) {
                                    setquestion_max_note(e.target.value);
                                }

                            }
                            }
                        />



                    </div>}

                    {String(question_type) === "qcm" && <div className="session_caract_Dialog" > QCM

                        {tab_qcm_choix &&
                            tab_qcm_choix.map((choix) => (
                                <div>

                                    <TextField

                                        value={choix}
                                        autoFocus
                                        margin="dense"
                                        id="field_question_qcm"
                                        name="field_question_qcm"

                                        fullWidth
                                        onChange={(e) => {
                                            // setquestion_max_note(e.target.value);

                                        }
                                        }
                                    />
                                </div>
                            ))}
                        <nav className="session_caract_Dialog_icone_bton_add_reduce" onClick={(event) => {
                            setadd_new_qcm_choix("1")
                        }}>
                            <IoMdAddCircleOutline /> Ajouter un choix</nav>

                        <nav className="session_caract_Dialog_icone_bton_add_reduce" onClick={(event) => {
                            remove_last_list_to_qcm();
                            setadd_new_qcm_choix("");
                        }} > <IoMdRemoveCircleOutline />  Supprimer un choix</nav>

                        {add_new_qcm_choix && String(add_new_qcm_choix) === "1" &&

                            <div>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="field_question_qcm_new"
                                    name="field_question_qcm_new"

                                    fullWidth

                                    value={new_qcm_choix_value}
                                    onChange={(e) => {
                                        setnew_qcm_choix_value(e.target.value);

                                    }
                                    }
                                />
                                <nav className="session_caract_Dialog_icone_bton_add_reduce" onClick={(event) => {
                                    setadd_new_qcm_choix("");
                                    add_new_list_to_qcm();
                                }}>   <LiaSave /> Enregistrer
                                </nav>
                            </div>
                        }

                    </div>}



                    {String(question_type) === "checkbox" && <div className="session_caract_Dialog" > checkbox

                        {tab_checkbox_choix &&
                            tab_checkbox_choix.map((choix) => (
                                <div>

                                    <TextField

                                        value={choix}
                                        autoFocus
                                        margin="dense"
                                        id="field_question_checkbox"
                                        name="field_question_checkbox"

                                        fullWidth
                                        onChange={(e) => {
                                            // setquestion_max_note(e.target.value);

                                        }
                                        }
                                    />
                                </div>
                            ))}
                        <nav className="session_caract_Dialog_icone_bton_add_reduce" onClick={(event) => {
                            setadd_new_checkbox_choix("1")
                        }}>
                            <IoMdAddCircleOutline /> Ajouter un choix</nav>

                        <nav className="session_caract_Dialog_icone_bton_add_reduce" onClick={(event) => {
                            remove_last_list_to_checkbox();
                            setadd_new_checkbox_choix("");
                        }} > <IoMdRemoveCircleOutline />  Supprimer un choix</nav>

                        {add_new_checkbox_choix && String(add_new_checkbox_choix) === "1" &&

                            <div>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="field_question_checkbox_new"
                                    name="field_question_checkbox_new"

                                    fullWidth

                                    value={new_checkbox_choix_value}
                                    onChange={(e) => {
                                        setnew_checkbox_choix_value(e.target.value);

                                    }
                                    }
                                />
                                <nav className="session_caract_Dialog_icone_bton_add_reduce" onClick={(event) => {
                                    setadd_new_checkbox_choix("");
                                    add_new_list_to_checkbox();
                                }}>   <LiaSave /> Enregistrer
                                </nav>
                            </div>
                        }

                    </div>}


                    {(String(question_type) === "note" || String(question_type) === "bool" || String(question_type) === "entier") && <div className="session_caract_Dialog" >

                        <Tooltip className="tooltip_css" id="statistic_01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="statistic_01" data-tooltip-html="En cochant cette case, le système va créer une statistique associée à la question.">

                            <nav style={{ width: "100%", float: "left" }}>

                                <nav style={{ width: "75%", float: "left", "marginTop": '0.5rem' }}>
                                    Créer une statistique &nbsp;<FcInfo /> &nbsp;  &nbsp;  &nbsp;
                                </nav>
                                <nav style={{ width: "20%", float: "left", "textAlign": "right" }}>

                                    <Checkbox
                                        checked={statistic_checked}
                                        onChange={handleChange_statistic_checked}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </nav>
                            </nav>
                        </a>

                    </div>}

                </DialogContent>

                <DialogActions>


                    <div className="div_row">
                        {String(selected_question_id) === "" && <div className="div_row_gauche">  <Button onClick={Add_Update_One_Question} className="bton_enreg_dialog">Ajouter</Button> </div>}
                        {String(selected_question_id) !== "" && <div className="div_row_gauche">  <Button onClick={Add_Update_One_Question} className="bton_enreg_dialog">Mettre à jour</Button> </div>}

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_Question_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>
            </Dialog>
            {/***  End Dialog Question*/}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            <h3> Votre configuration des formulaires </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value("");
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Partner_Formulaires_with_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>


            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },

                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },



                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);

                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Getall_Partner_Formulaires_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            code: JSON.parse(item).code,
                                            nom: JSON.parse(item).nom,
                                            description: JSON.parse(item).description,
                                            commentaire: JSON.parse(item).commentaire,
                                            type: JSON.parse(item).type,
                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"
                                    disableSelectionOnClick
                                    onRowDoubleClick={(newSelectionModel) => {

                                        setgridline_id(newSelectionModel.row.id);
                                        handleClick_edit_config_From_Line(newSelectionModel.row.id);

                                    }}

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {


                                        //field === "order_header_status"
                                        if (params.field === "order_header_status" && String(params.value) == "0") {
                                            return 'cell--status--brouillon';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "1") {

                                            return 'cell--status--encours';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "2") {
                                            return 'cell--status--traite';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "3") {
                                            return 'cell--status--facture';
                                        }

                                    }}

                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }
                                    }}

                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}

                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>
                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                        <Button variant="outlined" onClick={(event) => {
                            setadd_new_form("1");
                            setdisplay_form("");
                            setform_edit_mode("");
                            clear_detail_field();
                            if (document.getElementById('myRef')) {
                                var divh = document.getElementById('myRef').offsetTop;
                                window.scrollTo({
                                    top: divh,
                                    behavior: "smooth",
                                });
                            }

                        }}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout un formulaire &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>
                    </div>
                </div>
                <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
            </div>

            {
                String(add_new_form) !== "1" && String(display_form) === "1" && String(form_edit_mode) !== "1" && <div className="div_row">

                    <div className="session_data">
                        <br />

                        <div className="session_caract"> Code <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_code"
                                id="detail_config_code"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_code}
                            />
                        </div>


                        {New_Option_type_Formulaire.length > 0 && <div className="session_caract"> Type <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_type"
                                id="detail_config_type"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={New_Option_type_Formulaire.filter((data) => (data).value === String(p_detail_from_type))[0].label}
                            />
                        </div>}




                        <div className="session_caract"> Nom <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_nom"
                                id="detail_config_nom"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_nom}
                            />
                        </div>

                        <div className="session_caract"> Description <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_description"
                                id="detail_config_description"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_description}
                            />
                        </div>

                        <div className="session_caract"> Message d'introduction <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_message_intro"
                                id="detail_config_message_intro"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_message_intro}
                            />
                        </div>


                        <div className="session_caract"> Commentaire <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_commentaire"
                                id="detail_config_commentaire"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_commentaire}
                            />
                        </div>


                        <div className="div_row" style={{ "padding": "10px" }}>


                            <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                <Button className="bton_emarge" style={{ "minWidth": "10rem" }} onClick={(event) => {
                                    Ajouter_Question();
                                }}>Ajout 1 Question </Button>
                            </div>

                            {p_detail_from_list_question && p_detail_from_list_question.length > 0 && <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',

                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        }, [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel_question(newSelectionModel);

                                        }}
                                        selectionModel={selectionModel}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={p_detail_from_list_question.map((item, index) => (
                                            {
                                                id: index,
                                                _id: (item)._id,
                                                question: (item).question,
                                                type: (item).type,
                                                max_note: (item).max_note,
                                                list_choix: (item).list_choix,
                                                list_case_cocher: (item).list_case_cocher,
                                                is_statistic: (item).is_statistic,



                                            }
                                        ))}

                                        columns={columns_question}
                                        pageSize={10}
                                        className="datagridclass"
                                        disableSelectionOnClick
                                        onRowDoubleClick={(newSelectionModel) => {

                                            setselected_question_id(newSelectionModel.row._id);
                                            setquestion_question(newSelectionModel.row.question);
                                            setquestion_type(newSelectionModel.row.type);
                                            setquestion_max_note(newSelectionModel.row.max_note);
                                            if (newSelectionModel.row.is_statistic === "1") {
                                                setstatistic_checked(true);
                                            } else {
                                                setstatistic_checked(false);
                                            }

                                            if (newSelectionModel.row.list_choix) {
                                                var tmp_tab = []
                                                for (let i = 0; i < newSelectionModel.row.list_choix.length; i++) {
                                                    tmp_tab.push(newSelectionModel.row.list_choix[i]['reponse_qcm']);
                                                }
                                                settab_qcm_choix(tmp_tab);
                                            }


                                            if (newSelectionModel.row.list_case_cocher) {
                                                var tmp_tab_checkbox = []
                                                for (let i = 0; i < newSelectionModel.row.list_case_cocher.length; i++) {
                                                    tmp_tab_checkbox.push(newSelectionModel.row.list_case_cocher[i]['reponse_checkbox']);

                                                }

                                                settab_checkbox_choix(tmp_tab_checkbox);
                                            }

                                            setDialog_Question_open(true);

                                        }}

                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}

                                        getRowClassName={(params) => {
                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }
                                        }}

                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}
                                        sx={{
                                            "& .MuiDataGrid-cellContent": {
                                                minHeight: 50,

                                            }
                                        }}

                                    />
                                </Box>
                                <br />

                            </div>}
                        </div>
                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                <Button variant="contained" className="bton_edit" onClick={enable_formulaire_fields}>Editer
                                </Button>

                            </div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>


                    </div>

                </div>
            }

            {
                String(add_new_form) !== "1" && String(display_form) === "1" && String(form_edit_mode) === "1" && <div className="div_row">

                    <div className="session_data">
                        <br />

                        <div className="session_caract"> Code <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_code"
                                id="detail_config_code"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_code}
                                onChange={(e) => {
                                    setp_detail_from_code(e.target.value);
                                    setconfig_data_changed("1");
                                }}
                            />
                        </div>


                        {String(form_edit_mode) === "1" && New_Option_type_Formulaire.length > 0 && <div className="session_caract"> Type <br />
                            <Autocomplete
                                disablePortal
                                name="detail_config_type"
                                id="detail_config_type"
                                className="disabled_style"

                                options={New_Option_type_Formulaire}
                                value={New_Option_type_Formulaire.filter((data) => (data).value === String(p_detail_from_type))[0].label}

                                onChange={(event, value) => {
                                    setconfig_data_changed("1");
                                    if (value && value.value) {
                                        setp_detail_from_type(value.value);
                                    } else {
                                        setp_detail_from_type("");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />
                        </div>}

                        <div className="session_caract"> Nom <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_nom"
                                id="detail_config_nom"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_nom}
                                onChange={(e) => {
                                    setp_detail_from_nom(e.target.value);
                                    setconfig_data_changed("1");
                                }}
                            />
                        </div>

                        <div className="session_caract"> Description <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_description"
                                id="detail_config_description"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_description}
                                onChange={(e) => {
                                    setp_detail_from_description(e.target.value);
                                    setconfig_data_changed("1");
                                }}
                            />
                        </div>

                        <div className="session_caract"> Message d'introduction <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_message_intro"
                                id="detail_config_message_intro"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_message_intro}
                                onChange={(e) => {
                                    setp_detail_from_message_intro(e.target.value);
                                    setconfig_data_changed("1");
                                }}
                                multiline={true}
                                rows={2}
                            />
                        </div>

                        <div className="session_caract"> Commentaire <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_commentaire"
                                id="detail_config_commentaire"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_from_commentaire}
                                onChange={(e) => {
                                    setp_detail_from_commentaire(e.target.value);
                                    setconfig_data_changed("1");
                                }}
                            />
                        </div>




                    </div>

                    <div className="div_row" style={{ "padding": "10px" }}>


                        <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                            <Button className="bton_emarge" style={{ "minWidth": "10rem" }} onClick={(event) => {
                                Ajouter_Question();
                            }}>Ajout 1 Question </Button>
                        </div>

                        {p_detail_from_list_question && p_detail_from_list_question.length > 0 && <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel_question(newSelectionModel);

                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={p_detail_from_list_question.map((item, index) => (
                                        {
                                            id: index,
                                            _id: (item)._id,
                                            question: (item).question,
                                            type: (item).type,
                                            max_note: (item).max_note,

                                        }
                                    ))}

                                    columns={columns_question}
                                    pageSize={10}
                                    className="datagridclass"
                                    disableSelectionOnClick
                                    onRowDoubleClick={(newSelectionModel) => {

                                        /* setgridline_id(newSelectionModel.row.id);
                                         handleClick_edit_config_From_Line(newSelectionModel.row.id);*/

                                    }}

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}

                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }
                                    }}

                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}

                                />
                            </Box>
                            <br />

                        </div>}
                    </div>
                    <div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                        {String(config_data_changed) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}
                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Update_formlaire_data}>Enregistrer les modifications
                                </Button>

                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={annule_edit_formulaire}>Annuler
                                </Button>
                            </div>
                        </div>



                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                </div>
            }

            {
                String(add_new_form) === "1" && <div className="div_row">

                    <div className="session_data">
                        Ajouter un nouveau formulaire
                        <br />

                        <div className="session_caract"> Code <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_code"
                                id="detail_config_code"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_one_from_code}
                                onChange={(e) => {
                                    setp_detail_one_from_code(e.target.value);

                                }}
                            />
                        </div>

                        {New_Option_type_Formulaire && <div className="session_caract"> Type <br />
                            <Autocomplete
                                disablePortal
                                name="detail_config_type"
                                id="detail_config_type"
                                className="disabled_style"

                                options={New_Option_type_Formulaire}
                                value={New_Option_type_Formulaire.filter((data) => (data).value === String(p_detail_one_from_type))[0].label}

                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_detail_one_from_type(value.value);
                                    } else {
                                        setp_detail_one_from_type("");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />
                        </div>}


                        <div className="session_caract"> Nom <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_nom"
                                id="detail_config_nom"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_one_from_nom}
                                onChange={(e) => {
                                    setp_detail_one_from_nom(e.target.value);

                                }}
                            />
                        </div>

                        <div className="session_caract"> Description <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_description"
                                id="detail_config_description"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_one_from_description}
                                onChange={(e) => {
                                    setp_detail_one_from_description(e.target.value);

                                }}
                            />
                        </div>

                        <div className="session_caract">  Message d'introduction <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_message_intro"
                                id="detail_config_message_intro"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_one_from_message_intro}
                                onChange={(e) => {
                                    setp_detail_one_from_message_intro(e.target.value);

                                }}
                            />
                        </div>


                        <div className="session_caract"> Commentaire <br />
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="detail_config_commentaire"
                                id="detail_config_commentaire"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_detail_one_from_commentaire}
                                onChange={(e) => {
                                    setp_detail_one_from_commentaire(e.target.value);

                                }}
                            />
                        </div>


                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                            <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                    <Button variant="contained" className="bton_enreg" onClick={Add_formlaire_data}>Enregistrer
                                    </Button>

                                </div>

                                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                    <Button variant="contained" className="bton_annule" onClick={annule_add_new_formulaire}>Annuler
                                    </Button>
                                </div>
                            </div>



                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>


                    </div>


                </div>
            }

        </div >
    )
}


export default Partner_Config_Formulaires;