import PartnerSignIn from "../components/PartnerSignIn";
import Navigation from "../components/Navigation";



export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
                <PartnerSignIn />
               
            </div>
        </div>
    );
}
