import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';

import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
    FcCancel, FcApproval, FcAcceptDatabase,
    FcPrint, FcExpired
} from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneSave } from "react-icons/ai";
import Module_Absence from "./Module_Absence";
import { RiMoreFill } from "react-icons/ri";
import { FcEditImage } from "react-icons/fc";
import { useHistory } from "react-router-dom";
import Module_Historique_Action from "./Module_Historique_Action";
import ToggleSwitch from "./ToggleSwitch";

import Autocomplete from '@mui/material/Autocomplete';
import { FcSearch, FcViewDetails, FcOpenedFolder } from "react-icons/fc";
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Module_Email_Management from "./Module_Email_Management";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
    GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton,
    GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridValueGetterParams,
    GridPreferencePanelsValue,

} from '@mui/x-data-grid';

import * as XLSX from 'xlsx';


const DisplayPartnerStagiaires = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [selectionModel_notes, setselectionModel_notes] = React.useState([]);


    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState("");

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState("");

    const filters = [
        { value: 'code_session', label: 'Code Session' },
        { value: 'class_title', label: 'Titre Formation' },
        { value: 'certif', label: 'Certification' }
    ]

    const [gridline_id, setgridline_id] = useState("");

    const [gridline_id_notes, setgridline_id_notes] = useState("");

    const [datagrid_columns_size_model1, setdatagrid_columns_size_model1] = useState(200);
    const [datagrid_columns_size_model2, setdatagrid_columns_size_model2] = useState(80);

    const columns = [
        { field: '_id', headerName: '_id', hide: true, editable: false, Width: 0, disableExport: true, },
        { field: 'session_id', headerName: 'session_id', hide: true, editable: false, Width: 0 , disableExport: true,},
        { field: 'id', headerName: 'id', hide: true, Width: 0 , disableExport: true,},
        { field: 'class_id', headerName: 'class_id', hide: true, Width: 0 , disableExport: true,},
        { field: 'class_internal_url', headerName: 'class_internal_url', hide: true, editable: false, Width: 0 , disableExport: true,},

        { field: 'telephone', headerName: 'Téléphone', hide: true, editable: false, Width: 0 },
        { field: 'date_naissance', headerName: 'date_naissance', hide: true, editable: false, Width: 0 },
        { field: 'adresse', headerName: 'adresse', hide: true, editable: false, Width: 0 },
        { field: 'code_postal', headerName: 'code_postal', hide: true, editable: false, Width: 0 },
        { field: 'ville', headerName: 'ville', hide: true, editable: false, Width: 0 },
        { field: 'pays', headerName: 'pays', hide: true, editable: false, Width: 0 },

        {
            field: 'warning_recyclage_alert', headerName: 'Alert', hide: false, Width: 150,


            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {cellValues.row.warning_recyclage_alert && String(cellValues.row.warning_recyclage_alert) === "1" && <nav>
                            <Tooltip className="tooltip_css" id="my_tooltip_alert_1" />
                            <a data-tooltip-id="my_tooltip_alert_1" data-tooltip-html={"Pensez au recyclage de l'apprenant"}>
                                <FcExpired />
                            </a>
                        </nav>}
                        {cellValues.row.warning_recyclage_alert && String(cellValues.row.warning_recyclage_alert) !== "1" && ""}

                    </div>

                );
            }

        },

        {
            field: 'civilite', headerName: 'Civ.', minWidth: 100, flex: 1, maxWidth: 100, hide: false, editable: false, editable: false,

            renderCell: (cellValues) => {
                return (

                    <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                        {New_civilite && New_civilite.length > 0 && cellValues.row.civilite &&
                            <nav>
                                {New_civilite.filter((data) => (data).id === String(cellValues.row.civilite))[0].label}

                            </nav>
                        }

                    </div>

                );
            },
        },



        { field: 'nom', headerName: 'Nom', minWidth: 200, flex: 1, hide: false, editable: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'Prénom', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'Email', minWidth: 250, flex: 1, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'employeur', headerName: 'Employeur', minWidth: 200, hide: true, editable: false },
        { field: 'client_nom', headerName: 'Client', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: 'client_facture', headerName: 'Client Fact.', minWidth: 200, flex: 1, hide: false, editable: false,



            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Get_List_Partner_Clients_result && New_Get_List_Partner_Clients_result.length > 0 && <nav>
                            {New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(cellValues.row.client_facture))[0].label}
                        </nav>}
                    </div>

                );
            }
        },


        {
            field: 'status', headerName: 'Statut', hide: false, width: datagrid_columns_size_model2,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "PréIns.";
                else if (String(params.value) === "1")
                    return "Inscrit";
                else if (String(params.value) === "-1")
                    return "Annulé";
                else if (String(params.value) === "2")
                    return "Encours";
                else
                    return "???";
            },
        },
        { field: 'code_session', headerName: 'Code session', hideable: true, editable: false, minWidth: 150, flex: 1 },
        { field: 'title', headerName: 'Formation', minWidth: datagrid_columns_size_model1, align: "center", hideable: true, editable: false, hide: true },
        {
            field: 'distantiel', headerName: 'Distantiel', width: datagrid_columns_size_model2,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";
                else
                    return "?";
            },
        },
        {
            field: 'presentiel', headerName: 'Presentiel', width: datagrid_columns_size_model2,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Non";
                else if (String(params.value) === "1")
                    return "Oui";
                else
                    return "?";
            },

        },
        { field: 'domaine', headerName: 'Domaine', minWidth: datagrid_columns_size_model2, align: "center", hide: true, hideable: true, editable: false },
        { field: 'date_debut', headerName: 'Du', minWidth: 90, maxWidth: 100, flex: 1, hideable: true, editable: false },
        { field: 'date_fin', headerName: 'Au', minWidth: 90, maxWidth: 100, flex: 1, hideable: true, editable: false },
        { field: 'class_internal_url', headerName: 'class_internal_url', hide: true, editable: false },

        {
            field: 'invoiced', headerName: 'Facturé', minWidth: 100, flex: 1, maxWidth: 150, hide: false, editable: false,
            valueFormatter: (params) => {
                if (String(params.value) === "1")
                    return "Oui";
                else
                    return "Non";
            },
        },
        { field: 'invoiced_ref', headerName: 'Num Fact.', minWidth: 100, flex: 1, maxWidth: 150, flex: 1, editable: false, hide: true, },
        { field: 'invoiced_date', headerName: 'Date Fact.', minWidth: 100, flex: 1, maxWidth: 150, flex: 1, editable: false, hide: true, },

        { field: 'class_recyclage_delai', headerName: 'Recycl. Delai', hide: true, flex: 1, minWidth: 100, },
        { field: 'class_recyclage_periodicite', headerName: 'Recycl. Perio', hide: true, flex: 1, minWidth: 100, },
        { field: 'nb_jour_avant_recyclage', headerName: 'Jrs avant Recycl.', hide: false, flex: 1, minWidth: 100, },


        {
            field: "Detail", headerName: 'Voir détail', disableExport: true,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            setuserimgclassprofil("");
                            setselected_row_data_json_age('');
                            setgridline_id(cellValues.row.id);
                            handleClick_edit_session_From_Line(cellValues.row.id);
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },
        {
            field: 'Action', headerName: 'Action', hide: false, minWidth: 100, maxWidth: 150, flex: 1, disableExport: true,
            renderCell: (cellValues) => {

                return (
                    <nav>
                        {cellValues.row.status && String(cellValues.row.status) === "1" &&

                            <Popup
                                trigger={<Button

                                >
                                    Annuler

                                </Button>}
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> définitivement annulée</font></i>. <br />
                                            Il (le stagiaire) sera automatiquement <i><font color="red"> notifié par email de l'annulation de son inscription. </font></i> <br />
                                            <br /> Motif du annulation : <br />
                                            <textarea name='motif_refus' id="motif_refus" style={{ "width": "80%" }}
                                                rows="3" maxlength="180" />

                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    handleClick_refuse(event, cellValues);
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                        }

                        {cellValues.row.status && String(cellValues.row.status) === "0" &&
                            <Popup
                                trigger={<Button


                                >
                                    <FcApproval /> Valider inscr.

                                </Button>}
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, l'inscription du stagiaire sera définitivement validée. <br />
                                            Il (le stagiaire) sera automatiquement notifié par email de la validation de son inscription. <br />
                                            Le stagiaire passera de l'onglet "préinscrit" vers l'onglet "inscription".<br />
                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    handleClick_accept(event, cellValues);
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        }

                        {/*cellValues.row.status && String(cellValues.row.status) === "-1" &&


                            <Popup
                                trigger={<Button

                                >
                                    <FcDownload />
                                    Réinscrire


                                </Button>}
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, le stagaire sera réinscrit à la meme session <br />
                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    //handleClick_accept(event, cellValues);
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                       */ }





                    </nav>
                )

            }
        },

        /*{
            field: "Impr.", headerName: 'Imprimer detail', disableExport: true,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            Print_Detail_Inscription(event, cellValues);
                        }}
                    >
                        <FcPrint />

                    </Button>

                );
            }
        },*/
        {
            field: "delete", headerName: 'Supprimer', disableExport: true,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, l'inscription du stagiaire sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]


    const columns_notes = [
        { field: '_id', headerName: '_id', hide: true, editable: false, Width: 0 },
        { field: 'evaluation_id', headerName: 'evaluation_id', hide: true, editable: false, Width: 0 },
        { field: 'inscription_id', headerName: 'inscription_id', hide: true, editable: false, Width: 0 },
        { field: 'note_evaluation_id', headerName: 'session_id', hide: true, editable: false, Width: 0 },

        { field: 'id', headerName: 'id', hide: true, Width: 0 },
        { field: 'class_eu_id', headerName: 'class_eu_id', hide: true, Width: 0 },
        { field: 'type_eval_id', headerName: 'type_eval_id', hide: true, Width: 0 },


        { field: 'class_ue_code', headerName: 'Code UE', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_ue_titre', headerName: 'Titre UE', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'note_evaluation_code', headerName: 'Evaluation', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'eval_date_heure_debut', headerName: 'Debut Eval', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'eval_date_heure_fin', headerName: 'Fin Eval', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'note', headerName: 'Note', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },

    ]

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }



    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }


    function handleClick_refuse(event, cellValues) {
        // Recuperation du motif du refus :
        var motif_refus = document.getElementById("motif_refus").value;


        var email = cellValues.row.email;
        var inscription_id = cellValues.row._id;


        Refuse_Inscritpion_with_Motif(email, inscription_id, motif_refus);
    }


    const [inscription_invoiced, setinscription_invoiced] = useState("");


    const [Refuse_Inscritpion_with_Motif_api, setRefuse_Inscritpion_with_Motif_api] = useState();
    const [Refuse_Inscritpion_with_Motif_message, setRefuse_Inscritpion_with_Motif_message] = useState();
    const [Refuse_Inscritpion_with_Motif_result, setRefuse_Inscritpion_with_Motif_result] = useState();
    function Refuse_Inscritpion_with_Motif(local_email, local_inscription_id, motif) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("inscription_id", local_inscription_id);
        form.append("motif", motif);
        form.append("email", local_email);

        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/RefuseAttendeeInscription_with_motif/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setRefuse_Inscritpion_with_Motif_api("true");
                setRefuse_Inscritpion_with_Motif_result(res.data.message);
                Getall_TrainingParticipant();
                alert(res.data.message);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setLoading(false);
                setRefuse_Inscritpion_with_Motif_api("false");
                setRefuse_Inscritpion_with_Motif_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Refuse_Inscritpion_with_Motif : Not good man :( mysearchtext = ' + error);
            setRefuse_Inscritpion_with_Motif_api("false");
            alert(" Impossible d'annuler l'inscription");

        })
    }




    function handleClick_accept(event, cellValues) {

        var email = cellValues.row.email;
        var inscription_id = cellValues.row._id;

        Accept_Inscritpion(email, inscription_id);
    }
    const [Accept_Inscritpion_api, setAccept_Inscritpion_api] = useState();
    const [Accept_Inscritpion_message, setAccept_Inscritpion_message] = useState();
    const [Accept_Inscritpion_result, setAccept_Inscritpion_result] = useState();
    function Accept_Inscritpion(local_email, local_inscription_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", local_inscription_id);
        form.append("email", local_email);

        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/AcceptAttendeeInscription/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setAccept_Inscritpion_api("true");
                setAccept_Inscritpion_result(res.data.message);
                Getall_TrainingParticipant();
                alert(res.data.message);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setLoading(false);
                setAccept_Inscritpion_api("false");
                setAccept_Inscritpion_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Accept_Inscritpion : Not good man :( mysearchtext = ' + error);
            setAccept_Inscritpion_api("false");
            alert(" Impossible de valider  l'inscription");

        })
    }

    const [Accept_List_Inscritpion_api, setAccept_List_Inscritpion_api] = useState();
    const [Accept_List_Inscritpion_message, setAccept_List_Inscritpion_message] = useState();
    const [Accept_List_Inscritpion_result, setAccept_List_Inscritpion_result] = useState();
    function Accept_List_Inscritpion(list_inscription_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_inscription_id", list_inscription_id);


        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Accept_List_AttendeeInscription/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setAccept_List_Inscritpion_api("true");
                setAccept_List_Inscritpion_result(res.data.message);
                Getall_TrainingParticipant();
                alert(res.data.message);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setLoading(false);
                setAccept_List_Inscritpion_api("false");
                setAccept_List_Inscritpion_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Accept_List_Inscritpion : Not good man :( mysearchtext = ' + error);
            setAccept_List_Inscritpion_api("false");
            alert(" Impossible de valider  la liste d'inscriptions");

        })
    }


    const [Recyclage_Desactivation_api, setRecyclage_Desactivation_api] = useState();
    const [Recyclage_Desactivation_message, setRecyclage_Desactivation_message] = useState();
    const [Recyclage_Desactivation_result, setRecyclage_Desactivation_result] = useState();
    function Recyclage_Desactivation(list_inscription_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_inscriptions_ids", list_inscription_id);


        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Inscription_Recyclage_Management_Done/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setRecyclage_Desactivation_api("true");
                setRecyclage_Desactivation_result(res.data.message);
                setSelectionModel([]);
                Getall_TrainingParticipant();
                alert(res.data.message);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setLoading(false);
                setRecyclage_Desactivation_api("false");
                setRecyclage_Desactivation_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Recyclage_Desactivation : Not good man :( mysearchtext = ' + error);
            setRecyclage_Desactivation_api("false");
            alert(" Impossible de désactivier le recyclage");

        })
    }


    function handleClick_delete(event, cellValues) {
        // Recuperation du motif du refus :
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        var prenom = cellValues.row.prenom;
        var session_id = cellValues.row.session_id;
        var class_internal_url = cellValues.row.class_internal_url;

        DeleteStagiaireData(nom, prenom, email, session_id, class_internal_url);
    }



    const [DeleteStagiaireData_api, setDeleteStagiaireData_api] = useState();
    const [DeleteStagiaireData_message, setDeleteStagiaireData_message] = useState();
    const [DeleteStagiaireData_result, setDeleteStagiaireData_result] = useState();
    function DeleteStagiaireData(nom, prenom, email, local_session_id, local_class_internal_url) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", local_class_internal_url);
        form.append("session_id", local_session_id);
        form.append("nom", nom);
        form.append("prenom", prenom);
        form.append("email", email);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/DeleteAttendeeInscription/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In DeleteStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In DeleteStagiaireData  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDeleteStagiaireData_api("true");
                setDeleteStagiaireData_result(res.data.message);

                Getall_TrainingParticipant();
                setaddOneParticipant('');
                setdisplay_detail_stagiaire('');
                setselected_code_session('');
                setsubmenu('');

                alert(" La mise à jour a été correctement faite.")
            }
            else {
                setDeleteStagiaireData_api("false");
                setDeleteStagiaireData_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('DeleteStagiaireData : Not good man :( mysearchtext = ' + error);
            setDeleteStagiaireData_api("false");
            alert(" Impossible de supprimer le stagaire");
        })
    }


    function clean_all_filters() {
        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");

        setgridline_id('');
        setselected_row_data_json_age('');

        setnb_stagiaire_Preinscrit("");
        setnb_stagiaire_Inscrit("");
        setSelectionModel([]);
        setRows([]);
        setRows_insc([]);
        setRows_preinsc([]);

        setsubmenu("");
        Getall_TrainingParticpant_no_filter();
    }



    function GetAttendee_Data_from_props(subdata, stagiaire_id) {


        var tab_sudata = String(subdata).split('&');


        setselected_code_session(tab_sudata[0]);

        setselected_session_id(tab_sudata[1]);

        setselected_internal_url(tab_sudata[2]);

        setselected_participant_email(tab_sudata[3]);
        setselectedattendeeemail(tab_sudata[3]);

        //setselected_class_title(mylocalattendee.title);

        setselected_id(stagiaire_id);

        submenu_detail_stagaire();
        setaddOneParticipant();
        setdisplay_detail_stagiaire("1");

        if (document.getElementById('detail_session')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('detail_session').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        GetAttendee_Data(tab_sudata[3], tab_sudata[1], tab_sudata[2]);

        // Recuperation de l'image de profil
        getStagiaireImages(tab_sudata[2], tab_sudata[1], tab_sudata[3]);


        // Recuperation des informations de la session
        GetCurrentSession(tab_sudata[1], tab_sudata[2]);

        Get_List_Of_All_PJ(stagiaire_id);

    }



    const myRef = useRef(null)

    const [Getall_TrainingParticipant_api, setGetall_TrainingParticipant_api] = useState();
    const [Getall_TrainingParticipant_message, setGetall_TrainingParticipant_message] = useState();
    const [Getall_TrainingParticipant_result, setGetall_TrainingParticipant_result] = useState([]);
    function Getall_TrainingParticipant(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        //console.log(" ### Getall_TrainingParticipant form = ", form)
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Statgaire_List_Partner_with_filter/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingParticipant  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingParticipant  res.data.message r_class = " + res.data.message);
                setGetall_TrainingParticipant_api("true");
                setGetall_TrainingParticipant_result(res.data.message);
                setRows(res.data.message);


            }
            else {
                setGetall_TrainingParticipant_api("false");
                setGetall_TrainingParticipant_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_TrainingParticipant = ', error);
            setGetall_TrainingParticipant_api("false");
            alert(" Impossible de recuperer la liste des stagiaires")
            //setmyApimyApiMessage("")
        })
    }



    function Getall_TrainingParticpant_no_filter(event) {

        var form = new FormData();



        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Statgaire_List_Partner_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingParticipant  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingParticipant  res.data.message r_class = " + res.data.message);
                setGetall_TrainingParticipant_api("true");
                setGetall_TrainingParticipant_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_TrainingParticipant_api("false");
                setGetall_TrainingParticipant_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_TrainingParticipant = ', error);
            setGetall_TrainingParticipant_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [display_detail_stagiaire, setdisplay_detail_stagiaire] = useState("");

    const [display_detail_absence, setdisplay_detail_absence] = useState("");

    const [display_detail_historique, setdisplay_detail_historique] = useState("");

    const [display_detail_ue, setdisplay_detail_ue] = useState("");
    const [display_notes, setdisplay_notes] = useState("");

    const [selected_id, setselected_id] = useState("");


    const [selected_id_status, setselected_id_status] = useState("");

    function handleClick_edit_session_From_Line(selected_row_id) {

        Get_List_Partner_Financeurs();

        var line = "";

        if (display_stagiaire_with_alert && display_stagiaire_with_alert === true)
            line = JSON.parse(rowss.filter((data) => (JSON.parse(data)).warning_recyclage === "1")[selected_row_id]);
        else
            line = JSON.parse(rowss[selected_row_id]);

        var code_session = line.code_session;
        setselected_code_session(line.code_session);


        var session_id = line.session_id;
        setselected_session_id(line.session_id);



        var internal_url = line.class_internal_url;
        setselected_internal_url(line.class_internal_url);

        var participant_email = line.email;
        setselected_participant_email(line.email);
        setselectedattendeeemail(line.email);

        setselected_id_status(line.status);

        setinscription_invoiced(line.invoiced);

        setselected_class_title(line.title);
        setselected_id(line._id);


        submenu_detail_stagaire();
        setaddOneParticipant();
        setdisplay_detail_stagiaire("1");

        if (document.getElementById('detail_session')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('detail_session').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        GetAttendee_Data(participant_email, session_id, internal_url);

        // Recuperation de l'image de profil
        getStagiaireImages(internal_url, session_id, participant_email);


        // Recuperation des informations de la session
        GetCurrentSession(session_id, internal_url);

        Get_List_Of_All_PJ(line._id);

        Get_Inscrit_Notes_Of_Evaluations(line._id, session_id);


    }



    const datagridSx = {
        borderRadius: 2,
        marginRight: 1,
        marginLeft: 1,
        border: 0,
        "& .MuiDataGrid-main": { borderRadius: 0 },
        /*'& div[data-rowIndex][role="row"]:nth-of-type(5n-4)': {
          color: "blue",
          fontSize: 18,
          //risky
          minHeight: "60px !important",
          height: 60,
          "& div": {
            minHeight: "60px !important",
            height: 60,
            lineHeight: "59px !important"
          }
        },*/
        "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
            }
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#c8cfd5",
            color: "black",
            fontSize: 16
        },

    };


    const [selected_session_id, setselected_session_id] = useState();
    const [selected_code_session, setselected_code_session] = useState();
    const [selected_internal_url, setselected_internal_url] = useState();

    const [selected_class_id, setselected_class_id] = useState();

    const [selected_class_title, setselected_class_title] = useState();
    const [selected_participant_email, setselected_participant_email] = useState();

    const [one_selected_internal_url, setone_selected_internal_url] = useState();

    const BorderLinearProgress = styled(LinearProgress)(() => ({
        height: "20px",
        width: "60px",
        borderRadius: "2px",
        backgroundColor: " #ebf5fb",
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#1bd900",
            transition: "none",
            transformOrigin: "left",
        },
    }));

    const [session_file_name, setsession_file_name] = useState();
    function submenu_import_stagiaire() {
        setsession_file_name();
        setliste_sessions_file_change_api();
        hiddenFileInput_session.current.click();
    }

    const myRef_details = useRef(null)

    const [addOneSession, setaddOneSession] = useState();
    function submenu_add_one_stagiaire() {
        setsubmenu("");
        setSelectionModel([]);
        setselected_code_session();
        Get_List_Partner_Financeurs();
        setaddOneParticipant("1");


        if (document.getElementById('myRef_details')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef_details').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        setdisplay_detail_stagiaire();
        setparticipant_data_changed();
        setparticipant_data_edit_mode();
        disableAttendeeDetailFields();
    }
    function clean_old_messages() {

    }

    const [GetCurrentClass_trainingsession_api, setGetCurrentClass_trainingsession_api] = useState();
    const [GetCurrentClass_trainingsession_message, setGetCurrentClass_trainingsession_message] = useState();
    const [GetCurrentClass_trainingsession_result, setGetCurrentClass_trainingsession_result] = useState();
    function GetCurrentClass_trainingsession(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionFormation_List/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
                setGetCurrentClass_trainingsession_api("true");
                setGetCurrentClass_trainingsession_result(res.data.message);
            }
            else {
                setGetCurrentClass_trainingsession_api("false");
                setGetCurrentClass_trainingsession_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
            setGetCurrentClass_trainingsession_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const [nb_stagiaire_Preinscrit, setnb_stagiaire_Preinscrit] = useState("0");
    function submenu_inscrit() {
        setsubmenu("inscrit");

        if (document.getElementById("inscrit")) {
            document.getElementById("inscrit").style.backgroundColor = "#104277";
            document.getElementById("inscrit").style.color = "white";
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
        }

        if (document.getElementById("preinscrit")) {
            document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
            document.getElementById("preinscrit").style.color = "black";
        }

    }

    const [print_detail_insc, setprint_detail_insc] = React.useState();
    function Print_Detail_Inscription(event, cellValues) {

        setprint_detail_insc("1");

        var code_session = cellValues.row.code_session;

        var session_id = cellValues.row.session_id;

        var internal_url = cellValues.row.class_internal_url;

        var participant_email = cellValues.row.email;



        DownloadAttendeeDetail(session_id, participant_email, internal_url);

    }
    function clearDetailAttendeFields() {

        if (document.getElementsByName("detail_client_rattachement") && document.getElementsByName("detail_client_rattachement")[0])
            document.getElementsByName("detail_client_rattachement")[0].value = "";

        if (document.getElementsByName("detail_facture_client_rattachement") && document.getElementsByName("detail_facture_client_rattachement")[0])
            document.getElementsByName("detail_facture_client_rattachement")[0].value = "";


        if (document.getElementsByName("detail_financeur_rattachement") && document.getElementsByName("detail_financeur_rattachement")[0])
            document.getElementsByName("detail_financeur_rattachement")[0].value = "";

        if (document.getElementsByName("detail_session_class") && document.getElementsByName("detail_session_class")[0])
            document.getElementsByName("detail_session_class")[0].value = "";


        if (document.getElementsByName("detail_civilite") && document.getElementsByName("detail_civilite")[0])
            document.getElementsByName("detail_civilite")[0].value = "";


        if (document.getElementsByName("detail_nom") && document.getElementsByName("detail_nom")[0])
            document.getElementsByName("detail_nom")[0].value = "";

        if (document.getElementsByName("detail_prenom") && document.getElementsByName("detail_prenom")[0])
            document.getElementsByName("detail_prenom")[0].value = "";

        if (document.getElementsByName("detail_prenom") && document.getElementsByName("detail_prenom")[0])
            document.getElementsByName("detail_prenom")[0].value = "";

        if (document.getElementsByName("detail_mail") && document.getElementsByName("detail_mail")[0])
            document.getElementsByName("detail_mail")[0].value = "";


        if (document.getElementsByName("detail_naissance") && document.getElementsByName("detail_naissance")[0])
            document.getElementsByName("detail_naissance")[0].value = "";

        if (document.getElementsByName("detail_tel") && document.getElementsByName("detail_tel")[0])
            document.getElementsByName("detail_tel")[0].value = "";

        if (document.getElementsByName("detail_adresse") && document.getElementsByName("detail_adresse")[0])
            document.getElementsByName("detail_adresse")[0].value = "";

        if (document.getElementsByName("detail_code_postal") && document.getElementsByName("detail_code_postal")[0])
            document.getElementsByName("detail_code_postal")[0].value = "";

        if (document.getElementsByName("detail_ville") && document.getElementsByName("detail_ville")[0])
            document.getElementsByName("detail_ville")[0].value = "";

        if (document.getElementsByName("detail_pays") && document.getElementsByName("detail_ville")[0])
            document.getElementsByName("detail_pays")[0].value = "";

        if (document.getElementsByName("detail_incr_date") && document.getElementsByName("detail_incr_date")[0])
            document.getElementsByName("detail_incr_date")[0].value = "";

        if (document.getElementsByName("detail_cout") && document.getElementsByName("detail_cout")[0])
            document.getElementsByName("detail_cout")[0].value = "";

        if (document.getElementsByName("detail_mode_fin") && document.getElementsByName("detail_mode_fin")[0])
            document.getElementsByName("detail_mode_fin")[0].value = "";

        if (document.getElementsByName("detail_employeur") && document.getElementsByName("detail_employeur")[0])
            document.getElementsByName("detail_employeur")[0].value = "";

        if (document.getElementsByName("date_evaluation") && document.getElementsByName("date_evaluation")[0])
            document.getElementsByName("date_evaluation")[0].value = "";

        if (document.getElementsByName("note_eval") && document.getElementsByName("note_eval")[0])
            document.getElementsByName("note_eval")[0].value = "";

        if (document.getElementsByName("certif_date") && document.getElementsByName("certif_date")[0])
            document.getElementsByName("certif_date")[0].value = "";

        setp_detail_civilite();
        setp_detail_nom();
        setp_detail_prenom();
        setp_detail_mail();
        setp_detail_naissance(new Date().toLocaleDateString('fr-FR'));
        setp_detail_tel();
        setp_detail_adresse();
        setp_detail_code_postal();
        setp_detail_ville();
        setp_detail_pays();
        setp_detail_cout();
        setp_detail_mode_fin("");
        setp_detail_employeur();
        setp_date_evaluation();
        setp_note_eval();
        setp_detail_session_class();
        setp_detail_status_inscrit();
        setp_detail_status_inscrit_label();
        setp_detail_opco();
        setp_detail_client_rattachement_nom();
        setp_detail_client_rattachement_id();

        setp_detail_financeur_rattachement_nom();
        setp_detail_financeur_rattachement_id();

        setp_detail_type_apprenant();
        setp_detail_type_apprenant_label();

        setfield_evaluation();
        setdetailuser_date_certification();
        setdetailuser_date_evaluation();
        setp_detail_facture_client_rattachement_id("");
    }


    function clear_one_Attende_Fields() {

        if (document.getElementsByName("one_formation") && document.getElementsByName("one_formation")[0])
            document.getElementsByName("one_formation")[0].value = "";

        if (document.getElementsByName("one_detail_session") && document.getElementsByName("one_detail_session")[0])
            document.getElementsByName("one_detail_session")[0].value = "";

        if (document.getElementsByName("one_nom_part") && document.getElementsByName("one_nom_part")[0])
            document.getElementsByName("one_nom_part")[0].value = "";

        if (document.getElementsByName("one_prenom_part") && document.getElementsByName("one_prenom_part")[0])
            document.getElementsByName("one_prenom_part")[0].value = "";

        if (document.getElementsByName("one_email_part") && document.getElementsByName("one_email_part")[0])
            document.getElementsByName("one_email_part")[0].value = "";

        if (document.getElementsByName("one_phone_part") && document.getElementsByName("one_phone_part")[0])
            document.getElementsByName("one_phone_part")[0].value = "";

        if (document.getElementsByName("one_adresse_part") && document.getElementsByName("one_adresse_part")[0])
            document.getElementsByName("one_adresse_part")[0].value = "";

        if (document.getElementsByName("one_code_postal_part") && document.getElementsByName("one_code_postal_part")[0])
            document.getElementsByName("one_code_postal_part")[0].value = "";

        if (document.getElementsByName("one_ville_part") && document.getElementsByName("one_ville_part")[0])
            document.getElementsByName("one_ville_part")[0].value = "";

        if (document.getElementsByName("one_pays_part") && document.getElementsByName("one_pays_part")[0])
            document.getElementsByName("one_pays_part")[0].value = "";

        if (document.getElementsByName("one_detail_type_apprenant") && document.getElementsByName("one_detail_type_apprenant")[0])
            document.getElementsByName("one_detail_type_apprenant")[0].value = "";

        if (document.getElementsByName("one_status_part") && document.getElementsByName("one_status_part")[0])
            document.getElementsByName("one_status_part")[0].value = "";

        if (document.getElementsByName("one_detail_client_rattachement") && document.getElementsByName("one_detail_client_rattachement")[0])
            document.getElementsByName("one_detail_client_rattachement")[0].value = "";

        if (document.getElementsByName("one_detail_financeur_rattachement") && document.getElementsByName("one_detail_financeur_rattachement")[0])
            document.getElementsByName("one_detail_financeur_rattachement")[0].value = "";




        if (document.getElementsByName("one_status_part") && document.getElementsByName("one_status_part")[0])
            document.getElementsByName("one_status_part")[0].value = "";



        setp_one_detail_session_class("");
        setp_one_nom_part("");
        setp_one_prenom_part("");
        setp_one_email_part("");


        setp_one_naissance_part(new Date().toLocaleDateString('fr-FR'));

        setp_one_phone_part("");
        setp_one_adresse_part("");
        setp_one_ville_part("");
        setp_one_code_postal_part("");
        setp_one_pays_part("");
        setp_one_status_part("");
        setp_one_detail_client_rattachement_nom("");
        setp_one_detail_client_rattachement_id("");

        setp_one_detail_financeur_rattachement_id("");
        setp_one_detail_financeur_rattachement_nom("");

        setp_one_detail_type_apprenant("");
        setp_one_detail_facture_client_rattachement_id("");


        setp_one_status_part("");
    }


    const [Resend_LMS_Credentials_api, setResend_LMS_Credentials_api] = useState();
    const [Resend_LMS_Credentials_result, setResend_LMS_Credentials_result] = useState();
    const [Resend_LMS_Credentials_message, setResend_LMS_Credentials_message] = useState();
    const Resend_LMS_Credentials = event => {

        clean_old_messages();
        const formData = new FormData();

        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("session_id", selected_session_id);

        var local_mail = ""
        if (document.getElementsByName("detail_mail") && document.getElementsByName("detail_mail")[0])
            local_mail = document.getElementsByName("detail_mail")[0].value;

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!local_mail.trim().match(validRegex)) {
            alert("L'adresse email est invalide. Impossible de renvoyer les identifiants");
            return;
        }
        formData.append("email", local_mail);
        formData.append("class_internal_url", selected_internal_url);
        //console.log("token = " + stored_cookie);
        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/LMS_Credential_Sending_mail/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setResend_LMS_Credentials_result(result['message']);
                    setResend_LMS_Credentials_api("true");
                    alert(" Les identifiants de connexion ont été correctement envoyés")
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setResend_LMS_Credentials_message(result['message']);
                    setResend_LMS_Credentials_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setResend_LMS_Credentials_api("false");
            });

    };


    const [SessionstartDate, setSessionstartDate] = useState();

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    const [SessionendDate, setSessionendDate] = useState();

    const filterPassedTime_end = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };


    const editorRef_evaluation = useRef(null);
    const [field_evaluation, setfield_evaluation] = useState("");
    function editor_keyup() {
    }

    const editorRef_contenu_ftion = useRef(null);
    const [editorRef_contenu_ftion_limite, seteditorRef_description_limite] = useState(800);

    const one_editorRef_contenu_ftion = useRef(null);
    const [one_editorRef_contenu_ftion_limite, setone_editorRef_description_limite] = useState(800);

    const [field_contenu_ftion, setfield_contenu_ftion] = useState("");
    function editor_contenu_ftion_keyup() {
        setsessionChanged(true);
    }

    const [SendAttendeeAttestation_api, setSendAttendeeAttestation_api] = useState();
    const [SendAttendeeAttestation_message, setSendAttendeeAttestation_message] = useState();
    const [SendAttendeeAttestation_result, setSendAttendeeAttestation_result] = useState();
    function SendAttendeeAttestation() {
        clean_old_messages();
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("attendee_email", selectedattendeeemail);
        form.append("class_internal_url", selected_internal_url);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendAttendeeCertification/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendAttendeeAttestation  res.data.status = " + res.data.status);
                //console.log(" In SendAttendeeAttestation  res.data.message r_class = " + res.data.message);
                setSendAttendeeAttestation_api("true");
                setSendAttendeeAttestation_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendAttendeeAttestation_api("false");
                setSendAttendeeAttestation_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendAttendeeAttestation = ', error);
            setSendAttendeeAttestation_api("false");

        })

    }



    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");


    function DownloadAttendeeDetail(local_session_id, local_email, local_internal_url) {


        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeDetail_perSession/" + stored_cookie + "/" + local_session_id + "/" + local_email + "/" + local_internal_url;


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    function DownloadAttendeeDetail_one(addendeeEmail) {


        var trainer = ""
        if (document.getElementsByName("formateur")[0])
            trainer = document.getElementsByName("formateur")[0].value;

        if (trainer == "") {
            alert(" Aucun formateur pour cette session ");
            return;
        }

        if (addendeeEmail == "") {
            alert(" Aucun utilisateur choisi ");
            return;
        }

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeDetail_perSession/" + stored_cookie + "/" + selected_code_session + "/" + addendeeEmail + "/" + selected_internal_url;


        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            }).catch((error) => {
                console.error('Error:', error);

            });
    }


    function DownloadAttendeeAttestation(event) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fiche_detaillee = "Attestation.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/PrintAttendeeCertification/" + stored_cookie + "/" + selected_code_session + "/" + selectedattendeeemail + "/" + selected_internal_url;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_fiche_detaillee)
            })
    }




    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");

    function AnnuleAttendeeDetailFields() {

        GetAttendee_Data(selected_participant_email, selected_session_id, selected_internal_url);

        setparticipant_data_changed();
        setparticipant_data_edit_mode();


        disableAttendeeDetailFields();
    }

    async function disableAttendeeDetailFields() {
        setparticipant_data_edit_mode("0");
        await sleep(10);

        if (document.getElementsByName("detail_session_class")[0]) {
            document.getElementsByName("detail_session_class")[0].disabled = true;
            document.getElementsByName("detail_session_class")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_client_rattachement")[0]) {
            document.getElementsByName("detail_client_rattachement")[0].disabled = true;
            document.getElementsByName("detail_client_rattachement")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_facture_client_rattachement")[0]) {
            document.getElementsByName("detail_facture_client_rattachement")[0].disabled = true;
            document.getElementsByName("detail_facture_client_rattachement")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_financeur_rattachement")[0]) {
            document.getElementsByName("detail_financeur_rattachement")[0].disabled = true;
            document.getElementsByName("detail_financeur_rattachement")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_civilite")[0]) {
            document.getElementsByName("detail_civilite")[0].disabled = true;
            document.getElementsByName("detail_civilite")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_nom")[0]) {
            document.getElementsByName("detail_nom")[0].disabled = true;
            document.getElementsByName("detail_nom")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_status_inscript")[0]) {
            document.getElementsByName("detail_status_inscript")[0].disabled = true;
            document.getElementsByName("detail_status_inscript")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_opco")[0]) {
            document.getElementsByName("detail_opco")[0].disabled = true;
            document.getElementsByName("detail_opco")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_type_apprenant")[0]) {
            document.getElementsByName("detail_type_apprenant")[0].disabled = true;
            document.getElementsByName("detail_type_apprenant")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_prenom")[0]) {
            document.getElementsByName("detail_prenom")[0].disabled = true;
            document.getElementsByName("detail_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_mode_financement") && document.getElementsByName("detail_mode_financement")[0]) {
            document.getElementsByName("detail_mode_financement")[0].disabled = true;
            document.getElementsByName("detail_mode_financement")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("detail_mail")[0]) {
            document.getElementsByName("detail_mail")[0].disabled = true;
            document.getElementsByName("detail_mail")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_naissance")[0]) {
            document.getElementsByName("detail_naissance")[0].disabled = true;
            document.getElementsByName("detail_naissance")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_tel")[0]) {
            document.getElementsByName("detail_tel")[0].disabled = true;
            document.getElementsByName("detail_tel")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_adresse")[0]) {
            document.getElementsByName("detail_adresse")[0].disabled = true;
            document.getElementsByName("detail_adresse")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_code_postal")[0]) {
            document.getElementsByName("detail_code_postal")[0].disabled = true;
            document.getElementsByName("detail_code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_ville")[0]) {
            document.getElementsByName("detail_ville")[0].disabled = true;
            document.getElementsByName("detail_ville")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_pays")[0]) {
            document.getElementsByName("detail_pays")[0].disabled = true;
            document.getElementsByName("detail_pays")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_cout")[0]) {
            document.getElementsByName("detail_cout")[0].disabled = true;
            document.getElementsByName("detail_cout")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_mode_fin")[0]) {
            document.getElementsByName("detail_mode_fin")[0].disabled = true;
            document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_employeur")[0]) {
            document.getElementsByName("detail_employeur")[0].disabled = true;
            document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("date_evaluation")[0]) {
            document.getElementsByName("date_evaluation")[0].disabled = true;
            document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("certif_date")[0]) {
            document.getElementsByName("certif_date")[0].disabled = true;
            document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("note_eval")[0]) {
            document.getElementsByName("note_eval")[0].disabled = true;
            document.getElementsByName("note_eval")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("date_evaluation")[0]) {
            document.getElementsByName("date_evaluation")[0].disabled = true;
            document.getElementsByName("date_evaluation")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("detail_incr_date")[0]) {
            document.getElementsByName("detail_incr_date")[0].disabled = true;
            document.getElementsByName("detail_incr_date")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("certif_date")[0]) {
            document.getElementsByName("certif_date")[0].disabled = true;
            document.getElementsByName("certif_date")[0].style.backgroundColor = "#ECEFF1";
        }

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = true;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
            }
        }


        // on desactive les champs "tuteurs". Pour info, ces champs ne sont jamais "enable"
        if (document.getElementsByName("detail_tuteur1_nom")[0]) {
            document.getElementsByName("detail_tuteur1_nom")[0].disabled = true;
            document.getElementsByName("detail_tuteur1_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur1_prenom")[0]) {
            document.getElementsByName("detail_tuteur1_prenom")[0].disabled = true;
            document.getElementsByName("detail_tuteur1_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur1_email")[0]) {
            document.getElementsByName("detail_tuteur1_email")[0].disabled = true;
            document.getElementsByName("detail_tuteur1_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur1_phone")[0]) {
            document.getElementsByName("detail_tuteur1_phone")[0].disabled = true;
            document.getElementsByName("detail_tuteur1_phone")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur2_nom")[0]) {
            document.getElementsByName("detail_tuteur2_nom")[0].disabled = true;
            document.getElementsByName("detail_tuteur2_nom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur2_prenom")[0]) {
            document.getElementsByName("detail_tuteur2_prenom")[0].disabled = true;
            document.getElementsByName("detail_tuteur2_prenom")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur2_email")[0]) {
            document.getElementsByName("detail_tuteur2_email")[0].disabled = true;
            document.getElementsByName("detail_tuteur2_email")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_tuteur2_phone")[0]) {
            document.getElementsByName("detail_tuteur2_phone")[0].disabled = true;
            document.getElementsByName("detail_tuteur2_phone")[0].style.backgroundColor = "#ECEFF1";
        }

    }


    function open_apprenant_dossier() {
        if (selected_id_status && String(selected_id_status) === "1" && p_detail_apprenant_id && String(p_detail_apprenant_id).trim().length > 3) {


            window.open(
                process.env.REACT_APP_FRONT_URL + "Partner/mes_apprenants/" + String(p_detail_apprenant_id) + "/" + String(selected_participant_email),
                '_blank'
            );
        }


    }

    function EnableAttendeeDetailFields() {
        /**
         * Important : 
         * Si l'inscription est deja validé, alors il devient impossible de modifier les données de l'inscrit depuis cet écran
         * Il faut aller dans les apprenants pour modifier l'apprenant.
         */
        if (selected_id_status && String(selected_id_status) === "1") {

            var message = "Cette inscription a déjà été validée. Pour modifier les données de l'apprenant, merci de passer par le menu 'mes apprenants' ";
            setDialog_1_message(message);
            setDialog_1_open(true);
            return;
        }


        setparticipant_data_edit_mode("1");

        const list_menu_header_names = ['detail_session_class', 'detail_client_rattachement', 'detail_facture_client_rattachement', 'detail_financeur_rattachement', 'detail_nom', 'detail_status_inscript',
            'detail_opco', 'detail_prenom', 'detail_mode_financement', 'detail_mail', 'detail_naissance', 'detail_tel', 'detail_adresse', 'detail_code_postal',
            'detail_ville', 'detail_pays', 'certif_date', 'detail_cout', 'detail_mode_fin', 'detail_employeur', 'date_evaluation', 'certif_date',
             'note_eval', 'detail_type_apprenant', 'date_evaluation', 'detail_incr_date']



        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].disabled = false;
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.backgroundColor = "#FFFFFF";
            }
        }



        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = false;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#FFFFFF";
            }
        }


    }

    function EnableAttendeeDetailFields_Deja_Inscrit() {
        /**
         * Important : 
         * Si l'inscription est deja validé, alors il devient impossible de modifier les données de l'inscrit depuis cet écran
         * Il faut aller dans les apprenants pour modifier l'apprenant.
         */
        if (selected_id_status && String(selected_id_status) === "1") {

            var message = "Cette inscription a déjà été validée. Seuls quelques données sont modifiable. \
             Pour modifier les données personnelles de l'apprenant, merci de passer par le menu 'mes apprenants'.  ";
            setDialog_1_message(message);
            setDialog_1_open(true);
            // return;
        }


        setparticipant_data_edit_mode("1");


        document.getElementsByName("detail_client_rattachement")[0].disabled = false;
        document.getElementsByName("detail_client_rattachement")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_facture_client_rattachement")[0].disabled = false;
        document.getElementsByName("detail_facture_client_rattachement")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_financeur_rattachement")[0].disabled = false;
        document.getElementsByName("detail_financeur_rattachement")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_opco")[0].disabled = false;
        document.getElementsByName("detail_opco")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_mode_financement")[0].disabled = false;
        document.getElementsByName("detail_mode_financement")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_cout")[0].disabled = false;
        document.getElementsByName("detail_cout")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("detail_mode_fin")[0].disabled = false;
        document.getElementsByName("detail_mode_fin")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("detail_employeur")[0].disabled = false;
        document.getElementsByName("detail_employeur")[0].style.backgroundColor = "#FFFFFF";

    }



    const [detailuser_date_inscription, setdetailuser_date_inscription] = useState();
    const [detailuser_date_evaluation, setdetailuser_date_evaluation] = useState();
    const [detailuser_date_certification, setdetailuser_date_certification] = useState();
    const [SessionstartDateInscription, setSessionstartDateInscription] = useState();


    const [attendee_lms_pwd, setattendee_lms_pwd] = useState();
    const [attendee_lms_login, setattendee_lms_login] = useState();


    function submenu_sessions() {
        setsubmenu("sessions");
        setdisplay_detail_stagiaire();
        setdisplay_detail_absence();
        setdisplay_notes("");
        setdisplay_detail_ue();
        setdisplay_detail_historique();
        desableSessionFields();
    }

    function submenu_absence() {
        setsubmenu("absence");
        setdisplay_detail_stagiaire();
        setdisplay_detail_historique();
        setdisplay_detail_ue();
        setdisplay_notes("");
        setdisplay_detail_absence("1");
        desableSessionFields();
    }

    function submenu_historique() {
        setsubmenu("historique");
        setdisplay_detail_stagiaire();
        setdisplay_detail_absence();
        setdisplay_detail_ue();
        setdisplay_notes("");
        setdisplay_detail_historique("1");
        desableSessionFields();
    }

    function submenu_unite_enseignement() {

        setsubmenu("ue");

        if (selected_id) {
            Get_Inscrit_List_Evaluation_Par_UE(selected_id);
            Get_Inscrit_List_UE(selected_id);
        }

        setdisplay_detail_stagiaire();
        setdisplay_detail_absence();
        setdisplay_detail_historique("");
        setdisplay_notes("");
        setdisplay_detail_ue("1");
        desableSessionFields();
    }

    function submenu_notes() {

        setsubmenu("notes");

        if (selected_id) {
            Get_Inscrit_List_Evaluation_Par_UE(selected_id);
            Get_Inscrit_List_UE(selected_id);
        }

        setdisplay_detail_stagiaire();
        setdisplay_detail_absence();
        setdisplay_detail_historique("");
        setdisplay_detail_ue("");
        setdisplay_notes("1");
        desableSessionFields();
    }


    const [SendEvaluationEmail_api, setSendEvaluationEmail_api] = useState();
    const [SendEvaluationEmail_message, setSendEvaluationEmail_message] = useState();
    const [SendEvaluationEmail_result, setSendEvaluationEmail_result] = useState();
    function SendEvaluationEmail() {
        clean_old_messages();
        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("attendee_email", selected_participant_email);
        form.append("class_internal_url", selected_internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendTrainingEvaluationEmail/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendEvaluationEmail  res.data.status = " + res.data.status);
                //console.log(" In SendEvaluationEmail  res.data.message r_class = " + res.data.message);
                setSendEvaluationEmail_api("true");
                setSendEvaluationEmail_result(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendEvaluationEmail_api("false");
                setSendEvaluationEmail_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendEvaluationEmail = ', error);
            setSendEvaluationEmail_api("false");

        })
    }

    function SendEvaluationEmail_One(email) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("attendee_email", email);
        form.append("class_internal_url", selected_internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/SendTrainingEvaluationEmail/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In SendEvaluationEmail  res.data.status = " + res.data.status);
                //console.log(" In SendEvaluationEmail  res.data.message r_class = " + res.data.message);
                setSendEvaluationEmail_api("true");
                setSendEvaluationEmail_result(res.data.message);
                alert(" La demande a été correctement envoyée");

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSendEvaluationEmail_api("false");
                setSendEvaluationEmail_message(res.data.message);
                alert(" Erreur : " + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( SendEvaluationEmail = ', error);
            setSendEvaluationEmail_api("false");

        })
    }


    const [record_Stagiaire_Image_api, setrecord_Stagiaire_Image_api] = useState();
    const [record_Stagiaire_Image_message, setrecord_Stagiaire_Image_message] = useState();
    const [record_Stagiaire_Image_result, setrecord_Stagiaire_Image_result] = useState();
    function record_Stagiaire_Image(l) {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Update_Stagiaire_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("class_internal_url", selected_internal_url);
        formData.append("session_id", selected_session_id);
        formData.append("email", selected_participant_email);

        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);


        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_Stagiaire_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_Stagiaire_Image_api("false");
                    alert(" Erreur : " + result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setrecord_Stagiaire_Image_api("false");
                alert(" Erreur : Impossible d'enregistrer l'image ");

            });


    };


    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/DeleteImage_Stagiaire_v2/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {

                console.log(' removeRecodedClassImage : status:', result['status']);
                console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");
                    getStagiaireImages();

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }



    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {
        clean_old_messages();

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);

        setLoading(true);



        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass_mass_for_many_session/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    setLoading(false);
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    setLoading(false);
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");

                    Getall_TrainingParticipant();
                    alert(" Les stagiaires ont été correctement importés");

                }

                else {
                    setLoading(false);
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer les stagiaires ");
            });
    }


    async function submenu_detail_stagaire() {
        setsubmenu("detail_session");

        await sleep(5);

        setdisplay_detail_stagiaire("1");
        setaddOneParticipant();



        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#104277";
            document.getElementById("detail_session").style.color = "white";
        }

        if (document.getElementById("piece_jointe")) {
            document.getElementById("piece_jointe").style.backgroundColor = "#d8edfc";
            document.getElementById("piece_jointe").style.color = "black";
        }


        /*  if (document.getElementById("preinscrit")) {
              document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
              document.getElementById("preinscrit").style.color = "black";
          }
  */

    }


    async function submenu_piece_jointe() {
        setsubmenu("piece_jointe");

        await sleep(5);

        setdisplay_detail_stagiaire("");
        setaddOneParticipant();


        if (document.getElementById("piece_jointe")) {
            document.getElementById("piece_jointe").style.backgroundColor = "#104277";
            document.getElementById("piece_jointe").style.color = "white";
        }

        if (document.getElementById("detail_session")) {
            document.getElementById("detail_session").style.backgroundColor = "#d8edfc";
            document.getElementById("detail_session").style.color = "black";
        }


        /*  if (document.getElementById("preinscrit")) {
              document.getElementById("preinscrit").style.backgroundColor = "#d8edfc";
              document.getElementById("preinscrit").style.color = "black";
          }
  */

    }


    const [New_Getall_TrainingSession_result, setNew_Getall_TrainingSession_result] = useState([]);

    const [Getall_TrainingSession_api, setGetall_TrainingSession_api] = useState();
    const [Getall_TrainingSession_message, setGetall_TrainingSession_message] = useState();
    const [Getall_TrainingSession_result, setGetall_TrainingSession_result] = useState();
    function Getall_TrainingSession(event) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAllValideSessionPartner_List/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_TrainingSession  res.data.status = " + res.data.status);
                //console.log(" In Getall_TrainingSession  res.data.message r_class = " + res.data.message);
                setGetall_TrainingSession_api("true");
                setGetall_TrainingSession_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_internal_url = JSON.parse(x).class_internal_url;
                    var local_title_session = JSON.parse(x).titre;
                    var local_code_session = JSON.parse(x).code_session;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_session,
                        "class_internal_url": local_internal_url,
                        "titre": local_title_session,
                        "code_session": local_code_session,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });


                if (new_data2.length > 0) {
                    setNew_Getall_TrainingSession_result(new_data2);
                }


            }
            else {
                setGetall_TrainingSession_api("false");
                setGetall_TrainingSession_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_TrainingSession = ', error);
            setGetall_TrainingSession_api("false");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Get_Inscrit_List_UE_result, setNew_Get_Inscrit_List_UE_result] = useState([]);
    const [tab_inscrit_ue_id, settab_inscrit_ue_id] = useState([]);

    const [Get_Inscrit_List_UE_api, setGet_Inscrit_List_UE_api] = useState();
    const [Get_Inscrit_List_UE_message, setGet_Inscrit_List_UE_message] = useState();
    const [Get_Inscrit_List_UE_result, setGet_Inscrit_List_UE_result] = useState();
    function Get_Inscrit_List_UE(local_inscription_id) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", local_inscription_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Inscrit_List_EU/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Inscrit_List_UE  res.data.status = " + res.data.status);
                // console.log(" In Get_Inscrit_List_UE  res.data.message r_class = " + res.data.message);
                setGet_Inscrit_List_UE_api("true");
                setGet_Inscrit_List_UE_result(res.data.message);

                var new_data2 = [];

                var tab_tmp = []
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_eu_id = JSON.parse(x).class_eu_id;
                    var local_ue_code = JSON.parse(x).code;
                    var local_ue_titre = JSON.parse(x).titre;


                    tab_tmp.push(local_class_eu_id);

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_ue_code,
                        "class_id": local_class_id,
                        "class_eu_id": local_class_eu_id,
                        "ue_code": local_ue_code,
                        "ue_titre": local_ue_titre,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "class_id": "",
                    "class_eu_id": "",
                    "ue_code": "",
                    "ue_titre": "",
                };
                new_data2.push(node);


                if (new_data2.length > 0) {
                    setNew_Get_Inscrit_List_UE_result(new_data2);
                    settab_inscrit_ue_id(tab_tmp);

                    if (New_Getall_Class_List_Evalution_result && New_Getall_Class_List_Evalution_result.length > 0) {

                        var local_defaultValue = New_Getall_Class_List_Evalution_result.filter((data) => {
                            if (tab_inscrit_evaluation_ue_id.includes(String(data.type_evaluation_id))) {

                                return data;
                            }
                        });

                        setinscription_list_ue_eval(local_defaultValue);
                    }


                }


            }
            else {
                setGet_Inscrit_List_UE_api("false");
                setGet_Inscrit_List_UE_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Inscrit_List_UE = ', error);
            setGet_Inscrit_List_UE_api("false");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Get_Inscrit_List_Evaluation_Par_UE_result, setNew_Get_Inscrit_List_Evaluation_Par_UE_result] = useState([]);
    const [tab_inscrit_evaluation_ue_id, settab_inscrit_evaluation_ue_id] = useState([]);

    const [tab_inscrit_evaluation_ue_id_V2, settab_inscrit_evaluation_ue_id_V2] = useState([]);

    const [Get_Inscrit_List_Evaluation_Par_UE_api, setGet_Inscrit_List_Evaluation_Par_UE_api] = useState();
    const [Get_Inscrit_List_Evaluation_Par_UE_message, setGet_Inscrit_List_Evaluation_Par_UE_message] = useState();
    const [Get_Inscrit_List_Evaluation_Par_UE_result, setGet_Inscrit_List_Evaluation_Par_UE_result] = useState();
    function Get_Inscrit_List_Evaluation_Par_UE(local_inscription_id) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", local_inscription_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Inscrit_List_EU_Type_Evaluation/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Inscrit_List_Evaluation_Par_UE  res.data.status = " + res.data.status);
                //console.log(" In Get_Inscrit_List_Evaluation_Par_UE  res.data.message r_class = " + res.data.message);
                setGet_Inscrit_List_Evaluation_Par_UE_api("true");
                setGet_Inscrit_List_Evaluation_Par_UE_result(res.data.message);

                var new_data2 = [];

                var tab_tmp = []
                var tab_tmp_V2 = []
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_eu_id = JSON.parse(x).class_eu_id;
                    var local_class_eu_code = JSON.parse(x).class_eu_code;
                    var local_type_eval__id = JSON.parse(x).type_eval__id;
                    var local_type_eval_code = JSON.parse(x).type_eval_code;
                    var local_type_eval_nom = JSON.parse(x).type_eval_nom;

                    tab_tmp.push(local_type_eval__id);



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_class_eu_code + " - " + local_type_eval_code,
                        "class_id": local_class_id,
                        "class_eu_id": local_class_eu_id,
                        "class_eu_code": local_class_eu_code,
                        "type_eval__id": local_type_eval__id,
                        "type_eval_code": local_type_eval_code,
                        "type_eval_nom": local_type_eval_nom,
                    };
                    new_data2.push(node);


                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "class_id": "",
                    "class_eu_id": "",
                    "class_eu_code": "",
                    "type_eval__id": "",
                    "type_eval_code": "",
                    "type_eval_nom": "",
                };
                new_data2.push(node);


                if (new_data2.length > 0) {
                    setNew_Get_Inscrit_List_Evaluation_Par_UE_result(new_data2);
                    settab_inscrit_evaluation_ue_id(tab_tmp);

                    //console.log(" ### settab_inscrit_evaluation_ue_id = ", tab_tmp);

                    if (New_Get_List_UE_From_Class_Id_result && New_Get_List_UE_From_Class_Id_result.length > 0) {

                        var local_defaultValue = New_Get_List_UE_From_Class_Id_result.filter((data) => {
                            if (tab_inscrit_ue_id.includes(String(data._id))) {

                                return data;
                            }
                        });

                        setinscription_list_ue(local_defaultValue);


                    }



                }


            }
            else {
                setGet_Inscrit_List_Evaluation_Par_UE_api("false");
                setGet_Inscrit_List_Evaluation_Par_UE_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Inscrit_List_Evaluation_Par_UE = ', error);
            setGet_Inscrit_List_Evaluation_Par_UE_api("false");
            //setmyApimyApiMessage("")
        })
    }



    const [nb_stagiaire_Inscrit, setnb_stagiaire_Inscrit] = useState("0");
    const hiddenFileInput_session = React.useRef(null);
    const [Edite_session, setEdite_session] = useState();
    const [selectionModel_preinsc, setselectionModel_preinsc] = React.useState([]);

    const [selectionModel_insc, setSelectionModel_insc] = React.useState([]);
    const [rowss_insc, setRows_insc] = useState([]);
    const [rowss_preinsc, setRows_preinsc] = useState([]);


    const [New_Get_List_UE_From_Class_Id_result, setNew_Get_List_UE_From_Class_Id_result] = useState([]);

    const [Get_List_UE_From_Class_Id_api, setGet_List_UE_From_Class_Id_api] = useState();
    const [Get_List_UE_From_Class_Id_message, setGet_List_UE_From_Class_Id_message] = useState();
    const [Get_List_UE_From_Class_Id_result, setGet_List_UE_From_Class_Id_result] = useState();
    function Get_List_UE_From_Class_Id(local_class_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Of_Given_Class/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_UE_From_Class_Id  res.data.status = " + res.data.status);
                //console.log(" In Get_List_UE_From_Class_Id  res.data.message r_class = " + res.data.message);
                setGet_List_UE_From_Class_Id_api("true");
                setGet_List_UE_From_Class_Id_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;

                    var local_class_id = JSON.parse(x).class_id;
                    var local_internal_url = JSON.parse(x).internal_url;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "class_id": local_class_id,
                        "internal_url": local_internal_url,

                    };
                    new_data2.push(node);
                });

                //---
                /* var node = {
                     "_id": "",
                     "id": "",
                     "label": "",
                     "code": "",
                     "titre": "",
                     "class_id": "",
                     "internal_url": "",
 
                 };
                 new_data2.push(node);*/

                if (new_data2.length > 0) {
                    setNew_Get_List_UE_From_Class_Id_result(new_data2);
                    // console.log("### setNew_Get_List_UE_From_Class_Id_result = ", new_data2);
                }
            }
            else {
                setGet_List_UE_From_Class_Id_api("false");
                setGet_List_UE_From_Class_Id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_List_UE_From_Class_Id = ', error);
            setGet_List_UE_From_Class_Id_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Getall_Class_List_Evalution_result, setNew_Getall_Class_List_Evalution_result] = useState([]);

    const [Getall_Class_List_Evalution_api, setGetall_Class_List_Evalution_api] = useState();
    const [Getall_Class_List_Evalution_message, setGetall_Class_List_Evalution_message] = useState();
    const [Getall_Class_List_Evalution_result, setGetall_Class_List_Evalution_result] = useState([]);
    function Getall_Class_List_Evalution(local_class_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Evaluation/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Class_List_Evalution  res.data.status = " + res.data.status);
                //console.log(" In Getall_Class_List_Evalution  res.data.message r_class = " + res.data.message);
                setGetall_Class_List_Evalution_api("true");
                setGetall_Class_List_Evalution_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_ue_id = JSON.parse(x).class_ue_id;
                    var local_class_ue_code = JSON.parse(x).class_ue_code;
                    var local_type_evaluation_id = JSON.parse(x).type_evaluation_id;
                    var local_type_evaluation_code = JSON.parse(x).type_evaluation_code;
                    var local_max_note = JSON.parse(x).max_note;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_type_evaluation_code + "-" + local_class_ue_code,
                        "class_id": local_class_id,
                        "class_ue_id": local_class_ue_id,
                        "class_ue_code": local_class_ue_code,
                        "type_evaluation_id": local_type_evaluation_id,
                        "type_evaluation_code": local_type_evaluation_code,
                        "max_note": local_max_note,


                    };
                    new_data2.push(node);
                });

                //---
                /* var node = {
                     "_id": "",
                     "id": "",
                     "label": "",
                     "class_id": "",
                     "class_ue_id": "",
                     "class_ue_code": "",
                     "type_evaluation_id": "",
                     "type_evaluation_code": "",
                     "max_note": "",
 
                 };
                 new_data2.push(node);*/

                if (new_data2.length > 0) {
                    setNew_Getall_Class_List_Evalution_result(new_data2);
                    //console.log(" setNew_Getall_Class_List_Evalution_result = ", new_data2);

                }
            }
            else {
                setGetall_Class_List_Evalution_api("false");
                setGetall_Class_List_Evalution_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Class_List_Evalution = ', error);
            setGetall_Class_List_Evalution_api("false");
            alert(" Impossible de recuperer la liste des évaluations de la formatio");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Inscrit_Notes_Of_Evaluations_api, setGet_Inscrit_Notes_Of_Evaluations_api] = useState();
    const [Get_Inscrit_Notes_Of_Evaluations_message, setGet_Inscrit_Notes_Of_Evaluations_message] = useState();
    const [Get_Inscrit_Notes_Of_Evaluations_result, setGet_Inscrit_Notes_Of_Evaluations_result] = useState([]);
    function Get_Inscrit_Notes_Of_Evaluations(local_inscription, local_session_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');

        var tab_my_inscription_ids = [];
        tab_my_inscription_ids.push(local_inscription);

        var tab_session_id = [];
        tab_session_id.push(local_session_id);

        form.append("token", stored_cookie);
        form.append("tab_session_id", tab_session_id);
        form.append("tab_inscription_id", tab_my_inscription_ids);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Participant_Notes/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Inscrit_Notes_Of_Evaluations  res.data.status = " + res.data.status);
                //console.log(" In Get_Inscrit_Notes_Of_Evaluations  res.data.message r_class = " + res.data.message);
                setGet_Inscrit_Notes_Of_Evaluations_api("true");
                setGet_Inscrit_Notes_Of_Evaluations_result(res.data.message);
            }
            else {
                setGet_Inscrit_Notes_Of_Evaluations_api("false");
                setGet_Inscrit_Notes_Of_Evaluations_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Inscrit_Notes_Of_Evaluations = ', error);
            setGet_Inscrit_Notes_Of_Evaluations_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [history_securite_read, sethistory_securite_read] = useState("");

    useEffect(() => {

        sethistory_securite_read(props.check_user_acces_right("historique", "read"))

        Getall_TrainingParticipant();
        Getall_TrainingSession();
        Get_List_Partner_Clients();
        GetCurrentPartnerClass();
        Get_List_Partner_Financeurs();


        let windowWidth = window.innerWidth;
        if (windowWidth < 1001) {
            setdatagrid_columns_size_model2(10);
        }

        Get_Partner_Object_Specific_Fields("inscription");

        if (props.object_key && String(props.object_key).length > 3 && props.subdata) {

            var tab_sudata = String(props.subdata).split('&');
            if (tab_sudata.length === 4)
                GetAttendee_Data_from_props(props.subdata, props.object_key);

        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }


    }, [])

    const [addOneParticipant, setaddOneParticipant] = useState("");
    const status_inscription = [

        {
            value: '0',
            label: 'PréInscription',
        },
        {
            value: '1',
            label: 'Inscription',
        },
        {
            value: '2',
            label: 'En Cours',
        },
        {
            value: '-1',
            label: 'Annulation',
        },

    ];

    const [p_detail_civilite, setp_detail_civilite] = useState();
    const [p_detail_nom, setp_detail_nom] = useState();
    const [p_detail_prenom, setp_detail_prenom] = useState();
    const [p_detail_mail, setp_detail_mail] = useState();

    const [p_detail_naissance, setp_detail_naissance] = useState();


    const [p_detail_tel, setp_detail_tel] = useState();
    const [p_detail_adresse, setp_detail_adresse] = useState();
    const [p_detail_code_postal, setp_detail_code_postal] = useState();
    const [p_detail_ville, setp_detail_ville] = useState();
    const [p_detail_pays, setp_detail_pays] = useState();
    const [p_detail_cout, setp_detail_cout] = useState();
    const [p_detail_mode_fin, setp_detail_mode_fin] = useState("");
    const [p_detail_employeur, setp_detail_employeur] = useState();

    const [p_detail_type_apprenant, setp_detail_type_apprenant] = useState();
    const [p_detail_type_apprenant_label, setp_detail_type_apprenant_label] = useState();


    const [p_date_evaluation, setp_date_evaluation] = useState();
    const [p_note_eval, setp_note_eval] = useState();
    const [p_detail_session_class, setp_detail_session_class] = useState();
    const [p_detail_session_class_id, setp_detail_session_class_id] = useState();
    const [p_detail_status_inscrit, setp_detail_status_inscrit] = useState();
    const [p_detail_status_inscrit_label, setp_detail_status_inscrit_label] = useState();
    const [p_detail_opco, setp_detail_opco] = useState();
    const [p_detail_client_rattachement_nom, setp_detail_client_rattachement_nom] = useState();
    const [p_detail_client_rattachement_id, setp_detail_client_rattachement_id] = useState();

    const [p_detail_facture_client_rattachement_id, setp_detail_facture_client_rattachement_id] = useState("");

    const [p_detail_financeur_rattachement_id, setp_detail_financeur_rattachement_id] = useState();
    const [p_detail_financeur_rattachement_nom, setp_detail_financeur_rattachement_nom] = useState();

    const [p_detail_apprenant_id, setp_detail_apprenant_id] = useState("");

    const [p_detail_tuteur1_nom, setp_detail_tuteur1_nom] = useState("");

    const [p_detail_tuteur1_civilite, setp_detail_tuteur1_civilite] = useState("");


    const [p_detail_tuteur1_prenom, setp_detail_tuteur1_prenom] = useState("");
    const [p_detail_tuteur1_email, setp_detail_tuteur1_email] = useState("");
    const [p_detail_tuteur1_telephone, setp_detail_tuteur1_telephone] = useState("");
    const [p_detail_tuteur1_adresse, setp_detail_tuteur1_adresse] = useState("");
    const [p_detail_tuteur1_cp, setp_detail_tuteur1_cp] = useState("");
    const [p_detail_tuteur1_ville, setp_detail_tuteur1_ville] = useState("");
    const [p_detail_tuteur1_pays, setp_detail_tuteur1_pays] = useState("");
    const [p_detail_tuteur1_include_com, setp_detail_tuteur1_include_com] = useState("");


    const [p_detail_tuteur2_nom, setp_detail_tuteur2_nom] = useState("");

    const [p_detail_tuteur2_civilite, setp_detail_tuteur2_civilite] = useState("");

    const [p_detail_tuteur2_prenom, setp_detail_tuteur2_prenom] = useState("");
    const [p_detail_tuteur2_email, setp_detail_tuteur2_email] = useState("");
    const [p_detail_tuteur2_telephone, setp_detail_tuteur2_telephone] = useState("");
    const [p_detail_tuteur2_adresse, setp_detail_tuteur2_adresse] = useState("");
    const [p_detail_tuteur2_cp, setp_detail_tuteur2_cp] = useState("");
    const [p_detail_tuteur2_ville, setp_detail_tuteur2_ville] = useState("");
    const [p_detail_tuteur2_pays, setp_detail_tuteur2_pays] = useState("");
    const [p_detail_tuteur2_include_com, setp_detail_tuteur2_include_com] = useState("");


    //---
    const [p_one_civilite_part, setp_one_civilite_part] = useState();
    const [p_one_formation, setp_one_formation] = useState();
    const [p_one_detail_session_class, setp_one_detail_session_class] = useState();
    const [p_one_nom_part, setp_one_nom_part] = useState();
    const [p_one_prenom_part, setp_one_prenom_part] = useState();
    const [p_one_email_part, setp_one_email_part] = useState();

    const [p_one_naissance_part, setp_one_naissance_part] = useState(new Date().toLocaleDateString('fr-FR'));

    const [p_one_phone_part, setp_one_phone_part] = useState();
    const [p_one_adresse_part, setp_one_adresse_part] = useState();
    const [p_one_ville_part, setp_one_ville_part] = useState();
    const [p_one_code_postal_part, setp_one_code_postal_part] = useState();
    const [p_one_pays_part, setp_one_pays_part] = useState();
    const [p_one_status_part, setp_one_status_part] = useState();
    const [p_one_detail_type_apprenant, setp_one_detail_type_apprenant] = useState();

    const [p_one_detail_modefinancement, setp_one_detail_modefinancement] = useState("");


    const [p_one_detail_client_rattachement_nom, setp_one_detail_client_rattachement_nom] = useState();
    const [p_one_detail_client_rattachement_id, setp_one_detail_client_rattachement_id] = useState();

    const [p_one_detail_facture_client_rattachement_id, setp_one_detail_facture_client_rattachement_id] = useState("");

    const [p_one_detail_financeur_rattachement_id, setp_one_detail_financeur_rattachement_id] = useState();
    const [p_one_detail_financeur_rattachement_nom, setp_one_detail_financeur_rattachement_nom] = useState();


    const [p_one_detail_code_session, setp_one_detail_code_session] = useState();


    //----

    const [p_myclass_title, setp_myclass_title] = useState("");
    const [p_code_session, setp_code_session] = useState("");
    const [p_adresse, setp_adresse] = useState("");
    const [p_code_postal, setp_code_postal] = useState("");
    const [p_ville, setp_ville] = useState("");
    const [p_pays, setp_pays] = useState("");
    const [p_formateur, setp_formateur] = useState("");
    const [p_lms_class_code, setp_lms_class_code] = useState("");
    const [p_nb_participant, setp_nb_participant] = useState("");
    const [p_prix_session, setp_prix_session] = useState("");

    const [p_session_ondemande, setp_session_ondemande] = useState("0");
    const [p_session_ondemande_label, setp_session_ondemande_label] = useState("");

    const [p_session_distance, setp_session_distance] = useState("0");
    const [p_session_distance_label, setp_session_distance_label] = useState("");

    const [p_session_presentiel, setp_session_presentiel] = useState("0");
    const [p_session_presentiel_label, setp_session_presentiel_label] = useState("");

    const [p_session_status, setp_session_status] = useState();
    const [p_session_status_label, setp_session_status_label] = useState();


    const [p_session_etape, setp_session_etape] = useState();
    const [p_session_etape_label, setp_session_etape_label] = useState();

    // Cette fonction remplit la zone detail de la personne inscrite
    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function GetAttendee_Data(attendee_email, session_id, internal_url) {
        setparticipant_data_changed();
        setparticipant_data_edit_mode();
        clean_old_messages();
        clearDetailAttendeFields();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", session_id);
        form.append("attendee_email", attendee_email);
        form.append("internal_url", internal_url);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetAttendeeDetail_perSession/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === "true") {
                // console.log(" In GetAttendee  res.data.status = " + res.data.status);
                // console.log(" In GetAttendee  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message) {
                    var mylocalattendee = JSON.parse(res.data.message);

                    if (mylocalattendee.inscription_validation_date) {
                        var date_du = new Date(moment(mylocalattendee.inscription_validation_date, "DD/MM/YYYY HH:mm:ss"));
                        setdetailuser_date_inscription(date_du);
                    }


                    if (mylocalattendee.code_session) {
                        setp_detail_session_class(mylocalattendee.code_session);
                        setworking_session_code(mylocalattendee.code_session);
                    }

                    if (mylocalattendee.session_id)
                        setp_detail_session_class_id(mylocalattendee.session_id);

                    if (mylocalattendee.invoiced)
                        setinscription_invoiced(mylocalattendee.invoiced);
                    else
                        setinscription_invoiced("");



                    if (mylocalattendee.status) {
                        setp_detail_status_inscrit(mylocalattendee.status);
                        setselected_id_status(mylocalattendee.status);

                        if (String(mylocalattendee.status) === "1")
                            setp_detail_status_inscrit_label("Inscrit");
                        else if (String(mylocalattendee.status) === "0")
                            setp_detail_status_inscrit_label("Preinsc.");
                        else if (String(mylocalattendee.status) === "-1")
                            setp_detail_status_inscrit_label("Annulé");
                        else if (String(mylocalattendee.status) === "2")
                            setp_detail_status_inscrit_label("Encours");
                        else
                            setp_detail_status_inscrit_label("?");
                    }


                    if (mylocalattendee.type_apprenant) {
                        setp_detail_type_apprenant(mylocalattendee.type_apprenant);
                        // console.log(" ### mylocalattendee.type_apprenant = ", mylocalattendee.type_apprenant);

                        if (String(mylocalattendee.type_apprenant) === "0")
                            setp_detail_type_apprenant_label("Autre");
                        else if (String(mylocalattendee.type_apprenant) === "1")
                            setp_detail_type_apprenant_label("Salariés");
                        else if (String(mylocalattendee.type_apprenant) === "2")
                            setp_detail_type_apprenant_label("Apprentis");
                        else if (String(mylocalattendee.type_apprenant) === "3")
                            setp_detail_type_apprenant_label("Particuliers");
                        else if (String(mylocalattendee.type_apprenant) === "4")
                            setp_detail_type_apprenant_label("Rech. Emploi");
                        else
                            setp_detail_type_apprenant_label("?");
                    }



                    if (mylocalattendee.opco)
                        setp_detail_opco(mylocalattendee.opco);



                    if (mylocalattendee.client_rattachement_id) {
                        setp_detail_client_rattachement_id(mylocalattendee.client_rattachement_id);
                        setp_detail_client_rattachement_nom(mylocalattendee.client_rattachement_nom);
                    } else {
                        setp_detail_client_rattachement_id("");
                        setp_detail_client_rattachement_nom("");
                    }

                    if (mylocalattendee.facture_client_rattachement_id) {
                        setp_detail_facture_client_rattachement_id(mylocalattendee.facture_client_rattachement_id);
                    } else {
                        setp_detail_facture_client_rattachement_id("");
                    }


                    if (mylocalattendee.financeur_rattachement_id) {
                        setp_detail_financeur_rattachement_id(mylocalattendee.financeur_rattachement_id);
                        setp_detail_financeur_rattachement_nom(mylocalattendee.financeur_rattachement_nom);

                    }


                    if (mylocalattendee.civilite)
                        setp_detail_civilite(mylocalattendee.civilite);
                    else
                        setp_detail_civilite("");


                    if (mylocalattendee.nom)
                        setp_detail_nom(mylocalattendee.nom);


                    if (mylocalattendee.apprenant_id)
                        setp_detail_apprenant_id(mylocalattendee.apprenant_id);
                    else
                        setp_detail_apprenant_id("");


                    if (mylocalattendee.prenom)
                        setp_detail_prenom(mylocalattendee.prenom);

                    if (mylocalattendee.email) {
                        setp_detail_mail(mylocalattendee.email);
                        setattendee_lms_login(mylocalattendee.email);
                    }

                    if (mylocalattendee.date_naissance) {
                        setp_detail_naissance(mylocalattendee.date_naissance);

                    }


                    if (mylocalattendee.telephone) {
                        setp_detail_tel(mylocalattendee.telephone);
                    }


                    if (mylocalattendee.adresse) {
                        setp_detail_adresse(mylocalattendee.adresse);
                    }


                    if (mylocalattendee.code_postal) {
                        setp_detail_code_postal(mylocalattendee.code_postal);
                    }


                    if (mylocalattendee.ville) {
                        setp_detail_ville(mylocalattendee.ville);
                    }


                    if (mylocalattendee.pays) {
                        setp_detail_pays(mylocalattendee.pays);
                    }



                    if (mylocalattendee.price) {
                        setp_detail_cout(mylocalattendee.price);
                    }



                    if (mylocalattendee.modefinancement) {
                        setp_detail_mode_fin(mylocalattendee.modefinancement);
                    } else {
                        setp_detail_mode_fin("");
                    }



                    if (mylocalattendee.employeur) {
                        setp_detail_employeur(mylocalattendee.employeur);
                    }


                    if (mylocalattendee.eval_date) {

                        var date_eval = new Date(moment(mylocalattendee.eval_date, "YYYY/MM/DD"));
                        setdetailuser_date_evaluation(date_eval);
                    }


                    if (mylocalattendee.certification_send_date) {
                        //alert(" certification_send_date = ", mylocalattendee.certification_send_date);

                        var date_eval = new Date(moment(mylocalattendee.certification_send_date, "DD/MM/YYYY"));
                        setdetailuser_date_certification(date_eval);
                    }

                    if (mylocalattendee.eval_note) {
                        setp_note_eval(mylocalattendee.eval_note);
                    }


                    if (mylocalattendee.eval_eval) {
                        setfield_evaluation(mylocalattendee.eval_eval);

                    }

                    if (mylocalattendee.lms_pwd) {
                        setattendee_lms_pwd("xxxxxx");
                    }

                    // Recuperation des tuteurs
                    if (mylocalattendee.tuteur1_nom) {
                        setp_detail_tuteur1_nom(mylocalattendee.tuteur1_nom);

                    }

                    if (mylocalattendee.tuteur1_civilite) {
                        setp_detail_tuteur1_civilite(mylocalattendee.tuteur1_civilite);

                    } else {
                        setp_detail_tuteur1_civilite("");
                    }

                    if (mylocalattendee.tuteur1_prenom) {
                        setp_detail_tuteur1_prenom(mylocalattendee.tuteur1_prenom);
                    }

                    if (mylocalattendee.tuteur1_email) {
                        setp_detail_tuteur1_email(mylocalattendee.tuteur1_email);
                    }

                    if (mylocalattendee.tuteur1_telephone) {
                        setp_detail_tuteur1_telephone(mylocalattendee.tuteur1_telephone);
                    }

                    if (mylocalattendee.tuteur1_adresse) {
                        setp_detail_tuteur1_adresse(mylocalattendee.tuteur1_adresse);
                    }

                    if (mylocalattendee.tuteur1_cp) {
                        setp_detail_tuteur1_cp(mylocalattendee.tuteur1_cp);
                    }

                    if (mylocalattendee.tuteur1_ville) {
                        setp_detail_tuteur1_ville(mylocalattendee.tuteur1_ville);
                    }

                    if (mylocalattendee.tuteur1_pays) {
                        setp_detail_tuteur1_pays(mylocalattendee.tuteur1_pays);
                    }

                    if (mylocalattendee.tuteur1_include_com) {
                        if (String(mylocalattendee.tuteur1_include_com) === "1")
                            setp_detail_tuteur1_include_com(true);
                        else
                            setp_detail_tuteur1_include_com(false);

                    }

                    if (mylocalattendee.tuteur2_nom) {
                        setp_detail_tuteur2_nom(mylocalattendee.tuteur2_nom);

                    }

                    if (mylocalattendee.tuteur2_civilite) {
                        setp_detail_tuteur2_civilite(mylocalattendee.tuteur2_civilite);
                    } else {
                        setp_detail_tuteur2_civilite("");
                    }


                    if (mylocalattendee.tuteur2_prenom) {
                        setp_detail_tuteur2_prenom(mylocalattendee.tuteur2_prenom);
                    }

                    if (mylocalattendee.tuteur2_email) {
                        setp_detail_tuteur2_email(mylocalattendee.tuteur2_email);
                    }

                    if (mylocalattendee.tuteur2_telephone) {
                        setp_detail_tuteur2_telephone(mylocalattendee.tuteur2_telephone);
                    }

                    if (mylocalattendee.tuteur2_adresse) {
                        setp_detail_tuteur2_adresse(mylocalattendee.tuteur2_adresse);
                    }

                    if (mylocalattendee.tuteur2_cp) {
                        setp_detail_tuteur2_cp(mylocalattendee.tuteur2_cp);
                    }

                    if (mylocalattendee.tuteur2_ville) {
                        setp_detail_tuteur2_ville(mylocalattendee.tuteur2_ville);
                    }

                    if (mylocalattendee.tuteur2_pays) {
                        setp_detail_tuteur2_pays(mylocalattendee.tuteur2_pays);
                    }

                    if (mylocalattendee.tuteur2_include_com) {

                        if (String(mylocalattendee.tuteur2_include_com) === "1")
                            setp_detail_tuteur2_include_com(true);
                        else
                            setp_detail_tuteur2_include_com(false);
                    }

                    /*
                   * Update 22/10/2023 :
                   Gestion des champs spécifiques. ils commencent tous par 'my_'
                   */
                    for (let i = 0; i < rows_champs_specifics.length; i++) {

                        var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
                        var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
                        var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
                        var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


                        if (mylocalattendee.hasOwnProperty(field_name)) {
                            var local_valeur = mylocalattendee[String(field_name)]

                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = local_valeur;

                            var new_val = { 'field_name': field_name, 'field_value': local_valeur, 'field_type': field_type }
                            var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);

                            if (johnIndex > -1) {
                                spec_field_updated_values[johnIndex] = new_val;

                            } else {
                                spec_field_updated_values.push(new_val);
                            }

                        } else {
                            if (document.getElementById(String(field_name)))
                                document.getElementById(String(field_name)).value = "";
                        }
                    }

                    setspec_field_updated_values_hooks(spec_field_updated_values);
                    /*
                                       *  end Update 22/10/2023 :
                                       */


                    var myelement;

                    if (document.getElementById('ajout_participant')) {
                        //myelement = document.getElementById('ajout_participant');
                        var divh = document.getElementById('ajout_participant').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }


                    disableAttendeeDetailFields();


                } else {
                    alert("Aucune donnée récuperée ");
                }

            } else if (String(res.data.status) === String("Err_Connexion")) {

                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetAttendee = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données du participant");
            //setmyApimyApiMessage("")
        })
    }

    let [sessionChanged, setsessionChanged] = useState(false);
    let [participant_data_changed, setparticipant_data_changed] = useState();
    let [participant_data_edit_mode, setparticipant_data_edit_mode] = useState();

    function IssessionChanged() {
        setsessionChanged(true);
    }




    const [is_lms_class_code, setis_lms_class_code] = useState();

    const [display_detail_insc, setdisplay_detail_insc] = React.useState();

    const [selectedattendeeemail, setselectedattendeeemail] = useState([])

    function Display_Detail_Inscription(event, cellValues) {
        clearDetailAttendeFields();
        setdisplay_detail_insc("1")
        var montant = String(cellValues.row.amount);
        var nom = cellValues.row.nom;
        var email = cellValues.row.email;
        setselectedattendeeemail(email);
        var prenom = cellValues.row.prenom;
        GetAttendee_Data(email, selected_code_session);
        getStagiaireImages(selected_internal_url, selected_code_session, email,);
    }

    const [getPartnerImages_api, setgetPartnerImages_api] = useState();
    const [getPartnerImages_message, setgetPartnerImages_message] = useState();
    const [getPartnerImages_result, setgetPartnerImages_result] = useState();
    function getStagiaireImages(local_internal_url, local_selected_code_session, local_email) {

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedStagiaireImage_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", local_internal_url);
        form.append("session_id", local_selected_code_session);
        form.append("email", local_email);


        axios.post(myurl, form).then(res => {

            //console.log(" getStagiaireImages : In test  res.data.status = " + res.data.status);
            // console.log(" getStagiaireImages: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setgetPartnerImages_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setgetPartnerImages_api("false");
                setgetPartnerImages_message(res.data.message)

            }
        }).catch((error) => {
            console.warn('getStagiaireImages ee: Not good man :(  = ', error);
            setgetPartnerImages_api("false");
            setgetPartnerImages_message(" Impossible de recuperer les images/médias du partenaire")
        })

    }

    let [fields1desabled, setfields1desabled] = useState(true);

    const [One_Create_Participant_api, setOne_Create_Participant_api] = useState();
    const [One_Create_Participant_message, setOne_Create_Participant_message] = useState();
    const [One_Create_Participant_result, setOne_Create_Participant_result] = useState();
    function One_Create_Participant() {
        clean_old_messages();
        var form = new FormData();
        var nb_participant = "0";
        var prix_session;



        if (!p_one_civilite_part || p_one_civilite_part.trim() == "") {
            alert(" Vous devez saisir la civilité du participant");
            return;
        }

        if (!p_one_nom_part || p_one_nom_part.trim() == "") {
            alert(" Vous devez saisir le nom du participant");
            return;
        }


        if (!p_one_prenom_part || p_one_prenom_part.trim() == "") {
            alert(" Vous devez saisir le prenom du participant");
            return;
        }

        if (!p_one_naissance_part || p_one_naissance_part.trim() == "") {
            alert(" Vous devez saisir la date de naissance du participant");
            return;
        }

        if (!p_one_email_part || p_one_email_part.trim() == "") {
            alert(" Vous devez saisir l'email du participant");
            return;
        }




        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_one_email_part.trim().match(validRegex)) {
            alert("L'adresse email est invalide 1.");
            return;
        }


        if (!p_one_phone_part || p_one_phone_part.trim() == "") {
            alert(" Vous devez saisir le téléphone du participant");
            return;
        }


        if (!p_one_detail_session_class || p_one_detail_session_class.trim() == "") {
            alert(" La session est invalide");
            return;
        }


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", p_one_formation);
        form.append("session_id", p_one_detail_session_class);
        form.append("civilite", p_one_civilite_part.trim());
        form.append("nom", p_one_nom_part.trim());
        form.append("prenom", p_one_prenom_part.trim());
        form.append("email", p_one_email_part.trim());
        form.append("date_naissance", p_one_naissance_part);
        form.append("telephone", p_one_phone_part.trim());

        if (String(p_one_civilite_part).trim().length == 0) {
            alert("La civilité  est invalide.");
            return;
        }

        if (String(p_one_nom_part).trim().length < 2) {
            alert("Le nom est invalide.");
            return;
        }

        if (String(p_one_prenom_part).trim().length < 2) {
            alert("Le prenom est invalide.");
            return;
        }

        if (String(p_one_phone_part).trim().length < 2) {
            alert("Le Téléphone est invalide.");
            return;
        }


        if (p_one_adresse_part)
            form.append("adresse", p_one_adresse_part);
        else
            form.append("adresse", "");

        if (p_one_code_postal_part)
            form.append("code_postal", p_one_code_postal_part);
        else
            form.append("code_postal", "");

        if (p_one_ville_part)
            form.append("ville", p_one_ville_part);
        else
            form.append("ville", "");

        if (p_one_pays_part)
            form.append("pays", p_one_pays_part);
        else
            form.append("pays", "");

        if (p_one_detail_type_apprenant)
            form.append("type_apprenant", p_one_detail_type_apprenant);
        else
            form.append("type_apprenant", "0");


        if (p_one_status_part)
            form.append("status", p_one_status_part);
        else
            form.append("status", "");

        if (p_one_detail_client_rattachement_id)
            form.append("client_rattachement_id", p_one_detail_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");


        if (p_one_detail_financeur_rattachement_id)
            form.append("financeur_rattachement_id", p_one_detail_financeur_rattachement_id);
        else
            form.append("financeur_rattachement_id", "");

        if (p_one_detail_facture_client_rattachement_id)
            form.append("facture_client_rattachement_id", p_one_detail_facture_client_rattachement_id);
        else
            form.append("facture_client_rattachement_id", "");


        form.append("modefinancement", p_one_detail_modefinancement);


        /*
         Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
       */
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {

            var local_value = String(spec_field_updated_values_hooks[i].field_value);

            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {
                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }
            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }
            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), String(spec_field_updated_values_hooks[i].field_value));
        }


        //console.log(" #### form = ", form);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/AddStagiairetoClass/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In One_Create_Participant  res.data.status = " + res.data.status);
            //console.log(" In One_Create_Participant  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setOne_Create_Participant_api("true");
                setOne_Create_Participant_result(res.data.message);
                setaddOneParticipant();
                Getall_TrainingParticipant();
                clear_one_Attende_Fields();
                setselected_id("");

                alert(res.data.message);

                //myRef_head.current.scrollIntoView({ behavior: "smooth" });
                if (document.getElementById('myRef_head')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setOne_Create_Participant_api("false");
                setOne_Create_Participant_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('One_Create_Participant : Not good man :(  = ' + error);
            setOne_Create_Participant_api("false");
            alert(" Impossible d'ajouter le participant")

        })
    }

    const myRef_head = useRef(null)

    const [UpdateStagiaireData_api, setUpdateStagiaireData_api] = useState();
    const [UpdateStagiaireData_message, setUpdateStagiaireData_message] = useState();
    const [UpdateStagiaireData_result, setUpdateStagiaireData_result] = useState();
    function Update_One_StagiaireData() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");



        if (p_detail_session_class.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", p_detail_session_class_id);
        form.append("civilite", p_detail_civilite);
        form.append("nom", p_detail_nom);
        form.append("prenom", p_detail_prenom);
        form.append("email", p_detail_mail);
        form.append("telephone", p_detail_tel);
        form.append("date_naissance", p_detail_naissance);

        if (!p_detail_nom || String(p_detail_nom).trim().length < 2) {
            alert("Le nom est invalide.");
            return;
        }

        if (!p_detail_prenom || String(p_detail_prenom).trim().length < 2) {
            alert("Le prenom est invalide.");
            return;
        }

        if (!p_detail_civilite || String(p_detail_civilite).trim() === "") {
            alert("La civilité est invalide.");
            return;
        }

        if (!p_detail_tel || String(p_detail_tel).trim().length < 2) {
            alert("Le numéro de téléphone est invalide.");
            return;
        }


        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!p_detail_mail.trim().match(validRegex)) {
            alert("L'adresse email est invalide.");
            return;
        }



        if (p_detail_employeur)
            form.append("employeur", p_detail_employeur);
        else
            form.append("employeur", "");

        form.append("status", p_detail_status_inscrit);

        if (p_detail_cout)
            form.append("price", p_detail_cout);
        else
            form.append("price", "");


        //---

        if (p_detail_adresse)
            form.append("adresse", p_detail_adresse);
        else
            form.append("adresse", "");

        if (p_detail_code_postal)
            form.append("code_postal", p_detail_code_postal);
        else
            form.append("code_postal", "");


        if (p_detail_ville)
            form.append("ville", p_detail_ville);
        else
            form.append("ville", "");


        if (p_detail_pays)
            form.append("pays", p_detail_pays);
        else
            form.append("pays", "");


        if (p_detail_mode_fin)
            form.append("modefinancement", p_detail_mode_fin);
        else
            form.append("modefinancement", "");


        if (p_detail_opco)
            form.append("opco", p_detail_opco);
        else
            form.append("opco", "");

        if (p_detail_type_apprenant)
            form.append("type_apprenant", p_detail_type_apprenant);
        else
            form.append("type_apprenant", "0");


        form.append("_id", selected_id);

        if (p_detail_client_rattachement_id)
            form.append("client_rattachement_id", p_detail_client_rattachement_id);
        else
            form.append("client_rattachement_id", "");


        if (p_detail_facture_client_rattachement_id)
            form.append("facture_client_rattachement_id", p_detail_facture_client_rattachement_id);
        else
            form.append("facture_client_rattachement_id", "");



        if (p_detail_financeur_rattachement_id)
            form.append("financeur_rattachement_id", p_detail_financeur_rattachement_id);
        else
            form.append("financeur_rattachement_id", "");

        /*
  Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
*/
        for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {

            var local_value = String(spec_field_updated_values_hooks[i].field_value);

            if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

                local_value = local_value.replaceAll(",", ".");

                if (isNaN(local_value)) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
                    return;
                }

            }
            else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

            }
            if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
                if (String(local_value).trim().length <= 0) {
                    alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
                    return;
                }

            }

            form.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
        }

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdateStagiaireData_api("true");
                setUpdateStagiaireData_result(res.data.message);
                setparticipant_data_changed();
                Getall_TrainingParticipant();
                disableAttendeeDetailFields();
                setparticipant_data_changed();
                setparticipant_data_edit_mode();
                GetAttendee_Data(p_detail_mail, p_detail_session_class_id, selected_internal_url);
                alert(" La mise à jour a été correctement faite.");


                //myRef_head.current.scrollIntoView({ behavior: "smooth" });
                if (document.getElementById('myRef_head')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdateStagiaireData_api("false");
                setUpdateStagiaireData_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setUpdateStagiaireData_api("false");
            alert(" Impossible de mettre à jour le participant");

        })
    }


    const [Re_Preinscrire_Cancelled_Attendee_api, setRe_Preinscrire_Cancelled_Attendee_api] = useState();
    const [Re_Preinscrire_Cancelled_Attendee_message, setRe_Preinscrire_Cancelled_Attendee_message] = useState();
    const [Re_Preinscrire_Cancelled_Attendee_result, setRe_Preinscrire_Cancelled_Attendee_result] = useState();
    function Re_Preinscrire_Cancelled_Attendee() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");



        if (p_detail_session_class.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", selected_internal_url);
        form.append("session_id", p_detail_session_class_id);
        form.append("email", p_detail_mail);
        form.append("status", "0");
        form.append("_id", selected_id);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Re_Preinscrire_Cancelled_Attendee  res.data.status = " + res.data.status);
            //console.log(" In Re_Preinscrire_Cancelled_Attendee  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setRe_Preinscrire_Cancelled_Attendee_api("true");
                setRe_Preinscrire_Cancelled_Attendee_result(res.data.message);
                setparticipant_data_changed();
                Getall_TrainingParticipant();
                disableAttendeeDetailFields();
                setparticipant_data_changed();
                setparticipant_data_edit_mode();
                GetAttendee_Data(p_detail_mail, p_detail_session_class_id, selected_internal_url);
                alert(" La mise à jour a été correctement faite.");


                //myRef_head.current.scrollIntoView({ behavior: "smooth" });
                if (document.getElementById('myRef_head')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setRe_Preinscrire_Cancelled_Attendee_api("false");
                setRe_Preinscrire_Cancelled_Attendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Re_Preinscrire_Cancelled_Attendee : Not good man :( mysearchtext = ' + error);
            setRe_Preinscrire_Cancelled_Attendee_api("false");
            alert(" Impossible de mettre à jour le participant");

        })
    }


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };



    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "raison_sociale": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Get_List_Partner_Financeurs_result, setNew_Get_List_Partner_Financeurs_result] = useState([]);

    const [Get_List_Partner_Financeurs_api, setGet_List_Partner_Financeurs_api] = useState();
    const [Get_List_Partner_Financeurs_message, setGet_List_Partner_Financeurs_message] = useState();
    const [Get_List_Partner_Financeurs_result, setGet_List_Partner_Financeurs_result] = useState();
    function Get_List_Partner_Financeurs(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("is_financeur", "1");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client_with_filter_Like/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Financeurs  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Financeurs  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Financeurs_api("true");
                setGet_List_Partner_Financeurs_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "raison_sociale": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Financeurs_result(new_data2);
            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Financeurs_api("false");
                setGet_List_Partner_Financeurs_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Partner_Financeurs = ', error);
            setGet_List_Partner_Financeurs_api("false");
            //setmyApimyApiMessage("")
        })
    }




    function annule_Add_One_Participant() {
        clear_one_Attende_Fields();
        setaddOneParticipant();

    }

    const [New_GetCurrentPartnerClass_result, setNew_GetCurrentPartnerClass_result] = useState([]);

    const [GetCurrentPartnerClass_api, setGetCurrentPartnerClass_api] = useState();
    const [GetCurrentPartnerClass_message, setGetCurrentPartnerClass_message] = useState();
    const [GetCurrentPartnerClass_result, setGetCurrentPartnerClass_result] = useState();
    function GetCurrentPartnerClass(event) {
        var form = new FormData();


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetCurrentPartnerClass  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentPartnerClass  res.data.message r_class = " + res.data.message);
                setGetCurrentPartnerClass_api("true");
                setGetCurrentPartnerClass_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_internal_url = JSON.parse(x).internal_url;
                    var local_title = JSON.parse(x).title;
                    var local_external_code = JSON.parse(x).external_code;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_title,
                        "internal_url": local_internal_url,
                        "title": local_title,
                        "external_code": local_external_code
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "internal_url": "",
                    "title": "",
                    "external_code": ""
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_GetCurrentPartnerClass_result(new_data2);



            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetCurrentPartnerClass_api("false");
                setGetCurrentPartnerClass_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( mysearchtext = ', error);
            setGetCurrentPartnerClass_api("false");
            //setmyApimyApiMessage("")
        })
    }

    // Cette fonction va aider à prefiltrer les code session en fonction de formation choisie
    function handle_change_p_one_formation(event) {

        setp_one_formation(event.target.value);
    }

    const [SessionendDateInscription, setSessionendDateInscription] = useState();

    function clearSessionFields() {

        if (document.getElementsByName("sessiondatedebut")[0])
            document.getElementsByName("code_session")[0].value = "";

        if (document.getElementsByName("sessiondatedebut")[0])
            document.getElementsByName("sessiondatedebut")[0].value = "";

        if (document.getElementsByName("sessiondatefin")[0])
            document.getElementsByName("sessiondatefin")[0].value = "";

        if (document.getElementsByName("session_status")[0])
            document.getElementsByName("session_status")[0].value = "";

        if (document.getElementsByName("session_etape")[0])
            document.getElementsByName("session_etape")[0].value = "";



        if (document.getElementsByName("adresse")[0])
            document.getElementsByName("adresse")[0].value = "";

        if (document.getElementsByName("code_postal")[0])
            document.getElementsByName("code_postal")[0].value = "";

        if (document.getElementsByName("ville")[0])
            document.getElementsByName("ville")[0].value = "";


        if (document.getElementsByName("pays")[0])
            document.getElementsByName("pays")[0].value = "";

        if (document.getElementsByName("nb_participant")[0])
            document.getElementsByName("nb_participant")[0].value = "";

        if (document.getElementsByName("prix_session")[0])
            document.getElementsByName("prix_session")[0].value = "";

        if (document.getElementsByName("presentiel")[0])
            document.getElementsByName("presentiel")[0].value = "";

        if (document.getElementsByName("session_ondemande")[0])
            document.getElementsByName("session_ondemande")[0].value = "";

        if (document.getElementsByName("distantiel")[0])
            document.getElementsByName("distantiel")[0].value = "";

        if (document.getElementsByName("sessiondatedebutinscription")[0])
            document.getElementsByName("sessiondatedebutinscription")[0].value = "";

        if (document.getElementsByName("sessiondatefininscription")[0])
            document.getElementsByName("sessiondatefininscription")[0].value = "";

        setSessionstartDateInscription();
        setSessionendDateInscription();
        setSessionendDate();
        setSessionstartDate();

        setfield_contenu_ftion();


        setp_myclass_title();
        setp_code_session();
        setp_adresse();
        setp_ville();
        setp_pays();
        setp_formateur();
        setp_lms_class_code();
        setp_nb_participant();
        setp_prix_session();
        setp_session_etape_label();
        setp_session_etape();
        setp_session_status_label();
        setp_session_status();
        setp_session_presentiel_label();
        setp_session_presentiel();
        setp_session_distance_label();
        setp_session_distance();
        setp_session_ondemande_label();
        setp_session_ondemande();



    }

    let [sessionStatus, setsessionStatus] = useState(false);
    const [selectedCertif, setselectedCertif] = useState();
    const [GetCurrentSession_id, setGetCurrentSession_id] = useState();

    const [urlpreview_certif, seturlpreview_certif] = useState();

    const [GetSpecificAttestation_Certif_api, setGetSpecificAttestation_Certif_api] = useState();
    const [GetSpecificAttestation_Certif_message, setGetSpecificAttestation_Certif_message] = useState();
    const [GetSpecificAttestation_Certif_result, setGetSpecificAttestation_Certif_result] = useState();
    function GetSpecificAttestation_Certif(nom) {
        clean_old_messages();
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("nom", nom);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetSpecificPartnerAttestation_Certificat/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In GetSpecificAttestation_Certif  res.data.status = " + res.data.status);
                //console.log(" In GetSpecificAttestation_Certif  res.data.message r_class = " + res.data.message);
                setGetSpecificAttestation_Certif_api("true");
                setGetSpecificAttestation_Certif_result(res.data.message);

                var mylocal = JSON.parse(res.data.message);
                //console.log(" ####  (mylocal.preview_url = ", mylocal.preview_url);
                seturlpreview_certif(mylocal.preview_url);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGetSpecificAttestation_Certif_api("false");
                setGetSpecificAttestation_Certif_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( GetSpecificAttestation_Certif = ', error);
            setGetSpecificAttestation_Certif_api("false");

        })
    }


    const [GetCurrentSession_api, setGetCurrentSession_api] = useState();
    const [GetCurrentSession_message, setGetCurrentSession_message] = useState();
    const [GetCurrentSession_result, setGetCurrentSession_result] = useState();
    function GetCurrentSession(local_session_id, local_selected_internal_url) {

        clean_old_messages();
        clearSessionFields();

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_internal_url", local_selected_internal_url);
        form.append("session_id", local_session_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetSessionFormation/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In GetCurrentSession  res.data.status = " + res.data.status);
                //console.log(" In GetCurrentSession  res.data.message r_class = " + res.data.message);
                //console.log(" In GetCurrentSession  res.data.message len = " + String(res.data.message).length);

                setGetCurrentSession_api("true");
                if (String(res.data.message).length > 0) {
                    setGetCurrentSession_result(res.data.message);
                    var mylocaltraining = JSON.parse(res.data.message);

                    if (mylocaltraining._id) {
                        setGetCurrentSession_id(mylocaltraining._id);
                    }

                    if (mylocaltraining.session_status) {
                        setp_session_status(mylocaltraining.session_status);
                        if (String(mylocaltraining.session_status) === "1")
                            setp_session_status_label("Actif");
                        else
                            setp_session_status_label("Inactif");
                    }

                    if (mylocaltraining.session_etape) {
                        setp_session_etape(mylocaltraining.session_etape);
                        if (String(mylocaltraining.session_etape) === "0")
                            setp_session_etape_label("Projet");
                        else if (String(mylocaltraining.session_etape) === "1")
                            setp_session_etape_label("Planification");
                        else if (String(mylocaltraining.session_etape) === "2")
                            setp_session_etape_label("Planifiée");
                        else if (String(mylocaltraining.session_etape) === "3")
                            setp_session_etape_label("Terminée");
                        else if (String(mylocaltraining.session_etape) === "4")
                            setp_session_etape_label("Facturée");
                        else if (String(mylocaltraining.session_etape) === "-1")
                            setp_session_etape_label("Annulée");

                    } else {


                        setp_session_etape_label("Projet");
                        setp_session_etape("0");
                    }



                    if (mylocaltraining.date_debut) {
                        //var date_du = new Date(moment(mylocaltraining.date_debut, "DD/MM/YYYY HH:mm:ss"));
                        setSessionstartDate(String(mylocaltraining.date_debut).substring(0, 10));
                    }

                    if (mylocaltraining.date_fin) {

                        setSessionendDate(String(mylocaltraining.date_fin).substring(0, 10));
                    }

                    if (mylocaltraining.date_debut_inscription) {

                        setSessionstartDateInscription(String(mylocaltraining.date_debut_inscription).substring(0, 10));

                    }

                    if (mylocaltraining.date_fin_inscription) {

                        setSessionendDateInscription(String(mylocaltraining.date_fin_inscription).substring(0, 10));
                    }

                    if (mylocaltraining.attestation_certif) {
                        setselectedCertif(mylocaltraining.attestation_certif);
                        GetSpecificAttestation_Certif(mylocaltraining.attestation_certif);

                    }


                    if (mylocaltraining.myclass[0].title)
                        setp_myclass_title(mylocaltraining.myclass[0].title);



                    if (mylocaltraining.code_session)
                        setp_code_session(mylocaltraining.code_session);


                    if (mylocaltraining.nb_participant)
                        setp_nb_participant(mylocaltraining.nb_participant);


                    if (mylocaltraining.prix_session)
                        setp_prix_session(mylocaltraining.prix_session);

                    if (mylocaltraining.distantiel) {
                        setp_session_distance(mylocaltraining.distantiel);
                        if (String(mylocaltraining.distantiel) === "1")
                            setp_session_distance_label("Oui");
                        else
                            setp_session_distance_label("Non")
                    }


                    if (mylocaltraining.session_ondemande) {
                        setp_session_ondemande(mylocaltraining.session_ondemande);
                        if (String(mylocaltraining.session_ondemande) === "1")
                            setp_session_ondemande_label("Oui");
                        else
                            setp_session_ondemande_label("Non")

                    }

                    if (mylocaltraining.presentiel) {
                        setp_session_presentiel(mylocaltraining.presentiel);
                        if (String(mylocaltraining.presentiel) === "1")
                            setp_session_presentiel_label("Oui");
                        else
                            setp_session_presentiel_label("Non")
                    }

                    if (mylocaltraining.contenu_ftion)
                        setfield_contenu_ftion(mylocaltraining.contenu_ftion);




                    if (mylocaltraining.adresse)
                        setp_adresse(mylocaltraining.adresse);


                    if (mylocaltraining.ville) {
                        setp_ville(mylocaltraining.ville);
                    }


                    if (mylocaltraining.ville) {
                        setp_pays(mylocaltraining.pays);
                    }


                    if (mylocaltraining.formateur) {
                        setp_formateur(mylocaltraining.formateur);
                    }


                    if (mylocaltraining.lms_class_code) {

                        setis_lms_class_code(mylocaltraining.lms_class_code);
                    }


                    if (mylocaltraining.code_postal)
                        setp_code_postal(mylocaltraining.code_postal);


                    if (String(mylocaltraining.session_status) === "true")
                        setsessionStatus(true);
                    else if (String(mylocaltraining.session_status) === "false")
                        setsessionStatus(false);


                    if (document.getElementById('detail_session')) {
                        // myRef.current.scrollIntoView({ behavior: "smooth" });
                        var divh = document.getElementById('detail_session').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }
                }

            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetCurrentSession_api("false");
                setGetCurrentSession_message(res.data.message);
                alert("Erreur : " + res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( GetCurrentSession = ', error);
            setGetCurrentSession_api("false");

        })
    }


    function desableSessionFields() {

        if (document.getElementsByName("myclass_title")[0]) {
            document.getElementsByName("myclass_title")[0].disabled = true;
            document.getElementsByName("myclass_title")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("code_session")[0]) {
            document.getElementsByName("code_session")[0].disabled = true;
            document.getElementsByName("code_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_status")[0]) {
            document.getElementsByName("session_status")[0].disabled = true;
            document.getElementsByName("session_status")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_etape")[0]) {
            document.getElementsByName("session_etape")[0].disabled = true;
            document.getElementsByName("session_etape")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatedebut")[0]) {
            document.getElementsByName("sessiondatedebut")[0].disabled = true;
            document.getElementsByName("sessiondatedebut")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatefin")[0]) {
            document.getElementsByName("sessiondatefin")[0].disabled = true;
            document.getElementsByName("sessiondatefin")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("adresse")[0]) {
            document.getElementsByName("adresse")[0].disabled = true;
            document.getElementsByName("adresse")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("code_postal")[0]) {
            document.getElementsByName("code_postal")[0].disabled = true;
            document.getElementsByName("code_postal")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("ville")[0]) {
            document.getElementsByName("ville")[0].disabled = true;
            document.getElementsByName("ville")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("pays")[0]) {
            document.getElementsByName("pays")[0].disabled = true;
            document.getElementsByName("pays")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("formateur")[0]) {
            document.getElementsByName("formateur")[0].disabled = true;
            document.getElementsByName("formateur")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("lms_class_code")[0]) {
            document.getElementsByName("lms_class_code")[0].disabled = true;
            document.getElementsByName("lms_class_code")[0].style.backgroundColor = "#ECEFF1";
        }


        if (document.getElementsByName("nb_participant")[0]) {
            document.getElementsByName("nb_participant")[0].disabled = true;
            document.getElementsByName("nb_participant")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("prix_session")[0]) {
            document.getElementsByName("prix_session")[0].disabled = true;
            document.getElementsByName("prix_session")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("session_ondemande")[0]) {
            document.getElementsByName("session_ondemande")[0].disabled = true;
            document.getElementsByName("session_ondemande")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("presentiel")[0]) {
            document.getElementsByName("presentiel")[0].disabled = true;
            document.getElementsByName("presentiel")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("distantiel")[0]) {
            document.getElementsByName("distantiel")[0].disabled = true;
            document.getElementsByName("distantiel")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatedebutinscription")[0]) {
            document.getElementsByName("sessiondatedebutinscription")[0].disabled = true;
            document.getElementsByName("sessiondatedebutinscription")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("sessiondatefininscription")[0]) {
            document.getElementsByName("sessiondatefininscription")[0].disabled = true;
            document.getElementsByName("sessiondatefininscription")[0].style.backgroundColor = "#ECEFF1";
        }

        // Pour les champs spécifiques
        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;

            if (document.getElementsByName(String(field_name))[0]) {
                document.getElementsByName(String(field_name))[0].disabled = true;
                document.getElementsByName(String(field_name))[0].style.backgroundColor = "#ECEFF1";
            }
        }

        setfields1desabled(true);

    }


    const [isLoading, setLoading] = useState();


    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();

    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const [tab_convention_pieces_jointes_result, settab_convention_pieces_jointes_result] = useState([]);

    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size 2 =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;


    };



    const [sessions_file_change_1_convention_api, setsessions_file_change_1_convention_api] = useState();
    const [sessions_file_change_1_convention_result, setsessions_file_change_1_convention_result] = useState();
    const [sessions_file_change_1_convention_message, setsessions_file_change_1_convention_message] = useState();
    const sessions_file_change_1_convention = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        if (file_type !== "application/pdf") {
            alert("Le fichier n'est pas de type PDF");
            return;
        }



        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


        var new_tmp = [];

        if (tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.length > 0) {

            tab_convention_pieces_jointes_result.map((x) => {
                new_tmp.push(x);

            });

        }

        new_tmp.push(event.target.files[0])

        settab_convention_pieces_jointes_result(new_tmp);

        console.log(" tab_convention_pieces_jointes_result = ", tab_convention_pieces_jointes_result)


        return;


    };

    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        console.log("file_size 1 =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }
        setfile_2_name(event.target.files[0]);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;


    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();

    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            //console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "inscription");
            formData.append("object_owner_id", selected_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);

            setLoading(true);
            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    setLoading(false);
                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(selected_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                        alert(result['message']);
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                    alert(" Impossible d'ajouter la pièce jointe")
                });

        }


    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();

    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(selected_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                    alert(result['message'])
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }


    const Delete_one_attached_document_convention = (event) => {
        var file_name = event.target.id;

    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();

    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "inscription");
        formData.append("object_owner_id", local_employee_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);
        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes

    // Action en mass

    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();
    const actionmass_ftion = (event) => {
        const value = event.target.value;


        if (String(value) === "supprimer" || String(value) === "convention" || String(value) === "desactiver"
            || String(value) === "exporter") {
            setactionmass_ftion_val(value);
        }
        else {
            setactionmass_ftion_val();
        }
    }


    const [liste_selected_inscription_id, setliste_selected_inscription_id] = useState([]);
    async function actionmass_ftion_Traitemet() {


        var liste_session = "";
        var liste_emails = "";

        if (display_stagiaire_with_alert && display_stagiaire_with_alert === true) {
            liste_session = GetSelectedRowsSession_filtered_on_alert();
            liste_emails = GetSelectedRows_Emails_filtered_on_alert();
        }
        else {
            liste_session = GetSelectedRowsSession();
            liste_emails = GetSelectedRows_Emails();
        }

        setliste_selected_inscription_id(liste_session);
        setselectedtraining_emails(liste_session);

        console.log(" liste_session = ", liste_session);


        //console.log(" actionmass_ftion_val = ", actionmass_ftion_val);

        if (String(actionmass_ftion_val) === String("valide_inscr")) {
            Accept_List_Inscritpion(liste_session);

        }


        if (String(actionmass_ftion_val) === String("supprimer")) {
            Delete_LIST_Stagiaires(liste_session);
        }

        else if (String(actionmass_ftion_val) === String("convention")) {
            Get_List_Stagiaire_Conventions_Individuel();
            setselected_convention_id();
            setselected_convention_type();
            setselected_convention_email_destinataire_prod();
            setselected_convention_email_test();
            setDialog_convention_open(true);
        }

        else if (String(actionmass_ftion_val) === String("exporter")) {
            Export_Inscriptions(liste_session);
        }
        else if (String(actionmass_ftion_val) === String("recyclage_disable")) {
            Recyclage_Desactivation(liste_session);
        }
        else if (String(actionmass_ftion_val) === String("sendemail")) {
            // console.log(" #### selectedtraining_emails_liste = ", selectedtraining_emails_liste);
            setDialog_SEND_EMAIL_open(true);
        }
    }

    function Export_Inscriptions(tab_class) {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_inscriptions.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_Inscription_To_Excel_From_from_List_Id/" + stored_cookie + "/" + tab_class;


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les inscrits (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les inscrits ")

            });


        setLoading(false);
    }

    const [selectedtraining, setselectedtraining] = useState([])
    function GetSelectedRowsSession() {
        //  alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length);
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);
        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss[myid]);
            tab_tmp.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining(tab_tmp);
        return tab_tmp;

    }

    function GetSelectedRowsSession_filtered_on_alert() {
        //alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length);
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);


        var tab_tmp = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss.filter((data) => (JSON.parse(data)).warning_recyclage === "1")[myid]);
            tab_tmp.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining(tab_tmp);
        return tab_tmp;

    }


    const [selectedtraining_emails, setselectedtraining_emails] = useState([])
    const [selectedtraining_emails_liste, setselectedtraining_emails_liste] = useState("")

    const [selected_list_inscription_id, setselected_list_inscription_id] = useState("")


    function GetSelectedRows_Emails() {

        var tab_tmp = []
        var tab_tmp_inscription_id = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss[myid]);
            tab_tmp.push(line.email);
            tab_tmp_inscription_id.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining_emails(tab_tmp);

        setselectedtraining_emails_liste(tab_tmp.join());

        setselected_list_inscription_id(tab_tmp_inscription_id.join());

        return tab_tmp;
    }


    function GetSelectedRows_Emails_filtered_on_alert() {

        var tab_tmp = []
        var tab_tmp_inscription_id = []
        for (var i = 0; i < selectionModel.length; i++) {
            var myid = parseInt(String(selectionModel[i]));
            var line = JSON.parse(rowss.filter((data) => (JSON.parse(data)).warning_recyclage === "1")[myid]);
            tab_tmp.push(line.email);
            tab_tmp_inscription_id.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }
        setselectedtraining_emails(tab_tmp);

        setselectedtraining_emails_liste(tab_tmp.join());
        setselected_list_inscription_id(tab_tmp_inscription_id.join());

        return tab_tmp;
    }


    const [Delete_LIST_Stagiaires_api, setDelete_LIST_Stagiaires_api] = useState();
    const [Delete_LIST_Stagiaires_message, setDelete_LIST_Stagiaires_message] = useState();
    const [Delete_LIST_Stagiaires_result, setDelete_LIST_Stagiaires_result] = useState();
    function Delete_LIST_Stagiaires(local_liste_class) {


        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        // console.log(" #### local_liste_class = ", local_liste_class)

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_inscription_id", local_liste_class);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_List_AttendeeInscription/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_LIST_Stagiaires  res.data.status = " + res.data.status);
            //console.log(" In Delete_LIST_Stagiaires  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_LIST_Stagiaires_api("true");
                setDelete_LIST_Stagiaires_result(res.data.message);
                setSelectionModel([]);
                Getall_TrainingParticipant();
                alert(res.data.message);
            }
            else {
                setDelete_LIST_Stagiaires_api("false");
                setDelete_LIST_Stagiaires_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_LIST_Stagiaires : Not good man :( mysearchtext = ' + error);
            setDelete_LIST_Stagiaires_api("false");
            alert(" Impossible de supprimer la liste des stagiaires");
        })
    }

    // -- end action en mass


    // Gestion champs specifiques 

    /*
    *** ALGORITHME
    1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
    2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
       - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
    
    3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    4 - Afficher en html les elements 'rows_champs_specifics'
    5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
    6 - On recuperer les valeur lors du click d'enregistrement.
    
    */


    const list_nom_champs_spec = []
    const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

    //const spec_champs_valeur = ""

    const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
    const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
    const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
    function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("object_name", local_collection_obj_metier);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

                setGet_Partner_Object_Specific_Fields_api("true");
                setGet_Partner_Object_Specific_Fields_result(res.data.message);


                setrows_champs_specifics(res.data.message);


            }
            else {
                setGet_Partner_Object_Specific_Fields_api("false");
                setGet_Partner_Object_Specific_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
            setGet_Partner_Object_Specific_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }


    const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
    const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

    function afficher_champs_spec() {

        setdisplay_champs_specifics("1");
        //setrows_champs_specifics(spec_champs_valeur);

    }

    function findIndexByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {

            if (data[i][key] == value) {
                return i;
            }
        }
        return -1;
    }


    function findValueByProperty(data, key, value) {
        for (var i = 0; i < data.length; i++) {
            if (data[i][key] == value) {

                var tmp_val = JSON.parse(data[i]);

                return tmp_val.field_value

            }
        }
        return "";
    }


    const spec_field_updated_values = []
    const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);


    function change_champs_spec_handle() {

        for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


            if (document.getElementsByName(String(field_name))[0]) {

                var field_value = document.getElementsByName(String(field_name))[0].value;
                var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
                var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
                if (johnIndex > -1) {
                    spec_field_updated_values[johnIndex] = new_val;

                } else {
                    spec_field_updated_values.push(new_val);
                }
            }

        }

        //setrows_champs_specifics(spec_champs_valeur);

        //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
        setspec_field_updated_values_hooks(spec_field_updated_values)
    }
    // -- end champs specifiques 


    function submenu_add_one_tuteur() {
        setDialog_tuteur_1_open(true);
    }


    const [Dialog_tuteur_1_message, setDialog_tuteur_1_message] = React.useState(false);
    const [Dialog_tuteur_1_open, setDialog_tuteur_1_open] = React.useState(false);

    function Dialog_1_handle_change_tuteur(message) {
        setDialog_tuteur_1_message(message);
        setDialog_tuteur_1_open(true);
    }

    const Dialog_tuteur_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_tuteur_1_handleClose_buton = () => {
        setDialog_tuteur_1_open(false);
    };




    const [quel_tuteur, setquel_tuteur] = useState("1");


    const [Add_Update_Stagiaire_Tuteur_api, setAdd_Update_Stagiaire_Tuteur_api] = useState();
    const [Add_Update_Stagiaire_Tuteur_message, setAdd_Update_Stagiaire_Tuteur_message] = useState();
    const [Add_Update_Stagiaire_Tuteur_result, setAdd_Update_Stagiaire_Tuteur_result] = useState();
    function Add_Update_Stagiaire_Tuteur() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        if (p_detail_session_class.length <= 0) {
            alert(" Vous devez choisir une session");
            return;
        }
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_id);

        if (String(quel_tuteur) === "1") {
            if (p_detail_tuteur1_nom)
                form.append("tuteur1_nom", p_detail_tuteur1_nom);
            else
                form.append("tuteur1_nom", "");

            if (p_detail_tuteur1_civilite)
                form.append("tuteur1_civilite", p_detail_tuteur1_civilite);
            else {
                form.append("tuteur1_civilite", "");
            }


            if (p_detail_tuteur1_prenom)
                form.append("tuteur1_prenom", p_detail_tuteur1_prenom);
            else
                form.append("tuteur1_prenom", "");

            if (p_detail_tuteur1_email && String(p_detail_tuteur1_email).trim() !== "") {
                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!p_detail_tuteur1_email.trim().match(validRegex)) {
                    alert("L'adresse email du tuteur est invalide.");
                    return;
                }

                form.append("tuteur1_email", p_detail_tuteur1_email.trim());
            }


            if (p_detail_tuteur1_telephone)
                form.append("tuteur1_telephone", p_detail_tuteur1_telephone);
            else
                form.append("tuteur1_telephone", "");


            if (p_detail_tuteur1_adresse)
                form.append("tuteur1_adresse", p_detail_tuteur1_adresse);
            else
                form.append("tuteur1_adresse", "");


            if (p_detail_tuteur1_cp)
                form.append("tuteur1_cp", p_detail_tuteur1_cp);
            else
                form.append("tuteur1_cp", "");

            if (p_detail_tuteur1_ville)
                form.append("tuteur1_ville", p_detail_tuteur1_ville);
            else
                form.append("tuteur1_ville", "");

            if (p_detail_tuteur1_pays)
                form.append("tuteur1_pays", p_detail_tuteur1_pays);
            else
                form.append("tuteur1_pays", "");



            if (p_detail_tuteur1_include_com && String(p_detail_tuteur1_include_com) === "true")
                form.append("tuteur1_include_com", "1");
            else
                form.append("tuteur1_include_com", "0");

        }

        if (String(quel_tuteur) === "2") {
            if (p_detail_tuteur2_nom)
                form.append("tuteur2_nom", p_detail_tuteur2_nom);
            else
                form.append("tuteur2_nom", "");


            if (p_detail_tuteur2_civilite)
                form.append("tuteur2_civilite", p_detail_tuteur2_civilite);
            else {
                form.append("tuteur2_civilite", "");
            }

            if (p_detail_tuteur2_prenom)
                form.append("tuteur2_prenom", p_detail_tuteur2_prenom);
            else
                form.append("tuteur2_prenom", "");

            if (p_detail_tuteur2_email && String(p_detail_tuteur2_email).trim() !== "") {
                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!p_detail_tuteur2_email.trim().match(validRegex)) {
                    alert("L'adresse email du tuteur est invalide.");
                    return;
                }
                form.append("tuteur2_email", p_detail_tuteur2_email.trim());
            }



            if (p_detail_tuteur2_telephone)
                form.append("tuteur2_telephone", p_detail_tuteur2_telephone);
            else
                form.append("tuteur2_telephone", "");


            if (p_detail_tuteur2_adresse)
                form.append("tuteur2_adresse", p_detail_tuteur2_adresse);
            else
                form.append("tuteur2_adresse", "");


            if (p_detail_tuteur2_cp)
                form.append("tuteur2_cp", p_detail_tuteur2_cp);
            else
                form.append("tuteur2_cp", "");

            if (p_detail_tuteur2_ville)
                form.append("tuteur2_ville", p_detail_tuteur2_ville);
            else
                form.append("tuteur2_ville", "");

            if (p_detail_tuteur2_pays)
                form.append("tuteur2_pays", p_detail_tuteur2_pays);
            else
                form.append("tuteur2_pays", "");



            if (p_detail_tuteur2_include_com && String(p_detail_tuteur2_include_com) === "true")
                form.append("tuteur2_include_com", "1");
            else
                form.append("tuteur2_include_com", "0");

        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/UpdateStagiairetoClass_Tuteurs/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
            //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_Stagiaire_Tuteur_api("true");
                setAdd_Update_Stagiaire_Tuteur_result(res.data.message);
                Dialog_tuteur_1_handleClose_buton();

                alert(res.data.message);

            }
            else {
                setAdd_Update_Stagiaire_Tuteur_api("false");
                setAdd_Update_Stagiaire_Tuteur_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( mysearchtext = ' + error);
            setAdd_Update_Stagiaire_Tuteur_api("false");
            alert(" Impossible de mettre à jour le tuteur");

        })
    }


    // -- convention 
    const [Dialog_convention_message, setDialog_convention_message] = React.useState(false);
    const [Dialog_convention_open, setDialog_convention_open] = React.useState(false);
    function Dialog_convention_handle_change_participant_session(message) {
        setDialog_convention_message(message);
        setDialog_convention_open(true);
    }

    const Dialog_convention_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_convention_handleClose_buton = () => {

        setDialog_convention_open(false);
        setselected_convention_id();
        setselected_convention_type();
        setselected_convention_email_destinataire_prod();
        setselected_convention_email_test();

        setactionmass_ftion_val();
        setSelectionModel([]);
        settab_convention_pieces_jointes_result([])
    };


    const [selected_convention_id, setselected_convention_id] = useState();
    const [selected_convention_type, setselected_convention_type] = useState("");
    const [selected_convention_email_destinataire_prod, setselected_convention_email_destinataire_prod] = useState("");
    const [selected_convention_email_test, setselected_convention_email_test] = useState("");


    const [New_Get_List_Stagiaire_Conventions_Individuel_result, setNew_Get_List_Stagiaire_Conventions_Individuel_result] = useState([]);

    const [Get_List_Stagiaire_Conventions_Individuel_api, setGet_List_Stagiaire_Conventions_Individuel_api] = useState();
    const [Get_List_Stagiaire_Conventions_Individuel_message, setGet_List_Stagiaire_Conventions_Individuel_message] = useState();
    const [Get_List_Stagiaire_Conventions_Individuel_result, setGet_List_Stagiaire_Conventions_Individuel_result] = useState();
    function Get_List_Stagiaire_Conventions_Individuel(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        //form.append("inscription_id", selected_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Conventions_Stagiaire_Individuelles/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_Stagiaire_Conventions_Individuel  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Stagiaire_Conventions_Individuel  res.data.message r_class = " + res.data.message);
                setGet_List_Stagiaire_Conventions_Individuel_api("true");
                setGet_List_Stagiaire_Conventions_Individuel_result(res.data.message);

                var new_data2 = [];
                var node = {
                    "_id": "default_pdf",
                    "id": "default_pdf",
                    "label": "PDF par default",
                    "ref_interne": "",
                    "nom": "",
                    "sujet": "",
                    "type_doc": "",
                    "cible": "",
                    "joint_pdf": "",

                };
                new_data2.push(node);

                var node2 = {
                    "_id": "default_mail",
                    "id": "default_mail",
                    "label": "Email par default",
                    "ref_interne": "",
                    "nom": "",
                    "sujet": "",
                    "type_doc": "",
                    "cible": "",
                    "joint_pdf": "",

                };
                new_data2.push(node2);
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Stagiaire_Conventions_Individuel_result(new_data2);

            }
            else {
                setGet_List_Stagiaire_Conventions_Individuel_api("false");
                setGet_List_Stagiaire_Conventions_Individuel_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Stagiaire_Conventions_Individuel = ', error);
            setGet_List_Stagiaire_Conventions_Individuel_api("false");
            alert("Impossible de récupérer les modèles de convention");
            //setmyApimyApiMessage("")
        })
    }


    const [Send_Stagiaire_Conventions_By_Email_api, setSend_Stagiaire_Conventions_By_Email_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_message, setSend_Stagiaire_Conventions_By_Email_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_result, setSend_Stagiaire_Conventions_By_Email_result] = useState();
    function Send_Stagiaire_Conventions_By_Email(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", selected_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", selected_convention_email_destinataire_prod);

        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Sent_Convention_Individuelle_Stagiaire_By_Email/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Send_Stagiaire_Conventions_By_Email  res.data.status = " + res.data.status);
                //console.log(" In Send_Stagiaire_Conventions_By_Email  res.data.message r_class = " + res.data.message);
                setSend_Stagiaire_Conventions_By_Email_api("true");
                setSend_Stagiaire_Conventions_By_Email_result(res.data.message);

                alert(res.data.message);
                Dialog_convention_handleClose_buton()
            }
            else {
                setSend_Stagiaire_Conventions_By_Email_api("false");
                setSend_Stagiaire_Conventions_By_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email = ', error);
            setSend_Stagiaire_Conventions_By_Email_api("false");
            alert("Impossible d'envoyer la convention par email");
            //setmyApimyApiMessage("")
        })
    }

    function Download_Stagiaire_Conventions_PDF() {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "Convention.pdf";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Download_Convention_Individuelle_Stagiaire_PDF/" + stored_cookie + "/" + selected_id + "/" + selected_convention_id;


        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Download_Stagiaire_Conventions_PDF  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible de télécharger la convention (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible de télécharger la convention ")

            });

    }

    function Download_Stagiaire_Conventions_PDF_Masse() {


        const stored_cookie = getCookie('tokenmysypart');
        var nom_fichier_cmd = "Convention.pdf";
        for (let i = 0; i < liste_selected_inscription_id.length; i++) {

            var local_selected_id = liste_selected_inscription_id[i];



            var url = process.env.REACT_APP_API_URL + "myclass/api/Download_Convention_Stagiaire_PDF/" + stored_cookie + "/" + local_selected_id + "/" + selected_convention_id;

            //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
            setLoading(true);
            axios.get(url, { responseType: 'blob', },)
                .then((res) => {
                    setLoading(false);
                    //console.log(" In Download_Stagiaire_Conventions_PDF  res.data = " + res.data);
                    if (String(res.data) === String("false")) {
                        alert("Impossible de télécharger la convention (2) ");
                    } else {
                        fileDownload(res.data, nom_fichier_cmd)

                    }
                }).catch((error) => {
                    setLoading(false);
                    console.error('Error:', error);
                    alert(" Impossible de télécharger la convention ")

                });
        }

    }


    const [working_session_code, setworking_session_code] = useState("");

    const [Send_Stagiaire_Conventions_By_Email_Test_api, setSend_Stagiaire_Conventions_By_Email_Test_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Test_message, setSend_Stagiaire_Conventions_By_Email_Test_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Test_result, setSend_Stagiaire_Conventions_By_Email_Test_result] = useState();
    function Send_Stagiaire_Conventions_By_Email_Test(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", selected_id);
        form.append("courrier_template_id", selected_convention_id);
        form.append("email_test", selected_convention_email_test);
        form.append("email_production", "");

        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);


        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Sent_Convention_Individuelle_Stagiaire_By_Email/",
            {
                method: 'POST',
                body: form,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                setLoading(false);
                console.log(" In Send_Stagiaire_Conventions_By_Email_Test  res.data.status = " + result['status']);
                console.log(" In Send_Stagiaire_Conventions_By_Email_Test  res.data.message r_class = " + result['message']);
                if (String(result['status']) === String("true")) {

                    setSend_Stagiaire_Conventions_By_Email_Test_api("true");
                    setSend_Stagiaire_Conventions_By_Email_Test_result(result['message']);

                    alert(result['message']);
                    Dialog_convention_handleClose_buton()
                }
                else {
                    setSend_Stagiaire_Conventions_By_Email_Test_api("false");
                    setSend_Stagiaire_Conventions_By_Email_Test_message(result['message']);
                    alert(result['message']);
                }

            }).catch((error) => {
                setLoading(false);
                console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email_Test = ', error);
                setSend_Stagiaire_Conventions_By_Email_Test_api("false");
                alert("Impossible d'envoyer l'email de test ");
                //setmyApimyApiMessage("")
            })
    }




    const [Get_Stagiaire_List_Email_Com_api, setGet_Stagiaire_List_Email_Com_api] = useState();
    const [Get_Stagiaire_List_Email_Com_message, setGet_Stagiaire_List_Email_Com_message] = useState();
    const [Get_Stagiaire_List_Email_Com_result, setGet_Stagiaire_List_Email_Com_result] = useState();
    function Get_Stagiaire_List_Email_Com(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", selected_id);
        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Stagiaire_Communication_List_Email/";

        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Stagiaire_List_Email_Com  res.data.status = " + res.data.status);
                //console.log(" In Get_Stagiaire_List_Email_Com  res.data.message r_class = " + res.data.message);
                setGet_Stagiaire_List_Email_Com_api("true");
                setGet_Stagiaire_List_Email_Com_result(res.data.message);

                var tab_email_prod = []
                res.data.message.map((x) => {
                    tab_email_prod.push(String(JSON.parse(x).email));
                }
                );

                var liste_email_prod = tab_email_prod.join()
                setselected_convention_email_destinataire_prod(liste_email_prod);

            }
            else {
                setGet_Stagiaire_List_Email_Com_api("false");
                setGet_Stagiaire_List_Email_Com_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Stagiaire_List_Email_Com = ', error);
            setGet_Stagiaire_List_Email_Com_api("false");
            alert("Impossible de récupérer les adresses email de communication");
            //setmyApimyApiMessage("")
        })
    }

    const [Send_Stagiaire_Conventions_By_Email_Masse_api, setSend_Stagiaire_Conventions_By_Email_Masse_api] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Masse_message, setSend_Stagiaire_Conventions_By_Email_Masse_message] = useState();
    const [Send_Stagiaire_Conventions_By_Email_Masse_result, setSend_Stagiaire_Conventions_By_Email_Masse_result] = useState();
    function Send_Stagiaire_Conventions_By_Email_Masse(event) {

        var form = new FormData();

        form.append("class_internal_url", selected_internal_url);
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_inscription_id", liste_selected_inscription_id);
        form.append("courrier_template_id", selected_convention_id);

        // pièce jointe
        form.append("file_business_object", "");
        var list_files = new FormData();

        for (let i = 0; i < tab_convention_pieces_jointes_result.length; i++) {
            form.append('File', tab_convention_pieces_jointes_result[i]);

        }
        //form.append("File", list_files);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Sent_Convention_Stagiaire_By_Email_mass/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Send_Stagiaire_Conventions_By_Email_Masse  res.data.status = " + res.data.status);
                //console.log(" In Send_Stagiaire_Conventions_By_Email_Masse  res.data.message r_class = " + res.data.message);
                setSend_Stagiaire_Conventions_By_Email_Masse_api("true");
                setSend_Stagiaire_Conventions_By_Email_Masse_result(res.data.message);

                alert(res.data.message);
                Dialog_convention_handleClose_buton()
            }
            else {
                setSend_Stagiaire_Conventions_By_Email_Masse_api("false");
                setSend_Stagiaire_Conventions_By_Email_Masse_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Send_Stagiaire_Conventions_By_Email_Masse = ', error);
            setSend_Stagiaire_Conventions_By_Email_Masse_api("false");
            alert("Impossible d'envoyer les conventions en masse par email");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Convention_List_Of_All_PJ_result, setGet_Convention_List_Of_All_PJ_result] = useState([]);

    function Delete_Convention_Attached_Doc(event) {

        var doc_to_del_name = event.target.id;
        const myArray = tab_convention_pieces_jointes_result;



        let new_myArray = myArray.filter(file => file.name !== String(doc_to_del_name));

        console.log(" new_myArray = ", new_myArray);


        var new_tab = []
        for (let i = 0; i < new_myArray.length; i++) {
            new_tab.push(new_myArray[i]);
        }

        settab_convention_pieces_jointes_result(new_tab);
    }


    const [selected_row_data_json, setselected_row_data_json] = useState([]);

    const [selected_row_data_json_age, setselected_row_data_json_age] = useState("");

    function Show_Detailled_stagiaire_Data() {

        handleClick_edit_session_From_Line(gridline_id);
        Dialog_stagiaire_handleClose_buton();
    }

    const [Dialog_stagiaire_message, setDialog_stagiaire_message] = React.useState(false);
    const [Dialog_stagiaire_open, setDialog_stagiaire_open] = React.useState(false);
    function Dialog_stagiaire_handle_change_participant_session(message) {
        setDialog_stagiaire_message(message);
        setDialog_stagiaire_open(true);
    }

    const Dialog_stagiaire_handleClose = () => {

    };

    const Dialog_stagiaire_handleClose_buton = () => {
        setselected_row_data_json_age('');
        setDialog_stagiaire_open(false);
    };

    const New_Option = [
        { "id": "0", "label": "Valider Insc.", "value": "valide_inscr" },
        { "id": "1", "label": "Supprimer", "value": "supprimer" },
        { "id": "2", "label": "Exporter", "value": "exporter" },
        { "id": "3", "label": "Desactiver Recyclage", "value": "recyclage_disable" },
        { "id": "4", "label": "Rédiger un Email", "value": "sendemail" },
    ]


    const New_civilite = [
        { "id": "mme", "label": "Mme", "value": "mme" },
        { "id": "m", "label": "M", "value": "m" },
        { "id": "neutre", "label": "Neutre", "value": "neutre" },
        { "id": "", "label": "", "value": "" },
    ]



    const New_status_inscription_add_with_annule = [
        { "id": "0", "label": "PréInscription", "value": "0" },
        { "id": "1", "label": "Inscription", "value": "1" },
        { "id": "2", "label": "En Cours", "value": "2" },
        { "id": "-1", "label": "Annule", "value": "-1" },
    ]


    const New_type_apprenant = [
        { "id": "0", "label": "Autre", "value": "0" },
        { "id": "1", "label": "Salariés", "value": "1" },
        { "id": "2", "label": "Apprentis", "value": "2" },
        { "id": "3", "label": "Particuliers", "value": "3" },
        { "id": "4", "label": "Rech. Emploi", "value": "4" },
    ]

    const New_Option_Filter = [
        { "id": "email", "label": "Email ", "value": "email" },
        { "id": "nom", "label": "Nom ", "value": "nom" },
        { "id": "code_session", "label": "Code Session", "value": "code_session" },
        { "id": "class_title", "label": "Titre Formation", "value": "class_title" },
        { "id": "client_nom", "label": "Lié au Client (nom client) ", "value": "client_nom" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]


    const New_Financement = [
        {
            value: 'cpf',
            label: 'CPF',
        },
        {
            value: 'perso',
            label: 'Fonds Propres',
        },
        {
            value: 'entreprise',
            label: 'Votre entreprise',
        },

        {
            value: 'mixte_cpf_perso',
            label: 'CPF & Fonds Propres',
        },
        {
            value: 'autre',
            label: 'Autre',
        },
        {
            value: 'modefinancement',
            label: 'modefinancement',
        },
        {
            value: '',
            label: '',
        },

    ];

    const [p_detail_class_eu_id, setp_detail_class_eu_id] = React.useState("");
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [ueChanged, setueChanged] = useState("");


    function annule_modif_UE_and_Eval() {
        setueChanged("");
        Get_Inscrit_List_UE(selected_id);
        Get_Inscrit_List_Evaluation_Par_UE(selected_id);
    }


    const [inscription_list_ue, setinscription_list_ue] = useState([]);
    const [inscription_list_ue_eval, setinscription_list_ue_eval] = useState([]);


    const [Update_UE_and_Evaluation_api, setUpdate_UE_and_Evaluation_api] = useState();
    const [Update_UE_and_Evaluation_message, setUpdate_UE_and_Evaluation_message] = useState();
    const [Update_UE_and_Evaluation_result, setUpdate_UE_and_Evaluation_result] = useState([]);
    function Update_UE_and_Evaluation() {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", selected_id);
        form.append("class_id", selected_class_id);

        var list_ue_json_str = JSON.stringify(inscription_list_ue);
        var list_ue_evaluation_json_str = JSON.stringify(inscription_list_ue_eval)

        form.append("list_eu", list_ue_json_str);
        form.append("list_eu_eval", list_ue_evaluation_json_str);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_AcceptAttendeeInscription_For_Initial_Formation/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_UE_and_Evaluation  res.data.status = " + res.data.status);
            //console.log(" In Update_UE_and_Evaluation  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_UE_and_Evaluation_api("true");
                setUpdate_UE_and_Evaluation_result(res.data.message);

                setueChanged("");
                Get_Inscrit_List_UE(selected_id);
                Get_Inscrit_List_Evaluation_Par_UE(selected_id);

                alert(res.data.message);



            }
            else {
                setUpdate_UE_and_Evaluation_api("false");
                setUpdate_UE_and_Evaluation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Update_UE_and_Evaluation : Not good man :( mysearchtext = ' + error);
            setUpdate_UE_and_Evaluation_api("false");
            alert(" Impossible de mettre à jour les UE et Evaluation");

        })
    }


    function Export_Inscrit_Notes() {
        const stored_cookie = getCookie('tokenmysypart');

        var tab_my_inscription_ids = [];
        tab_my_inscription_ids.push(selected_id);

        var tab_session_id = [];
        tab_session_id.push(selected_session_id);



        var nom_fichier_cmd = "export_notes.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_Excel_List_Participant_Notes/" + stored_cookie + "/" + tab_session_id + "/" + tab_my_inscription_ids + "/";

        //console.log(" ### url = ", url);

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les notes (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les notes ")

            });


        setLoading(false);
    }



    const [Dialog_SEND_EMAIL_open, setDialog_SEND_EMAIL_open] = React.useState(false);


    const Dialog_SEND_EMAIL_handleClose = () => {
        setDialog_SEND_EMAIL_open(false);
    }

    const Clean_Selected_Rows_For_Email = () => {
        setSelectionModel([])
    }

    const [display_stagiaire_with_alert, setdisplay_stagiaire_with_alert] = useState(false);

    const handleChange_display_stagiaire_with_alert = (event) => {
        setSelectionModel([]);
        setdisplay_stagiaire_with_alert(event.target.checked);
    };

// DEBUT  EXPORT EXCEL DATAGRID 

function CustomToolbar(props) {


    return (
        <GridToolbarContainer {...props}>

            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector
                slotProps={{ tooltip: { title: 'Change density' } }}
            />
            <Box sx={{ flexGrow: 1 }} />
            <ExportButton data_colums={props.data_colums} />


        </GridToolbarContainer>
    );
}

function getExcelData(apiRef) {
    // Select rows and columns
    const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

    // Format the data. Here we only keep the value
    const data = filteredSortedRowIds.map((id) => {
        const row = {};
        visibleColumnsField.forEach((field) => {
            row[field] = apiRef.current.getCellParams(id, field).value;
        });
        return row;
    });

    return data;
}


function ExportButton(props) {
    return (
        <GridToolbarExportContainer {...props}>
            <ExportMenuItem data_colums={props.data_colums} />
        </GridToolbarExportContainer>
    );
}

function handleExport(apiRef, data_colums) {
    const data = getExcelData(apiRef);
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
   

    const local_config = {
        keys: visibleColumnsField,

        columnNames: data_colums.filter((mydata) => (mydata).disableExport !== true).map(function (mydata) {
            if (mydata.headerName && visibleColumnsField.includes(mydata.field)) {
                if (mydata.headerName) {
                    return mydata.headerName;
                }
            }

        }),

        fileName: 'data_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_") + '.xlsx',
        sheetName: 'Export_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_"),
    };

    local_config.columnNames = local_config.columnNames.filter(function( element ) {
        return element !== undefined;
     });


    const rows = data.map((row) => {
        const mRow = {};
        for (const key of local_config.keys) {
            mRow[key] = row[key];
        }
        return mRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);
    XLSX.utils.sheet_add_aoa(worksheet, [[...local_config.columnNames]], {
        origin: 'A1',
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, local_config.sheetName);
    XLSX.writeFile(workbook, local_config.fileName, { compression: true });
}

function ExportMenuItem(props) {
    const apiRef = useGridApiContext();
    const { hideMenu } = props;

    return (
        <MenuItem
            onClick={() => {
                handleExport(apiRef, props.data_colums);
                // Hide the export menu after the export
                hideMenu?.();
            }}
        >
            Export Excel
        </MenuItem>
    );
}

// FIN EXPORT EXCEL DATAGRID 

    return (
        <div className="displaypartnerstagiaire">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}



            { /********* Dialog redaction email */}
            <Dialog

                //onClose={Dialog_SEND_EMAIL_handleClose}
                className="module_email_management"
                PaperProps={{
                    sx: {
                        width: "100%",
                        maxWidth: "920px!important",
                    },
                }}
                open={Dialog_SEND_EMAIL_open}
                static
                onClose={() => null}
            >


                <DialogContent >
                    <DialogContentText>

                    </DialogContentText>


                    <Module_Email_Management father_close_function={Dialog_SEND_EMAIL_handleClose} email_destinataire={selectedtraining_emails_liste}
                        list_inscription_id={selected_list_inscription_id}
                        related_collection={"inscription"} Clean_Selected_Rows_For_Email={Clean_Selected_Rows_For_Email} />

                </DialogContent>


            </Dialog>
            { /********* Fin Dialog redaction email */}



            { /**** Dialog info stagiaire  */}
            <Dialog
                open={Dialog_stagiaire_open}
                //onClose={Dialog_stagiaire_handleClose}
                className="displaypartnersession"
                static
                onClose={() => null}
            >

                <DialogTitle>Info Stagiaire</DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="div_row_dialog">

                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Civilite
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {New_civilite && New_civilite.length > 0 && selected_row_data_json.civilite &&
                                <nav>
                                    {New_civilite.filter((data) => (data).id === String(selected_row_data_json.civilite))[0].label}
                                </nav>
                            }

                        </div>
                    </div>

                    <div className="div_row_dialog">

                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Nom & Prénom
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {setselected_row_data_json.length > 0 && selected_row_data_json.nom &&
                                <nav> {selected_row_data_json.nom.toUpperCase()}   {selected_row_data_json.prenom.charAt(0).toUpperCase() + selected_row_data_json.prenom.slice(1)}</nav>}
                        </div>
                    </div>



                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            E-mail
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            {<nav> {selected_row_data_json.email} </nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Téléphone
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav> {selected_row_data_json.telephone}</nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Entreprise
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav> {selected_row_data_json.client_rattachement_nom}</nav>}
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Age
                        </div>

                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav>  {selected_row_data_json_age} ans - {selected_row_data_json.date_naissance}  </nav>}
                        </div>

                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Adresse postale
                        </div>
                        {selected_row_data_json.pays && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav> {selected_row_data_json.adresse} <br />
                                {selected_row_data_json.code_postal}  {selected_row_data_json.ville} <br />
                                {selected_row_data_json.pays.toUpperCase()}
                            </nav>}

                        </div>}
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Formation
                        </div>

                        {selected_row_data_json.title && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav> {selected_row_data_json.title}  </nav>}
                        </div>}

                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Code Session
                        </div>

                        {selected_row_data_json.code_session && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav>   {selected_row_data_json.code_session}  </nav>}
                        </div>}

                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Début Session
                        </div>

                        {selected_row_data_json.date_du && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav>   {selected_row_data_json.date_du}  </nav>}
                        </div>}

                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left' }}>
                            Fin Session
                        </div>

                        {selected_row_data_json.date_au && <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            {<nav>   {selected_row_data_json.date_au}  </nav>}
                        </div>}

                    </div>


                    <div className="div_row" > &nbsp;

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Show_Detailled_stagiaire_Data} className="bton_enreg_dialog">Voir détail</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_stagiaire_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>
            { /**** FIN Dialog info stagiaire  */}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            { /**** Dialog Tuteur  */}
            <Dialog
                open={Dialog_tuteur_1_open}
                //onClose={Dialog_tuteur_1_handleClose}

                className="displaypartnersession"
                static
                onClose={() => null}
            >

                <DialogTitle>Tuteur {quel_tuteur}</DialogTitle>
                {String(quel_tuteur) === "1" && <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_tuteur_1_message}
                    </DialogContentText>


                    <div className="session_caract"> <b> Civilité </b><br />
                        <Autocomplete
                            disablePortal
                            name="event_dialog_civilite"
                            id="event_dialog_civilite"
                            value={New_civilite.filter((data) => (data).value === String(p_detail_tuteur1_civilite))[0].label}

                            fullWidth

                            options={New_civilite}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_tuteur1_civilite(value.value);
                                } else {
                                    setp_detail_tuteur1_civilite("");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>

                    <div className="session_caract_Dialog" >  <b>Nom </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_nom}
                            onChange={(e) => {
                                setp_detail_tuteur1_nom(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" >  <b> Prénom</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_prenom}
                            onChange={(e) => {
                                setp_detail_tuteur1_prenom(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > <b> Email</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_email}
                            onChange={(e) => {
                                setp_detail_tuteur1_email(e.target.value);
                            }}
                        />
                    </div>


                    <div className="session_caract_Dialog" > Téléphone
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_telephone}
                            onChange={(e) => {
                                setp_detail_tuteur1_telephone(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Adresse
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_adresse}
                            onChange={(e) => {
                                setp_detail_tuteur1_adresse(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Code postal
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_cp}
                            onChange={(e) => {
                                setp_detail_tuteur1_cp(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Ville
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_ville}
                            onChange={(e) => {
                                setp_detail_tuteur1_ville(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Pays
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur1_pays}
                            onChange={(e) => {
                                setp_detail_tuteur1_pays(e.target.value);
                            }}
                        />
                    </div>

                    {/* <div className="session_caract_Dialog" > Inclure Communication
                        <Switch checked={p_detail_tuteur1_include_com} onChange={(e) => {
                            setp_detail_tuteur1_include_com(e.target.checked)
                        }} />
                    </div>*/}

                    <Tooltip className="tooltip_css" id="tooltip_com1" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_com1" data-tooltip-html="Si oui, ce tuteur recevra tous les emails échangés">
                        <div className="session_caract" style={{ "float": "right", "fontSize": "12px", 'fontWeight': 'bolder', "width": "100%" }}>

                            <nav onChange={(e) => {


                                //console.log("is_contact_include_com == ", is_contact_include_com);
                                if (p_detail_tuteur1_include_com === true)
                                    setp_detail_tuteur1_include_com(false)
                                else {
                                    // Pour activier la fontion de "include com", le contact foit avoir une adresse email
                                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                    if (!p_detail_tuteur1_email.trim().match(validRegex)) {
                                        alert("L'adresse email est invalide. Pour activer cette fontion, vous devez saisir une adresse email valide");
                                        return;
                                    }

                                    setp_detail_tuteur1_include_com(true);

                                }

                            }}
                                id="toggleSwitchNav" name="toggleSwitchNav">
                                <ToggleSwitch
                                    label="Inclure / Exclure de la communication ? " id="toggleSwitch" name="toggleSwitch" checked={p_detail_tuteur1_include_com} style={{ height: "2rem" }} />
                            </nav>

                        </div>
                    </a>

                </DialogContent>}

                {String(quel_tuteur) === "2" && <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_tuteur_1_message}
                    </DialogContentText>

                    <div className="session_caract"> <b> Civilité </b><br />
                        <Autocomplete
                            disablePortal
                            name="event_dialog_civilite"
                            id="event_dialog_civilite"
                            value={New_civilite.filter((data) => (data).value === String(p_detail_tuteur2_civilite))[0].label}

                            fullWidth

                            options={New_civilite}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_tuteur2_civilite(value.value);
                                } else {
                                    setp_detail_tuteur2_civilite("");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>


                    <div className="session_caract_Dialog" ><b> Nom </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_nom}
                            onChange={(e) => {
                                setp_detail_tuteur2_nom(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > <b>Prénom</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_prenom}
                            onChange={(e) => {
                                setp_detail_tuteur2_prenom(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > <b> Email</b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_email}
                            onChange={(e) => {
                                setp_detail_tuteur2_email(e.target.value);
                            }}
                        />
                    </div>


                    <div className="session_caract_Dialog" > Téléphone
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_telephone}
                            onChange={(e) => {
                                setp_detail_tuteur2_telephone(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Adresse
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_adresse}
                            onChange={(e) => {
                                setp_detail_tuteur2_adresse(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Code postal
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_cp}
                            onChange={(e) => {
                                setp_detail_tuteur2_cp(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Ville
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_ville}
                            onChange={(e) => {
                                setp_detail_tuteur2_ville(e.target.value);
                            }}
                        />
                    </div>

                    <div className="session_caract_Dialog" > Pays
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={p_detail_tuteur2_pays}
                            onChange={(e) => {
                                setp_detail_tuteur2_pays(e.target.value);
                            }}
                        />
                    </div>

                    {/*<div className="session_caract_Dialog" > Inclure Communication
                        <Switch checked={p_detail_tuteur2_include_com} onChange={(e) => {
                            setp_detail_tuteur2_include_com(e.target.checked)
                        }} />
                    </div>*/}

                    <Tooltip className="tooltip_css" id="tooltip_com" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="tooltip_com" data-tooltip-html="Si oui, ce tuteur recevra tous les emails échangés">
                        <div className="session_caract" style={{ "float": "right", "fontSize": "12px", 'fontWeight': 'bolder', "width": "100%" }}>

                            <nav onChange={(e) => {


                                //console.log("is_contact_include_com == ", is_contact_include_com);
                                if (p_detail_tuteur2_include_com === true)
                                    setp_detail_tuteur2_include_com(false)
                                else {
                                    // Pour activier la fontion de "include com", le contact foit avoir une adresse email
                                    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                    if (!p_detail_tuteur2_email.trim().match(validRegex)) {
                                        alert("L'adresse email est invalide. Pour activer cette fontion, vous devez saisir une adresse email valide");
                                        return;
                                    }

                                    setp_detail_tuteur2_include_com(true);

                                }

                            }}
                                id="toggleSwitchNav" name="toggleSwitchNav">
                                <ToggleSwitch
                                    label="Inclure / Exclure de la communication ? " id="toggleSwitch" name="toggleSwitch" checked={p_detail_tuteur2_include_com} style={{ height: "2rem" }} />
                            </nav>

                        </div>
                    </a>


                </DialogContent>}


                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={Add_Update_Stagiaire_Tuteur} className="bton_enreg_dialog">Mettre à jour</Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_tuteur_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            { /**** FIN Dialog Tuteur  */}

            { /**** Dialog Convention  */}
            <Dialog
                open={Dialog_convention_open}
                // onClose={Dialog_convention_handleClose}
                className="displaypartnersession"
                static
                onClose={() => null}>

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>



                    <div className="session_caract_Dialog" > Choisir une convention <b>Individuelle </b>
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_convention"
                            id="event_dialog_version_convention"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Get_List_Stagiaire_Conventions_Individuel_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_convention_id(value._id);

                                    if (String(value._id) === "default_mail") {
                                        setselected_convention_type("email");
                                    }
                                    else if (String(value._id) === "default_pdf") {
                                        setselected_convention_type("pdf");
                                    } else {
                                        //var result2 = New_Get_List_Stagiaire_Conventions_Individuel_result.filter((local_mymanager) => local_mymanager._id === String(value._id))

                                        //console.log("### value.type_doc = ", value.type_doc);
                                        setselected_convention_type(value.type_doc);
                                    }
                                    settab_convention_pieces_jointes_result([]);
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>

                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" > Type Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, width: '75%' }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"

                            select


                            value={selected_convention_type}
                            onChange={(e) => {
                                setselected_convention_type(e.target.value);
                            }

                            }
                        >

                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >PDF &nbsp;</MenuItem>
                        </TextField>
                        {String(selected_convention_type) === "email" && <label className="bton_image_class" style={{ "width": "20%", "float": "right", "height": "3.5rem", "paddingTop": "15px" }} onClick={(e) => {

                            if (selected_convention_email_test) {
                                var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (!selected_convention_email_test.match(validRegex)) {
                                    alert("L'adresse email de test est invalide.");
                                    return;
                                }
                                Send_Stagiaire_Conventions_By_Email_Test();
                            } else {
                                alert("L'adresse email de test est invalide.");
                            }



                        }} > <FcSearch /> &nbsp; Test</label>}

                    </div>}

                    {String(selected_convention_type) === "pdf" && <div className="session_caract_Dialog" > Type Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"

                            select
                            fullWidth

                            value={selected_convention_type}
                            onChange={(e) => {
                                setselected_convention_type(e.target.value);
                            }
                            }
                        >

                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >PDF &nbsp;</MenuItem>
                        </TextField>

                    </div>}

                    {String(selected_convention_type) === "email" && <div className="session_caract_Dialog" >  Adresse email de test

                        <Tooltip className="tooltip_css" id="test01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="test01" data-tooltip-html="Pour recevoir une email de test avant de l'envoyer">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_test_convention"
                                id="event_dialog_email_test_convention"

                                fullWidth

                                value={selected_convention_email_test}
                                onChange={(e) => {
                                    setselected_convention_email_test(e.target.value);
                                }
                                }
                            />
                        </a>

                    </div>
                    }

                    {String(selected_convention_type) === "pdf" && <div className="session_caract_Dialog" > Recevoir un pré-envoie
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_email_test_convention"
                            id="event_dialog_email_test_convention"
                            fullWidth
                            value=""

                        />

                    </div>
                    }

                    {String(selected_convention_type) === "email" &&
                        String(actionmass_ftion_val) !== String("convention") && <div className="session_caract_Dialog" > Email destinaitaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_production_convention"
                                id="event_dialog_email_production_convention"

                                fullWidth

                                value={selected_convention_email_destinataire_prod}
                                onChange={(e) => {
                                    setselected_convention_email_destinataire_prod(e.target.value);
                                }
                                }
                            />

                        </div>
                    }

                    {String(selected_convention_type) === "pdf" &&
                        String(actionmass_ftion_val) !== String("convention") && <div className="session_caract_Dialog" > Email destinaitaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_email_production_convention"
                                id="event_dialog_email_production_convention"

                                fullWidth

                                value=""

                            />

                        </div>
                    }

                    {String(selected_convention_type) === "email" && <div>

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes Convention

                            </div>

                            <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload_convention_file">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload_convention_file"
                                            name="upload_convention_file"
                                            type="file"
                                            accept=".pdf"
                                            onChange={sessions_file_change_1_convention}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>


                            </div>


                            {tab_convention_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    {<div className="div_row">
                                        {tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj_convention"  >
                                                <nav style={{ "color": "green", "cursor": "pointer" }}
                                                    onClick={(e) => {
                                                        Delete_Convention_Attached_Doc(e);
                                                    }}
                                                    name={val.name} id={val.name}> Supprimer  {val.name} </nav> <br />


                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>}



                        </div>
                    </div>}

                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        {String(actionmass_ftion_val) !== String("convention") && <div className="div_row_gauche">
                            {String(selected_convention_type) === "email" && <Button onClick={(e) => {
                                if (String(selected_convention_email_test).length > 2) {
                                    const response = window.confirm(" Vous allez envoyer la convention au destinaitaire final. Continuer ?");
                                    if (!response) {
                                        return;
                                    }
                                    Send_Stagiaire_Conventions_By_Email();
                                    Dialog_convention_handleClose_buton();
                                }
                            }}
                                className="bton_enreg_dialog">Envoyer mail</Button>}
                            {String(selected_convention_type) === "pdf" &&
                                <Button className="bton_enreg_dialog" onClick={(e) => {
                                    Download_Stagiaire_Conventions_PDF();
                                    Dialog_convention_handleClose_buton();
                                }}
                                >Télécharger</Button>}
                        </div>}


                        {String(actionmass_ftion_val) === String("convention") && <div className="div_row_gauche">
                            {String(selected_convention_type) === "email" && <Button onClick={(e) => {
                                if (String(selected_convention_email_test).length > 2) {
                                    const response = window.confirm(" Vous allez envoyer " + String(selectionModel.length) + " convention(s) aux destinaitaires final. Continuer ?");
                                    if (!response) {
                                        return;
                                    }
                                    Send_Stagiaire_Conventions_By_Email_Masse();
                                    Dialog_convention_handleClose_buton();
                                }
                            }}
                                className="bton_enreg_dialog">Envoyer</Button>}
                            {String(selected_convention_type) === "pdf" &&
                                <Button className="bton_enreg_dialog" onClick={(e) => {
                                    const response = window.confirm(" Vous allez télécharger " + String(selectionModel.length) + " convention(s). Continuer ?");
                                    if (!response) {
                                        return;
                                    }

                                    Download_Stagiaire_Conventions_PDF_Masse();
                                    Dialog_convention_handleClose_buton();
                                }}
                                >Télécharger</Button>}
                        </div>}



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_convention_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            { /****  FIN Dialog Convention  */}
            <h3> Vos stagiaires </h3>

            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}

                        {/*<TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                            <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                            <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié au Client (nom client)  &nbsp;</MenuItem>


                        </TextField>*/}
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                            {/*<TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                options={filters}
                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value);

                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                                <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié au Client (nom client)  &nbsp;</MenuItem>


                            </TextField>*/}
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                            {/*<TextField
                                name="filtre3"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                options={filters}
                                value={p_filtre3}
                                onChange={(e) => {
                                    setp_filtre3(e.target.value)
                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                                <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié au Client (nom client)  &nbsp;</MenuItem>


                            </TextField>*/}
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value("");
                                }} />
                            </a>
                        </div>}
                    </div>
                }

                {p_filtre4 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre4"
                                id="filtre4"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre4))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre4(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}



                            {/*<TextField
                                name="filtre4"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                options={filters}
                                value={p_filtre4}
                                onChange={(e) => {
                                    setp_filtre4(e.target.value)
                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                                <MenuItem value="client_nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié au Client (nom client)  &nbsp;</MenuItem>


                            </TextField> */}
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre4).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre4_value"
                                    id="filtre4_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre4_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre4_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>



                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }
                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_TrainingParticipant}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>
            </div>

            <div style={{ "textAlign": "left", "float": "left", "width": "100%", "paddingRight": "10px" }} id="participant_menu_tab">

                <div className="session_data">

                    <div style={{ textAlign: "right", "width": '100%', 'float': 'left' }}>
                        <nav style={{ width: '50%', float: 'left' }}> &nbsp; </nav>
                        <nav style={{ width: '50%', float: 'left' }}>
                            <FormGroup style={{ float: 'right' }}>
                                <FormControlLabel control={<Checkbox
                                    checked={display_stagiaire_with_alert}
                                    onChange={handleChange_display_stagiaire_with_alert}
                                    inputProps={{ 'aria-label': 'controlled' }}

                                />} label="Alerte" style={{ color: 'red', fontSize: "10px", fontStyle: 'italic' }} />
                            </FormGroup>
                        </nav>
                    </div>


                    <div style={{ "border": "None", paddingRight: '10px' }}>

                        {(!display_stagiaire_with_alert || display_stagiaire_with_alert !== true) && <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },

                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },


                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },
                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    components={{
                                        Toolbar: CustomToolbar
                                    }}
                                    componentsProps={{ toolbar: { data_colums: columns } }}

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_session_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            setaddOneParticipant();
                                            setdisplay_detail_stagiaire();
                                            setselected_code_session();
                                            setsubmenu();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            class_id: JSON.parse(item).class_id,
                                            class_internal_url: JSON.parse(item).class_internal_url,
                                            code_session: JSON.parse(item).code_session,
                                            session_id: JSON.parse(item).session_id,
                                            email: JSON.parse(item).email,
                                            nom: JSON.parse(item).nom,
                                            prenom: JSON.parse(item).prenom,
                                            employeur: JSON.parse(item).employeur,
                                            client_nom: JSON.parse(item).client_rattachement_nom,

                                            client_facture: JSON.parse(item).facture_client_rattachement_id,

                                            status: JSON.parse(item).status,
                                            title: JSON.parse(item).title,
                                            distantiel: JSON.parse(item).distantiel,
                                            presentiel: JSON.parse(item).presentiel,
                                            domaine: JSON.parse(item).domaine,
                                            date_debut: JSON.parse(item).date_du,
                                            date_fin: JSON.parse(item).date_au,
                                            class_internal_url: JSON.parse(item).class_internal_url,
                                            civilite: JSON.parse(item).civilite,

                                            telephone: JSON.parse(item).telephone,
                                            date_naissance: JSON.parse(item).date_naissance,
                                            adresse: JSON.parse(item).adresse,
                                            civilcode_postalite: JSON.parse(item).code_postal,
                                            ville: JSON.parse(item).ville,
                                            pays: JSON.parse(item).pays,

                                            invoiced: JSON.parse(item).invoiced,
                                            invoiced_ref: JSON.parse(item).invoiced_ref,
                                            invoiced_date: JSON.parse(item).invoiced_date,

                                            class_recyclage_delai: JSON.parse(item).class_recyclage_delai,
                                            class_recyclage_periodicite: JSON.parse(item).class_recyclage_periodicite,
                                            nb_jour_avant_recyclage: JSON.parse(item).nb_jour_avant_recyclage,
                                            warning_recyclage: JSON.parse(item).warning_recyclage,
                                            warning_recyclage_alert: JSON.parse(item).warning_recyclage,
                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        ///setuserimgclassprofil("");

                                        Get_List_UE_From_Class_Id(newSelectionModel.row.class_id);

                                        Getall_Class_List_Evalution(newSelectionModel.row.class_id);

                                        setselected_internal_url(newSelectionModel.row.class_internal_url);
                                        setselected_class_id(newSelectionModel.row.class_id);
                                        setselected_session_id(newSelectionModel.session_id);

                                        setinscription_invoiced(newSelectionModel.row.invoiced);

                                        setselected_row_data_json_age('');
                                        setgridline_id(newSelectionModel.row.id);
                                        setselected_id_status(newSelectionModel.row.status);

                                        //getStagiaireImages(newSelectionModel.row.class_internal_url, newSelectionModel.row.session_id, newSelectionModel.row.email);

                                        var al = JSON.parse(rowss[newSelectionModel.row.id])
                                        if (al.date_naissance) {
                                            var date_eval = new Date(moment(al.date_naissance, "DD/MM/YYYY"));
                                            var diff = Date.now() - date_eval.getTime();
                                            var age = new Date(diff);
                                            var reel_age = Math.abs(age.getUTCFullYear() - 1970);
                                            setselected_row_data_json_age(reel_age)

                                        }

                                        Get_Inscrit_List_UE(newSelectionModel.row._id);
                                        Get_Inscrit_List_Evaluation_Par_UE(newSelectionModel.row._id);
                                        setselected_row_data_json(JSON.parse(rowss[newSelectionModel.row.id]));
                                        setDialog_stagiaire_open(true);

                                    }}

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                   /* components={{
                                        Toolbar: GridToolbar,
                                    }}*/
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "statut"
                                        if (params.field === "status" && String(params.value) == "0") {
                                            return 'cell--statut--preinscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "1") {
                                            return 'cell--statut--inscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "-1") {
                                            return 'cell--statut--annule';
                                        }

                                    }}
                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {

                                            return 'line--statut--selected';
                                        }
                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}

                                />
                            </Box>
                            <br />

                        </div>}

                        {(display_stagiaire_with_alert && display_stagiaire_with_alert === true) && <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;

                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },

                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },


                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },
                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_session_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            setaddOneParticipant();
                                            setdisplay_detail_stagiaire();
                                            setselected_code_session();
                                            setsubmenu();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.filter((data) => (JSON.parse(data)).warning_recyclage === "1").map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            class_id: JSON.parse(item).class_id,
                                            class_internal_url: JSON.parse(item).class_internal_url,
                                            code_session: JSON.parse(item).code_session,
                                            session_id: JSON.parse(item).session_id,
                                            email: JSON.parse(item).email,
                                            nom: JSON.parse(item).nom,
                                            prenom: JSON.parse(item).prenom,
                                            employeur: JSON.parse(item).employeur,
                                            client_nom: JSON.parse(item).client_rattachement_nom,

                                            client_facture: JSON.parse(item).facture_client_rattachement_id,

                                            status: JSON.parse(item).status,
                                            title: JSON.parse(item).title,
                                            distantiel: JSON.parse(item).distantiel,
                                            presentiel: JSON.parse(item).presentiel,
                                            domaine: JSON.parse(item).domaine,
                                            date_debut: JSON.parse(item).date_du,
                                            date_fin: JSON.parse(item).date_au,
                                            class_internal_url: JSON.parse(item).class_internal_url,
                                            civilite: JSON.parse(item).civilite,

                                            telephone: JSON.parse(item).telephone,
                                            date_naissance: JSON.parse(item).date_naissance,
                                            adresse: JSON.parse(item).adresse,
                                            civilcode_postalite: JSON.parse(item).code_postal,
                                            ville: JSON.parse(item).ville,
                                            pays: JSON.parse(item).pays,

                                            invoiced: JSON.parse(item).invoiced,
                                            invoiced_ref: JSON.parse(item).invoiced_ref,
                                            invoiced_date: JSON.parse(item).invoiced_date,

                                            class_recyclage_delai: JSON.parse(item).class_recyclage_delai,
                                            class_recyclage_periodicite: JSON.parse(item).class_recyclage_periodicite,
                                            nb_jour_avant_recyclage: JSON.parse(item).nb_jour_avant_recyclage,
                                            warning_recyclage_alert: JSON.parse(item).warning_recyclage,
                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        ///setuserimgclassprofil("");

                                        Get_List_UE_From_Class_Id(newSelectionModel.row.class_id);

                                        Getall_Class_List_Evalution(newSelectionModel.row.class_id);

                                        setselected_internal_url(newSelectionModel.row.class_internal_url);
                                        setselected_class_id(newSelectionModel.row.class_id);
                                        setselected_session_id(newSelectionModel.session_id);

                                        setinscription_invoiced(newSelectionModel.row.invoiced);

                                        setselected_row_data_json_age('');
                                        setgridline_id(newSelectionModel.row.id);
                                        setselected_id_status(newSelectionModel.row.status);

                                        //getStagiaireImages(newSelectionModel.row.class_internal_url, newSelectionModel.row.session_id, newSelectionModel.row.email);

                                        var al = JSON.parse(rowss[newSelectionModel.row.id])
                                        if (al.date_naissance) {
                                            var date_eval = new Date(moment(al.date_naissance, "DD/MM/YYYY"));
                                            var diff = Date.now() - date_eval.getTime();
                                            var age = new Date(diff);
                                            var reel_age = Math.abs(age.getUTCFullYear() - 1970);
                                            setselected_row_data_json_age(reel_age)

                                        }

                                        Get_Inscrit_List_UE(newSelectionModel.row._id);
                                        Get_Inscrit_List_Evaluation_Par_UE(newSelectionModel.row._id);
                                        setselected_row_data_json(JSON.parse(rowss.filter((data) => (JSON.parse(data)).warning_recyclage === "1")[newSelectionModel.row.id]));
                                        setDialog_stagiaire_open(true);

                                    }}

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "statut"
                                        if (params.field === "status" && String(params.value) == "0") {
                                            return 'cell--statut--preinscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "1") {
                                            return 'cell--statut--inscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "-1") {
                                            return 'cell--statut--annule';
                                        }

                                    }}
                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {

                                            return 'line--statut--selected';
                                        }
                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}

                                />
                            </Box>
                            <br />

                        </div>}

                        <div className="div_row">

                            {selectionModel && selectionModel.length >= 1 && <div className="block_en_mass">
                                <nav >Traitement en masse </nav>
                                &nbsp;
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    className="block_en_mass_select"
                                    fullWidth
                                    options={New_Option}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            if (String(value.value) === "supprimer"
                                                || String(value.value) === "convention" || String(value.value) === "exporter"
                                                || String(value.value) === "valide_inscr"
                                                || String(value.value) === "recyclage_disable"
                                                || String(value.value) === "sendemail") {
                                                setactionmass_ftion_val(value.value);
                                            }
                                            else {
                                                setactionmass_ftion_val();
                                            }

                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />


                                {actionmass_ftion_val && <nav className='block_en_mass_bton_action'>
                                    <Popup
                                        trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                            <FcAcceptDatabase /> Traiter

                                        </Button>}
                                        modal
                                        nested
                                        position="center center"

                                    >

                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information </div>
                                                <div className="gest_content">
                                                    {' '}

                                                    {String(actionmass_ftion_val) === "valide_inscr" &&
                                                        <font>  Confirmer la <b> validation des inscriptions </b> en masse pour {selectionModel.length} lignes.
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "supprimer" &&
                                                        <font color="red">  Confirmer la <b> suppression </b> en masse pour {selectionModel.length} lignes.
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "exporter" &&
                                                        <font>  Confirmer l' <b> export </b> en masse pour {selectionModel.length} lignes.
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "convention" &&
                                                        <font>  Confirmer l' <b> édition des conventions </b> en masse pour {selectionModel.length} lignes.
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "recyclage_disable" &&
                                                        <font>  Confirmer la <b> désactivation du recyclage  </b> en masse pour {selectionModel.length} lignes.
                                                        </font>}

                                                    {String(actionmass_ftion_val) === "sendemail" &&
                                                        <font>  Confirmer la <b> la rédaction d'un email </b> en masse pour {selectionModel.length} lignes.
                                                        </font>}



                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            actionmass_ftion_Traitemet();
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </nav>
                                }

                            </div>}
                        </div>


                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        <Button variant="outlined" onClick={submenu_import_stagiaire} className="detail_class_submenu bton_import_excel"
                            id='menu_import_participant' name='menu_import_participant'>Importer des participants excel &nbsp;
                            <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                        </Button>


                        <Button variant="outlined" onClick={submenu_add_one_stagiaire}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 participant &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />

                        </Button>

                        <br />
                        {session_file_name && <nav><i>{session_file_name}</i></nav>}
                        {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les stagiaires ont été correctement importés</nav>}
                        {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                        <input type="file"
                            accept=".csv"
                            ref={hiddenFileInput_session}
                            style={{ display: 'none' }}
                            name="liste_sessions_file"
                            onChange={liste_sessions_file_change}
                        />

                        <a href='/sample/template_import_stagiaires.csv' download>Télécharger un fichier modèle</a>
                    </div>
                </div>
                <div className="div_row" ref={myRef_details} id="myRef_details"> &nbsp;</div>

                {((selected_id && String(selected_id).length > 2) || (String(addOneParticipant) === "1")) && <div className="div_row">
                    <Button variant="outlined" ref={myRef} onClick={submenu_detail_stagaire} className="detail_class_submenu" id='detail_session' name='detail_session'>Détail Participant</Button>

                    <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_class_submenu" id='piece_jointe' name='piece_jointe'>Pièces Jointes </Button>


                    {/* <Button variant="outlined" onClick={submenu_sessions} className="detail_class_submenu" id='preinscrit' name='preinscrit'>Détail Session </Button>
                    <Button variant="outlined" onClick={submenu_absence} className="detail_class_submenu" id='absence' name='absence'>Absence </Button> */}

                    {String(props.formation_initiale) === "1" && String(addOneParticipant) !== "1" && <Button variant="outlined" onClick={submenu_unite_enseignement} className="detail_class_submenu" id='ue' name='ue'>Unit. Enseignement </Button>}

                    {String(props.formation_initiale) === "1" && String(addOneParticipant) !== "1" && <Button variant="outlined" onClick={submenu_notes} className="detail_class_submenu" id='notes' name='ue'>Notes </Button>}


                    {String(addOneParticipant) !== "1" && String(history_securite_read) === "1" && <Button variant="outlined" onClick={submenu_historique} className="detail_class_submenu" id='historique' name='historique'>Historique </Button>}

                </div>}



                {String(addOneParticipant) === "1" && <div className="div_row session_data" style={{ "border": "None", "backgroundColor": "#F0F0F0" }}>
                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>Ajout d'un nouveau participant </nav>



                    <div className="session_caract">  Formation <br />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="disabled_style enable_style"
                            options={New_GetCurrentPartnerClass_result}

                            onChange={(event, value) => {
                                if (value && value.internal_url) {
                                    setp_one_formation(value.internal_url);
                                    setp_one_detail_session_class('');
                                    setp_one_detail_session_class("");
                                }
                            }}


                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />
                    </div>

                    {p_one_formation && Getall_TrainingSession_result && <div className="session_caract"> <b> Session</b>
                        <br />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="disabled_style enable_style"
                            options={New_Getall_TrainingSession_result.filter((session_ch) => session_ch.class_internal_url === p_one_formation)}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_one_detail_session_class(value._id);
                                    setp_one_detail_code_session(value.code_session);
                                } else {
                                    setp_one_detail_session_class("");
                                    setp_one_detail_code_session("");
                                }

                            }}
                            value={p_one_detail_code_session}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />
                    </div>}

                    {!p_one_formation && Getall_TrainingSession_result && <div className="session_caract"> <b> Session </b><br />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="disabled_style enable_style"
                            options={New_Getall_TrainingSession_result}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_one_detail_session_class(value._id);
                                }
                            }}
                            value={p_one_detail_code_session}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />
                    </div>}



                    <div className="session_caract"> <b> Civilité </b> <br />
                        <Autocomplete
                            disablePortal
                            name="one_civilite_part"
                            id="one_civilite_part"
                            className="disabled_style enable_style"

                            options={New_civilite}
                            onChange={(event, value) => {
                                if (value && value.value) {

                                    setp_one_civilite_part(value.value);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>


                    <div className="session_caract"> <b> Nom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_nom_part"
                            id="one_nom_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_nom_part}
                            onChange={(e) => {
                                setp_one_nom_part(e.target.value);
                            }
                            }

                        />
                    </div>


                    <div className="session_caract"> <b> Prénom </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_prenom_part"
                            id="one_prenom_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_prenom_part}
                            onChange={(e) => {
                                setp_one_prenom_part(e.target.value);
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> <b> Email </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_email_part"
                            id="one_email_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_email_part}
                            onChange={(e) => {
                                setp_one_email_part(e.target.value);
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> <b> Date Naissance </b><br />
                        <DatePicker
                            name="one_naissance_part"
                            id="one_naissance_part"
                            selected={new Date(moment(p_one_naissance_part, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_one_naissance_part(format(date, 'd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                            locale='fr-FR'

                        />

                    </div>

                    <div className="session_caract"> <b> Téléphone </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_phone_part"
                            id="one_phone_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_phone_part}
                            onChange={(e) => {
                                setp_one_phone_part(e.target.value);
                            }
                            }
                        />
                    </div>


                    <div className="session_caract"> Adresse <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_adresse_part"
                            id="one_adresse_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_adresse_part}
                            onChange={(e) => {
                                setp_one_adresse_part(e.target.value);
                            }
                            }
                        />
                    </div>


                    <div className="session_caract"> Code postal <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_code_postal_part"
                            id="one_code_postal_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_code_postal_part}
                            onChange={(e) => {
                                setp_one_code_postal_part(e.target.value);
                            }
                            }
                        />
                    </div>


                    <div className="session_caract"> Ville <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_ville_part"
                            id="one_ville_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_ville_part}
                            onChange={(e) => {
                                setp_one_ville_part(e.target.value);
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> Pays <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_pays_part"
                            id="one_pays_part"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={p_one_pays_part}
                            onChange={(e) => {
                                setp_one_pays_part(e.target.value);
                            }
                            }
                        />
                    </div>

                    <div className="session_caract"> Statut Inscription <br />

                        <Autocomplete
                            disablePortal
                            name="one_status_part"
                            id="one_status_part"
                            className="disabled_style enable_style"

                            options={New_status_inscription_add_with_annule}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_one_status_part(value.value);
                                } else {
                                    setp_one_status_part("");
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                        {/*<TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            select
                            value={p_one_status_part}
                            onChange={(e) => {
                                setp_one_status_part(e.target.value);
                            }
                            }
                            name="one_status_part"
                            id="one_status_part"

                            InputLabelProps={{
                                shrink: true,
                            }}

                            disabled={false}
                            className="disabled_style enable_style"
                        >
                            {status_inscription.map((option) => (
                                <MenuItem key={option.value} value={option.value} style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                    {option.label} &nbsp; <br />
                                </MenuItem>
                            ))}
                        </TextField>*/}
                    </div>

                    <div className="session_caract"> Client <br />

                        <Autocomplete
                            disablePortal
                            name="one_detail_client_rattachement"
                            id="one_detail_client_rattachement"
                            className="disabled_style"
                            options={New_Get_List_Partner_Clients_result}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_one_detail_client_rattachement_id(value._id);
                                    setp_one_detail_client_rattachement_nom(value.nom);


                                    Dialog_1_handle_change_participant_session(" Pensez à mettre à jour le client à facturer s'il est différent du client principal");
                                    setp_one_detail_facture_client_rattachement_id(value._id);


                                }
                                else {
                                    setp_one_detail_client_rattachement_id("");
                                    setp_one_detail_client_rattachement_nom("");


                                    Dialog_1_handle_change_participant_session(" Pensez à mettre à jour le client à facturer s'il est différent du client principal");
                                    setp_one_detail_facture_client_rattachement_id("");

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    <div className="session_caract"> A Facturer <br />

                        <Autocomplete
                            disablePortal
                            name="one_detail_facture_client"
                            id="one_detail_facture_client"
                            className="disabled_style"
                            options={New_Get_List_Partner_Clients_result}
                            value={New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(p_one_detail_facture_client_rattachement_id))[0].label}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_one_detail_facture_client_rattachement_id(value._id);

                                } else {
                                    setp_one_detail_facture_client_rattachement_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>


                    {New_Get_List_Partner_Financeurs_result && New_Get_List_Partner_Financeurs_result.length > 0 &&
                        <div className="session_caract"> Financeur <br />

                            <Autocomplete
                                disablePortal
                                name="one_detail_financeur_rattachement"
                                id="one_detail_financeur_rattachement"
                                className="disabled_style"
                                options={New_Get_List_Partner_Financeurs_result}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_one_detail_financeur_rattachement_id(value._id);
                                        setp_one_detail_financeur_rattachement_nom(value.nom);

                                    } else {
                                        setp_one_detail_financeur_rattachement_id("");
                                        setp_one_detail_financeur_rattachement_nom("");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}

                    <div className="session_caract"> Type apprenant <br />

                        <Autocomplete
                            disablePortal
                            name="one_detail_type_apprenant"
                            id="one_detail_type_apprenant"
                            className="disabled_style"
                            options={New_type_apprenant}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setp_one_detail_type_apprenant(value._id);
                                } else {
                                    setp_one_detail_type_apprenant("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>

                    <div className="session_caract"> Mode Financement <br />

                        <Autocomplete
                            disablePortal
                            name="one_detail_mode_financement"
                            id="one_detail_mode_financement"
                            className="disabled_style"
                            options={New_Financement}
                            onChange={(event, value) => {
                                if (value && value.value) {

                                    setp_one_detail_modefinancement(value.value);
                                } else {
                                    setp_one_detail_modefinancement("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>

                    {/* -- début champs specifiques **/}
                    <div className="div_row">
                        <hr />
                    </div>
                    <div className="div_row" style={{ "padding": "5px" }}> Vos champs spécifiques<br />

                        {rows_champs_specifics &&
                            rows_champs_specifics.map((champ_spec) => (

                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        type="number"
                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            setparticipant_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />}

                                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name={JSON.parse(champ_spec).field_name}
                                        id={JSON.parse(champ_spec).field_name}

                                        InputLabelProps={{
                                            shrink: true,
                                        }}


                                        className="disabled_style"
                                        onChange={(e) => {
                                            change_champs_spec_handle(e.target.value);
                                            setparticipant_data_changed("1");
                                        }}
                                    //onChange={change_champs_spec_handle}


                                    />}

                                </div>
                            ))}

                        <br />

                    </div>
                    {/* -- end  champs specifiques **/}


                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                            <Button variant="contained" className="bton_enreg" onClick={One_Create_Participant}>Enregistrer le participant
                            </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                            <Button variant="contained" className="bton_annule" onClick={annule_Add_One_Participant}>Annuler
                            </Button>

                        </div>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                </div>
                }


                {String(display_detail_stagiaire) === "1" && selected_code_session && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail inscrit </nav>
                    {String(GetAttendee_api) === "true" && <div className="div_row" style={{ "border": "None" }}>

                        {String(Resend_LMS_Credentials_api) === "true" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                            Les identifiants de connexion ont été correctement envoyés</div>}


                        {String(Resend_LMS_Credentials_api) === "false" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                            {Resend_LMS_Credentials_message}</div>}

                        <div className="div_row" style={{ "border": "None" }}>

                            <div className="div_row" style={{ "border": "None" }}>
                                <div className="div_row_gauche">

                                    <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                                        <div className="div_row_gauche_image">
                                            <img class="img_class_logo" src={userimgclassprofil} /><br />

                                        </div>
                                        <div className="div_row_droite_image">
                                            <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                            {<nav>

                                                <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                                {String(userimgclassprofilchanged) === "1" &&
                                                    <nav className="bton_image_class" onClick={record_Stagiaire_Image}>Enregistrer la photo<br />  </nav>
                                                }

                                                {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                                    <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                                }
                                                {String(userimgclassprofilchanged) === "1" &&
                                                    <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                                    </div>}


                                            </nav>}

                                            {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                            {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                            {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                            {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                                        </div>

                                    </div>
                                    <div className="div_row_bis tips_img_class">
                                        <li>
                                            L'image doit etre carrée.
                                        </li>
                                        <li>
                                            Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                                        </li>
                                        <li>
                                            Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                            Les dimensions maximales sont de 256pixels X 256pixels
                                        </li>
                                        <li>
                                            La taille de l'image ne doit pas dépasser 1 mega octet
                                        </li>
                                    </div>

                                </div>

                                {/*<div className="div_row_droite">
                                    <div style={{ "marginLeft": "1rem" }}>

                                        <label className="bton_image_class" onClick={(e) => {
                                            Get_List_Stagiaire_Conventions_Individuel();
                                            setselected_convention_id();
                                            setselected_convention_type();
                                            setselected_convention_email_destinataire_prod();

                                            setselected_convention_email_test();
                                            Get_Stagiaire_List_Email_Com();
                                            setDialog_convention_open(true);
                                        }} >Editer une convention</label><br />
                                    </div>

                                </div>*/}
                            </div>

                            <div className="session_data" >
                                <div>

                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract" tyle={{ "width": "80%", "float": "left" }}> <b> Session</b><br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            required
                                            name="detail_session_class"
                                            id="detail_session_class"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style disabled_style_left_text_with_visualiser_icone"
                                            value={p_detail_session_class}



                                        />
                                        <Tooltip className="tooltip_css" id="detail_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="detail_01" data-tooltip-html="Voir les détails">
                                            <div className="disabled_style disabled_style_right_bton_visualiser" onClick={(event) => {
                                                if (selected_session_id && selected_internal_url && String(selected_session_id).length > 2 && String(selected_internal_url).length > 2) {
                                                    window.open(
                                                        process.env.REACT_APP_FRONT_URL + "Partner/mes_sessions/" + String(selected_session_id) + "/" + String(selected_internal_url),
                                                        '_blank'
                                                    );
                                                }
                                            }
                                            }
                                            >
                                                <div className="disabled_style_with_visualiser_icone" >
                                                    <FcOpenedFolder />

                                                </div>
                                            </div>
                                        </a>

                                    </div>}


                                    {String(participant_data_edit_mode) === "1" && <div className="session_caract"> <b> Session </b> <br />

                                        <Autocomplete
                                            disablePortal
                                            name="detail_session_class"
                                            id="detail_session_class"
                                            className="disabled_style"

                                            options={New_Getall_TrainingSession_result}
                                            value={New_Getall_TrainingSession_result.filter((data) => (data).label === String(working_session_code))[0].label}
                                            onChange={(event, value) => {
                                                if (value && value._id) {

                                                    setworking_session_code(value.label);
                                                    setp_detail_session_class(value._id);
                                                    setparticipant_data_changed("1");
                                                    setp_detail_session_class_id(value._id);
                                                    Dialog_1_handle_change_participant_session(" Attention, vous allez modifier la session d'affectation du participant");

                                                } else {
                                                    Dialog_1_handle_change_participant_session(" Attention, vous allez modifier la session d'affectation du participant");

                                                }

                                            }}

                                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                            />
                                            }
                                        />


                                    </div>}


                                    {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Client  <br />

                                        <Autocomplete
                                            disablePortal
                                            name="detail_client_rattachement"
                                            id="detail_client_rattachement"
                                            className="disabled_style"
                                            options={New_Get_List_Partner_Clients_result}
                                            onChange={(event, value) => {

                                                if (value && value._id) {
                                                    setp_detail_client_rattachement_id(value._id);
                                                    setp_detail_client_rattachement_nom(value.nom);

                                                    setparticipant_data_changed("1");
                                                    Dialog_1_handle_change_participant_session(" Attention, vous allez modifier le client de rattachement du participant. Pensez à mettre à jour le client à facturer s'il est différent du client principal");

                                                    if (value._id !== p_detail_client_rattachement_id) {
                                                        setp_detail_facture_client_rattachement_id(value._id);
                                                    }

                                                } else {

                                                    setp_detail_client_rattachement_id("");
                                                    setp_detail_client_rattachement_nom("");

                                                    Dialog_1_handle_change_participant_session(" Attention, vous allez modifier le client de rattachement du participant. Pensez à mettre à jour le client à facturer s'il est différent du client principal");
                                                    setp_detail_facture_client_rattachement_id("");

                                                }
                                            }}
                                            value={p_detail_client_rattachement_nom}

                                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                            />
                                            }
                                        />

                                    </div>}

                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Client<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_client_rattachement"
                                            id="detail_client_rattachement"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style disabled_style_left_text_with_visualiser_icone"
                                            value={p_detail_client_rattachement_nom}
                                        />

                                        <Tooltip className="tooltip_css" id="detail_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="detail_01" data-tooltip-html="Voir les détails">
                                            <div className="disabled_style disabled_style_right_bton_visualiser" onClick={(event) => {

                                                if (p_detail_client_rattachement_id && String(p_detail_client_rattachement_id).length > 2) {
                                                    window.open(
                                                        process.env.REACT_APP_FRONT_URL + "Partner/mes_clients/" + String(p_detail_client_rattachement_id),
                                                        '_blank'
                                                    );
                                                }
                                                else {
                                                    alert(" Aucun client selectionné")
                                                }
                                            }
                                            }
                                            >
                                                <div className="disabled_style_with_visualiser_icone" >
                                                    <FcOpenedFolder />

                                                </div>
                                            </div>
                                        </a>

                                    </div>}


                                    {New_Get_List_Partner_Clients_result && New_Get_List_Partner_Clients_result.length > 0 &&
                                        String(participant_data_edit_mode) === "1" && <div className="session_caract">  A Facturer <br />

                                            <Autocomplete
                                                disablePortal
                                                name="detail_facture_client_rattachement"
                                                id="detail_facture_client_rattachement"
                                                className="disabled_style"
                                                options={New_Get_List_Partner_Clients_result}
                                                value={New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(p_detail_facture_client_rattachement_id))[0].label}
                                                onChange={(event, value) => {

                                                    if (value && value._id) {
                                                        setp_detail_facture_client_rattachement_id(value._id);
                                                        setparticipant_data_changed("1");
                                                        Dialog_1_handle_change_participant_session(" Attention, vous allez modifier l'entité à facturer ");

                                                    } else {
                                                        setp_detail_facture_client_rattachement_id("");
                                                        setparticipant_data_changed("1");
                                                        Dialog_1_handle_change_participant_session(" Attention, vous allez modifier l'entité à facturer ");

                                                    }
                                                }}


                                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                                />
                                                }
                                            />

                                        </div>}

                                    {New_Get_List_Partner_Clients_result && New_Get_List_Partner_Clients_result.length > 0 && String(participant_data_edit_mode) !== "1" &&
                                        <div className="session_caract">  A Facturer <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_facture_client_rattachement"
                                                id="detail_facture_client_rattachement"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style disabled_style_left_text_with_visualiser_icone"
                                                value={New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(p_detail_facture_client_rattachement_id))[0].label}


                                            />

                                            <Tooltip className="tooltip_css" id="detail_01" style={{ "fontSize": "12px" }} />
                                            <a data-tooltip-id="detail_01" data-tooltip-html="Voir les détails">
                                                <div className="disabled_style disabled_style_right_bton_visualiser" onClick={(event) => {

                                                    if (p_detail_facture_client_rattachement_id && String(p_detail_facture_client_rattachement_id).length > 2) {
                                                        window.open(
                                                            process.env.REACT_APP_FRONT_URL + "Partner/mes_clients/" + String(p_detail_facture_client_rattachement_id),
                                                            '_blank'
                                                        );
                                                    }
                                                    else {
                                                        alert(" Aucune entité à facturer selectionné")
                                                    }
                                                }
                                                }
                                                >
                                                    <div className="disabled_style_with_visualiser_icone" >
                                                        <FcOpenedFolder />

                                                    </div>
                                                </div>
                                            </a>

                                        </div>}





                                    {New_Get_List_Partner_Financeurs_result && New_Get_List_Partner_Financeurs_result.length > 0 &&
                                        String(participant_data_edit_mode) === "1" && <div className="session_caract"> Financeur <br />

                                            <Autocomplete
                                                disablePortal
                                                name="detail_financeur_rattachement"
                                                id="detail_financeur_rattachement"
                                                className="disabled_style"
                                                options={New_Get_List_Partner_Financeurs_result}
                                                onChange={(event, value) => {
                                                    if (value && value._id) {
                                                        setp_detail_financeur_rattachement_id(value._id);
                                                        setp_detail_financeur_rattachement_nom(value.nom);
                                                        setparticipant_data_changed("1");
                                                        Dialog_1_handle_change_participant_session(" Attention, vous allez modifier l'organisme financeur du participant");
                                                    } else {
                                                        setp_detail_financeur_rattachement_id("");
                                                        setp_detail_financeur_rattachement_nom("");
                                                        setparticipant_data_changed("1");
                                                        Dialog_1_handle_change_participant_session(" Attention, vous allez modifier l'organisme financeur du participant");

                                                    }
                                                }}
                                                value={p_detail_financeur_rattachement_nom}

                                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                                />
                                                }
                                            />

                                        </div>}

                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Financeur<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_financeur_rattachement"
                                            id="detail_financeur_rattachement"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style disabled_style_left_text_with_visualiser_icone"
                                            value={p_detail_financeur_rattachement_nom}
                                        />

                                        <Tooltip className="tooltip_css" id="detail_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="detail_01" data-tooltip-html="Voir les détails">
                                            <div className="disabled_style disabled_style_right_bton_visualiser" onClick={(event) => {

                                                if (p_detail_financeur_rattachement_id && String(p_detail_financeur_rattachement_id).length > 2) {
                                                    window.open(
                                                        process.env.REACT_APP_FRONT_URL + "Partner/mes_clients/" + String(p_detail_financeur_rattachement_id),
                                                        '_blank'
                                                    );
                                                }
                                                else {
                                                    alert(" Aucun financeur selectionné")
                                                }
                                            }
                                            }
                                            >
                                                <div className="disabled_style_with_visualiser_icone" >
                                                    <FcOpenedFolder />

                                                </div>
                                            </div>
                                        </a>

                                    </div>}


                                    {String(participant_data_edit_mode) === "1" && <div className="session_caract"> Type apprenant<br />

                                        <Autocomplete
                                            disablePortal
                                            name="detail_type_apprenant"
                                            id="detail_type_apprenant"
                                            className="disabled_style"
                                            options={New_type_apprenant}
                                            value={New_type_apprenant.filter((val) => val.value === String(p_detail_type_apprenant))[0].label}
                                            onChange={(event, value) => {

                                                if (value && value.id) {
                                                    setp_detail_type_apprenant(value.id);
                                                    setparticipant_data_changed("1");
                                                } else {
                                                    setp_detail_type_apprenant("");
                                                    setparticipant_data_changed("1");
                                                }
                                            }}

                                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                            />
                                            }
                                        />
                                    </div>}

                                    {New_Financement && New_Financement.length > 0 && String(participant_data_edit_mode) === "1" && <div className="session_caract"> Mode Financement<br />

                                        <Autocomplete
                                            disablePortal
                                            name="detail_mode_financement"
                                            id="detail_mode_financement"
                                            className="disabled_style"
                                            options={New_Financement}
                                            value={New_Financement.filter((val) => val.value === String(p_detail_mode_fin))[0].label}
                                            onChange={(event, value) => {

                                                if (value && value.value) {
                                                    setp_detail_mode_fin(value.value);
                                                    setparticipant_data_changed("1");
                                                } else {
                                                    setp_detail_mode_fin(value.value);
                                                    setparticipant_data_changed("1");
                                                }
                                            }}

                                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                            />
                                            }
                                        />
                                    </div>}



                                    {String(participant_data_edit_mode) !== "1" && <div className="session_caract"> Type apprenant <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_type_apprenant"
                                            id="detail_type_apprenant"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_type_apprenant_label}
                                        />

                                    </div>}

                                    {New_Financement && New_Financement.length > 0 && String(participant_data_edit_mode) !== "1" &&
                                        <div className="session_caract"> Mode Financement <br />
                                            <TextField
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                required
                                                name="detail_mode_financement"
                                                id="detail_mode_financement"

                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled={false}
                                                className="disabled_style"
                                                value={New_Financement.filter((val) => val.value === String(p_detail_mode_fin))[0].label}
                                            />

                                        </div>}


                                    {(String(participant_data_edit_mode) !== "1" || String(p_detail_apprenant_id).trim().length > 3) && <div className="session_caract"> Statut<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_status_inscript"
                                            id="detail_status_inscript"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_status_inscrit_label}
                                        />

                                    </div>}

                                    {String(participant_data_edit_mode) === "1" && String(p_detail_apprenant_id).trim().length <= 3 && <div className="session_caract"> Statut <br />

                                        <Autocomplete
                                            disablePortal
                                            name="detail_status_inscript"
                                            id="detail_status_inscript"
                                            className="disabled_style"

                                            options={New_status_inscription_add_with_annule}
                                            value={New_status_inscription_add_with_annule.filter((val) => val.value === String(p_detail_status_inscrit))[0].label}
                                            onChange={(event, value) => {
                                                if (value && value.value) {

                                                    setp_detail_status_inscrit(value.value);
                                                    setparticipant_data_changed("1");
                                                } else {
                                                    setp_detail_status_inscrit("");
                                                    setparticipant_data_changed("1");
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                            />
                                            }
                                        />


                                    </div>}

                                    {String(participant_data_edit_mode) === "1" && String(p_detail_apprenant_id).trim().length <= 3 && <div className="session_caract"> <b> Civilité </b> <br />

                                        <Autocomplete
                                            disablePortal
                                            name="detail_civilite"
                                            id="detail_civilite"
                                            className="disabled_style"
                                            value={New_civilite.filter((val) => val.value === String(p_detail_civilite))[0].label}
                                            options={New_civilite}
                                            onChange={(event, value) => {
                                                if (value && value.value) {

                                                    setp_detail_civilite(value.value);
                                                    setparticipant_data_changed("1");

                                                } else {
                                                    setp_detail_civilite("");
                                                    setparticipant_data_changed("1");
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                            />
                                            }
                                        />



                                    </div>}

                                    {(String(participant_data_edit_mode) !== "1" || String(p_detail_apprenant_id).trim().length > 3) && <div className="session_caract"> <b> Civilité </b> <br />
                                        {New_civilite && New_civilite.length > 0 && p_detail_civilite && <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_civilite"
                                            id="detail_civilite"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled={false}
                                            className="disabled_style"
                                            value={New_civilite.filter((data) => (data).id === String(p_detail_civilite))[0].label}

                                        />}

                                    </div>}


                                    <div className="session_caract"> <b> Nom </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_nom"
                                            id="detail_nom"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_nom}
                                            onChange={(e) => {
                                                setp_detail_nom(e.target.value);
                                                setparticipant_data_changed("1");
                                            }
                                            }

                                        />
                                    </div>

                                    <div className="session_caract"> <b> Prénom </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_prenom"
                                            id="detail_prenom"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_prenom}
                                            onChange={(e) => {
                                                setp_detail_prenom(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>

                                    <div className="session_caract"> <b> Email </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_mail"
                                            id="detail_mail"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_mail}
                                            onChange={(e) => {
                                                setp_detail_mail(e.target.value);
                                                setparticipant_data_changed("1");
                                            }
                                            }

                                        />
                                    </div>

                                    <div className="session_caract"> <b> Date Naissance </b><br />
                                        <DatePicker
                                            name="detail_naissance"
                                            id="detail_naissance"
                                            selected={new Date(moment(p_detail_naissance, "DD/MM/YYYY"))}
                                            onChange={(date) => {
                                                setp_detail_naissance(format(date, 'd/MM/yyyy'));
                                                setparticipant_data_changed("1");
                                            }
                                            }
                                            showTimeSelect={false}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style enable_style"
                                            locale='fr-FR'

                                        />

                                    </div>

                                    <div className="session_caract"> <b> Téléphone </b> <br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_tel"
                                            id="detail_tel"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_tel}
                                            onChange={(e) => {
                                                setp_detail_tel(e.target.value);
                                                setparticipant_data_changed("1");
                                            }
                                            }
                                        />
                                    </div>


                                    <div className="session_caract"> Adresse<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_adresse"
                                            id="detail_adresse"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_adresse}
                                            onChange={(e) => {
                                                setp_detail_adresse(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>
                                    <div className="session_caract"> Code postal<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_code_postal"
                                            id="detail_code_postal"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_code_postal}
                                            onChange={(e) => {
                                                setp_detail_code_postal(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>
                                    <div className="session_caract"> Ville<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_ville"
                                            id="detail_ville"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_ville}
                                            onChange={(e) => {
                                                setp_detail_ville(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>

                                    <div className="session_caract"> Pays<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_pays"
                                            id="detail_pays"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_pays}
                                            onChange={(e) => {
                                                setp_detail_pays(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>


                                    <div className="session_caract">
                                        Date Inscription
                                        <DatePicker
                                            name="detail_incr_date"
                                            id="detail_incr_date"
                                            selected={detailuser_date_inscription}

                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'

                                        />
                                    </div>


                                    <div className="session_caract"> Cout <br />
                                        <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_cout"
                                            id="detail_cout"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "1", max: "999999", step: "1" }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_cout}
                                            onChange={(e) => {
                                                setp_detail_cout(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>


                                    <div className="session_caract"> Opco <br />
                                        <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_opco"
                                            id="detail_opco"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_opco}
                                            onChange={(e) => {
                                                setp_detail_opco(e.target.value);

                                                setparticipant_data_changed("1");
                                            }}
                                        />
                                    </div>



                                    <div className="session_caract"> Employeur<br />
                                        <TextField
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="detail_employeur"
                                            id="detail_employeur"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={false}
                                            className="disabled_style"
                                            value={p_detail_employeur}
                                            onChange={(e) => {
                                                setp_detail_employeur(e.target.value);
                                                setparticipant_data_changed("1");
                                            }}
                                        />


                                    </div>

                                    {/* -- début champs specifiques **/}
                                    <div className="div_row" style={{ "border": 'none' }}>
                                        <hr />
                                    </div>
                                    <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

                                        {rows_champs_specifics &&
                                            rows_champs_specifics.map((champ_spec) => (

                                                <div className="session_caract">  {JSON.parse(champ_spec).field_label} <br />

                                                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}

                                                        type="number"
                                                        inputProps={{ min: "1", max: "999999", step: "1" }}
                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle(e.target.value);
                                                            setparticipant_data_changed("1");
                                                        }}
                                                    //onChange={change_champs_spec_handle}


                                                    />}

                                                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                        required
                                                        name={JSON.parse(champ_spec).field_name}
                                                        id={JSON.parse(champ_spec).field_name}

                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}


                                                        className="disabled_style"
                                                        onChange={(e) => {
                                                            change_champs_spec_handle(e.target.value);
                                                            setparticipant_data_changed("1");
                                                        }}
                                                    //onChange={change_champs_spec_handle}


                                                    />}

                                                </div>
                                            ))}

                                        <br />

                                    </div>
                                    {/* -- end  champs specifiques **/}

                                    <div className="div_row">
                                        {String(participant_data_changed) === "1" && String(participant_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                            /!\ Pensez à enregistrer les modifications
                                        </div>}


                                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                            {String(participant_data_changed) === "1" && String(participant_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                                <Button variant="contained" className="bton_enreg" onClick={Update_One_StagiaireData}>Enregistrer les modifications
                                                </Button>

                                            </div>}

                                            {String(participant_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} onClick={AnnuleAttendeeDetailFields}>
                                                <Button variant="contained" className="bton_annule" onClick={AnnuleAttendeeDetailFields}>Annuler
                                                </Button>
                                            </div>}
                                        </div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <hr className="hr_break" />
                                        </div>



                                        {String(participant_data_edit_mode) !== "1" && String(inscription_invoiced) !== "1" && p_detail_apprenant_id && String(p_detail_apprenant_id).trim().length > 3 && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                            <Button variant="contained" className="bton_edit" onClick={EnableAttendeeDetailFields_Deja_Inscrit}>Editer
                                            </Button>
                                        </div>}




                                        {String(participant_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                            {String(selected_id_status) !== "1" && (!p_detail_apprenant_id || String(p_detail_apprenant_id).trim().length <= 3) &&
                                                <Button variant="contained" className="bton_edit" onClick={EnableAttendeeDetailFields}>Editer
                                                </Button>}



                                            {selected_id_status && String(selected_id_status) === "1" && p_detail_apprenant_id &&
                                                String(p_detail_apprenant_id).trim().length > 3 && <Button variant="contained" className="bton_annule" onClick={open_apprenant_dossier}>Voir dossier &nbsp;
                                                    <Tooltip className="tooltip_css" id="tooltip_id_01" />
                                                    <a data-tooltip-id="tooltip_id_01" data-tooltip-html="L'inscription est déjà validée. Pour modifier les informations de l'apprenant, accedez au dossier.">
                                                        <FcOpenedFolder style={{ fontSize: "20px" }} />

                                                    </a>
                                                </Button>}

                                            {selected_id_status && String(selected_id_status) === "-1" && p_detail_apprenant_id &&
                                                String(p_detail_apprenant_id).trim().length > 3 &&
                                                <Popup
                                                    trigger={

                                                        <Button variant="contained" className="bton_enreg" onClick={Re_Preinscrire_Cancelled_Attendee}>PréInscire &nbsp;


                                                        </Button>

                                                    }
                                                    modal
                                                    nested
                                                    position="center center"

                                                >

                                                    {close => (
                                                        <div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}

                                                                <font>  Confirmer la réinscription de l'apprenant à la session.
                                                                </font>

                                                            </div>
                                                            <div className="gest_actions">
                                                                <div style={{ "width": "45%", "float": "left" }}>
                                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                                        Re_Preinscrire_Cancelled_Attendee();
                                                                        close();
                                                                    }}> Valider </button>

                                                                </div>
                                                                <div style={{ "width": "45%", "float": "right" }}>
                                                                    <button
                                                                        className="gest_bton_popup"
                                                                        onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Annuler
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>

                                            }


                                        </div>}

                                    </div>



                                    <div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <hr className="hr_break" />
                                        </div>

                                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                            Tuteurs

                                            <div style={{ "width": '100%', "float": 'left' }}>
                                                <div style={{ "width": '50%', "float": 'left', "borderRight": "thick double #32a1ce" }}>
                                                    <div className="tuteur_field" style={{ "textAlign": "center", "width": "100%" }}> Tuteur 1 </div>

                                                    <div className="tuteur_field"> <b> Civilité </b><br />


                                                        {p_detail_tuteur1_civilite && <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="field_detail_tuteur1_civilite"
                                                            id="field_detail_tuteur1_civilite"
                                                            className="disabled_style mysy_gris"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}

                                                            value={String(p_detail_tuteur1_civilite)[0].toUpperCase() + String(p_detail_tuteur1_civilite).slice(1)}

                                                        />}

                                                        {!p_detail_tuteur1_civilite && <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="field_detail_tuteur1_civilite"
                                                            id="field_detail_tuteur1_civilite"
                                                            className="disabled_style mysy_gris"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}

                                                            value=""
                                                        />}



                                                    </div>

                                                    <div className="tuteur_field"> <b> Nom </b> <br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur1_nom"
                                                            id="detail_tuteur1_nom"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"

                                                            value={p_detail_tuteur1_nom}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur1_nom(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    <div className="tuteur_field" > <b>Prénom </b><br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur1_prenom"
                                                            id="detail_tuteur1_prenom"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"
                                                            value={p_detail_tuteur1_prenom}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur1_prenom(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    <div className="tuteur_field"> <b> Email </b> <br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur1_email"
                                                            id="detail_tuteur1_email"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"
                                                            value={p_detail_tuteur1_email}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur1_email(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    <div className="tuteur_field"> Téléphone <br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur1_phone"
                                                            id="detail_tuteur1_phone"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"
                                                            value={p_detail_tuteur1_telephone}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur1_telephone(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    {String(p_detail_apprenant_id).trim().length <= 0 && <div className="tuteur_field" style={{ "cursor": "pointer" }} onClick={(e) => {
                                                        setquel_tuteur("1");
                                                        setDialog_tuteur_1_open(true);
                                                    }}> Voir plus  <RiMoreFill />  <FcEditImage style={{ "fontSize": "large" }} />

                                                    </div>}





                                                </div>
                                                <div style={{ "width": '50%', "float": 'left', }}>

                                                    <div className="tuteur_field" style={{ "textAlign": "center", "width": "100%" }}> Tuteur 2 </div>

                                                    <div className="tuteur_field"> <b> Civilité </b><br />


                                                        {p_detail_tuteur2_civilite && <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="field_detail_tuteur2_civilite"
                                                            id="field_detail_tuteur2_civilite"
                                                            className="disabled_style mysy_gris"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}

                                                            value={String(p_detail_tuteur2_civilite)[0].toUpperCase() + String(p_detail_tuteur2_civilite).slice(1)}

                                                        />}

                                                        {!p_detail_tuteur2_civilite && <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="field_detail_tuteur2_civilite"
                                                            id="field_detail_tuteur2_civilite"
                                                            className="disabled_style mysy_gris"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}

                                                            value=""
                                                        />}


                                                    </div>

                                                    <div className="tuteur_field"> <b>Nom </b><br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur2_nom"
                                                            id="detail_tuteur2_nom"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"

                                                            value={p_detail_tuteur2_nom}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur2_nom(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    <div className="tuteur_field"> <b>Prénom </b><br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur2_prenom"
                                                            id="detail_tuteur2_prenom"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"
                                                            value={p_detail_tuteur2_prenom}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur2_prenom(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    <div className="tuteur_field"> <b>Email </b><br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur2_email"
                                                            id="detail_tuteur2_email"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"
                                                            value={p_detail_tuteur2_email}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur2_email(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    <div className="tuteur_field"> Téléphone <br />
                                                        <TextField
                                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                            required
                                                            name="detail_tuteur2_phone"
                                                            id="detail_tuteur2_phone"

                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            disabled={false}
                                                            className="disabled_style"
                                                            value={p_detail_tuteur2_telephone}
                                                            onChange={(e) => {
                                                                setp_detail_tuteur2_telephone(e.target.value);
                                                                setparticipant_data_changed("1");
                                                            }}
                                                        />


                                                    </div>

                                                    {String(p_detail_apprenant_id).trim().length <= 0 && <div className="tuteur_field" style={{ "cursor": "pointer" }} onClick={(e) => {
                                                        setquel_tuteur("2");
                                                        setDialog_tuteur_1_open(true);
                                                    }}> Voir plus  <RiMoreFill /> <FcEditImage style={{ "fontSize": "large" }} />

                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {is_lms_class_code && <div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <hr className="hr_break" />
                                        </div>
                                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                            Acces LMS

                                        </div>
                                        <div className="div_row" style={{ "border": "None" }}>
                                            <div className="session_caract"> login LMS<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                                    name="login_lms"
                                                    id="login_lms"
                                                    value={attendee_lms_login}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={true}
                                                    className="disabled_style"
                                                    onChange={(e) => setattendee_lms_login(e.target.value)}
                                                />
                                            </div>

                                            <div className="session_caract"> Mot de passe LMS<br />
                                                <TextField
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                                    required
                                                    type="password"
                                                    name="lms_pwd"
                                                    id="lms_pwd"
                                                    value="xxxxx"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    disabled={true}
                                                    className="disabled_style"

                                                />
                                            </div>
                                            <div className="session_caract" style={{ "paddingTop": "15px" }}> &nbsp;<br />
                                                <Button variant="contained" className="bton_enreg" onClick={Resend_LMS_Credentials} style={{ "width": "80%" }}> (R)envoyer les Identifiants
                                                </Button>
                                            </div>

                                        </div>
                                    </div>}
                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>



                                    <div className="div_row" style={{ "border": "None" }}>
                                        <hr className="hr_break" />
                                    </div>

                                </div>
                                {/*
                                <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                    Evaluation

                                </div>

                                <div className="div_row" style={{ "border": "None" }}>
                                    <div className="session_caract">
                                        Date Evaluation
                                        <DatePicker
                                            name="date_evaluation"
                                            id="date_evaluation"
                                            selected={detailuser_date_evaluation}
                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'


                                        />
                                    </div>

                                    <div className="session_caract"> Note Evaluation<br />
                                        <TextField sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                            required
                                            name="note_eval"
                                            id="note_eval"
                                            type="number"


                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{ min: "0", max: "5", step: "1", readOnly: true, }}
                                            className="disabled_style"
                                            value={p_note_eval}

                                        />
                                    </div>

                                    <div className="session_caract"> Evaluation<br />
                                        <Editor
                                            onInit={(evt, editor) => editorRef_evaluation.current = editor}
                                            initialValue={field_evaluation}
                                            onKeyUp={editor_keyup}
                                            disabled={true}

                                            init={{
                                                resize: false,

                                                height: 100,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: false,
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                            }}
                                        />



                                    </div>


                                </div>


                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>

                                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                        {String(SendEvaluationEmail_api) === "false" && <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                            {SendEvaluationEmail_message}</div>}


                                        {String(SendEvaluationEmail_api) === "true" && <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                            La demande d'evaluation ete envoyée par email </div>}


                                        <Button variant="contained" className="bton_enreg" onClick={SendEvaluationEmail}>Envoi demande evaluation
                                        </Button>

                                    </div>

                                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                        <Button variant="contained" className="bton_edit" onClick={DownloadAttendeeDetail}>Imprimer fiche detaillée
                                        </Button>

                                    </div>

                                </div>}

                                */}
                                {/*<div className="div_row" style={{ "border": "None" }}>
                                    <hr className="hr_break" />
                                </div>

                                <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                    Attestation

                                </div>
                                <div className="div_row" style={{ "border": "None" }}>
                                    {detailuser_date_certification && <div className="session_caract">
                                        Attestation delivrée le
                                        <DatePicker
                                            name="certif_date"
                                            id="certif_date"
                                            selected={detailuser_date_certification}

                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'


                                        />
                                    </div>}

                                    {!detailuser_date_certification && <div className="session_caract">
                                        Attestation NON délivrée encore
                                        <nav style={{ "visibility": "hidden" }}> <DatePicker
                                            name="certif_date"
                                            id="certif_date"
                                            selected={detailuser_date_certification}

                                            dateFormat="dd/MM/yyyy"
                                            className="disabled_style"
                                            locale='fr-FR'
                                            style={{ "visibility": "hidden" }}

                                        />
                                        </nav>
                                    </div>}

                                </div>
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>



                                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>


                                        {String(SendAttendeeAttestation_api) === "true" &&
                                            <div className="div_row okUpdateData" style={{ "textAlign": "center" }}>
                                                L'attestation a été bien envoyée par email
                                            </div>}

                                        {String(SendAttendeeAttestation_api) === "false" &&
                                            <div className="div_row koUpdateData" style={{ "textAlign": "center" }}>
                                                {SendAttendeeAttestation_message}
                                            </div>}


                                        {!detailuser_date_certification && <Button variant="contained" className="bton_enreg" onClick={SendAttendeeAttestation}>Delivrer l'attestation de formation
                                        </Button>}
                                    </div>

                                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                        <Button variant="contained" className="bton_edit" onClick={DownloadAttendeeAttestation}>Imprimer l'attestation de formation
                                        </Button>




                                    </div>

                                </div>}
                                        */}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>


                            </div>


                        </div>

                    </div>}

                    {String(GetAttendee_api) === "false" && <div className="div_row koUpdateData">
                        {GetAttendee_message}
                    </div>}


                </div>}



                {String(submenu) === "piece_jointe" && String(addOneParticipant) !== "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>

                    <div>
                        <div className="div_row" style={{ "border": "None" }}>
                            <hr className="hr_break" />
                        </div>

                        <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                            Pièces jointes

                        </div>

                        <div className="div_row" style={{ "padding": "5px" }}>

                            <div style={{ "fontSize": "12px" }}>
                                <label htmlFor="upload-photo">
                                    <input
                                        style={{ display: "none" }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={sessions_file_change_1}
                                    />

                                    <Fab
                                        color="secondary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        variant="extended"
                                    >
                                        <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                    </Fab>

                                </label>
                            </div>
                            {file_1_name && file_1_name.name &&
                                <nav style={{ "fontSize": "12px" }}>
                                    <div className="session_caract">Nom du fichier <br />
                                        <TextField
                                            name="file_to_download_type_name"
                                            id="file_to_download_type_name"

                                            InputLabelProps={{
                                                shrink: true,
                                            }}

                                            disabled={false}
                                            className="disabled_style enable_style"
                                            value={p_detail_one_file_to_download_type_name}
                                            onChange={(e) => {
                                                setp_detail_one_file_to_download_type_name(e.target.value);

                                            }}
                                        />

                                    </div>
                                    <br />
                                    <br />
                                    Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                        onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                                </nav>}

                            {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                            {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                        </div>


                        {file_1_name && file_1_name.name && <div className="div_row">

                            <div className="div_row_gauche">
                                <Button variant="contained" onClick={Record_All_PJ}
                                    startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                            </div>


                        </div>}

                        {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                            <div className="div_row">
                                Liste des pièces jointes <br />
                                <div className="div_row">
                                    {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                        <div className="div_row_list_pj"  >
                                            <nav style={{ "color": "green", "cursor": "pointer" }} onClick={Download_one_attached_document} name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}> Télécharger &nbsp;<i> {(JSON.parse(val).file_business_object)}</i> </nav> <br />
                                            <Popup
                                                trigger={
                                                    <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                                }
                                                modal
                                                nested
                                                position="center center"
                                            >
                                                {close => (
                                                    <div>
                                                        <button className="gest_close" onClick={close}>
                                                            &times;
                                                        </button>
                                                        <div className="gest_header"> MySy Information </div>
                                                        <div className="gest_content">
                                                            {' '}

                                                            En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                        </div>
                                                        <div className="gest_actions">
                                                            <div style={{ "width": "45%", "float": "left" }}>
                                                                <button className="gest_bton_popup" onClick={(event) => {

                                                                    Delete_one_attached_document(event);
                                                                    close();
                                                                }}
                                                                    name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                                > Valider </button>

                                                            </div>
                                                            <div style={{ "width": "45%", "float": "right" }}>
                                                                <button
                                                                    className="gest_bton_popup"
                                                                    onClick={() => {
                                                                        //console.log('modal closed ');
                                                                        close();
                                                                    }}
                                                                >
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>}


                    </div>
                </div>}


                {String(submenu) === "historique" && String(display_detail_historique) === "1" && String(addOneParticipant) !== "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>

                    <Module_Historique_Action related_collection="inscription"
                        related_collection_recid={selected_id}
                        read_access={props.read_access}
                        write_access={props.write_access} />
                </div>}

                {String(props.formation_initiale) === "1" && String(submenu) === "ue" && String(addOneParticipant) !== "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>

                    <h3> Gestion des UE d'un inscrit </h3>
                    <div className="div_row">

                        <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                            {New_Get_List_UE_From_Class_Id_result && New_Get_List_UE_From_Class_Id_result.length > 0 &&

                                <Autocomplete
                                    multiple
                                    name="detail_ue"
                                    id="detail_ue"
                                    //className="disabled_style"
                                    disablePortal
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}

                                    options={New_Get_List_UE_From_Class_Id_result}
                                    defaultValue={New_Get_List_UE_From_Class_Id_result.filter((data) => {
                                        if (tab_inscrit_ue_id.includes(String(data._id))) {

                                            return data;
                                        }
                                    })}
                                    //defaultValue={[New_Get_List_UE_From_Class_Id_result[0], New_Get_List_UE_From_Class_Id_result[2],]}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    // style={{ width: 500 }}

                                    onChange={(event, value) => {
                                        setinscription_list_ue(value)
                                        setueChanged("1");
                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une UE "
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                            }

                        </div>


                        <div className="session_caract"> <b>Evaluation  </b> <br />
                            {New_Getall_Class_List_Evalution_result && New_Getall_Class_List_Evalution_result.length > 0 &&

                                <Autocomplete
                                    multiple
                                    name="detail_ue"
                                    id="detail_ue"
                                    //className="disabled_style"
                                    disablePortal
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}

                                    options={New_Getall_Class_List_Evalution_result}
                                    defaultValue={New_Getall_Class_List_Evalution_result.filter((data) => {


                                        for (let i = 0; i < New_Get_Inscrit_List_Evaluation_Par_UE_result.length; i++) {
                                            //console.log(" lecture de New_Get_Inscrit_List_Evaluation_Par_UE_result[i] = ", New_Get_Inscrit_List_Evaluation_Par_UE_result[i])

                                            if (data.class_ue_id && String(data.class_ue_id) !== "" && data.class_ue_id === New_Get_Inscrit_List_Evaluation_Par_UE_result[i].class_eu_id &&
                                                data.type_evaluation_id === New_Get_Inscrit_List_Evaluation_Par_UE_result[i].type_eval__id
                                            ) {
                                                //console.log(" GAGNEEEE ");
                                                return data;
                                            }
                                        }
                                    })}

                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    // style={{ width: 500 }}

                                    onChange={(event, value) => {

                                        setinscription_list_ue_eval(value)
                                        setueChanged("1");

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir un type d'évaluation "
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />

                            }

                        </div>

                    </div>
                    <div className="div_row">
                        {ueChanged && String(ueChanged) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}

                        {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>



                            {ueChanged && String(ueChanged) === "1" && <div className="div_row">

                                <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                                    <Button variant="contained" className="bton_enreg" onClick={Update_UE_and_Evaluation}>Enregistrer les modifications
                                    </Button>
                                </div>


                                <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                                    <Button variant="contained" className="bton_annule" onClick={annule_modif_UE_and_Eval}>Annuler les modifications
                                    </Button>
                                </div>

                            </div>}

                        </div>}


                    </div>

                </div>}

                {String(props.formation_initiale) === "1" && String(submenu) === "notes" && String(display_notes) === "1" && String(addOneParticipant) !== "1" && <div className="div_row" style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>

                    <h3> Les notes de l'inscrit </h3>
                    <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                        <Button className="bton_emarge" onClick={(event) => {
                            Export_Inscrit_Notes();
                        }}>Exporter (excel)</Button>
                    </div>

                    <div className="session_data">
                        <div style={{ "border": "None", paddingRight: '10px' }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',


                                        /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                            "& .MuiDataGrid-row": {
                                                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                            }
                                        },*/
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },


                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        },
                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection

                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_notes(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_session_From_Line(newSelectionModel);*/
                                            if (newSelectionModel.length !== 1) {

                                            }
                                        }}
                                        selectionModel={selectionModel_notes}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Get_Inscrit_Notes_Of_Evaluations_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                evaluation_id: JSON.parse(item).evaluation_id,
                                                inscription_id: JSON.parse(item).inscription_id,
                                                note_evaluation_id: JSON.parse(item).note_evaluation_id,
                                                class_eu_id: JSON.parse(item).class_eu_id,
                                                type_eval_id: JSON.parse(item).type_eval_id,
                                                class_ue_code: JSON.parse(item).class_ue_code,
                                                class_ue_titre: JSON.parse(item).class_ue_titre,
                                                note_evaluation_code: JSON.parse(item).note_evaluation_code,
                                                eval_date_heure_debut: JSON.parse(item).eval_date_heure_debut,
                                                eval_date_heure_fin: JSON.parse(item).eval_date_heure_fin,
                                                note: JSON.parse(item).note,

                                            }
                                        ))}

                                        columns={columns_notes}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {

                                        }}

                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {
                                            //field === 'distantiel'
                                            if (params.field === 'distantiel' && String(params.value) === "1") {
                                                return 'cell--distantiel';
                                            }
                                            if (params.field === "presentiel" && String(params.value) == "1") {
                                                return 'cell--presentiel';
                                            }


                                            //field === "statut"
                                            if (params.field === "status" && String(params.value) == "0") {
                                                return 'cell--statut--preinscrit';
                                            }

                                            if (params.field === "status" && String(params.value) == "1") {
                                                return 'cell--statut--inscrit';
                                            }

                                            if (params.field === "status" && String(params.value) == "-1") {
                                                return 'cell--statut--annule';
                                            }

                                        }}
                                        getRowClassName={(params) => {
                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {

                                                return 'line--statut--selected';
                                            }

                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }

                                        }}
                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}

                                    />
                                </Box>
                                <br />

                            </div>


                        </div>

                    </div>

                </div>}



            </div>

        </div >
    );
}

export default DisplayPartnerStagiaires;

