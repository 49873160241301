import React, { useState, useEffect } from "react";
import UpdateUserInfo from "../components/UpdateUserInfo";
import bannerimg from "../mysy_img/MYSY_banner_compte.png";
import profileimg from "../mysy_img/MYSY-profil-2.png";
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import UserHistoryRecherche from "../components/UserHistoryRecherche";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import axios from "axios";
import { getCookie, removeCookie } from 'react-use-cookie';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";

function Account() {

    const [tempo, settempo] = useState(".");
    setInterval(check, 2000);


    window.setTimeout(function() {
        window.location.href = process.env.REACT_APP_BASE_URL
    }, 7000);

    
    function check() {
        var val = tempo+" .";
        //alert(" val = "+val);
        settempo(val);
        
      }


    return (
        <div className="accountuseractivated">
 
            <Helmet>
                <title>Creation compte utilisateur</title>
                <meta name="description" content="creation utisateur formation connexion" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Creation compte utilisateur" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/create_account"} />
            </Helmet>
            <Header />

            <div className="div_mobile">

                <div>

                  <p>Votre comtpe a été activé.
                  Merci d'avoir choisi MySy.
                  </p>


                  Vous allez être redirigé vers le moteur de recherche dans quelques secondes. {tempo}
                </div>
                <div className="pieddepage">
                    <Footer />
                </div>
            </div>

        </div>
    );
}

export default Account;


