import React, { useState, useEffect, useCallback, useRef } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client'
import moment from "moment";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Chart from 'react-apexcharts'
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';

function PartenairStat() {

	const today = new Date();
	const tomorrow = new Date(today);
	const [state, setState] = useState([
		{
			startDate: today.setDate(tomorrow.getDate() - 8),
			endDate: today.setDate(tomorrow.getDate()),
			key: "selection"
		}
	]);


	const [data_global, setdata_global] = useState([]);
	const stored_part = getCookie('tokenmysypart');



	/*

Recuperation de toutes les formation du partenaire
	*/

	const [allclassAPI, getallclassAPI] = useState();
	const [allclassAPI_Result, getallclassAPI_Result] = useState();

	function getAllPartnerClass() {

		var form = new FormData();
		form.append('token', stored_part);
		var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class_external_code/";

		axios.post(myurl, form).then(res => {

			if (String(res.data.status) != String("false")) {
				//console.log(" In getAllPartnerClass  res.data.status = " + res.data.status);
				//console.log(" In getAllPartnerClass  res.data.message = " + res.data.message);
				getallclassAPI("true");

				if (String(res.data.status) === "true") {
					getallclassAPI_Result(res.data.message);
				}


			}
			else {
				//console.log(" In getAllPartnerClass  res.data.status = " + res.data.status);
				//console.log(" In getAllPartnerClass  res.data.message = " + res.data.message);
				getallclassAPI("false");

			}


		}).catch((error) => {
			console.warn('Not good man :( mysearchtext = ');
			getallclassAPI("false");

		})


	}

	function getStatData() {
		var form = new FormData();
		const ch_array = [];
		const ch_categories = [];

		var ch_data2 = "";


		var date_start = ""
		var date_end = ""

		if (state[0].startDate) {
			//console.log("Start = " + state[0].startDate);
			//console.log("Start = " + moment(state[0].startDate).format('YYYY-MM-DD'));
			date_start = moment(state[0].startDate).format('YYYY-MM-DD');
		}
		if (state[0].endDate) {
			//console.log("endDate = " + state[0].endDate);
			//console.log("END = " + moment(state[0].endDate).format('YYYY-MM-DD'));
			date_end = moment(state[0].endDate).format('YYYY-MM-DD');
		}

		var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetStat_class_view/";
		form.append("date_start", date_start);
		form.append("date_end", date_end);
		form.append('token', stored_part);

		//alert("myurl = "+myurl);
		axios.post(myurl, form).then(res => {

			if (String(res.data.status) != String("false")) {
				//console.log(" In test  res.data.status = " + res.data.status);
				//console.log(" In test  res.data.message = " + res.data.message);
				res.data.message.map(obj => {

					if (JSON.parse(obj).Date_view) {
						//console.log("Date_view " + JSON.parse(obj).Date_view + " nb_view = " + JSON.parse(obj).nb_view);
						ch_array.push(parseInt(JSON.parse(obj).nb_view));
						ch_categories.push(JSON.parse(obj).Date_view);
					} else if (JSON.parse(obj).data) {

						//ch_data2 = String(obj.data);
						//console.log("data  ok " + JSON.parse(obj).data)

						ch_data2 = JSON.parse(obj).data;
					}

				});


				//console.log(" ch_array = " + ch_array);
				//console.log(" ch_data2 = " + ch_data2);

				var new_data2 = ch_data2.split(',').map(Number);
				new_data2.push(123);
				//console.log(" new_data2 b= " + new_data2);

				setglobal_view_data(ch_array);
				//console.log(global_view_data);
				setglobal_view_categories(ch_categories);
				setglobal_view_data_v2(new_data2);
			}
			else {
				//console.log(" In test  res.data.status = " + res.data.status);
				//console.log(" In test  res.data.message = " + res.data.message);

			}


		}).catch((error) => {
			console.warn('Not good man :( mysearchtext = ', error);

			//setmyApimyApiMessage("")
		})
	}

	const [detail_view_categories, setdetail_view_categories] = useState();
	const detail_series = detail_view_categories;

	function Get_Top5_Stat() {
		var form = new FormData();

		var date_start = ""
		var date_end = ""

		if (state[0].startDate) {
			//console.log("Start = " + state[0].startDate);
			//console.log("Start = " + moment(state[0].startDate).format('YYYY-MM-DD'));
			date_start = moment(state[0].startDate).format('YYYY-MM-DD');
		}
		if (state[0].endDate) {
			//console.log("endDate = " + state[0].endDate);
			//console.log("END = " + moment(state[0].endDate).format('YYYY-MM-DD'));
			date_end = moment(state[0].endDate).format('YYYY-MM-DD');
		}

		var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetStat_class_view_topX/";
		form.append("date_start", date_start);
		form.append("date_end", date_end);
		form.append('token', stored_part);
		form.append('topX', '8');

		//alert("myurl = "+myurl);

		var top5training = [];

		axios.post(myurl, form).then(res => {

			if (String(res.data.status) != String("false")) {
				//console.log(" In test  res.data.status = " + res.data.status);
				//console.log(" In test  res.data.message = " + res.data.message);


				res.data.message.map(obj => {
					if (JSON.parse(obj).name1) {
						//console.log(" ---name_serie = " + JSON.parse(obj).name1 + " data_series = " + JSON.parse(obj).data1);
						top5training.push(JSON.parse(obj).name1);
						var ch_data2 = JSON.parse(obj).data1;

						let new_data2 = ch_data2.map(i => Number(i));
						setdetail_view_data1(new_data2);


						var nom = JSON.parse(obj).name1;
						setdetail_view_data1_name(nom);
						//console.log("new_data2 la = ", ch_data2);
						//console.log("result = ", new_data2);

					}

					if (JSON.parse(obj).name2) {
						//console.log(" ---name_serie = " + JSON.parse(obj).name2 + " data_series = " + JSON.parse(obj).data2);
						top5training.push(JSON.parse(obj).name2);
						var ch_data2 = JSON.parse(obj).data2;
						let new_data2 = ch_data2.map(i => Number(i));
						setdetail_view_data2(new_data2);

						var nom = JSON.parse(obj).name2;
						setdetail_view_data2_name(nom);
					}

					if (JSON.parse(obj).name3) {
						//console.log(" ---name_serie = " + JSON.parse(obj).name3 + " data_series = " + JSON.parse(obj).data3);
						top5training.push(JSON.parse(obj).name3);
						var ch_data2 = JSON.parse(obj).data3;
						let new_data2 = ch_data2.map(i => Number(i));
						setdetail_view_data3(new_data2);

						var nom = JSON.parse(obj).name3;
						setdetail_view_data3_name(nom);
					}

					if (JSON.parse(obj).name4) {
						//console.log(" ---name_serie = " + JSON.parse(obj).name4 + " data_series = " + JSON.parse(obj).data4);
						top5training.push(JSON.parse(obj).name4);
						var ch_data2 = JSON.parse(obj).data4;
						let new_data2 = ch_data2.map(i => Number(i));
						setdetail_view_data4(new_data2);

						var nom = JSON.parse(obj).name4;
						setdetail_view_data4_name(nom);
					}

					if (JSON.parse(obj).categories) {
						//console.log(" ---categories = " + JSON.parse(obj).categories);
						var ch_data2 = JSON.parse(obj).categories;
						var new_data2 = ch_data2.split(',');
						setdetail_view_categorie(new_data2);
					}


				});

				settopX_formation(top5training);

			}
			else {
				//console.log(" In test  res.data.status = " + res.data.status);
				//console.log(" In test  res.data.message = " + res.data.message);

			}


		}).catch((error) => {
			console.warn('Not good man :( mysearchtext = ');

			//setmyApimyApiMessage("")
		})
	}


	const [topX_formation, settopX_formation] = useState([]);

	const [detail_view_categorie, setdetail_view_categorie] = useState([]);

	const [detail_view_data1, setdetail_view_data1] = useState([]);
	const [detail_view_data1_name, setdetail_view_data1_name] = useState([]);

	const [detail_view_data2, setdetail_view_data2] = useState([]);
	const [detail_view_data2_name, setdetail_view_data2_name] = useState([]);

	const [detail_view_data3, setdetail_view_data3] = useState([]);
	const [detail_view_data3_name, setdetail_view_data3_name] = useState([]);

	const [detail_view_data4, setdetail_view_data4] = useState([]);
	const [detail_view_data4_name, setdetail_view_data4_name] = useState([]);



	var series_detail = [
		{
			name: detail_view_data1_name,
			data: detail_view_data1
		},

		{
			name: detail_view_data2_name,
			data: detail_view_data2
		},

		{
			name: detail_view_data3_name,
			data: detail_view_data3
		},

		{
			name: detail_view_data4_name,
			data: detail_view_data4
		},
	]

	const options_detail = {
		chart: {
			id: 'apexchart-example'
		},
		xaxis: {
			categories: detail_view_categorie //['2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07', '2022-09-06']
		},
		colors: ['#CB4335', '#1A5276', '#28B463', '#6E2C00', '#1B2631']
	}

	const [istrainingselected, setistrainingselected] = useState();
	function DisplayTranningStat() {
		var mytraining = ""
		if (document.getElementById("selectedtraining") && document.getElementById("selectedtraining").value != "")
			mytraining = document.getElementById("selectedtraining").value;
		setistrainingselected("1");

		//alert(" recuperation des stat de " + mytraining);


		var form = new FormData();

		var date_start = ""
		var date_end = ""

		if (state[0].startDate) {
			//console.log("Start = " + state[0].startDate);
			//console.log("Start = " + moment(state[0].startDate).format('YYYY-MM-DD'));
			date_start = moment(state[0].startDate).format('YYYY-MM-DD');
		}
		if (state[0].endDate) {
			//console.log("endDate = " + state[0].endDate);
			//console.log("END = " + moment(state[0].endDate).format('YYYY-MM-DD'));
			date_end = moment(state[0].endDate).format('YYYY-MM-DD');
		}

		var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetStat_class_by_internal_url/";
		form.append("date_start", date_start);
		form.append("date_end", date_end);
		form.append('token', stored_part);
		form.append('internal_url', mytraining);

		//alert("myurl = "+myurl);


		axios.post(myurl, form).then(res => {

			if (String(res.data.status) != String("false")) {
				//console.log(" In DisplayTranningStat  res.data.status = " + res.data.status);
				//console.log(" In DisplayTranningStat  res.data.message = " + res.data.message);


				res.data.message.map(obj => {
					if (JSON.parse(obj).name1) {
						//console.log(" ---name_serie = " + JSON.parse(obj).name1 + " data_series = " + JSON.parse(obj).data1);
						var ch_data2 = JSON.parse(obj).data1;

						let new_data2 = ch_data2.map(i => Number(i));
						setdetail_view_data1_selected_training(new_data2);


						var nom = JSON.parse(obj).name1;
						setdetail_view_data1_name_selected_training(nom);
						//console.log("new_data2 a = ", ch_data2);
						//console.log("result = ", new_data2);

					}


					if (JSON.parse(obj).categories) {
						//console.log(" ---categories = " + JSON.parse(obj).categories);
						var ch_data2 = JSON.parse(obj).categories;
						var new_data2 = ch_data2.split(',');
						setdetail_view_categorie_selected_training(new_data2);
					}
				});
			}
			else {
				//console.log(" In DisplayTranningStat  res.data.status = " + res.data.status);
				//console.log(" In DisplayTranningStat  res.data.message = " + res.data.message);

			}


		}).catch((error) => {
			console.warn('Not good man :( DisplayTranningStat = ');

			//setmyApimyApiMessage("")
		})
	}

	const [detail_view_data1_selected_training, setdetail_view_data1_selected_training] = useState([]);
	const [detail_view_data1_name_selected_training, setdetail_view_data1_name_selected_training] = useState([]);

	var series_detail_selected_training = [
		{
			name: detail_view_data1_name_selected_training,
			data: detail_view_data1_selected_training
		},


	]
	const [detail_view_categorie_selected_training, setdetail_view_categorie_selected_training] = useState([]);
	const options_detail_selected_training = {
		chart: {
			id: 'apexchart-example'
		},
		xaxis: {
			categories: detail_view_categorie_selected_training //['2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07', '2022-09-06']
		},
		colors: ['#CB4335']
	}

	const [global_view_categories, setglobal_view_categories] = useState([]);
	const options_global_view = {
		chart: {
			id: 'apexchart-example'
		},
		xaxis: {
			categories: global_view_categories
		},
		colors: ['#17A8F5', '#A93226']
	}


	const [global_view_data, setglobal_view_data] = useState([]);
	const [global_view_data_v2, setglobal_view_data_v2] = useState([]);

	const series_Gobal = [
		{
			name: 'global_view',
			data: global_view_data
		},
	]

	useEffect(() => {
		getAllPartnerClass();
		getStatData();
		Get_Top5_Stat();
	}, []);





	return (

		<div className="partenairstat">

			<div className="div_row">
				<div className="div_row_gauche">

					<h5>Choisir une periode</h5>
					<DateRange
						onChange={item => setState([item.selection])}
						moveRangeOnFirstSelection={false}
						ranges={state}
					/>
					<br />
					<Button onClick={Get_Top5_Stat} className="btn_enreg"> Mettre à jour </Button> <br />

					{/*<Button onClick={getStatData} className="btn_enreg"> Recup Stat info</Button> <br /> */}

				</div>

				<div className="div_row_droite">

				</div>

			</div>

			<div className="div_row">
				<div className="titre1"> La tendance de votre entreprise </div>


			


				{global_view_data && global_view_data.length > 3 && <div className="row">

				
					<div className="section col-md-6">
						<h3 className="section-title">Graphique en ligne  (Toute formation)</h3>
						<div className="section-content">
							<Chart options={options_global_view} series={series_Gobal} type="line" />
						</div>
					</div>

					<div className="section col-md-6">
						<h3 className="section-title">
							Diagramme à bandes  (Toute formation) </h3>
						<div className="section-content">
							<Chart options={options_global_view} series={series_Gobal} type="bar" />
						</div>
					</div>

				</div>
				}

			</div>

			<div className="div_row">
				<div className="titre1"> Le top 5  de vos formation sur la periode</div>
				{topX_formation && <div className="row">

					<div className="section col-md-6">
						<h5> Liste des formations concernées : </h5>
						{topX_formation &&
							topX_formation.map((val) => (
								<li style={{ "textAlign": "left", "marginLeft": "5rem" }}>
									{val}

								</li>

							))}

					</div>

					<div className="section col-md-6">
						<h3 className="section-title"> Histogramme </h3>
						<div className="section-content">
							<Chart options={options_detail} series={series_detail} type="line" />
						</div>
					</div>


				</div>}

			</div>



			{String(allclassAPI) === "true" && <div className="div_row">
				<div className="titre1"> Les detail d'une formation </div>
				<h5> Selectionnez une formation </h5>
				<div className="div_row_gauche">
					<select id={"selectedtraining"} name={"selectedtraining"}
						onChange={DisplayTranningStat}>
						<option selected value="">Choisir une formation</option>

						{allclassAPI_Result &&
							allclassAPI_Result.map((val) => (

								<option value={JSON.parse(val).internal_url}>{JSON.parse(val).external_code} = &gt; {(JSON.parse(val).internal_url).substring(0, 25)}</option>

							))}
					</select>
				</div>

				<div className="div_row_droite">
					{istrainingselected && String(istrainingselected) === "1" && <div className="section col-md-6"> Formation selectionnée :
						<h3 className="section-title">{document.getElementById("selectedtraining").value} </h3>
						<div className="section-content">
							<Chart options={options_detail_selected_training} series={series_detail_selected_training} type="line" />
						</div>
					</div>}
				</div>

			</div>
			}
		</div>
	);
}



export default PartenairStat;

