
import Navigation from "../components/Navigation";
import Footer from "./footer";

const recherche = () => {


  return (
    <div>
    <Navigation />
    <div>

        </div>

        </div>

  )
}


export default recherche;
