import RechercheArticle from "../components/RechercheArticles";
import RechercheArticle_new_v2 from "../components/RechercheArticles_new_v2";
import Navigation from "../components/Navigation";

export default function displaydetailarticle() {
    return (
        <div>
           <div >
            <Navigation />
            </div>
         
            <RechercheArticle_new_v2 />
        
            
        </div>
    );
}