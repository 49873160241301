import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';

import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Global } from "recharts";

import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
const Partner_Prix_Achat = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_order_lines, setrows_order_lines] = useState([]);
    const [selectionModel_order_lines, setselectionModel_order_lines] = React.useState([]);

    const [isLoading, setLoading] = useState();

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const filters = [
        { value: 'code_session', label: 'Code Session' },
        { value: 'class_title', label: 'Titre Formation' },
        { value: 'certif', label: 'Certification' }
    ]


    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code_groupe_prix', headerName: 'Code', hide: false, editable: false, flex: 1, minWidth: 150, },
       

        { field: 'date_debut', headerName: 'Début', flex: 1, minWidth: 150, hide: false, editable: false },
        { field: 'date_fin', headerName: 'Fin', flex: 1, minWidth: 150,  },
        { field: 'periodicite', headerName: 'Périodicité', flex: 1, minWidth: 150, hide: false, editable: false },
        { field: 'fournisseur', headerName: 'Fournisseur', flex: 1, minWidth: 150, hide: true, editable: false },
        { field: 'prix', headerName: 'prix', flex: 1, minWidth: 150,  editable: false },


        {
            field: "delete", headerName: 'Supprimer', flex: 1, minWidth: 150,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le groupe de prix sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Pruch_Price_Grp(event, cellValues);

                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {

                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]


    const [Get_Partner_Purchase_Prices_api, setGet_Partner_Purchase_Prices_api] = useState();
    const [Get_Partner_Purchase_Prices_message, setGet_Partner_Purchase_Prices_message] = useState();
    const [Get_Partner_Purchase_Prices_result, setGet_Partner_Purchase_Prices_result] = useState([]);
    function Get_Partner_Purchase_Prices(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Group_Purchase_Price_List/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Partner_Purchase_Prices  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Purchase_Prices  res.data.message r_class = " + res.data.message);
                setGet_Partner_Purchase_Prices_api("true");
                setGet_Partner_Purchase_Prices_result(res.data.message);
            }
            else {
                setGet_Partner_Purchase_Prices_api("false");
                setGet_Partner_Purchase_Prices_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Purchase_Prices = ', error);
            setGet_Partner_Purchase_Prices_api("false");
            alert(" Impossible de recuperer la liste des prix d'achat ");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        Get_Partner_Purchase_Prices();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });


    }, [])

    const [selected_pruchase_price_id, setselected_pruchase_price_id] = useState();
    const [gridline_id, setgridline_id] = useState("");
    const [p_purchase_price_code, setp_purchase_price_code] = useState("");
    const [p_purchase_price_fournisseur, setp_purchase_price_fournisseur] = useState("");
    const [p_purchase_price_prix, setp_purchase_price_prix] = useState("");
    const [p_purchase_price_periodicite, setp_purchase_price_periodicite] = useState("");

    const [p_purchase_price_date_debut, setp_purchase_price_date_debut] = useState(new Date().toLocaleDateString('fr-FR'));
    const [p_purchase_price_date_fin, setp_purchase_price_date_fin] = useState(date_today_90j.toLocaleDateString('fr-FR'));



    const [Dialog_pruch_price_open, setDialog_pruch_price_open] = React.useState(false);

    function func_Dialog_pruch_price_open(message) {
        setDialog_pruch_price_open(true);
    }

    const Dialog_pruch_price_open_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_pruch_price_handleClose_buton = () => {

        setp_purchase_price_code();
        setp_purchase_price_fournisseur();
        setp_purchase_price_prix();
        setp_purchase_price_periodicite();
        setp_purchase_price_date_debut(new Date().toLocaleDateString('fr-FR'));
        setp_purchase_price_date_fin(date_today_90j.toLocaleDateString('fr-FR'));
        setselected_pruchase_price_id('');
        setdialog_data_changed('');
        setDialog_pruch_price_open(false);
    };

    const Dialog_pruch_price_handleClose = () => {
        setDialog_pruch_price_open(false);
    };


    function handleClick_edit_groupe_purch_price_From_Line(selected_row_id) {

        var line = JSON.parse(Get_Partner_Purchase_Prices_result[selected_row_id]);

        setselected_pruchase_price_id(line._id);
        setp_purchase_price_code(line.code_groupe_prix);
        setp_purchase_price_fournisseur(line.fournisseur);
        setp_purchase_price_prix(line.prix);
        setp_purchase_price_periodicite(line.periodicite);
        setp_purchase_price_date_debut(line.date_debut);
        setp_purchase_price_date_fin(line.date_fin);
        setDialog_pruch_price_open(true);
    }


    const [Add_Update_Grp_Purchase_Price_api, setAdd_Update_Grp_Purchase_Price_api] = useState();
    const [Add_Update_Grp_Purchase_Price_message, setAdd_Update_Grp_Purchase_Price_message] = useState();
    const [Add_Update_Grp_Purchase_Price_result, setAdd_Update_Grp_Purchase_Price_result] = useState();
    function Add_Update_Grp_Purchase_Price(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = "";

        if (String(selected_pruchase_price_id).trim().length > 2) {
            // Il s'agit d'une mise à jour
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Group_Purchase_Price/";
            form.append("_id", selected_pruchase_price_id);
            form.append("code_groupe_prix", p_purchase_price_code);
            form.append("periodicite", p_purchase_price_periodicite);
            form.append("date_debut", p_purchase_price_date_debut);
            form.append("date_fin", p_purchase_price_date_fin);
            form.append("fournisseur", p_purchase_price_fournisseur);
            form.append("prix", p_purchase_price_prix);



        } else {
            // Il s'agit d'une creation 
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Group_Purchase_Price/";
            form.append("code_groupe_prix", p_purchase_price_code);
            form.append("periodicite", p_purchase_price_periodicite);
            form.append("date_debut", p_purchase_price_date_debut);
            form.append("date_fin", p_purchase_price_date_fin);
            form.append("fournisseur", p_purchase_price_fournisseur);
            form.append("prix", p_purchase_price_prix);
        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_Update_Grp_Purchase_Price  res.data.status = " + res.data.status);
                //console.log(" In Add_Update_Grp_Purchase_Price  res.data.message r_class = " + res.data.message);
                setAdd_Update_Grp_Purchase_Price_api("true");
                setAdd_Update_Grp_Purchase_Price_result(res.data.message);
                Dialog_pruch_price_handleClose_buton();
                Get_Partner_Purchase_Prices();
                setSelectionModel([]);
                setgridline_id();
                alert(res.data.message);
            }
            else {
                setAdd_Update_Grp_Purchase_Price_api("false");
                setAdd_Update_Grp_Purchase_Price_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Add_Update_Grp_Purchase_Price = ', error);
            setAdd_Update_Grp_Purchase_Price_api("false");
            alert(" Impossible d'ajouter / mettre à jour le groupe de prix d'achat");
            //setmyApimyApiMessage("")
        })
    }


    const [handleClick_delete_Pruch_Price_Grp_api, sethandleClick_delete_Pruch_Price_Grp_api] = useState();
    const [handleClick_delete_Pruch_Price_Grp_message, sethandleClick_delete_Pruch_Price_Grp_message] = useState();
    const [handleClick_delete_Pruch_Price_Grp_result, sethandleClick_delete_Pruch_Price_Grp_result] = useState();
    function handleClick_delete_Pruch_Price_Grp(event, cellValues) {

        var group_prix = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", group_prix);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Group_Purchase_Price/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Pruch_Price_Grp  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Pruch_Price_Grp  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Pruch_Price_Grp_api("true");
                sethandleClick_delete_Pruch_Price_Grp_result(res.data.message);
                Dialog_pruch_price_handleClose_buton();
                setSelectionModel([]);
                setgridline_id();
                Get_Partner_Purchase_Prices();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_Pruch_Price_Grp_api("false");
                sethandleClick_delete_Pruch_Price_Grp_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Pruch_Price_Grp = ', error);
            sethandleClick_delete_Pruch_Price_Grp_api("false");
            alert(" Impossible de supprimer le groupe de prix d'achat");
            //setmyApimyApiMessage("")
        })
    }

    
    const [dialog_data_changed, setdialog_data_changed] = useState();

    return (

        <div className="partner_prix_achat">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}



            <Dialog
                open={Dialog_pruch_price_open}
                onClose={Dialog_pruch_price_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle>Groupe prix achat </DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="session_caract_Dialog" > Code groupe de prix
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_code_groupe_prix"
                            id="event_dialog_code_groupe_prix"

                            fullWidth

                            value={p_purchase_price_code}
                            onChange={(e) => {
                                setp_purchase_price_code(e.target.value);
                                setdialog_data_changed("1");
                            }
                            }
                        />
                    </div>


                    <div className="session_caract_Dialog" > Fournisseur
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_fournisseur"
                            id="event_dialog_fournisseur"

                            fullWidth

                            value={p_purchase_price_fournisseur}
                            onChange={(e) => {
                                setp_purchase_price_fournisseur(e.target.value);
                                setdialog_data_changed("1");
                            }
                            }
                        />
                    </div>

                    <div className="session_caract_Dialog" > Périodicité
                    <Tooltip className="tooltip_css" id="tooltip_id_perodicite" />
                        <a data-tooltip-id="tooltip_id_perodicite" data-tooltip-html=" A quel péridicité est appliqué le montant HT <br/> Exemple : Ce montant est appliqué : <br/>
                        <li> Par heure ?</li>
                        <li> En fixe, quelque soit le nombre d'heure (ex : la correction des copies) </li>
                       
                         ">
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_periodocite"
                            id="event_dialog_periodocite"
                            select
                            fullWidth

                            value={p_purchase_price_periodicite}
                            onChange={(e) => {
                                setp_purchase_price_periodicite(e.target.value);
                                setdialog_data_changed("1");
                            }
                            }
                        >
                            <MenuItem value="fixe" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Fixe &nbsp;</MenuItem>
                            <MenuItem value="heure" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par heure &nbsp;</MenuItem>
                           {/*<MenuItem value="jour" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par jour &nbsp;</MenuItem>
                            <MenuItem value="mois" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par mois &nbsp;</MenuItem>
                            <MenuItem value="annee" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Par année &nbsp;</MenuItem>*/}


                        </TextField>
                        </a>
                    </div>


                    <div className="session_caract_Dialog" > Debut
                        <DatePicker
                            name="event_dialog_date_debut"
                            id="event_dialog_date_debut"

                            selected={new Date(moment(p_purchase_price_date_debut, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_purchase_price_date_debut(format(date, 'd/MM/yyyy'));
                                setdialog_data_changed("1");

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'

                        />

                    </div>


                    <div className="session_caract_Dialog" > Fin
                        <DatePicker
                            name="event_dialog_date_fin"
                            id="event_dialog_date_fin"
                            selected={new Date(moment(p_purchase_price_date_fin, "DD/MM/YYYY"))}
                            onChange={(date) => {

                                setp_purchase_price_date_fin(format(date, 'd/MM/yyyy'));
                                setdialog_data_changed("1");

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'
                        />

                    </div>

                    <div className="session_caract_Dialog" > Taux Horaire Brut (HT)
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_fournisseur"
                            id="event_dialog_fournisseur"
                            type="number"
                            step="any"
                            fullWidth

                            value={p_purchase_price_prix}
                            onChange={(e) => {
                                setp_purchase_price_prix(e.target.value);
                                setdialog_data_changed("1");
                            }
                            }
                        />
                    </div>

                    {String(dialog_data_changed) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center", "width": "100%" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}



                </DialogContent>


                <DialogActions>


                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(selected_pruchase_price_id).trim().length > 2 && <Button onClick={Add_Update_Grp_Purchase_Price} className="bton_enreg_dialog">Mettre à jour</Button>}
                            {String(selected_pruchase_price_id).trim() === "" && <Button onClick={Add_Update_Grp_Purchase_Price} className="bton_enreg_dialog">Ajouter</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_pruch_price_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            <h3> Vos prix d'achat </h3>

            <div>
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value)
                            }}
                        >

                            <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                            <MenuItem value="class_external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Code Formation (code externe) &nbsp;</MenuItem>
                            <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                            <MenuItem value="session_start_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date début session  &nbsp;</MenuItem>
                            <MenuItem value="session_end_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date fin session  &nbsp;</MenuItem>

                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}
                                options={filters}
                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value)
                                }}
                            >

                                <MenuItem value="code_session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Code Session &nbsp;&nbsp;</MenuItem>
                                <MenuItem value="class_external_code" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Code Formation (code externe) &nbsp;</MenuItem>
                                <MenuItem value="class_title" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Titre Formation &nbsp;</MenuItem>
                                <MenuItem value="session_start_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date début session  &nbsp;</MenuItem>
                                <MenuItem value="session_end_date" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Date fin session  &nbsp;</MenuItem>


                            </TextField>
                        </div>
                        <div className="div_row_droite texte_area_filter" >
                            {String(p_filtre2).length > 3 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>


                        {String(p_filtre2).length > 3 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}
                    </div>
                }


                {<div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={"Getall_TrainingSession"}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={"clean_all_filters"}>Annuler
                        </Button>

                    </div>

                </div>}

            </div>


            <div className="div_row" style={{ "paddingRight": "10px" }}>


                <div className="session_data">
                    <div style={{ "border": "None" }}>
                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_session_From_Line(newSelectionModel);
                                        if (newSelectionModel.length !== 1) {
                                            setaddOneParticipant();
                                            setdisplay_detail_insc();
                                            setselected_code_session();
                                            setsubmenu();
                                        }*/
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Get_Partner_Purchase_Prices_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            date_debut: JSON.parse(item).date_debut,
                                            date_fin: JSON.parse(item).date_fin,
                                            code_groupe_prix: JSON.parse(item).code_groupe_prix,
                                            periodicite: JSON.parse(item).periodicite,
                                            fournisseur: JSON.parse(item).fournisseur,
                                            prix: JSON.parse(item).prix,


                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        setgridline_id(newSelectionModel.row.id);
                                        setselected_pruchase_price_id(newSelectionModel.row._id);

                                        handleClick_edit_groupe_purch_price_From_Line(newSelectionModel.row.id);



                                    }}



                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}


                                />
                            </Box>
                            <br />

                        </div>
                    </div>
                </div>
            </div>
            <div className="div_row" style={{ "border": "None" }}>

                <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                    <Button variant="outlined" onClick={() => {

                        setp_purchase_price_fournisseur();
                        setp_purchase_price_prix();
                        setp_purchase_price_periodicite();
                        setp_purchase_price_date_debut(new Date().toLocaleDateString('fr-FR'));
                        setp_purchase_price_date_fin(date_today_90j.toLocaleDateString('fr-FR'));
                        setselected_pruchase_price_id('');
                        setdialog_data_changed('');

                        setDialog_pruch_price_open(true);


                    }}
                        className="detail_class_submenu bton_add_session"
                        id='menu_import_participant' name='menu_import_participant'>Ajout 1 Prix &nbsp;
                        <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                    </Button>
                </div>
            </div>

        </div >
    )
}


export default Partner_Prix_Achat;