import React, { useState, useEffect } from "react";
import axios from "axios";

import profileimg from "../mysy_img/MYSY-profil-2.png";
import TrainingForm from "./AddClassManual"
import PartnerTranings from "./DisplayPartnerTrainingsPagination";
import HebergementLms from "./HebergementLms";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import PartnerInfo from "./UpdatePartnerInfo";
import PartenairStat from "./PartenairStat";
import { Helmet } from "react-helmet";

import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { GiTeacher } from "react-icons/gi";
import Footer from "./Fotter";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useCookies } from "react-cookie";
import Header from "./Header";
import Facture from "./Facture";
import Abonnement from "./Abonnement";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import CheckOut from "./CheckOut";

import { FaHandPointRight } from "react-icons/fa";
import OrderConfirmation from "./OrderConfirmation";
import Partner_Client from "./Partner_Client";
import Pricing from "./Pricing";
import { MdAddShoppingCart } from "react-icons/md";
import {
    FcHome, FcBullish, FcAddDatabase, FcFolder,
    FcList, FcCurrencyExchange,
    FcFeedback, FcKey, FcVideoCall, FcSettings,
    FcBusinesswoman, FcServices, FcCollect, FcCandleSticks, FcKindle,
    FcFlowChart, FcReading, FcFinePrint, FcCancel, FcCalendar, FcOrgUnit,
    FcEngineering
} from "react-icons/fc";

import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { FcInspection } from "react-icons/fc";

import { PiStudent } from "react-icons/pi";

import { BiPurchaseTag } from "react-icons/bi";

import { BsTools } from "react-icons/bs";

import DisplayPartnerSession from "./DisplayPartnerSession";
import DisplayPartnerStagiaires from "./DisplayPartnerStagiaires";

import Employes from "./Employes";
import Materiels from "./Materiels";
import Partner_Commande from "./Partner_Commande";
import Config_Documents from "./Config_Document";
import Config_Documents_Super_Admin from "./Config_Document_Super_Admin";
import Factures_Client from "./Partner_Facture";
import Configuration_Technique from "./Partner_Config_Technique";
import Config_Champs_Personnalise from "./Config_Champs_Personnalise";
import Module_Agenda from "./Module_Agenda";
import Partner_Configuration_Session_Steps from "./Partner_Config_Session_Steps";
import Partner_Configuration_Jours_Travail from "./Partner_Config_Jours_Travail";
import Dashbord_Session from "./Dashbord_Session";
import Dashbord_Ressources_Humaines from "./Dashbord_Ressources_Humaines";
import Dashbord_Inscriptions from "./Dashbord_Inscription";
import Dashbord_Factures from "./Dashbord_Factures";
import Materiel_Salle from "./Materiel_Salle";
import CRM_Opportunite from "./Crm_Opportunite";
import Partner_Config_Formulaires from "./Partner_Config_Formulaires";
import { AiOutlineUserAdd } from "react-icons/ai";
import PartnerClientActivite from "./PartnerClient_Activite";


import Mon_Tableau_De_Bord from "./Mon_Tableau_De_Bord";
import Dashbord_Formation from "./Dashbord_Formation";
import Partner_Prix_Achat from "./Partner_Prix_Achat";
import Apprenant from "./Apprenant";
import { PiSlideshowFill, PiSlideshowThin } from "react-icons/pi";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ImWarning } from "react-icons/im";
//import { FcHome} from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Dropdown from 'rsuite/Dropdown';
//import 'rsuite/dist/rsuite.min.css';
import { AiOutlineDashboard } from "react-icons/ai";
import { FcPodiumWithAudience } from "react-icons/fc";

import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SchoolIcon from '@mui/icons-material/School';

import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { FcBusinessContact } from "react-icons/fc";
import { FcCamcorderPro } from "react-icons/fc";
import { FcVideoProjector } from "react-icons/fc";
import HandymanIcon from '@mui/icons-material/Handyman';
import HubIcon from '@mui/icons-material/Hub';
import EuroSharpIcon from '@mui/icons-material/EuroSharp';
import CameraOutdoorSharpIcon from '@mui/icons-material/CameraOutdoorSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DisplayPartnerUnite_Enseignement from "./DisplayPartnerUnite_Enseignement";
import Partner_Domaine_Categorie_Metier from "./Partner_Domaine_Categorie_Metier";
import Partner_Config_Ftion_Initiale from "./Partner_Config_Ftion_Initiale";
import Notes_Evaluation from "./Notes_Evaluation";
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import Groupe_Apprenant from "./Groupe_Apprenant";
import Groups2Icon from '@mui/icons-material/Groups2';
import { LiaProductHunt } from "react-icons/lia";
import Partner_Product_Service from "./Partner_Product_Service";
import Module_Email_Management from "./Module_Email_Management";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';




const Partner = (props) => {
    const { commingmenu, commingpack } = useParams();
    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);
    const stored_part = getCookie('tokenmysypart');
    const [parntername, setparntername] = useState("");
    const [parnter_user_employee, setparnter_user_employee] = useState("");
    const [parnternbformation, setparnternbformation] = useState("");
    const [selectedfilt1, setselectedfilt1] = useState("");
    const [selectedfilt2, setselectedfilt2] = useState("");
    const [selectedfilt3, setselectedfilt3] = useState("");
    const [excelimportmessage, setexcelimportmessage] = useState("");
    const [apiexcelimportmessage, setapiexcelimportmessage] = useState("");
    const [gotocheckout, setgotocheckout] = useState("");


    const [cookie_menu_gauche_reduit, setcookie_menu_gauche_reduit, removecookie_menu_gauche_reduit] = useCookies(['mysy_menu_gauche_reduit']);

    const theme = createTheme({
        palette: {
            primary: {
                light: '#F0E68C',
                main: '#F0E68C',
                dark: '#F0E68C',
                contrastText: '#fff',
            },
            secondary: {
                light: '#99ffcc',
                main: '#99ffcc',
                dark: '#ba000d',
                contrastText: '#000',
            },

            tercier: {
                light: '#dbff75',
                main: '#dbff75',
                dark: '#dbff75',
                contrastText: '#000',
            },

            quatro: {
                light: '#E08A8B',
                main: '#E08A8B',
                dark: '#E08A8B',
                contrastText: '#000',
            },

            cinqo: {
                light: '#D2691E',
                main: '#D2691E',
                dark: '#D2691E',
                contrastText: '#000',
            },
        },
    });

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 0,
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));


    const StyledBadge_mysy_color1 = styled(Badge)(({ }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 0,
            border: '2px solid #ffffff',
            padding: '0 4px',
            background: '#6a329f',
            color: "#ffffff"
        },
    }));


    const StyledBadge_mysy_red = styled(Badge)(({ }) => ({
        '& .MuiBadge-badge': {
            right: 0,
            top: 0,
            border: '2px solid ',
            padding: '0 4px',
            background: '#f44336',
            color: "#ffffff",
        },
    }));



    // Constante permet de savoir a fonction du menu on a faire
    const [menu, setmenu] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);

    const [partnerPackService, setpartnerPackService] = useState("");

    const [isimgSelected, setisimgSelected] = useState(false);
    const [isimgSelectedfile, setisimgSelectedfile] = useState(false);
    const [userimgprofil, setuserimgprofil] = useState();

    const [partnerstatus, setpartnerstatus] = useState();
    const [refesh, setrefesh] = useState("init");

    const [firstConnexion, setfirstConnexion] = useState("");
    const [part_is_partner_admin_account, setpart_is_partner_admin_account] = useState("");

    const { action, orderid, packs, object_key, class_internal_url } = useParams()

    const [previous_action, setprevious_action] = useState("");


    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
        setapiexcelimportmessage("");
    };

    const changeHandler2 = (event) => {
        setapiexcelimportmessage();
        setformation_file_name();
        sethandleSubmission_api();
        hiddenFileInput_formation.current.click();
    };

    const hiddenFileInput_formation = React.useRef(null);

    const history = useHistory();


    const stored_cookie = getCookie('tokenmysypart');
    const location = useLocation();

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_cookie || stored_cookie.length <= 0) {
        if (location && location.state && location.state.mysy_arg) {
            //alert(" mysy arg (dans partner) = " + location.state.mysy_arg);
            history.push("/Connexion", { mysy_arg: location.state.mysy_arg });
        }
        else {
            history.push("/Connexion");
        }
    }


    const formData = new FormData();

    const [handleSubmission_api, sethandleSubmission_api] = useState();
    const [handleSubmission_result, sethandleSubmission_result] = useState();
    const [handleSubmission_message, sethandleSubmission_message] = useState();

    const handleSubmission = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setformation_file_name(event.target.files[0].name);

        //console.log(" import du ficier ", event.target.files[0].name);


        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');


        formData.append("token", stored_cookie);

        //console.log("token = " + stored_cookie); theme_name
        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/add_class_mass/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);
                setexcelimportmessage(result['message']);
                setmyApiResponse("true");
                setapiexcelimportmessage(result['status']);
                setLoading(false);
                if (String(result['status']) === String("true")) {

                    sethandleSubmission_api("true");
                    var local_message = result['message'] + " Vous allez être redirigé vers la liste des formations"
                    alert(local_message);
                    history.push("/Partner/affichage");
                    window.location.reload();
                    return;

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    sethandleSubmission_api("false");
                    sethandleSubmission_message(result['message']);
                    alert(result['message']);
                }
                //alert("result['status'] " + result['status']);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', result);
                setmyApiResponse("false");
                setapiexcelimportmessage("-1");
                sethandleSubmission_api("false");
                alert(" Impossible d'importer/mettre à jour les formations");
            });
    };

    const [myimage, setmyimage] = useState();
    const [isimage, setisimage] = useState();

    function getImage(e) {
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedImage/";
        var form = new FormData();
        form.append("token", stored_cookie);
        form.append("type", "partner");

        axios.post(myurl, form).then(res => {
            //console.log(" getImage : In test  res.data.status = " + res.data.status);
            //console.log(" getImage: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {

                if (res.data.message && res.data.message.img && res.data.message.img.length > 0) {
                    setisimage("True");
                }
                else {
                    setisimage("False");
                }
                setmyimage(res.data.message.img);
                var ch_img = "data:image/png;base64," + res.data.message.img;
                setuserimgprofil(ch_img);
            }
            else {
                //console.log(" In Erreur   res.data.status = " + res.data.status);
                //console.log(" In Erreur  res.data.message = " + res.data.message);
                setisimage("False");
                setuserimgprofil();
                //alert("4");
            }
        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setisimage("False");
            setuserimgprofil(profileimg);
        })

    }


    // Cette fonction recuperer les droits d'acces stocké en cookie
    const [connected_user_access_right, setconnected_user_access_right] = React.useState(null);
    function retrieve_user_acces_right() {
        const stored_cookie = getCookie('tokenmysypart');

        if (!stored_cookie || String(stored_cookie).trim() === "") {
            history.push("/Connexion");
            return;
        }

        var acces_right_token_name = stored_cookie + "_uar";



        var acces_right_token_val = getCookie(acces_right_token_name, { path: '/' });

        var tab_json_access_right = JSON.parse(acces_right_token_val);

        setconnected_user_access_right(tab_json_access_right);

    }

    // check du droit d'acces de l'utilisateur
    function check_user_acces_right(local_module, local_action) {

        //console.log(" ### connected_user_access_right = ", connected_user_access_right);
        //console.log(" #### check_user_acces_right = ", local_module, " --- local_action = ", local_action);

        if (String(local_action) === "read") {
            for (let i = 0; i < connected_user_access_right.length; i++) {
                if (String(connected_user_access_right[i].module) === String(local_module)
                    && String(connected_user_access_right[i].read) === "true") {

                    return 1;
                }
            }
            return 0;
        } else if (String(local_action) === "write") {
            for (let i = 0; i < connected_user_access_right.length; i++) {
                if (String(connected_user_access_right[i].module) === String(local_module)
                    && String(connected_user_access_right[i].write) === "true") {

                    return 1;
                }
            }
            return 0;
        } else {
            return 0;
        }

    }


    useEffect(() => {

        const stored_cookie_menu_gauche = getCookie('mysy_menu_gauche_reduit');

        if (String(stored_cookie_menu_gauche) === "1")
            setleft_menu(false);
        else
            setleft_menu(true);

        setapiexcelimportmessage();

        if (location && location.state && location.state.local_sub_menu) {
            setmenu(location.state.local_sub_menu);
            setpartnerPackService(location.state.currentpack);
            //alert(" le pack = "+location.state.currentpack);
        }

        var list_menu = ['creation', 'infopartner', 'statistique', 'abonnement', 'affichage', 'ordervalide',
            'mes_sessions', 'setuplms', 'facture', 'pricing', 'mes_clients', 'mes_devis', 'mes_cmd',
            'mes_stagiaires', 'mes_employes', 'mon_materiel', 'config_document', 'factures_client', "setuplms", "config_technique",
            "config_champ_specific", "module_agenda", "config_session_steps", "config_jours_heures", "dashbord_formation",
            "dashbord_session", "mon_tableau_de_bord", "prix_achat", "mes_apprenants", "dashbord_ressources_humaines",
            "dashbord_inscriptions", "mes_salles", "mes_opportunites", "config_formulaires", "dashbord_factures", "mes_ue",
            "domaine_categorie_metier", "config_formation_initiale", "note_evaluation", "groupe_apprenant",
            "part_produit_service", "config_document_super_admin"]


        if (action && list_menu.includes(action)) {

            setmenu(action);

            // /!\ il faudra terminer la fonction ci-dessous. aller dans la fonction pour voir le commentaire
            inactive_active_menu_header_from_url_action(action);

            if (action && orderid && packs && String(action) === "ordervalide") {
                CheckSalesOrder();
            }

            /* if (action && orderid && packs && String("abonnement")) {
                 //console.log(" abonnement : payement intent = ", orderid, "  pack = ", packs);
             }*/
        } else {
            DiplaytrainingList();

        }

        getImage();
        GetPartnerNameFromToken();
        setformation_file_name();
        GetPartnerLMSData();
        retrieve_user_acces_right();
    }, [])



    // Cette fontion desactive tous les menu header et reactive just le menu_header concerné
    function inactive_active_menu_header(current_menu_header) {
        const list_menu_header_names = ['my_account', 'my_class', 'my_session', 'my_apprenant', 'my_ressource', 'my_planning',
            'my_partners', 'my_invoices', 'my_prix_achat', 'my_e_learning', 'my_configuration', 'my_tbd', 'my_ue', 'domaine_categorie_metier',
            'config_formation_initiale', 'note_evaluation', 'groupe_apprenant', 'part_produit_service', 'monactivite', 'mon_tableau_de_bord']
        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.fontWeight = "400";
            }
        }


        if (document.getElementsByName(String(current_menu_header)) && document.getElementsByName(String(current_menu_header))[0]) {
            document.getElementsByName(String(current_menu_header))[0].style.fontWeight = "700";
        }
    }

    function inactive_active_menu_header_from_url_action(url_action) {
        const list_menu_header_names = ['my_account', 'my_class', 'my_session', 'my_apprenant', 'my_ressource', 'my_planning',
            'my_partners', 'my_invoices', 'my_prix_achat', 'my_e_learning', 'my_configuration', 'my_tbd', 'my_ue', 'domaine_categorie_metier',
            'config_formation_initiale', 'note_evaluation', 'groupe_apprenant', 'part_produit_service', 'monactivite', 'mon_tableau_de_bord']
        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.fontWeight = "400";
            }
        }

        // plus tard, il faudra mettre en brillance le menu et le soumenu concerné
    }

    function CreateTraining(event) {
        var security_retval = check_user_acces_right("formation", "read");
        if (security_retval === 1) {
            setmenu("creation");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/creation");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des formations");
        }
    }

    function PersonnalData(event) {

        setmenu("infopartner");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/infopartner");
        document.body.style.backgroundColor = "#ffffff";

        inactive_active_menu_header("my_account");

    }

    function monactivite_func(event) {

        setmenu("monactivite");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/monactivite");
        document.body.style.backgroundColor = "#ffffff";

        inactive_active_menu_header("monactivite");

    }



    function config_document_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_document");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_document");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_configuration");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function config_document_super_admin_func(event) {
        setmenu("config_document_super_admin");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/config_document_super_admin");
        document.body.style.backgroundColor = "#ffffff";
        inactive_active_menu_header("my_configuration");
    }

    function config_ftion_initale_general_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_formation_initiale");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_formation_initiale");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("config_formation_initiale");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }



    function config_specfic_fields_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_champ_specific");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_champ_specific");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function module_agenda_func(event) {
        var security_retval = check_user_acces_right("module_agenda", "read");
        if (security_retval === 1) {
            setmenu("module_agenda");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/module_agenda");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_planning");

        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function mon_tableau_de_bord_func(event) {
        setmenu("mon_tableau_de_bord");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/mon_tableau_de_bord");
        document.body.style.backgroundColor = "#ffffff";

        inactive_active_menu_header("mon_tableau_de_bord");
    }


    function prix_achat_func(event) {
        setmenu("prix_achat");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/prix_achat");
        document.body.style.backgroundColor = "#ffffff";
        inactive_active_menu_header("my_prix_achat");

    }





    function config_technique_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_technique");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_technique");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }

    function config_session_steps_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_session_steps");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_session_steps");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function config_jours_travaille_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_jours_heures");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_jours_heures");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function config_formulaires_func(event) {
        var security_retval = check_user_acces_right("config_document", "read");
        if (security_retval === 1) {
            setmenu("config_formulaires");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/config_formulaires");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function mes_factures_client_func(event) {
        var security_retval = check_user_acces_right("facture_client", "read");
        if (security_retval === 1) {
            setmenu("factures_client");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/factures_client");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_invoices");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des documents");
        }
    }


    function DisplayStat(event) {
        var security_retval = check_user_acces_right("formation", "read");
        if (security_retval === 1) {
            setmenu("statistique");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/statistique");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des formations");
        }
    }

    function DisplaySetUpLMS(event) {
        var security_retval = check_user_acces_right("lms", "read");
        if (security_retval === 1) {
            setmenu("setuplms");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/setuplms");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_e_learning");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du E-Learning");
        }

    }

    function DisplayFacure(event) {
        var security_retval = check_user_acces_right("commande", "read");
        if (security_retval === 1) {
            setmenu("facture");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/facture");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("facture");

        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des commandes");
        }

    }

    function Abonnement_func(event) {
        var security_retval = check_user_acces_right("abonnement", "read");
        if (security_retval === 1) {
            setmenu("abonnement");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/abonnement");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("abonnement");

        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des abonnements");
        }

    }

    function pricing_func(event) {
        var security_retval = check_user_acces_right("prix", "read");
        if (security_retval === 1) {
            setmenu("pricing");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/pricing");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_partners");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des prix");
        }

    }

    function mes_clients_func(event) {
        var security_retval = check_user_acces_right("client", "read");

        if (security_retval === 1) {
            setmenu("mes_clients");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_clients");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_partners");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des clients");
        }
    }

    function mes_devis_func(event) {
        var security_retval = check_user_acces_right("commande", "read");
        if (security_retval === 1) {
            setmenu("mes_devis");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_devis");
            document.body.style.backgroundColor = "#ffffff";
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des commandes");
        }
    }


    function mes_cmd_func(event) {
        var security_retval = check_user_acces_right("commande", "read");
        if (security_retval === 1) {
            setmenu("mes_cmd");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_cmd");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_invoices");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des commandes");
        }
    }


    function DiplayPartner_UE(event) {
        var security_retval = check_user_acces_right("formation", "read");

        if (security_retval === 1) {
            setmenu("mes_ue");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_ue");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("my_ue");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des sessions");
        }
    }


    function DiplayPartner_Domaine_Categorie_Metier(event) {
        var security_retval = check_user_acces_right("formation", "read");

        if (security_retval === 1) {
            setmenu("domaine_categorie_metier");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/domaine_categorie_metier");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("domaine_categorie_metier");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des sessions");
        }
    }


    function DiplayPartner_Produits_Services(event) {
        var security_retval = check_user_acces_right("formation", "read");

        if (security_retval === 1) {
            setmenu("part_produit_service");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/part_produit_service");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("part_produit_service");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des sessions");
        }
    }


    function DiplaySessionList(event) {
        var security_retval = check_user_acces_right("session", "read");


        if (security_retval === 1) {
            setmenu("mes_sessions");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_sessions");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("my_session");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des sessions");
        }
    }

    function Display_note_evaluation_func(event) {
        var security_retval = check_user_acces_right("note_evaluation", "read");

        if (security_retval === 1) {
            setmenu("note_evaluation");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/note_evaluation");
            document.body.style.backgroundColor = "#ffffff";

            inactive_active_menu_header("note_evaluation");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des sessions");
        }
    }

    function DiplayStagiaireList(event) {
        var security_retval = check_user_acces_right("stagiaire", "read");
        if (security_retval === 1) {
            setmenu("mes_stagiaires");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_stagiaires");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_apprenant");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des stagiaires");
        }
    }

    function mes_apprenants_func(event) {
        var security_retval = check_user_acces_right("stagiaire", "read");
        if (security_retval === 1) {
            setmenu("mes_apprenants");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_apprenants");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_apprenant");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des stagiaires");
        }
    }

    function groupe_apprenant_func(event) {
        var security_retval = check_user_acces_right("stagiaire", "read");
        if (security_retval === 1) {
            setmenu("groupe_apprenant");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/groupe_apprenant");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("groupe_apprenant");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des stagiaires");
        }
    }

    function DisplayeEmployeList(event) {
        var security_retval = check_user_acces_right("employe", "read");
        if (security_retval === 1) {
            setmenu("mes_employes");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/mes_employes");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_ressource");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion des employés");
        }
    }

    function DisplayeMaterielList(event) {
        var security_retval = check_user_acces_right("materiel", "read");

        if (security_retval === 1) {
            setmenu("mon_materiel");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_ressource");
            history.push("/Partner/mon_materiel");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function Display_mes_salles_func(event) {
        var security_retval = check_user_acces_right("materiel", "read");

        if (security_retval === 1) {
            setmenu("mes_salles");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_ressource");
            history.push("/Partner/mes_salles");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }


    function Display_mes_opportunites_func(event) {
        var security_retval = check_user_acces_right("client", "read");

        if (security_retval === 1) {
            setmenu("mes_opportunites");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/mes_opportunites");
            inactive_active_menu_header("my_partners");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function config_dashbord_formation_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_formation");
            setapiexcelimportmessage();
            setformation_file_name();
            history.push("/Partner/dashbord_formation");
            document.body.style.backgroundColor = "#ffffff";
            inactive_active_menu_header("my_tbd");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function config_dashbord_session_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_session");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_session");
            inactive_active_menu_header("my_tbd");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }


    function config_dashbord_ressources_humaines_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_ressources_humaines");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_ressources_humaines");
            inactive_active_menu_header("my_tbd");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }



    function config_dashbord_inscriptions_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_inscriptions");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_inscriptions");
            inactive_active_menu_header("my_tbd");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function config_dashbord_factures_func(event) {
        var security_retval = check_user_acces_right("dashbord", "read");

        if (security_retval === 1) {
            setmenu("dashbord_factures");
            setapiexcelimportmessage();
            setformation_file_name();
            document.body.style.backgroundColor = "#ffffff";
            history.push("/Partner/dashbord_factures");
            inactive_active_menu_header("my_tbd");
        } else {
            Dialog_1_handle_acces_insuffisant("Vos droits ne sont pas suffisants pour acceder à la gestion du matériel pédagogique");
        }
    }

    function DiplaytrainingList(event) {

        inactive_active_menu_header("my_class");
        setmenu("affichage");
        setapiexcelimportmessage();
        setformation_file_name();
        history.push("/Partner/affichage");

        inactive_active_menu_header("my_class");
        var server_address = "127.0.0.1";
        //var server_address = "89.156.84.196";
        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = selectedfilt1;
        var filtre2 = selectedfilt2;
        var filtre3 = selectedfilt3;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";

        // Recuperation des valeurs de filtres

        if (selectedfilt1) {
            filtre1_value = document.getElementsByName("filtre1")[0].value;
            form.append(filtre1, filtre1_value);
        }

        if (selectedfilt2) {
            filtre2_value = document.getElementsByName("filtre2")[0].value;
            form.append(filtre2, filtre2_value);
        }

        if (selectedfilt3) {
            filtre3_value = document.getElementsByName("filtre3")[0].value;
            form.append(filtre3, filtre3_value);
        }

        //console.log(" filtres1 = " + filtre1 + " = " + filtre1_value);
        //console.log(" filtres2 = " + filtre2 + " = " + filtre2_value);
        //console.log(" filtres3 = " + filtre3 + " = " + filtre3_value);


        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

        axios.post(myurl, form).then(res => {
            if (res.data.status !== "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("true");
                setResult(res.data.message)
                //setmyApimyApiMessage("OK")
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("false");
            //setmyApimyApiMessage("")
        })
    }

    function logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_part) != "undefined") {
            removeCookie_part('tokenmysypart', { path: '/' });
            history.push("/recherche-formation");
        }
    }

    function logout() {
        confirmAlert({
            title: 'Deconnexion ?',
            message: 'Confirmez la deconnexion',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const imgchangeHandler = (event) => {
        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;


        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 100000) {
            alert("L'image doit faire moins de 1 mega Kilo Octets");
            return;
        }
        setisimgSelectedfile(event.target.files[0]);
        setisimgSelected(true);
        setuserimgprofil(URL.createObjectURL(event.target.files[0]));
    };


    const [formation_initiale, setformation_initiale] = useState("");
    const [document_super_admin, setdocument_super_admin] = useState("");

    const [conntected_employee_activite_retard, setconntected_employee_activite_retard] = useState("0");
    const [conntected_employee_activite_new, setconntected_employee_activite_new] = useState("");

    const [conntected_employee_email, setconntected_employee_email] = useState("");
    const [conntected_employee_id, setconntected_employee_id] = useState("");


    const [GetPartnerNameFromToken_api_result, setGetPartnerNameFromToken_api_result] = useState();

    function GetPartnerNameFromToken(event) {

        var form = new FormData();

        //console.log(" GetPartnerNameFromToken stored_cookie = ", stored_cookie);

        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                setGetPartnerNameFromToken_api_result(true);
                //console.log(" In GetPartnerNameFromToken  res.data.status = " + res.data.status);
                //console.log(" In GetPartnerNameFromToken  res.data.message = " + res.data.message);
                setparntername(JSON.parse(res.data.message).nom);

                setconntected_employee_email(JSON.parse(res.data.message).email);

                if (JSON.parse(res.data.message).nb_activite_retard)
                    setconntected_employee_activite_retard(JSON.parse(res.data.message).nb_activite_retard)
                else
                    setconntected_employee_activite_retard("0")


                if (JSON.parse(res.data.message).nb_activite_new)
                    setconntected_employee_activite_new(JSON.parse(res.data.message).nb_activite_new)
                else
                    setconntected_employee_activite_new("")



                if (JSON.parse(res.data.message).pack_service) {
                    setpartnerPackService(JSON.parse(res.data.message).pack_service);
                }
                else {
                    setpartnerPackService("Aucun Service");
                }

                if (JSON.parse(res.data.message).nb_formation) {
                    setparnternbformation(JSON.parse(res.data.message).nb_formation);
                }
                else {
                    setparnternbformation("");
                }

                if (JSON.parse(res.data.message).ressource_humaine_id) {
                    setconntected_employee_id(JSON.parse(res.data.message).ressource_humaine_id);
                }
                else {
                    setconntected_employee_id("");
                }


                if (JSON.parse(res.data.message).formation_initiale) {
                    setformation_initiale(JSON.parse(res.data.message).formation_initiale);
                } else {
                    setformation_initiale("");
                }

                if (JSON.parse(res.data.message).document_super_admin) {
                    setdocument_super_admin(JSON.parse(res.data.message).document_super_admin);
                } else {
                    setdocument_super_admin("");
                }



                var partner_employee_user_name = "Inconnu";

                if (JSON.parse(res.data.message).contact_nom) {
                    partner_employee_user_name = (JSON.parse(res.data.message).contact_nom);
                }


                if (JSON.parse(res.data.message).contact_prenom) {
                    partner_employee_user_name = partner_employee_user_name + " " + (JSON.parse(res.data.message).contact_prenom);
                }

                setparnter_user_employee(partner_employee_user_name);


                if (JSON.parse(res.data.message).ispending) {
                    setpartnerstatus(JSON.parse(res.data.message).ispending);
                }

                if (JSON.parse(res.data.message).firstconnexion &&
                    String(JSON.parse(res.data.message).firstconnexion) === "0") {
                    setfirstConnexion("0");
                } else {
                    setfirstConnexion("1");
                }

                if (JSON.parse(res.data.message).is_partner_admin_account &&
                    String(JSON.parse(res.data.message).is_partner_admin_account) === "1") {
                    setpart_is_partner_admin_account("1");
                } else {
                    setpart_is_partner_admin_account("0");
                }

            }
            else {
                //console.log(" In GetPartnerNameFromToken  res.data.status = " + res.data.status);
                //console.log(" In GetPartnerNameFromToken  res.data.message = " + res.data.message);

            }


        }).catch((error) => {
            console.warn('In GetPartnerNameFromToken Not good man :( mysearchtext = ');

        })
    }

    function sendImage(e) {
        var formData = new FormData();

        formData.append('File', isimgSelectedfile);
        formData.append("token", stored_part);
        formData.append("type", "partner");
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/recordImage/",
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);
                setisimgSelected(false);

            })
            .catch((error) => {
                console.error('Error:');
                setisimgSelected(true);
            });
    };




    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };

    function alertactivecompte() {
        alert(" Vous devez ajouter un mode de Paiement pour acceder à cette fonctionnalité");
    }

    function ValideFirstConnexion() {
        setfirstConnexion("");
        setmenu("abonnement");
    }

    const [formation_file_name, setformation_file_name] = useState();
    const [CheckSalesOrder_api, setCheckSalesOrder_api] = useState("");
    const [CheckSalesOrder_message, setCheckSalesOrder_message] = useState("");
    function CheckSalesOrder(event) {

        var form = new FormData();

        formData.append("token", stored_part);
        formData.append("orderid", orderid);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CheckSalesOrder/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                setCheckSalesOrder_api("true")
            }
            else {
                setCheckSalesOrder_api("false")
                setCheckSalesOrder_message(String(res.data.message))
            }


        }).catch((error) => {
            //console.log('In CheckSalesOrder ', error);
            setCheckSalesOrder_api("false")

        })
    }

    const [isLoading, setLoading] = useState();


    const [GetPartnerLMSData_api, setGetPartnerLMSData_api] = useState();
    const [GetPartnerLMSData_result, setGetPartnerLMSData_result] = useState();
    const [GetPartnerLMSData_message, setGetPartnerLMSData_message] = useState();

    const [partner_lms_url, setpartner_lms_url] = useState();
    const [has_partner_lms_url, sethas_partner_lms_url] = useState();

    function GetPartnerLMSData(event) {

        var form = new FormData();

        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/LMS_Get_Partner_Data/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetPartnerLMSData  res.data.status = " + res.data.status);
                //console.log(" In GetPartnerLMSData  res.data.message = " + res.data.message);
                setGetPartnerLMSData_api("true");
                setGetPartnerLMSData_result(res.data.message);


                var my_lms_url = "";
                if (JSON.parse(res.data.message).lms_account &&
                    String(JSON.parse(res.data.message).lms_account) === "1") {
                    sethas_partner_lms_url(JSON.parse(res.data.message).lms_account);
                    my_lms_url = (JSON.parse(res.data.message).lms_virtualhost_url) + "user_portal.php?recid=" + encodeURIComponent(JSON.parse(res.data.message).lms_part_recid) +
                        "&mysytoken=" + encodeURIComponent(JSON.parse(res.data.message).lms_pwd_mask);

                    //console.log(" ### LMS url = ", my_lms_url);
                    setpartner_lms_url(my_lms_url);
                }

            }
            else {
                setGetPartnerLMSData_api("false");
                setGetPartnerLMSData_message(res.data.message);
            }


        }).catch((error) => {
            console.warn('In GetPartnerLMSData Not good man :( mysearchtext = ', error);
            setGetPartnerLMSData_api("false");

        })
    }

    const [deplie_hebergement, setdeplie_hebergement] = useState();
    function ftion_deplie_hebergement() {
        if (String(deplie_hebergement) === "1") {
            setdeplie_hebergement();
        } else {
            setdeplie_hebergement("1");
        }

    }
    const [deplie_mes_clients, setdeplie_mes_clients] = useState();
    function ftion_deplie_mes_clients() {
        if (String(deplie_mes_clients) === "1") {
            setdeplie_mes_clients();
        } else {
            setdeplie_mes_clients("1");
        }

    }

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_acces_insuffisant(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [left_menu, setleft_menu] = React.useState(true);

    const open_close_left_menu = () => {
        if (left_menu === true) {
            setleft_menu(false);
            setcookie_menu_gauche_reduit("mysy_menu_gauche_reduit", "1", { path: '/' });
        }
        else {
            setleft_menu(true);
            setcookie_menu_gauche_reduit("mysy_menu_gauche_reduit", "0", { path: '/' });
        }

    };


    const [selected_menu_header, setselected_menu_header] = React.useState("");
    const [Dialog_SEND_EMAIL_open, setDialog_SEND_EMAIL_open] = React.useState(false);


    const Dialog_SEND_EMAIL_handleClose = () => {
        setDialog_SEND_EMAIL_open(false);
    }

    const Clean_Selected_Rows_For_Email = () => {
        //setSelectionModel([])
    }


    function update_nb_activite_en_retard(new_val) {
        setconntected_employee_activite_retard(String(new_val));
    }

    function update_nb_activite_en_new(new_val) {
        setconntected_employee_activite_new(String(new_val));
    }


    return (

        <div className="partner">


            <Helmet>
                <title>Partenaires editeurs formations</title>
                <meta name="description" content="backoffice partenaire editeurs formation" />
                <meta name="robots" content="index,follow" />
            </Helmet>
            <Header />



            <div className="div_mobile" id="div_mobile">
                {isLoading && <div className="loader-container">
                    <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
                </div>}
                <Dialog
                    open={Dialog_1_open}
                    onClose={Dialog_1_handleClose}

                >

                    <DialogTitle>MySy Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <ImWarning />   {Dialog_1_message}
                        </DialogContentText>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                    </DialogActions>
                </Dialog>

                { /********* Dialog redaction email */}
                <Dialog

                    //  onClose={Dialog_SEND_EMAIL_handleClose}
                    className="module_email_management"
                    PaperProps={{
                        sx: {
                            width: "100%",
                            maxWidth: "920px!important",
                        },
                    }}
                    open={Dialog_SEND_EMAIL_open}
                    static
                    onClose={() => null}
                >


                    <DialogContent>
                        <DialogContentText>
                            {Dialog_1_message}
                        </DialogContentText>


                        <Module_Email_Management father_close_function={Dialog_SEND_EMAIL_handleClose} email_destinataire={""}
                            related_collection={"nointernalcontact"} Clean_Selected_Rows_For_Email={""} />

                    </DialogContent>


                </Dialog>
                { /********* Fin Dialog redaction email */}


                {left_menu === true && <div className="sub_dib_mobile"  >
                    <div className="div_gauche" id="div_gauche">
                        <nav className="not_display_mobile">
                            {left_menu === true && <div style={{ "cursor": "pointer", textAlign: "left" }} onClick={open_close_left_menu}>
                                <Tooltip className="tooltip_css" id="tooltip_id_0" />
                                <a data-tooltip-id="tooltip_id_0" data-tooltip-html="Réduire le menu">
                                    <PiSlideshowFill /> &nbsp; Réduire
                                </a>
                            </div>}
                            {left_menu === false && <div style={{ "cursor": "pointer", textAlign: "center" }}>
                                <Tooltip className="tooltip_css" id="tooltip_id_1" />
                                <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Agrandir le menu">
                                    <PiSlideshowThin onClick={open_close_left_menu} />
                                </a>
                            </div>}
                        </nav>

                        <img class="img-responsive img_user" src={userimgprofil} />


                        {!isimgSelected && String(part_is_partner_admin_account) === "1" && <div className="nonlable">
                            <input type="file" id="files" accept="image/*" onChange={imgchangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} />
                            <label for="files" className="label">Changer image</label>



                        </div>}

                        {isimgSelected && <div className="nonlable">
                            <Button variant="outlined" onClick={sendImage} className="enreg">Enregistrer</Button>
                        </div>}


                        {String(part_is_partner_admin_account) === "1" && <div className="nom_prenom">
                            {parntername && <nav>
                                <b> {parntername}&nbsp;-&nbsp;{String(partnerPackService).toUpperCase()} ({parnternbformation})</b> &nbsp; &nbsp;

                                {GetPartnerNameFromToken_api_result && conntected_employee_activite_retard && parseFloat(conntected_employee_activite_retard) > 0 &&
                                    <StyledBadge_mysy_red badgeContent={String(parseFloat(conntected_employee_activite_retard))} style={{ "cursor": "pointer" }}
                                        onClick={(event) => {
                                            monactivite_func();

                                        }}>

                                        <Tooltip className="tooltip_css" id="badge_tool_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="badge_tool_01" data-tooltip-html="Activité(s) en retard">
                                            &nbsp; &nbsp;</a>


                                    </StyledBadge_mysy_red>} &nbsp; &nbsp;

                                {GetPartnerNameFromToken_api_result && conntected_employee_activite_new && parseFloat(conntected_employee_activite_new) > 0 &&
                                    <StyledBadge_mysy_color1 badgeContent={conntected_employee_activite_new} style={{ "cursor": "pointer" }}
                                        onClick={(event) => {
                                            monactivite_func();

                                        }}>
                                        <Tooltip className="tooltip_css" id="badge_tool_02" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="badge_tool_02" data-tooltip-html="Nouvelle(s) activité(s) pour vous">
                                            &nbsp; &nbsp;</a>

                                    </StyledBadge_mysy_color1>}

                            </nav>}
                            {!parntername && <b> Partenaire </b>} </div>}


                        {String(part_is_partner_admin_account) !== "1" && <div className="nom_prenom">
                            {parnter_user_employee}
                            {!parntername && <b> Utilisateur </b>} &nbsp; &nbsp;
                            {GetPartnerNameFromToken_api_result && conntected_employee_activite_retard && parseFloat(conntected_employee_activite_retard) > 0 &&
                                <StyledBadge_mysy_red badgeContent={conntected_employee_activite_retard} style={{ "cursor": "pointer" }}
                                    onClick={(event) => {
                                        monactivite_func();

                                    }}>
                                    <Tooltip className="tooltip_css" id="badge_tool_03" style={{ "fontSize": "12px" }} />
                                    <a data-tooltip-id="badge_tool_03" data-tooltip-html="Activité(s) en retard">
                                        &nbsp; &nbsp;</a>
                                </StyledBadge_mysy_red>} &nbsp; &nbsp;

                            {GetPartnerNameFromToken_api_result && conntected_employee_activite_new && parseFloat(conntected_employee_activite_new) > 0 &&
                                <StyledBadge_mysy_color1 badgeContent={conntected_employee_activite_new} style={{ "cursor": "pointer" }}
                                    onClick={(event) => {
                                        monactivite_func();

                                    }}>
                                    <Tooltip className="tooltip_css" id="badge_tool_04" style={{ "fontSize": "12px" }} />
                                    <a data-tooltip-id="badge_tool_04" data-tooltip-html="Nouvelle(s) activité(s) pour vous">
                                        &nbsp; &nbsp;</a>

                                </StyledBadge_mysy_color1>}

                        </div>}

                        {String(formation_initiale) === "1" && <div className="nom_prenom">   Ftion Initiale


                        </div>}

                        <ThemeProvider theme={theme}>

                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">


                                        <SubMenu label="Mon Compte" className="my_chart" id="my_account" name="my_account" icon={<AccountBalanceIcon color={"primary"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "infopartner" && <MenuItem onClick={PersonnalData} className="sousmenu" icon={<FcHome className="icone_menu" />}>  MES INFORMATIONS </MenuItem>}
                                            {String(menu) === "infopartner" && <MenuItem onClick={PersonnalData} className="sousmenu_selected" icon={<FcHome className="icone_menu" />}>  MES INFORMATIONS </MenuItem>}


                                            {String(menu) !== "monactivite" && <MenuItem onClick={monactivite_func} className="sousmenu" icon={<CircleNotificationsIcon className="icone_menu" />}>  MON ACTIVITE </MenuItem>}
                                            {String(menu) === "monactivite" && <MenuItem onClick={monactivite_func} className="sousmenu_selected" icon={<CircleNotificationsIcon className="icone_menu" />}>  MON ACTIVITE </MenuItem>}


                                            {String(menu) !== "mon_tableau_de_bord" && <MenuItem onClick={mon_tableau_de_bord_func} className="sousmenu" icon={<AiOutlineDashboard className="icone_menu" />}>  MON TABLEAU DE BORD </MenuItem>}
                                            {String(menu) === "mon_tableau_de_bord" && <MenuItem onClick={mon_tableau_de_bord_func} className="sousmenu_selected" icon={<AiOutlineDashboard className="icone_menu" />}>  MON TABLEAU DE BORD </MenuItem>}


                                            {String(menu) !== "abonnement" && <MenuItem className="sousmenu" onClick={Abonnement_func} icon={<FcKey className="icone_menu" />} > ABONNEMENT </MenuItem>}
                                            {String(menu) === "abonnement" && <MenuItem className="sousmenu_selected" onClick={Abonnement_func} icon={<FcKey className="icone_menu" />}>  ABONNEMENT </MenuItem>}


                                            {String(menu) !== "facture" && <MenuItem className="sousmenu" onClick={DisplayFacure} icon={<FcFeedback className="icone_menu" />}> FACTURE MYSY</MenuItem>}
                                            {String(menu) === "facture" && <MenuItem className="sousmenu_selected" onClick={DisplayFacure} icon={<FcFeedback className="icone_menu" />}>FACTURE MYSY</MenuItem>}


                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>


                            <div class="separator"></div>

                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Formations" className="my_chart" id="my_class" name="my_class" icon={<ListAltIcon color={"secondary"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "affichage" && <MenuItem onClick={DiplaytrainingList} className="sousmenu" icon={<FcList className="icone_menu" />}> CATALOGUE </MenuItem>}
                                            {String(menu) === "affichage" && <MenuItem onClick={DiplaytrainingList} className="sousmenu_selected" icon={<FcList className="icone_menu" />}> CATALOGUE </MenuItem>}

                                            {String(formation_initiale) === "1" && String(menu) !== "mes_ue" && <MenuItem onClick={DiplayPartner_UE} className="sousmenu" icon={<FcOrgUnit className="icone_menu" />}> UNIT. ENSEI.  </MenuItem>}
                                            {String(formation_initiale) === "1" && String(menu) === "mes_ue" && <MenuItem onClick={DiplayPartner_UE} className="sousmenu_selected" icon={<FcOrgUnit className="icone_menu" />}> UNIT. ENSEI.  </MenuItem>}

                                            {String(menu) !== "domaine_categorie_metier" && <MenuItem onClick={DiplayPartner_Domaine_Categorie_Metier} className="sousmenu" icon={<FcEngineering className="icone_menu" />}> CARACTERISTIQUES   </MenuItem>}
                                            {String(menu) === "domaine_categorie_metier" && <MenuItem onClick={DiplayPartner_Domaine_Categorie_Metier} className="sousmenu_selected" icon={<FcEngineering className="icone_menu" />}> CARACTERISTIQUES </MenuItem>}

                                            {String(menu) !== "part_produit_service" && <MenuItem onClick={DiplayPartner_Produits_Services} className="sousmenu" icon={<LiaProductHunt className="icone_menu" />}> PRODUITS & SERVICES   </MenuItem>}
                                            {String(menu) === "part_produit_service" && <MenuItem onClick={DiplayPartner_Produits_Services} className="sousmenu_selected" icon={<LiaProductHunt className="icone_menu" />}> PRODUITS & SERVICES  </MenuItem>}

                                        </SubMenu>



                                    </Menu>
                                </Sidebar>

                            </div>



                            <div>
                                <Sidebar className="my_sidebar">
                                    {String(menu) !== "mes_sessions" && <Menu className="my_menu bck_menu_seul" id="my_session" name="my_session" onClick={DiplaySessionList} >&nbsp;<CoPresentIcon className="icone_menu" color={"secondary"} /> &nbsp;SESSIONS </Menu>}
                                    {String(menu) === "mes_sessions" && <Menu className="my_menu bck_menu_seul_selected" id="my_session" name="my_session" onClick={DiplaySessionList}  >&nbsp;<CoPresentIcon className="icone_menu" color={"secondary"} /> &nbsp;SESSIONS </Menu>}
                                </Sidebar>
                            </div>

                            {String(formation_initiale) === "1" &&
                                <div>
                                    <Sidebar className="my_sidebar">
                                        {String(menu) !== "note_evaluation" && <Menu className="my_menu bck_menu_seul" id="note_evaluation" name="note_evaluation" onClick={Display_note_evaluation_func} > &nbsp;<ScoreboardIcon className="icone_menu" color={"secondary"} /> &nbsp;NOTES & EVALUATIONS </Menu>}
                                        {String(menu) === "note_evaluation" && <Menu className="my_menu bck_menu_seul_selected" id="note_evaluation" name="note_evaluation" onClick={Display_note_evaluation_func}  > &nbsp;<ScoreboardIcon className="icone_menu" color={"secondary"} /> &nbsp;NOTES & EVALUATIONS </Menu>}
                                    </Sidebar>
                                </div>}


                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Apprenants" className="my_chart" id="my_apprenant" name="my_apprenant" icon={<SchoolIcon color={"secondary"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "mes_apprenants" && <MenuItem onClick={mes_apprenants_func} className="sousmenu" icon={<PiStudent className="icone_menu" />}>  FICHE APPRENANTS </MenuItem>}
                                            {String(menu) === "mes_apprenants" && <MenuItem onClick={mes_apprenants_func} className="sousmenu_selected" icon={<PiStudent className="icone_menu" />}>  FICHE APPRENANTS </MenuItem>}

                                            {String(menu) !== "mes_stagiaires" && <MenuItem onClick={DiplayStagiaireList} className="sousmenu" icon={<FcReading className="icone_menu" />}>  PARCOURS APPRENANTS </MenuItem>}
                                            {String(menu) === "mes_stagiaires" && <MenuItem onClick={DiplayStagiaireList} className="sousmenu_selected" icon={<FcReading className="icone_menu" />}>  PARCOURS APPRENANTS </MenuItem>}

                                            {String(formation_initiale) === "1" && String(menu) !== "groupe_apprenant" && <MenuItem onClick={groupe_apprenant_func} className="sousmenu" icon={<Groups2Icon className="icone_menu" />}>  GROUPE APPRENANTS </MenuItem>}
                                            {String(formation_initiale) === "1" && String(menu) === "groupe_apprenant" && <MenuItem onClick={groupe_apprenant_func} className="sousmenu_selected" icon={<Groups2Icon className="icone_menu" />}>  GROUPE APPRENANTS </MenuItem>}


                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>

                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Ressources" className="my_chart" id="my_ressource" name="my_ressource" icon={<HandymanIcon color={"secondary"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "mes_employes" && <MenuItem onClick={DisplayeEmployeList} className="sousmenu" icon={<FcBusinessContact className="icone_menu" />}> EMPLOYES </MenuItem>}
                                            {String(menu) === "mes_employes" && <MenuItem onClick={DisplayeEmployeList} className="sousmenu_selected" icon={<FcBusinessContact className="icone_menu" />}> EMPLOYES  </MenuItem>}

                                            {String(menu) !== "mes_salles" && <MenuItem onClick={Display_mes_salles_func} className="sousmenu" icon={<FcReading className="icone_menu" />}> SALLES </MenuItem>}
                                            {String(menu) === "mes_salles" && <MenuItem onClick={Display_mes_salles_func} className="sousmenu_selected" icon={<FcReading className="icone_menu" />}> SALLES  </MenuItem>}

                                            {String(menu) !== "mon_materiel" && <MenuItem onClick={DisplayeMaterielList} className="sousmenu" icon={<FcVideoProjector className="icone_menu" />}> MON MATERIEL </MenuItem>}
                                            {String(menu) === "mon_materiel" && <MenuItem onClick={DisplayeMaterielList} className="sousmenu_selected" icon={<FcVideoProjector className="icone_menu" />}> MON MATERIEL  </MenuItem>}


                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>

                            <div>
                                <Sidebar className="my_sidebar">
                                    {String(menu) !== "module_agenda" && <Menu className="my_menu bck_menu_seul" id="my_planning" name="my_planning" onClick={module_agenda_func} > <CalendarMonthIcon className="icone_menu" color={"secondary"} /> &nbsp; &nbsp;PLANNING </Menu>}
                                    {String(menu) === "module_agenda" && <Menu className="my_menu bck_menu_seul_selected" id="my_planning" name="my_planning" onClick={module_agenda_func}> <CalendarMonthIcon className="icone_menu" color={"secondary"} />&nbsp; &nbsp;PLANNING </Menu>}

                                </Sidebar>

                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu bck_menu_seul" id="my_send_mail" name="my_send_mail" onClick={(e) => {
                                        setDialog_SEND_EMAIL_open(true);
                                    }} > <ForwardToInboxIcon className="icone_menu" color={"secondary"} /> &nbsp; &nbsp;ENV. MAIL </Menu>

                                </Sidebar>


                            </div>



                            <div class="separator"></div>
                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Partenaires" className="my_chart" id="my_partners" name="my_partners" icon={<HubIcon color={"tercier"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "mes_clients" && <MenuItem onClick={mes_clients_func} className="sousmenu" icon={<FcBusinesswoman className="icone_menu" />}> CLIENTS / FINANCEURS</MenuItem>}
                                            {String(menu) === "mes_clients" && <MenuItem onClick={mes_clients_func} className="sousmenu_selected" icon={<FcBusinesswoman className="icone_menu" />}>CLIENTS / FINANCEURS </MenuItem>}

                                            {String(menu) !== "mes_opportunites" && <MenuItem onClick={Display_mes_opportunites_func} className="sousmenu" icon={<MdAddShoppingCart className="icone_menu" />}> OPPORTUNITES </MenuItem>}
                                            {String(menu) === "mes_opportunites" && <MenuItem onClick={Display_mes_opportunites_func} className="sousmenu_selected" icon={<MdAddShoppingCart className="icone_menu" />}> OPPORTUNITES  </MenuItem>}

                                            {String(menu) !== "pricing" && <MenuItem onClick={pricing_func} className="sousmenu" icon={<FcCurrencyExchange className="icone_menu" />}> PRIX CLIENT </MenuItem>}
                                            {String(menu) === "pricing" && <MenuItem onClick={pricing_func} className="sousmenu_selected" icon={<FcCurrencyExchange className="icone_menu" />}> PRIX CLIENT </MenuItem>}


                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>

                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Factures" className="my_chart" id="my_invoices" name="my_invoices" icon={<EuroSharpIcon color={"tercier"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "mes_cmd" && <MenuItem onClick={mes_cmd_func} className="sousmenu" icon={<FcCollect className="icone_menu" />}> DEVIS & COMMANDES</MenuItem>}
                                            {String(menu) === "mes_cmd" && <MenuItem onClick={mes_cmd_func} className="sousmenu_selected" icon={<FcCollect className="icone_menu" />}>DEVIS & COMMANDES </MenuItem>}

                                            {String(menu) !== "factures_client" && <MenuItem onClick={mes_factures_client_func} className="sousmenu" icon={<FcKindle className="icone_menu" />}> FACTURES & AVOIRS </MenuItem>}
                                            {String(menu) === "factures_client" && <MenuItem onClick={mes_factures_client_func} className="sousmenu_selected" icon={<FcKindle className="icone_menu" />}> FACTURES & AVOIRS </MenuItem>}

                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>


                            <div>
                                <Sidebar className="my_sidebar">
                                    {String(menu) !== "prix_achat" && <Menu className="my_menu bck_menu_seul" id="my_prix_achat" name="my_prix_achat" onClick={prix_achat_func} > <LocalOfferIcon className="icone_menu" color={"tercier"} /> &nbsp; &nbsp;PRIX ACHAT </Menu>}
                                    {String(menu) === "prix_achat" && <Menu className="my_menu bck_menu_seul_selected" id="my_prix_achat" name="my_prix_achat" onClick={prix_achat_func} > <LocalOfferIcon className="icone_menu" color={"tercier"} />&nbsp; &nbsp;PRIX ACHAT  </Menu>}

                                </Sidebar>

                            </div>

                            <div class="separator"></div>

                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Espace E-Learning" className="my_chart" id="my_e_learning" name="my_e_learning" icon={<CameraOutdoorSharpIcon color={"quatro"} />} style={{ overflow: 'hidden' }}>


                                            {String(has_partner_lms_url) === "1" && <MenuItem onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                    partner_lms_url,
                                                    '_blank'
                                                );
                                            }} className="sousmenu" icon={<FcVideoCall className="icone_menu" />}> MON HEBERGEMENT </MenuItem>}



                                            {String(has_partner_lms_url) === "1" && String(menu) !== "setuplms" && <MenuItem id="my_setuplms" name="my_setuplms" onClick={DisplaySetUpLMS} className="sousmenu" icon={<FcKindle className="icone_menu" />}> CONFIGURATION </MenuItem>}
                                            {String(has_partner_lms_url) === "1" && String(menu) === "setuplms" && <MenuItem id="my_setuplms" name="my_setuplms" onClick={DisplaySetUpLMS} className="sousmenu_selected" icon={<FcKindle className="icone_menu" />}> CONFIGURATION  </MenuItem>}

                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>

                            <div class="separator"></div>
                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Tableaux de bord" className="my_chart" id="my_tbd" name="my_tbd" icon={<DashboardCustomizeIcon color={"cinqo"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "dashbord_formation" && <MenuItem onClick={config_dashbord_formation_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> FORMATION </MenuItem>}
                                            {String(menu) === "dashbord_formation" && <MenuItem onClick={config_dashbord_formation_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> FORMATION </MenuItem>}

                                            {String(menu) !== "dashbord_session" && <MenuItem onClick={config_dashbord_session_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}>SESSION </MenuItem>}
                                            {String(menu) === "dashbord_session" && <MenuItem onClick={config_dashbord_session_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> SESSION </MenuItem>}

                                            {String(menu) !== "dashbord_ressources_humaines" && <MenuItem onClick={config_dashbord_ressources_humaines_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> RES. HUMAINES </MenuItem>}
                                            {String(menu) === "dashbord_ressources_humaines" && <MenuItem onClick={config_dashbord_ressources_humaines_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> RES. HUMAINES </MenuItem>}

                                            {String(menu) !== "dashbord_inscriptions" && <MenuItem onClick={config_dashbord_inscriptions_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> INSCRIPTIONS </MenuItem>}
                                            {String(menu) === "dashbord_inscriptions" && <MenuItem onClick={config_dashbord_inscriptions_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> INSCRIPTIONS </MenuItem>}

                                            {String(menu) !== "dashbord_factures" && <MenuItem onClick={config_dashbord_factures_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> FACTURES  </MenuItem>}
                                            {String(menu) === "dashbord_factures" && <MenuItem onClick={config_dashbord_factures_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> FACTURES </MenuItem>}


                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>

                            <div class="separator"></div>
                            <div>
                                <Sidebar className="my_sidebar">
                                    <Menu className="my_menu">
                                        <SubMenu label="Configuration" className="my_chart" id="my_configuration" name="my_configuration" icon={<SettingsIcon color={"purple"} />} style={{ overflow: 'hidden' }}>
                                            {String(menu) !== "config_document" && <MenuItem onClick={config_document_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> DOCUMENTS & MAILS</MenuItem>}
                                            {String(menu) === "config_document" && <MenuItem onClick={config_document_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> DOCUMENTS & MAILS </MenuItem>}


                                            {String(document_super_admin) === "1" && String(menu) !== "super_admin_config_document" && <MenuItem onClick={config_document_super_admin_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> DOCUMENTS SUPERADMIN</MenuItem>}
                                            {String(document_super_admin) === "1" && String(menu) === "super_admin_config_document" && <MenuItem onClick={config_document_super_admin_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> DOCUMENTS SUPERADMIN </MenuItem>}



                                            {String(menu) !== "config_champ_specific" && <MenuItem onClick={config_specfic_fields_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> CHAMP SPECIFIQUE </MenuItem>}
                                            {String(menu) === "config_champ_specific" && <MenuItem onClick={config_specfic_fields_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> CHAMP SPECIFIQUE  </MenuItem>}

                                            {String(menu) !== "config_technique" && <MenuItem onClick={config_technique_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> TECHNIQUE </MenuItem>}
                                            {String(menu) === "config_technique" && <MenuItem onClick={config_technique_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> TECHNIQUE </MenuItem>}



                                            {String(menu) !== "config_session_steps" && <MenuItem onClick={config_session_steps_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> SESSION ETAPE </MenuItem>}
                                            {String(menu) === "config_session_steps" && <MenuItem onClick={config_session_steps_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> SESSION ETAPE </MenuItem>}


                                            {String(menu) !== "config_jours_heures" && <MenuItem onClick={config_jours_travaille_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> JOURS & HEURES </MenuItem>}
                                            {String(menu) === "config_jours_heures" && <MenuItem onClick={config_jours_travaille_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> JOURS & HEURES </MenuItem>}


                                            {String(menu) !== "config_formulaires" && <MenuItem onClick={config_formulaires_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> FORMULAIRES </MenuItem>}
                                            {String(menu) === "config_formulaires" && <MenuItem onClick={config_formulaires_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> FORMULAIRES </MenuItem>}


                                        </SubMenu>

                                    </Menu>
                                </Sidebar>

                            </div>

                            {String(formation_initiale) === "1" && <div>
                                <div class="separator"></div>
                                <div>
                                    <Sidebar className="my_sidebar">
                                        <Menu className="my_menu">
                                            <SubMenu label="Config. Ftion Initale" className="my_chart" id="my_configuration_ftion_initiale" name="my_configuration_ftion_initiale" icon={<SettingsIcon color={"purple"} />} style={{ overflow: 'hidden' }}>
                                                {String(menu) !== "config_formation_initiale" && <MenuItem onClick={config_ftion_initale_general_func} className="sousmenu" icon={<FcFinePrint className="icone_menu" />}> GENERAL </MenuItem>}
                                                {String(menu) === "config_formation_initiale" && <MenuItem onClick={config_ftion_initale_general_func} className="sousmenu_selected" icon={<FcFinePrint className="icone_menu" />}> GENERAL </MenuItem>}


                                            </SubMenu>

                                        </Menu>
                                    </Sidebar>

                                </div>
                            </div>}

                        </ThemeProvider>
                    </div>

                </div>}

                {
                    left_menu === true && <div>
                        {String(firstConnexion) === "1" && String(part_is_partner_admin_account) === "1" &&
                            <div className="div_droite firstConnexion">
                                <div className="firstConnexion_text">
                                    <b>Cher partenaire, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                    Nous sommes ravis de vous accueillir dans notre aventure MySy.
                                    Pour commencer à publier vos formations, veuillez choisir une formule d'abonnement en cliquant <Button onClick={ValideFirstConnexion} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; &nbsp; ici</Button><br />
                                    &nbsp; <br /> Merci.
                                </div>

                            </div>}

                        {String(firstConnexion) === "1" && String(part_is_partner_admin_account) !== "1" &&
                            <div className="div_droite firstConnexion">
                                <div className="firstConnexion_text">
                                    <b>Cher Utilisateur, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                    Nous sommes ravis de vous accueillir dans notre aventure MySy. Nous vous invitons à modifier votre mot de passe à partir du menu <i>"MES INFORMATIONS"</i> ==&gt; Bouton <i>"SECURITE"</i>  <br /><br />
                                    <Button onClick={(e) => setfirstConnexion()} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; Fermer</Button><br />
                                    &nbsp; <br /> Merci.
                                </div>

                            </div>}


                        {String(myApiResponse) === String("false") && <div className="div_droite">
                            <div className="koUpdateData"> Impossible de contacter  l'hote distant. Merci de contacter votre administrateur  </div>
                        </div>

                        }

                        {String(menu) === "affichage" &&
                            <div className="div_droite">

                                <PartnerTranings ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }


                        {String(menu) === "mes_ue" &&
                            <div className="div_droite">

                                <DisplayPartnerUnite_Enseignement ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "domaine_categorie_metier" &&
                            <div className="div_droite">

                                <Partner_Domaine_Categorie_Metier ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "part_produit_service" &&
                            <div className="div_droite">

                                <Partner_Product_Service ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }


                        {String(menu) === "config_formation_initiale" &&
                            <div className="div_droite">

                                <Partner_Config_Ftion_Initiale ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "note_evaluation" &&
                            <div className="div_droite">

                                <Notes_Evaluation ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "groupe_apprenant" &&
                            <div className="div_droite">

                                <Groupe_Apprenant ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }




                        {String(menu) === "checkout" && <div className="div_droite">
                            <Button onClick={"returntoorder"} className="btn_retour_ps"> Retour aux produits & Services </Button>
                            <CheckOut packs={location.state.new_pack} periodicity={location.state.period} mypackprice={location.state.price} />
                        </div>

                        }

                        {String(menu) === "creation" &&
                            <div className="div_droite">


                                <div className="parter_div_droite_haut">
                                    {String(apiexcelimportmessage) === String("true") &&
                                        <div className="parter_div_filtrer_row okUpdateData">
                                            {excelimportmessage}<br />&nbsp;<br />

                                        </div>

                                    }
                                    {String(apiexcelimportmessage) === String("false") &&
                                        <div className="parter_div_filtrer_row koUpdateData">
                                            Impossible de traiter le fichier <br />
                                            <u> <b> {excelimportmessage} </b></u>
                                            <br />&nbsp;<br />
                                        </div>

                                    }

                                    {String(apiexcelimportmessage) === String("-1") &&
                                        <div className="parter_div_filtrer_row koUpdateData">
                                            Impossible de traiter le fichier <br />&nbsp;<br />
                                        </div>

                                    }


                                    <div className="parter_div_filtrer_row">
                                        <div>


                                            {String(partnerstatus) === String("0") && <div className="parter_div_filtrer_row_gauche">

                                                <Button variant="outlined" onClick={changeHandler2} className="detail_class_submenu" style={{
                                                    "background": "#c8cfd5",
                                                    "minWidth": "15rem", "color": "black", "width": "auto"
                                                }}
                                                    id='menu_import_formation' name='menu_import_formation'>Choisir un fichier et importer des formations &nbsp; <SendIcon />
                                                </Button><br />
                                                {formation_file_name && <nav className="okUpdateData"><i>{formation_file_name}</i></nav>}
                                                <input type="file"
                                                    accept=".csv"
                                                    ref={hiddenFileInput_formation}
                                                    style={{ display: 'none' }}
                                                    name="liste_formation_file"
                                                    onChange={handleSubmission}
                                                />



                                                <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                                <br />

                                                {/* <input type="file" name="file" accept=".csv" onChange={changeHandler} /> <br /> */}
                                                <br />&nbsp;
                                                <div className="import_excel_text">

                                                    <nav className="titre1">  Comment utiliser le fichier ? </nav>
                                                    <nav style={{ "color": "orange" }}>
                                                        Dans le fichier à importer, seules les colonnes suivantes sont obligatoires  :
                                                        <ul>
                                                            <li>external_code : C'est le code de la formation chez vous, editeur de la formation </li>
                                                            <li> titre : Le titre de la formation </li>
                                                            <li> domaine : Le domaine au quel est rattaché la formation</li>
                                                            <li> description : La descriptin de la formation</li>
                                                        </ul>
                                                    </nav>
                                                    Pour le reste, <br />
                                                    <ul>
                                                        <li> Il s'agit d'un fichier csv. Les colonnes sont separées par des ";"</li>
                                                        <li> A l'interieur d'une même colonne, les informations sont separées par des ";"     </li>
                                                        <li> Champ 'Metier' :  les valeurs acceptées sont : IT, RH, vente, dev_perso, Graphisme, Management, Digital, Office, Autre</li>
                                                        <li> ...</li>
                                                    </ul>
                                                    <a href='/sample/MySy_Import_formation_csv.pdf' download>Télécharger le guide complet pour plus d'informations</a><br />
                                                </div>
                                                <div style={{ "width": "50%" }}> &nbsp; </div>





                                            </div>}

                                            {String(partnerstatus) !== String("0") && <div className="parter_div_filtrer_row_gauche">


                                                Import excel d'une formation <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                                <br />

                                                <input type="file" name="file" onChange={""} accept=".csv" />


                                                <br />
                                                <div align="right">
                                                    <Button onClick={alertactivecompte} variant="contained" endIcon={<SendIcon />}> Importer </Button>
                                                </div>

                                            </div>}
                                        </div>

                                        <br />

                                    </div>
                                </div>
                                {/* <div className="parter_div_droite_bas">
                                <div className="titre1_bis"> Création manuelle d'une formation </div>

                                <TrainingForm mytrainingclass={{ 'ref': '', 'dist': 'Oui', 'plus_produit': '0' }} ispending={partnerstatus}
                                    currentpack={String(partnerPackService).toUpperCase()} />
                            </div>*/}

                            </div>
                        }


                        {String(menu) === "infopartner" &&
                            <div className="div_droite">

                                <PartnerInfo check_user_acces_right={check_user_acces_right} />

                            </div>
                        }

                        {String(menu) === "statistique" &&
                            <div className="div_droite">
                                <PartenairStat />
                            </div>
                        }

                        {String(menu) === "setuplms" &&
                            <div className="div_droite">
                                <HebergementLms conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("lms", "read")} write_access={check_user_acces_right("lms", "write")} />
                            </div>
                        }


                        {String(menu) === "mes_sessions" &&
                            <div className="div_droite">

                                <DisplayPartnerSession conntected_employee_id={conntected_employee_id}
                                    object_key={orderid}
                                    class_internal_url={packs}
                                    read_access={check_user_acces_right("session", "read")} write_access={check_user_acces_right("session", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "mes_employes" &&
                            <div className="div_droite">
                                <Employes conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("employe", "read")} write_access={check_user_acces_right("employe", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "factures_client" &&
                            <div className="div_droite">
                                <Factures_Client conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("facture_client", "read")}
                                    write_access={check_user_acces_right("facture_client", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "prix_achat" &&
                            <div className="div_droite">
                                <Partner_Prix_Achat conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix_achat", "read")} write_access={check_user_acces_right("prix_achat", "write")} />
                            </div>
                        }


                        {String(menu) === "mon_materiel" &&
                            <div className="div_droite">
                                <Materiels conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")}
                                    write_access={check_user_acces_right("materiel", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }


                        {String(menu) === "mes_salles" &&
                            <div className="div_droite">
                                <Materiel_Salle conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")} write_access={check_user_acces_right("materiel", "write")} />
                            </div>
                        }

                        {String(menu) === "mes_opportunites" &&
                            <div className="div_droite">

                                <CRM_Opportunite conntected_employee_id={conntected_employee_id}
                                    read_access={check_user_acces_right("materiel", "read")}
                                    write_access={check_user_acces_right("materiel", "write")}
                                    reduce={"0"} />
                            </div>
                        }


                        {String(menu) === "mes_cmd" &&
                            <div className="div_droite">
                                <Partner_Commande conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("commande", "read")}
                                    write_access={check_user_acces_right("commande", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }


                        {String(menu) === "facture" && <div className="div_droite">

                            <Facture currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                read_access={check_user_acces_right("compte_partner", "read")} write_access={check_user_acces_right("compte_partner", "write")}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }

                        {String(menu) === "pricing" && <div className="div_droite">

                            <Pricing conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix", "read")} write_access={check_user_acces_right("prix", "write")} />
                        </div>
                        }

                        {String(menu) === "mes_apprenants" && <div className="div_droite">


                            <Apprenant conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                                read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }

                        {String(menu) === "mes_stagiaires" && <div className="div_droite">

                            <DisplayPartnerStagiaires conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                                read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")}
                                formation_initiale={formation_initiale}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }


                        {String(menu) === "abonnement" && <div className="div_droite">

                            <Abonnement currentpack={partnerPackService} conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("abonnement", "read")} write_access={check_user_acces_right("abonnement", "write")} />
                        </div>
                        }

                        {String(menu) === "ordervalide" && orderid && packs && <div className="div_droite">
                            <OrderConfirmation num_cmd={orderid} packs={String(packs).toLocaleUpperCase()} />
                        </div>
                        }

                        {String(menu) === "mes_clients" && <div className="div_droite">

                            <Partner_Client conntected_employee_id={conntected_employee_id} object_key={orderid}
                                read_access={check_user_acces_right("client", "read")} write_access={check_user_acces_right("client", "write")}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }



                        {String(menu) === "config_document" && <div className="div_droite">

                            <Config_Documents conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }

                        {String(menu) === "config_document_super_admin" && <div className="div_droite">

                            <Config_Documents_Super_Admin conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }


                        {String(menu) === "config_technique" && <div className="div_droite">

                            <Configuration_Technique conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }


                        {String(menu) === "config_session_steps" && <div className="div_droite">

                            <Partner_Configuration_Session_Steps conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }



                        {String(menu) === "config_jours_heures" && <div className="div_droite">

                            <Partner_Configuration_Jours_Travail conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }


                        {String(menu) === "config_formulaires" && <div className="div_droite">

                            <Partner_Config_Formulaires conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />

                        </div>
                        }


                        {String(menu) === "config_champ_specific" && <div className="div_droite">

                            <Config_Champs_Personnalise conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }


                        {String(menu) === "module_agenda" && <div className="div_droite">

                            <Module_Agenda conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("module_agenda", "read")} write_access={check_user_acces_right("module_agenda", "write")} />
                        </div>
                        }


                        {String(menu) === "dashbord_session" && <div className="div_droite">

                            <Dashbord_Session conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }

                        {String(menu) === "dashbord_formation" && <div className="div_droite">

                            <Dashbord_Formation conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }

                        {String(menu) === "dashbord_ressources_humaines" && <div className="div_droite">

                            <Dashbord_Ressources_Humaines conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }

                        {String(menu) === "dashbord_inscriptions" && <div className="div_droite">
                            <Dashbord_Inscriptions conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }


                        {String(menu) === "dashbord_factures" && <div className="div_droite">
                            <Dashbord_Factures conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }

                        {String(menu) === "mon_tableau_de_bord" && <div className="div_droite">

                            <Mon_Tableau_De_Bord conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />

                        </div>
                        }

                        {String(menu) === "monactivite" && <div className="div_droite">
                            <PartnerClientActivite conntected_employee_id={conntected_employee_id} check_user_acces_right={check_user_acces_right}
                                email={conntected_employee_email} related_collection={"partnair_account"} update_nb_activite_en_retard={update_nb_activite_en_retard}
                                update_nb_activite_en_new={update_nb_activite_en_new}

                            />
                        </div>
                        }



                    </div>
                }


                {/* div_gauche div_gauche_reduit */}
                {
                    left_menu === false && <div className="sub_dib_mobile"  >
                        <div className="div_gauche div_gauche_reduit" id="div_gauche">

                            <nav className="not_display_mobile">
                                {left_menu === true && <div style={{ "cursor": "pointer", textAlign: "left" }} onClick={open_close_left_menu}>
                                    <Tooltip className="tooltip_css" id="tooltip_id_0" />
                                    <a data-tooltip-id="tooltip_id_0" data-tooltip-html="Réduire le menu">
                                        <PiSlideshowFill />  &nbsp; Réduire
                                    </a>
                                </div>}
                                {left_menu === false && <div style={{ "cursor": "pointer", textAlign: "center" }}>
                                    <Tooltip className="tooltip_css" id="tooltip_id_1" />
                                    <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Agrandir le menu">
                                        <PiSlideshowThin onClick={open_close_left_menu} />
                                    </a>
                                </div>}
                            </nav>

                            <div> <hr /> </div>

                            <Tooltip className="tooltip_css" id="tooltip_id_2" />
                            <a data-tooltip-id="tooltip_id_2" data-tooltip-html="INFORMATIONS">
                                {String(menu) !== "infopartner" && <div className="sousmenu" onClick={PersonnalData}>   <FcHome className="icone_menu" /> &nbsp; </div>}
                                {String(menu) === "infopartner" && <div className="sousmenu_selected" onClick={PersonnalData}  >  <FcHome className="icone_menu" />  &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_22" />
                            <a data-tooltip-id="tooltip_id_22" data-tooltip-html="MON ACTIVITE">
                                {String(menu) !== "monactivite" && <div className="sousmenu" onClick={monactivite_func}>   <CircleNotificationsIcon className="icone_menu" /> &nbsp; </div>}
                                {String(menu) === "monactivite" && <div className="sousmenu_selected" onClick={monactivite_func}  >  <CircleNotificationsIcon className="icone_menu" />  &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />


                            <Tooltip className="tooltip_css" id="tooltip_id_3" />
                            <a data-tooltip-id="tooltip_id_3" data-tooltip-html="MON TABLEAU DE BORD">
                                {String(menu) !== "mon_tableau_de_bord" && <div className="sousmenu" onClick={mon_tableau_de_bord_func}>   <AiOutlineDashboard className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "mon_tableau_de_bord" && <div className="sousmenu_selected" onClick={mon_tableau_de_bord_func}  >  <AiOutlineDashboard className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_4" />
                            <a data-tooltip-id="tooltip_id_4" data-tooltip-html="PLANNING">
                                {String(menu) !== "module_agenda" && <div className="sousmenu" onClick={module_agenda_func}>   <FcCalendar className="icone_menu" /> &nbsp; </div>}
                                {String(menu) === "module_agenda" && <div className="sousmenu_selected" onClick={module_agenda_func}  >  <FcCalendar className="icone_menu" />&nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_5" />
                            <a data-tooltip-id="tooltip_id_5" data-tooltip-html="ABONNEMENT">
                                {String(menu) !== "abonnement" && <div className="sousmenu" onClick={Abonnement_func} > <FcKey className="icone_menu" />&nbsp; </div>}
                                {String(menu) === "abonnement" && <div className="sousmenu_selected" onClick={Abonnement_func} >  <FcKey className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_6" />
                            <a data-tooltip-id="tooltip_id_6" data-tooltip-html="FACTURE MYSY">
                                {String(menu) !== "facture" && <div className="sousmenu" onClick={DisplayFacure}  ><FcFeedback className="icone_menu" /> &nbsp;</div>}
                                {String(menu) === "facture" && <div className="sousmenu_selected" onClick={DisplayFacure}  > <FcFeedback className="icone_menu" />&nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <div> <hr /> </div>

                            {/* <Tooltip className="tooltip_css" id="tooltip_id_7" />
                        <a data-tooltip-id="tooltip_id_7" data-tooltip-html="CREER FORMATION">
                            {String(menu) !== "creation" && <div className="sousmenu" onClick={CreateTraining}> <FcAddDatabase className="icone_menu" /> &nbsp;</div>}
                            {String(menu) === "creation" && <div className="sousmenu_selected" onClick={CreateTraining}> <FcAddDatabase className="icone_menu" /> </div>}
                        </a>
                        <hr className="my_hr" />*/}

                            <Tooltip className="tooltip_css" id="tooltip_id_8" />
                            <a data-tooltip-id="tooltip_id_8" data-tooltip-html="FORMATIONS">
                                {String(menu) !== "affichage" && <div className="sousmenu" onClick={DiplaytrainingList}  > <FcList className="icone_menu" /> &nbsp;</div>}
                                {String(menu) === "affichage" && <div className="sousmenu_selected" onClick={DiplaytrainingList}  ><FcList className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_81" />
                            <a data-tooltip-id="tooltip_id_81" data-tooltip-html="UNITE D'ENSEIGNEMENT">
                                {String(menu) !== "mes_ue" && <div className="sousmenu" onClick={DiplayPartner_UE}  > <FcOrgUnit className="icone_menu" /> &nbsp;</div>}
                                {String(menu) === "mes_ue" && <div className="sousmenu_selected" onClick={DiplayPartner_UE}  ><FcOrgUnit className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />


                            <Tooltip className="tooltip_css" id="tooltip_id_82" />
                            <a data-tooltip-id="tooltip_id_82" data-tooltip-html="CARACTERISTIQUES">
                                {String(menu) !== "domaine_categorie_metier" && <div className="sousmenu" onClick={DiplayPartner_Domaine_Categorie_Metier}  > <FcEngineering className="icone_menu" /> &nbsp;</div>}
                                {String(menu) === "domaine_categorie_metier" && <div className="sousmenu_selected" onClick={DiplayPartner_Domaine_Categorie_Metier}  ><FcEngineering className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_821" />
                            <a data-tooltip-id="tooltip_id_821" data-tooltip-html="PRODUITS & SERVICES">
                                {String(menu) !== "part_produit_service" && <div className="sousmenu" onClick={DiplayPartner_Produits_Services}  > <LiaProductHunt className="icone_menu" /> &nbsp;</div>}
                                {String(menu) === "part_produit_service" && <div className="sousmenu_selected" onClick={DiplayPartner_Produits_Services}  ><LiaProductHunt className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />



                            <Tooltip className="tooltip_css" id="tooltip_id_9" />
                            <a data-tooltip-id="tooltip_id_9" data-tooltip-html="SESSIONS">
                                {String(menu) !== "mes_sessions" && <div className="sousmenu" onClick={DiplaySessionList}  > <FcFlowChart className="icone_menu" /> &nbsp; </div>}
                                {String(menu) === "mes_sessions" && <div className="sousmenu_selected" onClick={DiplaySessionList}  ><FcFlowChart className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_10" />
                            <a data-tooltip-id="tooltip_id_10" data-tooltip-html="STAGIAIRES">
                                {String(menu) !== "mes_stagiaires" && <div className="sousmenu" onClick={DiplayStagiaireList}  > <FcReading className="icone_menu" />&nbsp; </div>}
                                {String(menu) === "mes_stagiaires" && <div className="sousmenu_selected" onClick={DiplayStagiaireList}  ><FcReading className="icone_menu" />&nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_11" />
                            <a data-tooltip-id="tooltip_id_11" data-tooltip-html="APPRENANTS">
                                {String(menu) !== "mes_apprenants" && <div className="sousmenu" onClick={mes_apprenants_func}  > <PiStudent className="icone_menu" />&nbsp; </div>}
                                {String(menu) === "mes_apprenants" && <div className="sousmenu_selected" onClick={mes_apprenants_func}  ><PiStudent className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_112" />
                            <a data-tooltip-id="tooltip_id_112" data-tooltip-html="ENV. EMAIL">
                                <div className="sousmenu" onClick={(e) => {
                                    setDialog_SEND_EMAIL_open(true);
                                }}  > <ForwardToInboxIcon className="icone_menu" />&nbsp; </div>
                            </a>
                            <hr className="my_hr" />


                            <Tooltip className="tooltip_css" id="tooltip_id_111" />
                            {String(formation_initiale) === "1" && <a data-tooltip-id="tooltip_id_111" data-tooltip-html="GROUPE APPRENANTS">
                                {String(menu) !== "groupe_apprenant" && <div className="sousmenu" onClick={groupe_apprenant_func}  > <Groups2Icon className="icone_menu" />&nbsp; </div>}
                                {String(menu) === "groupe_apprenant" && <div className="sousmenu_selected" onClick={groupe_apprenant_func}  ><Groups2Icon className="icone_menu" /> &nbsp;</div>}
                            </a>}
                            <hr className="my_hr" />


                            <Tooltip className="tooltip_css" id="tooltip_id_12" />
                            <a data-tooltip-id="tooltip_id_12" data-tooltip-html="EMPLOYES">
                                {String(menu) !== "mes_employes" && <div className="sousmenu" onClick={DisplayeEmployeList}  > <GiTeacher className="icone_menu" />&nbsp; </div>}
                                {String(menu) === "mes_employes" && <div className="sousmenu_selected" onClick={DisplayeEmployeList}  ><GiTeacher className="icone_menu" /> &nbsp;</div>}
                            </a>
                            <hr className="my_hr" />

                            <Tooltip className="tooltip_css" id="tooltip_id_13" />
                            <a data-tooltip-id="tooltip_id_13" data-tooltip-html=" MON MATERIEL">
                                {String(menu) !== "mon_materiel" && <div className="sousmenu" onClick={DisplayeMaterielList}  > <BsTools className="icone_menu" />&nbsp; </div>}
                                {String(menu) === "mon_materiel" && <div className="sousmenu_selected" onClick={DisplayeMaterielList}  ><BsTools className="icone_menu" />&nbsp; </div>}
                            </a>
                            <hr className="my_hr" />


                            <Tooltip className="tooltip_css" id="tooltip_id_14" />
                            <a data-tooltip-id="tooltip_id_14" data-tooltip-html="STATISTIQUES">
                                {String(menu) !== "statistique" && <div className="sousmenu" onClick={DisplayStat}> <FcBullish className="icone_menu" />&nbsp;</div>}
                                {String(menu) === "statistique" && <div className="sousmenu_selected" onClick={DisplayStat}> <FcBullish className="icone_menu" />&nbsp;</div>}
                            </a>
                            <hr className="my_hr" />


                            <div>  <hr /> </div>

                            <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_15" />
                                <a data-tooltip-id="tooltip_id_15" data-tooltip-html="CLIENTS">
                                    {String(menu) !== "mes_clients" && <div className="sousmenu" onClick={mes_clients_func}><FcBusinesswoman className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "mes_clients" && <div className="sousmenu_selected" onClick={mes_clients_func}> <FcBusinesswoman className="icone_menu" />&nbsp;</div>}
                                </a>
                            </div>

                            <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_16a" />
                                <a data-tooltip-id="tooltip_id_16a" data-tooltip-html="OPPORTUNITES">
                                    {String(menu) !== "mes_opportunites" && <div className="sousmenu" onClick={Display_mes_opportunites_func}><MdAddShoppingCart className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "mes_opportunites" && <div className="sousmenu_selected" onClick={Display_mes_opportunites_func}> <MdAddShoppingCart className="icone_menu" />&nbsp;</div>}
                                </a>
                            </div>

                            <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_16" />
                                <a data-tooltip-id="tooltip_id_16" data-tooltip-html=" PRIX CLIENT">
                                    {String(menu) !== "pricing" && <div className="sousmenu" onClick={pricing_func} ><FcCurrencyExchange className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "pricing" && <div className="sousmenu_selected" onClick={pricing_func} > <FcCurrencyExchange className="icone_menu" />&nbsp;</div>}
                                </a>
                            </div>

                            <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_17" />
                                <a data-tooltip-id="tooltip_id_17" data-tooltip-html="COMMANDES & DEVIS">
                                    {String(menu) !== "mes_cmd" && <div className="sousmenu" onClick={mes_cmd_func}><FcCollect className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "mes_cmd" && <div className="sousmenu_selected" onClick={mes_cmd_func}>  <FcCollect className="icone_menu" />&nbsp;</div>}
                                </a>
                            </div>

                            <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_18" />
                                <a data-tooltip-id="tooltip_id_18" data-tooltip-html="FACTURES & AVOIRS">
                                    {String(menu) !== "factures_client" && <div className="sousmenu" onClick={mes_factures_client_func}><FcKindle className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "factures_client" && <div className="sousmenu_selected" onClick={mes_factures_client_func}>  <FcCollect FcKindle="icone_menu" />&nbsp;</div>}
                                </a>
                            </div>

                            <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_19" />
                                <a data-tooltip-id="tooltip_id_19" data-tooltip-html="PRIX ACHAT">
                                    {String(menu) !== "prix_achat" && <div className="sousmenu" onClick={prix_achat_func}><BiPurchaseTag className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "prix_achat" && <div className="sousmenu_selected" onClick={prix_achat_func}> <BiPurchaseTag FcKindle="icone_menu" />&nbsp;</div>}
                                </a>
                            </div>


                            <div>  <hr /> </div>


                            {String(has_partner_lms_url) === "1" && <div className="sousmenu" onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                    partner_lms_url,
                                    '_blank'
                                );
                            }}>
                                <Tooltip className="tooltip_css" id="tooltip_id_2" />
                                <a data-tooltip-id="tooltip_id_2" data-tooltip-html="HEBERGEMENT E-LEARNING">
                                    <FcVideoCall className="icone_menu" />
                                </a>
                            </div>}

                            {String(has_partner_lms_url) === "1" && <div>
                                <hr className="my_hr" />
                                <Tooltip className="tooltip_css" id="tooltip_id_20" />
                                <a data-tooltip-id="tooltip_id_20" data-tooltip-html="CONFIGURATION E-LEARNING">
                                    {String(menu) !== "statistique" && <div className="sousmenu" onClick={DisplaySetUpLMS}><FcServices className="icone_menu" />&nbsp;</div>}
                                    {String(menu) === "statistique" && <div className="sousmenu_hebergement_lms" onClick={DisplaySetUpLMS}>  <FcServices className="icone_menu" />&nbsp;</div>}

                                </a>
                            </div>}



                        </div>
                    </div>
                }

                {/* div_droite div_droite_ralonge */}
                {
                    left_menu === false && <div>

                        {String(firstConnexion) === "1" && String(part_is_partner_admin_account) === "1" &&
                            <div className="div_droite firstConnexion">
                                <div className="firstConnexion_text">
                                    <b>Cher partenaire, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                    Nous sommes ravis de vous accueillir dans notre aventure MySy.
                                    Pour commencer à publier vos formations, veuillez choisir une formule d'abonnement en cliquant <Button onClick={ValideFirstConnexion} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; &nbsp; ici</Button><br />
                                    &nbsp; <br /> Merci.
                                </div>

                            </div>}

                        {String(firstConnexion) === "1" && String(part_is_partner_admin_account) !== "1" &&
                            <div className="div_droite firstConnexion">
                                <div className="firstConnexion_text">
                                    <b>Cher Utilisateur, c'est votre première connexion, </b> <br /> &nbsp;<br />
                                    Nous sommes ravis de vous accueillir dans notre aventure MySy. Nous vous invitons à modifier votre mot de passe à partir du menu <i>"MES INFORMATIONS"</i> ==&gt; Bouton <i>"SECURITE"</i> <br /><br />
                                    <Button onClick={(e) => setfirstConnexion()} className="firstConnexion_bton"> &nbsp; &nbsp;<FaHandPointRight /> &nbsp; Fermer</Button><br />
                                    &nbsp; <br /> Merci.
                                </div>

                            </div>}


                        {String(myApiResponse) === String("false") && <div className="div_droite div_droite_ralonge">
                            <div className="koUpdateData"> Impossible de contacter  l'hote distant. Merci de contacter votre administrateur  </div>
                        </div>

                        }

                        {String(menu) === "affichage" &&
                            <div className="div_droite div_droite_ralonge">

                                <PartnerTranings ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "mes_ue" &&
                            <div className="div_droite div_droite_ralonge">
                                <DisplayPartnerUnite_Enseignement ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }


                        {String(menu) === "domaine_categorie_metier" &&
                            <div className="div_droite div_droite_ralonge">
                                <Partner_Domaine_Categorie_Metier ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "part_produit_service" &&
                            <div className="div_droite div_droite_ralonge">
                                <Partner_Product_Service ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }



                        {String(menu) === "config_formation_initiale" &&
                            <div className="div_droite div_droite_ralonge">

                                <Partner_Config_Ftion_Initiale ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "note_evaluation" &&
                            <div className="div_droite div_droite_ralonge">

                                <Notes_Evaluation ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }

                        {String(menu) === "groupe_apprenant" &&
                            <div className="div_droite div_droite_ralonge">

                                <Groupe_Apprenant ispending={partnerstatus} currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                    class_external_code={orderid} class_internal_url={packs} formation_initiale={formation_initiale} />
                            </div>
                        }


                        {String(menu) === "checkout" && <div className="div_droite div_droite_ralonge">
                            <Button onClick={"returntoorder"} className="btn_retour_ps"> Retour aux produits & Services </Button>
                            <CheckOut packs={location.state.new_pack} periodicity={location.state.period} mypackprice={location.state.price} />
                        </div>

                        }

                        {String(menu) === "creation" &&
                            <div className="div_droite div_droite_ralonge">


                                <div className="parter_div_droite_haut">
                                    {String(apiexcelimportmessage) === String("true") &&
                                        <div className="parter_div_filtrer_row okUpdateData">
                                            {excelimportmessage}<br />&nbsp;<br />

                                        </div>

                                    }
                                    {String(apiexcelimportmessage) === String("false") &&
                                        <div className="parter_div_filtrer_row koUpdateData">
                                            Impossible de traiter le fichier <br />
                                            <u> <b> {excelimportmessage} </b></u>
                                            <br />&nbsp;<br />
                                        </div>

                                    }

                                    {String(apiexcelimportmessage) === String("-1") &&
                                        <div className="parter_div_filtrer_row koUpdateData">
                                            Impossible de traiter le fichier <br />&nbsp;<br />
                                        </div>

                                    }


                                    <div className="parter_div_filtrer_row">
                                        <div>


                                            {String(partnerstatus) === String("0") && <div className="parter_div_filtrer_row_gauche">

                                                <Button variant="outlined" onClick={changeHandler2} className="detail_class_submenu" style={{
                                                    "background": "#c8cfd5",
                                                    "minWidth": "15rem", "color": "black", "width": "auto",

                                                }}
                                                    id='menu_import_formation' name='menu_import_formation'>Choisir un fichier et importer des formations &nbsp; <SendIcon />
                                                </Button><br />
                                                {formation_file_name && <nav className="okUpdateData"><i>{formation_file_name}</i></nav>}
                                                <input type="file"
                                                    accept=".csv"
                                                    ref={hiddenFileInput_formation}
                                                    style={{ display: 'none' }}
                                                    name="liste_formation_file"
                                                    onChange={handleSubmission}
                                                />



                                                <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                                <br />

                                                {/* <input type="file" name="file" accept=".csv" onChange={changeHandler} /> <br /> */}
                                                <br />&nbsp;
                                                <div className="import_excel_text">

                                                    <nav className="titre1">  Comment utiliser le fichier ? </nav>
                                                    <nav style={{ "color": "orange" }}>
                                                        Dans le fichier à importer, seules les colonnes suivantes sont obligatoires :
                                                        <ul>
                                                            <li>external_code : C'est le code de la formation chez vous, editeur de la formation </li>
                                                            <li> titre : Le titre de la formation </li>
                                                            <li> domaine : Le domaine au quel est rattaché la formation</li>
                                                            <li> description : La descriptin de la formation</li>
                                                        </ul>
                                                    </nav>
                                                    Pour le reste, <br />
                                                    <ul>
                                                        <li> Il s'agit d'un fichier csv. Les colonnes sont separées par des ";"</li>
                                                        <li> A l'interieur d'une même colonne, les informations sont separées par des ";"     </li>
                                                        <li> Champ 'Metier' :  les valeurs acceptées sont : IT, RH, vente, dev_perso, Graphisme, Management, Digital, Office, Autre</li>
                                                        <li> ...</li>
                                                    </ul>
                                                    <a href='/sample/MySy_Import_formation_csv.pdf' download>Télécharger le guide complet pour plus d'informations</a><br />
                                                </div>
                                                <div style={{ "width": "50%" }}> &nbsp; </div>





                                            </div>}

                                            {String(partnerstatus) !== String("0") && <div className="parter_div_filtrer_row_gauche">


                                                Import excel d'une formation <a href='/sample/template_import_formations.csv' download>Télécharger un fichier modèle</a>

                                                <br />

                                                <input type="file" name="file" onChange={""} accept=".csv" />


                                                <br />
                                                <div align="right">
                                                    <Button onClick={alertactivecompte} variant="contained" endIcon={<SendIcon />}> Importer </Button>
                                                </div>

                                            </div>}
                                        </div>

                                        <br />

                                    </div>
                                </div>
                                <div className="parter_div_droite_bas">
                                    <div className="titre1_bis"> Création manuelle d'une formation </div>

                                    <TrainingForm mytrainingclass={{ 'ref': '', 'dist': 'Oui', 'plus_produit': '0' }} ispending={partnerstatus}
                                        currentpack={String(partnerPackService).toUpperCase()} check_user_acces_right={check_user_acces_right} />
                                </div>

                            </div>
                        }


                        {String(menu) === "infopartner" &&
                            <div className="div_droite div_droite_ralonge">

                                <PartnerInfo check_user_acces_right={check_user_acces_right} />

                            </div>
                        }

                        {String(menu) === "statistique" &&
                            <div className="div_droite div_droite_ralonge">
                                <PartenairStat />
                            </div>
                        }

                        {String(menu) === "setuplms" &&
                            <div className="div_droite div_droite_ralonge">
                                <HebergementLms conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("lms", "read")} write_access={check_user_acces_right("lms", "write")} />
                            </div>
                        }


                        {String(menu) === "mes_sessions" &&
                            <div className="div_droite div_droite_ralonge">

                                <DisplayPartnerSession conntected_employee_id={conntected_employee_id} object_key={orderid} class_internal_url={packs}
                                    read_access={check_user_acces_right("session", "read")} write_access={check_user_acces_right("session", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "mes_employes" &&
                            <div className="div_droite div_droite_ralonge">
                                <Employes conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("employe", "read")}
                                    write_access={check_user_acces_right("employe", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "factures_client" &&
                            <div className="div_droite div_droite_ralonge">
                                <Factures_Client conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("facture_client", "read")}
                                    write_access={check_user_acces_right("facture_client", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "prix_achat" &&
                            <div className="div_droite div_droite_ralonge">
                                <Partner_Prix_Achat conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix_achat", "read")} write_access={check_user_acces_right("prix_achat", "write")} />
                            </div>
                        }


                        {String(menu) === "mon_materiel" &&
                            <div className="div_droite div_droite_ralonge">
                                <Materiels conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")}
                                    write_access={check_user_acces_right("materiel", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }


                        {String(menu) === "mes_salles" &&
                            <div className="div_droite div_droite_ralonge">
                                <Materiel_Salle conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")}
                                    write_access={check_user_acces_right("materiel", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }

                        {String(menu) === "mes_opportunites" &&
                            <div className="div_droite div_droite_ralonge">
                                <CRM_Opportunite conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("materiel", "read")} write_access={check_user_acces_right("materiel", "write")} />
                            </div>
                        }


                        {String(menu) === "mes_cmd" &&
                            <div className="div_droite div_droite_ralonge">
                                <Partner_Commande conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("commande", "read")}
                                    write_access={check_user_acces_right("commande", "write")}
                                    check_user_acces_right={check_user_acces_right} />
                            </div>
                        }


                        {String(menu) === "facture" && <div className="div_droite div_droite_ralonge">

                            <Facture currentpack={partnerPackService} conntected_employee_id={conntected_employee_id}
                                read_access={check_user_acces_right("compte_partner", "read")} write_access={check_user_acces_right("compte_partner", "write")}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }

                        {String(menu) === "pricing" && <div className="div_droite div_droite_ralonge">

                            <Pricing conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("prix", "read")} write_access={check_user_acces_right("prix", "write")} />
                        </div>
                        }

                        {String(menu) === "mes_apprenants" && <div className="div_droite div_droite_ralonge">

                            <Apprenant conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                                read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }

                        {String(menu) === "mes_stagiaires" && <div className="div_droite div_droite_ralonge">

                            <DisplayPartnerStagiaires conntected_employee_id={conntected_employee_id} object_key={orderid} subdata={packs}
                                read_access={check_user_acces_right("stagiaire", "read")} write_access={check_user_acces_right("stagiaire", "write")}
                                formation_initiale={formation_initiale} check_user_acces_right={check_user_acces_right} />
                        </div>
                        }


                        {String(menu) === "abonnement" && <div className="div_droite div_droite_ralonge">

                            <Abonnement currentpack={partnerPackService} conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("abonnement", "read")} write_access={check_user_acces_right("abonnement", "write")} />
                        </div>
                        }

                        {String(menu) === "ordervalide" && orderid && packs && <div className="div_droite div_droite_ralonge">
                            <OrderConfirmation num_cmd={orderid} packs={String(packs).toLocaleUpperCase()} />
                        </div>
                        }

                        {String(menu) === "mes_clients" && <div className="div_droite div_droite_ralonge">

                            <Partner_Client conntected_employee_id={conntected_employee_id} object_key={orderid}
                                read_access={check_user_acces_right("client", "read")} write_access={check_user_acces_right("client", "write")}
                                check_user_acces_right={check_user_acces_right} />
                        </div>
                        }



                        {String(menu) === "config_document" && <div className="div_droite div_droite_ralonge">

                            <Config_Documents conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }

                        {String(menu) === "config_technique" && <div className="div_droite div_droite_ralonge">

                            <Configuration_Technique conntected_employee_id={conntected_employee_id}
                                read_access={check_user_acces_right("config_document", "read")}
                                write_access={check_user_acces_right("config_document", "write")}
                                reduce={"1"} />
                        </div>
                        }


                        {String(menu) === "config_session_steps" && <div className="div_droite div_droite_ralonge">

                            <Partner_Configuration_Session_Steps conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }



                        {String(menu) === "config_jours_heures" && <div className="div_droite div_droite_ralonge">

                            <Partner_Configuration_Jours_Travail conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }


                        {String(menu) === "config_formulaires" && <div className="div_droite div_droite_ralonge">

                            <Partner_Config_Formulaires conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />

                        </div>
                        }


                        {String(menu) === "config_champ_specific" && <div className="div_droite div_droite_ralonge">

                            <Config_Champs_Personnalise conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("config_document", "read")} write_access={check_user_acces_right("config_document", "write")} />
                        </div>
                        }


                        {String(menu) === "module_agenda" && <div className="div_droite div_droite_ralonge">

                            <Module_Agenda conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("module_agenda", "read")} write_access={check_user_acces_right("module_agenda", "write")} />
                        </div>
                        }


                        {String(menu) === "dashbord_session" && <div className="div_droite div_droite_ralonge">

                            <Dashbord_Session conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }

                        {String(menu) === "dashbord_formation" && <div className="div_droite div_droite_ralonge">

                            <Dashbord_Formation conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }

                        {String(menu) === "dashbord_ressources_humaines" && <div className="div_droite div_droite_ralonge">

                            <Dashbord_Ressources_Humaines conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />
                        </div>
                        }


                        {String(menu) === "mon_tableau_de_bord" && <div className="div_droite div_droite_ralonge">
                            <Mon_Tableau_De_Bord conntected_employee_id={conntected_employee_id} read_access={check_user_acces_right("dashbord", "read")} write_access={check_user_acces_right("dashbord", "write")} />

                        </div>
                        }

                        {String(menu) === "monactivite" && <div className="div_droite div_droite_ralonge">
                            <PartnerClientActivite conntected_employee_id={conntected_employee_id} check_user_acces_right={check_user_acces_right}
                                email={conntected_employee_email} related_collection={"partnair_account"} update_nb_activite_en_retard={update_nb_activite_en_retard}
                                update_nb_activite_en_new={update_nb_activite_en_new} />
                        </div>
                        }



                    </div>
                }


                <div className="pieddepage">
                    <Footer />
                </div>

            </div >

        </div >


    )
}


export default Partner;