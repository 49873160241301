import React from "react";

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const ToggleSwitch_v3_mysy = ({ label, checked, mysy_type }) => {
  return (
    <div className="toggleswitch2_mysy">
      <div className="container">

        <nav style={{ "width": "100%", "float": "right", "textAlign": 'right', "maxHeight": "2.5rem" }}>
          <div className="toggle-switch">


            {mysy_type && String(mysy_type) === "2" &&
              <Button variant="contained" style={{"background":'#218e1f', }} className="label">
              <nav style={{fontSize:"10px", color:"white"}}> Oui &nbsp;</nav>
              </Button>
            }


            {mysy_type && String(mysy_type) === "1" &&
              <Button variant="contained"  className="label mysy_color_gradia_yellox_green"> 
                <nav  style={{fontSize:"10px", color:"black"}}> Part </nav>
              </Button>
            }

            {mysy_type && String(mysy_type) === "0" &&
              <Button variant="contained" style={{"background":'#bbb'}}  className="label">
              <nav  style={{fontSize:"10px", color:"black"}}> Non &nbsp; </nav>
              </Button>
            }

            
          </div>
        </nav>
      </div>
    </div>
  );
};

export default ToggleSwitch_v3_mysy;