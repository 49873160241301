import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import {
	Box,
	Container,
	Row,
	Column,
	FooterLink,
	Heading,
} from "./FooterStyles";

const Fotter_v3 = () => {

	// Integration du gestionnaire de cookie : axeptio

	window.axeptioSettings = {
		clientId: "6273ecd99c50de9cfb190555",
		cookiesVersion: "mysy training technology-fr",
	};

	(function (d, s) {
		var t = d.getElementsByTagName(s)[0], e = d.createElement(s);

		e.async = true; e.src = "//static.axept.io/sdk-slim.js";
		t.parentNode.insertBefore(e, t);
	})(document, "script");

 
	return (
		<div className='footer'>
			<div  style={{ "minHeight": "5rem", "width":"100%" }}>
				&nbsp;
			</div>

			<div className='Box_div'>
				<div className='Container_div'>
					<div className='Row_Div'>
						<div className='Column_div'>
							<div className='Heading_p'>MySy Training</div>
							<a href="/qui-sommes-nous/#en_4_points" className='FooterLink_a'>À propos de nous</a>
							<a href="/qui-sommes-nous/#notre_objectif" className='FooterLink_a'>Notre vision</a>
							<a href="/ContactezNous" className='FooterLink_a'>Nous contacter</a>
						</div>

						<div className='Column_div'>
							<div className='Heading_p'>Prix </div>
							<a href="/Produits-Services" className='FooterLink_a'>Nos packs</a>
							<a href="/Produits-Services" className='FooterLink_a'>Publier votre catalogue	</a>
							<a href="/Recherche-Article-formation" className='FooterLink_a'>Le blog	</a>
							
						</div>

						

						<div className='Column_div'>
							<div className='Heading_p'>Social Media</div>
							<div style={{ 'float': "center", "textAlign": "center" }}>
                                <div className="media_sociaux">
                                    <FacebookShareButton
                                        url={"https://www.facebook.com/MySy-Training-114572794561371/"}
                                    >
                                        <SocialIcon network="facebook" className="SocialIcon" />
                                    </FacebookShareButton>
                                    &nbsp;

                                    <TwitterShareButton
                                        url={"https://twitter.com/mysy_training"}
                                        hashtag={"#MySyTraining"}
                                    >
                                        <SocialIcon network="twitter" className="SocialIcon" />
                                    </TwitterShareButton>

                                    &nbsp;
                                    <LinkedinShareButton
                                        url={"https://www.linkedin.com/"}
                                        summary={"ee"}
                                        source={"https://www.linkedin.com/in/mysy-training-96b53a237/"}

                                    >
                                        <SocialIcon network="linkedin" className="SocialIcon" />
                                    </LinkedinShareButton>


                                </div>

                            </div>
							{/*
							<a href="https://www.facebook.com/profile.php?id=100080411044908" className='FooterLink_a'>
								<i className="fab fa-facebook-f">
									<span style={{ marginLeft: "10px" }}>
										Facebook
									</span>
								</i>
							</a>
							<a href="https://www.linkedin.com/in/mysy-training-96b53a237/" className='FooterLink_a'>
								<i className="fab fa-instagram">
									<span style={{ marginLeft: "10px" }}>
										LinkedIn
									</span>
								</i>
							</a>
							<a href="https://twitter.com/mysy_training" className='FooterLink_a'>
								<i className="fab fa-twitter">
									<span style={{ marginLeft: "10px" }}>
										Twitter
									</span>
								</i>
	</a>*/}
							
						</div>
					</div>
				</div>
				<br />
				<hr />
				<i className='copyright'>
				Copyright © 2024 MySy. Tous droits réservés. &nbsp; 
				<a href='/sample/MYSY_POLITIQUE_CONFIDENTIALITE.pdf' download target = "_blank">| Confidentialité | Cookies | données personnelles &nbsp; </a>
				<a href='/sample/MYSY_CGU.pdf' download target = "_blank">| Conditions d’utilisation &nbsp;  </a>
				<a href='/sample/MYSY_MENTIONS_LEGALES.pdf' download target = "_blank">| Mentions légales </a>
				<a href='/sample/Conditions_Generales-Vente_CGV.pdf' download target = "_blank">| CGV </a>
				</i>
			</div>

		</div>

	);
};
export default Fotter_v3;
