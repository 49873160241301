
import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'

import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import Autocomplete from '@mui/material/Autocomplete';
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

const Module_Attestation_Formation = (props) => {

    const myconntected_employee_id = props.conntected_employee_id;

    const [selected_employee_id, setselected_employee_id] = useState(props.conntected_employee_id);

    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };
    function Close_Dialog_Detail_Event_open() {
        setDialog_1_open(false);
    }


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }



    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }


    const columns_attestations = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'email', headerName: 'email', minwidth: 200, flex : 1, hideable: true, resizable: true, },
        { field: 'nom', headerName: 'nom',minwidth: 200, flex : 1, hide: false, editable: true, },
        { field: 'prenom', headerName: 'prenom',minwidth: 200, flex : 1, hide: false, editable: false, },
        {
            field: 'status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Init";
                else if (String(params.value) === "1")
                    return "Envoyé";
                else
                    return " ?";
            },
        },
        {
            field: 'date_envoie', headerName: 'Date Envoi', width: 150, editable: false,
            valueFormatter: (params) => {
                return String(params.value).substring(0, 16)

            },
        },
        { field: 'courrier_template_id', headerName: 'Modèle', width: 0, editable: false, hide: true },
        { field: 'courrier_template_nom', headerName: 'Modèle', width: 150, editable: false, flex: true, },
        {/*
            field: "voir", headerName: 'voir',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            //handleClick_valide_emargement(event, cellValues);


                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        */ },

        {/*
            field: "envoyer", headerName: 'Envoyer',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            //handleClick_valide_emargement(event, cellValues);


                        }}
                    >
                        <FcApproval />

                    </Button>

                );
            }
        */},

    ]

    const New_Option_attestation_Masse = [
        { "id": "resend", "label": "(R)envoyer", "value": "resend" },
    ]
    const [gridline_id, setgridline_id] = useState("");

    const [Getall_Attestation_By_Session_api, setGetall_Attestation_By_Session_api] = useState();
    const [Getall_Attestation_By_Session_message, setGetall_Attestation_By_Session_message] = useState();
    const [Getall_Attestation_By_Session_result, setGetall_Attestation_By_Session_result] = useState([]);
    function Getall_Attestation_By_Session(event) {

        setselectionModel_attestation([])

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Attestion_By_Session/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Attestation_By_Session  res.data.status = " + res.data.status);
                //console.log(" In Getall_Attestation_By_Session  res.data.message r_class = " + res.data.message);
                setGetall_Attestation_By_Session_api("true");
                setGetall_Attestation_By_Session_result(res.data.message);


            }
            else {
                setGetall_Attestation_By_Session_api("false");
                setGetall_Attestation_By_Session_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Attestation_By_Session = ', error);
            setGetall_Attestation_By_Session_api("false");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {
        setselected_session_id(props.related_collection_recid);
        Getall_Attestation_By_Session();

    }, [])


    const [actionmass_attesation_val, setactionmass_attesation_val] = useState();
    const [selected_session_id, setselected_session_id] = useState("");

    function GetSelectedRows_Attesation_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_attestation.length; i++) {
            var myid = parseInt(String(selectionModel_attestation[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }


    const [CreateLis_Attestation_Ftion_api, setCreateLis_Attestation_Ftion_api] = useState();
    const [CreateLis_Attestation_Ftion_message, setCreateLis_Attestation_Ftion_message] = useState();
    const [CreateLis_Attestation_Ftion_result, setCreateLis_Attestation_Ftion_result] = useState();
    function CreateLis_Attestation_Ftion(tab_inscription) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_inscriptions_ids", tab_inscription);
        form.append("courrier_template_id", selected_convention_id);

        //alert(" mysession = "+mysession);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Init_Attestation_Formation_With_Template/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreateLis_Attestation_Ftion_api("true");
                setCreateLis_Attestation_Ftion_result(res.data.message);
                Getall_Attestation_By_Session();
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreateLis_Attestation_Ftion_api("false");
                setCreateLis_Attestation_Ftion_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('CreateLis_Attestation_Ftion : Not good man :( mysearchtext = ' + error);
            setCreateLis_Attestation_Ftion_api("false");
            alert(" Impossible d'initialiser la liste des attestations");

        })
    }


    const [CreateLis_Attestation_Ftion_For_Session_api, setCreateLis_Attestation_Ftion_For_Session_api] = useState();
    const [CreateLis_Attestation_Ftion_For_Session_message, setCreateLis_Attestation_Ftion_For_Session_message] = useState();
    const [CreateLis_Attestation_Ftion_For_Session_result, setCreateLis_Attestation_Ftion_For_Session_result] = useState();
    function CreateLis_Attestation_Ftion_For_Session() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);
        form.append("courrier_template_id", selected_convention_id);

        //alert(" mysession = "+mysession);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Init_Attestation_Formation_With_Template_For_All_Inscription/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreateLis_Attestation_Ftion_For_Session_api("true");
                setCreateLis_Attestation_Ftion_For_Session_result(res.data.message);
                Getall_Attestation_By_Session();
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreateLis_Attestation_Ftion_For_Session_api("false");
                setCreateLis_Attestation_Ftion_For_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('CreateLis_Attestation_Ftion_For_Session : Not good man :( mysearchtext = ' + error);
            setCreateLis_Attestation_Ftion_For_Session_api("false");
            alert(" Impossible d'initialiser la liste des attestations");

        })
    }


    const [Send_Attestation_By_Email_For_Ids_api, setSend_Attestation_By_Email_For_Ids_api] = useState();
    const [Send_Attestation_By_Email_For_Ids_message, setSend_Attestation_By_Email_For_Ids_message] = useState();
    const [Send_Attestation_By_Email_For_Ids_result, setSend_Attestation_By_Email_For_Ids_result] = useState();
    function Send_Attestation_By_Email_For_Ids(tab_inscription) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);
        form.append("email_test", "");
        form.append("email_production", "");
        form.append("tab_inscriptions_ids", tab_inscription);


        //alert(" mysession = "+mysession);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Attestation_From_Session_By_Email/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSend_Attestation_By_Email_For_Ids_api("true");
                setSend_Attestation_By_Email_For_Ids_result(res.data.message);
                Getall_Attestation_By_Session();
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSend_Attestation_By_Email_For_Ids_api("false");
                setSend_Attestation_By_Email_For_Ids_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Send_Attestation_By_Email_For_Ids : Not good man :( mysearchtext = ' + error);
            setSend_Attestation_By_Email_For_Ids_api("false");
            alert(" Impossible d'envoyer les attestations");

        })
    }

    const [selectionModel_attestation, setselectionModel_attestation] = React.useState([]);

    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();

    async function actionmass_ftion_Traitemet() {

        if (String(actionmass_attesation_val) === String("reinit")) {

            alert(" ### reinit");

        }

        else if (String(actionmass_attesation_val) === String("resend")) {
            var tab_ids_inscription = []
            for (var i = 0; i < selectionModel_attestation.length; i++) {
                var myid = parseInt(String(selectionModel_attestation[i]));
                var line = JSON.parse(Getall_Attestation_By_Session_result[myid]);
                var line_id = JSON.parse(Getall_Attestation_By_Session_result[myid])._id;

                tab_ids_inscription.push(line_id);
            }


            Send_Attestation_By_Email_For_Ids(tab_ids_inscription);

        }

    }


    const [Dialog_LIST_ATTESTATION_open, setDialog_LIST_ATTESTATION_open] = React.useState(false);
    const Dialog_LIST_ATTESTATION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_LIST_ATTESTATION_handleClose_buton = () => {
        setDialog_LIST_ATTESTATION_open(false);
    };


    const New_Option_Type_Document = [
        { "id": "email", "label": "Email", "value": "email" },

    ]

    const [selected_convention_id, setselected_convention_id] = useState();
    const [selected_convention_type, setselected_convention_type] = useState("");
    const [selected_convention_email_destinataire_prod, setselected_convention_email_destinataire_prod] = useState("");
    const [selected_convention_email_test, setselected_convention_email_test] = useState("");

    const [New_Get_List_Stagiaire_Attestation_Ftion_result, setNew_Get_List_Stagiaire_Attestation_Ftion_result] = useState([]);

    const [Get_List_Stagiaire_Attestation_Ftion_api, setGet_List_Stagiaire_Attestation_Ftion_api] = useState();
    const [Get_List_Stagiaire_Attestation_Ftion_message, setGet_List_Stagiaire_Attestation_Ftion_message] = useState();
    const [Get_List_Stagiaire_Attestation_Ftion_result, setGet_List_Stagiaire_Attestation_Ftion_result] = useState();
    function Get_List_Stagiaire_Attestation_Ftion(ref_interne, nom, type_doc, courrier_template_type_document_ref_interne) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type_doc", type_doc);
        form.append("courrier_template_type_document_ref_interne", "ATTESTATION_FORMATION");



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Modele_Attestion_Formation_With_Filter/";
        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Stagiaire_Attestation_Ftion  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Stagiaire_Attestation_Ftion  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_List_Stagiaire_Attestation_Ftion_api("true");
                setGet_List_Stagiaire_Attestation_Ftion_result(res.data.message);

                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Stagiaire_Attestation_Ftion_result(new_data2);

            }
            else {
                setGet_List_Stagiaire_Attestation_Ftion_api("false");
                setGet_List_Stagiaire_Attestation_Ftion_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Stagiaire_Attestation_Ftion = ', error);
            setGet_List_Stagiaire_Attestation_Ftion_api("false");
            alert("Impossible de récupérer les modèles de convocations");
            //setmyApimyApiMessage("")
        })
    }


    const [action_server_to_run, setaction_server_to_run] = useState("");
    const [action_server_type_doc, setaction_server_type_doc] = useState("");
    const [action_server_nom_doc, setaction_server_nom_doc] = useState("");
    const [action_server_ref_interne_doc, setaction_server_ref_interne_doc] = useState("");
    const [action_server_type_doc_ref_interne, setaction_server_type_doc_ref_interne] = useState("");

    return (

        <div className="module_attestation_formation">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

                className="module_agenda"

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" >

                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Action a traiter </nav>

                    </div>

                    <div className="session_caract_Dialog" >  <b>Action a traiter </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={"action_server_to_run"}


                        />
                    </div>




                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={"Update_One_Agenda_Event"} className="bton_enreg_dialog">Mettre à jour</Button>

                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Event_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            {/*  Dialog pour gerer les LIST_ATTESTATION */}
            <Dialog
                open={Dialog_LIST_ATTESTATION_open}
                onClose={Dialog_LIST_ATTESTATION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Type Document<br />

                        <Autocomplete
                            disablePortal
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="event_dialog_type_convention"
                            id="event_dialog_type_convention"
                            fullWidth
                            options={New_Option_Type_Document}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setselected_convention_type(value.value);
                                    Get_List_Stagiaire_Attestation_Ftion(action_server_ref_interne_doc, action_server_nom_doc,
                                        String(value.value), Dialog_1_message);

                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps = {{...params.inputProps, style: { fontSize: 12, height:"1.4rem" }}}

                                    />
                            }
                        />



                    </div>

                    {(String(selected_convention_type) === "email" || String(selected_convention_type) === "pdf") && <div className="session_caract_Dialog" > Choisir une attestation de formation
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_convention"
                            id="event_dialog_version_convention"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Get_List_Stagiaire_Attestation_Ftion_result}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_convention_id(value._id);

                                    if (String(value._id) === "default_mail") {
                                        setselected_convention_type("email");
                                    }
                                    else if (String(value._id) === "default_pdf") {
                                        setselected_convention_type("pdf");
                                    } else {
                                        var result2 = Get_List_Stagiaire_Attestation_Ftion_result.filter((local_mymanager) => JSON.parse(local_mymanager)._id === String(value._id))
                                        setselected_convention_type(JSON.parse(result2).type_doc);
                                    }

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps = {{...params.inputProps, style: { fontSize: 12, height:"1.4rem" }}}

                                    />
                            }
                        />

                    </div>}

                   

                    {String(selected_convention_type) === "pdf" && <div className="session_caract_Dialog" > Recevoir un pré-envoie
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_email_test_convention"
                            id="event_dialog_email_test_convention"
                            fullWidth
                            value=""

                        />

                    </div>
                    }

               
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            {String(selected_convention_type) === "email" && <Button onClick={(e) => {

                                var tab_ids_inscription = []
                                for (var i = 0; i < selectionModel_attestation.length; i++) {
                                    var myid = parseInt(String(selectionModel_attestation[i]));
                                    var line = JSON.parse(selectionModel_attestation[myid]);
                                    var line_id = JSON.parse(selectionModel_attestation[myid])._id;
                                    tab_ids_inscription.push(line_id);
                                }



                                CreateLis_Attestation_Ftion_For_Session();
                                Dialog_LIST_ATTESTATION_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Initialiser la liste </Button>}


                            {String(selected_convention_type) === "pdf" &&
                                <Button className="bton_enreg_dialog" onClick={(e) => {

                                    var tab_ids_inscription = []
                                    for (var i = 0; i < selectionModel_attestation.length; i++) {
                                        var myid = parseInt(String(selectionModel_attestation[i]));
                                        var line = JSON.parse(selectionModel_attestation[myid]);
                                        var line_id = JSON.parse(selectionModel_attestation[myid])._id;
                                        tab_ids_inscription.push(line_id);
                                    }



                                    CreateLis_Attestation_Ftion_For_Session();
                                    Dialog_LIST_ATTESTATION_handleClose_buton();
                                }}
                                >Initialiser </Button>}

                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_LIST_ATTESTATION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les LIST_ATTESTATION */}


            <div>

                <div>
                    <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>

                        <Popup
                            trigger={
                                <Button className="bton_emarge">Initialiser la liste</Button>
                            }
                            modal
                            nested
                            position="center center"

                        >

                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}


                                        <font> Confirmez la (ré)initialisation de la liste d'émargement. <br />
                                            <font color="red">  /!\ Si un tableau d'émargement existe pour cette session, il sera supprimé et récréé </font>
                                        </font>


                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                setDialog_LIST_ATTESTATION_open(true);
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    //console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>



                        <Button className="bton_emarge" onClick={(event) => {
                            Getall_Attestation_By_Session();
                        }}>Actualiser la liste</Button><br />
                    </div>
                    <div className="session_data">
                        <div style={{ "border": "None", marginLeft: '5px', marginRight: '5px' }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',


                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        
                                        
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                        
                                        '& .line--attestion_envoye': {
                                            backgroundColor: '#ccffcc',
                                            color: 'black',
                                            fontWeight: '600',
                                        },

                                    }} 
                                >
                                    <DataGrid
                                        checkboxSelection

                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_attestation(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_session_From_Line(newSelectionModel);*/
                                            if (newSelectionModel.length !== 1) {

                                            }
                                        }}
                                        selectionModel={selectionModel_attestation}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_Attestation_By_Session_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                email: JSON.parse(item).email,
                                                nom: JSON.parse(item).nom,
                                                prenom: JSON.parse(item).prenom,
                                                status: JSON.parse(item).statut,
                                                date_envoie: JSON.parse(item).date_envoie,
                                                courrier_template_id: JSON.parse(item).courrier_template_id,
                                                courrier_template_nom: JSON.parse(item).courrier_template_nom,

                                            }
                                        ))}

                                        columns={columns_attestations}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {

                                        }}



                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }
 
                                        }}

                                        getRowClassName={(params) => {
    
                                          
                                            if (String(params.row.status) === "1") {
                                                
                                                return 'line--attestion_envoye';
                                            }

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {

                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }

                                        }}
                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}
                                        sx={{
                                            "& .MuiDataGrid-cellContent": {
                                                minHeight: 50,

                                            },
                                            [`& .${gridClasses.cell}`]: {
                                                py: 1,
                                            },
                                        }}


                                    />
                                </Box>
                                <br />

                            </div>

                            <div className="div_row">

                                {selectionModel_attestation && selectionModel_attestation.length >= 1 && <div className="block_en_mass">
                                    <nav >Traitement en masse </nav>
                                    &nbsp;
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        className="block_en_mass_select"
                                        fullWidth
                                        options={New_Option_attestation_Masse}
                                        onChange={(event, value) => {
                                            if (value && value.value) {
                                                if (String(value.value) === "reinit" || String(value.value) === "resend") {

                                                    setactionmass_attesation_val(value.value);

                                                }
                                                else {
                                                    setactionmass_attesation_val("");
                                                }

                                            }

                                        }}

                                        renderInput={(params) => <TextField {...params} placeholder="Choisir une option" />
                                        }
                                    />



                                    {actionmass_attesation_val && <nav className='block_en_mass_bton_action'>
                                        <Popup
                                            trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                                <FcAcceptDatabase /> Traiter

                                            </Button>}
                                            modal
                                            nested
                                            position="center center"

                                        >

                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}
                                                        {String(actionmass_attesation_val) === "resend" &&

                                                            <font color="red">  Confirmer l'envoi de  en masse de {selectionModel_attestation.length} attestation(s) de formation.
                                                            </font>}
                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {
                                                                actionmass_ftion_Traitemet();
                                                                close();
                                                            }}> Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                    </nav>
                                    }

                                </div>}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}


export default Module_Attestation_Formation;
