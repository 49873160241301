import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";


const Partner_Configuration_Session_Steps = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [isLoading, setLoading] = useState();

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'config_name', headerName: 'Nom étape', flex: 1, hide: false, editable: false },
        { field: 'config_value', headerName: 'Sequence', flex: 1, hide: false, editable: false },
        { field: 'config_comment', headerName: 'Sequence', flex: 1, hide: true, editable: false },
        { field: 'config_gagne', headerName: 'Gagne', flex: 1, hide: true, editable: false },


        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]



    const myRef = useRef(null)


    const myRef_head = useRef(null);
    const [selected_id, setselected_id] = useState("");


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [p_detail_config_valeur, setp_detail_config_valeur] = useState("");
    const [Config_data_changed, setConfig_data_changed] = useState("");
    const [p_detail_config_point, setp_detail_config_point] = useState("");
    const [config_data_edit_mode, setconfig_data_edit_mode] = useState("");

    const [p_detail_config_comment, setp_detail_config_comment] = useState("");


    const [Getall_Parter_Session_Steps_api, setGetall_Parter_Session_Steps_api] = useState();
    const [Getall_Parter_Session_Steps_message, setGetall_Parter_Session_Steps_message] = useState();
    const [Getall_Parter_Session_Steps_result, setGetall_Parter_Session_Steps_result] = useState();
    function Getall_Parter_Session_Steps(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_session_step/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_Session_Steps  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_Session_Steps  res.data.message r_class = " + res.data.message);
                setGetall_Parter_Session_Steps_api("true");
                setGetall_Parter_Session_Steps_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Parter_Session_Steps_api("false");
                setGetall_Parter_Session_Steps_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Parter_Session_Steps = ', error);
            setGetall_Parter_Session_Steps_api("false");
            alert(" Impossible de recuperer la liste des étapes de session");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {

        Getall_Parter_Session_Steps();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }, [])

    function handleClick_edit_config_From_Line(selected_row_id) {

        //submenu_detail_employe();

        setselected_row_id_val(selected_row_id);

        var line = JSON.parse(rowss[selected_row_id]);

        setdisplay_detail_config("1");

        var invoice_id = line._id;
        setselected_id(invoice_id);

        var local_config_name = line.session_step_name;
        setp_detail_config_point(local_config_name);


        var local_config_value = line.session_step_sequence;
        setp_detail_config_valeur(local_config_value);

        var local_config_comment = line.session_step_comment;
        setp_detail_config_comment(local_config_comment);

        

        Disable_Config_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Enable_Config_DetailFields() {

        setconfig_data_edit_mode("1");

        if (document.getElementsByName("detail_config_point")[0]) {
            document.getElementsByName("detail_config_point")[0].disabled = false;
            document.getElementsByName("detail_config_point")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_valeur")[0]) {
            document.getElementsByName("detail_config_valeur")[0].disabled = false;
            document.getElementsByName("detail_config_valeur")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_comment")[0]) {
            document.getElementsByName("detail_config_comment")[0].disabled = false;
            document.getElementsByName("detail_config_comment")[0].style.backgroundColor = "#FFFFFF";
        }

    }

    function Disable_Config_DetailFields() {

        setconfig_data_edit_mode("0");

        if (document.getElementsByName("detail_config_point")[0]) {

            document.getElementsByName("detail_config_point")[0].disabled = true;
            document.getElementsByName("detail_config_point")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_valeur")[0]) {
            document.getElementsByName("detail_config_valeur")[0].disabled = true;
            document.getElementsByName("detail_config_valeur")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_comment")[0]) {
            document.getElementsByName("detail_config_comment")[0].disabled = true;
            document.getElementsByName("detail_config_comment")[0].style.backgroundColor = "#ECEFF1";
        }

    }

    const [Update_One_Config_Data_api, setUpdate_One_Config_Data_api] = useState();
    const [Update_One_Config_Data_message, setUpdate_One_Config_Data_message] = useState();
    const [Update_One_Config_Data_result, setUpdate_One_Config_Data_result] = useState();
    function Update_One_Config_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_step_name", p_detail_config_point);
        form.append("session_step_sequence", p_detail_config_valeur);
        form.append("session_step_comment", p_detail_config_comment);

      
        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Partner_session_step/";


        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Config_Data  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Config_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_Config_Data_api("true");
                setUpdate_One_Config_Data_result(res.data.message);

                Getall_Parter_Session_Steps();

                setp_detail_config_valeur();
                setp_detail_config_point();
                setp_detail_config_comment();
 

                setConfig_data_changed();
                setconfig_data_edit_mode();
                setdisplay_detail_config();
                setadd_new_step();

                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_One_Config_Data_api("false");
                setUpdate_One_Config_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('UpdateStagiaireData : Not good man :( Update_One_Config_Data = ' + error);
            setUpdate_One_Config_Data_api("false");
            alert(" Impossible de mettre à jour le paramétrage ");

        })
    }


    function handleClick_delete(event, cellValues) {

        var local_id = cellValues.row._id;
        var local_session_step_name = cellValues.row.config_name;


        Delete_Personalise_Field_Data(local_id, local_session_step_name);
    }

    const [Delete_Personalise_Field_Data_api, setDelete_Personalise_Field_Data_api] = useState();
    const [Delete_Personalise_Field_Data_message, setDelete_Personalise_Field_Data_message] = useState();
    const [Delete_Personalise_Field_Data_result, setDelete_Personalise_Field_Data_result] = useState();
    function Delete_Personalise_Field_Data(local_id, local_session_step_name) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_id);
        form.append("session_step_name", local_session_step_name);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Partner_session_step/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Personalise_Field_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Personalise_Field_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Personalise_Field_Data_api("true");
                setDelete_Personalise_Field_Data_result(res.data.message);

                Getall_Parter_Session_Steps();
                setp_detail_config_valeur();
                setp_detail_config_point();
                setp_detail_config_comment();
                setConfig_data_changed();
                setconfig_data_edit_mode();
                setdisplay_detail_config();



                alert(" Le document a été correctement supprimé.");
                //myRef_head.current.scrollIntoView({ behavior: "smooth" });
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setDelete_Personalise_Field_Data_api("false");
                setDelete_Personalise_Field_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.warn('Delete_Personalise_Field_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Personalise_Field_Data_api("false");
            alert(" Impossible de supprimer le document");
        })
    }

    const [display_detail_config, setdisplay_detail_config] = React.useState();
    const [add_new_step, setadd_new_step] = React.useState();

    const [selected_row_id_val, setselected_row_id_val] = React.useState();

    function submenu_add_one_step() {
        setdisplay_detail_config("0");
        setconfig_data_edit_mode("1");
        setadd_new_step('1');
        Enable_Config_DetailFields();

    }

    function Annule_Order_DetailFields() {

        var line = JSON.parse(rowss[selected_row_id_val]);

        setdisplay_detail_config("1");

        var invoice_id = line._id;
        setselected_id(invoice_id);

        var local_config_name = line.session_step_name;
        setp_detail_config_point(local_config_name);


        var local_config_value = line.session_step_sequence;
        setp_detail_config_valeur(local_config_value);

        var local_config_comment = line.session_step_comment;
        setp_detail_config_comment(local_config_comment);


        setConfig_data_changed();
        setconfig_data_edit_mode();

        Disable_Config_DetailFields();

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }

    return (
        <div className="partner_configuration_technique">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            <h3> Votre configuration des étapes de session </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        <TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="config_name" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom du point de configuration &nbsp;</MenuItem>


                        </TextField>
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1();
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={"Getall_Parter_Invoice_With_Filter"}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={"clean_all_filters"}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule "statut"
                                    '& .cell--status--encours': {
                                        backgroundColor: '#E6F7C8',
                                        color: '#1a3e72',
                                        fontWeight: 'light',
                                    },
                                    '& .cell--status--traite': {
                                        backgroundColor: '#E6CEAA',
                                        color: '#F9E79F',
                                        fontWeight: 'light',
                                    },




                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /* if (newSelectionModel.length === 1)
                                             handleClick_edit_config_From_Line(newSelectionModel);
                                         if (newSelectionModel.length !== 1) {
                                             setp_detail_config_valeur();
                                             setp_detail_config_point();
                                             setConfig_data_changed();
                                             setconfig_data_edit_mode();
                                             setdisplay_detail_config();
 
 
                                         }*/
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            config_name: JSON.parse(item).session_step_name,
                                            config_value: JSON.parse(item).session_step_sequence,
                                            config_comment: JSON.parse(item).session_step_comment,
                                            config_gagne: JSON.parse(item).session_step_gagne,
                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        handleClick_edit_config_From_Line(newSelectionModel.row.id);

                                    }}

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {


                                        //field === "order_header_status"
                                        if (params.field === "order_header_status" && String(params.value) == "0") {
                                            return 'cell--status--brouillon';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "1") {

                                            return 'cell--status--encours';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "2") {
                                            return 'cell--status--traite';
                                        }

                                        if (params.field === "order_header_status" && String(params.value) == "3") {
                                            return 'cell--status--facture';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        if (String(params.row.status) === "-1") {
                                            return 'line--statut--annule';
                                        }
                                        if (String(params.row.status) === "0") {
                                            return 'line--statut--preinscrit';
                                        }
                                        if (String(params.row.status) === "1") {
                                            return 'line--statut--inscrit';
                                        }
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>
                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                        <Button variant="outlined" onClick={submenu_add_one_step}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout une étape &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>
                    </div>
                </div>
                <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
            </div>


            {String(display_detail_config) === "1" && <div className="div_row">
                {String(config_data_edit_mode) === "1" &&
                    <div className="session_caract"> Point  de configuration<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_config_point"
                            id="detail_config_point"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_config_point}
                            onChange={(e) => {
                                setp_detail_config_point(e.target.value);
                                setConfig_data_changed("1");
                            }
                            }

                        />


                    </div>}
                {String(config_data_edit_mode) !== "1" &&
                    <div className="session_caract"> Point  de configuration <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_config_point"
                            id="detail_config_point"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_config_point}
                        />


                    </div>}

                {String(config_data_edit_mode) === "1" && <div className="session_caract"> Valeur de configuration<br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="detail_config_valeur"
                        id="detail_config_valeur"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_detail_config_valeur}
                        onChange={(e) => {
                            setp_detail_config_valeur(e.target.value);
                            setConfig_data_changed("1");
                        }
                        }
                    />
                </div>}

                {String(config_data_edit_mode) !== "1" && <div className="session_caract"> Valeur de configuration<br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="detail_config_valeur"
                        id="detail_config_valeur"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_detail_config_valeur}

                    />
                </div>}

                {String(config_data_edit_mode) === "1" && <div className="session_caract"> Commentaire <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="detail_config_comment"
                        id="detail_config_comment"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_detail_config_comment}
                        onChange={(e) => {
                            setp_detail_config_comment(e.target.value);
                            setConfig_data_changed("1");
                        }
                        }
                    />
                </div>}

                {String(config_data_edit_mode) !== "1" && <div className="session_caract"> Commentaire<br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="detail_config_comment"
                        id="detail_config_comment"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_detail_config_comment}

                    />
                </div>}

                <div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(Config_data_changed) === "1" && String(config_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}
                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(config_data_edit_mode) === "1" && String(Config_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Update_One_Config_Data}>Enregistrer les modifications
                            </Button>

                        </div>}

                        {String(config_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_Order_DetailFields}>Annuler
                            </Button>
                        </div>}
                    </div>


                    {String(config_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                        <Button variant="contained" className="bton_edit" onClick={Enable_Config_DetailFields}>Editer
                        </Button>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>

            </div>
            }

            {String(add_new_step) === "1" && <div className="div_row">
                {String(config_data_edit_mode) === "1" &&
                    <div className="session_caract"> Point  de configuration<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="one_detail_config_point"
                            id="one_detail_config_point"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_config_point}
                            onChange={(e) => {
                                setp_detail_config_point(e.target.value);
                                setConfig_data_changed("1");
                            }
                            }
                        />

                    </div>}


                <div className="session_caract"> Valeur de configuration<br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="detail_config_valeur"
                        id="detail_config_valeur"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_detail_config_valeur}
                        onChange={(e) => {
                            setp_detail_config_valeur(e.target.value);
                            setConfig_data_changed("1");
                        }
                        }
                    />
                </div>

                <div className="session_caract"> Commentaire <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="detail_config_comment"
                        id="detail_config_comment"

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={p_detail_config_comment}
                        onChange={(e) => {
                            setp_detail_config_comment(e.target.value);
                            setConfig_data_changed("1");
                        }
                        }
                    />
                </div>

             
                <div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(Config_data_changed) === "1" && String(config_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}
                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(config_data_edit_mode) === "1" && String(Config_data_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Update_One_Config_Data}>Enregistrer les modifications
                            </Button>

                        </div>}

                        {String(config_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_Order_DetailFields}>Annuler
                            </Button>
                        </div>}
                    </div>



                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>

            </div>
            }

        </div>
    )
}


export default Partner_Configuration_Session_Steps;

