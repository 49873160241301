
import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import fileDownload from 'js-file-download';
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import Autocomplete from '@mui/material/Autocomplete';
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";



const Module_Evaluation_Positionnement = (props) => {

    const myconntected_employee_id = props.conntected_employee_id;

    const [selected_employee_id, setselected_employee_id] = useState(props.conntected_employee_id);

    const history = useHistory();
    const [isLoading, setLoading] = useState();


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    const columns_q_position = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'form_code', headerName: 'Form. Code', hide: true, minwidth: 200, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minwidth: 300, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'nom', minwidth: 300, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minwidth: 300, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: 'status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Init.";
                else if (String(params.value) === "1")
                    return "Env.";
                else if (String(params.value) === "2")
                    return "Rép.";
                else
                    return " ?";
            },
        },

        {
            field: 'date_envoie', headerName: 'Date Envoi', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },
        {
            field: 'date_reponse', headerName: 'Date Réponse', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },


    ]

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };
    function Close_Dialog_Detail_Event_open() {
        setDialog_1_open(false);
    }


    const [Dialog_LIST_POSITION_QUESTION_open, setDialog_LIST_POSITION_QUESTION_open] = React.useState(false);
    const Dialog_LIST_POSITION_QUESTION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_LIST_POSITION_QUESTION_handleClose_buton = () => {
        setDialog_LIST_POSITION_QUESTION_open(false);
    };

    const [selectionModel_q_position, setselectionModel_q_position] = React.useState([]);

    const [Getall_Survey_Q_Positionnement_By_Session_api, setGetall_Survey_Q_Positionnement_By_Session_api] = useState();
    const [Getall_Survey_Q_Positionnement_By_Session_message, setGetall_Survey_Q_Positionnement_By_Session_message] = useState();
    const [Getall_Survey_Q_Positionnement_By_Session_result, setGetall_Survey_Q_Positionnement_By_Session_result] = useState([]);
    function Getall_Survey_Q_Positionnement_By_Session(event) {

        setselectionModel_q_position([])

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);
        form.append("formulaire_type", "pos");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Survey_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                // console.log(" In Getall_Survey_Q_Positionnement_By_Session  res.data.status = " + res.data.status);
                console.log(" In Getall_Survey_Q_Positionnement_By_Session  res.data.message r_class = " + res.data.message);
                setGetall_Survey_Q_Positionnement_By_Session_api("true");
                setGetall_Survey_Q_Positionnement_By_Session_result(res.data.message);


            }
            else {
                setGetall_Survey_Q_Positionnement_By_Session_api("false");
                setGetall_Survey_Q_Positionnement_By_Session_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Survey_Q_Positionnement_By_Session = ', error);
            setGetall_Survey_Q_Positionnement_By_Session_api("false");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {
        setselected_session_id(props.related_collection_recid);
        Getall_Survey_Q_Positionnement_By_Session();

    }, [])



    const New_Option_Type_Document = [
        { "id": "email", "label": "Email", "value": "email" },
    ]

    const [selected_formulaire_id, setselected_formulaire_id] = useState();
    const [selected_formulaire_type, setselected_formulaire_type] = useState("");

    const [New_Getall_Partner_Formulaires_with_Filter, setNew_Getall_Partner_Formulaires_with_Filter] = useState([]);

    const [Getall_Partner_Formulaires_with_Filter_api, setGetall_Partner_Formulaires_with_Filter_api] = useState();
    const [Getall_Partner_Formulaires_with_Filter_message, setGetall_Partner_Formulaires_with_Filter_message] = useState();
    const [Getall_Partner_Formulaires_with_Filter_result, setGetall_Partner_Formulaires_with_Filter_result] = useState([]);
    function Getall_Partner_Formulaires_with_Filter(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type", "pos");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Formulaire_with_filter/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Formulaires_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Formulaires_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Formulaires_with_Filter_api("true");
                setGetall_Partner_Formulaires_with_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " " + local_nom,
                        "nom": local_nom,
                        "code": local_code,
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Formulaires_with_Filter(new_data2);
            }
            else {
                setGetall_Partner_Formulaires_with_Filter_api("false");
                setGetall_Partner_Formulaires_with_Filter_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Formulaires_with_Filter = ', error);
            setGetall_Partner_Formulaires_with_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [gridline_id, setgridline_id] = useState("");

    const [selected_session_id, setselected_session_id] = useState("");

    const [Create_List_Survey_For_Session_For_Inscrit_api, setCreate_List_Survey_For_Session_For_Inscrit_api] = useState();
    const [Create_List_Survey_For_Session_For_Inscrit_message, setCreate_List_Survey_For_Session_For_Inscrit_message] = useState();
    const [Create_List_Survey_For_Session_For_Inscrit_result, setCreate_List_Survey_For_Session_For_Inscrit_result] = useState();
    function Create_List_Survey_For_Session_For_Inscrit() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);
        form.append("formulaire_id", selected_formulaire_id);
        form.append("survey_type", "pos");
        form.append("sending_date", "");


        //alert(" mysession = "+mysession); 

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Init_Survey_Tab_For_All_Session_Inscrit/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreate_List_Survey_For_Session_For_Inscrit_api("true");
                setCreate_List_Survey_For_Session_For_Inscrit_result(res.data.message);
                Getall_Survey_Q_Positionnement_By_Session();
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreate_List_Survey_For_Session_For_Inscrit_api("false");
                setCreate_List_Survey_For_Session_For_Inscrit_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Create_List_Survey_For_Session_For_Inscrit : Not good man :( mysearchtext = ' + error);
            setCreate_List_Survey_For_Session_For_Inscrit_api("false");
            alert(" Impossible d'initialiser la liste des enquête de positionnement ");

        })
    }

    const New_Option_q_position_Masse = [
        { "id": "resend", "label": "(R)envoyer", "value": "resend" },
    ]

    const [actionmass_q_position_val, setactionmass_q_position_val] = useState();

    async function actionmass_ftion_Traitemet() {

        if (String(actionmass_q_position_val) === String("reinit")) {

            alert(" ### reinit");

        }

        else if (String(actionmass_q_position_val) === String("resend")) {
            var tab_ids_survey = []
            for (var i = 0; i < selectionModel_q_position.length; i++) {
                var myid = parseInt(String(selectionModel_q_position[i]));
                var line = JSON.parse(Getall_Survey_Q_Positionnement_By_Session_result[myid]);
                var line_id = JSON.parse(Getall_Survey_Q_Positionnement_By_Session_result[myid])._id;

                tab_ids_survey.push(line_id);
            }


            console.log(" ### tab_ids_survey === ", tab_ids_survey);

            Send_Survey_By_Email_For_Ids(tab_ids_survey);

        }

    }



    const [Send_Survey_By_Email_For_Ids_api, setSend_Survey_By_Email_For_Ids_api] = useState();
    const [Send_Survey_By_Email_For_Ids_message, setSend_Survey_By_Email_For_Ids_message] = useState();
    const [Send_Survey_By_Email_For_Ids_result, setSend_Survey_By_Email_For_Ids_result] = useState();
    function Send_Survey_By_Email_For_Ids(tab_survey) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_ids", tab_survey);


        //alert(" mysession = "+mysession);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Send_Survey_TabIds/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSend_Survey_By_Email_For_Ids_api("true");
                setSend_Survey_By_Email_For_Ids_result(res.data.message);
                Getall_Survey_Q_Positionnement_By_Session();

                setselectionModel_q_position([]);
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSend_Survey_By_Email_For_Ids_api("false");
                setSend_Survey_By_Email_For_Ids_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Send_Survey_By_Email_For_Ids : Not good man :( mysearchtext = ' + error);
            setSend_Survey_By_Email_For_Ids_api("false");
            alert(" Impossible d'envoyer les demandes");

        })
    }


    function Export_survey_datatab_class() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_reponses.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_Survey_with_filter/" + stored_cookie + "/" + selected_session_id + "/pos/";


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les réponses (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les réponses ")

            });


        setLoading(false);
    }


    return (

        <div className="module_evaluation_positionnement">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

                className="module_agenda"

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" >

                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Action a traiter </nav>

                    </div>

                    <div className="session_caract_Dialog" >  <b>Action a traiter </b>
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"
                            fullWidth
                            value={"action_server_to_run"}


                        />
                    </div>




                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={"Update_One_Agenda_Event"} className="bton_enreg_dialog">Mettre à jour</Button>

                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_Event_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>



            {/*  Dialog pour gerer les LIST_POSITION_QUESTION */}
            <Dialog
                open={Dialog_LIST_POSITION_QUESTION_open}
                onClose={Dialog_LIST_POSITION_QUESTION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Choisir un questionnaire de positionnement
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_q_position"
                            id="event_dialog_version_q_position"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Getall_Partner_Formulaires_with_Filter}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_formulaire_id(value._id);

                                } else {
                                    setselected_formulaire_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {

                                var tab_ids_inscription = []
                                for (var i = 0; i < selectionModel_q_position.length; i++) {
                                    var myid = parseInt(String(selectionModel_q_position[i]));
                                    var line = JSON.parse(selectionModel_q_position[myid]);
                                    var line_id = JSON.parse(selectionModel_q_position[myid])._id;
                                    tab_ids_inscription.push(line_id);
                                }



                                Create_List_Survey_For_Session_For_Inscrit();
                                Dialog_LIST_POSITION_QUESTION_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Initialiser la liste </Button>




                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_LIST_POSITION_QUESTION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les LIST_POSITION_QUESTION */}

            <div>
                <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>

                    <Popup
                        trigger={
                            <Button className="bton_emarge">Initialiser le questionnaire</Button>
                        }
                        modal
                        nested
                        position="center center"

                    >

                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}


                                    <font> Confirmez la (ré)initialisation l'enquête. <br />
                                        <font color="red">  /!\ Si une enquête de positionnement existe pour cette session, elle sera supprimée et récréée </font>
                                    </font>


                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            Getall_Partner_Formulaires_with_Filter();
                                            setDialog_LIST_POSITION_QUESTION_open(true);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>



                    <Button className="bton_emarge" onClick={(event) => {
                        Getall_Survey_Q_Positionnement_By_Session();
                    }}>Actualiser la liste</Button>

                    <Button className="bton_emarge" onClick={(event) => {
                        Export_survey_datatab_class();
                    }}>Exporter (excel)</Button><br />


                </div>

                <div className="session_data">
                    <div style={{ "border": "None", marginLeft: '5px', marginRight: '5px' }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',


                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },


                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },

                                    '& .line--positionnement_envoye': {
                                        backgroundColor: '#F9E79F',
                                        color: 'black',

                                    },

                                    '& .line--positionnement_repondu': {
                                        backgroundColor: '#ABEBC6',
                                        color: 'black',
                                        fontWeight: '600',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_q_position(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_session_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {

                                        }
                                    }}
                                    selectionModel={selectionModel_q_position}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Getall_Survey_Q_Positionnement_By_Session_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            email: JSON.parse(item).email,
                                            nom: JSON.parse(item).nom,
                                            prenom: JSON.parse(item).prenom,
                                            status: JSON.parse(item).statut,
                                            form_code: JSON.parse(item).formulaire.code,
                                            date_envoie: JSON.parse(item).date_envoi,
                                            date_reponse: JSON.parse(item).date_reponse,
                                        }
                                    ))}

                                    columns={columns_q_position}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {

                                    }}

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {


                                    }}

                                    getRowClassName={(params) => {

                                        if (String(params.row.status) === "1") {
                                            return 'line--positionnement_envoye';
                                        }

                                        if (String(params.row.status) === "2") {
                                            return 'line--positionnement_repondu';
                                        }

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {

                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        },
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                        <div className="div_row">

                            {selectionModel_q_position && selectionModel_q_position.length >= 1 && <div className="block_en_mass">
                                <nav >Traitement en masse </nav>
                                &nbsp;
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    className="block_en_mass_select"
                                    fullWidth
                                    options={New_Option_q_position_Masse}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            if (String(value.value) === "reinit" || String(value.value) === "resend") {

                                                setactionmass_q_position_val(value.value);

                                            }
                                            else {
                                                setactionmass_q_position_val("");
                                            }

                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option" />
                                    }
                                />



                                {actionmass_q_position_val && String(actionmass_q_position_val).length > 1 && <nav className='block_en_mass_bton_action'>
                                    <Popup
                                        trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                            <FcAcceptDatabase /> Traiter

                                        </Button>}
                                        modal
                                        nested
                                        position="center center"

                                    >

                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information </div>
                                                <div className="gest_content">
                                                    {' '}
                                                    {String(actionmass_q_position_val) === "resend" &&

                                                        <font color="red">  Confirmer l'envoi de  en masse de {selectionModel_q_position.length} questionnaire(s) de positionnement.
                                                        </font>}
                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            actionmass_ftion_Traitemet();
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </nav>
                                }

                            </div>}
                        </div>
                    </div>

                </div>
            </div>


          

        </div>
    )
}


export default Module_Evaluation_Positionnement;
