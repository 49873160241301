import Navigation from "../components/Navigation";
import ResetUserPwd from "../components/ResetUserPwd";

const recherche_draft = () => {

  return (
            <div>
              <div>
            <Navigation />
            </div>
            <ResetUserPwd/>
        </div>

  )
}


export default recherche_draft;