import Mon_Catalogue_Public from "../components/Mon_Catalogue_Public";
import Navigation from "../components/Navigation";
import Footer from "./footer";

const mon_catalogue_public = () => {


  return (
    <div>
    
      <div>
        <Mon_Catalogue_Public />
      </div>

    </div>

  )
}


export default mon_catalogue_public;
