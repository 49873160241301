import Link from "next/link";
import Navigation from "../components/Navigation";
import Emarge_QR_Code from "../components/Emarge_QR_Code";



export default function emarge_qr_code() {
    return (
        <div>
             <Navigation />
            <div>
              <Emarge_QR_Code />
               
            </div>
        </div>
    );
}
