import React, { useState, useEffect } from "react";
import Navigation from "../components/Navigation";
import bannerimg2 from "./../mysy_img/MYSY-LOGO-BLUE.png";
import ok_png from "./../mysy_img/Ok_PNG.png";
import { Helmet } from "react-helmet";
import Footer from "./../components/Fotter";
import Header from "./../components/Header";


const EmargementOk = () => {

    const [tempo, settempo] = useState(".");
    setInterval(check, 2300);
   

    window.setTimeout(function () {
        window.location.href = process.env.REACT_APP_BASE_URL
    }, 7000);


    function check() {
        var val = tempo + " .";
        //alert(" val = "+val);
        settempo(val);

    }
    return (
        <div className="notfound">
            <div>
                <Navigation />
            </div>
            <Helmet>
                <title>MySy Training, Emargement</title>
                <meta name="description" content="Nous sommes une base de données uniquement dediée aux formations et tutoriels" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Trouver des Formations.Acces libre.Qualité top" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/Emargement"} />

            </Helmet>
            <Header />


            <div className="div_mobile">
                <div className="nom_prenom"> Votre demande d'emargement a bien été prise en compte  <img src={ok_png} className="img_ok"/> </div>
                <div className="nom_prenom">
                    <div>
                        Vous allez être redirigé vers le moteur de recherche dans quelques secondes. {tempo}
                    </div>
                </div>
            </div>
            <div className="pieddepage">
                <Footer />
            </div>


        </div>
    )
}

export default EmargementOk;