import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcSearch } from "react-icons/fc";
import { FcAlarmClock, FcMultipleSmartphones, FcWorkflow, FcTemplate } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import 'moment/locale/fr';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RestoreFromTrashRounded } from "@mui/icons-material";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';
import ToggleSwitch from "./ToggleSwitch";
import Module_Historique_Action from "./Module_Historique_Action";
import { HiDocumentCheck } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";
import Autocomplete from '@mui/material/Autocomplete';
import { FcOpenedFolder } from "react-icons/fc";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import Module_Editique from "./Module_Editique";
import Module_Attestation_Formation from "./Module_Attestation_Formation";

import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcPlanner } from "react-icons/fc";
import { CiBoxList } from "react-icons/ci";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'
import { makeStyles } from "@material-ui/core/styles";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { IconButton } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    dialog: {
        position: 'absolute',
        // left: 10,
        top: 400
    },

    paper: {
        overflowY: 'unset',
    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '-9%',
        backgroundColor: 'lightgray',
        color: 'gray',
    },
}));



const Module_Session_Planification = (props) => {

    const classes = useStyles();

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);


    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }


    const columns_sequence = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'type', headerName: 'type', hide: true },
        { field: 'grp_apprenant_id', headerName: 'grp_apprenant_id', hide: true },
        { field: 'ue_id', headerName: 'ue_id', hide: true },
        { field: 'unite_enseignement_planif_id', headerName: 'unite_enseignement_planif_id', hide: true },

        { field: 'session_id', headerName: 'session_id', width: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'sequence_title', headerName: 'Titre', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'grp_apprenant_code', headerName: 'Groupe', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'sequence_start', headerName: 'Debut', minWidth: 200, flex: 1, hide: false, editable: false },
        { field: 'sequence_end', headerName: 'Fin', minWidth: 200, flex: 1, hide: false, editable: false },

        { field: 'agenda', headerName: 'Agenda', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <Display_Colunm_text {...params} />, },
        { field: 'objectif', headerName: 'Objectif', width: 0, flex: 0, hide: true, editable: false, },
        { field: 'commentaire', headerName: 'Commentaire', width: 0, flex: 0, hide: true, editable: false, },


        {
            field: "Detail", headerName: 'Voir detail', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            setsequence_gridline_id(cellValues.row.id);
                            setselected_detail_sequence_title(cellValues.row.sequence_title);

                            setselected_sequence_startDate(cellValues.row.sequence_start);
                            setselected_sequence_endDate(cellValues.row.sequence_end);

                            setp_detail_sequence_agenda(cellValues.row.agenda);
                            setp_detail_sequence_objectif(cellValues.row.objectif);
                            setp_detail_sequence_comment(cellValues.row.commentaire);

                            Getall_List_Sequence_Ressource(cellValues.row._id);

                            setselected_sequence_id(cellValues.row._id);
                            setdisplay_detail_sequence("1");



                            if (document.getElementById('myRef_seq_ressource')) {
                                var divh = document.getElementById('myRef_seq_ressource').offsetTop;
                                window.scrollTo({
                                    top: divh,
                                    behavior: "smooth",
                                });
                            }

                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "modifier", headerName: 'Modifier', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {

                            setsequence_gridline_id(cellValues.row.id);
                            setdisplay_detail_sequence("");
                            setp_detail_sequence_title(cellValues.row.sequence_title);
                            setselected_sequence_id(cellValues.row._id);
                            var date_du = new Date(moment(cellValues.row.sequence_start, "DD/MM/YYYY HH:mm"));
                            var date_au = new Date(moment(cellValues.row.sequence_end, "DD/MM/YYYY HH:mm"));

                            setsequence_startDate(date_du);
                            setsequence_endDate(date_au);

                            setsubmenu_seq_dialog("dialog_detail");


                            setselectionModel_sequence([]);
                            Getall_List_Sequence_Ressource(cellValues.row._id);

                            setp_detail_event_type(cellValues.row.type);

                            setp_detail_sequence_agenda(cellValues.row.agenda);
                            setp_detail_sequence_objectif(cellValues.row.objectif);
                            setp_detail_sequence_comment(cellValues.row.commentaire);

                            setp_detail_sequence_groupe_apprenant_id(cellValues.row.grp_apprenant_id);

                            if (cellValues.row.ue_id) {

                                setp_detail_sequence_ue_id(cellValues.row.ue_id);
                                Get_List_Given_UE_Planif_lines(cellValues.row.ue_id);

                            } else {

                                setp_detail_sequence_ue_id("");
                                //---
                                var node = {
                                    "_id": "",
                                    "id": "",
                                    "label": "",
                                    "code": "",
                                    "titre": "",
                                    "volume_horaire": "",
                                    "in_use": "",
                                };
                                var new_data2 = [];
                                new_data2.push(node);
                                setNew_Get_List_Given_UE_Planif_lines_result(new_data2);

                            }

                            if (cellValues.row.unite_enseignement_planif_id) {
                                setp_detail_sequence_ue_planif_line_id(cellValues.row.unite_enseignement_planif_id);
                            } else {
                                setp_detail_sequence_ue_planif_line_id("");
                            }

                            // console.log(" New_Get_List_Given_UE_Planif_lines_result", New_Get_List_Given_UE_Planif_lines_result);


                            setupdate_sequence("1");
                            submenu_update_sequence();
                        }}
                    >
                        <FcViewDetails />

                    </Button>

                );
            }
        },

        {
            field: "delete", headerName: 'Supprimer', minWidth: 200, flex: 1,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la séquence sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            Delete_Sequence_Session_Data(cellValues.row._id);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const columns_sequence_new_planification = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'type', headerName: 'type', hide: true },
        { field: 'grp_apprenant_id', headerName: 'grp_apprenant_id', hide: true },
        { field: 'ue_id', headerName: 'ue_id', hide: true },
        { field: 'unite_enseignement_planif_id', headerName: 'unite_enseignement_planif_id', hide: true },

        { field: 'session_id', headerName: 'session_id', width: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'sequence_title', headerName: 'Titre', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'grp_apprenant_code', headerName: 'Groupe', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'sequence_start', headerName: 'Debut', minWidth: 200, flex: 1, hide: false, editable: false },
        { field: 'sequence_end', headerName: 'Fin', minWidth: 200, flex: 1, hide: false, editable: false },

        { field: 'agenda', headerName: 'Agenda', minWidth: 200, flex: 1, hide: false, editable: false, renderCell: (params) => <Display_Colunm_text {...params} />, },
        { field: 'objectif', headerName: 'Objectif', width: 0, flex: 0, hide: true, editable: false, },
        { field: 'commentaire', headerName: 'Commentaire', width: 0, flex: 0, hide: true, editable: false, },



    ]

    const [gridline_sequence_new_planificatio_id, setgridline_sequence_new_planificatio_id] = useState("");

    const columns_sequence_ressources_affectation = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true, minWidth: 200, },
        { field: 'related_target_collection_id', headerName: 'related_target_collection_id', minWidth: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'related_target_collection', headerName: 'related_target_collection', minWidth: 150, hide: true, editable: false, resizable: true },
        { field: 'type_ressource', headerName: 'Type', minWidth: 150, hide: true, editable: false },
        { field: 'related_target_collection_object', headerName: 'object', minWidth: 200, hide: false, editable: false, resizable: true, flex: 1, },
        { field: 'related_target_collection_id_nom', headerName: 'nom', minWidth: 200, hide: false, editable: false, resizable: true, flex: 1, },
        { field: 'poste', headerName: 'Poste', minWidth: 200, hide: false, editable: false, flex: 1, },
        { field: 'comment', headerName: 'Comment', width: 200, hide: true, editable: false },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la ressource sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {

                                            Delete_Sequence_Session_Ressource_Data(cellValues.row._id);
                                            //console.log('cellValues.row =  ', cellValues.row);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }


    ]

    const columns_modele_planning = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'session_id', headerName: 'session_id', width: 200, hide: true, flex: 1, minWidth: 150, maxWidth: 300 },
        { field: 'code', headerName: 'code', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'nom', headerName: 'nom', minWidth: 200, flex: 1, hide: false, editable: false, resizable: true },
        { field: 'is_public', headerName: 'is_public', minWidth: 200, flex: 1, hide: true, editable: false },
        { field: 'comment', headerName: 'comment', minWidth: 200, flex: 1, hide: true, editable: false },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le modème de planification sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {

                                            Delete_Saved_Model_Planning(cellValues.row._id);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>

                );
            }
        }


    ]

    const [selectionModel_modele_planning, setselectionModel_modele_planning] = React.useState([]);


    const [Delete_Saved_Model_Planning_api, setDelete_Saved_Model_Planning_api] = useState();
    const [Delete_Saved_Model_Planning_message, setDelete_Saved_Model_Planning_message] = useState();
    const [Delete_Saved_Model_Planning_result, setDelete_Saved_Model_Planning_result] = useState();
    function Delete_Saved_Model_Planning(local_seq_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_seq_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Sequence_Planning_Model/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Saved_Model_Planning  res.data.status = " + res.data.status);
            //console.log(" In Delete_Saved_Model_Planning  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Saved_Model_Planning_api("true");
                setDelete_Saved_Model_Planning_result(res.data.message);
                setgridline_sequence_new_planificatio_id("");
                alert(res.data.message);
                Getall_Partner_Modele_Planning();
            }
            else {
                setDelete_Saved_Model_Planning_api("false");
                setDelete_Saved_Model_Planning_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Saved_Model_Planning : Not good man :( mysearchtext = ' + error);
            setDelete_Saved_Model_Planning_api("false");
            alert(" Impossible de supprimer le modèle de planification");
        })
    }
    const myRef_seq_ressource = useRef(null)
    const myRef_add_session = useRef(null)

    const [update_sequence, setupdate_sequence] = useState();
    function submenu_update_sequence() {
        setupdate_sequence("1");

        setDialog_seq_1_open(true);
    }

    const [sequence_gridline_id, setsequence_gridline_id] = useState("");
    const [p_detail_sequence_title, setp_detail_sequence_title] = useState();
    const [p_detail_sequence_comment, setp_detail_sequence_comment] = useState();
    const [p_detail_sequence_agenda, setp_detail_sequence_agenda] = useState();
    const [p_detail_sequence_objectif, setp_detail_sequence_objectif] = useState();
    const [sequence_startDate, setsequence_startDate] = useState(new Date());
    const [sequence_endDate, setsequence_endDate] = useState(new Date());

    const [p_detail_sequence_groupe_apprenant_id, setp_detail_sequence_groupe_apprenant_id] = useState("");
    const [p_detail_sequence_ue_id, setp_detail_sequence_ue_id] = useState("");

    const [p_detail_sequence_ue_planif_line_id, setp_detail_sequence_ue_planif_line_id] = useState("");


    const [selected_detail_sequence_title, setselected_detail_sequence_title] = useState();
    const [selected_detail_sequence_comment, setselected_detail_sequence_comment] = useState();
    const [selected_sequence_startDate, setselected_sequence_startDate] = useState(new Date());
    const [selected_sequence_endDate, setselected_sequence_endDate] = useState(new Date());


    const [display_view, setdisplay_view] = useState("list");
    function func_display_planning_view() {
        setdisplay_view("planning");
    }

    function func_display_list_view() {
        setdisplay_view("list");
    }


    const renderEventContent = (eventInfo) => {


        //console.log(eventInfo.event);
        return (
            <>
                <b>{eventInfo.timeText}</b><br />
                <i>{eventInfo.event.title}</i><br />
                <i>{eventInfo.event.extendedProps.grp_code}</i>
            </>
        )
    };

    const [actionmass_sequence_val, setactionmass_sequence_val] = useState();
    const actionmass_sequence = (event) => {
        const value = event.target.value;


        if (String(value) === "ajout_ressource" || String(value) === "supprimer") {
            setactionmass_sequence_val(value);
        }
        else {
            setactionmass_sequence_val();
        }
    }


    const [display_detail_sequence, setdisplay_detail_sequence] = useState();
    const [selected_sequence_id, setselected_sequence_id] = useState("");
    const [tab_selected_sequence_id, settab_selected_sequence_id] = useState([]);



    const [Getall_List_Sequence_Ressource_api, setGetall_List_Sequence_Ressource_api] = useState();
    const [Getall_List_Sequence_Ressource_message, setGetall_List_Sequence_Ressource_message] = useState();
    const [Getall_List_Sequence_Ressource_result, setGetall_List_Sequence_Ressource_result] = useState([]);
    function Getall_List_Sequence_Ressource(local_sequence_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("sequence_session_id", local_sequence_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Sequence_Ressource_Affectation/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_List_Sequence_Ressource  res.data.status = " + res.data.status);
                //console.log(" In Getall_List_Sequence_Ressource  res.data.message r_class = " + res.data.message);
                setGetall_List_Sequence_Ressource_api("true");
                setGetall_List_Sequence_Ressource_result(res.data.message);

            }
            else {
                setGetall_List_Sequence_Ressource_api("false");
                setGetall_List_Sequence_Ressource_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_List_Sequence_Ressource = ', error);
            setGetall_List_Sequence_Ressource_api("false");
            alert("Impossible de récuperer la liste des ressources de la sequence")
            //setmyApimyApiMessage("")
        })
    }

    const [Delete_Sequence_Session_Data_api, setDelete_Sequence_Session_Data_api] = useState();
    const [Delete_Sequence_Session_Data_message, setDelete_Sequence_Session_Data_message] = useState();
    const [Delete_Sequence_Session_Data_result, setDelete_Sequence_Session_Data_result] = useState();
    function Delete_Sequence_Session_Data(local_seq_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_seq_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Session_Sequence/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Data_api("true");
                setDelete_Sequence_Session_Data_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setDialog_seq_ressource_1_selected_type_ressource("");
                setDialog_seq_ressource_1_selected_ressource_id("");
                setDialog_seq_ressource_1_selected_ressource_poste("");
                setdisplay_detail_sequence();
                setselected_sequence_id("");
                setupdate_sequence();
                //setgridline_id('');
                setsequence_gridline_id("");

                Dialog_DIALOG_AGENDA_handleClose_buton();

                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Data_api("false");
                setDelete_Sequence_Session_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Data_api("false");
            alert(" Impossible de supprimer la sequence");
        })
    }

    const [selected_code_session, setselected_code_session] = useState();
    const [selected_session_id, setselected_session_id] = useState(props.related_collection_recid);
    const [selected_internal_url, setselected_internal_url] = useState();
    const [selected_class_title, setselected_class_title] = useState();

    const [one_selected_internal_url, setone_selected_internal_url] = useState();

    const [Delete_Sequence_Session_Data_Mass_api, setDelete_Sequence_Session_Data_Mass_api] = useState();
    const [Delete_Sequence_Session_Data_Mass_message, setDelete_Sequence_Session_Data_Mass_message] = useState();
    const [Delete_Sequence_Session_Data_Mass_result, setDelete_Sequence_Session_Data_Mass_result] = useState();
    function Delete_Sequence_Session_Data_Mass(local_tab_ids) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_id", local_tab_ids);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_Session_Sequence_Mass/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Data_Mass  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Data_Mass  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Data_Mass_api("true");
                setDelete_Sequence_Session_Data_Mass_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setDialog_seq_ressource_1_selected_type_ressource("");
                setDialog_seq_ressource_1_selected_ressource_id("");
                setDialog_seq_ressource_1_selected_ressource_poste("");
                setdisplay_detail_sequence();
                setselected_sequence_id("");
                setupdate_sequence();

                setAdd_One_Sequence_Session_mass();
                settab_selected_sequence_id([]);
                setselectionModel_sequence([]);


                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Data_Mass_api("false");
                setDelete_Sequence_Session_Data_Mass_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Data_Mass : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Data_Mass_api("false");
            alert(" Impossible de supprimer les sequences en masse");
        })
    }


    function GetSelectedRows_Sequence_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_sequence.length; i++) {
            var myid = parseInt(String(selectionModel_sequence[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_sequence_Traitement() {
        setcheck_ressource_avabilities("");
        setAdd_One_Sequence_Session_mass("1");
        var liste_formation = GetSelectedRows_Sequence_Ids();

        var tab_seq_mongo_ids = [];

        for (var i = 0; i < selectionModel_sequence.length; i++) {

            var line_id = selectionModel_sequence[i];
            var mongo_id = JSON.parse(Getall_Sequence_Of_Session_result[line_id])._id;
            tab_seq_mongo_ids.push(mongo_id);


        }

        settab_selected_sequence_id(tab_seq_mongo_ids);


        if (String(actionmass_sequence_val) === "ajout_ressource") {
            setDialog_seq_ressource_1_open(true);

        }
        else if (String(actionmass_sequence_val) === "supprimer") {

            Delete_Sequence_Session_Data_Mass(tab_seq_mongo_ids);

        }

        setactionmass_sequence_val("");


    }


    const [Dialog_seq_choix_model_emargement_message, setDialog_seq_choix_model_emargement_message] = React.useState(false);
    const [Dialog_seq_choix_model_emargement_open, setDialog_seq_choix_model_emargement_open] = React.useState(false);



    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_choix_model_emargement_message(message);
        setDialog_seq_planif_periode_type("");
        setDialog_seq_choix_model_emargement_open(true);
    }

    const Dialog_seq_choix_model_emargement_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_seq_choix_model_emargement_handleClose_buton = () => {
        setDialog_seq_planif_periode_type("");
        setDialog_seq_choix_model_emargement_open(false);
    };



    const [isLoading, setLoading] = useState();

    const [p_detail_event_id, setp_detail_event_id] = useState();
    const [p_detail_title, setp_detail_title] = useState();
    const [p_detail_start, setp_detail_start] = useState();
    const [p_detail_end, setp_detail_end] = useState();
    const [p_detail_comment, setp_detail_comment] = useState();
    const [p_detail_sequence_session_id, setp_detail_sequence_session_id] = useState("");

    const [p_detail_justified, setp_detail_justified] = useState(false);
    const [p_detail_event_type, setp_detail_event_type] = useState("");


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());

    const [mysy_events, setmysy_events] = useState();

    const [Partner_Debut_Journee, setPartner_Debut_Journee] = useState("7:00");
    const [Partner_Fin_Journee, setPartner_Fin_Journee] = useState("20:00");


    const [Dialog_seq_ressource_1_selected_type_ressource, setDialog_seq_ressource_1_selected_type_ressource] = React.useState("");
    const [Dialog_seq_ressource_1_selected_ressource_id, setDialog_seq_ressource_1_selected_ressource_id] = React.useState("");
    const [Dialog_seq_ressource_1_selected_ressource_poste, setDialog_seq_ressource_1_selected_ressource_poste] = React.useState("");



    const [Add_One_Sequence_Session_mass, setAdd_One_Sequence_Session_mass] = useState();

    const [Add_One_Sequence_Session_Ressource_api, setAdd_One_Sequence_Session_Ressource_api] = useState();
    const [Add_One_Sequence_Session_Ressource_message, setAdd_One_Sequence_Session_Ressource_message] = useState();
    const [Add_One_Sequence_Session_Ressource_result, setAdd_One_Sequence_Session_Ressource_result] = useState();
    const Add_One_Sequence_Session_Ressource = event => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("sequence_session_id", selected_sequence_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        if (String("new_ressource_type") === "ressource_humaine" && String(new_ressource_poste).trim() === "") {
            alert(" Vous devez préciser le poste occupé pour cette ressource");
            return;
        }

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Affectation_Ressource_Poste/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session_Ressource  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session_Ressource  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_Ressource_api("true");
                setAdd_One_Sequence_Session_Ressource_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                setDialog_seq_ressource_1_selected_type_ressource("");
                setDialog_seq_ressource_1_selected_ressource_id("");
                setDialog_seq_ressource_1_selected_ressource_poste("");
                setAdd_One_Sequence_Session_mass();
                settab_selected_sequence_id([]);
                setselectionModel_sequence([]);
                alert(res.data.message);
                Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Sequence_Session_Ressource_api("false");
                setAdd_One_Sequence_Session_Ressource_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session_Ressource = ' + error);
            setAdd_One_Sequence_Session_Ressource_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }



    const [Add_One_Ressource_Fom_Dialog_Sequence_api, setAdd_One_Ressource_Fom_Dialog_Sequence_api] = useState();
    const [Add_One_Ressource_Fom_Dialog_Sequence_message, setAdd_One_Ressource_Fom_Dialog_Sequence_message] = useState();
    const [Add_One_Ressource_Fom_Dialog_Sequence_result, setAdd_One_Ressource_Fom_Dialog_Sequence_result] = useState();
    const Add_One_Ressource_Fom_Dialog_Sequence = event => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("sequence_session_id", selected_sequence_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        if (String("new_ressource_type") === "ressource_humaine" && String(new_ressource_poste).trim() === "") {
            alert(" Vous devez préciser le poste occupé pour cette ressource");
            return;
        }

        console.log(" form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Affectation_Ressource_Poste/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Ressource_Fom_Dialog_Sequence  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Ressource_Fom_Dialog_Sequence  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Ressource_Fom_Dialog_Sequence_api("true");
                setAdd_One_Ressource_Fom_Dialog_Sequence_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                setDialog_seq_ressource_1_selected_type_ressource("");
                setDialog_seq_ressource_1_selected_ressource_id("");
                setDialog_seq_ressource_1_selected_ressource_poste("");

                alert(res.data.message);
                //Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Ressource_Fom_Dialog_Sequence_api("false");
                setAdd_One_Ressource_Fom_Dialog_Sequence_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Ressource_Fom_Dialog_Sequence = ' + error);
            setAdd_One_Ressource_Fom_Dialog_Sequence_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }


    const [Delete_Sequence_Session_Ressource_Data_api, setDelete_Sequence_Session_Ressource_Data_api] = useState();
    const [Delete_Sequence_Session_Ressource_Data_message, setDelete_Sequence_Session_Ressource_Data_message] = useState();
    const [Delete_Sequence_Session_Ressource_Data_result, setDelete_Sequence_Session_Ressource_Data_result] = useState();
    function Delete_Sequence_Session_Ressource_Data(local_seq_ressource_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_seq_ressource_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Sequence_Affectation_Ressource_Poste/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Sequence_Session_Ressource_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Sequence_Session_Ressource_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_Sequence_Session_Ressource_Data_api("true");
                setDelete_Sequence_Session_Ressource_Data_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                alert(res.data.message)
            }
            else {
                setDelete_Sequence_Session_Ressource_Data_api("false");
                setDelete_Sequence_Session_Ressource_Data_message(res.data.message);

                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Sequence_Session_Ressource_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Sequence_Session_Ressource_Data_api("false");
            alert(" Impossible de supprimer la sequence");
        })
    }

    const [Add_One_Sequence_Session_api, setAdd_One_Sequence_Session_api] = useState();
    const [Add_One_Sequence_Session_message, setAdd_One_Sequence_Session_message] = useState();
    const [Add_One_Sequence_Session_result, setAdd_One_Sequence_Session_result] = useState();
    const Add_One_Sequence_Session = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(sequence_startDate).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(sequence_endDate).format("DD/MM/YYYY HH:mm");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type", p_detail_event_type);
        form.append("session_id", selected_session_id);
        form.append("sequence_title", new_title);
        form.append("sequence_start", new_start_date);
        form.append("sequence_end", new_end_date);

        form.append("agenda", p_detail_sequence_agenda);
        form.append("objectif", p_detail_sequence_objectif);
        form.append("commentaire", p_detail_sequence_comment);

        form.append("grp_apprenant_id", p_detail_sequence_groupe_apprenant_id);
        form.append("ue_id", p_detail_sequence_ue_id);
        form.append("ue_planif_line_id", p_detail_sequence_ue_planif_line_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Session_Sequence_Return_New_Seq_Data/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_api("true");
                setAdd_One_Sequence_Session_result(res.data.new_sequence_data);

                Getall_Sequence_Of_Session(selected_session_id);

                if (res.data.new_sequence_data.length > 0) {
                    var mylocal_sequence_data = JSON.parse(res.data.new_sequence_data);

                    if (mylocal_sequence_data._id)
                        setselected_sequence_id(mylocal_sequence_data._id);
                    else
                        setselected_sequence_id("");
                }

                /*setp_detail_sequence_title("");
                setp_detail_sequence_comment("");

                setp_detail_sequence_agenda("");
                setp_detail_sequence_objectif("");

                setsequence_startDate();
                setsequence_endDate();
                */



                alert(res.data.message);
                //Close_Dialog_Detail_seq_open();
                // Dialog_DIALOG_AGENDA_handleClose_buton();

            }
            else {
                setAdd_One_Sequence_Session_api("false");
                setAdd_One_Sequence_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session = ' + error);
            setAdd_One_Sequence_Session_api("false");
            alert(" Impossible d'ajouter la séquence");
        })
    }

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "ismanager": ""
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Training_Materiel_No_Filter_result, setNew_Getall_Training_Materiel_No_Filter_result] = useState([]);

    const [Getall_Training_Materiel_No_Filter_api, setGetall_Training_Materiel_No_Filter_api] = useState();
    const [Getall_Training_Materiel_No_Filter_message, setGetall_Training_Materiel_No_Filter_message] = useState();
    const [Getall_Training_Materiel_No_Filter_result, setGetall_Training_Materiel_No_Filter_result] = useState();
    function Getall_Training_Materiel_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_No_Filter_api("true");
                setGetall_Training_Materiel_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_ref_interne = JSON.parse(x).ref_interne;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "ref_interne": local_ref_interne,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "ref_interne": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Materiel_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Materiel_No_Filter_api("false");
                setGetall_Training_Materiel_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Training_Materiel_No_Filter = ', error);
            setGetall_Training_Materiel_No_Filter_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }



    const [Add_One_Sequence_Session_Ressource_Mass_api, setAdd_One_Sequence_Session_Ressource_Mass_api] = useState();
    const [Add_One_Sequence_Session_Ressource_Mass_message, setAdd_One_Sequence_Session_Ressource_Mass_message] = useState();
    const [Add_One_Sequence_Session_Ressource_Mass_result, setAdd_One_Sequence_Session_Ressource_Mass_result] = useState();
    const Add_One_Sequence_Session_Ressource_Mass = tab_ids => {

        var new_ressource_type = Dialog_seq_ressource_1_selected_type_ressource;
        var new_ressource_id = Dialog_seq_ressource_1_selected_ressource_id;
        var new_ressource_poste = Dialog_seq_ressource_1_selected_ressource_poste;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_sequence_session_id", tab_selected_sequence_id);
        form.append("poste", new_ressource_poste);
        form.append("related_target_collection", new_ressource_type);
        form.append("related_target_collection_id", new_ressource_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Affectation_Ressource_Poste_Mass/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_One_Sequence_Session_Ressource_Mass  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Sequence_Session_Ressource_Mass  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_One_Sequence_Session_Ressource_Mass_api("true");
                setAdd_One_Sequence_Session_Ressource_Mass_result(res.data.message);

                Getall_List_Sequence_Ressource(selected_sequence_id);

                setDialog_seq_ressource_1_selected_type_ressource("");
                setDialog_seq_ressource_1_selected_ressource_id("");
                setDialog_seq_ressource_1_selected_ressource_poste("");

                setAdd_One_Sequence_Session_mass();
                settab_selected_sequence_id([]);
                setselectionModel_sequence([]);

                alert(res.data.message);
                Dialog_seq_ressource_1_handleClose_buton();
            }
            else {
                setAdd_One_Sequence_Session_Ressource_Mass_api("false");
                setAdd_One_Sequence_Session_Ressource_Mass_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Sequence_Session_Ressource_Mass = ' + error);
            setAdd_One_Sequence_Session_Ressource_Mass_api("false");
            alert(" Impossible d'ajouter la ressource à la séquence");
        })
    }


    const [Getall_Sequence_Of_Session_result_planning_view, setGetall_Sequence_Of_Session_result_planning_view] = useState([]);

    const [Getall_Sequence_Of_Session_api, setGetall_Sequence_Of_Session_api] = useState();
    const [Getall_Sequence_Of_Session_message, setGetall_Sequence_Of_Session_message] = useState();
    const [Getall_Sequence_Of_Session_result, setGetall_Sequence_Of_Session_result] = useState([]);
    function Getall_Sequence_Of_Session(local_session_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", local_session_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Session_Sequence_List/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Sequence_Of_Session  res.data.status = " + res.data.status);
                // console.log(" In Getall_Sequence_Of_Session  res.data.message r_class = " + res.data.message);
                setGetall_Sequence_Of_Session_api("true");
                setGetall_Sequence_Of_Session_result(res.data.message);

                //console.log(" ##NB_Sequence  = ", res.data.message.length);
                setnb_sequence(res.data.message.length);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //console.log(" ### new_data XXX = ", x);

                    //---
                    var localid = JSON.parse(x)._id; // on donne volontairement a l'id la valeur de '_id'
                    var local_id = JSON.parse(x)._id;
                    var local_title = JSON.parse(x).sequence_title;
                    var local_start = new Date(moment(JSON.parse(x).sequence_start, "DD/MM/YYYY hh:mm"))
                    var local_end = new Date(moment(JSON.parse(x).sequence_end, "DD/MM/YYYY hh:mm"))

                    var local_agenda = JSON.parse(x).agenda;
                    var local_objectif = JSON.parse(x).objectif;
                    var local_session_id = JSON.parse(x).session_id;
                    var local_session_comment = JSON.parse(x).commentaire;
                    var local_session_type = JSON.parse(x).type;

                    var local_session_ue_id = JSON.parse(x).ue_id;
                    var local_session_grp_apprenant_id = JSON.parse(x).grp_apprenant_id;

                    var local_session_ue_planif_line_id = JSON.parse(x).unite_enseignement_planif_id;
                    var local_session_color = JSON.parse(x).grp_apprenant_color;
                    var local_session_grp_code = JSON.parse(x).grp_apprenant_code;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "title": local_title,
                        "start": local_start,
                        "end": local_end,
                        "grp_code": local_session_grp_code,
                        "color": local_session_color,
                        "local_agenda": local_agenda,
                        "local_objectif": local_objectif,
                        "local_comment": local_session_comment,
                        "local_type": local_session_type,
                        "local_ue_id": local_session_ue_id,
                        "local_grp_apprenant_id": local_session_grp_apprenant_id,
                        "local_ue_planif_line_id": local_session_ue_planif_line_id,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0) {
                    setGetall_Sequence_Of_Session_result_planning_view(new_data2);
                   // console.log(" ### new_data2 Getall_Sequence_Of_Session = ", new_data2);
                }


            }
            else {
                setGetall_Sequence_Of_Session_api("false");
                setGetall_Sequence_Of_Session_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Sequence_Of_Session = ', error);
            setGetall_Sequence_Of_Session_api("false");
            alert("Impossible de récuperer la liste des séquences")
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Modele_Planning_result_planning_view, setNew_Getall_Partner_Modele_Planning_result_planning_view] = useState([]);

    const [Getall_Partner_Modele_Planning_api, setGetall_Partner_Modele_Planning_api] = useState();
    const [Getall_Partner_Modele_Planning_message, setGetall_Partner_Modele_Planning_message] = useState();
    const [Getall_Partner_Modele_Planning_result, setGetall_Partner_Modele_Planning_result] = useState([]);
    function Getall_Partner_Modele_Planning() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Sequence_Planning_Model_Header/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Modele_Planning  res.data.status = " + res.data.status);
                //  console.log(" In Getall_Partner_Modele_Planning  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Modele_Planning_api("true");
                setGetall_Partner_Modele_Planning_result(res.data.message);

                //console.log(" ##NB_Sequence  = ", res.data.message.length);
                setnb_sequence(res.data.message.length);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //console.log(" ### new_data XXX = ", x);

                    //---
                    var localid = JSON.parse(x)._id; // on donne volontairement a l'id la valeur de '_id'
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;

                    var local_nom = JSON.parse(x).nom;
                    var local_code = JSON.parse(x).code;
                    var local_is_public = JSON.parse(x).is_public;
                    var local_comment = JSON.parse(x).comment;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "nom": local_nom,
                        "code": local_code,
                        "is_public": local_is_public,
                        "comment": local_comment,
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0) {
                    setNew_Getall_Partner_Modele_Planning_result_planning_view(new_data2);
                    // console.log(" ### new_data2 = ", new_data2);
                }


            }
            else {
                setGetall_Partner_Modele_Planning_api("false");
                setGetall_Partner_Modele_Planning_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Modele_Planning = ', error);
            setGetall_Partner_Modele_Planning_api("false");
            alert("Impossible de récuperer la liste des modèles de planning")
            //setmyApimyApiMessage("")
        })
    }



    const [planning_model_new_application_date, setplanning_model_new_application_date] = useState();
    const [new_planification_lines, setnew_planification_lines] = useState([]);

    const [New_Get_Given_Sequence_Planning_Model_With_Option_result_planning_view, setNew_Get_Given_Sequence_Planning_Model_With_Option_result_planning_view] = useState([]);

    const [Get_Given_Sequence_Planning_Model_With_Option_api, setGet_Given_Sequence_Planning_Model_With_Option_api] = useState();
    const [Get_Given_Sequence_Planning_Model_With_Option_message, setGet_Given_Sequence_Planning_Model_With_Option_message] = useState();
    const [Get_Given_Sequence_Planning_Model_With_Option_result, setGet_Given_Sequence_Planning_Model_With_Option_result] = useState([]);
    function Get_Given_Sequence_Planning_Model_With_Option() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_modele_planning_id);
        form.append("date_debut", format(planning_model_new_application_date, 'dd/MM/yyyy'));


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Sequence_Planning_Model_With_Option/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {

                Close_Dialog_RECORD_MODEL_PLANNING();
                Close_Dialog_MODEL_PLANNING();

                //console.log(" In Get_Given_Sequence_Planning_Model_With_Option  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Sequence_Planning_Model_With_Option  res.data.message r_class.lines_data = " + res.data.message.lines_data);
                setGet_Given_Sequence_Planning_Model_With_Option_api("true");
                setGet_Given_Sequence_Planning_Model_With_Option_result(res.data.message);

                var mylocaldata = JSON.parse(res.data.message.lines_data);


                //setnb_sequence(res.data.message.length);

                var new_data2 = [];
                const new_data = mylocaldata.map((x) => {

                    //---
                    var localid = (x)._id; // on donne volontairement a l'id la valeur de '_id'
                    var local_id = (x)._id;
                    var local_title = (x).sequence_title;

                    var local_start = new Date(moment((x).new_sequence_start, "DD/MM/YYYY hh:mm"))
                    var local_end = new Date(moment((x).new_sequence_end, "DD/MM/YYYY hh:mm"))

                    var local_agenda = (x).agenda;
                    var local_objectif = (x).objectif;
                    var local_session_id = (x).session_id;
                    var local_session_comment = (x).commentaire;
                    var local_session_type = (x).type;

                    var local_session_ue_id = (x).ue_id;
                    var local_session_grp_apprenant_id = (x).grp_apprenant_id;

                    var local_session_ue_planif_line_id = (x).unite_enseignement_planif_id;
                    var local_session_color = (x).grp_apprenant_color;
                    var local_session_grp_code = (x).grp_apprenant_code;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "title": local_title,
                        "start": local_start,
                        "end": local_end,
                        "grp_code": local_session_grp_code,
                        "color": local_session_color,
                        "local_agenda": local_agenda,
                        "local_objectif": local_objectif,
                        "local_comment": local_session_comment,
                        "local_type": local_session_type,
                        "local_ue_id": local_session_ue_id,
                        "local_grp_apprenant_id": local_session_grp_apprenant_id,
                        "local_ue_planif_line_id": local_session_ue_planif_line_id,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0) {
                    setnew_planification_lines(new_data2);
                   // console.log(" ### new_data2 = ", new_data2);
                }

                // console.log(" ### ENN new_data2 = ", new_data2);
            }
            else {
                setGet_Given_Sequence_Planning_Model_With_Option_api("false");
                setGet_Given_Sequence_Planning_Model_With_Option_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Given_Sequence_Planning_Model_With_Option = ', error);
            setGet_Given_Sequence_Planning_Model_With_Option_api("false");
            alert("Impossible de récuperer les données du modèle de planning")
            //setmyApimyApiMessage("")
        })
    }



    const [selectionModel_sequence_new_planification, setselectionModel_sequence_new_planification] = React.useState([]);

    const [selectionModel_sequence, setselectionModel_sequence] = React.useState([]);
    const [Dialog_seq_1_message, setDialog_seq_1_message] = React.useState(false);
    const [Dialog_seq_1_open, setDialog_seq_1_open] = React.useState(false);

    function Close_Dialog_Detail_seq_open() {
        clearSequence_Data();
        setNew_Get_List_Given_UE_Planif_lines_result([])


        var node = {
            "_id": "",
            "id": "",
            "label": "",
            "code": "",
            "titre": "",
            "volume_horaire": "",
            "in_use": "",


        };
        var new_data2 = [];
        new_data2.push(node);
        setNew_Get_List_Given_UE_Planif_lines_result(new_data2);

        setp_detail_sequence_ue_planif_line_id("");
        setp_detail_sequence_groupe_apprenant_id("");
        setp_detail_sequence_ue_id("");
        seteu_planif_line_insdispo("");
        setp_detail_sequence_ue_planif_line_id("");
        setDialog_seq_1_open(false);
        submenu_sequence_detail();
        setDialog_seq_ressource_1_selected_ressource_id("");
        setDialog_seq_ressource_1_selected_type_ressource("");
        setDialog_seq_ressource_1_selected_ressource_id("");
        setDialog_seq_ressource_1_selected_ressource_poste("");
        setselected_sequence_id("");
    }

    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_1_message(message);
        setDialog_seq_1_open(true);
    }


    const [Dialog_DIALOG_AGENDA_message, setDialog_DIALOG_AGENDA_message] = React.useState(false);
    const [Dialog_DIALOG_AGENDA_open, setDialog_DIALOG_AGENDA_open] = React.useState(false);
    function Dialog_DIALOG_AGENDA_handle_change_participant_session(message) {
        setDialog_DIALOG_AGENDA_message(message);
        setDialog_DIALOG_AGENDA_open(true);
    }

    const Dialog_DIALOG_AGENDA_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_DIALOG_AGENDA_handleClose_buton = () => {

        setNew_Get_List_Given_UE_Planif_lines_result([])


        var node = {
            "_id": "",
            "id": "",
            "label": "",
            "code": "",
            "titre": "",
            "volume_horaire": "",
            "in_use": "",


        };
        var new_data2 = [];
        new_data2.push(node);
        setNew_Get_List_Given_UE_Planif_lines_result(new_data2);

        setp_detail_sequence_ue_planif_line_id("");
        setp_detail_sequence_groupe_apprenant_id("");
        setp_detail_sequence_ue_id("");
        seteu_planif_line_insdispo("");
        setp_detail_sequence_ue_planif_line_id("");

        setDialog_DIALOG_AGENDA_open(false);
    };

    const New_Option_Type_Event = [

        { "id": "0", "label": "Absence", "value": "absence" },
        { "id": "1", "label": "Planning", "value": "planning" },
        { "id": "2", "label": "Autre", "value": "autre" },
        { "id": "", "label": "", "value": "" },
    ]


    const [Dialog_seq_planif_periode_type, setDialog_seq_planif_periode_type] = React.useState("");


    const [Dialog_seq_CREATION_SEMAINE_TYPE_message, setDialog_seq_CREATION_SEMAINE_TYPE_message] = React.useState(false);
    const [Dialog_seq_CREATION_SEMAINE_TYPE_open, setDialog_seq_CREATION_SEMAINE_TYPE_open] = React.useState(false);



    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_CREATION_SEMAINE_TYPE_message(message);
        setDialog_seq_CREATION_SEMAINE_TYPE_open(true);
    }

    const Dialog_seq_CREATION_SEMAINE_TYPE_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_seq_CREATION_SEMAINE_TYPE_handleClose_buton = () => {
        setDialog_seq_CREATION_SEMAINE_TYPE_open(false);
    };


    const [semaine_type_event, setsemaine_type_event] = React.useState([]);


    const [Dialog_EVENT_SEMAINE_TYPE_message, setDialog_EVENT_SEMAINE_TYPE_message] = React.useState(false);
    const [Dialog_EVENT_SEMAINE_TYPE_open, setDialog_EVENT_SEMAINE_TYPE_open] = React.useState(false);
    function Dialog_EVENT_SEMAINE_TYPE_handle_change_participant_session(message) {
        setDialog_EVENT_SEMAINE_TYPE_message(message);
        setDialog_EVENT_SEMAINE_TYPE_open(true);
    }

    const Dialog_EVENT_SEMAINE_TYPE_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_EVENT_SEMAINE_TYPE_handleClose_buton = () => {
        setp_detail_sequence_event_type_semaine_id("");
        setp_detail_sequence_event_type_semaine_title("");
        setp_detail_sequence_event_type_semaine_comment("");
        setp_detail_sequence_event_type_semaine_agenda("");
        setp_detail_sequence_event_type_semaine_objectif("");

        setsequence_event_type_semaine_startDate(new Date());
        setsequence_event_type_semaine_endDate(new Date());

        setDialog_EVENT_SEMAINE_TYPE_open(false);
        setDialog_seq_CREATION_SEMAINE_TYPE_open(true);
    };



    const [p_detail_sequence_event_type_semaine_id, setp_detail_sequence_event_type_semaine_id] = useState("");

    const [p_detail_sequence_event_type_semaine_title, setp_detail_sequence_event_type_semaine_title] = useState("");
    const [p_detail_sequence_event_type_semaine_comment, setp_detail_sequence_event_type_semaine_comment] = useState("");
    const [p_detail_sequence_event_type_semaine_agenda, setp_detail_sequence_event_type_semaine_agenda] = useState("");
    const [p_detail_sequence_event_type_semaine_objectif, setp_detail_sequence_event_type_semaine_objectif] = useState("");


    const [sequence_event_type_semaine_startDate, setsequence_event_type_semaine_startDate] = useState(new Date());
    const [sequence_event_type_semaine_endDate, setsequence_event_type_semaine_endDate] = useState(new Date());


    const handleSelect_event_type_semaine = (info) => {


        setDialog_seq_CREATION_SEMAINE_TYPE_open(false);


        setp_detail_sequence_event_type_semaine_title("Nouvelle")
        setsequence_event_type_semaine_startDate(info.start)
        setsequence_event_type_semaine_endDate(info.end)
        setp_detail_sequence_event_type_semaine_agenda("");
        //setp_detail_event_id("");
        setp_detail_sequence_event_type_semaine_objectif("");
        setp_detail_sequence_event_type_semaine_comment("");

        setDialog_EVENT_SEMAINE_TYPE_open(true);

    };


    const renderEventContent_event_type_semaine = (eventInfo) => {
        //console.log(eventInfo);
        return (
            <>
                <b>{eventInfo.timeText}</b><br />
                <i>{eventInfo.event.title}</i>
            </>
        )
    };


    const handleEventClick_event_type_semaine = (arg) => {

        console.log(" arg = ", arg);

        setp_detail_sequence_event_type_semaine_title(arg.event.title)
        setsequence_event_type_semaine_startDate(arg.event.start)
        setsequence_event_type_semaine_endDate(arg.event.end)


        setp_detail_sequence_event_type_semaine_id(arg.event.id);


        if (arg.event.extendedProps.comment) {
            setp_detail_sequence_event_type_semaine_comment(arg.event.extendedProps.comment);
        }
        else
            setp_detail_sequence_event_type_semaine_comment("");


        if (arg.event.extendedProps.objectif) {
            setp_detail_sequence_event_type_semaine_objectif(arg.event.extendedProps.objectif);
        }
        else
            setp_detail_sequence_event_type_semaine_objectif("");



        if (arg.event.extendedProps.agenda) {
            setp_detail_sequence_event_type_semaine_agenda(arg.event.extendedProps.agenda);

        }
        else
            setp_detail_sequence_event_type_semaine_agenda("");


        setDialog_seq_CREATION_SEMAINE_TYPE_open(false)
        setDialog_EVENT_SEMAINE_TYPE_open(true);

    }


    const Add_event_type_semaine = event => {

        setDialog_seq_CREATION_SEMAINE_TYPE_open(false);

        var new_title = p_detail_sequence_event_type_semaine_title;
        var new_agenda = p_detail_sequence_event_type_semaine_agenda;
        var new_objectif = p_detail_sequence_event_type_semaine_objectif;
        var new_comment = p_detail_sequence_event_type_semaine_comment;
        var new_start_date = moment(sequence_event_type_semaine_startDate).format("YYYY-MM-DD HH:mm:ss");
        var new_end_date = moment(sequence_event_type_semaine_endDate).format("YYYY-MM-DD HH:mm:ss");

        var local_id = String(Date.now())

        var local_tab = semaine_type_event;

        moment.locale('fr');
        var local_getDay = moment(sequence_event_type_semaine_startDate).format('dddd')



        var node = {
            "id": local_id,
            "title": new_title,
            "start": new_start_date,
            "end": new_end_date,
            "agenda": new_agenda,
            "objectif": new_objectif,
            "comment": new_comment,
            "day_week": local_getDay,
        };
        local_tab.push(node);
        setsemaine_type_event(local_tab);

        Dialog_EVENT_SEMAINE_TYPE_handleClose_buton();
        //console.log(" semaine_type_event = ", semaine_type_event);

        setDialog_seq_CREATION_SEMAINE_TYPE_open(true);

        setp_detail_sequence_event_type_semaine_id("");
        setp_detail_sequence_event_type_semaine_title("");
        setp_detail_sequence_event_type_semaine_agenda("");
        setp_detail_sequence_event_type_semaine_objectif("");
        setp_detail_sequence_event_type_semaine_comment("");
        setsequence_event_type_semaine_endDate(new Date());
        setsequence_event_type_semaine_startDate(new Date());


        setDialog_EVENT_SEMAINE_TYPE_open(true);

    }



    const Update_event_type_semaine = event => {

        var new_title = p_detail_sequence_event_type_semaine_title;
        var new_agenda = p_detail_sequence_event_type_semaine_agenda;
        var new_objectif = p_detail_sequence_event_type_semaine_objectif;
        var new_comment = p_detail_sequence_event_type_semaine_comment;
        var new_start_date = moment(sequence_event_type_semaine_startDate).format("YYYY-MM-DD HH:mm:ss");
        var new_end_date = moment(sequence_event_type_semaine_endDate).format("YYYY-MM-DD HH:mm:ss");
        var local_id = p_detail_sequence_event_type_semaine_id

        var local_tab = semaine_type_event;

        moment.locale('fr');
        var local_getDay = moment(sequence_event_type_semaine_startDate).format('dddd')



        var node = {
            "id": local_id,
            "title": new_title,
            "start": new_start_date,
            "end": new_end_date,
            "agenda": new_agenda,
            "objectif": new_objectif,
            "comment": new_comment,
            "day_week": local_getDay,
        };


        for (let i = 0; i < local_tab.length; i++) {
            var local_node = local_tab[i]


            if (String(local_node['id']) === String(p_detail_sequence_event_type_semaine_id)) {
                local_node['title'] = new_title;
                local_node['start'] = new_start_date;
                local_node['end'] = new_end_date;
                local_node['agenda'] = new_agenda;
                local_node['objectif'] = new_objectif;
                local_node['comment'] = new_comment;
                local_node['day_week'] = local_getDay;
            }
        }


        setsemaine_type_event(local_tab);

        Dialog_EVENT_SEMAINE_TYPE_handleClose_buton();
        //console.log(" semaine_type_event = ", semaine_type_event);


        setp_detail_sequence_event_type_semaine_id("");
        setp_detail_sequence_event_type_semaine_title("");
        setp_detail_sequence_event_type_semaine_agenda("");
        setp_detail_sequence_event_type_semaine_objectif("");
        setp_detail_sequence_event_type_semaine_comment("");
        setsequence_event_type_semaine_endDate(new Date());
        setsequence_event_type_semaine_startDate(new Date());

        setDialog_seq_CREATION_SEMAINE_TYPE_open(true);
    }


    const Remove_event_type_semaine = event => {

        var local_id = p_detail_sequence_event_type_semaine_id

        var local_tab = semaine_type_event;



        const result = local_tab.filter(element => element.id !== local_id);

        setsemaine_type_event(result);

        Dialog_EVENT_SEMAINE_TYPE_handleClose_buton();
        //console.log(" semaine_type_event = ", semaine_type_event);


        setp_detail_sequence_event_type_semaine_id("");
        setp_detail_sequence_event_type_semaine_title("");
        setp_detail_sequence_event_type_semaine_agenda("");
        setp_detail_sequence_event_type_semaine_objectif("");
        setp_detail_sequence_event_type_semaine_comment("");
        setsequence_event_type_semaine_endDate(new Date());
        setsequence_event_type_semaine_startDate(new Date());

        setDialog_seq_CREATION_SEMAINE_TYPE_open(true);
    }

    const [planification_model_date_from, setplanification_model_date_from] = useState();
    const [planification_model_date_to, setplanification_model_date_to] = useState();


    const [planification_ecrase_existing_seq, setplanification_ecrase_existing_seq] = useState("1");


    function submenu_add_one_Ressource() {
        setGet_Ressource_Avabilities_avability_check("");
        setcheck_ressource_avabilities("1");
        setDialog_seq_ressource_1_open(true);
    }


    function clearSequence_Data() {
        setupdate_sequence();
        setp_detail_sequence_title("");

        setp_detail_sequence_comment("");
        setp_detail_sequence_agenda("");
        setp_detail_sequence_objectif("");

        setsequence_startDate(new Date());
        setsequence_endDate(new Date());

    }

    function submenu_add_one_sequence() {

        setselected_sequence_id("");
        setupdate_sequence();
        setp_detail_sequence_title("");

        setp_detail_sequence_comment("");
        setp_detail_sequence_agenda("");
        setp_detail_sequence_objectif("");

        setsequence_startDate(new Date());
        setsequence_endDate(new Date());

        setDialog_seq_1_open(true);
    }


    function submenu_update_sequence() {
        setupdate_sequence("1");

        setDialog_seq_1_open(true);
    }


    const [nb_sequence, setnb_sequence] = useState("0");

    const [Dialog_seq_ressource_1_message, setDialog_seq_ressource_1_message] = React.useState(false);
    const [Dialog_seq_ressource_1_open, setDialog_seq_ressource_1_open] = React.useState(false);


    function Dialog_1_handle_change_participant_session(message) {
        setDialog_seq_ressource_1_message(message);
        setDialog_seq_ressource_1_open(true);
    }

    const Dialog_seq_ressource_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_seq_ressource_1_handleClose_buton = () => {
        setGet_Ressource_Avabilities_avability_check("");
        setcheck_ressource_avabilities("");
        setDialog_seq_ressource_1_open(false);
    };

    const handleEventClick = (arg) => {


        setp_detail_sequence_title(arg.event.title)
        setsequence_startDate(arg.event.start)
        setsequence_endDate(arg.event.end)
        setp_detail_event_id(arg.event.id);
        setsubmenu_seq_dialog("dialog_detail");

        setGetall_List_Sequence_Ressource_result([]);
        setselectionModel_sequence([]);

        console.log(" ### arg.event.extendedProps = ", arg.event.extendedProps);

        console.log(" ### arg.event.extendedProps.local_type = ", arg.event.extendedProps.local_type);

        if (arg.event.extendedProps._id) {
            setselected_sequence_id(arg.event.extendedProps._id);
            Getall_List_Sequence_Ressource(arg.event.extendedProps._id);
        }
        else
            setselected_sequence_id("");

        if (arg.event.extendedProps.local_comment) {
            setp_detail_sequence_comment(arg.event.extendedProps.local_comment);
        }
        else
            setp_detail_sequence_comment("");

        if (arg.event.extendedProps.local_type) {
            setp_detail_event_type(arg.event.extendedProps.local_type);
        }
        else
            setp_detail_event_type("");


        if (arg.event.extendedProps.local_ue_id) {
            setp_detail_sequence_ue_id(arg.event.extendedProps.local_ue_id);
            Get_List_Given_UE_Planif_lines(arg.event.extendedProps.local_ue_id);
        }
        else {
            setp_detail_sequence_ue_id("");
            //---
            var node = {
                "_id": "",
                "id": "",
                "label": "",
                "code": "",
                "titre": "",
                "volume_horaire": "",
                "in_use": "",


            };
            var new_data2 = [];
            new_data2.push(node);
            setNew_Get_List_Given_UE_Planif_lines_result(new_data2);
        }


        if (arg.event.extendedProps.local_grp_apprenant_id) {
            setp_detail_sequence_groupe_apprenant_id(arg.event.extendedProps.local_grp_apprenant_id);
            //console.log(" ## arg.event.extendedProps.local_grp_apprenant_id = ", arg.event.extendedProps.local_grp_apprenant_id)
        }
        else
            setp_detail_sequence_groupe_apprenant_id("");




        if (arg.event.extendedProps.local_objectif) {
            setp_detail_sequence_objectif(arg.event.extendedProps.local_objectif);
        }
        else
            setp_detail_sequence_objectif("");

        if (arg.event.extendedProps._id) {
            setp_detail_event_id(arg.event.extendedProps._id);
        }
        else
            setp_detail_event_id("");


        if (arg.event.extendedProps.local_agenda) {
            setp_detail_sequence_agenda(arg.event.extendedProps.local_agenda);
        }
        else
            setp_detail_sequence_agenda("");

        if (arg.event.extendedProps.local_ue_planif_line_id) {
            setp_detail_sequence_ue_planif_line_id(arg.event.extendedProps.local_ue_planif_line_id);

        }
        else {
            setp_detail_sequence_ue_planif_line_id("");

        }

        setDialog_DIALOG_AGENDA_open(true);

    }


    const [Dialog_seq_choix_model_emargement, setDialog_seq_choix_model_emargement] = React.useState("0");


    const New_Option_Type_Ressource = [
        { "id": "0", "label": "Employe", "value": "ressource_humaine" },
        { "id": "1", "label": "Materiel", "value": "ressource_materielle" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_Model_Sequence = [
        { "id": "0", "label": "Calendrier de travail", "value": "0" },
        { "id": "1", "label": "La 1/2 journée", "value": "1" },
        { "id": "2", "label": "La journée", "value": "2" },
        { "id": "3", "label": "Un modèle à la semaine", "value": "3" },
        { "id": "4", "label": "Un modèle au mois", "value": "4" },
    ]

    const New_Option_Gestion_Model_Planning = [
        { "id": "record", "label": "Enreg. Modèle", "value": "record" },
        { "id": "gestion", "label": "Gestion modèles", "value": "gestion" },
        { "id": "", "label": "", "value": "" },
    ]


    const datagridSx = {
        borderRadius: 2,
        marginRight: 1,
        marginLeft: 1,
        border: 0,
        "& .MuiDataGrid-main": { borderRadius: 0 },

        "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
            }
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#c8cfd5",
            color: "black",
            fontSize: 16
        }
    };


    const [Add_Automatic_Sequence_Session_api, setAdd_Automatic_Sequence_Session_api] = useState();
    const [Add_Automatic_Sequence_Session_message, setAdd_Automatic_Sequence_Session_message] = useState();
    const [Add_Automatic_Sequence_Session_result, setAdd_Automatic_Sequence_Session_result] = useState();
    const Add_Automatic_Sequence_Session = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(sequence_startDate).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(sequence_endDate).format("DD/MM/YYYY HH:mm");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("jounree_modele_id", "");
        form.append("option", Dialog_seq_choix_model_emargement);
        form.append("ecrase_existing", planification_ecrase_existing_seq);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Create_Automatic_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Automatic_Sequence_Session  res.data.status = " + res.data.status);
            //console.log(" In Add_Automatic_Sequence_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Automatic_Sequence_Session_api("true");
                setAdd_Automatic_Sequence_Session_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title("");
                setp_detail_sequence_comment("");

                setp_detail_sequence_agenda("");
                setp_detail_sequence_objectif("");

                setsequence_startDate();
                setsequence_endDate();

                setDialog_seq_choix_model_emargement_open(false);
                setDialog_seq_planif_periode_type("");
                setplanification_ecrase_existing_seq("");

                alert(res.data.message);
                Close_Dialog_Detail_seq_open();

            }
            else {
                setAdd_Automatic_Sequence_Session_api("false");
                setAdd_Automatic_Sequence_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Automatic_Sequence_Session = ' + error);
            setAdd_Automatic_Sequence_Session_api("false");
            alert(" Impossible de créer automatiquement les séquences");
        })
    }

    const [Update_One_Sequence_Session_api, setUpdate_One_Sequence_Session_api] = useState();
    const [Update_One_Sequence_Session_message, setUpdate_One_Sequence_Session_message] = useState();
    const [Update_One_Sequence_Session_result, setUpdate_One_Sequence_Session_result] = useState();
    const Update_One_Sequence_Session = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(sequence_startDate).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(sequence_endDate).format("DD/MM/YYYY HH:mm");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type", p_detail_event_type);
        form.append("session_id", selected_session_id);
        form.append("sequence_title", new_title);
        form.append("sequence_start", new_start_date);
        form.append("sequence_end", new_end_date);

        form.append("agenda", p_detail_sequence_agenda);
        form.append("objectif", p_detail_sequence_objectif);
        form.append("commentaire", p_detail_sequence_comment);
        form.append("grp_apprenant_id", p_detail_sequence_groupe_apprenant_id);
        form.append("ue_id", p_detail_sequence_ue_id);

        form.append("ue_planif_line_id", p_detail_sequence_ue_planif_line_id);


        form.append("_id", selected_sequence_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Session_Sequence/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_One_Sequence_Session  res.data.status = " + res.data.status);
            //console.log(" In Update_One_Sequence_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_One_Sequence_Session_api("true");
                setUpdate_One_Sequence_Session_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title("");
                setp_detail_sequence_comment("");

                setp_detail_sequence_agenda("");
                setp_detail_sequence_objectif("");

                setsequence_startDate();
                setsequence_endDate();

                setupdate_sequence();

                alert(res.data.message);
                Dialog_DIALOG_AGENDA_handleClose_buton();
                Close_Dialog_Detail_seq_open();

            }
            else {
                setUpdate_One_Sequence_Session_api("false");
                setUpdate_One_Sequence_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_One_Sequence_Session = ' + error);
            setUpdate_One_Sequence_Session_api("false");
            alert(" Impossible de mettre à jour  la séquence");
        })
    }



    const [Add_Automatic_Sequence_Session_From_Week_Model_api, setAdd_Automatic_Sequence_Session_From_Week_Model_api] = useState();
    const [Add_Automatic_Sequence_Session_From_Week_Model_message, setAdd_Automatic_Sequence_Session_From_Week_Model_message] = useState();
    const [Add_Automatic_Sequence_Session_From_Week_Model_result, setAdd_Automatic_Sequence_Session_From_Week_Model_result] = useState([]);
    const Add_Automatic_Sequence_Session_From_Week_Model = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(planification_model_date_from).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(planification_model_date_to).format("DD/MM/YYYY HH:mm");

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("planif_date_debut", new_start_date);
        form.append("planif_date_fin", new_end_date);
        form.append("ecrase_existing", planification_ecrase_existing_seq);

        var json_str = JSON.stringify(semaine_type_event)
        form.append("semaine_tab_event", json_str);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Create_Automatic_Sequence_From_Week_Model/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Automatic_Sequence_Session_From_Week_Model  res.data.status = " + res.data.status);
            //console.log(" In Add_Automatic_Sequence_Session_From_Week_Model  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Automatic_Sequence_Session_From_Week_Model_api("true");
                setAdd_Automatic_Sequence_Session_From_Week_Model_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title("");
                setp_detail_sequence_comment("");

                setp_detail_sequence_agenda("");
                setp_detail_sequence_objectif("");

                setsequence_startDate();
                setsequence_endDate();

                setDialog_seq_choix_model_emargement_open(false);
                setDialog_seq_planif_periode_type("");

                alert(res.data.message);

                Close_Dialog_Detail_seq_open();
                Dialog_seq_CREATION_SEMAINE_TYPE_handleClose_buton();
                Dialog_EVENT_SEMAINE_TYPE_handleClose_buton();
                Dialog_seq_CREATION_SEMAINE_TYPE_handleClose_buton();

            }
            else {
                setAdd_Automatic_Sequence_Session_From_Week_Model_api("false");
                setAdd_Automatic_Sequence_Session_From_Week_Model_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Automatic_Sequence_Session_From_Week_Model = ' + error);
            setAdd_Automatic_Sequence_Session_From_Week_Model_api("false");
            alert(" Impossible de créer automatiquement les séquences");
        })
    }


    const [Add_Automatic_Sequence_Session_From_Month_Model_api, setAdd_Automatic_Sequence_Session_From_Month_Model_api] = useState();
    const [Add_Automatic_Sequence_Session_From_Month_Model_message, setAdd_Automatic_Sequence_Session_From_Month_Model_message] = useState();
    const [Add_Automatic_Sequence_Session_From_Month_Model_result, setAdd_Automatic_Sequence_Session_From_Month_Model_result] = useState([]);
    const Add_Automatic_Sequence_Session_From_Month_Model = event => {

        var new_title = p_detail_sequence_title;
        var new_start_date = moment(planification_model_date_from).format("DD/MM/YYYY HH:mm");
        var new_end_date = moment(planification_model_date_to).format("DD/MM/YYYY HH:mm");

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("planif_date_debut", new_start_date);
        form.append("planif_date_fin", new_end_date);
        form.append("ecrase_existing", planification_ecrase_existing_seq);

        var json_str = JSON.stringify(semaine_type_event)
        form.append("semaine_tab_event", json_str);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Create_Automatic_Sequence_From_Month_Model/";

        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Automatic_Sequence_Session_From_Month_Model  res.data.status = " + res.data.status);
            //console.log(" In Add_Automatic_Sequence_Session_From_Month_Model  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Automatic_Sequence_Session_From_Month_Model_api("true");
                setAdd_Automatic_Sequence_Session_From_Month_Model_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);

                setp_detail_sequence_title("");
                setp_detail_sequence_comment("");

                setp_detail_sequence_agenda("");
                setp_detail_sequence_objectif("");

                setsequence_startDate();
                setsequence_endDate();

                setDialog_seq_choix_model_emargement_open(false);
                setDialog_seq_planif_periode_type("");

                alert(res.data.message);
                Close_Dialog_Detail_seq_open();
                Dialog_seq_CREATION_SEMAINE_TYPE_handleClose_buton();
                Dialog_EVENT_SEMAINE_TYPE_handleClose_buton();
                Dialog_seq_CREATION_SEMAINE_TYPE_handleClose_buton();



            }
            else {
                setAdd_Automatic_Sequence_Session_From_Month_Model_api("false");
                setAdd_Automatic_Sequence_Session_From_Month_Model_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Automatic_Sequence_Session_From_Month_Model = ' + error);
            setAdd_Automatic_Sequence_Session_From_Month_Model_api("false");
            alert(" Impossible de créer automatiquement les séquences");
        })
    }

    const [selected_formulaire_id, setselected_formulaire_id] = useState();

    const handleSelect = (info) => {
        setp_detail_sequence_title("Nouvelle")
        setsequence_startDate(info.start)
        setsequence_endDate(info.end)
        setp_detail_sequence_agenda("");
        setp_detail_event_id("");
        setselected_sequence_id("");
        setp_detail_sequence_objectif("");
        setp_detail_sequence_comment("");
        setsubmenu_seq_dialog("dialog_detail");

        setGetall_List_Sequence_Ressource_result([]);
        setselectionModel_sequence([]);
        setDialog_DIALOG_AGENDA_open(true);
    };


    const [New_Getall_Partner_Groupe_Inscrit_result, setNew_Getall_Partner_Groupe_Inscrit_result] = useState([]);

    const [Getall_Partner_Groupe_Inscrit_api, setGetall_Partner_Groupe_Inscrit_api] = useState();
    const [Getall_Partner_Groupe_Inscrit_message, setGetall_Partner_Groupe_Inscrit_message] = useState();
    const [Getall_Partner_Groupe_Inscrit_result, setGetall_Partner_Groupe_Inscrit_result] = useState([]);
    function Getall_Partner_Groupe_Inscrit(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.related_collection_recid);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Groupe_Inscrit_With_Filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Groupe_Inscrit  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Groupe_Inscrit  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Groupe_Inscrit_api("true");
                setGetall_Partner_Groupe_Inscrit_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_type_groupe_code = JSON.parse(x).type_groupe_code;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_session_id = JSON.parse(x).session_id;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "nom": local_nom,
                        "type_groupe_code": local_type_groupe_code,
                        "class_id": local_class_id,
                        "session_id": local_session_id,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "nom": "",
                    "type_groupe_code": "",
                    "class_id": "",
                    "session_id": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Groupe_Inscrit_result(new_data2);

            }
            else {
                setGetall_Partner_Groupe_Inscrit_api("false");
                setGetall_Partner_Groupe_Inscrit_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Groupe_Inscrit = ', error);
            setGetall_Partner_Groupe_Inscrit_api("false");
            alert(" Impossible de recuperer la liste des groupes d'inscrits");
            //setmyApimyApiMessage("")
        })
    }



    const [New_Getall_Class_List_UE_result, setNew_Getall_Class_List_UE_result] = useState([]);

    const [Getall_Class_List_UE_api, setGetall_Class_List_UE_api] = useState();
    const [Getall_Class_List_UE_message, setGetall_Class_List_UE_message] = useState();
    const [Getall_Class_List_UE_result, setGetall_Class_List_UE_result] = useState();
    function Getall_Class_List_UE(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", props.session_class_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Of_Given_Class/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Class_List_UE  res.data.status = " + res.data.status);
                //console.log(" In Getall_Class_List_UE  res.data.message r_class = " + res.data.message);
                setGetall_Class_List_UE_api("true");
                setGetall_Class_List_UE_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;


                    var local_credit = JSON.parse(x).credit;
                    var local_pres_dist_hyp = JSON.parse(x).pres_dist_hyp;
                    var local_is_noted = JSON.parse(x).is_noted;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "credit": local_credit,
                        "pres_dist_hyp": local_pres_dist_hyp,
                        "is_noted": local_is_noted,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "credit": "",
                    "pres_dist_hyp": "",
                    "is_noted": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Class_List_UE_result(new_data2);

                // console.log(" ### new_data2 = ", new_data2);
            }
            else {
                setGetall_Class_List_UE_api("false");
                setGetall_Class_List_UE_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Class_List_UE = ', error);
            setGetall_Class_List_UE_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Get_List_Given_UE_Planif_lines_result, setNew_Get_List_Given_UE_Planif_lines_result] = useState([]);

    const [Get_List_Given_UE_Planif_lines_api, setGet_List_Given_UE_Planif_lines_api] = useState();
    const [Get_List_Given_UE_Planif_lines_message, setGet_List_Given_UE_Planif_lines_message] = useState();
    const [Get_List_Given_UE_Planif_lines_result, setGet_List_Given_UE_Planif_lines_result] = useState();
    function Get_List_Given_UE_Planif_lines(local_ue_id) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ue_id", local_ue_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Planif_lines/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_Given_UE_Planif_lines  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Given_UE_Planif_lines  res.data.message r_class = " + res.data.message);
                setGet_List_Given_UE_Planif_lines_api("true");
                setGet_List_Given_UE_Planif_lines_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;
                    var local_volume_horaire = JSON.parse(x).volume_horaire;
                    var local_in_use = JSON.parse(x).in_use;

                    var dispo_indispo_txt = "";
                    if (local_in_use === "1") {
                        dispo_indispo_txt = "INDISPO - ";
                    }

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": dispo_indispo_txt + local_code + "-" + local_titre,
                        "code": local_code,
                        "titre": local_titre,
                        "volume_horaire": local_volume_horaire,
                        "in_use": local_in_use,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "titre": "",
                    "volume_horaire": "",
                    "in_use": "",


                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Get_List_Given_UE_Planif_lines_result(new_data2);

                // console.log(" ### new_data2 = ", new_data2);
            }
            else {
                setGet_List_Given_UE_Planif_lines_api("false");
                setGet_List_Given_UE_Planif_lines_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_List_Given_UE_Planif_lines = ', error);
            setGet_List_Given_UE_Planif_lines_api("false");
            alert(" Impossible de recuperer les lignes de planification de UE de la formation");
            //setmyApimyApiMessage("")
        })
    }


    const [default_h, setdefault_h] = useState('50rem');

    useEffect(() => {

        Getall_Training_Employee_No_Filter();
        Getall_Training_Materiel_No_Filter();

        Getall_Sequence_Of_Session(props.related_collection_recid);

        setSessionendDate(props.SessionendDate);
        setSessionstartDate(props.SessionstartDate);

        Getall_Class_List_UE();
        Getall_Partner_Groupe_Inscrit();

        if (document.getElementById('menu_import_participant')) {
            setdefault_h(document.getElementById('menu_import_participant').offsetTop)

            // console.log(" hh = ", document.getElementById('menu_import_participant').offsetTop);
        }

    }, [])

    const [SessionendDate, setSessionendDate] = useState(props.SessionendDate);

    const filterPassedTime_end = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const [SessionstartDate, setSessionstartDate] = useState(props.SessionstartDate);

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const Dialog_seq_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const [Get_Ressource_Avabilities_avability_check, setGet_Ressource_Avabilities_avability_check] = useState("");

    const [Get_Ressource_Avabilities_api, setGet_Ressource_Avabilities_api] = useState();
    const [Get_Ressource_Avabilities_message, setGet_Ressource_Avabilities_message] = useState();
    const [Get_Ressource_Avabilities_result, setGet_Ressource_Avabilities_result] = useState([]);
    function Get_Ressource_Avabilities(local_related_collection_recid) {

        var form = new FormData();


        var event_start = String(selected_sequence_startDate);
        var event_end = String(selected_sequence_endDate);



        //var event_start = format(String(line[0].eval_date_heure_debut), 'dd/MM/yyyy HH:mm'); 
        //var event_end = format(String(line[0].eval_date_heure_fin), 'dd/MM/yyyy HH:mm');


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("related_collection", Dialog_seq_ressource_1_selected_type_ressource);
        form.append("related_collection_recid", local_related_collection_recid);
        form.append("event_start", String(event_start));
        form.append("event_end", String(event_end));

        //console.log(" ### form = ", form)

        //return;

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Is_Ressource_Available/";

        setLoading(true);


        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                /*console.log(" In Get_Ressource_Avabilities  res.data.status = " + res.data.status);
                console.log(" In Get_Ressource_Avabilities  res.data.message r_class = " + res.data.message);
                console.log(" In Get_Ressource_Avabilities  res.data.avability_check r_class = " + res.data.avability_check);
*/

                setGet_Ressource_Avabilities_api("true");
                setGet_Ressource_Avabilities_result(res.data.message);
                setGet_Ressource_Avabilities_avability_check(res.data.avability_check);

            }
            else {
                setGet_Ressource_Avabilities_api("false");
                setGet_Ressource_Avabilities_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Ressource_Avabilities = ', error);
            setGet_Ressource_Avabilities_api("false");
            alert("Impossible de récuperer la liste des ressources de la sequence")
            //setmyApimyApiMessage("")
        })
    }


    const [check_ressource_avabilities, setcheck_ressource_avabilities] = useState("");

    const [eu_planif_line_insdispo, seteu_planif_line_insdispo] = useState("");

    const [submenu_seq_dialog, setsubmenu_seq_dialog] = useState("dialog_detail");

    function submenu_sequence_detail() {
        setsubmenu_seq_dialog("dialog_detail");
    }

    function submenu_sequence_ressource() {

        if (!selected_sequence_id || String(selected_sequence_id).length < 3) {
            alert("Vous devez créer la séquence avant d'ajouter des ressources ");
            return;
        }
        setsubmenu_seq_dialog("dialog_ressource");
    }


    const [submenu_seq_dialog_ressource_type, setsubmenu_seq_dialog_ressource_type] = useState("");
    const [submenu_seq_dialog_ressource_id, setsubmenu_seq_dialog_ressource_id] = useState("");


    const [action_modele_planning, setaction_modele_planning] = useState("");

    const [Dialog_MODEL_PLANNING_message, setDialog_MODEL_PLANNING_message] = React.useState(false);
    const [Dialog_MODEL_PLANNING_open, setDialog_MODEL_PLANNING_open] = React.useState(false);

    function Close_Dialog_MODEL_PLANNING() {
        setgridline_sequence_new_planificatio_id("");
        setDialog_MODEL_PLANNING_open(false);
        setplanning_model_new_application_date();
    }


    const [selected_modele_planning_id, setselected_modele_planning_id] = useState("");
    const handleRowClick = (
        params, // GridRowParams
        event, // MuiEvent<React.MouseEvent<HTMLElement>>
        details, // GridCallbackDetails
    ) => {

        setselected_modele_planning_id(params.row._id);
        setgridline_sequence_new_planificatio_id(params.row.id);
    };

    const [Dialog_RECORD_MODEL_PLANNING_message, setDialog_RECORD_MODEL_PLANNING_message] = React.useState(false);
    const [Dialog_RECORD_MODEL_PLANNING_open, setDialog_RECORD_MODEL_PLANNING_open] = React.useState(false);

    function Close_Dialog_RECORD_MODEL_PLANNING() {
        setDialog_RECORD_MODEL_PLANNING_open(false);
    }


    const [p_model_planning_code, setp_model_planning_code] = useState("");
    const [p_model_planning_nom, setp_model_planning_nom] = useState("");

    const [Record_New_Planning_Model_api, setRecord_New_Planning_Model_api] = useState();
    const [Record_New_Planning_Model_message, setRecord_New_Planning_Model_message] = useState();
    const [Record_New_Planning_Model_result, setRecord_New_Planning_Model_result] = useState();
    const Record_New_Planning_Model = event => {
        var form = new FormData();

        if (String(p_model_planning_code) === "") {
            alert(" Vous devez définir un code");
            return;
        }

        if (String(p_model_planning_nom) === "") {
            alert(" Vous devez définir un nom");
            return;
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("code", p_model_planning_code);
        form.append("nom", p_model_planning_nom);
        form.append("is_public", "1");
        form.append("comment", "");

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Sequence_Planning_Model/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Record_New_Planning_Model  res.data.status = " + res.data.status);
            //console.log(" In Record_New_Planning_Model  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setRecord_New_Planning_Model_api("true");
                setRecord_New_Planning_Model_result(res.data.message);

                alert(res.data.message);
                Close_Dialog_RECORD_MODEL_PLANNING();
            }
            else {
                setRecord_New_Planning_Model_api("false");
                setRecord_New_Planning_Model_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Record_New_Planning_Model = ' + error);
            setRecord_New_Planning_Model_api("false");
            alert(" Impossible de créer le nouveau modèle de plannig");
        })
    }


    const [Apply_Planning_To_Session_api, setApply_Planning_To_Session_api] = useState();
    const [Apply_Planning_To_Session_message, setApply_Planning_To_Session_message] = useState();
    const [Apply_Planning_To_Session_result, setApply_Planning_To_Session_result] = useState();
    const Apply_Planning_To_Session = event => {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);



        var new_planification_lines_normalized = [];
        const new_data = new_planification_lines.map((x) => {

            var _id = x._id;

            var color = "";
            if (x.color)
                color = x.color;

            var grp_code = "";
            if (x.grp_code)
                grp_code = x.grp_code


            var id = x.id;
            var local_agenda = x.local_agenda;
            var local_comment = x.local_comment;

            var local_grp_apprenant_id = "";
            if (x.local_grp_apprenant_id)
                local_grp_apprenant_id = x.local_grp_apprenant_id

            var local_objectif = x.local_objectif;
            var local_type = x.local_type;


            var local_ue_id = "";
            if( x.local_ue_id )
                local_ue_id = x.local_ue_id


            var local_ue_planif_line_id = "";
            if (x.local_ue_planif_line_id)
                local_ue_planif_line_id = x.local_ue_planif_line_id

            var title = x.title;



            var start = moment(x.start).format("DD/MM/YYYY HH:mm");
            var end = moment(x.end).format("DD/MM/YYYY HH:mm");

            //---
            var node = {
                "_id": _id,
                "color": color,
                "title": title,
                "start": start,
                "end": end,

                "grp_code": grp_code,
                "id": id,
                "local_agenda": local_agenda,

                "local_agenda": local_agenda,
                "local_comment": local_comment,
                "local_grp_apprenant_id": local_grp_apprenant_id,
                "local_objectif": local_objectif,
                "local_type": local_type,
                "local_ue_id": local_ue_id,
                "local_ue_planif_line_id": local_ue_planif_line_id,
               
            }
            new_planification_lines_normalized.push(node);

            });


        var json_str = JSON.stringify(new_planification_lines_normalized)
        form.append("tab_sequence_modele", json_str);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Apply_Planning_Model_To_Session/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Apply_Planning_To_Session  res.data.status = " + res.data.status);
            //console.log(" In Apply_Planning_To_Session  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setApply_Planning_To_Session_api("true");
                setApply_Planning_To_Session_result(res.data.message);

                Getall_Sequence_Of_Session(selected_session_id);
                setnew_planification_lines([]);
                alert(res.data.message);

            }
            else {
                setApply_Planning_To_Session_api("false");
                setApply_Planning_To_Session_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {


            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Apply_Planning_To_Session = ' + error);
            setApply_Planning_To_Session_api("false");
            alert(" Impossible d'appliquer le nouveau planning à la sessions");
        })
    }


    function Cancel_Apply_Planning_To_Session() {

        setnew_planification_lines([])
    }


    const [active_planif_modele, setactive_planif_modele] = useState("");

    return (
        <div className="modulesessionplanification">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}



            {/*** Dialog ajout RECORD MODEL PLANNING  */}

            <Dialog
                open={Dialog_RECORD_MODEL_PLANNING_open}
                //onClose={Dialog_RECORD_MODEL_PLANNING_handleClose}

                static
                onClose={() => null}

                PaperProps={{


                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        //position: 'absolute',
                        top: default_h
                    },
                }}
                hideBackdrop={true}
                disableScrollLock
                classes={{
                    paper: classes.dialog
                }}

                className="displaypartnersession mysy_MuiPaper-root"
            >

                <DialogTitle>
                    Créer un nouveau modèle de planning
                </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_RECORD_MODEL_PLANNING_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > <b> Code </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_model_code"
                            id="event_dialog_model_code"
                            type="text"


                            fullWidth

                            value={p_model_planning_code}
                            onChange={(e) => {
                                setp_model_planning_code(e.target.value);
                            }
                            }
                        />
                    </div>

                    <div className="session_caract_Dialog"> <b> Nom </b>  <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_model_nom"
                            id="event_dialog_model_nom"
                            type="text"


                            fullWidth

                            value={p_model_planning_nom}
                            onChange={(e) => {
                                setp_model_planning_nom(e.target.value);
                            }
                            }
                        />
                    </div>



                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={(date) => {

                                Record_New_Planning_Model();
                            }}
                                className="bton_enreg_dialog">Créer </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_RECORD_MODEL_PLANNING} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                    </div>

                    <IconButton
                        autoFocus
                        onClick={Close_Dialog_RECORD_MODEL_PLANNING}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>

            </Dialog>
            {/*** Fin ajout Dialog ajout RECORD MODEL PLANNING  */}


            {/*** Dialog ajout MODEL PLANNING  */}

            <Dialog
                open={Dialog_MODEL_PLANNING_open}
                //onClose={Dialog_MODEL_PLANNING_handleClose}

                static
                onClose={() => null}

                PaperProps={{


                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        //position: 'absolute',
                        //top: default_h
                    },
                }}
                hideBackdrop={true}
                disableScrollLock
                classes={{
                    paper: classes.dialog
                }}

                className="displaypartnersession mysy_MuiPaper-root"
            >

                <DialogTitle>
                    Gestion Modèle de planning
                </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_MODEL_PLANNING_message}
                    </DialogContentText>


                    {New_Getall_Partner_Modele_Planning_result_planning_view && New_Getall_Partner_Modele_Planning_result_planning_view.length > 0 && <div className="session_data" style={{ "border": "None" }}>

                        <div style={{ height: 350, width: '100%', paddingRight: '1px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    paddingRight: '1px',

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    }, [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            >
                                <DataGrid
                                    // checkboxSelection

                                    onRowClick={handleRowClick}


                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={New_Getall_Partner_Modele_Planning_result_planning_view.map((item, index) => (
                                        {
                                            id: index,
                                            _id: (item)._id,
                                            session_id: (item).session_id,
                                            code: (item).code,
                                            nom: (item).nom,
                                            is_public: (item).is_public,
                                            comment: (item).comment,

                                        }
                                    ))}

                                    columns={columns_modele_planning}
                                    pageSize={10}
                                    className="datagridclass"


                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    sx={datagridSx}
                                    getRowClassName={(params) => {

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_sequence_new_planificatio_id)) {
                                            return 'line--statut--selected';
                                        }



                                    }}


                                />
                            </Box>
                            <br />

                        </div>


                    </div>}

                    {New_Getall_Partner_Modele_Planning_result_planning_view && New_Getall_Partner_Modele_Planning_result_planning_view.length <= 0 && <div>
                        Aucun modèle de planning enregistré.
                    </div>}



                    <div className="div_row_dialog" style={{ textAlign: 'left' }}>
                        <div className="planification_Dialog_DatePicker">

                            Appliquer à partir du :
                            <DatePicker
                                name="planif_model_date_from"
                                id="planif_model_date_from"
                                selected={planning_model_new_application_date}
                                onChange={(date) => {

                                    setplanning_model_new_application_date(date);
                                }
                                }
                                showTimeSelect={false}
                                filterTime={filterPassedTime_start}
                                dateFormat="dd/MM/yyyy"
                                className="disabled_style "
                                fullWidth
                                locale='fr-FR'

                            />
                        </div>
                    </div>

                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={(date) => {

                                Get_Given_Sequence_Planning_Model_With_Option();
                            }}
                                className="bton_enreg_dialog">Appliquer </Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_MODEL_PLANNING} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                    </div>

                    <IconButton
                        autoFocus
                        onClick={Close_Dialog_MODEL_PLANNING}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>

            </Dialog>
            {/*** Fin ajout Dialog ajout MODEL PLANNING  */}


            {/*** Dialog ajout sequence  */}
            <Dialog
                open={Dialog_seq_1_open}
                //onClose={Dialog_seq_1_handleClose}


                static
                onClose={() => null}

                PaperProps={{


                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        //position: 'absolute',
                        top: default_h
                    },
                }}
                hideBackdrop={true}
                disableScrollLock
                classes={{
                    paper: classes.dialog
                }}

                className="displaypartnersession mysy_MuiPaper-root"
            >

                <DialogTitle>

                    {String(update_sequence) !== "1" && "Ajouter une séquence"}
                    {String(update_sequence) === "1" && "Mettre à jour une séquence"}


                </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_seq_1_message}
                    </DialogContentText>


                    {<div className="session_caract_Dialog" style={{ textAlign: 'right' }} >

                        <Button variant="outlined" onClick={submenu_sequence_detail} className="detail_class_submenu" id='dialog_detail_seq_detail'
                            name='dialog_detail_seq_detail'>Détail</Button>

                        {String(selected_sequence_id).length <= 3 &&
                            <Button variant="outlined" onClick={submenu_sequence_ressource} className="detail_class_submenu" id='dialog_detail_seq_ressource'
                                name='dialog_detail_seq_ressource' style={{ background: "grey", border: "None" }}>
                                <Tooltip className="tooltip_css" id="tooltip_ress01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="tooltip_ress01" data-tooltip-html="Vous devez créer la séquence avant d'ajouter des ressources">

                                    Ressouces
                                </a>
                            </Button>}

                        {String(selected_sequence_id).length > 3 &&
                            <Button variant="outlined" onClick={submenu_sequence_ressource} className="detail_class_submenu" id='dialog_detail_seq_ressource'
                                name='dialog_detail_seq_ressource'>Ressouces</Button>}
                    </div>}



                    {String(submenu_seq_dialog) === "dialog_ressource" && <div>


                        {New_Option_Type_Ressource && New_Option_Type_Ressource.length > 1 && <div className="session_caract_Dialog" > Type de ressource
                            <Autocomplete
                                disablePortal
                                name="event_dialog_type_ressource"
                                id="event_dialog_type_ressource"
                                fullWidth

                                options={New_Option_Type_Ressource}
                                value={New_Option_Type_Ressource.filter((data) => (data).value === String(Dialog_seq_ressource_1_selected_type_ressource))[0].label}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setDialog_seq_ressource_1_selected_type_ressource(value.value);

                                    } else {
                                        setDialog_seq_ressource_1_selected_type_ressource("");
                                        setGet_Ressource_Avabilities_avability_check("");
                                        setDialog_seq_ressource_1_selected_ressource_id("");
                                    }

                                    setGet_Ressource_Avabilities_avability_check("");
                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                }
                            />


                        </div>}

                        {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 1 &&
                            String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_humaine" && <div className="session_caract_Dialog" > Ressource Humaine

                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ressource_id"
                                    id="event_dialog_ressource_id"
                                    fullWidth
                                    options={New_Getall_Training_Employee_No_Filter_result}
                                    value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(Dialog_seq_ressource_1_selected_ressource_id))[0].label}


                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setDialog_seq_ressource_1_selected_ressource_id(value._id);

                                            //console.log(" ### check_ressource_avabilities =  ", check_ressource_avabilities)
                                            if (check_ressource_avabilities === "1") {
                                                // On ne fait le controle de disponibilité que si on une sequence precise. pas quand c'est en masse.
                                                Get_Ressource_Avabilities(value._id);
                                            }
                                        } else {
                                            setGet_Ressource_Avabilities_avability_check("");
                                            setDialog_seq_ressource_1_selected_ressource_id("");
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />
                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                                    <div className="session_caract_Dialog koUpdateData">
                                        <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                            <FcCancel /><FcInfo />  <font size="10px" color="orange"> /!\ Indisponible du {selected_sequence_startDate} au {selected_sequence_endDate} </font>
                                        </a>
                                    </div>
                                }

                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                                    <div className="session_caract_Dialog okUpdateData" >
                                        <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                            <FcApproval />  Disponible
                                        </a>
                                    </div>
                                }


                            </div>
                        }


                        {New_Getall_Training_Materiel_No_Filter_result && New_Getall_Training_Materiel_No_Filter_result.length > 1 &&
                            String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_materielle" && <div className="session_caract_Dialog" > Ressource Materielle

                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ressource_id"
                                    id="event_dialog_ressource_id"
                                    fullWidth
                                    options={New_Getall_Training_Materiel_No_Filter_result}
                                    value={New_Getall_Training_Materiel_No_Filter_result.filter((data) => (data)._id === String(Dialog_seq_ressource_1_selected_ressource_id))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setDialog_seq_ressource_1_selected_ressource_id(value._id);
                                            if (check_ressource_avabilities === "1") {
                                                // On ne fait le controle de disponibilité que si on une sequence precise. pas quand c'est en masse.
                                                Get_Ressource_Avabilities(value._id);
                                            }

                                        } else {
                                            setGet_Ressource_Avabilities_avability_check("");
                                            setDialog_seq_ressource_1_selected_ressource_id("");
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />

                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                                    <div className="session_caract_Dialog koUpdateData">
                                        <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                            <FcCancel /><FcInfo /> <font size="10px" color="orange"> /!\  Indisponible du {selected_sequence_startDate} au {selected_sequence_endDate} </font>
                                        </a>
                                    </div>
                                }

                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                                    <div className="session_caract_Dialog okUpdateData" >
                                        <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                            <FcApproval />  Disponible
                                        </a>
                                    </div>
                                }
                            </div>
                        }


                        <div className="session_caract_Dialog" > Poste
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_title"
                                id="event_dialog_title"

                                fullWidth

                                value={Dialog_seq_ressource_1_selected_ressource_poste}
                                onChange={(e) => {

                                    setDialog_seq_ressource_1_selected_ressource_poste(e.target.value);
                                }
                                }

                            />

                        </div>


                        {Dialog_seq_ressource_1_selected_ressource_id && <div className="div_row">
                            <div className="div_row_gauche">

                                &nbsp;
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'center' }}>


                                <Button onClick={Add_One_Ressource_Fom_Dialog_Sequence} className="bton_enreg_dialog">Ajout Ress. <PlaylistAddOutlinedIcon /> </Button>

                            </div>
                        </div>}




                        {Getall_List_Sequence_Ressource_result && Getall_List_Sequence_Ressource_result.length > 0 && <div className="session_data" style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',


                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_sequence(newSelectionModel);

                                        }}
                                        //selectionModel={selectionModel_sequence}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_List_Sequence_Ressource_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                related_target_collection_id: JSON.parse(item).related_target_collection_id,
                                                related_target_collection: JSON.parse(item).related_target_collection,
                                                type_ressource: JSON.parse(item).related_target_collection_object,
                                                related_target_collection_object: JSON.parse(item).related_target_collection_object,
                                                related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                                poste: JSON.parse(item).poste,
                                            }
                                        ))}

                                        columns={columns_sequence_ressources_affectation}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {


                                        }}



                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        sx={datagridSx}



                                    />
                                </Box>
                                <br />

                            </div>


                        </div>}

                        {Getall_List_Sequence_Ressource_result && Getall_List_Sequence_Ressource_result.length <= 0 && <div>
                            Aucune ressource.
                        </div>}




                    </div>}


                    {String(submenu_seq_dialog) !== "dialog_ressource" && <div>
                        <div className="session_caract_Dialog" >

                            <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Type </nav>


                            <nav style={{ "float": "right", "width": "65%" }}>
                                {New_Option_Type_Event && New_Option_Type_Event.length > 0 && <Autocomplete
                                    disablePortal
                                    name="event_dialog_type"
                                    id="event_dialog_type"
                                    fullWidth
                                    options={New_Option_Type_Event}
                                    value={New_Option_Type_Event.filter((data) => (data).value === String(p_detail_event_type))[0].label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_detail_event_type(value.value);

                                        } else {
                                            setp_detail_event_type("");
                                        }


                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />}


                            </nav>
                        </div>

                        <div className="session_caract_Dialog" > Unité d'enseignement


                            {New_Getall_Class_List_UE_result && New_Getall_Class_List_UE_result.length > 0 && <Autocomplete
                                disablePortal
                                name="event_dialog_ue"
                                id="event_dialog_ue"

                                fullWidth
                                value={New_Getall_Class_List_UE_result.filter((data) => (data)._id === String(p_detail_sequence_ue_id))[0].label}
                                options={New_Getall_Class_List_UE_result}
                                onChange={(event, value) => {
                                    seteu_planif_line_insdispo("");
                                    setp_detail_sequence_ue_planif_line_id("");

                                    if (value && value._id) {
                                        setp_detail_sequence_ue_id(value._id);
                                        Get_List_Given_UE_Planif_lines(value._id);
                                    } else {
                                        setp_detail_sequence_ue_id("");
                                        setNew_Get_List_Given_UE_Planif_lines_result([]);
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                }
                            />}


                        </div>


                        {New_Get_List_Given_UE_Planif_lines_result && New_Get_List_Given_UE_Planif_lines_result.length > 1 &&
                            p_detail_sequence_ue_id && String(p_detail_sequence_ue_id).length > 2 &&
                            <div className="session_caract_Dialog" > Planification
                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ue_id_planig_line_id"
                                    id="event_dialog_ue_id_planig_line_id"

                                    fullWidth
                                    value={New_Get_List_Given_UE_Planif_lines_result.filter((data) => (data)._id === String(p_detail_sequence_ue_planif_line_id))[0].label}
                                    options={New_Get_List_Given_UE_Planif_lines_result}
                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_detail_sequence_ue_planif_line_id(value._id);
                                            if (value.in_use === "1") {
                                                alert(" Cette ligne est deja planifiée");
                                                seteu_planif_line_insdispo("1");
                                            } else {
                                                seteu_planif_line_insdispo("");
                                            }
                                            //Get_List_Given_UE_Planif_lines(value._id);
                                        } else {
                                            setp_detail_sequence_ue_planif_line_id("");
                                            // setNew_Get_List_Given_UE_Planif_lines_result([]);
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />

                                {eu_planif_line_insdispo === "1" &&
                                    <div className="session_caract_Dialog koUpdateData">
                                        <Tooltip className="tooltip_css" id="eu_planif_line_insdispo" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="eu_planif_line_insdispo" data-tooltip-html="Cette ligne de planification est déjà utilisée">
                                            <FcCancel /><FcInfo />  <font size="10px" color="orange"> /!\ Cette ligne de planification est déjà utilisée </font>
                                        </a>
                                    </div>
                                }

                            </div>
                        }



                        <div className="session_caract_Dialog" > Groupe apprenants


                            {New_Getall_Class_List_UE_result && New_Getall_Class_List_UE_result.length > 0 && <Autocomplete
                                disablePortal
                                name="event_dialog_grp_apprenant"
                                id="event_dialog_grp_apprenant"

                                fullWidth
                                value={New_Getall_Partner_Groupe_Inscrit_result.filter((data) => (data)._id === String(p_detail_sequence_groupe_apprenant_id))[0].label}
                                options={New_Getall_Partner_Groupe_Inscrit_result}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_sequence_groupe_apprenant_id(value._id);
                                    } else {
                                        setp_detail_sequence_groupe_apprenant_id("");
                                    }


                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                }
                            />}

                        </div>



                        <div className="session_caract_Dialog" > Titre
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_title"
                                id="event_dialog_title"


                                fullWidth

                                value={p_detail_sequence_title}
                                onChange={(e) => {
                                    setp_detail_sequence_title(e.target.value);
                                }
                                }

                            />

                        </div>

                        <div className="session_caract_Dialog" > Debut
                            <DatePicker
                                name="event_dialog_start"
                                id="event_dialog_start"
                                selected={sequence_startDate}
                                onChange={(date) => {

                                    return setsequence_startDate(date);
                                }
                                }

                                className="disabled_style session_caract_Dialog_DatePicker"
                                style={{ width: "99%" }}
                                locale='fr-FR'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                is24Hour

                            />

                        </div>


                        <div className="session_caract_Dialog" > Fin
                            <DatePicker
                                name="event_dialog_end"
                                id="event_dialog_end"
                                selected={sequence_endDate}

                                onChange={(date) => {
                                    return setsequence_endDate(date);
                                }
                                }


                                className="disabled_style session_caract_Dialog_DatePicker"
                                style={{ width: "99%" }}
                                locale='fr-FR'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                is24Hour

                            />

                        </div>


                        <div className="session_caract_Dialog_comment_2_lines" > Agenda
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_agenda"
                                id="event_dialog_agenda"

                                fullWidth

                                value={p_detail_sequence_agenda}
                                onChange={(e) => {

                                    setp_detail_sequence_agenda(e.target.value);
                                }
                                }
                                multiline
                                minRows={2}
                                maxRows={2}
                                rows={2}

                            />

                        </div>

                        <div className="session_caract_Dialog_comment_2_lines" > Objectif
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_objectif"
                                id="event_dialog_objectif"

                                fullWidth

                                value={p_detail_sequence_objectif}
                                onChange={(e) => {

                                    setp_detail_sequence_objectif(e.target.value);
                                }
                                }
                                multiline
                                minRows={2}
                                maxRows={2}
                                rows={2}

                            />

                        </div>

                        <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_comment"
                                id="event_dialog_comment"

                                fullWidth

                                value={p_detail_sequence_comment}
                                onChange={(e) => {

                                    setp_detail_sequence_comment(e.target.value);
                                }
                                }
                                multiline
                                minRows={2}
                                maxRows={2}
                                rows={2}

                            />

                        </div>

                    </div>}


                </DialogContent>



                {String(submenu_seq_dialog) !== "dialog_ressource" && <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(update_sequence) !== "1" && <Button onClick={Add_One_Sequence_Session} className="bton_enreg_dialog">Ajouter</Button>}
                            {String(update_sequence) === "1" && <Button onClick={Update_One_Sequence_Session} className="bton_enreg_dialog">Mettre à jour</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Close_Dialog_Detail_seq_open} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                    </div>

                    <IconButton
                        autoFocus
                        onClick={Close_Dialog_Detail_seq_open}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>}


                {String(submenu_seq_dialog) === "dialog_ressource" && <DialogActions>

                    <IconButton
                        autoFocus
                        onClick={Close_Dialog_Detail_seq_open}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>}





            </Dialog>
            {/*** Fin ajout sequence */}


            {/*** Dialog Ajout Ressources */}

            <Dialog
                open={Dialog_seq_ressource_1_open}
                // onClose={Dialog_seq_ressource_1_handleClose}

                className="displaypartnersession"
                static
                onClose={() => null}

            >

                <DialogTitle>Ajout ressource  {/*:  <font size="3"> <i> {selected_sequence_startDate} au {selected_sequence_endDate} </i> </font> */} </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_seq_ressource_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Type de ressource
                        <Autocomplete
                            disablePortal
                            name="event_dialog_type_ressource"
                            id="event_dialog_type_ressource"
                            fullWidth

                            options={New_Option_Type_Ressource}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setDialog_seq_ressource_1_selected_type_ressource(value.value);

                                } else {
                                    setDialog_seq_ressource_1_selected_type_ressource("");
                                    setGet_Ressource_Avabilities_avability_check("");
                                    setDialog_seq_ressource_1_selected_ressource_id("");
                                }

                                setGet_Ressource_Avabilities_avability_check("");
                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />


                    </div>



                    {String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_humaine" && <div className="session_caract_Dialog" > Ressource Humaine

                        <Autocomplete
                            disablePortal
                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"
                            fullWidth
                            options={New_Getall_Training_Employee_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setDialog_seq_ressource_1_selected_ressource_id(value._id);

                                    //console.log(" ### check_ressource_avabilities =  ", check_ressource_avabilities)
                                    if (check_ressource_avabilities === "1") {
                                        // On ne fait le controle de disponibilité que si on une sequence precise. pas quand c'est en masse.
                                        Get_Ressource_Avabilities(value._id);
                                    }
                                } else {
                                    setGet_Ressource_Avabilities_avability_check("");
                                    setDialog_seq_ressource_1_selected_ressource_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />
                        {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                            <div className="session_caract_Dialog koUpdateData">
                                <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                    <FcCancel /><FcInfo />  <font size="10px" color="orange"> /!\ Indisponible du {selected_sequence_startDate} au {selected_sequence_endDate} </font>
                                </a>
                            </div>
                        }

                        {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                            <div className="session_caract_Dialog okUpdateData" >
                                <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                    <FcApproval />  Disponible
                                </a>
                            </div>
                        }


                    </div>
                    }


                    {String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_materielle" && <div className="session_caract_Dialog" > Ressource Materielle

                        <Autocomplete
                            disablePortal
                            name="event_dialog_ressource_id"
                            id="event_dialog_ressource_id"
                            fullWidth
                            options={New_Getall_Training_Materiel_No_Filter_result}

                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setDialog_seq_ressource_1_selected_ressource_id(value._id);
                                    if (check_ressource_avabilities === "1") {
                                        // On ne fait le controle de disponibilité que si on une sequence precise. pas quand c'est en masse.
                                        Get_Ressource_Avabilities(value._id);
                                    }

                                } else {
                                    setGet_Ressource_Avabilities_avability_check("");
                                    setDialog_seq_ressource_1_selected_ressource_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />

                        {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                            <div className="session_caract_Dialog koUpdateData">
                                <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                    <FcCancel /><FcInfo /> <font size="10px" color="orange"> /!\  Indisponible du {selected_sequence_startDate} au {selected_sequence_endDate} </font>
                                </a>
                            </div>
                        }

                        {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                            <div className="session_caract_Dialog okUpdateData" >
                                <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                    <FcApproval />  Disponible
                                </a>
                            </div>
                        }
                    </div>
                    }


                    <div className="session_caract_Dialog" > Poste
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={Dialog_seq_ressource_1_selected_ressource_poste}
                            onChange={(e) => {

                                setDialog_seq_ressource_1_selected_ressource_poste(e.target.value);
                            }
                            }

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            {String(Add_One_Sequence_Session_mass) !== "1" && <Button onClick={Add_One_Sequence_Session_Ressource} className="bton_enreg_dialog">Ajouter</Button>}

                            {String(Add_One_Sequence_Session_mass) === "1" && <Button onClick={Add_One_Sequence_Session_Ressource_Mass} className="bton_enreg_dialog">Ajouter en masse</Button>}
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_seq_ressource_1_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            {/*** Fin ajout ressource */}

            {/**** Dialogue pour EVENT SEMAINE TYPE */}

            <Dialog
                open={Dialog_EVENT_SEMAINE_TYPE_open}
                //onClose={Dialog_EVENT_SEMAINE_TYPE_handleClose}

                className="module_agenda"
                static
                onClose={() => null}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_EVENT_SEMAINE_TYPE_message}
                    </DialogContentText>

                    <div className="session_caract_Dialog" >


                        <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Type </nav>


                        <nav style={{ "float": "right", "width": "65%" }}>
                            <Autocomplete
                                disablePortal
                                name="event_dialog_type"
                                id="event_dialog_type"
                                value={p_detail_event_type}
                                fullWidth
                                options={New_Option_Type_Event}
                                onChange={(event, value) => {
                                    if (String(props.write_access) === "1") {

                                        if (value && value.value) {
                                            setp_detail_event_type(value.value);

                                        }
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />


                        </nav>
                    </div>


                    <div className="session_caract_Dialog" > Titre
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_title"
                            id="event_dialog_title"


                            fullWidth

                            value={p_detail_sequence_event_type_semaine_title}
                            onChange={(e) => {

                                setp_detail_sequence_event_type_semaine_title(e.target.value);
                            }
                            }

                        />

                    </div>

                    <div className="session_caract_Dialog" > Debut
                        <DatePicker
                            name="event_dialog_start"
                            id="event_dialog_start"
                            selected={sequence_event_type_semaine_startDate}
                            onChange={(date) => {

                                return setsequence_event_type_semaine_startDate(date);
                            }
                            }

                            className="disabled_style session_caract_Dialog_DatePicker"
                            style={{ width: "99%" }}
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>

                    <div className="session_caract_Dialog" > Fin
                        <DatePicker
                            name="event_dialog_end"
                            id="event_dialog_end"
                            selected={sequence_event_type_semaine_endDate}

                            onChange={(date) => {

                                return setsequence_event_type_semaine_endDate(date);
                            }
                            }


                            className="disabled_style session_caract_Dialog_DatePicker"
                            style={{ width: "99%" }}
                            locale='fr-FR'
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy HH:mm"
                            is24Hour

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Agenda
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_EVENT_SEMAINE_TYPE"
                            id="event_EVENT_SEMAINE_TYPE"

                            fullWidth

                            value={p_detail_sequence_event_type_semaine_agenda}
                            onChange={(e) => {

                                setp_detail_sequence_event_type_semaine_agenda(e.target.value);
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Objectif
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_objectif"
                            id="event_dialog_objectif"

                            fullWidth

                            value={p_detail_sequence_event_type_semaine_objectif}
                            onChange={(e) => {

                                setp_detail_sequence_event_type_semaine_objectif(e.target.value);
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>

                    <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="event_dialog_comment"
                            id="event_dialog_comment"

                            fullWidth

                            value={p_detail_sequence_event_type_semaine_comment}
                            onChange={(e) => {

                                setp_detail_sequence_event_type_semaine_comment(e.target.value);
                            }
                            }
                            multiline
                            minRows={2}
                            maxRows={2}
                            rows={2}

                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">


                        {String(p_detail_sequence_session_id) === "" && <div className="div_row_gauche">
                            {p_detail_sequence_event_type_semaine_id && String(p_detail_sequence_event_type_semaine_id) !== "" && <Button onClick={Update_event_type_semaine} className="bton_enreg_dialog">Mettre à jour</Button>}

                            {(!p_detail_sequence_event_type_semaine_id || String(p_detail_sequence_event_type_semaine_id) === "") && <Button onClick={Add_event_type_semaine} className="bton_enreg_dialog">Ajouter</Button>}



                        </div>}
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_EVENT_SEMAINE_TYPE_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>




                {p_detail_sequence_event_type_semaine_id && String(p_detail_sequence_event_type_semaine_id) !== "" &&
                    <DialogActions>
                        <div className="div_row" style={{ "textAlign": "center" }}>

                            <Popup
                                trigger={

                                    <Button className="bton_supprime_dialog"

                                        onClick={(event) => {

                                        }}
                                    >
                                        <CiTrash /> Supprimer

                                    </Button>

                                }
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, la séquence sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    Remove_event_type_semaine();
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>


                    </DialogActions>}

            </Dialog>

            {/**** fin pour Dialogue pour EVENT SEMAINE TYPE */}

            {/*  Dialogue CREATION SEMAINE TYPE  */}

            <Dialog
                open={Dialog_seq_CREATION_SEMAINE_TYPE_open}
                // onClose={Dialog_seq_CREATION_SEMAINE_TYPE_handleClose}
                className="displaypartnersession displaypopop_for_sequence_model_week"
                static
                onClose={() => null}
            >

                <DialogTitle>  {Dialog_seq_CREATION_SEMAINE_TYPE_message} </DialogTitle>
                <DialogContent className="DialogContent_width_for_week_model">
                    <DialogContentText>
                        {Dialog_seq_CREATION_SEMAINE_TYPE_message}
                    </DialogContentText>


                    {SessionstartDate && SessionendDate && <div className="div_row_dialog" style={{ textAlign: 'left' }}>
                        <div className="planification_Dialog_DatePicker">
                            Debut Formation = {new Date(moment(SessionstartDate, "DD/MM/YYYY")).toISOString().split('T')[0]} <br />
                        </div>

                        <div className="planification_Dialog_DatePicker">

                            Fin Formation = {new Date(moment(SessionendDate, "DD/MM/YYYY")).toISOString().split('T')[0]}
                        </div>


                    </div>}


                    <div className="div_row_dialog">

                        <div style={{ "width": "98%", "marginLeft": "5px", }}>
                            {Dialog_seq_planif_periode_type && String(Dialog_seq_planif_periode_type) === "mois" &&
                                <FullCalendar
                                    height={550}
                                    locales={allLocales}
                                    locale={'fr'}
                                    editable
                                    selectable
                                    //events={events}
                                    events={semaine_type_event}
                                    initialDate={SessionstartDate}

                                    select={handleSelect_event_type_semaine}
                                    headerToolbar={{
                                        start: "today prev next",
                                        center: "title",
                                        end: "dayGridMonth,timeGridWeek,timeGridDay",
                                    }}
                                    //eventContent={(info) => <EventItem info={info} />}
                                    //plugins={[timeGridPlugin]}




                                    plugins={[daygridPlugin, timeGridPlugin, interactionPlugin]}
                                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                                    slotMinTime={Partner_Debut_Journee}
                                    slotMaxTime={Partner_Fin_Journee}
                                    scrollTime='08:00'
                                    displayEventEnd={true}
                                    eventContent={renderEventContent_event_type_semaine}
                                    eventClick={handleEventClick_event_type_semaine}
                                />}


                            {Dialog_seq_planif_periode_type && String(Dialog_seq_planif_periode_type) === "semaine" &&

                                <FullCalendar
                                    height={550}
                                    locales={allLocales}
                                    locale={'fr'}
                                    editable
                                    selectable
                                    //events={events}
                                    events={semaine_type_event}
                                    initialDate={SessionstartDate}


                                    select={handleSelect_event_type_semaine}
                                    headerToolbar={{
                                        start: "today prev next",
                                        center: "title",
                                        end: "dayGridMonth,timeGridWeek,timeGridDay",
                                    }}
                                    //eventContent={(info) => <EventItem info={info} />}
                                    //plugins={[timeGridPlugin]}




                                    plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                                    views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                                    slotMinTime={Partner_Debut_Journee}
                                    slotMaxTime={Partner_Fin_Journee}
                                    scrollTime='08:00'
                                    displayEventEnd={true}
                                    eventContent={renderEventContent_event_type_semaine}
                                    eventClick={handleEventClick_event_type_semaine}
                                />}

                        </div>

                    </div>
                    <br />

                    <div className="div_row_dialog" style={{ textAlign: 'left' }}>
                        {SessionstartDate && SessionendDate &&

                            <div>
                                <div className="planification_Dialog_DatePicker">
                                    Dupliquer Du :
                                    <DatePicker
                                        name="planif_semaine_model_date_from"
                                        id="planif_semaine_model_date_from"
                                        selected={planification_model_date_from}
                                        onChange={(date) => {

                                            let local_date = new Date(date).getTime();
                                            let date_end_session = new Date(SessionendDate).getTime();
                                            let date_start_session = new Date(SessionstartDate).getTime();

                                            /* console.log(" local_date = ", local_date);
                                            console.log(" date_end_session  = ", date_end_session);
                                            console.log(" date_start_session  = ", date_start_session);*/

                                            if (local_date > date_end_session) {
                                                alert(" La date est après la fin de la session");
                                                return;
                                            }

                                            if (local_date < date_start_session) {
                                                alert(" La date est avant le début de la session");
                                                return;
                                            }

                                            setplanification_model_date_from(date);
                                        }
                                        }
                                        showTimeSelect={false}
                                        filterTime={filterPassedTime_start}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style "
                                        locale='fr-FR'

                                    />
                                </div>
                                <div className="planification_Dialog_DatePicker">

                                    Dupliquer Au :
                                    <DatePicker
                                        name="planif_semaine_model_date_to"
                                        id="planif_semaine_model_date_to"
                                        selected={planification_model_date_to}
                                        onChange={(date) => {

                                            let local_date = new Date(date).getTime();
                                            let date_end_session = new Date(SessionendDate).getTime();
                                            let date_start_session = new Date(SessionstartDate).getTime();

                                            /* console.log(" local_date = ", local_date);
                                             console.log(" date_end_session  = ", date_end_session);
                                             console.log(" date_start_session  = ", date_start_session);*/


                                            if (local_date > date_end_session) {
                                                alert(" La date est après la fin de la session");
                                                return;
                                            }

                                            if (local_date < date_start_session) {
                                                alert(" La date est avant le début de la session");
                                                return;
                                            }

                                            setplanification_model_date_to(date);

                                        }
                                        }
                                        showTimeSelect={false}
                                        filterTime={filterPassedTime_start}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style "
                                        locale='fr-FR'

                                    />
                                </div>

                            </div>}

                    </div>

                    <div className="div_row_dialog" style={{ textAlign: 'left' }}>

                        <FormControl>
                            <Tooltip className="tooltip_css" id="ecrase_01" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="ecrase_01" data-tooltip-html="Vous allez definitivement supprimer toutes planifications associées à cette session de formation">

                                <FormLabel id="demo-row-radio-buttons-group-label">Ecraser la planification existant  <FcInfo /></FormLabel>
                            </a>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="0"
                                onChange={(e) => {
                                    setplanification_ecrase_existing_seq(e.target.value);


                                    if (String(e.target.value) === "1") {
                                        alert(" Vous allez definitivement supprimer toutes planifications associées à cette session de formation ")
                                    }
                                }}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                <FormControlLabel value="0" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>

                    </div>
                </DialogContent>



                <DialogActions>
                    <div className="div_row">


                        <div className="div_row_gauche">
                            <Button onClick={(date) => {

                                let date_end_planif = new Date(planification_model_date_to).getTime();
                                let date_start_planif = new Date(planification_model_date_from).getTime();

                                if (date_end_planif < date_start_planif) {
                                    alert(" La date de début est postérieure à la date de fin ");
                                    return;
                                }


                                if (String(Dialog_seq_planif_periode_type) === "semaine")
                                    Add_Automatic_Sequence_Session_From_Week_Model();

                                else if (String(Dialog_seq_planif_periode_type) === "mois")
                                    Add_Automatic_Sequence_Session_From_Month_Model();
                            }}
                                className="bton_enreg_dialog">Crée en masse</Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_seq_CREATION_SEMAINE_TYPE_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            {/*** fin  CREATION SEMAINE TYPE */}


            {/*  Dialgue pour le choix du modele de sequene (la journée, 1/2 journée, etc*/}

            <Dialog
                open={Dialog_seq_choix_model_emargement_open}
                onClose={Dialog_seq_choix_model_emargement_handleClose}

                className="displaypartnersession"

            >

                <DialogTitle> Choisir un modèle de planification </DialogTitle>
                <DialogContent className="DialogContent_width" style={{ height: "15rem" }}>
                    <DialogContentText>
                        {Dialog_seq_choix_model_emargement_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" >
                        <Autocomplete
                            disablePortal
                            name="event_dialog_modele_seq"
                            id="event_dialog_modele_seq"
                            fullWidth

                            options={New_Option_Model_Sequence}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setDialog_seq_choix_model_emargement(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir un modèle " />
                            }
                        />


                    </div>


                    <div className="div_row_dialog" style={{ textAlign: 'left' }}>

                        <FormControl>

                            <Tooltip className="tooltip_css" id="ecrase_01" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="ecrase_01" data-tooltip-html="Vous allez definitivement supprimer toutes planifications associées à cette session de formation">

                                <FormLabel id="demo-row-radio-buttons-group-label">Ecraser la planification existant <FcInfo /> </FormLabel>
                            </a>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="0"
                                onChange={(e) => {
                                    setplanification_ecrase_existing_seq(e.target.value);


                                    if (String(e.target.value) === "1") {
                                        alert(" Vous allez definitivement supprimer toutes planifications associées à cette session de formation ")
                                    }
                                }}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Oui" />
                                <FormControlLabel value="0" control={<Radio />} label="Non" />
                            </RadioGroup>
                        </FormControl>

                    </div>

                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">


                            {Dialog_seq_choix_model_emargement && String(Dialog_seq_choix_model_emargement) !== "3" &&
                                String(Dialog_seq_choix_model_emargement) !== "4" && <Button onClick={Add_Automatic_Sequence_Session} className="bton_enreg_dialog">Crée en masse</Button>}


                            {Dialog_seq_choix_model_emargement && String(Dialog_seq_choix_model_emargement) === "3" &&
                                <Button onClick={(e) => {
                                    setDialog_seq_planif_periode_type("semaine");
                                    setp_detail_sequence_event_type_semaine_id("");
                                    setDialog_seq_choix_model_emargement_open(false);
                                    setDialog_seq_CREATION_SEMAINE_TYPE_open(true);
                                    setDialog_seq_CREATION_SEMAINE_TYPE_message(" Planification à la SEMAINE ")
                                    setplanification_model_date_from(SessionstartDate);

                                    setplanification_model_date_to(SessionendDate);


                                }} className="bton_enreg_dialog">Configurer la semaine</Button>}


                            {Dialog_seq_choix_model_emargement && String(Dialog_seq_choix_model_emargement) === "4" &&
                                <Button onClick={(e) => {
                                    setDialog_seq_planif_periode_type("mois");
                                    setp_detail_sequence_event_type_semaine_id("");
                                    setDialog_seq_choix_model_emargement_open(false);
                                    setDialog_seq_CREATION_SEMAINE_TYPE_open(true);
                                    setDialog_seq_CREATION_SEMAINE_TYPE_message(" Planification au MOIS ")
                                    setplanification_model_date_from(SessionstartDate);

                                    setplanification_model_date_to(SessionendDate);

                                }} className="bton_enreg_dialog">Configurer le mois</Button>}

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_seq_choix_model_emargement_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>


            </Dialog>

            {/*** fin dialog choix modele sequence */}

            {/**** Dialogue pour AGENDA */}

            <Dialog
                open={Dialog_DIALOG_AGENDA_open}
                //  onClose={Dialog_DIALOG_AGENDA_handleClose}


                static
                onClose={() => null}
                PaperProps={{


                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        //position: 'absolute',
                        top: default_h
                    },
                }}
                hideBackdrop={true}
                disableScrollLock
                classes={{
                    paper: classes.dialog
                }}

                className="module_agenda mysy_MuiPaper-root"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        {Dialog_DIALOG_AGENDA_message}
                    </DialogContentText>



                    {<div className="session_caract_Dialog" style={{ textAlign: 'right' }} >

                        <Button variant="outlined" onClick={submenu_sequence_detail} className="detail_class_submenu" id='dialog_detail_seq_detail'
                            name='dialog_detail_seq_detail'>Détail</Button>

                        {String(selected_sequence_id).length <= 3 &&
                            <Button variant="outlined" onClick={submenu_sequence_ressource} className="detail_class_submenu" id='dialog_detail_seq_ressource'
                                name='dialog_detail_seq_ressource' style={{ background: "grey", border: "None" }}>
                                <Tooltip className="tooltip_css" id="tooltip_ress01" style={{ "fontSize": "12px" }} />
                                <a data-tooltip-id="tooltip_ress01" data-tooltip-html="Vous devez créer la séquence avant d'ajouter des ressources">

                                    Ressouces
                                </a>
                            </Button>}

                        {String(selected_sequence_id).length > 3 &&
                            <Button variant="outlined" onClick={submenu_sequence_ressource} className="detail_class_submenu" id='dialog_detail_seq_ressource'
                                name='dialog_detail_seq_ressource'>Ressouces</Button>}
                    </div>}


                    {String(submenu_seq_dialog) === "dialog_ressource" && <div>


                        {New_Option_Type_Ressource && New_Option_Type_Ressource.length > 1 && <div className="session_caract_Dialog" > Type de ressource
                            <Autocomplete
                                disablePortal
                                name="event_dialog_type_ressource"
                                id="event_dialog_type_ressource"
                                fullWidth

                                options={New_Option_Type_Ressource}
                                value={New_Option_Type_Ressource.filter((data) => (data).value === String(Dialog_seq_ressource_1_selected_type_ressource))[0].label}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setDialog_seq_ressource_1_selected_type_ressource(value.value);

                                    } else {
                                        setDialog_seq_ressource_1_selected_type_ressource("");
                                        setGet_Ressource_Avabilities_avability_check("");
                                        setDialog_seq_ressource_1_selected_ressource_id("");
                                    }

                                    setGet_Ressource_Avabilities_avability_check("");
                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                }
                            />


                        </div>}

                        {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 1 &&
                            String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_humaine" && <div className="session_caract_Dialog" > Ressource Humaine

                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ressource_id"
                                    id="event_dialog_ressource_id"
                                    fullWidth
                                    options={New_Getall_Training_Employee_No_Filter_result}
                                    value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(Dialog_seq_ressource_1_selected_ressource_id))[0].label}


                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setDialog_seq_ressource_1_selected_ressource_id(value._id);

                                            //console.log(" ### check_ressource_avabilities =  ", check_ressource_avabilities)
                                            if (check_ressource_avabilities === "1") {
                                                // On ne fait le controle de disponibilité que si on une sequence precise. pas quand c'est en masse.
                                                Get_Ressource_Avabilities(value._id);
                                            }
                                        } else {
                                            setGet_Ressource_Avabilities_avability_check("");
                                            setDialog_seq_ressource_1_selected_ressource_id("");
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />
                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                                    <div className="session_caract_Dialog koUpdateData">
                                        <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                            <FcCancel /><FcInfo />  <font size="10px" color="orange"> /!\ Indisponible du {selected_sequence_startDate} au {selected_sequence_endDate} </font>
                                        </a>
                                    </div>
                                }

                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                                    <div className="session_caract_Dialog okUpdateData" >
                                        <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                            <FcApproval />  Disponible
                                        </a>
                                    </div>
                                }


                            </div>
                        }


                        {New_Getall_Training_Materiel_No_Filter_result && New_Getall_Training_Materiel_No_Filter_result.length > 1 &&
                            String(Dialog_seq_ressource_1_selected_type_ressource) === "ressource_materielle" && <div className="session_caract_Dialog" > Ressource Materielle

                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ressource_id"
                                    id="event_dialog_ressource_id"
                                    fullWidth
                                    options={New_Getall_Training_Materiel_No_Filter_result}
                                    value={New_Getall_Training_Materiel_No_Filter_result.filter((data) => (data)._id === String(Dialog_seq_ressource_1_selected_ressource_id))[0].label}

                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setDialog_seq_ressource_1_selected_ressource_id(value._id);
                                            if (check_ressource_avabilities === "1") {
                                                // On ne fait le controle de disponibilité que si on une sequence precise. pas quand c'est en masse.
                                                Get_Ressource_Avabilities(value._id);
                                            }

                                        } else {
                                            setGet_Ressource_Avabilities_avability_check("");
                                            setDialog_seq_ressource_1_selected_ressource_id("");
                                        }
                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />

                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "0" &&
                                    <div className="session_caract_Dialog koUpdateData">
                                        <Tooltip className="tooltip_css" id="ressource_indispo_01" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_01" data-tooltip-html="Cette ressource n'est pas disponible sur ce créneau. Verifiez son agenda">
                                            <FcCancel /><FcInfo /> <font size="10px" color="orange"> /!\  Indisponible du {selected_sequence_startDate} au {selected_sequence_endDate} </font>
                                        </a>
                                    </div>
                                }

                                {check_ressource_avabilities === "1" && Get_Ressource_Avabilities_avability_check && String(Get_Ressource_Avabilities_avability_check) === "1" &&
                                    <div className="session_caract_Dialog okUpdateData" >
                                        <Tooltip className="tooltip_css" id="ressource_indispo_02" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="ressource_indispo_02" data-tooltip-html="Disponible de la ressource est ok">

                                            <FcApproval />  Disponible
                                        </a>
                                    </div>
                                }
                            </div>
                        }


                        <div className="session_caract_Dialog" > Poste
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_title"
                                id="event_dialog_title"

                                fullWidth

                                value={Dialog_seq_ressource_1_selected_ressource_poste}
                                onChange={(e) => {

                                    setDialog_seq_ressource_1_selected_ressource_poste(e.target.value);
                                }
                                }

                            />

                        </div>


                        {Dialog_seq_ressource_1_selected_ressource_id && <div className="div_row">
                            <div className="div_row_gauche">

                                &nbsp;
                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'center' }}>


                                <Button onClick={Add_One_Ressource_Fom_Dialog_Sequence} className="bton_enreg_dialog">Ajout Ress. <PlaylistAddOutlinedIcon /> </Button>

                            </div>
                        </div>}




                        {Getall_List_Sequence_Ressource_result && Getall_List_Sequence_Ressource_result.length > 0 && <div className="session_data" style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',


                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_sequence(newSelectionModel);

                                        }}
                                        //selectionModel={selectionModel_sequence}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_List_Sequence_Ressource_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                related_target_collection_id: JSON.parse(item).related_target_collection_id,
                                                related_target_collection: JSON.parse(item).related_target_collection,
                                                type_ressource: JSON.parse(item).related_target_collection_object,
                                                related_target_collection_object: JSON.parse(item).related_target_collection_object,
                                                related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                                poste: JSON.parse(item).poste,
                                            }
                                        ))}

                                        columns={columns_sequence_ressources_affectation}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {


                                        }}



                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        sx={datagridSx}



                                    />
                                </Box>
                                <br />

                            </div>


                        </div>}

                        {Getall_List_Sequence_Ressource_result && Getall_List_Sequence_Ressource_result.length <= 0 && <div>
                            Aucune ressource.
                        </div>}




                    </div>}



                    {String(submenu_seq_dialog) !== "dialog_ressource" && <div>


                        <div className="session_caract_Dialog" >

                            <nav style={{ "float": "left", "width": "30%", "paddingTop": "15px" }}> Type </nav>


                            <nav style={{ "float": "right", "width": "65%" }}>
                                {New_Option_Type_Event && New_Option_Type_Event.length > 0 && <Autocomplete
                                    disablePortal
                                    name="event_dialog_type"
                                    id="event_dialog_type"
                                    fullWidth
                                    options={New_Option_Type_Event}
                                    value={New_Option_Type_Event.filter((data) => (data).value === String(p_detail_event_type))[0].label}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_detail_event_type(value.value);

                                        } else {
                                            setp_detail_event_type("");
                                        }


                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />}


                            </nav>
                        </div>


                        <div className="session_caract_Dialog" > Unité d'enseignement


                            {New_Getall_Class_List_UE_result && New_Getall_Class_List_UE_result.length > 0 &&
                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ue"
                                    id="event_dialog_ue"

                                    fullWidth
                                    value={New_Getall_Class_List_UE_result.filter((data) => (data)._id === String(p_detail_sequence_ue_id))[0].label}
                                    options={New_Getall_Class_List_UE_result}
                                    onChange={(event, value) => {
                                        seteu_planif_line_insdispo("");
                                        setp_detail_sequence_ue_planif_line_id("");
                                        if (value && value._id) {
                                            setp_detail_sequence_ue_id(value._id);
                                            Get_List_Given_UE_Planif_lines(value._id);
                                        } else {
                                            setp_detail_sequence_ue_id("");
                                            setNew_Get_List_Given_UE_Planif_lines_result([]);
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />}
                        </div>




                        {New_Get_List_Given_UE_Planif_lines_result && New_Get_List_Given_UE_Planif_lines_result.length > 1 &&
                            p_detail_sequence_ue_id && String(p_detail_sequence_ue_id).length > 2 &&
                            <div className="session_caract_Dialog" > Planification
                                <Autocomplete
                                    disablePortal
                                    name="event_dialog_ue_id_planig_line_id"
                                    id="event_dialog_ue_id_planig_line_id"

                                    fullWidth
                                    value={New_Get_List_Given_UE_Planif_lines_result.filter((data) => (data)._id === String(p_detail_sequence_ue_planif_line_id))[0].label}
                                    options={New_Get_List_Given_UE_Planif_lines_result}
                                    onChange={(event, value) => {
                                        if (value && value._id) {
                                            setp_detail_sequence_ue_planif_line_id(value._id);
                                            if (value.in_use === "1") {
                                                alert(" Cette ligne est deja planifiée");
                                                seteu_planif_line_insdispo("1");
                                            } else {
                                                seteu_planif_line_insdispo("");
                                            }
                                            //Get_List_Given_UE_Planif_lines(value._id);
                                        } else {
                                            setp_detail_sequence_ue_planif_line_id("");
                                            // setNew_Get_List_Given_UE_Planif_lines_result([]);
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                    }
                                />

                                {eu_planif_line_insdispo === "1" &&
                                    <div className="session_caract_Dialog koUpdateData">
                                        <Tooltip className="tooltip_css" id="eu_planif_line_insdispo" style={{ "fontSize": "12px" }} />
                                        <a data-tooltip-id="eu_planif_line_insdispo" data-tooltip-html="Cette ligne de planification est déjà utilisée">
                                            <FcCancel /><FcInfo />  <font size="10px" color="orange"> /!\ Cette ligne de planification est déjà utilisée </font>
                                        </a>
                                    </div>
                                }

                            </div>
                        }





                        <div className="session_caract_Dialog" > Groupe apprenants

                            {New_Getall_Class_List_UE_result && New_Getall_Class_List_UE_result.length > 0 && <Autocomplete
                                disablePortal
                                name="event_dialog_grp_apprenant"
                                id="event_dialog_grp_apprenant"

                                fullWidth
                                value={New_Getall_Partner_Groupe_Inscrit_result.filter((data) => (data)._id === String(p_detail_sequence_groupe_apprenant_id))[0].label}
                                options={New_Getall_Partner_Groupe_Inscrit_result}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_sequence_groupe_apprenant_id(value._id);
                                    } else {
                                        setp_detail_sequence_groupe_apprenant_id("");
                                    }


                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                                }
                            />}

                        </div>


                        <div className="session_caract_Dialog" > Titre
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_title"
                                id="event_dialog_title"


                                fullWidth

                                value={p_detail_sequence_title}
                                onChange={(e) => {
                                    if (String(p_detail_sequence_session_id) === "")
                                        setp_detail_sequence_title(e.target.value);
                                }
                                }

                            />

                        </div>

                        <div className="session_caract_Dialog" > Debut
                            <DatePicker
                                name="event_dialog_start"
                                id="event_dialog_start"
                                selected={sequence_startDate}
                                onChange={(date) => {

                                    return setsequence_startDate(date);
                                }
                                }

                                className="disabled_style session_caract_Dialog_DatePicker"
                                style={{ width: "99%" }}
                                locale='fr-FR'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                is24Hour

                            />

                        </div>

                        <div className="session_caract_Dialog" > Fin
                            <DatePicker
                                name="event_dialog_end"
                                id="event_dialog_end"
                                selected={sequence_endDate}

                                onChange={(date) => {

                                    return setsequence_endDate(date);
                                }
                                }


                                className="disabled_style session_caract_Dialog_DatePicker"
                                style={{ width: "99%" }}
                                locale='fr-FR'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="dd/MM/yyyy HH:mm"
                                is24Hour

                            />

                        </div>

                        <div className="session_caract_Dialog_comment_2_lines" > Agenda
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_agenda"
                                id="event_dialog_agenda"

                                fullWidth

                                value={p_detail_sequence_agenda}
                                onChange={(e) => {

                                    setp_detail_sequence_agenda(e.target.value);
                                }
                                }
                                multiline
                                minRows={2}
                                maxRows={2}
                                rows={2}

                            />

                        </div>

                        <div className="session_caract_Dialog_comment_2_lines" > Objectif
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_objectif"
                                id="event_dialog_objectif"

                                fullWidth

                                value={p_detail_sequence_objectif}
                                onChange={(e) => {

                                    setp_detail_sequence_objectif(e.target.value);
                                }
                                }
                                multiline
                                minRows={2}
                                maxRows={2}
                                rows={2}

                            />

                        </div>

                        <div className="session_caract_Dialog_comment_2_lines" > Commentaire
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_comment"
                                id="event_dialog_comment"

                                fullWidth

                                value={p_detail_sequence_comment}
                                onChange={(e) => {

                                    setp_detail_sequence_comment(e.target.value);
                                }
                                }
                                multiline
                                minRows={2}
                                maxRows={2}
                                rows={2}

                            />

                        </div>


                    </div>}

                </DialogContent>



                {String(submenu_seq_dialog) !== "dialog_ressource" &&

                    <DialogActions>
                        <div className="div_row">
                            {String(p_detail_sequence_session_id) === "" && <div className="div_row_gauche">
                                {p_detail_event_id && String(p_detail_event_id) !== "" && <Button onClick={Update_One_Sequence_Session} className="bton_enreg_dialog">Mettre à jour</Button>}
                                {(!p_detail_event_id || String(p_detail_event_id) === "") && <Button onClick={Add_One_Sequence_Session} className="bton_enreg_dialog">Ajouter</Button>}



                            </div>}
                            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                                <Button onClick={Dialog_DIALOG_AGENDA_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                            </div>
                        </div>

                        <IconButton
                            autoFocus
                            onClick={Dialog_DIALOG_AGENDA_handleClose_buton}
                            color="primary"
                            className={classes.customizedButton}
                        >
                            <CancelPresentationIcon />
                        </IconButton>
                    </DialogActions>
                }

                {String(submenu_seq_dialog) !== "dialog_ressource" && String(p_detail_sequence_session_id) === "" && p_detail_event_id && String(p_detail_event_id) !== "" &&
                    <DialogActions>
                        <div className="div_row" style={{ "textAlign": "center" }}>

                            <Popup
                                trigger={

                                    <Button className="bton_supprime_dialog"

                                        onClick={(event) => {

                                        }}
                                    >
                                        <CiTrash /> Supprimer

                                    </Button>

                                }
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, la séquence sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    Delete_Sequence_Session_Data(p_detail_event_id);
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>


                    </DialogActions>}

                {String(submenu_seq_dialog) === "dialog_ressource" && <DialogActions>

                    <IconButton
                        autoFocus
                        onClick={Dialog_DIALOG_AGENDA_handleClose_buton}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>}

            </Dialog>


            {/*** fin dialogue pr agenda */}


            <div style={{ "border": "None" }}>

                <div className="div_row" style={{ "border": "None", paddingRight: '10px' }}>


                    <div className="session_data" id='mysy_calendar' name="mysy_calendar">



                        <div style={{ "textAlign": "right", "float": "right", "width": "100%", "marginRight": '20px' }}>
                            Affichage :
                            <nav className="mode_affichage" onClick={func_display_planning_view}> <FcPlanner /> planning </nav>
                            <nav className="mode_affichage" onClick={func_display_list_view}> <CiBoxList /> Liste </nav>
                        </div>


                        {display_view && String(display_view) === "list" && <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box

                                    sx={{
                                        height: "500px",
                                        width: '100%',
                                        paddingRight: '2px',

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        }, [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },

                                    }}

                                >
                                    <DataGrid
                                        checkboxSelection={true}
                                        disableRowSelectionOnClick
                                        disableSelectionOnClick
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_sequence(newSelectionModel);

                                        }}
                                        selectionModel={selectionModel_sequence}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_Sequence_Of_Session_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                sequence_start: JSON.parse(item).sequence_start,
                                                sequence_end: JSON.parse(item).sequence_end,
                                                session_id: JSON.parse(item).session_id,
                                                sequence_title: JSON.parse(item).sequence_title,
                                                agenda: JSON.parse(item).agenda,
                                                objectif: JSON.parse(item).objectif,
                                                commentaire: JSON.parse(item).commentaire,

                                                grp_apprenant_id: JSON.parse(item).grp_apprenant_id,
                                                ue_id: JSON.parse(item).ue_id,
                                                unite_enseignement_planif_id: JSON.parse(item).unite_enseignement_planif_id,
                                                type: JSON.parse(item).type,

                                                grp_apprenant_code: JSON.parse(item).grp_apprenant_code,


                                            }
                                        ))}

                                        columns={columns_sequence}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            setsequence_gridline_id(newSelectionModel.row.id);
                                            setselected_detail_sequence_title(newSelectionModel.row.sequence_title);
                                            setselected_sequence_startDate(newSelectionModel.row.sequence_start);
                                            setselected_sequence_endDate(newSelectionModel.row.sequence_end);

                                            setp_detail_sequence_agenda(newSelectionModel.row.agenda);
                                            setp_detail_sequence_objectif(newSelectionModel.row.objectif);
                                            setp_detail_sequence_comment(newSelectionModel.row.commentaire);


                                            Getall_List_Sequence_Ressource(newSelectionModel.row._id);

                                            setselected_sequence_id(newSelectionModel.row._id);
                                            setdisplay_detail_sequence("1");

                                            if (document.getElementById('myRef_seq_ressource')) {
                                                var divh = document.getElementById('myRef_seq_ressource').offsetTop;
                                                window.scrollTo({
                                                    top: divh,
                                                    behavior: "smooth",
                                                });
                                            }

                                        }}



                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {
                                            //field === 'distantiel'
                                            if (params.field === 'distantiel' && String(params.value) === "1") {
                                                return 'cell--distantiel';
                                            }
                                            if (params.field === "presentiel" && String(params.value) == "1") {
                                                return 'cell--presentiel';
                                            }


                                            //field === "etape"
                                            if (params.field === "etape" && String(params.value) == "0") {
                                                return 'cell--etape--projet';
                                            }

                                            if (params.field === "etape" && String(params.value) == "1") {
                                                return 'cell--etape--planification';
                                            }

                                            if (params.field === "etape" && String(params.value) == "2") {
                                                return 'cell--etape--planifie';
                                            }

                                            if (params.field === "etape" && String(params.value) == "3") {
                                                return 'cell--etape--termine';
                                            }

                                            if (params.field === "etape" && String(params.value) == "4") {
                                                return 'cell--etape--facture';
                                            }

                                            if (params.field === "etape" && String(params.value) == "-1") {
                                                return 'cell--etape--annule';
                                            }

                                        }}
                                        getRowClassName={(params) => {

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(sequence_gridline_id)) {
                                                return 'line--statut--selected';
                                            }



                                        }}

                                    />
                                </Box>


                                {selectionModel_sequence && selectionModel_sequence.length > 1 &&
                                    <div className="block_en_mass">

                                        <nav>Traitement en masse / Séquence</nav>
                                        &nbsp;<select
                                            id={"actionmassinscr"} name={"actionmassinscr"}
                                            onChange={actionmass_sequence}
                                            className="action_mass">
                                            <option selected value="n/a">Choisir une action</option>
                                            <option value="ajout_ressource">Ajouter Ressource</option>
                                            <option value="supprimer">Supprimer</option>


                                        </select> &nbsp;

                                        {actionmass_sequence_val && String(actionmass_sequence_val).length > 1 &&
                                            <Popup
                                                trigger={<Button className="bton_traiter_en_mass" >
                                                    <FcAcceptDatabase /> Traiter

                                                </Button>}
                                                modal
                                                nested
                                                position="center center"
                                            >

                                                {close => (
                                                    <div>
                                                        <button className="gest_close" onClick={close}>
                                                            &times;
                                                        </button>
                                                        <div className="gest_header"> MySy Information </div>
                                                        {String(actionmass_sequence_val) === "ajout_ressource" && <div className="gest_content">
                                                            {' '}

                                                            Confirmer l'action <b> d'ajout de ressource </b> en masse.
                                                        </div>}

                                                        {String(actionmass_sequence_val) === "supprimer" && <div className="gest_content">
                                                            {' '}

                                                            Confirmer l'action <b style={{ "color": "red" }}> de suppression </b> en masse.
                                                        </div>}


                                                        <div className="gest_actions">
                                                            <div style={{ "width": "45%", "float": "left" }}>
                                                                <button className="gest_bton_popup" onClick={(event) => {
                                                                    actionmass_sequence_Traitement();
                                                                    close();
                                                                }}> Valider </button>

                                                            </div>
                                                            <div style={{ "width": "45%", "float": "right" }}>
                                                                <button
                                                                    className="gest_bton_popup"
                                                                    onClick={() => {
                                                                        //console.log('modal closed ');
                                                                        close();
                                                                    }}
                                                                >
                                                                    Annuler
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                        }
                                        <br />


                                    </div>

                                }
                                <br />

                            </div>

                        </div>}

                        {display_view && String(display_view) === "planning" && Getall_Sequence_Of_Session_result_planning_view &&
                            <div style={{ "border": "None" }}>
                                <h3> Affichage planning</h3>
                                &nbsp;

                                <div style={{ "width": "98%", "marginLeft": "5px", }} >

                                    <FullCalendar
                                        height={550}
                                        locales={allLocales}
                                        locale={'fr'}
                                        editable
                                        selectable
                                        //events={events}
                                        events={Getall_Sequence_Of_Session_result_planning_view}
                                        initialDate={SessionstartDate}
                                        select={handleSelect}
                                        headerToolbar={{
                                            start: "today prev next",
                                            center: "title",
                                            end: "dayGridMonth,timeGridWeek,timeGridDay",
                                        }}
                                        //eventContent={(info) => <EventItem info={info} />}
                                        //plugins={[timeGridPlugin]}
                                        plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                                        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                                        slotMinTime={Partner_Debut_Journee}
                                        slotMaxTime={Partner_Fin_Journee}
                                        scrollTime='08:00'
                                        displayEventEnd={true}
                                        eventContent={renderEventContent}
                                        eventClick={handleEventClick}


                                    />
                                </div>




                            </div>}




                    </div>

                    {new_planification_lines && new_planification_lines.length > 0 && <div className='div_row' style={{ backgroundColor: '#FBF2EF' }}>

                        <br />
                        <div className="titre1"> Proposition de Nouvelle planification </div>
                        {display_view && String(display_view) === "list" && <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box

                                    sx={{
                                        height: "500px",
                                        width: '100%',
                                        paddingRight: '2px',

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        }, [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },

                                    }}

                                >
                                    <DataGrid
                                        //  checkboxSelection={true}
                                        disableRowSelectionOnClick
                                        disableSelectionOnClick
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_sequence_new_planification(newSelectionModel);

                                        }}
                                        selectionModel={selectionModel_sequence_new_planification}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={new_planification_lines.map((item, index) => (
                                            {
                                                id: index,
                                                _id: (item)._id,
                                                sequence_start: format((item).start, 'dd/MM/yyyy HH:mm'),
                                                sequence_end: format((item).end, 'dd/MM/yyyy HH:mm'),
                                                session_id: (item).id,
                                                sequence_title: (item).title,
                                                agenda: (item).local_agenda,
                                                objectif: (item).local_objectif,
                                                commentaire: (item).local_comment,

                                                grp_apprenant_id: (item).grp_apprenant_id,
                                                ue_id: (item).local_ue_id,
                                                unite_enseignement_planif_id: (item).local_ue_planif_line_id,
                                                type: (item).local_type,

                                                grp_apprenant_code: (item).grp_apprenant_code,



                                            }
                                        ))}

                                        columns={columns_sequence_new_planification}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            /*
                                            setsequence_gridline_id(newSelectionModel.row.id);
                                            setselected_detail_sequence_title(newSelectionModel.row.sequence_title);
                                            setselected_sequence_startDate(newSelectionModel.row.sequence_start);
                                            setselected_sequence_endDate(newSelectionModel.row.sequence_end);

                                            setp_detail_sequence_agenda(newSelectionModel.row.agenda);
                                            setp_detail_sequence_objectif(newSelectionModel.row.objectif);
                                            setp_detail_sequence_comment(newSelectionModel.row.commentaire);


                                            Getall_List_Sequence_Ressource(newSelectionModel.row._id);

                                            setselected_sequence_id(newSelectionModel.row._id);
                                            setdisplay_detail_sequence("1");

                                            if (document.getElementById('myRef_seq_ressource')) {
                                                var divh = document.getElementById('myRef_seq_ressource').offsetTop;
                                                window.scrollTo({
                                                    top: divh,
                                                    behavior: "smooth",
                                                });
                                            }

                                       */ }}



                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {
                                            //field === 'distantiel'
                                            if (params.field === 'distantiel' && String(params.value) === "1") {
                                                return 'cell--distantiel';
                                            }
                                            if (params.field === "presentiel" && String(params.value) == "1") {
                                                return 'cell--presentiel';
                                            }


                                            //field === "etape"
                                            if (params.field === "etape" && String(params.value) == "0") {
                                                return 'cell--etape--projet';
                                            }

                                            if (params.field === "etape" && String(params.value) == "1") {
                                                return 'cell--etape--planification';
                                            }

                                            if (params.field === "etape" && String(params.value) == "2") {
                                                return 'cell--etape--planifie';
                                            }

                                            if (params.field === "etape" && String(params.value) == "3") {
                                                return 'cell--etape--termine';
                                            }

                                            if (params.field === "etape" && String(params.value) == "4") {
                                                return 'cell--etape--facture';
                                            }

                                            if (params.field === "etape" && String(params.value) == "-1") {
                                                return 'cell--etape--annule';
                                            }

                                        }}
                                        getRowClassName={(params) => {

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(sequence_gridline_id)) {
                                                return 'line--statut--selected';
                                            }



                                        }}

                                    />
                                </Box>

                                <br />

                            </div>


                        </div>}



                        {display_view && String(display_view) === "planning" &&
                            <div style={{ "border": "None" }}>
                                <h3> Affichage planning</h3>
                                &nbsp;

                                <div style={{ "width": "98%", "marginLeft": "5px", }} >

                                    <FullCalendar
                                        height={550}
                                        locales={allLocales}
                                        locale={'fr'}
                                        editable
                                        selectable
                                        //events={events}
                                        events={new_planification_lines}
                                        initialDate={planning_model_new_application_date}
                                        select={handleSelect}
                                        headerToolbar={{
                                            start: "today prev next",
                                            center: "title",
                                            end: "dayGridMonth,timeGridWeek,timeGridDay",
                                        }}
                                        //eventContent={(info) => <EventItem info={info} />}
                                        //plugins={[timeGridPlugin]}
                                        plugins={[timeGridPlugin, daygridPlugin, interactionPlugin]}
                                        views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
                                        slotMinTime={Partner_Debut_Journee}
                                        slotMaxTime={Partner_Fin_Journee}
                                        scrollTime='08:00'
                                        displayEventEnd={true}
                                        eventContent={renderEventContent}
                                        eventClick={handleEventClick}


                                    />
                                </div>




                            </div>}

                        <div className="div_row" style={{ "border": "None", "textAlign": "right" }}>

                            <Button variant="outlined" onClick={Apply_Planning_To_Session}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'> Appliquer nouveau planning


                            </Button>

                            <Button variant="outlined" onClick={Cancel_Apply_Planning_To_Session}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'> Annuler  nouveau planning


                            </Button>

                        </div>

                    </div>}

                    {!new_planification_lines || new_planification_lines.length <= 0 && <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        <Button variant="outlined"
                            className="detail_class_submenu bton_add_session bton_add_session_create_automatic" style={{ "width": "40%" }}
                            id='menu_import_participant' name='menu_import_participant' onClick={(event) => {
                                if (String(props.selected_session_invoiced_statut) === "1") {
                                    alert(" La session est en cours de facturation ")
                                } else if (String(props.selected_session_invoiced_statut) === "2") {
                                    alert(" La session est déjà facturée ")
                                } else {

                                    setDialog_seq_choix_model_emargement_open(true);
                                    setDialog_seq_planif_periode_type("");
                                }

                            }} > Planification automatique
                            &nbsp;  <FcMultipleSmartphones />
                        </Button>


                        &nbsp;
                        <Button variant="outlined"
                            onClick={(event) => {
                                if (String(props.selected_session_invoiced_statut) === "1") {
                                    alert(" La session est en cours de facturation ")
                                } else if (String(props.selected_session_invoiced_statut) === "2") {
                                    alert(" La session est déjà facturée ")
                                } else {

                                    submenu_add_one_sequence()
                                }

                            }}

                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Séq. &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                        &nbsp;
                        {String(active_planif_modele) !== "1" && <Button variant="outlined"
                            onClick={(event) => {
                                setaction_modele_planning("");
                                setactive_planif_modele("1");
                            }}

                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Activer Modèle &nbsp;
                            <FcTemplate style={{ "fontSize": "large", "color": "green" }} />

                        </Button>}

                        {String(active_planif_modele) === "1" && <Button variant="outlined"
                            onClick={(event) => {
                                setaction_modele_planning("");
                                setactive_planif_modele("");
                            }}

                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Désactiver Modèle &nbsp;
                            <FcTemplate style={{ "fontSize": "large", "color": "green" }} />

                        </Button>}

                        <br />

                    </div>}

                    {String(active_planif_modele) === "1" && <div style={{ "textAlign": "right", "float": "right", "width": "40%", "marginRight": '20px' }}>

                        <nav style={{ "textAlign": "left", "float": "left", "width": "65%" }}>

                            {New_Option_Gestion_Model_Planning && New_Option_Gestion_Model_Planning.length > 0 && <Autocomplete
                                disablePortal
                                name="model_filtre"
                                id="model_filtre"
                                // className="disabled_style"
                                //value={New_Option_Gestion_Model_Planning.filter((data) => (data).value === String(p_filtre1))[0].label}
                                options={New_Option_Gestion_Model_Planning}
                                onChange={(event, value) => {
                                    setgridline_sequence_new_planificatio_id("");
                                    setplanning_model_new_application_date();
                                    if (value && value.value) {
                                        setaction_modele_planning(value.value);

                                    } else {
                                        setaction_modele_planning("");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}
                        </nav>
                        <nav style={{ "textAlign": "right", "float": "left", "width": "30%", "marginTop": "10px" }}>


                            {action_modele_planning && String(action_modele_planning).length > 1 && <Button className="bton_enreg"
                                onClick={(event) => {
                                    setplanning_model_new_application_date();
                                    if (String(action_modele_planning) === "gestion") {
                                        Getall_Partner_Modele_Planning();
                                        setDialog_MODEL_PLANNING_open(true);
                                    } else if (String(action_modele_planning) === "record") {
                                        setDialog_RECORD_MODEL_PLANNING_open(true);
                                    }
                                }}>
                                <FcAcceptDatabase /> Traiter

                            </Button>}

                        </nav>

                    </div>}

                </div>
                <div className="div_row" ref={myRef_seq_ressource} id="myRef_seq_ressource"> &nbsp;</div>
                {String(display_detail_sequence) === "1" && <div className="div_row" style={{ "border": "None" }}>

                    <div className="session_caract"> Titre <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="seq_title"
                            id="seq_title"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={selected_detail_sequence_title}

                        />
                    </div>

                    <div className="session_caract"> début <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="seq_start"
                            id="seq_start"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={selected_sequence_startDate}

                        />
                    </div>

                    <div className="session_caract"> Fin <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="seq_end"
                            id="seq_end"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style enable_style"
                            value={selected_sequence_endDate}

                        />
                    </div>


                    <div className="session_data">
                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '1px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',


                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_sequence(newSelectionModel);

                                        }}
                                        //selectionModel={selectionModel_sequence}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_List_Sequence_Ressource_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                related_target_collection_id: JSON.parse(item).related_target_collection_id,
                                                related_target_collection: JSON.parse(item).related_target_collection,
                                                type_ressource: JSON.parse(item).related_target_collection_object,
                                                related_target_collection_object: JSON.parse(item).related_target_collection_object,
                                                related_target_collection_id_nom: JSON.parse(item).related_target_collection_id_nom,
                                                poste: JSON.parse(item).poste,
                                            }
                                        ))}

                                        columns={columns_sequence_ressources_affectation}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {


                                        }}



                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        sx={datagridSx}



                                    />
                                </Box>
                                <br />

                            </div>


                        </div>

                    </div>
                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        <Button variant="outlined"
                            onClick={(event) => {
                                if (String(props.selected_session_invoiced_statut) === "1") {
                                    alert(" La session est en cours de facturation ")
                                } else if (String(props.selected_session_invoiced_statut) === "2") {
                                    alert(" La session est déjà facturée ")
                                } else {

                                    submenu_add_one_Ressource()
                                }

                            }}


                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Ressource &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                        <br />

                    </div>

                </div>}

            </div>

        </div >
    );
}

export default Module_Session_Planification;

