import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { Fab } from "@material-ui/core";
import fileDownload from 'js-file-download'
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';



const Module_Historique_Action = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");

    const [related_collection, setrelated_collection] = useState(props.related_collection);
    const [related_collection_recid, setrelated_collection_recid] = useState(props.related_collection_recid);


    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    useEffect(() => {
        //menu_info();
        Get_List_Historic_Action();
      
        var divh = document.getElementById('div_header').offsetTop;
        window.scrollTo({
            top: divh,
            behavior: "smooth",
        });


    }, [related_collection_recid]);


    const [Get_List_Historic_Action_api, setGet_List_Historic_Action_api] = useState();
    const [Get_List_Historic_Action_message, setGet_List_Historic_Action_message] = useState();
    const [Get_List_Historic_Action_result, setGet_List_Historic_Action_result] = useState();
    function Get_List_Historic_Action(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);
        form.append("related_collection", related_collection);
        form.append("related_collection_recid", related_collection_recid);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Action_Historique_List/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Historic_Action  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Historic_Action  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setGet_List_Historic_Action_api("true");
                setGet_List_Historic_Action_result(res.data.message);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Historic_Action_api("false");
                setGet_List_Historic_Action_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Historic_Action = ', error);
            setGet_List_Historic_Action_api("false");
            alert(" Impossible de récupérer l'historique");
            //setmyApimyApiMessage("")
        })
    }

    const [isLoading, setLoading] = useState();

    return (
        <div className="module_historique_action">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>
            <div className="div_row" id="div_header" style={{border:"none"}}>
                <nav style={{fontSize:"calc(1.1rem + .3vw)", color:"rgb(10, 42, 77)"}}> Historique des actions </nav>
            </div>

            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                }}
            >

                {Get_List_Historic_Action_result &&
                    Get_List_Historic_Action_result.map((event) => (

                        <TimelineItem>
                            <TimelineOppositeContent color="textSecondary" style={{"fontSize":"13px"}}>
                            {JSON.parse(event).date_update}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent style={{"fontSize":"13px"}}><b>{JSON.parse(event).connected_user_nom} :</b>   {JSON.parse(event).action_description} </TimelineContent>
                        </TimelineItem>

                    ))}






            </Timeline>

        </div>
    );
}

export default Module_Historique_Action;
