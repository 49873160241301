import React, { useRef, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import { getCookie, setCookie } from 'react-use-cookie';

import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";

import { useHistory } from "react-router-dom";

import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from '@tinymce/tinymce-react';
import { IoArrowUndoCircle } from "react-icons/io5";
import { GrUserAdmin, GrOrderedList } from "react-icons/gr";
import parse from 'html-react-parser';
import fileDownload from 'js-file-download'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import GestionAdministrative from "./GestionAdministrative";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import Autocomplete from '@mui/material/Autocomplete';
import { Fab } from "@material-ui/core";
import { CiTrash } from "react-icons/ci";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { BarChart } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { RiFileExcel2Line } from "react-icons/ri";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import Module_Historique_Action from "./Module_Historique_Action";

const AddClassManual = (props) => {
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL + "/myclass/api/add_partner_account/";
  const [inputs, setInputs] = useState({});

  const [mypublished, setmypublished] = useState("0");
  const [mypublished_label, setmypublished_label] = useState("-");

  const [mytrainingsupport, setmytrainingsupport] = useState("n/a");
  const [mytrainingsupport_label, setmytrainingsupport_label] = useState("-");

  const [mytrainingdurationunit, setmytrainingdurationunit] = useState("heure");


  const [mycpf, setmycpf] = useState("0");
  const [mycpf_label, setmycpf_label] = useState("Non");

  const [mycertif, setmycertif] = useState("0");
  const [mycertif_label, setmycertif_label] = useState("Non");

  const [mypresence, setmypresence] = useState("0");
  const [mypresence_label, setmypresence_label] = useState("Non");


  const [mydistance, setmydistance] = useState("0");
  const [mydistance_label, setmydistance_label] = useState("Non");


  var date_today_90j = new Date();
  date_today_90j.setDate(date_today_90j.getDate() + 90);


  var date_today_30j = new Date();
  date_today_30j.setDate(date_today_30j.getDate() + 30);


  var date_today_minus_180j = new Date();
  date_today_minus_180j.setDate(date_today_minus_180j.getDate() - 180);


  const [importmessage, setimportmessage] = useState("");
  const [myApiResponse, setmyApiResponse] = useState("");
  const setText = "coucou";
  const [ShowModal, setShowModal] = useState(true);
  const [result, setResult] = useState("");
  const [isupdateclass, setisupdateclass] = useState(false);
  const [testval, settestval] = useState("");
  const [displayedinternalurl, setdisplayedinternalurl] = useState("");
  const [datamodification, setdatamodification] = useState("0");
  const [datelieu, setdatelieu] = useState("");

  const [plus_produit_enabled, setplus_produit_enabled] = useState("");
  const [mots_cle_enabled, setmots_cle_enabled] = useState("");
  const [zone_diffusion_enabled, setzone_diffusion_enabled] = useState("");

  function ExpandableCell_50({ value }) {
    const [expanded, setExpanded] = React.useState(false);

    return (
      <nav style={{ width: '100%', textAlign: 'center' }}>
        {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

          {expanded ? value : String(value).slice(0, 50)}&nbsp;
          {value.length > 50 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link
              type="button"
              component="button"
              sx={{ fontSize: 'inherit' }}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
            </Link>
          )}
        </div>}

        {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
          &nbsp;
        </div>
        }
      </nav>
    );
  }


  const [selectionModel_list_unite_enseignement, setselectionModel_list_unite_enseignement] = React.useState([]);

  const columns_list_unite_enseignement = [
    { field: 'id', headerName: 'id', hide: true },
    { field: '_id', headerName: '_id', hide: true },
    { field: 'code', headerName: 'Code', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
    { field: 'titre', headerName: 'Titre', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
    { field: 'credit', headerName: 'Credit', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
    {
      field: 'pres_dist_hyp', headerName: 'Animation', minWidth: 150, flex: 1,
      renderCell: (cellValues) => {
        return (
          <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
            {cellValues.row.pres_dist_hyp && String(cellValues.row.pres_dist_hyp) === "1" && "Distanciel"}
            {cellValues.row.pres_dist_hyp && String(cellValues.row.pres_dist_hyp) === "0" && "Présentiel"}
            {cellValues.row.pres_dist_hyp && String(cellValues.row.pres_dist_hyp) === "2" && "Hybride"}
          </div>

        );
      }
    },
    {
      field: 'is_noted', headerName: 'Evalué', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
      renderCell: (cellValues) => {
        return (
          <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
            {cellValues.row.is_noted && String(cellValues.row.is_noted) === "1" && "Oui"}
            {cellValues.row.is_noted && String(cellValues.row.is_noted) === "0" && "Non"}

          </div>

        );
      }
    },
    { field: 'duration_concat', headerName: 'Durée', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
    { field: 'duration', headerName: 'Durée', minWidth: 150, hide: true, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
    { field: 'duration_unite', headerName: 'Unit.', minWidth: 150, hide: true, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },

    {
      field: "delete", headerName: 'Supprimer',
      renderCell: (cellValues) => {
        return (

          <Popup
            trigger={<Button


            >
              <CiTrash />

            </Button>}
            modal
            nested
            position="center center"
          >
            {close => (
              <div>
                <button className="gest_close" onClick={close}>
                  &times;
                </button>
                <div className="gest_header"> MySy Information </div>
                <div className="gest_content">
                  {' '}

                  En confirmant cette opération, l'UE  <i><font color="red"> définitivement supprimée de cette formation </font></i>. <br />

                </div>
                <div className="gest_actions">
                  <div style={{ "width": "45%", "float": "left" }}>
                    <button className="gest_bton_popup" onClick={(event) => {
                      handleClick_delete_UE_From_Class(event, cellValues);
                      //console.log('modal closed ');
                      close();
                    }}> Valider </button>

                  </div>
                  <div style={{ "width": "45%", "float": "right" }}>
                    <button
                      className="gest_bton_popup"
                      onClick={() => {
                        //console.log('modal closed ');
                        close();
                      }}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Popup>


        );
      }
    },

  ]


  const [selectionModel_list_evaluation_nite_enseignement, setselectionModel_list_evaluation_nite_enseignement] = React.useState([]);

  const columns_list_evaluation_unite_enseignement = [
    { field: 'id', headerName: 'id', hide: true },
    { field: '_id', headerName: '_id', hide: true },
    { field: 'class_id', headerName: 'class_id', Width: 0, hide: true, },

    { field: 'class_ue_id', headerName: 'class_ue_id', Width: 0, hide: true },

    {
      field: 'class_ue_code', headerName: 'Code UE', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
      renderCell: (cellValues) => {
        return (
          <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
            {New_Getall_Class_List_UE_result && New_Getall_Class_List_UE_result.length > 0 && <nav>
              {New_Getall_Class_List_UE_result.filter((data) => (data)._id === String(cellValues.row.class_ue_id))[0].label}

              {/*cellValues.row.class_ue_id*/}
            </nav>}


          </div>

        );
      }
    },


    { field: 'type_evaluation_id', headerName: 'type_evaluation_id', Width: 0, hide: true, },

    {
      field: 'type_evaluation_code', headerName: 'Type Eval', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
      renderCell: (cellValues) => {
        return (
          <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
            {New_Getall_Partner_Type_Evalution_List_result && New_Getall_Partner_Type_Evalution_List_result.length > 0 && <nav>
              {New_Getall_Partner_Type_Evalution_List_result.filter((data) => (data)._id === String(cellValues.row.type_evaluation_id))[0].label}
            </nav>}


          </div>

        );
      }
    },


    { field: 'max_note', headerName: 'max_note', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },

    {
      field: "delete", headerName: 'Supprimer',
      renderCell: (cellValues) => {
        return (

          <Popup
            trigger={<Button


            >
              <CiTrash />

            </Button>}
            modal
            nested
            position="center center"
          >
            {close => (
              <div>
                <button className="gest_close" onClick={close}>
                  &times;
                </button>
                <div className="gest_header"> MySy Information </div>
                <div className="gest_content">
                  {' '}

                  En confirmant cette opération, l'évaluation   <i><font color="red"> définitivement supprimée de l'UE ainsi que de la formation </font></i>. <br />

                </div>
                <div className="gest_actions">
                  <div style={{ "width": "45%", "float": "left" }}>
                    <button className="gest_bton_popup" onClick={(event) => {
                      handleClick_delete_evaluation_ue_class(event, cellValues);
                      //console.log('modal closed ');
                      close();
                    }}> Valider </button>

                  </div>
                  <div style={{ "width": "45%", "float": "right" }}>
                    <button
                      className="gest_bton_popup"
                      onClick={() => {
                        //console.log('modal closed ');
                        close();
                      }}
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Popup>


        );
      }
    },

  ]


  function handleClick_delete_evaluation_ue_class(event, cellValues) {
    Delete_Given_Evaluation_UE_Class(cellValues.row._id);
  }


  const [Delete_Given_Evaluation_UE_Class_api, setDelete_Given_Evaluation_UE_Class_api] = useState();
  const [Delete_Given_Evaluation_UE_Class_message, setDelete_Given_Evaluation_UE_Class_message] = useState();
  const [Delete_Given_Evaluation_UE_Class_result, setDelete_Given_Evaluation_UE_Class_result] = useState();
  function Delete_Given_Evaluation_UE_Class(local_evaluation_ue_id) {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("class_ue_evaluation_id", local_evaluation_ue_id);


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Class_UE_Evaluation/";

    axios.post(myurl, form).then(res => {
      //console.log(" In Delete_Given_Evaluation_UE_Class  res.data.status = " + res.data.status);
      //console.log(" In Delete_Given_Evaluation_UE_Class  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setDelete_Given_Evaluation_UE_Class_api("true");
        setDelete_Given_Evaluation_UE_Class_result(res.data.message);

        Getall_Class_List_Evalution();

        setadd_ue_evalution("");
        setselected_ue_id("");
        setselected_eu_evalution_id("");
        setsetgridline_evaluation_ue_id("");
        setp_detail_max_node("");
        setclass_unite_enseignement_type_evaluation_id("");

      }
      else {
        setDelete_Given_Evaluation_UE_Class_api("false");
        setDelete_Given_Evaluation_UE_Class_message(res.data.message);


        alert(res.data.message);


      }

    }).catch((error) => {

      console.warn('Delete_Given_Evaluation_UE_Class : Not good man :( mysearchtext = ' + error);
      setDelete_Given_Evaluation_UE_Class_api("false");
      alert(" Impossible de supprimer l'évaluation ");
    })
  }


  function handleClick_delete_UE_From_Class(event, cellValues) {
    Delete_Given_UE_From_Class(cellValues.row._id);
  }

  const [Delete_Given_UE_From_Class_api, setDelete_Given_UE_From_Class_api] = useState();
  const [Delete_Given_UE_From_Class_message, setDelete_Given_UE_From_Class_message] = useState();
  const [Delete_Given_UE_From_Class_result, setDelete_Given_UE_From_Class_result] = useState();
  function Delete_Given_UE_From_Class(local_ue_id) {


    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    if (props.mytrainingclass['class_id'])
      form.append("class_id", props.mytrainingclass['class_id']);
    else
      form.append("class_id", selected_class_id);


    form.append("ue_id", local_ue_id);


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_UE_From_Class/";

    axios.post(myurl, form).then(res => {
      //console.log(" In Delete_Given_UE_From_Class  res.data.status = " + res.data.status);
      //console.log(" In Delete_Given_UE_From_Class  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setDelete_Given_UE_From_Class_api("true");
        setDelete_Given_UE_From_Class_result(res.data.message);
        Getall_Class_List_UE();
        alert(res.data.message)
      }
      else {
        setDelete_Given_UE_From_Class_api("false");
        setDelete_Given_UE_From_Class_message(res.data.message);

        alert(res.data.message);

      }

    }).catch((error) => {

      console.warn('Delete_Given_UE_From_Class : Not good man :( mysearchtext = ' + error);
      setDelete_Given_UE_From_Class_api("false");
      alert(" Impossible de supprimer l'UE ");
    })
  }

  const [ue_gridline_id, setue_gridline_id] = useState("");

  const [New_Getall_Class_List_UE_result, setNew_Getall_Class_List_UE_result] = useState([]);

  const [Getall_Class_List_UE_api, setGetall_Class_List_UE_api] = useState();
  const [Getall_Class_List_UE_message, setGetall_Class_List_UE_message] = useState();
  const [Getall_Class_List_UE_result, setGetall_Class_List_UE_result] = useState([]);
  function Getall_Class_List_UE(event) {

    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    if (props.mytrainingclass['class_id'])
      form.append("class_id", props.mytrainingclass['class_id']);
    else if (selected_class_id)
      form.append("class_id", selected_class_id);
    else
      form.append("class_internal_url", props.mytrainingclass['class_internal_url']);



    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Of_Given_Class/";

    setLoading(true);

    axios.post(myurl, form).then(res => {


      setLoading(false);
      if (String(res.data.status) === String("true")) {
        //console.log(" In Getall_Class_List_UE  res.data.status = " + res.data.status);
        console.log(" In Getall_Class_List_UE  res.data.message r_class = " + res.data.message);
        setGetall_Class_List_UE_api("true");
        setGetall_Class_List_UE_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x)._id;
          var local_code = JSON.parse(x).code;
          var local_titre = JSON.parse(x).titre;


          var local_credit = JSON.parse(x).credit;
          var local_pres_dist_hyp = JSON.parse(x).pres_dist_hyp;
          var local_is_noted = JSON.parse(x).is_noted;



          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "label": local_code,
            "code": local_code,
            "titre": local_titre,
            "credit": local_credit,
            "pres_dist_hyp": local_pres_dist_hyp,
            "is_noted": local_is_noted,

          };
          new_data2.push(node);
        });

        //---
        var node = {
          "_id": "",
          "id": "",
          "label": "",
          "code": "",
          "titre": "",
          "credit": "",
          "pres_dist_hyp": "",
          "is_noted": "",

        };
        new_data2.push(node);

        if (new_data2.length > 0)
          setNew_Getall_Class_List_UE_result(new_data2);
      }
      else {
        setGetall_Class_List_UE_api("false");
        setGetall_Class_List_UE_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {

      setLoading(false);
      console.warn('Not good man :( Getall_Class_List_UE = ', error);
      setGetall_Class_List_UE_api("false");
      alert(" Impossible de recuperer la liste des UE de la formation");
      //setmyApimyApiMessage("")
    })
  }


  const [New_Getall_Partner_List_UE_result, setNew_Getall_Partner_List_UE_result] = useState([]);

  const [Getall_Partner_List_UE_api, setGetall_Partner_List_UE_api] = useState();
  const [Getall_Partner_List_UE_message, setGetall_Partner_List_UE_message] = useState();
  const [Getall_Partner_List_UE_result, setGetall_Partner_List_UE_result] = useState();
  function Getall_Partner_List_UE(event) {

    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_no_filter/";

    setLoading(true);

    axios.post(myurl, form).then(res => {


      setLoading(false);
      if (String(res.data.status) === String("true")) {
        //console.log(" In Getall_Partner_List_UE  res.data.status = " + res.data.status);
        //console.log(" In Getall_Partner_List_UE  res.data.message r_class = " + res.data.message);
        setGetall_Partner_List_UE_api("true");
        setGetall_Partner_List_UE_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x)._id;
          var local_code = JSON.parse(x).code;
          var local_titre = JSON.parse(x).titre;


          var local_credit = JSON.parse(x).credit;
          var local_pres_dist_hyp = JSON.parse(x).pres_dist_hyp;
          var local_is_noted = JSON.parse(x).is_noted;



          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "label": local_code,
            "code": local_code,
            "titre": local_titre,
            "credit": local_credit,
            "pres_dist_hyp": local_pres_dist_hyp,
            "is_noted": local_is_noted,

          };
          new_data2.push(node);
        });

        if (new_data2.length > 0)
          setNew_Getall_Partner_List_UE_result(new_data2);
      }
      else {
        setGetall_Partner_List_UE_api("false");
        setGetall_Partner_List_UE_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {

      setLoading(false);
      console.warn('Not good man :( Getall_Partner_List_UE = ', error);
      setGetall_Partner_List_UE_api("false");
      alert(" Impossible de recuperer la liste des UE de la formation");
      //setmyApimyApiMessage("")
    })
  }


  const [New_Getall_Partner_Type_Evalution_List_result, setNew_Getall_Partner_Type_Evalution_List_result] = useState([]);

  const [Getall_Partner_Type_Evalution_List_api, setGetall_Partner_Type_Evalution_List_api] = useState();
  const [Getall_Partner_Type_Evalution_List_message, setGetall_Partner_Type_Evalution_List_message] = useState();
  const [Getall_Partner_Type_Evalution_List_result, setGetall_Partner_Type_Evalution_List_result] = useState([]);
  function Getall_Partner_Type_Evalution_List(event) {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Type_Evaluation/";

    setLoading(true);

    axios.post(myurl, form).then(res => {


      setLoading(false);
      if (String(res.data.status) === String("true")) {
        //console.log(" In Getall_Partner_Type_Evalution_List  res.data.status = " + res.data.status);
        //console.log(" In Getall_Partner_Type_Evalution_List  res.data.message r_class = " + res.data.message);
        setGetall_Partner_Type_Evalution_List_api("true");
        setGetall_Partner_Type_Evalution_List_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x)._id;
          var local_code = JSON.parse(x).code;
          var local_nom = JSON.parse(x).nom;

          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "label": local_code,
            "code": local_code,
            "nom": local_nom,


          };
          new_data2.push(node);
        });

        //---
        var node = {
          "_id": "",
          "id": "",
          "label": "",
          "code": "",
          "nom": "",

        };
        new_data2.push(node);

        if (new_data2.length > 0)
          setNew_Getall_Partner_Type_Evalution_List_result(new_data2);
      }
      else {
        setGetall_Partner_Type_Evalution_List_api("false");
        setGetall_Partner_Type_Evalution_List_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {

      setLoading(false);
      console.warn('Not good man :( Getall_Partner_Type_Evalution_List = ', error);
      setGetall_Partner_Type_Evalution_List_api("false");
      alert(" Impossible de recuperer la liste des types d'evaluation");
      //setmyApimyApiMessage("")
    })
  }


  const [New_Getall_Class_List_Evalution_result, setNew_Getall_Class_List_Evalution_result] = useState([]);

  const [Getall_Class_List_Evalution_api, setGetall_Class_List_Evalution_api] = useState();
  const [Getall_Class_List_Evalution_message, setGetall_Class_List_Evalution_message] = useState();
  const [Getall_Class_List_Evalution_result, setGetall_Class_List_Evalution_result] = useState([]);
  function Getall_Class_List_Evalution(event) {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    if (props.mytrainingclass['class_id'])
      form.append("class_id", props.mytrainingclass['class_id']);
    else
      form.append("class_id", selected_class_id);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Evaluation/";

    setLoading(true);

    axios.post(myurl, form).then(res => {


      setLoading(false);
      if (String(res.data.status) === String("true")) {
        //console.log(" In Getall_Class_List_Evalution  res.data.status = " + res.data.status);
        console.log(" In Getall_Class_List_Evalution  res.data.message r_class = " + res.data.message);
        setGetall_Class_List_Evalution_api("true");
        setGetall_Class_List_Evalution_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x)._id;
          var local_class_id = JSON.parse(x).class_id;
          var local_class_ue_id = JSON.parse(x).class_ue_id;
          var local_type_evaluation_id = JSON.parse(x).type_evaluation_id;
          var local_max_note = JSON.parse(x).max_note;


          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "label": local_class_id,
            "class_id": local_class_id,
            "class_ue_id": local_class_ue_id,
            "type_evaluation_id": local_type_evaluation_id,
            "max_note": local_max_note,


          };
          new_data2.push(node);
        });

        //---
        var node = {
          "_id": "",
          "id": "",
          "label": "",
          "class_id": "",
          "class_ue_id": "",
          "type_evaluation_id": "",
          "max_note": "",

        };
        new_data2.push(node);

        if (new_data2.length > 0)
          setNew_Getall_Class_List_Evalution_result(new_data2);
      }
      else {
        setGetall_Class_List_Evalution_api("false");
        setGetall_Class_List_Evalution_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {

      setLoading(false);
      console.warn('Not good man :( Getall_Class_List_Evalution = ', error);
      setGetall_Class_List_Evalution_api("false");
      alert(" Impossible de recuperer la liste des évaluations de la formatio");
      //setmyApimyApiMessage("")
    })
  }


  const presentiel = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  const support_training_list = [
    {
      value: 'video',
      label: 'Video',
    },
    {
      value: 'document',
      label: 'Document',
    },
    {
      value: 'réalité virtuelle',
      label: 'Réalité Virtuelle',
    },
    {
      value: 'mixte',
      label: 'Mixte',
    },
    {
      value: 'autre',
      label: 'Autre',
    },
    {
      value: 'n/a',
      label: 'n/a',
    },

  ];


  const New_support_training_list = [
    {
      value: 'video',
      label: 'Video',
    },
    {
      value: 'document',
      label: 'Document',
    },
    {
      value: 'réalité virtuelle',
      label: 'Réalité Virtuelle',
    },
    {
      value: 'mixte',
      label: 'Mixte',
    },
    {
      value: 'autre',
      label: 'Autre',
    },
    {
      value: 'n/a',
      label: 'n/a',
    },

  ];
  const duration_unit_list = [
    {
      value: 'heure',
      label: 'Heures',
    },

    {
      value: 'jour',
      label: 'Jours',
    },
    {
      value: 'semaine',
      label: 'Semaines',
    },
    {
      value: 'mois',
      label: 'Mois',
    },
    {
      value: 'user_rythme',
      label: 'Rythme stagiaire',
    }
  ];


  const New_duration_unit_list = [
    {
      value: 'heure',
      label: 'Heures',
    },

    {
      value: 'jour',
      label: 'Jours',
    },
    {
      value: 'semaine',
      label: 'Semaines',
    },
    {
      value: 'mois',
      label: 'Mois',
    },
    {
      value: 'user_rythme',
      label: 'Rythme stagiaire',
    }
  ];



  const publiee = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];



  function handleOnClose(event) {
    setShowModal(false);
  }



  function DeleteClass(event) {

    var code = document.getElementsByName("external_code")[0].value;
    var server_address = "127.0.0.1";
    //var server_address = "89.156.84.196";



    var formData = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append("class_internal_url", props.mytrainingclass['ref']);



    var myurl = process.env.REACT_APP_API_URL + "myclass/api/delete_Class_by_internal_url/";
    setLoading(true);
    fetch(
      myurl,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setimportmessage(result['message']);
        setmyApiResponse(result['status']);


        if (String(result['status']) === "true") {
          //alert(result['status']+"  -- "+myApiResponse+' mess = '+result['message']);
          document.getElementsByName("external_code")[0].value = "";
          document.getElementsByName("title")[0].value = "";

          //document.getElementsByName("objectif")[0].value = "";
          //document.getElementsByName("programme")[0].value = "";
          //document.getElementsByName("prerequis")[0].value = "";


          //document.getElementsByName("description2")[0].value = "";

          document.getElementsByName("distentiel")[0].value = "";
          document.getElementsByName("price")[0].value = "";
          document.getElementsByName("domaine")[0].value = "";
          document.getElementsByName("duree")[0].value = "";
          //document.getElementsByName("mots_cle")[0].value = "";
          //document.getElementsByName("plus_produit")[0].value = "";
          document.getElementsByName("url")[0].value = "";
          document.getElementsByName("lms_class_code")[0].value = "";
          document.getElementsByName("class_inscription_url")[0].value = "";
          document.getElementsByName("metier")[0].value = "";

          document.getElementsByName("note")[0].value = "";

          //document.getElementsByName("objectif")[0].value = "";
          //document.getElementsByName("programme")[0].value = "";
          //document.getElementsByName("prerequis")[0].value = "";
          alert(" La formation a été correctement supprimée.");
          history.push({
            pathname: "/Partner/",
            state: {
              current_pack: props.currentpack, local_sub_menu: 'affichage', price: "",
              period: "", new_pack: ""
            }
          });
          window.location.reload();
        } else if (String(result['status']) === "false") {
          alert(result['message']);
        } else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
        }



      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        setmyApiResponse("false");
      });
  }

  const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

  const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
  const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
  const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
  function Getall_Training_Employee_No_Filter(event) {

    var form = new FormData();


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



    axios.post(myurl, form).then(res => {

      setLoading(true);

      if (String(res.data.status) === String("true")) {
        setLoading(false);
        //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
        //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
        setGetall_Training_Employee_No_Filter_api("true");
        setGetall_Training_Employee_No_Filter_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x)._id;
          var local_nom = JSON.parse(x).nom;
          var local_prenom = JSON.parse(x).prenom;
          var local_ismanager = JSON.parse(x).ismanager;


          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "label": local_nom + " " + local_prenom,
            "nom": local_nom,
            "prenom": local_prenom,
            "ismanager": local_ismanager
          };
          new_data2.push(node);
        });

        if (new_data2.length > 0)
          setNew_Getall_Training_Employee_No_Filter_result(new_data2);

      }
      else {
        setGetall_Training_Employee_No_Filter_api("false");
        setGetall_Training_Employee_No_Filter_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
      setGetall_Training_Employee_No_Filter_api("false");
      alert(" Impossible de recuperer la liste des employés");
      //setmyApimyApiMessage("")
    })
  }

  const [history_securite_read, sethistory_securite_read] = useState("");

  useEffect(() => {

    Get_List_Domaines();
    Get_List_Metiers();
    Get_List_Categorie();

    Get_Partner_Object_Specific_Fields("myclass");
    Getall_Training_Employee_No_Filter();
    Get_List_Niveau_Formation();

    setfield_description("");
    setfield_objectif("");
    setfield_programme("");
    setfield_prerequis("");
    setfield_mots_cle("");
    setfield_plus_produit("");
    setfield_datelieu("");
    setfield_zone_diffusion("");
    setmyApiResponse();
    Getall_Partner_Type_Evalution_List();
    Getall_Class_List_Evalution();

    fillfield();

    sethistory_securite_read(props.check_user_acces_right("historique", "read"))

    if (props.mytrainingclass['class_external_code']) {
      getClassImage();
      //console.log("props.mytrainingclass['plus_produit'] = " + props.mytrainingclass['plus_produit']);
      setplus_produit_enabled(props.mytrainingclass['plus_produit']);
      setmots_cle_enabled(props.mytrainingclass['mots_cle']);
      setzone_diffusion_enabled(props.mytrainingclass['zone_diffusion']);
      Getall_Class_List_UE();
      Getall_Partner_List_UE();
    }

    setdatamodification("0");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    submenu_detail_formation();

  }, [props.mytrainingclass['ref'],]);


  const [field_description, setfield_description] = useState("");
  const [field_objectif, setfield_objectif] = useState("");
  const [field_programme, setfield_programme] = useState("");

  const [field_methode_pedago, setfield_methode_pedago] = useState("");
  const [field_handicap, setfield_handicap] = useState("");

  const [field_suivi_eval, setfield_suivi_eval] = useState("");


  const [field_prerequis, setfield_prerequis] = useState("");
  const [field_pourqui, setfield_pourqui] = useState("");
  const [field_mots_cle, setfield_mots_cle] = useState("");
  const [field_plus_produit, setfield_plus_produit] = useState("");

  const [field_datelieu, setfield_datelieu] = useState("");
  const [field_zone_diffusion, setfield_zone_diffusion] = useState("");

  const [selected_class_id, setselected_class_id] = useState("");


  function fillfield(event) {
    setisupdateclass(true);
    // DisableTraining();
    var val = props.mytrainingclass['ref'];


    // si la reference n'est pas rempli, alors on fait de la creation
    // si non il s'agit  get pour un update/
    if (!val) {
      setisupdateclass(false);
      return;
    }


    var form = new FormData();
    //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("internal_url", val);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_partner_class/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      if (String(res.data.status) === String("true")) {
        setLoading(false);
        //console.log(" In get_partner_class  res.data.status = " + res.data.status);
        //console.log(" In get_partner_class  res.data.message r_class = " + res.data.message);
        setmyApiResponse("True");
        setResult(res.data.message);


        var mylocaltraining = JSON.parse(res.data.message);


        if (mylocaltraining) {
          setdisplayedinternalurl(mylocaltraining.internal_url);




          if (mylocaltraining._id) {
            setselected_class_id(mylocaltraining._id);
            Get_List_Of_All_PJ(mylocaltraining._id);
          }

          //document.getElementsByName("external_code")[0].value = mylocaltraining.external_code;
          setp_detail_external_code(mylocaltraining.external_code);
          setp_detail_title(mylocaltraining.title);

          //document.getElementsByName("title")[0].value = mylocaltraining.title;



          if (mylocaltraining.objectif) {
            //document.getElementsByName("objectif")[0].value = mylocaltraining.objectif;
            //alert(" laaaaa ");
            setfield_objectif(mylocaltraining.objectif);
          }


          if (mylocaltraining.programme) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_programme(mylocaltraining.programme);
          }


          if (mylocaltraining.methode_pedagogique) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_methode_pedago(mylocaltraining.methode_pedagogique);
          }


          if (mylocaltraining.suivi_eval) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_suivi_eval(mylocaltraining.suivi_eval);
          }

          if (mylocaltraining.condition_handicape) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_handicap(mylocaltraining.condition_handicape);
          }


          if (mylocaltraining.pourqui) {
            //document.getElementsByName("programme")[0].value = mylocaltraining.programme;
            setfield_pourqui(mylocaltraining.pourqui);
          }



          if (mylocaltraining.prerequis) {
            //document.getElementsByName("prerequis")[0].value = mylocaltraining.prerequis;
            setfield_prerequis(mylocaltraining.prerequis);
          }

          //document.getElementsByName("description2")[0].value = mylocaltraining.description;
          setfield_description(mylocaltraining.description);


          setp_formateur_label("-");
          if (mylocaltraining.formateur_id) {
            setp_formateur_id(mylocaltraining.formateur_id);
            setp_formateur_label(mylocaltraining.formateur_nom_prenom);
            //console.log(" #### mylocaltraining.formateur_nom_prenom = ", mylocaltraining.formateur_nom_prenom);

          } else {
            setp_formateur_id("");
            setp_formateur_label("-");

          }

          if (mylocaltraining.presentiel.distantiel) {


            setmydistance(mylocaltraining.presentiel.distantiel);
            var result2 = presentiel.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.presentiel.distantiel)))
            if (result2[0]) {
              setmydistance_label(result2[0].label);

            }
          } else {
            setmydistance("0");
            setmydistance_label("Non");
          }


          if (mylocaltraining.presentiel.presentiel) {


            setmypresence(mylocaltraining.presentiel.presentiel);
            var result2 = presentiel.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.presentiel.presentiel)))
            if (result2[0]) {
              setmypresence_label(result2[0].label);


            }
          } else {
            setmypresence("0");
            setmypresence_label("Non");
          }


          //setmypresence(mylocaltraining.presentiel.presentiel);

          if (mylocaltraining.published) {
            setmypublished(mylocaltraining.published);
            var result2 = publiee.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.published)))
            if (result2[0])
              setmypublished_label(result2[0].label);
          }
          else {
            setmypublished("0");
            setmypublished_label("Non");
          }

          if (mylocaltraining.cpf) {
            setmycpf(mylocaltraining.cpf);
            var result2 = cpf.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.cpf)))
            if (result2[0])
              setmycpf_label(result2[0].label);
          }
          else {
            setmycpf("0");
            setmycpf_label("Non");
          }


          if (mylocaltraining.support) {
            setmytrainingsupport(mylocaltraining.support);


            var result2 = New_support_training_list.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.support)))
            if (result2[0])
              setmytrainingsupport_label(result2[0].label);

          }
          else {
            setmytrainingsupport("n/a");
            setmytrainingsupport_label("Autre");
          }

          if (mylocaltraining.duration_unit) {
            setmytrainingdurationunit(mylocaltraining.duration_unit);
            //console.log(" ### duration_unit = ", mylocaltraining.duration_unit);
          }
          else {
            setmytrainingdurationunit("heure");
          }



          if (mylocaltraining.certif) {
            setmycertif(mylocaltraining.certif);
            var result2 = certif.filter((local_mymanager) => local_mymanager.value === String((mylocaltraining.certif)));


            if (result2[0]) {
              setmycertif_label(result2[0].label);

            }
          }
          else {
            setmycertif("0");
            setmycertif_label("Non");
          }

          if (mylocaltraining.class_level && String(mylocaltraining.class_level).trim() !== "") {
            setp_class_level(mylocaltraining.class_level);
            setp_class_level_label(mylocaltraining.class_level_description);

          }
          else {
            setp_class_level("2");
            setp_class_level_label("Aucune certification");
          }


          if (mylocaltraining.price) {
            document.getElementsByName("price")[0].value = mylocaltraining.price;
            setp_detail_price(mylocaltraining.price);
          }
          else
            setp_detail_price("");


          if (mylocaltraining.domaine) {
            setmytrainingdomaine(mylocaltraining.domaine);
            //console.log(" ### mylocaltraining.domaine = ", mylocaltraining.domaine);
          }
          else
            setmytrainingdomaine("");


          if (mylocaltraining.metier) {
            setmytrainingmetier(mylocaltraining.metier);
            //console.log(" ### mylocaltraining.metier = ", mylocaltraining.metier);
          }
          else
            setmytrainingmetier("");


          if (mylocaltraining.categorie) {
            setmytrainingcategorie(mylocaltraining.categorie);
            //console.log(" ### mylocaltraining.metier = ", mylocaltraining.metier);
          }
          else
            setmytrainingcategorie("");



          if (mylocaltraining.duration) {
            // document.getElementsByName("duree")[0].value = mylocaltraining.duration;
            setp_detail_duree(mylocaltraining.duration);
          }
          else
            setp_detail_duree("");


          //document.getElementsByName("mots_cle")[0].value = "";
          if (mylocaltraining.mots_cle) {
            //document.getElementsByName("mots_cle")[0].value = mylocaltraining.mots_cle;
            setfield_mots_cle(mylocaltraining.mots_cle);
          }




          document.getElementsByName("note")[0].value = "";
          if (mylocaltraining.note) {
            //document.getElementsByName("note")[0].value = mylocaltraining.note;
            setp_detail_note(mylocaltraining.note);
          } else {
            setp_detail_note("");
          }

          //document.getElementsByName("plus_produit")[0].value = "";
          if (mylocaltraining.plus_produit) {
            //document.getElementsByName("plus_produit")[0].value = mylocaltraining.plus_produit;
            setfield_plus_produit(mylocaltraining.plus_produit);
          }

          document.getElementsByName("url")[0].value = "";
          if (mylocaltraining.url) {
            //document.getElementsByName("url")[0].value = mylocaltraining.url;
            setp_detail_url(mylocaltraining.note);
          } else {
            setp_detail_url("");
          }

          document.getElementsByName("lms_class_code")[0].value = "";

          if (mylocaltraining.lms_class_code) {
            //document.getElementsByName("lms_class_code")[0].value = mylocaltraining.lms_class_code;
            setp_detail_lms_class_code(mylocaltraining.lms_class_code);
          } else {
            setp_detail_lms_class_code("");
          }


          if (mylocaltraining.version) {
            //document.getElementsByName("lms_class_code")[0].value = mylocaltraining.lms_class_code;
            setp_detail_version_catalogue(mylocaltraining.version);
          } else {
            setp_detail_version_catalogue("");
          }


          if (mylocaltraining.recyclage_delai) {
            setp_detail_recyclage_delai(mylocaltraining.recyclage_delai);
          } else {
            setp_detail_recyclage_delai("0");
          }


          if (mylocaltraining.recyclage_alert) {
            setp_detail_recyclage_alert(mylocaltraining.recyclage_alert);
          } else {
            setp_detail_recyclage_alert("0");
          }


          document.getElementsByName("class_inscription_url")[0].value = "";
          if (mylocaltraining.class_inscription_url) {
            //document.getElementsByName("class_inscription_url")[0].value = mylocaltraining.class_inscription_url;
            setp_detail_class_inscription_url(mylocaltraining.note);
          } else {
            setp_detail_class_inscription_url("");
          }

          document.getElementsByName("class_banner_img_url")[0].value = "";
          if (mylocaltraining.img_banner_detail_class) {
            //document.getElementsByName("class_banner_img_url")[0].value = mylocaltraining.img_banner_detail_class;
            setp_detail_class_banner_img_url(mylocaltraining.note);
          } else {
            setp_detail_class_banner_img_url("");
          }

          //document.getElementsByName("zone_diffusion")[0].value = "";
          if (mylocaltraining.zone_diffusion_str) {
            //document.getElementsByName("zone_diffusion")[0].value = mylocaltraining.zone_diffusion_str;
            document.getElementsByName("zone_diffusion_v2")[0].value = mylocaltraining.zone_diffusion_str;
          }



          getClassImage(mylocaltraining._id);
          /*
               * Update 22/10/2023 :
               Gestion des champs spécifiques. ils commencent tous par 'my_'
               */

          //console.log(" #### props.rows_champs_specifics = ", props.rows_champs_specifics);
          for (let i = 0; i < rows_champs_specifics.length; i++) {

            var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
            var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
            var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
            var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;

            //console.log(" field_name = ", field_name);

            if (mylocaltraining.hasOwnProperty(field_name)) {
              var local_valeur = mylocaltraining[String(field_name)]

              if (document.getElementById(String(field_name)))
                document.getElementById(String(field_name)).value = local_valeur;

              var new_val = { 'field_name': field_name, 'field_value': local_valeur }
              var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);

              if (johnIndex > -1) {
                spec_field_updated_values[johnIndex] = new_val;

              } else {
                spec_field_updated_values.push(new_val);
              }

            } else {
              if (document.getElementById(String(field_name)))
                document.getElementById(String(field_name)).value = "";
            }
          }

          setspec_field_updated_values_hooks(spec_field_updated_values);
          /*
                             *  end Update 22/10/2023 :
                             */

        }

      }
      else {
        //console.log(" In test  res.data.status = "+res.data.status);
        //console.log(" In test  res.data.message = "+res.data.message);
        setmyApiResponse("False");
        //setmyApimyApiMessage("")
      }


    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( mysearchtext = ', error);
      setmyApiResponse("False");
      //setmyApimyApiMessage("")
    })
  }



  const [title_limite, settitle_limite] = useState(120);

  const editorRef_description = useRef(null);
  const [editorRef_description_limite, seteditorRef_description_limite] = useState(5000);


  const editorRef_objectif = useRef(null);
  const [editorRef_objectif_limite, seteditorRef_objectif] = useState(5000);

  const editorRef_programme = useRef(null);
  const [editorRef_programme_limite, seteditorRef_programme] = useState(5000);


  const editorRef_methode_pedago = useRef(null);
  const [editorRef_methode_pedago_limite, seteditorRef_methode_pedago] = useState(2000);

  const editorRef_handicap = useRef(null);
  const [editorRef_handicap_limite, seteditorRef_handicap_limite] = useState(2000);

  const editorRef_suivi_eval = useRef(null);
  const [editorRef_suivi_eval_limite, seteditorRef_suivi_eval_limite] = useState(2000);

  const editorRef_prerequis = useRef(null);
  const editorRef_pourqui = useRef(null);
  const editorRef_mots_cle = useRef(null);
  const editorRef_plus_produit = useRef(null);
  const editorRef_zone_diffusion = useRef(null);
  const editorRef_date_lieu = useRef(null);


  function RecordTraining(event) {


    var code = document.getElementsByName("external_code")[0].value;

    if (code.length < 1) {
      alert(" Le Champ 'code' est obligatoire")
      return;
    }

    var title = document.getElementsByName("title")[0].value;
    if (String(title).length > title_limite) {
      alert(" Champ 'Titre', limite depassée !")
      return;
    }

    if (title.length < 1) {
      alert(" Le Champ 'Titre' est obligatoire")
      return;
    }


    var presentiel = mypresence;
    var distentiel = mydistance;
    var domaine = mytrainingdomaine;

    var mypublier = mypublished;
    var niveau_ftion = p_class_level;
    var price = document.getElementsByName("price")[0].value;
    var duree = document.getElementsByName("duree")[0].value;
    var url = document.getElementsByName("url")[0].value;
    var lms_class_code = p_detail_lms_class_code;
    var class_inscription_url = document.getElementsByName("class_inscription_url")[0].value;

    var mymetier = mytrainingmetier;


    var mycategorie = mytrainingcategorie;
    var version = p_detail_version_catalogue;




    var mynote = document.getElementsByName("note")[0].value;
    var localmycpf = mycpf;
    var mycertif = mycertif;

    var trainingSupport = mytrainingsupport;
    var banniereImg = document.getElementsByName("class_banner_img_url")[0].value;


    var pourqui = "";
    if (editorRef_pourqui.current) {
      pourqui = editorRef_pourqui.current.getContent();
    }



    var objectif = "";
    if (editorRef_objectif.current) {
      objectif = editorRef_objectif.current.getContent();
      if (parse(objectif.replace(/(<([^>]+)>)/ig, '')).length > editorRef_objectif_limite) {
        alert(" Champ 'Objectif', limite depassée !")
        return;
      }
    }

    if (objectif.length < 1) {
      alert(" Le Champ 'Objectif' est obligatoire")
      return;
    }

    var programme = "";
    if (editorRef_programme.current) {
      programme = editorRef_programme.current.getContent();

      if (parse(programme.replace(/(<([^>]+)>)/ig, '')).length > editorRef_programme_limite) {
        alert(" Champ 'Programme', limite depassée !")
        return;
      }
    }



    var methode_pedago = "";
    if (editorRef_methode_pedago.current) {
      methode_pedago = editorRef_methode_pedago.current.getContent();

      if (parse(methode_pedago.replace(/(<([^>]+)>)/ig, '')).length > editorRef_methode_pedago_limite) {
        alert(" Champ 'Méthode pédagogique', limite depassée !")
        return;
      }
    }


    var handicap = "";
    if (editorRef_handicap.current) {
      handicap = editorRef_handicap.current.getContent();

      if (parse(handicap.replace(/(<([^>]+)>)/ig, '')).length > editorRef_handicap_limite) {
        alert(" Champ 'Handicape', limite depassée !")
        return;
      }
    }



    var suivi_eval = "";
    if (editorRef_suivi_eval.current) {
      suivi_eval = editorRef_suivi_eval.current.getContent();

      if (parse(suivi_eval.replace(/(<([^>]+)>)/ig, '')).length > seteditorRef_suivi_eval_limite) {
        alert(" Champ 'Suivi et Evaluation', limite depassée !")
        return;
      }
    }





    var prerequis = "";
    if (editorRef_prerequis.current) {
      prerequis = editorRef_prerequis.current.getContent();
    }

    var description = "";
    if (editorRef_description.current) {
      description = editorRef_description.current.getContent();

      if (parse(description.replace(/(<([^>]+)>)/ig, '')).length > editorRef_description_limite) {
        alert(" Champ 'Description', limite depassée !")
        return;
      }
    }

    if (description.length < 1) {
      alert(" Le Champ 'Description' est obligatoire")
      return;
    }

    var mots_cle = "";
    if (editorRef_mots_cle.current) {
      mots_cle = editorRef_mots_cle.current.getContent();
    }

    var plus_produit = "";
    if (editorRef_plus_produit.current) {
      plus_produit = editorRef_plus_produit.current.getContent();
    }



    // Verification si il y a plus de 3 mot clés separés par des ";"
    mots_cle = mots_cle.replace(/(<([^>]+)>)/ig, '');
    mots_cle = mots_cle.replaceAll("&nbsp;", '');
    mots_cle = mots_cle.replaceAll(",", ";");


    var words = mots_cle.split(';');

    var traited_list_mot_cle = []
    for (let i = 0; i < words.length; i++) {
      //console.log(" ### alanlyse du mot  "+String(words[i]))
      if (words[i] && String(words[i]).length > 2 && String(words[i]).length < 50)
        traited_list_mot_cle.push(String(words[i]));
      else if (words[i] && (String(words[i]).length < 2 || String(words[i]).length > 50)) {
        alert(" Chaque mot clé doit faire entre 2 et 50 caractères. Le mot '" + String(words[i]) + "' est incorrecte");
        return;
      }

    }

    //console.log(" ## traited_list_mot_cle = ", traited_list_mot_cle);

    if (traited_list_mot_cle.length > 3) {
      alert(" Il a plus de 3 mots clés. le max autorisé est 3");
      return;
    }

    var list_mot_cle_string = traited_list_mot_cle.toString().replaceAll(",", ";");

    //console.log(" ## list_mot_cle_string = ", list_mot_cle_string);

    //return;


    var myurl = "";

    if (isupdateclass) {
      // Il s'agit d'une mise à jour
      myurl = process.env.REACT_APP_API_URL + "myclass/api/update_class/";
      //alert("Il s'agit d'une mise à jour");

    } else {
      // Il s'agit d'une creation 
      myurl = process.env.REACT_APP_API_URL + "myclass/api/create_class/";
      //alert(" Il s'agit d'une creation ");
    }


    //alert(" OK Enreg code = "+code+" distentiel = "+distentiel+" duree = "+duree);
    const formData = new FormData();

    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');

    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);

    if (props.mytrainingclass['class_id'])
      formData.append("class_id", props.mytrainingclass['class_id']);
    else
      formData.append("class_id", selected_class_id);




    formData.append('external_code', code);
    formData.append('title', title);

    formData.append('objectif', objectif);
    formData.append('programme', programme);

    formData.append('methode_pedagogique', methode_pedago);
    formData.append('condition_handicape', handicap);

    formData.append('suivi_eval', suivi_eval);

    formData.append('prerequis', prerequis);

    formData.append('description', description);
    formData.append('formateur_id', p_formateur_id);
    formData.append('presentiel', presentiel);
    formData.append('domaine', domaine);
    formData.append('distantiel', distentiel);
    formData.append('class_level', niveau_ftion);
    formData.append('price', price);
    formData.append('duration', duree);
    formData.append('duration_unit', mytrainingdurationunit);
    formData.append('url', url);
    formData.append('lms_class_code', lms_class_code);
    formData.append('class_inscription_url', class_inscription_url);
    formData.append('mots_cle', list_mot_cle_string);
    formData.append('plus_produit', plus_produit);
    formData.append('internal_url', displayedinternalurl);
    formData.append('zone_diffusion', "");
    formData.append('metier', mymetier);
    formData.append('categorie', mycategorie);
    formData.append('version', version);

    formData.append('recyclage_delai', p_detail_recyclage_delai);
    formData.append('recyclage_periodicite', p_detail_recyclage_periodicite);
    formData.append('recyclage_alert', p_detail_recyclage_alert);


    formData.append('note', mynote);

    formData.append('published', mypublier);
    formData.append('cpf', localmycpf);

    formData.append('certif', mycertif);

    formData.append('pourqui', pourqui);
    formData.append('support', trainingSupport);
    formData.append('img_banner_detail_class', banniereImg);
    formData.append('img_url', banniereImg);


    /*
             Update du 22/10/2023 - Gestion des champs spécifiques ajoutés par le partenaire
           */
    for (let i = 0; i < spec_field_updated_values_hooks.length; i++) {
      var local_value = String(spec_field_updated_values_hooks[i].field_value);

      if (String(spec_field_updated_values_hooks[i].field_type) === "float") {

        local_value = local_value.replaceAll(",", ".");

        if (isNaN(local_value)) {
          alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " doit être numérique.")
          return;
        }

      }
      else if (String(spec_field_updated_values_hooks[i].field_type) === "string") {

      }
      if (String(spec_field_updated_values_hooks[i].is_mandatory) === "1") {
        if (String(local_value).trim().length <= 0) {
          alert("Le champ " + spec_field_updated_values_hooks[i].field_label + " est obligatoire.")
          return;
        }

      }

      formData.append(String(spec_field_updated_values_hooks[i].field_name), local_value);
    }

    //console.log(" formData = "+formData);
    setLoading(true);

    fetch(
      myurl,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result['message'], "result['status'] = ", result['status'], "result['class_id'] = ", result['class_id']);
        setLoading(false);
        setimportmessage(result['message']);
        setmyApiResponse(result['status']);
        setdatamodification("0");

        if (String(result['status']) === "true") {

          var myclass_id = String(result['class_id']);


          if (String(userimgclassprofilchanged) === "1")
            sendClassImage(myclass_id);
        }

        //alert(result['status'] + "  -- " + myApiResponse + ' mess = ' + result['message']);

        if (String(result['status']) === "true") {
          alert(" La formation a été correctement ajoutée / mise à jour. Vous allez être redirigé vers la liste des formations.");
          history.push({
            pathname: "/Partner/",
            state: {
              current_pack: props.currentpack, local_sub_menu: 'affichage', price: "",
              period: "", new_pack: ""
            }
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        setmyApiResponse("false");
      });
  };

  function DataUpdated() {
    setdatamodification("1");
    setimportmessage();

  }

  const [mytrainingcategorie, setmytrainingcategorie] = useState("");

  const [New_Get_List_Categorie_result, setNew_Get_List_Categorie_result] = useState([]);

  const [Get_List_Categorie_api, setGet_List_Categorie_api] = useState();
  const [Get_List_Categorie_message, setGet_List_Categorie_message] = useState();
  const [Get_List_Categorie_result, setGet_List_Categorie_result] = useState();
  function Get_List_Categorie() {
    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Categorie/";
    setLoading(true);
    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);
        // console.log(' IN Get_List_Categorie Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Categorie_api("true");
          setGet_List_Categorie_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {


            //---
            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_nom = JSON.parse(x).nom;
            var local_code = JSON.parse(x).code;
            var local_class_domaine_id = JSON.parse(x).class_domaine_id;
            var local_class_domaine_nom = JSON.parse(x).class_domaine_nom;


            //---
            var node = {
              "id": localid,
              "_id": local_id,
              "label": local_nom,
              "nom": local_nom,
              "code": local_code,
              "class_domaine_id": local_class_domaine_id,
              "class_domaine_nom": local_class_domaine_nom,

            };
            new_data2.push(node);
          });

          //---
          var node = {
            "id": "",
            "_id": "",
            "label": "",
            "nom": "",
            "code": "",
            "class_domaine_id": "",
            "class_domaine_nom": "",

          };
          new_data2.push(node);

          if (new_data2.length > 0) {
            setNew_Get_List_Categorie_result(new_data2);
            //console.log(" ### new_data2 ", new_data2);
            //console.log(" ### setNew_Get_List_Categorie_result ", New_Get_List_Categorie_result);
          } else {
            setNew_Get_List_Categorie_result([]);
          }


        }
        else {
          setGet_List_Categorie_api("false");
          setGet_List_Categorie_message(data['message']);
        }

      }).catch((error) => {
        setLoading(false);
        console.error('Error Get_List_Categorie :', error);
        setGet_List_Categorie_api("false");
      });

  }


  const [mytrainingmetier, setmytrainingmetier] = useState("");

  const [New_Get_List_Metiers_result, setNew_Get_List_Metiers_result] = useState([]);

  const [Get_List_Metiers_api, setGet_List_Metiers_api] = useState();
  const [Get_List_Metiers_message, setGet_List_Metiers_message] = useState();
  const [Get_List_Metiers_result, setGet_List_Metiers_result] = useState();
  function Get_List_Metiers() {
    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_class_metier/";
    setLoading(true);
    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);
        // console.log(' IN Get_List_Metiers Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Metiers_api("true");
          setGet_List_Metiers_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {


            //---
            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_nom = JSON.parse(x).nom;
            var local_code = JSON.parse(x).code;
            var local_class_domaine_id = JSON.parse(x).class_domaine_id;
            var local_class_domaine_nom = JSON.parse(x).class_domaine_nom;


            //---
            var node = {
              "id": localid,
              "_id": local_id,
              "label": local_nom,
              "nom": local_nom,
              "code": local_code,
              "class_domaine_id": local_class_domaine_id,
              "class_domaine_nom": local_class_domaine_nom,

            };
            new_data2.push(node);
          });

          //---
          var node = {
            "id": "",
            "_id": "",
            "label": "",
            "nom": "",
            "code": "",
            "class_domaine_id": "",
            "class_domaine_nom": "",

          };
          new_data2.push(node);

          if (new_data2.length > 0) {
            setNew_Get_List_Metiers_result(new_data2);
            //console.log(" ### new_data2 ", new_data2);
            //console.log(" ### setNew_Get_List_Metiers_result ", New_Get_List_Metiers_result);
          } else {
            setNew_Get_List_Metiers_result([]);
          }


        }
        else {
          setGet_List_Metiers_api("false");
          setGet_List_Metiers_message(data['message']);
        }

      }).catch((error) => {
        setLoading(false);
        console.error('Error Get_List_Metiers :', error);
        setGet_List_Metiers_api("false");
      });

  }


  const handleChangeMetierTraining = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmytrainingmetier(value);
    setdatamodification("1");

  }


  const [mytrainingdomaine, setmytrainingdomaine] = useState("");

  const [New_Get_List_Domaines_result, setNew_Get_List_Domaines_result] = useState([]);

  const [Get_List_Domaines_api, setGet_List_Domaines_api] = useState();
  const [Get_List_Domaines_message, setGet_List_Domaines_message] = useState();
  const [Get_List_Domaines_result, setGet_List_Domaines_result] = useState();
  function Get_List_Domaines() {
    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_domaine/";

    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        //console.log(' IN Get_List_Domaines Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Domaines_api("true");
          setGet_List_Domaines_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {

            //---
            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_code = JSON.parse(x).code;
            var local_nom = JSON.parse(x).nom;


            //---
            var node = {
              "id": localid,
              "_id": local_id,
              "nom": local_nom,
              "code": local_code,
              "label": local_nom,


            };
            new_data2.push(node);
          });

          var node = {
            "id": "",
            "_id": "",
            "nom": "",
            "code": "",
            "label": "",


          };
          new_data2.push(node);

          if (new_data2.length > 0) {
            setNew_Get_List_Domaines_result(new_data2);

            // console.log(" ## new_data2 =  ", new_data2);
            // console.log(" ## New_Get_List_Domaines_result =  ", New_Get_List_Domaines_result);
          } else {
            setNew_Get_List_Domaines_result([])
          }
        }
        else {
          setGet_List_Domaines_api("false");
          setGet_List_Domaines_message(data['message']);
        }

      }).catch((error) => {
        console.error('Error Get_List_Domaines :', error);
        setGet_List_Domaines_api("false");
      });
  }

  const handleChangeDomaineTraining = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setmytrainingdomaine(value);
    setdatamodification("1");

  }


  function alertactivecompte() {
    //alert(" ispending = ===== " + props.ispending);
    if (String(props.ispending) === String("1")) {
      alert(" Vous devez ajouter un mode de Paiement pour acceder à cette fonctionnalité");
      return;
    }
  }

  const [isimgclassSelected, setisimgclassSelected] = useState(false);
  const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);
  const [userimgclassprofil, setuserimgclassprofil] = useState();
  const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

  const imglogoclasschangeHandler = (event) => {


    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    //console.log(event.target.files);

    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;



    //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
    if (file_size > 1000000) {
      alert("L'image ne doit pas dépasser un 1 Méga octets");
      return;
    }
    setisimgclassSelected(event.target.files[0]);
    setisimgclassSelectedfile(true);

    setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
    setuserimgclassprofilchanged("1");
  };


  const [isimgclassdeleted, setisimgclassdeleted] = useState("");
  const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");

  function removeRecodedClassImage() {
    var class_external_code = "";
    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("class_external_code", class_external_code);
    form.append("related_collection", "myclass");
    form.append("related_collection_recid", selected_class_id);
    form.append("type", "partner");
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/DeleteRecodedClassImage/",
      {
        method: 'POST',
        body: form,
      }
    ).then((response) => response.json())
      .then((result) => {
        setLoading(false);

        //console.log(' removeRecodedClassImage : status:', result['status']);
        //console.log('removeRecodedClassImage : Success:', result['message']);

        setisimgclassSelected(false);

        if (String(result['status']) === String("true")) {
          setisimgclassdeleted("1");
          setuserimgclassprofil("");
        }
        else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
          return;
        }

        else {
          setisimgclassdeleted("0");
          setisimgclassdeleted_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassdeleted("0");
        setisimgclassdeleted_message(result['message']);
      });

  }

  const [isimgclassSaved, setisimgclassSaved] = useState("");
  const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
  function sendClassImage(local_myclass_id) {

    var class_external_code = "";

    if (document.getElementsByName("external_code")[0].value && document.getElementsByName("external_code")[0].value.length > 3) {
      class_external_code = document.getElementsByName("external_code")[0].value;
    } else {
      alert(" Vous devez saisir le code externe de la formation avant d'enregistrer une image. Le code externe prend au moins 3 caractères");
      return;
    }


    var formData = new FormData();
    formData.append('File', isimgclassSelected);
    const stored_cookie = getCookie('tokenmysypart');
    formData.append("token", stored_cookie);
    formData.append("class_external_code", class_external_code);
    formData.append("related_collection", "myclass");
    formData.append("related_collection_recid", local_myclass_id);
    formData.append("type", "myclass");

    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/recordClassImage/",
      {
        method: 'POST',
        body: formData,
      }
    ).then((response) => response.json())
      .then((result) => {
        setLoading(false);
        //console.log('status:', result['status']);
        //console.log('Success:', result['message']);
        setisimgclassSelected(false);

        if (String(result['status']) == String("true")) {
          setisimgclassSaved("1");
        }
        else {
          setisimgclassSaved("0");
          setisimgclassSaved_message(result['message']);
          setisimgclassSelected(true);
        }

      })
      .catch((error) => {
        setLoading(false);
        console.error('Error:');
        setisimgclassSelected(true);
        setisimgclassSaved("0");
        setisimgclassSaved_message(result['message']);
      });
  };


  const [myclassimage, setmyclassimage] = useState();
  const [isclassimage, setisclassimage] = useState();

  function getClassImage(local_class_id) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedClassImage/";

    var class_external_code = props.mytrainingclass['class_external_code'];

    if (class_external_code.length < 3) {
      //console.log("Le code externe prend au moins 3 caractères");
      return;
    }


    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("type", "partner");
    form.append("related_collection", "myclass");
    form.append("related_collection_recid", local_class_id);
    form.append("class_external_code", class_external_code);

    setLoading(true);
    axios.post(myurl, form).then(res => {

      if (res.data.status != "False") {
        setLoading(false);
        //console.log(" getClassImage : In test  res.data.status = " + res.data.status);
        //console.log(" getClassImage: res.data.message.img = " + res.data.message);

        if (res.data.message && res.data.message.img && res.data.message.img.length > 0) {
          setisclassimage("True");

          setisclassimage(res.data.message.img);
          var ch_img = "data:image/png;base64," + res.data.message.img;
          setuserimgclassprofil(ch_img);

        }
        else {
          setisclassimage("False");
          setuserimgclassprofil();
        }

      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setisclassimage("False");
        setuserimgclassprofil();
        //alert("4");
      }
    }).catch((error) => {
      setLoading(false);
      console.warn('getClassImage ee: Not good man :( getClassImage = ');
      setisclassimage("False");
      setuserimgclassprofil("");
    })

  }


  const [activepack, setactivepack] = useState();
  function ActivePackStandard() {

    history.push({
      pathname: "/Partner/",
      state: {
        current_pack: props.currentpack, local_sub_menu: 'checkout', price: "50",
        period: "mensuelle", new_pack: "standard"
      }
    });
    window.location.reload();
  }

  function ActivePackGold() {

    history.push({
      pathname: "/Partner/",
      state: {
        current_pack: props.currentpack, local_sub_menu: 'checkout', price: "50",
        period: "mensuelle", new_pack: "gold"
      }
    });
    window.location.reload();
  }



  function change_zone_diffsion() {
    setfield_zone_diffusion(document.getElementById("zone_diffusion_v2").value)
  }


  function UndoModif() {
    setfield_description("");
    setfield_objectif("");
    setfield_programme("");

    setfield_methode_pedago("");
    setfield_handicap("");


    setfield_prerequis("");
    setfield_mots_cle("");
    setfield_plus_produit("");
    setfield_datelieu("");
    setfield_zone_diffusion("");
    seteditor_tochange("");


    fillfield();
    if (props.mytrainingclass['class_external_code']) {

      //console.log("props.mytrainingclass['plus_produit'] = " + props.mytrainingclass['plus_produit']);
      setplus_produit_enabled(props.mytrainingclass['plus_produit']);
      setmots_cle_enabled(props.mytrainingclass['mots_cle']);
      setzone_diffusion_enabled(props.mytrainingclass['zone_diffusion']);
    }

    setdatamodification("0");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  }

  const [editor_tochange, seteditor_tochange] = useState("");
  function editor_keyup() {
    seteditor_tochange("1");
  }

  const cpf = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];


  const certif = [
    {
      value: '1',
      label: 'Oui',
    },
    {
      value: '0',
      label: 'Non',
    },

  ];

  function retourList() {
    props.closeTrainingForm();

  }

  const [isLoading, setLoading] = useState();

  // Gestion champs specifiques 

  /*
  *** ALGORITHME
  1 - Récuperer la liste des champs specifiques de la collection concernée - Array : list_nom_champs_spec
  2 - Récupérer les caracteristiques de champs champs spécifique : "id, nom, label, type valeur, etc"
     - avec un champ 'value' à vide. Mettre dans le tableau JSON, 'spec_champs_valeur'
  
  3 - Créer une nouvelle table qui permettra de gerer les hook : const [rows_champs_specifics, setrows_champs_specifics] = useState([]);
  4 - Afficher en html les elements 'rows_champs_specifics'
  5 - Gerer la modification avec la fonction 'change_champs_spec_handle'
  6 - On recuperer les valeur lors du click d'enregistrement.
  
  */


  const list_nom_champs_spec = []
  const [spec_champs_valeur, setspec_champs_valeur] = useState([]);

  //const spec_champs_valeur = ""

  const [Get_Partner_Object_Specific_Fields_api, setGet_Partner_Object_Specific_Fields_api] = useState();
  const [Get_Partner_Object_Specific_Fields_message, setGet_Partner_Object_Specific_Fields_message] = useState();
  const [Get_Partner_Object_Specific_Fields_result, setGet_Partner_Object_Specific_Fields_result] = useState();
  function Get_Partner_Object_Specific_Fields(local_collection_obj_metier) {

    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("object_name", local_collection_obj_metier);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Object_Specific_Valide_Displayed_Fields/";
    setLoading(true);
    axios.post(myurl, form).then(res => {

      if (String(res.data.status) === String("true")) {
        setLoading(false);
        //console.log(" In Get_Partner_Object_Specific_Fields  res.data.status = " + res.data.status);
        //console.log(" In Get_Partner_Object_Specific_Fields  res.data.message r_class = " + res.data.message);

        setGet_Partner_Object_Specific_Fields_api("true");
        setGet_Partner_Object_Specific_Fields_result(res.data.message);


        setrows_champs_specifics(res.data.message);


      }
      else {
        setGet_Partner_Object_Specific_Fields_api("false");
        setGet_Partner_Object_Specific_Fields_message(res.data.message);
        alert(res.data.message)
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Get_Partner_Object_Specific_Fields = ', error);
      setGet_Partner_Object_Specific_Fields_api("false");
      alert(" Impossible de recuperer la liste des collection et champs personnalisables");
      //setmyApimyApiMessage("")
    })
  }

  const [New_Get_List_Niveau_Formation_result, setNew_Get_List_Niveau_Formation_result] = useState([]);

  const [Get_List_Niveau_Formation_api, setGet_List_Niveau_Formation_api] = useState();
  const [Get_List_Niveau_Formation_message, setGet_List_Niveau_Formation_message] = useState();
  const [Get_List_Niveau_Formation_result, setGet_List_Niveau_Formation_result] = useState([]);
  function Get_List_Niveau_Formation() {
    var form = new FormData();
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Niveau_Formation/";
    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    setLoading(true);
    fetch(myurl,
      {
        method: 'POST',
        body: form,
      })
      .then((data) => data.json())
      .then((data) => {
        setLoading(false);
        //console.log(' IN Get_List_Niveau_Formation Success:', data['message'], "data['status'] = ", data['status']);

        if (String(data['status']) === String("true")) {
          setGet_List_Niveau_Formation_api("true");
          setGet_List_Niveau_Formation_result(data['message']);

          var new_data2 = [];
          const new_data = data['message'].map((x) => {

            //---
            var localid = JSON.parse(x).id;
            var local_id = JSON.parse(x)._id;
            var local_code = JSON.parse(x).code;
            var local_description = JSON.parse(x).description;


            //---
            var node = {
              "_id": local_id,
              "id": localid,
              "label": local_description,
              "description": local_description,
              "code": local_code,

            };
            new_data2.push(node);
          });

          if (new_data2.length > 0)
            setNew_Get_List_Niveau_Formation_result(new_data2);




        }
        else {
          setGet_List_Niveau_Formation_api("false");
          setGet_List_Niveau_Formation_message(data['message']);
          alert(data['message'])
        }

      }).catch((error) => {
        setLoading(false);
        console.error('Error Get_List_Niveau_Formation :', error);
        setGet_List_Niveau_Formation_api("false");
        alert(" Impossible de récupérer la liste des niveaux de formation");

      });

  }



  //const [rows_champs_specifics, setrows_champs_specifics] = useState([]);

  // Cas spécifique de cette page.
  const [rows_champs_specifics, setrows_champs_specifics] = useState(props.rows_champs_specifics);

  const [display_champs_specifics, setdisplay_champs_specifics] = useState("0");

  function afficher_champs_spec() {

    setdisplay_champs_specifics("1");
    //setrows_champs_specifics(spec_champs_valeur);

  }

  function findIndexByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {

      if (data[i][key] == value) {
        return i;
      }
    }
    return -1;
  }


  function findValueByProperty(data, key, value) {
    for (var i = 0; i < data.length; i++) {
      if (data[i][key] == value) {

        var tmp_val = JSON.parse(data[i]);

        return tmp_val.field_value

      }
    }
    return "";
  }


  const spec_field_updated_values = []
  const [spec_field_updated_values_hooks, setspec_field_updated_values_hooks] = useState([]);


  function change_champs_spec_handle() {

    for (let i = 0; i < rows_champs_specifics.length; i++) {

      var field_name = JSON.parse(rows_champs_specifics[i]).field_name;
      var field_type = JSON.parse(rows_champs_specifics[i]).field_type;
      var field_label = JSON.parse(rows_champs_specifics[i]).field_label;
      var is_mandatory = JSON.parse(rows_champs_specifics[i]).is_mandatory;


      if (document.getElementsByName(String(field_name))[0]) {

        var field_value = document.getElementsByName(String(field_name))[0].value;
        var new_val = { 'field_name': field_name, 'field_value': field_value, 'field_type': field_type, 'field_label': field_label, 'is_mandatory': is_mandatory }
        var johnIndex = findIndexByProperty(spec_field_updated_values, 'field_name', field_name);
        if (johnIndex > -1) {
          spec_field_updated_values[johnIndex] = new_val;

        } else {
          spec_field_updated_values.push(new_val);
        }
      }

    }

    //setrows_champs_specifics(spec_champs_valeur);

    //console.log(" OUTT spec_field_updated_values = ", spec_field_updated_values);
    setspec_field_updated_values_hooks(spec_field_updated_values)
  }
  // -- end champs specifiques 


  const [p_formateur_id, setp_formateur_id] = useState("");
  const [p_formateur_label, setp_formateur_label] = useState("-");

  const [p_class_level, setp_class_level] = useState("2");
  const [p_class_level_label, setp_class_level_label] = useState("Aucune certification");



  // -- Gestion pièces jointes 

  const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
  const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
  const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();

  const Download_one_attached_document = (event) => {
    const stored_cookie = getCookie('tokenmysypart');


    var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
    var token = stored_cookie;
    var file_name = event.target.id;


    var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;

    setLoading(true);
    axios.get(url, { responseType: 'blob', },)
      .then((res) => {
        setLoading(false);
        fileDownload(res.data, nom_fiche_detaillee);
        setDownload_one_attached_document_api("true");
      }).catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        setDownload_one_attached_document_api("false");

      });
  }


  const [tab_convention_pieces_jointes_result, settab_convention_pieces_jointes_result] = useState([]);

  const [file_1_name, setfile_1_name] = useState();

  const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
  const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
  const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
  const sessions_file_change_1 = event => {

    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;




    console.log("file_size 2 =  ", file_size, " file_type = ", file_type);
    if (file_size > 10000000) {
      alert("Le fichier ne doit pas depasser un 1 Méga octets");
      console.log("Le fichier ne doit pas depasser un 1 Méga octets");
      return;
    }

    setfile_1_name(event.target.files[0]);

    const formData = new FormData();
    formData.append('File', fileUploaded);
    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
    const stored_cookie = getCookie('tokenmysypart');

    //formData.append("token", stored_cookie);
    //formData.append("class_internal_url", internal_url);
    //console.log("token = " + stored_cookie);

    return;


  };



  const [sessions_file_change_1_convention_api, setsessions_file_change_1_convention_api] = useState();
  const [sessions_file_change_1_convention_result, setsessions_file_change_1_convention_result] = useState();
  const [sessions_file_change_1_convention_message, setsessions_file_change_1_convention_message] = useState();
  const sessions_file_change_1_convention = event => {


    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;


    if (file_type !== "application/pdf") {
      alert("Le fichier n'est pas de type PDF");
      return;
    }



    if (file_size > 10000000) {
      alert("Le fichier ne doit pas depasser un 1 Méga octets");
      console.log("Le fichier ne doit pas depasser un 1 Méga octets");
      return;
    }

    //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


    var new_tmp = [];

    if (tab_convention_pieces_jointes_result && tab_convention_pieces_jointes_result.length > 0) {

      tab_convention_pieces_jointes_result.map((x) => {
        new_tmp.push(x);

      });

    }

    new_tmp.push(event.target.files[0])

    settab_convention_pieces_jointes_result(new_tmp);

    console.log(" tab_convention_pieces_jointes_result = ", tab_convention_pieces_jointes_result)


    return;


  };

  const [file_2_name, setfile_2_name] = useState();

  const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
  const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
  const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
  const sessions_file_change_2 = event => {

    const fileUploaded = event.target.files[0];
    let file_size = event.target.files[0].size;
    let file_type = event.target.files[0].type;


    console.log("file_size 1 =  ", file_size, " file_type = ", file_type);
    if (file_size > 10000000) {
      alert("Le fichier ne doit pas depasser un 1 Méga octets");
      console.log("Le fichier ne doit pas depasser un 1 Méga octets");
      return;
    }
    setfile_2_name(event.target.files[0]);

    const formData = new FormData();
    formData.append('File', fileUploaded);
    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
    const stored_cookie = getCookie('tokenmysypart');

    //formData.append("token", stored_cookie);
    //formData.append("class_internal_url", internal_url);
    //console.log("token = " + stored_cookie);

    return;


  };


  const [add_one_eu_to_class, setadd_one_eu_to_class] = useState("");

  const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
  const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
  const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();

  function Record_All_PJ() {

    if (!p_detail_one_file_to_download_type_name) {
      alert(" Vous devez donner un nom à la pièce jointe");
      return;
    }

    if (file_1_name && file_1_name.name) {
      //console.log(" ### Traitement de  : ", file_1_name.name);

      const formData = new FormData();
      formData.append('File', file_1_name);
      //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
      const stored_cookie = getCookie('tokenmysypart');

      formData.append("token", stored_cookie);
      formData.append("file_business_object", p_detail_one_file_to_download_type_name);
      formData.append("file_name", file_1_name.name);
      formData.append("status", "0");
      formData.append("object_owner_collection", "myclass");
      formData.append("object_owner_id", selected_class_id);

      //formData.append("class_internal_url", internal_url);
      //console.log("token = " + stored_cookie);

      setLoading(true);
      fetch(
        process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
        {
          method: 'POST',
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((result) => {

          setLoading(false);
          //console.log(" ## result['status'] = ", result['status'])
          if (String(result['status']) === String("true")) {
            //console.log('Success:', result['message']);
            setRecord_All_PJ_result(result['message']);
            setRecord_All_PJ_api("true");

            Get_List_Of_All_PJ(selected_class_id);
            setfile_1_name();
            setp_detail_one_file_to_download_type_name();
            //GetCurrentClass_trainingsession();
            alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

          }
          else {
            setRecord_All_PJ_message(result['message']);
            setRecord_All_PJ_api("false");
            alert(result['message']);
          }

        })
        .catch((error) => {
          setLoading(false);
          console.error(' Record_All_PJ Error:', error);
          setRecord_All_PJ_api("false");
          alert(" Impossible d'ajouter la pièce jointe")
        });

    }


  }


  const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
  const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
  const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();

  const Delete_one_attached_document = (event) => {
    const stored_cookie = getCookie('tokenmysypart');

    var formData = new FormData();
    var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
    var file_name = event.target.id;


    formData.append("token", stored_cookie);
    formData.append("file_name", file_name);

    var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";

    setLoading(true);
    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);
        //console.log(" ## result['status'] = ", result['status'])
        if (String(result['status']) === String("true")) {
          //console.log('Success:', result['message']);
          setDelete_one_attached_document_result(result['message']);
          setDelete_one_attached_document_api("true");
          Get_List_Of_All_PJ(selected_class_id);
          alert(result['message'])

        }
        else {
          setDelete_one_attached_document_message(result['message']);
          setDelete_one_attached_document_api("false");
          alert(result['message'])
        }

      })
      .catch((error) => {
        setLoading(false);
        console.error(' Record_All_PJ Error:', error);
        setDelete_one_attached_document_api("false");
        alert(" Impossible de supprimer le document")

      });


  }


  const Delete_one_attached_document_convention = (event) => {
    var file_name = event.target.id;

  }

  const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
  const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
  const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();

  function Get_List_Of_All_PJ(local_employee_id) {

    const formData = new FormData();

    //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
    const stored_cookie = getCookie('tokenmysypart');

    formData.append("token", stored_cookie);
    formData.append("object_owner_collection", "myclass");
    formData.append("object_owner_id", local_employee_id);


    //formData.append("class_internal_url", internal_url);
    //console.log("token = " + stored_cookie);
    setLoading(true);

    fetch(
      process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setLoading(false);

        //console.log(" ## result['status'] = ", result['status'])
        if (String(result['status']) === String("true")) {
          //console.log('Message :', result['message']);
          setGet_List_Of_All_PJ_result(result['message']);
          setGet_List_Of_All_PJ_api("true");

        }
        else {
          setGet_List_Of_All_PJ_message(result['message']);
          setGet_List_Of_All_PJ_api("false");
        }

      })
      .catch((error) => {
        setLoading(false);
        console.error(' Get_List_Of_All_PJ Error:', error);
        setGet_List_Of_All_PJ_api("false");
      });

  }



  function Delete_file_1_name() {
    setfile_1_name();
  }

  function Delete_file_2_name() {
    setfile_2_name();
  }

  const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

  // -- end gestion pièces jointes

  const [submenu, setsubmenu] = useState("");

  function submenu_color_management(current_menu) {
    const list_sous_menu = ["detail_formation", "unite_enseignement", "admission", "piece_jointe", "note_evaluation", "historique"]

    for (let i = 0; i < list_sous_menu.length; i++) {

      var sous_menu = list_sous_menu[i];
      if (String(sous_menu) !== current_menu) {
        if (document.getElementById(sous_menu)) {
          document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
          document.getElementById(sous_menu).style.color = "black";
          document.getElementById(sous_menu).style.fontWeight = "normal";
        }
      }

    }

    if (document.getElementById(current_menu)) {
      document.getElementById(current_menu).style.backgroundColor = "#104277";
      document.getElementById(current_menu).style.color = "white";
      document.getElementById(current_menu).style.fontWeight = "bold";
    }

  }


  function submenu_detail_formation() {
    setsubmenu("detail_formation");
    submenu_color_management("detail_formation");
  }

  function submenu_unite_enseignement() {
    setsubmenu("unite_enseignement");
    submenu_color_management("unite_enseignement");
  }

  function submenu_admission() {
    setsubmenu("admission");
    submenu_color_management("admission");
  }

  function submenu_note_evaluation() {
    setsubmenu("note_evaluation");
    submenu_color_management("note_evaluation");
    Getall_Class_List_Evalution();
  }


  function submenu_piece_jointe() {
    setsubmenu("piece_jointe");
    submenu_color_management("piece_jointe");
  }


  function submenu_statistiques() {
    setsubmenu("statistiques");
    submenu_color_management("statistiques");
    Getall_Employee_Statistic_Question_List();
  }

  function submenu_historique() {
    setsubmenu("historique");
    submenu_color_management("historique");

  }


  const myRef = useRef(null)

  const [p_detail_external_code, setp_detail_external_code] = useState("");
  const [p_detail_title, setp_detail_title] = useState("");
  const [p_detail_duree, setp_detail_duree] = useState("");
  const [p_detail_url, setp_detail_url] = useState("");
  const [p_detail_lms_class_code, setp_detail_lms_class_code] = useState("");

  const [p_detail_version_catalogue, setp_detail_version_catalogue] = useState("");

  const [p_detail_recyclage_delai, setp_detail_recyclage_delai] = useState("0");
  const [p_detail_recyclage_periodicite, setp_detail_recyclage_periodicite] = useState("mois");
  const [p_detail_recyclage_alert, setp_detail_recyclage_alert] = useState("0");

  const [p_detail_class_inscription_url, setp_detail_class_inscription_url] = useState("");
  const [p_detail_class_banner_img_url, setp_detail_class_banner_img_url] = useState("");
  const [p_detail_note, setp_detail_note] = useState("");
  const [p_detail_price, setp_detail_price] = useState("");

  const [selected_ue_id, setselected_ue_id] = useState("");
  const [selected_ue_credit, setselected_ue_credit] = useState("");
  const [selected_ue_is_noted, setselected_ue_is_noted] = useState("");
  const [selected_ue_pres_dist_hyp, setselected_ue_pres_dist_hyp] = useState("");

  const New_Option_Is_Noted = [
    { "id": "1", "label": "Oui", "value": "1" },
    { "id": "0", "label": "Non", "value": "0" },
    { "id": "", "label": "", "value": "" },
  ]


  const New_Option_pres_dist_hyp = [
    { "id": "1", "label": "Distanciel", "value": "1" },
    { "id": "2", "label": "Hybride", "value": "2" },
    { "id": "0", "label": "Présentiel", "value": "0" },
    { "id": "", "label": "", "value": "" },
  ]

  const [Dialog_Unite_Enseignement_open, setDialog_Unite_Enseignement_open] = React.useState(false);
  const Dialog_Unite_Enseignement_handleClose = () => {
    setDialog_Unite_Enseignement_open(false);
    setselected_ue_id("");
    setselected_ue_credit("");
    setselected_ue_is_noted("");
    setselected_ue_pres_dist_hyp("");
    setadd_one_eu_to_class("");

  };


  function clean_ue_data() {
    setselected_ue_id("");
    setselected_ue_credit("");
    setselected_ue_is_noted("");
    setselected_ue_pres_dist_hyp("");
    setadd_one_eu_to_class("");

  }

  const Dialog_Unite_Enseignement_handleClose_buton = () => {
    setDialog_Unite_Enseignement_open(false);
    setselected_ue_id("");
    setselected_ue_credit("");
    setselected_ue_is_noted("");
    setselected_ue_pres_dist_hyp("");
    setadd_one_eu_to_class("");
  };

  const [Dialog_1_message, setDialog_1_message] = React.useState(false);

  const [Add_Update_UE_To_Class_api, setAdd_Update_UE_To_Class_api] = useState();
  const [Add_Update_UE_To_Class_message, setAdd_Update_UE_To_Class_message] = useState();
  const [Add_Update_UE_To_Class_result, setAdd_Update_UE_To_Class_result] = useState();
  function Add_Update_UE_To_Class() {
    setLoading(true);
    var form = new FormData();
    //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);

    if (props.mytrainingclass['class_id'])
      form.append("class_id", props.mytrainingclass['class_id']);
    else
      form.append("class_id", selected_class_id);

    form.append("ue_id", selected_ue_id);
    form.append("credit", selected_ue_credit);
    form.append("pres_dist_hyp", selected_ue_pres_dist_hyp);
    form.append("is_noted", selected_ue_is_noted);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_UE_To_Class/";


    axios.post(myurl, form).then(res => {
      setLoading(false);
      // console.log(" In Add_Update_UE_To_Class  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_UE_To_Class  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_UE_To_Class_api("true");
        setAdd_Update_UE_To_Class_result(res.data.message);
        Getall_Class_List_UE();
        Dialog_Unite_Enseignement_handleClose_buton();
        alert(res.data.message);



      }
      else {
        setAdd_Update_UE_To_Class_api("false");
        setAdd_Update_UE_To_Class_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Add_Update_UE_To_Class : Not good man :( mysearchtext = ' + error);
      setAdd_Update_UE_To_Class_api("false");
      alert(" Impossible d'ajouter l'UE à la formation ");

    })
  }


  const [Add_Update_Evaluation_to_UE_api, setAdd_Update_Evaluation_to_UE_api] = useState();
  const [Add_Update_Evaluation_to_UE_message, setAdd_Update_Evaluation_to_UE_message] = useState();
  const [Add_Update_Evaluation_to_UE_result, setAdd_Update_Evaluation_to_UE_result] = useState();
  function Add_Update_Evaluation_to_UE() {
    setLoading(true);
    var form = new FormData();
    //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);


    form.append("max_note", p_detail_max_node);

    var myurl = "";

    if (String(add_ue_evalution) === "1") {
      if (props.mytrainingclass['class_id'])
        form.append("class_id", props.mytrainingclass['class_id']);
      else
        form.append("class_id", selected_class_id);

      form.append("class_ue_id", selected_ue_id);
      form.append("type_evaluation_id", selected_eu_evalution_id);

      myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Class_UE_Evaluation/";
    } else {
      form.append("class_ue_evaluation_id", class_unite_enseignement_type_evaluation_id);
      myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Class_UE_Evaluation/";
    }

    axios.post(myurl, form).then(res => {
      setLoading(false);
      // console.log(" In Add_Update_Evaluation_to_UE  res.data.status = " + res.data.status);
      //console.log(" In Add_Update_Evaluation_to_UE  res.data.message r_class = " + res.data.message);

      if (String(res.data.status) === String("true")) {
        setAdd_Update_Evaluation_to_UE_api("true");
        setAdd_Update_Evaluation_to_UE_result(res.data.message);

        setadd_ue_evalution("");
        setselected_ue_id("");
        setselected_eu_evalution_id("");
        setsetgridline_evaluation_ue_id("");
        setp_detail_max_node("");
        setclass_unite_enseignement_type_evaluation_id("");
        Getall_Class_List_Evalution();


        Dialog_Evaluation_Unite_Enseignement_handleClose_buton();
        alert(res.data.message);

      }
      else {
        setAdd_Update_Evaluation_to_UE_api("false");
        setAdd_Update_Evaluation_to_UE_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Add_Update_Evaluation_to_UE : Not good man :( mysearchtext = ' + error);
      setAdd_Update_Evaluation_to_UE_api("false");
      alert(" Impossible d'ajouter l'évaluation l'UE  ");

    })
  }


  const [gridline_ue_id, setgridline_ue_id] = useState("");

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }


  const filter_New_Get_List_Metiers_result = () => {

    var local_tab = New_Get_List_Metiers_result.filter((data) => data.class_domaine_id === String(String(mytrainingdomaine).trim()))
    console.log(" ### local_tab = ", local_tab);

    local_tab.push({
      "id": "",
      "_id": "",
      "label": "",
      "nom": "",
      "code": "",
      "class_domaine_id": "",
      "class_domaine_nom": "",

    })

    console.log(" ### local_tab 2 = ", local_tab);

    return local_tab;

  };


  const [Dialog_Evaluation_Unite_Enseignement_open, setDialog_Evaluation_Unite_Enseignement_open] = React.useState(false);

  const Dialog_Evaluation_Unite_Enseignement_handleClose = () => {
    setDialog_Evaluation_Unite_Enseignement_open(false);

  };
  const Dialog_Evaluation_Unite_Enseignement_handleClose_buton = () => {
    setDialog_Evaluation_Unite_Enseignement_open(false);

  };

  const [selected_eu_evalution_id, setselected_eu_evalution_id] = React.useState("");
  const [setgridline_evaluation_ue_id, setsetgridline_evaluation_ue_id] = React.useState("");

  const [class_unite_enseignement_type_evaluation_id, setclass_unite_enseignement_type_evaluation_id] = React.useState("");

  const [p_detail_max_node, setp_detail_max_node] = React.useState("");


  const [add_ue_evalution, setadd_ue_evalution] = React.useState("");


  const [New_Getall_Employee_Statistic_Question_List_result, setNew_Getall_Employee_Statistic_Question_List_result] = useState([]);

  const [Getall_Employee_Statistic_Question_List_api, setGetall_Employee_Statistic_Question_List_api] = useState();
  const [Getall_Employee_Statistic_Question_List_message, setGetall_Employee_Statistic_Question_List_message] = useState();
  const [Getall_Employee_Statistic_Question_List_result, setGetall_Employee_Statistic_Question_List_result] = useState([]);
  function Getall_Employee_Statistic_Question_List(event) {

    var form = new FormData();

    const stored_cookie = getCookie('tokenmysypart');
    form.append("token", stored_cookie);
    form.append("related_collection", "");
    form.append("related_collection_id", "");
    form.append("class_id", selected_class_id);


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Entity_Question_List_With_Stat_For_Given_related_collection_id/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);

      if (String(res.data.status) === String("true")) {
        //console.log(" In Getall_Employee_Statistic_Question_List  res.data.status = " + res.data.status);
        //console.log(" In Getall_Employee_Statistic_Question_List  res.data.message r_class = " + res.data.message);
        setGetall_Employee_Statistic_Question_List_api("true");
        setGetall_Employee_Statistic_Question_List_result(res.data.message);

        var new_data2 = [];
        const new_data = res.data.message.map((x) => {

          //---
          var localid = JSON.parse(x).id;
          var local_id = JSON.parse(x).question_id;
          var local_question_id = JSON.parse(x).question_id;
          var local_question_question = JSON.parse(x).question_question;
          var local_question_type = JSON.parse(x).question_type;


          //---
          var node = {
            "_id": local_id,
            "id": localid,
            "question_id": local_question_id,
            "label": local_question_question,
            "question_question": local_question_question,
            "question_type": local_question_type,
          };
          new_data2.push(node);
        });

        //---
        var node = {
          "_id": "",
          "id": "",
          "question_id": "",
          "label": "",
          "question_question": "",
        };

        new_data2.push(node);


        if (new_data2.length > 0)
          setNew_Getall_Employee_Statistic_Question_List_result(new_data2);

      }
      else {
        setGetall_Employee_Statistic_Question_List_api("false");
        setGetall_Employee_Statistic_Question_List_message(res.data.message);
        alert(res.data.message);
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Getall_Employee_Statistic_Question_List = ', error);
      setGetall_Employee_Statistic_Question_List_api("false");
      alert(" Impossible de recuperer la liste des questions ");
      //setmyApimyApiMessage("")
    })
  }

  const [selected_statistic_question_id, setselected_statistic_question_id] = useState("");
  const [selected_statistic_question_type, setselected_statistic_question_type] = useState("");

  const [p_filter_date_from, setp_filter_date_from] = useState(date_today_minus_180j.toLocaleDateString('fr-FR'));
  const [p_filter_date_to, setp_filter_date_to] = useState(date_today_30j.toLocaleDateString('fr-FR'));


  const [Global_Getall_Qry_Survey_Data_result_JSON, setGlobal_Getall_Qry_Survey_Data_result_JSON] = useState([]);

  const [Getall_Qry_Survey_Data_result_JSON, setGetall_Qry_Survey_Data_result_JSON] = useState([]);
  const [Getall_Qry_Survey_Data_api, setGetall_Qry_Survey_Data_api] = useState();
  const [Getall_Qry_Survey_Data_message, setGetall_Qry_Survey_Data_message] = useState();
  const [Getall_Qry_Survey_Data_result, setGetall_Qry_Survey_Data_result] = useState([]);
  function Getall_Qry_Survey_Data(event) {

    var form = new FormData();
    const stored_cookie = getCookie('tokenmysypart');

    var date_from = "";
    if (p_filter_date_from) {
      date_from = p_filter_date_from;

    }

    var date_to = "";
    if (p_filter_date_to) {
      date_to = p_filter_date_to;

    }


    form.append("token", stored_cookie);
    form.append("periode_start_date", date_from);
    form.append("periode_end_date", date_to);
    form.append("related_collection", "");
    form.append("related_collection_id", "");
    form.append("question_id", selected_statistic_question_id);
    form.append("class_id", selected_class_id);


    var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Survey_Stat_Data_For_Given_related_collection_id/";

    setLoading(true);
    axios.post(myurl, form).then(res => {
      setLoading(false);
      if (String(res.data.status) === String("true")) {
        //console.log(" In Getall_Qry_Survey_Data  res.data.status = " + res.data.status);
        console.log(" In Getall_Qry_Survey_Data  res.data.message r_class = " + res.data.message);

        var json_data = JSON.parse(res.data.message[0]);

        //console.log(" ### json_data = ", json_data);
        var jsonObj0 = json_data.data

        //console.log(" ### jsonObj0 = ", jsonObj0);

        var new_data2 = [];
        const new_data = jsonObj0.map((x) => {
          var month_year = (x).month_year;
          var type_question = (x).type_question;
          var label = (x).label;
          var value = parseFloat((x).value);
          var count = (x).count;
          var nb_question_response = parseFloat((x).nb_question_response);
          var somme_question_response = parseFloat((x).somme_question_response);
          var moyenne_question_response = parseFloat((x).moyenne_question_response);


          //---
          var node = {
            "month_year": month_year,
            "label": label,
            "type_question": type_question,
            "value": parseFloat(String(value)),
            "count": count,
            "nb_question_response": parseInt(String(nb_question_response)),
            "somme_question_response": parseFloat(String(somme_question_response)),
            "moyenne_question_response": parseFloat(String(moyenne_question_response)),

          };
          new_data2.push(node);
        });

        setGetall_Qry_Survey_Data_result_JSON(new_data2);
        //setGetall_Qry_Survey_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

        //console.log(" ### new_data2 011 = ", new_data2);


        var jsonObj0_global = json_data.global
        //console.log(" ### jsonObj0_global= ", jsonObj0_global);
        setGlobal_Getall_Qry_Survey_Data_result_JSON(jsonObj0_global);



        setGetall_Qry_Survey_Data_api("true");
        setGetall_Qry_Survey_Data_result(res.data.message);


      }
      else {
        setGetall_Qry_Survey_Data_api("false");
        setGetall_Qry_Survey_Data_message(res.data.message);
        alert(res.data.message)
      }

    }).catch((error) => {
      setLoading(false);
      console.warn('Not good man :( Getall_Qry_Survey_Data = ', error);
      setGetall_Qry_Survey_Data_api("false");
      alert("Impossible de récuperer les données demandées")
      //setmyApimyApiMessage("")
    })
  }



  const otherSetting = {
    height: 300,
    yAxis: [{ label: '' }],
    grid: { horizontal: true },
    sx: {
      [`& .${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)',
      },
    },
  };

  const valueFormatter = (value) => `${value}`;

  const [display_view, setdisplay_view] = useState("line");
  function func_display_line_view() {
    setdisplay_view("line");
  }

  function func_display_bars_view() {
    setdisplay_view("bars");
  }



  function Export_Statistic_to_Excel(local_dashbord_internal_id) {

    const stored_cookie = getCookie('tokenmysypart');

    var date_from = "";
    if (p_filter_date_from) {
      date_from = p_filter_date_from;
      date_from = date_from.replaceAll('/', "-")

    }

    var date_to = "";
    if (p_filter_date_to) {
      date_to = p_filter_date_to;
      date_to = date_to.replaceAll('/', "-")
    }




    var nom_fichier_cmd_excel = "export_excel.xlsx";
    var local_related_collection = "inscription";
    //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

    var url = process.env.REACT_APP_API_URL + "myclass/api/Export_Excel_Survey_Stat_Data_For_Given_related_collection_id/" + stored_cookie + "/" + local_related_collection + "/-/" + date_from + "/" + date_to + "/" + selected_statistic_question_id + "/" + selected_class_id + "/";

    //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);

    console.log(" #### url = ", url)

    setLoading(true);
    axios.get(url, { responseType: 'blob', },)
      .then((res) => {
        setLoading(false);
        //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
        if (String(res.data) === String("false")) {
          alert("Impossible d'exporter les données (2) ");
        } else {
          fileDownload(res.data, nom_fichier_cmd_excel)

        }
      }).catch((error) => {
        setLoading(false);
        console.error('Error:', error);
        alert(" Impossible d'exporter les données ")

      });

  }


  return (
    <div className="addclassmanual">
      {isLoading && <div className="loader-container">
        <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
      </div>}

      {/*  Dialog pour gerer les Unite_Enseignement */}
      <Dialog
        open={Dialog_Unite_Enseignement_open}
        onClose={Dialog_Unite_Enseignement_handleClose}
        className="displaypartnersession"
      >

        <DialogTitle>MySy Information</DialogTitle>
        <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
          <DialogContentText>
            {Dialog_1_message}
          </DialogContentText>



          {add_one_eu_to_class && String(add_one_eu_to_class) === "1" && <div className="session_caract_Dialog" > <b> Choisir une unité d'enseignement </b>
            {New_Getall_Partner_List_UE_result && New_Getall_Partner_List_UE_result.length > 0 &&
              <Autocomplete
                disablePortal
                name="event_dialog_ue_id"
                id="event_dialog_ue_id"
                fullWidth
                //className="disabled_style enable_style"
                options={New_Getall_Partner_List_UE_result}
                //value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(selected_ue_id))[0].label}
                onChange={(event, value) => {
                  if (value && value._id) {

                    setselected_ue_id(value._id);

                  } else {
                    setselected_ue_id("");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une UE"
                  inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                />
                }
              />}

          </div>}



          {add_one_eu_to_class && String(add_one_eu_to_class) !== "1" &&
            New_Getall_Partner_List_UE_result && New_Getall_Partner_List_UE_result.length > 0 &&
            selected_ue_id && <div className="session_caract_Dialog" > <b> Unité d'enseignement </b>
              <TextField
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                name="event_dialog_credit"
                id="event_dialog_credit"
                fullWidth
                value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(selected_ue_id))[0].label}

              />

            </div>


          }
          <div className="session_caract_Dialog" > <b> Crédits </b>
            <TextField
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

              name="event_dialog_credit"
              id="event_dialog_credit"
              type="number"
              fullWidth
              value={selected_ue_credit}
              onChange={(e) => {
                setselected_ue_credit(e.target.value);
              }
              }

            />

          </div>


          <div className="session_caract_Dialog"> <b> Est Evalué </b><br />

            <Autocomplete
              disablePortal
              name="event_dialog_is_noted"
              id="event_dialog_is_noted"
              value={New_Option_Is_Noted.filter((data) => (data).id === String(selected_ue_is_noted))[0].label}

              fullWidth

              options={New_Option_Is_Noted}
              onChange={(event, value) => {
                if (value && value.value) {
                  setselected_ue_is_noted(value.value);
                } else {
                  setselected_ue_is_noted("");
                }
              }}
              renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

              />}
            />


          </div>

          <div className="session_caract_Dialog"> <b> Mode d'animation </b><br />
            <Autocomplete
              disablePortal
              name="event_dialog_mode_animation"
              id="event_dialog_mode_animation"
              value={New_Option_pres_dist_hyp.filter((data) => (data).id === String(selected_ue_pres_dist_hyp))[0].label}

              fullWidth

              options={New_Option_pres_dist_hyp}
              onChange={(event, value) => {
                if (value && value.value) {
                  setselected_ue_pres_dist_hyp(value.value);
                } else {
                  setselected_ue_pres_dist_hyp("");
                }
              }}
              renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

              />
              }
            />


          </div>

        </DialogContent>

        <DialogActions>
          <div className="div_row">
            <div className="div_row_gauche">
              <Button onClick={(e) => {

                Add_Update_UE_To_Class();

              }}
                className="bton_enreg_dialog"> Enregisrer </Button>




            </div>



            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
              <Button onClick={Dialog_Unite_Enseignement_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
            </div>
          </div>

        </DialogActions>

      </Dialog>
      {/* FIN  Dialog pour gerer les Unite_Enseignement */}



      {/*  Dialog pour gerer une EVALUATION à une Unite_Enseignement */}
      <Dialog
        open={Dialog_Evaluation_Unite_Enseignement_open}
        onClose={Dialog_Evaluation_Unite_Enseignement_handleClose}
        className="displaypartnersession"
      >

        <DialogTitle>MySy Information</DialogTitle>
        <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>
          <DialogContentText>
            {Dialog_1_message}
          </DialogContentText>

          {add_ue_evalution && String(add_ue_evalution) === "1" && <div className="session_caract_Dialog" > <b> Choisir une unité d'enseignement </b>
            {New_Getall_Class_List_UE_result && New_Getall_Class_List_UE_result.length > 0 &&
              <Autocomplete
                disablePortal
                name="event_dialog_ue_id"
                id="event_dialog_ue_id"
                fullWidth
                //className="disabled_style enable_style"
                options={New_Getall_Class_List_UE_result}
                //value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(selected_ue_id))[0].label}
                onChange={(event, value) => {
                  if (value && value._id) {

                    setselected_ue_id(value._id);

                  } else {
                    setselected_ue_id("");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une UE"
                  inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                />
                }
              />}

          </div>}

          {add_ue_evalution && String(add_ue_evalution) !== "1" && New_Getall_Class_List_UE_result &&
            New_Getall_Class_List_UE_result.length > 0 && <div className="session_caract_Dialog" > <b> Unité d'enseignement </b>
              <TextField
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                name="event_dialog_ue_id"
                id="event_dialog_ue_id"
                fullWidth
                disabled={true}
                value={New_Getall_Class_List_UE_result.filter((data) => (data)._id === String(selected_ue_id))[0].label}

              />


            </div>}



          {add_ue_evalution && String(add_ue_evalution) === "1" && <div className="session_caract_Dialog" > <b> Choisir un type d'évaluation </b>
            {New_Getall_Partner_Type_Evalution_List_result && New_Getall_Partner_Type_Evalution_List_result.length > 0 &&
              <Autocomplete
                disablePortal
                name="event_dialog_ue_id"
                id="event_dialog_ue_id"
                fullWidth
                //className="disabled_style enable_style"
                options={New_Getall_Partner_Type_Evalution_List_result}
                //value={New_Getall_Partner_List_UE_result.filter((data) => (data)._id === String(selected_ue_id))[0].label}
                onChange={(event, value) => {
                  if (value && value._id) {

                    setselected_eu_evalution_id(value._id);

                  } else {
                    setselected_eu_evalution_id("");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir type d'évaluation"
                  inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                />
                }
              />}

          </div>}


          {add_ue_evalution && String(add_ue_evalution) !== "1" && New_Getall_Partner_Type_Evalution_List_result &&
            New_Getall_Partner_Type_Evalution_List_result.length > 0 && selected_eu_evalution_id && <div className="session_caract_Dialog" > <b> Type d'évaluation </b>

              <TextField
                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                name="event_dialog_ue_id"
                id="event_dialog_ue_id"
                disabled={true}
                fullWidth
                value={New_Getall_Partner_Type_Evalution_List_result.filter((data) => (data)._id === String(selected_eu_evalution_id))[0].label}

              />
            </div>}


          <div className="session_caract_Dialog" > <b> Max Note </b>
            <TextField
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

              name="event_dialog_credit"
              id="event_dialog_credit"
              type="number"
              fullWidth
              value={p_detail_max_node}
              onChange={(e) => {
                setp_detail_max_node(e.target.value);
              }
              }

            />

          </div>


        </DialogContent>

        <DialogActions>
          <div className="div_row">
            <div className="div_row_gauche">


              {String(add_ue_evalution) !== "1" &&
                <Button onClick={(e) => {

                  Add_Update_Evaluation_to_UE();

                }}
                  className="bton_enreg_dialog"> Mettre à jour </Button>
              }

              {String(add_ue_evalution) === "1" &&
                <Button onClick={(e) => {

                  Add_Update_Evaluation_to_UE();

                }}
                  className="bton_enreg_dialog"> Ajouter </Button>
              }




            </div>



            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
              <Button onClick={Dialog_Evaluation_Unite_Enseignement_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
            </div>
          </div>

        </DialogActions>

      </Dialog>
      {/* FIN  Dialog pour gerer une EVALUATION à une Unite_Enseignement  */}




      {String(props.mytrainingclass.ref).trim() !== "" && <div className="div_row_ch">


        <div className="row_block zone_retour_liste" onClick={retourList}>

          <Button variant="contained" color="success" className="bton_retour_list">
            Retour liste formations &nbsp; <GrOrderedList />

          </Button>

        </div>

        <div className="row_block zone_gestion_admin">
          {/*  <Button variant="contained" color="success" className="bton_gestion_admin"

            onClick={(e) => {
              e.preventDefault();
              window.open(
                "/GestionAdministrative/" + displayedinternalurl,
                '_blank'
              );

            }}>

            GESTION ADMINISTRATIVE &nbsp;  <GrUserAdmin />
          </Button>*/}
        </div>



      </div>}

      <br />


      <div className="training_data" onChange={DataUpdated}>


        {props.formation_initiale && String(props.formation_initiale) === "1" &&
          <div className="div_row" style={{ "border": "None", "marginLeft": '10px' }} ref={myRef}>
            <Button variant="outlined" onClick={submenu_detail_formation} className="detail_class_submenu" id='detail_formation' name='detail_formation'>Détail Formation</Button>
            <Button variant="outlined" onClick={submenu_unite_enseignement} className="detail_class_submenu" id='unite_enseignement' name='unite_enseignement'>Unite Enseign. </Button>
            <Button variant="outlined" onClick={submenu_admission} className="detail_class_submenu" id='admission' name='admission'>Admission </Button>

            <Button variant="outlined" onClick={submenu_note_evaluation} className="detail_class_submenu" id='note_evaluation' name='note_evaluation'>Notes & Evaluation </Button>

            <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_class_submenu" id='piece_jointe' name='piece_jointe'>Pièces Jointes </Button>

            <Button variant="outlined" onClick={submenu_statistiques} className="detail_class_submenu" id='statistiques' name='statistiques'>Statistiques  </Button>
            {String(history_securite_read) === "1" && <Button variant="outlined" onClick={submenu_historique} className="detail_class_submenu" id='historique' name='historique'>Historique  </Button>}

          </div>}

        {String(props.formation_initiale) !== "1" &&
          <div className="div_row" style={{ "border": "None", "marginLeft": '10px' }} ref={myRef}>
            <Button variant="outlined" onClick={submenu_detail_formation} className="detail_class_submenu" id='detail_formation' name='detail_formation'>Détail Formation</Button>
            <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_class_submenu" id='piece_jointe' name='piece_jointe'>Pièces Jointes </Button>
          </div>}
        <br />


        {submenu && String(submenu) === "unite_enseignement" &&
          <div>
            <div style={{ height: 550, width: '100%', paddingRight: '10px', paddingLeft: '10px' }}>
              &nbsp;
              <Box
                sx={{
                  height: 500,
                  width: '100%',
                  paddingRight: '5px',
                  paddingLeft: '5px',

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#c8cfd5",
                    color: "black",
                    fontSize: 14
                  },


                  '& .line--statut--selected': {
                    backgroundColor: '#FBF2EF',
                    color: 'black',
                  },
                  '& .line--statut--pair': {
                    backgroundColor: 'rgba(235, 235, 235, .7)',
                    color: 'black',
                  },
                  '& .line--statut--impair': {
                    backgroundColor: '#FFFFFF',
                    color: 'black',
                  }, [`& .${gridClasses.cell}`]: {
                    py: 1,
                  },

                }}
              >
                <DataGrid
                  checkboxSelection

                  onSelectionModelChange={(newSelectionModel) => {
                    setselectionModel_list_unite_enseignement(newSelectionModel);
                    /*if (newSelectionModel.length === 1)
                        handleClick_edit_session_From_Line(newSelectionModel);*/
                    if (newSelectionModel.length !== 1) {

                    }
                  }}
                  selectionModel={selectionModel_list_unite_enseignement}

                  localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                  rows={Getall_Class_List_UE_result.map((item, index) => (
                    {
                      id: index,
                      _id: JSON.parse(item)._id,
                      code: JSON.parse(item).code,
                      titre: JSON.parse(item).titre,

                      credit: JSON.parse(item).credit,
                      pres_dist_hyp: JSON.parse(item).pres_dist_hyp,
                      is_noted: JSON.parse(item).is_noted,

                      duration: JSON.parse(item).duration,
                      duration_unite: JSON.parse(item).duration_unite,
                      duration_concat: JSON.parse(item).duration_concat,

                    }
                  ))}

                  columns={columns_list_unite_enseignement}
                  pageSize={10}
                  className="datagridclass"

                  onRowDoubleClick={(newSelectionModel) => {
                    clean_ue_data();
                    setgridline_ue_id(newSelectionModel.row.id);
                    setselected_ue_id(newSelectionModel.row._id)

                    var current_class_ue_data = New_Getall_Class_List_UE_result.filter((ue_data) => ue_data._id === String((newSelectionModel.row._id)))

                    setselected_ue_credit(current_class_ue_data[0]['credit']);
                    setselected_ue_is_noted(current_class_ue_data[0]['is_noted']);
                    setselected_ue_pres_dist_hyp(current_class_ue_data[0]['pres_dist_hyp']);
                    setadd_one_eu_to_class("0");

                    setDialog_Unite_Enseignement_open(true);


                  }}



                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  //sx={datagridSx}
                  getCellClassName={(params) => {





                    // Pour la gestion de la couleur de zone double cliquée
                    if (String(params.row.id) === String(ue_gridline_id)) {
                      return 'line--statut--selected';
                    }
                    else if (parseInt(String(params.row.id)) % 2 === 0) {
                      return 'line--statut--pair';
                    }
                    else if (parseInt(String(params.row.id)) % 2 !== 0) {
                      return 'line--statut--impair';
                    }

                  }}

                  getRowClassName={(params) => {

                    // Pour la gestion de la couleur de zone double cliquée
                    if (String(params.row.id) === String(ue_gridline_id)) {

                      return 'line--statut--selected';
                    }
                    else if (parseInt(String(params.row.id)) % 2 === 0) {
                      return 'line--statut--pair';
                    }
                    else if (parseInt(String(params.row.id)) % 2 !== 0) {
                      return 'line--statut--impair';
                    }

                  }}
                  getEstimatedRowHeight={() => 200}
                  getRowHeight={() => "auto"}
                  sx={{
                    "& .MuiDataGrid-cellContent": {
                      minHeight: 50,

                    },
                    [`& .${gridClasses.cell}`]: {
                      py: 1,
                    },
                  }}


                />
              </Box>
              <br />
              <div className="div_row" style={{ "border": "None" }}>

                <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                  <Button variant="outlined" onClick={(e) => {
                    Getall_Partner_List_UE();
                    setadd_one_eu_to_class("1")
                    setDialog_Unite_Enseignement_open(true);
                  }}
                    className="detail_class_submenu bton_add_session"
                    id='menu_import_participant' name='menu_import_participant'>Ajout 1 UE &nbsp;
                    <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                  </Button>
                </div>
              </div>
            </div>
          </div>}

        {submenu && String(submenu) === "admission" && <div> admission </div>}

        {submenu && String(submenu) === "note_evaluation" && <div>

          <div style={{ height: 550, width: '100%', paddingRight: '10px', paddingLeft: '10px' }}>
            &nbsp;
            <Box
              sx={{
                height: 500,
                width: '100%',
                paddingRight: '5px',
                paddingLeft: '5px',

                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#c8cfd5",
                  color: "black",
                  fontSize: 14
                },


                '& .line--statut--selected': {
                  backgroundColor: '#FBF2EF',
                  color: 'black',
                },
                '& .line--statut--pair': {
                  backgroundColor: 'rgba(235, 235, 235, .7)',
                  color: 'black',
                },
                '& .line--statut--impair': {
                  backgroundColor: '#FFFFFF',
                  color: 'black',
                }, [`& .${gridClasses.cell}`]: {
                  py: 1,
                },

              }}
            >
              <DataGrid
                checkboxSelection

                onSelectionModelChange={(newSelectionModel) => {
                  setselectionModel_list_evaluation_nite_enseignement(newSelectionModel);
                  /*if (newSelectionModel.length === 1)
                      handleClick_edit_session_From_Line(newSelectionModel);*/
                  if (newSelectionModel.length !== 1) {

                  }
                }}
                selectionModel={selectionModel_list_evaluation_nite_enseignement}

                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                rows={Getall_Class_List_Evalution_result.map((item, index) => (
                  {
                    id: index,
                    _id: JSON.parse(item)._id,
                    class_id: JSON.parse(item).class_id,
                    class_ue_id: JSON.parse(item).class_ue_id,
                    type_evaluation_id: JSON.parse(item).type_evaluation_id,
                    max_note: JSON.parse(item).max_note,


                  }
                ))}

                columns={columns_list_evaluation_unite_enseignement}
                pageSize={10}
                className="datagridclass"

                onRowDoubleClick={(newSelectionModel) => {

                  setadd_ue_evalution("0");
                  setsetgridline_evaluation_ue_id(newSelectionModel.row.id);
                  setselected_eu_evalution_id(newSelectionModel.row.type_evaluation_id)
                  setselected_ue_id(newSelectionModel.row.class_ue_id);
                  setp_detail_max_node(newSelectionModel.row.max_note);
                  setclass_unite_enseignement_type_evaluation_id(newSelectionModel.row._id);

                  Getall_Partner_Type_Evalution_List();

                  setDialog_Evaluation_Unite_Enseignement_open(true);




                }}



                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                //sx={datagridSx}
                getCellClassName={(params) => {

                }}

                getRowClassName={(params) => {

                  // Pour la gestion de la couleur de zone double cliquée
                  if (String(params.row.id) === String(ue_gridline_id)) {

                    return 'line--statut--selected';
                  }
                  else if (parseInt(String(params.row.id)) % 2 === 0) {
                    return 'line--statut--pair';
                  }
                  else if (parseInt(String(params.row.id)) % 2 !== 0) {
                    return 'line--statut--impair';
                  }

                }}
                getEstimatedRowHeight={() => 200}
                getRowHeight={() => "auto"}
                sx={{
                  "& .MuiDataGrid-cellContent": {
                    minHeight: 50,

                  },
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                  },
                }}


              />
            </Box>
            <br />
            <div className="div_row" style={{ "border": "None" }}>

              <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                <Button variant="outlined" onClick={(e) => {
                  Getall_Partner_Type_Evalution_List();
                  setadd_ue_evalution("1")
                  setDialog_Evaluation_Unite_Enseignement_open(true);
                }}
                  className="detail_class_submenu bton_add_session"
                  id='menu_import_participant' name='menu_import_participant'>Ajout 1 Evaluation UE &nbsp;
                  <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                </Button>
              </div>
            </div>
          </div>

        </div>}

        {submenu && String(submenu) === "piece_jointe" && <div>


          <div style={{ "marginRight": "10px" }}>

            <div style={{ "padding": "5px", "marginRight": "10px" }}>
              <br />

              <div className="div_row" style={{ "padding": "5px", "border": "None", "marginBottom": "1px" }}>

                <div style={{ "fontSize": "12px" }}>
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={sessions_file_change_1}
                    />

                    <Fab
                      color="secondary"
                      size="small"
                      component="span"
                      aria-label="add"
                      variant="extended"
                    >
                      <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                    </Fab>

                  </label>
                </div>
                {file_1_name && file_1_name.name &&
                  <nav style={{ "fontSize": "12px" }}>
                    <div className="session_caract">Nom du fichier <br />
                      <TextField
                        name="file_to_download_type_name"
                        id="file_to_download_type_name"

                        InputLabelProps={{
                          shrink: true,
                        }}

                        disabled={false}
                        className="disabled_style enable_style"
                        value={p_detail_one_file_to_download_type_name}
                        onChange={(e) => {
                          setp_detail_one_file_to_download_type_name(e.target.value);

                        }}
                      />

                    </div>
                    <br />
                    <br />
                    Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                      onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                  </nav>}

                {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


              </div>


              {file_1_name && file_1_name.name && <div className="div_row" style={{ "padding": "5px", "border": "none" }}>

                <div className="div_row_gauche" style={{ "padding": "5px", "border": "none" }}>
                  <Button variant="contained" onClick={Record_All_PJ}
                    startIcon={<AiTwotoneSave />} className="bton_enreg" style={{ "width": "80%" }}>ENREGISTRER LA PIECE</Button>
                </div>


              </div>}

              {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px", "border": "None" }}>
                <div className="div_row" style={{ "padding": "5px", "border": "None", "marginBottom": "1px" }}>

                  <div className="div_row" style={{ "padding": "5px", "border": "None" }}>
                    {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                      <div className="div_row_list_pj"  >

                        <nav name={(JSON.parse(val).file_name)}
                          id={(JSON.parse(val).file_name)}> <i> <AttachFileIcon />{(JSON.parse(val).file_business_object)} </i> <br /><font style={{ "color": "green", "cursor": "pointer" }}
                            name={(JSON.parse(val).file_name)}
                            id={(JSON.parse(val).file_name)} onClick={Download_one_attached_document}>  Télécharger &nbsp;</font></nav>


                        <Popup
                          trigger={
                            <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                          }
                          modal
                          nested
                          position="center center"
                        >
                          {close => (
                            <div>
                              <button className="gest_close" onClick={close}>
                                &times;
                              </button>
                              <div className="gest_header"> MySy Information </div>
                              <div className="gest_content">
                                {' '}

                                En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                              </div>
                              <div className="gest_actions">
                                <div style={{ "width": "45%", "float": "left" }}>
                                  <button className="gest_bton_popup" onClick={(event) => {

                                    Delete_one_attached_document(event);
                                    close();
                                  }}
                                    name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                  > Valider </button>

                                </div>
                                <div style={{ "width": "45%", "float": "right" }}>
                                  <button
                                    className="gest_bton_popup"
                                    onClick={() => {
                                      //console.log('modal closed ');
                                      close();
                                    }}
                                  >
                                    Annuler
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>

                      </div>
                    ))}
                  </div>
                </div>
              </div>}
            </div>

          </div>

        </div>}

        {submenu && String(submenu) === "detail_formation" &&
          <div>
            <div className="training_caract">
              <TextField
                required
                name="external_code"
                label="Code"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"

                value={p_detail_external_code}
                onChange={(e) => {
                  setp_detail_external_code(e.target.value);
                }
                }


              />
            </div>

            <div className="training_caract">
              <TextField
                required
                name="title"
                label="Titre (max 90 caractères) "
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_title}
                onChange={(e) => {
                  setp_detail_title(e.target.value);
                }
                }
              />
            </div>




            <div className="training_caract">
              <Autocomplete
                disablePortal
                name="formateur_id"
                id="formateur_id"
                className="disabled_style"
                options={New_Getall_Training_Employee_No_Filter_result}
                value={p_formateur_label}
                onChange={(event, value) => {
                  if (value && value._id) {
                    setp_formateur_id(value._id);
                    setp_formateur_label(value.label)
                    DataUpdated();

                  } else {
                    setp_formateur_id("");
                    setp_formateur_label("")
                    DataUpdated();
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Formateur" />
                }
              />



            </div>




            <div className="training_caract">

              <Autocomplete
                disablePortal
                name="certif"
                id="certif"
                className="disabled_style"
                options={cpf}
                value={mycpf_label}
                onChange={(event, value) => {
                  if (value && value.value) {

                    setmycpf(value.value);
                    setmycpf_label(value.label);
                    setdatamodification("1");

                  } else {
                    setmycpf("");
                    setmycpf_label("");
                    setdatamodification("1");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Eligible CPF" />
                }
              />

            </div>

            <div className="training_caract">

              <Autocomplete
                disablePortal
                name="certif"
                id="certif"
                className="disabled_style"
                options={certif}
                value={mycertif_label}
                onChange={(event, value) => {
                  if (value && value.value) {

                    setmycertif(value.value);
                    setmycertif_label(value.label);
                    setdatamodification("1");

                  } else {
                    setmycertif("");
                    setmycertif_label("");
                    setdatamodification("1");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Certification" />
                }
              />


            </div>

            <div className="training_caract">
              <Autocomplete
                disablePortal


                name="class_level"
                id="class_level"
                className="disabled_style"
                options={New_Get_List_Niveau_Formation_result}
                value={p_class_level_label}
                onChange={(event, value) => {
                  if (value && value.code) {
                    setp_class_level(value.code);
                    setp_class_level_label(value.description)
                    DataUpdated();

                  } else {
                    setp_class_level("");
                    setp_class_level_label("")
                    DataUpdated();
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Niveau (2 à 8)" />
                }
              />
            </div>


            <div className="training_caract">

              <Autocomplete
                disablePortal
                name="published"
                id="published"
                className="disabled_style"
                options={publiee}
                value={mypublished_label}
                onChange={(event, value) => {
                  if (value && value.value) {
                    //   console.log(" value = ", value);
                    setmypublished(value.value);
                    setmypublished_label(value.label);
                    setdatamodification("1");

                  } else {
                    setmypublished("");
                    setmypublished_label("");
                    setdatamodification("1");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Publié" />
                }
              />


            </div>

            <div className="training_caract">

              <Autocomplete
                disablePortal
                name="presentiel"
                id="presentiel"
                className="disabled_style"
                options={presentiel}
                value={mypresence_label}
                onChange={(event, value) => {
                  if (value && value.value) {
                    setmypresence(value.value);
                    setmypresence_label(value.label);
                    setdatamodification("1");
                  } else {
                    setmypresence("");
                    setmypresence_label("");
                    setdatamodification("1");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Présentiel" />
                }
              />


            </div>

            <div className="training_caract">

              <Autocomplete
                disablePortal
                name="distentiel"
                id="distentiel"
                className="disabled_style"
                options={presentiel}
                value={mydistance_label}
                onChange={(event, value) => {
                  if (value && value.value) {

                    setmydistance(value.value);
                    setmydistance_label(value.label);
                    setdatamodification("1");

                  } else {
                    setmydistance("");
                    setmydistance_label("");
                    setdatamodification("1");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="A distance" />
                }
              />

            </div>


            <div className="training_caract">

              <Autocomplete
                disablePortal

                name="training_support"
                id="training_support"
                className="disabled_style"
                options={New_support_training_list}

                value={mytrainingsupport_label}
                onChange={(event, value) => {
                  if (value && value.value) {

                    setmytrainingsupport(value.value);
                    setdatamodification("1");
                    setmytrainingsupport_label(value.label)

                  } else {
                    setmytrainingsupport("");
                    setdatamodification("1");
                    setmytrainingsupport_label("")
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Support de formation" />
                }
              />

            </div>


            {New_Get_List_Domaines_result && New_Get_List_Domaines_result.length > 0 && <div className="training_caract">
              <Autocomplete
                disablePortal

                name="domaine"
                id="domaine"
                className="disabled_style"
                options={New_Get_List_Domaines_result}

                value={New_Get_List_Domaines_result.filter((data) => (data)._id === String(mytrainingdomaine))[0].label}

                onChange={(event, value) => {
                  if (value && value._id) {
                    setmytrainingdomaine(value._id);
                    setmytrainingmetier("");
                    setmytrainingcategorie("");
                    setdatamodification("1");
                  } else {
                    setmytrainingdomaine("");
                    setmytrainingmetier("");
                    setmytrainingcategorie("");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Domaine" />
                }
              />

            </div>}


            {New_Get_List_Metiers_result && New_Get_List_Metiers_result.length > 0 && mytrainingdomaine &&
              <div className="training_caract">
                <Autocomplete
                  disablePortal

                  name="metier"
                  id="metier"
                  className="disabled_style"
                  options={New_Get_List_Metiers_result.filter((data) => data.class_domaine_id === String(String(mytrainingdomaine).trim()))}

                  value={New_Get_List_Metiers_result.filter((data) => (data)._id === String(mytrainingmetier))[0].label}


                  onChange={(event, value) => {

                    if (value && value._id) {
                      setmytrainingmetier(value._id);
                      setdatamodification("1");
                    } else {
                      setmytrainingmetier("");
                      setdatamodification("1");
                    }
                  }}

                  renderInput={(params) => <TextField {...params} label="Métier " />
                  }
                />

              </div>}




            {(!mytrainingdomaine || String(mytrainingdomaine).trim().length <= 0 || String(mytrainingdomaine).trim() === "") && <div className="training_caract">
              <Autocomplete
                disablePortal

                name="metier"
                id="metier"
                className="disabled_style"
                options={New_Get_List_Metiers_result}
                value={mytrainingmetier}
                onChange={(event, value) => {


                  if (value && value._id) {

                    setmytrainingmetier(value.metier);
                    setdatamodification("1");
                  } else {
                    setmytrainingmetier("");
                    setdatamodification("1");
                  }
                }}

                renderInput={(params) => <TextField {...params} label="Métier" />
                }
              />

            </div>}



            {New_Get_List_Categorie_result && New_Get_List_Categorie_result.length > 0 && mytrainingdomaine &&
              <div className="training_caract">
                <Autocomplete
                  disablePortal

                  name="categorie"
                  id="categorie"
                  className="disabled_style"
                  options={New_Get_List_Categorie_result.filter((data) => data.class_domaine_id === String(String(mytrainingdomaine).trim()))}

                  value={New_Get_List_Categorie_result.filter((data) => (data)._id === String(mytrainingcategorie))[0].label}


                  onChange={(event, value) => {

                    if (value && value._id) {
                      setmytrainingcategorie(value._id);
                      setdatamodification("1");
                    } else {
                      setmytrainingcategorie("");
                      setdatamodification("1");

                    }
                  }}

                  renderInput={(params) => <TextField {...params} label="Catégorie" />
                  }
                />

              </div>}




            {(!mytrainingdomaine || String(mytrainingdomaine).trim().length <= 0 || String(mytrainingdomaine).trim() === "") && <div className="training_caract">
              <Autocomplete
                disablePortal

                name="categorie"
                id="categorie"
                className="disabled_style"
                options={New_Get_List_Categorie_result}
                value={mytrainingcategorie}
                onChange={(event, value) => {


                  if (value && value._id) {

                    setmytrainingcategorie(value.metier);
                    setdatamodification("1");
                  } else {
                    setmytrainingcategorie("");
                    setdatamodification("1");


                  }
                }}

                renderInput={(params) => <TextField {...params} label="Catégorie" />
                }
              />

            </div>}



            <div className="training_caract">
              <TextField
                name="price"
                label="Prix"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_price}
                onChange={(e) => {
                  setp_detail_price(e.target.value);
                }
                }
              />
            </div>

            <div className="training_caract">

              <TextField
                name="duree"
                label="Durée"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_duree}
                onChange={(e) => {
                  setp_detail_duree(e.target.value);
                }
                }
              />
            </div>
            <Tooltip className="tooltip_css" id="my-tooltip11" />
            <a data-tooltip-id="my-tooltip11" data-tooltip-html="Les durée autorisées sont : 'heure', 'jour', 'semaine', 'mois', 'annee', 'rythme participant'">
              <div className="training_caract">
                <Autocomplete
                  disablePortal


                  name="duration_unit"
                  id="duration_unit"
                  className="disabled_style"
                  options={New_duration_unit_list}
                  value={mytrainingdurationunit}
                  onChange={(event, value) => {
                    if (value && value.value) {
                      setmytrainingdurationunit(value.value);
                      setdatamodification("1");
                    } else {
                      setmytrainingdurationunit("");
                      setdatamodification("1");
                    }
                  }}

                  renderInput={(params) => <TextField {...params} label="Unité de durée" />
                  }
                />


              </div>
            </a>

            <div className="training_caract">
              <TextField
                name="url"
                label="site web"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_url}
                onChange={(e) => {
                  setp_detail_url(e.target.value);
                }
                }
              />
            </div>

            <div className="training_caract">
              <TextField
                name="lms_class_code"
                label="Code LMS"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_lms_class_code}
                onChange={(e) => {
                  setp_detail_lms_class_code(e.target.value);
                }
                }
              />
            </div>


            <div className="training_caract">
              <TextField
                name="class_inscription_url"
                label="Page d'inscription"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_class_inscription_url}
                onChange={(e) => {
                  setp_detail_class_inscription_url(e.target.value);
                }
                }
              />
            </div>



            <div className="training_caract">
              <Tooltip className="tooltip_css" id="tooltip_img_profil_01" />
              <a data-tooltip-id="tooltip_img_profil_01" data-tooltip-html="L'image de profil de la formation. <br/>L'ordre de priorité d'affichage des images est : <br/>
        1 - L'image carrée téléchargée en bas de page <br/>
        2 - Image Bannière <br/>
        3 - L'image par defaut du métier dans le système
         ">
                <TextField
                  name="class_banner_img_url"
                  id="class_banner_img_url"
                  label="Image Bannière"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={false}
                  className="disabled_style"

                  value={p_detail_class_banner_img_url}
                  onChange={(e) => {
                    setp_detail_class_banner_img_url(e.target.value);
                  }
                  }
                />
              </a>
            </div>


            <div className="training_caract">
              <TextField
                name="note"
                id="note"
                label="Note"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ min: "1", max: "5", step: "0.5" }}
                disabled={false}
                className="disabled_style"
                value={p_detail_note}
                onChange={(e) => {
                  setp_detail_note(e.target.value);
                }
                }
              />
            </div>

            <div className="training_caract">
              <TextField
                name="lms_class_code"
                label="Version Catalogue"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_version_catalogue}
                onChange={(e) => {
                  setp_detail_version_catalogue(e.target.value);
                }
                }
              />
            </div>

            <div className="training_caract">
              <TextField
                name="recyclage"
                label="Recyclage (mois)"
                type="number"
                inputProps={{ min: "0", max: "120", step: "1" }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_recyclage_delai}
                onChange={(e) => {
                  setp_detail_recyclage_delai(e.target.value);
                }
                }
              />
            </div>

            <div className="training_caract">
              <TextField
                name="recyclage alerte"
                label="Alerte Recyclage (jours)"
                type="number"
                inputProps={{ min: "0", max: "180", step: "1" }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={false}
                className="disabled_style"
                value={p_detail_recyclage_alert}
                onChange={(e) => {
                  setp_detail_recyclage_alert(e.target.value);
                }
                }
              />
            </div>

            {/* -- début champs specifiques **/}
            <div className="div_row" style={{ "border": 'none' }}>
              <hr />
            </div>
            <div className="div_row" style={{ "padding": "5px", "border": 'none' }}> Vos champs spécifiques<br />

              {rows_champs_specifics &&
                rows_champs_specifics.map((champ_spec) => (

                  <div className="training_caract">
                    {String(JSON.parse(champ_spec).field_type) === "float" && <TextField
                      name={JSON.parse(champ_spec).field_name}
                      id={JSON.parse(champ_spec).field_name}
                      label={JSON.parse(champ_spec).field_label}
                      type="number"
                      inputProps={{ min: "1", max: "999999", step: "1" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={false}
                      className="disabled_style"
                      onChange={(e) => {
                        change_champs_spec_handle(e.target.value);
                        //setEmployee_data_changed("1");
                      }}
                    />}

                    {String(JSON.parse(champ_spec).field_type) === "string" && <TextField
                      name={JSON.parse(champ_spec).field_name}
                      id={JSON.parse(champ_spec).field_name}
                      label={JSON.parse(champ_spec).field_label}
                      type="text"

                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={false}
                      className="disabled_style"
                      onChange={(e) => {
                        change_champs_spec_handle(e.target.value);
                        //setEmployee_data_changed("1");
                      }}
                    />}

                  </div>
                ))}

              <br />

            </div>
            {/* -- end  champs specifiques **/}

          </div>}

        {String(history_securite_read) === "1" && String(submenu) === String("historique") && <div style={{ "border": "None", "background": '#F8F9F9', "padding": '5px' }}>
          <Module_Historique_Action related_collection="myclass"
            related_collection_recid={selected_class_id}
            read_access={props.read_access}
            write_access={props.write_access} />
        </div>}

      </div>




      {submenu && String(submenu) === "detail_formation" && <div>

        <div style={{ "width": "100%" }}>
          {String(datamodification) === String("1") && <div className="parter_div_filtrer_row">
            <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
          </div>}

          {String(props.ispending) === String("0") && <div className="parter_div_filtrer_row">


            {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) && <div className="parter_div_filtrer_row_gauche" >
              <Button variant="contained" onClick={RecordTraining} startIcon={<AiTwotoneSave />} className="bton_enreg2">ENREGISTRER LA FORMATION </Button>
            </div>
            }

            {/*String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" >
          <Button variant="contained" onClick={"ModifTraining"} startIcon={<AiTwotoneEdit />}>MODIFIER </Button>
        </div>
      */ }



            {String(datamodification) !== String("1") && String(editor_tochange) !== String("1") && String(props.mytrainingclass.ref) !== "" &&
              <div className="parter_div_filtrer_row_droite" >

                <Popup
                  trigger={
                    <Button variant="contained" startIcon={<DeleteIcon />} className="bton_suppr2">   SUPPRIMER LA FORMATION </Button>

                  }
                  modal
                  nested
                  position="center center"
                >
                  {close => (
                    <div>
                      <button className="gest_close" onClick={close}>
                        &times;
                      </button>
                      <div className="gest_header"> MySy Information </div>
                      <div className="gest_content">
                        {' '}

                        En confirmant cette opération, la formation sera <i><font color="red"> définitivement supprimée</font></i>. <br />
                        Avant, assurez-vous de n'avoir aucune session rattachée à la formation.

                      </div>
                      <div className="gest_actions">
                        <div style={{ "width": "45%", "float": "left" }}>
                          <button className="gest_bton_popup" onClick={(event) => {
                            DeleteClass(event);
                            //console.log('modal closed ');
                            close();
                          }}> Valider </button>

                        </div>
                        <div style={{ "width": "45%", "float": "right" }}>
                          <button
                            className="gest_bton_popup"
                            onClick={() => {
                              console.log('modal closed ');
                              close();
                            }}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>

              </div>
            }


            {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) && String(props.mytrainingclass.ref) !== "" && <div className="parter_div_filtrer_row_droite" >
              <Button variant="contained" onClick={UndoModif} startIcon={<IoArrowUndoCircle />} className="bton_suppr2">   ANNULER LES MODIFICATIONS </Button>

            </div>}

          </div>}

          {String(props.ispending) !== String("0") && <div className="parter_div_filtrer_row">

            datamodification = {datamodification}
            {String(datamodification) === String("1") && <div className="parter_div_filtrer_row_gauche bton_enreg2" onClick={alertactivecompte} >
              <AiTwotoneSave />ENREGISTRER
            </div>
            }


            {String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" onClick={alertactivecompte}>
              <AiTwotoneEdit />MODIFICATION
            </div>
            }


            <div className="parter_div_filtrer_row_droite" >
              <Button variant="outlined" color="error" onClick={alertactivecompte} startIcon={<DeleteIcon />} className="bton_suppr2">   Supprimer</Button>

            </div>

          </div>}


          {String(myApiResponse) === String("true") && importmessage &&
            <div className="parter_div_filtrer_row okUpdateData">
              {importmessage}

            </div>}

          {String(myApiResponse) === String("false") &&
            <div className="parter_div_filtrer_row koUpdateData">
              {importmessage}

            </div>}
        </div>


        <div className="training_data">

          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Description * (max 1000 caractères) </div>
              <Editor
                onInit={(evt, editor) => editorRef_description.current = editor}
                initialValue={field_description}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {

                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated()
                    seteditor_tochange("");
                  }
                }}

                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>

          <div className="training_text" >
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Objectif* (max 1000 caractères) </div>
              <Editor
                onInit={(evt, editor) => editorRef_objectif.current = editor}
                initialValue={field_objectif}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>

          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Programme (max 1000 caractères) </div>
              <Editor
                onInit={(evt, editor) => editorRef_programme.current = editor}
                initialValue={field_programme}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>

          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text">Ressources Pédagogiques et techniques  (max 1000 caractères) </div>
              <Editor
                onInit={(evt, editor) => editorRef_methode_pedago.current = editor}
                initialValue={field_methode_pedago}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>


          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text">Suivi et Évaluation (max 1000 caractères) </div>
              <Editor
                onInit={(evt, editor) => editorRef_suivi_eval.current = editor}
                initialValue={field_suivi_eval}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>



          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Accès handicapé  (max 1000 caractères) </div>
              <Editor
                onInit={(evt, editor) => editorRef_handicap.current = editor}
                initialValue={field_handicap}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>


          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Pré-requis</div>
              <Editor
                onInit={(evt, editor) => editorRef_prerequis.current = editor}
                initialValue={field_prerequis}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>

          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Pour Qui ?</div>
              <Editor
                onInit={(evt, editor) => editorRef_pourqui.current = editor}
                initialValue={field_pourqui}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>

          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Mots clés</div>
              <Editor
                onInit={(evt, editor) => editorRef_mots_cle.current = editor}
                initialValue={field_mots_cle}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>

          <div className="training_text">
            <div className="plus_produit_desabled">
              <div className="titre_training_text"> Plus produit</div>
              <Editor
                onInit={(evt, editor) => editorRef_plus_produit.current = editor}
                initialValue={field_plus_produit}
                onKeyUp={editor_keyup}
                onEditorChange={(newValue, editor) => {
                  if (String(editor_tochange) === "1") {
                    //alert(" change");
                    DataUpdated();
                    seteditor_tochange("");
                  }
                }}
                init={{
                  resize: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],

                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                }}
              />

            </div>
          </div>



        </div>

        &nbsp;
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          onChange={DataUpdated}
        >
          <div>

            {(String(props.currentpack).toLocaleLowerCase() === String("gold") ||
              String(props.currentpack).toLocaleLowerCase() === String("mesure"))
              && <div style={{ "width": "100%", "marginBottom": "1rem" }}>


              </div>}



            {String(props.currentpack).toLocaleLowerCase() === String("decouverte") && <div style={{ "width": "100%", "marginBottom": "1rem" }}>

              <nav className="new_zone">
                <TextField
                  name="mots_cle"
                  id="mots_cle"
                  label="Mots clés"
                  type="text"
                  multiline
                  maxRows={4}
                  rows={4}

                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  className="plus_produit_desabled2"
                  value=""
                />
                <nav className="plus_produit_activation" onClick={ActivePackStandard}> Passer au Standard</nav>
              </nav>


              {/*  <nav className="new_zone">
              <TextField
                name="zone_diffusion"
                label="Zone Diffusion (FR-Marseille;FR-Paris;etc)"
                type="text"
                multiline
                maxRows={4}
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
                className="plus_produit_desabled2"
                value=""
              />
              <nav className="plus_produit_activation" onClick={ActivePackGold}> Passer au Gold </nav>
            </nav>*/}

              <nav className="new_zone">
                <TextField
                  name="plus_produit"
                  label="Plus Produit"
                  type="text"
                  multiline
                  maxRows={4}
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  className="plus_produit_desabled2"
                  value=""
                />
                <nav className="plus_produit_activation" onClick={ActivePackStandard}> Passer au Standard</nav>
              </nav>

            </div>
            }

            {String(props.currentpack).toLocaleLowerCase() === String("standard") &&
              <div >

                {/*  <nav className="new_zone2">
                <TextField
                  name="zone_diffusion"
                  label="Zone Diffusion (FR-Marseille;FR-Paris;etc)"
                  type="text"
                  multiline
                  maxRows={4}
                  rows={4}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  className="plus_produit_desabled2"
                  value=""
                />
                <nav className="plus_produit_activation_no_float" onClick={ActivePackGold}> Passer au Gold </nav>
              </nav>
*/}

                <nav >
                  &nbsp;
                </nav>



              </div>
            }


            <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >



              <Tooltip className="tooltip_css" id="tooltip_img_profil" />
              <a data-tooltip-id="tooltip_img_profil" data-tooltip-html="L'image de profil de la formation. <br/>L'ordre de priorité d'affichage des images est : <br/>
        1 - L'image carrée téléchargée en bas de page <br/>
        2 - Image Bannière <br/>
        3 - L'image par defaut du métier dans le système
         ">
                <div className="div_row_gauche_image"> Image de profil <br />
                  <img class="img_class_logo" src={userimgclassprofil} /><br />

                </div>
              </a>
              <div className="div_row_droite_image">
                <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /> <br />
                <label for="files_img_class" className="bton_image_class">Charger une image</label><br />
                <nav className="bton_supprime_image_class" onClick={removeRecodedClassImage}>Supprimer l'image</nav><br />
                {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
              </div>

            </div>
            <div className="div_row_bis tips_img_class">
              <li>
                L'image doit etre carrée.
              </li>
              <li>
                Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
              </li>
              <li>
                Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                Les dimensions maximales sont de 256pixels X 256pixels
              </li>
              <li>
                La taille de l'image ne doit pas dépasser 1 mega octet
              </li>
            </div>
          </div>

        </Box>

        <div style={{ "marginRight": "10px" }}>
          <div className="div_row" style={{ "border": "None" }}>
            <hr className="hr_break" />
          </div>

        </div>


        {String(datamodification) === String("1") && <div className="parter_div_filtrer_row">
          <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div>
        </div>}

        {String(props.ispending) === String("0") && <div className="parter_div_filtrer_row">


          {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) && <div className="parter_div_filtrer_row_gauche" >
            <Button variant="contained" onClick={RecordTraining} startIcon={<AiTwotoneSave />} className="bton_enreg2">ENREGISTRER LA FORMATION </Button>
          </div>
          }

          {/*String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" >
          <Button variant="contained" onClick={"ModifTraining"} startIcon={<AiTwotoneEdit />}>MODIFIER </Button>
        </div>
      */ }



          {String(datamodification) !== String("1") && String(editor_tochange) !== String("1") &&
            String(props.mytrainingclass.ref) !== "" && <div className="parter_div_filtrer_row_droite" >
              <Button variant="contained" onClick={DeleteClass} startIcon={<DeleteIcon />} className="bton_suppr2">  SUPPRIMER LA FORMATION </Button>

            </div>
          }


          {(String(datamodification) === String("1") || String(editor_tochange) === String("1")) &&
            String(props.mytrainingclass.ref) !== "" && <div className="parter_div_filtrer_row_droite" >
              <Button variant="contained" onClick={UndoModif} startIcon={<IoArrowUndoCircle />} className="bton_suppr2">   ANNULER LES MODIFICATIONS </Button>

            </div>}

        </div>}

        {String(props.ispending) !== String("0") && <div className="parter_div_filtrer_row">
          {String(datamodification) === String("1") && <div className="parter_div_filtrer_row_gauche bton_enreg2" onClick={alertactivecompte} >
            <AiTwotoneSave />ENREGISTRER
          </div>
          }


          {String(datamodification) === String("0") && <div className="parter_div_filtrer_row_gauche" onClick={alertactivecompte}>
            <AiTwotoneEdit />MODIFICATION
          </div>
          }


          <div className="parter_div_filtrer_row_droite" >
            <Button variant="outlined" color="error" onClick={alertactivecompte} startIcon={<DeleteIcon />} className="bton_suppr2">   Supprimer</Button>

          </div>

        </div>}


        {String(myApiResponse) === String("true") && importmessage &&
          <div className="parter_div_filtrer_row okUpdateData">
            {importmessage}

          </div>}

        {String(myApiResponse) === String("false") &&
          <div className="parter_div_filtrer_row koUpdateData">
            {importmessage}

          </div>}

      </div>}

      {submenu && String(submenu) === "statistiques" && <div style={{ height: "80rem", width: '100%', paddingRight: '10px', paddingLeft: '10px' }}>
        <div>
          <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}>  Les Statitiques </nav>

          <div className="div_row" style={{ "border": "None" }} >
            <div className="session_caract">
              Choisir une question
              {New_Getall_Employee_Statistic_Question_List_result && New_Getall_Employee_Statistic_Question_List_result.length > 0 &&
                <div style={{ width: '90%', }}>


                  <Autocomplete
                    disablePortal
                    id="competence_nom"
                    name="competence_nom"

                    fullWidth

                    options={New_Getall_Employee_Statistic_Question_List_result}
                    value={New_Getall_Employee_Statistic_Question_List_result.filter((data) => (data)._id === String(selected_statistic_question_id))[0].label}

                    onChange={(event, value) => {


                      if (value && value._id) {
                        setselected_statistic_question_id(value.question_id);
                        setselected_statistic_question_type(value.question_type);
                      } else {
                        setselected_statistic_question_id("");
                        setselected_statistic_question_type("");
                      }
                    }}

                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                      inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                    />
                    }
                  />
                </div>}
            </div>

            {selected_statistic_question_id && String(selected_statistic_question_id) !== "d" &&

              <div style={{ width: '100%', marginRight: '1rem', 'float': 'left' }}>

                <div style={{ width: '100%', marginRight: '1rem', 'float': 'left' }}>
                  <div className="session_caract">
                    Date Debut
                    <DatePicker
                      name="one_sessiondatedebut"
                      id="one_sessiondatedebut"

                      selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                      onChange={(date) => {
                        setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                      }
                      }
                      showTimeSelect={false}
                      dateFormat="dd/MM/yyyy"
                      className="disabled_style session_caract_Dialog_DatePicker"
                      locale='fr-FR'

                    />

                  </div>

                </div>
                <div style={{ width: '100%', marginRight: '1rem', 'float': 'left' }}>

                  <div className="session_caract">
                    Date Fin
                    <DatePicker
                      name="one_sessiondatefin"
                      id="one_sessiondatefin"
                      selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                      onChange={(date) => {
                        setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                      }
                      }
                      showTimeSelect={false}
                      dateFormat="dd/MM/yyyy"
                      className="disabled_style session_caract_Dialog_DatePicker"
                    />

                  </div>

                </div>



              </div>}
          </div>
          <div className="session_caract">
            {String(selected_statistic_question_id) !== "" && <div>  <Button onClick={Getall_Qry_Survey_Data} className="bton_enreg_dialog"> Afficher</Button> </div>}

          </div>

          {Getall_Qry_Survey_Data_result_JSON && Getall_Qry_Survey_Data_result_JSON.length > 0 &&
            Getall_Qry_Survey_Data_result_JSON && <div style={{ "height": "30rem" }}>

              {display_view && String(display_view) === "bars" && <div style={{ "textAlign": "right", "float": "right", "width": "100%" }}>
                Affichage &nbsp;
                <nav className="mode_affichage" onClick={Export_Statistic_to_Excel}> <RiFileExcel2Line /> Export </nav>
                <nav className="mode_affichage" onClick={func_display_line_view}> <SsidChartIcon /> Ligne </nav>
                <nav className="mode_affichage mode_affichage_selected" onClick={func_display_bars_view}> <BarChartIcon /> Bar </nav>

              </div>}

              {display_view && String(display_view) === "line" && <div style={{ "textAlign": "right", "float": "right", "width": "100%" }}>
                Affichage &nbsp;
                <nav className="mode_affichage" onClick={Export_Statistic_to_Excel}> <RiFileExcel2Line /> Export </nav>
                <nav className="mode_affichage mode_affichage_selected" onClick={func_display_line_view}> <SsidChartIcon /> Ligne </nav>
                <nav className="mode_affichage" onClick={func_display_bars_view}> <BarChartIcon /> Bar </nav>
              </div>}


              {display_view && String(display_view) === "bars" && <Box style={{ "color": "black", "height": "30rem" }}>
                &nbsp;

                <div className="session_data" style={{ paddingLeft: "2rem", height: '30rem' }}>

                  {Getall_Qry_Survey_Data_result_JSON && Getall_Qry_Survey_Data_result_JSON.length > 0 &&
                    Getall_Qry_Survey_Data_result_JSON &&
                    <BarChart

                      dataset={Getall_Qry_Survey_Data_result_JSON}
                      xAxis={[{
                        scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle',
                        valueFormatter: (month, context) =>
                          context.location === 'tick'
                            ? `${month.slice(0, 2)}` + "\n" + `${month.slice(3, 7)}`
                            : `${month}`,
                      }]}
                      series={[
                        { dataKey: 'nb_question_response', label: 'NB', valueFormatter },
                        { dataKey: 'value', label: `${selected_statistic_question_type}`, color: '#FA7070', valueFormatter },


                      ]}
                      {...otherSetting}
                      margin={{ right: 15, left: 100 }}
                      height={450}
                    />}
                </div>

              </Box>}

              {display_view && String(display_view) === "line" && <Box style={{ "color": "black", "height": "30rem" }}>
                &nbsp;

                <div className="session_data" style={{ paddingLeft: "2rem", height: '30rem' }}>


                  {Getall_Qry_Survey_Data_result_JSON && Getall_Qry_Survey_Data_result_JSON.length > 0 &&
                    Getall_Qry_Survey_Data_result_JSON &&


                    <LineChart
                      dataset={Getall_Qry_Survey_Data_result_JSON}
                      xAxis={[{
                        scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle',
                        valueFormatter: (month, context) =>
                          context.location === 'tick'
                            ? `${month.slice(0, 2)}` + " \n" + `${month.slice(3, 7)}`
                            : `${month}`,
                      }]}
                      series={[
                        { dataKey: 'nb_question_response', label: 'NB', valueFormatter: (value) => (value == null ? 'NaN' : value.toString()), },
                        { dataKey: 'value', label: `${selected_statistic_question_type}`, color: '#FA7070', valueFormatter: (value) => (value == null ? 'NaN' : value.toString()), },
                      ]}

                      margin={{ right: 15, left: 100 }}
                      height={450}
                    />



                  }
                </div>



              </Box>}

              <br />
              {Global_Getall_Qry_Survey_Data_result_JSON && <div>

                Nombre total  d'enquêtes sur la période : {Global_Getall_Qry_Survey_Data_result_JSON['global_nb_question_response']} <br />

                {String(selected_statistic_question_type) === "Somme" &&
                  <nav>
                    Nombre de réponses <b><i> 'Oui' </i></b> sur la période : {Global_Getall_Qry_Survey_Data_result_JSON['global_somme_question_response']} <br />

                  </nav>}

                {String(selected_statistic_question_type) === "Moyenne" &&
                  <nav>
                    Somme totale d'enquêtes sur la période : {Global_Getall_Qry_Survey_Data_result_JSON['global_somme_question_response']} <br />
                    Moyenne Globale sur la période : {Global_Getall_Qry_Survey_Data_result_JSON['global_moyenne_question_response']} <br />
                  </nav>}

              </div>}
            </div>}

        </div>
      </div>}


    </div>
  );
}

export default AddClassManual;
