import React, { useRef, useState, useEffect } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { tokens } from "../theme";
import { MdDeleteForever } from "react-icons/md";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import {
    useTheme,
} from "@mui/material";

import { ResponsiveBar } from "@nivo/bar";
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { RiFileExcel2Line } from "react-icons/ri";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@mui/material/Button';

const Dashbord_Ressources_Humaines_Tache_by_rhid = (props) => {
    const [gridline_id, setgridline_id] = useState("");
    const [isLoading, setLoading] = useState();
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [user_dashbord_id, setuser_dashbord_id] = useState(props.user_dashbord_id);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);

    const [filter_type_tache, setfilter_type_tache] = useState("");

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'civilite', headerName: 'Civ.', hide: true, minWidth: 100, flex: 1, maxWidth: 60, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'email', headerName: 'Email', hide: true, flex: 1, minWidth: 250, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'ismanager', headerName: 'Manager', hide: true, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'fonction', headerName: 'Fonction', hide: true, minWidth: 200, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} /> },

        { field: 'agenda_event_title', headerName: 'Titre Event', hide: false, minWidth: 250, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_code_session', headerName: 'Code session', hide: false, minWidth: 250, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_type', headerName: 'Type Event', hide: false, minWidth: 150, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'event_duration_hour', headerName: 'Durée (h)', hide: false, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_date_jour', headerName: 'Date', hide: false, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_start', headerName: 'Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_end', headerName: 'Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_sequence_session_id', headerName: 'agenda_id', hide: true, maxWidth: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
    ]

    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));

    const [event_type_filter, setevent_type_filter] = useState(props.user_dashbord_filter_type_envent);


    const [filter_value, setfilter_value] = useState("");

    const [Getall_Qry_RH_Data_result_JSON, setGetall_Qry_RH_Data_result_JSON] = useState([]);
    const [Getall_Qry_RH_Data_api, setGetall_Qry_RH_Data_api] = useState();
    const [Getall_Qry_RH_Data_message, setGetall_Qry_RH_Data_message] = useState();
    const [Getall_Qry_RH_Data_result, setGetall_Qry_RH_Data_result] = useState([]);
    function Getall_Qry_RH_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }

        var event_type = "";
        if (event_type_filter) {
            event_type = event_type_filter;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);
        if (selected_employee_id && String(setselected_employee_id).length > 5)
            form.append("tab_rh_id", selected_employee_id);
        else
            form.append("tab_rh_id", "all");

        form.append("event_type", event_type);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Humain_Ressource_With_Planning/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_RH_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_RH_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_RH_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_RH_Data_api("true");
                setGetall_Qry_RH_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_RH_Data_api("false");
                setGetall_Qry_RH_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_RH_Data = ', error);
            setGetall_Qry_RH_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

 

    const [Remove_From_User_TBD_api, setRemove_From_User_TBD_api] = useState();
    const [Remove_From_User_TBD_message, setRemove_From_User_TBD_message] = useState();
    const [Remove_From_User_TBD_result, setRemove_From_User_TBD_result] = useState();
    function Remove_From_User_TBD() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');



        form.append("token", stored_cookie);
        form.append("user_dashbord_id", user_dashbord_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Remove_From_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Remove_From_User_TBD  res.data.message r_class = " + res.data.message);

                setRemove_From_User_TBD_api("true");
                setRemove_From_User_TBD_result(res.data.message);
                Getall_Qry_RH_Data();
                alert(res.data.message);
                window.location.reload();


            }
            else {
                setRemove_From_User_TBD_api("false");
                setRemove_From_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Remove_From_User_TBD = ', error);
            setRemove_From_User_TBD_api("false");
            alert("Impossible de supprimer le tableau de bord de votre liste");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [selected_employee_id, setselected_employee_id] = useState("");


    useEffect(() => {

        setp_filter_date_from(props.user_dashbord_filter_start);
        setp_filter_date_to(props.user_dashbord_filter_end);
        setevent_type_filter(props.user_dashbord_filter_type_envent);


        Getall_Qry_RH_Data();


        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [p_filter_date_from, p_filter_date_to])


    function Export_Dashbord_to_CSV(local_dashbord_internal_id) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_csv.csv";
        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/TBD_RH_01_Export_Dashbord_To_Csv/" + stored_cookie + "/" + local_dashbord_internal_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les données (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les données ")

            });

    }


    const New_option_type_planing = [
        { "id": "absence", "label": "Absence", "value": "absence" },
        { "id": "planning", "label": "Planning", "value": "planning" },
        { "id": "autre", "label": "Autre", "value": "autre" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_option_periode = [
        { "id": "m0", "label": "Mois en cours", "value": "m0" },
        { "id": "m1", "label": "Mois précédant ", "value": "m1" },
        { "id": "d", "label": "Choisir des dates", "value": "d" },

    ]


    return (
        <div className="dashbord_session" style={{ "marginLeft": "1px", "marginRight": "2px" }}>
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <div style={{ "width": "100%", "float": "left" }}>
                <div className="" style={{ "width": "50%", "float": "left" }}>
                    Filtre <br />
                    <Autocomplete
                        disablePortal
                        name="filtre11"
                        id="filtre11"
                        className="disabled_style"
                        options={New_option_periode}
                        onChange={(event, value) => {
                            if (value && value.value) {
                                setfilter_value(value.value);

                            } else {
                                setfilter_value("");
                            }
                        }}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une période"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />


                </div>

                {filter_value && String(filter_value) === "d" &&

                    <div style={{ "width": "100%", "float": "left" }}>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Debut
                                <DatePicker
                                    name="one_sessiondatedebut"
                                    id="one_sessiondatedebut"

                                    selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'

                                />

                            </div>
                        </div>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Fin
                                <DatePicker
                                    name="one_sessiondatefin"
                                    id="one_sessiondatefin"
                                    selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                />

                            </div>
                        </div>

                    </div>}
                <br />

                <div style={{ "width": "100%", "float": "left" }}>
                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Type Tache <br />

                        <Autocomplete
                            disablePortal
                            name="filtre12"
                            id="filtre12"
                            className="disabled_style"
                            options={New_option_type_planing}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setfilter_type_tache(value.value);


                                } else {
                                    setfilter_type_tache("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>

                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Employé <br />

                        <Autocomplete
                            disablePortal
                            name="employee_id"
                            id="employee_id"
                            className="disabled_style"
                            options={New_Getall_Training_Employee_No_Filter_result}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setselected_employee_id(value._id);

                                } else {
                                    setselected_employee_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>
                </div>

                <div className="div_row">
                    <br />
                    <Button variant="contained" className="bton_enreg" onClick={(e) => {
        
                        Getall_Qry_RH_Data();

                    }}> Afficher
                    </Button><br />
                </div>

            </div>

            <div style={{ "height": "40rem" }}>

                <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                    <nav style={{ "width": "80%", "float": "left", "textAlign": "center" }}>  TBD : {props.user_dashbord_title} - Du : {props.user_dashbord_filter_start} au : {props.user_dashbord_filter_end}</nav>
                    <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer de ma liste de TBD">
                        <Popup
                            trigger={
                                <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                    <MdDeleteForever style={{ "cursor": "pointer", }} />
                                </nav>
                            }
                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        En confirmant cette opération, le tableau sera supprimé de votre tableau de bord global.<br />
                                        Vous pourrez le rajouter à nouveau si besoin. <br />

                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                Remove_From_User_TBD();
                                                //console.log('modal closed ');
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </a>

                    <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip03" data-tooltip-html="Export Csv/Excel">
                        <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                            <RiFileExcel2Line style={{ "cursor": "pointer", }} onClick={(event) => {
                                Export_Dashbord_to_CSV(props.user_dashbord_id);

                            }} />
                        </nav>
                    </a>

                </div>

                <Box style={{ "color": "black", "height": "30rem" }}>
                    &nbsp;

                    <div className="session_data">
                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',

                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection

                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_session_From_Line(newSelectionModel);*/
                                            if (newSelectionModel.length !== 1) {
                                                /*  setaddOneParticipant();
                                                  setdisplay_detail_insc();
                                                  setselected_code_session();
                                                  setsubmenu();*/
                                            }
                                        }}
                                        selectionModel={selectionModel}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_Qry_RH_Data_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                civilite: JSON.parse(item).civilite,
                                                nom: JSON.parse(item).nom,
                                                prenom: JSON.parse(item).prenom,
                                                email: JSON.parse(item).email,
                                                ismanager: JSON.parse(item).ismanager,
                                                fonction: JSON.parse(item).fonction,
                                                agenda_event_title: JSON.parse(item).agenda_event_title,
                                                agenda_event_code_session: JSON.parse(item).agenda_event_code_session,
                                                agenda_event_type: JSON.parse(item).agenda_event_type,
                                                agenda_date_jour: JSON.parse(item).agenda_date_jour,
                                                agenda_event_start: JSON.parse(item).agenda_event_start,
                                                agenda_event_end: JSON.parse(item).agenda_event_end,
                                                event_duration_hour: JSON.parse(item).event_duration_hour,
                                                agenda_event_sequence_session_id: JSON.parse(item).agenda_event_sequence_session_id,

                                            }
                                        ))}

                                        columns={columns}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            /*setgridline_id(newSelectionModel.row.id);
                                            setaddOneSession("0");
                                            setEdite_session("0");
                                            //handleClick_edit_session_From_Line(newSelectionModel.row.id);
    
                                            setselected_session_id(newSelectionModel.row._id);
    
                                            setsession_selected_row_id(newSelectionModel.row.id);
    
                                            setDialog_2_message(String(JSON.parse(rowss[newSelectionModel.row.id]).title));
                                            setDialog_2_open(true);*/

                                        }}



                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }

                                        }}

                                        getRowClassName={(params) => {

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {

                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }

                                        }}
                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}


                                    />
                                </Box>
                                <br />

                            </div>
                            {Getall_Qry_RH_Data_result &&
                                    Getall_Qry_RH_Data_result.map((my_data) => (
                                        <div >

                                            {JSON.parse(my_data).total_duration &&
                                                <nav style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "700", "fontSize": "2rem", height:"5rem",
                                                "color":'#6495ED' }}>
                                                    Durée totale : {JSON.parse(my_data).total_duration}
                                                    <div className="div_row"> &nbsp; </div>
                                                </nav>}



                                        </div>
                                    ))}

                        </div>

                    </div>


                </Box>

            </div>

        </div>
    )
}


export default Dashbord_Ressources_Humaines_Tache_by_rhid;