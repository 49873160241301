import React, { useRef, useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import axios from "axios";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import { Helmet } from "react-helmet";
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import slideimg1 from "../mysy_img/education.jpg";
import slideimg2 from "../mysy_img/simpson2.jpg";
import slideimg3 from "../mysy_img/mysy_home.jpg";
import { Button, } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import { confirmAlert } from 'react-confirm-alert';

const DisplayDetailClass = (props) => {

    const { classId } = useParams();
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState();
    const [DetailTraining, setDetailTraining] = useState([]);
    const [closepopup, setclosepopup] = useState(0);
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userIp, setuserIp] = useState("");
    const [mynote, setmynote] = useState(0);
    const [userconnected, setuserconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
    const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);

    // Gestion des Cookies
    const stored_partner = cookie_part.tokenmysypart;
    const stored_user = cookie.tokenmysych;

    var server_address = "127.0.0.1";

    useEffect(() => {
        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            local_user_connect = 1;
        }

        if (typeof (stored_partner) === "undefined" || String(stored_partner) === '') {
            setpartnerconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            local_part_connect = 1;
        }

        async function fetchData() {
            const result = await axios('https://geolocation-db.com/json/',);
            setuserIp(result.data.IPv4);
            setcountry_code(result.data.country_code);
            setcountry_name(result.data.country_name);
            setcity(result.data.city);
            setpostal(result.data.postal);
            setlatitude(result.data.latitude);
            setlongitude(result.data.longitude);
            setIPv4(result.data.IPv4);
            setstate(result.data.state);
            setuserIp(result.data.IPv4);
        }
        fetchData();


        Display();



    }, []);


    function contactUs(e) {

        var sender_mail = "";
        var sender_tel = "";
        var token = "";
        if (String(userconnected) === "0") {
            sender_mail = document.getElementsByName("usermail")[0].value;
            sender_tel = document.getElementsByName("usernumtel")[0].value;
            if (sender_mail.length == 0 && sender_tel.length == 0) {
                alert(" Les information fournies sont incorrectes ");
                return;
            }
        } else {
            token = String(stored_user)

        }


        var useravis = document.getElementsByName("useravis")[0].value;
        var usersupqual = document.getElementsByName("userqualsup")[0].value;
        var usernote = document.getElementsByName("usernote")[0].value;

        if (useravis.length < 20 || usersupqual.length < 20 || usernote.length < 0) {
            alert("Merci de saisir le nombre minimum de caractère requis pour les champs ");
            return;
        }


        var re = /\S+@\S+\.\S+/;
        if (re.test(sender_mail) == false) {
            alert("l'email est incorrecte");
            return;
        }


        ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")


        //alert("contacter "+sender_mail+" - "+sender_tel+" - "+usermessage+" - url = "
        //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
        var form = new FormData();
        form.append("type", "email");
        form.append("recever_mail", "");
        form.append("recever_tel", "");
        form.append("sender_mail", sender_mail);
        form.append("sender_tel", sender_tel);
        form.append("object", DetailTraining["external_code"]);
        form.append("token", "");
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);

        axios.post(myurl, form).then(res => {
            if (res.data.status != "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setclosepopup(1);
                document.getElementsByName("usermail")[0].value = "";
                document.getElementsByName("usernumtel")[0].value = "";
                document.getElementsByName("usercomment")[0].value = "";
            }
            else {
                //console.log(" contact-nous  statut = " + res.data.status);
                //console.log(" contact-nous   res.data.message = " + res.data.message);
            }
        }).catch((error) => {
            console.warn('contact-nous  Not good man :( mysearchtext = ');

        })


    }

    function Display(e) {
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_class_coup_de_coeur/";
        var form = new FormData();
        form.append("internal_url", classId);
        form.append("token", "");
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                if (res.data.message.length > 0) {
                    setmyApiResponse("True");
                }
                else {
                    setmyApiResponse("False");
                }
                setResult(res.data.message);
                setDetailTraining(JSON.parse(res.data.message));
            }
            else {
                //console.log(" In Erreur   res.data.status = " + res.data.status);
                //console.log(" In Erreur  res.data.message = " + res.data.message);
                setmyApiResponse("False");


            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");

        })


    }

    const history = useHistory();

    const fadeImages = [
        {
            url: slideimg1,
        },
        {
            url: slideimg2,
        },
        {
            url: slideimg3,
        },
    ];




    function CheckNote(e) {
        const val = e.target.value;

        if (isNaN(val)) {
            //alert(val + " is not a number <br/> mynote = "+mynote);
            document.getElementById("usernote").value = mynote;
        } else {
            if (val > 5) {
                alert(" Valeur Maxi doit etre 5");
                document.getElementById("usernote").value = mynote;
            } else {
                setmynote(val);
            }
        }

    }
    /// ----
    return (


        <div className="displaydetailclass">

            <Helmet>
                <meta name="description" content="Liste des articles sur les formations" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content={DetailTraining["title"]} />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/" + `${DetailTraining["internal_url"]}`} />
                <title> {`${DetailTraining["title"]}`}
                </title>
            </Helmet>

            <div class="containerBox">
                <a href={process.env.REACT_APP_BASE_URL}> <img class="img-responsive log_img" src={bannerimg2} /> </a>

            </div>
            <h1 className="h1_transparent">{`${DetailTraining["title"]}`}</h1>


            {String(myApiResponse) === String("False") &&
                <div className="koUpdateData">
                    Impossible de recuperer la formation. Merci de contacter le support.
                </div>
            }



            {myApiResponse === "True" &&
                <div className="display_screen_max_600">

                    <div>
                    </div>

                    <div >
                        <div className="detail_rightbox_left_title">
                            <b>Titre </b> <br />

                            {DetailTraining["title"]}
                        </div>

                        <div className="detail_rightbox_right_button_acces">
                            <Button variant="contained"
                                color="success"
                                className="accesformation"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                        DetailTraining["url"],
                                        '_blank'
                                    );

                                }}
                                >Accedez a la formation</Button>


                        </div>
                    </div>
                    <div className="detail_row">
                        <div className="detail_rightbox_left1">
                            <b> Formateur</b><br />
                            {DetailTraining["trainer"]}

                        </div>

                        <div className="detail_rightbox_right1">
                            <b>Prix </b> <br />
                            {DetailTraining["price"]}

                        </div>

                    </div>

                    <div className="detail_row">
                        <div className="detail_rightbox_left1">
                            <b> Distanciel</b><br />
                            {DetailTraining.presentiel && DetailTraining.presentiel.distantiel === "1" && <div> Oui </div>}
                            {DetailTraining.presentiel && DetailTraining.presentiel.distantiel !== "1" && <div> Non </div>}

                        </div>
                        <div className="detail_rightbox_right1">
                            <b> Presentiel</b><br />
                            {DetailTraining.presentiel && DetailTraining.presentiel.presentiel === "1" && <div> Oui </div>}
                            {DetailTraining.presentiel && DetailTraining.presentiel.presentiel !== "1" && <div> Non </div>}
                        </div>

                    </div>


                    <div className="detail_row">
                        <div className="detail_rightbox_left1">
                            <b> Durée</b><br />
                            {DetailTraining["duration"]}  &nbsp; {DetailTraining["duration_unit"]}

                        </div>
                        <div className="detail_rightbox_right1">
                            <b> Langue</b><br />
                            {DetailTraining["lang"]} &nbsp;
                        </div>

                    </div>


                    {DetailTraining.presentiel && DetailTraining.presentiel.presentiel === "0" && <div className="detail_rightbox_left11_red">
                        <b>En ligne </b> <br /> <br /><br />
                    </div>
                    }

                    {DetailTraining.location && <div className="detail_rightbox_right11">
                        <b>A distance </b> <br />

                        <a href="#" alt="redirection vers googlemap">
                            {DetailTraining.location.rue} &nbsp;
                            {DetailTraining.location.ville}  &nbsp;
                            {DetailTraining.location.cp} &nbsp;
                            {DetailTraining.location.pays} &nbsp;
                        </a>
                    </div>
                    }


                    {DetailTraining["description"] && <div className="detail_rightbox_right2">
                        <b> Description</b><br />
                        <p>
                            {DetailTraining["description"]}
                        </p>

                    </div>}

                    {DetailTraining["objectif"] && <div className="detail_rightbox_right2">
                        <b> Objectif</b><br />
                        <p>
                            {DetailTraining["objectif"]} &nbsp;
                        </p>

                    </div>}

                    {DetailTraining["pointsfort"] && <div className="detail_rightbox_right2">
                        <b> Points forts</b><br />
                        <p>
                            {DetailTraining["pointsfort"]} &nbsp;
                        </p>

                    </div>}

                    <div>
                        <br />

                        <div>
                            <div className="detail_rightbox_left1_end"> Vous avez suivi cette formation, votre avis nous interesse </div>
                            <div className="detail_rightbox_right1_end">
                                <Popup className="popup-content_max_601" trigger={<button className="avisformation"> Votre avis nous interesse </button>}
                                    position="left center" >
                                    <div className="popupcontact popup-content_max_601">

                                        {String(userconnected) === "0" && <div>
                                            <div class="form-group">
                                                <label className="koUpdateData" for="exampleFormControlInput1">Email address (*)</label>
                                                <input type="email" class="form-control" id="usermail" name="usermail" placeholder="name@example.com" />
                                            </div>
                                            <br />



                                            <div class="form-group">
                                                <label className="koUpdateData" for="exampleFormControlInput1">Num Telephone (*)</label>
                                                <input type="tel" class="form-control" id="usernumtel" name="usernumtel" placeholder="06 29 ..."
                                                />
                                            </div>

                                            <br />
                                        </div>
                                        }
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Qualité du support</label>
                                            <textarea class="form-control" id="userqualsup" name="userqualsup" rows="2"></textarea>
                                        </div>
                                        <br />
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Votre avis</label>
                                            <textarea class="form-control" id="useravis" name="useravis" rows="5"  ></textarea>
                                        </div>
                                        <br />
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Notes, sur 5</label>
                                            <textarea class="form-control" id="usernote" name="usernote" rows="1" cols="3" onChange={CheckNote}></textarea>
                                        </div>

                                        <br />

                                        <button type="submit" class="btn btn-secondary btn-sm" onClick={contactUs}>Envoyer</button>
                                    </div>

                                </Popup>
                            </div>

                            <div className="detail_rightbox_right2" style={{ textAlign: 'center', border: 0 }}>
                                <FacebookShareButton
                                    url={"https://www.mysy-training.com/Display-Detail-formation/" + DetailTraining['external_code']}

                                    quote={DetailTraining["title"] + " -- " + DetailTraining["description"]}
                                    hashtag={"#MySyTraining"}
                                >
                                    <SocialIcon network="facebook" style={{ height: 35, width: 35 }} />
                                </FacebookShareButton>
                                &nbsp;

                                <TwitterShareButton
                                    url={"https://www.mysy-training.com/Display-Detail-formation/" + DetailTraining['external_code']}
                                    hashtag={"#MySyTraining"}
                                >
                                    <SocialIcon network="twitter" style={{ height: 35, width: 35 }} />
                                </TwitterShareButton>

                                &nbsp;
                                <LinkedinShareButton
                                    url={"https://www.mysy-training.com/Display-Detail-formation/" + DetailTraining['external_code']}
                                    title={" Une nouvelle formation : " + DetailTraining["title"]}
                                    summary={DetailTraining["title"] + " -- " + DetailTraining["description"]}
                                    source={"https://www.mysy-training.com/"}

                                >
                                    <SocialIcon network="linkedin" style={{ height: 35, width: 35 }} />
                                </LinkedinShareButton>


                            </div>
                        </div>
                    </div>


                </div>
            }


            {myApiResponse == "True" &&

                <div className="display_screen_min_601">

                    <div>

                        {/*closepopup == '0' && <Popup trigger={<button className="btn btn-primary"> Contactez nous </button>}>
              <div className="popupcontact">
                <div class="form-group">
                  <label className="koUpdateData" for="exampleFormControlInput1">Email address (*)</label>
                  <input type="email" class="form-control" id="usermail" name="usermail" placeholder="name@example.com" />
                </div>
                <br />

                <div class="form-group">
                  <label className="koUpdateData" for="exampleFormControlInput1">Num Telephone (*)</label>
                  <input type="tel" class="form-control" id="usernumtel" name="usernumtel" placeholder="06 29 ..."
                  />
                </div>
                                                                                                                                                          .-0

                <br />
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Commentaire</label>
                  <textarea class="form-control" name="usercomment" rows="2"></textarea>
                </div>


                <button type="submit" class="btn btn-secondary btn-sm" onClick={contactUs}>Envoyer</button>
              </div>

            </Popup>
    */ }
                    </div>

                    <div className="detail_leftbox">


                        <div className="pub">
                            <div className="slide-container">
                                <Fade>
                                    {fadeImages.map((fadeImage, index) => (
                                        <div className="each-fade" key={index}>
                                            <div className="image-container">
                                                <img src={fadeImage.url} className="slide_img" />
                                            </div>
                                            <h2>{fadeImage.caption}</h2>
                                        </div>
                                    ))}
                                </Fade>
                            </div>

                        </div>


                    </div>



                    <div className="detail_rightbox">

                        <div className="detail_rightbox_left">
                            <b>Titre :</b> <br />
                            <p> {DetailTraining["title"]} </p>


                        </div>
                        <div className="detail_rightbox_right">

                            <Button variant="contained"
                                color="success"
                                className="accesformation"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                        DetailTraining["url"],
                                        '_blank'
                                    );

                                }}> Accedez à la formation</Button>
                        </div>


                        <br />
                        <br />


                        <hr />
                        <b> Description</b><br />
                        <p>
                            {DetailTraining["description"]}
                        </p>
                        {DetailTraining["objectif"] && <div>
                            <hr />
                            <b> Objectif</b><br />
                            <p>
                                {DetailTraining["objectif"]} &nbsp;
                            </p>

                        </div>}

                        {DetailTraining["pointsfort"] && <div>
                            <hr />
                            <b> Points forts</b><br />
                            <p>
                                {DetailTraining["pointsfort"]} &nbsp;
                            </p>

                        </div>}

                        <hr />
                        <div className="block">
                            <b> Formateur</b><br />
                            {DetailTraining["trainer"]}
                        </div>
                        <div className="block">
                            <b> Prix</b><br />

                            {DetailTraining["price"]}

                        </div>
                        <div className="block">
                            <b> langue</b><br />

                            {DetailTraining["lang"]}
                        </div>
                        <div className="detail_row">
                            <hr />
                        </div>

                        <div className="block">
                            <b> Durée</b><br />

                            {DetailTraining["duration"]}  &nbsp; {DetailTraining["duration_unit"]}
                        </div>



                        {DetailTraining["certif"] && <div className="block">
                            <b> Certifiante</b><br />
                            {DetailTraining["certif"] && String(DetailTraining["certif"]) !== String("1") && <div>

                                Non  &nbsp; </div>
                            }


                            {DetailTraining["certif"] && String(DetailTraining["certif"]) === String("1") && <div>

                                Oui  &nbsp; </div>
                            }


                        </div>}


                        <div className="block">
                            <b> Distanciel</b><br />
                            {DetailTraining.presentiel && DetailTraining.presentiel.distantiel === "1" && <div> Oui </div>}
                            {DetailTraining.presentiel && DetailTraining.presentiel.distantiel !== "1" && <div> Non </div>}

                        </div>
                        <div className="block">
                            <b> Presentiel</b><br />
                            {DetailTraining.presentiel && DetailTraining.presentiel.presentiel === "1" && <div> Oui </div>}
                            {DetailTraining.presentiel && DetailTraining.presentiel.presentiel !== "1" && <div> Non </div>}

                        </div>

                        <div>
                            <br />
                            <br />
                            <div className="detail_rightbox_left">
                                <br />
                                {/* <p style={{ "color": "red", "font-style": "italic" }}> Vous avez suivi cette formation, partager votre avis </p>
*/}
                            </div>
                            <div className="detail_rightbox_right">

                                <Popup trigger={<button className="avisformation"> Votre avis nous interesse </button>}
                                    position="left center" className="popup-content2">
                                    <div className="popupcontact popup-content2">

                                        {String(userconnected) === "0" && <div>
                                            <div class="form-group">
                                                <label className="koUpdateData" for="exampleFormControlInput1">Email address (*)</label>
                                                <input type="email" class="form-control" id="usermail" name="usermail" placeholder="name@example.com" />
                                            </div>
                                            <br />



                                            <div class="form-group">
                                                <label className="koUpdateData" for="exampleFormControlInput1">Num Telephone (*)</label>
                                                <input type="tel" class="form-control" id="usernumtel" name="usernumtel" placeholder="06 29 ..."
                                                />
                                            </div>

                                            <br />
                                        </div>
                                        }
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Qualité du support</label>
                                            <textarea class="form-control" id="userqualsup" name="userqualsup" rows="2"></textarea>
                                        </div>
                                        <br />
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Votre avis</label>
                                            <textarea class="form-control" id="useravis" name="useravis" rows="5"  ></textarea>
                                        </div>
                                        <br />
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Notes, sur 5</label>
                                            <textarea class="form-control" id="usernote" name="usernote" rows="1" cols="3" onChange={CheckNote}></textarea>
                                        </div>

                                        <br />

                                        <button type="submit" class="btn btn-secondary btn-sm" onClick={contactUs}>Envoyer</button>
                                    </div>

                                </Popup>
                            </div>

                        </div>

                        <div>



                        </div>
                        <div className="detail_rightbox_right">
                            <FacebookShareButton
                                url={"https://www.mysy-training.com/Display-Detail-formation/" + DetailTraining['external_code']}

                                quote={DetailTraining["title"] + " -- " + DetailTraining["description"]}
                                hashtag={"#MySyTraining"}
                            >
                                <SocialIcon network="facebook" style={{ height: 35, width: 35 }} />
                            </FacebookShareButton>
                            &nbsp;

                            <TwitterShareButton
                                url={"https://www.mysy-training.com/Display-Detail-formation/" + DetailTraining['external_code']}
                                hashtag={"#MySyTraining"}
                            >
                                <SocialIcon network="twitter" style={{ height: 35, width: 35 }} />
                            </TwitterShareButton>

                            &nbsp;
                            <LinkedinShareButton
                                url={"https://www.mysy-training.com/Display-Detail-formation/" + DetailTraining['external_code']}
                                title={" Une nouvelle formation : " + DetailTraining["title"]}
                                summary={DetailTraining["title"] + " -- " + DetailTraining["description"]}
                                source={"https://www.mysy-training.com/"}

                            >
                                <SocialIcon network="linkedin" style={{ height: 35, width: 35 }} />
                            </LinkedinShareButton>


                        </div>
                    </div>

                </div>

            }

        </div>
    )
}


export default DisplayDetailClass;