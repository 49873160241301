import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";

const Partner_Product_Service = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    const [rows_affectations, setrows_affectations] = useState([]);
    const [selectionModel_affectations, setSelectionModel_affectations] = React.useState([]);


    const [rows_ressources_associes, setrows_ressources_associes] = useState([]);
    const [selectionModel_ressources_associes, setselectionModel_ressources_associes] = React.useState([]);


    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState("");

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState("");

    const [gridline_id, setgridline_id] = useState("");
    const [gridline_affectation_id, setgridline_affectation_id] = useState("");

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'code', flex: 1, },
        { field: 'nom', headerName: 'Nom', flex: 1, hide: false, },
        { field: 'famille', headerName: 'Famille', flex: 1, hide: false, },
        { field: 'type', headerName: 'Type', width: 150, hide: false, },
        { field: 'code_taxe', headerName: 'Taxe', width: 150, hide: false, },
        { field: 'prix_vente', headerName: 'P. Vente', width: 150, hide: false, },

        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le produit sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]

    function clean_all_filters() {

        setgridline_id('');
        setgridline_affectation_id('');
        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");


        setSelectionModel([]);
        setsubmenu("");

        Getall_Partner_Product_Service();



    }

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const myRef_head = useRef(null);

    const [isLoading, setLoading] = useState();



    function handleClick_delete(event, cellValues) {

        var local_product_id = cellValues.row._id;

        Delete_Product_Service(local_product_id);
    }

    const [Delete_Product_Service_api, setDelete_Product_Service_api] = useState();
    const [Delete_Product_Service_message, setDelete_Product_Service_message] = useState();
    const [Delete_Product_Service_result, setDelete_Product_Service_result] = useState();
    function Delete_Product_Service(local_rh_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_rh_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Partner_Produit_Service/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Product_Service  res.data.status = " + res.data.status);
            //console.log(" In Delete_Product_Service  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Product_Service_api("true");
                setDelete_Product_Service_result(res.data.message);
                clean_all_filters();

                Getall_Partner_Product_Service();

                alert(res.data.message)
            }
            else {
                setDelete_Product_Service_api("false");
                setDelete_Product_Service_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {

            console.log('Delete_Product_Service : Not good man :( Delete_Product_Service = ' + error);
            setDelete_Product_Service_api("false");
            alert(" Impossible de supprimer le produit");
        })
    }

    const New_Option = [

        { "id": "0", "label": "Employe", "value": "ressource_humaine" },
        { "id": "1", "label": "Formation", "value": "myclass" },
        { "id": "2", "label": "Session de formation", "value": "session_formation" },
        { "id": "2", "label": "Materiel", "value": "ressource_materielle" },
    ]



    const New_Option_type_produit = [
        { "id": "produit", "label": "Produit", "value": "produit" },
        { "id": "service", "label": "Service", "value": "service" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_Filter = [
        { "id": "nom", "label": "Nom Produit", "value": "nom" },
        { "id": "code", "label": "Code Produit", "value": "code" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]

    const [New_Getall_Partner_Product_Service_result, setNew_Getall_Partner_Product_Service_result] = useState([]);

    const [Getall_Partner_Product_Service_api, setGetall_Partner_Product_Service_api] = useState();
    const [Getall_Partner_Product_Service_message, setGetall_Partner_Product_Service_message] = useState();
    const [Getall_Partner_Product_Service_result, setGetall_Partner_Product_Service_result] = useState([]);
    function Getall_Partner_Product_Service(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Produit_Service/";


        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Product_Service  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Product_Service  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Product_Service_api("true");
                setGetall_Partner_Product_Service_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_type = JSON.parse(x).type;
                    var local_code_taxe = JSON.parse(x).code_taxe;
                    var local_prix_vente = JSON.parse(x).prix_vente;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + "-" + local_nom,
                        "code": local_code,
                        "nom": local_nom,
                        "type": local_type,
                        "code_taxe": local_code_taxe,
                        "prix_vente": local_prix_vente,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "nom": "",
                    "type": "",
                    "code_taxe": "",
                    "prix_vente": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Product_Service_result(new_data2);


                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Product_Service_api("false");
                setGetall_Partner_Product_Service_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Product_Service = ', error);
            setGetall_Partner_Product_Service_api("false");
            alert(" Impossible de recuperer la liste des produits et services");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Partner_Product_Service_With_Filter_api, setGetall_Partner_Product_Service_With_Filter_api] = useState();
    const [Getall_Partner_Product_Service_With_Filter_message, setGetall_Partner_Product_Service_With_Filter_message] = useState();
    const [Getall_Partner_Product_Service_With_Filter_result, setGetall_Partner_Product_Service_With_Filter_result] = useState();
    function Getall_Partner_Product_Service_With_Filter(event) {

        var form = new FormData();

        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Produit_Service_with_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Product_Service_With_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Product_Service_With_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Product_Service_With_Filter_api("true");
                setGetall_Partner_Product_Service_With_Filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Product_Service_With_Filter_api("false");
                setGetall_Partner_Product_Service_With_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Product_Service_With_Filter = ', error);
            setGetall_Partner_Product_Service_With_Filter_api("false");
            alert(" Impossible de recuperer la liste des produits et services");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {

        Getall_Partner_Product_Service();


        if (props.class_external_code && String(props.class_external_code).length > 3) {
            setadd_One_Product();
            setdisplay_detail_produit("1");
            setsubmenu("detail_produit");

            /* 
            Dans le cas précis des produits et service, "props.class_external_code" est utilisé comme
            l'_id du produit*/

            //console.log(" ### Vos Prix   props.class_external_code = ", props.class_external_code);

            setselected_id(props.class_external_code);
            Display_Detail_Product_Data(props.class_external_code);


            if (document.getElementById('myRef')) {
                var divh = document.getElementById('myRef').offsetTop;
                window.scrollTo({
                    top: divh,
                    behavior: "smooth",
                });
            }


        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [])


    /****** Gestion image */
    const [session_file_name, setsession_file_name] = useState();
    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();

    const [isimgclassdeleted, setisimgclassdeleted] = useState("");
    const [isimgclassdeleted_message, setisimgclassdeleted_message] = useState("");
    const [isimgclassSaved, setisimgclassSaved] = useState("");
    const [isimgclassSaved_message, setisimgclassSaved_message] = useState("");
    const [userimgclassprofil, setuserimgclassprofil] = useState();
    const [userimgclassprofilchanged, setuserimgclassprofilchanged] = useState("");

    function removeRecodedLogoImage() {
        if (!isimgclassSelected_recid || String(isimgclassSelected_recid).length < 3) {
            alert(" Image incorrecte. Impossible de la supprimer ");
            return;
        }

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("image_recid", isimgclassSelected_recid);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/DeleteImage_Partner_Product/",
            {
                method: 'POST',
                body: form,
            }
        ).then((response) => response.json())
            .then((result) => {

                console.log(' removeRecodedClassImage : status:', result['status']);
                console.log('removeRecodedClassImage : Success:', result['message']);


                if (String(result['status']) === String("true")) {
                    setisimgclassSelected("");
                    setisimgclassSelected_recid("");
                    setisimgclassSelectedfile("");
                    setuserimgclassprofil("");
                    get_Product_Images();

                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                    return;
                }

                else {
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                alert(" Impossible de supprimer l'image ");
            });

    }


    const imglogoclasschangeHandler = (event) => {


        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        //console.log(event.target.files);

        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;



        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 1000000) {
            alert("L'image ne doit pas dépasser un 1 Méga octets");
            return;
        }
        setisimgclassSelected(event.target.files[0]);
        setisimgclassSelectedfile(true);

        setuserimgclassprofil(URL.createObjectURL(event.target.files[0]));
        setuserimgclassprofilchanged("1");

    };


    const [get_Product_Images_api, setget_Product_Images_api] = useState();
    const [get_Product_Images_message, setget_Product_Images_message] = useState();
    const [get_Product_Images_result, setget_Product_Images_result] = useState();
    function get_Product_Images(product_id) {

        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecoded_Partner_Product_Image_from_front/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", product_id);


        axios.post(myurl, form).then(res => {

            //console.log(" get_Product_Images : In test  res.data.status = " + res.data.status);
            // console.log(" get_Product_Images: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setget_Product_Images_api("true");

                if (JSON.parse(res.data.message).logo_img) {
                    var partner_logo_img = "data:image/png;base64," + JSON.parse(res.data.message).logo_img;
                    setisimgclassSelected(partner_logo_img);
                    setuserimgclassprofil(partner_logo_img);
                    setisimgclassSelected_recid(JSON.parse(res.data.message).logo_img_recid);
                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setget_Product_Images_api("false");
                get_Product_Images_message(res.data.message)
                alert(res.data.message);

            }
        }).catch((error) => {
            console.warn('get_Product_Images ee: Not good man :(  = ', error);
            setget_Product_Images_api("false");
            get_Product_Images_message(" Impossible de recuperer l'image du produit")
        })

    }


    const [record_product_Image_api, setrecord_product_Image_api] = useState();
    const [record_product_Image_message, setrecord_product_Image_message] = useState();
    const [record_product_Image_result, setrecord_product_Image_result] = useState();
    function record_product_Image() {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Product_Image/";

        const stored_cookie = getCookie('tokenmysypart');
        formData.append("token", stored_cookie);
        formData.append("_id", selected_id);

        formData.append('file_img', isimgclassSelected);
        formData.append('file_img_recid', isimgclassSelected_recid);


        //console.log(" ### formData = ", formData);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result['message'], "result['status'] = ", result['status']);

                if (String(result['status']) === String("true")) {
                    setrecord_product_Image_api("true");
                    setuserimgclassprofilchanged("");
                    alert(" L'image a été enregistrée ");

                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    setrecord_product_Image_api("false");
                    alert(result['message']);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setrecord_product_Image_api("false");
                alert(" Erreur : Impossible d'enregistrer l'image ");

            });


    };

    /******* Fin gestion image */

    async function submenu_detail_produit() {
        setsubmenu("detail_produit");

    }
    const [selected_id, setselected_id] = useState("");
    const [selected_employe_email, setselected_employe_email] = useState("");
    function handleClick_edit_produit_service_From_Line(selected_row_id) {

        submenu_detail_produit();

        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var product_id = line._id;
        setselected_id(product_id);

        //console.log(" ### employe_id  = ", employe_id);
        Display_Detail_Product_Data(product_id);


        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    const [product_data_changed, setproduct_data_changed] = useState();
    const [display_detail_produit, setdisplay_detail_produit] = React.useState();

    const [add_One_Product, setadd_One_Product] = useState();
    const [isimgclassSelected, setisimgclassSelected] = useState(false);
    const [isimgclassSelected_recid, setisimgclassSelected_recid] = useState("");
    const [isimgclassSelectedfile, setisimgclassSelectedfile] = useState(false);


    const [p_detail_code, setp_detail_code] = useState();
    const [p_detail_nom, setp_detail_nom] = useState();
    const [p_detail_description, setp_detail_description] = useState("");
    const [p_detail_detail, setp_detail_detail] = useState("");
    const [p_detail_famille, setp_detail_famille] = useState("");
    const [p_detail_code_taxe, setp_detail_code_taxe] = useState("");
    const [p_detail_type, setp_detail_type] = useState("");
    const [p_detail_prix_vente, setp_detail_prix_vente] = useState("");



    function Display_Detail_Product_Data(local_product_id) {
        //clearDetailAttendeFields();
        setadd_One_Product();
        setdisplay_detail_produit("1")


        Get_Product_Data(local_product_id);
        get_Product_Images(local_product_id);

        // console.log(" eeeeee local_product_id = ", local_product_id);
    }


    const [Get_Product_Data_api, setGet_Product_Data_api] = useState();
    const [Get_Product_Data_message, setGet_Product_Data_message] = useState();
    const [Get_Product_Data_result, setGet_Product_Data_result] = useState();
    function Get_Product_Data(materiel_id) {
        setproduct_data_changed("");
        setproduct_data_edit_mode("");
        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", materiel_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Produit_Service/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Product_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Product_Data  res.data.message r_class = " + res.data.message);
                setGet_Product_Data_api("true");
                setGet_Product_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_materiel = JSON.parse(res.data.message);


                    if (mylocal_materiel.code)
                        setp_detail_code(mylocal_materiel.code);


                    if (mylocal_materiel.nom)
                        setp_detail_nom(mylocal_materiel.nom);
                    else
                        setp_detail_nom("");


                    if (mylocal_materiel.description)
                        setp_detail_description(mylocal_materiel.description);
                    else
                        setp_detail_description("");


                    if (mylocal_materiel.famille)
                        setp_detail_famille(mylocal_materiel.famille);
                    else
                        setp_detail_famille("");


                    if (mylocal_materiel.code_taxe)
                        setp_detail_code_taxe(mylocal_materiel.code_taxe);
                    else
                        setp_detail_code_taxe("");

                    if (mylocal_materiel.prix_vente)
                        setp_detail_prix_vente(mylocal_materiel.prix_vente);
                    else
                        setp_detail_prix_vente("");


                    if (mylocal_materiel.type)
                        setp_detail_type(mylocal_materiel.type);
                    else
                        setp_detail_type("");


                    disable_Product_DetailFields();
                } else {
                    alert(" Aucun produit ou service recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Product_Data_api("false");
                setGet_Product_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Product_Data = ', error);
            setGet_Product_Data_api("false");
            alert(" Impossible de recuperer les données du produit ");
            //setmyApimyApiMessage("")
        })
    }

    const [product_data_edit_mode, setproduct_data_edit_mode] = useState("");

    function disable_Product_DetailFields() {
        setproduct_data_edit_mode("0");

        const list_champs = ["detail_code", "detail_nom", "detail_description", "detail_famille", "detail_code_taxe",
            "detail_type", "detail_prix_vente"]

        for (let i = 0; i < list_champs.length; i++) {
            if (document.getElementsByName(String(list_champs[i])) && document.getElementsByName(String(list_champs[i]))[0]) {

                document.getElementsByName(String(list_champs[i]))[0].disabled = true;
                document.getElementsByName(String(list_champs[i]))[0].style.backgroundColor = "#ECEFF1";

            }
        }

    }

    function Enable_Product_DetailFields() {
        setproduct_data_edit_mode("1");

        const list_champs = ["detail_code", "detail_nom", "detail_description", "detail_famille", "detail_code_taxe",
            "detail_type", "detail_prix_vente"]

        for (let i = 0; i < list_champs.length; i++) {
            if (document.getElementsByName(String(list_champs[i])) && document.getElementsByName(String(list_champs[i]))[0]) {

                document.getElementsByName(String(list_champs[i]))[0].disabled = false;
                document.getElementsByName(String(list_champs[i]))[0].style.backgroundColor = "#FFFFFF";

            }
        }
    }



    function Annule_Product_DetailFields() {
        setisimgclassSelected();
        setuserimgclassprofil();
        setisimgclassSelected_recid();

        Get_Product_Data(selected_id);
        get_Product_Images(selected_id);

        setproduct_data_changed("");
        setproduct_data_edit_mode("");

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }



    const [Update_One_Product_Data_api, setUpdate_One_Product_Data_api] = useState();
    const [Update_One_Product_Data_message, setUpdate_One_Product_Data_message] = useState();
    const [Update_One_Product_Data_result, setUpdate_One_Product_Data_result] = useState();
    function Update_One_Product_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_code);

        if (p_detail_nom)
            form.append("nom", p_detail_nom);
        else
            form.append("nom", "");


        if (p_detail_description)
            form.append("description", p_detail_description);
        else
            form.append("description", "");


        if (p_detail_famille)
            form.append("famille", p_detail_famille);
        else
            form.append("famille", "");

        if (p_detail_code_taxe)
            form.append("code_taxe", p_detail_code_taxe);
        else
            form.append("code_taxe", "");

        if (p_detail_prix_vente)
            form.append("prix_vente", p_detail_prix_vente);
        else
            form.append("prix_vente", "");


        if (p_detail_type)
            form.append("type", p_detail_type);
        else
            form.append("type", "");


        form.append("_id", selected_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Produit_Service/";

        axios.post(myurl, form).then(res => {
            console.log(" In Update_One_Product_Data  res.data.status = " + res.data.status);
            console.log(" In Update_One_Product_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setUpdate_One_Product_Data_api("true");
                setUpdate_One_Product_Data_result(res.data.message);

                Getall_Partner_Product_Service();

                disable_Product_DetailFields();
                setproduct_data_changed("");
                setproduct_data_edit_mode("");



                setadd_One_Product("");
                // setgridline_id("");
                //Get_Materiel_Data(selected_id);
                Display_Detail_Product_Data(selected_id);

                /*   window.scrollTo({
                       top: 0,
                       behavior: "smooth",
                   });*/

                alert(res.data.message);

            }
            else {
                setUpdate_One_Product_Data_api("false");
                setUpdate_One_Product_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('UpdateStagiaireData : Not good man :( Update_One_Product_Data = ' + error);
            setUpdate_One_Product_Data_api("false");
            alert(" Impossible de mettre à jour le produit");

        })
    }

    const myRef = useRef(null)

    const [p_one_detail_code, setp_one_detail_code] = useState();
    const [p_one_detail_nom, setp_one_detail_nom] = useState();
    const [p_one_detail_description, setp_one_detail_description] = useState();
    const [p_one_detail_famille, setp_one_detail_famille] = useState();
    const [p_one_detail_code_taxe, setp_one_detail_code_taxe] = useState();
    const [p_one_detail_type, setp_one_detail_type] = useState();
    const [p_one_detail_prix_vente, setp_one_detail_prix_vente] = useState();

    const [Add_One_Product_Data_api, setAdd_One_Product_Data_api] = useState();
    const [Add_One_Product_Data_message, setAdd_One_Product_Data_message] = useState();
    const [Add_One_Product_Data_result, setAdd_One_Product_Data_result] = useState();
    function Add_One_Product_Data() {
        var form = new FormData();


        if (p_one_detail_code.trim() == "") {
            alert(" Vous devez saisir un code pour le produit");
            return;
        }


        if (p_one_detail_nom.trim() == "") {
            alert(" Vous devez saisir le nom du materiel");
            return;
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_one_detail_code);
        form.append("nom", p_one_detail_nom);



        if (p_one_detail_description)
            form.append("description", p_one_detail_description);
        else
            form.append("description", "");


        if (p_one_detail_famille)
            form.append("famille", p_one_detail_famille);
        else
            form.append("famille", "");


        if (p_one_detail_code_taxe)
            form.append("code_taxe", p_one_detail_code_taxe);
        else
            form.append("code_taxe", "");

        if (p_one_detail_type)
            form.append("type", p_one_detail_type);
        else
            form.append("type", "");

        if (p_one_detail_type)
            form.append("prix_vente", p_one_detail_prix_vente);
        else
            form.append("prix_vente", "");



        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Produit_Service/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_One_Product_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_One_Product_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_One_Product_Data_api("true");
                setAdd_One_Product_Data_result(res.data.message);

                Getall_Partner_Product_Service();

                setproduct_data_changed("");
                setproduct_data_edit_mode("");
                clean_detail_fields();

                setsubmenu("");
                setadd_One_Product("");

                alert(res.data.message);

                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_One_Product_Data_api("false");
                setAdd_One_Product_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_One_Product_Data = ' + error);
            setAdd_One_Product_Data_api("false");
            alert(" Impossible d'ajouter le produit");

        })
    }


    function submenu_add_one_product() {

        clean_detail_fields();
        setdisplay_detail_produit();
        setadd_One_Product("1");
        setsubmenu("detail_produit");

        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }

    function Annule_add_one_product() {
        setdisplay_detail_produit();
        setadd_One_Product();
        setsubmenu("");
        setSelectionModel([]);
        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }
    }


    function clean_detail_fields() {
        setp_one_detail_code("");
        setp_one_detail_nom("");
        setp_one_detail_description("");
        setp_one_detail_famille("");
        setp_one_detail_code_taxe("");
        setp_one_detail_prix_vente("");

        setgridline_id("");

    }


    return (
        <div className="partner_product_service">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            <h3> Vos Prix  </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >
                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value();
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}

                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Partner_Product_Service_With_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            {/* Datagrid */}
            <div className="div_row">
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },

                                    /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                        "& .MuiDataGrid-row": {
                                            "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                        }
                                    },*/
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },
                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_materiel_From_Line(newSelectionModel);
                                            */
                                        if (newSelectionModel.length !== 1) {
                                            setsubmenu();
                                            // setdisplay_detail_materiel();
                                            // setadd_One_Materiel();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            email: JSON.parse(item).email,
                                            nom: JSON.parse(item).nom,
                                            code: JSON.parse(item).code,
                                            famille: JSON.parse(item).famille,
                                            type: JSON.parse(item).type,
                                            code_taxe: JSON.parse(item).code_taxe,
                                            prix_vente: JSON.parse(item).prix_vente,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {

                                        setgridline_id(newSelectionModel.row.id);
                                        handleClick_edit_produit_service_From_Line(newSelectionModel.row.id);

                                    }}


                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                    }}
                                    getRowClassName={(params) => {



                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>

                </div>

                <div className="div_row" style={{ "border": "None" }}>

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>

                        {/* <Button variant="outlined" onClick={submenu_import_materiel} className="detail_class_submenu bton_import_excel"
                            id='menu_import_participant' name='menu_import_participant'>Importer du materiel Excel &nbsp;
                            <img src={excel_icone} alt="ajout csv" className="icon_excel" />
                        </Button>
                                */}


                        <Button variant="outlined" onClick={submenu_add_one_product}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Produit &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                        <br />
                        {session_file_name && <nav><i>{session_file_name}</i></nav>}
                        {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Le materiel ont été correctement importé</nav>}
                        {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}

                        {/*
                        <input type="file"
                            accept=".csv"
                            ref={hiddenFileInput_session}
                            style={{ display: 'none' }}
                            name="liste_sessions_file"
                            onChange={liste_sessions_file_change}
                        />

                        <a href='/sample/template_import_employes.csv' download>Télécharger un fichier modèle</a>
                            */}
                    </div>


                </div>
                <div className="div_row"> &nbsp;</div>


            </div>

            <div className="div_row" ref={myRef} id="myRef"> </div>
            {/* Detail */}


            {String(submenu) === "detail_produit" && String(add_One_Product) !== "1" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_produit} className="detail_class_submenu" id='detail_materiel' name='detail_materiel'>Détail Produit</Button>
                    <Button variant="outlined" onClick={"submenu_affectation"} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={"submenu_ressource_associe"} className="detail_class_submenu" id='ressource_associe' name='affectation'>Ressources Associées  </Button>
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Détail Produit </nav>
                <div className="div_row" style={{ "border": "None" }}>
                    <div className="div_row_gauche">

                        <div className="div_row_bis" style={{ "marginBottom": "10px", "marginTop": "1rem" }} >
                            <div className="div_row_gauche_image">
                                <img class="img_class_logo" src={userimgclassprofil} /><br />

                            </div>
                            <div className="div_row_droite_image">
                                <input type="file" id="files_img_class" accept="image/*" onChange={imglogoclasschangeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} /><br />
                                {<nav>

                                    <label for="files_img_class" className="bton_image_class">Charger une photo</label><br />

                                    {String(userimgclassprofilchanged) === "1" &&
                                        <nav className="bton_image_class" onClick={record_product_Image}>Enregistrer la photo<br />  </nav>
                                    }

                                    {String(isimgclassSelected_recid) && String(isimgclassSelected_recid).length > 3 &&
                                        <nav className="bton_supprime_image_class" onClick={removeRecodedLogoImage}>Supprimer la photo<br />  </nav>
                                    }
                                    {String(userimgclassprofilchanged) === "1" &&
                                        <div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer la photo
                                        </div>}


                                </nav>}

                                {/*isimgclassSelected && <Button variant="outlined" onClick={sendClassImage} className="bton_image_class">Enregistrer image</Button>*/}
                                {isimgclassdeleted && String(isimgclassdeleted) === "1" && <nav className="koUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassdeleted && String(isimgclassdeleted) === "0" && <nav className="okUpdateData"> {isimgclassdeleted_message}</nav>}
                                {isimgclassSelected && String(isimgclassSaved) === "0" && <nav className="koUpdateData"> {isimgclassSaved_message}</nav>}
                            </div>

                        </div>
                        <div className="div_row_bis tips_img_class">
                            <li>
                                L'image doit etre carrée.
                            </li>
                            <li>
                                Les formats autorisés sont : <i>['jpg', 'jpeg', 'png', 'jpe', 'webp']</i>
                            </li>
                            <li>
                                Les dimensions recommandées: 128pixels X 128pixels --   144pixels X 144pixels --  168pixels X 168pixels<br />
                                Les dimensions maximales sont de 256pixels X 256pixels
                            </li>
                            <li>
                                La taille de l'image ne doit pas dépasser 1 mega octet
                            </li>
                        </div>

                    </div>
                </div>
                <div className="session_data" >
                    <div className="session_caract"> <b>Code Produit </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_code"
                            id="detail_code"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_code}
                            onChange={(e) => {
                                setp_detail_code(e.target.value);
                                setproduct_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b> Nom </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_nom"
                            id="detail_nom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_nom}
                            onChange={(e) => {
                                setp_detail_nom(e.target.value);
                                setproduct_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Description<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_description"
                            id="detail_description"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_description}
                            onChange={(e) => {
                                setp_detail_description(e.target.value);
                                setproduct_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Famille<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_famille"
                            id="detail_famille"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_famille}
                            onChange={(e) => {
                                setp_detail_famille(e.target.value);
                                setproduct_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Code taxe<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_code_taxe"
                            id="detail_code_taxe"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_code_taxe}
                            onChange={(e) => {
                                setp_detail_code_taxe(e.target.value);
                                setproduct_data_changed("1");
                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Prix de vente<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_prix_vente"
                            id="detail_prix_vente"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            type={"number"}
                            inputProps={{ min: "1", max: "999999", step: "0.1" }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_prix_vente}
                            onChange={(e) => {
                                setp_detail_prix_vente(e.target.value);
                                setproduct_data_changed("1");
                            }
                            }

                        />
                    </div>


                    {String(product_data_edit_mode) !== "1" && <div className="session_caract"> Type<br />

                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="detail_type"
                            id="detail_type"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_detail_type}

                        />
                    </div>}

                    {String(product_data_edit_mode) === "1" && <div className="session_caract"> Type<br />

                        <Autocomplete
                            disablePortal
                            name="detail_type"
                            id="detail_type"
                            className="disabled_style"
                            //className="disabled_style enable_style"
                            options={New_Option_type_produit}
                            value={New_Option_type_produit.filter((data) => (data).id === String(p_detail_type))[0].label}

                            onChange={(event, value) => {
                                if (value && value.id) {
                                    setp_detail_type(value.id);
                                    setproduct_data_changed("1");

                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />

                            }
                        />

                    </div>}



                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    {String(product_data_changed) === "1" && String(product_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>}

                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {String(product_data_changed) === "1" && String(product_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Update_One_Product_Data}>Enregistrer les modifications
                            </Button>

                        </div>}

                        {String(product_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_Product_DetailFields}>Annuler
                            </Button>
                        </div>}
                    </div>


                    {String(product_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                        <Button variant="contained" className="bton_edit" onClick={Enable_Product_DetailFields}>Editer
                        </Button>

                    </div>}
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                </div>


            </div>}

            {String(submenu) === "detail_produit" && String(add_One_Product) === "1" && <div className="div_row">
                <div className="div_row">
                    <Button variant="outlined" onClick={submenu_detail_produit} className="detail_class_submenu" id='detail_materiel' name='detail_materiel'> Détail Produit</Button>
                    {/*<Button variant="outlined" onClick={submenu_affectation} className="detail_class_submenu" id='affectation' name='affectation'>Affectation  </Button>
                    <Button variant="outlined" onClick={submenu_ressource_associe} className="detail_class_submenu" id='ressource_associe' name='affectation'>Ressources Associées  </Button>
                        */}
                </div>
                <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600" }}> Ajouter un  nouveau  produit/service </nav>
                <div className="session_data" >
                    <div className="session_caract"> <b>Code produit </b> <br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_code"
                            id="p_one_detail_code"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_code}
                            onChange={(e) => {
                                setp_one_detail_code(e.target.value);

                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> <b> Nom </b><br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_nom"
                            id="p_one_detail_nom"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_nom}
                            onChange={(e) => {
                                setp_one_detail_nom(e.target.value);

                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Description<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_description"
                            id="p_one_detail_description"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_description}
                            onChange={(e) => {
                                setp_one_detail_description(e.target.value);

                            }
                            }

                        />
                    </div>


                    <div className="session_caract"> Famille<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_famille"
                            id="p_one_detail_famille"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_famille}
                            onChange={(e) => {
                                setp_one_detail_famille(e.target.value);

                            }
                            }

                        />
                    </div>

                    <div className="session_caract"> Taux TVA<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_code_taxe"
                            id="p_one_detail_code_taxe"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            type={"number"}
                            inputProps={{ min: "1", max: "999999", step: "0.1" }}
                            className="disabled_style"
                            value={p_one_detail_code_taxe}
                            onChange={(e) => {
                                setp_one_detail_code_taxe(e.target.value);

                            }
                            }

                        />
                    </div>


                    <div className="session_caract"> Prix de Vente<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="p_one_detail_prix_vente"
                            id="p_one_detail_prix_vente"

                            InputLabelProps={{
                                shrink: true,
                            }}

                            type={"number"}
                            inputProps={{ min: "1", max: "999999", step: "0.1" }}

                            disabled={false}
                            className="disabled_style"
                            value={p_one_detail_prix_vente}
                            onChange={(e) => {
                                setp_one_detail_prix_vente(e.target.value);

                            }
                            }

                        />
                    </div>



                    <div className="session_caract"> Type<br />
                        <Autocomplete
                            disablePortal
                            name="p_one_detail_type"
                            id="p_one_detail_type"
                            className="disabled_style"

                            options={New_Option_type_produit}
                            //  value={New_Option_type_produit.filter((data) => (data).id === String(p_one_detail_type))[0].label}


                            onChange={(event, value) => {
                                if (value && value.id) {
                                    setp_one_detail_type(value.id);


                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>


                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>

                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        {<div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_One_Product_Data}>Enregistrer
                            </Button>

                        </div>}

                        {<div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_add_one_product}>Annuler
                            </Button>
                        </div>}
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>

            </div>}

        </div >
    );
}

export default Partner_Product_Service;
