import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import Rating from '@mui/material/Rating';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import 'react-tooltip/dist/react-tooltip.css'

import 'reactjs-popup/dist/index.css';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useParams } from 'react-router-dom'
import Header from "./Header";
import Footer from "./Fotter";
import { FcInfo } from "react-icons/fc";
import { Document, Page } from 'react-pdf';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import { daDK } from "@mui/material/locale";
import Checkbox from '@mui/material/Checkbox';

import Display_Action_And_Class from "./Display_Action_And_Class";


function SurveyDocument() {

    const history = useHistory();
    const { survey_id } = useParams();
    const [isLoading, setLoading] = useState();


    const [tab_variable, settab_variable] = useState([]);


    const [get_Given_Survey_Data_api, setget_Given_Survey_Data_api] = useState();
    const [get_Given_Survey_Data_message, setget_Given_Survey_Data_message] = useState();
    const [get_Given_Survey_Data_result, setget_Given_Survey_Data_result] = useState([]);
    function get_Given_Survey_Data() {


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Survey_Data_No_Token/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        //form.append("token", stored_cookie);
        form.append("survey_id", survey_id);



        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            //console.log(" get_Given_Survey_Data : In test  res.data.status = " + res.data.status);
            console.log(" get_Given_Survey_Data: res.data.message = " + res.data.message);

            if (String(res.data.status) === "true") {
                setget_Given_Survey_Data_api("true");
                setget_Given_Survey_Data_result(res.data.message);

                var new_data2 = {};

                const new_data = JSON.parse(res.data.message).list_questions.map((x) => {
                    var obj_name = x._id;
                    var local_node = {}
                    new_data2[String(obj_name)] = "";


                });

                settab_variable(new_data2);


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setget_Given_Survey_Data_api("false");
                setget_Given_Survey_Data_message(res.data.message)
                alert(res.data.message);

            }
        }).catch((error) => {
            setLoading(false);
            console.warn('get_Given_Survey_Data ee: Not good man :(  = ', error);
            setget_Given_Survey_Data_api("false");
            setget_Given_Survey_Data_message(" Impossible de recuperer les données")
        })

    }



    useEffect(() => {

        get_Given_Survey_Data();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])


    const [Record_Survey_Data_api, setRecord_Survey_Data_api] = useState();
    const [Record_Survey_Data_message, setRecord_Survey_Data_message] = useState();
    const [Record_Survey_Data_result, setRecord_Survey_Data_result] = useState();
    function Record_Survey_Data() {
        var form = new FormData();

        const myJSON = JSON.stringify(tab_variable);


        form.append("survey_id", survey_id);
        form.append("list_response", myJSON);

        var is_empty_field = 0;

        console.log(" tab_variable = ", tab_variable);



        for (let i = 0; i < Object.keys(tab_variable).length; i++) {

            var key = Object.keys(tab_variable)[i];
            var val = tab_variable[key];

            document.getElementsByName(key)[0].style.backgroundColor = "#FFFFFF";

            if (String(val).length === 0) {
                document.getElementsByName(key)[0].style.backgroundColor = "#F9E79F";
                is_empty_field = 1;
            }

        }

        if (is_empty_field === 1) {
            alert(" Le questionnaire n'est pas correctement rempli");
            return;
        }


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Record_Survey_Data_No_Token/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Record_Survey_Data  res.data.status = " + res.data.status);
            //console.log(" In Record_Survey_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {

                alert(res.data.message);
                history.push("/");
            }
            else {
                setRecord_Survey_Data_api("false");
                setRecord_Survey_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Record_Survey_Data : Not good man :( Record_Survey_Data = ' + error);
            setRecord_Survey_Data_api("false");
            alert(" Impossible d'enregistrer le formulaire ");

        })
    }



    function change_survey_data(e) {

        var tmp = tab_variable;

        tmp[String(e.name)] = e.value;

        settab_variable(tmp)

    }

    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
        setValue(event.target.value);
    };



    const [selected_qcm_value, setselected_qcm_value] = React.useState('');
    const handleChange_qcm = (event) => {



        document.getElementsByName(event.target.name)[0].value = event.target.value;

        // setselected_qcm_value(event.target.value);
        change_survey_data(event.target);
    };

    const [selected_checkbox_value, setselected_checkbox_value] = React.useState('');
    const handleChange_checkbox = (event) => {
        var tmp_variable = tab_variable;
        var tab = String(event.target.name).split(":");

        var al = String(tab[0]);

        if (event.currentTarget.checked) {
           // console.log('checked');
            if (!tmp_variable[al] || String(tmp_variable[al]) === "") {
                //console.log(" première action ");
                var t = [];
                t.push(String(tab[1]));
            } else {
                //console.log(" PASSS première action ");
                var t = tmp_variable[al];
                t.push(String(tab[1]));
            }


            tmp_variable[al] = t;

            //console.log(" sortirrr  tmp_variable = ", tmp_variable);
            settab_variable(tmp_variable);

            //console.log(" ###  sortir settab_variable = ", tab_variable);


        }
        else {
            console.log('not checked');

            if (!tmp_variable[al] || String(tmp_variable[al]) === "") {
                console.log(" première action ");

            } else {



                var new_variable = []
                for (let i = 0; i < tmp_variable[al].length; i++) {
                    if (String(tmp_variable[al][i]) !== String(tab[1])) {
                        new_variable.push(String(tmp_variable[al][i]))

                    }
                }

                tmp_variable[al] = new_variable;



                settab_variable(tmp_variable);



            }
        }

    };



    return (
        <div className="surveydocument">


            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Header />

            <div className="div_row">
                &nbsp;
            </div>
            {String(get_Given_Survey_Data_api) === "true" && <div style={{ "textAlign": "left", }}>



                <div style={{ "width": "80%", "marginLeft": "10%", "background": "#F2F4F4", overflow: "auto", "paddingBottom": "1rem" }}>
                    <br />
                    <div className="question_box_introduction">
                        Bonjour   {JSON.parse(get_Given_Survey_Data_result).prenom} &nbsp; {JSON.parse(get_Given_Survey_Data_result).nom}

                        <nav>
                            {JSON.parse(get_Given_Survey_Data_result).message_introduction}
                        </nav>
                    </div>



                    {String(JSON.parse(get_Given_Survey_Data_result).survey_type) === "pos" && <div className="question_box_entete">
                        Questionnaire de positionnement
                    </div>}


                    {String(JSON.parse(get_Given_Survey_Data_result).survey_type) === "hot_eval" && <div className="question_box_entete">
                        Questionnaire d'évaluation
                    </div>}

                    {String(JSON.parse(get_Given_Survey_Data_result).survey_type) === "cold_eval" && <div className="question_box_entete">
                        Questionnaire d'évaluation
                    </div>}

                    {String(JSON.parse(get_Given_Survey_Data_result).survey_type) === "human_eval" && <div className="question_box_entete">
                        Questionnaire d'évaluation pour le formateur <b> {JSON.parse(get_Given_Survey_Data_result).related_rh_data_civilite &&  JSON.parse(get_Given_Survey_Data_result).related_rh_data_civilite}. &nbsp;
                        {JSON.parse(get_Given_Survey_Data_result).related_rh_data_nom &&  JSON.parse(get_Given_Survey_Data_result).related_rh_data_nom} &nbsp;  
                        {JSON.parse(get_Given_Survey_Data_result).related_rh_data_prenom &&  JSON.parse(get_Given_Survey_Data_result).related_rh_data_prenom} </b>
                    </div>}

                    {String(JSON.parse(get_Given_Survey_Data_result).survey_type) === "autre_eval" && <div className="question_box_entete">
                        Questionnaire d'évaluation
                    </div>}

                    <div className="question_box_entete">
                        {JSON.parse(get_Given_Survey_Data_result).class_title && <nav>  Formation :  {JSON.parse(get_Given_Survey_Data_result).class_title}  </nav>}
                        {JSON.parse(get_Given_Survey_Data_result).session_code && <nav>  Code Session :  {JSON.parse(get_Given_Survey_Data_result).session_code}  </nav>}
                        {JSON.parse(get_Given_Survey_Data_result).session_title && <nav>   Titre Session :  {JSON.parse(get_Given_Survey_Data_result).session_title}  </nav>}
                        {JSON.parse(get_Given_Survey_Data_result).session_code_date_debut && <nav>  Date début :  {JSON.parse(get_Given_Survey_Data_result).session_code_date_debut}  </nav>}
                        {JSON.parse(get_Given_Survey_Data_result).session_code_date_fin && <nav>  Date fin :  {JSON.parse(get_Given_Survey_Data_result).session_code_date_fin}  </nav>}
                    </div>

                    {JSON.parse(get_Given_Survey_Data_result).list_questions && JSON.parse(get_Given_Survey_Data_result).list_questions.length > 0 &&
                        JSON.parse(get_Given_Survey_Data_result).list_questions.map((question, index) => (
                            <div>
                                <div className="question_box">
                                    <nav style={{ width: "100%", "float": "left" }}> <nav style={{ width: "5%", "float": "left" }}>  N° {index + 1}. </nav> <nav style={{ fontSize: '16px', "fontWeight": '600', "float": "left", 'color': '#34495E' }}>   {question.question}  </nav> </nav><br />

                                    {question.type && String(question.type) === "text" &&
                                        <TextField
                                            margin="dense"
                                            name={question._id}
                                            fullWidth
                                            type="text"
                                            multiline={true}
                                            rows={2}
                                            onChange={(e) => {
                                                change_survey_data(e.target);
                                            }}

                                        />}

                                    {question.type && String(question.type) === "note" && question.max_note &&
                                        <div id={question._id}
                                            name={question._id}>
                                            <Rating
                                                id={question._id}
                                                name={question._id}
                                                precision={0.5}
                                                defaultValue={0}
                                                max={parseInt(question.max_note)}
                                                onChange={(e) => {
                                                    change_survey_data(e.target);
                                                }}
                                            />

                                        </div>
                                    }




                                    {question.type && String(question.type) === "entier" &&
                                        <div id={question._id}
                                            name={question._id}>


                                            <TextField
                                                name={question._id}
                                                id={question._id}

                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ min: "0", step: "1" }}
                                                disabled={false}
                                                onChange={(e) => {
                                                    change_survey_data(e.target);
                                                }}
                                            />



                                        </div>
                                    }
                                    {question.type && String(question.type) === "bool" &&
                                        <div id={question._id}
                                            name={question._id}>
                                            <FormControl>

                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"

                                                    onChange={(e) => {
                                                        change_survey_data(e.target);
                                                    }}
                                                    name={question._id}
                                                >
                                                    <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                                                    <FormControlLabel value="non" control={<Radio />} label="Non" />

                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    }


                                    {question.type && String(question.type) === "qcm" &&
                                        <div id={question._id}
                                            name={question._id}>
                                            <FormControl>

                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name={question._id}
                                                    //value={selected_qcm_value}
                                                    onChange={handleChange_qcm}
                                                >
                                                    {question.list_choix &&
                                                        question.list_choix.map((list_choix, index) => (

                                                            <FormControlLabel value={list_choix.reponse_qcm} control={<Radio />} label={index + ". " + list_choix.reponse_qcm} />
                                                        ))}
                                                </RadioGroup>
                                            </FormControl>


                                        </div>}


                                    {question.type && String(question.type) === "checkbox" &&
                                        <div id={question._id}
                                            name={question._id}>
                                            <FormControl>

                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"

                                                >
                                                    {question.list_case_cocher &&
                                                        question.list_case_cocher.map((case_cocher, index) => (
                                                            <FormControlLabel control={<Checkbox />} id={question._id + ":" + case_cocher.reponse_checkbox} name={question._id + ":" + case_cocher.reponse_checkbox} onChange={handleChange_checkbox} label={index + ". " + case_cocher.reponse_checkbox} />

                                                        ))}
                                                </RadioGroup>
                                            </FormControl>


                                        </div>}


                                </div>

                                {/*
                                Question : {question.question} <br />
                                Type : {question.type} <br />
                                {question.max_note && question.max_note} <br />

                                */}
                            </div>


                        ))}

                </div>

                <div style={{ "width": "80%", "marginLeft": "10%", "background": "white", }}>
                    <br />

                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Record_Survey_Data}>Envoyer
                            </Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={(event) => {
                                history.push("/");
                            }}  >Annuler
                            </Button>
                        </div>
                    </div>



                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                </div>

                <div style={{ "width": "80%", "marginLeft": "10%", "background": "#F2F4F4", overflow: "auto", "paddingBottom": "1rem" }}>
                    <br />

                    {get_Given_Survey_Data_result && get_Given_Survey_Data_result.length > 0 && JSON.parse(get_Given_Survey_Data_result).class_internal_url &&
                        String(JSON.parse(get_Given_Survey_Data_result).class_internal_url).length > 5 && <div className="question_box_entete">
                            <Display_Action_And_Class classId={JSON.parse(get_Given_Survey_Data_result).class_internal_url} action={""} />

                        </div>}
                </div>

            </div>}

            {String(get_Given_Survey_Data_api) === "false" && <div style={{ height: "40vh" }}>

                Le document est invalide

            </div>}

            <div className="div_row">
                &nbsp;
            </div>


            <div className="pieddepage">
                <Footer />
            </div>
        </div>
    );
}

export default SurveyDocument;