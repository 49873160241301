
import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
//import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
//import timeGridPlugin from "@fullcalendar/timegrid";

import allLocales from '@fullcalendar/core/locales-all'
import { RepeatOnRounded } from "@mui/icons-material";
import { alignPropType } from "react-bootstrap/esm/types";


import ToggleSwitch from "./ToggleSwitch";
import ToggleSwitch_v2_mysy from "./ToggleSwitch_v2_mysy";
import Autocomplete from '@mui/material/Autocomplete';
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FcSearch } from "react-icons/fc";
import { Fab } from "@material-ui/core";
import {
    ListItem,
    ListItemText,
    useTheme,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";

import Draggable, { DraggableCore } from 'react-draggable';




import update from "immutability-helper";

const Test_Drag_Drop = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const [task_column, settask_column] = useState("");

    const [task_column_data, settask_column_data] = useState("");

    const eventHandler = (e, data) => {
        //console.log('Event Type', e.type);
        //console.log({ e, data });

        if (String(e.type) === "mouseup") {

            console.log('Event Type', e.type);

            var al = data.lastX;
            console.log('data lastX', al);

            console.log(" col1_left = ", col1_left);
            console.log(" col2_left = ", col2_left);
            console.log(" col3_left = ", col3_left);




            const new_data = Getall_Partner_CRM_Opport_Step_result.map((x) => {

                var local_code = JSON.parse(x).code;

                var myoffsets = document.getElementById(local_code).getBoundingClientRect();

                if (parseFloat(String(data.lastX)) > parseFloat(String(myoffsets.left))) {
                    settask_column(local_code);
                }


            });
        }

    }

    const [col1_left, setcol1_left] = useState(0);
    const [col2_left, setcol2_left] = useState(0);
    const [col3_left, setcol3_left] = useState(0);

    useEffect(() => {

        Getall_Partner_CRM_Opport_Step();

    }, []);

    const [table_step, settable_step] = useState();

    const New_step_x = [{"step":"nouveau"}, {"step":"qualifie"}, {"step":"proposition"}, {"step":"gagne"}, {"step":"perdu"}]


    const [New_Getall_Partner_CRM_Opport_Step_result, setNew_Getall_Partner_CRM_Opport_Step_result] = useState([]);

    const [Getall_Partner_CRM_Opport_Step_api, setGetall_Partner_CRM_Opport_Step_api] = useState();
    const [Getall_Partner_CRM_Opport_Step_message, setGetall_Partner_CRM_Opport_Step_message] = useState();
    const [Getall_Partner_CRM_Opport_Step_result, setGetall_Partner_CRM_Opport_Step_result] = useState();
    function Getall_Partner_CRM_Opport_Step(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_CRM_List_Opportunite_Etape/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_CRM_Opport_Step  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_CRM_Opport_Step  res.data.message r_class = " + res.data.message);
                setGetall_Partner_CRM_Opport_Step_api("true");
                setGetall_Partner_CRM_Opport_Step_result(res.data.message);

                let table = "<table style='width:100%; position:absolute; top:6rem; zIndex:2' class='step_td'><thead><tr>";
                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;
                    var local_rang = JSON.parse(x).rang;

                    table += "<td class='step_td'>" + local_description + "</td>";


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "description": local_description,
                        "rang": local_rang
                    };
                    new_data2.push(node);

                    var node = {
                        "_id": "",
                        "id": "",
                        "label": "",
                        "code": "",
                        "description": "",
                        "rang": ""
                    };
                    new_data2.push(node);

                });



                table += "</tr></thead>";

                if (new_data2.length > 0) {
                    setNew_Getall_Partner_CRM_Opport_Step_result(new_data2);

                    table += "<tbody style='height:50rem;zIndex:2' class='step_td' ><tr>";

                    var tmp = 0;
                    const new_data = res.data.message.map((x) => {

                        table += "<td class='step_td' id='" + String(JSON.parse(x).code) + "' > &nbsp; </td>";
                        tmp = tmp + 1;

                    });

                    table += "</tr></tbody> </table>";

                    console.log(" ### table = ", table);

                    let element = document.getElementById('cherif');
                    element.innerHTML = table;

                    settable_step(table);
                }

            }
            else {
                setGetall_Partner_CRM_Opport_Step_api("false");
                setGetall_Partner_CRM_Opport_Step_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_CRM_Opport_Step = ', error);
            setGetall_Partner_CRM_Opport_Step_api("false");
            alert(" Impossible de récuperer les étapes");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Get_List_Oppurtunite_With_Filter_result, setNew_Get_List_Oppurtunite_With_Filter_result] = useState([]);

    const [Get_List_Oppurtunite_With_Filter_api, setGet_List_Oppurtunite_With_Filter_api] = useState();
    const [Get_List_Oppurtunite_With_Filter_message, setGet_List_Oppurtunite_With_Filter_message] = useState();
    const [Get_List_Oppurtunite_With_Filter_result, setGet_List_Oppurtunite_With_Filter_result] = useState();
    function Get_List_Oppurtunite_With_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_CRM_Opportunite_with_filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_Oppurtunite_With_Filter  res.data.status = " + res.data.status);
                console.log(" In Get_List_Oppurtunite_With_Filter  res.data.message r_class = " + res.data.message);
                setGet_List_Oppurtunite_With_Filter_api("true");
                setGet_List_Oppurtunite_With_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_statut = JSON.parse(x).statut;
                    var local_contact_nom = JSON.parse(x).contact_nom;
                    var local_contact_email = JSON.parse(x).contact_email;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_statut,
                        "statut": local_statut,
                        "contact_nom": local_contact_nom,
                        "contact_email": local_contact_email,
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Oppurtunite_With_Filter_result(new_data2);

            }
            else {
                setGet_List_Oppurtunite_With_Filter_api("false");
                setGet_List_Oppurtunite_With_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Oppurtunite_With_Filter = ', error);
            setGet_List_Oppurtunite_With_Filter_api("false");
            alert(" Impossible de recuperer la liste des opportunités ");
            //setmyApimyApiMessage("")
        })
    }

    function test_td_element() {

        let element = document.getElementById('cherif');
        element.innerHTML = table_step;

       
    }


  

    return (

        <div className="test_drag_drop">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Test Page Drag & Drop</h3>
            <Button variant="contained"
                color="success"
                className="btn_abonnement"
                onClick={(e) => {
                    Get_List_Oppurtunite_With_Filter();
                }}
            > Get All opportunités
            </Button>

            ---
            <Button variant="contained"
                color="success"
                className="btn_abonnement"
                onClick={(e) => {
                    test_td_element();
                }}
            > Test TD element
            </Button>

            <div className="div_row">

                &nbsp;
            </div>

            <Draggable
                // grid={[10, 5]}
                scale={1}
                defaultPosition={{ x: 10, y: 20 }}
                onMouseDown={eventHandler}
                onStart={eventHandler}
                onDrag={eventHandler}
                onStop={eventHandler}
            >
                <div style={{ border: "2px solid red", padding: "1rem", width: "10%" , "zIndex":"97", "position":"relative"}}>
                    <div className="handle">Drag from here</div>
                    <div> Step = {task_column}</div>
                </div>
            </Draggable>



            {New_Get_List_Oppurtunite_With_Filter_result && New_Get_List_Oppurtunite_With_Filter_result.length > 0 &&
                New_Get_List_Oppurtunite_With_Filter_result.map((data) => (


                    <Draggable
                        // grid={[10, 5]}
                        scale={1}
                        defaultPosition={{ x: parseFloat(document.getElementById(data.statut).getBoundingClientRect().left)+10, y: data.id*2 }}
                        onMouseDown={eventHandler}
                        onStart={eventHandler}
                        onDrag={eventHandler}
                        onStop={eventHandler}
                    >
                        <div style={{ border: "2px solid red", padding: "1rem", width: "10%", "zIndex":"99", "position":"relative"}}>
                            <div className="handle">Drag from here</div>
                            <div> Step = {data.contact_email}</div>
                        </div>
                    </Draggable>

                ))}

            <div style={{ "width": "90%", "marginLeft": "auto", "marginRight": "auto" }}>
                <div className="div_row" id="cherif" style={{ "width": "90%", "marginLeft": "auto", "marginRight": "auto" }}>

                </div>

            </div>


        </div>
    )
}


export default Test_Drag_Drop;
