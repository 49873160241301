import React, { useState, useEffect, useRef } from "react";
import Navigation from "../components/Navigation";
import bannerimg2 from "./../mysy_img/MYSY-LOGO-BLUE.png";
import ok_png from "./../mysy_img/Ok_PNG.png";
import { Helmet } from "react-helmet";
import Footer from "./../components/Fotter";
import Header from "./../components/Header";
import { useParams } from 'react-router-dom';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import axios from "axios";


const Automaticquotation = () => {



    const { objid, partner_owner_recid } = useParams();

    const [tempo, settempo] = useState(".");
    setInterval(check, 3000);


    window.setTimeout(function () {
        if (String(already_run) === "true")
            window.location.href = "/Partner/mes_cmd/";
    }, 10000);


    function check() {
        var val = " . " + tempo;
        //alert(" val = "+val);
        settempo(val);

    }

    const [isLoading, setLoading] = useState(false);

    const [already_run, setalready_run] = useState(false);
    const [quotation_ref, setquotation_ref] = useState("");


    const [Create_Automatic_Quotation_api, setCreate_Automatic_Quotation_api] = useState();
    const [Create_Automatic_Quotation_message, setCreate_Automatic_Quotation_message] = useState();
    const [Create_Automatic_Quotation_result, setCreate_Automatic_Quotation_result] = useState();
    function Create_Automatic_Quotation() {

        setalready_run(true);

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        form.append("partner_owner_recid", partner_owner_recid);
        form.append("lead_website_id", objid);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Create_Automatic_Quotation/";

        setLoading(true);



        axios.post(myurl, form).then(res => {
            console.log(" In Create_Automatic_Quotation  res.data.status = " + res.data.status);
            console.log(" In Create_Automatic_Quotation  res.data.message r_class = " + res.data.message);


            if (String(res.data.status) === String("true")) {

                setCreate_Automatic_Quotation_api("true");
                setCreate_Automatic_Quotation_result(res.data.message);
                setCreate_Automatic_Quotation_message(res.data.message);
                setquotation_ref(res.data.quotation_ref)

                //myRef_head.current.scrollIntoView({ behavior: "smooth" });

            }
            else {
                setCreate_Automatic_Quotation_api("false");
                setCreate_Automatic_Quotation_message(res.data.message);
                if (String(setquotation_ref) === "")
                    alert(res.data.message);
            }
            setLoading(false);

        }).catch((error) => {

            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Create_Automatic_Quotation = ' + error);
            setCreate_Automatic_Quotation_api("false");
            alert(" Impossible de créer le devis");

        })
    }
    const initialized = useRef(false)

    useEffect(() => {

        if (!initialized.current) {
            initialized.current = true

            if (String(already_run) === "false")
                Create_Automatic_Quotation();
        }

    }, []);


    return (
        <div className="notfound">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <div>
                <Navigation />
            </div>
            <Helmet>
                <title>MySy Training, Emargement</title>
                <meta name="description" content="Nous sommes une base de données uniquement dediée aux formations et tutoriels" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Trouver des Formations.Acces libre.Qualité top" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/Emargement"} />

            </Helmet>
            <Header />


            <div className="div_mobile">
                {isLoading && <div className="loader-container">
                    <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
                </div>}
                {/*<div className="nom_prenom"> Votre devis est en cours de création  <img src={ok_png} className="img_ok" /> </div>*/}
                <div className="nom_prenom">
                    <div>


                        {Create_Automatic_Quotation_api && String(Create_Automatic_Quotation_api) === "false" &&
                            <div style={{ "color": "red", "marginTop": "10rem" }}>
                                Impossible de créer le devis : {Create_Automatic_Quotation_message}
                            </div>
                        }

                        {Create_Automatic_Quotation_api && String(Create_Automatic_Quotation_api) === "true" &&
                            <div style={{ "color": "green", "marginTop": "10rem" }}>

                                <div>
                                    <img src={ok_png} className="img_ok" /> &nbsp; Super le devis a été créé avec la référence :&nbsp; &nbsp;  {quotation_ref} <br />
                                </div>
                                Vous allez être redirigé vers la liste des  devis dans quelques secondes {tempo}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="pieddepage">
                <Footer />
            </div>


        </div>
    )
}

export default Automaticquotation;