import React, { useState, useEffect } from "react";
import UpdateUserInfo from "../components/UpdateUserInfo";
import bannerimg from "../mysy_img/MYSY_banner_compte.png";
import profileimg from "../mysy_img/MYSY-profil-2.png";
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import UserHistoryRecherche from "../components/UserHistoryRecherche";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import bannerimg2 from "../mysy_img/MYSY-LOGO-BLUE.png";
import axios from "axios";
import { getCookie, removeCookie } from 'react-use-cookie';
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import Footer from "./Fotter";
import Header from "./Header";
import UpgradeToPro from "./UpgradeToPro"
import Profil_Objectif from "./ProfilObjectif";
import { useLocation } from "react-router-dom";

function Account() {
    const [menu, setmenu] = useState("affichage");
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
    const [result, setResult] = useState("");
    const [myApiResponse, setmyApiResponse] = useState("");
    const stored_user = getCookie('tokenmysych');
    const history = useHistory();
    const [country_code, setcountry_code] = useState("");
    const [country_name, setcountry_name] = useState("");
    const [city, setcity] = useState("");
    const [postal, setpostal] = useState("");
    const [latitude, setlatitude] = useState("");
    const [longitude, setlongitude] = useState("");
    const [IPv4, setIPv4] = useState("");
    const [state, setstate] = useState("");
    const [userimgprofil, setuserimgprofil] = useState();
    const [userIp, setuserIp] = useState("");
    const [username, setusername] = useState("");
    const [migratedaccount, setmigratedaccount] = useState("0");

    const apiUrl = process.env.REACT_APP_API_URL;
    const formData = new FormData();
 
    //alert("stored_user =  "+stored_user);
    const location = useLocation();
    useEffect(() => {
        getImage();

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            //alert(" début puch");
            history.push("/mysy-training-login/0/0");
        }


        async function fetchData() {
            const result = await axios('https://geolocation-db.com/json/',);
            //alert(result.data.IPv4);
            setuserIp(result.data.IPv4);
            setcountry_code(result.data.country_code);
            setcountry_name(result.data.country_name);
            setcity(result.data.city);
            setpostal(result.data.postal);
            setlatitude(result.data.latitude);
            setlongitude(result.data.longitude);
            setIPv4(result.data.IPv4);
            setstate(result.data.state);
            setuserIp(result.data.IPv4);
        }
        GetUserNameFromToken();

        fetchData();


        if (location && location.state && location.state.menu) {
            displayProfil_Objectif();
        }



    }, []);

    const [myimage, setmyimage] = useState();
    const [isimage, setisimage] = useState();

    function getImage(e) {
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedImage/"; 
        var form = new FormData();
        form.append("token", stored_user);
        form.append("type", "user");

        axios.post(myurl, form).then(res => {
            if (res.data.status != "False") {
                //console.log(" getImage : In test  res.data.status = " + res.data.status);
                //console.log(" getImage: res.data.message.img = " + res.data.message);
                if (res.data.message.img.length > 0) {
                    setisimage("True");
                }
                else {
                    setisimage("False");
                }
                setmyimage(res.data.message.img);
                var ch_img = "data:image/png;base64," + res.data.message.img;
                setuserimgprofil(ch_img);
            }
            else {
                //console.log(" In Erreur   res.data.status = " + res.data.status);
                //console.log(" In Erreur  res.data.message = " + res.data.message);
                setisimage("False");
                setuserimgprofil(profileimg);
            }
        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setisimage("False");
            setuserimgprofil(profileimg);
        })

    }


    function logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            removeCookie('tokenmysych', { path: '/' });
            history.push("/mysy-training-login/0/0");
        }
    }

    function logout() {
        confirmAlert({
            title: 'Deconnexion ?',
            message: 'Confirmez la deconnexion',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    const displayHistory = () => {
        setmenu("recherche");
    };

    
    const displayProfil_Objectif = () => {
        setmenu("profil_objectif");
    };



    const upgradetopro = () => {
        setmenu("upgradecompte");
    };

    const displayPersoInfo = () => {
        setmenu("affichage");
    };

    function GetUserNameFromToken(event) {

        var form = new FormData();

        form.append("token", stored_user);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_user_account/";

        axios.post(myurl, form).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In GetUserNameFromToken  res.data.status = " + res.data.status);
                //console.log(" In GetUserNameFromToken  res.data.message = " + res.data.message);
                setusername(JSON.parse(res.data.message).email);
                if (JSON.parse(res.data.message).migrated &&
                    String(JSON.parse(res.data.message).migrated) === String("1")) {
                     setmigratedaccount(String(JSON.parse(res.data.message).migrated));
                }
                //setmyApiResponse("true");
                //setResult(res.data.message)
                //setmyApimyApiMessage("OK")
            }
            else {
                //console.log(" In GetUserNameFromToken  res.data.status = " + res.data.status);
                //console.log(" In GetUserNameFromToken  res.data.message = " + res.data.message);
                //setmyApiResponse("false");
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('In GetUserNameFromToken Not good man :( mysearchtext = ');
            //setmyApiResponse("false");
            //setmyApimyApiMessage("")
        })
    }



    function finaldeleteuseraccount(e) {

        var form = new FormData();
        form.append("token", stored_user);
        // Ajout des info IP user
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/send_mail_delete_user/";


        axios.post(myurl, form).then(res => {
            if (String(res.data.status) != String("false")) {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("true");
                setResult(res.data.message)
                alert(res.data.message);

            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("false");
                //setmyApimyApiMessage("")
            }


        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("false");
            //setmyApimyApiMessage("")
        })


    }


    function deletemyaccount(e) {
        confirmAlert({
            title: 'Suppremier votre compte',
            message: 'Cette action va supprimer toutes vos données, Confirmez-vous la suppression du compte ?',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => finaldeleteuseraccount()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }

    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const [isnewimage, setisnewimage] = useState();

    const changeHandler = (event) => {
        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;


        //alert("file_size =  "+file_size+" file_type = "+file_type+" a = "+a);
        if (file_size > 100000) {
            alert("L'image doit faire moins de 100 Kilo Octets");
            return;
        }
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
        setuserimgprofil(URL.createObjectURL(event.target.files[0]));
    };

    function sendImage(e) {
        formData.append('File', selectedFile);
        formData.append("token", stored_user);
        formData.append("type", "user");
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/recordImage/",
            {
                method: 'POST',
                body: formData,
            }
        ).then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message']);
                setIsSelected(false);

            })
            .catch((error) => {
                console.error('Error:');
                setIsSelected(true);
            });
    };

    return (
        <div className="account">
            <Helmet>
                <title>Création compte MySy Trainig Technology</title>
                <meta name="description" content="Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, Gestion formateurs, Catalogue formations personnalisé" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Création compte MySy Trainig Technology" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <h1 style={{ fontSize: '0.1px', opacity:'0' }}>Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, 
                    Gestion formateurs, Catalogue formations personnalisé </h1>

            <Header />

            <div className="div_mobile">
                <div>
                    <h1 className="h1_transparent">Connexion MySy Training Technology </h1>
                </div>
                <div>
                    <div className="div_gauche">
                        <div>
                            <img class="img-responsive img_user" src={userimgprofil} align="center" alt="MySy Training profile image" />

                            {!isSelected && <div className="nonlable">
                                <input type="file" id="files" accept="image/*" onChange={changeHandler} style={{ opacity: "0", zIndex: "-1", maxWidth: "5px", maxHeight: "0px" }} />
                                <label for="files" className="label">Changer image</label>
                            </div>}

                            {isSelected && <div className="nonlable">
                                <Button variant="outlined" onClick={sendImage} >Enregistrer</Button>
                            </div>}

                        </div>
                        <div className="nom_prenom">     {username && <b> {username} </b>}
                            {!username && <b> xxxx </b>} </div>
                        <hr className="my_hr" />
                        <div className="sousmenu" onClick={displayPersoInfo}>  INFORMATIONS PERSONNELLES</div>
                        <hr className="my_hr" />
                        <div className="sousmenu" onClick={displayProfil_Objectif}> PROFILS & OBJECTIFS</div>
                        <hr className="my_hr" />
                        {/*<div className="sousmenu" onClick={displayHistory}>  HISTORIQUE RECHERCHE</div>
                        <hr className="my_hr" />
                        <div className="sousmenu">  HISTORIQUE CONNEXION</div>
                        <hr className="my_hr" />
                        <div className="sousmenu">  MES MESSAGES </div>
                        {String(migratedaccount) !== String("1") && <div>
                            <hr className="my_hr" />
                            <div className="sousmenu" style={{ "color": "#81BC3A" }} onClick={upgradetopro}>  PASSER AU COMPTE PRO</div>
                        </div>}

                      


                        <hr className="my_hr" />*/}
                        <div className="sousmenu" onClick={deletemyaccount}>  SUPPRIMER MON COMPTE</div>
                        <hr className="my_hr" />
                       
                    </div>
                    <div className="div_droite">
                        {String(myApiResponse) === String("true") && <div className="okUpdateData">
                            {result}

                        </div>}

                        {String(myApiResponse) !== String("true") && <div className="koUpdateData">
                            {result}

                        </div>}
                        {String(menu) === String("affichage") && <UpdateUserInfo />}

                        {String(menu) === String("recherche") && <UserHistoryRecherche />}

                        {String(menu) === String("upgradecompte") && <UpgradeToPro />}

                        {String(menu) === String("profil_objectif") && <Profil_Objectif />}


                    </div>
                </div>
                <div className="pieddepage">
                    <Footer />
                </div>

            </div>
        </div>
    );
}

export default Account;

