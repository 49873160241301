import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import Partner from "./Partner";
import { useHistory } from "react-router-dom";
import Facture from "./Facture";
import { FaHandPointRight } from "react-icons/fa";
import { useParams } from 'react-router-dom'

function OrderConfirmation_3DS(props) {
    const [menu, setmenu] = useState("");
    const history = useHistory();
    const { orderid, packs, nb_class } = useParams()

    useEffect(() => {

        CheckSalesOrder();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, []);

    function GoToInvoice() {
        setmenu("facture");
    }


    const [stripe_payement_id, setstripe_payement_id] = useState("");

    const [CheckSalesOrder_api, setCheckSalesOrder_api] = useState("");
    const [CheckSalesOrder_message, setCheckSalesOrder_message] = useState("");
    const [CheckSalesOrder_result, setCheckSalesOrder_result] = useState("");
    const stored_part = getCookie('tokenmysypart');
    function CheckSalesOrder(event) {

        var form = new FormData();

        form.append("token", stored_part);
        form.append("orderid", orderid);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/CheckSalesOrder/";

        axios.post(myurl, form).then(res => {

            //console.log(res.data.status, " -- ", res.data.message)
            if (String(res.data.status) === String("true")) {
                setCheckSalesOrder_api("true");
                setCheckSalesOrder_result(String(res.data.message));

                if (JSON.parse(res.data.message).stripe_pi) {
                    setstripe_payement_id(JSON.parse(res.data.message).stripe_pi);
                    check_payement_finalise_order(JSON.parse(res.data.message).stripe_pi);
                } else {
                    setcheck_payement_finalise_order_api("false");
                }
            }
            else {
                setCheckSalesOrder_api("false")
                setCheckSalesOrder_message(String(res.data.message));
            }


        }).catch((error) => {
            //console.log('In CheckSalesOrder ', error);
            setCheckSalesOrder_api("false")

        })
    }

    function parent_retur_main_page() {
        if (window.parent) {
            window.parent.location.reload();
        }
    }


    // Verification si le payement est bien passé
    const [check_payement_finalise_order_api, setcheck_payement_finalise_order_api] = useState("");
    const [check_payement_finalise_order_message, setcheck_payement_finalise_order_message] = useState("");
    function check_payement_finalise_order(stripe_pi) {

        var formData_tpm = new FormData();
        formData_tpm.append('token', stored_part);
        formData_tpm.append('stripe_payment_id', stripe_pi);
        formData_tpm.append('packs', packs);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/payment_retrieve_status/";

        axios.post(myurl, formData_tpm).then(res => {
            if (String(res.data.status) === String("true")) {
                setcheck_payement_finalise_order_api("true");

                MySy_LMS_Account_Management();


            }
            else {
                setcheck_payement_finalise_order_api("false")
                setcheck_payement_finalise_order_message(String(res.data.message))
            }


        }).catch((error) => {
            //console.log('In CheckSalesOrder ', error);
            setcheck_payement_finalise_order_api("false")

        })

    }


     // Mise à jour du compte LMs Mysy
     const [MySy_LMS_Account_Management_api, setMySy_LMS_Account_Management_api] = useState("");
     const [MySy_LMS_Account_Management_message, setMySy_LMS_Account_Management_message] = useState("");
     function MySy_LMS_Account_Management() {
 
         var formData_tpm = new FormData();
         formData_tpm.append('token', stored_part);
         //formData_tpm.append('stripe_payment_id', stripe_pi);
         //formData_tpm.append('packs', packs);
 
 
 
         var myurl = process.env.REACT_APP_API_URL + "myclass/api/Check_MySy_LMS_Account/";
 
         axios.post(myurl, formData_tpm).then(res => {
            console.log('In MySy_LMS_Account_Management status = ', res.data.status, "  -- message = ", res.data.message);
             if (String(res.data.status) === String("true")) {
                 setMySy_LMS_Account_Management_api("true");
 
             }
             else {
                 setMySy_LMS_Account_Management_api("false")
                 setMySy_LMS_Account_Management_message(String(res.data.message))
             }
 
 
         }).catch((error) => {
             console.log('In MySy_LMS_Account_Management ', error);
             setMySy_LMS_Account_Management_api("false")
 
         })
 
     }

    return (
        <div className="orderconfirmation">


            {String(menu) !== "facture" && <div>

                <h2 style={{ "textAlign": "center", "color": "green", "marginTop": "3rem" }}> Commande numéro :  {orderid} </h2>



                {String(CheckSalesOrder_api) === "true" && String(check_payement_finalise_order_api) === "true" &&
                    <div className="div_row_central">


                        Votre commande a été prise en compte <br />
                        Votre pack <b> {String(packs).toLocaleUpperCase()}  </b>
                        Merci d'avoir choisi MySy Training.

                        <br />
                        <nav style={{ "color": "orange", "textAlign": "center" }}> /!\ : Vous devez finaliser votre abonnement pour qu'il soit pris en compte en cliquant sur le bouton ci-dessous 'je finalise mon abonnement'.
                            <br />
                        </nav>
                        <br />
                        Si vous avez besoin aide n'hesitez pas à contacter notre équipe support par le chatbot (en bas de votre écran) ou mail.
                        <br />

                        <br />
                        Votre facture est disponible dans votre espace personnel.

                        <br />
                        <br />

                    </div>}


                {String(CheckSalesOrder_api) === "true" && String(check_payement_finalise_order_api) === "false" &&
                    <div className="div_row_central koUpdateData">
                        Le payement de la commande  {String(orderid)}  n'est pas passé .<br />
                        Merci de contacter notre équipe support par le chatbot (en bas de votre écran) ou mail.

                        <br />

                    </div>}


                {String(CheckSalesOrder_api) === "false" &&
                    <div className="div_row_central koUpdateData">
                        Impossible de trouver la commnde {String(orderid)} .<br />
                        Merci de contacter notre équipe support par le chatbot (en bas de votre écran) ou mail.

                        <br />

                    </div>}



                <div className="div_row_central">
                    <Button variant="contained" color="success" className="div_row22 btn_enreg_cmd"
                        onClick={parent_retur_main_page}> IMPORTANT : Je finalise mon abonnement </Button>

                </div>
            </div>}

        </div>
    );

}

export default OrderConfirmation_3DS;