import ProduitsServices from "../components/ProduitsServices";
import Navigation from "../components/Navigation";

export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
                <ProduitsServices />
            </div>
        </div>
    );
}
