import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { getCookie, setCookie } from 'react-use-cookie';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { Button, } from "reactstrap";
import PasswordIcon from '@mui/icons-material/Password'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useHistory } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import axios from "axios";


function UpdateUserInfo() {
  const history = useHistory();
  const [myApiResponse, setmyApiResponse] = useState("");
  const [result, setResult] = useState("");
  const [myApimyApiMessage, setmyApimyApiMessage] = useState("")
  const [testval, settestval] = useState("");
  const [fields1desabled, setfields1desabled] = useState(true);
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [updatepwd, setupdatepwd] = useState();
  const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
  const [newpawdvisible, setnewpawdvisible] = useState(false);
  const [confpwdvisible, setconfpwdvisible] = useState(false);
  const [pwdfieldsdesabled, setpwdfieldsdesabled] = useState(true);


  const [valuesPass1, setValuesPass1] = React.useState({
    password1: '',
    showPassword1: false,
  });

  const [valuesPass2, setValuesPass2] = React.useState({
    password2: '',
    showPassword2: false,
  });

  const [valuesPass3, setValuesPass3] = React.useState({
    password3: '',
    showPassword3: false,
  });


  // recuperation et gestion  du token utilisateur 
  const stored_user = getCookie('tokenmysych');
  if (!stored_user || stored_user.length <= 0) {
    history.push("/mysy-user-account");
  }


  const myserver = "127.0.0.1";
  //const myserver = "89.156.84.196";




  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    getCurrentUserData(); desablesecurtypwdfields();
  }, [])


  function handleChange1(prop) {
    return (event) => {
      setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
    };
  }

  function handleChange2(prop) {
    return (event) => {
      setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
    };
  }

  function handleChange3(prop) {
    return (event) => {
      setValuesPass3({ ...valuesPass3, [prop]: event.target.value });
    };
  }

  function getCurrentUserData() {
    const formData = new FormData();
    const url = process.env.REACT_APP_API_URL + "myclass/api/get_user_account/";
    formData.append('token', stored_user);
    //alert( "stored_user = "+stored_user);

    axios.post(url, formData).then(res => {
      if (String(res.data.status) !== String("false")) {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("True");
        setResult(res.data.message);
        var mylocaltraining = JSON.parse(res.data.message);
        if (mylocaltraining) {

          if (mylocaltraining.adr_city) {
            document.getElementsByName("adr_city")[0].value = mylocaltraining.adr_city;
            document.getElementsByName("adr_city")[0].disabled = true;
            document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.email) {
            document.getElementsByName("mail")[0].value = mylocaltraining.email;
            document.getElementsByName("mail")[0].disabled = true;
            document.getElementsByName("mail")[0].style.backgroundColor = "#ECEFF1";
          }


          if (mylocaltraining.adr_city) {
            document.getElementsByName("adr_zip")[0].value = mylocaltraining.adr_zip;
            document.getElementsByName("adr_zip")[0].disabled = true;
            document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.adr_country) {
            document.getElementsByName("adr_country")[0].value = mylocaltraining.adr_country;
            document.getElementsByName("adr_country")[0].disabled = true;
            document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.link_facebook) {
            document.getElementsByName("link_facebook")[0].value = mylocaltraining.link_facebook;
            document.getElementsByName("link_facebook")[0].disabled = true;
            document.getElementsByName("link_facebook")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.mob_phone) {
            document.getElementsByName("mob_phone")[0].value = mylocaltraining.mob_phone;
            document.getElementsByName("mob_phone")[0].disabled = true;
            document.getElementsByName("mob_phone")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.link_linkedin) {
            document.getElementsByName("link_linkedin")[0].value = mylocaltraining.link_linkedin;
            document.getElementsByName("link_linkedin")[0].disabled = true;
            document.getElementsByName("link_linkedin")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.link_twitter) {
            document.getElementsByName("link_twitter")[0].value = mylocaltraining.link_twitter;
            document.getElementsByName("link_twitter")[0].disabled = true;
            document.getElementsByName("link_twitter")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.adr_street) {
            document.getElementsByName("adr_street")[0].value = mylocaltraining.adr_street;
            document.getElementsByName("adr_street")[0].disabled = true;
            document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.surname) {
            document.getElementsByName("surname")[0].value = mylocaltraining.surname;
            document.getElementsByName("surname")[0].disabled = true;
            document.getElementsByName("surname")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.last_name) {
            document.getElementsByName("last_name")[0].value = mylocaltraining.last_name;
            document.getElementsByName("last_name")[0].disabled = true;
            document.getElementsByName("last_name")[0].style.backgroundColor = "#ECEFF1";
          }

          if (mylocaltraining.birthday) {
            document.getElementsByName("birthday")[0].value = mylocaltraining.birthday;
            document.getElementsByName("birthday")[0].disabled = true;
            document.getElementsByName("birthday")[0].style.backgroundColor = "#ECEFF1";
          }

          desablefield1();

        }
      }
      else {
        //console.log(" In test  res.data.status = " + res.data.status);
        //console.log(" In test  res.data.message = " + res.data.message);
        setmyApiResponse("False");
        setmyApimyApiMessage(res.data.message)
        settestval("false");
      }


    }).catch((error) => {
      console.warn('Not good man :( mysearchtext = ');
      setmyApiResponse("False");
      setmyApimyApiMessage("Impossible de contacter l'hote distant")
      settestval("false");
    })



  }

  function desablefield1() {

    setupdatepwd("b");
    document.getElementsByName("adr_city")[0].disabled = true;
    document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";


    document.getElementsByName("adr_city")[0].disabled = true;
    document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("adr_zip")[0].disabled = true;
    document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("mail")[0].disabled = true;
    document.getElementsByName("mail")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("adr_country")[0].disabled = true;
    document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("mob_phone")[0].disabled = true;
    document.getElementsByName("mob_phone")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("link_facebook")[0].disabled = true;
    document.getElementsByName("link_facebook")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("link_linkedin")[0].disabled = true;
    document.getElementsByName("link_linkedin")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("link_twitter")[0].disabled = true;
    document.getElementsByName("link_twitter")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("adr_street")[0].disabled = true;
    document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("surname")[0].disabled = true;
    document.getElementsByName("surname")[0].style.backgroundColor = "#ECEFF1";

    document.getElementsByName("last_name")[0].disabled = true;
    document.getElementsByName("last_name")[0].style.backgroundColor = "#ECEFF1";

    setfields1desabled(true);
  }


  function enablefieds1() {
    document.getElementsByName("adr_city")[0].disabled = false;
    document.getElementsByName("adr_city")[0].style.backgroundColor = "";

    document.getElementsByName("adr_city")[0].disabled = false;
    document.getElementsByName("adr_city")[0].style.backgroundColor = "";

    document.getElementsByName("adr_zip")[0].disabled = false;
    document.getElementsByName("adr_zip")[0].style.backgroundColor = "";

    document.getElementsByName("adr_country")[0].disabled = false;
    document.getElementsByName("adr_country")[0].style.backgroundColor = "";

    document.getElementsByName("mob_phone")[0].disabled = false;
    document.getElementsByName("mob_phone")[0].style.backgroundColor = "";

    document.getElementsByName("link_facebook")[0].disabled = false;
    document.getElementsByName("link_facebook")[0].style.backgroundColor = "";

    document.getElementsByName("link_linkedin")[0].disabled = false;
    document.getElementsByName("link_linkedin")[0].style.backgroundColor = "";

    document.getElementsByName("link_twitter")[0].disabled = false;
    document.getElementsByName("link_twitter")[0].style.backgroundColor = "";

    document.getElementsByName("adr_street")[0].disabled = false;
    document.getElementsByName("adr_street")[0].style.backgroundColor = "";

    document.getElementsByName("surname")[0].disabled = false;
    document.getElementsByName("surname")[0].style.backgroundColor = "";

    document.getElementsByName("last_name")[0].disabled = false;
    document.getElementsByName("last_name")[0].style.backgroundColor = "";


    setfields1desabled(false);
  }


  function RecordData() {
    const formData = new FormData();
    const url = process.env.REACT_APP_API_URL + "myclass/api/update_user_account/";

    var adr_city = document.getElementsByName("adr_city")[0].value;
    var adr_zip = document.getElementsByName("adr_zip")[0].value;
    var adr_country = document.getElementsByName("adr_country")[0].value;
    var mob_phone = document.getElementsByName("mob_phone")[0].value;
    var link_facebook = document.getElementsByName("link_facebook")[0].value;
    var link_linkedin = document.getElementsByName("link_linkedin")[0].value;
    var link_twitter = document.getElementsByName("link_twitter")[0].value;
    var adr_street = document.getElementsByName("adr_street")[0].value;
    var surname = document.getElementsByName("surname")[0].value;
    var last_name = document.getElementsByName("last_name")[0].value;


    formData.append('token', stored_user);
    formData.append('adr_city', adr_city);
    formData.append('adr_zip', adr_zip);
    formData.append('mob_phone', mob_phone);
    formData.append('adr_country', adr_country);
    formData.append('link_facebook', link_facebook);
    formData.append('link_twitter', link_twitter);
    formData.append('adr_street', adr_street);
    formData.append('surname', surname);
    formData.append('last_name', last_name);
    formData.append('link_linkedin', link_linkedin);


    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) === String("true")) {
          settestval("true");
          desablefield1();
        }else if (String(result['status']) === String("Err_Connexion")) {
          alert('Erreur: ' + result['message']);
          history.push("/Connexion");
      }


      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
      });


  };


  function desablesecurtypwdfields() {
    document.getElementsByName("current_pwd")[0].disabled = true;
    document.getElementsByName("current_pwd")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("current_pwd")[0].value = "";

    document.getElementsByName("new_pwd")[0].disabled = true;
    document.getElementsByName("new_pwd")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("new_pwd")[0].value = "";

    document.getElementsByName("new_pwd_conf")[0].disabled = true;
    document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#ECEFF1";
    document.getElementsByName("new_pwd_conf")[0].value = "";


    setpwdfieldsdesabled(true);

  }


  function enablesecurtypwdfields() {

    document.getElementsByName("current_pwd")[0].disabled = false;
    document.getElementsByName("current_pwd")[0].style.backgroundColor = "";

    document.getElementsByName("new_pwd")[0].disabled = false;
    document.getElementsByName("new_pwd")[0].style.backgroundColor = "";

    document.getElementsByName("new_pwd_conf")[0].disabled = false;
    document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "";

    setpwdfieldsdesabled(false);
  }



  function UpdateUserPwd() {

    setupdatepwd(false);
    const url = process.env.REACT_APP_API_URL + "myclass/api/change_user_pwd/";
    const formData = new FormData();
    formData.append('token', stored_user);

    // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
    if (!stored_user || stored_user.length <= 0) {
      history.push("/account");
    }

    var pwd = document.getElementsByName("current_pwd")[0].value;
    var new_pwd = document.getElementsByName("new_pwd")[0].value;
    var conf_new_pwd = document.getElementsByName("new_pwd_conf")[0].value;


    formData.append('pwd', pwd);
    formData.append('new_pwd', new_pwd);
    formData.append('conf_new_pwd', conf_new_pwd);


    fetch(
      url,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log('Success:', result['message'], "result['status'] = ", result['status']);
        setmyApiResponse(result['status']);
        setResult(result['message']);
        if (String(result['status']) == String("true")) {
          setupdatepwd(true);
          setfields1desabled(true);
        }

      })
      .catch((error) => {
        console.error('Error:', error);
        setmyApiResponse("false");
        setupdatepwd(false);
      });
  }




  function handleChange(prop) {
    return (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowPassword_current_pwd = () => {
    var filtre2_value = document.getElementsByName("current_pwd")[0].value;

    if (currentpawdvisible == false) {
      document.getElementsByName("current_pwd")[0].type = "text";
      setcurrentpawdvisible(true);
    }
    else if (currentpawdvisible == true) {
      document.getElementsByName("current_pwd")[0].type = "password";
      setcurrentpawdvisible(false);
    }
  };

  const handleClickShowPassword_new_pwd = () => {
    if (newpawdvisible == false) {
      document.getElementsByName("new_pwd")[0].type = "text";
      setnewpawdvisible(true);
    }
    else if (newpawdvisible == true) {
      document.getElementsByName("new_pwd")[0].type = "password";
      setnewpawdvisible(false);
    }

  };

  const handleClickShowPassword_new_pwd_conf = () => {
    if (confpwdvisible == false) {
      document.getElementsByName("new_pwd_conf")[0].type = "text";
      setconfpwdvisible(true);
    }
    else if (confpwdvisible == true) {
      document.getElementsByName("new_pwd_conf")[0].type = "password";
      setconfpwdvisible(false);
    }

  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div className='updateuserinfo'>

      <div className="titre1"> Données personnelles </div>
      <hr className="my_hr"/>

      {String(testval) == String("true") && <div className="okUpdateData">
        Les données ont été mises à jour.
      </div>
      }

      {String(testval) == String("false") && <div className="koUpdateData">
        Erreur mise à jour.
      </div>
      }


       &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

        <TextField
          required
          label="Nom"
          name="last_name"
          className="texte_area"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />


        <TextField
          name="surname"
          label="Prénom"
          className="texte_area"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <br />

        <TextField
          name="mob_phone"
          label="Téléphone"
          className="texte_area"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />


        <TextField
          name="mail"
          className="texte_area"
          label="Adresse mail"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <br />

        <TextField
          name="link_linkedin"
          label="LinkedIn"
          className="texte_area"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkedInIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />


        <TextField
          name="link_facebook"
          label="Facebook"
          className="texte_area"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FacebookIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />


        <TextField
          name="link_twitter"
          label="Twitter"
          className="texte_area"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TwitterIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <br />
        <TextField
          name="adr_street"
          label="Adresse"
          className="texte_area_adress"
          sx={{ m: 1, width: '90%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <br />
        <TextField
        className="texte_area"
          name="adr_zip"
          label="Code Postal"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />

        <TextField
        className="texte_area"
          name="adr_city"
          label="Ville"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <br />

        <TextField
        className="texte_area"
          name="adr_country"
          label="Pays"
          sx={{ m: 1, width: '48%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HomeIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
        <br />

        <div className="div_row22">
          {fields1desabled == false && <div className="div_row">
            <div className="div_row_gauche">
              <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
            </div>
            <div className="div_row_droite">
              <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={desablefield1}>Annuler</Button>
            </div>
          </div>
          }
          {fields1desabled == true &&
            <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablefieds1}>Modifier</Button>
          }

        </div>
      </Box>

      <hr className="my_hr"/>
      <div style={{"width":"10%"}}> &nbsp;</div>
      <div className="titre1"> Securité </div>
      <hr className="my_hr"/>


      {updatepwd == false && <div className="koUpdateData">
        Erreur mise à jour.
      </div>
      }

      {updatepwd == true && <div className="okUpdateData">
        La mise à jour été faite
      </div>
      }
       &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 } }}>
        
        <FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
          <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
          <OutlinedInput
            id="current_pwd"
            name="current_pwd"
            type={values.showPassword1 ? 'text' : 'password'}
            value={values.password1}
            onChange={handleChange1('password1')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword_current_pwd}
                  edge="end"
                >
                  {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password 1"
          />
        </FormControl>
        <br />

        <FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small"  className="texte_area_passwd">
          <InputLabel htmlFor="outlined-adornment-password2"></InputLabel>
          <OutlinedInput
            id="new_pwd"
            name="new_pwd"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password2}
            onChange={handleChange2('password2')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword_new_pwd}
                  edge="end"
                >
                  {newpawdvisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password 2"
          />
        </FormControl>
        <br />

        <FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
          <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
          <OutlinedInput
            id="new_pwd_conf"
            name="new_pwd_conf"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password3}
            onChange={handleChange3('password3')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword_new_pwd_conf}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <div className="div_row22">

          {pwdfieldsdesabled == false && <div className="div_row">
            <div className="div_row_gauche">
              <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={UpdateUserPwd}>Enregistrer</Button>
            </div>
            <div className="div_row_droite">
              <Button variant="contained" color="success" className="div_row22 btn_modif " onClick={desablesecurtypwdfields}>Annuler</Button>
            </div>
          </div>
          }
          {pwdfieldsdesabled == true &&
            <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablesecurtypwdfields}>Modifier</Button>
          }


        </div>


      </Box>

      <br />
    </div>
  );
}
export default UpdateUserInfo;