import React, { useState, useEffect } from "react";
import ReactPlayer from 'react-player'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { SocialIcon } from 'react-social-icons';
import { Button, } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { confirmAlert } from 'react-confirm-alert';
import topimg from "../mysy_img/top.png";
import coeurimg from "../mysy_img/coeur.png";
import img_met_program from "../mysy_img/met_programmation.jpg";
import img_met_hotel from "../mysy_img/met_hotel.jpg";
import img_met_graphisme from "../mysy_img/metier_graphisme.jpg";

import img_met_autre from "../mysy_img/met_autre.jpg";
import img_met_digital from "../mysy_img/met_digital.jpg";
import img_met_management from "../mysy_img/met_management.jpg";
import img_met_projet from "../mysy_img/met_projet.jpg";

import img_met_market from "../mysy_img/met_market.jpg";
import img_met_autre2 from "../mysy_img/met_autre2.jpg";
import img_met_dev_perso from "../mysy_img/metier_dev_perso.jpg";
import img_met_rh from "../mysy_img/met_rh.jpg";

import img_met_bureautic from "../mysy_img/met_burautic.jpg";
import img_met_vente from "../mysy_img/met_vente.jpg";
import axios from "axios";
//import { Rating } from 'react-simple-star-rating'
import Rating from '@mui/material/Rating';
import parse from 'html-react-parser'

import { FcCalendar } from "react-icons/fc";

const Formation_Cartouche_Com = (props) => {
  const [userconnected, setuserconnected] = useState("0");
  const [partnerconnected, setpartnerconnected] = useState("0");
  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);
  const history = useHistory();
  const [myfilter, setmyfilter] = useState(props.formation.price);

  const [profileimg, setprofileimg] = useState(props.formation.price);


  // Gestion des Cookies
  const stored_partner = cookie_part.tokenmysypart;
  const stored_user = cookie.tokenmysych;

  function test(val) {

    if (userconnected === "0" && partnerconnected === "0") {
      confirmAlert({
        title: 'Activer votre session ?',
        message: 'Merci de vous connecter pour acceder au detail de cette formation',
        buttons: [
          {
            label: 'Oui',
            onClick: () => { history.push("/mysy-training-login/training/" + props.formation.internal_url) }
          },
          {
            label: 'Non',
            onClick: () => { return }

          }
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
      });
    } else {
      window.open(
        "/Display-Detail-formation/" + props.formation.internal_url,
        '_blank'
      );
    }


  }

  const [myclassimage, setmyclassimage] = useState();
  const [isclassimage, setisclassimage] = useState("False");
  const [userimgclassprofil, setuserimgclassprofil] = useState();

  function getClassImage(e) {
    var myurl = process.env.REACT_APP_API_URL + "myclass/api/getRecodedClassImage_from_front/";

    var class_external_code = props.formation.external_code;

    if (class_external_code.length < 3) {
      //console.log("Le code externe prend au moins 3 caractères");
      return;
    }

    //console.log(" class_external_code = " + class_external_code);

    var form = new FormData();
    form.append("class_external_code", class_external_code);


    axios.post(myurl, form).then(res => {

      if (res.data.status != "False") {
        //console.log(" getClassImage : In test  res.data.status = " + res.data.status);
        //console.log(" getClassImage: res.data.message.img = " + res.data.message);

        if (res.data.message.img.length > 0) {
          setisclassimage("True");
          //console.log(" getClassImage : In test  res.data.status = " + res.data.status);
          //console.log(" getClassImage: res.data.message.img = " + res.data.message);

          var ch_img = "data:image/png;base64," + res.data.message.img;
          setuserimgclassprofil(ch_img);
          //console.log("ch_img  = "+ch_img);
        }
        else {
          setisclassimage("False");
        }

      }
      else {
        //console.log(" In Erreur   res.data.status = " + res.data.status);
        //console.log(" In Erreur  res.data.message = " + res.data.message);
        setisclassimage("False");
        setuserimgclassprofil();
        //alert("4");
      }
    }).catch((error) => {
      console.warn('getClassImage grrr : Not good man :( getClassImage = ');
      setisclassimage("False");
      setuserimgclassprofil(profileimg);
    })

  }


  useEffect(() => {
    setmyfilter(props.myfil);

    if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
      setuserconnected("0");
    } else {
      setuserconnected("1");
    }

    if (typeof (stored_partner) === "undefined" || String(stored_partner) === '') {
      setpartnerconnected("0");
    } else {
      setpartnerconnected("1");
    }

    getClassImage();
    GetCurrentClass_trainingsession();

  }, []);


  const [GetCurrentClass_api, setGetCurrentClass_api] = useState();
  const [GetCurrentClass_message, setGetCurrentClass_message] = useState();
  const [GetCurrentClass_result, setGetCurrentClass_result] = useState();
  function GetCurrentClass_trainingsession(event) {

    var form = new FormData();

    form.append("class_internal_url", props.formation.internal_url);

    form.append("token", stored_partner);

    var myurl = process.env.REACT_APP_API_URL + "myclass/api/GetActiveSessionFormation_List/";

    axios.post(myurl, form).then(res => {

      if (res.data.status != "false") {
        //console.log(" In GetCurrentClass_trainingsession  res.data.status = " + res.data.status);
        //console.log(" In GetCurrentClass_trainingsession  res.data.message r_class = " + res.data.message);
        setGetCurrentClass_api("true");
        setGetCurrentClass_result(res.data.message);
      }
      else {
        setGetCurrentClass_api("false");
        setGetCurrentClass_message(res.data.message);
      }

    }).catch((error) => {
      console.warn('Not good man :( GetCurrentClass_trainingsession = ', error);
      setGetCurrentClass_api("false");
      //setmyApimyApiMessage("")
    })
  }


  return (

    <div className="formation_cartouche_com">

      <div style={{ "width": "100%" }}>
        <div className="forma_img_gauche">
          {props.formation.img_url && String(props.formation.img_url).length > 0 && String(isclassimage) === "False" &&
            <img src={`${props.formation.img_url}`} className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            String(isclassimage) === "False" &&
            !props.formation.metier && <img src={img_met_autre} alt="autre" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("it") &&
            String(isclassimage) === "False" && <img src={img_met_program} alt="informatique" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("graphisme") &&
            String(isclassimage) === "False" && <img src={img_met_graphisme} alt="graphisme" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("management") &&
            String(isclassimage) === "False" && <img src={img_met_management} alt="management" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("digital") &&
            String(isclassimage) === "False" && <img src={img_met_digital} alt="digital" className="training_img" />}

          {(!props.formation.img_url || String(props.formation.img_url).length <= 0) &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("office") &&
            String(isclassimage) === "False" && <img src={img_met_bureautic} alt="office" className="training_img" />}

          {!props.formation.img_url || String(props.formation.img_url).length <= 0 &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("rh") &&
            String(isclassimage) === "False" && <img src={img_met_rh} alt="ressources humaines" className="training_img" />}

          {!props.formation.img_url || String(props.formation.img_url).length <= 0 &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("vente") &&
            String(isclassimage) === "False" && <img src={img_met_vente} alt="vente" className="training_img" />}

          {!props.formation.img_url || String(props.formation.img_url).length <= 0 &&
            props.formation.metier && String(props.formation.metier).toLocaleLowerCase() === String("dev_perso") &&
            String(isclassimage) === "False" && <img src={img_met_dev_perso} alt="developpement personnel" className="training_img" />}

          {String(isclassimage) === "True" && <img src={userimgclassprofil} alt="developpement personnel" className="training_img" />}


        </div>
        <div className="forma_rightbox"
          onClick={(e) => {
            e.preventDefault();

            window.open(
              "/Display-Detail-formation/" + props.formation.internal_url,
              '_blank'
            );

          }}>
          <div className="p_forma">
            <div className="div_row">

              <div className="div_row_gauche" style={{ "marginTop": "5px" }}>
                <Rating name="half-rating-read" defaultValue={props.formation.note} precision={0.5} readOnly />
              </div>
              <div className="div_row_droite div_row_droite_cpf">
                <div className="block_cpf">
                  <div className="block_cpf_txt">

                    {props.formation.cpf && String(props.formation.cpf) === String("1") &&
                      <div>
                        OUI Éligible au CPF &nbsp;
                      </div>}

                    {props.formation.cpf && String(props.formation.cpf) !== String("1") &&
                      <div>
                        NON Éligible au CPF &nbsp;
                      </div>}

                    {!props.formation.cpf &&
                      <div>
                        NON Éligible au CPF  &nbsp;
                      </div>}

                  </div>
                </div>
              </div>
            </div>

            <div className="div_row">
              <p className="p_class training_title">
                <b>
                  {parse(String(props.formation.title).replace(/(<([^>]+)>)/ig, ''))}
                </b>

              </p>
            </div>
            <div className="div_row">

              {String(props.formation.price).length == 9 &&
                <div className="training_price" > <i>  Prix : Non communiqué</i> &nbsp;
                </div>
              }


              {String(props.formation.price).length != 9 && String(props.formation.price) === String("0") &&
                <div className="training_price">Gratuit  &nbsp;
                </div>
              }

              {String(props.formation.price).length != 9 && String(props.formation.price) !== String("0") &&
                <div className="training_price">{props.formation.price} €&nbsp; HT
                </div>
              }


            </div>

            <div className="div_row">
              {parse(String(props.formation.description).replace(/(<([^>]+)>)/ig, '')).substring(0, 200)}...

            </div>

          </div>
        </div>
        <div className="div_row">

          <hr style={{ "width": "90%", "marginLeft": "auto", "marginRight": "auto", "marginBottom": "2px", "marginTop": "2px" }} />

          <div className="div_row_gauche div_row_gauche_critere" >
            <div className="div_row_critere" style={{ "marginTop": "0.5rem", "marginBottom": "0.3rem" }}>
              {!props.formation.extented_search &&
                <div className="div_row_gauche">


                  {props.formation.duration_unit &&
                    <div style={{ "width": "100%" }}>
                      <div className="criteres ftion_tab_gauche">Durée :
                      </div>

                      {String(props.formation.duration_unit) === "heure" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} hr &nbsp;
                        </div>}

                      {String(props.formation.duration_unit) === "heures" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} hr &nbsp;
                        </div>}


                      {String(props.formation.duration_unit) === "jour" &&
                        <div className="criteres ftion_tab_droite">
                          {props.formation.duration} jr  &nbsp;
                        </div>}

                    </div>
                  }


                  {!props.formation.duration_unit &&
                    <div style={{ "width": "100%" }}>
                      <div className="criteres ftion_tab_gauche">Durée :
                      </div>

                      {props.formation.duration && <div className="criteres ftion_tab_droite">
                        {props.formation.duration} jrs &nbsp;
                      </div>}

                      {!props.formation.duration && <div className="criteres ftion_tab_droite">
                        <i> En traitement &nbsp; </i>
                      </div>}


                    </div>

                  }
                  <div style={{ "width": "100%" }}>
                    <div className="criteres ftion_tab_gauche">
                      Certification :
                    </div>

                    <div className="criteres ftion_tab_droite">
                      {props.formation.certif && String(props.formation.certif) === String("1") &&
                        <div>
                          Oui &nbsp;
                        </div>}

                      {props.formation.certif && String(props.formation.certif) !== String("1") &&
                        <div>
                          Non &nbsp;
                        </div>}

                      {!props.formation.certif &&
                        <div>
                          NC  &nbsp;
                        </div>}


                    </div>
                  </div>

                  <div className="media_sociaux" style={{ 'float': "left", marginTop: "0.2rem", }}>
                    <FacebookShareButton
                      url={"https://www.mysy-training.com/Display-Detail-formation/" + props.formation.internal_url}

                      quote={props.formation.title + " -- " + props.formation.description}
                      hashtag={"ySyTraining"}
                    >
                      <SocialIcon network="facebook" style={{ height: 20, width: 20 }} />
                    </FacebookShareButton>
                    &nbsp;

                    <TwitterShareButton
                      url={"https://www.mysy-training.com/Display-Detail-formation/" + props.formation.internal_url}
                      hashtag={"#MySy-Formation"}
                    >
                      <SocialIcon network="twitter" style={{ height: 20, width: 20 }} />
                    </TwitterShareButton>

                    &nbsp;
                    <LinkedinShareButton
                      url={"https://www.mysy-training.com/Display-Detail-formation/" + props.formation.internal_url}
                      title={" Une nouvelle formation : " + props.formation.title}
                      summary={props.formation.title + " -- " + props.formation.description}

                      source={"https://www.mysy-training.com/"}

                    >
                      <SocialIcon network="linkedin" style={{ height: 20, width: 20 }} />
                    </LinkedinShareButton>

                  </div>

                  {/*  <div style={{ "width": "90%" }}>
                <div className="criteres ftion_tab_gauche">
                  Type
                </div>

                <div className="criteres ftion_tab_droite">
                  {props.formation.type} &nbsp;
                </div>
              </div>

              <div style={{ "width": "90%" }}>
                <div className="criteres ftion_tab_gauche">
                  Support
                </div>

                <div className="criteres ftion_tab_droite">
                  {props.formation.support} &nbsp;
                </div>
              </div>

                  */}
                  {/*props.formation.zone_diffusion && props.formation.zone_diffusion.country && <div style={{ "width": "100%" }}>
                  <div className="criteres ftion_tab_gauche">
                    Test : pays
                  </div>

                  <div className="criteres ftion_tab_droite">
                    {props.formation.zone_diffusion.country} &nbsp;
                  </div>
                </div>*/}

                  {/*props.formation.zone_diffusion && props.formation.zone_diffusion.city && <div style={{ "width": "100%" }}>
                  <div className="criteres ftion_tab_gauche">
                    Test : Ville
                  </div>

                  <div className="criteres ftion_tab_droite">
                    {props.formation.zone_diffusion.city} &nbsp;
                  </div>
              </div>*/}


                  {/*
              {props.formation.presentiel && props.formation.presentiel.presentiel &&
                <div style={{ "width": "100%" }}>
                  <div className="criteres ftion_tab_gauche">
                    Presentiel
                  </div>

                  <div className="criteres ftion_tab_droite">

                    {props.formation.presentiel.presentiel} &nbsp;

                  </div>
                </div>}
              {props.formation.presentiel && props.formation.presentiel.distantiel &&
                <div style={{ "width": "100%" }}>
                  <div className="criteres ftion_tab_gauche">
                    Distantiel
                  </div>

                  <div className="criteres ftion_tab_droite">

                    {props.formation.presentiel.distantiel} &nbsp;

                  </div>
                </div>}
              */}


                </div>}


              <div className="div_row_droite" style={{ textAlign: "center" }}>
                {String(props.formation.coeur) === String("1") && <div>
                  <img class="imgcoeur" src={coeurimg} alt="coup de coeur" />
                </div>}





                <div style={{ "width": "100%", "float": "right" }}>
                  <div className="criteres ftion_tab_gauche">
                    Sur site :
                  </div>
                  {props.formation.presentiel && props.formation.presentiel.presentiel &&
                    String(props.formation.presentiel.presentiel) === String("1") && <div className="criteres ftion_tab_droite">
                      Oui
                    </div>}

                  {props.formation.presentiel && props.formation.presentiel.presentiel &&
                    String(props.formation.presentiel.presentiel) !== String("1") && <div className="criteres ftion_tab_droite">
                      Non
                    </div>}

                  {!props.formation.presentiel || !props.formation.presentiel.presentiel &&
                    <div className="criteres ftion_tab_droite">
                      NC
                    </div>}
                </div>

                <div style={{ "width": "100%", "float": "right" }}>
                  <div className="criteres ftion_tab_gauche">
                    A distance :
                  </div>
                  {props.formation.presentiel && props.formation.presentiel.distantiel &&
                    String(props.formation.presentiel.distantiel) === String("1") && <div className="criteres ftion_tab_droite">
                      Oui
                    </div>}

                  {props.formation.presentiel && props.formation.presentiel.distantiel &&
                    String(props.formation.presentiel.distantiel) !== String("1") && <div className="criteres ftion_tab_droite">
                      Non
                    </div>}

                  {!props.formation.presentiel || !props.formation.presentiel.distantiel &&
                    <div className="criteres ftion_tab_droite">
                      NC
                    </div>}
                </div>

                <div style={{ "width": "100%", "float": "right" }}>
                  <div className="criteres ftion_tab_gauche">
                    Support :
                  </div>
                  {props.formation.support && <div className="criteres ftion_tab_droite">
                    {props.formation.support}
                  </div>}




                </div>

              </div>

            </div>
          </div>
          {/*<div className="div_row_droite div_row_droite_critere" >
            {!props.formation.extented_search && props.formation.coeur != "1" &&
              <div style={{ 'float': "right", "width": "100%", textAlign: "right", marginTop: "0rem", }}>

                <Button className="bton_se_renseinger"

                  onClick={(e) => {
                    e.preventDefault();

                    window.open(
                      "/Display-Detail-formation/" + props.formation.internal_url + "/Information",
                      '_blank'
                    );


                  }}

                >Se renseigner</Button>


                <button className="bton_voir_detail"

                  onClick={(e) => {
                    e.preventDefault();
                    //test(props.formation.external_code);
                    window.open(
                      "/Display-Detail-formation/" + props.formation.internal_url,
                      '_blank'
                    );


                  }}
                >Voir details</button>

                {GetCurrentClass_result && GetCurrentClass_result.length > 0 &&
                  <Button variant="contained" className="bton_sinscrire"

                    onClick={(e) => {
                      e.preventDefault();

                      window.open(
                        "/Display-Detail-formation/" + props.formation.internal_url + "/Inscription",
                        '_blank'
                      );

                    }}

                  >S'incrire</Button>
                }

              </div>}

          </div> */}


          {/* /!\ : le pavé pour les cartouche */}

          <div className="div_row_droite div_row_droite_critere"  style={{"width":"100% ! important"}}>
            {!props.formation.extented_search && props.formation.coeur != "1" &&
              <div style={{ 'float': "right", "width": "100%", textAlign: "center", marginTop: "0rem", }}>

                <Button className="bton_voir_detail" style={{"fontSize":"1.8rem", "height":"3.5rem"}}

                  onClick={(e) => {
                    e.preventDefault();
                    //test(props.formation.external_code);
                    window.open(
                      "/Display-Detail-formation/" + props.formation.internal_url,
                      '_blank'
                    );
                  }}
                > <FcCalendar /> &nbsp; Voir details & Dates </Button>
  
                <img src={`${props.formation.img_url}`} className="training_img" />
              </div>}
          </div> 

        </div>
      </div>
    </div>

  )
}


export default Formation_Cartouche_Com;