import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import ToggleSwitch from "./ToggleSwitch";
import ToggleSwitch_v2_mysy from "./ToggleSwitch_v2_mysy";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

import { useCallback, } from "react";
import { DndProvider, useDrag } from "react-dnd";
import { useDrop } from 'react-dnd'

import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import Rating from '@mui/material/Rating';

import { CiBoxList } from "react-icons/ci";
import { FcPlanner } from "react-icons/fc";

import AddCircleIcon from '@mui/icons-material/AddCircle';


const CRM_Opportunite = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const [selected_id, setselected_id] = useState("");


    const [gridline_id, setgridline_id] = useState("");


    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'partner_client_id', headerName: 'partner_client_id', flex: 1, },
        { field: 'titre', headerName: 'titre', flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        { field: 'description', headerName: 'description', width: 0, hide: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'statut', headerName: 'statut', width: 150, flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        { field: 'contact_nom', headerName: 'contact_nom', width: 150, flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'contact_email', headerName: 'contact_email', width: 150, flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'revenu_cible', headerName: 'revenu_cible', width: 150, flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'probabilite_gain', headerName: 'probabilite_gain', width: 150, flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'priorite', headerName: 'priorite', width: 150, flex: 1, hide: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la salle sera <i><font color="red"> définitivement supprimée</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_CRM_Opport_data(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]


    function clean_all_filters() {

        setgridline_id('');

        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");


        setSelectionModel([]);
        setsubmenu("");

        Getall_Partner_CRM_Opportunite();
    }

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    const myRef_head = useRef(null);

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };

    const [isLoading, setLoading] = useState();


    const [session_file_name, setsession_file_name] = useState();

    const [liste_sessions_file_change_api, setliste_sessions_file_change_api] = useState();
    const [liste_sessions_file_change_result, setliste_sessions_file_change_result] = useState();
    const [liste_sessions_file_change_message, setliste_sessions_file_change_message] = useState();
    const liste_sessions_file_change = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;

        //console.log("file_size =  ",file_size," file_type = ",file_type);
        if (file_size > 1000000) {
            alert("Le fichier ne doit pas dépasser un 1 Méga octets");
            return;
        }

        setsession_file_name(event.target.files[0].name);

        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        //console.log("token = " + stored_cookie);

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/xxxxxxxxxxxxxxxxxxxxxx/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                setLoading(false);
                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setliste_sessions_file_change_result(result['message']);
                    setliste_sessions_file_change_api("true");
                    clean_all_filters();
                    Getall_Partner_CRM_Opportunite();
                    alert(" Les opportunités ont été correctement importées");

                }

                else {
                    setliste_sessions_file_change_message(result['message']);
                    setliste_sessions_file_change_api("false");
                    alert('Erreur: ' + result['message']);

                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setliste_sessions_file_change_api("false");
                alert(" Impossible d'importer les opportunités ");
            });
    }


    const myRef = useRef(null)



    const [New_Getall_Partner_CRM_Opportunite_result, setNew_Getall_Partner_CRM_Opportunite_result] = useState([]);

    const [Getall_Partner_CRM_Opportunite_api, setGetall_Partner_CRM_Opportunite_api] = useState();
    const [Getall_Partner_CRM_Opportunite_message, setGetall_Partner_CRM_Opportunite_message] = useState();
    const [Getall_Partner_CRM_Opportunite_result, setGetall_Partner_CRM_Opportunite_result] = useState([]);
    function Getall_Partner_CRM_Opportunite(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_CRM_Opportunite_no_filter/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_CRM_Opportunite  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_CRM_Opportunite  res.data.message r_class = " + res.data.message);
                setGetall_Partner_CRM_Opportunite_api("true");
                setGetall_Partner_CRM_Opportunite_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_titre = JSON.parse(x).titre;
                    var local_description = JSON.parse(x).description;
                    var local_statut = JSON.parse(x).statut;
                    var local_priorite = JSON.parse(x).priorite;
                    var local_revenu_cible = JSON.parse(x).revenu_cible;
                    var local_client_nom = JSON.parse(x).client_nom;
                    var local_client_id = JSON.parse(x).partner_client_id;

                    var local_contact_nom = JSON.parse(x).contact_nom;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_titre,
                        "titre": local_titre,
                        "title": local_titre,
                        "description": local_description,
                        "status": local_statut,
                        "priorite": local_priorite,
                        "revenu_cible": local_revenu_cible,
                        "client_nom": local_client_nom,
                        "client_id": local_client_id,
                        "contact_nom": local_contact_nom,

                    };
                    new_data2.push(node);
                    setTaskStatus(new_data2);

                    var node = {
                        "_id": "",
                        "id": "",
                        "label": "",
                        "titre": "",
                        "title": "",
                        "description": "",
                        "status": "",
                        "priorite": "",
                        "revenu_cible": "",
                        "client_nom": "",
                        "client_id": "",
                        "contact_nom": "",
                    };
                    new_data2.push(node);

                });


                if (new_data2.length > 0) {
                    setNew_Getall_Partner_CRM_Opportunite_result(new_data2);

                }

            }
            else {
                setGetall_Partner_CRM_Opportunite_api("false");
                setGetall_Partner_CRM_Opportunite_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_CRM_Opportunite = ', error);
            setGetall_Partner_CRM_Opportunite_api("false");
            alert(" Impossible de récuperer les opportunités");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Option_Status, setNew_Option_Status] = useState([]);

    const [Opportunite_chanel, setOpportunite_chanel] = useState([]);
    const [Opportunite_chanel_label, setOpportunite_chanel_label] = useState();

    const [New_Getall_Partner_CRM_Opport_Step_result, setNew_Getall_Partner_CRM_Opport_Step_result] = useState([]);

    const [Getall_Partner_CRM_Opport_Step_api, setGetall_Partner_CRM_Opport_Step_api] = useState();
    const [Getall_Partner_CRM_Opport_Step_message, setGetall_Partner_CRM_Opport_Step_message] = useState();
    const [Getall_Partner_CRM_Opport_Step_result, setGetall_Partner_CRM_Opport_Step_result] = useState();
    function Getall_Partner_CRM_Opport_Step(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_CRM_List_Opportunite_Etape/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_CRM_Opport_Step  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_CRM_Opport_Step  res.data.message r_class = " + res.data.message);
                setGetall_Partner_CRM_Opport_Step_api("true");
                setGetall_Partner_CRM_Opport_Step_result(res.data.message);

                var new_data2 = [];
                var local_opport_chanel = [];
                var local_new_option = [];
                var local_opport_chanel_label = {};
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;
                    var local_rang = JSON.parse(x).rang;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "description": local_description,
                        "rang": local_rang
                    };
                    new_data2.push(node);
                    local_opport_chanel.push(local_code);

                    var node_for_status = {
                        "_id": local_id,
                        "id": local_code,
                        "label": local_code[0].toUpperCase() + local_code.slice(1),
                        "value": local_code,

                    };
                    local_new_option.push(node_for_status);


                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",
                    "rang": ""
                };

                //local_opport_chanel.push(node);

                if (new_data2.length > 0) {
                    setNew_Getall_Partner_CRM_Opport_Step_result(new_data2);
                    setOpportunite_chanel(local_opport_chanel);

                    if (String(props.reduce) === "0") {
                        var tmp = 80 / new_data2.length;
                        var tmp_val = tmp + "vw";

                        setkanban_colonne_width(tmp_val);
                    } else {
                        var tmp = 95 / new_data2.length;
                        var tmp_val = tmp + "vw";

                        setkanban_colonne_width(tmp_val);
                    }


                } else {
                    setNew_Getall_Partner_CRM_Opport_Step_result([]);
                    setOpportunite_chanel([]);
                }

                var node_for_status = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "value": "",

                };
                local_new_option.push(node_for_status);
                setNew_Option_Status(local_new_option);

                for (let i = 0; i < local_opport_chanel.length; i++) {
                    var temp_val = String(local_opport_chanel[i]);
                    local_opport_chanel_label[String(local_opport_chanel[i])] = temp_val[0].toUpperCase() + temp_val.slice(1);
                }

                setOpportunite_chanel_label(local_opport_chanel_label);
            }
            else {
                setGetall_Partner_CRM_Opport_Step_api("false");
                setGetall_Partner_CRM_Opport_Step_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_CRM_Opport_Step = ', error);
            setGetall_Partner_CRM_Opport_Step_api("false");
            alert(" Impossible de récuperer les étapes");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Partner_CRM_Opportunite_with_Filter_api, setGetall_Partner_CRM_Opportunite_with_Filter_api] = useState();
    const [Getall_Partner_CRM_Opportunite_with_Filter_message, setGetall_Partner_CRM_Opportunite_with_Filter_message] = useState();
    const [Getall_Partner_CRM_Opportunite_with_Filter_result, setGetall_Partner_CRM_Opportunite_with_Filter_result] = useState();
    function Getall_Partner_CRM_Opportunite_with_Filter(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_CRM_Opportunite_with_filter/";



        setSelectionModel([]);
        setGetall_Partner_CRM_Opportunite_result([]);
        setTaskStatus([]);

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_CRM_Opportunite_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_CRM_Opportunite_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_CRM_Opportunite_with_Filter_api("true");
                setGetall_Partner_CRM_Opportunite_with_Filter_result(res.data.message);


                setGetall_Partner_CRM_Opportunite_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_titre = JSON.parse(x).titre;
                    var local_description = JSON.parse(x).description;
                    var local_statut = JSON.parse(x).statut;
                    var local_priorite = JSON.parse(x).priorite;
                    var local_revenu_cible = JSON.parse(x).revenu_cible;
                    var local_client_nom = JSON.parse(x).client_nom;
                    var local_client_id = JSON.parse(x).partner_client_id;

                    var local_contact_nom = JSON.parse(x).contact_nom;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_titre,
                        "titre": local_titre,
                        "title": local_titre,
                        "description": local_description,
                        "status": local_statut,
                        "priorite": local_priorite,
                        "revenu_cible": local_revenu_cible,
                        "client_nom": local_client_nom,
                        "client_id": local_client_id,
                        "contact_nom": local_contact_nom,

                    };
                    new_data2.push(node);
                    setTaskStatus(new_data2);

                    var node = {
                        "_id": "",
                        "id": "",
                        "label": "",
                        "titre": "",
                        "title": "",
                        "description": "",
                        "status": "",
                        "priorite": "",
                        "revenu_cible": "",
                        "client_nom": "",
                        "client_id": "",
                        "contact_nom": "",
                    };
                    new_data2.push(node);

                });


                if (new_data2.length > 0) {
                    setNew_Getall_Partner_CRM_Opportunite_result(new_data2);

                }

            }
            else {
                setGetall_Partner_CRM_Opportunite_with_Filter_api("false");
                setGetall_Partner_CRM_Opportunite_with_Filter_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_CRM_Opportunite_with_Filter = ', error);
            setGetall_Partner_CRM_Opportunite_with_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }




    useEffect(() => {


        Get_List_Partner_Clients();
        Getall_Training_Employee_No_Filter();

        Getall_Partner_CRM_Opport_Step();
        Getall_Partner_CRM_Opportunite();


        var local_id = New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(p_detail_vendeur_id))
        if (local_id.length <= 0) {
            setp_detail_vendeur_id("");
        } else {
            setp_detail_vendeur_id(props.conntected_employee_id);
        }


        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, []);





    const [p_one_detail_titre, setp_one_detail_titre] = useState();
    const [p_one_detail_description, setp_one_detail_description] = useState();
    const [p_one_detail_revenu_cible, setp_one_detail_revenu_cible] = useState();
    const [p_one_detail_probabilite_gain, setp_one_detail_probabilite_gain] = useState();
    const [p_one_detail_priorite, setp_one_detail_priorite] = useState();

    const [p_one_detail_partner_client_id, setp_one_detail_partner_client_id] = useState();
    const [p_one_detail_contact_civilite, setp_one_detail_contact_civilite] = useState();
    const [p_one_detail_contact_nom, setp_one_detail_contact_nom] = useState();
    const [p_one_detail_contact_email, setp_one_detail_contact_email] = useState();
    const [p_one_detail_contact_telephone, setp_one_detail_contact_telephone] = useState();

    const [p_one_detail_commentaire, setp_one_detail_commentaire] = useState();
    const [p_one_detail_vendeur_id, setp_one_detail_vendeur_id] = useState();
    const [p_one_detail_statut, setp_one_detail_statut] = useState();
    const [p_one_detail_date_fermeture, setp_one_detail_date_fermeture] = useState();




    const [p_detail_titre, setp_detail_titre] = useState();
    const [p_detail_description, setp_detail_description] = useState();
    const [p_detail_revenu_cible, setp_detail_revenu_cible] = useState();
    const [p_detail_probabilite_gain, setp_detail_probabilite_gain] = useState("");
    const [p_detail_priorite, setp_detail_priorite] = useState();

    const [p_detail_partner_client_id, setp_detail_partner_client_id] = useState("");
    const [p_detail_contact_civilite, setp_detail_contact_civilite] = useState("");
    const [p_detail_contact_nom, setp_detail_contact_nom] = useState();
    const [p_detail_contact_email, setp_detail_contact_email] = useState();
    const [p_detail_contact_telephone, setp_detail_contact_telephone] = useState();



    const [p_detail_commentaire, setp_detail_commentaire] = useState();
    const [p_detail_vendeur_id, setp_detail_vendeur_id] = useState('');
    const [p_detail_statut, setp_detail_statut] = useState("");
    const [p_detail_statut_id, setp_detail_statut_id] = useState("");
    const [p_detail_date_fermeture, setp_detail_date_fermeture] = useState();


    const [p_detail_statut_is_gagne, setp_detail_statut_is_gagne] = useState("");



    function clear_detail_fields() {
        setp_detail_titre("");
        setp_detail_description("");
        setp_detail_revenu_cible("");
        setp_detail_probabilite_gain("");
        setp_detail_priorite("");
        setp_detail_partner_client_id("");
        setp_detail_contact_civilite("");
        setp_detail_contact_nom("");
        setp_detail_contact_email("");
        setp_detail_contact_telephone("");
        setp_detail_commentaire("");
        setp_detail_vendeur_id('');
        setp_detail_statut("");
        setp_detail_statut_id('');
        setp_detail_statut_is_gagne('');
        setp_detail_date_fermeture(date_today_90j.toLocaleDateString('fr-FR'));

    }

    function clear_One_detail_fields() {
        setp_one_detail_titre("");
        setp_one_detail_description("");
        setp_one_detail_revenu_cible("");
        setp_one_detail_probabilite_gain("");
        setp_one_detail_priorite("");
        setp_one_detail_partner_client_id("");
        setp_one_detail_contact_civilite("");
        setp_one_detail_contact_nom("");
        setp_one_detail_contact_email("");
        setp_one_detail_contact_telephone("");
        setp_one_detail_commentaire("");
        setp_one_detail_vendeur_id("");
        setp_one_detail_statut("");
        setp_one_detail_date_fermeture(date_today_90j.toLocaleDateString('fr-FR'));

    }

    const [Add_Update_Crm_Opportunite_api, setAdd_Update_Crm_Opportunite_api] = useState();
    const [Add_Update_Crm_Opportunite_message, setAdd_Update_Crm_Opportunite_message] = useState();
    const [Add_Update_Crm_Opportunite_result, setAdd_Update_Crm_Opportunite_result] = useState();
    function Add_Update_Crm_Opportunite() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("titre", p_detail_titre);
        form.append("description", p_detail_description);
        //form.append("statut", p_detail_statut);
        form.append("statut_id", p_detail_statut_id);



        if (p_detail_revenu_cible)
            form.append("revenu_cible", p_detail_revenu_cible);
        else
            form.append("revenu_cible", "");


        if (p_detail_probabilite_gain)
            form.append("probabilite_gain", p_detail_probabilite_gain);
        else
            form.append("probabilite_gain", "");


        if (p_detail_priorite)
            form.append("priorite", p_detail_priorite);
        else
            form.append("priorite", "");

        if (p_detail_commentaire)
            form.append("commentaire", p_detail_commentaire);
        else
            form.append("commentaire", "");


        if (p_detail_vendeur_id)
            form.append("vendeur_id", p_detail_vendeur_id);
        else
            form.append("vendeur_id", "");

        if (p_detail_date_fermeture)
            form.append("date_fermeture", p_detail_date_fermeture);
        else
            form.append("date_fermeture", "");

        if (p_detail_partner_client_id)
            form.append("partner_client_id", p_detail_partner_client_id);
        else
            form.append("partner_client_id", "");

        if (p_detail_contact_civilite)
            form.append("contact_civilite", p_detail_contact_civilite);
        else
            form.append("contact_civilite", "");


        if (p_detail_contact_nom)
            form.append("contact_nom", p_detail_contact_nom);
        else
            form.append("contact_nom", "");


        if (p_detail_contact_email)
            form.append("contact_email", p_detail_contact_email);
        else
            form.append("contact_email", "");


        if (p_detail_contact_telephone)
            form.append("contact_telephone", p_detail_contact_telephone);
        else
            form.append("contact_telephone", "");



        var myurl = "";

        if (selected_id && String(selected_id).length > 2) {
            form.append("opport_id", selected_id);
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_CRM_Opportunite/";
        } else {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_CRM_Opportunite/";
        }



        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_Crm_Opportunite  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Crm_Opportunite  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_Crm_Opportunite_api("true");
                setAdd_Update_Crm_Opportunite_result(res.data.message);

                Getall_Partner_CRM_Opport_Step();
                Getall_Partner_CRM_Opportunite();

                setgridline_id('');
                clear_detail_fields();
                alert(res.data.message);



                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

            }
            else {
                setAdd_Update_Crm_Opportunite_api("false");
                setAdd_Update_Crm_Opportunite_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_Crm_Opportunite = ' + error);
            setAdd_Update_Crm_Opportunite_api("false");
            alert(" Impossible de mettre à jour l'opportunité");

        })
    }

    const [Add_Update_Crm_Opportunite_Status_api, setAdd_Update_Crm_Opportunite_Status_api] = useState();
    const [Add_Update_Crm_Opportunite_Status_message, setAdd_Update_Crm_Opportunite_Status_message] = useState();
    const [Add_Update_Crm_Opportunite_Status_result, setAdd_Update_Crm_Opportunite_Status_result] = useState();
    function Add_Update_Crm_Opportunite_Status(local_opport_id, new_status_id) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("statut_id", new_status_id);
        form.append("opport_id", local_opport_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_CRM_Opportunite/";

        //setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_Crm_Opportunite_Status  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Crm_Opportunite_Status  res.data.message r_class = " + res.data.message);

            //setLoading(false);
            if (String(res.data.status) === String("true")) {
                setAdd_Update_Crm_Opportunite_Status_api("true");
                setAdd_Update_Crm_Opportunite_Status_result(res.data.message);

                Getall_Partner_CRM_Opport_Step();
                Getall_Partner_CRM_Opportunite();

                setgridline_id('');
                clear_detail_fields();
                Getall_Partner_CRM_Opportunite();



            }
            else {
                setAdd_Update_Crm_Opportunite_Status_api("false");
                setAdd_Update_Crm_Opportunite_Status_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            //setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Update_Crm_Opportunite_Status = ' + error);
            setAdd_Update_Crm_Opportunite_Status_api("false");
            alert(" Impossible de mettre à jour l'opportunité");

        })
    }


    const [handleClick_delete_CRM_Opport_data_api, sethandleClick_delete_CRM_Opport_data_api] = useState();
    const [handleClick_delete_CRM_Opport_data_message, sethandleClick_delete_CRM_Opport_data_message] = useState();
    const [handleClick_delete_CRM_Opport_data_result, sethandleClick_delete_CRM_Opport_data_result] = useState();
    function handleClick_delete_CRM_Opport_data(event, cellValues) {

        var local_opport_id = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("opport_id", local_opport_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_CRM_Opportunite/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_CRM_Opport_data  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_CRM_Opport_data  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_CRM_Opport_data_api("true");
                sethandleClick_delete_CRM_Opport_data_result(res.data.message);

                Getall_Partner_CRM_Opport_Step();
                Getall_Partner_CRM_Opportunite();
                alert(res.data.message);

            }
            else {
                sethandleClick_delete_CRM_Opport_data_api("false");
                sethandleClick_delete_CRM_Opport_data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_CRM_Opport_data = ', error);
            sethandleClick_delete_CRM_Opport_data_api("false");
            alert(" Impossible de supprimer l'opportunité");
            //setmyApimyApiMessage("")
        })
    }

    const [handleClick_delete_CRM_Opport_data_from_id_api, sethandleClick_delete_CRM_Opport_data_from_id_api] = useState();
    const [handleClick_delete_CRM_Opport_data_from_id_message, sethandleClick_delete_CRM_Opport_data_from_id_message] = useState();
    const [handleClick_delete_CRM_Opport_data_from_id_result, sethandleClick_delete_CRM_Opport_data_from_id_result] = useState();
    function handleClick_delete_CRM_Opport_data_from_id(id_to_delete) {

        var local_opport_id = id_to_delete;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("opport_id", local_opport_id);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Given_CRM_Opportunite/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_CRM_Opport_data_from_id  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_CRM_Opport_data_from_id  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_CRM_Opport_data_from_id_api("true");
                sethandleClick_delete_CRM_Opport_data_from_id_result(res.data.message);

                Getall_Partner_CRM_Opport_Step();
                Getall_Partner_CRM_Opportunite();


                Dialog_OPPORTUNITE_handleClose_buton();

                alert(res.data.message);

            }
            else {
                sethandleClick_delete_CRM_Opport_data_from_id_api("false");
                sethandleClick_delete_CRM_Opport_data_from_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_CRM_Opport_data_from_id = ', error);
            sethandleClick_delete_CRM_Opport_data_from_id_api("false");
            alert(" Impossible de supprimer l'opportunité");
            //setmyApimyApiMessage("")
        })
    }


    function handleClick_edit_Opportunite_From_Line(selected_row_id) {

        //console.log("### selected_row_id = ", selected_row_id);


        var line = JSON.parse(Getall_Partner_CRM_Opportunite_result[selected_row_id]);

        //console.log("### line = ", line);
        var opportunite_id = line._id;
        setselected_id(opportunite_id);

        //console.log(" ### employe_id  = ", employe_id);
        Get_Given_Opportunite_Data(opportunite_id);
        setDialog_OPPORTUNITE_open(true);


    }

    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;
                    var local_cond_paiement_id = JSON.parse(x).invoice_condition_paiement_id;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,
                        "invoice_condition_paiement_id": local_cond_paiement_id,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "raison_sociale": "",
                    "invoice_condition_paiement_id": "",

                };
                new_data2.push(node);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }


    const [kanban_colonne_width, setkanban_colonne_width] = useState("16vw");

    const [Get_Given_Opportunite_Data_api, setGet_Given_Opportunite_Data_api] = useState();
    const [Get_Given_Opportunite_Data_message, setGet_Given_Opportunite_Data_message] = useState();
    const [Get_Given_Opportunite_Data_result, setGet_Given_Opportunite_Data_result] = useState();
    function Get_Given_Opportunite_Data(local_opportunite_id) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("opport_id", local_opportunite_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_CRM_Opportunite/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Given_Opportunite_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Opportunite_Data  res.data.message r_class = " + res.data.message);
                setGet_Given_Opportunite_Data_api("true");
                setGet_Given_Opportunite_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_opportunite = JSON.parse(res.data.message);


                    if (mylocal_opportunite.titre)
                        setp_detail_titre(mylocal_opportunite.titre);


                    if (mylocal_opportunite.description)
                        setp_detail_description(mylocal_opportunite.description);


                    if (mylocal_opportunite.revenu_cible)
                        setp_detail_revenu_cible(mylocal_opportunite.revenu_cible);

                    if (mylocal_opportunite.probabilite_gain)
                        setp_detail_probabilite_gain(mylocal_opportunite.probabilite_gain);


                    if (mylocal_opportunite.priorite)
                        setp_detail_priorite(mylocal_opportunite.priorite);



                    if (mylocal_opportunite.partner_client_id)
                        setp_detail_partner_client_id(mylocal_opportunite.partner_client_id);

                    if (mylocal_opportunite.contact_civilite)
                        setp_detail_contact_civilite(mylocal_opportunite.contact_civilite);


                    if (mylocal_opportunite.contact_nom)
                        setp_detail_contact_nom(mylocal_opportunite.contact_nom);


                    if (mylocal_opportunite.contact_email)
                        setp_detail_contact_email(mylocal_opportunite.contact_email);
                    else
                        setp_detail_contact_email("");


                    if (mylocal_opportunite.contact_telephone)
                        setp_detail_contact_telephone(mylocal_opportunite.contact_telephone);
                    else
                        setp_detail_contact_telephone("");


                    if (mylocal_opportunite.commentaire)
                        setp_detail_commentaire(mylocal_opportunite.commentaire);
                    else
                        setp_detail_commentaire("");

                    if (mylocal_opportunite.vendeur_id)
                        setp_detail_vendeur_id(mylocal_opportunite.vendeur_id);
                    else
                        setp_detail_vendeur_id("");


                    if (mylocal_opportunite.statut)
                        setp_detail_statut(mylocal_opportunite.statut);
                    else
                        setp_detail_statut("");


                    if (mylocal_opportunite.statut_id)
                        setp_detail_statut_id(mylocal_opportunite.statut_id);
                    else
                        setp_detail_statut_id("");


                    if (mylocal_opportunite.is_gagne)
                        setp_detail_statut_is_gagne(mylocal_opportunite.is_gagne);
                    else
                        setp_detail_statut_is_gagne("");


                    if (mylocal_opportunite.date_fermeture)
                        setp_detail_date_fermeture(mylocal_opportunite.date_fermeture);



                } else {
                    alert(" Aucune opportunité récuperée");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Given_Opportunite_Data_api("false");
                setGet_Given_Opportunite_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Opportunite_Data = ', error);
            setGet_Given_Opportunite_Data_api("false");
            alert(" Impossible de recuperer les données de l'opportunité");
            //setmyApimyApiMessage("")
        })
    }


    // MySy Comment : design d'une tache (couleur, taille, etc)
    const classes = {
        board: {
            display: "flex",
            margin: "0 auto",
            width: "100%",
            fontFamily: 'Arial, "Helvetica Neue", sans-serif'
        },
        column: {
            // minWidth: 200,
            //width: "16vw",
            width: kanban_colonne_width,
            height: "80vh",
            margin: "0 auto",
            backgroundColor: "#212F3D",
            border: 'double #32a1ce',

        },
        columnHead: {
            textAlign: "center",
            padding: 10,
            fontSize: "1.2em",
            backgroundColor: "#C6D8AF",
            border: "red",
        },
        item: {
            padding: 10,
            margin: 10,
            fontSize: "0.8em",
            cursor: "pointer",
            backgroundColor: "white"
        }
    };


    const KanbanColumn = ({ status, changeTaskStatus, children }) => {
        const ref = useRef(null);
        const [, drop] = useDrop({
            accept: "card",
            drop(item) {
                changeTaskStatus(item.id, status);
            }
        });
        drop(ref);
        return <div ref={ref}> {children}</div>;
    };

    const KanbanItem = ({ id, children }) => {
        const ref = useRef(null);


        const [{ isDragging }, drag] =
            useDrag({
                type: "card",
                item: () => ({ id }),
                collect: monitor => ({
                    isDragging: monitor.isDragging()
                })
            });

        const opacity = isDragging ? 0 : 1;
        drag(ref);
        return (
            <div ref={ref} style={{ opacity }}>
                {children}
            </div>
        );
    };

    const [tasks, setTaskStatus] = useState([]);

    // MySy Comment : quand on change la position d'une tache, dans cette fonction on appel l'api de mise à jour
    const changeTaskStatus = useCallback(
        (id, status) => {


            let task = tasks.find(task => task._id === id);
            const taskIndex = tasks.indexOf(task);
            task = { ...task, status };
            let newTasks = update(tasks, {
                [taskIndex]: { $set: task }
            });

            setTaskStatus(newTasks);
            // Recuperer l'_id du statuts

            if (New_Option_Status.length > 0 && status) {
                var local_status_id = New_Option_Status.filter((data) => (data).value === String(status))[0]._id;
                Add_Update_Crm_Opportunite_Status(id, local_status_id);
            }
            //eeeee */

        },
        [tasks]
    );



    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "ismanager": ""
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [Dialog_OPPORTUNITE_open, setDialog_OPPORTUNITE_open] = React.useState(false);
    const Dialog_OPPORTUNITE_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_OPPORTUNITE_handleClose_buton = () => {

        setDialog_OPPORTUNITE_open(false);
        clear_One_detail_fields();
        clear_detail_fields();
    };

    const New_Option_Proba_Gain = [
        { "id": "faible", "label": "Faible", "value": "faible" },
        { "id": "moyen", "label": "Moyen", "value": "moyen" },
        { "id": "fort", "label": "Fort", "value": "fort" },
        { "id": "inconnu", "label": "Inconnu", "value": "inconnu" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_Option_Civilite = [
        {
            value: 'M',
            label: 'M',
        },
        {
            value: 'Mme',
            label: 'Mme',
        },
        {
            value: 'Neutre',
            label: 'Neutre',
        },
        {
            value: '',
            label: '',
        },
    ];

    const [opport_changed, setopport_changed] = useState("0");

    const [display_view, setdisplay_view] = useState("kanban");
    function func_display_kanban_view() {
        setdisplay_view("kanban");
    }

    function func_display_list_view() {
        setdisplay_view("list");
    }


    function submenu_add_one_crm_opport() {
        Getall_Partner_CRM_Opport_Step();
        Getall_Partner_CRM_Opportunite();
        clear_detail_fields();
        setselected_id("");
        setDialog_OPPORTUNITE_open(true);
    }

    const New_Option_Filter = [
        { "id": "titre", "label": "Titre ", "value": "titre" },
        // { "id": "vendeur_id", "label": "Vendeur ", "value": "vendeur_id" }, ==> a faire plus tard
        { "id": "revenu_cible_superieur", "label": "Revenu (Supérieur à ) ", "value": "revenu_cible_superieur" },
        { "id": "revenu_cible_inferieur", "label": "Revenu (Inférieur à ) ", "value": "revenu_cible_inferieur" },
        { "id": "date_fermeture_avant", "label": "Date fermeture (avant) ", "value": "date_fermeture_avant" },
        { "id": "date_fermeture_apres", "label": "Date fermeture (après) ", "value": "date_fermeture_apres" },
        { "id": "partner_client_nom", "label": "Nom Client", "value": "partner_client_nom" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]


    return (
        <div className="crm_opportunite">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>


            {/*  Dialog pour gerer une OPPORTUNITE */}
            <Dialog
                open={Dialog_OPPORTUNITE_open}
                onClose={Dialog_OPPORTUNITE_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "30rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                  
                    {p_detail_statut_is_gagne && String(p_detail_statut_is_gagne) === "1" && 
                    <div className="session_caract_Dialog" style={{ textAlign: "right", marginRight: '10px' }} >
                        <Button onClick={(e) => { 
                            window.open(
                                process.env.REACT_APP_FRONT_URL + "Partner/mes_cmd",
                                '_blank'
                            );

                        }}
                           style={{ 
                            
                           }}><AddCircleIcon style={{ fontSize: "15px", }}/> Devis </Button>
                    </div>}

                    <div className="session_caract_Dialog" > Titre<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="dialog_opport_titre"
                            id="dialog_opport_titre"
                            className="disabled_style"

                            value={p_detail_titre}
                            onChange={(e) => {
                                setp_detail_titre(e.target.value);
                                setopport_changed("1");
                            }
                            }
                        />
                    </div>


                    <div className="session_caract_Dialog" > Description<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="dialog_opport_desc"
                            id="dialog_opport_desc"
                            className="disabled_style"

                            value={p_detail_description}
                            onChange={(e) => {
                                setp_detail_description(e.target.value);
                                setopport_changed("1");
                            }
                            }
                        />
                    </div>

                    <div className="session_caract_Dialog" > Client
                        {New_Get_List_Partner_Clients_result && New_Get_List_Partner_Clients_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                id="dialog_opport_ref_client"
                                Name="dialog_opport_ref_client"

                                className="disabled_style"

                                options={New_Get_List_Partner_Clients_result}
                                value={New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(p_detail_partner_client_id))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {

                                        setp_detail_partner_client_id(value._id);
                                        setp_detail_contact_civilite("");
                                        setp_detail_contact_nom("");
                                        setp_detail_contact_email("");
                                        setp_detail_contact_telephone("")

                                    } else {
                                        setp_detail_partner_client_id("");
                                    }
                                    setopport_changed("1");

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}

                    </div>


                    {String(p_detail_partner_client_id).length < 2 &&
                        <div className="session_caract_Dialog" > Civilité

                            <Autocomplete
                                disablePortal
                                name="dialog_opport_nom_contact"
                                id="dialog_opport_nom_contact"
                                className="disabled_style"
                                options={New_Option_Civilite}
                                value={New_Option_Civilite.filter((data) => (data).value === String(p_detail_contact_civilite))[0].label}

                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_detail_contact_civilite(value.value);
                                        setopport_changed("1");

                                    } else {
                                        setp_detail_contact_civilite("");
                                        setopport_changed("1");

                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>
                    }


                    {String(p_detail_partner_client_id).length < 2 &&
                        <div className="session_caract_Dialog" > Nom Contact
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="dialog_opport_nom_contact"
                                id="dialog_opport_nom_contact"

                                className="disabled_style"

                                value={p_detail_contact_nom}
                                onChange={(e) => {
                                    setp_detail_contact_nom(e.target.value);
                                    setopport_changed("1");
                                }
                                }
                            />

                        </div>
                    }

                    {String(p_detail_partner_client_id).length < 2 &&
                        <div className="session_caract_Dialog" > Email Contact
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="dialog_opport_nom_contact"
                                id="dialog_opport_nom_contact"

                                className="disabled_style"

                                value={p_detail_contact_email}
                                onChange={(e) => {
                                    setp_detail_contact_email(e.target.value);
                                    setopport_changed("1");
                                }
                                }
                            />

                        </div>
                    }



                    {String(p_detail_partner_client_id).length < 2 &&
                        <div className="session_caract_Dialog" > Téléphone Contact
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="dialog_opport_nom_contact"
                                id="dialog_opport_nom_contact"

                                className="disabled_style"

                                value={p_detail_contact_telephone}
                                onChange={(e) => {
                                    setp_detail_contact_telephone(e.target.value);
                                    setopport_changed("1");
                                }
                                }
                            />

                        </div>
                    }



                    <div className="session_caract_Dialog" > Statut<br />
                        {New_Option_Status && New_Option_Status.length > 0 &&
                            <Autocomplete
                                disablePortal
                                name="dialog_opport_status"
                                id="dialog_opport_status"
                                className="disabled_style"
                                value={New_Option_Status.filter((data) => (data)._id === String(p_detail_statut_id))[0].label}
                                options={New_Option_Status}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_detail_statut(value.value);
                                        setp_detail_statut_id(value._id);
                                        setopport_changed("1");
                                    } else {
                                        setp_detail_statut("");
                                        setp_detail_statut_id("")
                                        setopport_changed("1");
                                    }


                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}




                    </div>


                    <div className="session_caract_Dialog" > Probabilité de Gain<br />

                        {New_Option_Proba_Gain && <Autocomplete
                            disablePortal
                            name="dialog_opport_proba_gain"
                            id="dialog_opport_proba_gain"
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                            className="disabled_style"
                            options={New_Option_Proba_Gain}
                            value={New_Option_Proba_Gain.filter((data) => (data).value === String(p_detail_probabilite_gain))[0].label}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_detail_probabilite_gain(value.value);
                                } else {
                                    setp_detail_probabilite_gain("");
                                }
                                setopport_changed("1");
                            }}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>


                    <div className="session_caract_Dialog" > Montant Gain (estimé)
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="dialog_opport_montant"
                            id="dialog_opport_montant"
                            type="number"
                            step="any"
                            className="disabled_style"

                            value={p_detail_revenu_cible}
                            onChange={(e) => {
                                setp_detail_revenu_cible(e.target.value);
                                setopport_changed("1");
                            }
                            }
                        />

                    </div>






                    <div className="session_caract_Dialog" > Priorité &nbsp;<br />
                        <nav style={{ "float": "right", "width": "80%", "marginRight": "10%", "textAlign": "right" }}>
                            <Rating
                                name="dialog_opport_prio"
                                id="dialog_opport_prio"


                                precision={0.5}
                                value={parseInt(String(p_detail_priorite))}
                                onChange={(event, newValue) => {
                                    setp_detail_priorite(newValue);
                                }} />
                        </nav>


                    </div>


                    <div className="session_caract_Dialog" > Date Fermeture <br />
                        <DatePicker
                            name="dialog_opport_date_fermeture"
                            id="dialog_opport_date_fermeture"

                            selected={new Date(moment(p_detail_date_fermeture, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_detail_date_fermeture(format(date, 'd/MM/yyyy'));
                                setopport_changed("1");

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style"

                            locale='fr-FR'

                        />

                    </div>

                    <div className="session_caract_Dialog" > Commentaire
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="dialog_opport_comment"
                            id="dialog_opport_comment"
                            multiline
                            rows={2}
                            className="disabled_style"

                            value={p_detail_commentaire}
                            onChange={(e) => {
                                setp_detail_commentaire(e.target.value);
                                setopport_changed("1");
                            }
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > Vendeur <br />


                        {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&
                            <Autocomplete
                                disablePortal
                                name="dialog_opport_vendeur"
                                id="dialog_opport_vendeur"
                                className="disabled_style"
                                options={New_Getall_Training_Employee_No_Filter_result}
                                value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === p_detail_vendeur_id)[0].label || ""}


                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_vendeur_id(value._id);
                                        setopport_changed("1");
                                    } else {
                                        setp_detail_vendeur_id("");
                                        setopport_changed("1");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                    </div>


                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">


                            {selected_id && String(selected_id).length > 2 && <Button onClick={(e) => {
                                Add_Update_Crm_Opportunite();
                                Dialog_OPPORTUNITE_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Mettre à jour </Button>}


                            {String(selected_id).length < 2 && <Button onClick={(e) => {
                                Add_Update_Crm_Opportunite();
                                Dialog_OPPORTUNITE_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Ajouter </Button>}


                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_OPPORTUNITE_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>

                        {selected_id && String(selected_id).length > 2 && <div className="div_row">
                            <div className="div_row" style={{ "textAlign": "center", "marginTop": "10px" }}>
                                <Button onClick={(e) => {
                                    const response = window.confirm("Confirmez-vous la suppression de l'opportunité ?");
                                    if (!response) {
                                        return;
                                    }
                                    handleClick_delete_CRM_Opport_data_from_id(selected_id);
                                }
                                } className="bton_supprime_dialog">Supprimer l'opportunité</Button>
                            </div>


                        </div>}
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer une OPPORTUNITE */}


            <h3> Vos Opportunités </h3>

            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value("");
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Partner_CRM_Opportunite_with_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>



            <div style={{ "textAlign": "right", "float": "right", "width": "100%", "paddingRight": "15px" }}>
                Affichage : <nav className="mode_affichage" onClick={func_display_kanban_view}> <FcPlanner /> Kanban </nav>
                <nav className="mode_affichage" onClick={func_display_list_view}> <CiBoxList /> Liste </nav>
            </div>


            {
                String(display_view) === "list" && <div className="div_row">
                    <div className="session_data">
                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',

                                        // Gestion des cellule a distance ou presentiel
                                        '& .cell--distantiel': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'black',
                                        },

                                        '& .cell--presentiel': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'black',
                                        },


                                        // gestion couleur des lignes avec le statut d'inscription
                                        '& .line--statut--annule': {
                                            backgroundColor: '#D8D8D8',
                                            color: 'white',
                                        },

                                        '& .line--statut--inscrit': {
                                            backgroundColor: '#CEF6D8',
                                            color: 'black',
                                        },
                                        '& .line--statut--preinscrit': {
                                            backgroundColor: '#F7F2E0',
                                            color: 'black',
                                        },

                                        /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                            "& .MuiDataGrid-row": {
                                                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                            }
                                        },*/
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },
                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        },

                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        disableSelectionOnClick
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setSelectionModel(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_materiel_From_Line(newSelectionModel);
                                                */
                                            if (newSelectionModel.length !== 1) {
                                                setsubmenu();
                                                //setdisplay_detail_materiel();
                                                //setadd_One_Materiel();
                                            }
                                        }}
                                        selectionModel={selectionModel}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={Getall_Partner_CRM_Opportunite_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                partner_client_id: JSON.parse(item).partner_client_id,
                                                titre: JSON.parse(item).titre,
                                                description: JSON.parse(item).description,
                                                statut: JSON.parse(item).statut,
                                                contact_nom: JSON.parse(item).contact_nom,
                                                contact_email: JSON.parse(item).contact_email,
                                                revenu_cible: JSON.parse(item).revenu_cible,
                                                probabilite_gain: JSON.parse(item).probabilite_gain,
                                                priorite: JSON.parse(item).priorite,

                                            }
                                        ))}

                                        columns={columns}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            clear_detail_fields();
                                            setgridline_id(newSelectionModel.row.id);
                                            handleClick_edit_Opportunite_From_Line(newSelectionModel.row.id);

                                        }}


                                        rowsPerPageOptions={[10]}
                                        //disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {
                                            //field === 'distantiel'
                                            if (params.field === 'distantiel' && String(params.value) === "1") {
                                                return 'cell--distantiel';
                                            }
                                            if (params.field === "presentiel" && String(params.value) == "1") {
                                                return 'cell--presentiel';
                                            }


                                            //field === "statut"
                                            if (params.field === "status" && String(params.value) == "0") {
                                                return 'cell--statut--preinscrit';
                                            }

                                            if (params.field === "status" && String(params.value) == "1") {
                                                return 'cell--statut--inscrit';
                                            }

                                            if (params.field === "status" && String(params.value) == "-1") {
                                                return 'cell--statut--annule';
                                            }

                                        }}
                                        getRowClassName={(params) => {

                                            if (String(params.row.status) === "-1") {
                                                return 'line--statut--annule';
                                            }
                                            if (String(params.row.status) === "0") {
                                                return 'line--statut--preinscrit';
                                            }
                                            if (String(params.row.status) === "1") {
                                                return 'line--statut--inscrit';
                                            }

                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }

                                        }}

                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}
                                    />
                                </Box>
                                <br />

                            </div>

                        </div>

                    </div>

                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>



                            <Button variant="outlined" onClick={submenu_add_one_crm_opport}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Opportunités &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>

                            <br />
                            {session_file_name && <nav><i>{session_file_name}</i></nav>}
                            {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les opportunités  ont été correctement importées</nav>}
                            {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}


                        </div>

                    </div>
                    <div className="div_row"> &nbsp;</div>


                </div>
            }

            <div className="div_row" ref={myRef} id="myRef"> &nbsp; </div>

            {
                String(display_view) !== "list" && <div className="div_row">
                    <DndProvider backend={HTML5Backend}>
                        <section style={classes.board}>
                            {Opportunite_chanel.map(channel => (
                                <KanbanColumn
                                    key={channel}
                                    status={channel}
                                    changeTaskStatus={changeTaskStatus}
                                >
                                    <div style={classes.column}>
                                        <div style={classes.columnHead}>{Opportunite_chanel_label[channel]}</div>
                                        <div style={{ overflowY: 'auto', height: '95%' }}>
                                            {tasks
                                                .filter(item => item.status === channel)
                                                .map(item => (
                                                    <KanbanItem key={item._id} id={item._id} >

                                                        <div style={classes.item} id={item._id}
                                                            onClick={(event) => {
                                                                clear_detail_fields();

                                                                setselected_id(item._id)

                                                                Get_Given_Opportunite_Data(item._id);
                                                                setDialog_OPPORTUNITE_open(true);

                                                                // iciiiii
                                                            }}
                                                        >

                                                            {String(item.title).length > 30 && <nav style={{ 'fontWeight': '700', wordBreak: 'break-all' }} > {String(item.title).substring(0, 26)}... </nav>}
                                                            {String(item.title).length <= 30 && <nav style={{ 'fontWeight': '700', wordBreak: 'break-all' }} > {String(item.title).substring(0, 30)} </nav>}

                                                            <nav style={{}}>  Revenu :  {Intl.NumberFormat().format(String(item.revenu_cible))} € </nav>
                                                            {item.client_id && String(item.client_id).length > 1 && <nav style={{ 'fontWeight': '700' }}> Client :  {item.client_nom} </nav>}
                                                            {String(item.client_id).length <= 0 && String(item.contact_nom).length > 1 && <nav style={{ 'fontWeight': '700' }}> Contact :  {item.contact_nom} </nav>}

                                                            <Rating name="half-rating-read" defaultValue={String(item.priorite)} precision={0.5} readOnly /><br />
                                                            {String(item.description).length > 30 && <nav style={{ wordBreak: 'break-all' }} >{String(item.description).substring(0, 27)}...</nav>}
                                                            {String(item.description).length <= 30 && <nav style={{ wordBreak: 'break-all' }} >{String(item.description).substring(0, 30)}</nav>}
                                                        </div>

                                                    </KanbanItem>
                                                ))}
                                        </div>
                                    </div>
                                </KanbanColumn>
                            ))}
                        </section>
                    </DndProvider>
                    <div className="div_row"> &nbsp;</div>
                    <div className="div_row" style={{ "border": "None" }}>

                        <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>



                            <Button variant="outlined" onClick={submenu_add_one_crm_opport}
                                className="detail_class_submenu bton_add_session"
                                id='menu_import_participant' name='menu_import_participant'>Ajout 1 Opportunités &nbsp;
                                <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                            </Button>

                            <br />
                            {session_file_name && <nav><i>{session_file_name}</i></nav>}
                            {String(liste_sessions_file_change_api) === String("true") && <nav className="okUpdateData"> Les opportunités  ont été correctement importées</nav>}
                            {String(liste_sessions_file_change_api) === String("false") && <nav className="koUpdateData"> {liste_sessions_file_change_message} </nav>}


                        </div>


                    </div>
                    <div className="div_row"> &nbsp;</div>

                </div>
            }
        </div >
    );
}

export default CRM_Opportunite;
