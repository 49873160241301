import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import ToggleSwitch from "./ToggleSwitch";
import { FcInfo } from "react-icons/fc";
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

const Partner_Config_Ftion_Initiale = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("technique");
    const [rowss, setRows] = useState([]);

    const [selectionModel_type_eval, setselectionModel_type_eval] = React.useState([]);


    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    function Annule_Mise_A_jour() {

    }

    const columns_type_evaluation = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'code', headerName: 'Code', fminWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'description', headerName: 'Description', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le type d'évaluation sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete_Type_Evaluation(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }
    ]



    const [handleClick_delete_Type_Evaluation_api, sethandleClick_delete_Type_Evaluation_api] = useState();
    const [handleClick_delete_Type_Evaluation_message, sethandleClick_delete_Type_Evaluation_message] = useState();
    const [handleClick_delete_Type_Evaluation_result, sethandleClick_delete_Type_Evaluation_result] = useState();
    function handleClick_delete_Type_Evaluation(event, cellValues) {

        var type_eval = cellValues.row._id;

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", type_eval);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Type_Evaluation/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In handleClick_delete_Type_Evaluation  res.data.status = " + res.data.status);
                //console.log(" In handleClick_delete_Type_Evaluation  res.data.message r_class = " + res.data.message);
                sethandleClick_delete_Type_Evaluation_api("true");
                sethandleClick_delete_Type_Evaluation_result(res.data.message);

                Getall_Parter_config_type_evaluation();

                setp_one_detail_config_code("");
                setp_one_detail_config_nom("");
                setp_one_detail_config_description("");
                setconfig_type_eval_edit_mode("");
                setconfig_type_eval_changed("");
                setdisplay_detail_type_eval("");
                setadd_config_type_eval("");
                setgridline_type_eval_id("");


                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }


            }
            else {
                sethandleClick_delete_Type_Evaluation_api("false");
                sethandleClick_delete_Type_Evaluation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( handleClick_delete_Type_Evaluation = ', error);
            sethandleClick_delete_Type_Evaluation_api("false");
            alert(" Impossible de supprimer le type d'évaluation");
            //setmyApimyApiMessage("")
        })
    }

    const [p_filtre1, setp_filtre1] = useState();
    const [p_filtre1_value, setp_filtre1_value] = useState();

    const [p_filtre2, setp_filtre2] = useState();
    const [p_filtre2_value, setp_filtre2_value] = useState();

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const [isLoading, setLoading] = useState();

    const [selected_type_eval_id, setselected_type_eval_id] = useState();


    const [New_Getall_Parter_config_type_evaluation_result, setNew_Getall_Parter_config_type_evaluation_result] = useState([]);

    const [Getall_Parter_config_type_evaluation_api, setGetall_Parter_config_type_evaluation_api] = useState();
    const [Getall_Parter_config_type_evaluation_message, setGetall_Parter_config_type_evaluation_message] = useState();
    const [Getall_Parter_config_type_evaluation_result, setGetall_Parter_config_type_evaluation_result] = useState([]);
    function Getall_Parter_config_type_evaluation(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Type_Evaluation/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Parter_config_type_evaluation  res.data.status = " + res.data.status);
                //console.log(" In Getall_Parter_config_type_evaluation  res.data.message r_class = " + res.data.message);
                setGetall_Parter_config_type_evaluation_api("true");
                setGetall_Parter_config_type_evaluation_result(res.data.message);
                //setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " " + local_nom,
                        "nom": local_nom,
                        "code": local_code,
                        "description": local_description,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",
                    "description": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Parter_config_type_evaluation_result(new_data2);

            }
            else {
                setGetall_Parter_config_type_evaluation_api("false");
                setGetall_Parter_config_type_evaluation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Parter_config_type_evaluation = ', error);
            setGetall_Parter_config_type_evaluation_api("false");
            alert(" Impossible de recuperer la liste des points de configuration");
            //setmyApimyApiMessage("")
        })
    }



    function submenu_type_eval() {
        Getall_Parter_config_type_evaluation();
        setsubmenu("type_eval");

        submenu_color_management("type_eval");

    }

    function submenu_color_management(current_menu) {
        const list_sous_menu = ["type_eval",]

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }



        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }

    const myRef_head = useRef(null);

    useEffect(() => {

        Getall_Parter_config_type_evaluation();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

        submenu_type_eval();

    }, [])

    const [p_one_detail_config_code, setp_one_detail_config_code] = useState("");
    const [p_one_detail_config_nom, setp_one_detail_config_nom] = useState("");
    const [p_one_detail_config_description, setp_one_detail_config_description] = useState("");


    const [p_detail_config_code, setp_detail_config_code] = useState("");
    const [p_detail_config_nom, setp_detail_config_nom] = useState("");
    const [p_detail_config_description, setp_detail_config_description] = useState("");

    const [config_type_eval_edit_mode, setconfig_type_eval_edit_mode] = useState("");
    const [config_type_eval_changed, setconfig_type_eval_changed] = useState("");

    const [gridline_type_eval_id, setgridline_type_eval_id] = useState("");
    const [display_detail_type_eval, setdisplay_detail_type_eval] = React.useState();


    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function Enable_Config_Type_Eval_DetailFields() {
        await sleep(5);
        setconfig_type_eval_edit_mode("1");

        if (document.getElementsByName("detail_config_code")[0]) {
            document.getElementsByName("detail_config_code")[0].disabled = false;
            document.getElementsByName("detail_config_code")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_nom")[0]) {
            document.getElementsByName("detail_config_nom")[0].disabled = false;
            document.getElementsByName("detail_config_nom")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("detail_config_desc")[0]) {
            document.getElementsByName("detail_config_desc")[0].disabled = false;
            document.getElementsByName("detail_config_desc")[0].style.backgroundColor = "#FFFFFF";
        }

    }


    async function Disable_Config_Type_Eval_DetailFields() {

        await sleep(10);

        setconfig_type_eval_edit_mode("0");

        if (document.getElementsByName("detail_config_code")[0]) {

            document.getElementsByName("detail_config_code")[0].disabled = true;
            document.getElementsByName("detail_config_code")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("detail_config_nom")[0]) {

            document.getElementsByName("detail_config_nom")[0].disabled = true;
            document.getElementsByName("detail_config_nom")[0].style.backgroundColor = "#ECEFF1";
            console.log(" ICIIII ")
        }

        if (document.getElementsByName("detail_config_desc")[0]) {
            document.getElementsByName("detail_config_desc")[0].disabled = true;
            document.getElementsByName("detail_config_desc")[0].style.backgroundColor = "#ECEFF1";
        }

    }

    const myRef = useRef(null)

    async function Annule_Type_Eval_DetailFields() {

        setconfig_type_eval_edit_mode("0");
        var line1 = New_Getall_Parter_config_type_evaluation_result.filter((data) => (data)._id === String(selected_type_eval_id))


        setdisplay_detail_type_eval("1");
        setadd_config_type_eval("");


        setp_detail_config_code(line1[0].code);
        setp_detail_config_nom(line1[0].nom);
        setp_detail_config_description(line1[0].description);



        if (document.getElementById('myRef')) {
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }


        Disable_Config_Type_Eval_DetailFields();
    }


    function Annule_Add_Type_Eval_DetailFields() {

        var line = JSON.parse(Getall_Parter_config_type_evaluation_result[selected_type_eval_id]);

        setdisplay_detail_type_eval("");
        setadd_config_type_eval("");

        setp_detail_config_code(line.code);
        setp_detail_config_nom(line.nom);
        setp_detail_config_description(line.description);


        Disable_Config_Type_Eval_DetailFields();

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }




    const [Add_Config_Type_Eval_api, setAdd_Config_Type_Eval_api] = useState();
    const [Add_Config_Type_Eval_message, setAdd_Config_Type_Eval_message] = useState();
    const [Add_Config_Type_Eval_result, setAdd_Config_Type_Eval_result] = useState();
    function Add_Config_Type_Eval() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_one_detail_config_code);
        form.append("nom", p_one_detail_config_nom);
        form.append("description", p_one_detail_config_description);


        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Evaluation_Type/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_Config_Type_Eval  res.data.status = " + res.data.status);
            //console.log(" In Add_Config_Type_Eval  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {

                setAdd_Config_Type_Eval_api("true");
                setAdd_Config_Type_Eval_result(res.data.message);

                Getall_Parter_config_type_evaluation();

                setp_one_detail_config_code("");
                setp_one_detail_config_nom("");
                setp_one_detail_config_description("");
                setconfig_type_eval_edit_mode("");
                setconfig_type_eval_changed("");
                setdisplay_detail_type_eval("");
                setadd_config_type_eval("");
                setgridline_type_eval_id("");


                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Config_Type_Eval_api("false");
                setAdd_Config_Type_Eval_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Add_Config_Type_Eval = ' + error);
            setAdd_Config_Type_Eval_api("false");
            alert(" Impossible d'ajouter  le paramétrage ");

        })
    }


    const [Update_Config_Type_Eval_api, setUpdate_Config_Type_Eval_api] = useState();
    const [Update_Config_Type_Eval_message, setUpdate_Config_Type_Eval_message] = useState();
    const [Update_Config_Type_Eval_result, setUpdate_Config_Type_Eval_result] = useState();
    function Update_Config_Type_Eval() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("code", p_detail_config_code);
        form.append("nom", p_detail_config_nom);
        form.append("description", p_detail_config_description);
        form.append("_id", selected_type_eval_id);

        //console.log(" form == ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Evaluation_Type/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Update_Config_Type_Eval  res.data.status = " + res.data.status);
            //console.log(" In Update_Config_Type_Eval  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {

                setUpdate_Config_Type_Eval_api("true");
                setUpdate_Config_Type_Eval_result(res.data.message);

                Getall_Parter_config_type_evaluation();

                setp_detail_config_code("");
                setp_detail_config_nom("");
                setp_detail_config_description("");
                setconfig_type_eval_edit_mode("");
                setconfig_type_eval_changed("");
                setdisplay_detail_type_eval("");
                setadd_config_type_eval("");
                setgridline_type_eval_id("");


                alert(res.data.message);
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setUpdate_Config_Type_Eval_api("false");
                setUpdate_Config_Type_Eval_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('UpdateStagiaireData : Not good man :( Update_Config_Type_Eval = ' + error);
            setUpdate_Config_Type_Eval_api("false");
            alert(" Impossible de mettre à jour le paramétrage ");

        })
    }

    const [add_config_type_eval, setadd_config_type_eval] = useState("");

    function add_one_eval() {

        setp_one_detail_config_code("");
        setp_one_detail_config_nom("");
        setp_one_detail_config_description("");

        setdisplay_detail_type_eval("");
        setadd_config_type_eval("1");
    }
    return (
        <div className="partner_ftion_initiale_configuration">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <h3> Formation Initale configuration </h3>
            <div className="div_row" ref={myRef_head} id="myRef_head" >
                <Button variant="outlined" onClick={submenu_type_eval} className="detail_class_submenu" id='technique' name='technique'>Type Evaluation</Button>
                {/*
                <Button variant="outlined" onClick={submenu_type_client} className="detail_class_submenu" id='type_client' name='type_client'>Type de clients </Button>
                <Button variant="outlined" onClick={submenu_site} className="detail_class_submenu" id='site' name='site'>Sites de formation  </Button>
                <Button variant="outlined" onClick={submenu_condition_paiement} className="detail_class_submenu" id='condition_paiement' name='condition_paiement'>Conditions de paiement  </Button>
                <Button variant="outlined" onClick={submenu_etape_opportunite} className="detail_class_submenu" id='etape_opportunite' name='etape_opportunite'>Etapes d'opportunité </Button>
                <Button variant="outlined" onClick={submenu_competence} className="detail_class_submenu" id='competence' name='competence'>Compétences </Button>
                <Button variant="outlined" onClick={submenu_devis} className="detail_class_submenu" id='devis' name='devis'>Devis </Button>
    */
                }

                {/*** Type evaluation  */}
                {String(submenu) === "type_eval" && <div className="div_row" style={{ paddingRight: '10px' }}>

                    <div>
                        <div className="div_row" style={{ paddingRight: '10px' }}>
                            <div className="session_data">
                                <div style={{ "border": "None" }}>
                                    <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                        &nbsp;
                                        <Box
                                            sx={{
                                                height: 500,
                                                width: '100%',
                                                paddingRight: '1px',

                                                // Gestion des cellule "statut"
                                                '& .cell--status--encours': {
                                                    backgroundColor: '#E6F7C8',
                                                    color: '#1a3e72',
                                                    fontWeight: 'light',
                                                },
                                                '& .cell--status--traite': {
                                                    backgroundColor: '#E6CEAA',
                                                    color: '#F9E79F',
                                                    fontWeight: 'light',
                                                },

                                                "& .MuiDataGrid-columnHeaders": {
                                                    backgroundColor: "#c8cfd5",
                                                    color: "black",
                                                    fontSize: 14
                                                },

                                                '& .line--statut--selected': {
                                                    backgroundColor: '#FBF2EF',
                                                    color: 'black',
                                                },
                                                '& .line--statut--pair': {
                                                    backgroundColor: 'rgba(235, 235, 235, .7)',
                                                    color: 'black',
                                                },
                                                '& .line--statut--impair': {
                                                    backgroundColor: '#FFFFFF',
                                                    color: 'black',
                                                },

                                            }}
                                        >
                                            <DataGrid
                                                disableSelectionOnClick
                                                // checkboxSelection
                                                onSelectionModelChange={(newSelectionModel) => {
                                                    setselectionModel_type_eval(newSelectionModel);
                                                    /*if (newSelectionModel.length === 1)
                                                        handleClick_edit_config_From_Line(newSelectionModel);*/
                                                    if (newSelectionModel.length !== 1) {
                                                        setp_detail_config_code("");
                                                        setp_detail_config_nom("");
                                                        setp_detail_config_description("");
                                                        setconfig_type_eval_edit_mode("");
                                                        setconfig_type_eval_changed("");


                                                    }
                                                }}
                                                selectionModel={selectionModel_type_eval}

                                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                                rows={Getall_Parter_config_type_evaluation_result.map((item, index) => (
                                                    {
                                                        id: index,
                                                        _id: JSON.parse(item)._id,
                                                        code: JSON.parse(item).code,
                                                        nom: JSON.parse(item).nom,
                                                        description: JSON.parse(item).description,
                                                    }
                                                ))}

                                                columns={columns_type_evaluation}
                                                pageSize={10}
                                                className="datagridclass"

                                                onRowDoubleClick={(newSelectionModel) => {
                                                    setgridline_type_eval_id(newSelectionModel.row.id);
                                                    setp_detail_config_code(newSelectionModel.row.code);
                                                    setp_detail_config_nom(newSelectionModel.row.nom);
                                                    setp_detail_config_description(newSelectionModel.row.description);
                                                    setselected_type_eval_id(newSelectionModel.row._id);

                                                    setadd_config_type_eval("");
                                                    setdisplay_detail_type_eval("1");
                                                    setadd_config_type_eval("");
                                                    setconfig_type_eval_edit_mode("");
                                                    setconfig_type_eval_changed("");

                                                    Disable_Config_Type_Eval_DetailFields();

                                                    //handleClick_edit_config_From_Line(newSelectionModel.row.id);

                                                }}


                                                rowsPerPageOptions={[10]}
                                                //disableSelectionOnClick
                                                components={{
                                                    Toolbar: GridToolbar,
                                                }}
                                                //sx={datagridSx}
                                                getCellClassName={(params) => {



                                                }}
                                                getRowClassName={(params) => {


                                                    // Pour la gestion de la couleur de zone double cliquée
                                                    if (String(params.row.id) === String(gridline_type_eval_id)) {
                                                        return 'line--statut--selected';
                                                    }
                                                    else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                        return 'line--statut--pair';
                                                    }
                                                    else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                        return 'line--statut--impair';
                                                    }
                                                }}


                                            />
                                        </Box>
                                        <br />

                                    </div>

                                </div>

                            </div>
                            <div className="div_row" style={{ "border": "None" }}>

                                <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                                    <Button variant="outlined" onClick={add_one_eval}
                                        className="detail_class_submenu bton_add_session"
                                        id='menu_import_participant' name='menu_import_participant'>Ajout 1 Type Eval &nbsp;
                                        <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                                    </Button>
                                </div>
                            </div>
                            <div className="div_row" ref={myRef} id="myRef"> &nbsp;</div>
                        </div>




                        {display_detail_type_eval && String(display_detail_type_eval) === "1" && String(add_config_type_eval) !== "1" && <div className="div_row">


                            {String(config_type_eval_edit_mode) !== "1" && <div>

                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_code"
                                        id="detail_config_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_config_code}

                                    />

                                </div>



                                <div className="session_caract"> Nom <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_nom"
                                        id="detail_config_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_config_nom}

                                    />

                                </div>

                                <div className="session_caract"> Description <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_desc"
                                        id="detail_config_desc"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_config_description}

                                    />

                                </div>

                            </div>}


                            {String(config_type_eval_edit_mode) === "1" && <div>

                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_code"
                                        id="detail_config_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_config_code}
                                        onChange={(e) => {
                                            setp_detail_config_code(e.target.value);
                                            setconfig_type_eval_changed("1");
                                        }}

                                    />

                                </div>



                                <div className="session_caract"> Nom <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_nom"
                                        id="detail_config_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_config_nom}
                                        onChange={(e) => {
                                            setp_detail_config_nom(e.target.value);
                                            setconfig_type_eval_changed("1");
                                        }}

                                    />

                                </div>

                                <div className="session_caract"> Description <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_desc"
                                        id="detail_config_desc"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_detail_config_description}
                                        onChange={(e) => {
                                            setp_detail_config_description(e.target.value);
                                            setconfig_type_eval_changed("1");
                                        }}
                                    />

                                </div>

                            </div>}



                            <div>
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                                {String(config_type_eval_changed) === "1" && String(config_type_eval_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                                    /!\ Pensez à enregistrer les modifications
                                </div>}
                                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                    {String(config_type_eval_edit_mode) === "1" && String(config_type_eval_changed) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                        <Button variant="contained" className="bton_enreg" onClick={Update_Config_Type_Eval}>Enregistrer les modifications
                                        </Button>

                                    </div>}

                                    {String(config_type_eval_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                        <Button variant="contained" className="bton_annule" onClick={Annule_Type_Eval_DetailFields}>Annuler
                                        </Button>
                                    </div>}
                                </div>


                                {String(config_type_eval_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                                    <Button variant="contained" className="bton_edit" onClick={(event) => {
                                        Enable_Config_Type_Eval_DetailFields();
                                    }} >Editer
                                    </Button>

                                </div>}
                                <div className="div_row" style={{ "border": "None" }}>
                                    &nbsp;
                                </div>
                            </div>

                        </div>
                        }

                        {add_config_type_eval && String(display_detail_type_eval) !== "1" && String(add_config_type_eval) === "1" && <div className="div_row">

                            <div>

                                <div className="session_caract"> Code <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_code"
                                        id="detail_config_code"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_one_detail_config_code}
                                        onChange={(e) => {
                                            setp_one_detail_config_code(e.target.value);

                                        }}

                                    />

                                </div>



                                <div className="session_caract"> Nom <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_nom"
                                        id="detail_config_nom"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_one_detail_config_nom}
                                        onChange={(e) => {
                                            setp_one_detail_config_nom(e.target.value);

                                        }}

                                    />

                                </div>

                                <div className="session_caract"> Description <br />
                                    <TextField
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                        required
                                        name="detail_config_desc"
                                        id="detail_config_desc"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={false}
                                        className="disabled_style"
                                        value={p_one_detail_config_description}
                                        onChange={(e) => {
                                            setp_one_detail_config_description(e.target.value);

                                        }}
                                    />

                                </div>

                                <div>
                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>

                                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                            <Button variant="contained" className="bton_enreg" onClick={Add_Config_Type_Eval}>Enregistrer les modifications
                                            </Button>

                                        </div>

                                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                            <Button variant="contained" className="bton_annule" onClick={Annule_Add_Type_Eval_DetailFields}>Annuler
                                            </Button>
                                        </div>
                                    </div>


                                    <div className="div_row" style={{ "border": "None" }}>
                                        &nbsp;
                                    </div>
                                </div>

                            </div>

                        </div>}
                    </div>
                </div>}


            </div>


        </div>
    )
}


export default Partner_Config_Ftion_Initiale;
