import React, { useState, useCallback, useRef } from "react";
import { DndProvider, useDrag } from "react-dnd";
import { useDrop } from 'react-dnd'


import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

const tasksList = [
  { _id: 1, title: "First Task", status: "backlog" },
  { _id: 2, title: "Second Task", status: "backlog" },
  { _id: 3, title: "Third Task", status: "backlog" },
  { _id: 4, title: "Fourth Task", status: "new" },
  { _id: 5, title: "Fifth Task", status: "new" },
  { _id: 6, title: "Sixth Task", status: "wip" },
  { _id: 7, title: "Seventh Task", status: "review" },
  { _id: 8, title: "Eighth Task", status: "review" },
  { _id: 9, title: "Ninth Task", status: "done" },
  { _id: 10, title: "Tenth Task", status: "done" },
  { _id: 11, title: "mysy Task", status: "MySy" }
];

// MySy Comment : Ici la liste des colonnes - etape des opportunité
const channels = ["backlog", "new", "wip", "review", "done", "MySy"];

const labelsMap = {
  "backlog": "Backlog",
  new: "To Do",
  wip: "In Progress",
  review: "Review",
  done: "Done",
  "MySy": "MySy Task"
};


// MySy Comment : design d'une tache (couleur, taille, etc)
const classes = {
  board: {
    display: "flex",
    margin: "0 auto",
    width: "90vw",
    fontFamily: 'Arial, "Helvetica Neue", sans-serif'
  },
  column: {
    minWidth: 200,
    width: "18vw",
    height: "80vh",
    margin: "0 auto",
    backgroundColor: "#212F3D",
    border: 'double #32a1ce',


  },
  columnHead: {
    textAlign: "center",
    padding: 10,
    fontSize: "1.2em",
    backgroundColor: "#C6D8AF",
    border: "red",
  },
  item: {
    padding: 10,
    margin: 10,
    fontSize: "0.8em",
    cursor: "pointer",
    backgroundColor: "white"
  }
};

const Kanban = () => {
  const [tasks, setTaskStatus] = useState(tasksList);


  // MySy Comment : quand on change la position d'une tache, dans cette fonction on appel l'api de mise à jour
  const changeTaskStatus = useCallback(
    (id, status) => {
      let task = tasks.find(task => task._id === id);
      const taskIndex = tasks.indexOf(task);
      task = { ...task, status };
      let newTasks = update(tasks, {
        [taskIndex]: { $set: task }
      });
      setTaskStatus(newTasks);

      console.log(" Coucou cherif mysy : Call MySy API")
    },
    [tasks]
  );

  return (
    <main>
      <header> MySy Opportunité </header>
      <DndProvider backend={HTML5Backend}>
        <section style={classes.board}>
          {channels.map(channel => (
            <KanbanColumn
              key={channel}
              status={channel}
              changeTaskStatus={changeTaskStatus}
            >
              <div style={classes.column}>
                <div style={classes.columnHead}>{labelsMap[channel]}</div>
                <div>
                  {tasks
                    .filter(item => item.status === channel)
                    .map(item => (
                      <KanbanItem key={item._id} id={item._id}>
                        <div style={classes.item}>{item.title} <br /> buton_edit</div>

                      </KanbanItem>
                    ))}
                </div>
              </div>
            </KanbanColumn>
          ))}
        </section>
      </DndProvider>
    </main>
  );
};

export default Kanban;

const KanbanColumn = ({ status, changeTaskStatus, children }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    drop(item) {
      changeTaskStatus(item.id, status);
    }
  });
  drop(ref);
  return <div ref={ref}> {children}</div>;
};

const KanbanItem = ({ id, children }) => {
  const ref = useRef(null);


  const [{ isDragging }, drag] =
    useDrag({
      type: "card",
      item: () => ({ id }),
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <div ref={ref} style={{ opacity }}>
      {children}
    </div>
  );
};
