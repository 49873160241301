import React from "react";
import Navigation from "../components/Navigation";
import bannerimg2 from "./../mysy_img/MYSY-LOGO-BLUE.png";

import maintenance_img from "./../mysy_img/mysy_maintenance_img.png";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";

import { Helmet } from "react-helmet";
import Footer from "../components/Fotter";
import Header from "../components/Header";


const Maintenance_Page = () => {

  return (
    <div className="notfound">
      <div>
        <Navigation />
      </div>
      <Helmet>
        <title>MySy Training, Maintenance</title>
        <meta name="description" content="Nous sommes une base de données uniquement dediée aux formations et tutoriels" />
        <meta name="robots" content="index,follow" />
        <meta name="title" content="Trouver des Formations.Acces libre.Qualité top" />
       

      </Helmet>
      <div className="header">




     


        <div className="header_div_row2">

          <div className="header_div_row_bas">
            <div className="header_div_001">
              <div className="header_div_002">
                <h1 className="header_h1_transparent">Connexion MySy Training Technology </h1>

                <div className="vvv" >
                  <a href={process.env.REACT_APP_BASE_URL}> <img class="img-responsive header_img_logo22" src={logowhite} alt="MySy Training Technology" /> </a>

                </div>
                <div className="header_menuPrincipal_left" >
                 &nbsp;
                </div>

              </div>
              <div className="header_div_003">
                <h1 className="header_h1_transparent">Connexion MySy Training Technology </h1>

                <div className="header_menuPrincipal_bton" >
                  <div style={{ "textAlign": "left" }}>
                  

                  
                  </div>
                </div>



                {/* <div className="header_menuPrincipal" onClick={handleBlogMenu}>
                    Le blog
                </div> */}


                <div className="header_menuPrincipal" 
                 >
                  &nbsp;
                </div>
              </div>
            </div>


            <div className="header_text_head">
              <hr className="header_div_004" />
              <font style={{ "color": "#81BC3A", "fontWeight": "bold" }}>Recherche </font> de Formations & Tutoriels
            </div>

          </div>
        </div>

      </div>


      <div className="div_mobile">
        <div className="nom_prenom" style={{ "marginTop": "4rem" }}> Nous revenons dans 5 min ! <br /><br />

          <p>
            Nous sommes désolés pour le désagrément, mais nous effectuons actuellement des travaux de maintenance. <br />

            <br />

          </p>
          <br />
          <p>
            - L'équipe MySy Training Technology
          </p>

          <img src={maintenance_img} className="img_ok" style={{ "maxWidth": '20%' }} />

        </div>

      </div>
      <div className="pieddepage">
        <Footer />
      </div>


    </div>
  )
}

export default Maintenance_Page;