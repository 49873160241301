import AccountUserActivated from "../components/AccountUserActivated";
import Navigation from "../components/Navigation";



const accountuseractivated = () => {

    return(
        <div className={"accountuseractivated"}>
            <Navigation />
            <AccountUserActivated />
           
        </div>

    )
}

export default accountuseractivated;

