import Recherche_cartouche_com from "../components/Recherche_formation_com";
import Navigation from "../components/Navigation";
import Footer from "./footer";

const recherche = () => {


  return (
    <div>
      <Navigation />
      <div>
        <Recherche_cartouche_com />
      </div>

    </div>

  )
}


export default recherche;
