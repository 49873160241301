import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Autocomplete from '@mui/material/Autocomplete';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom'
import Header from "./Header";
import Footer from "./Fotter";
import { FcInfo } from "react-icons/fc";
import { Document, Page } from 'react-pdf'

function ESignDiplayDignedDocument() {

    const history = useHistory();
    const { document_id, secret_key_signature, partner_owner_recid } = useParams();
    const [isLoading, setLoading] = useState();

    const [get_E_Document_PDF, setget_E_Document_PDF] = useState();
    const [selected_e_doc_id, setselected_e_doc_id] = useState();
    const [selected_e_doc_email, setselected_e_doc_email] = useState();
    const [selected_e_doc_secret, setselected_e_doc_secret] = useState();

    const [is_valide_e_document, setis_valide_e_document] = useState(false);

    const [get_E_Document_api, setget_E_Document_api] = useState();
    const [get_E_Document_message, setget_E_Document_message] = useState();
    const [get_E_Document_result, setget_E_Document_result] = useState();
    function get_E_Document() {


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_E_Document_Signed_No_Token/";

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("e_doc_id", document_id);
        form.append("secret_key_signature", secret_key_signature);
        form.append("partner_owner_recid", partner_owner_recid);



        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            //console.log(" get_E_Document : In test  res.data.status = " + res.data.status);
            //console.log(" get_E_Document: res.data.message.img = " + res.data.message);

            if (String(res.data.status) === "true") {
                setget_E_Document_api("true");

                if (JSON.parse(res.data.message).document_data_signed) {
                    setis_valide_e_document(true);
                    var document_data_signed = "data:application/pdf;base64," + JSON.parse(res.data.message).document_data_signed;
                    setget_E_Document_PDF(document_data_signed);
                    setselected_e_doc_id(JSON.parse(res.data.message)._id);
                    //setselected_e_doc_email(JSON.parse(res.data.message).email_destinataire);
                    //setselected_e_doc_secret(JSON.parse(res.data.message).secret_key_open);
                   

                }


            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {
                setget_E_Document_api("false");
                get_E_Document_message(res.data.message)
                alert(res.data.message);

            }
        }).catch((error) => {
            setLoading(false);
            console.warn('get_E_Document ee: Not good man :(  = ', error);
            setget_E_Document_api("false");
            get_E_Document_message(" Impossible de recuperer le E-Document")
        })

    }

  

    useEffect(() => {

        get_E_Document();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])



    return (
        <div className="esign">

        
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Header />

            <h3> Document Electronique  </h3>

            {is_valide_e_document && <div>
                <div style={{ width: "80%", "marginLeft": "auto", "marginRight": "auto" }}>
                    <embed src={get_E_Document_PDF} width='100%' height='700px' type="application/pdf" />


                </div>
                <div className="div_row">
                    &nbsp;
                </div>


            </div>}

            {!is_valide_e_document && <div style={{ height: "40vh" }}>
                &nbsp;

            </div>}

            <div className="div_row">
                &nbsp;
            </div>
            <div className="pieddepage">
                <Footer />
            </div>
        </div>
    );
}

export default ESignDiplayDignedDocument;