import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { getCookie, setCookie } from 'react-use-cookie';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import EmailIcon from '@mui/icons-material/Email'
import { Button, Form, FormGroup, Label } from "reactstrap";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useHistory } from "react-router-dom";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";


const Partner_Securite_Data = (props) => {

    const history = useHistory();
    const [isLoading, setLoading] = useState();
    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [testval, settestval] = useState();
    const [fields1desabled, setfields1desabled] = useState(true);
    const [mailfieldsdesabled, setmailfieldsdesabled] = useState(true);
    const [pwdfieldsdesabled, setpwdfieldsdesabled] = useState(true);

    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const [valuesPass1, setValuesPass1] = React.useState({
        password1: '',
        showPassword1: false,
    });

    const [valuesPass2, setValuesPass2] = React.useState({
        password2: '',
        showPassword2: false,
    });

    const [valuesPass3, setValuesPass3] = React.useState({
        password3: '',
        showPassword3: false,
    });


    const [valuesPass1_v2, setValuesPass1_v2] = React.useState({
        password1_v2: '',
        showPassword1_v2: false,
    });


    const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
    const [newpawdvisible, setnewpawdvisible] = useState(false);
    const [confpwdvisible, setconfpwdvisible] = useState(false);
    const [updatemainmail, setupdatemainmail] = useState();
    const [updatepwd, setupdatepwd] = useState();


    function enablesecurtymailfields() {


        document.getElementsByName("current_email")[0].disabled = false;
        document.getElementsByName("current_email")[0].style.backgroundColor = "#FFFFFF";



        document.getElementsByName("new_email")[0].disabled = false;
        document.getElementsByName("new_email")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("conf_new_email")[0].disabled = false;
        document.getElementsByName("conf_new_email")[0].style.backgroundColor = "#FFFFFF";


        setmailfieldsdesabled(false);
        setResult("");
        setupdatepwd();
        setupdatemainmail();
    }

    function desablesecurtymailfields() {
        document.getElementsByName("current_email")[0].disabled = true;
        document.getElementsByName("current_email")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("current_email")[0].value = "";


        document.getElementsByName("new_email")[0].disabled = true;
        document.getElementsByName("new_email")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("new_email")[0].value = "";


        document.getElementsByName("conf_new_email")[0].disabled = true;
        document.getElementsByName("conf_new_email")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("conf_new_email")[0].value = "";


        setmailfieldsdesabled(true);
        setResult("");
        setupdatepwd();
        setupdatemainmail();
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // recuperation et gestion  du token utilisateur 
    const stored_user = getCookie('tokenmysypart');
    if (!stored_user || stored_user.length <= 0) {
        history.push("/PartnerLogin");
    }

    const handleClickShowPassword_current_pwd = () => {
        var filtre2_value = document.getElementsByName("current_pwd")[0].value;

        if (currentpawdvisible == false) {
            document.getElementsByName("current_pwd")[0].type = "text";
            setcurrentpawdvisible(true);
        }
        else if (currentpawdvisible == true) {
            document.getElementsByName("current_pwd")[0].type = "password";
            setcurrentpawdvisible(false);
        }

    };


    const handleClickShowPassword_current_pwd_v2 = () => {
        var filtre2_value = document.getElementsByName("current_pwd")[0].value;

        if (currentpawdvisible == false) {
            document.getElementsByName("current_pwd_v2")[0].type = "text";
            setcurrentpawdvisible(true);
        }
        else if (currentpawdvisible == true) {
            document.getElementsByName("current_pwd_v2")[0].type = "password";
            setcurrentpawdvisible(false);
        }

    };



    const handleClickShowPassword_new_pwd = () => {
        if (newpawdvisible == false) {
            document.getElementsByName("new_pwd")[0].type = "text";
            setnewpawdvisible(true);
        }
        else if (newpawdvisible == true) {
            document.getElementsByName("new_pwd")[0].type = "password";
            setnewpawdvisible(false);
        }
    };


    const handleClickShowPassword_new_pwd_conf = () => {
        if (confpwdvisible == false) {
            document.getElementsByName("new_pwd_conf")[0].type = "text";
            setconfpwdvisible(true);
        }
        else if (confpwdvisible == true) {
            document.getElementsByName("new_pwd_conf")[0].type = "password";
            setconfpwdvisible(false);
        }

    };

    function handleChange1(prop) {
        return (event) => {
            setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
        };
    }

    function handleChange2(prop) {
        return (event) => {
            setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
        };
    }


    function handleChange2_v2(prop) {
        return (event) => {
            setValuesPass1_v2({ ...valuesPass1_v2, [prop]: event.target.value });
        };
    }


    function handleChange3(prop) {
        return (event) => {
            setValuesPass3({ ...valuesPass3, [prop]: event.target.value });
        };
    }

    function UpdatePartnerPwd() {

        //setupdatepwd(false);
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_pwd/";
        const formData = new FormData();
        formData.append('token', stored_user);

        // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
        if (!stored_user || stored_user.length <= 0) {
            history.push("/PartnerLogin");
        }

        var pwd = document.getElementsByName("current_pwd")[0].value;
        var new_pwd = document.getElementsByName("new_pwd")[0].value;
        var conf_new_pwd = document.getElementsByName("new_pwd_conf")[0].value;


        formData.append('pwd', pwd);
        formData.append('new_pwd', new_pwd);
        formData.append('conf_new_pwd', conf_new_pwd);
        formData.append('partner_account_id', props.partner_account_id);

        document.getElementsByName("identique")[0].style.backgroundColor = "white";
        if (new_pwd !== conf_new_pwd) {
            document.getElementsByName("identique")[0].style.backgroundColor = "red";
            return;
        }

        document.getElementsByName("caractere")[0].style.backgroundColor = "white";
        if (String(new_pwd).length < 8) {
            document.getElementsByName("caractere")[0].style.backgroundColor = "red";
            return
        }

        var regex = /\d/g;
        document.getElementsByName("nombre")[0].style.backgroundColor = "white";
        if (!regex.test(new_pwd)) {
            document.getElementsByName("nombre")[0].style.backgroundColor = "red";
            return
        }

        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        document.getElementsByName("special")[0].style.backgroundColor = "white";
        if (!specialChars.test(new_pwd)) {
            document.getElementsByName("special")[0].style.backgroundColor = "red";
            return
        }

        setLoading(true);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log('message:', result['message'], "result['status'] = ", result['status']);
                setmyApiResponse(result['status']);
                setResult(result['message']);
                if (String(result['status']) === String("true")) {
                    setupdatepwd(true);
                    setpwdfieldsdesabled(true);
                    desablesecurtypwdfields();
                    alert("Le mot de passe a été mis à jour.")
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert( result['message']);
                    history.push("/Connexion");
                } else {

                    alert(result['message']);
                }


            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setmyApiResponse("false");
                setupdatepwd(false);
                alert(result['message']);
            });
    }


    function UpdatePartnerMail() {

        setupdatemainmail(false);
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_main_mail/";
        const formData = new FormData();
        formData.append('token', stored_user);

        // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
        if (!stored_user || stored_user.length <= 0) {
            history.push("/PartnerLogin");
        }

        var mail = document.getElementsByName("current_email")[0].value;
        var new_mail = document.getElementsByName("new_email")[0].value;
        var conf_new_mail = document.getElementsByName("conf_new_email")[0].value;


        formData.append('mail', mail);
        formData.append('new_mail', new_mail);
        formData.append('conf_new_mail', conf_new_mail);
        formData.append('partner_account_id', props.partner_account_id);

        setLoading(true);
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setmyApiResponse(result['status']);
                setResult(result['message']);
                if (String(result['status']) == String("true")) {
                    setupdatemainmail(true);
                    desablesecurtypwdfields();
                    alert("L'adresse email a été mise à jour.")
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                } else {
                    alert("Erreur :", result['message']);
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setmyApiResponse("false");
                setupdatemainmail(false);
            });
    }

    function desablesecurtypwdfields() {
        document.getElementsByName("current_pwd")[0].disabled = true;
        document.getElementsByName("current_pwd")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("current_pwd")[0].value = "";

        document.getElementsByName("new_pwd")[0].disabled = true;
        document.getElementsByName("new_pwd")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("new_pwd")[0].value = "";

        document.getElementsByName("new_pwd_conf")[0].disabled = true;
        document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("new_pwd_conf")[0].value = "";


        setpwdfieldsdesabled(true);
        setResult("");
        setupdatepwd();
        setupdatemainmail();
    }



    function enablesecurtypwdfields() {

        document.getElementsByName("current_pwd")[0].disabled = false;
        document.getElementsByName("current_pwd")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("new_pwd")[0].disabled = false;
        document.getElementsByName("new_pwd")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("new_pwd_conf")[0].disabled = false;
        document.getElementsByName("new_pwd_conf")[0].style.backgroundColor = "#FFFFFF";

        setpwdfieldsdesabled(false);
        setResult("");
        setupdatepwd();
        setupdatemainmail();
    }

    const [valuesconf_new_insert_key, setvaluesconf_new_insert_key] = React.useState({
        password_insertconf_new_key: '',
        showpassword_insertconf_new_key: false,
    });

    const [conf_new_insert_key_visible, setconf_new_insert_key_visible] = useState(false);

    function handleChangeconf_new_insert_key(prop) {
        return (event) => {
            setvaluesconf_new_insert_key({ ...conf_new_insert_key_visible, [prop]: event.target.value });
        };
    }

    function desablesecurtyInsertKeyfields() {

        setinsert_key_updated();
        document.getElementsByName("current_insert_key")[0].disabled = true;
        document.getElementsByName("current_insert_key")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("current_insert_key")[0].value = "";

        document.getElementsByName("new_insert_key")[0].disabled = true;
        document.getElementsByName("new_insert_key")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("new_insert_key")[0].value = "";

        document.getElementsByName("conf_new_insert_key")[0].disabled = true;
        document.getElementsByName("conf_new_insert_key")[0].style.backgroundColor = "#ECEFF1";
        document.getElementsByName("conf_new_insert_key")[0].value = "";


        setinsert_keyfieldsdesabled(true);
        setResult("");
        setupdatepwd();
        setupdatemainmail();
    }


    function UpdatePartnerInsertKey() {

        setinsert_key_updated();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_insert_key/";
        const formData = new FormData();
        formData.append('token', stored_user);

        // Si le partenaire n'est pas connecté, il est renvoyé vers la page de connexion
        if (!stored_user || stored_user.length <= 0) {
            history.push("/PartnerLogin");
        }

        var current_key = document.getElementsByName("current_insert_key")[0].value;
        var new_key = document.getElementsByName("new_insert_key")[0].value;
        var conf_new_key = document.getElementsByName("conf_new_insert_key")[0].value;



        document.getElementsByName("identique_insert_key")[0].style.backgroundColor = "white";
        if (new_key !== conf_new_key) {
            document.getElementsByName("identique_insert_key")[0].style.backgroundColor = "red";
            return;
        }

        document.getElementsByName("caractere_insert_key")[0].style.backgroundColor = "white";
        if (String(new_key).length < 8) {
            document.getElementsByName("caractere_insert_key")[0].style.backgroundColor = "red";
            return
        }

        var regex = /\d/g;
        document.getElementsByName("nombre_insert_key")[0].style.backgroundColor = "white";
        if (!regex.test(new_key)) {
            document.getElementsByName("nombre_insert_key")[0].style.backgroundColor = "red";
            return
        }

        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        document.getElementsByName("special_insert_key")[0].style.backgroundColor = "white";
        if (!specialChars.test(new_key)) {
            document.getElementsByName("special_insert_key")[0].style.backgroundColor = "red";
            return
        }


        formData.append('current_insert_key', current_key);
        formData.append('new_insert_key', new_key);
        formData.append('conf_insert_key', conf_new_key);
        formData.append('partner_account_id', props.partner_account_id);



        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setinsert_key_api(result['status']);
                setinsert_key_api_message(result['message']);
                if (String(result['status']) === String("true")) {
                    desablesecurtyInsertKeyfields();
                    document.getElementsByName("current_insert_key")[0].value = new_key;
                    setinsert_key_updated(true);
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }


            })
            .catch((error) => {
                console.error('Error:', error);
                setinsert_key_api("false");
                setinsert_key_updated(false);
            });
    }

    function enablesecurtyInsertKeyfields() {

        setinsert_key_updated();
        document.getElementsByName("current_insert_key")[0].disabled = false;
        document.getElementsByName("current_insert_key")[0].style.backgroundColor = "#FFFFFF";


        document.getElementsByName("new_insert_key")[0].disabled = false;
        document.getElementsByName("new_insert_key")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("conf_new_insert_key")[0].disabled = false;
        document.getElementsByName("conf_new_insert_key")[0].style.backgroundColor = "#FFFFFF";

        setinsert_keyfieldsdesabled(false);

        setResult("");
        setupdatepwd();
        setupdatemainmail();
    }

    const handleClickconf_new_insert_key = () => {
        if (conf_new_insert_key_visible == false) {
            document.getElementsByName("conf_new_insert_key")[0].type = "text";
            setconf_new_insert_key_visible(true);
        }
        else if (conf_new_insert_key_visible == true) {
            document.getElementsByName("conf_new_insert_key")[0].type = "password";
            setconf_new_insert_key_visible(false);
        }
    };

    const [insert_key_api, setinsert_key_api] = useState("");
    const [insert_key_api_message, setinsert_key_api_message] = useState("");
    const [insert_key_updated, setinsert_key_updated] = useState();

    const [insert_keyfieldsdesabled, setinsert_keyfieldsdesabled] = useState(true);

    // Gestion champs caché : Current_Insertion_Key
    const [values_current_insert_key, setvalues_current_insert_key] = React.useState({
        current_insert_key: '',
        showCurrent_insert_key: false,
    });


    const [valuescurrent_insert_key, setvaluescurrent_insert_key] = React.useState({
        password_insert_current_key: '',
        showpassword_insert_current_key: false,
    });

    const [current_insert_key_visible, setcurrent_insert_key_visible] = useState(false);

    function handleChange_current_insert_key(prop) {
        return (event) => {
            setvaluescurrent_insert_key({ ...current_insert_key_visible, [prop]: event.target.value });
        };
    }

    const handleClickcurrent_insert_key = () => {
        if (current_insert_key_visible == false) {
            document.getElementsByName("current_insert_key")[0].type = "text";
            setcurrent_insert_key_visible(true);
        }
        else if (current_insert_key_visible == true) {
            document.getElementsByName("current_insert_key")[0].type = "password";
            setcurrent_insert_key_visible(false);
        }
    };
    // FIN Gestion champs caché : Current_Insertion_Key

    // Gestion champs caché : new_Insertion_Key
    const [values_new_insert_key, setvalues_new_insert_key] = React.useState({
        new_insert_key: '',
        shownew_insert_key: false,
    });


    const [valuesnew_insert_key, setvaluesnew_insert_key] = React.useState({
        password_insert_new_key: '',
        showpassword_insert_new_key: false,
    });

    const [new_insert_key_visible, setnew_insert_key_visible] = useState(false);

    function handleChange_new_insert_key(prop) {
        return (event) => {
            setvaluesnew_insert_key({ ...new_insert_key_visible, [prop]: event.target.value });
        };
    }

    const handleClicknew_insert_key = () => {
        if (new_insert_key_visible == false) {
            document.getElementsByName("new_insert_key")[0].type = "text";
            setnew_insert_key_visible(true);
        }
        else if (new_insert_key_visible == true) {
            document.getElementsByName("new_insert_key")[0].type = "password";
            setnew_insert_key_visible(false);
        }
    };


    useEffect(() => {

        desablesecurtymailfields();
        desablesecurtypwdfields();
        desablesecurtyInsertKeyfields();

    }, [])

    const [p_current_pwd, setp_current_pwd] = useState("");
    const [p_new_pwd, setp_new_pwd] = useState("");
    const [p_new_pwd_conf, setp_new_pwd_conf] = useState("");
    const [p_current_email, setp_current_email] = useState("");
    const [p_new_email, setp_new_email] = useState("");
    const [p_conf_new_email, setp_conf_new_email] = useState("");
    const [p_current_insert_key, setp_current_insert_key] = useState("");
    const [p_new_insert_key, setp_new_insert_key] = useState("");
    const [p_conf_new_insert_key, setp_conf_new_insert_key] = useState("");
    const [pwd2_type, setpwd2_type] = useState("password");

    return (

        <div className="partner_client partner_client_securite_data">
             {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <div style={{ "width": "10%" }}> &nbsp;</div>

            <div className="div_row_gauche">
                <div className="titre1"> Mot de passe </div>

                {updatepwd == false && <div className="koUpdateData">
                    Erreur mise à jour.
                </div>
                }

                {updatepwd == true && <div className="okUpdateData">
                    La mise à jour été faite
                </div>
                }
                &nbsp;
                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 } }}>

                    {/*<FormControl sx={{ m: 1, width: '90%' }} size="small" className="texte_area_passwd">
                        <Label>Mot de passe actuel</Label>
                        <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                        <OutlinedInput
                            id="current_pwd"
                            name="current_pwd"
                            type={values.showPassword1 ? 'text' : 'password'}
                            value={values.password1}
                            onChange={handleChange1('password1')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_current_pwd}
                                        edge="end"
                                    >
                                        {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }

                        />
                    </FormControl>
                        */}

                    {<TextField
                        id="current_pwd"
                        name="current_pwd"
                        label="Mot de passe actuel"
                        type={values.showPassword1 ? 'text' : 'password'}
                        value={values.password1}
                        onChange={handleChange1('password1')}
                        sx={{ m: 1, width: '90%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_current_pwd}
                                        edge="end"
                                    >
                                        {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />
                    }
                    {/*  <TextField
                        id="current_pwd_v2"
                        name="current_pwd_v2"
                        type={valuesPass1_v2.showPassword1_v2 ? 'text' : 'password'}
                        value={valuesPass1_v2.password1_v2}
                        label="New pwdddd"
                        onChange={handleChange2_v2('password1')}
                        sx={{ m: 1, width: '90%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_current_pwd_v2}
                                        edge="end"
                                    >
                                        {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}


                    /> */}


                    {/* <FormControl sx={{ m: 1, width: '90%' }} size="small" className="texte_area_passwd">
                        <Label>Nouveau mot de passe</Label>
                        <InputLabel htmlFor="outlined-adornment-password2"></InputLabel>
                        <OutlinedInput
                            id="new_pwd"
                            name="new_pwd"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password2}
                            onChange={handleChange2('password2')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_new_pwd}
                                        edge="end"
                                    >
                                        {newpawdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }

                        />
                        </FormControl> */}

                    {<TextField

                        label="Nouveau mot de passe"
                        id="new_pwd"
                        name="new_pwd"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password2}
                        onChange={handleChange2('password2')}
                        sx={{ m: 1, width: '90%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_new_pwd}
                                        edge="end"
                                    >
                                        {newpawdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />
                    }

                    {/*<FormControl sx={{ m: 1, width: '90%' }} size="small" className="texte_area_passwd">
                        <Label>Confirmation du nouveau mot de passe</Label>
                        <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                        <OutlinedInput
                            id="new_pwd_conf"
                            name="new_pwd_conf"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password3}
                            onChange={handleChange3('password3')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_new_pwd_conf}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }

                        />
                        </FormControl> */}

                    {<TextField

                        label="Confirmation du nouveau mot de passe"
                        id="new_pwd_conf"
                        name="new_pwd_conf"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password3}
                        onChange={handleChange3('password3')}
                        sx={{ m: 1, width: '90%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_new_pwd_conf}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {confpwdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />
                    }
                    <div className="text_pwd_crit">
                        Créez un mot de passe contenant au moins :
                        <ul>
                            <li name="caractere">
                                8 caractères
                            </li>
                            <li name="nombre">
                                1 nombre
                            </li>
                            <li name="special">
                                1 caractère spécial p. ex., $, !, @, %, &
                            </li>

                            <li name="identique">
                                Identiques
                            </li>
                        </ul>
                    </div>

                    <div className="div_row22" style={{ "textAlign": "left" }}>

                        {pwdfieldsdesabled == false && <div className="div_row" style={{ "textAlign": "left" }} >
                            <div className="div_row_gauche">
                                <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={UpdatePartnerPwd}>Enregistrer</Button>
                            </div>
                            <div className="div_row_droite">
                                <Button variant="contained" color="success" className="div_row22 btn_modif " onClick={desablesecurtypwdfields}>Annuler</Button>
                            </div>
                        </div>
                        }
                        {pwdfieldsdesabled == true &&
                            <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablesecurtypwdfields}>Editer</Button>
                        }
                    </div>


                </Box>
            </div>



            <div className="div_row_droite">
                <div className="titre1"> Emails contact </div>
                {updatemainmail === false && <div className="koUpdateData">
                    Erreur mise à jour.
                </div>
                }

                {updatemainmail === true && <div className="okUpdateData">
                    La mise à jour été faite
                </div>
                }
                <br />
                <TextField
                    name="current_email"
                    label="Adresse mail"
                    sx={{ m: 1, width: '90%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={p_current_email}
                    onChange={(e) => setp_current_email(e.target.value)}
                />

                <TextField
                    name="new_email"
                    label="Nouvelle Adresse mail"
                    sx={{ m: 1, width: '90%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={p_new_email}
                    onChange={(e) => setp_new_email(e.target.value)}
                />

                <TextField
                    name="conf_new_email"
                    label="Confirmation mail"
                    sx={{ m: 1, width: '90%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={p_conf_new_email}
                    onChange={(e) => setp_conf_new_email(e.target.value)}
                />
                <div className="text_pwd_crit">
                    &nbsp;
                    <ul style={{ "listStyleType": "none" }}>
                        <li>
                            &nbsp;
                        </li>
                        <li>
                            &nbsp;
                        </li>
                        <li>
                            &nbsp;
                        </li>

                        <li>
                            &nbsp;
                        </li>
                        <li>
                            &nbsp;
                        </li>
                    </ul>
                </div>
                {mailfieldsdesabled == false && <div className="div_row" style={{ "textAlign": "left" }} >
                    <div className="div_row_gauche" >
                        <Button variant="contained" color="success" className="div_row22 btn_enreg " style={{ "marginTop": "0px" }} onClick={UpdatePartnerMail}>Enregistrer</Button>
                    </div>
                    <div className="div_row_droite">
                        <Button variant="contained" color="success" className="div_row22 btn_modif" style={{ "marginTop": "0px" }} onClick={desablesecurtymailfields}>Annuler</Button>
                    </div>
                </div>
                }
                {mailfieldsdesabled == true && <div className="div_row22" style={{ "textAlign": "right" }}>
                    <Button variant="contained" color="success" className="div_row22 btn_modif" style={{ "marginTop": "0px", }} onClick={enablesecurtymailfields}>Editer</Button>
                </div>
                }




            </div>
            <div style={{ "width": "10%" }}> &nbsp;</div>

            <div style={{ "width": "100%", "float": "left", "marginBottom": "2rem" }}>
                <div className="block_cle_rattachement">
                    <div className="titre1"> Clé de rattachement  </div>
                    {insert_key_updated === false && <div className="koUpdateData">
                        Erreur mise à jour {setinsert_key_api_message}
                    </div>
                    }

                    {insert_key_updated === true && <div className="okUpdateData">
                        La clé a été correctement mise à jour
                    </div>
                    }


                    &nbsp;
                    <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 } }} >

                        {/*<FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
                            <Label>Clé de rattachement actuelle</Label>
                            <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                            <OutlinedInput
                                id="current_insert_key"
                                name="current_insert_key"
                                type={values.current_insert_key ? 'text' : 'password'}
                                value={values.current_insert_key}
                                onChange={handleChange_current_insert_key('current_insert_key')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickcurrent_insert_key}
                                            edge="end"
                                        >
                                            {current_insert_key_visible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }

                            />
                        </FormControl>*/}

                        {<TextField

                            label="Clé de rattachement actuelle"
                            id="current_insert_key"
                            name="current_insert_key"
                            type={values.current_insert_key ? 'text' : 'password'}
                            value={values.current_insert_key}
                            onChange={handleChange_current_insert_key('current_insert_key')}
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickcurrent_insert_key}
                                            edge="end"
                                        >
                                            {current_insert_key_visible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        }

                        {/*<FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
                            <Label>Nouvelle clé de rattachement</Label>
                            <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                            <OutlinedInput
                                id="new_insert_key"
                                name="new_insert_key"
                                type={values.new_insert_key ? 'text' : 'password'}
                                value={values.new_insert_key}
                                onChange={handleChange_new_insert_key('current_insert_key')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClicknew_insert_key}
                                            edge="end"
                                        >
                                            {new_insert_key_visible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }

                            />
                        </FormControl>*/}


                        {<TextField

                            label="Nouvelle clé de rattachement"
                            id="new_insert_key"
                            name="new_insert_key"
                            type={values.new_insert_key ? 'text' : 'password'}
                            value={values.new_insert_key}
                            onChange={handleChange_new_insert_key('current_insert_key')}
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClicknew_insert_key}
                                            edge="end"
                                        >
                                            {new_insert_key_visible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        }


                        {/*<FormControl sx={{ m: 1, width: '90%' }} variant="standard" size="small" className="texte_area_passwd">
                            <Label>Confirmation nouvelle clé de rattachement</Label>
                            <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                            <OutlinedInput
                                id="conf_new_insert_key"
                                name="conf_new_insert_key"
                                type={values.new_insert_key ? 'text' : 'password'}
                                value={values.new_insert_key}
                                onChange={handleChangeconf_new_insert_key('conf_current_insert_key')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickconf_new_insert_key}
                                            edge="end"
                                        >
                                            {conf_new_insert_key_visible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }

                            />
                            </FormControl>*/}


                        {<TextField

                            label="Confirmation nouvelle clé de rattachement"
                            id="conf_new_insert_key"
                            name="conf_new_insert_key"
                            type={values.new_insert_key ? 'text' : 'password'}
                            value={values.new_insert_key}
                            onChange={handleChangeconf_new_insert_key('conf_current_insert_key')}
                            sx={{ m: 1, width: '90%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickconf_new_insert_key}
                                            edge="end"
                                        >
                                            {conf_new_insert_key_visible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}

                        />
                        }
                        <div className="text_pwd_crit">
                            Créez une clé contenant au moins :
                            <ul>
                                <li name="caractere_insert_key">
                                    8 caractères
                                </li>
                                <li name="nombre_insert_key">
                                    1 nombre
                                </li>
                                <li name="special_insert_key">
                                    1 caractère spécial p. ex., $, !, @, %, &
                                </li>

                                <li name="identique_insert_key">
                                    Identiques
                                </li>
                            </ul>
                        </div>

                        <div className="div_row22" style={{ "textAlign": "left" }}>


                            {insert_keyfieldsdesabled == false && <div className="div_row">

                                <div className="div_row_gauche">
                                    <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={UpdatePartnerInsertKey}>Enregistrer</Button>
                                </div>

                                <div className="div_row_droite">
                                    <Button variant="contained" color="success" className="div_row22 btn_modif " onClick={desablesecurtyInsertKeyfields}>Annuler</Button>
                                </div>
                            </div>
                            }
                            {insert_keyfieldsdesabled == true &&
                                <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablesecurtyInsertKeyfields}>Editer</Button>
                            }
                        </div>


                    </Box>
                </div>
                <div style={{ "width": "48%", "float": "right" }}>
                <div className="titre1"> Parametrage SMTP </div>
                    &nbsp;

                </div>
            </div>

           

        </div>
    )
}


export default Partner_Securite_Data;
