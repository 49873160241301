import Subdomain_Recherche_new_v2 from "../components/Subdomain_Recherche_new_v2";
import Navigation from "../components/Navigation";
import Footer from "./footer";

const subdomain_recherche_new_v2 = () => {

  return (
    <div>
      <Navigation />
      <div>
        <Subdomain_Recherche_new_v2 />
      </div>

    </div>

  )
}


export default subdomain_recherche_new_v2;
