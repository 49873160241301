import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcSearch } from "react-icons/fc";
import { FcAlarmClock, FcMultipleSmartphones, FcWorkflow } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import 'moment/locale/fr';

import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RestoreFromTrashRounded } from "@mui/icons-material";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';
import ToggleSwitch from "./ToggleSwitch";
import Module_Historique_Action from "./Module_Historique_Action";
import { HiDocumentCheck } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";
import Autocomplete from '@mui/material/Autocomplete';
import { FcOpenedFolder } from "react-icons/fc";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import Module_Editique from "./Module_Editique";
import Module_Attestation_Formation from "./Module_Attestation_Formation";

import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcPlanner } from "react-icons/fc";
import { CiBoxList } from "react-icons/ci";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';


const Module_Selection_Apprenants = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);


    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);


    const [p_filtre1, setp_filtre1] = useState("Inscrit ");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("Type de groupe");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState("");

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState("");


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    const columns_participants = [
        { field: '_id', headerName: '_id', hide: true, Width: 0 },
        { field: 'id', headerName: 'id', hide: true, Width: 0 },

        { field: 'email', headerName: 'email', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'nom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'groupe', headerName: 'groupe', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },



    ]


    const [selectionModel_participants, setselectionModel_participants] = React.useState([]);

    const [selected_id, setselected_id] = React.useState("");

    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }

    const [isLoading, setLoading] = useState();

    const New_Option_Filter = [
        { "id": "user", "label": "Lié a la Formation (code externe) ", "value": "class_external_code" },
        { "id": "user_group", "label": "Lié a la Classe (code Classe) ", "value": "code_session" },
        { "id": "ty", "label": "Lié a l'UE (code UE) ", "value": "code_ue" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]


    const New_Option_type_user = [
        { "id": "user", "label": "Personnes Inscrites", "value": "user" },
        { "id": "user_group", "label": "Groupe ", "value": "user_group" },
        { "id": "", "label": "", "value": "" },

    ]

    const New_Option_type_goupe = [
        { "id": "td", "label": "Groupe TD", "value": "td" },
        { "id": "tp", "label": "Groupe TP", "value": "tp" },
        { "id": "", "label": "", "value": "" },
    ]

    const [GetListePreinscrit_insc_api, setGetListePreinscrit_insc_api] = useState();
    const [GetListePreinscrit_insc_meassage, setGetListePreinscrit_insc_meassage] = useState();
    const [GetListePreinscrit_insc_result, setGetListePreinscrit_insc_result] = useState([])
    function GetListeInscrit() {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", props.session_id);


        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Accepted_Insription_From_Session_id_Reduice_Fields/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                setLoading(false);
                //console.log(' GetListeInscrit :  Success:', data['message'], "data['status'] = ", data['status']);
                setGetListePreinscrit_insc_result(data['message']);

                if (String(data['status']) === String("true")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setGetListePreinscrit_insc_api("true");
                    // setnb_stagiaire_Inscrit(data['message'].length);
                    //console.log(" ### setnb_stagiaire_Inscrit = ", data['message'].length);

                } else if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                }
                else {
                    setGetListePreinscrit_insc_api("false");

                }

            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setGetListePreinscrit_insc_api("false");
            });

    }

    const myRef_head = useRef(null);


    function get_attendee_list_data() {
        if (String(p_filtre1_value) === "user") {
            GetListeInscrit();
        }
    }

    const [gridline_id, setgridline_id] = useState("");

    const New_Option_PreInscription = [
        { "id": "inscr", "label": "Inscrire", "value": "inscr" },
    ]
    const [actionmass_preinsc_val, setactionmass_preinsc_val] = useState();

    function GetSelectedRows_PreInsc_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_participants.length; i++) {
            //console.log(" ### selectionModel_insc[i] = ", selectionModel_insc[i]);
            var myid = parseInt(String(selectionModel_participants[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }


    async function actionmass_preinsc_Traitemet() {

        var liste_preinscr_id = GetSelectedRows_PreInsc_Ids();

        //console.log(" liste_preinscr_id = ", liste_preinscr_id);



        var tab_mongo_preins_ids = [];

        for (var i = 0; i < liste_preinscr_id.length; i++) {

            var line = GetListePreinscrit_insc_result[liste_preinscr_id[i]]


            var line_json = JSON.parse(line)
            //console.log(" line_json['prenom'] = ", line_json['prenom']);
            //console.log(" line_json['_id'] = ", line_json['_id']);
            tab_mongo_preins_ids.push(line_json['_id'])

        }

        if (String(actionmass_preinsc_val) === String("inscr") && props.related_target_collection === "groupe_inscription") {
            Record_Membres_To_Groupe_Inscrit(tab_mongo_preins_ids);

        }
    }
    const [Record_Membres_To_Groupe_Inscrit_api, setRecord_Membres_To_Groupe_Inscrit_api] = useState();
    const [Record_Membres_To_Groupe_Inscrit_message, setRecord_Membres_To_Groupe_Inscrit_message] = useState();
    const [Record_Membres_To_Groupe_Inscrit_result, setRecord_Membres_To_Groupe_Inscrit_result] = useState();
    function Record_Membres_To_Groupe_Inscrit(list_inscription_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", props.related_target_collection_id);
        form.append("tab_inscriptions_ids", list_inscription_id);



        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Update_Groupe_Inscrit_Membres/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                setLoading(false);
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setRecord_Membres_To_Groupe_Inscrit_api("true");
                setRecord_Membres_To_Groupe_Inscrit_result(res.data.message);

                setselectionModel_participants([]);
                setactionmass_preinsc_val("");
                alert(res.data.message);


            } else if (String(res.data.status) === String("Err_Connexion")) {
                setLoading(false);
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setLoading(false);
                setRecord_Membres_To_Groupe_Inscrit_api("false");
                setRecord_Membres_To_Groupe_Inscrit_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Record_Membres_To_Groupe_Inscrit : Not good man :( mysearchtext = ' + error);
            setRecord_Membres_To_Groupe_Inscrit_api("false");
            alert(" Impossible d'inscrire les apprenants au groupe ");

        })
    }



    const [New_Get_List_UE_From_Class_Id_result, setNew_Get_List_UE_From_Class_Id_result] = useState([]);

    const [Get_List_UE_From_Class_Id_api, setGet_List_UE_From_Class_Id_api] = useState();
    const [Get_List_UE_From_Class_Id_message, setGet_List_UE_From_Class_Id_message] = useState();
    const [Get_List_UE_From_Class_Id_result, setGet_List_UE_From_Class_Id_result] = useState();
    function Get_List_UE_From_Class_Id(local_class_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Unite_Enseignement_Of_Given_Class/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_UE_From_Class_Id  res.data.status = " + res.data.status);
                //console.log(" In Get_List_UE_From_Class_Id  res.data.message r_class = " + res.data.message);
                setGet_List_UE_From_Class_Id_api("true");
                setGet_List_UE_From_Class_Id_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_titre = JSON.parse(x).titre;

                    var local_class_id = JSON.parse(x).class_id;
                    var local_internal_url = JSON.parse(x).internal_url;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "titre": local_titre,
                        "class_id": local_class_id,
                        "internal_url": local_internal_url,

                    };
                    new_data2.push(node);
                });

                //---
                /* var node = {
                     "_id": "",
                     "id": "",
                     "label": "",
                     "code": "",
                     "titre": "",
                     "class_id": "",
                     "internal_url": "",
 
                 };
                 new_data2.push(node);*/

                if (new_data2.length > 0) {
                    setNew_Get_List_UE_From_Class_Id_result(new_data2);
                    // console.log("### setNew_Get_List_UE_From_Class_Id_result = ", new_data2);
                }
            }
            else {
                setGet_List_UE_From_Class_Id_api("false");
                setGet_List_UE_From_Class_Id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Get_List_UE_From_Class_Id = ', error);
            setGet_List_UE_From_Class_Id_api("false");
            alert(" Impossible de recuperer la liste des UE de la formation");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Class_List_Evalution_result, setNew_Getall_Class_List_Evalution_result] = useState([]);

    const [Getall_Class_List_Evalution_api, setGetall_Class_List_Evalution_api] = useState();
    const [Getall_Class_List_Evalution_message, setGetall_Class_List_Evalution_message] = useState();
    const [Getall_Class_List_Evalution_result, setGetall_Class_List_Evalution_result] = useState([]);
    function Getall_Class_List_Evalution(local_class_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("class_id", local_class_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Class_Evaluation/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Class_List_Evalution  res.data.status = " + res.data.status);
                //console.log(" In Getall_Class_List_Evalution  res.data.message r_class = " + res.data.message);
                setGetall_Class_List_Evalution_api("true");
                setGetall_Class_List_Evalution_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_ue_id = JSON.parse(x).class_ue_id;
                    var local_class_ue_code = JSON.parse(x).class_ue_code;
                    var local_type_evaluation_id = JSON.parse(x).type_evaluation_id;
                    var local_type_evaluation_code = JSON.parse(x).type_evaluation_code;
                    var local_max_note = JSON.parse(x).max_note;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_type_evaluation_code + "-" + local_class_ue_code,
                        "class_id": local_class_id,
                        "class_ue_id": local_class_ue_id,
                        "class_ue_code": local_class_ue_code,
                        "type_evaluation_id": local_type_evaluation_id,
                        "type_evaluation_code": local_type_evaluation_code,
                        "max_note": local_max_note,


                    };
                    new_data2.push(node);
                });

                //---
                /* var node = {
                     "_id": "",
                     "id": "",
                     "label": "",
                     "class_id": "",
                     "class_ue_id": "",
                     "class_ue_code": "",
                     "type_evaluation_id": "",
                     "type_evaluation_code": "",
                     "max_note": "",
 
                 };
                 new_data2.push(node);*/

                if (new_data2.length > 0) {
                    setNew_Getall_Class_List_Evalution_result(new_data2);
                    //console.log(" setNew_Getall_Class_List_Evalution_result = ", new_data2);

                }
            }
            else {
                setGetall_Class_List_Evalution_api("false");
                setGetall_Class_List_Evalution_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Class_List_Evalution = ', error);
            setGetall_Class_List_Evalution_api("false");
            alert(" Impossible de recuperer la liste des évaluations de la formatio");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Get_Inscrit_List_UE_result, setNew_Get_Inscrit_List_UE_result] = useState([]);
    const [tab_inscrit_ue_id, settab_inscrit_ue_id] = useState([]);

    const [Get_Inscrit_List_UE_api, setGet_Inscrit_List_UE_api] = useState();
    const [Get_Inscrit_List_UE_message, setGet_Inscrit_List_UE_message] = useState();
    const [Get_Inscrit_List_UE_result, setGet_Inscrit_List_UE_result] = useState();
    function Get_Inscrit_List_UE(local_inscription_id) {
        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("inscription_id", local_inscription_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Inscrit_List_EU/";

        setLoading(true);

        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Inscrit_List_UE  res.data.status = " + res.data.status);
                // console.log(" In Get_Inscrit_List_UE  res.data.message r_class = " + res.data.message);
                setGet_Inscrit_List_UE_api("true");
                setGet_Inscrit_List_UE_result(res.data.message);

                var new_data2 = [];

                var tab_tmp = []
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_class_eu_id = JSON.parse(x).class_eu_id;
                    var local_ue_code = JSON.parse(x).code;
                    var local_ue_titre = JSON.parse(x).titre;


                    tab_tmp.push(local_class_eu_id);

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_ue_code,
                        "class_id": local_class_id,
                        "class_eu_id": local_class_eu_id,
                        "ue_code": local_ue_code,
                        "ue_titre": local_ue_titre,
                    };
                    new_data2.push(node);

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "class_id": "",
                    "class_eu_id": "",
                    "ue_code": "",
                    "ue_titre": "",
                };
                new_data2.push(node);


                if (new_data2.length > 0) {
                    setNew_Get_Inscrit_List_UE_result(new_data2);
                    settab_inscrit_ue_id(tab_tmp);

                    if (New_Getall_Class_List_Evalution_result && New_Getall_Class_List_Evalution_result.length > 0) {

                        var local_defaultValue = New_Getall_Class_List_Evalution_result.filter((data) => {
                            if (tab_inscrit_evaluation_ue_id.includes(String(data.type_evaluation_id))) {

                                return data;
                            }
                        });

                        setinscription_list_ue_eval(local_defaultValue);
                    }


                }


            }
            else {
                setGet_Inscrit_List_UE_api("false");
                setGet_Inscrit_List_UE_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Inscrit_List_UE = ', error);
            setGet_Inscrit_List_UE_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [tab_inscrit_evaluation_ue_id, settab_inscrit_evaluation_ue_id] = useState([]);

    const [inscription_list_ue, setinscription_list_ue] = useState([]);
    const [inscription_list_ue_eval, setinscription_list_ue_eval] = useState([]);

    useEffect(() => {

        Get_List_UE_From_Class_Id(props.class_id);
        Getall_Class_List_Evalution(props.class_id);
    }, [])

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const [ueChanged, setueChanged] = useState("");



    return (
        <div className="moduleselectionevaluationapprenants">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}
            <nav style={{ "border": "None", "fontSize": "20px", "paddingTop": "10px", "paddingLeft": "5px" }}> Selection des Apprenants & Groupe d'apprenants </nav>


            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                            required
                            name="filtre1"
                            id="filtre1"

                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={false}
                            className="disabled_style"
                            value={p_filtre1}

                        />

                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 && New_Option_type_user && New_Option_type_user.length > 0 &&
                            <Autocomplete
                                disablePortal
                                name="filtre1_value"
                                id="filtre1_value"
                                className="disabled_style"
                                value={New_Option_type_user.filter((data) => (data).value === String(p_filtre1_value))[0].label}
                                options={New_Option_type_user}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre1_value(value.value);
                                    } else {
                                        setp_filtre1_value("");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        }

                    </div>


                </div>

                {p_filtre1_value && String(p_filtre1_value) === "user_group" &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre2"
                                id="filtre2"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre2}

                            />

                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {p_filtre1_value && String(p_filtre1_value) === "user_group" &&

                                <Autocomplete
                                    disablePortal
                                    name="filtre2_value"
                                    id="filtre2_value"
                                    className="disabled_style"
                                    value={New_Option_type_goupe.filter((data) => (data).value === String(p_filtre2_value))[0].label}
                                    options={New_Option_type_goupe}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            setp_filtre2_value(value.value);
                                        } else {
                                            setp_filtre2_value("");
                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />
                            }
                        </div>

                    </div>
                }


                {/*
                <div className="div_row">
                    <div className="session_caract"> <b>Unite D'enseignement  </b> <br />
                        {New_Get_List_UE_From_Class_Id_result && New_Get_List_UE_From_Class_Id_result.length > 0 &&

                            <Autocomplete
                                multiple
                                name="detail_ue"
                                id="detail_ue"
                                //className="disabled_style"
                                disablePortal
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.label}

                                options={New_Get_List_UE_From_Class_Id_result}
                                defaultValue={New_Get_List_UE_From_Class_Id_result.filter((data) => {
                                    if (tab_inscrit_ue_id.includes(String(data._id))) {

                                        return data;
                                    }
                                })}

                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.label}
                                    </li>
                                )}
                                // style={{ width: 500 }}

                                onChange={(event, value) => {
                                    setinscription_list_ue(value)
                                    setueChanged("1");
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une UE "
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        }

                    </div>



                </div>
*/}
                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={get_attendee_list_data}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={"clean_all_filters"}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>


                <div className="div_row" style={{ paddingRight: '10px' }}>

                    <div className="session_data">
                        <div style={{ "border": "None" }}>

                            <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>

                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',



                                        /*"& .MuiDataGrid-virtualScrollerRenderZone": {
                                            "& .MuiDataGrid-row": {
                                                "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" }
                                            }
                                        },*/
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        },
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },


                                    }}
                                >
                                    <DataGrid
                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_participants(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_employee_From_Line(newSelectionModel);*/
                                            if (newSelectionModel.length !== 1) {
                                                /*  setsubmenu();
                                                  setdisplay_detail_employe();
                                                  setadd_One_Employee();*/
                                            }
                                        }}
                                        selectionModel={selectionModel_participants}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={GetListePreinscrit_insc_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: JSON.parse(item)._id,
                                                code: JSON.parse(item).code,
                                                email: JSON.parse(item).apprenant_email,
                                                nom: JSON.parse(item).apprenant_nom,
                                                prenom: JSON.parse(item).apprenant_prenom,
                                                groupe: JSON.parse(item).groupe,
                                            }
                                        ))}

                                        columns={columns_participants}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            setselected_id(newSelectionModel.row._id);
                                            setgridline_id(newSelectionModel.row.id);
                                            //handleClick_edit_evaluation_From_Line(newSelectionModel.row.id);
                                        }}

                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {

                                        }}
                                        getRowClassName={(params) => {
                                            // Pour la gestion de la couleur de zone double cliquée
                                            if (String(params.row.id) === String(gridline_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }


                                        }}
                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}
                                        sx={{
                                            "& .MuiDataGrid-cellContent": {
                                                minHeight: 50,

                                            }
                                        }}

                                    />
                                </Box>


                                {selectionModel_participants && selectionModel_participants.length >= 1 &&
                                    <div className="div_row" style={{ "border": "none", "marginLeft": "10px" }}>
                                        <div className="block_en_mass">
                                            <nav className='traitement_mass'>Traitement en masse</nav>
                                            &nbsp;

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                className="block_en_mass_select"
                                                fullWidth
                                                options={New_Option_PreInscription}
                                                onChange={(event, value) => {
                                                    if (value && value.value) {
                                                        if (String(value.value) === "inscr") {
                                                            setactionmass_preinsc_val(value.value);
                                                        }
                                                        else {
                                                            setactionmass_preinsc_val("");
                                                        }
                                                    }
                                                    else {
                                                        setactionmass_preinsc_val("");
                                                    }

                                                }}

                                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                                />
                                                }
                                            />
                                            &nbsp;

                                            {actionmass_preinsc_val && String(actionmass_preinsc_val).length > 1 && <nav className='block_en_mass_bton_action'>
                                                <Popup
                                                    trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>
                                                        <FcAcceptDatabase /> Traiter

                                                    </Button>}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >

                                                    {close => (
                                                        <div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}
                                                                {String(actionmass_preinsc_val) === "inscr" &&
                                                                    <font>  Confirmer la <b> validation des inscriptions </b> en masse pour {selectionModel_participants.length} lignes.
                                                                    </font>}


                                                            </div>
                                                            <div className="gest_actions">
                                                                <div style={{ "width": "45%", "float": "left" }}>
                                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                                        actionmass_preinsc_Traitemet();
                                                                        close();
                                                                    }}> Valider </button>

                                                                </div>
                                                                <div style={{ "width": "45%", "float": "right" }}>
                                                                    <button
                                                                        className="gest_bton_popup"
                                                                        onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Annuler
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </nav>
                                            }
                                            <br />

                                        </div>

                                    </div>}
                                <br />

                            </div>


                        </div>
                    </div>

                    <div className="div_row" style={{ "border": "None" }}>


                    </div>
                </div>

            </div>

        </div >
    );
}

export default Module_Selection_Apprenants;
