import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { Fab } from "@material-ui/core";
import fileDownload from 'js-file-download'
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";

const Config_Documents_Super_Admin = (props) => {
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const editorRef_programme = useRef(null);
    const [editorRef_programme_limite, seteditorRef_programme] = useState(1000);


    const [editor_tochange, seteditor_tochange] = useState("");


    const one_editorRef_programme = useRef(null);
    const [one_editorRef_programme_limite, setone_editorRef_programme_limite] = useState(1000);


    const [field_programme, setfield_programme] = useState("");
    const [field_programme_initial_value, setfield_programme_initial_value] = useState("");

    const [one_field_programme, setone_field_programme] = useState("");
    const [one_field_programme_initial_value, setone_field_programme_initial_value] = useState("");


    const [one_editor_tochange, setone_editor_tochange] = useState("");



    const one_editorRef_corp_mail = useRef(null);
    const [one_editorRef_corp_mail_limite, setone_editorRef_corp_mail_limite] = useState(1000);

    const [field_corp_mail, set_field_corp_mail] = useState("");
    const [field_corp_mail_initial_value, set_field_corp_mail_initial_value] = useState("");

    const [one_field_corp_mail, setone_field_corp_mail] = useState("");
    const [one_field_corp_mail_initial_value, setone_field_corp_mail_initial_value] = useState("");

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);

    const columns = [
        { field: '_id', headerName: '_id', hide: true },
        { field: 'id', headerName: 'id', hide: true },
        { field: 'partner_owner_nom', headerName: 'Client Nom', minWidth: 150, flex: 1, },
        { field: 'nom', headerName: 'nom', minWidth: 150, flex: 1, },
        { field: 'ref_interne', headerName: 'ref_interne', minWidth: 50, maxWidth: 200, flex: 1, },
        { field: 'type_doc', headerName: 'type_doc', minWidth: 50, maxWidth: 100, flex: 1, },
        { field: 'cible', headerName: 'cible', minWidth: 100, maxWidth: 150, flex: 1, hide: false, editable: false },
        { field: 'default', headerName: 'par default', minWidth: 100, maxWidth: 100, flex: 1, hide: false, editable: false },
        { field: 'sujet', headerName: 'sujet', hide: false, editable: false, minWidth: 150, flex: 1, },
        /*{
            field: 'duplicate', headerName: 'Dupliquer', hide: false, editable: false, minWidth: 50, maxWidth: 100, flex: 1,
            renderCell: (cellValues) => {

                return (
                    <nav>
                        {cellValues.row.duplicate && String(cellValues.row.duplicate) === "1" &&

                            <Button style={{ "color": "green" }} onClick={(e) => {

                                setduplicate_doc_sujet();
                                setduplicate_doc_nom();
                                setduplicate_doc_type();

                                setDialog_duplicate_doc_open(true);

                                setdocument_id_to_duplicate(cellValues.row._id);


                            }}>
                                <HiOutlineDocumentDuplicate /> Dupliquer

                            </Button>

                        }



                    </nav>
                )

            }
        },*/


        /* {
             field: "Dupliquer", headerName: 'Dupliquer',
             renderCell: (cellValues) => {
                 return (
 
                     <Button
 
                         onClick={(event) => {
                             Display_Detail_Document(event, cellValues);
                         }}
                     >
                         <FcViewDetails />
 
                     </Button>
 
                 );
             }
         },*/
       /* {
            field: "delete", headerName: 'Supprimer',
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {
                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, le document sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            handleClick_delete(event, cellValues);
                                            //console.log('modal closed ');
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }*/

    ]



    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState();
    const [p_filtre3_value, setp_filtre3_value] = useState();

    const [p_filtre4, setp_filtre4] = useState();
    const [p_filtre4_value, setp_filtre4_value] = useState();

    const myRef = useRef(null)
    const myRef_head = useRef(null);

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    function editor_keyup() {
        seteditor_tochange("1");
    }


    function handleClick_delete(event, cellValues) {
        // Recuperation du motif du refus :
        var local_ref_interne = cellValues.row.ref_interne;
        var local_id = cellValues.row._id;


        Delete_Partner_Document_Data(local_id, local_ref_interne);
    }

    const [Delete_Partner_Document_Data_api, setDelete_Partner_Document_Data_api] = useState();
    const [Delete_Partner_Document_Data_message, setDelete_Partner_Document_Data_message] = useState();
    const [Delete_Partner_Document_Data_result, setDelete_Partner_Document_Data_result] = useState();
    function Delete_Partner_Document_Data(local_id, local_ref_interne) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ref_interne", local_ref_interne);
        form.append("_id", local_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Partner_Document/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Delete_Partner_Document_Data  res.data.status = " + res.data.status);
            //console.log(" In Delete_Partner_Document_Data  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Partner_Document_Data_api("true");
                setDelete_Partner_Document_Data_result(res.data.message);

                Getall_Partner_Document_No_filter();

                setpartner_document_data_edit_mode();
                setpartner_document_data_changed();
                clear_detail_fields();
                setsubmenu();
                setdisplay_partner_document();
                setdisplay_detail_document();
                setSelectionModel([]);

                alert(" Le document a été correctement supprimé.");
                //myRef_head.current.scrollIntoView({ behavior: "smooth" });
                if (document.getElementById('myRef_head')) {
                    var divh = document.getElementById('myRef_head').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setDelete_Partner_Document_Data_api("false");
                setDelete_Partner_Document_Data_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_Partner_Document_Data : Not good man :( mysearchtext = ' + error);
            setDelete_Partner_Document_Data_api("false");
            alert(" Impossible de supprimer le document");
        })
    }




    const [selected_id, setselected_id] = useState();
    const [selected_template_ref_interne, setselected_template_ref_interne] = useState();

    function handleClick_display_partner_document(selected_row_id) {

        setdisplay_detail_document("1");
        //submenu_detail_employe();


        // Nettoyages des eventuelles pièces jointes
        setGet_List_Of_All_PJ_result([]);


        var line = JSON.parse(rowss[selected_row_id]);

        //console.log("### line = ", line);
        var document_id = line._id;
        setselected_id(document_id);

        setselected_template_ref_interne(line.ref_interne);

        //console.log(" ### line.ref_interne  = ", line.ref_interne);
        Get_Partner_Document_Data(document_id);

        // Recuperation des éventuelles pièces jointes associées à ce document
        Get_List_Of_All_PJ(document_id);

        if (document.getElementById('myRef')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });

        }

    }

    {/*function Display_Detail_Document(event, cellValues) {

        var local_document_id = String(cellValues.row._id);

        setselected_id(cellValues.row._id);
        Get_Partner_Document_Data(local_document_id);

    }*/}

    const [p_detail_cible, setp_detail_cible] = useState("");
    const [p_detail_contenu_doc, setp_detail_contenu_doc] = useState("");
    const [p_detail_nom, setp_detail_nom] = useState("");
    const [p_detail_ref_interne, setp_detail_ref_interne] = useState("");
    const [p_detail_sujet, setp_detail_sujet] = useState("");
    const [p_detail_type_doc, setp_detail_type_doc] = useState("");
    const [p_detail_duplicate, setp_detail_duplicate] = useState("");

    const [p_detail_joint_pdf, setp_detail_joint_pdf] = useState(false);

    const [p_detail_default_version, setp_detail_default_version] = useState(false);
    const [p_detail_edition_par_client, setp_detail_edition_par_client] = useState(false);



    const [p_one_detail_cible, setp_one_detail_cible] = useState("");
    const [p_one_detail_contenu_doc, setp_one_detail_contenu_doc] = useState("");
    const [p_one_detail_nom, setp_one_detail_nom] = useState("");
    const [p_one_detail_ref_interne, setp_one_detail_ref_interne] = useState("");
    const [p_one_detail_sujet, setp_one_detail_sujet] = useState("");
    const [p_one_detail_type_doc, setp_one_detail_type_doc] = useState("");
    const [p_one_detail_duplicate, setp_one_detail_duplicate] = useState("");

    const [p_one_detail_edit_by_client, setp_one_detail_edit_by_client] = useState("");

    const [p_one_detail_courrier_template_type_document_ref_interne, setp_one_detail_courrier_template_type_document_ref_interne] = useState("");


    function clear_detail_fields() {
        setp_detail_cible("");
        setp_detail_contenu_doc();
        setp_detail_nom("");
        setp_detail_ref_interne("");
        setcourrier_template_type_document_ref_interne("")
        setp_detail_sujet("");
        setp_detail_type_doc("");
        setfield_programme();
    }


    function clear_one_detail_fields() {
        setp_one_detail_cible("");
        setp_one_detail_contenu_doc();
        setp_one_detail_nom("");
        setp_one_detail_ref_interne("");
        setcourrier_template_type_document_ref_interne('');
        setp_one_detail_sujet("");
        setoriginal_courrier_template_id("");

        setp_one_detail_courrier_template_type_document_ref_interne("");

        setp_one_detail_duplicate("");
        setp_one_detail_type_doc("");
        setp_one_detail_edit_by_client("")
        setone_field_programme();
        setone_field_corp_mail();
        setp_detail_joint_pdf(false);
        setp_detail_default_version(false);
        setpartner_document_data_changed(false);

    }


    const [display_detail_document, setdisplay_detail_document] = useState("");

    const [Get_Partner_Document_Data_api, setGet_Partner_Document_Data_api] = useState();
    const [Get_Partner_Document_Data_message, setGet_Partner_Document_Data_message] = useState();
    const [Get_Partner_Document_Data_result, setGet_Partner_Document_Data_result] = useState();
    function Get_Partner_Document_Data(local_partner_document_id) {
        setpartner_document_data_changed("");
        setpartner_document_data_edit_mode("");

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_partner_document_id);

        //console.log(" ## form = ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Document_Super_Admin/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === "true") {
                //console.log(" In Get_Partner_Document_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Partner_Document_Data  res.data.message r_class = " + res.data.message);
                setGet_Partner_Document_Data_api("true");
                setGet_Partner_Document_Data_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_document = JSON.parse(res.data.message);

                    if (mylocal_document.nom)
                        setp_one_detail_nom(mylocal_document.nom);

                    if (mylocal_document.original_courrier_template_id)
                        setoriginal_courrier_template_id(mylocal_document.original_courrier_template_id);
                    else
                        setoriginal_courrier_template_id("")




                    if (mylocal_document.type_doc)
                        setp_detail_type_doc(mylocal_document.type_doc);

                    if (mylocal_document.cible)
                        setp_detail_cible(mylocal_document.cible);


                    if (mylocal_document.ref_interne) {
                        setp_detail_ref_interne(mylocal_document.ref_interne);
                    }


                    if (mylocal_document.sujet) {
                        setp_detail_sujet(mylocal_document.sujet);
                    }


                    // ----
                    setp_detail_joint_pdf(false);
                    if (mylocal_document.joint_pdf) {
                        if (String(mylocal_document.joint_pdf) === "1")
                            setp_detail_joint_pdf(true);
                    }

                    setp_detail_default_version(false);
                    if (mylocal_document.default_version) {
                        if (String(mylocal_document.default_version) === "1")
                            setp_detail_default_version(true);
                    }


                    setp_detail_edition_par_client(false);
                    if (mylocal_document.edit_by_client) {
                        if (String(mylocal_document.edit_by_client) === "1")
                            setp_detail_edition_par_client(true);
                    }

                    set_field_corp_mail("");
                    if (mylocal_document.corps_mail) {
                        set_field_corp_mail(mylocal_document.corps_mail);
                        set_field_corp_mail_initial_value(mylocal_document.corps_mail);
                    }


                    // ---



                    setfield_programme("");
                    if (mylocal_document.contenu_doc) {
                        setfield_programme(mylocal_document.contenu_doc);
                        setfield_programme_initial_value(mylocal_document.contenu_doc);
                    }

                    disable_Partner_DocumentFields();
                    if (document.getElementById('myRef')) {
                        // myRef.current.scrollIntoView({ behavior: "smooth" });
                        var divh = document.getElementById('myRef').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }

                    if (document.getElementsByName('detail_document_principal') && document.getElementsByName('detail_document_principal')[0])
                        document.getElementsByName('detail_document_principal')[0].focus();

                } else {
                    alert(" Aucun document recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Partner_Document_Data_api("false");
                setGet_Partner_Document_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_Document_Data = ', error);
            setGet_Partner_Document_Data_api("false");
            alert(" Impossible de recuperer les données du document");
            //setmyApimyApiMessage("")
        })
    }


    const [courrier_template_type_document_ref_interne, setcourrier_template_type_document_ref_interne] = useState("");


    const [Get_Default_Original_Partner_Document_Data_api, setGet_Default_Original_Partner_Document_Data_api] = useState();
    const [Get_Default_Original_Partner_Document_Data_message, setGet_Default_Original_Partner_Document_Data_message] = useState();
    const [Get_Default_Original_Partner_Document_Data_result, setGet_Default_Original_Partner_Document_Data_result] = useState();
    function Get_Default_Original_Partner_Document_Data() {
        setpartner_document_data_changed("");
        setpartner_document_data_edit_mode("");

        clear_detail_fields();

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ref_interne", p_detail_ref_interne);
        form.append("original_courrier_template_id", original_courrier_template_id);

        //console.log(" ## form = ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Default_Partner_Document_By_Internal_Ref/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === "true") {
                console.log(" In Get_Default_Original_Partner_Document_Data  res.data.status = " + res.data.status);
                console.log(" In Get_Default_Original_Partner_Document_Data  res.data.message r_class = " + res.data.message);

                console.log(" ~~~11111");
                setGet_Default_Original_Partner_Document_Data_api("true");
                console.log(" ~~~2222");
                setGet_Default_Original_Partner_Document_Data_result(res.data.message);

                console.log(" ~~~333");
                if (res.data.message.length > 0) {
                    console.log(" ~~~444");
                    var mylocal_document = JSON.parse(res.data.message);
                    console.log(" ~~~5555");
                    if (mylocal_document.nom)
                        setp_detail_nom(mylocal_document.nom);

                    document.getElementsByName("document_type")[0].value = "";
                    if (mylocal_document.type_doc)
                        setp_detail_type_doc(mylocal_document.type_doc);



                    if (mylocal_document.cible)
                        setp_detail_cible(mylocal_document.cible);




                    document.getElementsByName("document_ref_interne")[0].value = "";
                    if (mylocal_document.ref_interne) {
                        setp_detail_ref_interne(mylocal_document.ref_interne);
                    }

                    document.getElementsByName("document_sujet")[0].value = "";
                    if (mylocal_document.sujet) {
                        setp_detail_sujet(mylocal_document.sujet);
                    }

                    setone_field_programme("");
                    if (mylocal_document.contenu_doc) {

                        setfield_programme(String(mylocal_document.contenu_doc));
                        setfield_programme_initial_value(String(mylocal_document.contenu_doc));

                    }

                    setone_field_corp_mail("");
                    if (mylocal_document.corps_mail) {

                        set_field_corp_mail(String(mylocal_document.corps_mail));
                        set_field_corp_mail_initial_value(String(mylocal_document.corps_mail));
                    }

                    setp_detail_joint_pdf(false);
                    if (mylocal_document.joint_pdf) {
                        if (String(mylocal_document.joint_pdf) === "1")
                            setp_detail_joint_pdf(true);
                    }


                    disable_Partner_DocumentFields();
                    if (document.getElementById('myRef')) {
                        //myRef.current.scrollIntoView({ behavior: "smooth" });

                        var divh = document.getElementById('myRef').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });

                    }
                    setpartner_document_data_changed("1");
                    setpartner_document_data_edit_mode("1");

                } else {
                    alert(" Aucun document recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Default_Original_Partner_Document_Data_api("false");
                setGet_Default_Original_Partner_Document_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Default_Original_Partner_Document_Data = ', error);
            setGet_Default_Original_Partner_Document_Data_api("false");
            alert(" Impossible de recuperer le modèle initial");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Default_Original_Partner_Document_Data_By_id_api, setGet_Default_Original_Partner_Document_Data_By_id_api] = useState();
    const [Get_Default_Original_Partner_Document_Data_By_id_message, setGet_Default_Original_Partner_Document_Data_By_id_message] = useState();
    const [Get_Default_Original_Partner_Document_Data_By_id_result, setGet_Default_Original_Partner_Document_Data_By_id_result] = useState();
    function Get_Default_Original_Partner_Document_Data_By_id(local_id) {
        setpartner_document_data_changed("");
        setpartner_document_data_edit_mode("");

        clear_detail_fields();

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_id);

        //console.log(" ## form = ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_DFAULT_Partner_Document/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Default_Original_Partner_Document_Data_By_id  res.data.status = " + res.data.status);
                //console.log(" In Get_Default_Original_Partner_Document_Data_By_id  res.data.message r_class = " + res.data.message);
                setLoading(false);
                setGet_Default_Original_Partner_Document_Data_By_id_api("true");
                setGet_Default_Original_Partner_Document_Data_By_id_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_document = JSON.parse(res.data.message);

                    if (mylocal_document._id)
                        setoriginal_courrier_template_id(mylocal_document._id);


                    if (mylocal_document.nom)
                        setp_one_detail_nom(mylocal_document.nom);


                    if (mylocal_document.type_doc)
                        setp_one_detail_type_doc(mylocal_document.type_doc);


                    if (mylocal_document.cible)
                        setp_one_detail_cible(mylocal_document.cible);



                    if (mylocal_document.ref_interne) {
                        setp_one_detail_ref_interne(mylocal_document.ref_interne);
                    }

                    if (mylocal_document.courrier_template_type_document_ref_interne) {
                        setp_one_detail_courrier_template_type_document_ref_interne(mylocal_document.courrier_template_type_document_ref_interne);
                    } else {
                        setp_one_detail_courrier_template_type_document_ref_interne("");
                    }


                    if (mylocal_document.sujet) {
                        setp_one_detail_sujet(mylocal_document.sujet);
                    }

                    setp_detail_joint_pdf(false)
                    if (mylocal_document.joint_pdf) {
                        if (String(mylocal_document.joint_pdf) === "1")
                            setp_detail_joint_pdf(true)

                    }


                    if (mylocal_document.duplicate) {
                        setp_one_detail_duplicate(mylocal_document.duplicate);
                    }
                    else {
                        setp_one_detail_duplicate("0");
                    }

                    if (mylocal_document.edit_by_client) {
                        setp_one_detail_edit_by_client(mylocal_document.edit_by_client);
                    }
                    else {
                        setp_one_detail_edit_by_client("0");
                    }


                    setfield_programme("");
                    if (mylocal_document.contenu_doc) {
                        setone_field_programme(String(mylocal_document.contenu_doc));
                        setone_field_programme_initial_value(String(mylocal_document.contenu_doc));
                    }

                    set_field_corp_mail("");
                    if (mylocal_document.corps_mail) {
                        setone_field_corp_mail(String(mylocal_document.corps_mail));
                        setone_field_corp_mail_initial_value(String(mylocal_document.corps_mail));
                    }


                    if (document.getElementById('myRef')) {
                        //myRef.current.scrollIntoView({ behavior: "smooth" });

                        var divh = document.getElementById('myRef').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });

                    }


                } else {
                    alert(" Aucun document recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Default_Original_Partner_Document_Data_By_id_api("false");
                setGet_Default_Original_Partner_Document_Data_By_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Default_Original_Partner_Document_Data_By_id = ', error);
            setGet_Default_Original_Partner_Document_Data_By_id_api("false");
            alert(" Impossible de recuperer le modèle initial");
            //setmyApimyApiMessage("")
        })
    }



    function disable_Partner_DocumentFields() {
        setpartner_document_data_edit_mode("0");

        if (document.getElementsByName("document_name")[0]) {
            document.getElementsByName("document_name")[0].disabled = true;
            document.getElementsByName("document_name")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("nom_document")[0]) {
            document.getElementsByName("nom_document")[0].disabled = true;
            document.getElementsByName("nom_document")[0].style.backgroundColor = "#ECEFF1";
        }



        if (document.getElementsByName("document_type")[0]) {
            document.getElementsByName("document_type")[0].disabled = true;
            document.getElementsByName("document_type")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("document_ref_interne")[0]) {
            document.getElementsByName("document_ref_interne")[0].disabled = true;
            document.getElementsByName("document_ref_interne")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("document_sujet")[0]) {
            document.getElementsByName("document_sujet")[0].disabled = true;
            document.getElementsByName("document_sujet")[0].style.backgroundColor = "#ECEFF1";
        }

        if (document.getElementsByName("document_contente")[0]) {
            document.getElementsByName("document_contente")[0].disabled = true;
            document.getElementsByName("document_contente")[0].style.backgroundColor = "#ECEFF1";
        }


    }


    function Edit_Document_Data_Fields() {
        Enable_Partner_DocumentFields();
        Get_Given_Personnalisable_Collection_and_Fields(selected_template_ref_interne);

        if (document.getElementsByName('one_detail_document_principal') && document.getElementsByName('one_detail_document_principal')[0])
            document.getElementsByName('one_detail_document_principal')[0].focus();
    }

    function Enable_Partner_DocumentFields() {
        setpartner_document_data_edit_mode("1");

        if (document.getElementsByName("document_name")[0]) {
            document.getElementsByName("document_name")[0].disabled = false;
            document.getElementsByName("document_name")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("nom_document")[0]) {
            document.getElementsByName("nom_document")[0].disabled = false;
            document.getElementsByName("nom_document")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("document_type")[0]) {
            document.getElementsByName("document_type")[0].disabled = false;
            document.getElementsByName("document_type")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("document_ref_interne")[0]) {
            document.getElementsByName("document_ref_interne")[0].disabled = false;
            document.getElementsByName("document_ref_interne")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("document_sujet")[0]) {
            document.getElementsByName("document_sujet")[0].disabled = false;
            document.getElementsByName("document_sujet")[0].style.backgroundColor = "#FFFFFF";
        }

        if (document.getElementsByName("document_contente")[0]) {
            document.getElementsByName("document_contente")[0].disabled = false;
            document.getElementsByName("document_contente")[0].style.backgroundColor = "#FFFFFF";
        }


    }


    const [partner_document_data_changed, setpartner_document_data_changed] = useState("");
    const [partner_document_data_edit_mode, setpartner_document_data_edit_mode] = useState("");


    const [p_champ_table, setp_champ_table] = useState();
    const [p_collection, setp_collection] = useState();

    function add_selected_fields() {
        if (!p_champ_table || String(p_champ_table) === "undefined") {
            alert(" vous devez choisir un champ ");
            return;
        }

        if (String(partner_document_data_edit_mode) === "1") {
            // Cas d'un update
            if (document_principal_ou_corp_mail && String(document_principal_ou_corp_mail) == "2") {
                one_editorRef_corp_mail.current.insertContent(String(p_champ_table));

            } else {
                one_editorRef_programme.current.insertContent(String(p_champ_table));
            }
        } else if (String(display_add_one_document) === "1") {
            // Cas de la creation d'un nouveau document
            if (document_principal_ou_corp_mail && String(document_principal_ou_corp_mail) == "2") {
                one_editorRef_corp_mail.current.insertContent(String(p_champ_table));

            } else {
                one_editorRef_programme.current.insertContent(String(p_champ_table));
            }
        }

    }


    function add_one_selected_fields() {
        if (String(p_champ_table).length > 4)
            one_editorRef_programme.current.insertContent(String(p_champ_table));
        else
            alert(" Le champ est invalide");
    }


    const [Get_Personnalisable_Collection_and_Fields_api, setGet_Personnalisable_Collection_and_Fields_api] = useState();
    const [Get_Personnalisable_Collection_and_Fields_message, setGet_Personnalisable_Collection_and_Fields_message] = useState();
    const [Get_Personnalisable_Collection_and_Fields_result, setGet_Personnalisable_Collection_and_Fields_result] = useState();
    function Get_Personnalisable_Collection_and_Fields() {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_All_Personnalisable_Collection_Fields/";

        setLoading(true);

        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Personnalisable_Collection_and_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Personnalisable_Collection_and_Fields  res.data.message r_class = " + res.data.message);

                setGet_Personnalisable_Collection_and_Fields_api("true");
                setGet_Personnalisable_Collection_and_Fields_result(res.data.message);

            }
            else {
                setGet_Personnalisable_Collection_and_Fields_api("false");
                setGet_Personnalisable_Collection_and_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Personnalisable_Collection_and_Fields = ', error);
            setGet_Personnalisable_Collection_and_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Personnalisable_Collections_api, setGet_Personnalisable_Collections_api] = useState();
    const [Get_Personnalisable_Collections_message, setGet_Personnalisable_Collections_message] = useState();
    const [Get_Personnalisable_Collections_result, setGet_Personnalisable_Collections_result] = useState();
    function Get_Personnalisable_Collections() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Personnalisable_Collection/";

        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Personnalisable_Collections  res.data.status = " + res.data.status);
                //console.log(" In Get_Personnalisable_Collections  res.data.message r_class = " + res.data.message);

                setGet_Personnalisable_Collections_api("true");
                setGet_Personnalisable_Collections_result(res.data.message);

            }
            else {
                setGet_Personnalisable_Collections_api("false");
                setGet_Personnalisable_Collections_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Personnalisable_Collections = ', error);
            setGet_Personnalisable_Collections_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {
        Get_Personnalisable_Collections();
        //Get_Personnalisable_Collection_and_Fields();

        Getall_Partner_Document_Super_Admin_No_filter();
        // myRef_head.current.scrollIntoView({ behavior: "smooth" });

        if (document.getElementById('myRef_head')) {
            var divh = document.getElementById('myRef_head').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }, [])



    const [Add_one_Partner_Document_api, setAdd_one_Partner_Document_api] = useState();
    const [Add_one_Partner_Document_message, setAdd_one_Partner_Document_message] = useState();
    const [Add_one_Partner_Document_result, setAdd_one_Partner_Document_result] = useState();
    function Add_one_Partner_Document() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        if (p_one_detail_ref_interne)
            form.append("ref_interne", p_one_detail_ref_interne);
        else {
            alert(" Vous devez definir une reference interne");
            return;
        }


        if (p_one_detail_type_doc)
            form.append("type_doc", p_one_detail_type_doc);
        else {
            alert(" Vous devez definir un type de document");
            return;
        }


        if (original_courrier_template_id)
            form.append("original_courrier_template_id", original_courrier_template_id);
        else {
            form.append("original_courrier_template_id", "");

        }


        if (p_one_detail_cible)
            form.append("cible", p_one_detail_cible);
        else {
            form.append("cible", "");

        }

        if (p_one_detail_courrier_template_type_document_ref_interne)
            form.append("courrier_template_type_document_ref_interne", p_one_detail_courrier_template_type_document_ref_interne);
        else {
            form.append("courrier_template_type_document_ref_interne", "");

        }


        if (p_one_detail_duplicate)
            form.append("duplicate", p_one_detail_duplicate);
        else {
            form.append("duplicate", "0");

        }


        //-----

        if (p_detail_default_version)
            form.append("default_version", "1");
        else {
            form.append("default_version", "0");

        }

        if (p_detail_joint_pdf)
            form.append("joint_pdf", "1");
        else {
            form.append("joint_pdf", "0");

        }


        if (p_one_detail_edit_by_client)
            form.append("edit_by_client", p_one_detail_edit_by_client);
        else {
            form.append("edit_by_client", "0");
        }


        if (p_detail_default_version)
            form.append("default_version", "1");
        else {
            form.append("default_version", "0");

        }


        // -------


        if (p_one_detail_nom)
            form.append("nom", p_one_detail_nom);
        else {
            alert(" Vous devez definir un nom de document");
            return;
        }


        if (p_one_detail_sujet)
            form.append("sujet", p_one_detail_sujet);
        else {
            alert(" Vous devez definir un sujet");
            return;
        }



        if (one_field_programme) {
            var programme = one_field_programme;
            programme = programme.replace('&lt;', '<');
            programme = programme.replace('&gt;', '>');
            programme = programme.replace('&le;', '<=');
            programme = programme.replace('&ge;', '>=');
            form.append("contenu_doc", programme);
        }
        else {
            form.append("contenu_doc", "");
        }

        if (one_field_corp_mail) {
            var corps_mail = one_field_corp_mail;
            corps_mail = corps_mail.replace('&lt;', '<');
            corps_mail = corps_mail.replace('&gt;', '>');
            corps_mail = corps_mail.replace('&le;', '<=');
            corps_mail = corps_mail.replace('&ge;', '>=');
            form.append("corps_mail", corps_mail);
        }
        else {
            form.append("corps_mail", "");
        }


        //console.log(" Add_one_Partner_Document affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Partner_Document/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            //console.log(" In Add_one_Partner_Document  res.data.status = " + res.data.status);
            //console.log(" In Add_one_Partner_Document  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setAdd_one_Partner_Document_api("true");
                setAdd_one_Partner_Document_result(res.data.message);

                setoriginal_courrier_template_id("");
                clear_one_detail_fields();
                setadd_one_document();
                setdisplay_add_one_document();
                Getall_Partner_Document_No_filter();
                alert(res.data.message);
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }
            else {
                setAdd_one_Partner_Document_api("false");
                setAdd_one_Partner_Document_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('UpdateStagiaireData : Not good man :( Add_one_Partner_Document = ' + error);
            setAdd_one_Partner_Document_api("false");
            alert(" Impossible d'ajouter le nouveau  document");

        })
    }


    const [New_Get_Given_Personnalisable_Collection_and_Fields_result, setNew_Get_Given_Personnalisable_Collection_and_Fields_result] = useState([]);


    const [Get_Given_Personnalisable_Collection_and_Fields_api, setGet_Given_Personnalisable_Collection_and_Fields_api] = useState();
    const [Get_Given_Personnalisable_Collection_and_Fields_message, setGet_Given_Personnalisable_Collection_and_Fields_message] = useState();
    const [Get_Given_Personnalisable_Collection_and_Fields_result, setGet_Given_Personnalisable_Collection_and_Fields_result] = useState();
    function Get_Given_Personnalisable_Collection_and_Fields(local_courrier_template_ref_interne) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("courrier_template_ref_interne", local_courrier_template_ref_interne);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Personnalisable_Fields_By_template_ref_interne/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Given_Personnalisable_Collection_and_Fields  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Personnalisable_Collection_and_Fields  res.data.message r_class = " + res.data.message);

                setGet_Given_Personnalisable_Collection_and_Fields_api("true");
                setGet_Given_Personnalisable_Collection_and_Fields_result(res.data.message);

                const new_data = res.data.message.map((x) => {

                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;

                    //---
                    var local_courrier_template_ref_interne = JSON.parse(x).courrier_template_ref_interne;
                    var local_nom_champ_technique = JSON.parse(x).nom_champ_technique;
                    var local_nom_champ_fonctionel = JSON.parse(x).nom_champ_fonctionel;

                    //---
                    return {
                        "id": localid, "_id": String(local_id), "label": local_nom_champ_fonctionel, "courrier_template_ref_interne": local_courrier_template_ref_interne,
                        "nom_champ_technique": local_nom_champ_technique, "nom_champ_fonctionel": local_nom_champ_fonctionel
                    }

                    // return {"_id": " + str(local_id) + ", "label": " + local_courrier_template_ref_interne + '" , "courrier_template_ref_interne": "' + local_courrier_template_ref_interne + '", "nom_champ_technique": "' + local_nom_champ_technique + '",  "nom_champ_fonctionel": "' + local_nom_champ_fonctionel + '","valide": "1" };

                });

                setNew_Get_Given_Personnalisable_Collection_and_Fields_result(new_data);



            }
            else {
                setGet_Given_Personnalisable_Collection_and_Fields_api("false");
                setGet_Given_Personnalisable_Collection_and_Fields_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Given_Personnalisable_Collection_and_Fields = ', error);
            setGet_Given_Personnalisable_Collection_and_Fields_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables de l'objet");
            //setmyApimyApiMessage("")
        })
    }


    const [document_id_to_duplicate, setdocument_id_to_duplicate] = useState();

    const [Duplicate_Partner_Document_api, setDuplicate_Partner_Document_api] = useState();
    const [Duplicate_Partner_Document_message, setDuplicate_Partner_Document_message] = useState();
    const [Duplicate_Partner_Document_result, setDuplicate_Partner_Document_result] = useState();
    function Duplicate_Partner_Document() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", document_id_to_duplicate);

        if (duplicate_doc_sujet)
            form.append("sujet", duplicate_doc_sujet);
        else {
            alert(" Vous devez definir un sujet");
            return;
        }


        if (duplicate_doc_nom)
            form.append("nom", duplicate_doc_nom);
        else {
            alert(" Vous devez definir un nom de document");
            return;
        }


        if (duplicate_doc_type)
            form.append("type_doc", duplicate_doc_type);
        else {
            alert(" Vous devez definir un type de document");
            return;
        }

        //console.log(" Duplicate_Partner_Document affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Duplicate_Given_Partner_Document/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Duplicate_Partner_Document  res.data.status = " + res.data.status);
            //console.log(" In Duplicate_Partner_Document  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setDuplicate_Partner_Document_api("true");
                setDuplicate_Partner_Document_result(res.data.message);
                Dialog_duplicate_doc_handleClose_buton();

                alert(res.data.message);
                clear_one_detail_fields();
                setadd_one_document();
                setdisplay_add_one_document();
                Getall_Partner_Document_No_filter();
            }
            else {
                setDuplicate_Partner_Document_api("false");
                setDuplicate_Partner_Document_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.log('UpdateStagiaireData : Not good man :( Duplicate_Partner_Document = ' + error);
            setDuplicate_Partner_Document_api("false");
            alert(" Impossible de dupliquer le document");

        })
    }

    const [Update_one_Partner_Document_api, setUpdate_one_Partner_Document_api] = useState();
    const [Update_one_Partner_Document_message, setUpdate_one_Partner_Document_message] = useState();
    const [Update_one_Partner_Document_result, setUpdate_one_Partner_Document_result] = useState();
    function Update_one_Partner_Document() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", selected_id);

        if (p_detail_ref_interne)
            form.append("ref_interne", p_detail_ref_interne);
        else {
            alert(" Vous devez definir une reference interne");
            return;
        }


        if (p_detail_cible)
            form.append("cible", p_detail_cible);
        else {
            form.append("cible", "");
        }




        if (p_detail_type_doc)
            form.append("type_doc", p_detail_type_doc);
        else {
            alert(" Vous devez definir un type de document");
            return;
        }


        if (p_one_detail_nom)
            form.append("nom", p_one_detail_nom);
        else {
            alert(" Vous devez definir un nom de document");
            return;
        }


        if (p_detail_sujet)
            form.append("sujet", p_detail_sujet);
        else {
            alert(" Vous devez definir un sujet");
            return;
        }


        //-----

        if (p_detail_default_version)
            form.append("default_version", "1");
        else {
            form.append("default_version", "0");

        }

        if (p_detail_joint_pdf)
            form.append("joint_pdf", "1");
        else {
            form.append("joint_pdf", "0");

        }

        if (p_detail_edition_par_client)
            form.append("edit_by_client", "1");
        else {
            form.append("edit_by_client", "0");

        }


        if (p_detail_default_version)
            form.append("default_version", "1");
        else {
            form.append("default_version", "0");

        }


        // -------




        if (field_programme) {
            var programme = field_programme;

            programme = programme.replace('&lt;', '<');
            programme = programme.replace('&gt;', '>');
            programme = programme.replace('&le;', '<=');
            programme = programme.replace('&ge;', '>=');


            form.append("contenu_doc", programme);
            setp_detail_contenu_doc(programme);
        }
        else {
            form.append("contenu_doc", "");
            setp_detail_contenu_doc("");
        }


        if (field_corp_mail) {
            var corps_mail = field_corp_mail;

            corps_mail = corps_mail.replace('&lt;', '<');
            corps_mail = corps_mail.replace('&gt;', '>');
            corps_mail = corps_mail.replace('&le;', '<=');
            corps_mail = corps_mail.replace('&ge;', '>=');


            form.append("corps_mail", corps_mail);
            setp_detail_contenu_doc(corps_mail);
        }
        else {
            form.append("corps_mail", "");

        }


        //console.log(" Update_one_Partner_Document affectation form == ", form);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Partner_Document_Super_Admin/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Update_one_Partner_Document  res.data.status = " + res.data.status);
            //console.log(" In Update_one_Partner_Document  res.data.message r_class = " + res.data.message);

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                setUpdate_one_Partner_Document_api("true");
                setUpdate_one_Partner_Document_result(res.data.message);
                if (myRef.current) {
                    myRef.current.scrollIntoView({ behavior: "smooth" });
                }

                disable_Partner_DocumentFields();
                setpartner_document_data_edit_mode("0");
                setpartner_document_data_changed("0");
                Get_Partner_Document_Data(selected_id);
                alert(res.data.message);

            }
            else {
                setUpdate_one_Partner_Document_api("false");
                setUpdate_one_Partner_Document_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.log('UpdateStagiaireData : Not good man :( Update_one_Partner_Document = ' + error);
            setUpdate_one_Partner_Document_api("false");
            alert(" Impossible de mettre à jour le document");

        })
    }

    const [Getall_Partner_Document_No_filter_api, setGetall_Partner_Document_No_filter_api] = useState();
    const [Getall_Partner_Document_No_filter_message, setGetall_Partner_Document_No_filter_message] = useState();
    const [Getall_Partner_Document_No_filter_result, setGetall_Partner_Document_No_filter_result] = useState();
    function Getall_Partner_Document_No_filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Document_no_filter/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Document_No_filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Document_No_filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Document_No_filter_api("true");
                setGetall_Partner_Document_No_filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Document_No_filter_api("false");
                setGetall_Partner_Document_No_filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Document_No_filter = ', error);
            setGetall_Partner_Document_No_filter_api("false");
            alert(" Impossible de recuperer la liste des documents");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Partner_Document_Super_Admin_No_filter_api, setGetall_Partner_Document_Super_Admin_No_filter_api] = useState();
    const [Getall_Partner_Document_Super_Admin_No_filter_message, setGetall_Partner_Document_Super_Admin_No_filter_message] = useState();
    const [Getall_Partner_Document_Super_Admin_No_filter_result, setGetall_Partner_Document_Super_Admin_No_filter_result] = useState();
    function Getall_Partner_Document_Super_Admin_No_filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Document_Super_Admin_no_filter/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Document_Super_Admin_No_filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Document_Super_Admin_No_filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Document_Super_Admin_No_filter_api("true");
                setGetall_Partner_Document_Super_Admin_No_filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Document_Super_Admin_No_filter_api("false");
                setGetall_Partner_Document_Super_Admin_No_filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Document_Super_Admin_No_filter = ', error);
            setGetall_Partner_Document_Super_Admin_No_filter_api("false");
            alert(" Impossible de recuperer la liste des documents");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Partner_Document_With_filter_api, setGetall_Partner_Document_With_filter_api] = useState();
    const [Getall_Partner_Document_With_filter_message, setGetall_Partner_Document_With_filter_message] = useState();
    const [Getall_Partner_Document_With_filter_result, setGetall_Partner_Document_With_filter_result] = useState();
    function Getall_Partner_Document_With_filter(event) {

        var form = new FormData();
        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;


        var filtre1_value = "";
        var filtre2_value = "";

        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Document_with_filter/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Document_With_filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Document_With_filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Document_With_filter_api("true");
                setGetall_Partner_Document_With_filter_result(res.data.message);
                setRows(res.data.message);
            }
            else {
                setGetall_Partner_Document_With_filter_api("false");
                setGetall_Partner_Document_With_filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Document_With_filter = ', error);
            setGetall_Partner_Document_With_filter_api("false");
            alert(" Impossible de recuperer la liste des documents");
            //setmyApimyApiMessage("")
        })
    }

    const [Get_Given_Personnalisable_Fields_From_template_ID_api, setGet_Given_Personnalisable_Fields_From_template_ID_api] = useState();
    const [Get_Given_Personnalisable_Fields_From_template_ID_message, setGet_Given_Personnalisable_Fields_From_template_ID_message] = useState();
    const [Get_Given_Personnalisable_Fields_From_template_ID_result, setGet_Given_Personnalisable_Fields_From_template_ID_result] = useState();
    function Get_Given_Personnalisable_Fields_From_template_ID(local_courrier_template_id) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("courrier_template_id", local_courrier_template_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Personnalisable_Fields_By_courrier_template_id/";
        setLoading(true);

        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                console.log(" In Get_Given_Personnalisable_Fields_From_template_ID  res.data.status = " + res.data.status);
                console.log(" In Get_Given_Personnalisable_Fields_From_template_ID  res.data.message r_class = " + res.data.message);

                setGet_Given_Personnalisable_Fields_From_template_ID_api("true");
                setGet_Given_Personnalisable_Fields_From_template_ID_result(res.data.message);

            }
            else {
                setGet_Given_Personnalisable_Fields_From_template_ID_api("false");
                setGet_Given_Personnalisable_Fields_From_template_ID_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Given_Personnalisable_Fields_From_template_ID = ', error);
            setGet_Given_Personnalisable_Fields_From_template_ID_api("false");
            alert(" Impossible de recuperer la liste des collection et champs personnalisables de l'objet");
            //setmyApimyApiMessage("")
        })
    }


    const [display_partner_document, setdisplay_partner_document] = React.useState();
    function Annule_Partner_Document_DetailFields() {

        Get_Partner_Document_Data(selected_id);

        setpartner_document_data_changed("");
        setpartner_document_data_edit_mode("");

    }

    const [New_Get_List_Personnalisable_Default_Document_result, setNew_Get_List_Personnalisable_Default_Document_result] = useState();

    const [Get_List_Personnalisable_Default_Document_api, setGet_List_Personnalisable_Default_Document_api] = useState();
    const [Get_List_Personnalisable_Default_Document_message, setGet_List_Personnalisable_Default_Document_message] = useState();
    const [Get_List_Personnalisable_Default_Document_result, setGet_List_Personnalisable_Default_Document_result] = useState();
    function Get_List_Personnalisable_Default_Document() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Default_Partner_Document/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_List_Personnalisable_Default_Document  res.data.status = " + res.data.status);
                //console.log(" In Get_List_Personnalisable_Default_Document  res.data.message r_class = " + res.data.message);

                setGet_List_Personnalisable_Default_Document_api("true");
                setGet_List_Personnalisable_Default_Document_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_type_doc = JSON.parse(x).type_doc;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " - " + local_type_doc + "",
                        "nom": local_nom,
                        "type_doc": local_type_doc,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Personnalisable_Default_Document_result(new_data2);


            }
            else {
                setGet_List_Personnalisable_Default_Document_api("false");
                setGet_List_Personnalisable_Default_Document_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_List_Personnalisable_Default_Document = ', error);
            setGet_List_Personnalisable_Default_Document_api("false");
            alert(" Impossible de recuperer la liste des documents par default");
            //setmyApimyApiMessage("")
        })
    }

    const [selecte_defaul_doc_to_add, setselecte_defaul_doc_to_add] = React.useState();

    const [add_one_document, setadd_one_document] = React.useState();
    function submenu_add_one_model_document() {
        setSelectionModel([]);
        clear_one_detail_fields();
        setpartner_document_data_edit_mode();
        setpartner_document_data_changed();
        clear_detail_fields();
        setdisplay_partner_document();
        setdisplay_detail_document();

        setgridline_id("");

        Get_List_Personnalisable_Default_Document();
        setadd_one_document("1");

    }

    const [display_add_one_document, setdisplay_add_one_document] = React.useState();

    const [original_courrier_template_id, setoriginal_courrier_template_id] = React.useState("");


    function Get_And_Display_Selected_Document() {
        setdisplay_add_one_document("1");

        setadd_one_document("1");
        setdisplay_detail_document("0");
        setselected_id(selecte_defaul_doc_to_add);
        Get_Default_Original_Partner_Document_Data_By_id(selecte_defaul_doc_to_add);
        Get_Given_Personnalisable_Fields_From_template_ID(selecte_defaul_doc_to_add);
    }


    function Annule_Add_One_New_Doc() {
        clear_one_detail_fields();
        setadd_one_document();
        setdisplay_add_one_document();
        setp_champ_table("");


    }



    const [Dialog_duplicate_doc_message, setDialog_duplicate_doc_message] = React.useState(false);
    const [Dialog_duplicate_doc_open, setDialog_duplicate_doc_open] = React.useState(false);
    function Dialog_duplicate_doc_handle_change_participant_session(message) {
        setDialog_duplicate_doc_message(message);
        setDialog_duplicate_doc_open(true);
    }

    const Dialog_duplicate_doc_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_duplicate_doc_handleClose_buton = () => {
        setDialog_duplicate_doc_open(false);
    };

    const [duplicate_doc_sujet, setduplicate_doc_sujet] = React.useState();
    const [duplicate_doc_nom, setduplicate_doc_nom] = React.useState();
    const [duplicate_doc_type, setduplicate_doc_type] = React.useState();


    // Gestion des pièces jointes


    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();

    function Record_All_PJ() {

        if (file_1_name && file_1_name.name) {
            //console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", file_1_name.name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "courrier_template");
            formData.append("object_owner_id", selected_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);

            setLoading(true);
            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    setLoading(false);
                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(selected_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                        alert(result['message']);
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                    alert(" Impossible d'ajouter la pièce jointe")
                });

        }



    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();

    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";

        setLoading(true);
        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(selected_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                    alert(result['message'])
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState([]);
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();

    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "courrier_template");
        formData.append("object_owner_id", local_employee_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);
        setLoading(true);

        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                setLoading(false);
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }

    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();
    const [isLoading, setLoading] = useState();
    const [file_1_name, setfile_1_name] = useState();
    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;



        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');


        return;

    };

    const [document_principal_ou_corp_mail, setdocument_principal_ou_corp_mail] = useState();
    /*
    document_principal_ou_corp_mail = '1' pour le document principal 
     document_principal_ou_corp_mail = '2' pour le corps du mail qui comporte une pièce jointe 

    */

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();

    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    function clean_all_filters() {


        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");



        setgridline_id('');
        setSelectionModel([]);
        setsubmenu("");

        Getall_Partner_Document_No_filter();
    }


    // end gestion pièces jointes

    const [gridline_id, setgridline_id] = useState("");

    const New_Option_Filter = [
        { "id": "nom", "label": "Nom Document", "value": "nom" },
        { "id": "ref_interne", "label": "Ref. Interne", "value": "ref_interne" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]

    return (
        <div className="config_document">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}

            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={Dialog_1_handleClose_buton}>OK</Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={Dialog_duplicate_doc_open}
                onClose={Dialog_duplicate_doc_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">

                    <div className="session_caract_Dialog" > Type Document
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                            name="duplicate_type_doc"
                            id="duplicate_type_doc"

                            select
                            fullWidth

                            value={duplicate_doc_type}
                            onChange={(e) => {
                                setduplicate_doc_type(e.target.value);
                            }
                            }

                        >
                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="pdf" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >PDF &nbsp;</MenuItem>

                        </TextField>

                    </div>


                    <div className="session_caract_Dialog" > Nom Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="duplicate_nom_doc"
                            id="duplicate_nom_doc"

                            fullWidth
                            value={duplicate_doc_nom}
                            onChange={(e) => {
                                setduplicate_doc_nom(e.target.value);
                            }
                            }
                        />

                    </div>


                    <div className="session_caract_Dialog" > Sujet Document<br />
                        <TextField
                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}

                            name="duplicate_nom_doc"
                            id="duplicate_nom_doc"
                            fullWidth
                            value={duplicate_doc_sujet}
                            onChange={(e) => {
                                setduplicate_doc_sujet(e.target.value);
                            }
                            }
                        />

                    </div>


                </DialogContent>



                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {
                                if (String(duplicate_doc_nom).length > 2 && String(duplicate_doc_type).length > 2 && String(duplicate_doc_sujet).length > 10) {
                                    const response = window.confirm(" Vous allez créer un nouveau document. Continuer ?");
                                    if (!response) {
                                        return;
                                    }

                                    Duplicate_Partner_Document();

                                }
                                else {
                                    alert(" Les champs : 'Nom', 'Type' et 'Sujet' doivent faire plus de 10 caractères");
                                }
                            }}
                                className="bton_enreg_dialog">Dupliquer</Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_duplicate_doc_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>

            <h3> Configuration documents </h3>
            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}
                        {/*<TextField
                            name="filtre1"
                            label="Choisir un champ"
                            select
                            sx={{ m: 1, width: '100%' }}
                            value={p_filtre1}
                            onChange={(e) => {
                                setp_filtre1(e.target.value);

                            }}
                        >
                            <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                            <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                            <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>
                            <MenuItem value="session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Session (code session) &nbsp;</MenuItem>


                        </TextField>*/}
                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                            {/*<TextField
                                name="filtre2"
                                label="Choisir un champ"
                                select
                                sx={{ m: 1, width: '100%' }}

                                value={p_filtre2}
                                onChange={(e) => {
                                    setp_filtre2(e.target.value);

                                }}
                            >
                                <MenuItem value="email" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Email &nbsp;</MenuItem>
                                <MenuItem value="nom" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Nom  &nbsp;</MenuItem>
                                <MenuItem value="formation" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Formation (code externe) &nbsp;</MenuItem>
                                <MenuItem value="session" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Lié a la Session (code session) &nbsp;</MenuItem>


                            </TextField>*/}
                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2();
                                        setp_filtre2_value();
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }




                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Partner_Document_With_filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>


            <div className="div_row" style={{ "paddingRight": "5px" }}>
                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },

                                }}
                            >
                                <DataGrid
                                    checkboxSelection
                                    disableSelectionOnClick
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel);
                                        /* if (newSelectionModel.length === 1)
                                             handleClick_display_partner_document(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            setpartner_document_data_edit_mode();
                                            setpartner_document_data_changed();
                                            clear_detail_fields();
                                            setsubmenu();
                                            setdisplay_partner_document();
                                            setdisplay_detail_document();
                                        }
                                    }}
                                    selectionModel={selectionModel}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={rowss.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            nom: JSON.parse(item).nom,
                                            ref_interne: JSON.parse(item).ref_interne,
                                            type_doc: JSON.parse(item).type_doc,
                                            cible: JSON.parse(item).cible,
                                            sujet: JSON.parse(item).sujet,
                                            default: JSON.parse(item).default_version,
                                            duplicate: JSON.parse(item).duplicate,
                                            partner_owner_nom: JSON.parse(item).partner_owner_nom,

                                        }
                                    ))}

                                    columns={columns}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {
                                        setgridline_id(newSelectionModel.row.id);
                                        handleClick_display_partner_document(newSelectionModel.row.id);


                                    }}

                                    rowsPerPageOptions={[10]}
                                    //disableSelectionOnClick
                                    components={{
                                        Toolbar: GridToolbar,
                                    }}
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {
                                        //field === 'distantiel'
                                        if (params.field === 'distantiel' && String(params.value) === "1") {
                                            return 'cell--distantiel';
                                        }
                                        if (params.field === "presentiel" && String(params.value) == "1") {
                                            return 'cell--presentiel';
                                        }


                                        //field === "statut"
                                        if (params.field === "status" && String(params.value) == "0") {
                                            return 'cell--statut--preinscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "1") {
                                            return 'cell--statut--inscrit';
                                        }

                                        if (params.field === "status" && String(params.value) == "-1") {
                                            return 'cell--statut--annule';
                                        }

                                    }}
                                    getRowClassName={(params) => {

                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }

                                    }}


                                />
                            </Box>
                            <br />

                        </div>

                    </div>
                    <div className="div_row_gauche" style={{ "border": "None" }}>
                        <Button variant="outlined" onClick={submenu_add_one_model_document}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Document &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>
                    </div>

                </div>
            </div>


            {String(display_detail_document) === "1" && String(add_one_document) !== "1" && <div className="div_row">
                <h3> Détail document <i> {p_detail_nom} </i></h3>

                <div className="div_row">

                    <div className="div_row_gauche_2_tier">


                        <div className="div_row_gauche texte_area_filter" >
                            Nom document <br />
                            <TextField
                                name="nom_document"
                                //label="Nom document"
                                sx={{ m: 1, width: '100%' }}
                                value={p_one_detail_nom}
                                className="disabled_style"

                            />

                        </div>



                        <div className="div_row_gauche texte_area_filter" >
                            Type de document<br />
                            <TextField
                                name="document_type"
                                //label="Type de document"
                                sx={{ m: 1, width: '100%' }}
                                value={p_detail_type_doc}
                                className="disabled_style"

                            />

                        </div>


                        <div className="div_row_gauche texte_area_filter" ref={myRef} id="myRef">
                            Code Document <br />
                            <TextField
                                name="document_ref_interne"
                                //label="Code Document"
                                sx={{ m: 1, width: '100%' }}
                                value={p_detail_ref_interne}
                                className="disabled_style"
                            />

                        </div>

                        <div className="div_row_gauche texte_area_filter" >
                            Sujet <br />
                            <TextField
                                name="document_sujet"
                                //label="Sujet"
                                sx={{ m: 1, width: '100%' }}
                                value={p_detail_sujet}
                                onChange={(e) => {
                                    setp_detail_sujet(e.target.value);
                                    setpartner_document_data_changed("1");
                                }}
                                className="disabled_style"
                            />

                        </div>

                        {String(partner_document_data_edit_mode) !== "1" && String(p_detail_type_doc) === "email" && <div className="div_row_gauche texte_area_filter" >
                            Joindre en PDF <br />

                            <Checkbox
                                name="document_join_pdf"
                                className="disabled_style"
                                checked={p_detail_joint_pdf} 
                            />

                        </div>}

                        {String(partner_document_data_edit_mode) !== "1" && <div className="div_row_gauche texte_area_filter" >
                            Version par defaut <br />

                            <Checkbox
                                name="document_join_pdf"
                                className="disabled_style"
                                checked={p_detail_default_version}
                            />

                        </div>}

                        {String(partner_document_data_edit_mode) !== "1" && <div className="div_row_gauche texte_area_filter" >
                            Edition par client <br />

                            <Checkbox
                                name="document_join_pdf"
                                className="disabled_style"
                                checked={p_detail_edition_par_client}
                            />

                        </div>}



                        {String(partner_document_data_edit_mode) === "1" && String(p_detail_type_doc) === "email" && <div className="div_row_gauche texte_area_filter" >
                            Joindre en PDF <br />

                            <Checkbox
                                name="document_edition_par_client"
                                className="disabled_style"
                                checked={p_detail_joint_pdf}
                                onChange={(e) => {
                                    setp_detail_joint_pdf(e.target.checked);
                                    setpartner_document_data_changed("1");
                                }}
                            />

                        </div>}

                        {String(partner_document_data_edit_mode) === "1" && <div className="div_row_gauche texte_area_filter" >
                            Version par defaut <br />

                            <Checkbox
                                name="document_join_pdf"
                                className="disabled_style"
                                checked={p_detail_default_version}
                                onChange={(e) => {
                                    setp_detail_default_version(e.target.checked);
                                    setpartner_document_data_changed("1");
                                }}
                            />

                        </div>}

                        {String(partner_document_data_edit_mode) === "1" && <div className="div_row_gauche texte_area_filter" >
                            Edition par client  <br />

                            <Checkbox
                                name="document_edition_par_client"
                                className="disabled_style"
                                checked={p_detail_edition_par_client}
                                onChange={(e) => {
                                    setp_detail_edition_par_client(e.target.checked);
                                    setpartner_document_data_changed("1");
                                }}
                            />

                        </div>}



                    </div>
                    <div className="div_row_droite_1_tier" style={{ "textAlign": "left" }}>
                        {String(partner_document_data_edit_mode) === "1" &&
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={New_Get_Given_Personnalisable_Collection_and_Fields_result}
                                onChange={(event, value) => {
                                    setp_champ_table(value.nom_champ_technique);
                                }}


                                renderInput={(params) => <TextField {...params} label="Choisir " />
                                }
                            />
                        }

                        {String(partner_document_data_edit_mode) === "1" &&
                            <div className="div_row">
                                <Button className="bton_enreg" onClick={add_selected_fields} style={{ "margin": "1rem", "minWidth": '80%' }}
                                >Ajouter Champ Choisi &nbsp;

                                </Button>
                            </div>}

                    </div>


                    {p_detail_type_doc && String(p_detail_type_doc).toLocaleLowerCase() === "email" && <div className="div_row" style={{ "marginRight": "15px" }} >

                        <div>
                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                            <div className="div_row" style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "padding": "5px" }}>
                                Pièces jointes

                            </div>

                            {String(partner_document_data_edit_mode) === "1" && <div className="div_row" style={{ "padding": "5px" }}>

                                <div style={{ "fontSize": "12px" }}>
                                    <label htmlFor="upload-photo">
                                        <input
                                            style={{ display: "none" }}
                                            id="upload-photo"
                                            name="upload-photo"
                                            type="file"
                                            onChange={sessions_file_change_1}
                                        />

                                        <Fab
                                            color="secondary"
                                            size="small"
                                            component="span"
                                            aria-label="add"
                                            variant="extended"
                                        >
                                            <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                        </Fab>

                                    </label>
                                </div>
                                {file_1_name && file_1_name.name &&
                                    <nav style={{ "fontSize": "12px" }}>
                                        Fichier : {file_1_name.name} <font style={{ "cursor": "pointer" }}
                                            onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                                    </nav>}

                                {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                                {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                            </div>}


                            {file_1_name && file_1_name.name && <div className="div_row">

                                <div className="div_row_gauche">
                                    <Button variant="contained" onClick={Record_All_PJ}
                                        startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                                </div>


                            </div>}

                            {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                                <div className="div_row">
                                    Liste des pièces jointes <br />
                                    <div className="div_row">
                                        {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                            <div className="div_row_list_pj"  >
                                                <nav style={{ "color": "green", "cursor": "pointer" }} onClick={Download_one_attached_document} name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}> Télécharger &nbsp;<i> {(JSON.parse(val).file_business_object)}</i> </nav>
                                                <Popup
                                                    trigger={
                                                        <nav style={{ "color": "red", "cursor": "pointer", "marginBottom": "1rem" }}  > Supprimer</nav>

                                                    }
                                                    modal
                                                    nested
                                                    position="center center"
                                                >
                                                    {close => (
                                                        <div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}

                                                                En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                            </div>
                                                            <div className="gest_actions">
                                                                <div style={{ "width": "45%", "float": "left" }}>
                                                                    <button className="gest_bton_popup" onClick={(event) => {

                                                                        Delete_one_attached_document(event);
                                                                        close();
                                                                    }}
                                                                        name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                                    > Valider </button>

                                                                </div>
                                                                <div style={{ "width": "45%", "float": "right" }}>
                                                                    <button
                                                                        className="gest_bton_popup"
                                                                        onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Annuler
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}


                            <div className="div_row" style={{ "border": "None" }}>
                                <hr className="hr_break" />
                            </div>

                        </div>

                    </div>}

                </div>
                {String(partner_document_data_edit_mode) !== "1" && <div className="div_row">

                    {p_detail_joint_pdf === true && <div className="div_row" style={{ "border": "None", "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                        <Button variant="outlined"
                            onClick={(event) => {
                                setdocument_principal_ou_corp_mail("1");
                            }}
                            className="detail_class_submenu" id='detail_document_principal' name='detail_document_principal'>Document principal</Button>
                        <Button variant="outlined"
                            onClick={(event) => {
                                setdocument_principal_ou_corp_mail("2");
                            }}
                            className="detail_class_submenu" id='detail_corps_mail' name='detail_corps_mail'>Corps du mail  </Button>
                    </div>}


                    {p_detail_joint_pdf === true && String(document_principal_ou_corp_mail) === "2" &&
                        <div style={{ "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>

                            <div className="titre_training_text"> Corps du mail </div>
                            <Editor
                                id="corps_mail_content"
                                name="corps_mail_content"
                                onInit={(evt, editor) => one_editorRef_corp_mail.current = editor}
                                initialValue={field_corp_mail_initial_value}
                                disabled={true}
                                init={{
                                    resize: false,
                                    height: 700,
                                    menubar: true,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                        'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                    ],
                                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                }}
                            />

                        </div>
                    }

                    {String(document_principal_ou_corp_mail) !== "2" && <div>
                        <div style={{ "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                            <div className="titre_training_text"> Votre document </div>
                            <Editor
                                id="detail_document_principal"
                                name="detail_document_principal"
                                onInit={(evt, editor) => editorRef_programme.current = editor}
                                initialValue={field_programme_initial_value}

                                disabled={true}
                                init={{
                                    resize: false,
                                    height: 700,
                                    menubar: true,
                                    readonly: 1,
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                        'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                        'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                    ],
                                    toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                        'alignleft aligncenter alignright alignjustify | ' +
                                        'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                }}
                            />

                        </div>
                    </div>}
                </div>}



                {String(partner_document_data_edit_mode) === "1" && <div className="div_row">

                    <div className="div_row" >

                        {p_detail_joint_pdf === true && <div className="div_row" style={{ "border": "None", "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                            <Button variant="outlined"
                                onClick={(event) => {
                                    setdocument_principal_ou_corp_mail("1");
                                }}
                                className="detail_class_submenu" id='detail_employe' name='detail_employe'>Document principal</Button>
                            <Button variant="outlined"
                                onClick={(event) => {
                                    setdocument_principal_ou_corp_mail("2");
                                }}
                                className="detail_class_submenu" id='droit_acces' name='droit_acces'>Corps du mail  </Button>
                        </div>}


                        {p_detail_joint_pdf === true && String(document_principal_ou_corp_mail) === "2" &&
                            <div style={{ "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>

                                <div className="titre_training_text"> Modifier le  Corps du mail </div>
                                <Editor
                                    id="corps_mail_content"
                                    name="corps_mail_content"
                                    onInit={(evt, editor) => one_editorRef_corp_mail.current = editor}
                                    //initialValue={field_corp_mail}
                                    value={field_corp_mail}
                                    onKeyUp={editor_keyup}
                                    onEditorChange={(newValue, editor) => {
                                        set_field_corp_mail(one_editorRef_corp_mail.current.getContent());
                                        setpartner_document_data_changed("1");
                                    }}
                                    init={{
                                        resize: false,
                                        height: 700,
                                        menubar: true,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                            'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                        ],
                                        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                            'alignleft aligncenter alignright alignjustify | ' +
                                            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                    }}
                                />

                            </div>
                        }


                        {String(document_principal_ou_corp_mail) !== "2" &&
                            <div style={{ "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                                <div className="titre_training_text"> Modifier le contenu du document </div>
                                <Editor
                                    id="detail_document_principal"
                                    name="detail_document_principal"
                                    onInit={(evt, editor) => one_editorRef_programme.current = editor}
                                    //initialValue={field_programme_initial_value}

                                    onKeyUp={editor_keyup}
                                    value={field_programme}
                                    onEditorChange={(newValue, editor) => {
                                        setfield_programme(one_editorRef_programme.current.getContent());
                                        setpartner_document_data_changed("1");

                                    }}
                                    init={{
                                        resize: false,
                                        height: 700,
                                        menubar: true,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                            'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                        ],
                                        toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                            'alignleft aligncenter alignright alignjustify | ' +
                                            'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                    }}
                                />

                            </div>}

                    </div>

                </div>}



                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>
                {String(partner_document_data_changed) === "1" && String(partner_document_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                    /!\ Pensez à enregistrer les modifications
                </div>}


                <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    {String(partner_document_data_changed) === "1" && String(partner_document_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                        <Button variant="contained" className="bton_enreg" onClick={Update_one_Partner_Document}>Enregistrer les modifications
                        </Button>

                    </div>}

                    {String(partner_document_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                        <Button variant="contained" className="bton_annule" onClick={Annule_Partner_Document_DetailFields}>Annuler
                        </Button>
                    </div>}
                </div>

                {String(partner_document_data_edit_mode) !== "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>


                 &nbsp;

                </div>}


                {String(partner_document_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                    <Button variant="contained" className="bton_edit" onClick={Edit_Document_Data_Fields}>Editer
                    </Button>

                </div>}
                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>

            </div>}

            {String(display_detail_document) !== "1" && String(add_one_document) === "1" && <div className="div_row">
                <h3> Personnaliser un nouveau document </h3>
                <div className="div_row" style={{ "textAlign": "left" }}>

                    <div className="div_row_gauche"> Choisir le document à ajouter  <br />



                        <Autocomplete
                            disablePortal
                            name="champ_table"
                            id="champ_table"

                            options={New_Get_List_Personnalisable_Default_Document_result}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setselecte_defaul_doc_to_add(value._id);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    <div className="div_row">
                        <Button className="bton_enreg" onClick={Get_And_Display_Selected_Document} style={{ "margin": "1rem" }}
                        >Afficher le modèle par defaut &nbsp;

                        </Button>
                    </div>

                </div>

                {String(display_add_one_document) === "1" && <div>

                    <div className="div_row">

                        <div className="div_row_gauche">
                            <div className="div_row_gauche texte_area_filter" >
                                Type de document <br />
                                <TextField
                                    name="one_document_type"
                                    //label="Type de document"

                                    sx={{ m: 1, width: '100%' }}
                                    value={p_one_detail_type_doc}
                                    className="disabled_style"
                                />
                            </div>


                            <div className="div_row_gauche texte_area_filter" >
                                Nom document <br />
                                <TextField
                                    name="one_nom_document"
                                    //label="Nom document"

                                    sx={{ m: 1, width: '100%' }}
                                    value={p_one_detail_nom}
                                    className="disabled_style"
                                />
                            </div>



                            <div className="div_row_gauche texte_area_filter" ref={myRef} id="myRef">
                                Code Document <br />
                                <TextField
                                    name="one_document_ref_interne"
                                    //label="Code Document"
                                    sx={{ m: 1, width: '100%' }}
                                    value={p_one_detail_ref_interne}
                                    className="disabled_style"

                                />

                            </div>

                            <div className="div_row_gauche texte_area_filter" >
                                Sujet  <br />
                                <TextField
                                    name="one_document_sujet"
                                    //label="Sujet"
                                    sx={{ m: 1, width: '100%' }}
                                    value={p_one_detail_sujet}
                                    onChange={(e) => {
                                        setp_detail_sujet(e.target.value);
                                        setpartner_document_data_changed("1");
                                    }}
                                    className="disabled_style"
                                />

                            </div>

                            {String(p_one_detail_type_doc) === "email" && <div className="div_row_gauche texte_area_filter" >
                                Joindre en PDF  <br />

                                <Checkbox 
                                    name="document_join_pdf"
                                    //  className="disabled_style"
                                    checked={p_detail_joint_pdf}
                                    onChange={(e) => {
                                        setp_detail_joint_pdf(e.target.checked);
                                        setpartner_document_data_changed("1");
                                    }}

                                />

                            </div>}

                            <div className="div_row_gauche texte_area_filter" >
                                Version par defaut  <br />

                                <Checkbox
                                    name="document_join_pdf"
                                    //  className="disabled_style"
                                    checked={p_detail_default_version}
                                    onChange={(e) => {
                                        setp_detail_default_version(e.target.checked);
                                        setpartner_document_data_changed("1");
                                    }}

                                />

                            </div>

                            <div className="div_row_gauche texte_area_filter" >
                                Edition par client  <br />

                                <Checkbox
                                    name="document_join_pdf"
                                    //  className="disabled_style"
                                    checked={p_detail_edition_par_client}
                                    onChange={(e) => {
                                        setp_detail_edition_par_client(e.target.checked);
                                        setpartner_document_data_changed("1");
                                    }}

                                />

                            </div>

                        </div>
                        <div className="div_row"> &nbsp; </div>
                        <div className="div_row_droite texte_area_filter" style={{ "textAlign": "left" }}>
                            <div className="div_row"> Choisir un champ  <br />
                                <TextField
                                    name="one_champ_table"
                                    id="one_champ_table"
                                    select
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style enable_style"
                                    value={p_champ_table}
                                    onChange={(e) => {
                                        setp_champ_table(e.target.value);
                                    }}
                                >

                                    {Get_Given_Personnalisable_Fields_From_template_ID_result &&
                                        Get_Given_Personnalisable_Fields_From_template_ID_result.map((mycollection) => (
                                            <MenuItem key={JSON.parse(mycollection).nom_champ_technique}
                                                value={JSON.parse(mycollection).nom_champ_technique}
                                                style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontSize": "12px" }}>
                                                {JSON.parse(mycollection).nom_champ_fonctionel}&nbsp;&nbsp;

                                            </MenuItem>
                                        ))}
                                </TextField>

                            </div>

                            <div className="div_row">
                                <Button className="bton_enreg" onClick={add_one_selected_fields} style={{ "margin": "1rem", minWidth: '15rem' }}
                                >Ajouter le Champ Choisi &nbsp;

                                </Button>
                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None", "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                            <hr className="hr_break" />
                        </div>



                        <div className="div_row" >

                            {p_detail_joint_pdf === true && <div className="div_row" style={{ "border": "None", "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                                <Button variant="outlined"
                                    onClick={(event) => {
                                        setdocument_principal_ou_corp_mail("1");
                                    }}
                                    className="detail_class_submenu" id='one_detail_document_principal' name='one_detail_document_principal'>Document principal</Button>
                                <Button variant="outlined"
                                    onClick={(event) => {
                                        setdocument_principal_ou_corp_mail("2");
                                    }}
                                    className="detail_class_submenu" id='droit_acces' name='droit_acces'>Corps du mail  </Button>
                            </div>}


                            {p_detail_joint_pdf === true && String(document_principal_ou_corp_mail) === "2" &&
                                <div style={{ "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>

                                    <div className="titre_training_text"> Créer le  Corps du mail </div>
                                    <Editor
                                        id="one_corps_contente"
                                        name="one_corps_contente"
                                        onInit={(evt, editor) => one_editorRef_corp_mail.current = editor}
                                        //initialValue={one_field_corp_mail_initial_value}
                                        value={one_field_corp_mail}
                                        onKeyUp={editor_keyup}

                                        onEditorChange={(newValue, editor) => {
                                            /*if (String(editor_tochange) === "1") {
                                                //alert(" change");
                                                seteditor_tochange("");
                                                setpartner_document_data_changed("1");
                                            }*/
                                            setpartner_document_data_changed("1");
                                            setone_field_corp_mail(one_editorRef_corp_mail.current.getContent());
                                        }}
                                        init={{
                                            resize: false,
                                            height: 700,
                                            menubar: true,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                                'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                            ],
                                            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />

                                </div>
                            }


                            {String(document_principal_ou_corp_mail) !== "2" &&
                                <div style={{ "width": "98%", "marginLeft": "1%", "marginRight": "1%", "float": "left" }}>
                                    <div className="titre_training_text"> Créer le contenu du document </div>
                                    <Editor
                                        id="one_detail_document_principal"
                                        name="one_detail_document_principal"
                                        onInit={(evt, editor) => one_editorRef_programme.current = editor}
                                        //initialValue={one_field_programme_initial_value}
                                        value={one_field_programme}
                                        onKeyUp={editor_keyup}
                                        onEditorChange={(newValue, editor) => {
                                            /*if (String(editor_tochange) === "1") {
                                                //alert(" change");
                                                seteditor_tochange("");
                                                setpartner_document_data_changed("1");
                                            }
                                            */
                                            setpartner_document_data_changed("1");
                                            setone_field_programme(one_editorRef_programme.current.getContent());
                                        }}
                                        init={{
                                            resize: false,
                                            height: 700,
                                            menubar: true,
                                            plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                                'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                            ],
                                            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />

                                </div>}

                        </div>

                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                        /!\ Pensez à enregistrer les modifications
                    </div>


                    <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                        <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                            <Button variant="contained" className="bton_enreg" onClick={Add_one_Partner_Document}>Enregistrer les modifications
                            </Button>

                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                            <Button variant="contained" className="bton_annule" onClick={Annule_Add_One_New_Doc}>Annuler
                            </Button>
                        </div>
                    </div>
                </div>}

            </div>}

        </div >
    );
}

export default Config_Documents_Super_Admin;
