import React, { useRef, useState, useEffect } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { tokens } from "../theme";
import {  MdDeleteForever } from "react-icons/md";

import {
    useTheme,
} from "@mui/material";

import { ResponsivePie } from '@nivo/pie'

import { RiFileExcel2Line } from "react-icons/ri";

const Dashbord_Session_Repartition_Fteur = (props) => {

    const [user_dashbord_id, setuser_dashbord_id] = useState(props.user_dashbord_id);


    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);


    const [p_filter_date_from, setp_filter_date_from] = useState(props.user_dashbord_filter_start);
    const [p_filter_date_to, setp_filter_date_to] = useState(props.user_dashbord_filter_end);

    const [Add_To_User_TBD_api, setAdd_To_User_TBD_api] = useState();
    const [Add_To_User_TBD_message, setAdd_To_User_TBD_message] = useState();
    const [Add_To_User_TBD_result, setAdd_To_User_TBD_result] = useState();
    function Add_To_User_TBD(local_dashbord_internal_code) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        //console.log(" ### dashbord_internal_code = ", local_dashbord_internal_code);

        var tdb_title = "default";
        var filter_val = "";


        if (document.getElementsByName("tdb_title")[0]) {
            tdb_title = document.getElementsByName("tdb_title")[0].value;
        }

        if (document.getElementsByName("filtre11")[0]) {
            filter_val = document.getElementsByName("filtre11")[0].value;
        }

        // Si filter_val =  "d", alors on va recuperer l'interval de date
        if (String(filter_val) === "d") {
            var date_from = p_filter_date_from;
            var date_to = p_filter_date_to;


            filter_val = "{'session_start_date':'" + String(date_from) + "' , 'session_end_date':'" + String(date_to) + "'}";
        } else {
            filter_val = "{'periode':'" + String(filter_val) + "'}"
        }

        form.append("token", stored_cookie);
        form.append("dashbord_internal_code", local_dashbord_internal_code);
        form.append("default_filter", filter_val);
        form.append("title", tdb_title);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_To_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Add_To_User_TBD  res.data.message r_class = " + res.data.message);

                setAdd_To_User_TBD_api("true");
                setAdd_To_User_TBD_result(res.data.message);
                settdb_title();
                alert(res.data.message)


            }
            else {
                setAdd_To_User_TBD_api("false");
                setAdd_To_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Add_To_User_TBD = ', error);
            setAdd_To_User_TBD_api("false");
            alert("Impossible d'ajouter le tableau de bord");
            //setmyApimyApiMessage("")
        })
    }

    const [Remove_From_User_TBD_api, setRemove_From_User_TBD_api] = useState();
    const [Remove_From_User_TBD_message, setRemove_From_User_TBD_message] = useState();
    const [Remove_From_User_TBD_result, setRemove_From_User_TBD_result] = useState();
    function Remove_From_User_TBD() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');



        form.append("token", stored_cookie);
        form.append("user_dashbord_id", user_dashbord_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Remove_From_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Remove_From_User_TBD  res.data.message r_class = " + res.data.message);

                setRemove_From_User_TBD_api("true");
                setRemove_From_User_TBD_result(res.data.message);
                Getall_Qry_Session_Data_Pourcent_Trainer();
                alert(res.data.message);
                window.location.reload();


            }
            else {
                setRemove_From_User_TBD_api("false");
                setRemove_From_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Remove_From_User_TBD = ', error);
            setRemove_From_User_TBD_api("false");
            alert("Impossible de supprimer le tableau de bord de votre liste");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Qry_Session_Data_result_JSON, setGetall_Qry_Session_Data_result_JSON] = useState([]);
    const [Getall_Qry_Session_Data_api, setGetall_Qry_Session_Data_api] = useState();
    const [Getall_Qry_Session_Data_message, setGetall_Qry_Session_Data_message] = useState();
    const [Getall_Qry_Session_Data_result, setGetall_Qry_Session_Data_result] = useState([]);
    function Getall_Qry_Session_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Session_Data/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Session_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Session_Data  res.data.message r_class = " + res.data.message);
                setGetall_Qry_Session_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_Session_Data_api("true");
                setGetall_Qry_Session_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Session_Data_api("false");
                setGetall_Qry_Session_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Session_Data = ', error);
            setGetall_Qry_Session_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }



    const [Getall_Qry_Session_Data_By_Trainer_result_JSON, setGetall_Qry_Session_Data_By_Trainer_result_JSON] = useState([]);
    const [Getall_Qry_Session_Data_By_Trainer_api, setGetall_Qry_Session_Data_By_Trainer_api] = useState();
    const [Getall_Qry_Session_Data_By_Trainer_message, setGetall_Qry_Session_Data_By_Trainer_message] = useState();
    const [Getall_Qry_Session_Data_By_Trainer_result, setGetall_Qry_Session_Data_By_Trainer_result] = useState([]);
    function Getall_Qry_Session_Data_By_Trainer(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;
        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;
        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Session_By_Trainer_By_Periode/";


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Session_Data_By_Trainer  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Session_Data_By_Trainer  res.data.message r_class = " + res.data.message);
                setGetall_Qry_Session_Data_By_Trainer_result_JSON(JSON.parse('[' + res.data.message + ']'));


                //console.log(Getall_Qry_Session_Data_By_Trainer_result_JSON);

                setGetall_Qry_Session_Data_By_Trainer_api("true");
                setGetall_Qry_Session_Data_By_Trainer_result(res.data.message);


            }
            else {
                setGetall_Qry_Session_Data_By_Trainer_api("false");
                setGetall_Qry_Session_Data_By_Trainer_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Session_Data_By_Trainer = ', error);
            setGetall_Qry_Session_Data_By_Trainer_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }



    const [Getall_Qry_Session_Data_Pourcent_Trainer_result_JSON, setGetall_Qry_Session_Data_Pourcent_Trainer_result_JSON] = useState([]);
    const [Getall_Qry_Session_Data_Pourcent_Trainer_api, setGetall_Qry_Session_Data_Pourcent_Trainer_api] = useState();
    const [Getall_Qry_Session_Data_Pourcent_Trainer_message, setGetall_Qry_Session_Data_Pourcent_Trainer_message] = useState();
    const [Getall_Qry_Session_Data_Pourcent_Trainer_result, setGetall_Qry_Session_Data_Pourcent_Trainer_result] = useState([]);
    function Getall_Qry_Session_Data_Pourcent_Trainer(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Session_Repartition_Session_By_Trainer_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Session_Data_Pourcent_Trainer  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Session_Data_Pourcent_Trainer  res.data.message r_class = " + res.data.message);
                setGetall_Qry_Session_Data_Pourcent_Trainer_result_JSON(JSON.parse('[' + res.data.message + ']'));


                setGetall_Qry_Session_Data_Pourcent_Trainer_api("true");
                setGetall_Qry_Session_Data_Pourcent_Trainer_result(res.data.message);

                // console.log("### setGetall_Qry_Session_Data_Pourcent_Trainer_result_JSON = ", Getall_Qry_Session_Data_Pourcent_Trainer_result_JSON);

            }
            else {
                setGetall_Qry_Session_Data_Pourcent_Trainer_api("false");
                setGetall_Qry_Session_Data_Pourcent_Trainer_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Session_Data_Pourcent_Trainer = ', error);
            setGetall_Qry_Session_Data_Pourcent_Trainer_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    const [dashbord_internal_code, setdashbord_internal_code] = useState("");

    useEffect(() => {

        setp_filter_date_from(props.user_dashbord_filter_start);
        setp_filter_date_to(props.user_dashbord_filter_end);

        Getall_Qry_Session_Data_Pourcent_Trainer();


        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [p_filter_date_from, p_filter_date_to])


    const [filter_value, setfilter_value] = useState("");
    const [tdb_title, settdb_title] = useState();

    function Export_Dashbord_to_CSV(local_dashbord_internal_id) {

        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_csv.csv";
        console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_Dashbord_To_Csv/" + stored_cookie + "/" + local_dashbord_internal_id;

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);
        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les données (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les données ")

            });

    }

    return (
        <div className="dashbord_session" style={{ "marginLeft": "1px", "marginRight": "2px" }}>
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            {/*<div className="div_row">
                <div className="" style={{ "width": "30%" }}>
                    Filtre <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="filtre11"
                        id="filtre11"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={filter_value}
                        onChange={(e) => {
                            setfilter_value(e.target.value);
                        }
                        }

                    >
                        <MenuItem value="m0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Mois en cours &nbsp;&nbsp;</MenuItem>
                        <MenuItem value="t0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Trimestre en cours &nbsp;</MenuItem>
                        <MenuItem value="d" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Choisir des dates &nbsp;</MenuItem>
                    </TextField>
                </div>

                {filter_value && String(filter_value) === "d" && <div>
                    <div className="session_caract">
                        Date Debut
                        <DatePicker
                            name="one_sessiondatedebut"
                            id="one_sessiondatedebut"

                            selected={new Date(moment(p_filter_date_from, "dd/MM/yyyy"))}
                            onChange={(date) => {
                                setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'

                        />

                    </div>

                    <div className="session_caract">
                        Date Fin
                        <DatePicker
                            name="one_sessiondatefin"
                            id="one_sessiondatefin"
                            selected={new Date(moment(p_filter_date_to, "dd/MM/yyyy"))}
                            onChange={(date) => {
                                setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                        />

                    </div>

                </div>}
                <Button variant="contained" className="bton_enreg" onClick={(e) => {
                    Getall_Qry_Session_Data();
                    Getall_Qry_Session_Data_By_Trainer();
                }}> Afficher
                </Button><br />


            </div>*/}

            <div style={{ "height": "40rem" }}>
                <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                    <nav style={{ "width": "80%", "float": "left", "textAlign": "center" }}> TBD : {props.user_dashbord_title} - Du : {props.user_dashbord_filter_start} au : {props.user_dashbord_filter_end}</nav>
                    <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer de ma liste de TBD">
                        <Popup
                            trigger={
                                <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                    <MdDeleteForever style={{ "cursor": "pointer", }} />
                                </nav>
                            }
                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        En confirmant cette opération, le tableau sera supprimé de votre tableau de bord global.<br />
                                        Vous pourrez le rajouter à nouveau si besoin. <br />

                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {
                                                Remove_From_User_TBD();
                                                //console.log('modal closed ');
                                                close();
                                            }}> Valider </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </a>

                    <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                    <a data-tooltip-id="my-tooltip03" data-tooltip-html="Export Csv/Excel">
                        <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                            <RiFileExcel2Line style={{ "cursor": "pointer", }} onClick={(event) => {
                                Export_Dashbord_to_CSV(props.user_dashbord_id);

                            }} />
                        </nav>
                    </a>

                </div>

                <Box style={{ "color": "black", "height": "30rem" }}>
                    &nbsp;
                    <ResponsivePie
                        data={Getall_Qry_Session_Data_Pourcent_Trainer_result_JSON}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.2
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'ruby'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'c'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'go'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'python'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'scala'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'lisp'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'elixir'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'javascript'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'balde_em'
                                },
                                id: 'lines'
                            }
                        ]}
                        legends={[
                            {
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />

                </Box>
            </div>
        </div>
    )
}


export default Dashbord_Session_Repartition_Fteur;