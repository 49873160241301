import React, { useState, useEffect } from "react";
import UpdateUserInfo from "../components/UpdateUserInfo";
import Button from '@mui/material/Button';

import { AiOutlineEdit } from "react-icons/ai";
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { Button2, Form, FormGroup, Label } from "reactstrap";
import { faBullseye } from "@fortawesome/fontawesome-free-solid";
import tick_ok from "../mysy_img/tick_OK.png";
import tick_ko from "../mysy_img/tick_KO.png";
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import PaymentMethod from "./PaymentMethode";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import fileDownload from 'js-file-download'
import PayementComponent_Carte from "./PaymentMethode_Carte"
import img_logo_stripe from "./../mysy_img/logo-stripe-secure.png";
import SendIcon from '@mui/icons-material/Send';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { RiAddCircleLine } from "react-icons/ri";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";

function Abonnement(props) {

    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [menu, setmenu] = useState("default");
    const [fields1desabled, setfields1desabled] = useState(true);
    const [periode, setperiode] = useState("Mensuel");
    const [testval, settestval] = useState();
    const [gotocheckout, setgotocheckout] = useState("0");
    const [selectedpacks, setselectedpacks] = useState("");
    const [currentpack, setcurrentpack] = useState(props.currentpack);
    const [currentpack2, setcurrentpack2] = useState("");
    const [parnternbformation, setparnternbformation] = useState("");

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: 'num_facture', headerName: 'Num Facture', width: 200,  flex: 1 },
        { field: 'client_nom', headerName: 'Client', width: 200,  flex: 1 },
        { field: 'montant_ht', headerName: 'Montant HT €', width: 200,  flex: 1 },
        { field: 'montant_ttc', headerName: 'Montant TTC €', width: 100,  flex: 1 },
        { field: 'reduction', headerName: 'Discount', width: 150, flex: 1 },
        { field: 'date_facture', headerName: 'Date Facture', width: 150, flex: 1 },
        { field: 'mode_payement', headerName: 'Mode paiement', width: 150, flex: 1 },
        { field: 'date_payement', headerName: 'Date paiement', width: 150, flex: 1 },
        {
            field: "lien_pdf", headerName: 'Télécharger',
            renderCell: (cellValues) => {
                return (

                    <Button

                        onClick={(event) => {
                            handleClick(event, cellValues);
                        }}
                    >
                        <AiOutlineEdit />

                    </Button>

                );
            }
        }

    ]

    function handleClick(event, cellValues) {

        var nom_facture = cellValues.row.num_facture + ".pdf";
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');


        var num_facture = cellValues.row.num_facture;
        var url = process.env.REACT_APP_API_URL + "myclass/api/GetCustomerInvoice/" + stored_cookie + "/" + num_facture;



        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                fileDownload(res.data, nom_facture)
            })
    }



    const [selectionModel, setSelectionModel] = React.useState([]);
    const [rowss, setRows] = useState([]);
    const [tableData, setTableData] = useState([])

    function GetInvoices(event) {

        var form = new FormData();


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_invoice_by_customer/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status']);
                setTableData(data['message']);
                setRows(data['message']);
                if (String(data['status']) === String("false")) {
                    //console.log("erreur rrrr:" + data['status'])
                    setmyApiResponse("False");

                }
                else if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                    return;
                }
                else {
                    setmyApiResponse("True");

                }

            }).catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("false");
            });

    }

    const [paymentmode, setpaymentmode] = useState([]);
    const [paymentmodeapi, setpaymentmodeapi] = useState([]);
    const [paymentmode_nb_carte, setpaymentmode_nb_carte] = useState();

    function GetPayementMode(event) {

        var form = new FormData();


        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_payement_mode/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('LAAAAAAAAA  Success:', data['message'], "data['status'] = ", data['status'], " nb_carte", data['nb_carte']);

                if (String(data['status']) === String("true")) {

                    setpaymentmodeapi("True");
                    setpaymentmode_nb_carte(data['nb_carte']);

                    if (parseInt(data['nb_carte']) > 0 &&
                        data['message'] && data['message'][0] &&
                        Object.keys(data['message'][0]).length > 0) {
                        setpaymentmode(data['message']);
                        //setnew_mode_payement("0");
                    } else {
                        setpaymentmode([]);
                    }


                }
                else if (String(data['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + data['message']);
                    history.push("/Connexion");
                    return;
                }
                else {
                    //console.log("erreur rrrr:" + data['status'])
                    setpaymentmodeapi("True");
                    setpaymentmode_nb_carte('0')


                }


            }).catch((error) => {
                console.error('GetPayementMode : Error:', error);
                setpaymentmodeapi("False");
            });

    }


    function menu_defaut() {
        setmenu("default");
    }

    const [new_mode_payement, setnew_mode_payement] = useState("");
    function menu_paymentmethode() {
        setmenu("paymentmethode");
        setnew_mode_payement("1");
    }

    function menu_paymentmethode_close() {
        setnew_mode_payement("");
    }


    function menu_info() {
        setmenu("info");
        getCurrentUserData();
        GetPayementMode();
    }

    function Clik_bton_mensuel(e) {
        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel")[0].style.opacity = "80%";

        document.getElementsByName("bton_mensuel2")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_mensuel2")[0].style.opacity = "100%";

        document.getElementsByName("bton_annuel2")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_annuel2")[0].style.opacity = "80%";



        setperiode("Mensuel");
    }

    function Clik_bton_annuel(e) {
        document.getElementsByName("bton_annuel")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel")[0].style.opacity = "80%";


        document.getElementsByName("bton_annuel2")[0].style.backgroundColor = "#81BC3A";
        document.getElementsByName("bton_annuel2")[0].style.opacity = "100%";

        document.getElementsByName("bton_mensuel2")[0].style.backgroundColor = "#F8F9F9";
        document.getElementsByName("bton_mensuel2")[0].style.opacity = "80%";



        setperiode("Annuel");
    }


    function menu_plan() {
        setmenu("plan");
    }


    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }

    const [RecordData_api, setRecordData_api] = useState();
    const [RecordData_result, setRecordData_result] = useState();
    const [RecordData_message, setRecordData_message] = useState();

    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";

        var nom = document.getElementsByName("nom")[0].value;
        var vat_num = document.getElementsByName("vat_num")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;

        formData.append('partner_account_id', partner_account_id);
        formData.append('token', stored_part);
        formData.append('invoice_vat_num', vat_num);
        formData.append('invoice_nom', nom);
        formData.append('invoice_adr_street', adr_street);
        formData.append('invoice_adr_city', adr_city);
        formData.append('invoice_adr_zip', adr_zip);
        formData.append('invoice_adr_country', adr_country);
        formData.append('invoice_email', email);
        formData.append('invoice_telephone', telephone);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);

                setRecordData_api(result['status']);
                setmyApiResponse(result['status']);
                setRecordData_result(result['message']);
                if (String(result['status']) === String("true")) {
                    settestval(true);
                    desablefield1();
                }
                else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }



            })
            .catch((error) => {
                console.error('RecordData Error:', error);

                setmyApiResponse("false");
            });


    };

    function enablefieds1() {
        setfields1desabled(false);
        document.getElementsByName("nom")[0].disabled = false;
        document.getElementsByName("nom")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("telephone")[0].disabled = false;
        document.getElementsByName("telephone")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("email")[0].disabled = false;
        document.getElementsByName("email")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_street")[0].disabled = false;
        document.getElementsByName("adr_street")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_zip")[0].disabled = false;
        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_city")[0].disabled = false;
        document.getElementsByName("adr_city")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_country")[0].disabled = false;
        document.getElementsByName("adr_country")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("vat_num")[0].disabled = false;
        document.getElementsByName("vat_num")[0].style.backgroundColor = "#FFFFFF";
    }

    function desablefield1() {
        setfields1desabled(true);
        document.getElementsByName("nom")[0].disabled = true;
        //document.getElementsByName("nom")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("telephone")[0].disabled = true;
        //document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("email")[0].disabled = true;
        // document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_street")[0].disabled = true;
        //document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_zip")[0].disabled = true;
        //document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_city")[0].disabled = true;
        //document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_country")[0].disabled = true;
        //document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("vat_num")[0].disabled = true;
        //document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
    }

    const [getCurrentUserData_result, setgetCurrentUserData_result] = useState([]);
    const [Client_end_date_abonnement, setClient_end_date_abonnement] = useState();
    const [partner_account_id, setpartner_account_id] = useState("");
    const [is_partner_admin_account, setis_partner_admin_account] = useState("");

    function getCurrentUserData() {

        settestval('d'); // ==> Do not delete
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
        formData.append('token', stored_part);

        axios.post(url, formData).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In getCurrentUserData  res.data.status = " + res.data.status);
                //console.log(" In getCurrentUserData  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message);
                setgetCurrentUserData_result(res.data.message)

                var mylocaltraining = JSON.parse(res.data.message);
                if (mylocaltraining) {

                    setpartner_account_id(mylocaltraining._id);

                    if (mylocaltraining.is_partner_admin_account) {
                        setis_partner_admin_account(mylocaltraining.is_partner_admin_account);
                    } else {
                        setis_partner_admin_account("0");
                    }



                    if (mylocaltraining.end_date_abonnement && String(mylocaltraining.end_date_abonnement).length > 3) {
                        setClient_end_date_abonnement(mylocaltraining.end_date_abonnement);
                    }


                    if (mylocaltraining.invoice_vat_num) {
                        document.getElementsByName("vat_num")[0].value = mylocaltraining.invoice_vat_num;
                        document.getElementsByName("vat_num")[0].disabled = true;
                        document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
                    }


                    if (mylocaltraining.invoice_nom) {
                        document.getElementsByName("nom")[0].value = mylocaltraining.invoice_nom;
                        document.getElementsByName("nom")[0].disabled = true;
                        document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_street) {
                        document.getElementsByName("adr_street")[0].value = mylocaltraining.invoice_adr_street;
                        document.getElementsByName("adr_street")[0].disabled = true;
                        document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_city) {
                        document.getElementsByName("adr_city")[0].value = mylocaltraining.invoice_adr_city;
                        document.getElementsByName("adr_city")[0].disabled = true;
                        document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_zip) {
                        document.getElementsByName("adr_zip")[0].value = mylocaltraining.invoice_adr_zip;
                        document.getElementsByName("adr_zip")[0].disabled = true;
                        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_country) {
                        document.getElementsByName("adr_country")[0].value = mylocaltraining.invoice_adr_country;
                        document.getElementsByName("adr_country")[0].disabled = true;
                        document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_email) {
                        document.getElementsByName("email")[0].value = mylocaltraining.invoice_email;
                        document.getElementsByName("email")[0].disabled = true;
                        document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_telephone) {

                        document.getElementsByName("telephone")[0].value = mylocaltraining.invoice_telephone;
                        document.getElementsByName("telephone")[0].disabled = true;
                        document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.pack_service) {
                        setcurrentpack2(String(mylocaltraining.pack_service).toLocaleLowerCase());

                    }

                    if (mylocaltraining.nb_formation) {
                        setparnternbformation(String(mylocaltraining.nb_formation));

                    }

                    desablefield1();

                }
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })



    }


    function menu_facture() {
        setmenu("facture");
        GetInvoices();

    }

    useEffect(() => {
        //menu_info();
        getCurrentUserData();
        GetPayementMode();
        setpackprice();


    }, []);


    function confirmAbonnement_avec_arret_encours(packs) {
        if (String(packs).toLowerCase() === String("decouverte")) {

            if (String(periode) === String("Mensuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("50");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("90");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            if (String(periode) === String("Annuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("600");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("1080");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            setgotocheckout("1");
            setselectedpacks(packs);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            return;
        }

        if (!paymentmode || Object.keys(paymentmode).length <= 0) {
            alert(" Vous devez ajouter un mode de Paiement pour activer de ce pack")
            return;

        } else {


            if (String(periode) === String("Mensuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("50");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("90");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            if (String(periode) === String("Annuel")) {
                if (String(packs).toLowerCase() === String("standard")) {
                    setpackprice("600");
                }
                else if (String(packs).toLowerCase() === String("gold")) {
                    setpackprice("1080");
                }
                else if (String(packs).toLowerCase() === String("decouverte")) {
                    setpackprice("0");
                }
            }

            setgotocheckout("1");
            setselectedpacks(packs);
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }


    function confirmAbonnement(packs) {

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (Client_end_date_abonnement && String(Client_end_date_abonnement).length > 3) {

            confirmAlert({
                title: '',
                message: "Votre abonnement actuel s'arrete le " + Client_end_date_abonnement + ", en confirmant ce nouvel abonnement, votre demande d'arrêt sera annulée.",
                buttons: [
                    {
                        label: 'Oui',
                        onClick: () => { confirmAbonnement_avec_arret_encours(packs); }
                    },
                    {
                        label: 'Non',
                        onClick: () => { return }
                    }
                ]
            });


        } else {

            if (String(packs).toLowerCase() === String("decouverte")) {

                if (String(periode) === String("Mensuel")) {
                    if (String(packs).toLowerCase() === String("standard")) {
                        setpackprice("50");
                    }
                    else if (String(packs).toLowerCase() === String("gold")) {
                        setpackprice("90");
                    }
                    else if (String(packs).toLowerCase() === String("decouverte")) {
                        setpackprice("0");
                    }
                }

                if (String(periode) === String("Annuel")) {
                    if (String(packs).toLowerCase() === String("standard")) {
                        setpackprice("600");
                    }
                    else if (String(packs).toLowerCase() === String("gold")) {
                        setpackprice("1080");
                    }
                    else if (String(packs).toLowerCase() === String("decouverte")) {
                        setpackprice("0");
                    }
                }

                setgotocheckout("1");
                setselectedpacks(packs);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
                return;
            }

            if (!paymentmode || Object.keys(paymentmode).length <= 0) {
                alert(" Vous devez ajouter un mode de Paiement pour activer de ce pack")
                return;

            } else {


                if (String(periode) === String("Mensuel")) {
                    if (String(packs).toLowerCase() === String("standard")) {
                        setpackprice("50");
                    }
                    else if (String(packs).toLowerCase() === String("gold")) {
                        setpackprice("90");
                    }
                    else if (String(packs).toLowerCase() === String("decouverte")) {
                        setpackprice("0");
                    }
                }

                if (String(periode) === String("Annuel")) {
                    if (String(packs).toLowerCase() === String("standard")) {
                        setpackprice("600");
                    }
                    else if (String(packs).toLowerCase() === String("gold")) {
                        setpackprice("1080");
                    }
                    else if (String(packs).toLowerCase() === String("decouverte")) {
                        setpackprice("0");
                    }
                }

                setgotocheckout("1");
                setselectedpacks(packs);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            }
        }
    }


    const [StopAbonnement_api, setStopAbonnement_api] = useState([]);
    const [StopAbonnement_message, setStopAbonnement_message] = useState([]);
    const [StopAbonnement_result, setStopAbonnement_result] = useState([]);
    function StopAbonnement(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/stop_strip_subscription/";


        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {

                //console.log(" In getCurrentUserData  res.data.status = " + data.status);
                //console.log(" In getCurrentUserData  res.data.message = " + data.message);

                if (String(data['status']) === String("false")) {

                    setStopAbonnement_api("false");
                    setStopAbonnement_message(data['message']);

                }
                else if (String(data['status']) === String("true")) {

                    setStopAbonnement_api("true");
                    setStopAbonnement_result(data['message']);
                }

            }).catch((error) => {
                console.error('StopAbonnement Error:', error);
                setStopAbonnement_api("false");
            });

    }


    function confirmSTOPAbonnement(localpacks) {
        confirmAlert({
            title: '',
            message: "Confirmez l'arret de votre abonnement " + String(localpacks),
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { StopAbonnement(); }
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }



    function returntoorder() {
        setgotocheckout("0");

    }

    const [packprice, setpackprice] = useState();

    const [changepartnb_ftion, setchangepartnb_ftion] = useState();

    const [new_nb_formations, setnew_nb_formations] = useState();

    function Change_Partne_Nb_Ftion() {
        setcode_promo_code("");
        setcode_promo_type("");
        setcode_promo_valeur("");
        setcode_promo_text_to_display("");

        sethandleOnblur_Code_Promo_api("");
        sethandleOnblur_Code_Promo_result("");
        sethandleOnblur_Code_Promo_message("");

        setchangepartnb_ftion(true);
        setnew_nb_formations(parnternbformation);
        get_production_service_by_pack();

        get_current_active_class_mysy_and_lms();

        if (String(currentpack2).toLowerCase() === String("standard")) {
            setpackprice("50");
        }
        else if (String(currentpack2).toLowerCase() === String("gold")) {
            setpackprice("90");
        }
        else if (String(currentpack2).toLowerCase() === String("decouverte")) {
            setpackprice("0");
        }


    }

    const change_nb_formations = event => {

        let valeur = event.target.value;


        setnew_nb_formations(event.target.value);

        // document.getElementById('div_nb_formation').style.color = "#104277";
        //document.getElementById('div_ttc').style.color = "#104277";

    }

    function annule_enreg_new_ftion() {
        setcode_promo_code("");
        setcode_promo_type("");
        setcode_promo_valeur("");
        setcode_promo_text_to_display("");

        sethandleOnblur_Code_Promo_api("");
        sethandleOnblur_Code_Promo_result("");
        sethandleOnblur_Code_Promo_message("");
        setchangepartnb_ftion(false);
    }

    const [getpackproducts, setgetpackproducts] = useState();

    const [get_production_service_by_pack_api, setget_production_service_by_pack_api] = useState();
    const [get_production_service_by_pack_result, setget_production_service_by_pack_result] = useState();
    function get_production_service_by_pack() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_product_service/";
        formData.append('token', stored_part);
        formData.append('pack_name', currentpack);

        axios.post(url, formData).then(res => {
            if (res.data.status != "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setget_production_service_by_pack_api("True");
                setget_production_service_by_pack_result(res.data.message);
                setgetpackproducts(res.data.message);
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setget_production_service_by_pack_api("False");
                setgetpackproducts("");
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setget_production_service_by_pack_api("False");
            setgetpackproducts("");
            //setmyApimyApiMessage("")
        })
    }


    const [active_class_in_mysy, setactive_class_in_mysy] = useState();
    const [active_class_in_lms, setactive_class_in_lms] = useState();
    const [get_current_active_class_mysy_and_lms_api, setget_current_active_class_mysy_and_lms_api] = useState();
    const [get_current_active_class_mysy_and_lms_result, setget_current_active_class_mysy_and_lms_result] = useState();
    function get_current_active_class_mysy_and_lms() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Get_Active_Ftions_Mysy_and_Lms/";
        formData.append('token', stored_part);

        axios.post(url, formData).then(res => {
            if (res.data.status != "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.mysy_nb_active_class = " + res.data.mysy_nb_active_class);
                setget_current_active_class_mysy_and_lms_api("true");
                setget_current_active_class_mysy_and_lms_result(res.data.message);


                if (res.data.lms_nb_active_class) {
                    setactive_class_in_lms(res.data.lms_nb_active_class);
                }
                else {
                    setactive_class_in_lms("0");
                }

                if (res.data.mysy_nb_active_class) {
                    setactive_class_in_mysy(res.data.mysy_nb_active_class);
                }
                else {
                    setactive_class_in_mysy("0");
                }



            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setget_current_active_class_mysy_and_lms_api("false");

            }

        }).catch((error) => {
            console.warn('get_current_active_class_mysy_and_lms = ', error);
            setget_current_active_class_mysy_and_lms_api("false");
        })
    }

    const [isLoading, setLoading] = useState();

    const [neworderid, setneworderid] = useState("");
    const [New_Ordervalidation_api, setNew_Ordervalidation_api] = useState();
    const [New_Ordervalidation_message, setNew_Ordervalidation_message] = useState();

    function New_Ordervalidation() {
        //alert(" confirmation ok");


        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("periodicite", "mensuel");

        //form.append("discount", "0");
        form.append("pack", currentpack);
        form.append("discount_code", code_promo_code);
        form.append("discount_type", code_promo_type);
        form.append("discount_valeur", code_promo_valeur);
        form.append("discount_qty", new_nb_formations);


        /*
            /!\ : Important, il faut préciser le nombre d'article envoyé avec ceci :  form.append("nb_product", nb_article);
        */

        form.append("nb_product", "1");


        var prod_service = ""
        getpackproducts.map((service) => (
            prod_service = prod_service + " -- " + JSON.parse(service).designation
        ))
        //alert("prod_ser = " + prod_service);


        form.append("0", JSON.stringify({
            "code": currentpack, "prix": packprice, "comment": "nc", "qty": new_nb_formations,
            "pack_products": prod_service
        }));


        //form.append("1", JSON.stringify({ "code": "art_supp", "prix": "120", "comment": "nc" }));

        setLoading(true);
        var myurl = process.env.REACT_APP_API_URL + "myclass/api/createOrder/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                console.log('Success:', data['message'], "data['status'] = ", data['status'], "ORDER ID = ", data['order_id']);
                if (String(data['status']) === String("true")) {
                    setneworderid(data['order_id']);
                    setNew_Ordervalidation_api("true");
                    setchangepartnb_ftion(false);
                    setparnternbformation(new_nb_formations);

                    // Mise à jour du module LMS si les conditions sont remplies.
                    MySy_LMS_Account_Management();

                    // Mise à jour du plan de payement
                    Update_Payement_Plan_Qty();
                    setLoading(false);

                }
                else {
                    setNew_Ordervalidation_api("false");
                    setNew_Ordervalidation_message(data['message']);
                    setLoading(false);
                }


            }).catch((error) => {
                console.error('Error:', error);
                setneworderid("");
                setNew_Ordervalidation_api("false");
                setLoading(false);

            });
        // setLoading(false);

    }


    // Mise à jour du compte LMs Mysy
    const [MySy_LMS_Account_Management_api, setMySy_LMS_Account_Management_api] = useState("");
    const [MySy_LMS_Account_Management_message, setMySy_LMS_Account_Management_message] = useState("");
    function MySy_LMS_Account_Management() {

        var formData_tpm = new FormData();
        formData_tpm.append('token', stored_part);
        //formData_tpm.append('stripe_payment_id', stripe_pi);
        //formData_tpm.append('packs', packs);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Check_MySy_LMS_Account/";

        axios.post(myurl, formData_tpm).then(res => {
            console.log('In MySy_LMS_Account_Management status = ', res.data.status, "  -- message = ", res.data.message);
            if (String(res.data.status) === String("true")) {
                setMySy_LMS_Account_Management_api("true");

            }
            else {
                setMySy_LMS_Account_Management_api("false")
                setMySy_LMS_Account_Management_message(String(res.data.message))
            }


        }).catch((error) => {
            console.log('In MySy_LMS_Account_Management ', error);
            setMySy_LMS_Account_Management_api("false")

        })

    }

    // Mise à jour du compte LMs Mysy
    const [Update_Payement_Plan_Qty_api, setUpdate_Payement_Qty_Plan_api] = useState("");
    const [Update_Payement_Plan_Qty_message, setUpdate_Payement_Qty_Plan_message] = useState("");
    function Update_Payement_Plan_Qty() {

        var formData_tpm = new FormData();
        formData_tpm.append('token', stored_part);
        formData_tpm.append('qty', new_nb_formations);
        formData_tpm.append('old_qty', parnternbformation);
        formData_tpm.append("discount_code", code_promo_code);
        formData_tpm.append("discount_type", code_promo_type);
        formData_tpm.append("discount_valeur", code_promo_valeur);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/strip_update_subscription_qty/";

        axios.post(myurl, formData_tpm).then(res => {
            console.log('In Update_Payement_Plan status = ', res.data.status, "  -- message = ", res.data.message);
            if (String(res.data.status) === String("true")) {
                setUpdate_Payement_Qty_Plan_api("true");

            }
            else {
                setUpdate_Payement_Qty_Plan_api("false")
                setUpdate_Payement_Qty_Plan_message(String(res.data.message))
            }


        }).catch((error) => {
            console.log('In Update_Payement_Plan_Qty ', error);
            setUpdate_Payement_Qty_Plan_api("false")

        })

    }

    function confirmSNEwAbonnement() {
        confirmAlert({
            title: '',
            message: "Confirmez la modification du nombre de formations",
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => { New_Ordervalidation(); }
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    function make_new_abonnement() {

        //console.log(" old qty = ", parnternbformation, " New qty = ", new_nb_formations);
        if (parnternbformation == new_nb_formations) {
            alert(" Le nombre de formations est identique à l'abonnement actuel.");
            return;
        }

        /*
        Important : 
        Pour reduire le nombre de formation, l'utilisateur doit desactiver les formations dans le LMS et dans mysy
        de sorte à ce que le nouveau nombre de formation acheté ne soit pas inferieurs aux nombres de formations active
        si non le systeme refuse.
        */

        if (active_class_in_lms && parseInt(active_class_in_lms) > parseInt(new_nb_formations)) {
            alert("Vous avez  " + active_class_in_lms + " formation(s) active(s) dans le LMS, vous devez réduire ce nombre à au moins " + new_nb_formations + ".");
            return;
        }

        if (active_class_in_mysy && parseInt(active_class_in_mysy) > parseInt(new_nb_formations)) {

            alert("Vous avez " + active_class_in_mysy + " formation(s) publiée(s) dans le moteur de recherche, vous devez réduire ce nombre à au moins " + new_nb_formations + ".");

            return;
        }

        confirmSNEwAbonnement();
    }

    const [code_promo_code, setcode_promo_code] = useState("");
    const [code_promo_type, setcode_promo_type] = useState("");
    const [code_promo_valeur, setcode_promo_valeur] = useState("");
    const [code_promo_text_to_display, setcode_promo_text_to_display] = useState("");

    const [handleOnblur_Code_Promo_api, sethandleOnblur_Code_Promo_api] = useState("");
    const [handleOnblur_Code_Promo_result, sethandleOnblur_Code_Promo_result] = useState("");
    const [handleOnblur_Code_Promo_message, sethandleOnblur_Code_Promo_message] = useState("");

    const handleOnblur_Code_Promo = (event) => {

        setcode_promo_code("");
        setcode_promo_type("");
        setcode_promo_valeur("");
        setcode_promo_text_to_display("");

        sethandleOnblur_Code_Promo_api("");
        sethandleOnblur_Code_Promo_result("");
        sethandleOnblur_Code_Promo_message("");


        setcode_promo_code(event.target.value);
        //console.log(" ### event.target.value = ", event.target.value);
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Code_Promo/";
        formData.append('token', stored_part);
        formData.append('code_promo', event.target.value);

        axios.post(url, formData).then(res => {
            //console.log(" In getCurrentUserData  res.data.status = " + res.data.status);
            //console.log(" In getCurrentUserData  res.data.message = " + res.data.message);
            if (String(res.data.status) === "true") {

                sethandleOnblur_Code_Promo_api("true");
                sethandleOnblur_Code_Promo_result(res.data.message);

                var mycodepromo_data = JSON.parse(res.data.message);
                if (mycodepromo_data) {
                    //console.log(' mycodepromo_data = ', mycodepromo_data);
                    if (mycodepromo_data.type) {
                        setcode_promo_type(mycodepromo_data.type);
                    }
                    if (mycodepromo_data.valeur) {
                        setcode_promo_valeur(mycodepromo_data.valeur);
                    }

                    if (String(mycodepromo_data.type).toLocaleLowerCase() === "fix") {
                        setcode_promo_text_to_display("- " + mycodepromo_data.valeur + " €")
                    }
                    else if (String(mycodepromo_data.type).toLocaleLowerCase() === "percent") {
                        setcode_promo_text_to_display("- " + mycodepromo_data.valeur + " % ")
                    }

                }
            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
                return;
            }
            else {

                sethandleOnblur_Code_Promo_api("false");
                sethandleOnblur_Code_Promo_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Erro handleOnblur_Code_Promo = ', error);
            sethandleOnblur_Code_Promo_api("false");
        })
    }

    return (
        <div className="abonnement">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            {String(gotocheckout) === String("1") && <div>
                <Button onClick={returntoorder} className="btn_retour_ps"> Retour aux produits & Services </Button>

                <CheckOut packs={selectedpacks} periodicity={periode} mypackprice={packprice} /> </div>

            }


            {String(StopAbonnement_api) === String("true") && <div className="div_row">
                <nav className="okUpdateData"> Votre demande a été bien prise en compte <br /> {StopAbonnement_result} </nav>

            </div>}

            {String(StopAbonnement_api) === String("false") && <div className="div_row">
                <nav className="koUpdateData"> {StopAbonnement_message} </nav>

            </div>}

            {String(gotocheckout) === String("0") && <div>
                <div className="div_row">
                    <div className="div_row_gauche">

                        <div className="titre1"> Votre plan actuel </div>
                        Vous êtes actuellement sur un  plan  : {String(currentpack2).toUpperCase()} <br /><br />

                        <div className="div_row">
                            <div className="div_row_gauche pave_nb_ftion">
                                Nombre de formations   : {String(parnternbformation)} &nbsp;
                                {result && !(JSON.parse(result).end_date_abonnement) && !changepartnb_ftion
                                    && currentpack2
                                    && String(currentpack2).toLowerCase() !== "decouverte"
                                    &&
                                    <Button className="btn_modif_nb_ftion" onClick={Change_Partne_Nb_Ftion} >Modifier  &nbsp; <RiAddCircleLine /> </Button>
                                }
                            </div>
                            {String(New_Ordervalidation_api) === "true" && <div className="div_row okUpdateData">
                                la commande N° {neworderid} a été créée. Vous allez recevoir la confirmation de la commande et la facture par email. <br /> Merci.
                            </div>
                            }

                            {String(New_Ordervalidation_api) === "false" && <div className="div_row koUpdateData">
                                Impossible de créer la commande. {New_Ordervalidation_message}
                            </div>
                            }

                            {changepartnb_ftion && <div className="div_row_gauche pave_change_ftion" >
                                <div style={{ "width": "100%", "float": "left" }} >

                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                        <b><u>   Mesnuel </u> </b>
                                    </div>
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                        {packprice} €
                                    </div>

                                </div>
                                <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                        Nombre :
                                    </div>

                                    <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                        <input
                                            value={new_nb_formations}
                                            onChange={change_nb_formations}
                                            type="number"
                                            min="1"
                                            max="50"
                                            className="nb_ftion"
                                        />
                                    </div>
                                </div>
                                <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                        Total HT :
                                    </div>

                                    <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                        {packprice * new_nb_formations} €
                                    </div>
                                </div>

                                <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <Tooltip className="tooltip_css" id="tooltip_id_1" />
                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left" }} className="block_remise">

                                        <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Saisir le code remise">
                                            Code Remise :
                                            <input type="text" id="discount_val" name="discount_val"
                                                minlength="4" maxlength="20" size="20" style={{ "width": "50%", "float": "right" }}
                                                onBlur={handleOnblur_Code_Promo}>

                                            </input>
                                        </a>

                                        {handleOnblur_Code_Promo_api && String(handleOnblur_Code_Promo_api) === "false" &&
                                            <nav className="koUpdateData">  {handleOnblur_Code_Promo_message} </nav>
                                        }
                                    </div>

                                    {/*<div style={{ "textAlign": "right", "width": "50%", "float": "left" }} className="block_remise">
                                        val_remise_ici
                                    </div>*/}

                                    {(!code_promo_text_to_display || String(code_promo_text_to_display)) === "" &&
                                        <div style={{ "textAlign": "right", "width": "50%", "float": "right" }} className="block_remise">
                                            0 €
                                        </div>
                                    }

                                    {code_promo_text_to_display && String(code_promo_text_to_display) != "" &&

                                        <div style={{ "textAlign": "right", "width": "50%", "float": "right", "color": "green", "fontSize": "bold" }} className="block_remise">
                                            {code_promo_text_to_display}
                                        </div>
                                    }

                                </div>

                                {code_promo_text_to_display && String(code_promo_text_to_display) != "" &&
                                    <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                        <div style={{ "textAlign": "left", "width": "50%", "float": "left", "color": "green", "fontSize": "bold" }}>
                                            Montant Remise :
                                        </div>


                                        {String(handleOnblur_Code_Promo_api) === "true" &&
                                            <div style={{ "textAlign": "right", "width": "50%", "float": "right", "color": "green", "fontSize": "bold" }}>
                                                {String(code_promo_type) === "fix" && <nav>
                                                    -  {(((packprice * new_nb_formations) - parseFloat(code_promo_valeur))).toFixed(2)} €
                                                </nav>}

                                                {String(code_promo_type) === "percent" && <nav>
                                                    -  {((((packprice * new_nb_formations) * ((parseFloat(code_promo_valeur) / 100))))).toFixed(2)} €
                                                </nav>}
                                            </div>}

                                    </div>
                                }

                                <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                        TVA (20%):
                                    </div>

                                    {String(handleOnblur_Code_Promo_api) !== "true" && <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                        {(packprice * new_nb_formations * 0.2).toFixed(2)} €
                                    </div>}
                                    {String(handleOnblur_Code_Promo_api) === "true" &&
                                        <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                            {String(code_promo_type) === "fix" && <nav>
                                                {(((packprice * new_nb_formations) - parseFloat(code_promo_valeur)) * 0.2).toFixed(2)} €
                                            </nav>}

                                            {String(code_promo_type) === "percent" && <nav>
                                                {((((packprice * new_nb_formations) * (1 - (parseFloat(code_promo_valeur) / 100)))) * 0.2).toFixed(2)} €
                                            </nav>}
                                        </div>}

                                </div>

                                <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                        Total TTC  :
                                    </div>


                                    {String(handleOnblur_Code_Promo_api) !== "true" &&
                                        <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                            {(packprice * new_nb_formations * 1.2).toFixed(2)} €
                                        </div>
                                    }
                                    {String(handleOnblur_Code_Promo_api) === "true" &&
                                        <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>

                                            {String(code_promo_type) === "fix" && <nav>
                                                {(((packprice * new_nb_formations) - parseFloat(code_promo_valeur)) * 1.2).toFixed(2)} €
                                            </nav>}

                                            {String(code_promo_type) === "percent" && <nav>
                                                {((((packprice * new_nb_formations) * (1 - (parseFloat(code_promo_valeur) / 100)))) * 1.2).toFixed(2)} €
                                            </nav>}

                                        </div>
                                    }


                                </div>

                                <div style={{ "width": "100%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <div className="pave_btn_enreg_new_nb_ftion" >
                                        <Button className="btn_enreg_new_nb_ftion" onClick={make_new_abonnement} >Enregistrer </Button>
                                    </div>

                                    <div className="pave_btn_annule_new_nb_ftion">
                                        <Button className="btn_annule_new_nb_ftion" onClick={annule_enreg_new_ftion} >Annuler  </Button>
                                    </div>
                                </div>


                            </div>}
                        </div>

                        <br />
                        {result && (JSON.parse(result).end_date_abonnement) &&
                            <nav style={{ "color": "red" }}>Cet abonnement expire le {(JSON.parse(result).end_date_abonnement)} </nav>}

                        <div style={{ "marginBottom": "2rem" }}> &nbsp; </div>
                        <div className="titre1"> Mode de Paiement </div>


                        {paymentmode && Object.keys(paymentmode).length > 0 && paymentmode.map((payment) => (

                            <ul className="list_produits_service">
                                <div>


                                    <ul>
                                        Type carte :  {(JSON.parse(payment).brand)}
                                    </ul>
                                    <ul>
                                        Nom carte :  {(JSON.parse(payment).nom_carte)}
                                    </ul>
                                    <ul>
                                        Num carte :  XXXXXX{(JSON.parse(payment).last4)}
                                    </ul>
                                    <ul>
                                        Expiration :  {(JSON.parse(payment).exp_month)}/{(JSON.parse(payment).exp_year)}
                                    </ul>
                                    <ul>
                                        Date Creation :  {(JSON.parse(payment).createdatetime)}
                                    </ul>


                                </div>

                            </ul>


                        ))}


                        {String(paymentmodeapi) === String("True") && String(paymentmode_nb_carte) === "0" && <div>
                            <div className="warninigUpdateData">
                                Aucun mode de paiement enregistré.
                            </div>
                            <div className="okUpdateData">
                                Profitez du pack découverte gratuitement sans moyen de paiement, pendant 15 jours.
                            </div>

                        </div>}

                        {String(paymentmodeapi) === String("True") && <div>


                            {paymentmode && Object.keys(paymentmode).length > 0 && paymentmode.map((payment) => (
                                <ul className="list_produits_service">
                                    <div>  {String(JSON.parse(payment).type) === String("cb") &&
                                        <b> Carte Bleue </b>} </div>

                                    <div>  {String(JSON.parse(payment).type) === String("sepa") &&
                                        <b> SEPA </b>} </div>


                                    {String(JSON.parse(payment).type) === String("sepa") && <div>
                                        <ul>
                                            BIC :  {JSON.parse(payment).bic}
                                        </ul>
                                        <ul>
                                            IBAN : {(JSON.parse(payment).iban).substring(0, 4)}XXXXXXXX
                                        </ul>
                                        <ul>
                                            Titulaire du compte : {JSON.parse(payment).nom_compte}
                                        </ul>
                                        <ul>
                                            Dernière mise à jour :  {(JSON.parse(payment).date_update).substring(0, 10)}
                                        </ul>

                                    </div>}

                                    {String(JSON.parse(payment).type) === String("cb") && <div>
                                        <ul>
                                            Num carte :  {(JSON.parse(payment).num_carte).substring(0, 10)}XXX
                                        </ul>
                                        <ul>
                                            Date expiration : {JSON.parse(payment).date_exp_carte}
                                        </ul>
                                        <ul>
                                            Titulaire de la carte : {JSON.parse(payment).nom_carte}
                                        </ul>
                                        <ul>
                                            Dernière mise à jour :  {(JSON.parse(payment).date_update).substring(0, 10)}
                                        </ul>
                                    </div>}

                                </ul>


                            ))}

                            {paymentmode && Object.keys(paymentmode).length > 0 &&
                                String(new_mode_payement) !== "1" && <div className="div_row_central">
                                    <img src={img_logo_stripe} className="img_payment" /> &nbsp;
                                    <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={menu_paymentmethode}>Editer</Button>
                                </div>}

                            {/*  new_mode_payement = {new_mode_payement} <br />
                            paymentmode && Object.keys(paymentmode).length > 0 &&
                                String(new_mode_payement) === "1" &&
                                <div style={{
                                    "width": "10%", "marginLeft": "auto", "marginLeft": "auto", "marginRight": "auto", "textAlign": "center",
                                    "marginTop": "1rem", "cursor": "pointer"
                                }}
                                    onClick={menu_paymentmethode}>  <IoAddCircleOutline /><br />Ajouter aa</div>
                           */ }

                            {(!paymentmode || Object.keys(paymentmode).length <= 0) &&
                                String(new_mode_payement) !== "1" &&

                                <div style={{
                                    "width": "10%", "marginLeft": "auto", "marginLeft": "auto", "marginRight": "auto", "textAlign": "center",
                                    "marginTop": "1rem", "cursor": "pointer"
                                }}
                                    onClick={menu_paymentmethode}>  <IoAddCircleOutline /><br />Ajouter</div>
                            }


                            {String(menu) === "paymentmethode" && String(new_mode_payement) === "1" && <div style={{ "width": "90%" }}>
                                <div style={{
                                    "width": "10%", "marginLeft": "auto", "marginLeft": "auto", "marginRight": "auto", "textAlign": "center",
                                    "marginTop": "1rem", "cursor": "pointer", "marginBottom": "1rem"
                                }}
                                    onClick={menu_paymentmethode_close}>  <IoCloseCircleOutline /><br />Fermer </div>
                                <PayementComponent_Carte currentpack={String(currentpack).toUpperCase()} />

                            </div>

                            }

                        </div>}

                    </div>




                    <div className="div_row_droite">

                        <div className="titre1"> Detail de Facturation </div>
                        {String(testval) === String("true") && <div className="okUpdateData"> La modification a ete prise en compte </div>}

                        {String(testval) === String("false") && <div className="koUpdateData"> Impossible de modifier les informations </div>}

                        &nbsp;
                        <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                            <TextField
                                required
                                disabled
                                label="Raison sociale"
                                name="nom"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                            <TextField

                                disabled
                                label="num TVA"
                                name="vat_num"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            <br />

                            <TextField
                                disabled
                                name="telephone"
                                label="Téléphone"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />


                            <TextField
                                name="email"
                                disabled
                                label="Adresse mail"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />


                            <TextField
                                name="adr_street"
                                disabled
                                label="Adresse"
                                className="texte_area_adress"
                                sx={{ m: 1, width: '96%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            <br />
                            <TextField
                                name="adr_zip"
                                disabled
                                label="Code Postal"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                            <TextField
                                name="adr_city"
                                disabled
                                label="Ville"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            <br />
                            <TextField
                                name="adr_country"
                                label="Pays"
                                disabled
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                            <br />


                            <div className="div_row22">
                                {fields1desabled == false && <div className="div_row">
                                    <div className="div_row_gauche">
                                        <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
                                    </div>
                                    <div className="div_row_droite">
                                        <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={getCurrentUserData}>Annuler</Button>
                                    </div>
                                </div>

                                }
                                {fields1desabled == true &&
                                    <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablefieds1}>Editer</Button>
                                }

                            </div>
                        </Box>
                    </div>
                </div>
                <hr style={{ "width": "100%" }} />
                <div className="div_row">
                    <div className="produitsservices">
                        <div className="critere">
                            <div className="critere_gauche">

                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="inputGroup-sizing-sm3">Devise  </span>
                                    <select className="form-select form-select-sm limite_input" onChange={"ChangeDevise"} aria-label=".form-select-sm example"
                                        id={"devise"} name={"devise"}  >
                                        <option selected value="euro">€</option>
                                        {/*<option value="dollard">$ US</option>*/}

                                    </select>
                                </div>
                            </div>
                            <div className="critere_droite">
                                <span className="span1"> Periodicité &nbsp; </span>
                                <button
                                    onClick={Clik_bton_mensuel}
                                    className="bton_radius_gauche"
                                    id="bton_mensuel" name="bton_mensuel">Mensuelle
                                </button>


                                <button
                                    onClick={Clik_bton_annuel}
                                    className="bton_radius_droite"
                                    id="bton_annuel" name="bton_annuel">Annuelle
                                </button>


                            </div>

                        </div>


                        <div className="div_row">
                            <div className="div_row_description">
                                <div className="div_basique_header">  &nbsp;
                                </div>
                                <div style={{ "marginBottom": "1rem", "background": "#E9ECEF" }}>
                                    Tarifs 
                                </div>
                                <div className="produits_service_label height_publication_header">
                                    <b> Marketplace </b>
                                </div>

                                <div className="produits_service_label height_publication">
                                    <b> Publication des
                                        formations </b>

                                    <ul>
                                        <li>Mise en ligne de votre formation
                                            (Description, Objectif,
                                            Programme, etc).</li>
                                        <li>Mise en forme des textes selon
                                            votre souhait. </li>
                                        <li>Définition du pays et la région
                                            de
                                            publication</li>
                                        <li>Import/Export Excel</li>
                                    </ul>
                                </div>


                                <div className="produits_service_label height_price_1">
                                    <b> Gestion des prix </b>
                                    <ul>
                                        <li>Remise client / Tout public par période.
                                        </li>
                                    </ul>

                                </div>

                                <div className="produits_service_label height_affichage_ftion">
                                    <b> Personnalisation de l'affichage d'une formation </b>
                                    <ul>
                                        <li>Ajout de vos propres images pour communiquer sur votre structure et votre marque.
                                        </li>
                                        <li>Affichage de votre logo à côté de la formation</li>
                                        <li>Affichage de votre bannière en grand sur la page détaillée.</li>

                                    </ul>
                                </div>
                                <div className="produits_service_label height_leads">
                                    <b> Génération automatique de leads </b>

                                    <ul>
                                        <li>L’intérêt des stagiaires génère des leads qui vous sont envoyés automatiquement.
                                        </li>


                                    </ul>
                                </div>



                                <div className="produits_service_label height_appel_offre">
                                    <b>Appels d’offre </b>

                                    <ul>
                                        <li>Consultation des appels d’offre de formation pour manifestation d’intérêt.</li>
                                        <li>Publication d’un appel d’offre de formation et gestion des réponses.</li>

                                    </ul>
                                </div>
                                <div className="produits_service_label height_publication_header">
                                    <b> Gestion Administrative & Pédagogique </b>
                                </div>
                                <div className="produits_service_label height_gestion_admin">
                                    <b>Gestion administrative</b>

                                    <ul>
                                        <li>Publication catalogue formations (intranet / API)</li>
                                        <li>Gestion des Inscriptions (mise en attente, motif annulation, etc) </li>
                                        <li>Gestion des Sessions (planifiée, à la demande)</li>
                                        <li>Gestion avis participants</li>
                                        <li>Gestion des employés et du matériel pédagogique</li>
                                        <li>Émargement électronique</li>


                                    </ul>
                                </div>
                                <div className="produits_service_label height_gestion_crm">
                                    <b>Gestion relation client (CRM) </b>

                                    <ul>
                                        <li>Gestion clients</li>
                                        <li>Affectation et Suivi tâches</li>
                                        <li>Catalogue prix clients</li>
                                        <li>Devis & Commandes (envoi électronique) </li>
                                        <li>Facturation</li>

                                    </ul>
                                </div>

                                <div className="produits_service_label height_publication_header">
                                    <b> Hébergement de formations (LMS) </b>
                                </div>


                                <div className="produits_service_label height_intranet">
                                    <b>Intranet personnalisé</b>

                                    <ul>
                                        <li>Mise en place d’un site web (intranet) personnalisé.</li>

                                    </ul>
                                </div>
                                <div className="produits_service_label height_e_learning_persona">
                                    <b>Plate-forme de E-learning personnalisée</b>

                                    <ul>
                                        <li>Nom de domaine personnalisé</li>
                                        <li>Personnalisation de la charte graphique (couleurs & bannière) </li>

                                    </ul>

                                </div>

                                <div className="produits_service_label height_e_learning">
                                    <b>Système de E-learning</b>

                                    <ul>
                                        <li>Echange prof / apprenant (courriel interne)</li>
                                        <li>Espace apprenant</li>
                                        <li>Evaluation (note)</li>
                                        <li>Gestion planning</li>
                                        <li>Travaux dirigés & pratiques</li>
                                        <li>Session live virtuelle</li>

                                    </ul>
                                </div>



                            </div>

                            <div className="div_row_basique">
                                <div className="div_basique_header">  Découverte </div>
                                <hr style={{ "margin": "2px" }} />
                                <div style={{ "marginBottom": "0px", "fontSize": "small" }}>
                                    Pour chaque Formation
                                </div>

                                <div className="produits_service_label height_publication_header">
                                    &nbsp;
                                </div>

                                <div className="produits_service_tick height_publication">

                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick height_price_1">
                                    <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick height_affichage_ftion">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick height_leads">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick height_appel_offre">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_label height_publication_header">
                                    &nbsp;
                                </div>


                                <div className="produits_service_tick height_gestion_admin">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick height_gestion_crm">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_label height_publication_header">
                                    &nbsp;
                                </div>


                                <div className="produits_service_tick height_intranet">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>
                                <div className="produits_service_tick height_e_learning_persona">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>

                                <div className="produits_service_tick height_e_learning">
                                    <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                                </div>


                                <div className="div_row_basique_price">
                                    Gratuit 15 jours
                                </div>
                                <div className="div_row_basique_price">
                                    &nbsp;
                                </div>

                                {String(currentpack2).toLowerCase() !== "decouverte" && <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement"
                                        onClick={(e) => {
                                            confirmAbonnement("Decouverte");
                                        }}
                                    > Activer ce pack  </Button>
                                </div>}

                                {String(currentpack2).toLowerCase() === String("decouverte") && (!JSON.parse(result).end_date_abonnement) &&
                                    <div className="abonnement">
                                        <Button variant="contained"
                                            color="success"
                                            className="btn_arret_abonnement"
                                            onClick={(e) => {
                                                confirmSTOPAbonnement("decouverte");
                                            }}
                                        > Arreter  </Button>
                                    </div>}

                                {String(currentpack2).toLowerCase() === String("decouverte") && (JSON.parse(result).end_date_abonnement) &&
                                    <div className="abonnement">
                                        <Button variant="contained"
                                            color="success"
                                            className="btn_arret_abonnement" style={{ "cursor": "none" }}

                                        > Arrêt le {(JSON.parse(result).end_date_abonnement)} </Button>
                                    </div>}

                            </div>

                            <div className="div_row_standard">
                        <div className="div_standard_header"> Standard </div>
                        <hr style={{ "margin": "0.2rem" }} />
                        <div style={{ "marginBottom": "0.8rem", "fontSize": "small" }}>
                            Pour chaque Formation
                        </div>
                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_publication">

                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_price_1">
                        <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_affichage_ftion">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_leads">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_appel_offre">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_gestion_admin">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_gestion_crm">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_intranet">
                            <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_e_learning_persona">
                        <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_e_learning">
                        <img src={tick_ko} alt="KO" className="tick_ok_ko" />
                        </div>


                        <div className="div_row_standard_price">
                            50 HT €/mois

                        </div>

                        <div className="div_row_standard_price">
                            &nbsp;
                        </div>

                        {String(currentpack2).toLowerCase() !== "standard" && <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement"
                                        onClick={(e) => {
                                            confirmAbonnement("Standard");
                                        }}> Activer ce pack  </Button>
                                </div>}

                                {String(currentpack2).toLowerCase() === String("standard") && (!JSON.parse(result).end_date_abonnement) && <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_arret_abonnement"
                                        onClick={(e) => {
                                            confirmSTOPAbonnement("standard");
                                        }}
                                    > Arreter  </Button>
                                </div>}


                                {String(currentpack2).toLowerCase() === String("standard") && (JSON.parse(result).end_date_abonnement) &&
                                    <div className="abonnement">
                                        <Button variant="contained"
                                            color="success"
                                            className="btn_arret_abonnement" style={{ "cursor": "none" }}

                                        > Arrêt le {(JSON.parse(result).end_date_abonnement)} </Button>
                                    </div>}

                    </div>

                    <div className="div_row_gold">
                        <div className="div_gold_header">   Gold </div>
                        <hr style={{ "margin": "0.2rem" }} />
                        <div style={{ "marginBottom": "0.8rem", "fontSize": "small" }}>
                            Pour chaque Formation
                        </div>
                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>

                        <div className="produits_service_tick height_publication">

                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_price_1">
                            <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_affichage_ftion">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_leads">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_appel_offre">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_gestion_admin">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_gestion_crm">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_label height_publication_header">
                            &nbsp;
                        </div>


                        <div className="produits_service_tick height_intranet">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>
                        <div className="produits_service_tick height_e_learning_persona">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>

                        <div className="produits_service_tick height_e_learning">
                        <img src={tick_ok} alt="OK" className="tick_ok_ko" />
                        </div>


                        <div className="div_row_gold_price">
                            90 € HT /mois

                        </div>

                        <div className="div_row_gold_price">
                            &nbsp;
                        </div>

                        {String(currentpack2).toLowerCase() !== "gold" && <div className="abonnement">
                                    <Button variant="contained"
                                        color="success"
                                        className="btn_abonnement"
                                        onClick={(e) => {
                                            confirmAbonnement("Gold");
                                        }}
                                    > Activer ce pack  </Button>
                                </div>}

                                {String(currentpack2).toLowerCase() === String("gold") && (!JSON.parse(result).end_date_abonnement) &&
                                    <div className="abonnement">
                                        <Button variant="contained"
                                            color="success"
                                            className="btn_arret_abonnement"
                                            onClick={(e) => {
                                                confirmSTOPAbonnement("Gold");
                                            }}
                                        > Arreter  </Button>
                                    </div>}

                                {String(currentpack2).toLowerCase() === String("gold") && (JSON.parse(result).end_date_abonnement) &&
                                    <div className="abonnement">
                                        <Button variant="contained"
                                            color="success"
                                            className="btn_arret_abonnement" style={{ "cursor": "none" }}

                                        > Arrêt le {(JSON.parse(result).end_date_abonnement)} </Button>
                                    </div>}


                    </div>



                            {<div style={{ "width": "1%" }}> &nbsp;</div>}

                        </div>


                    </div>
                </div>

            </div>}
        </div>
    );

}

export default Abonnement;