import SeDesabonner from "../components/Sedesabonner";
import Navigation from "../components/Navigation";

const recherche_draft = () => {


  return (
    <div>
      <Navigation />
      <div>
        <SeDesabonner />
      </div>

    </div>

  )
}


export default recherche_draft;
