import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { tokens } from "./../theme";
import { MdOutlineAddchart, } from "react-icons/md";
import {
    useTheme,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from "@nivo/bar";

import { TbColumns2, TbColumns1 } from "react-icons/tb";
import { Stroller } from "@mui/icons-material";
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";

const Dashbord_Ressources_Humaines = (props) => {

    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);
    const [selectionModel, setSelectionModel] = React.useState([]);


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'civilite', headerName: 'Civ.', hide: true, minWidth: 100, flex: 1, maxWidth: 60, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'email', headerName: 'Email', hide: true, flex: 1, minWidth: 250, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'ismanager', headerName: 'Manager', hide: true, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'fonction', headerName: 'Fonction', hide: true, minWidth: 200, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} /> },

        { field: 'agenda_event_title', headerName: 'Titre Event', hide: false, minWidth: 250, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_code_session', headerName: 'Code session', hide: false, minWidth: 250, flex: 1,  renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_type', headerName: 'Type Event', hide: false, minWidth: 150, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'event_duration_hour', headerName: 'Durée (h)', hide: false, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_date_jour', headerName: 'Date', hide: false, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_start', headerName: 'Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_end', headerName: 'Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_sequence_session_id', headerName: 'agenda_id', hide: true, maxWidth: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
    ]


    const [selectionModel_task_cost, setSelectionModel_task_cost] = React.useState([]);
    const columns_task_cost = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'rh_civilite', headerName: 'Civ.', hide: true, minWidth: 100, flex: 1, maxWidth: 60 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 200 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_email', headerName: 'Email', hide: true, flex: 1, minWidth: 250 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_ismanager', headerName: 'Manager', hide: true, minWidth: 50, flex: 1, maxWidth: 100 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_fonction', headerName: 'Fonction', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_telephone_mobile', headerName: 'Mobile', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_adr_adresse', headerName: 'Adesse', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_adr_code_postal', headerName: 'CP', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_adr_ville', headerName: 'Ville', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_adr_pays', headerName: 'Pays', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},


        { field: 'rh_contrat_date_debut', headerName: 'Ctrat Du', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_date_fin', headerName: 'Ctrat Au', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_type_contrat', headerName: 'Ctrat Type', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_type_employe', headerName: 'Ctrat Empl.', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_cout', headerName: 'Ctrat Cout', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_periodicite', headerName: 'Ctrat Périodicité', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_quantite', headerName: 'Ctrat Qte', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},

        { field: 'rh_contrat_groupe_prix_achat_id', headerName: 'Grp Prix ID', hide: true, Width: 0,  renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_groupe_prix_achat_code', headerName: 'Grp Prix Code', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},

        { field: 'rh_contrat_groupe_prix_achat_cout', headerName: 'Grp Prix Cout', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_contrat_groupe_prix_achat_periodicite', headerName: 'Grp Prix Périodicité', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},


        { field: 'rh_event_planning_event_title', headerName: 'Planning Titre', hide: false, minWidth: 200, flex: 1,  renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_even_comment', headerName: 'Planning Comment', hide: true, minWidth: 100, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_event_planning_event_type', headerName: 'Planning Type', hide: false, minWidth: 150, flex: 1, maxWidth: 100 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_event_planning_event_duration_hour', headerName: 'Planning Durée (h)', hide: false, minWidth: 100, flex: 1, maxWidth: 100 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_event_planning_agenda_date_jour', headerName: 'Planning Date', hide: true, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_event_planning_event_start', headerName: 'Planning Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_event_planning_event_end', headerName: 'Planning Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
        { field: 'rh_event_planning_event_cost', headerName: 'Planning Cout Total (HT)', hide: false, minWidth: 150, flex: 1, maxWidth: 150 , renderCell: (params) => <ExpandableCell_50 {...params} />,},
 
    ]


    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));


    const [filter_value, setfilter_value] = useState("d");

    const [filter_type_tache, setfilter_type_tache] = useState("");

    const [tdb_title, settdb_title] = useState();

    const [display_nb_column, setdisplay_nb_column] = useState("1");
    const [display_column_size, setdisplay_column_size] = useState("100%");





    const [Getall_Qry_RH_Data_result_JSON, setGetall_Qry_RH_Data_result_JSON] = useState([]);
    const [Getall_Qry_RH_Data_api, setGetall_Qry_RH_Data_api] = useState();
    const [Getall_Qry_RH_Data_message, setGetall_Qry_RH_Data_message] = useState();
    const [Getall_Qry_RH_Data_result, setGetall_Qry_RH_Data_result] = useState([]);
    function Getall_Qry_RH_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);
        if (selected_employee_id && String(setselected_employee_id).length > 5)
            form.append("tab_rh_id", selected_employee_id);
        else
            form.append("tab_rh_id", "all");

        form.append("event_type", filter_type_tache);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Humain_Ressource_With_Planning/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_RH_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_RH_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_RH_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_RH_Data_api("true");
                setGetall_Qry_RH_Data_result(res.data.message);

                var local_total_duration = "0";
                const new_data = res.data.message.map((x) => {
                    if (JSON.parse(x).total_duration) {
                        console.log(" DUREATION TOTAL = ", JSON.parse(x).total_duration)
                    }

                });


            }
            else {
                setGetall_Qry_RH_Data_api("false");
                setGetall_Qry_RH_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_RH_Data = ', error);
            setGetall_Qry_RH_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Qry_RH_Task_Cost_Data_result_JSON, setGetall_Qry_RH_Task_Cost_Data_result_JSON] = useState([]);
    const [Getall_Qry_RH_Task_Cost_Data_api, setGetall_Qry_RH_Task_Cost_Data_api] = useState();
    const [Getall_Qry_RH_Task_Cost_Data_message, setGetall_Qry_RH_Task_Cost_Data_message] = useState();
    const [Getall_Qry_RH_Task_Cost_Data_result, setGetall_Qry_RH_Task_Cost_Data_result] = useState([]);
    function Getall_Qry_RH_Task_Cost_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);
        if (selected_employee_id && String(setselected_employee_id).length > 5)
            form.append("tab_rh_id", selected_employee_id);
        else
            form.append("tab_rh_id", "all");

        form.append("event_type", filter_type_tache);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Humain_Ressource_With_Planning_And_Cost/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_RH_Task_Cost_Data  res.data.status = " + res.data.status);
                // console.log(" In Getall_Qry_RH_Task_Cost_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_RH_Task_Cost_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');
                //console.log(" ### Getall_Qry_RH_Task_Cost_Data -jsonObj  = ", jsonObj)

                setGetall_Qry_RH_Task_Cost_Data_api("true");
                setGetall_Qry_RH_Task_Cost_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_RH_Task_Cost_Data_api("false");
                setGetall_Qry_RH_Task_Cost_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_RH_Task_Cost_Data = ', error);
            setGetall_Qry_RH_Task_Cost_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [dashbord_internal_code, setdashbord_internal_code] = useState("");
    const [gridline_id, setgridline_id] = useState("");

    const [Add_To_User_TBD_api, setAdd_To_User_TBD_api] = useState();
    const [Add_To_User_TBD_message, setAdd_To_User_TBD_message] = useState();
    const [Add_To_User_TBD_result, setAdd_To_User_TBD_result] = useState();
    function Add_To_User_TBD(local_dashbord_internal_code) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        //console.log(" ### dashbord_internal_code = ", local_dashbord_internal_code);

        var tdb_title = "default";
        var filter_val = "";
        var filter_type_event = "";


        if (document.getElementsByName("tdb_title")[0]) {
            tdb_title = document.getElementsByName("tdb_title")[0].value;
        }

        if (filter_value) {
            filter_val = filter_value
        }

        if (filter_type_tache) {
            filter_type_event = filter_type_tache
        }

        // Si filter_val =  "d", alors on va recuperer l'interval de date
        if (String(filter_val) === "d") {
            var date_from = p_filter_date_from;
            var date_to = p_filter_date_to;


            filter_val = "{'session_start_date':'" + String(date_from) + "' , 'session_end_date':'" + String(date_to) + "'}";
        } else {
            filter_val = "{'periode':'" + String(filter_val) + "'}"
        }

        form.append("token", stored_cookie);
        form.append("dashbord_internal_code", local_dashbord_internal_code);
        form.append("default_filter", filter_val);
        form.append("title", tdb_title);
        form.append("type_event", filter_type_event);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_To_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Add_To_User_TBD  res.data.message r_class = " + res.data.message);

                setAdd_To_User_TBD_api("true");
                setAdd_To_User_TBD_result(res.data.message);
                settdb_title();
                alert(res.data.message)


            }
            else {
                setAdd_To_User_TBD_api("false");
                setAdd_To_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Add_To_User_TBD = ', error);
            setAdd_To_User_TBD_api("false");
            alert("Impossible d'ajouter le tableau de bord");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [selected_employee_id, setselected_employee_id] = useState("");


    useEffect(() => {
        Getall_Qry_RH_Data();
        Getall_Qry_RH_Task_Cost_Data();
        Getall_Training_Employee_No_Filter();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])



    const New_option_type_planing = [
        { "id": "absence", "label": "Absence", "value": "absence" },
        { "id": "planning", "label": "Planning", "value": "planning" },
        { "id": "autre", "label": "Autre", "value": "autre" },
        { "id": "", "label": "", "value": "" },
    ]

    const New_option_periode = [
        { "id": "m0", "label": "Mois en cours", "value": "m0" },
        { "id": "m1", "label": "Mois précédant ", "value": "m1" },
        { "id": "d", "label": "Choisir des dates", "value": "d" },

    ]


    return (
        <div className="dashbord_session">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Tableaux de bord des ressources humaines (Employés) </h3>
            <div className="div_row"> &nbsp;</div>
            <div className="div_row" style={{ "textAlign": "right", "paddingRight": "20px" }}> Affichage &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_1_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_1_col" data-tooltip-html="Affichage sur une colonne">
                    <TbColumns1 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("1");
                        setdisplay_column_size("100%");

                    }} />
                </a>  &nbsp; &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_2_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_2_col" data-tooltip-html="Affichage sur deux colonnes">
                    <TbColumns2 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("2");
                        setdisplay_column_size("50%");

                    }} />
                </a> </div>

            <div style={{ "width": "100%", "float": "left" }}>
                <div className="" style={{ "width": "50%", "float": "left" }}>
                    Filtre <br />
                    <Autocomplete
                        disablePortal
                        name="filtre11"
                        id="filtre11"
                        className="disabled_style"
                        options={New_option_periode}
                        onChange={(event, value) => {
                            if (value && value.value) {
                                setfilter_value(value.value);

                            } else {
                                setfilter_value("");
                            }
                        }}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une période"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />


                </div>

                {filter_value && String(filter_value) === "d" &&

                    <div style={{ "width": "100%", "float": "left" }}>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Debut
                                <DatePicker
                                    name="one_sessiondatedebut"
                                    id="one_sessiondatedebut"

                                    selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                    locale='fr-FR'

                                />

                            </div>
                        </div>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Fin
                                <DatePicker
                                    name="one_sessiondatefin"
                                    id="one_sessiondatefin"
                                    selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style enable_style"
                                />

                            </div>
                        </div>

                    </div>}
                <br />

                <div style={{ "width": "100%", "float": "left" }}>
                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Type Tache <br />

                        <Autocomplete
                            disablePortal
                            name="filtre12"
                            id="filtre12"
                            className="disabled_style"
                            options={New_option_type_planing}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setfilter_type_tache(value.value);


                                } else {
                                    setfilter_type_tache("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />



                    </div>

                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Employé <br />

                        <Autocomplete
                            disablePortal
                            name="employee_id"
                            id="employee_id"
                            className="disabled_style"
                            options={New_Getall_Training_Employee_No_Filter_result}
                            onChange={(event, value) => {
                                if (value && value._id) {
                                    setselected_employee_id(value._id);

                                } else {
                                    setselected_employee_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>
                </div>

                <div className="div_row">
                    <br />
                    <Button variant="contained" className="bton_enreg" onClick={(e) => {
                        Getall_Qry_RH_Data();
                        Getall_Qry_RH_Task_Cost_Data();

                    }}> Afficher
                    </Button><br />
                </div>

            </div>


            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>


                <div style={{ "float": "left", "width": String(display_column_size),"float": "left", "paddingRight": "5px" }} >
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Employés - Détail Tâche </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("3")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "3" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("RESSOURCES_HUMAINES_01")
                            Add_To_User_TBD("tbd_rh_01");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}

                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_session_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /*  setaddOneParticipant();
                                                      setdisplay_detail_insc();
                                                      setselected_code_session();
                                                      setsubmenu();*/
                                                }
                                            }}
                                            selectionModel={selectionModel}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Qry_RH_Data_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    civilite: JSON.parse(item).civilite,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    email: JSON.parse(item).email,
                                                    ismanager: JSON.parse(item).ismanager,
                                                    fonction: JSON.parse(item).fonction,
                                                    agenda_event_title: JSON.parse(item).agenda_event_title,
                                                    agenda_event_code_session: JSON.parse(item).agenda_event_code_session,
                                                    agenda_event_type: JSON.parse(item).agenda_event_type,
                                                    agenda_date_jour: JSON.parse(item).agenda_date_jour,
                                                    agenda_event_start: JSON.parse(item).agenda_event_start,
                                                    agenda_event_end: JSON.parse(item).agenda_event_end,
                                                    event_duration_hour: JSON.parse(item).event_duration_hour,
                                                    agenda_event_sequence_session_id: JSON.parse(item).agenda_event_sequence_session_id,

                                                }
                                            ))}

                                            columns={columns}
                                            pageSize={20}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {

                                            }}



                                            rowsPerPageOptions={[20]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }

                                            }}

                                            getRowClassName={(params) => {

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {

                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }

                                            }}

                                            getEstimatedRowHeight={() => 200}
                                            getRowHeight={() => "auto"}


                                        />
                                    </Box>
                                    <br />

                                </div>
                                {Getall_Qry_RH_Data_result &&
                                    Getall_Qry_RH_Data_result.map((my_data) => (
                                        <div >

                                            {JSON.parse(my_data).total_duration &&
                                                <nav style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "700", "fontSize": "2rem", height:"5rem",
                                                "color":'#6495ED' }}>
                                                    Durée totale : {JSON.parse(my_data).total_duration}
                                                    <div className="div_row"> &nbsp; </div>
                                                </nav>}



                                        </div>
                                    ))}
                            </div>

                        </div>



                    </Box>

                </div>

            </div>


            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>


                <div style={{ "float": "left", "width": String(display_column_size), "float": "left", "paddingRight": "5px" }} >
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Employés Détail Taches et Coût</nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("3")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "3" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("RESSOURCES_HUMAINES_02")
                            Add_To_User_TBD("tbd_rh_02");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}

                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel_task_cost(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_session_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /*  setaddOneParticipant();
                                                      setdisplay_detail_insc();
                                                      setselected_code_session();
                                                      setsubmenu();*/
                                                }
                                            }}
                                            selectionModel={selectionModel_task_cost}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Qry_RH_Task_Cost_Data_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    rh_civilite: JSON.parse(item).rh_civilite,
                                                    rh_nom: JSON.parse(item).rh_nom,
                                                    rh_prenom: JSON.parse(item).rh_prenom,
                                                    rh_email: JSON.parse(item).rh_email,
                                                    rh_ismanager: JSON.parse(item).rh_ismanager,
                                                    rh_fonction: JSON.parse(item).rh_fonction,
                                                    rh_telephone_mobile: JSON.parse(item).rh_telephone_mobile,
                                                    rh_adr_adresse: JSON.parse(item).rh_adr_adresse,
                                                    rh_adr_code_postal: JSON.parse(item).rh_adr_code_postal,
                                                    rh_adr_ville: JSON.parse(item).rh_adr_ville,
                                                    rh_adr_pays: JSON.parse(item).rh_adr_pays,
                                                    rh_contrat_date_debut: JSON.parse(item).rh_contrat_date_debut,
                                                    rh_contrat_date_fin: JSON.parse(item).rh_contrat_date_fin,
                                                    rh_contrat_type_contrat: JSON.parse(item).rh_contrat_type_contrat,
                                                    rh_contrat_type_employe: JSON.parse(item).rh_contrat_type_employe,
                                                    rh_contrat_cout: JSON.parse(item).rh_contrat_cout,
                                                    rh_contrat_periodicite: JSON.parse(item).rh_contrat_periodicite,
                                                    rh_contrat_quantite: JSON.parse(item).rh_contrat_quantite,
                                                    rh_contrat_groupe_prix_achat_id: JSON.parse(item).rh_contrat_groupe_prix_achat_id,
                                                    rh_contrat_groupe_prix_achat_code: JSON.parse(item).rh_contrat_groupe_prix_achat_code,


                                                    rh_contrat_groupe_prix_achat_cout: JSON.parse(item).rh_contrat_groupe_prix_achat_cout,
                                                    rh_contrat_groupe_prix_achat_periodicite: JSON.parse(item).rh_contrat_groupe_prix_achat_periodicite,
                                                    rh_event_planning_event_title: JSON.parse(item).rh_event_planning_event_title,
                                                    rh_event_planning_even_comment: JSON.parse(item).rh_event_planning_even_comment,
                                                    rh_event_planning_event_type: JSON.parse(item).rh_event_planning_event_type,
                                                    rh_event_planning_event_duration_hour: JSON.parse(item).rh_event_planning_event_duration_hour,
                                                    rh_event_planning_agenda_date_jour: JSON.parse(item).rh_event_planning_agenda_date_jour,
                                                    rh_event_planning_event_start: JSON.parse(item).rh_event_planning_event_start,
                                                    rh_event_planning_event_end: JSON.parse(item).rh_event_planning_event_end,
                                                    rh_event_planning_event_cost: JSON.parse(item).rh_event_planning_event_cost,

                                                }
                                            ))}

                                            columns={columns_task_cost}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {

                                            }}



                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }

                                            }}

                                            getRowClassName={(params) => {

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {

                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }

                                            }}
                                            getEstimatedRowHeight={() => 200}
                                            getRowHeight={() => "auto"}

                                        />
                                    </Box>
                                    <br />

                                </div>
                                {Getall_Qry_RH_Task_Cost_Data_result &&
                                    Getall_Qry_RH_Task_Cost_Data_result.map((my_data) => (
                                        <div >

                                            {JSON.parse(my_data).total_duration &&
                                                <nav style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "700", "fontSize": "2rem", height:"5rem",
                                                "color":'#6495ED' }}>
                                                    Durée totale : {JSON.parse(my_data).total_duration}
                                                    <div className="div_row"> &nbsp; </div>
                                                </nav>}

                                            {JSON.parse(my_data).total_cost &&
                                                <nav style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "700", "fontSize": "2rem", height:"5rem",
                                                "color":'#FF7F50' }}>
                                                    Coût total : {JSON.parse(my_data).total_cost}
                                                    <div className="div_row"> &nbsp; </div>
                                                </nav>}

                                        </div>
                                    ))}


                            </div>

                        </div>



                    </Box>

                </div>

            </div>


        </div>)
}


export default Dashbord_Ressources_Humaines;

