import React, { useRef, useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { Button, } from "reactstrap";
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import moment from "moment";
import { getCookie } from 'react-use-cookie';
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from '@tinymce/tinymce-react';

function UpdateUserGoals(props) {


    const [fields1desabled, setfields1desabled] = useState(true);
    const url = process.env.REACT_APP_API_URL + "myclass/api/add_update_user_objectif/";
    const [defaultdate, setdefaultdate] = useState(new Date().setDate(new Date().getDate() + 10));
    const [mindate, setmindate] = useState(new Date().setDate(new Date().getDate() + 1));
    const history = useHistory();
    const editorRef_description = useRef(null);
    const [datamodification, setdatamodification] = useState("0");
    const [field_description, setfield_description] = useState("");

    useEffect(() => {

        getGolData();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, [props.name_obj]);

    function cleanField() {
        document.getElementsByName("name")[0].value = "";

        document.getElementsByName("domaine")[0].value = "";
        document.getElementsByName("niveau")[0].value = "";
    }

    const [recorddata_api, setrecorddata_api] = useState();
    const [recorddata_api_message, setrecorddata_api_message] = useState();
    const [recorddata_api_result, setrecorddata_api_result] = useState([]);
    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/add_update_user_objectif/";

        var name = document.getElementsByName("name")[0].value;
        var date_souhaite = format(StartDate, 'dd/MM/yyyy')
        var domaine = document.getElementsByName("domaine")[0].value;
        var niveau = document.getElementsByName("niveau")[0].value;

        var description_new = "";
        if (editorRef_description.current) {
            description_new = editorRef_description.current.getContent();
        }



        const stored_cookie = getCookie('tokenmysych');

        formData.append('token', stored_cookie);
        formData.append('name', name);
        formData.append('description', description_new);
        formData.append('date_souhaite', date_souhaite);
        formData.append('domaine', domaine);
        formData.append('niveau', niveau);

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                if (String(result['status']) === String("true")) {
                    setrecorddata_api("true");
                    setrecorddata_api_result(result['message']);

                    history.push({
                        pathname: "/mysy-user-account/",
                        state: {
                            menu: "profil_objectif"
                        }
                    });
                    { window.location.reload() }

                }else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setrecorddata_api("false");
                    setrecorddata_api_message(result['message']);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setrecorddata_api("false");
            });


    };


    const [getGolData_api, setgetGolData_api] = useState();
    const [getGolData_api_message, setgetGolData_api_message] = useState();
    const [getGolData_api_result, setgetGolData_api_result] = useState([]);
    function getGolData() {

        cleanField();


        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_user_objectif/";

        const stored_cookie = getCookie('tokenmysych');
        formData.append('token', stored_cookie);

        var name = props.name_obj;
        formData.append('name', name);
        //alert( "stored_user = "+stored_user);

        axios.post(url, formData).then(res => {
            if (String(res.data.status) === String("true")) {
                //console.log(" In getGolData  res.data.status = " + res.data.status);
                //console.log(" In getGolData  res.data.message = " + res.data.message);
                setgetGolData_api("true");

                var mylocalobj = JSON.parse(res.data.message);
                if (mylocalobj) {

                    if (mylocalobj.name) {
                        document.getElementsByName("name")[0].value = mylocalobj.name;
                        document.getElementsByName("name")[0].disabled = true;
                        document.getElementsByName("name")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocalobj.description) {


                        setfield_description(mylocalobj.description);
                    }

                    if (mylocalobj.domaine) {
                        document.getElementsByName("domaine")[0].value = mylocalobj.domaine;
                        document.getElementsByName("domaine")[0].disabled = true;
                        document.getElementsByName("domaine")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocalobj.date_souhaite) {

                        var va = new Date(moment(mylocalobj.date_souhaite, "DD/MM/YYYY"))
                        setStartDate(va);

                    }



                    if (mylocalobj.niveau) {
                        document.getElementsByName("niveau")[0].value = mylocalobj.niveau;
                        document.getElementsByName("niveau")[0].disabled = true;
                        document.getElementsByName("niveau")[0].style.backgroundColor = "#ECEFF1";
                    }




                } else {
                    //console.log(" In test  res.data.status = " + res.data.status);
                    //console.log(" In test  res.data.message = " + res.data.message);

                }
            }
            else {
                setgetGolData_api("true");
                setgetGolData_api_message(res.data.message);
            }


        }).catch((error) => {
            console.warn('getGolData : Not good man :( mysearchtext = ', error);
            setgetGolData_api("false");

        })
    }

    const [fiedsupdated, setfiedsupdated] = useState(false);

    function updateBox() {
        setfiedsupdated(true);

    }


    const [editfields, seteditfields] = useState(false);
    function enablefieds1() {


        document.getElementsByName("domaine")[0].disabled = false;
        document.getElementsByName("niveau")[0].disabled = false;


        document.getElementsByName("domaine")[0].style.background = "#fff";
        document.getElementsByName("niveau")[0].style.background = "#fff";

        seteditfields(true);

    }

    function desablefield1() {
        document.getElementsByName("name")[0].disabled = true;
        //document.getElementsByName("date_souhaite")[0].disabled = true;
        document.getElementsByName("domaine")[0].disabled = true;
        document.getElementsByName("niveau")[0].disabled = true;

        document.getElementsByName("name")[0].style.background = "#ECEFF1";
        //document.getElementsByName("date_souhaite")[0].style.background = "#ECEFF1";
        document.getElementsByName("domaine")[0].style.background = "#ECEFF1";
        document.getElementsByName("niveau")[0].style.background = "#ECEFF1";

        seteditfields(false);

    }

    const [StartDate, setStartDate] = useState(new Date());
    const [mydate_val, setmydate_val] = useState();
    const handleChangeDate = (date) => {
        //alert(" date = " + format(date, 'dd/MM/yyyy'));
        setStartDate(date);
        setfiedsupdated(true);

    }

    const [editor_tochange, seteditor_tochange] = useState("");
    function editor_keyup() {
        seteditor_tochange("1");
    }

    function DataUpdated() {
        setdatamodification("1");
    }

  

    return (
        <div className="updateusergoals">

          
           <div className="div_row">



                {String(getGolData_api) === String("false") && <div className="koUpdateData"> Impossible d'afficher le detail de l'objectif </div>}


                {String(recorddata_api) === String("true") && <div className="okUpdateData"> L'objectif a été ajouté/mise à jour </div>}

                {String(recorddata_api) === String("false") && <div className="koUpdateData"> {recorddata_api_message} </div>}


                 &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}
                    onChange={updateBox} >

                    <TextField
                        required
                        label="Nom"
                        name="name"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                    <TextField
                        name="domaine"
                        label="Domaine"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />


                    <TextField
                        name="niveau"
                        label="Niveau"
                        className="texte_area"
                        sx={{ m: 1, width: '48%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />

                    <div className="div_row">
                        <div className="div_row_gauche">
                            Data Souhaitée :
                            <DatePicker
                                selected={StartDate}
                                onChange={handleChangeDate}
                                dateFormat="dd/MM/yyyy"
                            />


                        </div>
                        <div className="div_row_droite">


                            <div className="training_text">
                                <div className="plus_produit_desabled">
                                    <div className="titre_training_text"> Description </div>
                                    <Editor
                                        onInit={(evt, editor) => editorRef_description.current = editor}
                                        initialValue={field_description}
                                        onKeyUp={editor_keyup}
                                        onEditorChange={(newValue, editor) => {

                                            if (String(editor_tochange) === "1") {
                                                //alert(" change");
                                                DataUpdated()
                                                seteditor_tochange("");
                                                setfiedsupdated(true);
                                            }
                                        }}

                                        init={{
                                            resize: false,
                                            height: 220,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="div_row22">

                        {fiedsupdated && <div className="div_row"><div className="koUpdateData" style={{ "color": "orange", "textAlign": "center" }}> /!\ Pensez à enregistrer les modifications</div></div>}

                        {(!props.name_obj || fiedsupdated) && <div className="div_row">
                            <div className="div_row_gauche">
                                <Button variant="contained" color="success" className="div_row22 bton_enreg " onClick={RecordData}>Enregistrer &nbsp; <AiTwotoneSave /> </Button>
                            </div>
                            <div className="div_row_droite" style={{ "textAlign": "right" }}>
                                <Button variant="contained" color="success" className="div_row22 bton_annnule" onClick={"CloseLocalcomp"}>Annuler</Button>
                            </div>
                        </div>
                        }
                        {/*props.name_obj && String(props.name_obj).length > 2 && !editfields && <div className="div_row_droite" style={{ "textAlign": "right" }}>
                            <Button variant="contained" color="success" className="div_row22 bton_enreg" onClick={enablefieds1}>Modifier &nbsp; <AiTwotoneEdit /> </Button>
                        </div>
                    */}

                    </div>
                </Box>
            </div>


        </div>

    );
}
export default UpdateUserGoals;