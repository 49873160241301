import Link from "next/link";
import Navigation from "../components/Navigation";
import SurveyDocument from "../components/SurveyDocument"



export default function Login() {
    return (
        <div>
             <Navigation />
            <div>
              <SurveyDocument />
               
            </div>
        </div>
    );
}
