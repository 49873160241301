import Link from "next/link";
import SignUp from "../components/SignUp";
import Navigation from "../components/Navigation";
// import SignUp from "../components/SignUp";
export default function Login() {
    return (
        <div>
             <Navigation />

            <div>
                <SignUp />
                {/* <SignUp /> */}
            </div>
        </div>
    );
}
