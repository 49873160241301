import React, { useState, useEffect } from "react";


const ArticleComment = (props) => {

    const [myfilter, setmyfilter] = useState(props.articelcomment.message);

    return (

        <div className="articlecomment">
            <div className="div_row">
                <div className="div_row_gauche">
                {String(props.articelcomment.sender_mail).split('@')[0]}<br/>
                {(String(props.articelcomment.date_update)).substring(0, 16)}

                </div>

                <div className="div_row_droite">
                    {props.articelcomment.message}
                </div>
            </div>

            <br/>
            <hr />
        </div>
    )
}


export default ArticleComment;
