import CoupDeCoeur from "../components/CoupDeCoeur";
import Navigation from "../components/Navigation";

export default function displaydetailclass() {
    return (
        <div>
           <div >
            <Navigation />
            </div>
            <CoupDeCoeur /> 
            
        </div>
    );
}