import React, { useRef, useState, useEffect } from "react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import 'reactjs-popup/dist/index.css';

import "react-datepicker/dist/react-datepicker.css";

import Button from '@mui/material/Button';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import fileDownload from 'js-file-download'

import Dashbord_Session_Par_Fteur from "./Dashbord_Session_Par_Fteur";
import Dashbord_Session_Taux_Remplissage from "./Dashbord_Session_Taux_Remplissage";
import Dashbord_Session_Repartition_Fteur from "./Dashbord_Session_Repartition_Fteur";
import Dashbord_Formation_Evol_Session from "./Dashbord_Formation_Evol_Session";
import Dashbord_Ressources_Humaines_Tache_by_rhid from "./Dashbord_Ressources_Humaines_Tache_by_rhid";
import Dashbord_Ressources_Humaines_Tache_Couts from "./Dashbord_Ressources_Humaines_Tache_Couts";
import Dashbord_Factures_Par_Periode from "./Dashbord_Factures_Par_Periode";
import Dashbord_Factures_Par_Client from "./Dashbord_Factures_Par_Client";
import Dashbord_Factures_Devis_Par_Periode from "./Dashbord_Factures_Devis_Par_Periode";
import Dashbord_Inscriptions_all_inscriptions from "./Dashbord_Inscription_All_Inscription";
import Dashbord_Factures_Previsionnel_Par_Periode from "./Dashbord_Factures_Previsionnel_Par_Periode";
import Box from '@mui/material/Box';
import { TbColumns2, TbColumns1 } from "react-icons/tb";
import { BarChart } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts';
import { pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { MdDeleteForever } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ResponsiveBar } from "@nivo/bar";
import {
    useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';


import { FcPrint } from "react-icons/fc";


const Mon_Tableau_De_Bord = (props) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const history = useHistory();
    const [isLoading, setLoading] = useState();


    const [Get_Connected_User_Dashbord_api, setGet_Connected_User_Dashbord_api] = useState();
    const [Get_Connected_User_Dashbord_message, setGet_Connected_User_Dashbord_message] = useState();
    const [Get_Connected_User_Dashbord_result, setGet_Connected_User_Dashbord_result] = useState([]);

    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'civilite', headerName: 'Civ.', hide: true, minWidth: 100, flex: 1, maxWidth: 60, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'email', headerName: 'Email', hide: true, flex: 1, minWidth: 250, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'ismanager', headerName: 'Manager', hide: true, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'fonction', headerName: 'Fonction', hide: true, minWidth: 200, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} /> },

        { field: 'agenda_event_title', headerName: 'Titre Event', hide: false, minWidth: 250, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_code_session', headerName: 'Code session', hide: false, minWidth: 250, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_type', headerName: 'Type Event', hide: false, minWidth: 150, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'event_duration_hour', headerName: 'Durée (h)', hide: false, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_date_jour', headerName: 'Date', hide: false, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_start', headerName: 'Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_end', headerName: 'Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} /> },
        { field: 'agenda_event_sequence_session_id', headerName: 'agenda_id', hide: true, maxWidth: 1, renderCell: (params) => <ExpandableCell_50 {...params} /> },
    ]

    const columns_task_cost = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },

        { field: 'rh_civilite', headerName: 'Civ.', hide: true, minWidth: 100, flex: 1, maxWidth: 60, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_email', headerName: 'Email', hide: true, flex: 1, minWidth: 250, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_ismanager', headerName: 'Manager', hide: true, minWidth: 50, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_fonction', headerName: 'Fonction', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_telephone_mobile', headerName: 'Mobile', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_adr_adresse', headerName: 'Adesse', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_adr_code_postal', headerName: 'CP', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_adr_ville', headerName: 'Ville', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_adr_pays', headerName: 'Pays', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        { field: 'rh_contrat_date_debut', headerName: 'Ctrat Du', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_date_fin', headerName: 'Ctrat Au', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_type_contrat', headerName: 'Ctrat Type', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_type_employe', headerName: 'Ctrat Empl.', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_cout', headerName: 'Ctrat Cout', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_periodicite', headerName: 'Ctrat Périodicité', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_quantite', headerName: 'Ctrat Qte', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        { field: 'rh_contrat_groupe_prix_achat_id', headerName: 'Grp Prix ID', hide: true, Width: 0, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_groupe_prix_achat_code', headerName: 'Grp Prix Code', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        { field: 'rh_contrat_groupe_prix_achat_cout', headerName: 'Grp Prix Cout', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_contrat_groupe_prix_achat_periodicite', headerName: 'Grp Prix Périodicité', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },


        { field: 'rh_event_planning_event_title', headerName: 'Planning Titre', hide: false, minWidth: 200, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_even_comment', headerName: 'Planning Comment', hide: true, minWidth: 100, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_event_type', headerName: 'Planning Type', hide: false, minWidth: 150, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_event_duration_hour', headerName: 'Planning Durée (h)', hide: false, minWidth: 100, flex: 1, maxWidth: 100, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_agenda_date_jour', headerName: 'Planning Date', hide: true, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_event_start', headerName: 'Planning Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_event_end', headerName: 'Planning Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'rh_event_planning_event_cost', headerName: 'Planning Cout Total (HT)', hide: false, minWidth: 150, flex: 1, maxWidth: 150, renderCell: (params) => <ExpandableCell_50 {...params} />, },

    ]

    const [selectionModel_task_cost, setSelectionModel_task_cost] = React.useState([]);

    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    const [selectionModel, setSelectionModel] = React.useState([]);

    function Get_Connected_User_Dashbord() {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');


        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Connected_User_List_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Get_Connected_User_Dashbord  res.data.status = " + res.data.status);
                //console.log(" In Get_Connected_User_Dashbord  res.data.message r_class = " + res.data.message);

                setGet_Connected_User_Dashbord_api("true");
                setGet_Connected_User_Dashbord_result(res.data.message);

            }
            else {
                setGet_Connected_User_Dashbord_api("false");
                setGet_Connected_User_Dashbord_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Connected_User_Dashbord = ', error);
            setGet_Connected_User_Dashbord_api("false");
            alert("Impossible de récupérer la liste des tableaux de bord");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {


        if (String(submenu) === "") {
            submenu_rapport_activite();
        }
        Get_Connected_User_Dashbord();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])

    const New_option_periode = [
        { "id": "m0", "label": "Mois en cours", "value": "m0" },
        { "id": "m1", "label": "Mois précédant ", "value": "m1" },
        { "id": "d", "label": "Choisir des dates", "value": "d" },

    ]

    const New_option_group_by = [
        { "id": "mois", "label": "Mois", "value": "mois" },
        { "id": "class", "label": "Formation", "value": "class" },
        { "id": "session", "label": "Session", "value": "session" },
        { "id": "", "label": "", "value": "" },
    ]

    const [display_nb_column, setdisplay_nb_column] = useState("1");
    const [display_column_size, setdisplay_column_size] = useState("98%");

    const [submenu, setsubmenu] = useState("");
    function submenu_montbd() {
        setsubmenu("montbd");

        submenu_color_management("montbd");
    }

    function submenu_rapport_activite() {
        setsubmenu("rapport_activite");
        submenu_color_management("rapport_activite");
    }

    function submenu_rapport_financier() {
        setsubmenu("rapport_financier");
        submenu_color_management("rapport_financier");
    }


    function submenu_rapport_rh() {
        setsubmenu("rapport_rh");
        submenu_color_management("rapport_rh");
    }

    function submenu_bpf() {
        setsubmenu("bpf");
        submenu_color_management("bpf");
    }



    function submenu_color_management(current_menu) {
        const list_sous_menu = ["montbd", "rapport_activite", "rapport_financier", "rapport_rh", "bpf"]

        for (let i = 0; i < list_sous_menu.length; i++) {

            var sous_menu = list_sous_menu[i];
            if (String(sous_menu) !== current_menu) {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#d8edfc";
                    document.getElementById(sous_menu).style.color = "black";
                    document.getElementById(sous_menu).style.fontWeight = "normal";
                }
            }

            if (String(sous_menu) === "editique") {
                if (document.getElementById(sous_menu)) {
                    document.getElementById(sous_menu).style.backgroundColor = "#6C3483";
                    document.getElementById(sous_menu).style.color = "#FFFFFF";
                    document.getElementById(sous_menu).style.fontWeight = "bold";
                }
            }

        }

        if (document.getElementById(current_menu)) {
            document.getElementById(current_menu).style.backgroundColor = "#104277";
            document.getElementById(current_menu).style.color = "white";
            document.getElementById(current_menu).style.fontWeight = "bold";
        }

    }

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);
    const valueFormatter_count = (value) => `${value} Insc`;

    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));
    const [filter_group_by, setfilter_group_by] = useState("");
    const [filter_value, setfilter_value] = useState("d");

    const [Getall_Qry_Inscription_Data_result_JSON, setGetall_Qry_Inscription_Data_result_JSON] = useState([]);

    const [Getall_Qry_Inscription_Data_api, setGetall_Qry_Inscription_Data_api] = useState();
    const [Getall_Qry_Inscription_Data_message, setGetall_Qry_Inscription_Data_message] = useState();
    const [Getall_Qry_Inscription_Data_result, setGetall_Qry_Inscription_Data_result] = useState([]);
    function Getall_Qry_Inscription_Data(groupe_by) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);



        var myurl = "";
        if (String(groupe_by) === "class")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_Group_By_Class/";
        else if (String(groupe_by) === "session")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_Group_By_Session/";
        else if (String(groupe_by) === "periode_cumule")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_By_Session_By_Periode_cumule/";
        else
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_By_Session_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Inscription_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Inscription_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Inscription_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log(" ### jsonObj = ", jsonObj)

                setGetall_Qry_Inscription_Data_api("true");
                setGetall_Qry_Inscription_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Inscription_Data_api("false");
                setGetall_Qry_Inscription_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Inscription_Data = ', error);
            setGetall_Qry_Inscription_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const chartSetting = {
        yAxis: [
            {
                label: "  ",
            },
        ],
        width: 1100,
        height: 400,

        margin: 100,
    };

    const chartSetting_V2 = {
        yAxis: [
            {
                label: "  ",
            },
        ],
        width: 1100,
        height: 600,

        margin: 100,
    };


    function display_inscrit_by_periode() {
        setfilter_group_by("");
        Getall_Qry_Inscription_Data("");
    }

    function display_inscrit_by_formation() {
        setfilter_group_by("class");
        Getall_Qry_Inscription_Data("class");
    }

    function display_inscrit_by_session() {
        setfilter_group_by("session");
        Getall_Qry_Inscription_Data("session");
    }


    function display_inscrit_by_periode_cumule() {
        setfilter_group_by("periode_cumule");
        Getall_Qry_Inscription_Data("periode_cumule");
    }


    const [Getall_Qry_Formation_Session_result_JSON, setGetall_Qry_Formation_Session_result_JSON] = useState([]);
    const [Getall_Qry_Formation_Session_api, setGetall_Qry_Formation_Session_api] = useState();
    const [Getall_Qry_Formation_Session_message, setGetall_Qry_Formation_Session_message] = useState();
    const [Getall_Qry_Formation_Session_result, setGetall_Qry_Formation_Session_result] = useState([]);
    function Getall_Qry_Formation_Session(session_groupe_by) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Session_By_Periode_V2/";

        if (String(session_groupe_by) === "periode_cumule")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Session_By_Periode_Cumule_V2/";

        else if (String(session_groupe_by) === "class")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Session_By_Formation_V2/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                console.log(" In Getall_Qry_Formation_Session  res.data.status = " + res.data.status);
                console.log(" In Getall_Qry_Formation_Session  res.data.message r_class = " + res.data.message);


                setGetall_Qry_Formation_Session_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');


                setGetall_Qry_Formation_Session_api("true");
                setGetall_Qry_Formation_Session_result(res.data.message);

                console.log(" Getall_Qry_Formation_Session_result_JSON = ", Getall_Qry_Formation_Session_result_JSON);


            }
            else {
                setGetall_Qry_Formation_Session_api("false");
                setGetall_Qry_Formation_Session_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Formation_Session = ', error);
            setGetall_Qry_Formation_Session_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [filter_session_group_by, setfilter_session_group_by] = useState("");

    function display_session_by_periode() {
        setfilter_session_group_by("");
        Getall_Qry_Formation_Session("");
    }


    function display_session_by_formation() {
        setfilter_session_group_by("class");
        Getall_Qry_Formation_Session("class");
    }


    function display_session_by_periode_cumule() {
        setfilter_session_group_by("periode_cumule");
        Getall_Qry_Formation_Session("periode_cumule");
    }


    const [Getall_Qry_Session_Data_result_JSON, setGetall_Qry_Session_Data_result_JSON] = useState([]);
    const [Getall_Qry_Session_Data_api, setGetall_Qry_Session_Data_api] = useState();
    const [Getall_Qry_Session_Data_message, setGetall_Qry_Session_Data_message] = useState();
    const [Getall_Qry_Session_Data_result, setGetall_Qry_Session_Data_result] = useState([]);
    function Getall_Qry_Session_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }

        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Session_Data/";

        //console.log(" ### form = ", form);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Session_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Session_Data  res.data.message r_class = " + res.data.message);
                setGetall_Qry_Session_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_Session_Data_api("true");
                setGetall_Qry_Session_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Session_Data_api("false");
                setGetall_Qry_Session_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Session_Data = ', error);
            setGetall_Qry_Session_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Qry_Session_Taux_Remplissage_result_JSON, setGetall_Qry_Session_Taux_Remplissage_result_JSON] = useState([]);
    const [Getall_Qry_Session_Taux_Remplissage_api, setGetall_Qry_Session_Taux_Remplissage_api] = useState();
    const [Getall_Qry_Session_Taux_Remplissage_message, setGetall_Qry_Session_Taux_Remplissage_message] = useState();
    const [Getall_Qry_Session_Taux_Remplissage_result, setGetall_Qry_Session_Taux_Remplissage_result] = useState([]);
    function Getall_Qry_Session_Taux_Remplissage(session_groupe_by) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Taux_Remplissage_Session_Data_V2/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Session_Taux_Remplissage  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Session_Taux_Remplissage  res.data.message r_class = " + res.data.message);


                setGetall_Qry_Session_Taux_Remplissage_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');



                setGetall_Qry_Session_Taux_Remplissage_api("true");
                setGetall_Qry_Session_Taux_Remplissage_result(res.data.message);


            }
            else {
                setGetall_Qry_Session_Taux_Remplissage_api("false");
                setGetall_Qry_Session_Taux_Remplissage_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Session_Taux_Remplissage = ', error);
            setGetall_Qry_Session_Taux_Remplissage_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }



    const [p_detail_partner_client_id, setp_detail_partner_client_id] = useState("");
    const [p_detail_partner_client_nom, setp_detail_partner_client_nom] = useState("");


    const [Getall_Qry_Invoice_Data_result_JSON, setGetall_Qry_Invoice_Data_result_JSON] = useState([]);
    const [Getall_Qry_Invoice_Data_api, setGetall_Qry_Invoice_Data_api] = useState();
    const [Getall_Qry_Invoice_Data_message, setGetall_Qry_Invoice_Data_message] = useState();
    const [Getall_Qry_Invoice_Data_result, setGetall_Qry_Invoice_Data_result] = useState([]);
    function Getall_Qry_Invoice_Data(local_group_by) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Factures_Data_By_Periode/";

        if (local_group_by === "class")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Factures_Data_By_Class/";

        if (local_group_by === "client") {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Factures_Data_By_Client_V2/";
            form.append("filter_client_id", "");
        }


        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Invoice_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Invoice_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Invoice_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("jsonObj =  ", jsonObj)

                setGetall_Qry_Invoice_Data_api("true");
                setGetall_Qry_Invoice_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Invoice_Data_api("false");
                setGetall_Qry_Invoice_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Invoice_Data = ', error);
            setGetall_Qry_Invoice_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const valueFormatter_CA = (value) => `${value}€`;


    const [filter_invoice_group_by, setfilter_invoice_group_by] = useState("periode");


    function display_invoice_by_formation() {
        setfilter_invoice_group_by("class");
        Getall_Qry_Invoice_Data("class");
    }

    function display_invoice_by_periode() {
        setfilter_invoice_group_by("periode");
        Getall_Qry_Invoice_Data("");
    }


    function display_invoice_by_client() {
        setfilter_invoice_group_by("client");
        Getall_Qry_Invoice_Data("client");
    }


    function display_invoice_by_periode_cumule() {
        setfilter_invoice_group_by("cumule");
        Getall_Qry_Invoice_Data("");
    }

    const [Getall_Qry_Quotation_Data_result_JSON, setGetall_Qry_Quotation_Data_result_JSON] = useState([]);
    const [Getall_Qry_Quotation_Data_api, setGetall_Qry_Quotation_Data_api] = useState();
    const [Getall_Qry_Quotation_Data_message, setGetall_Qry_Quotation_Data_message] = useState();
    const [Getall_Qry_Quotation_Data_result, setGetall_Qry_Quotation_Data_result] = useState([]);
    function Getall_Qry_Quotation_Data(local_group_by) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", "");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Quotation_Data_By_Periode/";

        if (local_group_by === "client") {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Quotation_Data_By_Client/";
        }

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Quotation_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Quotation_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Quotation_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("Getall_Qry_Quotation_Data_result_JSON jsonObj =  ", jsonObj)

                setGetall_Qry_Quotation_Data_api("true");
                setGetall_Qry_Quotation_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Quotation_Data_api("false");
                setGetall_Qry_Quotation_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Quotation_Data = ', error);
            setGetall_Qry_Quotation_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [filter_quotation_group_by, setfilter_quotation_group_by] = useState("periode");


    function display_quotation_by_formation() {
        setfilter_quotation_group_by("class");
        Getall_Qry_Quotation_Data("class");
    }

    function display_quotation_by_periode() {
        setfilter_quotation_group_by("periode");
        Getall_Qry_Quotation_Data("");
    }


    function display_quotation_by_client() {
        setfilter_quotation_group_by("client");
        Getall_Qry_Quotation_Data("client");
    }


    function display_quotation_by_periode_cumule() {
        setfilter_quotation_group_by("cumule");
        Getall_Qry_Quotation_Data("");
    }


    const [Getall_Qry_Previsionnel_Data_result_JSON, setGetall_Qry_Previsionnel_Data_result_JSON] = useState([]);
    const [Getall_Qry_Previsionnel_Data_api, setGetall_Qry_Previsionnel_Data_api] = useState();
    const [Getall_Qry_Previsionnel_Data_message, setGetall_Qry_Previsionnel_Data_message] = useState();
    const [Getall_Qry_Previsionnel_Data_result, setGetall_Qry_Previsionnel_Data_result] = useState([]);
    function Getall_Qry_Previsionnel_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", "");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Facture_Previsionnelle_Data_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Previsionnel_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Previsionnel_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Previsionnel_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("jsonObj =  ", jsonObj)

                setGetall_Qry_Previsionnel_Data_api("true");
                setGetall_Qry_Previsionnel_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Previsionnel_Data_api("false");
                setGetall_Qry_Previsionnel_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Previsionnel_Data = ', error);
            setGetall_Qry_Previsionnel_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }



    const [Getall_Qry_RH_Data_result_JSON, setGetall_Qry_RH_Data_result_JSON] = useState([]);
    const [Getall_Qry_RH_Data_api, setGetall_Qry_RH_Data_api] = useState();
    const [Getall_Qry_RH_Data_message, setGetall_Qry_RH_Data_message] = useState();
    const [Getall_Qry_RH_Data_result, setGetall_Qry_RH_Data_result] = useState([]);
    function Getall_Qry_RH_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }

        var event_type = "";
        /*if (event_type_filter) {
            event_type = event_type_filter;

        }*/


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);

        form.append("tab_rh_id", "all");

        form.append("event_type", event_type);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Humain_Ressource_With_Planning/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_RH_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_RH_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_RH_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_RH_Data_api("true");
                setGetall_Qry_RH_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_RH_Data_api("false");
                setGetall_Qry_RH_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_RH_Data = ', error);
            setGetall_Qry_RH_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Qry_RH_Task_Cost_Data_result_JSON, setGetall_Qry_RH_Task_Cost_Data_result_JSON] = useState([]);
    const [Getall_Qry_RH_Task_Cost_Data_api, setGetall_Qry_RH_Task_Cost_Data_api] = useState();
    const [Getall_Qry_RH_Task_Cost_Data_message, setGetall_Qry_RH_Task_Cost_Data_message] = useState();
    const [Getall_Qry_RH_Task_Cost_Data_result, setGetall_Qry_RH_Task_Cost_Data_result] = useState([]);
    function Getall_Qry_RH_Task_Cost_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        var event_type = "";
        /*if (event_type_filter) {
            event_type = event_type_filter;

        }*/

        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);

        form.append("tab_rh_id", "all");

        form.append("event_type", event_type);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Humain_Ressource_With_Planning_And_Cost/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_RH_Task_Cost_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_RH_Task_Cost_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_RH_Task_Cost_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                setGetall_Qry_RH_Task_Cost_Data_api("true");
                setGetall_Qry_RH_Task_Cost_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_RH_Task_Cost_Data_api("false");
                setGetall_Qry_RH_Task_Cost_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_RH_Task_Cost_Data = ', error);
            setGetall_Qry_RH_Task_Cost_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    /********* GESTION BPF */
    const [Getall_Qry_BPF_PAVE_Data_result_JSON, setGetall_Qry_BPF_PAVE_Data_result_JSON] = useState({});
    const [Getall_Qry_BPF_PAVE_Data_api, setGetall_Qry_BPF_PAVE_Data_api] = useState();
    const [Getall_Qry_BPF_PAVE_Data_message, setGetall_Qry_BPF_PAVE_Data_message] = useState();
    const [Getall_Qry_BPF_PAVE_Data_result, setGetall_Qry_BPF_PAVE_Data_result] = useState([]);
    function Getall_Qry_BPF_PAVE_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }

        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Generate_BPF_From_partner_invoice_header/";

        //console.log(" ### form = ", form);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_BPF_PAVE_Data  res.data.status = " + res.data.status);
                // console.log(" In Getall_Qry_BPF_PAVE_Data  res.data.message r_class = " + res.data.message);
                setGetall_Qry_BPF_PAVE_Data_result_JSON(JSON.parse(res.data.message));

                setGetall_Qry_BPF_PAVE_Data_api("true");
                setGetall_Qry_BPF_PAVE_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_BPF_PAVE_Data_api("false");
                setGetall_Qry_BPF_PAVE_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_BPF_PAVE_Data = ', error);
            setGetall_Qry_BPF_PAVE_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    function Export_BPF_PDF() {

        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;
            date_from = date_from.replaceAll('/', "-")

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;
            date_to = date_to.replaceAll('/', "-")
        }


        var local_client_id = "";
        if (!p_detail_partner_client_id || String(p_detail_partner_client_id) === "") {
            local_client_id = "-"
        } else {
            local_client_id = p_detail_partner_client_id;
        }


        var nom_fichier_cmd_excel = "BPF.pdf";
        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- local_dashbord_internal_id = ", local_dashbord_internal_id);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Print_BPF_PDF/" + stored_cookie + "/" + date_from + "/" + date_to + "/";

        //console.log(" nom_fichier_cmd = ", nom_fichier_cmd, " --- selected_id = ", selected_id, " --- url =", url);

        console.log(" #### url = ", url)

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les données (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd_excel)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les données ")

            });

    }




    /****** FIN GESTION BPF */
    return (
        <div className="mon_tableau_de_bord">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <div className="div_row">

                {/*  <Button variant="outlined" onClick={submenu_montbd} className="detail_class_submenu" id='montbd' name='montbd' >Mon tableau de bord  </Button> */}
                <Button variant="outlined" onClick={submenu_rapport_activite} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Rapport Activite  </Button>
                <Button variant="outlined" onClick={submenu_rapport_financier} className="detail_class_submenu" id='rapport_financier' name='rapport_financier' >Rapports Financiers  </Button>
                <Button variant="outlined" onClick={submenu_rapport_rh} className="detail_class_submenu" id='rapport_rh' name='rapport_rh' >Ressources Humaine  </Button>
                <Button variant="outlined" onClick={submenu_bpf} className="detail_class_submenu" id='bpf' name='bpf' > Bilan Péd. Fin. (BPF)  </Button>

            </div>

            <div>

                <div style={{ "width": "100%", "float": "left" }}>
                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Filtre <br />

                        <Autocomplete
                            disablePortal
                            name="filtre11"
                            id="filtre11"
                            className="disabled_style"
                            options={New_option_periode}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setfilter_value(value.value);

                                } else {
                                    setfilter_value("");
                                }


                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une période"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />


                    </div>

                    {filter_value && String(filter_value) === "d" &&
                        <div style={{ "width": "100%", "float": "left" }}>
                            <div className="" style={{ "width": "50%", "float": "left" }}>
                                <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                    Date Debut
                                    <DatePicker
                                        name="one_sessiondatedebut"
                                        id="one_sessiondatedebut"

                                        selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                                        onChange={(date) => {
                                            setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                                        }
                                        }
                                        showTimeSelect={false}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style"
                                        locale='fr-FR'

                                    />

                                </div>
                            </div>

                            <div className="" style={{ "width": "50%", "float": "left" }}>
                                <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                    Date Fin
                                    <DatePicker
                                        name="one_sessiondatefin"
                                        id="one_sessiondatefin"
                                        selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                                        onChange={(date) => {
                                            setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                                        }
                                        }
                                        showTimeSelect={false}
                                        dateFormat="dd/MM/yyyy"
                                        className="disabled_style"
                                    />

                                </div>
                            </div>

                        </div>}

                    <div className="div_row">
                        <Button variant="contained" className="bton_enreg" onClick={(e) => {

                            // Recuperation des rapports d'activités
                            if (String(submenu) === "rapport_activite") {
                                Getall_Qry_Inscription_Data("");
                                Getall_Qry_Formation_Session("");
                                //  Getall_Qry_Session_Data();
                                Getall_Qry_Session_Taux_Remplissage("");
                            }

                            // Recuperation des rapports financiers
                            if (String(submenu) === "rapport_financier") {
                                Getall_Qry_Invoice_Data();
                                Getall_Qry_Quotation_Data();
                                Getall_Qry_Previsionnel_Data();
                            }

                            // Recuperation des rapports ressources humaines
                            if (String(submenu) === "rapport_rh") {
                                Getall_Qry_RH_Data();
                                Getall_Qry_RH_Task_Cost_Data();
                            }

                            // Recuperation des rapports ressources humaines
                            if (String(submenu) === "bpf") {
                                Getall_Qry_BPF_PAVE_Data();
                            }


                        }}> Afficher
                        </Button><br />

                    </div>
                </div>


                <hr className="hr_break" />

                {String(submenu) === String("rapport_financier") && <div style={{ "border": "None" }}>
                    <h3> Rapports Financiers </h3>

                    <Box >
                        <div className="session_data" >
                            {Getall_Qry_Invoice_Data_result_JSON && Getall_Qry_Invoice_Data_result_JSON.length > 0 &&
                                Getall_Qry_Invoice_Data_result_JSON[0].data &&
                                <div>
                                    <div className="titre1"> Le Chiffre d'affaire </div>
                                    <div className="parent_group_by">

                                        <Button variant="outlined" onClick={display_invoice_by_periode} className="detail_class_submenu" id='montbd' name='montbd' > Par Période </Button>
                                        <Button variant="outlined" onClick={display_invoice_by_formation} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par Ftion </Button>
                                        <Button variant="outlined" onClick={display_invoice_by_client} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par Client   </Button>
                                        <Button variant="outlined" onClick={display_invoice_by_periode_cumule} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par période (Cumulé)   </Button>

                                    </div>

                                    {filter_invoice_group_by && String(filter_invoice_group_by) === "periode" && <BarChart
                                        dataset={Getall_Qry_Invoice_Data_result_JSON[0].data}
                                        xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle', }]}
                                        series={[
                                            { dataKey: 'TotalAmount', label: 'CA TTC', valueFormatter_CA },
                                            { dataKey: 'count', label: 'Count', valueFormatter_count },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}

                                    />}

                                    {filter_invoice_group_by && String(filter_invoice_group_by) === "client" && <BarChart
                                        dataset={Getall_Qry_Invoice_Data_result_JSON[0].data}
                                        xAxis={[{
                                            scaleType: 'band', dataKey: 'Client_nom', tickPlacement: 'middle',
                                            valueFormatter: (month, context) => {
                                                if (month) {
                                                    if (String(month).length < 4) {
                                                        return `${month.slice(0, 4)}`
                                                    }

                                                    else if (String(month).length >= 4 && String(month).length <= 8) {
                                                        return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, String(month).length)}`
                                                    }
                                                    else if (String(month).length > 8) {
                                                        return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, 8)}` + " \n" + `${month.slice(8, 12)}`
                                                    }
                                                }
                                            }
                                        }]}
                                        series={[
                                            { dataKey: 'value', label: 'CA TTC', valueFormatter_CA },


                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}

                                    />}

                                    {filter_invoice_group_by && String(filter_invoice_group_by) === "cumule" && <BarChart
                                        dataset={Getall_Qry_Invoice_Data_result_JSON[0].data}
                                        xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle', }]}
                                        series={[
                                            { dataKey: 'TotalAmount_cumule', label: 'CA TTC', valueFormatter_CA },
                                            { dataKey: 'count', label: 'Count', valueFormatter_count },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}

                                    />}


                                    {filter_invoice_group_by && String(filter_invoice_group_by) === "class" &&
                                        <BarChart

                                            dataset={Getall_Qry_Invoice_Data_result_JSON[0].data}
                                            xAxis={[{
                                                scaleType: 'band', dataKey: 'class_external_code', tickPlacement: 'middle',
                                                valueFormatter: (month, context) => {
                                                    if (month) {
                                                        if (String(month).length < 4) {
                                                            return `${month.slice(0, 4)}`
                                                        }

                                                        else if (String(month).length >= 4 && String(month).length <= 8) {
                                                            return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, String(month).length)}`
                                                        }
                                                        else if (String(month).length > 8) {
                                                            return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, 8)}` + " \n" + `${month.slice(8, 12)}`
                                                        }
                                                    }
                                                }
                                                ,
                                            }]}
                                            series={[
                                                { dataKey: 'TotalAmount_HT', label: 'CA HT', valueFormatter_CA },


                                            ]}
                                            {...chartSetting}
                                            margin={{ right: 15, left: 100 }}


                                        />}
                                </div>
                            }

                            <hr className="hr_break" />
                            <br />


                            {Getall_Qry_Quotation_Data_result_JSON && Getall_Qry_Quotation_Data_result_JSON.length > 0 &&
                                Getall_Qry_Quotation_Data_result_JSON[0].data &&
                                <div>
                                    <div className="titre1"> Le suivi des devis </div>
                                    <div className="parent_group_by">

                                        <Button variant="outlined" onClick={display_quotation_by_periode} className="detail_class_submenu" id='montbd' name='montbd' > Par Période </Button>
                                        {/*<Button variant="outlined" onClick={display_quotation_by_client} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par Client   </Button>*/}
                                        {/* <Button variant="outlined" onClick={display_quotation_by_periode_cumule} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par période (Cumulé)   </Button>*/}

                                    </div>
                                    {filter_quotation_group_by && String(filter_quotation_group_by) === "periode" && <BarChart
                                        dataset={Getall_Qry_Quotation_Data_result_JSON[0].data}
                                        xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                        series={[
                                            { dataKey: 'nb_devis_gagne', label: 'Gagné', color: '#41B06E' },
                                            { dataKey: 'nb_devis_perdu', label: 'Perdu', color: '#FA7070' },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}

                                    />}

                                    {/*filter_quotation_group_by && String(filter_quotation_group_by) === "client" && <BarChart
                                        dataset={Getall_Qry_Quotation_Data_result_JSON[0].data}
                                        xAxis={[{ scaleType: 'band', dataKey: 'Client_nom', tickPlacement: 'middle' }]}
                                        series={[
                                            { dataKey: 'nb_devis_gagne', label: 'Gagné', color: '#41B06E' },
                                            { dataKey: 'nb_devis_perdu', label: 'Perdu', color: '#FA7070' },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}

                                    />*/}

                                    {/*filter_quotation_group_by && String(filter_quotation_group_by) === "cumule" && <BarChart
                                        dataset={Getall_Qry_Invoice_Data_result_JSON[0].data}
                                        xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                        series={[
                                            { dataKey: 'nb_cumule_gagne', label: 'Gagné', color: '#41B06E' },
                                            { dataKey: 'nb_cumule_perdu', label: 'Perdu', color: '#FA7070' },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}

                                    />*/}
                                </div>}

                            <hr className="hr_break" />
                            <br />

                            {Getall_Qry_Previsionnel_Data_result_JSON && Getall_Qry_Previsionnel_Data_result_JSON.length > 0 &&
                                Getall_Qry_Previsionnel_Data_result_JSON[0].data &&
                                <BarChart

                                    dataset={Getall_Qry_Previsionnel_Data_result_JSON[0].data}
                                    xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                    series={[
                                        { dataKey: 'ca_month_previsionnel', label: 'CA Prév', valueFormatter_CA },



                                    ]}
                                    {...chartSetting}
                                    margin={{ right: 15, left: 100 }}

                                />}


                        </div>
                    </Box>
                </div>}

                {String(submenu) === String("rapport_rh") && <div style={{ "border": "None" }}>

                    <h3> Rapports Ressources Humaines </h3>
                    <Box >
                        <div className="session_data" >
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_session_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /*  setaddOneParticipant();
                                                      setdisplay_detail_insc();
                                                      setselected_code_session();
                                                      setsubmenu();*/
                                                }
                                            }}
                                            selectionModel={selectionModel}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Qry_RH_Data_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    civilite: JSON.parse(item).civilite,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    email: JSON.parse(item).email,
                                                    ismanager: JSON.parse(item).ismanager,
                                                    fonction: JSON.parse(item).fonction,
                                                    agenda_event_title: JSON.parse(item).agenda_event_title,
                                                    agenda_event_code_session: JSON.parse(item).agenda_event_code_session,
                                                    agenda_event_type: JSON.parse(item).agenda_event_type,
                                                    agenda_date_jour: JSON.parse(item).agenda_date_jour,
                                                    agenda_event_start: JSON.parse(item).agenda_event_start,
                                                    agenda_event_end: JSON.parse(item).agenda_event_end,
                                                    event_duration_hour: JSON.parse(item).event_duration_hour,
                                                    agenda_event_sequence_session_id: JSON.parse(item).agenda_event_sequence_session_id,

                                                }
                                            ))}

                                            columns={columns}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {
                                                /*setgridline_id(newSelectionModel.row.id);
                                                setaddOneSession("0");
                                                setEdite_session("0");
                                                //handleClick_edit_session_From_Line(newSelectionModel.row.id);
                                
                                                setselected_session_id(newSelectionModel.row._id);
                                
                                                setsession_selected_row_id(newSelectionModel.row.id);
                                
                                                setDialog_2_message(String(JSON.parse(rowss[newSelectionModel.row.id]).title));
                                                setDialog_2_open(true);*/

                                            }}



                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {

                                                /*// Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {
                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }*/

                                            }}

                                            getRowClassName={(params) => {

                                                /* // Pour la gestion de la couleur de zone double cliquée
                                                 if (String(params.row.id) === String(gridline_id)) {
 
                                                     return 'line--statut--selected';
                                                 }
                                                 else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                     return 'line--statut--pair';
                                                 }
                                                 else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                     return 'line--statut--impair';
                                                 }*/

                                            }}
                                            getEstimatedRowHeight={() => 200}
                                            getRowHeight={() => "auto"}


                                        />
                                    </Box>
                                    <br />

                                </div>
                                {Getall_Qry_RH_Data_result &&
                                    Getall_Qry_RH_Data_result.map((my_data) => (
                                        <div >

                                            {JSON.parse(my_data).total_duration &&
                                                <nav style={{
                                                    "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "600", "fontSize": "1.3rem",
                                                    "color": '#6495ED', "marginBottom": "10px"
                                                }}>
                                                    Durée totale : {JSON.parse(my_data).total_duration}

                                                </nav>}



                                        </div>
                                    ))}

                            </div>
                        </div>
                    </Box>

                    <hr className="hr_break" />
                    <br />

                    <Box >
                        &nbsp;

                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',

                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel_task_cost(newSelectionModel);
                                                /*if (newSelectionModel.length === 1)
                                                    handleClick_edit_session_From_Line(newSelectionModel);*/
                                                if (newSelectionModel.length !== 1) {
                                                    /*  setaddOneParticipant();
                                                      setdisplay_detail_insc();
                                                      setselected_code_session();
                                                      setsubmenu();*/
                                                }
                                            }}
                                            selectionModel={selectionModel_task_cost}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Qry_RH_Task_Cost_Data_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    rh_civilite: JSON.parse(item).rh_civilite,
                                                    rh_nom: JSON.parse(item).rh_nom,
                                                    rh_prenom: JSON.parse(item).rh_prenom,
                                                    rh_email: JSON.parse(item).rh_email,
                                                    rh_ismanager: JSON.parse(item).rh_ismanager,
                                                    rh_fonction: JSON.parse(item).rh_fonction,
                                                    rh_telephone_mobile: JSON.parse(item).rh_telephone_mobile,
                                                    rh_adr_adresse: JSON.parse(item).rh_adr_adresse,
                                                    rh_adr_code_postal: JSON.parse(item).rh_adr_code_postal,
                                                    rh_adr_ville: JSON.parse(item).rh_adr_ville,
                                                    rh_adr_pays: JSON.parse(item).rh_adr_pays,
                                                    rh_contrat_date_debut: JSON.parse(item).rh_contrat_date_debut,
                                                    rh_contrat_date_fin: JSON.parse(item).rh_contrat_date_fin,
                                                    rh_contrat_type_contrat: JSON.parse(item).rh_contrat_type_contrat,
                                                    rh_contrat_type_employe: JSON.parse(item).rh_contrat_type_employe,
                                                    rh_contrat_cout: JSON.parse(item).rh_contrat_cout,
                                                    rh_contrat_periodicite: JSON.parse(item).rh_contrat_periodicite,
                                                    rh_contrat_quantite: JSON.parse(item).rh_contrat_quantite,
                                                    rh_contrat_groupe_prix_achat_id: JSON.parse(item).rh_contrat_groupe_prix_achat_id,

                                                    rh_contrat_groupe_prix_achat_code: JSON.parse(item).rh_contrat_groupe_prix_achat_code,
                                                    rh_contrat_groupe_prix_achat_cout: JSON.parse(item).rh_contrat_groupe_prix_achat_cout,
                                                    rh_contrat_groupe_prix_achat_periodicite: JSON.parse(item).rh_contrat_groupe_prix_achat_periodicite,
                                                    rh_event_planning_event_title: JSON.parse(item).rh_event_planning_event_title,
                                                    rh_event_planning_even_comment: JSON.parse(item).rh_event_planning_even_comment,
                                                    rh_event_planning_event_type: JSON.parse(item).rh_event_planning_event_type,
                                                    rh_event_planning_event_duration_hour: JSON.parse(item).rh_event_planning_event_duration_hour,
                                                    rh_event_planning_agenda_date_jour: JSON.parse(item).rh_event_planning_agenda_date_jour,
                                                    rh_event_planning_event_start: JSON.parse(item).rh_event_planning_event_start,
                                                    rh_event_planning_event_end: JSON.parse(item).rh_event_planning_event_end,
                                                    rh_event_planning_event_cost: JSON.parse(item).rh_event_planning_event_cost,

                                                }
                                            ))}

                                            columns={columns_task_cost}
                                            pageSize={10}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {

                                            }}



                                            rowsPerPageOptions={[10]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {

                                                /*  // Pour la gestion de la couleur de zone double cliquée
                                                  if (String(params.row.id) === String(gridline_id)) {
                                                      return 'line--statut--selected';
                                                  }
                                                  else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                      return 'line--statut--pair';
                                                  }
                                                  else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                      return 'line--statut--impair';
                                                  }*/

                                            }}

                                            getRowClassName={(params) => {

                                                /* // Pour la gestion de la couleur de zone double cliquée
                                                 if (String(params.row.id) === String(gridline_id)) {
     
                                                     return 'line--statut--selected';
                                                 }
                                                 else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                     return 'line--statut--pair';
                                                 }
                                                 else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                     return 'line--statut--impair';
                                                 }*/

                                            }}
                                            getEstimatedRowHeight={() => 200}
                                            getRowHeight={() => "auto"}


                                        />
                                    </Box>
                                    <br />

                                </div>
                                {Getall_Qry_RH_Task_Cost_Data_result &&
                                    Getall_Qry_RH_Task_Cost_Data_result.map((my_data) => (
                                        <div >

                                            {JSON.parse(my_data).total_duration &&
                                                <nav style={{
                                                    "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "600", "fontSize": "1.3rem",
                                                    "color": '#6495ED'
                                                }}>
                                                    Durée totale : {JSON.parse(my_data).total_duration}

                                                </nav>}

                                            {JSON.parse(my_data).total_cost &&
                                                <nav style={{
                                                    "paddingLeft": "5px", "textAlign": "left", "width": "100%", "fontWeight": "600", "fontSize": "1.3rem",
                                                    "color": '#FF7F50', "marginBottom": "10px"
                                                }}>
                                                    Coût total : {JSON.parse(my_data).total_cost}

                                                </nav>}

                                        </div>
                                    ))}
                            </div>

                        </div>


                    </Box>


                </div>}

                {String(submenu) === String("rapport_activite") && <div style={{ "border": "None" }}>
                    <h3> Rapport d'activité </h3>
                    <Box >
                        <div className="session_data" >

                            {Getall_Qry_Inscription_Data_result_JSON && Getall_Qry_Inscription_Data_result_JSON.length > 0 &&
                                Getall_Qry_Inscription_Data_result_JSON[0].data &&
                                <div>
                                    <div className="titre1"> Les Inscriptions </div>
                                    <div className="parent_group_by">

                                        <Button variant="outlined" onClick={display_inscrit_by_periode} className="detail_class_submenu" id='montbd' name='montbd' > Par Période </Button>
                                        <Button variant="outlined" onClick={display_inscrit_by_formation} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par Ftion </Button>
                                        <Button variant="outlined" onClick={display_inscrit_by_session} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par Session   </Button>
                                        <Button variant="outlined" onClick={display_inscrit_by_periode_cumule} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par période (Cumulé)   </Button>

                                    </div>



                                    <BarChart

                                        dataset={Getall_Qry_Inscription_Data_result_JSON[0].data}
                                        // xAxis={[{ scaleType: 'band', dataKey: 'label', tickPlacement: 'middle' }]}
                                        xAxis={[{
                                            scaleType: 'band', dataKey: 'label', tickPlacement: 'middle',
                                            valueFormatter: (month, context) => {

                                                if (month) {
                                                    if (String(month).length < 4) {
                                                        return `${month.slice(0, 4)}`
                                                    }

                                                    else if (String(month).length >= 4 && String(month).length <= 8) {
                                                        return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, String(month).length)}`
                                                    }

                                                    else if (String(month).length > 8) {
                                                        return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, 8)}` + " \n" + `${month.slice(8, 12)}`
                                                    }
                                                }

                                            },

                                        }]
                                        }

                                        series={[

                                            { dataKey: 'count', label: 'Nb Inscrits', valueFormatter_count, color: '#1a75ff', },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}


                                    />
                                </div>
                            }

                            <hr className="hr_break" />
                            <br />


                            {Getall_Qry_Formation_Session_result_JSON && Getall_Qry_Formation_Session_result_JSON.length > 0 &&
                                Getall_Qry_Formation_Session_result_JSON[0].data &&
                                <div>
                                    <div className="titre1"> Les Sessions de formation  </div>

                                    <div className="parent_group_by">

                                        <Button variant="outlined" onClick={display_session_by_periode} className="detail_class_submenu" id='montbd' name='montbd' > Par Période </Button>
                                        <Button variant="outlined" onClick={display_session_by_formation} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par Ftion </Button>
                                        <Button variant="outlined" onClick={display_session_by_periode_cumule} className="detail_class_submenu" id='rapport_activite' name='rapport_activite' >Par période (Cumulé)   </Button>

                                    </div>



                                    <BarChart

                                        dataset={Getall_Qry_Formation_Session_result_JSON[0].data}
                                        // xAxis={[{ scaleType: 'band', dataKey: 'label', tickPlacement: 'middle' }]}
                                        xAxis={[{
                                            scaleType: 'band', dataKey: 'label', tickPlacement: 'middle',
                                            valueFormatter: (month, context) => {
                                                if (month) {
                                                    if (String(month).length < 4) {
                                                        return `${month.slice(0, 4)}`
                                                    }

                                                    else if (String(month).length >= 4 && String(month).length <= 8) {
                                                        return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, String(month).length)}`
                                                    }

                                                    else if (String(month).length > 8) {
                                                        return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, 8)}` + " \n" + `${month.slice(8, 12)}`
                                                    }
                                                }
                                            },
                                        }
                                        ]
                                        }

                                        series={[

                                            { dataKey: 'count', label: 'Nb Sessions', valueFormatter_count, color: '#00802b', },

                                        ]}
                                        {...chartSetting}
                                        margin={{ right: 15, left: 100 }}


                                    />
                                </div>
                            }

                            <hr className="hr_break" />
                            <br />

                            {Getall_Qry_Session_Taux_Remplissage_result_JSON[0] && Getall_Qry_Session_Taux_Remplissage_result_JSON[0].taux_replissage &&
                                Getall_Qry_Session_Taux_Remplissage_result_JSON[0].taux_non_replissage &&
                                <div>
                                    <div className="titre1"> Taux de remplissage des sessions </div>
                                    <Box >
                                        &nbsp;


                                        <BarChart


                                            series={[
                                                { ...Getall_Qry_Session_Taux_Remplissage_result_JSON[0].taux_replissage, stack: 'total', color: '#00802b', },
                                                { ...Getall_Qry_Session_Taux_Remplissage_result_JSON[0].taux_non_replissage, stack: 'total', color: '#ffcc00', },

                                            ]}

                                            xAxis={[{
                                                data: Getall_Qry_Session_Taux_Remplissage_result_JSON[0].axis_data, tickPlacement: 'middle',
                                                scaleType: 'band',
                                                valueFormatter: (month, context) => {

                                                    if (month) {
                                                        if (String(month).length < 4) {
                                                            return `${month.slice(0, 4)}`
                                                        }

                                                        else if (String(month).length >= 4 && String(month).length <= 8) {
                                                            return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, String(month).length)}`
                                                        }

                                                        else if (String(month).length > 8) {
                                                            return `${month.slice(0, 4)}` + " \n" + `${month.slice(4, 8)}` + " \n" + `${month.slice(8, 12)}`
                                                        }
                                                    }

                                                }
                                            }]}
                                            {...chartSetting_V2}
                                            margin={{ right: 15, left: 100 }}


                                        />


                                    </Box>
                                </div>}

                        </div>

                    </Box>


                </div>}

                {String(submenu) === String("bpf") && <div style={{ "border": "None" }}>
                    <h3> Bilan Pédagogique et Financier (BPF)  </h3>
                    <div className="titre1"> C. Bilan financier hors taxes : Origine des produits de l'organisme </div>
                    <div className="div_row">

                        <div style={{ "width": '100%', "float": "left" }}>

                            <div style={{ "width": '70%', "float": "left" }}>
                                Produits provenant :
                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "25px" }}>
                                &nbsp;
                            </div>


                        </div>
                        <div style={{ "width": '100%', "float": "left" }}>

                            <div style={{ "width": '70%', "float": "left", "fontWeight": "600" }}>
                                - Des entreprises pour la formation de leurs salariés  :
                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "15px", "fontWeight": "600" }}
                                className="bpf_blck_valeur1">

                                {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c1_entreprise &&
                                    Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c1_entreprise.length > 0 && <nav>
                                        {parseFloat(String(Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c1_entreprise[0].bpf_c1_entreprise)).toLocaleString()}&nbsp;€
                                    </nav>}

                            </div>

                        </div>

                        <div style={{ "width": '100%', "float": "left", "marginTop": "2rem" }}>

                            <div style={{ "width": '70%', "float": "left" }}>
                                - Des organismes gestionnaires des fonds de la formation professionnelle pour des actions dispensées dans le cadre :
                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "25px" }}>
                                &nbsp;
                            </div>


                        </div>

                        {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c2_type_apprenant &&
                            Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c2_type_apprenant.length > 0 && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c2_type_apprenant.map((my_bpf_c2_data) => (
                                <div>
                                    <div style={{ "width": '100%', "float": "left", "marginBottom": "5px" }}>

                                        <div style={{ "width": '70%', "float": "left", "paddingLeft": "3rem", "fontWeight": "600" }}>
                                            <nav>
                                                {my_bpf_c2_data.Type_Apprenant_Desc.charAt(0).toUpperCase() + my_bpf_c2_data.Type_Apprenant_Desc.slice(1)}
                                            </nav>
                                        </div>

                                        <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "40px", "fontWeight": "600" }}
                                            className="bpf_blck_valeur1">

                                            <nav>
                                                {parseFloat(String(my_bpf_c2_data.TotalAmount_HT)).toLocaleString()}&nbsp;€
                                            </nav>

                                        </div>

                                    </div>


                                </div>))}

                        <br />

                        <div style={{ "width": '100%', "float": "left" }}>

                            <div style={{ "width": '70%', "float": "left", "fontWeight": "600" }}>
                                - Total des produits provenant des organismes gestionnaires des fonds de la formation   :
                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "15px", "fontWeight": "600" }}
                                className="bpf_blck_valeur1" >

                                {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.total_bpf_c_type_apprenant && <nav>
                                    {Getall_Qry_BPF_PAVE_Data_result_JSON.total_bpf_c_type_apprenant}&nbsp;€
                                </nav>}

                            </div>

                        </div>

                        <br />
                        <br />
                        <div style={{ "width": '100%', "float": "left", "marginTop": "2rem" }}>

                            <div style={{ "width": '70%', "float": "left" }}>
                                Des pouvoirs publics  :
                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "25px" }}>
                                &nbsp;
                            </div>


                        </div>

                        {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c3_c8_type_pouvoir_public &&
                            Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c3_c8_type_pouvoir_public.length > 0 && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c3_c8_type_pouvoir_public.map((my_bpf_c3_c8_data) => (
                                <div className="div_row">
                                    <div style={{ "width": '100%', "float": "left" }}>

                                        <div style={{ "width": '70%', "float": "left", "paddingLeft": "3rem", "fontWeight": "600" }}>
                                            <nav>
                                                {my_bpf_c3_c8_data.Type_pouvoir_public_desc.charAt(0).toUpperCase() + my_bpf_c3_c8_data.Type_pouvoir_public_desc.slice(1)}

                                            </nav>
                                        </div>

                                        <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}
                                            className="bpf_blck_valeur1">

                                            <nav>
                                                {parseFloat(String(my_bpf_c3_c8_data.TotalAmount_HT)).toLocaleString()}&nbsp;€
                                            </nav>

                                        </div>

                                    </div>


                                </div>))}

                        <br />

                        <div style={{ "width": '100%', "float": "left" }}>

                            <div style={{ "width": '70%', "float": "left", "fontWeight": "600", "marginTop": "2rem" }}>
                                - De contrats conclus avec des personnes à titre individuel et à leurs frais  <br />

                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "15px", "fontWeight": "600" }}
                                className="bpf_blck_valeur1">

                                {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c9_is_company_particulier &&
                                    Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c9_is_company_particulier.length > 0 && <nav>
                                        {parseFloat(String(Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_c9_is_company_particulier[0].TotalAmount_HT)).toLocaleString()}&nbsp;€
                                    </nav>}

                            </div>

                        </div>

                        <div style={{ "width": '100%', "float": "left", "marginTop": "2rem" }}>

                            <div style={{ "width": '70%', "float": "left", "fontWeight": "600" }}>
                                TOTAL DES PRODUITS RÉALISÉS AU TITRE DE LA FORMATION PROFESSIONNELLE :
                            </div>

                            <div style={{ "width": '25%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">
                                {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.total_bpf_c1_c9 && <nav>
                                    {parseFloat(String(Getall_Qry_BPF_PAVE_Data_result_JSON.total_bpf_c1_c9)).toLocaleString()}&nbsp;€
                                </nav>}
                            </div>


                        </div>

                    </div>
                    <hr className="hr_break" />

                    <div className="titre1"> D. Bilan financier hors taxes : Charges de l'organisme </div>

                    <div style={{ "width": '100%', "float": "left" }}>
                        Ces données peuvent vous être transmises par votre comptable si vous en avez un, ce qui vous fera gagner un temps précieux.
                        Ne pas oublier les charge indirectes (ex : bouteilles d'eau achetées pour les apprenants, d'autres achats ponctuels, notes de frais de vos formateurs, etc)
                    </div>
                    <hr className="hr_break" />

                    <div className="titre1"> E. Personnes dispensant des heures de formation  </div>
                    <div className="div_row">

                        <div className="div_row">

                            <div className="bpf_blck_valeur50" >
                                <nav>
                                    &nbsp;
                                </nav>
                            </div>


                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}>

                                <nav>
                                    Nombre Formateurs
                                </nav>

                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}>

                                <nav>
                                    Nombre d'heures
                                </nav>

                            </div>

                        </div>

                        <div className="div_row">

                            <div className="bpf_blck_valeur50">
                                Personnes de votre organisme dispensant des heures de formation
                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px" }} className="bpf_blck_valeur1">
                                {Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_e1_nb_formateurs_internee}
                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px" }} className="bpf_blck_valeur1">
                                {Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_e1_nb_heures_formateurs_internes}
                            </div>


                        </div>

                        <div className="div_row">

                            <div className="bpf_blck_valeur50">
                                Personnes extérieures à votre organisme dispensant des heures de formation dans le cadre de contrats de sous-traitance
                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px" }} className="bpf_blck_valeur1">
                                {Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_e2_nb_formateurs_externes}
                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px" }} className="bpf_blck_valeur1">
                                {parseFloat(String(Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_e2_nb_heures_formateurs_externes)).toLocaleString()}
                            </div>


                        </div>


                    </div>

                    <hr className="hr_break" />

                    <div className="titre1"> F1. Les différents types de stagiaires au sein de votre organisme </div>
                    <div className="div_row">
                        <div className="div_row">

                            <div className="bpf_blck_valeur50" >
                                <nav>
                                    &nbsp;
                                </nav>
                            </div>


                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}>

                                <nav>
                                    Nombre apprenants
                                </nav>

                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}>

                                <nav>
                                    Nombre d'heures
                                </nav>

                            </div>

                        </div>
                    </div>

                    {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_f1_type_apprenant &&
                        Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_f1_type_apprenant.length > 0 && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_f1_type_apprenant.map((my_bpf_f1_data) => (
                            <div className="div_row">
                                <div className="div_row">

                                    <div className="bpf_blck_valeur50" >
                                        <nav>
                                            {my_bpf_f1_data.Type_Apprenant_Desc.charAt(0).toUpperCase() + my_bpf_f1_data.Type_Apprenant_Desc.slice(1)}

                                        </nav>
                                    </div>

                                    <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                        <nav>
                                            {my_bpf_f1_data.total_bpf_f1_type_apprenant}
                                        </nav>

                                    </div>

                                    <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                        {my_bpf_f1_data.total_bpf_f1_nb_heure_formation && <nav>
                                            {parseFloat(String(my_bpf_f1_data.total_bpf_f1_nb_heure_formation)).toLocaleString()} (h)
                                        </nav>}

                                    </div>

                                </div>
                            </div>))}

                    <div className="div_row">
                        <div className="div_row">

                            <div className="bpf_blck_valeur50" >
                                <nav>
                                    TOTAL
                                </nav>
                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                <nav>
                                    {Getall_Qry_BPF_PAVE_Data_result_JSON.total_f1_apprenant}
                                </nav>

                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                <nav>
                                    {parseFloat(String(Getall_Qry_BPF_PAVE_Data_result_JSON.total_f1_nb_heure)).toLocaleString()} (h)

                                </nav>

                            </div>

                        </div>
                    </div>

                    <div className="div_row">
                        <div className="div_row">

                            <div className="bpf_blck_valeur50" >
                                <nav>
                                    Dont Formation à <font style={{ "color": "red" }}> distance </font>
                                </nav>
                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                <nav>
                                    {Getall_Qry_BPF_PAVE_Data_result_JSON.total_f1_apprenant_a_distance}
                                </nav>

                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                <nav>
                                    &nbsp;

                                </nav>

                            </div>

                        </div>
                    </div>

                    <hr className="hr_break" />

                    <div className="titre1"> F3. L’objectif général des prestations dispensées </div>
                    <div className="div_row">
                        <div className="div_row">

                            <div className="bpf_blck_valeur50" >
                                <nav>
                                    &nbsp;
                                </nav>
                            </div>


                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}>

                                <nav>
                                    Nombre apprenants
                                </nav>

                            </div>

                            <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }}>

                                <nav>
                                    Nombre d'heures
                                </nav>

                            </div>

                        </div>
                    </div>

                    {Getall_Qry_BPF_PAVE_Data_result_JSON && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_f3_class_niveau_formation &&
                        Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_f3_class_niveau_formation.length > 0 && Getall_Qry_BPF_PAVE_Data_result_JSON.bpf_f3_class_niveau_formation.map((my_bpf_f_data) => (
                            <div className="div_row">
                                <div className="div_row">

                                    <div className="bpf_blck_valeur50" >
                                        <nav>
                                            {my_bpf_f_data.Class_Level_Desc}
                                        </nav>
                                    </div>

                                    <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                        <nav>
                                            {my_bpf_f_data.Nb_Apprenant_By_Class_Level}
                                        </nav>

                                    </div>

                                    <div style={{ "width": '23%', "float": "right", textAlign: "right", paddingRight: "25px", "fontWeight": "600" }} className="bpf_blck_valeur1">

                                        {my_bpf_f_data.total_nb_heure_formation_by_level && <nav>
                                            {parseFloat(String(my_bpf_f_data.total_nb_heure_formation_by_level)).toLocaleString()} (h)
                                        </nav>}

                                    </div>

                                </div>
                            </div>))}



                    <hr className="hr_break" />

                    <div className="div_row">

                        <Button style={{ width: "10rem", float: "right", "marginRight": "1rem" }}
                            onClick={(e) => {
                                Export_BPF_PDF();

                            }}
                        >
                           Imprimer &nbsp; <FcPrint/> &nbsp; 

                        </Button>


                      
                    </div>
                </div>}

                {String(submenu) === String("montbd") && <div style={{ "border": "None" }}>

                    <h3> Mon tableau de bord </h3>

                    <div className="div_row"> &nbsp;</div>
                    <div className="div_row" style={{ "textAlign": "right", "paddingRight": "20px" }}> Affichage &nbsp;
                        <Tooltip className="tooltip_css" id="my_tooltip_1_col" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my_tooltip_1_col" data-tooltip-html="Affichage sur une colonne">
                            <TbColumns1 style={{ "cursor": "pointer" }} onClick={(e) => {
                                setdisplay_nb_column("1");
                                setdisplay_column_size("95%");
                            }} />
                        </a>  &nbsp; &nbsp;
                        <Tooltip className="tooltip_css" id="my_tooltip_2_col" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my_tooltip_2_col" data-tooltip-html="Affichage sur deux colonnes">
                            <TbColumns2 style={{ "cursor": "pointer" }} onClick={(e) => {
                                setdisplay_nb_column("2");
                                setdisplay_column_size("48%");
                            }} />
                        </a> </div>


                    {/*   Gestion des TBD des Inscriptions  */}

                    {display_nb_column && <div>
                        {Get_Connected_User_Dashbord_result &&
                            Get_Connected_User_Dashbord_result.map((step) => (

                                <div className="div_row" >
                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_inscription_01" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>
                                            <Dashbord_Inscriptions_all_inscriptions user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)}
                                                user_dashbord_filter_group_by={String(JSON.parse(step).group_by)}

                                            />
                                        </div>
                                    }


                                </div>

                            ))}
                    </div>}

                    {/*   Gestion des TBD des sessions et formations  */}
                    {display_nb_column && <div>
                        {Get_Connected_User_Dashbord_result &&
                            Get_Connected_User_Dashbord_result.map((step) => (

                                <div className="div_row">

                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_session_01" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>

                                            <Dashbord_Session_Taux_Remplissage user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }

                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_session_02" &&

                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>
                                            <Dashbord_Session_Par_Fteur user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }


                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_session_03" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>

                                            <Dashbord_Session_Repartition_Fteur user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }

                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_code_formation_01" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>

                                            <Dashbord_Formation_Evol_Session user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }


                                </div>

                            ))}
                    </div>}



                    {/*   Gestion des TBD des factures */}
                    {display_nb_column && <div>
                        {Get_Connected_User_Dashbord_result &&
                            Get_Connected_User_Dashbord_result.map((step) => (

                                <div className="div_row">


                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_facture_01" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>
                                            <Dashbord_Factures_Par_Periode user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }

                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_facture_02" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>
                                            <Dashbord_Factures_Par_Client user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }

                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_facture_03" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>
                                            <Dashbord_Factures_Devis_Par_Periode user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }


                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_facture_04" &&
                                        <div style={{ "width": String(display_column_size), "float": "left", "paddingRight": "2px" }}>
                                            <Dashbord_Factures_Previsionnel_Par_Periode user_dashbord_id={String(JSON.parse(step)._id)}
                                                user_dashbord_title={String(JSON.parse(step).title)}
                                                user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                                user_dashbord_filter_end={String(JSON.parse(step).end_date)} />
                                        </div>
                                    }



                                </div>

                            ))}
                    </div>}

                    {/*   Gestion des TBD des ressources humaines */}
                    {display_nb_column && <div>
                        {Get_Connected_User_Dashbord_result &&
                            Get_Connected_User_Dashbord_result.map((step) => (

                                <div className="div_row" style={{ "width": "95%", "float": "left", "paddingRight": "2px" }}>



                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_rh_01" &&
                                        <Dashbord_Ressources_Humaines_Tache_by_rhid user_dashbord_id={String(JSON.parse(step)._id)}
                                            user_dashbord_title={String(JSON.parse(step).title)}
                                            user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                            user_dashbord_filter_end={String(JSON.parse(step).end_date)}
                                            user_dashbord_filter_type_envent={String(JSON.parse(step).type_event)}

                                        />
                                    }

                                    {String(JSON.parse(step).dashbord_internal_code) === "tbd_rh_02" &&
                                        <Dashbord_Ressources_Humaines_Tache_Couts user_dashbord_id={String(JSON.parse(step)._id)}
                                            user_dashbord_title={String(JSON.parse(step).title)}
                                            user_dashbord_filter_start={String(JSON.parse(step).start_date)}
                                            user_dashbord_filter_end={String(JSON.parse(step).end_date)}
                                            user_dashbord_filter_type_envent={String(JSON.parse(step).type_event)}

                                        />
                                    }




                                </div>

                            ))}
                    </div>}


                    {String(Get_Connected_User_Dashbord_api) === "true" && (!Get_Connected_User_Dashbord_result || Get_Connected_User_Dashbord_result.length <= 0) && <div>


                        <div className="div_row">
                            <p className="text_tbd_vide"> Pour utiliser votre tableau de bord, Merci de le configurer à partir du menu : "Configuration" == &gt; "Tableau de bord"</p>

                        </div>

                    </div>}

                </div>}
            </div>

        </div >
    )
}


export default Mon_Tableau_De_Bord;