import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import img_visa_mastercard from "./../mysy_img/visa_mastercard.png"
import img_paypal from "./../mysy_img/paypal.png"
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import { getCookie } from 'react-use-cookie';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import moment from 'moment'
import img_logo_stripe from  "./../mysy_img/logo-stripe-secure.png";


function PaymentMethode(props) {
    const [menu, setmenu] = useState("cb");
    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");

    const [paymentadded, setpaymentadded] = useState("");

    const [nom_carte, setnom_carte] = useState("");
    const [num_carte, setnum_carte] = useState("");
    const [date_exp, setdate_exp] = useState("");
    const [cvv, setcvv] = useState("");



    function menu_sepa() {
        setmenu("sepa");
    }

    function menu_cb() {
        setmenu("cb");
    }

    function handleChange1(prop) {
        return (event) => {
            setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
        };
    }

    function handleChange2(prop) {
        return (event) => {
            setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
        };
    }
    const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
    const [currentpawdvisible2, setcurrentpawdvisible2] = useState(false);

    const handleClickShowPassword_current_pwd = () => {
        var filtre2_value = document.getElementsByName("pwd")[0].value;

        if (currentpawdvisible == false) {
            document.getElementsByName("pwd")[0].type = "text";
            setcurrentpawdvisible(true);
        }
        else if (currentpawdvisible == true) {
            document.getElementsByName("pwd")[0].type = "password";
            setcurrentpawdvisible(false);
        }

    };

    const handleClickShowPassword_current_pwd2 = () => {
        var filtre2_value = document.getElementsByName("secret")[0].value;

        if (currentpawdvisible2 == false) {
            document.getElementsByName("secret")[0].type = "text";
            setcurrentpawdvisible2(true);
        }
        else if (currentpawdvisible2 == true) {
            document.getElementsByName("secret")[0].type = "password";
            setcurrentpawdvisible2(false);
        }

    };


    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });



    const [valuesPass1, setValuesPass1] = React.useState({
        password1: '',
        showPassword1: false,
    });

    const [valuesPass2, setValuesPass2] = React.useState({
        password2: '',
        showPassword2: false,
    });


    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }


    function RecordData() {


        if (!document.getElementsByName("cgp")[0].checked) {
            alert(" Vous devez avoir lu les conditions de paiement. Merci.");
            return;
        }

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/add_payement_mode/";


        var pwd = document.getElementsByName("pwd")[0].value;
        var secret = document.getElementsByName("secret")[0].value;

        formData.append('token', stored_part);
        formData.append('type', String(menu));
        formData.append('pwd', pwd);
        formData.append('secret', secret);

        if (String(menu) === String("cb")) {
            var nom_carte = document.getElementsByName("nom_carte")[0].value;
            var num_carte = document.getElementsByName("num_carte")[0].value;
            var date_exp_carte = document.getElementsByName("date_exp")[0].value;
            var cvv_carte = document.getElementsByName("cvv")[0].value;
            formData.append('nom_carte', nom_carte);
            formData.append('num_carte', num_carte);
            formData.append('date_exp_carte', date_exp_carte);
            formData.append('cvv_carte', cvv_carte);
        }
        else if (String(menu) === String("sepa")) {
            var nom_compte = document.getElementsByName("nom_compte")[0].value;
            var iban = document.getElementsByName("num_iban")[0].value;
            var bic = document.getElementsByName("bic")[0].value;
            formData.append('nom_compte', nom_compte);
            formData.append('iban', iban);
            formData.append('bic', bic);
        }

        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setmyApiResponse(result['status']);
                setResult(result['message']);

                if (String(result['status']) === String("true")) {
                    setpaymentadded("1");
                    window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
                }else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setpaymentadded("0");
                }


            })
            .catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("false");
                setpaymentadded("0");
            });


    };

    const [mynote, setmynote] = useState("");
    function CheckCardNumber(e) {
        const val = e.target.value;
        if (!isNaN(val) && String(val).length <= 16) {
            setmynote(val);
        }
        else {
            document.getElementById("num_carte").value = mynote;
        }
    }


    const [dateexp, setdateexp] = useState("");

    function Onleave_expdate_exp(e) {

        var val = document.getElementById("date_exp").value
        if (!moment(val, 'MM/YY', true).isValid()) {
            setdateexp("");
            alert("La date " + val + " n'est pas valide")

        }

        return;
    }


    function Checkdate_expdate_exp(e) {
        const val = e.target.value;

        if (String(val).length < 6) {
            setdateexp(val);
        }

        if (String(val).length == 6) {
            const regex = /^\d{2}\/\d{2}$/;
            const found = dateexp.match(regex);

            if (!found) {
                alert(" pas bon ");
            }
        }

        if (String(val).length > 6) {
            document.getElementById("date_exp").value = dateexp;
        }


    }
    useEffect(() => {

        //console.log(" props.mypaymentmode = " + props.mypaymentmode);
        
                var num_carte = "XXXX"+JSON.parse(props.mypaymentmode).last4;
                setnum_carte(num_carte);
        
                var exp_data = JSON.parse(props.mypaymentmode).exp_month+"/"+JSON.parse(props.mypaymentmode).exp_year;
                setdate_exp(exp_data);
        

        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, []);


    return (
        <div className="paymentmethode">
            <div className="row_gauche_bis">
            mypaymentmode = {props.mypaymentmode}

                <div className="div_row_central">
                    {String(paymentadded) === String("1") && <div className="okUpdateData">
                        Le mode de Paiement a été correctement ajouté
                    </div>
                    }

                    {String(paymentadded) === String("0") && <div className="koUpdateData">
                        {result}
                    </div>
                    }

                    <div className="titre1" onClick={menu_cb}> Prélèvement par carte bancaire </div>
                    {String(menu) === String("cb") && <div>

                        <div className="div_row">
                            <div className="div_row_gauche" style={{ "minHeight": "4rem" }}> Carte Bancaire </div>
                            <div className="div_row_droite" style={{ "textAlign": "right", "maxHeight": "rem" }}>
                                <img src={img_logo_stripe} className="img_payment" /> &nbsp;
                               
                            </div>
                        </div>
                         &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                            <TextField
                                required

                                label="Nom inscrit sur la carte "
                                name="nom_carte"
                                value={(JSON.parse(props.mypaymentmode).nom_carte)}
                                className="texte_area"
                                sx={{ m: 1, width: '95%' }}

                                variant="standard"
                            />

                            <TextField
                                required
                                //onChange={CheckCardNumber}
                                label="Numéro de carte"
                                name="num_carte"
                                id="num_carte"
                                value={"XXXX"+(JSON.parse(props.mypaymentmode).last4)}
                                autoComplete="off"
                                className="texte_area"
                                sx={{ m: 1, width: '95%' }}

                                variant="standard"
                            />

                            <TextField
                                required

                                label="Expiration (MM/AA)"
                                name="date_exp"
                                id="date_exp"
                                autoComplete="new-qsq"
                                value={(JSON.parse(props.mypaymentmode).exp_month)+"/"+(JSON.parse(props.mypaymentmode).exp_year)}
                                className="texte_area"
                                sx={{ m: 1, width: '45%' }}
                                onChange={Checkdate_expdate_exp}
                                onBlur={Onleave_expdate_exp}
                                variant="standard"
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />

                            <TextField
                                required

                                label="CVV"
                                name="cvv"
                                className="texte_area"
                                sx={{ m: 1, width: '45%' }}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}

                                variant="standard"
                            />



                        </Box>

                    </div>
                    }
                </div>


                <div> &nbsp;</div>

                <hr />
                <div className="div_row_central">
                    <div className="titre1" onClick={menu_sepa}> Prélèvement par virement SEPA</div>

                    {String(menu) === String("sepa") && <div>
                         &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                            <TextField
                                required

                                label="Nom du titulaire du compte"
                                name="nom_compte"
                                className="texte_area"
                                sx={{ m: 1, width: '95%' }}

                                variant="standard"
                            />

                            <TextField
                                required

                                label="IBAN"
                                name="num_iban"
                                className="texte_area"
                                sx={{ m: 1, width: '95%' }}

                                variant="standard"
                            />

                            <TextField
                                required

                                label="BIC"
                                name="bic"
                                className="texte_area"
                                sx={{ m: 1, width: '95%' }}

                                variant="standard"
                            />

                        </Box>

                    </div>}


                </div>
                <div> &nbsp;</div>

                <hr />

                <div className="div_row_central">

                    Pour confirmer le changement, veuillez entrer votre mot de passe et votre clé sécrete <br />

                     &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                        <FormControl sx={{ m: 1, width: '45%' }} variant="standard" size="small" className="texte_area_passwd">
                            <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                            <OutlinedInput
                                id="current_pwd"
                                name="pwd"
                                type={values.showPassword1 ? 'text' : 'password'}
                                value={values.password1}
                                autoComplete='off'
                                onChange={handleChange1('password1')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword_current_pwd}
                                            edge="end"
                                        >
                                            {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Mot de passe"
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 1, width: '45%' }} variant="standard" size="small" className="texte_area_passwd">

                            <InputLabel htmlFor="outlined-adornment-password">Clé secrete</InputLabel>
                            <OutlinedInput
                                id="secret"
                                name="secret"
                                type={values.showPassword2 ? 'text' : 'password'}
                                value={values.password2}

                                onChange={handleChange2('password2')}
                                endAdornment={

                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword_current_pwd2}
                                            edge="end"
                                        >
                                            {currentpawdvisible2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Clé secrete"

                            />
                        </FormControl>


                    </Box>

                </div>



                <div className="div_row_centre">
                    <div style={{
                        "width": "100%", "float": "left", "textAlign": "center", "fontSize": "medium",
                        "marginTop": "1rem", "marginBottom": "1rem"
                    }}>
                        <input type={"checkbox"} unchecked
                            id="cgp"
                            name="cgp"
                            style={{ "textAlign": "left", "width": "auto", "height": "1rem" }} />
                        <label for="cgp" style={{
                            "textAlign": "left", maxWidth: "90%", "fontSize": "small ",
                            "fontWeight": "bold", "color": "red"
                        }}>J'ai lu et j'accepte les conditions ci-dessus.</label>
                    </div>

                    <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
                </div>
            </div>

            <div className="row_droite_bis">
                <div className="div_row_central_droite">
                    <div className="titre1" style={{ "background": "#619E31" }}> Conditions de paiement</div>
                    <b> Veuillez vérifier la liste ci-dessous pour vous assurer que votre prélèvement automatique s'effectue avec succès. </b>
                    <ul>
                        <li> Vous devez activer le prélèvement automatique auprès de votre banque. </li>
                        <li> Vous devez avoir des fonds suffisants sur votre compte au moment de chaque prélèvement. </li>
                        <li> Vous ne devez pas contester le prélèvement de la part de Zervant ou Stripe (notre service de paiement partenaire) auprès de votre banque. </li>
                        <li> Si votre prélèvement est contesté, pour toute raison, nous vous enverrons immédiatement une nouvelle facture,
                            incluant les frais de contestation qui nous sont facturés par la banque (en général 8€ par contestation). Nous vous demanderons également de confirmer votre moyen de paiement dans les 7 jours. </li>
                        <li> Dans l'absence de confirmation de moyen de paiement après la première contestation, ou qu'un autre moyen est contesté, nous enlèverons l'option de prélèvement automatique pour votre compte, et vous demanderons d'utiliser une carte de paiement. </li>

                    </ul>
                </div>



            </div>

        </div >
    );

}

export default PaymentMethode;