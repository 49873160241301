
import Display_Partner_Catalog_DetailClass_new_v2 from "../components/Display_Partner_Catalog_DetailClass_new_v2";
import Navigation from "../components/Navigation";
 
export default function display_partner_catalog_detailclass() {
    return (
        <div>
           <div >
         
            </div>
            <Display_Partner_Catalog_DetailClass_new_v2 />
            
        </div>
    );
}