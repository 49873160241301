import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { tokens } from "./../theme";
import { MdOutlineAddchart, } from "react-icons/md";
import {
    useTheme,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from "@nivo/bar";

import { TbColumns2, TbColumns1 } from "react-icons/tb";
import { BarChart } from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts';
import { pieArcLabelClasses } from '@mui/x-charts/PieChart';

const Dashbord_Inscriptions = (props) => {
    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);
    const [selectionModel, setSelectionModel] = React.useState([]);


    const columns = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        {
            field: 'inscription_status', headerName: 'Inscrit', hide: false, minWidth: 50, flex: 1, maxWidth: 60,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Pré.";
                else if (String(params.value) === "1")
                    return "Ins.";
                else if (String(params.value) === "2")
                    return "Enc.";
                else if (String(params.value) === "-1")
                    return "Ann.";

                else
                    return "?";
            },
        },
        { field: 'apprenant_id', headerName: 'apprenant_id', hide: true, Width: 0, },
        { field: 'civilite', headerName: 'Civ.', hide: false, minWidth: 50, flex: 1, maxWidth: 60 },
        { field: 'nom', headerName: 'Nom', hide: false, flex: 1, minWidth: 20 },
        { field: 'prenom', headerName: 'Prénom', hide: false, flex: 1, minWidth: 200 },
        { field: 'email', headerName: 'Email', hide: false, flex: 1, minWidth: 200 },
        { field: 'telephone', headerName: 'Téléphone', hide: false, minWidth: 50, flex: 1, maxWidth: 100 },
        { field: 'session_class_title', headerName: 'Titre Ftion', hide: false, minWidth: 200, flex: 1, },
        { field: 'session_code_session', headerName: 'Code session', hide: false, minWidth: 100, flex: 1, maxWidth: 150 },
        { field: 'session_date_debut', headerName: 'Session Début', hide: false, minWidth: 150, flex: 1, maxWidth: 200 },
        { field: 'session_date_fin', headerName: 'Session Fin', hide: false, minWidth: 150, flex: 1, maxWidth: 200 },
        { field: 'session_distantiel', headerName: 'Distantiel', hide: true, Width: 20 },
        { field: 'session_presentiel', headerName: 'Présentiel', hide: true, Width: 20 },
    ]


    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));


    const [filter_value, setfilter_value] = useState("d");

    const [filter_type_tache, setfilter_type_tache] = useState("");

    const [filter_group_by, setfilter_group_by] = useState("");

    const [tdb_title, settdb_title] = useState();

    const [display_nb_column, setdisplay_nb_column] = useState("1");
    const [display_column_size, setdisplay_column_size] = useState("100%");

    const [dashbord_internal_code, setdashbord_internal_code] = useState("");
    const [gridline_id, setgridline_id] = useState("");

    const [Getall_Qry_Inscription_Data_result_JSON, setGetall_Qry_Inscription_Data_result_JSON] = useState([]);

    const [Getall_Qry_Inscription_Data_api, setGetall_Qry_Inscription_Data_api] = useState();
    const [Getall_Qry_Inscription_Data_message, setGetall_Qry_Inscription_Data_message] = useState();
    const [Getall_Qry_Inscription_Data_result, setGetall_Qry_Inscription_Data_result] = useState([]);
    function Getall_Qry_Inscription_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);



        var myurl = "";
        if (String(filter_group_by) === "class")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_Group_By_Class/";
        else if (String(filter_group_by) === "session")
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_Group_By_Session/";
        else
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Inscription_By_Session_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Inscription_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Inscription_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Inscription_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log(" ### jsonObj = ", jsonObj)

                setGetall_Qry_Inscription_Data_api("true");
                setGetall_Qry_Inscription_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Inscription_Data_api("false");
                setGetall_Qry_Inscription_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Inscription_Data = ', error);
            setGetall_Qry_Inscription_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    const [Add_To_User_TBD_api, setAdd_To_User_TBD_api] = useState();
    const [Add_To_User_TBD_message, setAdd_To_User_TBD_message] = useState();
    const [Add_To_User_TBD_result, setAdd_To_User_TBD_result] = useState();
    function Add_To_User_TBD(local_dashbord_internal_code) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        //console.log(" ### dashbord_internal_code = ", local_dashbord_internal_code);

        var tdb_title = "default";
        var filter_val = "";
        var local_filter_group_by = "";


        if (document.getElementsByName("tdb_title")[0]) {
            tdb_title = document.getElementsByName("tdb_title")[0].value;
        }

        /*
        if (document.getElementsByName("filtre11")[0]) {
            filter_val = document.getElementsByName("filtre11")[0].value;
        }*/
        if (filter_value) {
            filter_val = filter_value
        }

        if (filter_group_by) {
            local_filter_group_by = filter_group_by;
        }

        // Si filter_val =  "d", alors on va recuperer l'interval de date
        if (String(filter_val) === "d") {
            var date_from = p_filter_date_from;
            var date_to = p_filter_date_to;


            filter_val = "{'session_start_date':'" + String(date_from) + "' , 'session_end_date':'" + String(date_to) + "'}";
        } else {
            filter_val = "{'periode':'" + String(filter_val) + "'}"
        }

        form.append("token", stored_cookie);
        form.append("dashbord_internal_code", local_dashbord_internal_code);
        form.append("default_filter", filter_val);
        form.append("title", tdb_title);
        form.append("group_by", local_filter_group_by);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_To_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Add_To_User_TBD  res.data.message r_class = " + res.data.message);

                setAdd_To_User_TBD_api("true");
                setAdd_To_User_TBD_result(res.data.message);
                settdb_title();
                alert(res.data.message)


            }
            else {
                setAdd_To_User_TBD_api("false");
                setAdd_To_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Add_To_User_TBD = ', error);
            setAdd_To_User_TBD_api("false");
            alert("Impossible d'ajouter le tableau de bord");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {
        Getall_Qry_Inscription_Data();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])


    const New_option_periode = [
        { "id": "m0", "label": "Mois en cours", "value": "m0" },
        { "id": "m1", "label": "Mois précédant ", "value": "m1" },
        { "id": "d", "label": "Choisir des dates", "value": "d" },

    ]

    const New_option_group_by = [
        { "id": "mois", "label": "Mois", "value": "mois" },
        { "id": "class", "label": "Formation", "value": "class" },
        { "id": "session", "label": "Session", "value": "session" },
        { "id": "", "label": "", "value": "" },

    ]


    const valueFormatter_count = (value) => `${value} Insc`;

    const chartSetting = {
        yAxis: [
            {
                label: " Nombre d'inscription ",
            },
        ],
        width: 1000,
        height: 400,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
            },
        },
        margin: 100,
    };



    return (
        <div className="dashbord_session">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Tableaux de bord des Inscriptions </h3>
            <div className="div_row"> &nbsp;</div>

            <div className="div_row" style={{ "textAlign": "right", "paddingRight": "20px" }}> Affichage &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_1_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_1_col" data-tooltip-html="Affichage sur une colonne">
                    <TbColumns1 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("1");
                        setdisplay_column_size("100%");

                    }} />
                </a>  &nbsp; &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_2_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_2_col" data-tooltip-html="Affichage sur deux colonnes">
                    <TbColumns2 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("2");
                        setdisplay_column_size("50%");

                    }} />
                </a> </div>

            <div style={{ "width": "100%", "float": "left" }}>
                <div className="" style={{ "width": "50%", "float": "left" }}>
                    Filtre <br />

                    <Autocomplete
                        disablePortal
                        name="filtre11"
                        id="filtre11"
                        className="disabled_style"
                        options={New_option_periode}
                        onChange={(event, value) => {
                            if (value && value.value) {
                                setfilter_value(value.value);

                            } else {
                                setfilter_value("");
                            }


                        }}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une période"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />


                </div>

                {filter_value && String(filter_value) === "d" &&
                    <div style={{ "width": "100%", "float": "left" }}>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Debut
                                <DatePicker
                                    name="one_sessiondatedebut"
                                    id="one_sessiondatedebut"

                                    selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                    locale='fr-FR'

                                />

                            </div>
                        </div>

                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Fin
                                <DatePicker
                                    name="one_sessiondatefin"
                                    id="one_sessiondatefin"
                                    selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                />

                            </div>
                        </div>

                    </div>}
                <br />
                <div style={{ "width": "100%", "float": "left" }}>
                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Grouper par <br />
                        <Autocomplete
                            disablePortal
                            name="filtre12"
                            id="filtre12"
                            className="disabled_style"
                            options={New_option_group_by}
                            value={New_option_group_by.filter((data) => (data).value === String(filter_group_by))[0].label}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setfilter_group_by(value.value);


                                } else {
                                    setfilter_group_by("");
                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Grouper par"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />
                    </div>

                </div>
                <div className="div_row">
                    <Button variant="contained" className="bton_enreg" onClick={(e) => {
                        Getall_Qry_Inscription_Data();

                    }}> Afficher
                    </Button><br />

                </div>
            </div>


            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>


                <div style={{ "float": "left", "width": String(display_column_size), "height": "42rem", "float": "left", "paddingRight": "5px" }} >
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Employés - Détail Tâche </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("3")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "3" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("RESSOURCES_HUMAINES_01")
                            Add_To_User_TBD("tbd_inscription_01");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}
                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data" style={{ paddingLeft: "2rem" }}>


                            {Getall_Qry_Inscription_Data_result_JSON && Getall_Qry_Inscription_Data_result_JSON.length > 0 &&
                                Getall_Qry_Inscription_Data_result_JSON[0].data &&
                                <BarChart

                                    dataset={Getall_Qry_Inscription_Data_result_JSON[0].data}
                                    xAxis={[{ scaleType: 'band', dataKey: 'label', tickPlacement: 'middle' }]}
                                    series={[

                                        { dataKey: 'count', label: 'Count', valueFormatter_count },

                                    ]}
                                    {...chartSetting}
                                    margin={{ right: 15, left: 100 }}

                                />}
                        </div>

                    </Box>
                    <br />

                    {/*  <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data">
                            <div style={{ "border": "None" }}>

                                <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>
                                    &nbsp;
                                    <Box
                                        sx={{
                                            height: 500,
                                            width: '100%',
                                            paddingRight: '1px',
                                            '& .cell--inscrit': {
                                                backgroundColor: '#2ECC71',
                                                color: '#1a3e72',
                                                fontWeight: 'bold',
                                            },
                                            '& .cell--preinscrit': {
                                                backgroundColor: '#F7DC6F',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--encours': {
                                                backgroundColor: '#D2B4DE',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },
                                            '& .cell--annule': {
                                                backgroundColor: '#ABB2B9',
                                                color: '#1a3e72',
                                                fontWeight: 'light',
                                            },


                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: "#c8cfd5",
                                                color: "black",
                                                fontSize: 14
                                            },

                                            '& .line--statut--selected': {
                                                backgroundColor: '#FBF2EF',
                                                color: 'black',
                                            },
                                            '& .line--statut--pair': {
                                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                                color: 'black',
                                            },
                                            '& .line--statut--impair': {
                                                backgroundColor: '#FFFFFF',
                                                color: 'black',
                                            },

                                        }}
                                    >
                                        <DataGrid
                                            checkboxSelection

                                            onSelectionModelChange={(newSelectionModel) => {
                                                setSelectionModel(newSelectionModel);
                                                
                                            }}
                                            selectionModel={selectionModel}

                                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                            rows={Getall_Qry_Inscription_Data_result.map((item, index) => (
                                                {
                                                    id: index,
                                                    _id: JSON.parse(item)._id,
                                                    civilite: JSON.parse(item).civilite,
                                                    nom: JSON.parse(item).nom,
                                                    prenom: JSON.parse(item).prenom,
                                                    email: JSON.parse(item).email,
                                                    telephone: JSON.parse(item).telephone,
                                                    session_class_title: JSON.parse(item).session_class_title,
                                                    session_code_session: JSON.parse(item).session_code_session,
                                                    session_date_debut: JSON.parse(item).session_date_debut,
                                                    session_date_fin: JSON.parse(item).session_date_fin,
                                                    session_distantiel: JSON.parse(item).session_distantiel,
                                                    session_presentiel: JSON.parse(item).session_presentiel,
                                                    inscription_status: JSON.parse(item).inscription_status,
                                                    apprenant_id: JSON.parse(item).apprenant_id,

                                                }
                                            ))}

                                            columns={columns}
                                            pageSize={20}
                                            className="datagridclass"

                                            onRowDoubleClick={(newSelectionModel) => {

                                            }}



                                            rowsPerPageOptions={[20]}
                                            disableSelectionOnClick
                                            components={{
                                                Toolbar: GridToolbar,
                                            }}
                                            //sx={datagridSx}
                                            getCellClassName={(params) => {

                                                if (params.field === 'inscription_status' && String(params.value) === "-1") {
                                                    return 'cell--annule';
                                                }
                                                if (params.field === "inscription_status" && String(params.value) == "0") {
                                                    return 'cell--preinscrit';
                                                }

                                                if (params.field === "inscription_status" && String(params.value) == "1") {
                                                    return 'cell--inscrit';
                                                }

                                                if (params.field === "inscription_status" && String(params.value) == "2") {
                                                    return '.cell--encours';
                                                }

                                            }}

                                            getRowClassName={(params) => {

                                                // Pour la gestion de la couleur de zone double cliquée
                                                if (String(params.row.id) === String(gridline_id)) {

                                                    return 'line--statut--selected';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                    return 'line--statut--pair';
                                                }
                                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                    return 'line--statut--impair';
                                                }

                                            }}


                                        />
                                    </Box>
                                    <br />

                                </div>

                            </div>

                        </div>



                    </Box>*/}

                </div>
            </div>

        </div>)
}


export default Dashbord_Inscriptions;