import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import img_visa_mastercard from "./../mysy_img/visa_mastercard.png";
import img_logo_stripe from "./../mysy_img/logo-stripe-secure.png";
import img_paypal from "./../mysy_img/paypal.png";
import axios from "axios";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import { getCookie } from 'react-use-cookie';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

function PaymentMethodeNoConnect(props) {
    const [menu, setmenu] = useState("cb");
    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");

    const [paymentadded, setpaymentadded] = useState("");


    function menu_sepa() {
        setmenu("sepa");
    }

    function menu_cb() {
        setmenu("cb");
    }

    function handleChange1(prop) {
        return (event) => {
            setValuesPass1({ ...valuesPass1, [prop]: event.target.value });
        };
    }

    function handleChange2(prop) {
        return (event) => {
            setValuesPass2({ ...valuesPass2, [prop]: event.target.value });
        };
    }
    const [currentpawdvisible, setcurrentpawdvisible] = useState(false);
    const [currentpawdvisible2, setcurrentpawdvisible2] = useState(false);

    const handleClickShowPassword_current_pwd = () => {
        var filtre2_value = document.getElementsByName("pwd")[0].value;

        if (currentpawdvisible == false) {
            document.getElementsByName("pwd")[0].type = "text";
            setcurrentpawdvisible(true);
        }
        else if (currentpawdvisible == true) {
            document.getElementsByName("pwd")[0].type = "password";
            setcurrentpawdvisible(false);
        }

    };

    const handleClickShowPassword_current_pwd2 = () => {
        var filtre2_value = document.getElementsByName("secret")[0].value;

        if (currentpawdvisible2 == false) {
            document.getElementsByName("secret")[0].type = "text";
            setcurrentpawdvisible2(true);
        }
        else if (currentpawdvisible2 == true) {
            document.getElementsByName("secret")[0].type = "password";
            setcurrentpawdvisible2(false);
        }

    };


    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });



    const [valuesPass1, setValuesPass1] = React.useState({
        password1: '',
        showPassword1: false,
    });

    const [valuesPass2, setValuesPass2] = React.useState({
        password2: '',
        showPassword2: false,
    });


    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');


    const [RecordData_api, setRecordData_api] = useState();
    const [RecordData_result, setRecordData_result] = useState();
    const [RecordData_message, setRecordData_message] = useState();
    function RecordData() {

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/add_payement_mode/";


        var pwd = document.getElementsByName("pwd")[0].value;
        var secret = document.getElementsByName("secret")[0].value;

        formData.append('token', stored_part);
        formData.append('type', String(menu));
        formData.append('pwd', pwd);
        formData.append('secret', secret);


        //console.log(" menu = ", menu);

        if (String(menu) === String("cb")) {
            var nom_carte = document.getElementsByName("nom_carte")[0].value;
            var num_carte = document.getElementsByName("num_carte")[0].value;
            var date_exp_carte = document.getElementsByName("date_exp")[0].value;
            var cvv_carte = document.getElementsByName("cvv")[0].value;
            formData.append('nom_carte', nom_carte);
            formData.append('num_carte', num_carte);
            formData.append('date_exp_carte', date_exp_carte);
            formData.append('cvv_carte', cvv_carte);
        }


        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log(' paymenadded_api Success:', result['message'], "result['status'] = ", result['status']);
                setRecordData_api(result['status']);

              
                if (String(result['status']) === String("true")) {
                   
                    setpaymentadded("1");
                    
                }else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                   
                    setpaymentadded("0");

                    
                }
             

            })
            .catch((error) => {
                console.error('Error:', error);
                setRecordData_api("false");

                setpaymentadded("0");
            });


    };

    const [mynote, setmynote] = useState("");
    function CheckCardNumber(e) {
        const val = e.target.value;
        if (!isNaN(val) && String(val).length <= 16) {
            setmynote(val);
        }
        else {
            document.getElementById("num_carte").value = mynote;
        }
    }


    const [dateexp, setdateexp] = useState("");

    function Onleave_expdate_exp(e) {
        const regex = /^\d{2}\/\d{2}$/;
        var val = document.getElementById("date_exp").value
        const found = val.match(regex);

        if (!found) {
            setdateexp("");
            document.getElementById("date_exp").value = "";
        }
    }


    function Checkdate_expdate_exp(e) {
        const val = e.target.value;

        if (String(val).length < 6) {
            setdateexp(val);
        }

        if (String(val).length == 6) {
            const regex = /^\d{2}\/\d{2}$/;
            const found = dateexp.match(regex);

            if (!found) {
                alert(" pas bon ");
            }
        }

        if (String(val).length > 6) {
            document.getElementById("date_exp").value = dateexp;
        }


    }
    useEffect(() => {
        enablefieds1();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    }, []);


    function enablefieds1() {
        document.getElementsByName("nom_carte")[0].disabled = false;
        document.getElementsByName("nom_carte")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("num_carte")[0].disabled = false;
        document.getElementsByName("num_carte")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("date_exp")[0].disabled = false;
        document.getElementsByName("date_exp")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("cvv")[0].disabled = false;
        document.getElementsByName("cvv")[0].style.backgroundColor = "#FFFFFF";

    }
    return (
        <div className="paymentmethode_carte">


            <div className="div_row_central">
                {String(paymentadded) === String("1") && <div className="okUpdateData">
                    Le mode de Paiement a été correctement ajouté

                    {window.location.reload()}
                </div>
                }

                {String(paymentadded) === String("0") && <div className="koUpdateData">
                  Impossible d'ajouter/mettre à jour la carte. 
                  Merci de verifier vos informations.
                    {result}
                </div>
                }

                <div>

                    <div className="div_row_central">
                        <img src={img_logo_stripe} className="img_payment" /> &nbsp;

                    </div>

                     &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                        <TextField
                            required

                            label="Nom inscrit sur la carte"
                            name="nom_carte"
                            className="texte_area_carte"
                           
                            sx={{ m: 1, width: '95%' }}

                           
                        />

                        <TextField
                            required
                            onChange={CheckCardNumber}
                            label="Numéro de carte"
                            name="num_carte"
                            id="num_carte"
                            autoComplete="off"
                            className="texte_area_carte"
                            sx={{ m: 1, width: '95%' }}

                        />

                        <TextField
                            required

                            label="Expiration (MM/AA)"
                            name="date_exp"
                            id="date_exp"
                            autoComplete="new-qsq"
                            value={dateexp}
                            className="texte_area_carte"
                            sx={{ m: 1, width: '45%' }}
                            onChange={Checkdate_expdate_exp}
                            onBlur={Onleave_expdate_exp}
                            
                        />

                        <TextField
                            required
                            autoComplete='off'
                            label="CVV"
                            name="cvv"
                            className="texte_area_carte"
                            sx={{ m: 1, width: '45%' }}

                        />



                    </Box>

                </div>
            </div>


            <br />&nbsp; <br />


            <div className="div_row_central">

                Pour confirmer le changement, veuillez entrer votre mot de passe et votre clé sécrete  <br />

                 &nbsp;
<Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                    <FormControl sx={{ m: 1, width: '100%' }} variant="standard" size="small" className="texte_area_passwd carte_cle_pass">
                        <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                        <OutlinedInput
                            id="current_pwd"
                            name="pwd"
                            type={values.showPassword1 ? 'text' : 'password'}
                            value={values.password1}
                            autoComplete='off'
                            onChange={handleChange1('password1')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_current_pwd}
                                        edge="end"
                                    >
                                        {currentpawdvisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Mot de passe"
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                        />
                    </FormControl>

                    <br />
                    <FormControl sx={{ m: 1, width: '100%' }} variant="standard" size="small" className="texte_area_passwd carte_cle_pass">

                        <InputLabel htmlFor="outlined-adornment-password">Clé secrete</InputLabel>
                        <OutlinedInput
                            id="secret"
                            name="secret"
                            type={values.showPassword2 ? 'text' : 'password'}
                            value={values.password2}

                            onChange={handleChange2('password2')}
                            endAdornment={

                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword_current_pwd2}
                                        edge="end"
                                    >
                                        {currentpawdvisible2 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Clé secrete"

                        />
                    </FormControl>


                </Box>

            </div>

            <div className="div_row_central">
                <div style={{
                    "width": "100%", "float": "left", "textAlign": "center", "fontSize": "small",
                    "marginTop": "1rem", "marginBottom": "1rem"
                }}>
                    <input type={"checkbox"} unchecked
                        id="cgp"
                        name="cgp"
                        style={{ "textAlign": "left", "width": "auto", "height": "1rem" }} />
                    <label for="cgp" style={{
                        "textAlign": "left", maxWidth: "90%", "fontSize": "small ",
                        "color": "red"
                    }}>J'ai lu et j'accepte les conditions générales de paiement </label> <a href='/sample/Mysy_Conditions_Generales_Paiement.pdf' download> (télécharger).</a>
                </div>

            </div>
            <div className="div_row_centre">
                <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregistrer</Button>
            </div>
        </div>
    );

}

export default PaymentMethodeNoConnect;