import React from 'react';
import { getCookie, } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import SideBar from "./sidebar";
import { useCookies } from "react-cookie";
import ButtonHilight from "./ButtonHilight";

const Navigation = () => {

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
  const [cookie_part, setCookie_part, removeCookie_part] = useCookies(['tokenmysypart']);

  return (
    <div className={"navigation"}>
     
      <div className="test_page">
      <div className="bm-burger-button_label">
        </div>
        <SideBar />
        <ButtonHilight />
      </div>

    </div>


  )
}


export default Navigation;