import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcSearch } from "react-icons/fc";
import { FcAlarmClock, FcMultipleSmartphones, FcWorkflow } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import 'moment/locale/fr';

import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ResetTvRounded, RestoreFromTrashRounded } from "@mui/icons-material";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import { confirmAlert } from 'react-confirm-alert';
import ToggleSwitch from "./ToggleSwitch";
import Module_Historique_Action from "./Module_Historique_Action";
import { HiDocumentCheck } from "react-icons/hi2";
import { BsSend } from "react-icons/bs";
import Autocomplete from '@mui/material/Autocomplete';
import { FcOpenedFolder } from "react-icons/fc";
import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import Module_Editique from "./Module_Editique";
import Module_Attestation_Formation from "./Module_Attestation_Formation";

import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcPlanner } from "react-icons/fc";
import { CiBoxList } from "react-icons/ci";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'

const Module_Session_Evaluation = (props) => {

    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : String(value).slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {!value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    function Display_Colunm_text({ value }) {

        return (
            <div style={{ paddingLeft: "5px", paddingRight: "10px", "display": "block", wordBreak: "break-all" }}>
                {value}&nbsp;

            </div>
        );
    }

    const columns_list_evaluation = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'form_code', headerName: 'Form. Code', hide: true, minwidth: 200, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minwidth: 300, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'nom', minwidth: 300, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minwidth: 300, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: 'status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Init.";
                else if (String(params.value) === "1")
                    return "Env.";
                else if (String(params.value) === "2")
                    return "Rép.";
                else
                    return " ?";
            },
        },

        {
            field: 'date_envoie', headerName: 'Date Envoi', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },
        {
            field: 'date_reponse', headerName: 'Date Réponse', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },

    ]


    const columns_list_evaluation_cold = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'form_code', headerName: 'Form. Code', hide: true, minwidth: 200, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minwidth: 300, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'nom', minwidth: 300, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minwidth: 300, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: 'status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Init.";
                else if (String(params.value) === "1")
                    return "Env.";
                else if (String(params.value) === "2")
                    return "Rép.";
                else
                    return " ?";
            },
        },

        {
            field: 'date_envoie', headerName: 'Date Envoi', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },
        {
            field: 'date_reponse', headerName: 'Date Réponse', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },

    ]


    const columns_list_evaluation_enseignant = [
        { field: 'id', headerName: 'id', hide: true },
        { field: '_id', headerName: '_id', hide: true },
        { field: 'form_code', headerName: 'Form. Code', hide: true, minwidth: 200, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', minwidth: 300, flex: 1, hideable: true, resizable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'nom', minwidth: 300, flex: 1, hide: false, editable: true, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', minwidth: 300, flex: 1, hide: false, editable: false, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        {
            field: 'status', headerName: 'Statut', width: 100, editable: false, flex: true,
            valueFormatter: (params) => {
                if (String(params.value) === "0")
                    return "Init.";
                else if (String(params.value) === "1")
                    return "Env.";
                else if (String(params.value) === "2")
                    return "Rép.";
                else
                    return " ?";
            },
        },

        {
            field: 'date_envoie', headerName: 'Date Envoi', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },
        {
            field: 'date_reponse', headerName: 'Date Réponse', width: 150, editable: false,
            valueFormatter: (params) => {
                if (params.value)
                    return String(params.value).substring(0, 16)
                else
                    return ""

            },
        },

    ]

    const [selected_code_session, setselected_code_session] = useState();
    const [selected_session_id, setselected_session_id] = useState(props.related_collection_recid);
    const [selected_internal_url, setselected_internal_url] = useState();
    const [selected_class_title, setselected_class_title] = useState();

    const [one_selected_internal_url, setone_selected_internal_url] = useState();


    const [isLoading, setLoading] = useState();

    const [New_Getall_Training_Employee_No_Filter_result, setNew_Getall_Training_Employee_No_Filter_result] = useState([]);

    const [Getall_Training_Employee_No_Filter_api, setGetall_Training_Employee_No_Filter_api] = useState();
    const [Getall_Training_Employee_No_Filter_message, setGetall_Training_Employee_No_Filter_message] = useState();
    const [Getall_Training_Employee_No_Filter_result, setGetall_Training_Employee_No_Filter_result] = useState();
    function Getall_Training_Employee_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Humaine_no_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Employee_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Employee_No_Filter_api("true");
                setGetall_Training_Employee_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_ismanager = JSON.parse(x).ismanager;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom + " " + local_prenom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "ismanager": local_ismanager
                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "prenom": "",
                    "ismanager": ""
                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Training_Employee_No_Filter_result(new_data2);

            }
            else {
                setGetall_Training_Employee_No_Filter_api("false");
                setGetall_Training_Employee_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Training_Employee_No_Filter = ', error);
            setGetall_Training_Employee_No_Filter_api("false");
            alert(" Impossible de recuperer la liste des employés");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Training_Materiel_No_Filter_result, setNew_Getall_Training_Materiel_No_Filter_result] = useState([]);


    const [Getall_Training_Materiel_No_Filter_api, setGetall_Training_Materiel_No_Filter_api] = useState();
    const [Getall_Training_Materiel_No_Filter_message, setGetall_Training_Materiel_No_Filter_message] = useState();
    const [Getall_Training_Materiel_No_Filter_result, setGetall_Training_Materiel_No_Filter_result] = useState();
    function Getall_Training_Materiel_No_Filter(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Ressource_Materielle_no_filter/";

        setLoading(true);

        axios.post(myurl, form).then(res => {


            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Training_Materiel_No_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Training_Materiel_No_Filter_api("true");
                setGetall_Training_Materiel_No_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_ref_interne = JSON.parse(x).ref_interne;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "ref_interne": local_ref_interne,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Getall_Training_Materiel_No_Filter_result(new_data2);
            }
            else {
                setGetall_Training_Materiel_No_Filter_api("false");
                setGetall_Training_Materiel_No_Filter_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            setLoading(false);
            console.warn('Not good man :( Getall_Training_Materiel_No_Filter = ', error);
            setGetall_Training_Materiel_No_Filter_api("false");
            alert(" Impossible de recuperer la liste du materiel");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Formulaires_hot_evaluation_with_Filter, setNew_Getall_Partner_Formulaires_hot_evaluation_with_Filter] = useState([]);

    const [Getall_Partner_Formulaires_hot_evaluation_with_Filter_api, setGetall_Partner_Formulaires_hot_evaluation_with_Filter_api] = useState();
    const [Getall_Partner_Formulaires_hot_evaluation_with_Filter_message, setGetall_Partner_Formulaires_hot_evaluation_with_Filter_message] = useState();
    const [Getall_Partner_Formulaires_hot_evaluation_with_Filter_result, setGetall_Partner_Formulaires_hot_evaluation_with_Filter_result] = useState([]);
    function Getall_Partner_Formulaires_hot_evaluation_with_Filter(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type", "hot_eval");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Formulaire_with_filter/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Formulaires_hot_evaluation_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Formulaires_hot_evaluation_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Formulaires_hot_evaluation_with_Filter_api("true");
                setGetall_Partner_Formulaires_hot_evaluation_with_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " " + local_nom,
                        "nom": local_nom,
                        "code": local_code,
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Formulaires_hot_evaluation_with_Filter(new_data2);
            }
            else {
                setGetall_Partner_Formulaires_hot_evaluation_with_Filter_api("false");
                setGetall_Partner_Formulaires_hot_evaluation_with_Filter_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Formulaires_hot_evaluation_with_Filter = ', error);
            setGetall_Partner_Formulaires_hot_evaluation_with_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Survey_hot_evaluation_By_Session_api, setGetall_Survey_hot_evaluation_By_Session_api] = useState();
    const [Getall_Survey_hot_evaluation_By_Session_message, setGetall_Survey_hot_evaluation_By_Session_message] = useState();
    const [Getall_Survey_hot_evaluation_By_Session_result, setGetall_Survey_hot_evaluation_By_Session_result] = useState([]);
    function Getall_Survey_hot_evaluation_By_Session(event) {

        setSelectionModel_evaluation([])

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("formulaire_type", "hot_eval");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Survey_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                // console.log(" In Getall_Survey_hot_evaluation_By_Session  res.data.status = " + res.data.status);
                //console.log(" In Getall_Survey_hot_evaluation_By_Session  res.data.message r_class = " + res.data.message);
                setGetall_Survey_hot_evaluation_By_Session_api("true");
                setGetall_Survey_hot_evaluation_By_Session_result(res.data.message);
                setRows_evaluation(res.data.message);

            }
            else {
                setGetall_Survey_hot_evaluation_By_Session_api("false");
                setGetall_Survey_hot_evaluation_By_Session_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Survey_hot_evaluation_By_Session = ', error);
            setGetall_Survey_hot_evaluation_By_Session_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Create_List_Survey_hot_eval_For_Session_For_Inscrit_api, setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_api] = useState();
    const [Create_List_Survey_hot_eval_For_Session_For_Inscrit_message, setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_message] = useState();
    const [Create_List_Survey_hot_eval_For_Session_For_Inscrit_result, setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_result] = useState();
    function Create_List_Survey_hot_eval_For_Session_For_Inscrit() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("formulaire_id", selected_formulaire_id);
        form.append("survey_type", "hot_eval");
        form.append("sending_date", "");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Init_Survey_Tab_For_All_Session_Inscrit/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_api("true");
                setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_result(res.data.message);
                Getall_Survey_hot_evaluation_By_Session();
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_api("false");
                setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Create_List_Survey_hot_eval_For_Session_For_Inscrit : Not good man :( mysearchtext = ' + error);
            setCreate_List_Survey_hot_eval_For_Session_For_Inscrit_api("false");
            alert(" Impossible d'initialiser la liste des enquête de positionnement ");

        })
    }



    /*  Recuperation des evaluations */
    const [selectionModel_evaluation, setSelectionModel_evaluation] = React.useState([]);
    const [rowss_evaluation, setRows_evaluation] = useState([]);


    const [selectionModel_evaluation_cold, setSelectionModel_evaluation_cold] = React.useState([]);
    const [rowss_evaluation_cold, setRows_evaluation_cold] = useState([]);

    const [selectionModel_evaluation_formateur, setselectionModel_evaluation_formateur] = React.useState([]);
    const [rowss_evaluation_formateur, setRows_evaluation_formateur] = useState([]);

    /**** Les évaluations a froid */
    const [New_Getall_Partner_Formulaires_cold_evaluation_with_Filter, setNew_Getall_Partner_Formulaires_cold_evaluation_with_Filter] = useState([]);

    const [Getall_Partner_Formulaires_cold_evaluation_with_Filter_api, setGetall_Partner_Formulaires_cold_evaluation_with_Filter_api] = useState();
    const [Getall_Partner_Formulaires_cold_evaluation_with_Filter_message, setGetall_Partner_Formulaires_cold_evaluation_with_Filter_message] = useState();
    const [Getall_Partner_Formulaires_cold_evaluation_with_Filter_result, setGetall_Partner_Formulaires_cold_evaluation_with_Filter_result] = useState([]);
    function Getall_Partner_Formulaires_cold_evaluation_with_Filter(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type", "cold_eval");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Formulaire_with_filter/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Formulaires_cold_evaluation_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Formulaires_cold_evaluation_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Formulaires_cold_evaluation_with_Filter_api("true");
                setGetall_Partner_Formulaires_cold_evaluation_with_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " " + local_nom,
                        "nom": local_nom,
                        "code": local_code,
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Formulaires_cold_evaluation_with_Filter(new_data2);
            }
            else {
                setGetall_Partner_Formulaires_cold_evaluation_with_Filter_api("false");
                setGetall_Partner_Formulaires_cold_evaluation_with_Filter_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Formulaires_cold_evaluation_with_Filter = ', error);
            setGetall_Partner_Formulaires_cold_evaluation_with_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Formulaires_formateur_evaluation_with_Filter, setNew_Getall_Partner_Formulaires_formateur_evaluation_with_Filter] = useState([]);

    const [Getall_Partner_Formulaires_formateur_evaluation_with_Filter_api, setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_api] = useState();
    const [Getall_Partner_Formulaires_formateur_evaluation_with_Filter_message, setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_message] = useState();
    const [Getall_Partner_Formulaires_formateur_evaluation_with_Filter_result, setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_result] = useState([]);
    function Getall_Partner_Formulaires_formateur_evaluation_with_Filter(event) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("type", "human_eval");

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Formulaire_with_filter/";



        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Formulaires_formateur_evaluation_with_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Formulaires_formateur_evaluation_with_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_api("true");
                setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " " + local_nom,
                        "nom": local_nom,
                        "code": local_code,
                    };
                    new_data2.push(node);
                });

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "code": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Formulaires_formateur_evaluation_with_Filter(new_data2);
            }
            else {
                setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_api("false");
                setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Partner_Formulaires_formateur_evaluation_with_Filter = ', error);
            setGetall_Partner_Formulaires_formateur_evaluation_with_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Survey_cold_evaluation_By_Session_api, setGetall_Survey_cold_evaluation_By_Session_api] = useState();
    const [Getall_Survey_cold_evaluation_By_Session_message, setGetall_Survey_cold_evaluation_By_Session_message] = useState();
    const [Getall_Survey_cold_evaluation_By_Session_result, setGetall_Survey_cold_evaluation_By_Session_result] = useState([]);
    function Getall_Survey_cold_evaluation_By_Session(event) {

        setSelectionModel_evaluation([])

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("formulaire_type", "cold_eval");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Survey_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                // console.log(" In Getall_Survey_cold_evaluation_By_Session  res.data.status = " + res.data.status);
                //console.log(" In Getall_Survey_cold_evaluation_By_Session  res.data.message r_class = " + res.data.message);
                setGetall_Survey_cold_evaluation_By_Session_api("true");
                setGetall_Survey_cold_evaluation_By_Session_result(res.data.message);
                setRows_evaluation_cold(res.data.message);

            }
            else {
                setGetall_Survey_cold_evaluation_By_Session_api("false");
                setGetall_Survey_cold_evaluation_By_Session_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Survey_cold_evaluation_By_Session = ', error);
            setGetall_Survey_cold_evaluation_By_Session_api("false");
            //setmyApimyApiMessage("")
        })
    }



    const [Survey_formateur_evaluation_nom_prenom, setSurvey_formateur_evaluation_nom_prenom] = useState("");


    const [Getall_Survey_formateur_evaluation_By_Session_api, setGetall_Survey_formateur_evaluation_By_Session_api] = useState();
    const [Getall_Survey_formateur_evaluation_By_Session_message, setGetall_Survey_formateur_evaluation_By_Session_message] = useState();
    const [Getall_Survey_formateur_evaluation_By_Session_result, setGetall_Survey_formateur_evaluation_By_Session_result] = useState([]);
    function Getall_Survey_formateur_evaluation_By_Session(event) {

        setselectionModel_evaluation_formateur([])

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("formulaire_type", "human_eval");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Survey_with_filter/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                // console.log(" In Getall_Survey_formateur_evaluation_By_Session  res.data.status = " + res.data.status);
                //console.log(" In Getall_Survey_formateur_evaluation_By_Session  res.data.message r_class = " + res.data.message);

                //console.log(" In Getall_Survey_formateur_evaluation_By_Session  res.data.message r_class[0] = " + res.data.message[0]);

                if (res.data.message[0]) {
                    var local_jon = JSON.parse(res.data.message[0]);
                    //console.log(" ### local_jon = ", local_jon);
                    setSurvey_formateur_evaluation_nom_prenom(local_jon['rh_prenom'] + " " + local_jon['rh_nom'])
                }

                setGetall_Survey_formateur_evaluation_By_Session_api("true");
                setGetall_Survey_formateur_evaluation_By_Session_result(res.data.message);
                setRows_evaluation_formateur(res.data.message);

            }
            else {
                setGetall_Survey_formateur_evaluation_By_Session_api("false");
                setGetall_Survey_formateur_evaluation_By_Session_message(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Survey_formateur_evaluation_By_Session = ', error);
            setGetall_Survey_formateur_evaluation_By_Session_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [Create_List_Survey_cold_eval_For_Session_For_Inscrit_api, setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_api] = useState();
    const [Create_List_Survey_cold_eval_For_Session_For_Inscrit_message, setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_message] = useState();
    const [Create_List_Survey_cold_eval_For_Session_For_Inscrit_result, setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_result] = useState();
    function Create_List_Survey_cold_eval_For_Session_For_Inscrit() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("formulaire_id", selected_formulaire_id);
        form.append("survey_type", "cold_eval");
        form.append("sending_date", date_cold_eval_sending);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Init_Survey_Tab_For_All_Session_Inscrit/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_api("true");
                setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_result(res.data.message);
                Getall_Survey_cold_evaluation_By_Session();
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_api("false");
                setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Create_List_Survey_cold_eval_For_Session_For_Inscrit : Not good man :( mysearchtext = ' + error);
            setCreate_List_Survey_cold_eval_For_Session_For_Inscrit_api("false");
            alert(" Impossible d'initialiser la liste des enquête de positionnement ");

        })
    }


    const [Create_List_Survey_eval_formateur_For_Session_For_Inscrit_api, setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_api] = useState();
    const [Create_List_Survey_eval_formateur_For_Session_For_Inscrit_message, setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_message] = useState();
    const [Create_List_Survey_eval_formateur_For_Session_For_Inscrit_result, setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_result] = useState();
    function Create_List_Survey_eval_formateur_For_Session_For_Inscrit() {

        if (String(selected_formateur_id) === "" || String(selected_formateur_id).length < 5) {
            alert(" Vous devez choisir un formateur ");
            return;
        }
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("session_id", selected_session_id);
        form.append("formulaire_id", selected_formulaire_id);
        form.append("related_collection", "ressource_humaine");
        form.append("related_collection_id", selected_formateur_id);
        //form.append("class_id", props.session_class_id);
        form.append("survey_type", "human_eval");
        form.append("sending_date", "");


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Init_Survey_Tab_For_All_Session_Inscrit/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_api("true");
                setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_result(res.data.message);
                Getall_Survey_formateur_evaluation_By_Session();
                setselected_formateur_id("");
                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_api("false");
                setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Create_List_Survey_eval_formateur_For_Session_For_Inscrit : Not good man :( mysearchtext = ' + error);
            setCreate_List_Survey_eval_formateur_For_Session_For_Inscrit_api("false");
            alert(" Impossible d'initialiser la liste des enquête ");

        })
    }


    const [actionmass_eval_formateur, setactionmass_eval_formateur] = useState();

    const [actionmass_eval_froid, setactionmass_eval_froid] = useState();

    const [actionmass_eval_val, setactionmass_eval_val] = useState();
    const actionmass_evaluation = (event) => {
        const value = event.target.value;

        //console.log(" ### actionmass_evaluation =  ", value);

        if (String(value) === "demande evaluation" || String(value.value) === "export_evaluation") {
            setactionmass_eval_val(value);
            //console.log(" ### LAAA =  ", value);
        }

        else {
            setactionmass_eval_val();
        }
    }

    function GetSelectedRows_Evaluation_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_evaluation.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }


    function GetSelectedRows_Evaluation_formateur_Ids() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_evaluation_formateur.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation_formateur[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }
    async function actionmass_Formation_Traitemet() {
        var liste_formation = GetSelectedRows_Evaluation_formateur_Ids();

        var tab_ids_survey = []
        for (var i = 0; i < selectionModel_evaluation_formateur.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation_formateur[i]));
            var line = JSON.parse(rowss_evaluation_formateur[myid]);
            var line_id = JSON.parse(rowss_evaluation_formateur[myid])._id;
            tab_ids_survey.push(line_id);
        }

        //SendEvaluationEmail_Many_Ids(tab_ids_inscription);
        if (String(actionmass_eval_formateur) === "demande evaluation")
            Send_Survey_By_Email_For_Ids(tab_ids_survey);
        else if (String(actionmass_eval_formateur) === "export_evaluation")
            Export_Selected_Survey_PDF(tab_ids_survey);

    }



    async function actionmass_Evaluation_Traitemet() {
        var liste_formation = GetSelectedRows_Evaluation_Ids();


        var tab_ids_inscription = []
        for (var i = 0; i < selectionModel_evaluation.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation[i]));
            var line = JSON.parse(rowss_evaluation[myid]);
            var line_id = JSON.parse(rowss_evaluation[myid])._id;

            tab_ids_inscription.push(line_id);
        }


        //SendEvaluationEmail_Many_Ids(tab_ids_inscription);
        if (String(actionmass_eval_val) === "demande evaluation")
            Send_Survey_By_Email_For_Ids(tab_ids_inscription);
        else if (String(actionmass_eval_val) === "export_evaluation")
            Export_Selected_Survey_PDF(tab_ids_inscription);

    }


    function GetSelectedRows_Evaluation_Ids_cold() {

        var tab_tmp = []
        for (var i = 0; i < selectionModel_evaluation_cold.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation_cold[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }
    async function actionmass_Evaluation_Traitemet_cold() {
        var liste_formation = GetSelectedRows_Evaluation_Ids_cold();


        var tab_ids_inscription = []
        for (var i = 0; i < selectionModel_evaluation_cold.length; i++) {
            var myid = parseInt(String(selectionModel_evaluation_cold[i]));
            var line = JSON.parse(rowss_evaluation_cold[myid]);
            var line_id = JSON.parse(rowss_evaluation_cold[myid])._id;

            tab_ids_inscription.push(line_id);
        }


        //SendEvaluationEmail_Many_Ids(tab_ids_inscription); setactionmass_eval_val
        if (String(actionmass_eval_froid) === "demande evaluation")
            Send_Survey_By_Email_For_Ids(tab_ids_inscription);
        else if (String(actionmass_eval_froid) === "export_evaluation")
            Export_Selected_Survey_PDF(tab_ids_inscription);

    }

    const [Send_Survey_By_Email_For_Ids_api, setSend_Survey_By_Email_For_Ids_api] = useState();
    const [Send_Survey_By_Email_For_Ids_message, setSend_Survey_By_Email_For_Ids_message] = useState();
    const [Send_Survey_By_Email_For_Ids_result, setSend_Survey_By_Email_For_Ids_result] = useState();
    function Send_Survey_By_Email_For_Ids(tab_survey) {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("tab_ids", tab_survey);


        //alert(" mysession = "+mysession);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Send_Survey_TabIds/";


        setLoading(true);
        axios.post(myurl, form).then(res => {

            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In UpdateStagiaireData  res.data.status = " + res.data.status);
                //console.log(" In UpdateStagiaireData  res.data.message r_class = " + res.data.message);
                setSend_Survey_By_Email_For_Ids_api("true");
                setSend_Survey_By_Email_For_Ids_result(res.data.message);

                Getall_Survey_hot_evaluation_By_Session();
                Getall_Survey_cold_evaluation_By_Session();
                Getall_Survey_formateur_evaluation_By_Session();


                setSelectionModel_evaluation([]);
                setSelectionModel_evaluation_cold([]);
                setselectionModel_evaluation_formateur([]);

                setactionmass_eval_val("");
                setactionmass_eval_froid("");
                setactionmass_eval_formateur("");


                alert(res.data.message);

            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setSend_Survey_By_Email_For_Ids_api("false");
                setSend_Survey_By_Email_For_Ids_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Send_Survey_By_Email_For_Ids : Not good man :( mysearchtext = ' + error);
            setSend_Survey_By_Email_For_Ids_api("false");
            alert(" Impossible d'envoyer les demandes");

        })
    }


    function PrintEvaluationDetail() {

        var filedetail = "detail_evaluation.xlsx"

        const stored_cookie = getCookie('tokenmysypart');

        var url = process.env.REACT_APP_API_URL + "myclass/api/DownloadEvaluationList/" + stored_cookie + "/" + selected_session_id + "/" + selected_internal_url;

        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, filedetail)
            })
    }



    const New_Option_Evaluation_Masse = [
        { "id": "demande evaluation", "label": "Envoyer demande Evaluation", "value": "demande evaluation" },
        { "id": "export_evaluation", "label": "Export Evaluation (PDF) ", "value": "export_evaluation" },
    ]

    useEffect(() => {


        if (props.session_formateur_id) {
            setselected_formateur_id(props.session_formateur_id);
        } else {
            setselected_formateur_id("");
        }
        Getall_Training_Employee_No_Filter();
        Getall_Training_Materiel_No_Filter();

        Getall_Survey_hot_evaluation_By_Session();
        Getall_Survey_cold_evaluation_By_Session();
        Getall_Survey_formateur_evaluation_By_Session();

        setSessionendDate(props.SessionendDate);
        setSessionstartDate(props.SessionstartDate);

    }, [])


    const [SessionendDate, setSessionendDate] = useState(props.SessionendDate);

    const filterPassedTime_end = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const [SessionstartDate, setSessionstartDate] = useState(props.SessionstartDate);

    const filterPassedTime_start = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };



    function Export_survey_hot_eval_datatab_class() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_reponses.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_Survey_with_filter/" + stored_cookie + "/" + selected_session_id + "/hot_eval/";


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les réponses (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les réponses ")

            });


        setLoading(false);
    }


    function Export_survey_formateur_eval_datatab_class() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_reponses.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_Survey_with_filter/" + stored_cookie + "/" + selected_session_id + "/human_eval/";


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les réponses (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les réponses ")

            });


        setLoading(false);
    }



    function Export_survey_cold_eval_datatab_class() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_reponses.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_Survey_with_filter/" + stored_cookie + "/" + selected_session_id + "/cold_eval/";


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les réponses (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les réponses ")

            });


        setLoading(false);
    }


    function Export_Selected_Survey_PDF(tab_ids_survey) {
        const stored_cookie = getCookie('tokenmysypart');



        if (tab_ids_survey.length <= 0) {
            alert(" Aucune ligne selectionnée ");
            return;
        }
        var nom_fichier_cmd = "export_evaluation.zip";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_Survey_with_filter_Tab_Survey_Id/" + stored_cookie + "/" + tab_ids_survey.toString();


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les réponses (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les réponses ")

            });


        setLoading(false);
    }



    const [Dialog_1_message, setDialog_1_message] = React.useState(false);

    const [Dialog_LIST_HOT_EVALUATION_QUESTION_open, setDialog_LIST_HOT_EVALUATION_QUESTION_open] = React.useState(false);
    const Dialog_LIST_HOT_EVALUATION_QUESTION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_LIST_HOT_EVALUATION_QUESTION_handleClose_buton = () => {
        setDialog_LIST_HOT_EVALUATION_QUESTION_open(false);
    };


    const [selected_formulaire_id, setselected_formulaire_id] = useState();

    const [selected_formateur_id, setselected_formateur_id] = useState("");

    const [Dialog_LIST_COLD_EVALUATION_QUESTION_open, setDialog_LIST_COLD_EVALUATION_QUESTION_open] = React.useState(false);
    const Dialog_LIST_COLD_EVALUATION_QUESTION_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_LIST_COLD_EVALUATION_QUESTION_handleClose_buton = () => {
        setDialog_LIST_COLD_EVALUATION_QUESTION_open(false);
    };

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);
    const [date_cold_eval_sending, setdate_cold_eval_sending] = useState(date_today_90j.toLocaleDateString('fr-FR'));


    const [Dialog_LIST_EVALUATION_FORMATEUR_open, setDialog_LIST_EVALUATION_FORMATEUR_open] = React.useState(false);
    const Dialog_LIST_EVALUATION_FORMATEUR_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_LIST_EVALUATION_FORMATEUR_handleClose_buton = () => {
        setDialog_LIST_EVALUATION_FORMATEUR_open(false);
    };


    return (
        <div className="modulesessionevaluation">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            {/*  Dialog pour gerer les LIST_HOT_EVALUATION_QUESTION */}
            <Dialog
                open={Dialog_LIST_HOT_EVALUATION_QUESTION_open}
                onClose={Dialog_LIST_HOT_EVALUATION_QUESTION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog" > Choisir un questionnaire d'évaluation à chaud
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_q_hot_evaluation"
                            id="event_dialog_version_q_hot_evaluation"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Getall_Partner_Formulaires_hot_evaluation_with_Filter}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_formulaire_id(value._id);

                                } else {
                                    setselected_formulaire_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>
                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {

                                var tab_ids_inscription = []
                                for (var i = 0; i < selectionModel_evaluation.length; i++) {
                                    var myid = parseInt(String(selectionModel_evaluation[i]));
                                    var line = JSON.parse(selectionModel_evaluation[myid]);
                                    var line_id = JSON.parse(selectionModel_evaluation[myid])._id;
                                    tab_ids_inscription.push(line_id);
                                }

                                Create_List_Survey_hot_eval_For_Session_For_Inscrit();
                                Dialog_LIST_HOT_EVALUATION_QUESTION_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Initialiser la liste </Button>




                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_LIST_HOT_EVALUATION_QUESTION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les LIST_HOT_EVALUATION_QUESTION */}


            {/*  Dialog pour gerer les LIST_COLD_EVALUATION_QUESTION */}
            <Dialog
                open={Dialog_LIST_COLD_EVALUATION_QUESTION_open}
                onClose={Dialog_LIST_COLD_EVALUATION_QUESTION_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog"> <b> Choisir un questionnaire d'évaluation à froid </b>
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_q_COLD_evaluation"
                            id="event_dialog_version_q_COLD_evaluation"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Getall_Partner_Formulaires_cold_evaluation_with_Filter}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_formulaire_id(value._id);

                                } else {
                                    setselected_formulaire_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une questionnaire"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>

                    <div className="session_caract_Dialog" > <b> Envoyer le </b>
                        <DatePicker
                            name="event_dialog_start"
                            id="event_dialog_start"

                            selected={new Date(moment(date_cold_eval_sending, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setdate_cold_eval_sending(format(date, 'dd/MM/yyyy'));
                            }
                            }

                            className="disabled_style session_caract_Dialog_DatePicker"
                            style={{ width: "99%" }}
                            locale='fr-FR'
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"

                        />

                    </div>

                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {

                                var tab_ids_inscription = []
                                for (var i = 0; i < selectionModel_evaluation.length; i++) {
                                    var myid = parseInt(String(selectionModel_evaluation[i]));
                                    var line = JSON.parse(selectionModel_evaluation[myid]);
                                    var line_id = JSON.parse(selectionModel_evaluation[myid])._id;
                                    tab_ids_inscription.push(line_id);
                                }



                                Create_List_Survey_cold_eval_For_Session_For_Inscrit();
                                Dialog_LIST_COLD_EVALUATION_QUESTION_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Initialiser la liste </Button>

                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_LIST_COLD_EVALUATION_QUESTION_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les LIST_COLD_EVALUATION_QUESTION */}


            {/*  Dialog pour gerer les LIST_EVALUATION_FORMATEUR */}
            <Dialog
                open={Dialog_LIST_EVALUATION_FORMATEUR_open}
                onClose={Dialog_LIST_EVALUATION_FORMATEUR_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width" style={{ "minHeight": "20rem" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>


                    <div className="session_caract_Dialog"> <b> Choisir un questionnaire d'évaluation pour le formateur </b>
                        <Autocomplete
                            disablePortal
                            name="event_dialog_version_q_COLD_evaluation"
                            id="event_dialog_version_q_COLD_evaluation"
                            fullWidth
                            //className="disabled_style enable_style"
                            options={New_Getall_Partner_Formulaires_formateur_evaluation_with_Filter}

                            onChange={(event, value) => {
                                if (value && value._id) {

                                    setselected_formulaire_id(value._id);

                                } else {
                                    setselected_formulaire_id("");
                                }
                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une questionnaire"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />

                    </div>



                    {New_Getall_Training_Employee_No_Filter_result && New_Getall_Training_Employee_No_Filter_result.length > 0 &&
                        <div className="session_caract_Dialog"> <b> Choisir un formateur à évaluer </b>
                            <Autocomplete
                                disablePortal
                                name="event_dialog_formateur"
                                id="event_dialog_formateur"
                                fullWidth
                                //className="disabled_style enable_style"
                                options={New_Getall_Training_Employee_No_Filter_result}
                                value={New_Getall_Training_Employee_No_Filter_result.filter((data) => (data)._id === String(selected_formateur_id))[0].label}
                                onChange={(event, value) => {
                                    if (value && value._id) {

                                        setselected_formateur_id(value._id);

                                    } else {

                                        alert(" Vous devez choisir un formateur ")
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une questionnaire"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        </div>}



                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {

                                var tab_ids_inscription = []
                                for (var i = 0; i < selectionModel_evaluation.length; i++) {
                                    var myid = parseInt(String(selectionModel_evaluation[i]));
                                    var line = JSON.parse(selectionModel_evaluation[myid]);
                                    var line_id = JSON.parse(selectionModel_evaluation[myid])._id;
                                    tab_ids_inscription.push(line_id);
                                }



                                Create_List_Survey_eval_formateur_For_Session_For_Inscrit();
                                Dialog_LIST_EVALUATION_FORMATEUR_handleClose_buton();

                            }}
                                className="bton_enreg_dialog">Initialiser la liste </Button>

                        </div>



                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_LIST_EVALUATION_FORMATEUR_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>
            {/* FIN  Dialog pour gerer les LIST_EVALUATION_FORMATEUR */}




            <div style={{ "border": "None" }}>


                <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                    <div className="titre1">  Evaluation à Chaud </div>
                    <Popup
                        trigger={
                            <Button className="bton_emarge">Initialisation</Button>
                        }
                        modal
                        nested
                        position="center center"

                    >

                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}


                                    <font> Confirmez la (ré)initialisation de la demande. <br />
                                        <font color="red">  /!\ Si une demande d'évaluation existe pour cette session, elle sera supprimée et récréée </font>
                                    </font>


                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            Getall_Partner_Formulaires_hot_evaluation_with_Filter();
                                            setDialog_LIST_HOT_EVALUATION_QUESTION_open(true);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>



                    <Button className="bton_emarge" onClick={(event) => {
                        Getall_Survey_hot_evaluation_By_Session();
                    }}>Actualiser la liste</Button>


                    <Button className="bton_emarge" onClick={(event) => {
                        Export_survey_hot_eval_datatab_class();
                    }}>Exporter (excel)</Button>


                </div>
                <div style={{ height: "650px", width: '100%', padding: "10px" }} className="session_data">
                    <Box
                        sx={{
                            height: 500,
                            width: '100%',
                            paddingRight: '1px',


                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#c8cfd5",
                                color: "black",
                                fontSize: 14
                            },


                            '& .line--statut--pair': {
                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                color: 'black',
                            },


                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },

                            '& .line--positionnement_envoye': {
                                backgroundColor: '#F9E79F',
                                color: 'black',

                            },

                            '& .line--positionnement_repondu': {
                                backgroundColor: '#ABEBC6',
                                color: 'black',
                                fontWeight: '600',
                            },

                        }}
                    >

                        <DataGrid
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel_evaluation) => {
                                setSelectionModel_evaluation(newSelectionModel_evaluation);
                                //console.log("ch selected--" + newSelectionModel_evaluation);

                                if( selectionModel_evaluation.length <= 0 ){
                                    setactionmass_eval_val("");
                                }

                            }}

                            selectionModel={selectionModel_evaluation}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            rows={rowss_evaluation.map((item, index) => (
                                {
                                    id: index,
                                    _id: JSON.parse(item)._id,
                                    email: JSON.parse(item).email,
                                    nom: JSON.parse(item).nom,
                                    prenom: JSON.parse(item).prenom,
                                    status: JSON.parse(item).statut,
                                    form_code: JSON.parse(item).formulaire.code,
                                    date_envoie: JSON.parse(item).date_envoi,
                                    date_reponse: JSON.parse(item).date_reponse,

                                }
                            ))}

                            columns={columns_list_evaluation}
                            pageSize={10}
                            className="datagridclass"

                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}

                            getRowClassName={(params) => {

                                if (String(params.row.status) === "1") {
                                    return 'line--positionnement_envoye';
                                }

                                if (String(params.row.status) === "2") {
                                    return 'line--positionnement_repondu';
                                }

                                // Pour la gestion de la couleur de zone double cliquée

                                if (parseInt(String(params.row.id)) % 2 === 0) {
                                    return 'line--statut--pair';
                                }
                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                    return 'line--statut--impair';
                                }

                            }}
                            getEstimatedRowHeight={() => 200}
                            getRowHeight={() => "auto"}
                            sx={{
                                "& .MuiDataGrid-cellContent": {
                                    minHeight: 50,

                                },
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}

                        />
                    </Box>

                    {selectionModel_evaluation && String(selectionModel_evaluation) !== "" && selectionModel_evaluation.length > 0 &&
                        <div className="block_en_mass">

                            <nav >Traitement en masse / Evaluation</nav>
                            &nbsp;


                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className="block_en_mass_select"
                                fullWidth
                                options={New_Option_Evaluation_Masse}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        if (String(value.value) === "demande evaluation" || String(value.value) === "export_evaluation") {
                                            setactionmass_eval_val(value.value);
                                        }
                                        else {
                                            setactionmass_eval_val("");
                                        }

                                    }
                                    else {
                                        setactionmass_eval_val("");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option" />
                                }
                            />


                            {actionmass_eval_val && String(actionmass_eval_val).length > 1 && String(actionmass_eval_val) !== "" &&
                                selectionModel_evaluation.length > 0 &&

                                <Popup
                                    trigger={<Button className="bton_enreg" >
                                        <FcAcceptDatabase /> Traiter

                                    </Button>}
                                    modal
                                    nested
                                    position="center center"
                                >

                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}
                                                {actionmass_eval_val && String(actionmass_eval_val) === "demande evaluation" && <nav>
                                                    Confirmer l'envoi de la demande d'évaluation pour {selectionModel_evaluation.length} ligne(s).
                                                </nav>}

                                                {actionmass_eval_val && String(actionmass_eval_val) === "export_evaluation" && <nav>
                                                    Confirmer l'export PDF des évaluations pour {selectionModel_evaluation.length} ligne(s).
                                                </nav>}

                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                        actionmass_Evaluation_Traitemet();
                                                        close();
                                                    }}> Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            }
                            <br />


                        </div>
                    }

                    <br />




                </div>


            </div>

            <div className="div_row"> &nbsp; </div>
            <div style={{ "border": "None" }}>


                <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem", "width": "100%", "float": "right" }}>
                    <div className="titre1">  Evaluation à froid </div>

                    <div style={{ width: "100%", float: "left" }}>
                        <div style={{ width: "20%", float: "left", "textAlign": "left", "fontWeight": "700" }}>
                            {date_cold_eval_sending && <nav>Envoyé le {date_cold_eval_sending} </nav>}
                        </div>

                        <div style={{ width: "80%", float: "right" }}>
                            <Popup
                                trigger={
                                    <Button className="bton_emarge">Initialisation</Button>
                                }
                                modal
                                nested
                                position="center center"

                            >

                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}


                                            <font> Confirmez la (ré)initialisation de la demande. <br />
                                                <font color="red">  /!\ Si une demande d'évaluation existe pour cette session, elle sera supprimée et récréée </font>
                                            </font>


                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    Getall_Partner_Formulaires_cold_evaluation_with_Filter();
                                                    setDialog_LIST_COLD_EVALUATION_QUESTION_open(true);
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>



                            <Button className="bton_emarge" onClick={(event) => {
                                Getall_Survey_cold_evaluation_By_Session();
                            }}>Actualiser la liste</Button>


                            <Button className="bton_emarge" onClick={(event) => {
                                Export_survey_hot_eval_datatab_class();
                            }}>Exporter (excel)</Button>
                        </div>
                    </div>
                </div>

                <div style={{ height: "650px", width: '100%', padding: "10px" }} className="session_data">
                    <Box
                        sx={{
                            height: 500,
                            width: '100%',
                            paddingRight: '1px',


                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#c8cfd5",
                                color: "black",
                                fontSize: 14
                            },


                            '& .line--statut--pair': {
                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                color: 'black',
                            },


                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },

                            '& .line--positionnement_envoye': {
                                backgroundColor: '#F9E79F',
                                color: 'black',

                            },

                            '& .line--positionnement_repondu': {
                                backgroundColor: '#ABEBC6',
                                color: 'black',
                                fontWeight: '600',
                            },

                        }}
                    >

                        <DataGrid
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel_evaluation) => {
                                setSelectionModel_evaluation_cold(newSelectionModel_evaluation);
                                //console.log("ch selected--" + newSelectionModel_evaluation);

                                if( selectionModel_evaluation_cold.length <= 0 ){
                                    setactionmass_eval_froid("");
                                }

                            }}

                            selectionModel={selectionModel_evaluation_cold}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            rows={rowss_evaluation_cold.map((item, index) => (
                                {
                                    id: index,
                                    _id: JSON.parse(item)._id,
                                    email: JSON.parse(item).email,
                                    nom: JSON.parse(item).nom,
                                    prenom: JSON.parse(item).prenom,
                                    status: JSON.parse(item).statut,
                                    form_code: JSON.parse(item).formulaire.code,
                                    date_envoie: JSON.parse(item).date_envoi,
                                    date_reponse: JSON.parse(item).date_reponse,

                                }
                            ))}

                            columns={columns_list_evaluation_cold}
                            pageSize={10}
                            className="datagridclass"

                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}

                            getRowClassName={(params) => {

                                if (String(params.row.status) === "1") {
                                    return 'line--positionnement_envoye';
                                }

                                if (String(params.row.status) === "2") {
                                    return 'line--positionnement_repondu';
                                }

                                // Pour la gestion de la couleur de zone double cliquée

                                if (parseInt(String(params.row.id)) % 2 === 0) {
                                    return 'line--statut--pair';
                                }
                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                    return 'line--statut--impair';
                                }

                            }}
                            getEstimatedRowHeight={() => 200}
                            getRowHeight={() => "auto"}
                            sx={{
                                "& .MuiDataGrid-cellContent": {
                                    minHeight: 50,

                                },
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}

                        />
                    </Box>

                    {selectionModel_evaluation_cold && selectionModel_evaluation_cold.length > 0 &&
                        <div className="block_en_mass">
                            <nav >Traitement en masse / Evaluation froid</nav>
                            &nbsp;


                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className="block_en_mass_select"
                                fullWidth
                                options={New_Option_Evaluation_Masse}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        if (String(value.value) === "demande evaluation" || String(value.value) === "export_evaluation") {

                                            setactionmass_eval_froid(value.value);

                                        }
                                        else {
                                            setactionmass_eval_froid("");
                                        }

                                    }
                                    else {
                                        setactionmass_eval_froid("");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option" />
                                }
                            />

                            {actionmass_eval_froid && String(actionmass_eval_froid) !== "" && String(selectionModel_evaluation_cold).length > 0 &&
                                selectionModel_evaluation_cold.length > 0 &&
                                <Popup
                                    trigger={<Button className="bton_enreg" >
                                        <FcAcceptDatabase /> Traiter

                                    </Button>}
                                    modal
                                    nested
                                    position="center center"
                                >

                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}



                                                {actionmass_eval_froid && String(actionmass_eval_froid) === "demande evaluation" && <nav>
                                                    Confirmer l'envoi de la demande d'évaluation pour {selectionModel_evaluation_cold.length} ligne(s).
                                                </nav>}

                                                {actionmass_eval_froid && String(actionmass_eval_froid) === "export_evaluation" && <nav>
                                                    Confirmer l'export PDF des évaluations pour {selectionModel_evaluation_cold.length} ligne(s).
                                                </nav>}

                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                        actionmass_Evaluation_Traitemet_cold();
                                                        close();
                                                    }}> Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            }
                            <br />


                        </div>
                    }

                    <br />




                </div>


            </div>



            <div className="div_row"> &nbsp; </div>
            <div style={{ "border": "None" }}>


                <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem", "width": "100%", "float": "right" }}>
                    <div className="titre1">  Evaluation  Enseignant <b>  {Survey_formateur_evaluation_nom_prenom} </b> </div>

                    <div style={{ width: "100%", float: "left" }}>

                        <div style={{ width: "80%", float: "right" }}>
                            <Popup
                                trigger={
                                    <Button className="bton_emarge">Initialisation</Button>
                                }
                                modal
                                nested
                                position="center center"

                            >

                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}


                                            <font> Confirmez la (ré)initialisation de la demande. <br />
                                                <font color="red">  /!\ Si une demande d'évaluation existe pour cette session, elle sera supprimée et récréée </font>
                                            </font>


                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    Getall_Partner_Formulaires_formateur_evaluation_with_Filter();
                                                    setselected_formateur_id("");
                                                    setDialog_LIST_EVALUATION_FORMATEUR_open(true);
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>



                            <Button className="bton_emarge" onClick={(event) => {
                                Getall_Survey_formateur_evaluation_By_Session();
                            }}>Actualiser la liste</Button>


                            <Button className="bton_emarge" onClick={(event) => {
                                Export_survey_formateur_eval_datatab_class();
                            }}>Exporter (excel)</Button>
                        </div>
                    </div>
                </div>

                <div style={{ height: "650px", width: '100%', padding: "10px" }} className="session_data">
                    <Box
                        sx={{
                            height: 500,
                            width: '100%',
                            paddingRight: '1px',


                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#c8cfd5",
                                color: "black",
                                fontSize: 14
                            },


                            '& .line--statut--pair': {
                                backgroundColor: 'rgba(235, 235, 235, .7)',
                                color: 'black',
                            },


                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },

                            '& .line--positionnement_envoye': {
                                backgroundColor: '#F9E79F',
                                color: 'black',

                            },

                            '& .line--positionnement_repondu': {
                                backgroundColor: '#ABEBC6',
                                color: 'black',
                                fontWeight: '600',
                            },

                        }}
                    >

                        <DataGrid
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel_evaluation) => {
                                setselectionModel_evaluation_formateur(newSelectionModel_evaluation);
                                //console.log("ch selected--" + newSelectionModel_evaluation);

                                if( selectionModel_evaluation_formateur.length <= 0 ){
                                    setactionmass_eval_formateur("");
                                }

                            }}

                            selectionModel={selectionModel_evaluation_formateur}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            rows={rowss_evaluation_formateur.map((item, index) => (
                                {
                                    id: index,
                                    _id: JSON.parse(item)._id,
                                    email: JSON.parse(item).email,
                                    nom: JSON.parse(item).nom,
                                    prenom: JSON.parse(item).prenom,
                                    status: JSON.parse(item).statut,
                                    form_code: JSON.parse(item).formulaire.code,
                                    date_envoie: JSON.parse(item).date_envoi,
                                    date_reponse: JSON.parse(item).date_reponse,

                                }
                            ))}

                            columns={columns_list_evaluation_enseignant}
                            pageSize={10}
                            className="datagridclass"

                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            components={{
                                Toolbar: GridToolbar,
                            }}

                            getRowClassName={(params) => {

                                if (String(params.row.status) === "1") {
                                    return 'line--positionnement_envoye';
                                }

                                if (String(params.row.status) === "2") {
                                    return 'line--positionnement_repondu';
                                }

                                // Pour la gestion de la couleur de zone double cliquée

                                if (parseInt(String(params.row.id)) % 2 === 0) {
                                    return 'line--statut--pair';
                                }
                                else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                    return 'line--statut--impair';
                                }

                            }}
                            getEstimatedRowHeight={() => 200}
                            getRowHeight={() => "auto"}
                            sx={{
                                "& .MuiDataGrid-cellContent": {
                                    minHeight: 50,

                                },
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}

                        />
                    </Box>

                    {selectionModel_evaluation_formateur && selectionModel_evaluation_formateur.length > 0 &&
                        <div className="block_en_mass">
                            <nav >Traitement en masse / Evaluation Formateur</nav>
                            &nbsp;


                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                className="block_en_mass_select"
                                fullWidth
                                options={New_Option_Evaluation_Masse}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        if (String(value.value) === "demande evaluation" || String(value.value) === "export_evaluation") {

                                            setactionmass_eval_formateur(value.value);

                                        }
                                        else {
                                            setactionmass_eval_formateur("");
                                        }

                                    }
                                    else {
                                        setactionmass_eval_formateur("");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} placeholder="Choisir une option" />
                                }
                            />

                     

                            {actionmass_eval_formateur && String(actionmass_eval_formateur) !== "" && selectionModel_evaluation_formateur.length > 0 &&
                                <Popup
                                    trigger={<Button className="bton_enreg" >
                                        <FcAcceptDatabase /> Traiter

                                    </Button>}
                                    modal
                                    nested
                                    position="center center"
                                >

                                    {close => (
                                        <div>
                                            <button className="gest_close" onClick={close}>
                                                &times;
                                            </button>
                                            <div className="gest_header"> MySy Information </div>
                                            <div className="gest_content">
                                                {' '}


                                                {actionmass_eval_formateur && String(actionmass_eval_formateur) === "demande evaluation" && <nav>
                                                    Confirmer l'envoi de la demande d'évaluation pour {selectionModel_evaluation_formateur.length} ligne(s).
                                                </nav>}

                                                {actionmass_eval_formateur && String(actionmass_eval_formateur) === "export_evaluation" && <nav>
                                                    Confirmer l'export PDF des évaluations pour {selectionModel_evaluation_formateur.length} ligne(s).
                                                </nav>}

                                            </div>
                                            <div className="gest_actions">
                                                <div style={{ "width": "45%", "float": "left" }}>
                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                        actionmass_Formation_Traitemet();
                                                        close();
                                                    }}> Valider </button>

                                                </div>
                                                <div style={{ "width": "45%", "float": "right" }}>
                                                    <button
                                                        className="gest_bton_popup"
                                                        onClick={() => {
                                                            //console.log('modal closed ');
                                                            close();
                                                        }}
                                                    >
                                                        Annuler
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            }
                            <br />


                        </div>
                    }

                    <br />




                </div>


            </div>

        </div >
    );
}

export default Module_Session_Evaluation;

