import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import { FcViewDetails, FcDisapprove } from "react-icons/fc";
import { FcPodiumWithAudience } from "react-icons/fc";
import { FcInfo } from "react-icons/fc";
import { LiaDharmachakraSolid } from "react-icons/lia";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint, FcCheckmark } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ColorPicker from 'react-pick-color';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Fab } from "@material-ui/core";
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { AiTwotoneEdit, AiTwotoneSave } from "react-icons/ai";
import Autocomplete from '@mui/material/Autocomplete';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";

import { gridClasses } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { PiDotsThree } from "react-icons/pi";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import Rating from '@mui/material/Rating';
import Module_Selection_Evaluation_Apprenants from "./Module_Selection_Evaluation_Apprenants";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Module_Selection_Apprenants from "./Module_Selection_Apprenants";

import { FcAlarmClock, FcMultipleSmartphones, FcWorkflow } from "react-icons/fc";

import { makeStyles } from "@material-ui/core/styles";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {
    GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton,
    GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridValueGetterParams,
    GridPreferencePanelsValue,

} from '@mui/x-data-grid';
import * as XLSX from 'xlsx';


const useStyles = makeStyles((theme) => ({

    dialog: {
        position: 'absolute',
        // left: 10,
        top: 400
    },

    paper: {
        overflowY: 'unset',
    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        top: '-9%',
        backgroundColor: 'lightgray',
        color: 'gray',
    },
}));


const Groupe_Apprenant = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const [submenu, setsubmenu] = useState("");
    const [rowss, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [isLoading, setLoading] = useState();

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() + 90);




    const [p_filtre1, setp_filtre1] = useState("");
    const [p_filtre1_value, setp_filtre1_value] = useState("");

    const [p_filtre2, setp_filtre2] = useState("");
    const [p_filtre2_value, setp_filtre2_value] = useState("");

    const [p_filtre3, setp_filtre3] = useState("");
    const [p_filtre3_value, setp_filtre3_value] = useState("");

    const [p_filtre4, setp_filtre4] = useState("");
    const [p_filtre4_value, setp_filtre4_value] = useState("");


    function ExpandableCell_50({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }

    function ExpandableCell_50_bg_hiligt({ value }) {
        const [expanded, setExpanded] = React.useState(false);

        return (
            <nav>
                {value && <div style={{
                    paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all", color: '#0000FF',
                    "textAlign": "center"
                }}>

                    {expanded ? value : value.slice(0, 50)}&nbsp;
                    {value.length > 50 && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            type="button"
                            component="button"
                            sx={{ fontSize: 'inherit' }}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <nav>  <PiDotsThree />Réduire</nav> : <nav> <PiDotsThree />Voir</nav>}
                        </Link>
                    )}
                </div>}

                {value && <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all", color: '#0000FF' }}>
                    &nbsp;
                </div>
                }
            </nav>
        );
    }


    const columns_grp_apprenant = [
        { field: '_id', headerName: '_id', hide: true, Width: 0, disableExport: true, },
        { field: 'id', headerName: 'id', hide: true, Width: 0, disableExport: true, },

        { field: 'code', headerName: 'Code', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nom', headerName: 'Nom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'type_groupe_code', headerName: 'Type GRoupe', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'nb_membre', headerName: 'Nb membres', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'class_id', headerName: 'class_id', hide: true, Width: 0, disableExport: true, },

        {
            field: 'class_id_code', headerName: 'Ftion Code', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 && <nav>
                            {New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(cellValues.row.class_id))[0].label}
                        </nav>}


                    </div>

                );
            },
            valueGetter: (cellValues) => {
                if (cellValues.row.class_id) {
                    var mylabel = New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(cellValues.row.class_id))[0].label;
                    return mylabel;
                }
                
                return "";
            },
        },

        { field: 'session_id', headerName: 'session_id', hide: true, Width: 0, disableExport: true, },
        {
            field: 'session_code', headerName: 'Classe', minWidth: 150, flex: 1, renderCell: (params) => <ExpandableCell_50 {...params} />,
            renderCell: (cellValues) => {
                return (
                    <div style={{ paddingLeft: "5px", paddingRight: "15px", "display": "block", wordBreak: "break-all" }}>
                        {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&
                         <nav>
                            {New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(cellValues.row.session_id))[0].label}
                        </nav>}
                    </div>

                );
            },

            valueGetter: (cellValues) => {
                if (cellValues.row.session_id) {
                    var mylabel =  New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(cellValues.row.session_id))[0].label;
                    return mylabel;
                }
                
                return "";
            },

        },


        {
            field: "delete", headerName: 'Supprimer', disableExport: true,
            renderCell: (cellValues) => {
                return (
                    <nav>
                        {String(cellValues.row.nb_membre) !== "0" && <Button onClick={(e) => {
                            alert(" Vous devez supprimer les membres avant de supprimer le groupe");
                        }}>
                            <CiTrash />

                        </Button>}

                        {String(cellValues.row.nb_membre) === "0" &&
                            <Popup
                                trigger={<Button


                                >
                                    <CiTrash />

                                </Button>}
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            En confirmant cette opération, le groupe sera <i><font color="red"> définitivement supprimé</font></i>. <br />

                                        </div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    handleClick_delete_groupe(event, cellValues);
                                                    //console.log('modal closed ');
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {
                                                        //console.log('modal closed ');
                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>}

                    </nav>
                );
            }
        }

    ]

    const columns_grp_membre = [
        { field: '_id', headerName: '_id', hide: true, Width: 0, disableExport: true, },
        { field: 'id', headerName: 'id', hide: true, Width: 0, disableExport: true, },

        { field: 'nom', headerName: 'nom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'prenom', headerName: 'prenom', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },
        { field: 'email', headerName: 'email', flex: 1, hide: false, minWidth: 180, renderCell: (params) => <ExpandableCell_50 {...params} />, },

        {
            field: "delete", headerName: 'Supprimer', disableExport: true,
            renderCell: (cellValues) => {
                return (

                    <Popup
                        trigger={<Button

                            onClick={(event) => {

                                // handleClick_delete(event, cellValues);
                            }}
                        >
                            <CiTrash />

                        </Button>}
                        modal
                        nested
                        position="center center"
                    >
                        {close => (
                            <div>
                                <button className="gest_close" onClick={close}>
                                    &times;
                                </button>
                                <div className="gest_header"> MySy Information </div>
                                <div className="gest_content">
                                    {' '}

                                    En confirmant cette opération, la personne sera <i><font color="red"> supprimée du groupe </font></i>. <br />

                                </div>
                                <div className="gest_actions">
                                    <div style={{ "width": "45%", "float": "left" }}>
                                        <button className="gest_bton_popup" onClick={(event) => {
                                            var tab = []
                                            tab.push(String(cellValues.row._id));
                                            Delete_List_Membre_From_Groupe(tab);
                                            close();
                                        }}> Valider </button>

                                    </div>
                                    <div style={{ "width": "45%", "float": "right" }}>
                                        <button
                                            className="gest_bton_popup"
                                            onClick={() => {
                                                //console.log('modal closed ');
                                                close();
                                            }}
                                        >
                                            Annuler
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Popup>


                );
            }
        }

    ]


    const [Delete_List_Membre_From_Groupe_api, setDelete_List_Membre_From_Groupe_api] = useState();
    const [Delete_List_Membre_From_Groupe_message, setDelete_List_Membre_From_Groupe_message] = useState();
    const [Delete_List_Membre_From_Groupe_result, setDelete_List_Membre_From_Groupe_result] = useState();
    function Delete_List_Membre_From_Groupe(local_tab_ids) {


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("groupe_inscription_id", selected_id);
        form.append("tab_ids", local_tab_ids);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Groupe_Inscrit_Membres/";
        setLoading(true);

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_List_Membre_From_Groupe  res.data.status = " + res.data.status);
            //console.log(" In Delete_List_Membre_From_Groupe  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_List_Membre_From_Groupe_api("true");
                setDelete_List_Membre_From_Groupe_result(res.data.message);


                setselectionModel_grp_membre([]);
                Getall_Groupe_Membres(selected_id);

                alert(res.data.message)
            }
            else {
                setDelete_List_Membre_From_Groupe_api("false");
                setDelete_List_Membre_From_Groupe_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_List_Membre_From_Groupe : Not good man :( mysearchtext = ' + error);
            setDelete_List_Membre_From_Groupe_api("false");
            alert(" Impossible de supprimer les membres");
        })
    }

    const [selectionModel_grp_membre, setselectionModel_grp_membre] = React.useState([]);


    const New_Option_Filter = [
        { "id": "code", "label": "Code Groupe  ", "value": "code" },
        { "id": "type_groupe_code", "label": "Type de groupe (TD, TP, etc) ", "value": "type_groupe_code" },
        { "id": "class_external_code", "label": "Lié à la formation (code) ", "value": "class_external_code" },
        { "id": "classcode_session_external_code", "label": "Lié à la Session (code ) ", "value": "code_session" },
        { "id": "", "label": "", "value": "" },
        { "id": "1", "label": "", "value": "1" }, // Cette ligne pour couvrir l'ajout d'une ligne de filter. C'est fait expres.
    ]

    function handleClick_delete_groupe(event, cellValues) {
        Delete_Given_Goupe(cellValues.row._id);
    }


    const [Delete_Given_Goupe_api, setDelete_Given_Goupe_api] = useState();
    const [Delete_Given_Goupe_message, setDelete_Given_Goupe_message] = useState();
    const [Delete_Given_Goupe_result, setDelete_Given_Goupe_result] = useState();
    function Delete_Given_Goupe(local_grp_id) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_grp_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Groupe_Inscrit/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_Given_Goupe  res.data.status = " + res.data.status);
            //console.log(" In Delete_Given_Goupe  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setDelete_Given_Goupe_api("true");
                setDelete_Given_Goupe_result(res.data.message);

                Getall_Partner_Groupe_Inscrit();

                setdisplay_grp_apprenant("");
                setgrp_apprenant_data_changed("");
                setgrp_apprenant_data_edit_mode("");
                setgridline_id("");
                setSelectionModel([]);
                alert(res.data.message);

            }
            else {
                setDelete_Given_Goupe_api("false");
                setDelete_Given_Goupe_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {

            console.warn('Delete_Given_Goupe : Not good man :( mysearchtext = ' + error);
            setDelete_Given_Goupe_api("false");
            alert(" Impossible de supprimer le groupe ");
        })
    }


    const [selectionModel_grp_apprenant, setselectionModel_grp_apprenant] = React.useState([]);
    const [selectionModel_grp_apprenant_participant, setselectionModel_grp_apprenant_participant] = React.useState([]);
    const [selected_id, setselected_id] = React.useState("");
    const [display_grp_apprenant, setdisplay_grp_apprenant] = React.useState("");
    const [grp_apprenant_data_changed, setgrp_apprenant_data_changed] = React.useState("");
    const [grp_apprenant_data_edit_mode, setgrp_apprenant_data_edit_mode] = useState("");
    const [add_one_grp_apprenant, setadd_one_grp_apprenant] = React.useState("");
    const [gridline_id, setgridline_id] = useState("");



    const [New_Getall_Partner_Session_Reduice_Fields_result, setNew_Getall_Partner_Session_Reduice_Fields_result] = useState([]);

    const [Getall_Partner_Session_Reduice_Fields_api, setGetall_Partner_Session_Reduice_Fields_api] = useState();
    const [Getall_Partner_Session_Reduice_Fields_message, setGetall_Partner_Session_Reduice_Fields_message] = useState();
    const [Getall_Partner_Session_Reduice_Fields_result, setGetall_Partner_Session_Reduice_Fields_result] = useState();
    function Getall_Partner_Session_Reduice_Fields(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_Session_Ftion_Reduice_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Session_Reduice_Fields  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Session_Reduice_Fields  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Session_Reduice_Fields_api("true");
                setGetall_Partner_Session_Reduice_Fields_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code_session = JSON.parse(x).code_session;
                    var local_titre = JSON.parse(x).titre;
                    var local_class_internal_url = JSON.parse(x).class_internal_url;

                    var local_date_debut = JSON.parse(x).date_debut;
                    var local_date_fin = JSON.parse(x).date_fin;




                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code_session,
                        "code_session": local_code_session,
                        "titre": local_titre,
                        "class_internal_url": local_class_internal_url,
                        "date_debut": local_date_debut,
                        "date_fin": local_date_fin,
                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code_session": "",
                    "titre": "",
                    "class_internal_url": "",
                    "date_debut": "",
                    "date_fin": "",
                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Session_Reduice_Fields_result(new_data2);

            }
            else {
                setGetall_Partner_Session_Reduice_Fields_api("false");
                setGetall_Partner_Session_Reduice_Fields_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Session_Reduice_Fields = ', error);
            setGetall_Partner_Session_Reduice_Fields_api("false");
            alert(" Impossible de recuperer la liste des sessions de formation (classe)");
            //setmyApimyApiMessage("")
        })
    }

    const [New_Getall_Partner_Class_Reduice_Fields_result, setNew_Getall_Partner_Class_Reduice_Fields_result] = useState([]);

    const [Getall_Partner_Class_Reduice_Fields_api, setGetall_Partner_Class_Reduice_Fields_api] = useState();
    const [Getall_Partner_Class_Reduice_Fields_message, setGetall_Partner_Class_Reduice_Fields_message] = useState();
    const [Getall_Partner_Class_Reduice_Fields_result, setGetall_Partner_Class_Reduice_Fields_result] = useState();
    function Getall_Partner_Class_Reduice_Fields(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_All_Class_Few_Fields/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Class_Reduice_Fields  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Class_Reduice_Fields  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Class_Reduice_Fields_api("true");
                setGetall_Partner_Class_Reduice_Fields_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_external_code = JSON.parse(x).external_code;
                    var local_internal_code = JSON.parse(x).internal_code;
                    var local_internal_url = JSON.parse(x).internal_url;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_external_code,
                        "external_code": local_external_code,
                        "internal_code": local_internal_code,
                        "internal_url": local_internal_url
                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "external_code": "",
                    "internal_code": "",
                    "internal_url": ""
                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Partner_Class_Reduice_Fields_result(new_data2);

            }
            else {
                setGetall_Partner_Class_Reduice_Fields_api("false");
                setGetall_Partner_Class_Reduice_Fields_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Class_Reduice_Fields = ', error);
            setGetall_Partner_Class_Reduice_Fields_api("false");
            alert(" Impossible de recuperer la liste des formations");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Partner_Groupe_Inscrit_result, setNew_Getall_Partner_Groupe_Inscrit_result] = useState([]);

    const [Getall_Partner_Groupe_Inscrit_api, setGetall_Partner_Groupe_Inscrit_api] = useState();
    const [Getall_Partner_Groupe_Inscrit_message, setGetall_Partner_Groupe_Inscrit_message] = useState();
    const [Getall_Partner_Groupe_Inscrit_result, setGetall_Partner_Groupe_Inscrit_result] = useState([]);
    function Getall_Partner_Groupe_Inscrit(event) {

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Groupe_Inscrit_With_Filter/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Groupe_Inscrit  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Groupe_Inscrit  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Groupe_Inscrit_api("true");
                setGetall_Partner_Groupe_Inscrit_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_type_groupe_code = JSON.parse(x).type_groupe_code;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_session_id = JSON.parse(x).session_id;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "nom": local_nom,
                        "type_groupe_code": local_type_groupe_code,
                        "class_id": local_class_id,
                        "session_id": local_session_id,


                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "nom": "",
                    "type_groupe_code": "",
                    "class_id": "",
                    "session_id": "",

                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Groupe_Inscrit_result(new_data2);

            }
            else {
                setGetall_Partner_Groupe_Inscrit_api("false");
                setGetall_Partner_Groupe_Inscrit_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Groupe_Inscrit = ', error);
            setGetall_Partner_Groupe_Inscrit_api("false");
            alert(" Impossible de recuperer la liste des groupes d'inscrits");
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {
        Getall_Partner_Class_Reduice_Fields();
        Getall_Partner_Session_Reduice_Fields();

        Getall_Groupe_Apprenant_Type();

        Getall_Partner_Groupe_Inscrit();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])

    const myRef_head = useRef(null);
    const myRef = useRef(null)

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    function Enable_Grp_Apprenant_DetailFields() {
        setgrp_apprenant_data_edit_mode("1");

        var liste_champs = ['detail_code', 'detail_nom', 'detail_description', 'detail_classe', 'detail_type_grp', 'detail_ftion', 'detail_nom']

        for (let i = 0; i < liste_champs.length; i++) {
            if (document.getElementsByName(String(liste_champs[i])) && document.getElementsByName(String(liste_champs[i]))[0]) {
                document.getElementsByName(String(liste_champs[i]))[0].disabled = false;
                document.getElementsByName(String(liste_champs[i]))[0].style.backgroundColor = "#FFFFFF";

            }
        }
    }



    async function Disable_Grp_Apprenant_DetailFields() {
        await sleep(5);
        setgrp_apprenant_data_edit_mode("0");

        var liste_champs = ['detail_code', 'detail_nom', 'detail_description', 'detail_classe', 'detail_type_grp', 'detail_ftion', 'detail_nom']


        for (let i = 0; i < liste_champs.length; i++) {

            if (document.getElementsByName(String(liste_champs[i])) && document.getElementsByName(String(liste_champs[i]))[0]) {
                document.getElementsByName(String(liste_champs[i]))[0].disabled = true;
                document.getElementsByName(String(liste_champs[i]))[0].style.backgroundColor = "#ECEFF1";

            }
        }

    }

    async function submenu_detail() {
        await sleep(5);
        setsubmenu("detail_groupe");
        inactive_active_menu_header("detail_groupe");


        if (String(grp_apprenant_data_edit_mode) !== "1") {
            Disable_Grp_Apprenant_DetailFields();
        }

    }



    async function submenu_apprenant() {
        Getall_Groupe_Membres(selected_id);
        await sleep(5);
        setsubmenu("apprenant");
        inactive_active_menu_header("apprenant");

    }

    async function submenu_piece_jointe() {
        await sleep(5);
        setsubmenu("piece_jointe");
        inactive_active_menu_header("piece_jointe");

        // Refresh affichage des pièces jointes
        Get_List_Of_All_PJ(props.client_id);

    }



    async function submenu_selection() {
        await sleep(5);
        setsubmenu("selection");
        inactive_active_menu_header("selection");

    }




    // Cette fontion desactive tous les menu header et reactive just le menu_header concerné
    function inactive_active_menu_header(current_menu_header) {



        const list_menu_header_names = ['detail_groupe', 'apprenant', 'piece_jointe', 'selection',]
        for (let i = 0; i < list_menu_header_names.length; i++) {
            if (document.getElementsByName(String(list_menu_header_names[i])) && document.getElementsByName(String(list_menu_header_names[i]))[0]) {
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.fontWeight = "400";
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.backgroundColor = "#d8edfc";
                document.getElementsByName(String(list_menu_header_names[i]))[0].style.color = "#3b3e40";

            }
        }


        if (document.getElementsByName(String(current_menu_header)) && document.getElementsByName(String(current_menu_header))[0]) {
            document.getElementsByName(String(current_menu_header))[0].style.fontWeight = "700";
            document.getElementsByName(String(current_menu_header))[0].style.backgroundColor = "#104277";
            document.getElementsByName(String(current_menu_header))[0].style.color = "white";
        }
    }


    // -- Gestion pièces jointes 

    const [Download_one_attached_document_api, setDownload_one_attached_document_api] = useState();
    const [Download_one_attached_document_result, setDownload_one_attached_document_result] = useState();
    const [Download_one_attached_document_message, setDownload_one_attached_document_message] = useState();
    const Download_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');


        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var token = stored_cookie;
        var file_name = event.target.id;


        var url = process.env.REACT_APP_API_URL + "myclass/api/Get_Stored_Downloaded_File/" + token + "/" + file_name;
        setLoading(true);

        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                fileDownload(res.data, nom_fiche_detaillee);
                setDownload_one_attached_document_api("true");
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                setDownload_one_attached_document_api("false");

            });
    }


    const editorRef_competence = useRef(null);
    const [editorRef_competence_limite, seteditorRef_competence_limite] = useState(800);


    const [file_1_name, setfile_1_name] = useState();

    const [sessions_file_change_1_api, setsessions_file_change_1_api] = useState();
    const [sessions_file_change_1_result, setsessions_file_change_1_result] = useState();
    const [sessions_file_change_1_message, setsessions_file_change_1_message] = useState();
    const sessions_file_change_1 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_1_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };


    const [file_2_name, setfile_2_name] = useState();

    const [sessions_file_change_2_api, setsessions_file_change_2_api] = useState();
    const [sessions_file_change_2_result, setsessions_file_change_2_result] = useState();
    const [sessions_file_change_2_message, setsessions_file_change_2_message] = useState();
    const sessions_file_change_2 = event => {

        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;




        console.log("file_size =  ", file_size, " file_type = ", file_type);
        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        setfile_2_name(event.target.files[0]);
        const formData = new FormData();
        formData.append('File', fileUploaded);
        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        //formData.append("token", stored_cookie);
        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);

        return;

    };

    const [Record_All_PJ_api, setRecord_All_PJ_api] = useState();
    const [Record_All_PJ_result, setRecord_All_PJ_result] = useState();
    const [Record_All_PJ_message, setRecord_All_PJ_message] = useState();
    function Record_All_PJ() {

        if (!p_detail_one_file_to_download_type_name) {
            alert(" Vous devez donner un nom à la pièce jointe");
            return;
        }

        if (file_1_name && file_1_name.name) {
            //console.log(" ### Traitement de  : ", file_1_name.name);

            const formData = new FormData();
            formData.append('File', file_1_name);
            //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
            const stored_cookie = getCookie('tokenmysypart');

            formData.append("token", stored_cookie);
            formData.append("file_business_object", p_detail_one_file_to_download_type_name);
            formData.append("file_name", file_1_name.name);
            formData.append("status", "0");
            formData.append("object_owner_collection", "groupe_inscription");
            formData.append("object_owner_id", selected_id);

            //formData.append("class_internal_url", internal_url);
            //console.log("token = " + stored_cookie);


            fetch(
                process.env.REACT_APP_API_URL + "myclass/api/Store_User_Downloaded_File/",
                {
                    method: 'POST',
                    body: formData,
                }
            )
                .then((response) => response.json())
                .then((result) => {

                    //console.log(" ## result['status'] = ", result['status'])
                    if (String(result['status']) === String("true")) {
                        //console.log('Success:', result['message']);
                        setRecord_All_PJ_result(result['message']);
                        setRecord_All_PJ_api("true");

                        Get_List_Of_All_PJ(props.client_id);
                        setfile_1_name();
                        setp_detail_one_file_to_download_type_name();
                        //GetCurrentClass_trainingsession();
                        alert(" La pièce jointe " + file_1_name.name + " a été bien enregistrée")

                    }
                    else {
                        setRecord_All_PJ_message(result['message']);
                        setRecord_All_PJ_api("false");
                    }

                })
                .catch((error) => {
                    console.error(' Record_All_PJ Error:', error);
                    setRecord_All_PJ_api("false");
                });

        }

        if (file_2_name && file_2_name.name) {
            console.log(" ### Traitement de  : ", file_2_name.name);
        }

    }


    const [Delete_one_attached_document_api, setDelete_one_attached_document_api] = useState();
    const [Delete_one_attached_document_result, setDelete_one_attached_document_result] = useState();
    const [Delete_one_attached_document_message, setDelete_one_attached_document_message] = useState();
    const Delete_one_attached_document = (event) => {
        const stored_cookie = getCookie('tokenmysypart');

        var formData = new FormData();
        var nom_fiche_detaillee = "Fiche_Detaillee.pdf";
        var file_name = event.target.id;


        formData.append("token", stored_cookie);
        formData.append("file_name", file_name);

        var url = process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/";


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Delete_Stored_Downloaded_File/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Success:', result['message']);
                    setDelete_one_attached_document_result(result['message']);
                    setDelete_one_attached_document_api("true");
                    Get_List_Of_All_PJ(props.client_id);
                    alert(result['message'])

                }
                else {
                    setDelete_one_attached_document_message(result['message']);
                    setDelete_one_attached_document_api("false");
                }

            })
            .catch((error) => {
                console.error(' Record_All_PJ Error:', error);
                setDelete_one_attached_document_api("false");
                alert(" Impossible de supprimer le document")

            });


    }

    const [Get_List_Of_All_PJ_api, setGet_List_Of_All_PJ_api] = useState();
    const [Get_List_Of_All_PJ_result, setGet_List_Of_All_PJ_result] = useState();
    const [Get_List_Of_All_PJ_message, setGet_List_Of_All_PJ_message] = useState();
    function Get_List_Of_All_PJ(local_employee_id) {

        const formData = new FormData();

        //formData.append('token', 'K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA');
        const stored_cookie = getCookie('tokenmysypart');

        formData.append("token", stored_cookie);
        formData.append("object_owner_collection", "groupe_inscription");
        formData.append("object_owner_id", selected_id);


        //formData.append("class_internal_url", internal_url);
        //console.log("token = " + stored_cookie);


        fetch(
            process.env.REACT_APP_API_URL + "myclass/api/Get_List_object_owner_collection_Stored_Files/",
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {

                //console.log(" ## result['status'] = ", result['status'])
                if (String(result['status']) === String("true")) {
                    //console.log('Message :', result['message']);
                    setGet_List_Of_All_PJ_result(result['message']);
                    setGet_List_Of_All_PJ_api("true");

                }
                else {
                    setGet_List_Of_All_PJ_message(result['message']);
                    setGet_List_Of_All_PJ_api("false");
                }

            })
            .catch((error) => {
                console.error(' Get_List_Of_All_PJ Error:', error);
                setGet_List_Of_All_PJ_api("false");
            });

    }



    function Delete_file_1_name() {
        setfile_1_name();
    }

    function Delete_file_2_name() {
        setfile_2_name();
    }

    const [p_detail_one_file_to_download_type_name, setp_detail_one_file_to_download_type_name] = useState();

    // -- end gestion pièces jointes


    function Ajout_1_grp_apprenant() {

        clear_DetailFieds();

        setgrp_apprenant_data_edit_mode("");
        setgrp_apprenant_data_changed("");
        setdisplay_grp_apprenant("");
        setselected_id("");
        setgridline_id("");
        setp_detail_color("#33b8ff")
        setadd_one_grp_apprenant("1");
        setsubmenu("detail_groupe");

        if (document.getElementById('myRef')) {
            // myRef.current.scrollIntoView({ behavior: "smooth" });
            var divh = document.getElementById('myRef').offsetTop;
            window.scrollTo({
                top: divh,
                behavior: "smooth",
            });
        }

    }


    function Annule_Add_One_Evaluaton_DetailFields() {
        setgrp_apprenant_data_edit_mode("");
        setgrp_apprenant_data_changed("");
        setdisplay_grp_apprenant("");
        setselected_id("");
        setadd_one_grp_apprenant("");
        setsubmenu("");

    }

    const [New_Getall_Groupe_Apprenant_Type_result, setNew_Getall_Groupe_Apprenant_Type_result] = useState([]);

    const [Getall_Groupe_Apprenant_Type_api, setGetall_Groupe_Apprenant_Type_api] = useState();
    const [Getall_Groupe_Apprenant_Type_message, setGetall_Groupe_Apprenant_Type_message] = useState();
    const [Getall_Groupe_Apprenant_Type_result, setGetall_Groupe_Apprenant_Type_result] = useState();
    function Getall_Groupe_Apprenant_Type(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Type_Groupe_Apprenant/";

        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Groupe_Apprenant_Type  res.data.status = " + res.data.status);
                //console.log(" In Getall_Groupe_Apprenant_Type  res.data.message r_class = " + res.data.message);
                setGetall_Groupe_Apprenant_Type_api("true");
                setGetall_Groupe_Apprenant_Type_result(res.data.message);
                setRows(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_description = JSON.parse(x).description;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code + " - " + local_description,
                        "code": local_code,
                        "description": local_description,

                    };
                    new_data2.push(node);
                });


                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "description": "",

                };
                new_data2.push(node);


                if (new_data2.length > 0)
                    setNew_Getall_Groupe_Apprenant_Type_result(new_data2);

            }
            else {
                setGetall_Groupe_Apprenant_Type_api("false");
                setGetall_Groupe_Apprenant_Type_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Groupe_Apprenant_Type = ', error);
            setGetall_Groupe_Apprenant_Type_api("false");
            alert(" Impossible de recuperer la liste des type de groupe d'apprenants");
            //setmyApimyApiMessage("")
        })
    }

    const [GetAttendee_api, setGetAttendee_api] = useState();
    const [GetAttendee_message, setGetAttendee_message] = useState();
    const [GetAttendee_result, setGetAttendee_result] = useState();
    function Get_Given_Groupe_Data(evaluation_id) {
        setgrp_apprenant_data_changed("");
        setgrp_apprenant_data_edit_mode("");


        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", evaluation_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Groupe_Inscrit_Data/";

        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                setLoading(false);
                //console.log(" In Get_Given_Groupe_Data  res.data.status = " + res.data.status);
                //console.log(" In Get_Given_Groupe_Data  res.data.message r_class = " + res.data.message);
                setGetAttendee_api("true");
                setGetAttendee_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_groupe = JSON.parse(res.data.message);

                    if (mylocal_groupe.code)
                        setp_detail_code(mylocal_groupe.code);
                    else
                        setp_detail_code("");

                    if (mylocal_groupe.nom) {
                        setp_detail_nom(mylocal_groupe.nom);
                    } else {
                        setp_detail_nom("");
                    }


                    if (mylocal_groupe.description) {
                        setp_detail_description(mylocal_groupe.description);
                    } else {
                        setp_detail_description("");
                    }

                    if (mylocal_groupe.color) {
                        setp_detail_color(mylocal_groupe.color);
                    } else {
                        setp_detail_color("");
                    }

                    if (mylocal_groupe.comment) {
                        setp_detail_comment(mylocal_groupe.comment);
                    } else {
                        setp_detail_comment("");
                    }


                    if (mylocal_groupe.class_id) {
                        setp_detail_class_id(mylocal_groupe.class_id);
                    } else {
                        setp_detail_class_id("");
                    }

                    if (mylocal_groupe.class_internal_url) {
                        setp_detail_class_internal_url(mylocal_groupe.class_internal_url);
                    } else {
                        setp_detail_class_internal_url("");
                    }


                    if (mylocal_groupe.type_groupe_code) {
                        setp_detail_type_groupe_code(mylocal_groupe.type_groupe_code);
                    } else {
                        setp_detail_type_groupe_code("");
                    }

                    if (mylocal_groupe.session_id) {
                        setp_detail_session_id(mylocal_groupe.session_id);
                    } else {
                        setp_detail_session_id("");
                    }

                    if (document.getElementById('myRef')) {
                        var divh = document.getElementById('myRef').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });
                    }

                    Disable_Grp_Apprenant_DetailFields();
                } else {
                    alert(" Aucune donnée recuperée");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setLoading(false);
                setGetAttendee_api("false");
                setGetAttendee_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_Given_Groupe_Data = ', error);
            setGetAttendee_api("false");
            alert(" Impossible de recuperer les données du groupe");
            //setmyApimyApiMessage("")
        })
    }



    function clean_all_filters() {
        setgridline_id('');
        setp_filtre1("");
        setp_filtre1_value("");

        setp_filtre2("");
        setp_filtre2_value("");

        setp_filtre3("");
        setp_filtre3_value("");

        setp_filtre4("");
        setp_filtre4_value("");

        Getall_Partner_Groupe_Inscrit();
    }

    function Annule_Groupe_DetailFields() {
        Get_Given_Groupe_Data(selected_id);

        setgrp_apprenant_data_changed("");
        setgrp_apprenant_data_edit_mode("");

    }


    const [p_detail_code, setp_detail_code] = React.useState("");
    const [p_detail_nom, setp_detail_nom] = React.useState("");
    const [p_detail_description, setp_detail_description] = React.useState("");
    const [p_detail_comment, setp_detail_comment] = React.useState("");
    const [p_detail_class_id, setp_detail_class_id] = React.useState("");
    const [p_detail_class_internal_url, setp_detail_class_internal_url] = React.useState("");
    const [p_detail_session_id, setp_detail_session_id] = React.useState("");
    const [p_detail_type_groupe_code, setp_detail_type_groupe_code] = React.useState("");
    const [p_detail_color, setp_detail_color] = React.useState("#33b8ff");

    const [p_detail_nb_grp_auto, setp_detail_nb_grp_auto] = React.useState("");
    const [p_detail_split_type_auto, setp_detail_split_type_auto] = React.useState("");
    const [p_detail_class_id_auto, setp_detail_class_id_auto] = React.useState("");
    const [p_detail_session_id_auto, setp_detail_session_id_auto] = React.useState("");
    const [p_detail_class_internal_url_auto, setp_detail_class_internal_url_auto] = React.useState("");
    const [p_detail_type_groupe_code_auto, setp_detail_type_groupe_code_auto] = React.useState("");
    const [p_detail_split_racine, setp_detail_split_racine] = React.useState("");

    function clear_DetailFieds() {
        setp_detail_code("");
        setp_detail_nom("");
        setp_detail_description("");
        setp_detail_comment("");
        setp_detail_class_id("");
        setp_detail_session_id("");
        setp_detail_type_groupe_code("");
        setp_detail_color("");
    }


    const [Add_Update_Grp_Apprenant_Data_api, setAdd_Update_Grp_Apprenant_Data_api] = useState();
    const [Add_Update_Grp_Apprenant_Data_message, setAdd_Update_Grp_Apprenant_Data_message] = useState();
    const [Add_Update_Grp_Apprenant_Data_result, setAdd_Update_Grp_Apprenant_Data_result] = useState();
    function Add_Update_Grp_Apprenant_Data() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("code", p_detail_code);
        form.append("nom", p_detail_nom);
        form.append("description", p_detail_description);
        form.append("type_groupe_code", p_detail_type_groupe_code);
        form.append("class_id", p_detail_class_id);
        form.append("session_id", p_detail_session_id);
        form.append("color", p_detail_color);



        var myurl = "";

        if (selected_id && String(selected_id).length > 5) {
            form.append("_id", selected_id);
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Update_Groupe_Inscrit/";
        } else {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_Groupe_Inscrit/";
        }



        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Add_Update_Grp_Apprenant_Data  res.data.status = " + res.data.status);
            //console.log(" In Add_Update_Grp_Apprenant_Data  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setAdd_Update_Grp_Apprenant_Data_api("true");
                setAdd_Update_Grp_Apprenant_Data_result(res.data.message);

                Disable_Grp_Apprenant_DetailFields();
                setgrp_apprenant_data_changed("");
                setgrp_apprenant_data_edit_mode("");
                clear_DetailFieds();

                handleClick_edit_evaluation_From_Line(gridline_id);

                if (selected_id && String(selected_id).length > 5) {
                    Disable_Grp_Apprenant_DetailFields();
                    setgrp_apprenant_data_changed("");
                    setgrp_apprenant_data_edit_mode("");

                    setdisplay_grp_apprenant('1');

                } else {
                    Disable_Grp_Apprenant_DetailFields();
                    setgrp_apprenant_data_changed("");
                    setgrp_apprenant_data_edit_mode("");
                    setdisplay_grp_apprenant("");
                    setadd_one_grp_apprenant("");
                    setsubmenu("");
                }

                Getall_Partner_Groupe_Inscrit();


                alert(res.data.message);

                if (document.getElementById('myRef')) {
                    // myRef.current.scrollIntoView({ behavior: "smooth" });
                    var divh = document.getElementById('myRef').offsetTop;
                    window.scrollTo({
                        top: divh,
                        behavior: "smooth",
                    });
                }

            }
            else {
                setAdd_Update_Grp_Apprenant_Data_api("false");
                setAdd_Update_Grp_Apprenant_Data_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.log('UpdateStagiaireData : Not good man :( Add_Update_Grp_Apprenant_Data = ' + error);
            setAdd_Update_Grp_Apprenant_Data_api("false");
            alert(" Impossible d'ajouter ou  mettre à jour le groupe");

        })
    }


    function handleClick_edit_evaluation_From_Line(selected_row_id) {

        var line = JSON.parse(Getall_Partner_Groupe_Inscrit_result[selected_row_id]);

        setselected_id(line._id);
        setgridline_id(selected_row_id);

        Get_Given_Groupe_Data(line._id);

        setdisplay_grp_apprenant("1");
        setadd_one_grp_apprenant("");
        setgrp_apprenant_data_changed("");
        setgrp_apprenant_data_edit_mode("");
        submenu_detail();

        //Getall_Evaluation_Participant_List();

    }

    const [Getall_Partner_Groupe_Inscrit_With_Filter_api, setGetall_Partner_Groupe_Inscrit_With_Filter_api] = useState();
    const [Getall_Partner_Groupe_Inscrit_With_Filter_message, setGetall_Partner_Groupe_Inscrit_With_Filter_message] = useState();
    const [Getall_Partner_Groupe_Inscrit_With_Filter_result, setGetall_Partner_Groupe_Inscrit_With_Filter_result] = useState();
    function Getall_Partner_Groupe_Inscrit_With_Filter(event) {

        var form = new FormData();


        // Recuperation des parametres
        var filtre1 = p_filtre1;
        var filtre2 = p_filtre2;
        var filtre3 = p_filtre3;
        var filtre4 = p_filtre4;

        var filtre1_value = "";
        var filtre2_value = "";
        var filtre3_value = "";
        var filtre4_value = "";
        // Recuperation des valeurs de filtres

        if (p_filtre1_value) {
            filtre1_value = p_filtre1_value;
            form.append(filtre1, filtre1_value);
        }

        if (p_filtre2_value) {
            filtre2_value = p_filtre2_value;
            form.append(filtre2, filtre2_value);
        }

        if (p_filtre3_value) {
            filtre3_value = p_filtre3_value;
            form.append(filtre3, p_filtre3_value);
        }

        if (p_filtre4_value) {
            filtre4_value = p_filtre4_value;
            form.append(filtre4, p_filtre4_value);
        }


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Groupe_Inscrit_With_Filter/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Partner_Groupe_Inscrit_With_Filter  res.data.status = " + res.data.status);
                //console.log(" In Getall_Partner_Groupe_Inscrit_With_Filter  res.data.message r_class = " + res.data.message);
                setGetall_Partner_Groupe_Inscrit_With_Filter_api("true");
                setGetall_Partner_Groupe_Inscrit_With_Filter_result(res.data.message);

                setGetall_Partner_Groupe_Inscrit_result(res.data.message);



                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_code = JSON.parse(x).code;
                    var local_nom = JSON.parse(x).nom;
                    var local_class_id = JSON.parse(x).class_id;
                    var local_session_id = JSON.parse(x).session_id;
                    var local_type_groupe_code = JSON.parse(x).type_groupe_code;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_code,
                        "code": local_code,
                        "nom": local_nom,
                        "class_id": local_class_id,
                        "session_id": local_session_id,
                        "type_groupe_code": local_type_groupe_code,

                    };
                    new_data2.push(node);
                });

                //---
                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "code": "",
                    "nom": "",
                    "class_id": "",
                    "session_id": "",
                    "type_groupe_code": "",


                };
                new_data2.push(node);

                if (new_data2.length > 0)
                    setNew_Getall_Partner_Groupe_Inscrit_result(new_data2);

            }
            else {
                setGetall_Partner_Groupe_Inscrit_With_Filter_api("false");
                setGetall_Partner_Groupe_Inscrit_With_Filter_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Partner_Groupe_Inscrit_With_Filter = ', error);
            setGetall_Partner_Groupe_Inscrit_With_Filter_api("false");
            //setmyApimyApiMessage("")
        })
    }


    const [New_Getall_Groupe_Membres_result, setNew_Getall_Groupe_Membres_result] = useState([]);

    const [Getall_Groupe_Membres_api, setGetall_Groupe_Membres_api] = useState();
    const [Getall_Groupe_Membres_message, setGetall_Groupe_Membres_message] = useState();
    const [Getall_Groupe_Membres_result, setGetall_Groupe_Membres_result] = useState([]);
    function Getall_Groupe_Membres(local_groupe_id) {

        var evalutaion_id = "";

        var form = new FormData();


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_groupe_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Groupe_Membres/";



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Groupe_Membres  res.data.status = " + res.data.status);
                //console.log(" In Getall_Groupe_Membres  res.data.message r_class = " + res.data.message);
                setGetall_Groupe_Membres_api("true");
                setGetall_Groupe_Membres_result(res.data.message);


                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_prenom = JSON.parse(x).prenom;
                    var local_email = JSON.parse(x).email;
                    var local_inscription_id = JSON.parse(x).inscription_id;


                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "prenom": local_prenom,
                        "email": local_email,

                        "inscription_id": local_inscription_id,


                    };
                    new_data2.push(node);
                });



                if (new_data2)
                    setNew_Getall_Groupe_Membres_result(new_data2);

            }
            else {
                setGetall_Groupe_Membres_api("false");
                setGetall_Groupe_Membres_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Getall_Groupe_Membres = ', error);
            setGetall_Groupe_Membres_api("false");
            alert(" Impossible de recuperer la liste des membres du groupe");
            //setmyApimyApiMessage("")
        })
    }


    const [gridline_grp_member_id, setgridline_grp_member_id] = useState("");

    function Export_Groupe_Membres_exel() {
        const stored_cookie = getCookie('tokenmysypart');

        var nom_fichier_cmd = "export_notes.xlsx";

        var url = process.env.REACT_APP_API_URL + "myclass/api/Export_To_Excel_List_Groupe_Membres/" + stored_cookie + "/" + selected_id + "/";


        setLoading(true);
        axios.get(url, { responseType: 'blob', },)
            .then((res) => {
                setLoading(false);
                //console.log(" In Export_Dashbord_to_CSV  res.data = " + res.data);
                if (String(res.data) === String("false")) {
                    alert("Impossible d'exporter les membres (2) ");
                } else {
                    fileDownload(res.data, nom_fichier_cmd)

                }
            }).catch((error) => {
                setLoading(false);
                console.error('Error:', error);
                alert(" Impossible d'exporter les membres ")

            });


        setLoading(false);
    }



    function GetSelectedRows_Groupe_Membres_Ids() {


        var tab_tmp = []
        for (var i = 0; i < selectionModel_grp_membre.length; i++) {
            //console.log(" ### selectionModel_insc[i] = ", selectionModel_insc[i]);
            var myid = parseInt(String(selectionModel_grp_membre[i]));
            //var line = JSON.parse(rowss[myid]);
            tab_tmp.push(myid);
        }
        return tab_tmp;

    }

    async function actionmass_grp_membre_Traitemet() {

        var liste_apprenant_id = GetSelectedRows_Groupe_Membres_Ids();

        //console.log(" liste_preinscr_id = ", liste_preinscr_id);



        var tab_mongo_preins_ids = [];

        for (var i = 0; i < liste_apprenant_id.length; i++) {

            var line = Getall_Groupe_Membres_result[liste_apprenant_id[i]]


            var line_json = JSON.parse(line)
            //console.log(" line_json['prenom'] = ", line_json['prenom']);
            //console.log(" line_json['_id'] = ", line_json['_id']);
            tab_mongo_preins_ids.push(line_json['_id'])

        }

        if (String(actionmass_grp_membre_val) === String("delete_grp_membre")) {
            Delete_List_Membre_From_Groupe(tab_mongo_preins_ids);

        }
    }

    const [actionmass_grp_membre_val, setactionmass_grp_membre_val] = useState("");


    const New_Option_grp_membre = [
        { "id": "delete_grp_membre", "label": "Supprimer les membres", "value": "delete_grp_membre" },
        { "id": "", "label": "", "value": "" },
    ]

    const [Dialog_GRP_AUTOMATIC_message, setDialog_GRP_AUTOMATIC_message] = React.useState(false);
    const [Dialog_GRP_AUTOMATIC_open, setDialog_GRP_AUTOMATIC_open] = React.useState(false);


    const Dialog_GRP_AUTOMATIC_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_GRP_AUTOMATIC_handleClose_buton = () => {

        setDialog_GRP_AUTOMATIC_open(false);
        setp_detail_nb_grp_auto("");
        setp_detail_split_type_auto("");

        setp_detail_class_id_auto("");
        setp_detail_session_id_auto("");
        setp_detail_class_internal_url_auto("");
        setp_detail_type_groupe_code_auto("");
        setp_detail_split_racine("");
    };


    const New_Option_Groupe_By = [
        { "id": "0", "label": "Ordre Alphabétique (nom)", "value": "0" },
        { "id": "1", "label": "Aléatoire", "value": "1" },
        { "id": "", "label": "", "value": "" },
    ]

    const [Create_Automatic_Grp_api, setCreate_Automatic_Grp_api] = useState();
    const [Create_Automatic_Grp_message, setCreate_Automatic_Grp_message] = useState();
    const [Create_Automatic_Grp_result, setCreate_Automatic_Grp_result] = useState();
    function Create_Automatic_Grp() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);


        form.append("nb_groupe", p_detail_nb_grp_auto);
        form.append("split_type", p_detail_split_type_auto);

        form.append("type_groupe_code", p_detail_type_groupe_code_auto);
        form.append("class_id", p_detail_class_id_auto);
        form.append("session_id", p_detail_session_id_auto);

        form.append("racine", p_detail_split_racine);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Automatic_Creation_Groupe_Member_Inscrit/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Create_Automatic_Grp  res.data.status = " + res.data.status);
            //console.log(" In Create_Automatic_Grp  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setCreate_Automatic_Grp_api("true");
                setCreate_Automatic_Grp_result(res.data.message);

                Disable_Grp_Apprenant_DetailFields();
                setgrp_apprenant_data_changed("");
                setgrp_apprenant_data_edit_mode("");
                setSelectionModel([]);
                clear_DetailFieds();
                Dialog_GRP_AUTOMATIC_handleClose_buton();

                Getall_Partner_Groupe_Inscrit();

                alert(res.data.message);


                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });


            }
            else {
                setCreate_Automatic_Grp_api("false");
                setCreate_Automatic_Grp_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.log('UpdateStagiaireData : Not good man :( Create_Automatic_Grp = ' + error);
            setCreate_Automatic_Grp_api("false");
            alert(" Impossible de créer les groupes ");

        })
    }


    const [Automatic_Split_Membre_No_Grp_Creation_api, setAutomatic_Split_Membre_No_Grp_Creation_api] = useState();
    const [Automatic_Split_Membre_No_Grp_Creation_message, setAutomatic_Split_Membre_No_Grp_Creation_message] = useState();
    const [Automatic_Split_Membre_No_Grp_Creation_result, setAutomatic_Split_Membre_No_Grp_Creation_result] = useState();
    function Automatic_Split_Membre_No_Grp_Creation() {
        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");


        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        form.append("split_type", p_detail_split_type_auto);
        form.append("class_id", p_detail_class_id_auto);
        form.append("session_id", p_detail_session_id_auto);
        form.append("type_groupe_code", p_detail_type_groupe_code_auto);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Automatic_Split_Member_Inscrit_To_Grps/";




        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Automatic_Split_Membre_No_Grp_Creation  res.data.status = " + res.data.status);
            //console.log(" In Automatic_Split_Membre_No_Grp_Creation  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setAutomatic_Split_Membre_No_Grp_Creation_api("true");
                setAutomatic_Split_Membre_No_Grp_Creation_result(res.data.message);

                Disable_Grp_Apprenant_DetailFields();
                setgrp_apprenant_data_changed("");
                setgrp_apprenant_data_edit_mode("");
                setSelectionModel([]);
                clear_DetailFieds();
                Dialog_GRP_AUTOMATIC_handleClose_buton();


                Getall_Partner_Groupe_Inscrit();

                alert(res.data.message);

                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });


            }
            else {
                setAutomatic_Split_Membre_No_Grp_Creation_api("false");
                setAutomatic_Split_Membre_No_Grp_Creation_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);

            console.log('UpdateStagiaireData : Not good man :( Automatic_Split_Membre_No_Grp_Creation = ' + error);
            setAutomatic_Split_Membre_No_Grp_Creation_api("false");
            alert(" Impossible de repartir les inscrits ");

        })
    }

    const [actionmass_ftion_val, setactionmass_ftion_val] = useState();
    const actionmass_ftion = (event) => {
        const value = event.target.value;


        if (String(value) === "supprimer") {
            setactionmass_ftion_val(value);
        }
        else {
            setactionmass_ftion_val();
        }
    }


    const New_Option = [

        { "id": "2", "label": "Supprimer", "value": "supprimer" },
    ]

    async function actionmass_ftion_Traitemet() {


        var liste_grp_apprenant = GetSelectedRowsApprenantGrp();

        if (String(actionmass_ftion_val) === String("supprimer")) {

            Delete_LIST_Grp(liste_grp_apprenant);
        }

    }

    const [Delete_LIST_Grp_api, setDelete_LIST_Grp_api] = useState();
    const [Delete_LIST_Grp_message, setDelete_LIST_Grp_message] = useState();
    const [Delete_LIST_Grp_result, setDelete_LIST_Grp_result] = useState();
    function Delete_LIST_Grp(local_list_grp) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");

        //console.log(" #### local_liste_session = ", local_liste_session)

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_list_grp);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Delete_Groupe_Inscrit/";
        setLoading(true);
        axios.post(myurl, form).then(res => {
            //console.log(" In Delete_LIST_Grp  res.data.status = " + res.data.status);
            //console.log(" In Delete_LIST_Grp  res.data.message r_class = " + res.data.message);

            setLoading(false);

            if (String(res.data.status) === String("true")) {
                setDelete_LIST_Grp_api("true");
                setDelete_LIST_Grp_result(res.data.message);

                setselectionModel_grp_apprenant([]);


                setgridline_id('');
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });

                Getall_Partner_Groupe_Inscrit();

                alert(res.data.message);
            }
            else {
                setDelete_LIST_Grp_api("false");
                setDelete_LIST_Grp_message(res.data.message);
                alert(res.data.message);

            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Delete_LIST_Grp : Not good man :( mysearchtext = ' + error);
            setDelete_LIST_Grp_api("false");
            alert(" Impossible de supprimer la liste des apprenants");
        })
    }



    function GetSelectedRowsApprenantGrp() {
        ///alert(" les lignes slected sont :  " + selectionModel + " nb item = " + selectionModel.length); 
        //const selectedRowsData = selectionModel.map((id) => rowss.find((row) => row.id === id));
        //console.log(rowss[0]);
        var tab_tmp = []
        for (var i = 0; i < selectionModel_grp_apprenant.length; i++) {
            var myid = parseInt(String(selectionModel_grp_apprenant[i]));
            var line = JSON.parse(Getall_Partner_Groupe_Inscrit_result[myid]);
            tab_tmp.push(line._id);
            //console.log(rowss[myid]['external_code']);
        }

        return tab_tmp;

    }


    const [submenu_grp_dialog, setsubmenu_grp_dialog] = useState("create");

    function submenu_create_grp() {
        setsubmenu_grp_dialog("create");
    }

    function submenu_update_grp_members() {
        setsubmenu_grp_dialog("update");
    }

    const handleClose = () => {
        //setOpen(false);
    };


    const [Dialog_GRP_COLOR_message, setDialog_GRP_COLOR_message] = React.useState(false);
    const [Dialog_GRP_COLOR_open, setDialog_GRP_COLOR_open] = React.useState(false);


    const Dialog_GRP_COLOR_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_GRP_COLOR_handleClose_buton = () => {
        setDialog_GRP_COLOR_open(false);

    };


    // DEBUT  EXPORT EXCEL DATAGRID 

    function CustomToolbar(props) {


        return (
            <GridToolbarContainer {...props}>

                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={{ tooltip: { title: 'Change density' } }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <ExportButton data_colums={props.data_colums} />


            </GridToolbarContainer>
        );
    }

    function getExcelData(apiRef) {
        // Select rows and columns
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        // Format the data. Here we only keep the value
        const data = filteredSortedRowIds.map((id) => {
            const row = {};
            visibleColumnsField.forEach((field) => {
                row[field] = apiRef.current.getCellParams(id, field).value;
            });
            return row;
        });

        return data;
    }


    function ExportButton(props) {
        return (
            <GridToolbarExportContainer {...props}>
                <ExportMenuItem data_colums={props.data_colums} />
            </GridToolbarExportContainer>
        );
    }

    function handleExport(apiRef, data_colums) {
        const data = getExcelData(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
       

        const local_config = {
            keys: visibleColumnsField,

            columnNames: data_colums.filter((mydata) => (mydata).disableExport !== true).map(function (mydata) {
                if (mydata.headerName && visibleColumnsField.includes(mydata.field)) {
                    if (mydata.headerName) {
                        return mydata.headerName;
                    }
                }

            }),

            fileName: 'data_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_") + '.xlsx',
            sheetName: 'Export_' + String(new Date().toJSON().slice(0, 10)).replaceAll("-", "_"),
        };

        local_config.columnNames = local_config.columnNames.filter(function( element ) {
            return element !== undefined;
         });


        const rows = data.map((row) => {
            const mRow = {};
            for (const key of local_config.keys) {
                mRow[key] = row[key];
            }
            return mRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.sheet_add_aoa(worksheet, [[...local_config.columnNames]], {
            origin: 'A1',
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, local_config.sheetName);
        XLSX.writeFile(workbook, local_config.fileName, { compression: true });
    }

    function ExportMenuItem(props) {
        const apiRef = useGridApiContext();
        const { hideMenu } = props;

        return (
            <MenuItem
                onClick={() => {
                    handleExport(apiRef, props.data_colums);
                    // Hide the export menu after the export
                    hideMenu?.();
                }}
            >
                Export Excel
            </MenuItem>
        );
    }

    // FIN EXPORT EXCEL DATAGRID 

    return (
        <div className="groupe_apprenant">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}



            {/*** Dialog pickup Color */}

            <Dialog
                open={Dialog_GRP_COLOR_open}
                // onClose={Dialog_GRP_COLOR_handleClose}


                static
                onClose={() => null}

                PaperProps={{


                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        position: 'absolute',
                        //top: '5rem'
                    },
                }}
                hideBackdrop={true}
                disableScrollLock
                classes={{
                    paper: classes.dialog
                }}
                className="groupe_apprenant_dialog"

            >

                <DialogTitle>  Choisir une couleur</DialogTitle>
                <DialogContent className="DialogContent_color_pickup_width" >
                    <DialogContentText>

                    </DialogContentText>

                    <div className="session_caract"> Code couleur  <br />
                        <ColorPicker
                            color="#3573CB"
                            theme={{
                                background: 'lightgrey',
                                inputBackground: 'grey',
                                borderColor: 'darkgrey',
                                borderRadius: '8px',
                                color: 'black',
                                width: '100px'
                            }}
                            name="detail_grp_color"
                            id="detail_grp_color"
                            onChange={(color) => {
                                setp_detail_color(color.hex);
                                setgrp_apprenant_data_changed("1");
                            }
                            }
                        />



                    </div>
                </DialogContent>

                <DialogActions>

                    <IconButton
                        autoFocus
                        onClick={Dialog_GRP_COLOR_handleClose_buton}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>





            </Dialog>

            {/*** Fin ajout  pickup Color */}


            {/*** Dialog GROUPE AUTOMATIC */}

            <Dialog
                open={Dialog_GRP_AUTOMATIC_open}
                // onClose={Dialog_GRP_AUTOMATIC_handleClose}


                static
                onClose={() => null}

                PaperProps={{


                    className: classes.paper, // Apply the paper style
                    style: {
                        overflowY: 'unset',
                        position: 'absolute',
                        //top: '5rem'
                    },
                }}
                hideBackdrop={true}
                disableScrollLock
                classes={{
                    paper: classes.dialog
                }}
                className="groupe_apprenant_dialog mysy_MuiPaper-root"

            >

                <DialogTitle>  Création automatique des groupes </DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>

                    </DialogContentText>

                    {<div className="session_caract_Dialog" style={{ textAlign: 'right' }} >

                        <Button variant="outlined" onClick={submenu_create_grp} className="detail_class_submenu" id='dialog_detail_seq_detail'
                            name='dialog_detail_seq_detail'>Création</Button>

                        <Button variant="outlined" onClick={submenu_update_grp_members} className="detail_class_submenu" id='dialog_detail_seq_ressource'
                            name='dialog_detail_seq_ressource'>M.A.J Membres</Button>
                    </div>}

                    <div className="session_caract_Dialog" >  <b>Formation  </b> <br />
                        {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                name="detail_ftion"
                                id="detail_ftion"
                                //className="disabled_style"
                                fullWidth
                                //className="disabled_style enable_style"
                                options={New_Getall_Partner_Class_Reduice_Fields_result}
                                value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id_auto))[0].label}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_class_id_auto(value._id);
                                        setp_detail_class_internal_url_auto(value.internal_url);
                                        setgrp_apprenant_data_changed("1");
                                    } else {


                                        setp_detail_class_id_auto("");
                                        setp_detail_class_internal_url_auto("");
                                        setgrp_apprenant_data_changed("1");
                                    }

                                    setp_detail_session_id("");

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        }

                    </div>

                    <div className="session_caract_Dialog" ><b> Classe  </b> <br />
                        {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                name="detail_classe"
                                id="detail_classe"
                                fullWidth
                                options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_class_internal_url_auto))}
                                value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id_auto))[0].label}
                                onChange={(event, value) => {
                                    if (value && value._id) {
                                        setp_detail_session_id_auto(value._id);
                                        setgrp_apprenant_data_changed("1");
                                    } else {
                                        setp_detail_session_id_auto("");
                                        setgrp_apprenant_data_changed("1");
                                    }
                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        }

                    </div>

                    <div className="session_caract_Dialog" ><b>Type de Groupe  </b> <br />
                        {New_Getall_Groupe_Apprenant_Type_result && New_Getall_Groupe_Apprenant_Type_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                name="detail_type_grp"
                                id="detail_type_grp"
                                fullWidth
                                options={New_Getall_Groupe_Apprenant_Type_result}
                                value={New_Getall_Groupe_Apprenant_Type_result.filter((data) => (data).code === String(p_detail_type_groupe_code_auto))[0].label}

                                onChange={(event, value) => {
                                    if (value && value.code) {

                                        setp_detail_type_groupe_code_auto(value.code);
                                        setgrp_apprenant_data_changed("1");
                                    } else {

                                        setp_detail_type_groupe_code_auto("");
                                        setgrp_apprenant_data_changed("1");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />

                        }


                    </div>


                    <div className="session_caract_Dialog" > <b> Grouper les inscrits par </b>
                        <Autocomplete
                            disablePortal
                            name="event_dialog_type_ressource"
                            id="event_dialog_type_ressource"
                            fullWidth

                            options={New_Option_Groupe_By}
                            onChange={(event, value) => {
                                if (value.value)
                                    setp_detail_split_type_auto(value.value);
                                else
                                    setp_detail_split_type_auto("");
                            }}

                            renderInput={(params) => <TextField {...params} placeholder="Choisir une option " />
                            }
                        />


                    </div>



                    {String(submenu_grp_dialog) === "create" && <div>
                        <div className="session_caract_Dialog" > <b> Nombre de Groupe </b>
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_nb_grp"
                                id="event_dialog_nb_grp"
                                type="number"
                                fullWidth
                                inputProps={{ min: "1", max: "99", step: "1" }}
                                onChange={(e) => {
                                    setp_detail_nb_grp_auto(e.target.value);

                                }
                                }

                            />

                        </div>


                        <div className="session_caract_Dialog" > Racine
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}

                                name="event_dialog_racine"
                                id="event_dialog_racine"

                                fullWidth

                                value={p_detail_split_racine}
                                onChange={(e) => {

                                    setp_detail_split_racine(e.target.value);
                                }
                                }

                            />

                        </div>

                    </div>}

                    {String(submenu_grp_dialog) === "update" && <div>


                        <div className="div_row">
                            <div className="div_row_gauche">
                                <Button onClick={Automatic_Split_Membre_No_Grp_Creation} className="bton_enreg_dialog">Mettre à jour Membres</Button>

                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                                <Button onClick={Dialog_GRP_AUTOMATIC_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>

                            </div>
                        </div>



                    </div>}
                </DialogContent>



                {String(submenu_grp_dialog) === "create" && <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">

                            <Button onClick={Create_Automatic_Grp} className="bton_enreg_dialog">Créer en masse</Button>
                        </div>

                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_GRP_AUTOMATIC_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>



                    </div>
                    <IconButton
                        autoFocus
                        onClick={Dialog_GRP_AUTOMATIC_handleClose_buton}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>}

                {String(submenu_grp_dialog) !== "create" && <DialogActions>

                    <IconButton
                        autoFocus
                        onClick={Dialog_GRP_AUTOMATIC_handleClose_buton}
                        color="primary"
                        className={classes.customizedButton}
                    >
                        <CancelPresentationIcon />
                    </IconButton>

                </DialogActions>}





            </Dialog>

            {/*** Fin ajout  GROUPE AUTOMATIC */}

            <h3> Les groupes d'apprenants </h3>

            <div className="div_row">
                <div className="titre1">  Utilisez les filtres !</div>
                <div className="div_row" style={{ "marginBottom": "5px" }}>
                    <div className="div_row_gauche texte_area_filter" >

                        {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                            disablePortal
                            name="filtre1"
                            id="filtre1"
                            className="disabled_style"
                            value={New_Option_Filter.filter((data) => (data).value === String(p_filtre1))[0].label}
                            options={New_Option_Filter}
                            onChange={(event, value) => {
                                if (value && value.value) {
                                    setp_filtre1(value.value);

                                }

                            }}

                            renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                            />
                            }
                        />}


                    </div>



                    <div className="div_row_droite texte_area_filter_value" >
                        {p_filtre1 &&
                            <TextField
                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                required
                                name="filtre1_value"
                                id="filtre1_value"

                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={false}
                                className="disabled_style"
                                value={p_filtre1_value}
                                onChange={(e) => { setp_filtre1_value(e.target.value); }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AiFillCloseCircle
                                                style={{ 'cursor': "pointer", "color": "orangered" }}
                                                onClick={(e) => {
                                                    setp_filtre1_value("");
                                                }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        }
                    </div>
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                            &nbsp; <MdAddCircleOutline onClick={(e) => {
                                setp_filtre2("1");
                                setp_filtre2_value("");
                            }} />
                        </a>
                    </div>}
                    {p_filtre1 && <div className='filter_bton_add'>
                        <Tooltip className="tooltip_css" id="my-tooltip02" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip02" data-tooltip-html="Supprimer cette ligne de filtre">
                            &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                setp_filtre1("");
                                setp_filtre1_value("");
                            }}
                            />
                        </a>
                    </div>}


                </div>

                {p_filtre2 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre2"
                                id="filtre2"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre2))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre2(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}

                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre2).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre2_value"
                                    id="filtre2_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre2_value}
                                    onChange={(e) => setp_filtre2_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre2_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre3("1");
                                        setp_filtre3_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip04" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip04" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline
                                    onClick={(e) => {
                                        setp_filtre2("");
                                        setp_filtre2_value("");
                                    }}
                                />
                            </a>
                        </div>}

                    </div>
                }


                {p_filtre3 &&
                    <div className="div_row" style={{ "marginBottom": "5px" }}>
                        <div className="div_row_gauche texte_area_filter">

                            {New_Option_Filter && New_Option_Filter.length > 0 && <Autocomplete
                                disablePortal
                                name="filtre3"
                                id="filtre3"
                                className="disabled_style"
                                value={New_Option_Filter.filter((data) => (data).value === String(p_filtre3))[0].label}
                                options={New_Option_Filter}
                                onChange={(event, value) => {
                                    if (value && value.value) {
                                        setp_filtre3(value.value);

                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}


                        </div>
                        <div className="div_row_droite texte_area_filter_value" style={{ "marginTop": "0.5rem" }}>
                            {String(p_filtre3).length > 2 &&
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="filtre3_value"
                                    id="filtre3_value"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_filtre3_value}
                                    onChange={(e) => setp_filtre3_value(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AiFillCloseCircle
                                                    style={{ 'cursor': "pointer", "color": "orangered" }}
                                                    onClick={(e) => {
                                                        setp_filtre3_value("");
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }}

                                />}
                        </div>

                        {String(p_filtre2).length > 2 && <div className='filter_bton_add' >
                            <Tooltip className="tooltip_css" id="my-tooltip03" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip03" data-tooltip-html="Ajouter une nouvelle ligne de filtre">
                                &nbsp; <MdAddCircleOutline
                                    onClick={(e) => {
                                        setp_filtre4("1");
                                        setp_filtre4_value("");
                                    }}
                                />
                            </a>
                        </div>}

                        {String(p_filtre3).length > 2 && <div className='filter_bton_add'>
                            <Tooltip className="tooltip_css" id="my-tooltip05" style={{ "fontSize": "12px" }} />
                            <a data-tooltip-id="my-tooltip05" data-tooltip-html="Supprimer cette ligne de filtre">
                                &nbsp; <MdRemoveCircleOutline onClick={(e) => {
                                    setp_filtre3("");
                                    setp_filtre3_value("");
                                }} />
                            </a>
                        </div>}
                    </div>
                }


                <div className="div_row" ref={myRef_head} id="myRef_head" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                    <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>
                        <Button variant="contained" className="bton_enreg" onClick={Getall_Partner_Groupe_Inscrit_With_Filter}>Rechercher
                        </Button>
                    </div>

                    <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>
                        <Button variant="contained" className="bton_annule" onClick={clean_all_filters}>Annuler
                        </Button>

                    </div>

                </div>

                <br />
            </div>

            <div className="div_row" style={{ paddingRight: '10px' }}>

                <div className="session_data">
                    <div style={{ "border": "None" }}>

                        <div style={{ height: 550, width: '100%', paddingRight: '5px' }}>

                            &nbsp;
                            <Box
                                sx={{
                                    height: 500,
                                    width: '100%',
                                    paddingRight: '1px',

                                    // Gestion des cellule a distance ou presentiel
                                    '& .cell--distantiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },

                                    '& .cell--presentiel': {
                                        backgroundColor: '#AFE1AF',
                                        color: 'black',
                                    },


                                    // gestion couleur des lignes avec le statut d'inscription
                                    '& .line--statut--annule': {
                                        backgroundColor: '#D8D8D8',
                                        color: 'white',
                                    },

                                    '& .line--statut--inscrit': {
                                        backgroundColor: '#CEF6D8',
                                        color: 'black',
                                    },
                                    '& .line--statut--preinscrit': {
                                        backgroundColor: '#F7F2E0',
                                        color: 'black',
                                    },

                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: "#c8cfd5",
                                        color: "black",
                                        fontSize: 14
                                    },

                                    '& .line--statut--selected': {
                                        backgroundColor: '#FBF2EF',
                                        color: 'black',
                                    },
                                    '& .line--statut--pair': {
                                        backgroundColor: 'rgba(235, 235, 235, .7)',
                                        color: 'black',
                                    },
                                    '& .line--statut--impair': {
                                        backgroundColor: '#FFFFFF',
                                        color: 'black',
                                    },
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },


                                }}
                            >
                                <DataGrid
                                    checkboxSelection

                                    components={{
                                        Toolbar: CustomToolbar
                                    }}
                                    componentsProps={{ toolbar: { data_colums: columns_grp_apprenant } }}

                                    onSelectionModelChange={(newSelectionModel) => {
                                        setselectionModel_grp_apprenant(newSelectionModel);
                                        /*if (newSelectionModel.length === 1)
                                            handleClick_edit_employee_From_Line(newSelectionModel);*/
                                        if (newSelectionModel.length !== 1) {
                                            /*  setsubmenu();
                                              setdisplay_detail_employe();
                                              setadd_One_Employee();*/
                                        }
                                    }}
                                    selectionModel={selectionModel_grp_apprenant}

                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rows={Getall_Partner_Groupe_Inscrit_result.map((item, index) => (
                                        {
                                            id: index,
                                            _id: JSON.parse(item)._id,
                                            code: JSON.parse(item).code,
                                            nom: JSON.parse(item).nom,
                                            type_groupe_code: JSON.parse(item).type_groupe_code,
                                            class_id: JSON.parse(item).class_id,
                                            session_id: JSON.parse(item).session_id,
                                            nb_membre: JSON.parse(item).nb_membre,
                                        }
                                    ))}

                                    columns={columns_grp_apprenant}
                                    pageSize={10}
                                    className="datagridclass"

                                    onRowDoubleClick={(newSelectionModel) => {


                                        setselected_id(newSelectionModel.row._id);

                                        setgridline_id(newSelectionModel.row.id);

                                        handleClick_edit_evaluation_From_Line(newSelectionModel.row.id);


                                    }}

                                    rowsPerPageOptions={[10]}
                                    disableSelectionOnClick
                                    /*     components={{
                                             Toolbar: GridToolbar,
                                         }}*/
                                    //sx={datagridSx}
                                    getCellClassName={(params) => {



                                    }}
                                    getRowClassName={(params) => {
                                        // Pour la gestion de la couleur de zone double cliquée
                                        if (String(params.row.id) === String(gridline_id)) {
                                            return 'line--statut--selected';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 === 0) {
                                            return 'line--statut--pair';
                                        }
                                        else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                            return 'line--statut--impair';
                                        }


                                    }}
                                    getEstimatedRowHeight={() => 200}
                                    getRowHeight={() => "auto"}
                                    sx={{
                                        "& .MuiDataGrid-cellContent": {
                                            minHeight: 50,

                                        }
                                    }}

                                />
                            </Box>
                            <br />

                        </div>

                        <div className="div_row">

                            {selectionModel_grp_apprenant && selectionModel_grp_apprenant.length >= 1 && <div className="block_en_mass">
                                <nav >Traitement en masse </nav>
                                &nbsp;
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    className="block_en_mass_select"
                                    fullWidth
                                    options={New_Option}
                                    onChange={(event, value) => {
                                        if (value && value.value) {
                                            if (String(value.value) === "supprimer"
                                                || String(value.value) === "dupliquer" || String(value.value) === "exporter"
                                                || String(value.value) === "inscrire") {
                                                setactionmass_ftion_val(value.value);
                                            }
                                            else {
                                                setactionmass_ftion_val();
                                            }

                                        }

                                    }}

                                    renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                        inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                    />
                                    }
                                />



                                {actionmass_ftion_val && <nav className='block_en_mass_bton_action'>
                                    <Popup
                                        trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>

                                            <FcAcceptDatabase /> Traiter

                                        </Button>}
                                        modal
                                        nested
                                        position="center center"

                                    >

                                        {close => (
                                            <div>
                                                <button className="gest_close" onClick={close}>
                                                    &times;
                                                </button>
                                                <div className="gest_header"> MySy Information - {actionmass_ftion_val} </div>
                                                <div className="gest_content">
                                                    {' '}
                                                    {String(actionmass_ftion_val) === "supprimer" &&
                                                        <font color="red"> Confirmer la suppression de {selectionModel_grp_apprenant.length} groupe(s) .
                                                        </font>}




                                                </div>
                                                <div className="gest_actions">
                                                    <div style={{ "width": "45%", "float": "left" }}>
                                                        <button className="gest_bton_popup" onClick={(event) => {
                                                            actionmass_ftion_Traitemet();
                                                            close();
                                                        }}> Valider </button>

                                                    </div>
                                                    <div style={{ "width": "45%", "float": "right" }}>
                                                        <button
                                                            className="gest_bton_popup"
                                                            onClick={() => {
                                                                //console.log('modal closed ');
                                                                close();
                                                            }}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                </nav>
                                }

                            </div>}
                        </div>
                    </div>
                </div>


                <div className="div_row" style={{ "border": "None" }} id="bton_gestion_grp" name="bton_gestion_grp">

                    <div className="div_row_gauche div_row_gauche_etendu" style={{ "textAlign": "left", "paddingLeft": "5px" }}>
                        <Button variant="outlined"
                            className="detail_class_submenu bton_add_session bton_add_session_create_automatic" style={{ "width": "40%" }}
                            id='menu_import_participant' name='menu_import_participant' onClick={(event) => {


                                setDialog_GRP_AUTOMATIC_open(true);

                                submenu_create_grp();

                                Annule_Add_One_Evaluaton_DetailFields();

                            }} > Gestion automatique
                            &nbsp;  <FcMultipleSmartphones />
                        </Button>


                        &nbsp;

                        <Button variant="outlined" onClick={Ajout_1_grp_apprenant}
                            className="detail_class_submenu bton_add_session"
                            id='menu_import_participant' name='menu_import_participant'>Ajout 1 Grp &nbsp;
                            <IoAddCircleOutline style={{ "fontSize": "x-large", "color": "green" }} />
                        </Button>

                    </div>
                </div>
            </div>

            <div className="div_row" ref={myRef} id="myRef"> &nbsp; </div>

            {selected_id && String(selected_id).length > 5 && <div className="div_row">
                <Button variant="outlined" onClick={submenu_detail} className="detail_class_submenu" id='detail_groupe' name='detail_groupe'>Détail Evaluation</Button>
                <Button variant="outlined" onClick={submenu_apprenant} className="detail_class_submenu" id='apprenant' name='apprenant'>Membres </Button>
                <Button variant="outlined" onClick={submenu_selection} className="detail_class_submenu" id='selection' name='selection'>Selection   </Button>
                <Button variant="outlined" onClick={submenu_piece_jointe} className="detail_class_submenu" id='piece_jointe' name='piece_jointe'>Pièces Jointes  </Button>

            </div>}

            {String(submenu) === "detail_groupe" && <div>
                {String(display_grp_apprenant) === "1" && String(add_one_grp_apprenant) !== "1" && <div className="div_row session_data"  >


                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "paddingTop": "10px", "paddingLeft": "5px" }}> Détail Groupe </nav>

                    {String(grp_apprenant_data_edit_mode) !== "1" && <div>

                        <div>
                            <div className="session_caract"> <b> Code </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_code"
                                    id="detail_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_code}

                                />

                            </div>

                            <div className="session_caract"> <b> Nom </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_nom"
                                    id="detail_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_nom}

                                />

                            </div>

                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_description"
                                    id="detail_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_description}

                                />

                            </div>



                            <div className="session_caract"> <b>Formation </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_ftion"
                                    id="detail_ftion"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> <b> Classe </b>  <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_classe"
                                    id="detail_classe"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> <b>Type de Groupe </b> <br />
                                {New_Getall_Groupe_Apprenant_Type_result && New_Getall_Groupe_Apprenant_Type_result.length > 0 && <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_type_grp"
                                    id="detail_type_grp"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={New_Getall_Groupe_Apprenant_Type_result.filter((data) => (data).code === String(p_detail_type_groupe_code))[0].label}

                                />}

                            </div>

                            <div className="session_caract"> Code couleur &nbsp;
                                <nav className="disabled_style" style={{ backgroundColor: p_detail_color, borderRadius: '10px', paddingTop: '5px' }}

                                > &nbsp; </nav>

                            </div>

                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>
                        </div>



                    </div>}


                    {String(grp_apprenant_data_edit_mode) === "1" && <div>

                        <div>
                            <div className="session_caract"> <b> Code </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_code"
                                    id="detail_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_code}
                                    onChange={(e) => {
                                        setp_detail_code(e.target.value);
                                        setgrp_apprenant_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> <b> Nom </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_nom"
                                    id="detail_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_nom}
                                    onChange={(e) => {
                                        setp_detail_nom(e.target.value);
                                        setgrp_apprenant_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_description"
                                    id="detail_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_description}
                                    onChange={(e) => {
                                        setp_detail_description(e.target.value);
                                        setgrp_apprenant_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> <b>Formation  </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ftion"
                                        id="detail_ftion"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Class_Reduice_Fields_result}
                                        value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_class_id(value._id);
                                                setp_detail_class_internal_url(value.internal_url);
                                                setgrp_apprenant_data_changed("1");
                                            } else {


                                                setp_detail_class_id("");
                                                setp_detail_class_internal_url("");
                                                setgrp_apprenant_data_changed("1");
                                            }

                                            setp_detail_session_id("");

                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="session_caract"> <b>Classe    </b>  <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_classe"
                                        id="detail_classe"
                                        className="disabled_style"

                                        options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_class_internal_url))}

                                        value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_session_id(value._id);
                                                setgrp_apprenant_data_changed("1");
                                            } else {
                                                setp_detail_session_id("");
                                                setgrp_apprenant_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>




                            <div className="session_caract"><b>Type de Groupe </b> <br />
                                {New_Getall_Groupe_Apprenant_Type_result && New_Getall_Groupe_Apprenant_Type_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_type_grp"
                                        id="detail_type_grp"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        //options={New_Getall_Partner_List_UE_result}
                                        options={New_Getall_Groupe_Apprenant_Type_result}
                                        value={New_Getall_Groupe_Apprenant_Type_result.filter((data) => (data).code === String(p_detail_type_groupe_code))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value.code) {
                                                setp_detail_type_groupe_code(value.code);
                                                setgrp_apprenant_data_changed("1");
                                            } else {
                                                setp_detail_type_groupe_code("");
                                                setgrp_apprenant_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract"> Code couleur &nbsp;
                                <nav className="disabled_style" style={{ backgroundColor: p_detail_color, cursor: 'pointer', borderRadius: '10px', paddingTop: '15px' }} onClick={(event, value) => {
                                    setDialog_GRP_COLOR_open(true)
                                }}
                                > &nbsp; Cliquer pour choisir </nav>

                            </div>

                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                        </div>
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>}

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>
                        {String(grp_apprenant_data_changed) === "1" && String(grp_apprenant_data_edit_mode) === "1" && <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>
                            /!\ Pensez à enregistrer les modifications
                        </div>}


                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            {String(grp_apprenant_data_changed) === "1" && String(grp_apprenant_data_edit_mode) === "1" && <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Add_Update_Grp_Apprenant_Data}>Enregistrer les modifications
                                </Button>

                            </div>}

                            {String(grp_apprenant_data_edit_mode) === "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Annule_Groupe_DetailFields}>Annuler
                                </Button>
                            </div>}
                        </div>


                        {String(grp_apprenant_data_edit_mode) !== "1" && <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }}>

                            <Button variant="contained" className="bton_edit" onClick={Enable_Grp_Apprenant_DetailFields}>Editer
                            </Button>

                        </div>}
                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                </div>}


                {String(add_one_grp_apprenant) === "1" && String(display_grp_apprenant) !== "1" && <div className="div_row session_data">

                    <nav style={{ "border": "None", "fontSize": "22px", "fontWeight": "600", "paddingTop": "10px", "paddingLeft": "5px" }}>  Ajouter un nouveau groupe </nav>

                    <div>

                        <div>
                            <div className="session_caract"> <b> Code </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_code"
                                    id="detail_code"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_code}
                                    onChange={(e) => {
                                        setp_detail_code(e.target.value);
                                        setgrp_apprenant_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> <b> Nom </b> <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_nom"
                                    id="detail_nom"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_nom}
                                    onChange={(e) => {
                                        setp_detail_nom(e.target.value);
                                        setgrp_apprenant_data_changed("1");
                                    }
                                    }
                                />

                            </div>

                            <div className="session_caract"> Description  <br />
                                <TextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                                    required
                                    name="detail_description"
                                    id="detail_description"

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={false}
                                    className="disabled_style"
                                    value={p_detail_description}
                                    onChange={(e) => {
                                        setp_detail_description(e.target.value);
                                        setgrp_apprenant_data_changed("1");
                                    }
                                    }
                                />

                            </div>
                            <div className="session_caract"> <b>Formation  </b> <br />
                                {New_Getall_Partner_Class_Reduice_Fields_result && New_Getall_Partner_Class_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_ftion"
                                        id="detail_ftion"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Class_Reduice_Fields_result}
                                        value={New_Getall_Partner_Class_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_class_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_class_id(value._id);
                                                setp_detail_class_internal_url(value.internal_url);
                                                setgrp_apprenant_data_changed("1");
                                            } else {


                                                setp_detail_class_id("");
                                                setp_detail_class_internal_url("");
                                                setgrp_apprenant_data_changed("1");
                                            }

                                            setp_detail_session_id("");

                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="session_caract"> <b> Classe  </b> <br />
                                {New_Getall_Partner_Session_Reduice_Fields_result && New_Getall_Partner_Session_Reduice_Fields_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_classe"
                                        id="detail_classe"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data).class_internal_url === String(p_detail_class_internal_url))}
                                        value={New_Getall_Partner_Session_Reduice_Fields_result.filter((data) => (data)._id === String(p_detail_session_id))[0].label}
                                        onChange={(event, value) => {
                                            if (value && value._id) {
                                                setp_detail_session_id(value._id);
                                                setgrp_apprenant_data_changed("1");
                                            } else {
                                                setp_detail_session_id("");
                                                setgrp_apprenant_data_changed("1");
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>


                            <div className="session_caract"> <b>Type de Groupe  </b> <br />
                                {New_Getall_Groupe_Apprenant_Type_result && New_Getall_Groupe_Apprenant_Type_result.length > 0 &&

                                    <Autocomplete
                                        disablePortal
                                        name="detail_type_grp"
                                        id="detail_type_grp"
                                        className="disabled_style"
                                        //className="disabled_style enable_style"
                                        options={New_Getall_Groupe_Apprenant_Type_result}
                                        value={New_Getall_Groupe_Apprenant_Type_result.filter((data) => (data).code === String(p_detail_type_groupe_code))[0].label}

                                        onChange={(event, value) => {
                                            if (value && value.code) {

                                                setp_detail_type_groupe_code(value.code);
                                                setgrp_apprenant_data_changed("1");
                                            } else {

                                                setp_detail_type_groupe_code("");
                                                setgrp_apprenant_data_changed("1");
                                            }

                                        }}

                                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                        />
                                        }
                                    />

                                }

                            </div>

                            <div className="session_caract"> Code couleur &nbsp;
                                <nav className="disabled_style" style={{ backgroundColor: p_detail_color, cursor: 'pointer', borderRadius: '10px', paddingTop: '15px' }} onClick={(event, value) => {
                                    setDialog_GRP_COLOR_open(true)
                                }}
                                > &nbsp; Cliquer pour choisir </nav>

                            </div>


                            <div className="div_row" style={{ "border": "None" }}>
                                &nbsp;
                            </div>

                        </div>

                    </div>

                    <div className="div_row" style={{ "border": "None" }}>
                        &nbsp;
                    </div>
                    <div className="div_row" style={{ "border": "None" }}>



                        <div className="div_row" style={{ "border": "None", "color": "orange", "textAlign": "center" }}>

                            <div className="div_row_gauche" style={{ "textAlign": 'left', 'marginLeft': '15px' }}>

                                <Button variant="contained" className="bton_enreg" onClick={Add_Update_Grp_Apprenant_Data}>Enregistrer
                                </Button>

                            </div>

                            <div className="div_row_droite" style={{ "textAlign": 'right', 'marginRight': '15px' }} >
                                <Button variant="contained" className="bton_annule" onClick={Annule_Add_One_Evaluaton_DetailFields} >Annuler
                                </Button>
                            </div>
                        </div>



                        <div className="div_row" style={{ "border": "None" }}>
                            &nbsp;
                        </div>
                    </div>

                </div>}

            </div>}


            {String(submenu) === "piece_jointe" && <div className="div_row ">

                <div className="div_row session_data">
                    <div className="div_row" style={{ "padding": "5px", 'paddingTop': '15px' }}>

                        <div style={{ "fontSize": "12px" }}>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: "none" }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    onChange={sessions_file_change_1}
                                />

                                <Fab
                                    color="secondary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                >
                                    <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                </Fab>

                            </label>
                        </div>
                        {file_1_name && file_1_name.name &&
                            <nav style={{ "fontSize": "12px" }}>
                                <div className="session_caract">Nom du fichier <br />
                                    <TextField
                                        name="file_to_download_type_name"
                                        id="file_to_download_type_name"

                                        InputLabelProps={{
                                            shrink: true,
                                        }}

                                        disabled={false}
                                        className="disabled_style enable_style"
                                        value={p_detail_one_file_to_download_type_name}
                                        onChange={(e) => {
                                            setp_detail_one_file_to_download_type_name(e.target.value);

                                        }}
                                    />

                                </div>
                                <br />
                                <br />
                                Fichier : {file_1_name.name} <br /> <font style={{ "cursor": "pointer" }}
                                    onClick={Delete_file_1_name}>  Supprimer <IoIosRemoveCircleOutline />  </font>
                            </nav>}

                        {Record_All_PJ_api && String(Record_All_PJ_api) === "true" && <div className="okUpdateData"> Pièce jointe enregistrée </div>}

                        {Record_All_PJ_api && String(Record_All_PJ_api) === "false" && <div className="koUpdateData"> {Record_All_PJ_message} </div>}


                    </div>



                    {file_1_name && file_1_name.name && <div className="div_row">

                        <div className="div_row_gauche">
                            <Button variant="contained" onClick={Record_All_PJ}
                                startIcon={<AiTwotoneSave />} className="bton_enreg">ENREGISTRER LA PIECE</Button>
                        </div>



                    </div>}

                    {Get_List_Of_All_PJ_result && <div className="div_row" style={{ "padding": "5px" }}>
                        <div className="div_row">
                            <b> Liste des pièces jointes </b> <br />
                            <div className="div_row">
                                {Get_List_Of_All_PJ_result && Get_List_Of_All_PJ_result.map((val) => (
                                    <div className="div_row_list_pj"  >
                                        <nav name={(JSON.parse(val).file_name)}
                                            id={(JSON.parse(val).file_name)}> <i> <AttachFileIcon />{(JSON.parse(val).file_business_object)} </i> <br /><font style={{ "color": "green", "cursor": "pointer" }}
                                                name={(JSON.parse(val).file_name)}
                                                id={(JSON.parse(val).file_name)} onClick={Download_one_attached_document}>  Télécharger &nbsp;</font></nav>

                                        <Popup
                                            trigger={
                                                <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                            }
                                            modal
                                            nested
                                            position="center center"
                                        >
                                            {close => (
                                                <div>
                                                    <button className="gest_close" onClick={close}>
                                                        &times;
                                                    </button>
                                                    <div className="gest_header"> MySy Information </div>
                                                    <div className="gest_content">
                                                        {' '}

                                                        En confirmant cette opération, la pièce jointe sera <i><font color="red"> définitivement supprimée</font></i>. <br />


                                                    </div>
                                                    <div className="gest_actions">
                                                        <div style={{ "width": "45%", "float": "left" }}>
                                                            <button className="gest_bton_popup" onClick={(event) => {

                                                                Delete_one_attached_document(event);
                                                                close();
                                                            }}
                                                                name={(JSON.parse(val).file_name)} id={(JSON.parse(val).file_name)}
                                                            > Valider </button>

                                                        </div>
                                                        <div style={{ "width": "45%", "float": "right" }}>
                                                            <button
                                                                className="gest_bton_popup"
                                                                onClick={() => {
                                                                    //console.log('modal closed ');
                                                                    close();
                                                                }}
                                                            >
                                                                Annuler
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="div_row" style={{ "border": "None" }}>
                    &nbsp;
                </div>

            </div>}

            {String(submenu) === "selection" && <div className="div_row session_data">

                <Module_Selection_Apprenants related_target_collection={"groupe_inscription"}
                    related_target_collection_id={selected_id}
                    session_id={p_detail_session_id}
                    class_id={p_detail_class_id} />

            </div>}

            {String(submenu) === "apprenant" && <div>

                <div className="div_row" style={{ paddingRight: '10px' }}>


                    <div className="session_data">


                        <div style={{ "border": "None" }}>

                            <nav style={{ "border": "None", "fontSize": "20px", "paddingTop": "5px", "paddingLeft": "5px" }}> Membres du groupe </nav>

                            <div className="div_row" style={{ "textAlign": "right", "marginTop": "0.5rem" }}>
                                <Button className="bton_emarge" onClick={(event) => {
                                    Getall_Groupe_Membres(selected_id);

                                }}>Actualiser la liste</Button>

                                <Button className="bton_emarge" onClick={(event) => {
                                    Export_Groupe_Membres_exel();
                                }}>Exporter (excel)</Button><br />



                            </div>
                            <div style={{ height: 650, width: '100%', paddingRight: '5px' }}>

                                &nbsp;
                                <Box
                                    sx={{
                                        height: 500,
                                        width: '100%',
                                        paddingRight: '1px',



                                        '& .cell--note': {
                                            backgroundColor: '#AFE1AF',
                                            color: 'red',
                                        },

                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: "#c8cfd5",
                                            color: "black",
                                            fontSize: 14
                                        },

                                        '& .line--statut--selected': {
                                            backgroundColor: '#FBF2EF',
                                            color: 'black',
                                        },
                                        '& .line--statut--pair': {
                                            backgroundColor: 'rgba(235, 235, 235, .7)',
                                            color: 'black',
                                        },
                                        '& .line--statut--impair': {
                                            backgroundColor: '#FFFFFF',
                                            color: 'black',
                                        },

                                        '& .line--statut--noted_changed': {
                                            backgroundColor: '#F8F6B3',
                                            fontStyle: 'italic',
                                        },

                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },


                                    }}
                                >
                                    <DataGrid
                                        //onCellEditCommit={OnchangeCellDataGrid}
                                        components={{
                                            Toolbar: CustomToolbar
                                        }}
                                        componentsProps={{ toolbar: { data_colums: columns_grp_membre } }}

                                        checkboxSelection
                                        onSelectionModelChange={(newSelectionModel) => {
                                            setselectionModel_grp_membre(newSelectionModel);
                                            /*if (newSelectionModel.length === 1)
                                                handleClick_edit_employee_From_Line(newSelectionModel);*/
                                            if (newSelectionModel.length !== 1) {
                                                /*  setsubmenu();
                                                  setdisplay_detail_employe();
                                                  setadd_One_Employee();*/
                                            }
                                        }}
                                        selectionModel={selectionModel_grp_membre}

                                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                        rows={New_Getall_Groupe_Membres_result.map((item, index) => (
                                            {
                                                id: index,
                                                _id: (item)._id,
                                                nom: (item).nom,
                                                prenom: (item).prenom,
                                                email: (item).email,
                                                groupe: (item).groupe,

                                            }
                                        ))}

                                        columns={columns_grp_membre}
                                        pageSize={10}
                                        className="datagridclass"

                                        onRowDoubleClick={(newSelectionModel) => {
                                            /* setselected_id(newSelectionModel.row._id);
                
                                             setgridline_id(newSelectionModel.row.id);
                
                                             handleClick_edit_evaluation_From_Line(newSelectionModel.row.id);
                                             */


                                        }}

                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        /* components={{
                                             Toolbar: GridToolbar,
                                         }}*/
                                        //sx={datagridSx}
                                        getCellClassName={(params) => {

                                            // Traitement de la cellule 'notes' 
                                            if (String(params.field) === "note") {
                                                return 'cell--note';
                                            }

                                        }}

                                        getRowClassName={(params) => {
                                            // Pour la gestion de la couleur de zone double cliquée

                                            //console.log(" ### params.row = ", params.row);


                                            if (String(params.row.id) === String(gridline_grp_member_id)) {
                                                return 'line--statut--selected';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 === 0) {
                                                return 'line--statut--pair';
                                            }
                                            else if (parseInt(String(params.row.id)) % 2 !== 0) {
                                                return 'line--statut--impair';
                                            }


                                        }}
                                        getEstimatedRowHeight={() => 200}
                                        getRowHeight={() => "auto"}
                                        sx={{
                                            "& .MuiDataGrid-cellContent": {
                                                minHeight: 50,

                                            }
                                        }}

                                    />
                                </Box>
                                {selectionModel_grp_membre && selectionModel_grp_membre.length >= 1 &&
                                    <div className="div_row" style={{ "border": "none", "marginLeft": "10px" }}>
                                        <div className="block_en_mass">
                                            <nav className='traitement_mass'>Traitement en masse</nav>
                                            &nbsp;

                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                className="block_en_mass_select"
                                                fullWidth
                                                options={New_Option_grp_membre}
                                                onChange={(event, value) => {
                                                    if (value && value.value) {
                                                        if (String(value.value) === "delete_grp_membre") {
                                                            setactionmass_grp_membre_val(value.value);
                                                        }
                                                        else {
                                                            setactionmass_grp_membre_val("");
                                                        }
                                                    }
                                                    else {
                                                        setactionmass_grp_membre_val("");
                                                    }

                                                }}

                                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                                />
                                                }
                                            />
                                            &nbsp;


                                            {actionmass_grp_membre_val && String(actionmass_grp_membre_val).length > 1 && <nav className='block_en_mass_bton_action'>
                                                <Popup
                                                    trigger={<Button className="bton_enreg" style={{ "width": "90%" }}>
                                                        <FcAcceptDatabase /> Traiter

                                                    </Button>}
                                                    modal
                                                    nested
                                                    position="center center"
                                                >

                                                    {close => (
                                                        <div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}
                                                                {String(actionmass_grp_membre_val) === "delete_grp_membre" &&
                                                                    <font>  Confirmer la <b> supprression des membres </b> en masse pour {selectionModel_grp_membre.length} ligne(s).
                                                                    </font>}


                                                            </div>
                                                            <div className="gest_actions">
                                                                <div style={{ "width": "45%", "float": "left" }}>
                                                                    <button className="gest_bton_popup" onClick={(event) => {
                                                                        actionmass_grp_membre_Traitemet();
                                                                        close();
                                                                    }}> Valider </button>

                                                                </div>
                                                                <div style={{ "width": "45%", "float": "right" }}>
                                                                    <button
                                                                        className="gest_bton_popup"
                                                                        onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Annuler
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </nav>
                                            }
                                            <br />

                                        </div>

                                    </div>}
                                <br />


                            </div>



                            <div className="div_row"> &nbsp; </div>

                        </div>
                    </div>


                </div>

            </div>}


        </div >
    );
}

export default Groupe_Apprenant;
