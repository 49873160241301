import DisplayDetailArticle from "../components/DisplayDetailArticle";
import DisplayDetailArticle_new_v2 from "../components/DisplayDetailArticle_new_v2";



import Navigation from "../components/Navigation";

export default function displaydetailarticle() {
    return (
        <div>
            <div >
                <Navigation />
            </div>

            <DisplayDetailArticle_new_v2 />


        </div>
    );
}