import Navigation from "../components/Navigation";
import DeleteUserAccount from "../components/DeleteUserAccount";

const recherche_draft = () => {

  return (
            <div>
              <div>
            <Navigation />
            </div>
            <DeleteUserAccount/>
        </div>

  )
}


export default recherche_draft;