import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { tokens } from "./../theme";
import { MdOutlineAddchart, } from "react-icons/md";
import {
    useTheme,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';


import { BarChart } from '@mui/x-charts';

import { TbColumns2, TbColumns1 } from "react-icons/tb";
import { axisClasses } from '@mui/x-charts';
import { pieArcLabelClasses } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PieChart } from '@mui/x-charts/PieChart';

const pieParams = { height: 200, margin: { right: 5 } };
const palette = ['red', 'blue', 'green'];

const Dashbord_Factures = (props) => {



    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));


    const [filter_value, setfilter_value] = useState("d");

    const [filter_type_tache, setfilter_type_tache] = useState("");

    const [tdb_title, settdb_title] = useState();


    const [display_nb_column, setdisplay_nb_column] = useState("1");
    const [display_column_size, setdisplay_column_size] = useState("100%");

    const [dashbord_internal_code, setdashbord_internal_code] = useState("");
    const [gridline_id, setgridline_id] = useState("");


    const [Getall_Qry_Invoice_Data_result_JSON, setGetall_Qry_Invoice_Data_result_JSON] = useState([]);
    const [Getall_Qry_Invoice_Data_api, setGetall_Qry_Invoice_Data_api] = useState();
    const [Getall_Qry_Invoice_Data_message, setGetall_Qry_Invoice_Data_message] = useState();
    const [Getall_Qry_Invoice_Data_result, setGetall_Qry_Invoice_Data_result] = useState([]);
    function Getall_Qry_Invoice_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", p_detail_partner_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Factures_Data_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Invoice_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Invoice_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Invoice_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("jsonObj =  ", jsonObj)

                setGetall_Qry_Invoice_Data_api("true");
                setGetall_Qry_Invoice_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Invoice_Data_api("false");
                setGetall_Qry_Invoice_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Invoice_Data = ', error);
            setGetall_Qry_Invoice_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Qry_Invoice_Data_Group_By_Client_result_JSON, setGetall_Qry_Invoice_Data_Group_By_Client_result_JSON] = useState([]);
    const [Getall_Qry_Invoice_Data_Group_By_Client_api, setGetall_Qry_Invoice_Data_Group_By_Client_api] = useState();
    const [Getall_Qry_Invoice_Data_Group_By_Client_message, setGetall_Qry_Invoice_Data_Group_By_Client_message] = useState();
    const [Getall_Qry_Invoice_Data_Group_By_Client_result, setGetall_Qry_Invoice_Data_Group_By_Client_result] = useState([]);
    function Getall_Qry_Invoice_Data_Group_By_Client(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", p_detail_partner_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Factures_Data_By_Client_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Invoice_Data_Group_By_Client  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Invoice_Data_Group_By_Client  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Invoice_Data_Group_By_Client_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                console.log("jsonObj Group by Client =  ", jsonObj)

                setGetall_Qry_Invoice_Data_Group_By_Client_api("true");
                setGetall_Qry_Invoice_Data_Group_By_Client_result(res.data.message);

                var local_total = 0;
                const new_data = res.data.message.map((x) => {
                    var local_value = JSON.parse(x).value;
                    local_total = local_total + parseFloat(String(local_value));

                });

                settotal(parseFloat(String(local_total)));


            }
            else {
                setGetall_Qry_Invoice_Data_Group_By_Client_api("false");
                setGetall_Qry_Invoice_Data_Group_By_Client_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Invoice_Data_Group_By_Client = ', error);
            setGetall_Qry_Invoice_Data_Group_By_Client_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    const [Getall_Qry_Quotation_Data_result_JSON, setGetall_Qry_Quotation_Data_result_JSON] = useState([]);
    const [Getall_Qry_Quotation_Data_api, setGetall_Qry_Quotation_Data_api] = useState();
    const [Getall_Qry_Quotation_Data_message, setGetall_Qry_Quotation_Data_message] = useState();
    const [Getall_Qry_Quotation_Data_result, setGetall_Qry_Quotation_Data_result] = useState([]);
    function Getall_Qry_Quotation_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", p_detail_partner_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Quotation_Data_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Quotation_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Quotation_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Quotation_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("Getall_Qry_Quotation_Data_result_JSON jsonObj =  ", jsonObj)

                setGetall_Qry_Quotation_Data_api("true");
                setGetall_Qry_Quotation_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Quotation_Data_api("false");
                setGetall_Qry_Quotation_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Quotation_Data = ', error);
            setGetall_Qry_Quotation_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    const [Getall_Qry_Previsionnel_Data_result_JSON, setGetall_Qry_Previsionnel_Data_result_JSON] = useState([]);
    const [Getall_Qry_Previsionnel_Data_api, setGetall_Qry_Previsionnel_Data_api] = useState();
    const [Getall_Qry_Previsionnel_Data_message, setGetall_Qry_Previsionnel_Data_message] = useState();
    const [Getall_Qry_Previsionnel_Data_result, setGetall_Qry_Previsionnel_Data_result] = useState([]);
    function Getall_Qry_Previsionnel_Data(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("periode_start_date", date_from);
        form.append("periode_end_date", date_to);
        form.append("filter_value", filter_value);
        form.append("filter_client_id", p_detail_partner_client_id);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_List_Facture_Previsionnelle_Data_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Getall_Qry_Previsionnel_Data  res.data.status = " + res.data.status);
                //console.log(" In Getall_Qry_Previsionnel_Data  res.data.message r_class = " + res.data.message);

                setGetall_Qry_Previsionnel_Data_result_JSON(JSON.parse('[' + res.data.message + ']'));

                var jsonObj = JSON.parse('[' + res.data.message + ']');

                //console.log("jsonObj =  ", jsonObj)

                setGetall_Qry_Previsionnel_Data_api("true");
                setGetall_Qry_Previsionnel_Data_result(res.data.message);


            }
            else {
                setGetall_Qry_Previsionnel_Data_api("false");
                setGetall_Qry_Previsionnel_Data_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Previsionnel_Data = ', error);
            setGetall_Qry_Previsionnel_Data_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }

    useEffect(() => {
        Getall_Qry_Invoice_Data();
        Get_List_Partner_Clients();
        Getall_Qry_Invoice_Data_Group_By_Client();
        Getall_Qry_Quotation_Data();
        Getall_Qry_Previsionnel_Data();
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])


    const chartSetting = {
        yAxis: [
            {
                label: "  Chiffre d'affaire (€) ",
            },
        ],
        width: 1000,
        height: 400,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
            },
        },
        margin: 100,
    };


    const chartSetting_nb = {
        yAxis: [
            {
                label: "  Nomnbre de ",
            },
        ],
        width: 1000,
        height: 400,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
            },
        },
        margin: 100,
    };


    const valueFormatter_CA = (value) => `${value} €`;

    const valueFormatter_count = (value) => `${value} Fact`;

    
    const valueFormatter_CA_Prev = (value) => `${value} €`;

    const data = [
        { label: 'Group A', value: 400, color: '#0088FE' },
        { label: 'Group B', value: 300, color: '#00C49F' },
        { label: 'Group C', value: 300, color: '#FFBB28' },
        { label: 'Group D', value: 200, color: '#FF8042' },
    ];


    const data22 = [
        {
            "label": "Client 1",
            "value": 4367,
            "count": 4,
            "Client_id": "65f05b37c544f77525a30645",
            "Client_nom": "Client 1",
            "Client_raison_sociale": "RS Client 1"
        },
        {
            "label": "Client 2",
            "value": 1281,
            "count": 4,
            "Client_id": "65f05b71c544f77525a30647",
            "Client_nom": "Client 2",
            "Client_raison_sociale": "RS Client 2"
        },
        {
            "label": "part nom2",
            "value": 34,
            "count": 3,
            "Client_id": "65f4469bb14f05ccc461b595",
            "Client_nom": "part nom2",
            "Client_raison_sociale": "part nom2"
        }
    ];

    // const TOTAL22 = data22.map((item) => item.value).reduce((a, b) => a + b, 0);


    const [total, settotal] = useState(1);

    const getArcLabel22 = (params) => {
        const percent = params.value / parseFloat(String(total));
        return `${(percent * 100).toFixed(0)}%`;
    };



    const sizing = {

        width: 600,
        height: 600,
        // legend: { hidden: false },
    };
    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };


    const [tbd_title_value, settbd_title_value] = useState();

    const [New_Get_List_Partner_Clients_result, setNew_Get_List_Partner_Clients_result] = useState([]);

    const [Get_List_Partner_Clients_api, setGet_List_Partner_Clients_api] = useState();
    const [Get_List_Partner_Clients_message, setGet_List_Partner_Clients_message] = useState();
    const [Get_List_Partner_Clients_result, setGet_List_Partner_Clients_result] = useState();
    function Get_List_Partner_Clients(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        form.append("token", stored_cookie);


        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Partner_List_Partner_Client/";

        axios.post(myurl, form).then(res => {
            //console.log(" In Get_List_Partner_Clients  res.data.status = " + res.data.status);
            //console.log(" In Get_List_Partner_Clients  res.data.message r_class = " + res.data.message);

            if (String(res.data.status) === String("true")) {
                setGet_List_Partner_Clients_api("true");
                setGet_List_Partner_Clients_result(res.data.message);

                var new_data2 = [];
                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_nom = JSON.parse(x).nom;
                    var local_raison_sociale = JSON.parse(x).raison_sociale;
                    var local_cond_paiement_id = JSON.parse(x).invoice_condition_paiement_id;

                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "nom": local_nom,
                        "raison_sociale": local_raison_sociale,
                        "invoice_condition_paiement_id": local_cond_paiement_id,

                    };
                    new_data2.push(node);
                });

                if (new_data2.length > 0)
                    setNew_Get_List_Partner_Clients_result(new_data2);

                var node = {
                    "_id": "",
                    "id": "",
                    "label": "",
                    "nom": "",
                    "raison_sociale": "",
                    "invoice_condition_paiement_id": "",

                };
                new_data2.push(node);

            }
            else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }
            else {
                setGet_List_Partner_Clients_api("false");
                setGet_List_Partner_Clients_message(res.data.message);
                alert('Erreur: ' + res.data.message);
            }

        }).catch((error) => {
            console.warn('Not good man :( Get_List_Partner_Clients = ', error);
            setGet_List_Partner_Clients_api("false");
            alert('Erreur: Impossible de recuperer la liste des clients');
            //setmyApimyApiMessage("")
        })
    }

    const [p_detail_partner_client_id, setp_detail_partner_client_id] = useState("");
    const [p_detail_partner_client_nom, setp_detail_partner_client_nom] = useState("");


    const [Add_To_User_TBD_api, setAdd_To_User_TBD_api] = useState();
    const [Add_To_User_TBD_message, setAdd_To_User_TBD_message] = useState();
    const [Add_To_User_TBD_result, setAdd_To_User_TBD_result] = useState();
    function Add_To_User_TBD(local_dashbord_internal_code) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        //console.log(" ### dashbord_internal_code = ", local_dashbord_internal_code);

        //var tdb_title = "default";
        var filter_val = "";


        /*if (document.getElementsByName("tdb_title")[0]) {
            tdb_title = document.getElementsByName("tdb_title")[0].value;
        }
*/
        /*    if (document.getElementsByName("filtre11")[0]) {
                filter_val = document.getElementsByName("filtre11")[0].value;
            }*/

        if (filter_value) {
            filter_val = filter_value
        }
        // Si filter_val =  "d", alors on va recuperer l'interval de date
        if (String(filter_val) === "d") {
            var date_from = p_filter_date_from;
            var date_to = p_filter_date_to;


            filter_val = "{'session_start_date':'" + String(date_from) + "' , 'session_end_date':'" + String(date_to) + "'}";
        } else {
            filter_val = "{'periode':'" + String(filter_val) + "'}"
        }

        form.append("token", stored_cookie);
        form.append("dashbord_internal_code", local_dashbord_internal_code);
        form.append("default_filter", filter_val);
        form.append("title", tbd_title_value);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_To_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Add_To_User_TBD  res.data.message r_class = " + res.data.message);

                setAdd_To_User_TBD_api("true");
                setAdd_To_User_TBD_result(res.data.message);
                settdb_title();
                alert(res.data.message)


            }
            else {
                setAdd_To_User_TBD_api("false");
                setAdd_To_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Add_To_User_TBD = ', error);
            setAdd_To_User_TBD_api("false");
            alert("Impossible d'ajouter le tableau de bord");
            //setmyApimyApiMessage("")
        })
    }


    const New_option_periode = [
        { "id": "m0", "label": "Mois en cours", "value": "m0" },
        { "id": "m1", "label": "Mois précédant ", "value": "m1" },
        { "id": "d", "label": "Choisir des dates", "value": "d" },

    ]

    return (
        <div className="dashbord_session">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Tableaux de bord des Commandes et Factures </h3>
            <div className="div_row"> &nbsp;</div>


            <div className="div_row" style={{ "textAlign": "right", "paddingRight": "20px" }}> Affichage &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_1_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_1_col" data-tooltip-html="Affichage sur une colonne">
                    <TbColumns1 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("1");
                        setdisplay_column_size("100%");

                    }} />
                </a>  &nbsp; &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_2_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_2_col" data-tooltip-html="Affichage sur deux colonnes">
                    <TbColumns2 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("2");
                        setdisplay_column_size("50%");

                    }} />
                </a> </div>

            <div style={{ "width": "100%", "float": "left" }}>
                <div className="" style={{ "width": "50%", "float": "left" }}>
                    Filtre <br />

                    <Autocomplete
                        disablePortal
                        name="filtre11"
                        id="filtre11"
                        className="disabled_style"
                        options={New_option_periode}
                        onChange={(event, value) => {
                            if (value && value.value) {
                                setfilter_value(value.value);

                            } else {
                                setfilter_value("");
                            }
                        }}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une période"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />

                </div>

                {filter_value && String(filter_value) === "d" &&

                    <div style={{ "width": "100%", "float": "left" }}>
                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Debut
                                <DatePicker
                                    name="one_sessiondatedebut"
                                    id="one_sessiondatedebut"

                                    selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                    locale='fr-FR'

                                />

                            </div>
                        </div>

                        <div className="" style={{ "width": "50%", "float": "left" }}>
                            <div className="session_caract" style={{ "padding": '0px', 'width': '100%' }}>
                                Date Fin
                                <DatePicker
                                    name="one_sessiondatefin"
                                    id="one_sessiondatefin"
                                    selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                                    onChange={(date) => {
                                        setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                                    }
                                    }
                                    showTimeSelect={false}
                                    dateFormat="dd/MM/yyyy"
                                    className="disabled_style"
                                />

                            </div>
                        </div>

                        <br />
                    </div>}
                <div style={{ "width": "100%", "float": "left" }}>
                    <div className="" style={{ "width": "50%", "float": "left" }}>
                        Client <br />

                        {New_Get_List_Partner_Clients_result && New_Get_List_Partner_Clients_result.length > 0 &&

                            <Autocomplete
                                disablePortal
                                id="dialog_opport_ref_client"
                                Name="dialog_opport_ref_client"

                                className="disabled_style"

                                options={New_Get_List_Partner_Clients_result}
                                value={New_Get_List_Partner_Clients_result.filter((data) => (data)._id === String(p_detail_partner_client_id))[0].label}

                                onChange={(event, value) => {
                                    if (value && value._id) {

                                        setp_detail_partner_client_id(value._id);
                                        setp_detail_partner_client_nom(value.label);

                                    } else {
                                        setp_detail_partner_client_id("");
                                        setp_detail_partner_client_nom("");
                                    }

                                }}

                                renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                                    inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                                />
                                }
                            />}
                    </div>

                </div>
                <br />
                <div className="div_row">

                    <Button variant="contained" className="bton_enreg" onClick={(e) => {
                        Getall_Qry_Invoice_Data();
                        Getall_Qry_Invoice_Data_Group_By_Client();
                        Getall_Qry_Quotation_Data();
                        Getall_Qry_Previsionnel_Data();

                    }}> Afficher
                    </Button><br />
                </div>
            </div>

            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>

                <div style={{ "float": "left", "width": String(display_column_size), "height": "42rem", "float": "left", "paddingRight": "5px" }} >
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Factures & Commandes  </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("1")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "1" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            settbd_title_value(e.target.value);
                        }
                        }
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("FACTURE_01")
                            Add_To_User_TBD("tbd_facture_01");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}

                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data" style={{ paddingLeft: "2rem" }}>


                            {p_detail_partner_client_nom && String(p_detail_partner_client_nom) !== "" && " Client : " + { p_detail_partner_client_nom }}

                            {Getall_Qry_Invoice_Data_result_JSON && Getall_Qry_Invoice_Data_result_JSON.length > 0 &&
                                Getall_Qry_Invoice_Data_result_JSON[0].data &&
                                <BarChart

                                    dataset={Getall_Qry_Invoice_Data_result_JSON[0].data}
                                    xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                    series={[
                                        { dataKey: 'TotalAmount', label: 'CA', valueFormatter_CA },
                                        { dataKey: 'count', label: 'Count', valueFormatter_count },

                                    ]}
                                    {...chartSetting}
                                    margin={{ right: 15, left: 100 }}

                                />}
                        </div>

                    </Box>
                    <br />
                </div>

            </div>

            <div className="div_row"> &nbsp;</div>

            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>

                <div style={{ "float": "left", "width": String(display_column_size), "height": "42rem", "float": "left", "paddingRight": "5px" }} >
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Repartition CA par client  </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("2")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "2" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        onChange={(e) => {
                            settbd_title_value(e.target.value);
                        }
                        }

                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("FACTURE_02")
                            Add_To_User_TBD("tbd_facture_02");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}

                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        {Getall_Qry_Invoice_Data_Group_By_Client_result_JSON && Getall_Qry_Invoice_Data_Group_By_Client_result_JSON.length > 0 &&
                            Getall_Qry_Invoice_Data_Group_By_Client_result_JSON &&
                            <div className="session_data">

                                <PieChart

                                    series={[
                                        {
                                            data: Getall_Qry_Invoice_Data_Group_By_Client_result_JSON.map((d) => ({ label: d.label, id: d.label, value: d.value })),
                                            valueFormatter: (v, { dataIndex }) => {
                                                const { rank } = Getall_Qry_Invoice_Data_Group_By_Client_result_JSON[dataIndex];
                                                return ` ${v.value} €`;
                                            },
                                            arcLabel: getArcLabel22,
                                            cx: 250,
                                            cy: 200,
                                            innerRadius: 30,
                                            outerRadius: 150,
                                        },
                                    ]}
                                    sx={{
                                        [`& .${pieArcLabelClasses.root}`]: {
                                            fill: 'white',
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                        },
                                    }}
                                    width={800}
                                    height={400}

                                />

                            </div>}

                    </Box>
                    <br />
                </div>

            </div>

            <div className="div_row"> &nbsp;</div>


            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>

                <div style={{ "float": "left", "width": String(display_column_size), "height": "42rem", "float": "left", "paddingRight": "5px" }} >
                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Devis  </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("3")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "3" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            settbd_title_value(e.target.value);
                        }
                        }
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("FACTURE_03")
                            Add_To_User_TBD("tbd_facture_03");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}

                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data" style={{ paddingLeft: "2rem" }}>

                            {p_detail_partner_client_nom && String(p_detail_partner_client_nom) !== "" && " Client : " + { p_detail_partner_client_nom }}

                            {Getall_Qry_Quotation_Data_result_JSON && Getall_Qry_Quotation_Data_result_JSON.length > 0 &&
                                Getall_Qry_Quotation_Data_result_JSON[0].data &&
                                <BarChart


                                    dataset={Getall_Qry_Quotation_Data_result_JSON[0].data}
                                    xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                    series={[
                                        { dataKey: 'nb_devis_gagne', label: 'Gagné', color: '#41B06E' },
                                        { dataKey: 'nb_devis_perdu', label: 'Perdu', color: '#FA7070' },

                                    ]}
                                    {...chartSetting_nb}
                                    margin={{ right: 15, left: 100 }}


                                />}
                        </div>

                    </Box>
                    <br />
                </div>

            </div>

            <div className="div_row"> &nbsp;</div>

            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>
                <div style={{ "float": "left", "width": String(display_column_size), "height": "42rem", "float": "left", "paddingRight": "5px" }} >

                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> CA Prévionnel  </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">
                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("4")} />
                            </nav>
                        </a>
                    </div>

                    {tdb_title && String(tdb_title) === "4" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            settbd_title_value(e.target.value);
                        }
                        }
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("FACTURE_03")
                            Add_To_User_TBD("tbd_facture_04");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}

                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;

                        <div className="session_data" style={{ paddingLeft: "2rem" }}>

                            {Getall_Qry_Previsionnel_Data_result_JSON && Getall_Qry_Previsionnel_Data_result_JSON.length > 0 &&
                                Getall_Qry_Previsionnel_Data_result_JSON[0].data &&
                                <BarChart

                                    dataset={Getall_Qry_Previsionnel_Data_result_JSON[0].data}
                                    xAxis={[{ scaleType: 'band', dataKey: 'month_year', tickPlacement: 'middle' }]}
                                    series={[
                                        { dataKey: 'ca_month_previsionnel', label: 'CA Prév', valueFormatter_CA_Prev },
                                     //   { dataKey: 'ca_month_previsionnel', label: 'CA Prév', color: '#FA7070' },


                                    ]}
                                    {...chartSetting}
                                    margin={{ right: 15, left: 100 }}

                                />}
                        </div>

                    </Box>


                    <br />

                </div>

            </div>
        </div >)

}


export default Dashbord_Factures;