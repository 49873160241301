import Navigation from "../components/Navigation";
import Account from "./../components/Account";



const account = () => {

    return(
        <div className={"connexion"}>
            <Navigation />
            <Account />
        </div>

    )
}

export default account;

