
import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import CheckOut from "./CheckOut";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from '@tinymce/tinymce-react';
import parse from 'html-react-parser';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import add_plus from "../mysy_img/plus.png";
import excel_icone from "../mysy_img/excel_icone.png";
import participants from "../mysy_img/participants.png";
import fileDownload from 'js-file-download'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FcCancel, FcApproval, FcAcceptDatabase, FcPrint } from "react-icons/fc";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { AiFillCloseCircle } from "react-icons/ai";
import Box from '@mui/material/Box';
import { Typography, LinearProgress, sliderClasses } from '@mui/material';
import styled from 'styled-components';
import { CiTrash } from "react-icons/ci";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
//import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
//import interactionPlugin from "@fullcalendar/interaction";
//import timeGridPlugin from "@fullcalendar/timegrid";
import Checkbox from '@mui/material/Checkbox';
import allLocales from '@fullcalendar/core/locales-all'
import { RepeatOnRounded, ResetTvRounded, RestaurantMenu } from "@mui/icons-material";
import { alignPropType } from "react-bootstrap/esm/types";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ToggleSwitch from "./ToggleSwitch";
import ToggleSwitch_v2_mysy from "./ToggleSwitch_v2_mysy";
import ToggleSwitch_v3_mysy from "./ToggleSwitch_v3_mysy";


import Autocomplete from '@mui/material/Autocomplete';
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { FcSearch } from "react-icons/fc";
import { Fab } from "@material-ui/core";
import {
    ListItem,
    ListItemText,
    useTheme,
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { IoMdAddCircle, IoIosRemoveCircleOutline } from "react-icons/io";
import { FcViewDetails, FcDisapprove, FcInfo } from "react-icons/fc";
import { convertNwSeToNeSw } from "google-map-react";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Module_Email_Management = (props) => {

    const myconntected_employee_id = props.conntected_employee_id;

    const [selected_employee_id, setselected_employee_id] = useState(props.conntected_employee_id);

    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        settab_email_pieces_jointes_result([]);
        setselected_courrier_template_id("");
        setemail_destinataire("");
        setemail_cc("");
        setemail_bcc("");
        setinclude_client(false);
        setinclude_tuteur(false);
        settab_email_pieces_jointes_result([]);

        setDialog_1_open(false);
    };

    const [field_suivi_eval, setfield_suivi_eval] = useState("");

    const editorRef_suivi_eval = useRef(null);
    const [editorRef_suivi_eval_limite, seteditorRef_suivi_eval_limite] = useState(2000);

    const [editor_tochange, seteditor_tochange] = useState("");
    function editor_keyup() {
        seteditor_tochange("1");
    }

    const [datamodification, setdatamodification] = useState("0");

    function DataUpdated() {
        setdatamodification("1");

    }

    const [p_detail_cible, setp_detail_cible] = useState("");
    const [p_detail_contenu_doc, setp_detail_contenu_doc] = useState("");
    const [p_detail_nom, setp_detail_nom] = useState("");
    const [p_detail_ref_interne, setp_detail_ref_interne] = useState("");
    const [p_detail_sujet, setp_detail_sujet] = useState("");
    const [p_detail_type_doc, setp_detail_type_doc] = useState("");
    const [p_detail_duplicate, setp_detail_duplicate] = useState("");

    const [p_detail_joint_pdf, setp_detail_joint_pdf] = useState(false);

    const [p_detail_default_version, setp_detail_default_version] = useState(false);
    const [p_detail_edition_par_client, setp_detail_edition_par_client] = useState(false);



    const [p_one_detail_cible, setp_one_detail_cible] = useState("");
    const [p_one_detail_contenu_doc, setp_one_detail_contenu_doc] = useState("");
    const [p_one_detail_nom, setp_one_detail_nom] = useState("");
    const [p_one_detail_ref_interne, setp_one_detail_ref_interne] = useState("");
    const [p_one_detail_sujet, setp_one_detail_sujet] = useState("");
    const [p_one_detail_type_doc, setp_one_detail_type_doc] = useState("");
    const [p_one_detail_duplicate, setp_one_detail_duplicate] = useState("");

    const [p_one_detail_edit_by_client, setp_one_detail_edit_by_client] = useState("");

    const [p_one_detail_courrier_template_type_document_ref_interne, setp_one_detail_courrier_template_type_document_ref_interne] = useState("");
    const [original_courrier_template_id, setoriginal_courrier_template_id] = React.useState("");
    const [field_corp_mail, set_field_corp_mail] = useState("");
    const [field_corp_mail_initial_value, set_field_corp_mail_initial_value] = useState("");

    const [one_field_corp_mail, setone_field_corp_mail] = useState("");
    const [one_field_corp_mail_initial_value, setone_field_corp_mail_initial_value] = useState("");

    const one_editorRef_programme = useRef(null);

    const [one_editorRef_programme_limite, setone_editorRef_programme_limite] = useState(1000);



    const [field_programme, setfield_programme] = useState("");
    const [field_programme_initial_value, setfield_programme_initial_value] = useState("");

    const [one_field_programme, setone_field_programme] = useState("");
    const [one_field_programme_initial_value, setone_field_programme_initial_value] = useState("");

    const one_editorRef_corp_mail = useRef(null);
    const [one_editorRef_corp_mail_limite, setone_editorRef_corp_mail_limite] = useState(1000);
    const [partner_document_data_changed, setpartner_document_data_changed] = useState("");

    const [New_Get_Partner_List_SendEmail_Document_result, setNew_Get_Partner_List_SendEmail_Document_result] = useState([]);

    const [Get_Partner_List_SendEmail_Document_api, setGet_Partner_List_SendEmail_Document_api] = useState();
    const [Get_Partner_List_SendEmail_Document_message, setGet_Partner_List_SendEmail_Document_message] = useState();
    const [Get_Partner_List_SendEmail_Document_result, setGet_Partner_List_SendEmail_Document_result] = useState();
    function Get_Partner_List_SendEmail_Document(ref_interne_document) {

        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("ref_interne", ref_interne_document);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_List_Partner_Document_with_filter/";
        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Get_Partner_List_SendEmail_Document  res.data.status = " + res.data.status);
            //console.log(" In Get_Partner_List_SendEmail_Document  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setGet_Partner_List_SendEmail_Document_api("true");
                setGet_Partner_List_SendEmail_Document_result(res.data.message);

                var new_data2 = [];

                const new_data = res.data.message.map((x) => {

                    //---
                    var localid = JSON.parse(x).id;
                    var local_id = JSON.parse(x)._id;
                    var local_ref_interne = JSON.parse(x).ref_interne;
                    var local_nom = JSON.parse(x).nom;
                    var local_sujet = JSON.parse(x).sujet;
                    var local_type_doc = JSON.parse(x).type_doc;
                    var local_cible = JSON.parse(x).cible;
                    var local_joint_pdf = JSON.parse(x).joint_pdf;
                    var local_contenu_doc = JSON.parse(x).contenu_doc;



                    //---
                    var node = {
                        "_id": local_id,
                        "id": localid,
                        "label": local_nom,
                        "ref_interne": local_ref_interne,
                        "nom": local_nom,
                        "sujet": local_sujet,
                        "type_doc": local_type_doc,
                        "cible": local_cible,
                        "joint_pdf": local_joint_pdf,
                        "contenu_doc": local_contenu_doc,

                    };
                    new_data2.push(node);

                    //  console.log(" ## node = ", node)
                });

                if (new_data2.length > 0)
                    setNew_Get_Partner_List_SendEmail_Document_result(new_data2);

            }
            else {
                setGet_Partner_List_SendEmail_Document_api("false");
                setGet_Partner_List_SendEmail_Document_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Partner_List_SendEmail_Document = ', error);
            setGet_Partner_List_SendEmail_Document_api("false");
            alert("Impossible de récupérer les modèles de convocations");
            //setmyApimyApiMessage("")
        })
    }


    const [Get_Given_Partner_Document_Data_By_id_api, setGet_Given_Partner_Document_Data_By_id_api] = useState();
    const [Get_Given_Partner_Document_Data_By_id_message, setGet_Given_Partner_Document_Data_By_id_message] = useState();
    const [Get_Given_Partner_Document_Data_By_id_result, setGet_Given_Partner_Document_Data_By_id_result] = useState();
    function Get_Given_Partner_Document_Data_By_id(local_id) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("_id", local_id);

        //console.log(" ## form = ", form);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Partner_Document/";
        setLoading(true);
        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === "true") {
                //console.log(" In Get_Given_Partner_Document_Data_By_id  res.data.status = " + res.data.status);
                // console.log(" In Get_Given_Partner_Document_Data_By_id  res.data.message r_class = " + res.data.message);
                setLoading(false);
                setGet_Given_Partner_Document_Data_By_id_api("true");
                setGet_Given_Partner_Document_Data_By_id_result(res.data.message);

                if (res.data.message.length > 0) {
                    var mylocal_document = JSON.parse(res.data.message);

                    if (mylocal_document._id)
                        setoriginal_courrier_template_id(mylocal_document._id);


                    if (mylocal_document.nom)
                        setp_one_detail_nom(mylocal_document.nom);


                    if (mylocal_document.type_doc)
                        setp_one_detail_type_doc(mylocal_document.type_doc);


                    if (mylocal_document.cible)
                        setp_one_detail_cible(mylocal_document.cible);



                    if (mylocal_document.ref_interne) {
                        setp_one_detail_ref_interne(mylocal_document.ref_interne);
                    }

                    if (mylocal_document.courrier_template_type_document_ref_interne) {
                        setp_one_detail_courrier_template_type_document_ref_interne(mylocal_document.courrier_template_type_document_ref_interne);
                    } else {
                        setp_one_detail_courrier_template_type_document_ref_interne("");
                    }


                    if (mylocal_document.sujet) {
                        setp_one_detail_sujet(mylocal_document.sujet);
                    }

                    setp_detail_joint_pdf(false)
                    if (mylocal_document.joint_pdf) {
                        if (String(mylocal_document.joint_pdf) === "1")
                            setp_detail_joint_pdf(true)

                    }


                    if (mylocal_document.duplicate) {
                        setp_one_detail_duplicate(mylocal_document.duplicate);
                    }
                    else {
                        setp_one_detail_duplicate("0");
                    }

                    if (mylocal_document.edit_by_client) {
                        setp_one_detail_edit_by_client(mylocal_document.edit_by_client);
                    }
                    else {
                        setp_one_detail_edit_by_client("0");
                    }


                    setfield_programme("");
                    //  set_field_corp_mail("");
                    if (mylocal_document.contenu_doc) {
                        setone_field_programme(String(mylocal_document.contenu_doc));
                        setone_field_programme_initial_value(String(mylocal_document.contenu_doc));
                        set_field_corp_mail(String(mylocal_document.contenu_doc));
                        one_editorRef_programme.current.insertContent(String(mylocal_document.contenu_doc));


                    }

                    /* set_field_corp_mail("");
                     if (mylocal_document.corps_mail) {
                         setone_field_corp_mail(String(mylocal_document.corps_mail));
                         setone_field_corp_mail_initial_value(String(mylocal_document.corps_mail));
                     }*/


                    if (document.getElementById('myRef')) {
                        //myRef.current.scrollIntoView({ behavior: "smooth" });

                        var divh = document.getElementById('myRef').offsetTop;
                        window.scrollTo({
                            top: divh,
                            behavior: "smooth",
                        });

                    }

                } else {
                    alert(" Aucun document recuperé");
                }


            } else if (String(res.data.status) === String("Err_Connexion")) {
                alert('Erreur: ' + res.data.message);
                history.push("/Connexion");
            }

            else {
                setGet_Given_Partner_Document_Data_By_id_api("false");
                setGet_Given_Partner_Document_Data_By_id_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Get_Given_Partner_Document_Data_By_id = ', error);
            setGet_Given_Partner_Document_Data_By_id_api("false");
            alert(" Impossible de recuperer le modèle initial");
            //setmyApimyApiMessage("")
        })
    }


    const [Send_Front_Email_api, setSend_Front_Email_api] = useState();
    const [Send_Front_Email_message, setSend_Front_Email_message] = useState();
    const [Send_Front_Email_result, setSend_Front_Email_result] = useState();
    function Send_Front_Email() {


        /*

        /!\ : Pour eviter les spams, ce module ne doit pas envoyer plus de 20 email à la fois
        */

        const tab_destinataire = email_destinataire.split(',');
        if (tab_destinataire.length > 30) {
            alert(" Vous ne pouvez pas envoyer plus de 20 emails (destinataires) à la fois.");
            return;
        }

        const tab_email_cc = email_cc.split(',');
        if (tab_email_cc.length > 10) {
            alert(" Vous ne pouvez mettre plus de 10 adresses en copie.");
            return;
        }


        const tab_email_bcc = email_bcc.split(',');
        if (tab_email_bcc.length > 10) {
            alert(" Vous ne pouvez mettre plus de 10 adresses en copie-cachée.");
            return;
        }


        if (tab_email_pieces_jointes_result.length > 10) {
            alert("Vous ne pouvez pas envoyer plus de 5 pièces jointes à la fois.");
            return;
        }



        var form = new FormData();

        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("list_email_dest", email_destinataire);
        form.append("list_email_cc", email_cc);
        form.append("list_email_bcc", email_bcc);
        form.append("email_corps", field_programme);
        form.append("related_collection", props.related_collection);
        form.append("email_sujet", email_sujet);
      
        // pièce jointe


        for (let i = 0; i < tab_email_pieces_jointes_result.length; i++) {
            form.append('File', tab_email_pieces_jointes_result[i]);
        }


        var myurl = ""; 

        if (props.related_collection === "nointernalcontact") {
            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Email_From_Front_For_Unknown_Contact/";
        } else {

            myurl = process.env.REACT_APP_API_URL + "myclass/api/Prepare_and_Send_Email_From_Front/";

            form.append("list_inscription_id", props.list_inscription_id);

            if (include_client === true) {
                form.append("include_client", "1");
            } else {
                form.append("include_client", "0");
            }

            if (include_tuteur === true) {
                form.append("include_tuteur", "1");
            } else {
                form.append("include_tuteur", "0");
            }
        }

        setLoading(true);


        axios.post(myurl, form).then(res => {
            //console.log(" In Send_Front_Email  res.data.status = " + res.data.status);
            //console.log(" In Send_Front_Email  res.data.message r_class = " + res.data.message);
            setLoading(false);
            if (String(res.data.status) === String("true")) {


                setSend_Front_Email_api("true");
                setSend_Front_Email_result(res.data.message);
                Dialog_1_handleClose_buton();

                if (props.father_close_function)
                    props.father_close_function();

                if (props.Clean_Selected_Rows_For_Email)
                    props.Clean_Selected_Rows_For_Email();

                alert(res.data.message);

            }
            else {
                setSend_Front_Email_api("false");
                setSend_Front_Email_message(res.data.message);
                alert(res.data.message);
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Send_Front_Email = ', error);
            setSend_Front_Email_api("false");
            alert("Impossible d'envoyer les email");
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {

        Get_Partner_List_SendEmail_Document("SENDEMAIL_MODELE");
        if (props.email_destinataire) {
            setemail_destinataire(props.email_destinataire)
        }

        if (props.email_cc) {
            setemail_cc(props.email_cc)
        }

        if (props.email_bcc) {
            setemail_bcc(props.email_bcc)
        }

    }, [])


    const [selected_courrier_template_id, setselected_courrier_template_id] = useState("");

    const editorRef_programme = useRef(null);
    const [editorRef_programme_limite, seteditorRef_programme] = useState(1000);


    const [email_destinataire, setemail_destinataire] = useState("");
    const [email_cc, setemail_cc] = useState("");
    const [email_bcc, setemail_bcc] = useState("");

    const [email_sujet, setemail_sujet] = useState("");

    const [tab_email_pieces_jointes_result, settab_email_pieces_jointes_result] = useState([]);


    const sessions_file_change_1_convention = event => {


        const fileUploaded = event.target.files[0];
        let file_size = event.target.files[0].size;
        let file_type = event.target.files[0].type;


        if (file_type !== "application/pdf") {
            alert("Le fichier n'est pas de type PDF");
            return;
        }



        if (file_size > 10000000) {
            alert("Le fichier ne doit pas depasser un 1 Méga octets");
            console.log("Le fichier ne doit pas depasser un 1 Méga octets");
            return;
        }

        //var new_node = {'name':event.target.files[0].name, 'type':event.target.files[0].type}


        var new_tmp = [];

        if (tab_email_pieces_jointes_result && tab_email_pieces_jointes_result.length > 0) {

            tab_email_pieces_jointes_result.map((x) => {
                new_tmp.push(x);

            });

        }

        new_tmp.push(event.target.files[0])

        settab_email_pieces_jointes_result(new_tmp);

        //console.log(" tab_convention_pieces_jointes_result = ", tab_convention_pieces_jointes_result)
        return;
    };


    function Delete_Email_Attached_Doc(event) {

        var doc_to_del_name = event.target.id;
        const myArray = tab_email_pieces_jointes_result;


        console.log(" Suppression du fichier  : ", doc_to_del_name)
        let new_myArray = myArray.filter(file => file.name !== String(doc_to_del_name));

        //console.log(" new_myArray = ", new_myArray);


        var new_tab = []
        for (let i = 0; i < new_myArray.length; i++) {
            new_tab.push(new_myArray[i]);
        }

        settab_email_pieces_jointes_result(new_tab);


    }

    const [include_client, setinclude_client] = useState(false);
    const [include_tuteur, setinclude_tuteur] = useState(false);

    const handleChange_include_tuteur = (event) => {
        if (event.currentTarget.checked) {
            setinclude_tuteur(true);
        }
        else {
            setinclude_tuteur(false);
        }
    }


    const handleChange_include_client = (event) => {
        if (event.currentTarget.checked) {
            setinclude_client(true);
        }
        else {
            setinclude_client(false);
        }
    }



    return (

        <div className="module_email_management">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}


            <Dialog

                onClose={Dialog_1_handleClose}
                className="module_email_management"
                PaperProps={{
                    sx: {
                        width: "400px",
                        maxWidth: "400px!important",
                    },
                }}
                open={Dialog_1_open}
            >

                <DialogTitle></DialogTitle>
                <DialogContent style={{ "width": "400px" }}>
                    <DialogContentText>
                        {Dialog_1_message}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={(e) => {

                                if (selected_courrier_template_id && String(selected_courrier_template_id) !== "") {
                                    setfield_programme("");
                                    Get_Given_Partner_Document_Data_By_id(selected_courrier_template_id);
                                } else {
                                    setfield_programme("");
                                    set_field_corp_mail("");
                                }
                                setDialog_1_open(false);

                            }} className="bton_enreg_dialog">Confirmer</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={(e) => {

                                setDialog_1_open(false);

                            }} className="bton_annule_dialog" >Annuler</Button>
                        </div>
                    </div>

                </DialogActions>

            </Dialog>


            <div>
                <div className="titre1"> Gestion Email </div>
                <div className="session_caract_Dialog" style={{ "marginBottom": '0px' }} > Choisir un modèle de courrier
                    {New_Get_Partner_List_SendEmail_Document_result && <Autocomplete
                        disablePortal
                        id="competence_nom"
                        name="competence_nom"

                        fullWidth

                        options={New_Get_Partner_List_SendEmail_Document_result}

                        onChange={(event, value) => {

                            if (value && value._id) {
                                setselected_courrier_template_id(value._id);
                                var msg = "Vous allez modifier le coprs de l'email  ! ";
                                setDialog_1_message(msg);
                                setDialog_1_open(true);
                            } else {
                                setselected_courrier_template_id("");
                                var msg = "Vous allez modifier le coprs de l'email  ! ";
                                setDialog_1_message(msg);
                                setDialog_1_open(true);
                            }

                        }}

                        renderInput={(params) => <TextField {...params} label="" placeholder="Choisir une option"
                            inputProps={{ ...params.inputProps, style: { fontSize: 12, height: "1.4rem" } }}

                        />
                        }
                    />}
                </div>

                <div className="session_caract_Dialog" style={{ "marginBottom": '1px' }}>
                    <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', height: '2.5rem', paddingTop: '20px' }}>
                        Email
                    </div>

                    <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                        <TextField
                            margin="dense"
                            id="email_dest"

                            type="text"
                            fullWidth
                            value={email_destinataire}
                            onChange={(e) => {
                                setemail_destinataire(String(e.target.value).replaceAll(";", ","));
                            }
                            }
                        />

                    </div>
                </div>
                <div className="session_caract_Dialog" style={{ "marginBottom": '0px' }}>
                    <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', height: '2.5rem', paddingTop: '20px' }}>
                        CC
                    </div>

                    <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                        <TextField
                            margin="dense"
                            id="email_dest"

                            type="text"
                            fullWidth
                            value={email_cc}
                            onChange={(e) => {
                                setemail_cc(String(e.target.value).replaceAll(";", ","));

                            }
                            }
                        />
                    </div>
                </div>

                <div className="session_caract_Dialog" style={{ "marginBottom": '1px' }}>
                    <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', height: '2.5rem', paddingTop: '20px' }}>
                        BCC
                    </div>

                    <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                        <TextField
                            margin="dense"
                            id="email_dest"

                            type="text"
                            fullWidth
                            value={email_bcc}
                            onChange={(e) => {
                                setemail_bcc(String(e.target.value).replaceAll(";", ","));

                            }
                            }
                        />
                    </div>
                </div>

                <div className="session_caract_Dialog" style={{ "marginBottom": '1px' }}>
                    <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', height: '2.5rem', paddingTop: '20px' }}>
                        Sujet
                    </div>

                    <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                        <TextField
                            margin="dense"
                            id="email_dest"

                            type="text"
                            fullWidth
                            value={email_sujet}
                            onChange={(e) => {
                                setemail_sujet(e.target.value);

                            }
                            }
                        />
                    </div>
                </div>

                {props.related_collection && ['inscription', 'apprenant', 'contact'].includes(props.related_collection) && <div className="session_caract_Dialog" style={{ "marginBottom": '1px' }}>

                    <FormGroup sx={{ position: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'right' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={include_client}
                                    onChange={handleChange_include_client}
                                    name="include_client"
                                />
                            }
                            label="Client en CC"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={include_tuteur}
                                    onChange={handleChange_include_tuteur}
                                    name="include_tuteur"
                                />
                            }
                            label="Tuteur en CC"
                        />
                    </FormGroup>

                </div>}



                <div className="session_caract_Dialog" style={{ "marginBottom": '0px' }}>
                    <div style={{ "textAlign": 'left', "width": '100%' }}>

                        <Editor
                            id="detail_document_principal"
                            name="detail_document_principal"
                            onInit={(evt, editor) => one_editorRef_programme.current = editor}
                            //initialValue={field_programme_initial_value}

                            onKeyUp={editor_keyup}
                            value={field_programme}
                            onEditorChange={(newValue, editor) => {
                                setfield_programme(one_editorRef_programme.current.getContent());
                                setpartner_document_data_changed("1");

                            }}
                            init={{
                                resize: false,
                                height: 400,
                                menubar: true,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace',
                                    'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                    'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',

                                ],
                                toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                    'alignleft aligncenter alignright alignjustify | ' +
                                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',

                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
                            }}
                        />

                    </div>
                </div>

                <div>


                    <div className="div_row" style={{ "padding": "5px" }}>

                        <div style={{ "fontSize": "12px" }}>
                            <label htmlFor="upload_convention_file">
                                <input
                                    style={{ display: "none" }}
                                    id="upload_convention_file"
                                    name="upload_convention_file"
                                    type="file"
                                    onChange={sessions_file_change_1_convention}
                                />

                                <Fab
                                    color="secondary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                >
                                    <IoMdAddCircle /> <nav style={{ "fontSize": "12px" }}> Ajouter un fichier </nav>
                                </Fab>

                            </label>
                        </div>


                    </div>


                    {tab_email_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px" }}>

                        <div className="div_row">
                            Liste des pièces jointes <br />


                            {tab_email_pieces_jointes_result && <div className="div_row" style={{ "padding": "5px", "border": "None" }}>
                                <div className="div_row" style={{ "padding": "5px", "border": "None", "marginBottom": "1px" }}>

                                    <div className="div_row" style={{ "padding": "5px", "border": "None" }}>
                                        {tab_email_pieces_jointes_result && tab_email_pieces_jointes_result.map((val) => (
                                            <div className="div_row_list_pj"  >

                                                <nav name={((val).name)}
                                                    id={((val).name)}> <i> <AttachFileIcon />{((val).name)} </i> <font style={{ "color": "green", }}
                                                        name={((val).name)}
                                                        id={((val).name)} onClick={"Download_one_attached_document"}>  &nbsp;</font></nav>

                                                <Popup
                                                    trigger={
                                                        <nav style={{ "color": "red", "cursor": "pointer" }}  > Supprimer</nav>


                                                    }
                                                    modal
                                                    nested
                                                    position="center center"
                                                >
                                                    {close => (
                                                        <div>
                                                            <button className="gest_close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="gest_header"> MySy Information </div>
                                                            <div className="gest_content">
                                                                {' '}

                                                                Confirmer <font color="red"> la suppression </font> de la pièce jointe. <br />


                                                            </div>
                                                            <div className="gest_actions">
                                                                <div style={{ "width": "45%", "float": "left" }}>
                                                                    <button className="gest_bton_popup" onClick={(event) => {

                                                                        Delete_Email_Attached_Doc(event);
                                                                        close();
                                                                    }}
                                                                        name={((val).name)} id={((val).name)}
                                                                    > Valider </button>

                                                                </div>
                                                                <div style={{ "width": "45%", "float": "right" }}>
                                                                    <button
                                                                        className="gest_bton_popup"
                                                                        onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            close();
                                                                        }}
                                                                    >
                                                                        Annuler
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>}



                </div>
                <div className="div_row">
                    <div className="div_row_gauche">
                        <Popup
                            trigger={
                                <Button onClick={(e) => {


                                }} className="bton_enreg_dialog">Envoyer</Button>


                            }
                            modal
                            nested
                            position="center center"
                        >
                            {close => (
                                <div>
                                    <button className="gest_close" onClick={close}>
                                        &times;
                                    </button>
                                    <div className="gest_header"> MySy Information </div>
                                    <div className="gest_content">
                                        {' '}

                                        Envoyer l'email ? <br />


                                    </div>
                                    <div className="gest_actions">
                                        <div style={{ "width": "45%", "float": "left" }}>
                                            <button className="gest_bton_popup" onClick={(event) => {

                                                Send_Front_Email();
                                                close();
                                            }}

                                            > Envoyer </button>

                                        </div>
                                        <div style={{ "width": "45%", "float": "right" }}>
                                            <button
                                                className="gest_bton_popup"
                                                onClick={() => {
                                                    //console.log('modal closed ');
                                                    close();
                                                }}
                                            >
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                    <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                        <Button onClick={(e) => {
                            Dialog_1_handleClose_buton();
                            props.father_close_function();

                        }} className="bton_annule_dialog" >Annuler</Button>
                    </div>
                </div>
            </div>




        </div>
    )
}


export default Module_Email_Management;
