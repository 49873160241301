
import React, { useState, useEffect } from 'react';
//import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { getCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Button from '@mui/material/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import logowhite from "../mysy_img/MYSY-LOGO-WHITE.png";
import connect_white from "../mysy_img/connect_white_v2.png";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import connect_green from "../mysy_img/connect_green.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { format } from 'date-fns'
import moment from "moment";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";
import mysy_logo2 from "../mysy_img2/Logo_MySy.png"

const ButtonHilight = () => {
    const history = useHistory();
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [cookie, setCookie, removeCookie] = useCookies(['tokenmysych']);
    const [partcookie, setpartCookie, removepartCookie] = useCookies(['tokenmysypart']);

    const [country_code, setcountry_code] = useState("FR");
    const [country_name, setcountry_name] = useState("France");
    const [city, setcity] = useState("Paris");
    const [postal, setpostal] = useState("75001");
    const [latitude, setlatitude] = useState("48.861000061035156");
    const [longitude, setlongitude] = useState("2.3380000591278076");
    const [IPv4, setIPv4] = useState("172.71.122.128");
    const [state, setstate] = useState("France");
    const [userIp, setuserIp] = useState("172.71.122.128");


    const controlNavbar = () => {

        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY && window.scrollY > 100) {
                setShow(false);
            } else if (window.scrollY < lastScrollY && window.scrollY < 100) { // if scroll up show the navbar

                setShow(true);
            }

            // remember current page location to use in the next move
            setLastScrollY(window.scrollY);
        }
    };

    const [userconnected, setuserconnected] = useState("0");
    const [someoneconnected, setsomeoneconnected] = useState("0");
    const [partnerconnected, setpartnerconnected] = useState("0");
    var stored_user = getCookie('tokenmysych');
    var stored_part = getCookie('tokenmysypart');

    function whosisconnected() {

        stored_user = getCookie('tokenmysych');
        stored_part = getCookie('tokenmysypart');



        var local_user_connect = 0;
        var local_part_connect = 0;

        if (typeof (stored_user) === "undefined" || String(stored_user) === '') {
            setuserconnected("0");
            local_user_connect = 0;
        } else {
            setuserconnected("1");
            setsomeoneconnected("1");
            local_user_connect = 1;
            //alert(" user connected OK");
        }

        if (typeof (stored_part) === "undefined" || String(stored_part) === '') {

            setpartnerconnected("0");
            setsomeoneconnected("0");
            local_part_connect = 0;
        } else {
            setpartnerconnected("1");
            setsomeoneconnected("1");
            local_part_connect = 1;
            // alert(" partner connected OK");
        }
    }

    useEffect(() => {


        whosisconnected();

        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', controlNavbar);

            // cleanup function
            return () => {
                window.removeEventListener('scroll', controlNavbar);
            };
        }


    }, [lastScrollY]);


    const [PartnerMenu, setPartnerMenu] = React.useState(null);
    const [AccountMenu, setAccountMenu] = React.useState(null);
    const [ProduitMenu, setproduitMenu] = React.useState(null);
    const [AvisMenu, setavisMenu] = React.useState(null);



    const PartnerMenu_Close = () => {
        setPartnerMenu(null);
    };

    const AccountMenu_Close = () => {
        setAccountMenu(null);
    };


    const ProduitMenu_Close = () => {
        setproduitMenu(null);
    };

    const handlePartnerMenu = (event) => {
        setPartnerMenu(event.currentTarget);
    };

    const handleAccountMenu = (event) => {
        setAccountMenu(event.currentTarget);
    };


    const handleProduitMenu = (event) => {
        setproduitMenu(event.currentTarget);
    };

    const handleQuisommesNousMenu = (event) => {
        history.push("/qui-sommes-nous")
    };




    const handleClose_Account = () => {
        alert(" my account");
        setAccountMenu(null);
    };

    const handleAccountConnexion = () => {
        setAccountMenu(null);
        history.push("/Connexion")
    };

    const handleMyAccount = () => {
        setAccountMenu(null);
        history.push("/mysy-user-account")
    };



    const handleAccountCreation = () => {
        setAccountMenu(null);
        history.push("/create_account")
    };

    const handlePartnerConnexion = () => {
        setAccountMenu(null);
        history.push("/PartnerLogin")
    };


    const handleProduitService = () => {
        setproduitMenu(null);
        history.push("/tarifs_produits_services/")
    };

    const handleAvis = () => {
        setavisMenu(null);
        history.push("/Recherche-Article-formation")
    };


    function user_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        if (typeof (stored_user) != "undefined") {
            setCookie("tokenmysych", "");
        }
        history.push("/recherche-formation");
    }

    function user_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (user)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => user_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }


    function part_logout_confirmation() {
        /* si c'est une connexion partner qui est active */
        //alert(" deccc");
        //console.log(" ### AVANNN deconnexion iciiii");
        if (typeof (stored_part) != "undefined") {
            const stored_cookie = getCookie('tokenmysypart');
            var acces_right_token_name = stored_cookie + "_uar";

            setpartCookie("tokenmysypart", "", { path: '/' });
            setpartCookie(acces_right_token_name, "", { path: '/' });
            removeCookie(acces_right_token_name, { path: '/' });
        }

        setsomeoneconnected('0');
        setDialog_1_open(false);
        history.push("/recherche-formation");
    }

    function part_logout() {
        confirmAlert({
            title: '',
            message: 'Confirmez la deconnexion (pro)',
            buttons: [
                {
                    label: 'Oui',
                    onClick: () => part_logout_confirmation()
                },
                {
                    label: 'Non',
                    onClick: () => { return }
                }
            ]
        });
    }
    function handleAccountLogout() {

        if (String(userconnected) === String("1")) {
            user_logout();
        }
        else if (String(partnerconnected) === String("1")) {
            part_logout();
        }
    }

    const [CategorieMenu, setCategorieMenu] = React.useState(null);
    const CategorieMenu_Close = () => {
        setCategorieMenu(null);
    };

    const handleCategorieMenu = (event) => {
        setCategorieMenu(event.currentTarget);
    };


    const handleMarketplace = (event) => {
        history.push("/recherche-formation")
    };

    const handleClassCategorie = (e) => {
        setCategorieMenu(null);
        const pieces = window.location.href.split("/")
        const last = pieces[pieces.length - 1]


        var mymetier = "";
        mymetier = e.target.id
        if (last === mymetier) {
            //console.log(" ## RELAOD");
            window.location.reload(true);
        } else {
            history.push("/Recherche_new_v2/0/" + mymetier);
        }
    };

    function publiecatalogue() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }


    function resserver_demo() {
        history.push("/Partner", { mysy_arg: 'partner' });
    }


    const handleBlogMenu = (event) => {
        history.push("/Recherche-Article-formation")
    };



    const [Dialog_1_message, setDialog_1_message] = React.useState(false);
    const [Dialog_1_open, setDialog_1_open] = React.useState(false);
    function Dialog_1_handle_change_participant_session(message) {
        setDialog_1_message(message);
        setDialog_1_open(true);
    }

    const Dialog_1_handleClose = () => {
        //alert(" Utiliser le bouton 'fermer' ");
        //setOpen(false);
    };

    const Dialog_1_handleClose_buton = () => {
        setDialog_1_open(false);
    };


    const [Dialog_contact_message, setDialog_contact_message] = React.useState(false);
    const [Dialog_contact_open, setDialog_contact_open] = React.useState(false);
    function Dialog_contact_handle_change_participant_session(message) {
        setDialog_contact_message(message);
        setDialog_contact_open(true);
    }

    const Dialog_contact_handleClose = () => {

    };

    const Dialog_contact_handleClose_buton = () => {

        setDialog_contact_open(false);
        clear_contact_message_fields();

    };

    function clear_contact_message_fields() {
        setcontact_message_nom_prenom("");
        setcontact_message_email("");
        setcontact_message_phone("");
        setcontact_message_sujet("");
        setcontact_message_sujet("");
        setcontact_message_message("");
    }

    const [contact_message_nom_prenom, setcontact_message_nom_prenom] = React.useState("");
    const [contact_message_email, setcontact_message_email] = React.useState("");
    const [contact_message_phone, setcontact_message_phone] = React.useState("");
    const [contact_message_sujet, setcontact_message_sujet] = React.useState("");
    const [contact_message_message, setcontact_message_message] = React.useState("");

    function Send_Contact_Message(e) {

        var sender_mail = "";
        var sender_tel = "";
        var token = "";

        ////console.log("contacter " + sender_mail + " - " + sender_tel + " - ")

        var mysubject = contact_message_sujet;
        var mymessage = contact_message_message;
        var mysender_name = contact_message_nom_prenom;
        var mysender_email = contact_message_email;


        //alert("contacter "+mysender_email+" - "+mymessage+" - url = "
        //+process.env.REACT_APP_API_URL+" - user_ip ="+IPv4+" - "+latitude);

        var re = /\S+@\S+\.\S+/;
        if (re.test(mysender_email) == false) {
            alert("l'email est incorrecte");
            return;
        }

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/add_user_message/";
        var form = new FormData();
        form.append("type", "email");
        form.append("recever_mail", "contact@mysy-training.com");
        form.append("recever_tel", "+33769203945");
        form.append("sender_mail", mysender_email);
        form.append("sender_tel", sender_tel);
        form.append("object", mysubject);
        form.append("message", mymessage);
        form.append("sender_name", mysender_name);
        form.append("token", "");
        form.append("user_ip", IPv4);
        form.append("user_country_code", country_code);
        form.append("user_country_name", country_name);
        form.append("user_city", city);
        form.append("user_postal", postal);
        form.append("user_latitude", latitude);
        form.append("user_longitude", longitude);
        form.append("user_state", state);


        // console.log("## form = ", form);



        axios.post(myurl, form).then(res => {

            if (String(res.data.status) === String("false")) {

                alert(res.data.message);
            }

            else if (String(res.data.status) === String("true")) {
                Dialog_contact_handleClose_buton();
                alert(res.data.message);

            }
            else {

            }
        }).catch((error) => {
            console.warn('contact-nous  Not good man :( mysearchtext = ');


        })


    }


    return (
        <div className='ButtonHilight'>

            {/***   GESTION Contact */}
            <Dialog
                open={Dialog_contact_open}
                onClose={Dialog_contact_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>Contactez Nous</DialogTitle>
                <DialogContent className="DialogContent_width">


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                            Nom & Prénom
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>

                            <input
                                type='text'
                                name='name'
                                value={contact_message_nom_prenom}
                                onChange={(e) => {
                                    setcontact_message_nom_prenom(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Nom'
                            ></input>
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                            E-mail
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <input
                                type='email'
                                name='email'
                                id='email'
                                value={contact_message_email}
                                onChange={(e) => {
                                    setcontact_message_email(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Adresse email'
                            ></input>
                        </div>
                    </div>


                    <div className="div_row_dialog">
                        <div className="div_row_gauche_dialog_session" style={{ "textAlign": 'left', fontWeight: '400px' }}>
                            Téléphone
                        </div>
                        <div className="div_row_droite_dialog_session" style={{ "textAlign": 'right' }}>
                            <input
                                type='text'
                                name='phone'
                                id='phone'
                                value={contact_message_phone}
                                onChange={(e) => {
                                    setcontact_message_phone(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Numéro Tél'
                            ></input>
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            Sujet
                        </div>
                        <div className="div_row_dialog" >
                            <input
                                type='text'
                                name='subject'
                                value={contact_message_sujet}
                                onChange={(e) => {
                                    setcontact_message_sujet(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Sujet'
                            ></input>
                        </div>
                    </div>

                    <div className="div_row_dialog">
                        <div className="div_row_dialog" style={{ "textAlign": 'left' }}>
                            Votre message
                        </div>
                        <div className="div_row_dialog" >
                            <textarea
                                rows={3}
                                name='message'
                                value={contact_message_message}
                                onChange={(e) => {
                                    setcontact_message_message(e.target.value);
                                }}
                                className='form-control formInput'
                                placeholder='Message'
                            ></textarea>
                        </div>
                    </div>

                    <div className="div_row_dialog" >

                        <div>MySy Training Technology<br />
                        </div>
                        <div style={{ fontSize: "small" }}>

                            Adresse : 1 Cr du Havre, 75008 Paris <br />
                            Adresse email :
                            <a href="mailto: contact@mysy-training.com?subject=Mail from mysy website" target="_blank"> contact@mysy-training.com</a> <br />

                            Téléphone fixe : +331 77 00 38 57<br />
                        </div>

                    </div>


                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={Dialog_contact_handleClose_buton} className="bton_annule_dialog" >Fermer</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Send_Contact_Message} className="bton_enreg_dialog">Envoyer&nbsp; <SendIcon /></Button>

                        </div>
                    </div>

                </DialogActions>


            </Dialog>
            {/***  FIN GESTION Contact */}


            <Dialog
                open={Dialog_1_open}
                onClose={Dialog_1_handleClose}
                className="displaypartnersession"
            >

                <DialogTitle>MySy Information</DialogTitle>
                <DialogContent className="DialogContent_width">
                    <DialogContentText>
                        Confirmez la deconnexion
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <div className="div_row">
                        <div className="div_row_gauche">
                            <Button onClick={part_logout_confirmation} className="bton_enreg_dialog">Deconnexion</Button>
                        </div>
                        <div className="div_row_droite" style={{ "textAlign": 'center' }}>
                            <Button onClick={Dialog_1_handleClose_buton} className="bton_annule_dialog" >Annuler</Button>
                        </div>
                    </div>

                </DialogActions>
            </Dialog>

            <Helmet>

                <meta name="robots" content="index,follow" />
                <meta name="description" content="Éditeur Français de logiciels pour organisme de formation. Avec solutions de E-Learning, gestion administrative et gestion relation client nous accompagnons les organismes de formation à réaliser l’audit QUALIOPI en toute sérénité. Nous sommes les moins cher du marché. Nous vous accompagnons automatiser vos process : inscription, évaluation, émargement (qr-code), Check-list QUALIOPI, Personnalisation et édition documents, Gestion formateurs, Catalogue formations personnalisé" />
                <meta name="robots" content="index,follow" />
                <meta name="title" content="Formations.Gestion administrative.E-Learning.CRM" />
                <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}` + "/qui-sommes-nous"} />

            </Helmet>

            <Menu
                keepMounted
                anchorEl={PartnerMenu}
                onClose={PartnerMenu_Close}
                open={Boolean(PartnerMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handlePartnerConnexion} className="mymenu_item"
                >Mon Compte</MenuItem>
                <MenuItem onClick={PartnerMenu_Close} className="mymenu_item"
                >Deconnexion</MenuItem>

            </Menu>

            <Menu
                keepMounted
                anchorEl={AccountMenu}
                onClose={AccountMenu_Close}
                open={Boolean(AccountMenu)}
                style={{ "top": "2rem" }}

            >
                <MenuItem onClick={handleMyAccount} className="mymenu_item"
                >Mon Compte</MenuItem>
                <MenuItem onClick={handleAccountConnexion} className="mymenu_item"
                >Connexion</MenuItem>

                <MenuItem onClick={handleAccountCreation} className="mymenu_item"
                >S'inscrire</MenuItem>
                <MenuItem onClick={AccountMenu_Close} className="mymenu_item"
                >Deconnexion</MenuItem>
            </Menu>

            <Menu
                keepMounted
                anchorEl={ProduitMenu}
                onClose={ProduitMenu_Close}
                open={Boolean(ProduitMenu)}
                style={{ "top": "2rem" }}            >
                <MenuItem onClick={handleProduitService} className="mymenu_item"
                >  <button className='bton_menu'> Prix  </button></MenuItem>

                {/* <MenuItem onClick={handleAvis} className="mymenu_item"
                > <button className='bton_menu'> Blog </button> </MenuItem>*/}

            </Menu>


            <Menu
                keepMounted
                anchorEl={CategorieMenu}
                onClose={CategorieMenu_Close}
                open={Boolean(CategorieMenu)}
                style={{ "top": "2rem" }}
                className="ButtonHilight"
            >
                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="IT" >Informatique</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="vente" > Commerce</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item" >
                    <button className='bton_menu' id="RH" > Ressources Humaines</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="marketing" >Marketting</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="graphisme" >Graphisme</button>
                </MenuItem>


                <MenuItem onClick={handleClassCategorie} className="mymenu_item"  >
                    <button className='bton_menu' id="management" >Management</button>
                </MenuItem>

                <MenuItem onClick={handleClassCategorie} className="mymenu_item">
                    <button className='bton_menu' id="dev_perso" >Dev. Personnel </button>
                </MenuItem>


            </Menu>

            <div className={`mini_entete  ${show && 'hidden'}`}  >
                <div className="div_001">
                    <div className="div_002">
                        <h1 className="h1_transparent">MySy Training Technology. Logiciel de gestion des organismes de formation </h1>
                        <div className="vvv" >
                            <a href={process.env.REACT_APP_BASE_URL} style={{ "textDecoration": "none" }}> <img class="img-responsive header_img_logo22" src={logowhite} alt="MySy Training Technology" />
                                <nav className="training">TRAINING</nav>
                            </a>

                        </div>
                        <div className="header_menuPrincipal_left" onClick={handleMarketplace}>
                            Marketplace
                        </div>
                    </div>
                    <div className="div_003">
                        <h1 className="h1_transparent">Recherche Formations  Tutoriels</h1>
                        <div className="hilight_menuPrincipal_bton" >
                            <div style={{ "textAlign": "left" }}>
                                {String(someoneconnected) !== String("1") && <button className="buton_ch22" onClick={handleAccountConnexion}> <img src={connect_white} alt="connexion"
                                    style={{ "width": "25%" }} /> Connexion
                                </button>}
                                {String(someoneconnected) === String("1") && <button className="buton_ch22" onClick={(e) => setDialog_1_open(true)}> Deconnexion <img src={connect_green}
                                    alt="deconnexion" style={{ "width": "25%" }} />
                                </button>}
                            </div>
                        </div>

                        {String(someoneconnected) === String("1") && <div className="hilight_menuPrincipal" onClick={handleMyAccount}>
                            Mon Compte
                        </div>}

                        {String(someoneconnected) !== String("1") && <div className="hilight_menuPrincipal" onClick={handleAccountCreation}>
                            S'inscrire
                        </div>}

                        {<div className="hilight_menuPrincipal" onClick={(e) => {
                            // console.log(" oooooo ")
                            e.preventDefault();
                            var date2 = new Date();
                            const format2 = "YYYY-MM";
                            var dateTime2 = moment(date2).format(format2);
                            window.open(
                                "https://calendly.com/mysytraining/demo-mysy-training-solution?month=" + String(dateTime2),
                                '_blank'
                            );
                        }}
                            style={{ "background": "#53B7EB", "borderRadius": "5px", "paddingLeft": "3px", "paddingRight": "3px" }}>
                            Une Demo ?
                        </div>}

                        <div className="hilight_menuPrincipal" onClick={(event) => {
                            setDialog_contact_open(true);
                        }}>
                            Contact
                        </div>

                        <div className="hilight_menuPrincipal" onClick={handleProduitService}>
                            Tarifs
                        </div>


                        <div className="hilight_menuPrincipal" onClick={(e) => {
                            history.push("/fonctionnalites")
                        }}>
                            Fonctionnalites
                        </div>

                        <div className="hilight_menuPrincipal" onClick={handleQuisommesNousMenu}>
                            A propos
                        </div>



                    </div>


                </div>
                {/* <div className={`button1  ${show && 'hidden'}`} >
                    <div style={{ "width": "100%", "backgroundColor": "white" }}>

                        <Button variant="outlined"
                            className={`button1  ${show && 'hidden'}`}
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href = "/Recherche-Article-formation";
                            }}>  Le blog </Button> <br />

                    </div>
                        </div>*/}
            </div>

        </div>
    );
}



export default ButtonHilight;