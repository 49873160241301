import React, { useRef, useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { getCookie } from 'react-use-cookie';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import axios from "axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import HomeIcon from '@mui/icons-material/Home'
import { useHistory } from "react-router-dom";
import OrderConfirmation from "./OrderConfirmation";
import { IoAddCircleOutline } from "react-icons/io5";
import MyPaymentMethode from "./PaymentMethode";
import img_visa_mastercard from "./../mysy_img/visa_mastercard.png"
import img_logo_stripe from "./../mysy_img/logo-stripe-secure.png";
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import img_paypal from "./../mysy_img/paypal.png"
import moment from "moment";
import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import Popup from 'reactjs-popup';

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'


const stripePromise = loadStripe('pk_test_51LUUfAAbmaEugrFTI25uZBD3IFjbtaL6jUfRV83diDf7nco8worna4NGKhMHbPP71WCwT5EHFRdDNatxPrJWwgZ300kgH5EO4p');
//const stripePromise_PROD = loadStripe('pk_live_51LUUfAAbmaEugrFTyfNe4Dj0vbxc5qXQqNs0rztjAKy8oSlc5EEQyJGjA8Z5kXmP2gvUMcYLUBYCIyWWzLCw59RY00PtGInkVf');



function CheckOut(props) {

    const [myApiResponse, setmyApiResponse] = useState("");
    const [result, setResult] = useState("");
    const [menu, setmenu] = useState("default");
    const [fields1desabled, setfields1desabled] = useState(true);
    const [periode, setperiode] = useState("Mensuel");
    const [testval, settestval] = useState();
    const [gotocheckout, setgotocheckout] = useState("0");
    const [currentpack, setcurrentpack] = useState(props.packs);
    const [periodicity, setperiodicity] = useState(props.periodicity);
    const [packprice, setpackprice] = useState(props.mypackprice);
    const [getpackproducts, setgetpackproducts] = useState();
    const scrollDiv = useRef(null);
    const [okcommande, setokcommande] = useState();
    const stripe = useStripe();
    const elements = useElements();


    // recuperation et gestion  du token utilisateur 
    const history = useHistory();
    const stored_part = getCookie('tokenmysypart');
    if (!stored_part || stored_part.length <= 0) {
        history.push("/PartnerLogin");
    }


    function RecordData() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";

        var nom = document.getElementsByName("nom")[0].value;
        var vat_num = document.getElementsByName("vat_num")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;


        formData.append('partner_account_id', partner_account_id);
        formData.append('token', stored_part);
        formData.append('invoice_vat_num', vat_num);
        formData.append('invoice_nom', nom);
        formData.append('invoice_adr_street', adr_street);
        formData.append('invoice_adr_city', adr_city);
        formData.append('invoice_adr_zip', adr_zip);
        formData.append('invoice_adr_country', adr_country);
        formData.append('invoice_email', email);
        formData.append('invoice_telephone', telephone);



        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setmyApiResponse(result['status']);
                setResult(result['message']);
                if (String(result['status']) == String("true")) {
                    settestval(true);
                    desablefield1();
                }

            })
            .catch((error) => {
                console.error('Error:', error);
                setmyApiResponse("false");
            });


    };

    function desablefield1() {
        setfields1desabled(true);
        document.getElementsByName("nom")[0].disabled = true;
        //document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("telephone")[0].disabled = true;
        //document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("email")[0].disabled = true;
        // document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_street")[0].disabled = true;
        //document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_zip")[0].disabled = true;
        //document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_city")[0].disabled = true;
        //document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("adr_country")[0].disabled = true;
        //document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";

        document.getElementsByName("vat_num")[0].disabled = true;
        //document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
    }



    const [stripe_existing_souscription_id, setstripe_existing_souscription_id] = useState();

    const [stripe_account_id, setstripe_account_id] = useState("aaa");
    const [stripe_paymentmethod_id, setstripe_paymentmethod_id] = useState();

    const [partner_account_id, setpartner_account_id] = useState("");
    const [is_partner_admin_account, setis_partner_admin_account] = useState("");

    function getCurrentUserData() {

        settestval('d'); // ==> Do not delete
        setaddfacturationinfo_api("");
        setaddfacturationinfo_response("");
        setokcommande("");

        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
        formData.append('token', stored_part);

        axios.post(url, formData).then(res => {
            if (res.data.status != "False") {
                //console.log(" In getCurrentUserData  res.data.status = " + res.data.status);
                //console.log(" In getCurrentUserData  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message);

                var mylocaltraining = JSON.parse(res.data.message);
                if (mylocaltraining) {
                    setpartner_account_id(mylocaltraining._id);

                    if (mylocaltraining.is_partner_admin_account) {
                        setis_partner_admin_account(mylocaltraining.is_partner_admin_account);
                    } else {
                        setis_partner_admin_account("0");
                    }

                    setstripe_account_id(mylocaltraining.stripe_account_id);
                    setstripe_paymentmethod_id(mylocaltraining.stripe_paymentmethod_id);


                    if (mylocaltraining.invoice_vat_num) {
                        document.getElementsByName("vat_num")[0].value = mylocaltraining.invoice_vat_num;
                        document.getElementsByName("vat_num")[0].disabled = true;
                        document.getElementsByName("vat_num")[0].style.backgroundColor = "#ECEFF1";
                    }


                    if (mylocaltraining.invoice_nom) {
                        document.getElementsByName("nom")[0].value = mylocaltraining.invoice_nom;
                        document.getElementsByName("nom")[0].disabled = true;
                        document.getElementsByName("nom")[0].style.backgroundColor = "#ECEFF1";
                    }

                    if (mylocaltraining.invoice_adr_street) {
                        var tmp_val = mylocaltraining.invoice_adr_street;
                        document.getElementsByName("adr_street")[0].value = mylocaltraining.invoice_adr_street;
                        document.getElementsByName("adr_street")[0].disabled = true;
                        document.getElementsByName("adr_street")[0].style.backgroundColor = "#ECEFF1";

                        if (tmp_val.trim().length <= 0 &&
                            (String(props.packs).toLowerCase() !== String("decouverte"))) {
                            document.getElementsByName("adr_street")[0].style.borderColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }
                    else {
                        if (String(props.packs).toLowerCase() !== String("decouverte")) {
                            document.getElementsByName("adr_street")[0].style.backgroundColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }

                    if (mylocaltraining.invoice_adr_city) {
                        document.getElementsByName("adr_city")[0].value = mylocaltraining.invoice_adr_city;
                        document.getElementsByName("adr_city")[0].disabled = true;
                        document.getElementsByName("adr_city")[0].style.backgroundColor = "#ECEFF1";
                        if (mylocaltraining.invoice_adr_city.trim().length <= 0) {
                            document.getElementsByName("adr_city")[0].style.borderColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }
                    else {
                        if (String(props.packs).toLowerCase() !== String("decouverte")) {
                            document.getElementsByName("adr_city")[0].style.backgroundColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }

                    if (mylocaltraining.invoice_adr_zip) {
                        document.getElementsByName("adr_zip")[0].value = mylocaltraining.invoice_adr_zip;
                        document.getElementsByName("adr_zip")[0].disabled = true;
                        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#ECEFF1";
                        if (mylocaltraining.invoice_adr_zip.trim().length <= 0) {
                            document.getElementsByName("adr_zip")[0].style.borderColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }
                    else {
                        if (String(props.packs).toLowerCase() !== String("decouverte")) {
                            document.getElementsByName("adr_zip")[0].style.backgroundColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }

                    if (mylocaltraining.invoice_adr_country) {
                        document.getElementsByName("adr_country")[0].value = mylocaltraining.invoice_adr_country;
                        document.getElementsByName("adr_country")[0].disabled = true;
                        document.getElementsByName("adr_country")[0].style.backgroundColor = "#ECEFF1";
                        if (mylocaltraining.invoice_adr_country.trim().length <= 0) {
                            document.getElementsByName("adr_country")[0].style.borderColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }
                    else {
                        if (String(props.packs).toLowerCase() !== String("decouverte")) {
                            document.getElementsByName("adr_country")[0].style.backgroundColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }

                    if (mylocaltraining.invoice_email) {
                        document.getElementsByName("email")[0].value = mylocaltraining.invoice_email;
                        document.getElementsByName("email")[0].disabled = true;
                        document.getElementsByName("email")[0].style.backgroundColor = "#ECEFF1";
                        if (mylocaltraining.invoice_email.trim().length <= 0) {
                            document.getElementsByName("email")[0].style.borderColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }
                    else {
                        if (String(props.packs).toLowerCase() !== String("decouverte")) {
                            document.getElementsByName("email")[0].style.backgroundColor = "#FF0000";
                            setokcommande("ko");
                        }
                    }

                    if (mylocaltraining.invoice_telephone) {

                        document.getElementsByName("telephone")[0].value = mylocaltraining.invoice_telephone;
                        document.getElementsByName("telephone")[0].disabled = true;
                        document.getElementsByName("telephone")[0].style.backgroundColor = "#ECEFF1";
                    }


                    if (mylocaltraining.stripe_subscription_id) {

                        setstripe_existing_souscription_id(mylocaltraining.stripe_subscription_id);
                    }

                    desablefield1();

                }
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                //setmyApimyApiMessage("")
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            //setmyApimyApiMessage("")
        })
    }

    function enablefieds1() {

        setaddfacturationinfo_api("");
        setaddfacturationinfo_response("");
        setokcommande("ko");

        setfields1desabled(false);
        document.getElementsByName("nom")[0].disabled = false;
        document.getElementsByName("nom")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("telephone")[0].disabled = false;
        document.getElementsByName("telephone")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("email")[0].disabled = false;
        document.getElementsByName("email")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_street")[0].disabled = false;
        document.getElementsByName("adr_street")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_zip")[0].disabled = false;
        document.getElementsByName("adr_zip")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_city")[0].disabled = false;
        document.getElementsByName("adr_city")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("adr_country")[0].disabled = false;
        document.getElementsByName("adr_country")[0].style.backgroundColor = "#FFFFFF";

        document.getElementsByName("vat_num")[0].disabled = false;
        document.getElementsByName("vat_num")[0].style.backgroundColor = "#FFFFFF";
    }


    const [addpayementcheckout, setaddpayementcheckout] = useState();
    function menu_paymentmethode() {
        setaddpayementcheckout("addpaymentmethode");
        //alert("kk");
    }

    function get_production_service_by_pack() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/get_product_service/";
        formData.append('token', stored_part);
        formData.append('pack_name', props.packs);

        axios.post(url, formData).then(res => {
            if (res.data.status != "False") {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("True");
                setResult(res.data.message);
                setgetpackproducts(res.data.message);
            }
            else {
                //console.log(" In test  res.data.status = " + res.data.status);
                //console.log(" In test  res.data.message = " + res.data.message);
                setmyApiResponse("False");
                setgetpackproducts("");
            }

        }).catch((error) => {
            console.warn('Not good man :( mysearchtext = ');
            setmyApiResponse("False");
            setgetpackproducts("");
            //setmyApimyApiMessage("")
        })
    }

    const [paymentmode, setpaymentmode] = useState([]);
    const [paymentmodeapi, setpaymentmodeapi] = useState([]);



    function GetPayementMode(event) {

        var form = new FormData();
        //form.append("token","K3cw63eKokKSbV4spjQ48SUNnfy80yrXfA");
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/get_payement_mode/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('### GetPayementMode : Success:', data['message'], "data['status'] = ", data['status']);

                if (String(data['status']) === String("False") ||

                    !data['message'][0] || Object.keys(data['message'][0]).length === 0

                ) {
                    //console.log("erreur rrrr:" + data['status'])
                    setpaymentmodeapi("False");
                    setokcommande("ko");

                }
                else {
                    setpaymentmodeapi("True");
                    setpaymentmode(data['message']);
                    initpayement_front();

                    if (scrollDiv.current) {
                        scrollDiv.current.scrollIntoView({ behavior: "smooth" });
                    }


                }

            }).catch((error) => {
                console.error('Error:', error);
                setpaymentmodeapi("false");
                setokcommande("ko");
            });

    }




    useEffect(() => {

        getCurrentUserData();
        get_production_service_by_pack();
        GetPayementMode();

        if (String(props.packs).toLocaleLowerCase() === String("standard")) {
            setprice_ch(process.env.REACT_APP_API_URL_STRIP_ID_PACK_STAND);
        }
        else if (String(props.packs).toLocaleLowerCase() === String("gold")) {
            setprice_ch(process.env.REACT_APP_API_URL_STRIP_ID_PACK_GOLD);
        }

        else if (String(props.packs).toLocaleLowerCase() === String("decouverte")) {
            setnb_formations("1");
        }




        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);


    function Handler() {
        alert('works');
    }


    const [isLoading, setLoading] = useState();


    const [payment_in_process, setpayment_in_process] = useState();

    const [orderid, setorderid] = useState();
    const [apiorderid, setapiorderid] = useState();
    const [apiorderidmessage, setapiorderidmessage] = useState();

    function Ordervalidation() {
        //alert(" confirmation ok");

        if (String(nb_formations) === "0") {
            alert(" Vous devez choisir un nombre de formations ");
            document.getElementById('div_nb_formation').style.color = "red";
            document.getElementById('div_ttc').style.color = "red";

            return;
        }

        //console.log(" ### stripe_existing_souscription_id = ", stripe_existing_souscription_id);

        //initpayement_front();

        // ---------- début 

        setpayment_in_process("1");
        setLoading(true);

        /*
        IMPORTANT : 
        Quand c'est la première fois ou si le partenaire n'a pas de deja un abonnement en cours, 
        alors on faire une creation.

        Si non c'est une mise à jour qu'il faut faire

        la souscription_id du partenaire est stocké ici : stripe_existing_souscription_id
        */
        if (!stripe_existing_souscription_id) {
            // Le client n'a pas de soucription id, donc on fait une creation.
            var myurl = process.env.REACT_APP_API_URL + "myclass/api/create-subscription/";

            var form1 = new FormData();

            var local_souscription_id;
            var local_souscription_id2;

            form1.append("customerid", cust_strip_id_global);
            form1.append("pack", currentpack);
            form1.append("qty", nb_formations);
            form1.append("discount_code", code_promo_code);
            form1.append("discount_type", code_promo_type);
            form1.append("discount_valeur", code_promo_valeur);


            fetch(myurl, {
                method: 'POST',
                body: form1,
            }).then((data) => data.json())
                .then((data) => {
                    //console.log(' #### retour myclass/api/create-subscription/ = subscription_id:', data['subscription_id'], ' ### payment_intent_client_secret:', data['payment_intent_client_secret'], "data['Status'] = ", data['status'],);
                    local_souscription_id = data['subscription_id'];

                    //console.log(" ### INSIDE initpayement_front(); : local_souscription_id = ", local_souscription_id);
                    setpayement_id(data['subscription_id']);

                    if (String(data['status']) === String("true")) {
                        //console.log(" subscriptionId = " + subscriptionId + " -- et clientSecret = " + clientSecret);
                        setclientSecret_id(data['payment_intent_client_secret']);
                        setpayement_id(data['subscription_id']);

                        local_souscription_id2 = data['subscription_id'];

                        //setSubscriptionData(data['subscription_id'], data['payment_intent_client_secret'] );
                        //alert(" subscriptionId = " + data['subscription_id'] + " -- et clientSecret = " + data['payment_intent_client_secret']);

                    }


                }).catch((error) => {
                    //console.log('Error:', error.message);
                });

        }
        else {
            // Le client a une souscription id, donc on fait une mise à jour
            var myurl = process.env.REACT_APP_API_URL + "myclass/api/strip_update_subscription_plan/";

            var form1 = new FormData();

            var local_souscription_id;
            var local_souscription_id2;

            const stored_cookie = getCookie('tokenmysypart');
            form1.append("token", stored_cookie);
            form1.append("pack", currentpack);
            form1.append("qty", nb_formations);
            form1.append("discount_code", code_promo_code);
            form1.append("discount_type", code_promo_type);
            form1.append("discount_valeur", code_promo_valeur);

            fetch(myurl, {
                method: 'POST',
                body: form1,
            }).then((data) => data.json())
                .then((data) => {
                    //console.log(" #### status", data['status'], ' ### message : ', data['message']);
                    //local_souscription_id = data['subscription_id'];

                    //console.log(" ### INSIDE initpayement_front(); : local_souscription_id = ", local_souscription_id);
                    //setpayement_id(data['subscription_id']);

                    if (String(data['status']) === String("true")) {

                        console.log(" status = OKKK ");
                        //console.log(" subscriptionId = " + subscriptionId + " -- et clientSecret = " + clientSecret);
                        //setclientSecret_id(data['payment_intent_client_secret']);
                        //setpayement_id(data['subscription_id']);

                        //local_souscription_id2 = data['subscription_id'];

                        //setSubscriptionData(data['subscription_id'], data['payment_intent_client_secret'] );
                        //alert(" subscriptionId = " + data['subscription_id'] + " -- et clientSecret = " + data['payment_intent_client_secret']);

                    }


                }).catch((error) => {
                    //console.log('Error:', error.message);
                });
        }
        // ----- end 

        //console.log(" ### apres initpayement_front(); : local_souscription_id = ", local_souscription_id, "  local_souscription_id2 = ",local_souscription_id2);

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');
        form.append("token", stored_cookie);
        form.append("periodicite", periodicity);
        form.append("discount_code", code_promo_code);
        form.append("discount_type", code_promo_type);
        form.append("discount_valeur", code_promo_valeur);
        form.append("discount_qty", nb_formations);
        form.append("pack", currentpack);

        /*
            /!\ : Important, il faut préciser le nombre d'article envoyé avec ceci :  form.append("nb_product", nb_article);
        */

        form.append("nb_product", "1");
        if (String(props.packs).toLowerCase() === String("decouverte")) {
            form.append("end_date", moment(moment()).add(15, 'days').format("DD/MM/YYYY"));
        }

        var prod_service = ""
        getpackproducts.map((service) => (
            prod_service = prod_service + " -- " + JSON.parse(service).designation
        ))
        //alert("prod_ser = " + prod_service);


        form.append("0", JSON.stringify({
            "code": currentpack, "prix": packprice, "comment": "nc", "qty": nb_formations, "pack_products": prod_service
        }));


        //form.append("1", JSON.stringify({ "code": "art_supp", "prix": "120", "comment": "nc" }));



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/createOrder/";

        fetch(myurl,
            {
                method: 'POST',
                body: form,
            })
            .then((data) => data.json())
            .then((data) => {
                //console.log('Success:', data['message'], "data['status'] = ", data['status'], "ORDER ID = ", data['order_id']);
                if (String(data['status']) === String("true")) {
                    setorderid(data['order_id']);
                    setapiorderid("1");
                    //setmenu("ordervalide");
                    setpaymentmodeapi("");
                    setpaymentmode("");
                    setgotocheckout("");

                    console.log(" ### packprice = ", packprice);

                    /*
                    IMPORTANT :
                    On fait une finalisation d'abonnement que si c'est la première fois qu'il souscrit
                    */
                    if (!stripe_existing_souscription_id) {
                        if (parseFloat(packprice) > 0)
                            finalize_abonnement(local_souscription_id);
                        else if (String(props.packs).toLowerCase() === String("decouverte")) {
                            MySy_LMS_Account_Management();
                            window.location.reload();
                        }
                    } else {
                        console.log(" laaaaa ")
                        if (String(props.packs).toLowerCase() === String("decouverte")) {
                            MySy_LMS_Account_Management();
                            setLoading(false);
                            alert(" La mise à jour de votre abonnement a été correctement traitée. Vous allez recevoir par email les details de la commande et la facture.")
                            window.location.reload();
                        } else {
                            MySy_LMS_Account_Management();
                            setLoading(false);
                            alert(" La mise à jour de votre abonnement a été correctement traitée. Vous allez recevoir par email les details de la commande et la facture.")

                            window.location.reload();
                        }



                    }


                }
                else {
                    setapiorderid("0");
                    setapiorderidmessage(data['message']);
                    setLoading(false);
                }


            }).catch((error) => {
                console.error('Error:', error);
                setapiorderid("0");
                setLoading(false);

            });


    }

    const [paymentadded, setpaymentadded] = useState("");

    const [menumodepayement, setmenumodepayement] = useState("");
    function menu_cb() {
        setmenumodepayement("cb");
    }

    function menu_sepa() {
        setmenumodepayement("sepa");
    }


    // Mise à jour du compte LMs Mysy
    const [MySy_LMS_Account_Management_api, setMySy_LMS_Account_Management_api] = useState("");
    const [MySy_LMS_Account_Management_message, setMySy_LMS_Account_Management_message] = useState("");
    function MySy_LMS_Account_Management() {

        var formData_tpm = new FormData();
        formData_tpm.append('token', stored_part);
        //formData_tpm.append('stripe_payment_id', stripe_pi);
        //formData_tpm.append('packs', packs);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Check_MySy_LMS_Account/";

        axios.post(myurl, formData_tpm).then(res => {
            console.log('In MySy_LMS_Account_Management status = ', res.data.status, "  -- message = ", res.data.message);
            if (String(res.data.status) === String("true")) {
                setMySy_LMS_Account_Management_api("true");

            }
            else {
                setMySy_LMS_Account_Management_api("false")
                setMySy_LMS_Account_Management_message(String(res.data.message))
            }


        }).catch((error) => {
            console.log('In MySy_LMS_Account_Management ', error);
            setMySy_LMS_Account_Management_api("false")

        })

    }


    const [invoiceAdressOK, setinvoiceAdressOK] = useState();
    function checkInvoicingField() {

        var nom = document.getElementsByName("nom")[0].value;
        var vat_num = document.getElementsByName("vat_num")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;

        if (nom.trim().length <= 0) {
            alert("Information de facturation incomplete : Le nom ");
            setinvoiceAdressOK(false);
            return;
        }

        if (email.trim().length <= 0) {
            alert("Information de facturation incomplete : L'email ");
            setinvoiceAdressOK(false);
            return;
        }
        if (adr_street.trim().length <= 0) {
            alert("Information de facturation incomplete : L'adresse ");
            setinvoiceAdressOK(false);
            return;
        }

        if (adr_zip.trim().length <= 0) {
            alert("Information de facturation incomplete : Le code postal ");
            setinvoiceAdressOK(false);
            return;
        }

        if (adr_city.trim().length <= 0) {
            alert("Information de facturation incomplete : La ville ");
            setinvoiceAdressOK(false);
            return;
        }

        if (adr_country.trim().length <= 0) {
            alert("Information de facturation incomplete : Le pays ");
            setinvoiceAdressOK(false);
            return;
        }

    }

    const [addfacturationinfo_api, setaddfacturationinfo_api] = useState("");
    const [addfacturationinfo_response, setaddfacturationinfo_response] = useState("");

    function RecordData_infoFacturation() {
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/update_partner_account/";
        var nom = document.getElementsByName("nom")[0].value;
        var vat_num = document.getElementsByName("vat_num")[0].value;
        var telephone = document.getElementsByName("telephone")[0].value;
        var email = document.getElementsByName("email")[0].value;
        var adr_street = document.getElementsByName("adr_street")[0].value;
        var adr_zip = document.getElementsByName("adr_zip")[0].value;
        var adr_city = document.getElementsByName("adr_city")[0].value;
        var adr_country = document.getElementsByName("adr_country")[0].value;

        formData.append('partner_account_id', partner_account_id);
        formData.append('token', stored_part);
        formData.append('invoice_vat_num', vat_num);
        formData.append('invoice_nom', nom);
        formData.append('invoice_adr_street', adr_street);
        formData.append('invoice_adr_city', adr_city);
        formData.append('invoice_adr_zip', adr_zip);
        formData.append('invoice_adr_country', adr_country);
        formData.append('invoice_email', email);
        formData.append('invoice_telephone', telephone);

        if (nom.trim().length <= 0) {
            alert("Information de facturation incomplete : Le nom ");
            setinvoiceAdressOK(false);
            return;
        }

        if (email.trim().length <= 0) {
            alert("Information de facturation incomplete : L'email ");
            setinvoiceAdressOK(false);
            return;
        }
        if (adr_street.trim().length <= 0) {
            alert("Information de facturation incomplete : L'adresse ");
            setinvoiceAdressOK(false);
            return;
        }

        if (adr_zip.trim().length <= 0) {
            alert("Information de facturation incomplete : Le code postal ");
            setinvoiceAdressOK(false);
            return;
        }

        if (adr_city.trim().length <= 0) {
            alert("Information de facturation incomplete : La ville ");
            setinvoiceAdressOK(false);
            return;
        }

        if (adr_country.trim().length <= 0) {
            alert("Information de facturation incomplete : Le pays ");
            setinvoiceAdressOK(false);
            return;
        }
        fetch(
            url,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                //console.log('Success:', result['message'], "result['status'] = ", result['status']);
                setaddfacturationinfo_api(result['status']);


                if (String(result['status']) === String("true")) {
                    setaddfacturationinfo_response(result['message']);
                    setfields1desabled(true);
                    setokcommande("");
                } else if (String(result['status']) === String("Err_Connexion")) {
                    alert('Erreur: ' + result['message']);
                    history.push("/Connexion");
                }

                else {
                    setaddfacturationinfo_response("");
                    setfields1desabled(false);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setaddfacturationinfo_api("false");
                setaddfacturationinfo_response("");
            });
    };



    // Gestion payement Stripe

    const [price_ch, setprice_ch] = useState("");
    const [customer_ch, setcustomer_ch] = useState();
    const [subscriptionData, setSubscriptionData] = useState(null);

    const [payement_id, setpayement_id] = useState();
    const [clientSecret_id, setclientSecret_id] = useState();
    const [paymentIntent, setPaymentIntent] = useState();

    const [cust_strip_id_global, setcust_strip_id_global] = useState();



    function initpayement_front() {
        //alert("debut initpayement_front price_ch = " + price_ch);
        createSubscription(price_ch);
    }


    const createSubscription = async (priceId) => {

        var cust_strip_id = "";

        //----
        const formData_tpm = new FormData();
        const url_tmp = process.env.REACT_APP_API_URL + "myclass/api/get_partner_account/";
        formData_tpm.append('token', stored_part);

        fetch(url_tmp, {
            method: 'POST',
            body: formData_tpm,
        }).then((data) => data.json())
            .then((data) => {
                //console.log(" GRRRRRRRRRR  data.message = " + data.message);
                //console.log(" statusstatus  data.status = " + data.status);

                if (String(data['status']) === String("true")) {
                    var mylocaltraining = JSON.parse(data.message);
                    setcust_strip_id_global(mylocaltraining.stripe_account_id);
                    /* if (mylocaltraining) {

                        cust_strip_id = mylocaltraining.stripe_account_id;
                        var myurl = process.env.REACT_APP_API_URL + "myclass/api/create-subscription/";

                        var form = new FormData();


                        form.append("customerid", cust_strip_id);
                        form.append("pack", currentpack);
                        form.append("qty", nb_formations);

                        fetch(myurl, {
                            method: 'POST',
                            body: form,
                        }).then((data) => data.json())
                            .then((data) => {
                                //console.log(' #### retour myclass/api/create-subscription/ = subscription_id:', data['subscription_id'], ' ### payment_intent_client_secret:', data['payment_intent_client_secret'], "data['Status'] = ", data['status'],);

                                if (String(data['status']) === String("true")) {
                                    //console.log(" subscriptionId = " + subscriptionId + " -- et clientSecret = " + clientSecret);
                                    setclientSecret_id(data['payment_intent_client_secret']);
                                    setpayement_id(data['subscription_id']);

                                    //setSubscriptionData(data['subscription_id'], data['payment_intent_client_secret'] );
                                    //alert(" subscriptionId = " + data['subscription_id'] + " -- et clientSecret = " + data['payment_intent_client_secret']);

                                }


                            }).catch((error) => {
                                //console.log('Error:', error.message);
                            });
                    } */
                }


            }).catch((error) => {

                //console.log('Error:', error.message);


            });

        //--



    };

    const [payement_ok, setpayement_ok] = useState();
    const [amount_captured, setamount_captured] = useState();

    const [payement_3DS_url, setpayement_3DS_url] = useState();

    const [souscription_id, setsouscription_id] = useState();



    function finalize_abonnement(local_souscription_id) {
        //alert("Finalization de l'abonnement ");
        // Initialize an instance of stripe.

        setsouscription_id(local_souscription_id);

        const handleSubmit_ch = async (e) => {


            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            // Use card Element to tokenize payment details
            //alert(' clientSecret_id = ' + clientSecret_id)
            let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret_id, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: "cherif test MYSY",
                    }
                }
            });

            if (error) {
                // show error and collect new card details.
                alert(error.message);
                //console.log(error.message);
                return;
            }
            else {
                //alert(" OKK paymentIntent = " + paymentIntent);
                //console.log(paymentIntent);

                setPaymentIntent(paymentIntent);
                //alert(" début conformation cmd ");
                Ordervalidation();
            }
        }

        const handleSubmit_MySy_Back = async (e) => {

            var myurl = process.env.REACT_APP_API_URL + "myclass/api/confirm_payment/";
            var form = new FormData();
            form.append("stripe_payment_id", local_souscription_id);
            form.append('token', stored_part);


            fetch(myurl, {
                method: 'POST',
                body: form,
            }).then((data) => data.json())
                .then((data) => {
                    /*console.log(' #### status:', data['status'], '  -- payement_id:',
                        data['payement_id'], " -- amount_captured = ", data['amount_captured'],
                        " secure_url = ", data['secure_url']);*/

                    if (String(data['status']) === String("true")) {
                        setLoading(false);
                        setpayement_ok("1");
                        setamount_captured(data['amount_captured']);
                        setPaymentIntent("1");
                        setpayement_3DS_url(data['secure_url']);

                    }
                    else {
                        setLoading(false);
                        setpayement_ok("0");
                    }


                }).catch((error) => {

                    setLoading(false);
                    //console.log('Error:', error.message);
                    setpayement_ok("0");

                });

        }
        //handleSubmit_ch();
        handleSubmit_MySy_Back();


    }

    // Fing gestion stripe


    const [nb_formations, setnb_formations] = useState("0");

    const change_nb_formations = event => {

        if (String(payment_in_process) === "1") {
            // un payement est en cours, on ne modifie pas cette valeur
            return;
        }

        let valeur = event.target.value;

        if (String(props.packs).toLowerCase() === String("decouverte")) {
            setnb_formations("1");
        }
        else {

            setnb_formations(event.target.value);
        }
        document.getElementById('div_nb_formation').style.color = "#104277";
        document.getElementById('div_ttc').style.color = "#104277";

    }

    const [check_payement_finalise_order_api, setcheck_payement_finalise_order_api] = useState("");
    const [check_payement_finalise_order_message, setcheck_payement_finalise_order_message] = useState("");
    function check_payement_finalise_order() {

        var formData_tpm = new FormData();
        formData_tpm.append('token', stored_part);
        formData_tpm.append('stripe_payment_id', souscription_id);

        //console.log(" stripe_payment_id  = ", souscription_id);
        alert(" stripe_payment_id  = ", souscription_id);

        var myurl = process.env.REACT_APP_API_URL + "myclass/api/payment_retrieve_status/";

        axios.post(myurl, formData_tpm).then(res => {
            if (String(res.data.status) === String("true")) {
                setcheck_payement_finalise_order_api("true")


            }
            else {
                setcheck_payement_finalise_order_api("false")
                setcheck_payement_finalise_order_message(String(res.data.message))
            }


        }).catch((error) => {
            //console.log('In CheckSalesOrder ', error);
            setcheck_payement_finalise_order_api("false")

        })

    }

    const [code_promo_code, setcode_promo_code] = useState("");
    const [code_promo_type, setcode_promo_type] = useState("");
    const [code_promo_valeur, setcode_promo_valeur] = useState("");
    const [code_promo_text_to_display, setcode_promo_text_to_display] = useState("");

    const [handleOnblur_Code_Promo_api, sethandleOnblur_Code_Promo_api] = useState("");
    const [handleOnblur_Code_Promo_result, sethandleOnblur_Code_Promo_result] = useState("");
    const [handleOnblur_Code_Promo_message, sethandleOnblur_Code_Promo_message] = useState("");

    const handleOnblur_Code_Promo = (event) => {

        setcode_promo_code("");
        setcode_promo_type("");
        setcode_promo_valeur("");
        setcode_promo_text_to_display("");

        sethandleOnblur_Code_Promo_api("");
        sethandleOnblur_Code_Promo_result("");
        sethandleOnblur_Code_Promo_message("");




        setcode_promo_code(event.target.value);
        //console.log(" ### event.target.value = ", event.target.value);
        const formData = new FormData();
        const url = process.env.REACT_APP_API_URL + "myclass/api/Get_Given_Code_Promo/";
        formData.append('token', stored_part);
        formData.append('code_promo', event.target.value);

        axios.post(url, formData).then(res => {
            //console.log(" In getCurrentUserData  res.data.status = " + res.data.status);
            //console.log(" In getCurrentUserData  res.data.message = " + res.data.message);
            if (String(res.data.status) === "true") {

                sethandleOnblur_Code_Promo_api("true");
                sethandleOnblur_Code_Promo_result(res.data.message);

                var mycodepromo_data = JSON.parse(res.data.message);
                if (mycodepromo_data) {
                    //console.log(' mycodepromo_data = ', mycodepromo_data);
                    if (mycodepromo_data.type) {
                        setcode_promo_type(mycodepromo_data.type);
                    }
                    if (mycodepromo_data.valeur) {
                        setcode_promo_valeur(mycodepromo_data.valeur);
                    }

                    if (String(mycodepromo_data.type).toLocaleLowerCase() === "fix") {
                        setcode_promo_text_to_display("- " + mycodepromo_data.valeur + " €")
                    }
                    else if (String(mycodepromo_data.type).toLocaleLowerCase() === "percent") {
                        setcode_promo_text_to_display("- " + mycodepromo_data.valeur + " % ")
                    }

                }
            }
            else {

                sethandleOnblur_Code_Promo_api("false");
                sethandleOnblur_Code_Promo_message(res.data.message);
            }

        }).catch((error) => {
            console.warn('Erro handleOnblur_Code_Promo = ', error);
            sethandleOnblur_Code_Promo_api("false");
        })
    }


    return (
        <div className="checkout">

            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            {String(apiorderid) === String("0") && <div className="koUpdateData">
                Impossible de créer la commande.<br />
                {apiorderidmessage}
            </div>}


            {String(menu) === String("default") && <div>
                <h2 style={{ "textAlign": "center", "marginBottom": "2rem" }} ref={scrollDiv} id="scrollDiv"> Récapitulatif de votre commande </h2>

                <br />

                {payement_3DS_url && <div>

                    <iframe src={payement_3DS_url} id="3DS_iframe"
                        style={{
                            "border": "solid 2px", "width": "90%", "height": "600px",
                            "marginBottom": "2rem",
                            "marginLeft": "5%"
                        }}>

                    </iframe>

                </div>
                }


                <div className="div_row" >
                    <div className="div_row_gauche">

                        <div className="titre1"> Produits et Services </div>
                        Vous avez choisi un pack : <b> {String(props.packs).toLocaleUpperCase()} </b> :


                        {getpackproducts && String(getpackproducts).length > 0 && getpackproducts.map((service) => (
                            <ul className="list_produits_service">

                                {JSON.parse(service).designation}
                            </ul>

                        ))}
                        

                    </div>
                    <div className="div_row_droite">   <div className="titre1"> Détail prix </div>
                        <div>
                            <div style={{ "width": "90%", "float": "left" }}>
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                    Periodicité :
                                </div>
                                <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                    <b><u> {periodicity} </u> </b>
                                </div>


                            </div>

                            <div style={{ "width": "90%", "float": "left" }}>
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                    Prix unitaire :
                                </div>
                                <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                    {packprice} €
                                </div>


                            </div>

                            <div style={{ "width": "90%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                    Nombre de formations :
                                </div>
                                {(String(props.packs).toLowerCase() !== String("decouverte")) &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "left" }}>
                                        <input
                                            value={nb_formations}
                                            onChange={change_nb_formations}
                                            type="number"
                                            min="1"
                                            max="50"
                                            className="nb_ftion"
                                        />
                                    </div>
                                }

                                {(String(props.packs).toLowerCase() === String("decouverte")) &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                        1
                                    </div>
                                }


                            </div>

                            <div style={{ "width": "90%", "float": "left" }} id="div_ttc">
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                    Total HT :
                                </div>


                                {String(handleOnblur_Code_Promo_api) !== "true" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                        {(packprice * nb_formations).toFixed(2)} €
                                    </div>
                                }

                                {String(handleOnblur_Code_Promo_api) === "true" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>

                                        {String(code_promo_type) === "fix" && <nav>
                                            {(packprice * nb_formations).toFixed(2)} €
                                        </nav>}

                                        {String(code_promo_type) === "percent" && <nav>
                                            {(packprice * nb_formations).toFixed(2)} €
                                        </nav>}

                                    </div>
                                }



                            </div>

                            <div style={{ "width": "90%", "float": "left" }}>
                                <Tooltip className="tooltip_css" id="tooltip_id_1" />
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                    <a data-tooltip-id="tooltip_id_1" data-tooltip-html="Saisir le code remise">
                                        Code Remise :
                                        <input type="text" id="discount_val" name="discount_val"
                                            minlength="4" maxlength="20" size="20" style={{ "width": "50%", "float": "right" }}
                                            onBlur={handleOnblur_Code_Promo}>

                                        </input>
                                    </a>
                                    {handleOnblur_Code_Promo_api && String(handleOnblur_Code_Promo_api) === "false" &&
                                        <nav className="koUpdateData">  {handleOnblur_Code_Promo_message} </nav>
                                    }
                                </div>

                                {code_promo_text_to_display && String(code_promo_text_to_display) != "" &&

                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right", "color": "green", "fontSize": "bold" }}>
                                        {code_promo_text_to_display}
                                    </div>
                                }

                                {(!code_promo_text_to_display || String(code_promo_text_to_display)) === "" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                        0 €
                                    </div>
                                }

                            </div>


                            {code_promo_text_to_display && String(code_promo_text_to_display) != "" &&
                                <div style={{ "width": "90%", "float": "left" }} id="div_nb_formation" className="mob_heigh">
                                    <div style={{ "textAlign": "left", "width": "50%", "float": "left", "color": "green", "fontSize": "bold" }}>
                                        Montant Remise :
                                    </div>


                                    {String(handleOnblur_Code_Promo_api) === "true" &&
                                        <div style={{ "textAlign": "right", "width": "50%", "float": "right", "color": "green", "fontSize": "bold" }}>
                                            {String(code_promo_type) === "fix" && <nav>
                                                -  {(((packprice * nb_formations) - parseFloat(code_promo_valeur))).toFixed(2)} €
                                            </nav>}

                                            {String(code_promo_type) === "percent" && <nav>
                                                -  {((((packprice * nb_formations) * ((parseFloat(code_promo_valeur) / 100))))).toFixed(2)} €
                                            </nav>}
                                        </div>}

                                </div>
                            }
                            <div style={{ "width": "90%", "float": "left" }}>
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>

                                    TVA (20%):
                                </div>
                                {String(handleOnblur_Code_Promo_api) !== "true" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                        {(packprice * nb_formations * 0.2).toFixed(2)} €
                                    </div>}

                                {String(handleOnblur_Code_Promo_api) === "true" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                        {String(code_promo_type) === "fix" && <nav>
                                            {(((packprice * nb_formations) - parseFloat(code_promo_valeur)) * 0.2).toFixed(2)} €
                                        </nav>}

                                        {String(code_promo_type) === "percent" && <nav>
                                            {((((packprice * nb_formations) * (1 - (parseFloat(code_promo_valeur) / 100)))) * 0.2).toFixed(2)} €
                                        </nav>}
                                    </div>}
                            </div>


                            <div style={{ "width": "90%", "float": "left" }} id="div_ttc">
                                <div style={{ "textAlign": "left", "width": "50%", "float": "left" }}>
                                    Total TTC :
                                </div>


                                {String(handleOnblur_Code_Promo_api) !== "true" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>
                                        {(packprice * nb_formations * 1.2).toFixed(2)} €
                                    </div>
                                }

                                {String(handleOnblur_Code_Promo_api) === "true" &&
                                    <div style={{ "textAlign": "right", "width": "50%", "float": "right" }}>

                                        {String(code_promo_type) === "fix" && <nav>
                                            {(((packprice * nb_formations) - parseFloat(code_promo_valeur)) * 1.2).toFixed(2)} €
                                        </nav>}

                                        {String(code_promo_type) === "percent" && <nav>
                                            {((((packprice * nb_formations) * (1 - (parseFloat(code_promo_valeur) / 100)))) * 1.2).toFixed(2)} €
                                        </nav>}

                                    </div>
                                }


                                <div> &nbsp;
                                </div>
                            </div>

                            {String(props.packs).toLowerCase() === String("decouverte") && <div style={{ "width": "100%" }}>
                                <div style={{ "textAlign": "left", "width": "30%", "float": "left", "color": "red" }}>
                                    * Offre valable jusqu'au
                                </div>
                                <div style={{ "textAlign": "right", "width": "30%", "float": "left", "color": "red" }}>
                                    {moment(moment()).add(15, 'days').format("DD/MM/YYYY")}
                                </div>

                                <div> &nbsp;
                                </div>
                            </div>}

                        </div>
                    </div>


                </div>
                <hr style={{ "width": "100%" }} />
                <div className="div_row">
                    <div className="div_row_gauche">

                        <div className="titre1"> Detail de Facturation </div>

                        {String(addfacturationinfo_api) === String("true") && String(addfacturationinfo_response).length > 3 &&
                            <div className="okUpdateData"> La modification ete prise en compte </div>}

                        {String(addfacturationinfo_api) === String("false") || String(addfacturationinfo_response) === String("0") &&
                            <div className="koUpdateData"> Impossible de modifier les informations de facturation </div>}

                        &nbsp;
                        <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                            <TextField
                                required
                                disabled
                                label="Raison sociale"
                                name="nom"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                            <TextField

                                disabled
                                label="num TVA"
                                name="vat_num"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                            <br />

                            <TextField
                                disabled
                                name="telephone"
                                label="Téléphone"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />


                            <TextField
                                name="email"
                                disabled
                                required
                                label="Adresse mail"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />


                            <TextField
                                name="adr_street"
                                disabled
                                required
                                label="Adresse"
                                className="texte_area_adress"
                                sx={{ m: 1, width: '90%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                            <br />
                            <TextField
                                name="adr_zip"
                                disabled
                                required
                                label="Code Postal"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                            <TextField
                                name="adr_city"
                                disabled
                                required
                                label="Ville"
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                            <br />
                            <TextField
                                name="adr_country"
                                label="Pays"
                                disabled
                                required
                                className="texte_area"
                                sx={{ m: 1, width: '48%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <HomeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                            <br />


                            <div className="div_row22">
                                {fields1desabled == false && <div className="div_row">
                                    <div className="div_row_gauche">
                                        <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData_infoFacturation}>Enregister</Button>
                                    </div>
                                    <div className="div_row_droite">
                                        <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={getCurrentUserData}>Annuler</Button>
                                    </div>
                                </div>

                                }
                                {fields1desabled == true &&
                                    <Button variant="contained" color="success" className="div_row22 btn_modif" onClick={enablefieds1}>Editer</Button>
                                }

                            </div>
                        </Box>
                    </div>

                    <div className="div_row_droite">   <div className="titre1"> Mode de Paiement</div>



                        {String(paymentmodeapi) === String("True") && <div>
                            {paymentmode && String(paymentmode).length > 0 && paymentmode.map((payment) => (
                                <ul className="list_produits_service">
                                    <div>
                                        <ul>
                                            Type carte :  {(JSON.parse(payment).brand)}
                                        </ul>
                                        <ul>
                                            Nom carte :  {(JSON.parse(payment).nom_carte)}
                                        </ul>

                                        <ul>
                                            Num carte :  XXXXXX{(JSON.parse(payment).last4)}
                                        </ul>
                                        <ul>
                                            Expiration :  {(JSON.parse(payment).exp_month)}/{(JSON.parse(payment).exp_year)}
                                        </ul>
                                        <ul>
                                            Date Creation :  {(JSON.parse(payment).createdatetime)}
                                        </ul>


                                        <img src={img_logo_stripe} className="img_payment_checkout" /> &nbsp;




                                    </div>

                                </ul>


                            ))}


                            <br />
                            {/*payement_id &&
                                <div style={{ "width": "100%", "marginLeft": "auto", "marginRight": "auto" }}>
                                    <h1>Subscribe</h1>
                                    {clientSecret_id && <div>  <button onClick={initpayement_front}>
                                        Valide payement {clientSecret_id}
                                    </button>

                                        setpaymentmode =   {paymentmode}

                                    </div>}
                                    <div> les info de payement stocké sont :


                                        {paymentmode}

                                    </div>
                                    <p>
                                        Try the successful test card: <span>4242424242424242</span>.
                                    </p>

                                    <p>
                                        Try the test card that requires SCA: <span>4000002500003155</span>.
                                    </p>

                                    <p>
                                        Use any <i>future</i> expiry date, CVC,5 digit postal code
                                    </p>

                                    <hr />

                                    <div>
                                        <label>
                                            Full name
                                            <input type="text" id="name" value="cherif balde" />
                                        </label>

                                        <CardElement />

                                        <button onClick={finalize_abonnement}>
                                            Finalise Subscribe / Confirmer la commande
                                        </button>

                                        {paymentIntent && <h3> PAYEMENT OK REDIRECTION VERS LA PAGE OK</h3>}
                                    </div>
                                </div>
                            */}


                            {paymentmode && String(paymentmode).length > 0 && paymentmode.map((payment) => (
                                <ul className="list_produits_service">

                                    {String(JSON.parse(payment).type) === String("sepa") && <div>
                                        <div className="titre1" style={{ "fontSize": "small", "fontWeight": "normal" }}> Prelevement SEPA </div>
                                        <li>
                                            BIC :  {JSON.parse(payment).bic}
                                        </li>
                                        <li>
                                            IBAN : {(JSON.parse(payment).iban).substring(0, 10)}XXX
                                        </li>
                                        <li>
                                            Titulaire du compte : {JSON.parse(payment).nom_compte}
                                        </li>
                                        <li>
                                            Dernière mise à jour :  {(JSON.parse(payment).date_update).substring(0, 10)}
                                        </li>

                                    </div>}

                                    {String(JSON.parse(payment).type) === String("cb") && <div>
                                        <div className="titre1" style={{ "fontSize": "small", "fontWeight": "normal" }}> Prelevement Carte Bancaire </div>
                                        <li>
                                            Num carte :  {(JSON.parse(payment).num_carte).substring(0, 4)}XXXXXXXXX
                                        </li>
                                        <li>
                                            Date expiration : {JSON.parse(payment).date_exp_carte}
                                        </li>
                                        <li>
                                            Titulaire de la carte : {JSON.parse(payment).nom_carte}
                                        </li>
                                        <li>
                                            Dernière mise à jour :  {(JSON.parse(payment).date_update).substring(0, 10)}
                                        </li>

                                    </div>}



                                </ul>

                            ))}
                            {!paymentmode || String(paymentmode).length <= 0 && String(props.packs).toLocaleLowerCase() !== "decouverte" && <div className="koUpdateData div_row_central">
                                Vous n'avez pas de mode de Paiement enregistré
                                <div style={{
                                    "width": "50%", "marginLeft": "auto",
                                    "cursor": "pointer", "marginLeft": "auto", "marginRight": "auto", "textAlign": "center", "color": "green"
                                }} onClick={menu_paymentmethode}>  <IoAddCircleOutline /><br />Ajouter un mode de paiement</div>

                                {String(addpayementcheckout) === String("addpaymentmethode") && <div>
                                    <div className="paymentmethode">


                                        <div className="div_row_central">
                                            {String(paymentadded) === String("1") && <div className="okUpdateData">
                                                Le mode de Paiement a été correctement ajouté
                                            </div>
                                            }

                                            {String(paymentadded) === String("0") && <div className="koUpdateData">
                                                {result}
                                            </div>
                                            }

                                            <div className="titre1" onClick={menu_cb}> Prélèvement par carte bancaire </div>
                                            {String(menumodepayement) === String("cb") && <div>

                                                <div className="div_row_chekout">
                                                    <div className="div_row_gauche" style={{ "minHeight": "4rem" }}> Carte Bancaire </div>
                                                    <div className="div_row_droite" style={{ "textAlign": "right", "maxHeight": "rem" }}>
                                                        <img src={img_logo_stripe} className="img_payment_chekout" /> &nbsp;

                                                    </div>
                                                </div>
                                                &nbsp;
                                                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                                                    <TextField
                                                        required

                                                        label="Nom inscrit sur la carte"
                                                        name="nom_carte"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required
                                                        onChange={"CheckCardNumber"}
                                                        label="Numéro de carte"
                                                        name="num_carte"
                                                        id="num_carte"
                                                        autoComplete="off"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required

                                                        label="Expiration (MM/AA)"
                                                        name="date_exp"
                                                        id="date_exp"
                                                        autoComplete="new-qsq"
                                                        value={"dateexp"}
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '45%' }}
                                                        onChange={"Checkdate_expdate_exp"}
                                                        onBlur={"Onleave_expdate_exp"}
                                                        variant="standard"
                                                        inputProps={{
                                                            autoComplete: 'new-password',
                                                        }}
                                                    />

                                                    <TextField
                                                        required

                                                        label="CVV"
                                                        name="cvv"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '45%' }}
                                                        inputProps={{
                                                            autoComplete: 'new-password',
                                                        }}

                                                        variant="standard"
                                                    />



                                                </Box>

                                            </div>
                                            }
                                        </div>
                                        <div style={{ "width": "10%" }}> &nbsp;</div>

                                        <hr />
                                        <div className="div_row_central">
                                            <div className="titre1" onClick={menu_sepa}> Prélèvement par virement SEPA</div>

                                            {String(menumodepayement) === String("sepa") && <div>
                                                &nbsp;
                                                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                                                    <TextField
                                                        required

                                                        label="Nom du titulaire du compte"
                                                        name="nom_compte"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required

                                                        label="IBAN"
                                                        name="num_iban"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required

                                                        label="BIC"
                                                        name="bic"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                </Box>

                                            </div>}


                                        </div>
                                        <div style={{ "width": "10%" }}> &nbsp;</div>

                                        <hr />
                                        <div className="div_row_centre">
                                            <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregister</Button>
                                        </div>

                                    </div>
                                </div>}

                            </div>
                            }

                            {!paymentmode || String(paymentmode).length <= 0 && String(props.packs).toLocaleLowerCase() === "decouverte" && <div className="div_row_central">
                                Vous n'avez pas de mode de Paiement enregistré
                                <div style={{
                                    "width": "50%", "marginLeft": "auto",
                                    "cursor": "pointer", "marginLeft": "auto", "marginRight": "auto", "textAlign": "center", "color": "green"
                                }} onClick={menu_paymentmethode}>  <IoAddCircleOutline /><br />Ajouter un mode de paiement</div>

                                {String(addpayementcheckout) === String("addpaymentmethode") && <div>
                                    <div className="paymentmethode">


                                        <div className="div_row_central">
                                            {String(paymentadded) === String("1") && <div className="okUpdateData">
                                                Le mode de Paiement a été correctement ajouté
                                            </div>
                                            }

                                            {String(paymentadded) === String("0") && <div className="koUpdateData">
                                                {result}
                                            </div>
                                            }

                                            <div className="titre1" onClick={menu_cb}> Prélèvement par carte bancaire </div>
                                            {String(menumodepayement) === String("cb") && <div>

                                                <div className="div_row_chekout">
                                                    <div className="div_row_gauche" style={{ "minHeight": "4rem" }}> Carte Bancaire </div>
                                                    <div className="div_row_droite" style={{ "textAlign": "right", "maxHeight": "rem" }}>
                                                        <img src={img_visa_mastercard} className="img_payment_chekout" /> &nbsp;
                                                        <img src={img_paypal} className="img_payment_chekout" />
                                                    </div>
                                                </div>
                                                &nbsp;
                                                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                                                    <TextField
                                                        required

                                                        label="Nom inscrit sur la carte"
                                                        name="nom_carte"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required
                                                        onChange={"CheckCardNumber"}
                                                        label="Numéro de carte"
                                                        name="num_carte"
                                                        id="num_carte"
                                                        autoComplete="off"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required

                                                        label="Expiration (MM/AA)"
                                                        name="date_exp"
                                                        id="date_exp"
                                                        autoComplete="new-qsq"
                                                        value={"dateexp"}
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '45%' }}
                                                        onChange={"Checkdate_expdate_exp"}
                                                        onBlur={"Onleave_expdate_exp"}
                                                        variant="standard"
                                                        inputProps={{
                                                            autoComplete: 'new-password',
                                                        }}
                                                    />

                                                    <TextField
                                                        required

                                                        label="CVV"
                                                        name="cvv"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '45%' }}
                                                        inputProps={{
                                                            autoComplete: 'new-password',
                                                        }}

                                                        variant="standard"
                                                    />



                                                </Box>

                                            </div>
                                            }
                                        </div>
                                        <div style={{ "width": "10%" }}> &nbsp;</div>

                                        <hr />
                                        <div className="div_row_central">
                                            <div className="titre1" onClick={menu_sepa}> Prélèvement par virement SEPA</div>

                                            {String(menumodepayement) === String("sepa") && <div>
                                                &nbsp;
                                                <Box alignItems="left" sx={{ '& > :not(style)': { m: 1 }, width: '100%', }}>

                                                    <TextField
                                                        required

                                                        label="Nom du titulaire du compte"
                                                        name="nom_compte"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required

                                                        label="IBAN"
                                                        name="num_iban"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                    <TextField
                                                        required

                                                        label="BIC"
                                                        name="bic"
                                                        className="texte_area"
                                                        sx={{ m: 1, width: '95%' }}

                                                        variant="standard"
                                                    />

                                                </Box>

                                            </div>}


                                        </div>
                                        <div style={{ "width": "10%" }}> &nbsp;</div>

                                        <hr />
                                        <div className="div_row_centre">
                                            <Button variant="contained" color="success" className="div_row22 btn_enreg " onClick={RecordData}>Enregister</Button>
                                        </div>

                                    </div>
                                </div>}

                            </div>
                            }


                        </div>}

                        {String(paymentmodeapi) === String("False") && (String(props.packs).toLowerCase() !== String("decouverte")) &&
                            <div className="koUpdateData">
                                Impossible de recuperer votre mode de payment

                            </div>
                        }
                        {(String(props.packs).toLowerCase() === String("decouverte")) &&
                            <div className="OkUpdateData">
                                Abonnement decouverte : Gratuit 15 jours

                            </div>
                        }


                    </div>
                </div>

                <div className="div_row">



                    <div className="div_row_center">

                        {(String(okcommande) !== String("ko") ||
                            (String(props.packs).toLowerCase() === String("decouverte"))) &&
                            String(payment_in_process) !== "1"
                            && !stripe_existing_souscription_id
                            &&

                            <Button variant="contained" color="success" className="div_row22 btn_enreg_cmd " onClick={Ordervalidation}>Confirmer  la commande</Button>}

                        {(String(okcommande) !== String("ko") ||
                            (String(props.packs).toLowerCase() === String("decouverte"))) &&
                            String(payment_in_process) !== "1"
                            && stripe_existing_souscription_id &&

                            <Popup
                                trigger={<Button variant="contained" color="success" className="div_row22 btn_enreg_cmd "
                                >
                                    Mettre à jour mon abonnement

                                </Button>}
                                modal
                                nested
                                position="center center"
                            >
                                {close => (
                                    <div>
                                        <button className="gest_close" onClick={close}>
                                            &times;
                                        </button>
                                        <div className="gest_header"> MySy Information </div>
                                        <div className="gest_content">
                                            {' '}

                                            <b>Important :</b> <br />
                                            La modification de votre abonnement va entrainer la désactivation de vos formations en cours. Mais vous pourrez les réactiver selon les conditions de votre nouvel abonnement.</div>
                                        <div className="gest_actions">
                                            <div style={{ "width": "45%", "float": "left" }}>
                                                <button className="gest_bton_popup" onClick={(event) => {
                                                    Ordervalidation();
                                                    close();
                                                }}> Valider </button>

                                            </div>
                                            <div style={{ "width": "45%", "float": "right" }}>
                                                <button
                                                    className="gest_bton_popup"
                                                    onClick={() => {

                                                        close();
                                                    }}
                                                >
                                                    Annuler
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popup>

                        }


                        {String(okcommande) === String("ko") && (String(props.packs).toLowerCase() !== String("decouverte")) &&
                            String(payment_in_process) !== "1"
                            && <Button variant="contained" disabled className="div_row22 btn_enreg_cmd " onClick={""}>Confirmer la commande</Button>}
                    </div>
                </div>

            </div>}

            {String(menu) === String("ordervalide") && String(apiorderid) === String("1") && <div>
                <OrderConfirmation num_cmd={orderid} packs={String(props.packs).toLocaleUpperCase()} />
            </div>}

        </div>);

}

export default CheckOut;