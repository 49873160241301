import React, { useRef, useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Button from '@mui/material/Button';
import axios from "axios";
import { getCookie, setCookie } from 'react-use-cookie';
import { useHistory } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import 'reactjs-popup/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import moment from "moment";
import Box from '@mui/material/Box';
import img_loading_spin from "./../mysy_img/mysy_spin_loading.gif";
import { tokens } from "./../theme";
import { MdOutlineAddchart, } from "react-icons/md";


import {
    ListItem,
    ListItemText,
    useTheme,
} from "@mui/material";

import { ResponsiveLine } from '@nivo/line'
import { TbColumns2, TbColumns1 } from "react-icons/tb";


const Dashbord_Formation = (props) => {

    const history = useHistory();
    const [isLoading, setLoading] = useState();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMySyDashboard, setisMySyDashboard] = useState(false);

    var date_today_90j = new Date();
    date_today_90j.setDate(date_today_90j.getDate() - 90);


    const [p_filter_date_from, setp_filter_date_from] = useState(date_today_90j.toLocaleDateString('fr-FR'));
    const [p_filter_date_to, setp_filter_date_to] = useState(new Date().toLocaleDateString('fr-FR'));


    const [dashbord_internal_code, setdashbord_internal_code] = useState("");

    const [Getall_Qry_Formation_Session_result_JSON, setGetall_Qry_Formation_Session_result_JSON] = useState([]);
    const [Getall_Qry_Formation_Session_api, setGetall_Qry_Formation_Session_api] = useState();
    const [Getall_Qry_Formation_Session_message, setGetall_Qry_Formation_Session_message] = useState();
    const [Getall_Qry_Formation_Session_result, setGetall_Qry_Formation_Session_result] = useState([]);
    function Getall_Qry_Formation_Session(event) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        var date_from = "";
        if (p_filter_date_from) {
            date_from = p_filter_date_from;

        }

        var date_to = "";
        if (p_filter_date_to) {
            date_to = p_filter_date_to;

        }


        form.append("token", stored_cookie);
        form.append("session_start_date", date_from);
        form.append("session_end_date", date_to);
        form.append("filter_value", filter_value);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Get_Qery_Formation_By_Session_By_Periode/";

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                console.log(" In Getall_Qry_Formation_Session  res.data.status = " + res.data.status);
                console.log(" In Getall_Qry_Formation_Session  res.data.message r_class = " + res.data.message);
                setGetall_Qry_Formation_Session_result_JSON(JSON.parse( res.data.message ));

                var jsonObj = JSON.parse( res.data.message );

                console.log(" jsonObj = ", jsonObj);

                setGetall_Qry_Formation_Session_api("true");
                setGetall_Qry_Formation_Session_result(res.data.message );

                console.log(" Getall_Qry_Formation_Session_result_JSON = ", Getall_Qry_Formation_Session_result_JSON);


            }
            else {
                setGetall_Qry_Formation_Session_api("false");
                setGetall_Qry_Formation_Session_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Getall_Qry_Formation_Session = ', error);
            setGetall_Qry_Formation_Session_api("false");
            alert("Impossible de récuperer les données demandées")
            //setmyApimyApiMessage("")
        })
    }


    useEffect(() => {


        Getall_Qry_Formation_Session();

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

    }, [])

    const [Add_To_User_TBD_api, setAdd_To_User_TBD_api] = useState();
    const [Add_To_User_TBD_message, setAdd_To_User_TBD_message] = useState();
    const [Add_To_User_TBD_result, setAdd_To_User_TBD_result] = useState();
    function Add_To_User_TBD(local_dashbord_internal_code) {

        var form = new FormData();
        const stored_cookie = getCookie('tokenmysypart');

        //console.log(" ### dashbord_internal_code = ", local_dashbord_internal_code);

        var tdb_title = "default";
        var filter_val = "";


        if (document.getElementsByName("tdb_title")[0]) {
            tdb_title = document.getElementsByName("tdb_title")[0].value;
        }

        if (document.getElementsByName("filtre11")[0]) {
            filter_val = document.getElementsByName("filtre11")[0].value;
        }

        // Si filter_val =  "d", alors on va recuperer l'interval de date
        if (String(filter_val) === "d") {
            var date_from = p_filter_date_from;
            var date_to = p_filter_date_to;


            filter_val = "{'session_start_date':'" + String(date_from) + "' , 'session_end_date':'" + String(date_to) + "'}";
        } else {
            filter_val = "{'periode':'" + String(filter_val) + "'}"
        }

        form.append("token", stored_cookie);
        form.append("dashbord_internal_code", local_dashbord_internal_code);
        form.append("default_filter", filter_val);
        form.append("title", tdb_title);



        var myurl = process.env.REACT_APP_API_URL + "myclass/api/Add_To_User_Dashbord/";

        //console.log(" ## myurl = ", myurl);

        setLoading(true);
        axios.post(myurl, form).then(res => {
            setLoading(false);
            if (String(res.data.status) === String("true")) {
                //console.log(" In Add_To_User_TBD  res.data.status = " + res.data.status);
                //console.log(" In Add_To_User_TBD  res.data.message r_class = " + res.data.message);

                setAdd_To_User_TBD_api("true");
                setAdd_To_User_TBD_result(res.data.message);
                settdb_title();
                alert(res.data.message)


            }
            else {
                setAdd_To_User_TBD_api("false");
                setAdd_To_User_TBD_message(res.data.message);
                alert(res.data.message)
            }

        }).catch((error) => {
            setLoading(false);
            console.warn('Not good man :( Add_To_User_TBD = ', error);
            setAdd_To_User_TBD_api("false");
            alert("Impossible d'ajouter le tableau de bord");
            //setmyApimyApiMessage("")
        })
    }


    const [filter_value, setfilter_value] = useState("d");
    const [tdb_title, settdb_title] = useState();

    const [display_nb_column, setdisplay_nb_column] = useState("2");
    const [display_column_size, setdisplay_column_size] = useState("50%");

    return (
        <div className="dashbord_formation">
            {isLoading && <div className="loader-container">
                <div className="mysy_spinner">  <img src={img_loading_spin} />  </div>
            </div>}

            <h3> Tableaux de bord des formations</h3>
            <div className="div_row"> &nbsp;</div>
            <div className="div_row" style={{ "textAlign": "right", "paddingRight": "20px" }}> Affichage &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_1_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_1_col" data-tooltip-html="Affichage sur une colonne">
                    <TbColumns1 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("1");
                        setdisplay_column_size("100%");

                    }} />
                </a>  &nbsp; &nbsp;
                <Tooltip className="tooltip_css" id="my_tooltip_2_col" style={{ "fontSize": "12px" }} />
                <a data-tooltip-id="my_tooltip_2_col" data-tooltip-html="Affichage sur deux colonnes">
                    <TbColumns2 style={{ "cursor": "pointer" }} onClick={(e) => {
                        setdisplay_nb_column("2");
                        setdisplay_column_size("50%");

                    }} />
                </a> </div>

            <div className="div_row">
                <div className="" style={{ "width": "30%" }}>
                    Filtre <br />
                    <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="filtre11"
                        id="filtre11"
                        select
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"
                        value={filter_value}
                        onChange={(e) => {
                            setfilter_value(e.target.value);
                        }
                        }

                    >
                        <MenuItem value="m0" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }}>Mois en cours &nbsp;&nbsp;</MenuItem>
                        <MenuItem value="m1" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Mois précédant &nbsp;</MenuItem>
                        <MenuItem value="d" style={{ "paddingLeft": "5px", "textAlign": "left", "width": "100%" }} >Choisir des dates &nbsp;</MenuItem>
                    </TextField>
                </div>

                {filter_value && String(filter_value) === "d" && <div>
                    <div className="session_caract">
                        Date Debut
                        <DatePicker
                            name="one_sessiondatedebut"
                            id="one_sessiondatedebut"

                            selected={new Date(moment(p_filter_date_from, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_filter_date_from(format(date, 'dd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style session_caract_Dialog_DatePicker"
                            locale='fr-FR'

                        />

                    </div>

                    <div className="session_caract">
                        Date Fin
                        <DatePicker
                            name="one_sessiondatefin"
                            id="one_sessiondatefin"
                            selected={new Date(moment(p_filter_date_to, "DD/MM/YYYY"))}
                            onChange={(date) => {
                                setp_filter_date_to(format(date, 'dd/MM/yyyy'));

                            }
                            }
                            showTimeSelect={false}
                            dateFormat="dd/MM/yyyy"
                            className="disabled_style enable_style"
                        />

                    </div>

                </div>}
                <Button variant="contained" className="bton_enreg" onClick={(e) => {
                    Getall_Qry_Formation_Session();
                }}> Afficher
                </Button><br />


            </div>


            <div style={{ "float": "left", "width": "100%", "paddingRight": "5px" }}>
                <div style={{ "float": "left", "width": String(display_column_size), "height": "42rem", "float": "left", "paddingRight": "5px" }} >

                    <div className="titre1" style={{ "float": "left", "cursor": "none" }}>
                        <nav style={{ "width": "90%", "float": "left", "textAlign": "center" }}> TBD Formation : Evolution session par formation </nav>
                        <Tooltip className="tooltip_css" id="my-tooltip01" style={{ "fontSize": "12px" }} />
                        <a data-tooltip-id="my-tooltip01" data-tooltip-html="Ajouter à ma liste de TBD">

                            <nav style={{ "width": "9%", "float": "right", "textAlign": "right", "cursor": "pointer" }}>
                                <MdOutlineAddchart style={{ "cursor": "pointer", }} onClick={(e) => settdb_title("1")} />
                            </nav>
                        </a>

                    </div>
                    {tdb_title && String(tdb_title) === "1" && <nav> Donnez un titre  <TextField
                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
                        required
                        name="tdb_title"
                        id="tdb_title"
                        text

                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={false}
                        className="disabled_style"


                    />
                        <nav style={{ "textAlign": "center" }}>   <button onClick={(event) => {
                            setdashbord_internal_code("CODE_FORMATION_01")
                            Add_To_User_TBD("tbd_code_formation_01");

                        }}> Ajouter </button>  &nbsp;&nbsp; <button onClick={(e) => settdb_title("0")}> Fermer </button>
                        </nav>

                    </nav>}
                    <Box style={{ "color": "black", "height": "30rem" }}>
                        &nbsp;
                        <ResponsiveLine
                            data={Getall_Qry_Formation_Session_result_JSON}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: true,
                                reverse: false
                            }}
                            yFormat=" >-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'transportation',
                                legendOffset: 36,
                                legendPosition: 'middle'
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'count',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }}
                            pointSize={10}
                            pointColor={{ theme: 'background' }}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: 'serieColor' }}
                            pointLabelYOffset={-12}
                            useMesh={true}
                            legends={[
                                {
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 100,
                                    translateY: 0,
                                    itemsSpacing: 0,
                                    itemDirection: 'left-to-right',
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemOpacity: 0.75,
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemBackground: 'rgba(0, 0, 0, .03)',
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />



                    </Box>

                </div>

            </div>


        </div>
    )
}


export default Dashbord_Formation;